import { auth } from "../firebase/config";

export const getLatestToken = async () => {
    return auth.currentUser
        ?.getIdToken() // If passed true it will force refresh.
        .then(idToken => idToken)
        .catch(error => {
            throw new Error(error.response);
        });
};
