import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty, cloneDeep } from "lodash";
import { HNP_URL } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import {
    getRequiredObject,
    getInitialRowsCount,
    setTestLeadDefaultValues,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.LEAD_DEFINITION.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.LEAD_DEFINITION.filterOperator,
        filtersOperator: FILTERS.LEAD_DEFINITION.operator,
        filters: [],
    },
    createLeadDefinition: {
        leadName: "",
        isPrep: false,
        isAuth: false,
        prepData: null,
        authData: null,
        leadData: null,
        leadRequestUrl: "",
        authUrl: "",
        prepUrl: "",
        showPrompt: true,
        loading: false,
        mainLoader: false,
        hnpCreative: null,
        basics: {
            error: false,
        },
        prepError: {
            error: false,
        },
        authError: {
            error: false,
        },
        leadReqError: {
            error: false,
        },
        testLeadData: null,
        historyData: [],
        historyDataRaw: [],
    },
    createLeadDefinitionBackup: {},
    searchFilter: {
        filterApplied: getInitialTagData([], "leaddefinition"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};
const urlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

export const historyListingsTable = createAsyncThunk(
    "listings/leadDefinition/historyAdUnitListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${HNP_URL.FETCH_HISTORY_LISTINGS_LEAD_DEFINITION}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const deleteLeadDefinition = createAsyncThunk(
    "listings/leadDefinition/deleteLeadDefinition",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${HNP_URL.DELETE_HNP_CREATIVE}/${id}`,
            "DELETE"
        );
        if (get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Successfully deleted the lead definition with id - ${id}`,
                })
            );
            dispatch(fetchLeadDefinitionData());
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete the lead definition with id - ${id}`,
                })
            );
        }
        return response;
    }
);

const settingDefaultTestLeadValue = value => {
    if (value) {
        Object.keys(value).forEach(keys => {
            if (value[keys].hasOwnProperty("value")) {
                if (
                    value[keys].type === "number" ||
                    value[keys].type === "boolean"
                ) {
                    value[keys].value = setTestLeadDefaultValues(
                        value[keys].value
                    );
                    value[keys].value = value[keys].value.includes("%%")
                        ? value[keys].value.slice(2, -2)
                        : value[keys].value;
                } else if (
                    typeof value[keys].value === "string" &&
                    value[keys].value.includes("%%")
                ) {
                    value[keys].value = setTestLeadDefaultValues(
                        value[keys].value
                    );
                }
            } else {
                settingDefaultTestLeadValue(value[keys]);
            }
        });
    }
};
export const fetchHnpCreativeData = createAsyncThunk(
    "listings/leadDefinition/fetchHnpCreativeData",
    async (id, { dispatch }) => {
        dispatch(leadDefinitionActions.setCreateLeadDefinitionLoading(true));
        const response = await customFetch(
            `${HNP_URL.GET_LEAD_DEFINITION_DATA}/${id}`,
            "GET"
        );
        if (
            isEmpty(get(response, "data.data", {})) ||
            get(response, "status", "") != 200
        )
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch hnp creative Data!",
                    autoClose: 5000,
                })
            );
        dispatch(
            leadDefinitionActions.setCreateLeadDefinitionHnpCreative(
                get(response, "data.data", {})
            )
        );
        dispatch(
            leadDefinitionActions.setLeadName(
                get(response, "data.data.name", {})
            )
        );

        let clonedData = cloneDeep(
            get(response, "data.data.leadDef.requestParams", {})
        );
        settingDefaultTestLeadValue(clonedData);

        // dispatch(leadDefinitionActions.setTestLeadData(clonedData));
        return response;
    }
);

export const createAndUpdateHnpCreative = createAsyncThunk(
    "listings/leadDefinition/createAndUpdateHnpCreative",
    async ({ id = null, params }, { getState, dispatch }) => {
        dispatch(leadDefinitionActions.setMainLoader(true));
        const response = await customFetch(
            id != null
                ? `${HNP_URL.UPDATE_HNP_CREATIVE}/${id}`
                : HNP_URL.CREATE_HNP_CREATIVE,
            id != null ? "PUT" : "POST",
            params
        );
        if (response && get(response, "data.success", false)) {
            dispatch(leadDefinitionActions.setMainLoader(false));
            dispatch(
                leadDefinitionActions.setCreateLeadDefinitionShowPrompt(false)
            );
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `${
                        id != null ? "Updated" : "Created"
                    } Posting Spec Successfully`,
                    autoClose: 5000,
                })
            );
        } else
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to ${
                        id != null ? "update" : "create"
                    } hnp creative!`,
                    autoClose: 5000,
                })
            );
        return response;
    }
);

export const fetchLeadDefinitionData = createAsyncThunk(
    "listings/leadDefinition/fetchLeadDefinitionListingData",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "leadDefinition.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "leadDefinition.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.LEAD_DEFINITION
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(leadDefinitionSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.leadDefinition.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.LEAD_DEFINITION,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.leadDefinition.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                HNP_URL.FETCH_LEAD_DEFINITION_LIST,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch lead definition list",
                        })
                    );
                    dispatch(
                        leadDefinitionSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(
                leadDefinitionActions.setFilter(filterParamsData?.filters)
            );
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.LEAD_DEFINITION}.`;
            dispatch(leadDefinitionSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Posting Spec`;
            dispatch(leadDefinitionSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/leadDefinition/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.leadDefinition.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.LEAD_DEFINITION.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const leadDefinitionSlice = createSlice({
    name: "leadDefinition",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        setCreateLeadDefinitionIsPrep(state, action) {
            state.createLeadDefinition.isPrep = action.payload;
        },
        setCreateLeadDefinitionIsAuth(state, action) {
            state.createLeadDefinition.isAuth = action.payload;
        },
        setCreateLeadDefinitionPrepData(state, action) {
            state.createLeadDefinition.prepData = action.payload;
        },
        setCreateLeadDefinitionAuthData(state, action) {
            state.createLeadDefinition.authData = action.payload;
        },
        setCreateLeadDefinitionLeadData(state, action) {
            state.createLeadDefinition.leadData = action.payload;
        },
        setLeadName(state, action) {
            state.createLeadDefinition.leadName = action.payload;
            state.createLeadDefinition.basics.error =
                action.payload.trim() === "";
        },
        setLeadNameError(state, action) {
            state.createLeadDefinition.basics.error = action.payload;
        },
        setCreateLeadDefinitionLeadRequestUrl(state, action) {
            state.createLeadDefinition.leadRequestUrl = action.payload;
            state.createLeadDefinition.leadReqError.error =
                !action.payload.match(urlRegex);
        },
        setCreateLeadDefinitionReqUrlError(state, action) {
            state.createLeadDefinition.leadReqError.error = action.payload;
        },

        setCreateLeadDefinitionPrepRequestUrl(state, action) {
            state.createLeadDefinition.prepUrl = action.payload;
            state.createLeadDefinition.prepError.error =
                !action.payload.match(urlRegex);
        },
        setCreateLeadDefinitionAuthRequestUrl(state, action) {
            state.createLeadDefinition.authUrl = action.payload;
            state.createLeadDefinition.authError.error =
                !action.payload.match(urlRegex);
        },
        setCreateLeadDefinitionAuthRequestUrlError(state, action) {
            state.createLeadDefinition.authError.error = action.payload;
        },
        setCreateLeadDefinitionPrepUrlError(state, action) {
            state.createLeadDefinition.prepError.error = action.payload;
        },
        setCreateLeadDefinitionShowPrompt(state, action) {
            state.createLeadDefinition.showPrompt = action.payload;
        },
        setCreateLeadDefinitionLoading(state, action) {
            state.createLeadDefinition.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.createLeadDefinition.mainLoader = action.payload;
        },
        setCreateLeadDefinitionHnpCreative(state, action) {
            state.createLeadDefinition.hnpCreative = action.payload;
        },
        setCreateLeadDefinitionRequestParamsData(state, action) {
            state.createLeadDefinition.hnpCreative.leadDef = {
                requestParams: action.payload,
            };
        },
        setTestLeadData(state, action) {
            let clonedData = cloneDeep(action.payload[0]);
            if (!action.payload[1]) settingDefaultTestLeadValue(clonedData);
            state.createLeadDefinition.testLeadData = clonedData;
        },
        resetData(state, action) {
            let clonedLeadDefinition = cloneDeep(
                initialState.createLeadDefinition
            );
            if (action.payload) {
                clonedLeadDefinition.showPrompt = false;
            }
            state.createLeadDefinition = clonedLeadDefinition;
            state.createLeadDefinitionBackup = clonedLeadDefinition;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setHistoryData(state, action) {
            state.createLeadDefinition.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createLeadDefinition.historyDataRaw = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setCreateLeadDefinitionBackup(state, action) {
            state.createLeadDefinitionBackup = state.createLeadDefinition;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchLeadDefinitionData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchLeadDefinitionData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const leadDefinitionActions = leadDefinitionSlice.actions;
export default leadDefinitionSlice.reducer;
