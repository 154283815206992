import { useCallback, useEffect, useState } from "react";
import { getSimpleBezierPath, internalsSymbol, useStore } from "reactflow";
import EdgeLabelRenderer from "../../Shared/FlowRenderer/CustomEdgeLabelRenderer";
import Icon from "../../Shared/Icon/Icon";
import style from "./CustomEdge.module.scss";
import { useSelector } from "react-redux";
import Tooltip from "../../Shared/Tooltip/Tooltip";

const baseTurnAfter = 60;
const baseTurnAt = 20;

const getCustomSmoothStepPath = ({
    sourceNode,
    targetNode,
    data,
    isSourceRouter,
}) => {
    let direction =
        sourceNode.positionAbsolute.x - targetNode.positionAbsolute.x > 0
            ? "left"
            : "right";

    const sourceHandle = sourceNode[internalsSymbol].handleBounds.source.find(
        h => h.position === (data?.direction ? direction : "bottom")
    ) || { x: 0, y: 0, height: 0, width: 0 };

    const sourceX =
        sourceNode.positionAbsolute.x + sourceHandle.x + sourceHandle.width / 2;

    const sourceY =
        sourceNode.positionAbsolute.y +
        sourceHandle.y +
        sourceHandle.height / 2;

    const targetX = targetNode.positionAbsolute.x + targetNode.width / 2;

    const targetY = targetNode.positionAbsolute.y;

    let turnAfter = baseTurnAfter;
    let turnAt = baseTurnAt;

    const labelX = sourceX;
    // let labelY = sourceY + (turnAt + turnAfter) / 2;
    let labelY = sourceY + turnAt;
    // d = √((x2 – x1)² + (y2 – y1)²)

    let distance = Math.sqrt(
        Math.pow(targetX - sourceX, 2) + Math.pow(targetY - sourceY, 2)
    );

    // if (distance < 60) {
    //     labelY -= 10;
    // }

    const yDistance = Math.abs(targetY - sourceY);

    if (yDistance > 80) {
        const turnAfterMultiplier = yDistance / 80;
        turnAfter =
            baseTurnAfter * (turnAfterMultiplier > 4 ? 4 : turnAfterMultiplier);
    } else {
        // turnAt = baseTurnAt / 2;
    }

    if (data?.direction) {
        if (direction === "left") {
            return [
                `M ${sourceX} ${sourceY} 
                C ${targetX} ${sourceY} 
                ${targetX} ${targetY - turnAfter} 
                ${targetX} ${targetY}`,
                labelX,
                labelY,
                distance,
            ];
        }
        return [
            `M ${sourceX} ${sourceY} 
            C ${targetX} ${sourceY} 
            ${targetX} ${targetY - turnAfter} 
            ${targetX} ${targetY}`,
            labelX,
            labelY,
            distance,
        ];
    }

    // MOD with ruleSetID for a ruleSetId specific difference in line
    let nodeRuleSetValue = sourceNode?.ruleSetId
        ? sourceNode?.ruleSetId % 30
        : 0;
    let ty = targetY - (10 + nodeRuleSetValue) - distance / 10;

    ty = Math.max(sourceY + 40, ty);

    if (isSourceRouter) {
        ty = sourceY + 20;
    }

    const mx = (sourceX + targetX) / 2;

    const uy = targetY - 20;

    const xDiff = sourceX - targetX;

    const yDiff = ty - uy;
    const curve = 10;

    const curveX = Math.max(-curve, Math.min(curve, xDiff));
    const curveY = Math.max(-curve, Math.min(curve, yDiff));
    const absCurveY = Math.abs(curveY);

    // const dmx = sourceX - targetX > 0 ? 1 : -1;
    // const dmy = sourceY - ty > 0 ? -1 : 1;

    const dmy = 1;
    const dmx = 1;

    const extraCurves =
        ty - uy > 0
            ? `C ${mx + dmx * curveX} ${ty}
        ${mx} ${ty}
        ${mx} ${ty - dmy * curveY}

        C ${mx} ${uy + curveY}
        ${mx} ${uy}
        ${mx - dmx * curveX} ${uy}
    
        C ${targetX + dmx * curveX} ${uy}
        ${targetX} ${uy}
        ${targetX} ${uy + absCurveY}
        `
            : `
        C ${targetX + dmx * curveX} ${ty}
        ${targetX} ${ty}
        ${targetX} ${ty + absCurveY}
    `;

    return [
        `M ${sourceX} ${sourceY},

        C ${sourceX} ${ty - absCurveY}
        ${sourceX} ${ty}
        ${sourceX - dmx * curveX} ${ty}

        ${extraCurves}

        V ${targetY}`,
        labelX,
        labelY,
        distance,
    ];
};

export const CustomEdge = ({
    id,
    source,
    target,
    data,
    markerEnd,
    disablePasteIcon,
    readOnly,
    parentRef,
    noCustomFunctionForLinesPlot = false,

    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
}) => {
    const sourceNode = useStore(
        useCallback(store => store.nodeInternals.get(source), [source])
    );

    const targetNode = useStore(
        useCallback(store => store.nodeInternals.get(target), [target])
    );

    if (!sourceNode || !targetNode) {
        return null;
    }

    const isSourceRouter = source?.includes("set:");

    const [edgePath, labelX, labelY, distance] = noCustomFunctionForLinesPlot
        ? getSimpleBezierPath({
              sourceX,
              sourceY,
              sourcePosition,
              targetX,
              targetY,
              targetPosition,
          })
        : getCustomSmoothStepPath({
              sourceNode,
              targetNode,
              data,
              isSourceRouter,
          });

    const [showActions, setShowActions] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);

    const color = showActions
        ? isDarkMode
            ? "#81919E"
            : "#41C3A9"
        : data?.color;
    // markerEnd.color = showActions ? "#41C3A9" : data?.color;

    return (
        <>
            <path
                key={id + "transparent"}
                id={id}
                style={{
                    stroke: "transparent",
                    strokeWidth: "15px",
                }}
                className={`react-flow__edge-path ${style.edge}`}
                d={edgePath}
                onMouseEnter={() => setShowActions(true)}
                onMouseLeave={() => setShowActions(false)}
            />
            <path
                key={id}
                id={id}
                style={{
                    stroke: color,
                    strokeWidth: "2px",
                }}
                className={`react-flow__edge-path ${style.edge}${
                    readOnly ? style.addDisable : ""
                }`}
                d={edgePath}
                markerEnd={markerEnd}
            />
            {showActions && !readOnly && data?.addNode && (
                <EdgeLabelRenderer
                    parentRef={parentRef}
                    key={id + "edge-renderer"}
                >
                    <div
                        style={{
                            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                            color: color,
                        }}
                        className={`nodrag nopan ${style.addButton}`}
                        onMouseEnter={() => setShowActions(true)}
                        onMouseLeave={() => setShowActions(false)}
                    >
                        <Tooltip message={"Add Node"} position="top">
                            <span
                                className="dp-parent-inline dp-parent-ver-center"
                                onClick={
                                    !readOnly ? () => data?.addNode() : null
                                }
                            >
                                <Icon
                                    className={style.icon}
                                    icon="Add"
                                    enableHover={true}
                                    size="14px"
                                    disable={readOnly}
                                />
                            </span>
                        </Tooltip>
                        {data.loadCopiedNodes && (
                            <Tooltip
                                message={"Load copied nodes"}
                                position="top"
                            >
                                <span
                                    className="dp-parent-inline dp-parent-ver-center"
                                    onClick={() =>
                                        !disablePasteIcon &&
                                        data?.loadCopiedNodes()
                                    }
                                >
                                    <Icon
                                        className={`${style.icon} ${
                                            disablePasteIcon
                                                ? style.icon__disabled
                                                : ""
                                        } ml-5`}
                                        icon="Paste"
                                        enableHover={!readOnly ? true : false}
                                        size="14px"
                                        disable={disablePasteIcon || readOnly}
                                    />
                                </span>
                            </Tooltip>
                        )}

                        {data.disConnectFlow && (
                            <Tooltip message={"Disconnect Flow"} position="top">
                                <span
                                    className="dp-parent-inline dp-parent-ver-center"
                                    onClick={
                                        !readOnly
                                            ? () => data?.disConnectFlow()
                                            : null
                                    }
                                >
                                    <Icon
                                        icon="Disconnect"
                                        enableHover={!readOnly ? true : false}
                                        size="14px"
                                        disable={readOnly}
                                    />
                                </span>
                            </Tooltip>
                        )}
                        {expanded ? (
                            <span
                                className="dp-parent-inline dp-parent-ver-center"
                                onClick={() => {
                                    setExpanded(true);
                                    data?.expandCollapse();
                                }}
                            >
                                {data.expandCollapse && (
                                    <Icon
                                        className={`${style.icon} ml-5`}
                                        icon="Expand"
                                        enableHover={true}
                                        size="14px"
                                    />
                                )}
                            </span>
                        ) : (
                            <span
                                className="dp-parent-inline dp-parent-ver-center"
                                onClick={() => {
                                    setExpanded(false);
                                    data?.expandCollapse();
                                }}
                            >
                                {data.expandCollapse && (
                                    <Icon
                                        className={`${style.icon} ml-5`}
                                        icon="Collapse"
                                        enableHover={true}
                                        size="14px"
                                    />
                                )}
                            </span>
                        )}
                    </div>
                </EdgeLabelRenderer>
            )}
        </>
    );
};
