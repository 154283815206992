import React, { useState } from "react";
import c from "./Accordion.scss";
import AccordionContainer from "./AccordionContainer/AccordionContainer";

const Accordion = ({
    type,
    accordianClass,
    accordianClassOpen,
    accordianWrapClass,
    children,
    accordContainer,
    accordContainerClass,
    showContent = false,
    accordContainerKey,
}) => {
    const [showContainer, setShowContainer] = useState(showContent);
    const toggleContainer = () => {
        setShowContainer(!showContainer);
    };
    return (
        <div
            className={`${c.accordionWrap} ${
                type ? (type.small ? c.small : c.large) : c.large
            } ${accordianWrapClass ? accordianWrapClass : ""}`}
        >
            <div
                className={`${c.accordion} ${showContainer ? c.open : ""} ${
                    accordianClass ? accordianClass : ""
                } ${
                    accordianClassOpen && showContainer
                        ? accordianClassOpen
                        : ""
                }`}
                onClick={toggleContainer}
            >
                <div className={c.accContent}>{children}</div>
            </div>

            {showContainer ? (
                <AccordionContainer
                    key={accordContainerKey ? accordContainerKey : ""}
                    extraClass={accordContainerClass}
                >
                    {accordContainer}
                </AccordionContainer>
            ) : null}
        </div>
    );
};

export default Accordion;
