import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MAIN } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import customFetch from "../../fetch/customFetch";
import _, { get, isEmpty } from "lodash";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    updatedAt: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.FLOW.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.FLOW.filterOperator,
        filtersOperator: FILTERS.FLOW.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "flow"),
    },
    createSearchFilter: {
        filterApplied: getInitialTagData([], "createFlow"),
    },
    tableError: "",
    historyData: [],
    historyDataRaw: [],
    availableTargetting: [],
};

export const fetchFlowDetails = createAsyncThunk(
    "flowmanager/flow/flowData",
    async (id, { dispatch }) => {
        const response = await customFetch(`${MAIN.GET_FLOW}/${id}`);
        const data = get(response, "data.data", {});
        if (isEmpty(data))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Flow Details!",
                })
            );
        return data;
    }
);

export const fetchFlows = createAsyncThunk(
    "flowmanager/flow/fetchListingData",
    async (refreshClicked, { dispatch, getState }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "flows.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "flows.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.FLOW
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(flowSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "flowmanager.flows.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.FLOW,
                filterParamsList
            );
        }
        const listingData = get(getState(), "flowmanager.flows.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                MAIN.GET_ALL_FLOWS_POST,
                "POST",
                filterParamsData,
                {},
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch flows",
                        })
                    );
                    dispatch(
                        flowSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(flowActions.setFilter(filterParamsData?.filters));
            data = get(response, "data.data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.QUESTIONS}`;
            dispatch(flowSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new flow`;
            dispatch(flowSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const createFlow = createAsyncThunk(
    "flowmanager/flow/create",
    async (params, { dispatch }) => {
        let isConcurreny = false;
        let isDuplicateNode = false;
        const response = await customFetch(
            MAIN.SAVE_ALL,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    isConcurreny = true;
                }
                if (resData.message.includes("duplicate value in branches")) {
                    isDuplicateNode = true;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Duplicate values in targeting nodes aren't allowed.",
                        })
                    );
                    return response;
                }
            }
        );
        if (
            get(response, "data.status", false) !== "success" &&
            !isConcurreny &&
            !isDuplicateNode
        )
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to create the Flow! Please try again later!",
                })
            );

        if (isConcurreny) {
            return { isConcurreny: true };
        } else return response;
    }
);

export const deleteFlow = createAsyncThunk(
    "flowmanager/flow/delete",
    async (flow, { dispatch }) => {
        const response = await customFetch(MAIN.SAVE_FLOW, "POST", {
            ...flow,
            isActive: false,
        });
        if (get(response, "data.status", false) === "success") {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Flow with id ${flow?.id} deleted successfully!`,
                })
            );
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to delete this Flow! Please try again later!",
                })
            );
        }

        return response;
    }
);

export const updateFlows = createAsyncThunk(
    "flowmanager/flow/edit",
    async (params, { dispatch }) => {
        let isConcurreny = false;
        const response = await customFetch(
            MAIN.SAVE_FLOW,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Flow already modified while you were editing",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload flow",
                        })
                    );
                    isConcurreny = true;
                }
            }
        );
        if (!get(response, "data.success", false) && !isConcurreny)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to update the segment! Please try again later!",
                })
            );
        return response;
    }
);

export const deleteQuestion = createAsyncThunk(
    "flowmanager/flow/delete",
    async (params, { dispatch }) => {
        const response = await customFetch(MAIN.SAVE_FLOW, "POST", {
            ...params,
            isActive: false,
        });
        if (get(response, "data.status", false) !== "success") {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to delete the segment! Please try again later!",
                })
            );
        }
        return get(response, "data.status", false);
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/flow/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.flows.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        let filterParamsData = FILTER_PARAMS.CREATE_FLOW.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const onCreateFilterApplied = createAsyncThunk(
    "listings/flow/onCreateFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.flows.createSearchFilter.filterApplied;
        let filterApplied = [...initialData];

        let filterParamsData = FILTER_PARAMS.CREATE_FLOW.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchFlowHistory = createAsyncThunk(
    "flowmanager/flow/flowHistory",
    async id => {
        const response = await customFetch(`${MAIN.FETCH_FLOW_HISTORY}/${id}`);
        return get(response, "data", []);
    }
);

export const fetchPublishFlowHistory = createAsyncThunk(
    "flowmanager/flow/publishFlowHistory",
    async id => {
        const response = await customFetch(`${MAIN.GET_PUBLISH_HISTORY}/${id}`);
        return get(response, "data", []);
    }
);

export const fetchProdFlowDetails = createAsyncThunk(
    "flowmanager/flow/flowData",
    async (id, { dispatch }) => {
        const response = await customFetch(`${MAIN.GET_PROD_FLOW}/${id}`);
        const data = get(response, "data.data", {});
        if (isEmpty(data))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch production flow details",
                })
            );
        return data;
    }
);

const flowSlice = createSlice({
    name: "flow",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },

        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },

        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
        setAvailableTargetting(state, action) {
            state.availableTargetting = action.payload;
        },
        setFilters(state, action) {
            state.createSearchFilter.filterApplied = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchFlows.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchFlows.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []).map(_ => ({
                ..._,
                ..._.flow,
            }));
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(onCreateFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.createSearchFilter.filterApplied = filterApplied;
        });
    },
});

export const flowActions = flowSlice.actions;
export default flowSlice.reducer;
