import React from "react";
import Select from "../../Shared/Select/Select";
import styles from "../CollectionDetails/CollectionDetails.module.scss";
import { useState, useEffect, useRef } from "react";
import _, { get, isEmpty, cloneDeep } from "lodash";
import { questionsActions } from "../../store/listings/questions";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import AddNewDetail from "../AddNewDetail/AddNewDetail";
import Icon from "../../Shared/Icon/Icon";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreateSurveyPathData } from "../../store/listings/surveyPaths";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import { useParams } from "react-router-dom";
import { collectionActions } from "../../store/flowmanager/collection";
import { v4 as uuidv4 } from "uuid";
import CreateOfferPath from "../../Pages/CreateOfferPath/CreateOfferPath";
import LinearDeterminate from "../LinearDeterminate/LinearDeterminate";
import {
    createOfferPathsActions,
    updateOfferPathData,
} from "../../store/listings/offerPaths";
import { fetchOfferPathData } from "../../store/survey/offerpath";
import ReactSelect from "../../Shared/ReactSelect/ReactSelect";

const DetailWrap = ({
    inRightPanel = false,
    question,
    index,
    dataIndex,
    readOnly,
    editOptions,
    removeQuestion,
    isLoading,
}) => {
    const editDetailsRef = useRef();
    const createOfferPathRef = useRef(null);
    const dispatch = useDispatch();
    const [activeDetail, setActiveDetail] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);

    const DragHandle = SortableHandle(() => (
        <div
            className={` ${styles.option} ${styles.option__drag} circleIconColor circleIconColor--darkHover`}
        >
            <Icon icon="Drag" size="16px" />
        </div>
    ));

    const editDetail = async (detail, isPreview = false) => {
        setActiveDetail(detail);
        if (!isPreview) editDetailsRef.current.open();
    };

    const { mainLoader } = useSelector(state => state.listings.offerPaths);

    const updateOfferPath = async id => {
        let isValid = await createOfferPathRef.current.validateAllFields();
        if (isValid) {
            dispatch(createOfferPathsActions.setMainLoader(true));
            dispatch(updateOfferPathData(id))
                .unwrap()
                .then(async response => {
                    if (response) {
                        setTimeout(async () => {
                            await dispatch(fetchOfferPathData()).then(
                                async res => {
                                    await dispatch(
                                        collectionActions.setSelectedOfferPathId(
                                            get(response, "data.data.id", "")
                                        )
                                    );
                                }
                            );
                            dispatch(
                                createOfferPathsActions.setMainLoader(false)
                            );
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Success",
                                    message: "Updated Offer Path Successfully",
                                })
                            );
                            await editDetailsRef.current.close();
                        }, 1000);
                    } else {
                        dispatch(createOfferPathsActions.setMainLoader(false));
                    }
                });
        }
    };

    return (
        <div
            className={`${
                !inRightPanel
                    ? styles["question-details-wrap"]
                    : styles["question-details-wrap-sidepanel"]
            }`}
            data-index={dataIndex ? dataIndex : index + 1}
        >
            <div
                className={`dp-parent dp-parent-hor-space-between dp-parent-ver-center mb-20`}
            >
                {!inRightPanel && (
                    <div
                        className={`dp-parent dp-parent-hor-space-between dp-parent-ver-center ${styles.questionContainer}`}
                    >
                        <div className={styles.key}>Name : </div>
                        <div
                            className={styles.questionValue}
                            title={question.question}
                        >
                            {isLoading ? (
                                <LinearDeterminate
                                    customClass={styles.loader}
                                ></LinearDeterminate>
                            ) : question?.question ? (
                                question?.question
                            ) : (
                                question?.properties?.offer?.name
                            )}
                        </div>
                    </div>
                )}
                {editOptions && (
                    <div
                        className={`dp-parent gap-20 ${styles["edit-options"]}`}
                    >
                        {!readOnly && <DragHandle></DragHandle>}

                        <div
                            className={`${styles.option} circleIconColor`}
                            onClick={() => {
                                editDetail(question);
                            }}
                        >
                            <Icon icon="Edit" size="16px" enableHover={true} />
                        </div>
                        {!readOnly && (
                            <div
                                className={`${styles.option} circleIconColor circleIconColor--delete`}
                                onClick={() => {
                                    question?.id
                                        ? setDeletePopup(question)
                                        : removeQuestion(question);
                                }}
                            >
                                <Icon
                                    icon="Delete"
                                    size="16px"
                                    enableHover={true}
                                />
                            </div>
                        )}
                    </div>
                )}
                {deletePopup === question && (
                    <div className={styles.deletePopup}>
                        <PopupTargettingParams
                            setName={question}
                            Rfunction={removeQuestion}
                            setShowPopup={setDeletePopup}
                            customText={`Do you wish to delete this item?`}
                        />
                    </div>
                )}
            </div>
            <>
                <div className={`dp-parent dp-parent-ver-center`}>
                    <div className={`${styles.idKey}`}>ID :</div>
                    <div
                        className={`${styles.value} ${styles.layoutValue}`}
                        title={
                            question?.properties?.offer?.id
                                ? question?.properties?.offer?.id
                                : "-"
                        }
                    >
                        {question?.properties?.offer?.id
                            ? question?.properties?.offer?.id
                            : "-"}
                    </div>
                </div>
            </>
            <SidePanelInfinity
                title={`${
                    question?.properties?.offer?.id ? "Edit" : "Create"
                } Offer Path`}
                width={"95%"}
                ref={editDetailsRef}
                onSave={() => {
                    updateOfferPath(question?.properties?.offer?.id);
                }}
                disableSaveBtn={readOnly || mainLoader}
                children={
                    <div className={styles.offerWrapper}>
                        {question?.properties?.offer?.id ? (
                            <CreateOfferPath
                                id={question?.properties?.offer?.id}
                                isSidePanel={false}
                                ref={createOfferPathRef}
                            />
                        ) : (
                            <CreateOfferPath
                                id={null}
                                isSidePanel={false}
                                ref={createOfferPathRef}
                            />
                        )}
                    </div>
                }
            ></SidePanelInfinity>
        </div>
    );
};

const SortableItem = SortableElement(
    ({
        question,
        index,
        dataIndex,
        selectedQuestionId,
        surveyPathId,
        actions,
        className,
        readOnly,
        editOptions,
        removeQuestion,
        isLoading,
    }) => (
        <DetailWrap
            question={question}
            index={index}
            dataIndex={dataIndex}
            selectedQuestionId={selectedQuestionId}
            surveyPathId={surveyPathId}
            actions={actions}
            className={className}
            readOnly={readOnly}
            editOptions={editOptions}
            removeQuestion={removeQuestion}
            isLoading={isLoading}
        ></DetailWrap>
    )
);

const SortableList = SortableContainer(({ children }) => {
    return <div className="question-details">{children}</div>;
});

const DetailsList = ({
    selectedQuestionId,
    questionsList,
    editOptions,
    className,
    setQuestionList,
    actions,
    readOnly = false,
    surveyPathId = "",
    deletedQuestions,
    isLoading = false,
}) => {
    const [editId, setEditId] = useState([]);
    const dispatch = useDispatch();

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        if (typeof setQuestionList == "function") {
            let initialSortList = await arrayMove(
                questionsList,
                oldIndex,
                newIndex
            );
            let updatedList = await JSON.parse(
                JSON.stringify(initialSortList)
            )?.map((_, index) => {
                return {
                    ..._,
                    rank: index + 1,
                };
            });
            await setQuestionList(updatedList);
        }
    };

    const removeQuestion = question => {
        let questionListClone = cloneDeep([...questionsList]);
        questionListClone.find(
            value => value.rank === question?.rank
        ).isActive = false;
        let updatedQuestionList = [];
        let questionsDeleted = [...deletedQuestions];
        let rank = 1;
        questionListClone.forEach(_ => {
            if (_?.isActive) {
                let updatedQuestion = {
                    ..._,
                    rank: rank,
                };
                updatedQuestionList.push(updatedQuestion);
                rank++;
            } else {
                let updatedQuestion = {
                    ..._,
                    rank: -1, //Setting this to -1 as need to update the isActive status to backend
                };
                // created a new reference for deleted questions as the select only gives selected questions
                questionsDeleted.push(updatedQuestion);
            }
        });
        setQuestionList(updatedQuestionList);
        dispatch(collectionActions.setDeletedQuestions(questionsDeleted));
    };

    const saveQuestionHandler = question => {
        let prevState = cloneDeep(questionsList);
        const index = prevState.findIndex(ques => {
            return ques.id == question.id;
        });
        if (index !== -1) {
            prevState[index] = {
                ...prevState[index],
                questionName: question.questionName,
                questionTitle: question.questionTitle,
                optionList: question.optionList,
                hasOther: question.hasOther,
            };
        }
        setQuestionList(prevState);
        cancelQuestionHandler(question.id);
    };
    const cancelQuestionHandler = questionId => {
        setEditId(prevState => {
            return prevState.filter(id => {
                return id != questionId;
            });
        });
    };

    return (
        <>
            {editOptions && questionsList && (
                <SortableList onSortEnd={onSortEnd} useDragHandle>
                    {questionsList
                        ?.filter(_ => _.isActive)
                        ?.map((question, index) =>
                            editId.indexOf(question.id) == -1 ? (
                                question?.isActive ? (
                                    <SortableItem
                                        key={
                                            `sort-${question?.properties?.offer?.offer_path_id}` ||
                                            `sort-${question?.id}`
                                        }
                                        index={index}
                                        question={question}
                                        dataIndex={index + 1}
                                        selectedQuestionId={selectedQuestionId}
                                        surveyPathId={surveyPathId}
                                        actions={actions}
                                        className={className}
                                        readOnly={readOnly}
                                        editOptions={editOptions}
                                        removeQuestion={removeQuestion}
                                        isLoading={isLoading}
                                    />
                                ) : null
                            ) : (
                                <AddNewDetail
                                    question={question}
                                    className={styles["add-question-wrap"]}
                                    onSave={saveQuestionHandler}
                                    onCancel={cancelQuestionHandler.bind(
                                        null,
                                        question.id
                                    )}
                                    actions={questionsActions}
                                ></AddNewDetail>
                            )
                        )}
                </SortableList>
            )}
            {!editOptions &&
                questionsList &&
                questionsList.map((question, index) => (
                    <DetailWrap
                        key={
                            `detailWrap-${question?.properties?.offer?.name}` ||
                            `detailWrap-${question.id}`
                        }
                        question={question}
                        index={index}
                        selectedQuestionId={selectedQuestionId}
                        surveyPathId={surveyPathId}
                        actions={actions}
                        className={className}
                        readOnly={readOnly}
                        editOptions={editOptions}
                        removeQuestion={removeQuestion}
                    ></DetailWrap>
                ))}
        </>
    );
};

const mapOfferPathItems = (op, offerPathList) => {
    let newOfferPathList = op.map(_ => ({
        ..._,
        properties: {
            offer: {
                ..._.properties?.offer,
                id: +_?.properties?.offer?.offer_path_id,
                name: offerPathList?.find(
                    op => op.id === +_?.properties?.offer?.offer_path_id
                )?.name,
            },
        },
    }));
    return newOfferPathList;
};

const OfferPathDetails = ({
    type,
    cardTitle = "Details",
    isReadOnly = false,
    isSidePanel = false,
    isLoading = false,
}) => {
    const collectionState = useSelector(state => state.flowmanager.collection);
    const allOfferList = useSelector(state => state.survey.offerPath.list);
    let items = get(collectionState, "createCollection.detailsList", []);
    items = mapOfferPathItems(items, allOfferList);
    const addItems = get(collectionState, "createCollection.addItems", []);
    const deletedQuestions = get(
        collectionState,
        "createCollection.deletedQuestions",
        []
    );
    const addItemsBtnRef = useRef();
    const dispatch = useDispatch();
    const urlParams = useParams();
    const surveyPaths = useSelector(state => state.listings.surveyPaths);
    const {
        createSurveyPath: { offerGroups = [], adUnits = [] },
    } = surveyPaths;

    const addDetail = get(
        collectionState,
        "createCollection.itemsDisable",
        false
    );

    const addDefaultDetail = () => {
        dispatch(collectionActions.setDetailItems([...items]));
    };

    const addNewItems = _ => {
        addDefaultDetail();
        dispatch(collectionActions.setAddItems(true));
    };

    useEffect(() => {
        if (addItems) dispatch(collectionActions.setAddItems(false));
        if (isEmpty(adUnits) && isEmpty(offerGroups))
            dispatch(fetchCreateSurveyPathData());
        return () => {
            dispatch(collectionActions.resetCollection());
        };
    }, []);

    const assignRank = data => {
        const getTcpaWithId = arr => {
            return JSON.parse(JSON.stringify(arr)).map(item => {
                item.tempId = uuidv4();
                return item;
            });
        };
        dispatch(
            collectionActions.setDetailItems(
                data.map((item, index) => {
                    return {
                        ...item,
                        rank: index + 1,
                        tcpa: getTcpaWithId(item?.tcpa || []),
                    };
                })
            )
        );
    };

    useEffect(() => {
        assignRank(items);
    }, [items.length]);

    const onAddItem = data => {
        dispatch(collectionActions.setDetailItems(data));
    };

    const scrollToButton = () => {
        setTimeout(() => {
            addItemsBtnRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }, 10);
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={`${!isSidePanel ? "flexHeightTab" : ""} row`}
        >
            <div className={`row`}>
                <p className={`headerTitle`}>{cardTitle}</p>
                <p className={`headerTitleRequired`}>Collection item</p>
                <div className={`${styles["add-new-question"]}`}>
                    {items.length > 0 && (
                        <div className={styles["question-list-wrap"]}>
                            <DetailsList
                                setQuestionList={list => {
                                    onAddItem(list);
                                }}
                                className={styles["question-details-card"]}
                                questionsList={items}
                                editOptions={true}
                                readOnly={isReadOnly}
                                deletedQuestions={deletedQuestions}
                                isLoading={isLoading}
                            ></DetailsList>
                        </div>
                    )}
                </div>

                {!isReadOnly && (
                    <div ref={addItemsBtnRef} className="relative">
                        {addItems && (
                            <div className={styles["addItemsDropdownWrapper"]}>
                                <ReactSelect
                                    options={
                                        allOfferList?.map(op => ({
                                            ...op,
                                            offer_path_id: op?.id,
                                        })) || []
                                    }
                                    isMulti
                                    menuIsOpen
                                    selectedIds={
                                        items
                                            ?.filter(el => el?.isActive)
                                            ?.map(
                                                _ => _.properties?.offer?.id
                                            ) || []
                                    }
                                    maxMenuHeight={214}
                                    listSelector={"name"}
                                    searchIdField={"id"}
                                    onCancelHandler={e => {
                                        dispatch(
                                            collectionActions.setAddItems(false)
                                        );
                                    }}
                                    customOnSelect={data => {
                                        //transform data as required
                                        let initialItems = [...items];
                                        let transformedData = data
                                            .map(itm => ({
                                                ...initialItems.find(
                                                    item =>
                                                        item?.properties?.offer
                                                            ?.id === itm.id &&
                                                        item
                                                ),
                                                ...itm,
                                            }))
                                            ?.sort((a, b) => a.rank - b.rank)
                                            ?.map((ele, index) => ({
                                                ...items[index],
                                                properties: {
                                                    offer: ele,
                                                    configInfo:
                                                        ele?.properties
                                                            ?.configInfo || {},
                                                    buttons: ["next"],
                                                },
                                                isActive: true,
                                            }));
                                        onAddItem(transformedData);
                                        scrollToButton();
                                    }}
                                />
                            </div>
                        )}
                        <Button
                            className={styles["add-question-button"]}
                            onClick={addNewItems}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={addDetail || isLoading}
                        >
                            Add Items
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OfferPathDetails;
