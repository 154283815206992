import Card from "../../Shared/Card/Card";
import AdUnitBasics from "../../components/AdUnitBasics/AdUnitBasics";
import AdUnitSlots from "../../components/AdUnitSlots/AdUnitSlots";
import React, {
    useEffect,
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
} from "react";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import { adUnits } from "../../constants/leftPanelConstants";
import s from "./CreateAdUnit.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
    addAdUnitDetails,
    adUnitsActions,
    updateAdUnitDetails,
    fetchAdCreatives,
    fetchAdUnitDetails,
    fetchAdUnitsData,
    historyListingsTable,
} from "../../store/listings/adUnits";
import { useHistory, useParams } from "react-router";
import { toastListActions } from "../../store/toastList/toastList";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { get, isEqual } from "lodash";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import Popup from "../../Shared/Popup/Popup";
import ConfirmAndSave from "../../components/ConfirmAndSave/ConfirmAndSave";
import { adsConfirmConfig } from "../../constants/confirmSaveConstants";
import { getComparisonData } from "../../utils/historyPopUpUtils";
import { SURVEYS } from "../../constants/url";
import { reactQueryApiCall } from "../../generalApi/generalApi";

const CreateAdUnit = forwardRef(({ id, isSidePanel = false }, ref) => {
    const urlParams = useParams();
    const history = useHistory();
    const adUnitsData = useSelector(state => state.listings.adUnits);
    const {
        basics = {},
        slots = {},
        deletedSlots = [],
        historyData = [],
        historyDataRaw = [],
    } = adUnitsData.createAdUnit;
    const { loading, mainLoader } = useSelector(
        state => state.listings.adUnits
    );
    const showLeftPanel = useSelector(
        state => state.globalConfig.showLeftPanel
    );
    const dispatch = useDispatch();
    const {
        name = "",
        type = null,
        status = false,
        surveys = [],
        selectedSurveyIds = [],
        adUnitId = false,
    } = basics;
    const [showPrompt, setShowPrompt] = useState(true);
    const adUnitBasicRef = useRef();
    const [showPopup, setShowPopup] = useState(false);
    const [dropdownNewVal, setDropdownNewVal] = useState(false);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [formChanges, setFormChanges] = useState(false);

    const { apiData: offerPathKeyList } = reactQueryApiCall(
        "offerPathKeys",
        "Offer path keys for mapping",
        {
            url: SURVEYS.GET_OFFER_KEYS,
            method: "GET",
        }
    );

    useEffect(() => {
        setFormChanges(
            getComparisonData(
                adUnitsData.createAdUnitBackup,
                adUnitsData.createAdUnit,
                adsConfirmConfig,
                true
            )[0]?.length > 0 || false
        );
    }, [adUnitsData]);

    const slotType = {
        WeightGroup: "weight",
        PriorityGroup: "priority",
    };

    useEffect(() => {
        if (adUnitBasicRef.current) adUnitBasicRef.current.focus();
        dispatch(adUnitsActions.resetAdUnit());
        if ((!urlParams.id && !isSidePanel) || (!id && isSidePanel)) {
            dispatch(fetchAdCreatives());
            return;
        }
        !isSidePanel &&
            urlParams.id &&
            dispatch(fetchAdUnitDetails(urlParams.id));
        isSidePanel && id && dispatch(fetchAdUnitDetails(id));
    }, []);

    const onSaveAdUnitHandler = () => {
        const isFormValid = validateAllFields();
        if (isFormValid) {
            setShowSaveConfirm(true);
        }
    };

    const authUser = useSelector(state => state.auth.user);
    const isReadOnly = !authUser.modules.includes(`SurveyManager.AdUnit.Write`);

    const [dropdownError, setDropdownError] = useState(false);

    useImperativeHandle(ref, () => {
        return {
            validateAllFields: validateAllFields,
        };
    });

    const addAdUnit = () => {
        dispatch(adUnitsActions.setMainLoader(true));
        dispatch(addAdUnitDetails())
            .unwrap()
            .then(response => {
                setShowPrompt(false);
                if (response) {
                    dispatch(adUnitsActions.setMainLoader(false));
                    dispatch(
                        adUnitsActions.setWeightGroupId(
                            get(response, "data.data.weightGroup.id", "")
                        )
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Success",
                            message: "Created Ad Unit successfully",
                        })
                    );
                    navigateToListing();
                }
            });
    };

    const updateAdUnit = id => {
        dispatch(adUnitsActions.setMainLoader(true));
        dispatch(updateAdUnitDetails(id))
            .unwrap()
            .then(response => {
                setShowPrompt(false);
                if (response) {
                    dispatch(adUnitsActions.setMainLoader(false));
                    dispatch(
                        toastListActions.setToastList({
                            type: "Success",
                            message: "Updated Ad Unit Successfully",
                        })
                    );
                    dispatch(adUnitsActions.setAdUnitInitialType(null));
                    navigateToListing();
                }
            });
    };

    const navigateToListing = () => {
        history.replace({ pathname: `/surveydetails/adunits/listings` });
        dispatch(fetchAdUnitsData());
    };

    const validateAllFields = () => {
        const isAdUnitNameValid = adUnitBasicRef.current.validate();
        const isAdUnitSlotsValid =
            !!get(slots, "selectedListItems", []).length &&
            !isEqual(deletedSlots, get(slots, "selectedListItems", []));

        const isAdUnitSlotsValueValid = get(
            slots,
            "selectedListItems",
            []
        ).every(elem => elem.weight !== "" || elem.priority !== "");

        if (type == null) {
            setDropdownError(true);
        } else {
            setDropdownError(false);
        }
        dispatch(adUnitsActions.setAdUnitSlotsError(false));
        dispatch(adUnitsActions.setAdUnitBasicError(false));
        if (
            isAdUnitNameValid &&
            type != null &&
            isAdUnitSlotsValid &&
            isAdUnitSlotsValueValid
        ) {
            dispatch(adUnitsActions.setAdUnitBasicError(false));
            return true;
        } else {
            if (!isAdUnitNameValid || type === null) {
                dispatch(adUnitsActions.setAdUnitBasicError(true));
            }
            if (!isAdUnitSlotsValid) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Enter atleast one Ad Unit slot",
                    })
                );
                dispatch(adUnitsActions.setAdUnitSlotsError(true));
            }
            if (!isAdUnitSlotsValueValid) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Enter valid value for Ad Unit slot(s)",
                    })
                );
                dispatch(adUnitsActions.setAdUnitSlotsError(true));
            }
        }
    };

    const changeDropdownValue = val => {
        dispatch(adUnitsActions.setAdUnitType(val));
        setDropdownError(false);
        if (type != null && adUnitBasicRef.current.value.length > 0) {
            dispatch(adUnitsActions.setAdUnitBasicError(false));
        }
    };

    return (
        <div className="w-100">
            {mainLoader && <LinearDeterminate />}
            {!isSidePanel && (
                <Card className="sticky-below-header">
                    <CreateSaveHeader
                        isBig={true}
                        title={`${urlParams.id ? "Edit" : "Create"} Ad Unit`}
                        saveLabel={`${urlParams.id ? "Save" : "Create"}`}
                        desc={
                            "Create Ad Unit which can be linked to various Surveys"
                        }
                        showPrompt={!isReadOnly && showPrompt && formChanges}
                        onSave={!isReadOnly && onSaveAdUnitHandler}
                        saveBtn={!formChanges}
                        showHistoryIcon={urlParams.id ? true : false}
                        showHistory={() => {
                            historyDataAction(
                                historyListingsTable,
                                urlParams,
                                dispatch,
                                adUnitsActions
                            );
                        }}
                        loading={loading}
                    ></CreateSaveHeader>
                </Card>
            )}
            <div className="dp-parent">
                {!isSidePanel && (
                    <LeftPanel
                        isEdit={!!urlParams.id}
                        data={adUnits}
                        offset={160}
                        scrollOffset={155}
                        path={`listings.adUnits.createAdUnit`}
                        errorState={`error`}
                        loading={loading}
                    />
                )}
                <div
                    className={`rightPanel ${
                        !showLeftPanel ? "rightPanel__expanded" : ""
                    } ${isSidePanel ? "rightPanel__expanded" : ""}
                    ${isSidePanel ? "pl-0" : ""}
                    `}
                >
                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            <Card>
                                {showPopup && (
                                    <Popup
                                        showHeader={false}
                                        showFooter={false}
                                        showConfirmBox={true}
                                        saveButtonText={"Yes, Go ahead"}
                                        cancelButtonText={"Cancel"}
                                        closeOutside={true}
                                        message={
                                            "Changing slot type will reset added slots."
                                        }
                                        disableSave={false}
                                        width={"305px"}
                                        portal={false}
                                        onClose={() => {
                                            changeDropdownValue(type);
                                            setShowPopup(false);
                                        }}
                                        onSave={() => {
                                            dispatch(
                                                adUnitsActions.setAdUnitInitialType(
                                                    type
                                                )
                                            );
                                            // delete old slots
                                            dispatch(
                                                adUnitsActions.setDeletedSlots(
                                                    slots.selectedListItems
                                                )
                                            );
                                            //set new slots
                                            let clonedList = JSON.parse(
                                                JSON.stringify(
                                                    slots.selectedListItems
                                                )
                                            );
                                            dispatch(
                                                adUnitsActions.addSelectedListItems(
                                                    clonedList.map(obj => {
                                                        delete obj[
                                                            slotType[type]
                                                        ];
                                                        if (
                                                            obj["weightPercent"]
                                                        ) {
                                                            delete obj[
                                                                "weightPercent"
                                                            ];
                                                        }
                                                        obj["id"] = "-";
                                                        obj[
                                                            slotType[
                                                                dropdownNewVal
                                                            ]
                                                        ] = undefined;
                                                        return obj;
                                                    })
                                                )
                                            );
                                            // dispatch(
                                            //     adUnitsActions.setEditId(
                                            //         clonedList
                                            //     )
                                            // );
                                            dispatch(
                                                adUnitsActions.setAdUnitSlotsError(
                                                    true
                                                )
                                            );
                                            changeDropdownValue(dropdownNewVal);
                                            setShowPopup(false);
                                        }}
                                        textAlign={"left"}
                                        customClass={s.confirmationPopup}
                                    />
                                )}
                                <AdUnitBasics
                                    name={name}
                                    type={type}
                                    status={status}
                                    surveys={surveys}
                                    selectedSurveyIds={selectedSurveyIds}
                                    adUnitId={adUnitId}
                                    ref={adUnitBasicRef}
                                    onChangeName={e => {
                                        dispatch(
                                            adUnitsActions.setAdUnitName(
                                                e.target.value
                                            )
                                        );
                                        if (
                                            type != null &&
                                            e.target.value.length > 0
                                        ) {
                                            dispatch(
                                                adUnitsActions.setAdUnitBasicError(
                                                    false
                                                )
                                            );
                                        }
                                        if (e.target.value.length < 0) {
                                            dispatch(
                                                adUnitsActions.setAdUnitBasicError(
                                                    true
                                                )
                                            );
                                        }
                                    }}
                                    onSelectSurvey={survey => {
                                        dispatch(
                                            adUnitsActions.setSelectedSurveyIds(
                                                survey.map(x => x.id)
                                            )
                                        );
                                    }}
                                    onChangeType={val => {
                                        setDropdownNewVal(val.key);
                                        if (
                                            slots.selectedListItems.length ===
                                                0 ||
                                            type === null ||
                                            (type &&
                                                val.key &&
                                                type === val.key)
                                        ) {
                                            changeDropdownValue(val.key);
                                        } else {
                                            setShowPopup(true);
                                        }
                                    }}
                                    onChangeStatus={val =>
                                        dispatch(
                                            adUnitsActions.setAdUnitStatus(val)
                                        )
                                    }
                                    onSortFunction={val => {
                                        dispatch(
                                            adUnitsActions.setsortFunction(
                                                `job.bid*${val.bid} + job.score*${val.score}`
                                            )
                                        );
                                    }}
                                    dropdownError={dropdownError}
                                    readOnly={isReadOnly}
                                />
                            </Card>
                            <Card>
                                <AdUnitSlots
                                    loading={loading}
                                    action={adUnitsActions}
                                    data={slots}
                                    deletedSlots={deletedSlots}
                                    historyData={historyData}
                                    historyDataRaw={historyDataRaw}
                                    readOnly={isReadOnly || !type}
                                    type={type}
                                    offerPathKeyList={offerPathKeyList}
                                />
                            </Card>
                        </>
                    )}
                    {showSaveConfirm && (
                        <ConfirmAndSave
                            dataUpdated={adUnitsData.createAdUnit}
                            dataBackup={adUnitsData.createAdUnitBackup}
                            config={adsConfirmConfig}
                            saveCallback={() =>
                                (urlParams.id && !isSidePanel) ||
                                (id && isSidePanel)
                                    ? id
                                        ? updateAdUnit(id)
                                        : updateAdUnit(urlParams.id)
                                    : addAdUnit()
                            }
                            closeCallback={() => setShowSaveConfirm(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
});

export default CreateAdUnit;
