import React, {
    useCallback,
    useState,
    useRef,
    useEffect,
    forwardRef,
} from "react";
import { useDropzone } from "react-dropzone";
import style from "./FileUploader.module.scss";
import Icon from "../../Shared/Icon/Icon";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import { CSVLink } from "react-csv";
import { DEFAULT_TEMPLATE } from "../../constants/templateData";
import ShowImage from "./ShowImage";
import ReactDOM from "react-dom";
import upload_csv from "../../assets/img/upload_data.svg";
import { get, debounce } from "lodash";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch } from "react-redux";
import { getRandomId } from "../../utils/globalUtils";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import CustomTooltipRenderer from "../Renderers/CustomTooltipRenderer/CustomTooltipRenderer";

const FileUploader = forwardRef(
    (
        {
            acceptedFileformat = "text/csv, image/*",
            maxFiles = 1,
            setFiles,
            fileSelected = undefined,
            header = "Select CSV file*",
            label = "Drag or click to choose file",
            templateLabel = "Download Template",
            downloadTemplate = DEFAULT_TEMPLATE,
            showDownloadTemplate = true,
            templateFilename = "sample.csv",
            showPreview = false,
            showHeader = true,
            infoText = "Bulk Upload Zip codes CSV as per the sample format",
            showInfoText = true,
            dropContainer,
            customClass = null,
            showLabel = true,
            showSuccess = false,
            defaultImg = "",
            readOnly = false,
            btnUpload = false,
            btnUploadStyle = "",
            uploadBtnText = "Upload Image",
            maxSize = "",
            maxSizeExceed,
            fileInfo,
            deleteBtnRequired = true,
            fileUploaderBtnStyle,
            withPreviewClassAdd = true,
            showCustomError = false,
            customError,
            isBase64ImageRequired,
            withGreenTickImage,
        },
        ref
    ) => {
        const [selectedFiles, setSelectedFiles] = useState(
            isBase64ImageRequired && fileSelected
                ? [
                      {
                          base64String: fileSelected,
                          path: "Uploaded successfully",
                          name: "Uploaded successfully",
                      },
                  ]
                : fileSelected
        );
        const [images, setImages] = useState([]);
        const [isDocumentDragOver, setIsDocumentDragOver] = useState(false);
        const [errorBorder, setErrorBorder] = useState(false);
        const inputTag = useRef(null);
        const dispatch = useDispatch();
        const mapImages = (acceptedFiles, remove) => {
            if (maxSize != "" && typeof maxSizeExceed == "function") {
                if (acceptedFiles[0] && acceptedFiles[0]?.size > maxSize) {
                    maxSizeExceed(true);
                    setErrorBorder(true);
                } else {
                    maxSizeExceed(false);
                    setErrorBorder(false);
                }
            }
            if (remove) setImages([]);
            acceptedFiles?.map((file, index) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    setImages(prevState => [
                        ...prevState,
                        { id: index, src: e.target.result },
                    ]);
                };

                reader.readAsDataURL(file);
                return file;
            });
        };

        const onDrop = useCallback(acceptedFiles => {
            showPreview && mapImages(acceptedFiles, true);
            if (!acceptedFiles.length) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Error while uploading file.",
                    })
                );
            }
            console.log(acceptedFiles, "acceptedFiles");
            if (isBase64ImageRequired) {
                convertToBase64(acceptedFiles, base64ArrayOfObject => {
                    setSelectedFiles(base64ArrayOfObject);
                    setFiles({
                        fileData: acceptedFiles,
                        base64Data: [base64ArrayOfObject[0].base64String],
                    });
                });
            } else {
                setSelectedFiles(acceptedFiles);
                setFiles(acceptedFiles);
            }

            if (ref?.current?.className) ref.current.className = "";
        }, []);
        const lists =
            selectedFiles &&
            selectedFiles !== "" &&
            selectedFiles.map(list => (
                <li
                    key={list.path}
                    className={`${style.nameList} ${
                        showPreview && `${style.nameListWithPreview}`
                    }`}
                >
                    <Tooltip
                        message={list.path || null}
                        position="top"
                        hideTooltip={!list.path}
                    >
                        <span
                            className={`${style.listFileName} ${
                                showPreview &&
                                `${style.listFileNameWithPreview}`
                            }`}
                        >
                            <>
                                {withGreenTickImage && (
                                    <Icon size="16px" icon="CheckFilled" />
                                )}
                                <span className={style.fileLabel}>
                                    {list.path}
                                </span>
                            </>
                        </span>
                    </Tooltip>

                    <span
                        className={style.removeFileBtn}
                        onClick={e => {
                            e.stopPropagation();
                            removeFile(e);
                        }}
                    >
                        {!showPreview ? (
                            "Remove"
                        ) : (
                            <Icon
                                icon={"Delete"}
                                size={"18px"}
                                enableHover={true}
                            />
                        )}
                    </span>
                </li>
            ));

        useEffect(() => {
            document.addEventListener("dragover", documentDragOverHandler);
            document.addEventListener("dragend", documentDragLeaveHandler);
            document.addEventListener("drop", documentDragLeaveHandler);
            document.addEventListener(
                "dragleave",
                documentDragFinalLeaveHandler
            );

            return () => {
                document.removeEventListener(
                    "dragover",
                    documentDragOverHandler
                );
                document.removeEventListener(
                    "dragend",
                    documentDragLeaveHandler
                );
                document.removeEventListener("drop", documentDragLeaveHandler);
                document.removeEventListener(
                    "dragleave",
                    documentDragFinalLeaveHandler
                );
            };
        }, []);

        const documentDragOverHandler = e => {
            var dt = e.dataTransfer;
            if (
                dt.types &&
                (dt.types.indexOf
                    ? dt.types.indexOf("Files") != -1
                    : dt.types.contains("Files"))
            ) {
                setIsDocumentDragOver(true);
            }
        };

        const documentDragLeaveHandler = () => {
            setTimeout(() => {
                setIsDocumentDragOver(false);
            }, 0);
        };

        const documentDragFinalLeaveHandler = e => {
            const debounce_function = debounce(() => {
                if (!isDocumentDragOver) {
                    setIsDocumentDragOver(false);
                }
            }, 1000);
            if (e.currentTarget.contains(e.relatedTarget)) {
                return;
            } else {
                debounce_function();
            }
        };

        const FloatingDropzone = props => {
            const { getRootProps, getInputProps, isDragAccept, isDragReject } =
                useDropzone({
                    accept: acceptedFileformat,
                    maxFiles: maxFiles,
                    onDrop,
                });

            return props.dropContainer?.current
                ? ReactDOM.createPortal(
                      <div
                          {...getRootProps({
                              className: `${style.floatingDropZone} ${
                                  showPreview &&
                                  withPreviewClassAdd &&
                                  selectedFiles &&
                                  selectedFiles[0]?.name &&
                                  `${style.withPreview}`
                              }
                            ${isDocumentDragOver && `${style.active}`}   
    ${isDragAccept && `${style.dropzoneAccept}`} 
    ${isDragReject && `${style.dropzoneReject}`}`,
                          })}
                          ref={ref}
                      >
                          <input {...getInputProps()} />
                          {isDocumentDragOver && (
                              <div className={style.imgDiv}>
                                  <img
                                      src={upload_csv}
                                      alt={"Drop files here"}
                                  />
                                  <p className={style.imgCaption}>
                                      {!btnUpload
                                          ? "Drag and Drop a CSV file"
                                          : "Drag and Drop a Image file"}
                                  </p>
                              </div>
                          )}
                      </div>,
                      props.dropContainer.current
                  )
                : null;
        };

        useEffect(() => {
            setSelectedFiles(
                isBase64ImageRequired && fileSelected
                    ? [
                          {
                              base64String: fileSelected,
                              path: "Uploaded successfully",
                              name: "Uploaded successfully",
                          },
                      ]
                    : fileSelected
            );
        }, [fileSelected]);

        const {
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject,
            open,
        } = useDropzone({
            accept: acceptedFileformat,
            maxFiles: maxFiles,
            onDrop,
        });

        const removeFile = file => {
            const newFiles = [...selectedFiles];
            newFiles.splice(file, 1); // remove the file from the array
            showPreview && mapImages(newFiles, true);
            setSelectedFiles(newFiles);
            setFiles(newFiles);
        };

        useEffect(() => {
            if (defaultImg !== "" && !fileInfo?.length) {
                setImages([{ id: getRandomId(), src: defaultImg }]);
                const getFileName = url => {
                    if (url) {
                        var m = url.toString().match(/.*\/(.+?)\./);
                        if (m && m.length > 1) {
                            return {
                                path: m[1],
                                name: m[1],
                                fileType: url
                                    .split(/[#?]/)[0]
                                    .split(".")
                                    .pop()
                                    .trim(),
                            };
                        }
                    }
                    return "";
                };

                setSelectedFiles([getFileName(defaultImg)]);
            }

            if (defaultImg !== "" && fileInfo?.length) {
                setSelectedFiles(fileInfo);
                setImages([{ id: getRandomId(), src: defaultImg }]);
            }
        }, [defaultImg]);

        const hoverImg = () => {
            return (
                <div className={style.popUpHoverImg}>
                    <img src={get(images[0], "src", "")} />
                </div>
            );
        };
        const convertToBase64 = (files, callback) => {
            let base64String = "";
            let reader = new FileReader();
            reader.onload = () => {
                base64String = reader.result;
                callback([
                    {
                        base64String: base64String,
                        path: "Uploaded successfully",
                        name: "Uploaded successfully",
                    },
                ]);
            };
            reader.readAsDataURL(files[0]);
        };
        return (
            <>
                <div
                    className={`${customClass ? customClass : ""} ${
                        withGreenTickImage ? style.withGreenTickImage : ""
                    }`}
                >
                    {!btnUpload && (
                        <div
                            className={`${style.heading} ${
                                showPreview ? style.fullWidthHeading : ""
                            }`}
                        >
                            {showHeader ? (
                                <p
                                    className={`${style.title} ${style.selectTitle}`}
                                >
                                    {header}
                                </p>
                            ) : (
                                <>
                                    {showInfoText && (
                                        <div className={style.infoText}>
                                            <Icon icon="Info" size="14px" />
                                            {infoText}
                                        </div>
                                    )}
                                </>
                            )}
                            {showDownloadTemplate && (
                                <CSVLink
                                    data={downloadTemplate}
                                    filename={templateFilename}
                                >
                                    <p
                                        className={`${style.title} ${style.selectTitle} ${style.downloadTemplate}`}
                                    >
                                        {templateLabel}
                                    </p>
                                </CSVLink>
                            )}
                        </div>
                    )}
                    {btnUpload && (
                        <>
                            {<FloatingDropzone dropContainer={dropContainer} />}
                            <div
                                id="fileUploaderBtn"
                                onClick={e => {
                                    e.stopPropagation();
                                    open(e);
                                }}
                                {...getRootProps({
                                    className: `
                            ${
                                isDragActive &&
                                !showPreview &&
                                `${style.dropzoneActive}`
                            } 
    ${isDragAccept && `${style.dropzoneAccept}`} 
    ${isDragReject && `${style.dropzoneReject}`}`,
                                })}
                            >
                                <input ref={inputTag} {...getInputProps()} />
                                <Button
                                    className={
                                        fileUploaderBtnStyle &&
                                        fileUploaderBtnStyle
                                    }
                                    btnTheme={
                                        btnUploadStyle != ""
                                            ? btnUploadStyle
                                            : ButtonTypes.primary_btn
                                    }
                                >
                                    {uploadBtnText}
                                </Button>
                            </div>
                        </>
                    )}
                    {!btnUpload && (
                        <div className={style.uploadWrapper}>
                            <div
                                className={`${style.fileUpload} ${
                                    readOnly ? style.fileUpload__disabled : ""
                                } ${errorBorder && style.isError}`}
                            >
                                {
                                    <FloatingDropzone
                                        dropContainer={dropContainer}
                                    />
                                }
                                <div
                                    {...getRootProps({
                                        className: `${style.dropzone} ${
                                            showPreview &&
                                            `${style.withPreview}`
                                        } ${
                                            selectedFiles &&
                                            selectedFiles[0]?.name &&
                                            style.imgSelected
                                        }   
                                    ${
                                        isDragActive &&
                                        !showPreview &&
                                        `${style.dropzoneActive}`
                                    } 
            ${isDragAccept && `${style.dropzoneAccept}`} 
            ${isDragReject && `${style.dropzoneReject}`}`,
                                    })}
                                    // ref={ref}
                                >
                                    <input
                                        ref={inputTag}
                                        {...getInputProps()}
                                    />
                                    {selectedFiles && selectedFiles[0]?.name ? (
                                        <>
                                            {showPreview && !isDragActive && (
                                                // <ShowImage images={images} />

                                                <>
                                                    <CustomTooltipRenderer
                                                        data={[
                                                            get(
                                                                images[0],
                                                                "src",
                                                                ""
                                                            ),
                                                        ]}
                                                        customRenderer={
                                                            hoverImg
                                                        }
                                                        position="right"
                                                    >
                                                        <div
                                                            className={
                                                                style.mainImg
                                                            }
                                                        >
                                                            <img
                                                                src={get(
                                                                    images[0],
                                                                    "src",
                                                                    ""
                                                                )}
                                                            />
                                                        </div>
                                                    </CustomTooltipRenderer>
                                                </>
                                            )}
                                            <div
                                                className={`${style.fileName} ${
                                                    showPreview &&
                                                    `${style.fileNameWithPreview}`
                                                }`}
                                            >
                                                {!showPreview &&
                                                    showLabel &&
                                                    lists}
                                            </div>
                                        </>
                                    ) : isDragActive ? (
                                        <div
                                            className={`${style.fileName}
                                        ${
                                            showPreview && isDragActive
                                                ? style.dropMessageWithPreview
                                                : ""
                                        }
                                    `}
                                        >
                                            <span className={style.name}>
                                                Drop the files here ...
                                            </span>
                                        </div>
                                    ) : showSuccess ? (
                                        <div
                                            className={
                                                style.uploadSuccessWrapper
                                            }
                                        >
                                            <div
                                                className={
                                                    style.uploadSuccessText
                                                }
                                            >
                                                <Icon
                                                    icon="Check"
                                                    size="14px"
                                                />{" "}
                                                Uploaded successfully
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`${
                                                style.uploadIconWrapper
                                            } 
                                    ${
                                        showPreview &&
                                        style.uploadIconWrapperWithPreview
                                    }
                                    `}
                                        >
                                            <Icon
                                                icon="Upload1Filled"
                                                size="20px"
                                                style={style}
                                            />{" "}
                                            <div className={style.fileName}>
                                                <span className={style.name}>
                                                    {label}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {selectedFiles &&
                                    selectedFiles[0]?.name &&
                                    showPreview &&
                                    !isDragActive && (
                                        <div className={style.bottomRight}>
                                            <div
                                                className={`flex ${style.flexHeight}`}
                                            >
                                                <div
                                                    className={`${style.replaceIconWrapper} circleIconColor delete_icon`}
                                                >
                                                    <div
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            open(e);
                                                        }}
                                                    >
                                                        <Tooltip
                                                            message={
                                                                "Replace Image"
                                                            }
                                                            position="bottom"
                                                        >
                                                            <Icon
                                                                icon={"Replace"}
                                                                size={"14.8px"}
                                                                enableHover={
                                                                    true
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                {deleteBtnRequired && (
                                                    <div
                                                        className={
                                                            style.deleteIconWrapper
                                                        }
                                                    >
                                                        <div
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                removeFile(e);
                                                            }}
                                                        >
                                                            <Tooltip
                                                                message={
                                                                    "Remove Image"
                                                                }
                                                                position="bottom"
                                                            >
                                                                <Icon
                                                                    icon={
                                                                        "Delete"
                                                                    }
                                                                    size={
                                                                        "14.8px"
                                                                    }
                                                                    enableHover={
                                                                        true
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {maxSize != "" &&
                                selectedFiles &&
                                selectedFiles[0]?.size > maxSize && (
                                    <>
                                        <div className={style.errMsg}>
                                            <Icon
                                                icon="ErrorFilled"
                                                size="12px"
                                            />
                                            {`File size is larger than ${Math.round(
                                                maxSize / 1024 / 1024
                                            )} MB`}
                                        </div>
                                    </>
                                )}

                            {(showCustomError &&
                                selectedFiles &&
                                selectedFiles[0]?.name &&
                                customError) ||
                                (isBase64ImageRequired &&
                                    showCustomError &&
                                    customError && (
                                        <>
                                            <div
                                                className={`${style.errMsg} ${style.customMessage}`}
                                            >
                                                <Icon
                                                    icon="ErrorFilled"
                                                    size="12px"
                                                />
                                                {customError}
                                            </div>
                                        </>
                                    ))}
                        </div>
                    )}
                </div>
            </>
        );
    }
);

export default FileUploader;
