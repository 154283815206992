import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const sidePanelListSlice = createSlice({
    name: "sidePanelInfinity",
    initialState,
    reducers: {
        addSidePanel(state, action) {
            return [...state, { ...action.payload }];
        },
        removeSidePanel(state, action) {
            return [...state.filter(item => item.id !== action.payload)];
        },
    },
});

export const sidePanelListActions = sidePanelListSlice.actions;
export default sidePanelListSlice.reducer;
