import { debounce, get, keys } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../Shared/Card/Card";
import Icon from "../../Shared/Icon/Icon";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import { getRandomId, scrollTo } from "../../utils/globalUtils";
import s from "./LeftPanel.module.scss";
import { skeletonLoader } from "../../utils/globalUtils";
import { useHistory } from "react-router-dom";
import { globalConfigActions } from "../../store/globalConfig/globalConfig";

const LeftPanel = ({
    data = [],
    path,
    errorState,
    offset = 230,
    scrollOffset = 215,
    onDetectScroll,
    loading = false,
    isEdit = false,
    showHomeBtn = false,
    showHomeBtnTxt = "Home",
    designType,
    overrideClick,
    title,
    classWrapper = "",
}) => {
    const dispatch = useDispatch();
    const showLeftPanel = useSelector(
        state => state.globalConfig.showLeftPanel
    );
    const state = useSelector(state => get(state, path, {}));
    const [activeItem, setActiveItem] = useState(
        get(data, `0.children.0.hash`, "")
    );
    const [d, setDistance] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (designType === "userAccessControl") {
            data[0].children.map(item => {
                if (window.location.pathname.includes(item.path))
                    setActiveItem(item.hash);
            });
        }
    });
    const scrollFunction = e => {
        let currentHash = "#";
        document.querySelectorAll("[data-hash-id]").forEach((h, i) => {
            let distance = window.scrollY - h.offsetTop;
            let hash = "#" + h.dataset.hashId;
            if (
                distance < offset &&
                distance > -1 * offset &&
                currentHash !== hash
            ) {
                currentHash = hash;
                let id = hash.substring(1);
                if (distance < d) {
                    setActiveItem(id);
                }
                setDistance(distance);
                onDetectScroll &&
                    typeof onDetectScroll == "function" &&
                    onDetectScroll(id);
            }
        });
    };

    const resizeFunction = () => {
        debounce(() => {
            dispatch(
                globalConfigActions.setShowLeftPanel(window.innerWidth >= 1300)
            );
        }, 200)();
    };
    const onChildClick = (id, customOffset = scrollOffset) => {
        if (overrideClick && typeof overrideClick == "function") {
            setActiveItem(id);
            overrideClick(id);
        }
        {
            scrollTo(id, customOffset);
            onDetectScroll &&
                typeof onDetectScroll == "function" &&
                onDetectScroll(id);
        }
    };
    useEffect(() => {
        if (designType === "noNestingNav") {
            setActiveItem(data[0]?.parent?.id);
        }
        resizeFunction();
        window.addEventListener("scroll", scrollFunction);
        window.addEventListener("resize", resizeFunction);
        return () => {
            window.removeEventListener("scroll", scrollFunction);
            window.removeEventListener("resize", resizeFunction);
        };
    }, []);
    return (
        <div
            className={`${classWrapper ? s[classWrapper] : ""} ${s.wrapper} ${
                designType === "userAccessControl" || showLeftPanel
                    ? s.show
                    : ""
            } ${designType ? s[designType] : ""}`}
        >
            {!loading ? (
                <>
                    {showHomeBtn ? (
                        <Card>
                            <div
                                className={`${s.homeBtn} dp-parent dp-parent-ver-center`}
                                onClick={() => history.push("/")}
                            >
                                <Icon
                                    icon={"Home"}
                                    size="16px"
                                    color="#41c3a9"
                                    enableHover={true}
                                />
                                {showHomeBtnTxt}
                            </div>
                        </Card>
                    ) : null}

                    <Card className={s.panelBox}>
                        {data.map((dataItem, dataIndex) => {
                            const { parent = null, children = [] } = dataItem;
                            const hashes = children.map(child =>
                                get(child, "hash", "")
                            );
                            children.map(child => {
                                if (child.nestedChildren) {
                                    child.nestedChildren.forEach(_ => {
                                        _.hash && hashes.push(_.hash);
                                    });
                                }
                            });
                            const pages = get(
                                state,
                                get(parent, "pagesPath", ""),
                                {}
                            );
                            const pagesPath = get(parent, "pagesPath", "");
                            const selectedPageId = get(
                                parent,
                                "selectedPageId",
                                ""
                            );
                            const errorSections = get(
                                parent,
                                "errorSections",
                                []
                            );
                            const errorInOtherPages =
                                pagesPath != ""
                                    ? keys(pages)
                                          .filter(i => i != selectedPageId)
                                          .some(page =>
                                              errorSections.some(item =>
                                                  get(
                                                      pages,
                                                      `${page}.${item}.error`,
                                                      false
                                                  )
                                              )
                                          )
                                    : false;
                            if (parent) {
                                return (
                                    <div
                                        className={`${s.section} ${
                                            hashes.indexOf(activeItem) > -1 ||
                                            parent.id === activeItem
                                                ? s.active
                                                : ""
                                        }`}
                                        key={
                                            getRandomId() +
                                            get(parent, "title", "") +
                                            getRandomId() +
                                            dataIndex
                                        }
                                        onClick={() => {
                                            if (designType === "noNestingNav") {
                                                setActiveItem(parent.id);
                                            }

                                            if (
                                                typeof parent.callback ===
                                                "function"
                                            ) {
                                                parent.callback();
                                            }
                                        }}
                                    >
                                        {title && (
                                            <div className={s.title}>
                                                {title}
                                            </div>
                                        )}

                                        <div
                                            className={`${s.parent} ${
                                                hashes.indexOf(activeItem) > -1
                                                    ? s.selected
                                                    : ""
                                            } ${
                                                errorInOtherPages ? s.error : ""
                                            }`}
                                        >
                                            {(errorInOtherPages ||
                                                get(parent, "icon", "")) && (
                                                <div
                                                    className={`${s.icon} ${
                                                        errorInOtherPages
                                                            ? s.error
                                                            : ""
                                                    }`}
                                                >
                                                    <Icon
                                                        icon={
                                                            errorInOtherPages
                                                                ? "Error"
                                                                : hashes.indexOf(
                                                                      activeItem
                                                                  ) > -1
                                                                ? get(
                                                                      parent,
                                                                      "icon",
                                                                      ""
                                                                  ) + "Filled"
                                                                : get(
                                                                      parent,
                                                                      "icon",
                                                                      ""
                                                                  )
                                                        }
                                                        size="18px"
                                                        className={`${
                                                            hashes.indexOf(
                                                                activeItem
                                                            ) > -1
                                                                ? ""
                                                                : "circleIconColor circleIconColor--darkHover"
                                                        } `}
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={s.text}
                                                title={get(parent, "title", "")}
                                            >
                                                {dataIndex === 0 &&
                                                designType !==
                                                    "userAccessControl"
                                                    ? isEdit
                                                        ? "Edit "
                                                        : "Create "
                                                    : ""}
                                                {get(parent, "title", "")}
                                            </div>
                                        </div>
                                        {children.map((child, childIndex) => {
                                            const {
                                                hash,
                                                title,
                                                icon,
                                                path,
                                                customOffset,
                                                nestedChildren,
                                            } = child;
                                            const error = get(
                                                state,
                                                path != ""
                                                    ? `${path}.${errorState}`
                                                    : errorState,
                                                false
                                            );
                                            return (
                                                <div
                                                    className={`${s.children} ${
                                                        error
                                                            ? s.errorChild
                                                            : ""
                                                    } ${
                                                        activeItem == hash
                                                            ? s.selected
                                                            : ""
                                                    } ${
                                                        nestedChildren
                                                            ? s.nestedChildWrap
                                                            : ""
                                                    }`}
                                                    key={
                                                        getRandomId() +
                                                        title +
                                                        getRandomId() +
                                                        childIndex
                                                    }
                                                    onClick={() =>
                                                        onChildClick(
                                                            hash,
                                                            customOffset
                                                        )
                                                    }
                                                >
                                                    {icon && (
                                                        <div className={s.icon}>
                                                            <Icon
                                                                icon={
                                                                    activeItem ==
                                                                    hash
                                                                        ? icon +
                                                                          "Filled"
                                                                        : icon
                                                                }
                                                                size="18px"
                                                                className={`${
                                                                    activeItem ==
                                                                    hash
                                                                        ? ""
                                                                        : "circleIconColor circleIconColor--darkHover"
                                                                } `}
                                                            />
                                                        </div>
                                                    )}
                                                    {error && (
                                                        <div
                                                            className={s.error}
                                                        >
                                                            <Tooltip
                                                                message="One or more error(s) found"
                                                                position="right"
                                                            >
                                                                <Icon
                                                                    icon={
                                                                        "Error"
                                                                    }
                                                                    size="15px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={s.text}
                                                        title={title}
                                                    >
                                                        {title}
                                                        {nestedChildren &&
                                                            nestedChildren.map(
                                                                _ => {
                                                                    return (
                                                                        <div
                                                                            className={`${
                                                                                s.children
                                                                            } ${
                                                                                s.nestedChild
                                                                            } ${
                                                                                activeItem ==
                                                                                _.hash
                                                                                    ? s.selected
                                                                                    : ""
                                                                            }`}
                                                                            onClick={() =>
                                                                                onChildClick(
                                                                                    _.hash,
                                                                                    _.customOffset
                                                                                )
                                                                            }
                                                                            key={getRandomId()}
                                                                            title={
                                                                                _.title
                                                                            }
                                                                        >
                                                                            {
                                                                                _.title
                                                                            }
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            }
                        })}
                    </Card>
                </>
            ) : (
                <Card
                    className={`${s.skeletonCard} ${s.skeletonCardLeftPanel}`}
                >
                    {skeletonLoader([10, 10, 10, 10, 10])}
                </Card>
            )}
        </div>
    );
};

export default LeftPanel;
