import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { GIFTCARDS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import * as moment from "moment";
import axios from "axios";

const initialState = {
    loading: false,
    mainLoader: false,
    emailID: "",
    userID: "",
    giftCardID: "",
    expiry: moment().format("YYYY-MM-DDTHH:mm:ss"),
    validity: "",
    giftCardsList: [],
    url: "",
    isDay: false,
};

export const fetchGiftCardDetails = createAsyncThunk(
    "dashboards/urlgenerator/fetchGiftCardDetails",
    async (_, { dispatch }) => {
        const response = await customFetch(GIFTCARDS.GET_GIFTCARDS_LIST, "GET");
        const data = get(response, "data", []);
        if (data.length) return data;
        else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Gift Cards!",
                })
            );
        }
    }
);

export const fetchGiftCardUrlDetail = createAsyncThunk(
    "dashboards/urlgenerator/fetchGiftCardUrlDetail",
    async urlParams => {
        const response = await customFetch(
            `${GIFTCARDS.GET_GIFTCARDURL_LIST}email/${urlParams.email}/giftcard/${urlParams.giftCardId}`,
            "GET"
        );
        const data = get(response, "data", []);
        return data;
    }
);

export const createGiftCardUrl = createAsyncThunk(
    "dashboards/urlgenerator/createGiftCardUrl",
    async (_, thunkApi) => {
        const params = getGiftCardUrlParams(
            thunkApi.getState().dashboards.urlGenerator
        );
        return await customFetch(
            `${GIFTCARDS.CREATE_GIFT_CARD_URL}`,
            "POST",
            params
        );
    }
);

export const updateGiftCardUrl = createAsyncThunk(
    "dashboards/urlgenerator/updateGiftCardUrl",
    async (_, thunkApi) => {
        const params = getGiftCardUrlParams(
            thunkApi.getState().dashboards.urlGenerator
        );
        return await customFetch(
            `${GIFTCARDS.CREATE_GIFT_CARD_URL}`,
            "PUT",
            params
        );
    }
);

const getGiftCardUrlParams = state => {
    return {
        emailID: state.emailID,
        userID: state.userID,
        giftCardID: state.giftCardID.toString(),
        expiry: state.expiry,
    };
};

const urlGeneratorSlice = createSlice({
    name: "urlGenerator",
    initialState,
    reducers: {
        setEmailId(state, action) {
            state.emailID = action.payload;
        },
        setUserId(state, action) {
            state.userID = action.payload;
        },
        setGiftCardId(state, action) {
            state.giftCardID = action.payload;
        },
        setExpiry(state, action) {
            state.expiry = action.payload;
        },
        setValidity(state, action) {
            state.validity = action.payload;
            const expiryHours = state.isDay
                ? action.payload * 24
                : action.payload;
            state.expiry = moment(moment())
                .add(expiryHours, "hours")
                .format("YYYY-MM-DDTHH:mm:ss");
        },
        setUrl(state, action) {
            state.url = action.payload;
        },
        setIsDay(state, action) {
            state.isDay = action.payload;
            const expiryHours = action.payload
                ? state.validity * 24
                : state.validity;
            state.expiry = moment(moment())
                .add(expiryHours, "hours")
                .format("YYYY-MM-DDTHH:mm:ss");
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        resetGiftCardUrlDetails(state) {
            state.emailID = "";
            state.userID = "";
            state.giftCardID = null;
            state.expiry = moment().format("YYYY-MM-DDTHH:mm:ss");
            state.validity = "";
            state.url = "";
            state.isDay = false;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchGiftCardDetails.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchGiftCardDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.giftCardsList = action.payload.map(gc => ({
                id: gc.id,
                key: `${gc.id} : ${gc.name}`,
            }));
        });
        builder.addCase(fetchGiftCardUrlDetail.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchGiftCardUrlDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.emailID = action.payload.emailID;
            state.userID = action.payload.userID;
            state.isDay = false;
            state.expiry = action.payload.expiry;
            state.url = action.payload.url;
            const hasGiftCard = state.giftCardsList.some(
                gc => gc.id == action.payload.giftCardID
            );
            state.giftCardID = hasGiftCard ? action.payload.giftCardID : null;
        });
    },
});

export const urlGeneratorActions = urlGeneratorSlice.actions;
export default urlGeneratorSlice.reducer;
