import Input from "../../Shared/Input/Input";
import { forwardRef, useState } from "react";
import Select from "../../Shared/Select/Select";
import InputList from "../InputList/InputList";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import SelectWithActionBtn from "../../Shared/SelectWithActionBtn/SelectWithActionBtn";
import { ButtonTypes } from "../../Shared/Button/Button";
import s from "./FlowQuestionBasics.module.scss";
import RichTextBox from "../../Shared/RichTextBox/RichTextBox";
import ImageWithName from "../../components/Renderers/ImageWithName/ImageWithName";

import verticalBtnLink from "../../assets/img/question-preview/1-verticalBtn.jpg";
import horizontalBtnLink from "../../assets/img/question-preview/2-horizontalBtn.jpg";
import radioLink from "../../assets/img/question-preview/3-radio.jpg";
import dropdownLink from "../../assets/img/question-preview/4-dropdown.jpg";
import multiSquareLink from "../../assets/img/question-preview/5-multiSquare.jpg";
import multiRoundLink from "../../assets/img/question-preview/6-multiRound.jpg";
import multiRoundLeftLink from "../../assets/img/question-preview/7-multiRoundLeft.jpg";
import dateType from "../../assets/img/question-preview/8-dateformat.jpg";
import buttonRadio from "../../assets/img/question-preview/9-buttonRadio.png";
import multipleInput from "../../assets/img/question-preview/10-multipleInput.jpg";
import CustomTooltipRenderer from "../Renderers/CustomTooltipRenderer/CustomTooltipRenderer";
import Icon from "../../Shared/Icon/Icon";
import { urlValidationRegex } from "../../constants/validationUtils";

const QuestionTypes = [
    {
        id: "VERTICAL_BUTTON_SELECT",
        name: "Vertical Button",
        mediaUrl: verticalBtnLink,
    },
    {
        id: "HORIZONTAL_BUTTON_SELECT",
        name: "Horizontal Button",
        mediaUrl: horizontalBtnLink,
    },
    {
        id: "RADIO_SELECT",
        name: "Radio",
        mediaUrl: radioLink,
    },
    {
        id: "BUTTON_RADIO",
        name: "Button Radio",
        mediaUrl: buttonRadio,
    },
    {
        id: "DROPDOWN_SELECT",
        name: "Drop Down",
        mediaUrl: dropdownLink,
    },
    {
        id: "MULTISELECT_SQUARE_CHECK_ICON",
        name: "Multiselect Square Check Icon",
        mediaUrl: multiSquareLink,
    },
    {
        id: "MULTISELECT_ROUNDED_CHECK_ICON",
        name: "Multiselect Rounded Check Icon",
        mediaUrl: multiRoundLink,
    },
    {
        id: "MULTISELECT_ROUNDED_CHECK_ICON_LEFT",
        name: "Multiselect Rounded Check Icon Left",
        mediaUrl: multiRoundLeftLink,
    },
    {
        id: "DATE_SELECT",
        name: "Date",
        mediaUrl: dateType,
    },
    {
        id: "MULTIPLE_INPUTS",
        name: "Multiple Input",
        mediaUrl: multipleInput,
    },
];

const FlowQuestionBasics = forwardRef(
    (
        {
            title = null,
            basicsData = {},
            onChangeTitle,
            onChangeSubHeader,
            onChangeName,
            onChangeType,
            onChangeAnswers,
            onChangeHasOther,
            typeError,
            readOnly = false,

            logoDropDownData = [],
            logoImageSrc = null,
            fileUploaderActionLogo,
            maxSizeExceedFunctionLogo,
            onChangeLogoImg,
            bannerDropDownData = [],
            bannerImageSrc = null,
            fileUploaderActionBanner,
            maxSizeExceedFunctionBanner,
            onChangeBannerImg,
            allQuestionsList,
            questionLoading,
            onChangeTag,
            subTitleLength,
            titleLength,
        },
        ref
    ) => {
        const logoPopup = () => {
            return (
                <div className={s.imgPreviewWrapper}>
                    <img src={logoImageSrc} alt="" />
                </div>
            );
        };

        const bannerPopup = () => {
            return (
                <div className={s.imgPreviewWrapper}>
                    <img src={bannerImageSrc} alt="" />
                </div>
            );
        };

        return (
            <>
                {title && (
                    <h3
                        className="headerTitle"
                        data-hash-id="flowQuestionBasics"
                        id="flowQuestionBasics"
                    >
                        {title}
                    </h3>
                )}
                {basicsData?.id && (
                    <div className="form-row">
                        <p className={`headerTitleRequired`}>ID</p>
                        <p className={`infoText`}>
                            ID associated with an Question
                        </p>
                        <p className={`headerTitleRequired`}>{basicsData.id}</p>
                    </div>
                )}
                <div className="form-row">
                    <p className="headerTitleRequired">Question Name*</p>
                    <p className="infoText">
                        Its an unique identifier name for the question
                    </p>
                    <Input
                        value={basicsData.name}
                        className="maxChildContainer"
                        placeholder="Enter question name"
                        required={true}
                        name={basicsData.name}
                        onChange={e => onChangeName(e.target.value)}
                        disabled={readOnly}
                        ref={ref.name}
                    ></Input>
                </div>

                <div className="form-row">
                    <div
                        className={`dp-parent dp-parent-hor-space-between dp-child-ver-center headerTitleRequired maxChildContainer`}
                    >
                        <p className="headerTitleRequired">Question Title*</p>
                        <p className="infoText">
                            Its an unique identifier name for the question
                        </p>
                        {titleLength > 0 && (
                            <p className="headerTitleRequired">{titleLength}</p>
                        )}
                    </div>
                    <div
                        className={`maxChildContainer ${s.questionTitleRichText}`}
                    >
                        {readOnly ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: basicsData?.question,
                                }}
                            ></div>
                        ) : (
                            <RichTextBox
                                ref={ref.title}
                                htmlContent={basicsData?.question}
                                customOnBlur={data => {
                                    onChangeTitle(data);
                                }}
                                options={{
                                    fontFamily: true,
                                    blockType: false,
                                    fontSize: true,
                                    inline: true,
                                    list: false,
                                    textAlign: false,
                                    colorPicker: true,
                                    link: true,
                                    embedded: false,
                                    emoji: false,
                                    image: false,
                                    remove: false,
                                    history: false,
                                }}
                                customOptions={{
                                    macro: false,
                                }}
                                isFontSizesInNumber
                            />
                        )}
                    </div>
                </div>
                <div className="form-row">
                    <div
                        className={`dp-parent dp-parent-hor-space-between dp-child-ver-center headerTitleRequired maxChildContainer`}
                    >
                        <p className="headerTitleRequired">Subtitle</p>
                        <p className="infoText">
                            Its an unique identifier name for the question
                        </p>
                        {subTitleLength > 0 && (
                            <p className="headerTitleRequired">
                                {subTitleLength}
                            </p>
                        )}
                    </div>
                    <div className="maxChildContainer">
                        {readOnly ? (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: basicsData?.subHeader,
                                }}
                            ></div>
                        ) : (
                            <RichTextBox
                                htmlContent={basicsData?.subHeader}
                                customOnBlur={data => {
                                    onChangeSubHeader(data);
                                }}
                                options={{
                                    fontFamily: true,
                                    blockType: false,
                                    fontSize: true,
                                    inline: true,
                                    list: false,
                                    textAlign: false,
                                    colorPicker: true,
                                    link: true,
                                    embedded: false,
                                    emoji: false,
                                    image: false,
                                    remove: false,
                                    history: false,
                                }}
                                customOptions={{
                                    macro: false,
                                }}
                                isFontSizesInNumber
                            />
                        )}
                    </div>
                </div>

                <div className="form-row maxChildContainer">
                    <p className="headerTitleRequired dp-parent dp-parent-hor-space-between">
                        Logo
                        {urlValidationRegex.test(logoImageSrc) && (
                            <CustomTooltipRenderer
                                data={[logoImageSrc]}
                                customRenderer={logoPopup}
                                position={"left"}
                            >
                                <div
                                    className={`dp-parent cursorPointer dp-parent-ver-center gap-10 ${s.imageHover}`}
                                >
                                    <Icon icon={"View"} /> View Image
                                </div>
                            </CustomTooltipRenderer>
                        )}
                    </p>
                    <p className="infoText">
                        Its an unique identifier name for the question
                    </p>
                    {/* <SelectWithActionBtn
                        selectData={{
                            label: "Select Logo",
                            keyField: "shortName",
                            idField: "name",
                            options: logoDropDownData,
                            selectedId: logoImageSrc,
                            autoFocus: true,
                            showSearch: true,
                            position: "top",
                            className: s.selectClass,
                            selectClassName: s.selectClass,
                            onSelect: onChangeLogoImg,
                            customRender: true,
                        }}
                        fileUploadData={{
                            label: "Browse & Upload",
                            fileUploadAction: fileUploaderActionLogo,
                            maxFiles: 1,
                            btnUpload: true,
                            maxSize: 1048576,
                            showPreview: true,
                            maxSizeExceed: maxSizeExceedFunctionLogo,
                            acceptedFileformat: "image/png,image/webp",
                            withPreviewClassAdd: false,
                            btnUploadStyle: ButtonTypes.primaryHover_btn,
                        }}
                        type="fileUploader"
                        readOnly={readOnly}
                    /> */}
                    <Input
                        value={logoImageSrc === null ? "" : logoImageSrc}
                        className="maxChildContainer"
                        placeholder="Enter logo url"
                        name={logoImageSrc}
                        onChange={e => onChangeLogoImg(e.target.value || null)}
                        disabled={readOnly}
                    ></Input>
                </div>

                <div className="form-row maxChildContainer">
                    <p className="headerTitleRequired dp-parent dp-parent-hor-space-between">
                        Banner Image
                        {urlValidationRegex.test(bannerImageSrc) && (
                            <CustomTooltipRenderer
                                data={[bannerImageSrc]}
                                customRenderer={bannerPopup}
                                position={"left"}
                            >
                                <div
                                    className={`dp-parent cursorPointer dp-parent-ver-center gap-10 ${s.imageHover}`}
                                >
                                    <Icon icon={"View"} /> View Image
                                </div>
                            </CustomTooltipRenderer>
                        )}
                    </p>
                    <p className="infoText">
                        Its an unique identifier name for the question
                    </p>
                    {/* <SelectWithActionBtn
                        selectData={{
                            label: "Select Banner",
                            keyField: "shortName",
                            idField: "name",
                            options: bannerDropDownData,
                            selectedId: bannerImageSrc,
                            autoFocus: true,
                            showSearch: true,
                            position: "top",
                            className: s.selectClass,
                            selectClassName: s.selectClass,
                            onSelect: onChangeBannerImg,
                            customRender: true,
                        }}
                        fileUploadData={{
                            label: "Browse & Upload",
                            fileUploadAction: fileUploaderActionBanner,
                            maxFiles: 1,
                            btnUpload: true,
                            maxSize: 1048576,
                            showPreview: true,
                            maxSizeExceed: maxSizeExceedFunctionBanner,
                            acceptedFileformat: "image/png,image/webp",
                            withPreviewClassAdd: false,
                            btnUploadStyle: ButtonTypes.primaryHover_btn,
                        }}
                        type="fileUploader"
                        readOnly={readOnly}
                    /> */}
                    <Input
                        value={bannerImageSrc === null ? "" : bannerImageSrc}
                        className="maxChildContainer"
                        placeholder="Enter banner url"
                        name={bannerImageSrc}
                        onChange={e =>
                            onChangeBannerImg(e.target.value || null)
                        }
                        disabled={readOnly}
                    ></Input>
                </div>

                <div className="form-row">
                    <p className="headerTitleRequired">Question Type*</p>
                    <p className="infoText">
                        Its an unique identifier name for the question
                    </p>
                    <Select
                        label="Select"
                        options={QuestionTypes}
                        idField="id"
                        keyField="name"
                        selectedId={basicsData.type || null}
                        isDisabled={readOnly}
                        onSelect={key => onChangeType(key?.id)}
                        isError={typeError}
                        CustomRender={({ option }) => {
                            return <ImageWithName item={option} />;
                        }}
                        portal={false}
                        notooltip
                    />
                </div>

                <div className="form-row">
                    <p className="headerTitleRequired">Parent Question</p>
                    <p className="infoText">
                        Tag for question or parent question
                    </p>
                    <Select
                        label="Select parent question"
                        options={allQuestionsList}
                        idField="name"
                        keyField="name"
                        selectedId={basicsData?.tag || null}
                        isDisabled={readOnly}
                        onSelect={key => {
                            onChangeTag(key?.name);
                        }}
                        isError={typeError}
                        portal={false}
                        position="top"
                        closeIcon
                        loading={questionLoading}
                    />
                </div>

                <div className="form-row dp-parent dp-parent-ver-center gap-10">
                    <p className="headerTitleRequired mb-0">Has Other</p>
                    <ToggleSwitch
                        isToggleOn={basicsData.hasOther}
                        setIsToggleOn={onChangeHasOther}
                        disabled={readOnly}
                    />
                </div>

                <div className="form-row maxChildContainer">
                    <p className="headerTitleRequired">Answers*</p>
                    <p className="infoText">
                        Its an unique identifier name for the question
                    </p>
                    <InputList
                        type="multipleInputSortable"
                        placeholderOne="Enter Answer"
                        placeholderTwo="Enter Key"
                        mainState={basicsData.answers}
                        setMainState={data => onChangeAnswers(data)}
                        readOnly={readOnly}
                        showToggle
                    />
                </div>
            </>
        );
    }
);
export default FlowQuestionBasics;
