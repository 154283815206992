import {
    numberOfSetsOfferPath,
    numberOfSetsSurveyPath,
    numberOfSetsFeatureSets,
    numberOfSetsABTest,
    ALLOWEDDAYSLIST,
    DEVICESLIST,
    USERSTATUSDROPDOWNOPTIONS,
    REGIONSLIST,
    UTMSOURCESDROPDOWNOPTIONS,
    COUNTRIESLIST,
} from "./targettingParamsConstants.js";

const allTargetParamsKeyMap = {};
const allTargetParamsValMap = {};

[
    ...numberOfSetsOfferPath,
    ...numberOfSetsSurveyPath,
    ...numberOfSetsFeatureSets,
    ...numberOfSetsABTest,
    { id: "operatingSystems", name: "Operating Systems" },
    { id: "keyVals", name: "Key Values" },
].forEach(elem => {
    allTargetParamsKeyMap[elem.id] = elem.name;
});

const temp1 = {
    allowedDays: ALLOWEDDAYSLIST,
    devices: DEVICESLIST,
    userStatus: USERSTATUSDROPDOWNOPTIONS,
    region: REGIONSLIST,
    utmSources: UTMSOURCESDROPDOWNOPTIONS,
    countries: COUNTRIESLIST,
    genders: [
        { id: 1, label: "Male" },
        { id: 2, label: "Female" },
        { id: 3, label: "Others" },
    ],
};

Object.keys(temp1).map(key => {
    const obj = {};
    temp1[key].map(_ => {
        obj[_.id] = _.label;
    });
    allTargetParamsValMap[key] = obj;
});

export const surveyPathConfirmConfig = {
    text: {
        heading: "Manage Survey Paths",
        icon: "SurveyPath",
    },
    paths: [
        {
            id: "basics.nameId",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.selectedSurveyId",
            label: "Selected Survey Id",
            renderer: "text",
        },
        {
            id: "targettingParam.targetParams",
            label: "Inclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            valueMapping: allTargetParamsValMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
        {
            id: "questionBank.selected",
            label: "Selected Question Id",
            renderer: "array",
            ignoreList: ["createdAt", "updatedAt"],
        },
        {
            id: "questionBank.selectedItems",
            label: "Question Details",
            renderer: "array",
            ignoreList: ["createdAt", "updatedAt"],
            innerPaths: [],
        },
    ],
};

export const offerPathConfirmConfig = {
    text: {
        heading: "Manage Offer Paths",
        icon: "OfferPath",
    },
    paths: [
        {
            id: "basics.name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.status",
            label: "Status",
            renderer: "text",
        },
        {
            id: "basics.weight",
            label: "Weight",
            renderer: "text",
        },
        {
            id: "basics.maxSlotsToReturn",
            label: "Max Slots",
            renderer: "text",
        },
        {
            id: "basics.selectedSurveyIds",
            label: "Surveys",
            renderer: "text",
        },
        {
            id: "dedupeRules",
            label: "Dedupe Rules",
            renderer: "array",
            ignoreList: ["isNew"],
        },
        {
            id: "basics.enableAutoOfferPath",
            label: "Auto Offer Path",
            renderer: "text",
        },
        {
            id: "basics.explorationWeight",
            label: "Exploration Weight",
            renderer: "text",
        },
        {
            id: "slots.selectedItems",
            label: "Offer Path Slots",
            renderer: "array",
            ignoreList: ["showAccordionTable", "isEdit"],
            innerPaths: [],
        },
        {
            id: "slots.deletedSlotAds",
            label: "Deleted Ad Slots",
            renderer: "array",
            innerPaths: [],
        },
        {
            id: "targettingParam.targetParams",
            label: "Inclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
    ],
};

export const questionsConfirmConfig = {
    text: {
        heading: "Questions",
        icon: "Question",
    },
    paths: [
        {
            id: "basics.name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "questions.questionList",
            label: "Sub Questions",
            renderer: "array",
            ignoreList: ["id"],
        },
    ],
};

export const adsConfirmConfig = {
    text: {
        heading: "Ads",
        icon: "AdUnit",
    },
    paths: [
        {
            id: "basics.name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.type",
            label: "Type",
            renderer: "text",
        },
        {
            id: "basics.dynamicSortFunc",
            label: "Optimization",
            renderer: "text",
        },
        {
            id: "slots.selectedItems",
            label: "Ad Unit Offers",
            renderer: "array",
            ignoreList: ["createdAt", "updatedAt", "isNew"],
        },
    ],
};

export const leadDefinitionConfirmConfig = {
    text: {
        heading: "Posting Spec",
        icon: "LeadGeneration",
    },
    paths: [
        {
            id: "leadName",
            label: "Name",
            renderer: "text",
        },
        {
            id: "isPrep",
            label: "Enable Preping",
            renderer: "text",
        },
        {
            id: "isAuth",
            label: "Enable Authenticating",
            renderer: "text",
        },
        {
            id: "leadData",
            label: "Lead Data",
            renderer: "object",
            ignoreList: ["cacheTTL", "nextDelay", "description", "id"],
        },
        {
            id: "prepData",
            label: "Preping Data",
            renderer: "object",
            ignoreList: ["cacheTTL", "nextDelay", "description", "id"],
        },
        {
            id: "authData",
            label: "Auth Data",
            renderer: "object",
            ignoreList: ["cacheTTL", "nextDelay", "description", "id"],
        },
    ],
};

export const surveyConfirmConfig = {
    text: {
        heading: "Survey Configs",
        icon: "Configuration",
    },
    paths: [
        {
            id: "configBasics.name",
            label: "Version",
            renderer: "text",
        },
        {
            id: "configBasics.lander",
            label: "Lander",
            renderer: "text",
            valueMapping: {
                "8df6b9e7a46c47928a0528ce1cfe1a1c": "taboola",
                "98dd48ec02d3413082016519b8a090e2": "default",
                acbc9a2c4c0349558db711811e77c6d0: "gcs-default",
            },
        },
        {
            id: "configBasics.product",
            label: "Product",
            renderer: "text",
        },
        {
            id: "channelMapData",
            label: "A/B Test Channel Mapping",
            renderer: "array",
            innerPaths: [],
        },
        {
            id: "featureMaps.savedList",
            label: "Features",
            renderer: "array",
            innerPaths: ["value", "choice"],
        },
        {
            id: "featureSets",
            label: "Feature Sets",
            renderer: "array",
            innerPaths: [],
        },
    ],
};

export const featuresConfirmConfig = {
    text: {
        heading: "Features",
        icon: "FeatureMap",
    },
    paths: [
        {
            id: "basics.name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.desc",
            label: "Description",
            renderer: "text",
        },
        {
            id: "basics.imageSrc",
            label: "Screenshot URL",
            renderer: "text",
        },

        {
            id: "properties.type",
            label: "Property type",
            renderer: "text",
        },
        {
            id: "properties.scope",
            label: "Property scope",
            renderer: "text",
        },
        {
            id: "properties.valuekind",
            label: "Property value type",
            renderer: "text",
        },
        {
            id: "properties.choice",
            label: "Property choice",
            renderer: "array",
            innerPaths: [],
        },
    ],
};

export const featureSetsConfirmConfig = {
    text: {
        heading: "Feature Sets",
        icon: "FeatureSet",
    },
    paths: [
        {
            id: "basics.name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "basics.status",
            label: "Set Visibility",
            renderer: "text",
        },
        {
            id: "featureMaps.savedList",
            label: "Features",
            renderer: "array",
            innerPaths: ["value", "choice"],
        },
        {
            id: "targettingParam.targetParams",
            label: "Inclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
        {
            id: "exclusionTargettingParam.targetParams",
            label: "Exclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
    ],
};

export const containerConfirmConfig = {
    text: {
        heading: "Container",
        icon: "Container",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "type",
            label: "Type",
            renderer: "text",
        },
        {
            id: "vertical",
            label: "Vertical",
            renderer: "text",
        },
        {
            id: "collections",
            label: "Collection",
            renderer: "text",
        },
        {
            id: "collectionId",
            label: "Collection Id",
            renderer: "text",
        },
        {
            id: "containerLayoutId",
            label: "Container Layout Id",
            renderer: "text",
        },
        {
            id: "layoutId",
            label: "Layout Id",
            renderer: "text",
        },
        //Adding this as in Confirm and Save we have to use this constants
        {
            id: "layout.name",
            label: "Fallback Collection Item Layout",
            renderer: "text",
        },

        {
            id: "outerLayout.name",
            label: "Container layout",
            renderer: "text",
        },
        {
            id: "template",
            label: "Feature Group Id",
            renderer: "text",
        },
    ],
};

export const templateConfirmConfig = {
    text: {
        heading: "Feature Group",
        icon: "Template",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "configuration.value",
            label: "Features",
            renderer: "array",
        },
        {
            id: "vertical",
            label: "Vertical",
            renderer: "text",
        },
        {
            id: "domain",
            label: "Domain",
            renderer: "text",
        },
        {
            id: "properties",
            label: "Properties",
            renderer: "object",
        },
    ],
};

export const collectionConfirmConfig = {
    text: {
        heading: "Collection",
        icon: "Collection",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "vertical",
            label: "Vertical",
            renderer: "text",
        },
        {
            id: "domain",
            label: "Domain",
            renderer: "text",
        },
        {
            id: "items",
            label: "Items",
            renderer: "array",
        },
        {
            id: "detailsList",
            label: "Items",
            renderer: "array",
        },
    ],
};

export const questionConfirmConfig = {
    text: {
        heading: "Question",
        icon: "Question",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.question",
            label: "Question Title",
            renderer: "text",
        },
        {
            id: "basics.subHeader",
            label: "Subtitle",
            renderer: "text",
        },
        {
            id: "basics.logoImageSrc",
            label: "Logo",
            renderer: "text",
        },
        {
            id: "basics.bannerImageSrc",
            label: "Banner Image",
            renderer: "text",
        },
        {
            id: "basics.type",
            label: "Question Type",
            renderer: "text",
        },
        {
            id: "basics.hasOther",
            label: "Has Other",
            renderer: "text",
        },
        {
            id: "basics.answers",
            label: "Answers",
            renderer: "array",
        },
        {
            id: "basics.tag",
            label: "Parent Question",
            renderer: "text",
        },
    ],
};

export const flowCategoryConfirmConfig = {
    text: {
        heading: "Flow Category",
        icon: "Template",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "targettingParams.value",
            label: "Targetting params",
            renderer: "array",
        },
    ],
};

export const flowQuestionsConfirmConfig = {
    text: {
        heading: "Questions",
        icon: "Question",
    },
    paths: [
        {
            id: "name",
            label: "Question Name",
            renderer: "text",
        },
        {
            id: "question",
            label: "Question Title",
            renderer: "text",
        },
        {
            id: "subHeader",
            label: "Subtitle",
            renderer: "text",
        },
        {
            id: "logoImageSrc",
            label: "Logo Image URL",
            renderer: "text",
        },
        {
            id: "bannerImageSrc",
            label: "Banner Image URL",
            renderer: "text",
        },
        {
            id: "type",
            label: "Question Type",
            renderer: "text",
        },
        {
            id: "answers",
            label: "Answers",
            renderer: "array",
            ignoreList: ["updatedAt", "createdAt"],
        },
    ],
};

export const layoutConfirmConfig = {
    text: {
        heading: "Layout",
        icon: "Layout",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "previewLink",
            label: "Preview Link",
            renderer: "text",
        },
        {
            id: "version",
            label: "Version",
            renderer: "text",
        },
        {
            id: "description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "tags",
            label: "Tags",
            renderer: "array",
        },
        {
            id: "configurations.key",
            label: "Key",
            renderer: "string",
        },
        {
            id: "configurations.description",
            label: "Config Description",
            renderer: "string",
        },
        {
            id: "configurations.data",
            label: "Default Choice",
            renderer: "array",
        },
        {
            id: "configurations.choices",
            label: "Choices",
            renderer: "array",
        },
        {
            id: "configurations.required",
            label: "Required",
            renderer: "string",
        },
        {
            id: "configurations.isMultiSelect",
            label: "Is Multi select",
            renderer: "string",
        },
        {
            id: "configurations.max",
            label: "Max",
            renderer: "string",
        },
        {
            id: "configurations.min",
            label: "Is Multi select",
            renderer: "string",
        },
    ],
};

export const layoutConfirmAndSaveConfig = {
    text: {
        heading: "Layout",
        icon: "Layout",
    },
    paths: [
        {
            id: "basics.name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "basics.previewLink",
            label: "Preview Link",
            renderer: "text",
        },
        {
            id: "basics.version",
            label: "Version",
            renderer: "text",
        },
        {
            id: "basics.description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "basics.tags",
            label: "Tags",
            renderer: "array",
        },
        {
            id: "configurations",
            label: "Configurations",
            renderer: "array",
        },
    ],
};

export const confirmAndSaveTabs = ["Dependency", "Differentiation"];
export const publishTabs = ["Differentiation"];
