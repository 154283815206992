import { get, isEmpty } from "lodash";
import { getTargettingParams } from "./globalUtils";
import { v4 as uuidv4 } from "uuid";
import { getFeatureMap } from "./featuremapUtils";
import { defaultInitialStatusFilter } from "./filterUtils";

export const abTestParams = (data, isEdit = false) => {
    let getStatus = get(data, "status", "") ? "active" : "disabled";
    var configChannelMapArray = get(data, "channelMapData", []);
    var configChannelMapObject = configChannelMapArray.reduce(
        (obj, item) => Object.assign(obj, { [item.configID]: item.channel }),
        {}
    );
    return {
        id: isEdit ? null : get(data, "uuid", ""),
        name: get(data, "name", ""),
        status: getStatus,
        config_channel_map: configChannelMapObject,
    };
};

export const abTestVariantsParams = state => {
    const abtest = state.listings.abtests.createAbTest.basics;
    const pages = state.listings.abtests.createAbTest.variants.pages;
    const variants = [];
    const deleteVariants = [];
    for (const key in pages) {
        let obj = {
            name: pages[key].basics.name,
            weight: +pages[key].basics.weight,
            abtest: abtest.uuid,
            id: pages[key].basics.id
                ? pages[key].basics.id
                : uuidv4().replace(/-/g, ""),
            targeting: getTargettingParams(
                pages[key].targettingParam.targetParams,
                false
            ),
            exclusionTargeting: getTargettingParams(
                pages[key].exclusionTargettingParam.targetParams,
                false
            ),
            featureMap: pages[key].featureMaps.savedList
                ? getFeatureMap(pages[key].featureMaps.savedList)
                : null,
        };

        // obj.targeting.spamCheck = {
        //     ...obj.targeting.spamCheck,
        //     spamIP: obj.targeting.spamCheck.spamIP.toString(),
        // };

        // obj.exclusionTargeting.spamCheck = {
        //     ...obj.exclusionTargeting.spamCheck,
        //     spamIP: obj.exclusionTargeting.spamCheck.spamIP.toString(),
        // };

        if (
            state.listings.abtests.createAbTest.variants.deletedPageIds.indexOf(
                pages[key].basics.id
            ) === -1
        )
            variants.push(obj);
    }
    return variants;
};

export const successErrorVariantCount = response => {
    if (response.constructor === Array) {
        const errorCount = response.filter(
            res => !res || res?.StatusCode >= 400
        ).length;
        return {
            success: response.length - errorCount,
            error: errorCount,
        };
    }
};

export const inputDropdownDataRefactorToApi = array => {
    let valueContainer = [];
    array?.map((element, index) => {
        let outPut = {
            configID: element.valueOne,
            channel: element.valueSecond,
            configName: element.valueName,
        };
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const selectDropdownDataRefactorFromApi = array => {
    let valueContainer = [];
    array?.map((element, index) => {
        let outPut = {
            valueOne: element.configID,
            valueSecond: element.channel,
            valueName: element.configName,
        };
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const initialFilterData = [
    {
        ...defaultInitialStatusFilter,
        page: "abtests",
    },
];
