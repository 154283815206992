import React from "react";
import Select from "../../Shared/Select/Select";
import styles from "./CollectionDetails.module.scss";
import { useState, useEffect, useRef } from "react";
import _, { get, isEmpty, cloneDeep } from "lodash";
import { questionsActions } from "../../store/listings/questions";
import { REGISTER_DETAIL_DROPDOWN } from "../../constants/collectionConstants";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import AddNewDetail from "../AddNewDetail/AddNewDetail";
import Icon from "../../Shared/Icon/Icon";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import ToggleValueSwitch from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import CreateFlowQuestion from "../../Pages/CreateFlowQuestion/CreateFlowQuestion";
import { getPreviewObj } from "../../utils/globalUtils";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch, useSelector } from "react-redux";
import { fetchCreateSurveyPathData } from "../../store/listings/surveyPaths";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import { useParams, useHistory } from "react-router-dom";
import {
    collectionActions,
    fetchAllHnpCategories,
    fetchCollectionById,
} from "../../store/flowmanager/collection";
import { fetchQuestionsData } from "../../store/flowmanager/questions";
import FlowTcpa from "../FlowTcpa/FlowTcpa";
import {
    templateActions,
    fetchAllTemplate,
    fetchTemplate,
} from "../../store/flowmanager/template";
import CreateTemplate from "../../Pages/CreateTemplate/CreateTemplate";
import { fetchAllLayout } from "../../store/flowmanager/layout";
import LayoutEditComp from "../LayoutEditComp/LayoutEditComp";
import { v4 as uuidv4 } from "uuid";
import CreateFlowCategories from "../../Pages/CreateFlowCategories/CreateFlowCategories";
import TargetingData from "../../Pages/CreateFlow/TargetingData";
import LinkOutOffer from "../LinkOutOffer/LinkOutOffer";
import { Skeleton } from "@mui/material";
import { useFetchAvailableTargetingParams } from "../../generalApi/generalApi";
import {
    findByValue,
    createLinkOutData,
    getFinalTargetingData,
} from "../../utils/collectionUtils";
import ReactSelect from "../../Shared/ReactSelect/ReactSelect";

const operatorList = [
    { id: 1, text: "contains" },
    { id: 2, text: "not contains" },
    { id: 3, text: "exact" },
];

const DetailWrap = ({
    inRightPanel = false,
    question,
    index,
    dataIndex,
    selectedQuestionId,
    surveyPathId,
    actions,
    className,
    readOnly,
    editOptions,
    removeQuestion,
    editQuestion,
    tcpaWrite = false,
    checkboxControl = false,
    templates = [],
    layouts = [],
    updateLayouts,
    inContainer,
    defaultContainerLayout,
    setActiveDetailState,
    parentActiveDetail = {},
    selectedFlowDomain = null,
    isFlowLive = false,
}) => {
    const [readView, setReadView] = useState(false);
    const editDetailsRef = useRef();
    const editQuestionRef = useRef();
    const questionRef = useRef();
    const layoutOpenRef = useRef();
    const dispatch = useDispatch();

    const questionsList = get(question, "questionsList", []);
    const [editSection, setEditSection] = useState(false);
    const [tcpaLocalOptions, setTcpaLocalOptions] = useState([]);
    // const [hasOfferState, setHasOfferState] = useState({});
    // const [isToggleOn, setIsToggleOn] = useState(
    //     get(question, "hasOffer", true)
    // );
    const createTemplateState = useSelector(
        state => state.flowmanager.template.createTemplate
    );
    const { Extras, type, AdObject, id, properties, hnpLeadIds } = question;
    const [inputOne, setInputOne] = useState("");
    const [inputSecond, setInputSecond] = useState("");
    const [extras, setExtras] = useState(Extras || []);
    const [editSectionDropdown, setEditSectionDropdown] = useState({});
    const [errorSectionDropdown, setErrorSectionDropdown] = useState(false);
    const [adType, setAdType] = useState("Ad Unit");
    const [adTypeList, setAdTypeList] = useState([]);
    const [adObject, setAdObject] = useState(AdObject || {});
    const [isOffer, setIsOffer] = useState(
        !isEmpty(properties.configInfo) ? true : false
    );
    const [activeDetail, setActiveDetail] = useState({});
    const [updatedValidAnswers, setUpdatedValidAnswers] = useState(
        properties.configInfo?.leadData?.questionConfig
            ? properties.configInfo?.leadData?.questionConfig[0]
            : {
                  matchType: "",
                  questionName:
                      question.properties?.question_details?.name || "",
                  validAnswers: [],
              }
    );
    const [hnpLeads, setHnpLeads] = useState(hnpLeadIds);
    const [deletePopup, setDeletePopup] = useState(false);
    const [viewIsClick, setViewIsClick] = useState(false);
    const [createPhasePopup, setCreatePhasePopup] = useState(false);
    const [individualLayoutData, setIndividualLayoutData] = useState([]);
    const [layoutDataCopy, setLayoutDataCopy] = useState([]);
    const [layoutDefaultConfig, setLayoutDefaultConfig] = useState([]);
    const [addConfigList, setAddConfigList] = useState([]);

    const [showConfigSelect, setShowConfigSelect] = useState(false);
    const [previewItem, setPreviewItem] = useState({});
    const [layoutDropdownTypeData, setLayoutDropdownTypeData] = useState([]);
    const [hnpData, setHnpData] = useState([]);
    const [activeHnpId, setActiveHnpId] = useState(null);

    const addTcpaBtnRef = useRef();
    const templateOpenRef = useRef(null);
    const saveFunctionCallRef = useRef(null);
    const addAdUnitRef = useRef(null);
    const linkOutRef = useRef(null);
    const [linkoutOffer, setLinkoutOffer] = useState(null);
    const [editLinkOut, setEditLinkOut] = useState({ data: [], index: 0 });
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const { apiData = [] } = useFetchAvailableTargetingParams();

    const editHnpSidePanelRef = useRef(null);
    const createFlowPageRef = useRef(null);

    const surveyPaths = useSelector(state => state.listings.surveyPaths);
    const {
        createSurveyPath: { offerGroups = [], adUnits = [] },
        adTypeDataLoading,
    } = surveyPaths;

    const collectionState = useSelector(state => state.flowmanager.collection);
    const items = get(collectionState, "createCollection.detailsList", []);
    const createCollectionState = get(collectionState, "createCollection", []);
    const collectionType = createCollectionState?.type?.id;

    // container redux state
    const containerState = useSelector(
        state => state.flowmanager.container.createContainer
    );

    let cancelLayoutEdit = true;

    useEffect(() => {
        setAddConfigList(
            layoutDefaultConfig?.filter(
                _ =>
                    !individualLayoutData[0]?.configurations
                        ?.map(_ => _.key)
                        .includes(_.key)
            )
        );
    }, [layoutDefaultConfig, individualLayoutData]);

    useEffect(() => {
        if (editSection) {
            setEditSectionDropdown(get(question, "AdObject", {}));
        }
        return () => {
            setEditSectionDropdown({});
        };
    }, [editSection]);

    useEffect(() => {
        if (layouts?.length) {
            setLayoutDropdownTypeData(
                layouts.filter(
                    (obj, idx) =>
                        obj?.type === "SURVEY" && obj?.subType === "INNER"
                )
            );
        }
    }, [createCollectionState.type, layouts]);

    const DragHandle = SortableHandle(() => (
        <div
            className={` ${styles.option} ${styles.option__drag} circleIconColor circleIconColor--darkHover`}
        >
            <Icon icon="Drag" size="16px" disable={adTypeDataLoading} />
        </div>
    ));

    useEffect(() => {
        setTcpaLocalOptions(
            question?.tcpa && question?.tcpa[0]
                ? {
                      ...question.tcpa[0],
                      //   subQuestionName: question.questionName,
                  }
                : {
                      //   enable: false,
                      html: "",
                      jornaya: true,
                      trustedform: true,
                      implicit: true,
                      required: false,
                      //   checkbox_class: ["xl_large"],
                      //   subQuestionName: question.questionName,
                  }
        );
    }, [question]);

    const clickSaveandAdd = async activeDetail => {
        await saveTCPA(activeDetail);
    };

    useEffect(() => {
        adType && (adType === "Ad Unit" || adType === "AdUnit")
            ? setAdTypeList(adUnits)
            : setAdTypeList(offerGroups);
    }, [adUnits, offerGroups, adType]);

    useEffect(() => {
        //Added this as we have offer group list also and we want only adUnit in LinkOut Offer
        const setAdUnitListFunction = async () => {
            if (isEmpty(adUnits)) await dispatch(fetchCreateSurveyPathData());
        };
        setAdUnitListFunction();
    }, []);

    useEffect(() => {
        if (!isEmpty(activeDetail)) {
            const data = getPreviewObj(
                inContainer
                    ? isEmpty(activeDetail.layoutConfig)
                        ? {
                              ...activeDetail,
                              layoutConfig: containerState?.layout,
                          }
                        : activeDetail
                    : activeDetail,
                collectionState?.createCollection?.type?.id,
                inContainer ? containerState?.outerLayout : {},
                inContainer ? containerState.featureGroupProperties : {}
            );
            setPreviewItem(data);
        }
    }, [activeDetail, inContainer, containerState]);

    useEffect(() => {
        setHnpData(
            collectionState.createCollection?.hnpCategories?.filter(_ =>
                hnpLeads?.includes(_.hnpId)
            ) || []
        );
    }, [hnpLeads, activeDetail]);

    const addTcpa = () => {
        const tempTcpa = [
            ...activeDetail.tcpa,
            {
                html: "",
                jornaya: false,
                trustedform: false,
                implicit: true,
                required: false,
                tempId: uuidv4(),
            },
        ];
        const tempLayoutConfig = activeDetail?.layoutConfig || null;

        const tempItems = JSON.parse(JSON.stringify(items)).map(_ => {
            if (_.rank === activeDetail.rank) {
                _.properties = activeDetail.properties;
                _.tcpa = tempTcpa;
                _.layoutConfig = tempLayoutConfig;
            }
            return _;
        });

        setActiveDetail(tempItems.find(_ => _.rank === activeDetail.rank));

        setTimeout(() => {
            addTcpaBtnRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }, 10);
    };

    const deleteTcpa = deletedId => {
        const tempTcpa = JSON.parse(JSON.stringify(activeDetail.tcpa)).filter(
            _ => _.tempId !== deletedId
        );
        setActiveDetail({ ...activeDetail, tcpa: tempTcpa });
    };

    const cancelTcpa = () => {
        setActiveDetail({});
        setHnpLeads(hnpLeadIds);
        setIsOffer(!isEmpty(properties.configInfo) ? true : false);
    };

    const saveTCPA = activeDetail => {
        const isValid = activeDetail.tcpa.every(_ => !!_.html);
        let hasOfferDetails = null;
        if (isOffer) {
            if (adType === "LeadCategory" || adType === "OfferGroup") {
                hasOfferDetails = {
                    adType: adType || "LeadCategory",
                    // name:
                    //     adObject?.name ||
                    //     activeDetail?.properties?.configInfo?.name, //here set the selected value
                    id:
                        adObject?.id ||
                        activeDetail?.properties?.configInfo?.id,
                    extras:
                        extras || activeDetail?.properties?.configInfo?.extras,
                };
            } else {
                hasOfferDetails = {
                    adType: "AdUnit",
                    name:
                        adObject?.name ||
                        activeDetail?.properties?.configInfo?.name, //here set the selected value
                    id:
                        adObject?.id ||
                        activeDetail?.properties?.configInfo?.id,
                    extras:
                        extras || activeDetail?.properties?.configInfo?.extras,
                };
            }
        } else {
            hasOfferDetails = {};
        }

        if (isValid) {
            dispatch(
                collectionActions.setDetailItems(
                    JSON.parse(JSON.stringify(items)).map(_ => {
                        if (_.rank === activeDetail.rank) {
                            _.tcpa = activeDetail.tcpa;
                            _.properties = {
                                ...activeDetail.properties,
                                configInfo: hasOfferDetails,
                                linkOutOffer: !isEmpty(linkoutOffer)
                                    ? createLinkOutData(linkoutOffer)
                                    : null,
                            };
                            _.hnpLeadIds = hnpLeads
                                ? hnpLeads.map(_ => parseInt(_))
                                : [];
                            _.layoutConfig = !isEmpty(individualLayoutData)
                                ? individualLayoutData[0]
                                : null;
                            _.layoutConfigCopy = !isEmpty(individualLayoutData)
                                ? individualLayoutData[0]
                                : null;
                        }
                        return _;
                    })
                )
            );
            setActiveDetail({});

            editDetailsRef.current.close();
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Please enter valid text",
                })
            );
        }
    };

    const addNewExtra = newItem => {
        let extrasCopy = cloneDeep(extras);
        let idx = extrasCopy?.findIndex(
            item => get(newItem, "key", "") == get(item, "key", "")
        );
        if (idx == -1)
            extrasCopy.push({
                key: get(newItem, "key", ""),
                value: get(newItem, "value", ""),
            });
        else
            extrasCopy[idx] = {
                ...extrasCopy[idx],
                value: get(newItem, "value", ""),
            };
        setExtras(extrasCopy);
    };
    const removeExtra = key => {
        setExtras(
            cloneDeep(extras).filter(item => get(item, "key", "") != key)
        );
    };
    const setOperator = type => {
        setUpdatedValidAnswers({ ...updatedValidAnswers, matchType: type });
    };

    const updateValidAnswers = answers => {
        setUpdatedValidAnswers({
            ...updatedValidAnswers,
            validAnswers: answers.map(_ => _.hnpId),
        });
    };

    const hasOfferComponent = question => {
        return (
            <section
                className={`${styles.questionBankDetails_Wrapper} ${
                    !editSection ? styles.scrollable : ""
                }`}
            >
                <div className={styles.editSection}>
                    <div className={styles.hasOfferSwitch}>
                        <span>Has Offer</span>
                        {/* Refer QuestionDetails 369 */}
                        <ToggleSwitch
                            isToggleOn={isOffer}
                            disabled={readOnly}
                            setIsToggleOn={val => {
                                setIsOffer(val);
                                if (!val) {
                                    setExtras(Extras || []);
                                    setAdObject(adObject || {});
                                    setAdType(
                                        adType === "Ad Unit" ||
                                            adType === "AdUnit"
                                            ? "Ad Unit"
                                            : "LeadCategory"
                                    );
                                    setHnpLeads(null);
                                }
                            }}
                        />
                    </div>

                    {isOffer && (
                        <>
                            {!adTypeDataLoading ? (
                                <>
                                    <div
                                        className={`${styles.rowInputWrapper} `}
                                    >
                                        <div
                                            className={`${styles.inputWrapper} ${styles.typeToggle}`}
                                        >
                                            <label
                                                className={styles.inputLabel}
                                                htmlFor="adType"
                                            >
                                                Ad Type:
                                            </label>
                                            <div
                                                className={styles.toggleWrapper}
                                            >
                                                <ToggleValueSwitch
                                                    leftLabel={"Ad Unit"}
                                                    rightLabel={"Offer Group"}
                                                    toggleState={
                                                        adType ===
                                                            "LeadCategory" ||
                                                        adType ===
                                                            "Offer Group" ||
                                                        adType === "OfferGroup"
                                                    }
                                                    onChange={val => {
                                                        setAdType(
                                                            val
                                                                ? "LeadCategory"
                                                                : "Ad Unit"
                                                        );
                                                        setAdObject({
                                                            ...cloneDeep(
                                                                adObject
                                                            ),
                                                            id: null,
                                                        });
                                                    }}
                                                    isDisabled={readOnly}
                                                />
                                            </div>
                                        </div>
                                        {adType !== "LeadCategory" ? (
                                            <div
                                                className={`${styles.inputWrapper} ${styles.typeSelect}`}
                                            >
                                                <label
                                                    className={
                                                        styles.inputLabel
                                                    }
                                                    htmlFor="adUnit"
                                                >
                                                    Ad Unit
                                                </label>
                                                <div
                                                    className={
                                                        styles.dropdownWrapper
                                                    }
                                                >
                                                    <Select
                                                        label={"Select Ad Unit"}
                                                        placeholder={`Search and select ${adType?.toLowerCase()}`}
                                                        options={adTypeList}
                                                        keyField={"name"}
                                                        onSelect={setAdObject}
                                                        idField={"name"}
                                                        selectedId={get(
                                                            adObject,
                                                            "name",
                                                            null
                                                        )}
                                                        showSearch={true}
                                                        isDisabled={readOnly}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className={`${styles.inputWrapper} ${styles.typeSelect}`}
                                            >
                                                <label
                                                    className={
                                                        styles.inputLabel
                                                    }
                                                    htmlFor="answers"
                                                >
                                                    Categories:
                                                </label>
                                                <div
                                                    className={
                                                        styles.dropdownWrapper
                                                    }
                                                >
                                                    <Select
                                                        label={
                                                            extras?.length > 0
                                                                ? null
                                                                : "Select"
                                                        }
                                                        position={"top"}
                                                        options={
                                                            collectionState
                                                                .createCollection
                                                                .hnpCategories
                                                        }
                                                        keyField={"hnpCategory"}
                                                        idField={"hnpId"}
                                                        multiSelect={true}
                                                        selectedIds={
                                                            Array.isArray(
                                                                extras
                                                            )
                                                                ? extras
                                                                : []
                                                        }
                                                        showOptionWithKeyValue={
                                                            true
                                                        }
                                                        onSelect={leads => {
                                                            setExtras(
                                                                leads.map(
                                                                    _ => _.hnpId
                                                                )
                                                            );
                                                        }}
                                                        portal={false}
                                                        isDisabled={readOnly}
                                                        notooltip
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : null}
                        </>
                    )}
                </div>
            </section>
        );
    };

    const editDetail = async (detail, isPreview = false) => {
        setIndividualLayoutData([detail?.layoutConfig]);
        setLayoutDataCopy([detail?.layoutConfig]);
        if (!isPreview) {
            setLayoutDefaultConfig(
                layouts.find(_ => _.name === detail?.layoutConfig?.name)
                    ?.configurations || []
            );
        } else {
            setLayoutDefaultConfig([]);
        }

        setActiveDetail(detail);
        setAdType(detail?.properties?.configInfo?.adType || "Ad Unit");
        setExtras(detail?.properties?.configInfo?.extras || []);
        setAdObject({
            id: detail?.properties?.configInfo?.id,
            name: detail?.properties?.configInfo?.name,
        });
        setHnpLeads(detail.hnpLeadIds);
        setIsOffer(!isEmpty(detail.properties.configInfo) ? true : false);
        setLinkoutOffer(
            !isEmpty(detail.properties.linkOutOffer)
                ? createLinkOutData(detail.properties.linkOutOffer, true)
                : null
        );
        if (!isPreview) editDetailsRef.current.open();
    };

    const updateActiveTcpa = (id, data) => {
        const activeTcpa = JSON.parse(JSON.stringify(activeDetail.tcpa)).map(
            _ => {
                if (_.tempId === id) {
                    return data;
                }
                return _;
            }
        );
        setActiveDetail({ ...activeDetail, tcpa: activeTcpa });
    };

    const updateActiveHasOffer = (id, data) => {};

    const { loading, mainLoader } = useSelector(
        state => state.flowmanager.questions
    );
    const urlParams = useParams();
    const history = useHistory();
    const { collections } = useSelector(
        state => state.flowmanager.container.createContainer
    );

    const selectLayout = data => {
        const item = layouts.filter(_ => _.name === data?.name);
        setActiveDetail({
            ...activeDetail,
            layoutConfig: item[0],
        });

        setIndividualLayoutData(item);
        setLayoutDataCopy(item);
        setLayoutDefaultConfig(
            layouts.find(_ => _.name === item[0]?.name)?.configurations || []
        );
    };

    return (
        <div
            className={`${
                !inRightPanel
                    ? styles["question-details-wrap"]
                    : styles["question-details-wrap-sidepanel"]
            }`}
            data-index={dataIndex ? dataIndex : index + 1}
        >
            {inRightPanel && (
                <>
                    <div
                        className={`dp-parent dp-parent-hor-space-between ${styles["quesCardTitle"]}`}
                    >
                        <p>Question Details</p>
                        <div
                            className={`circleIconColor cursorPointer`}
                            onClick={() => editQuestionRef?.current?.open()}
                        >
                            <Tooltip message={"Edit Questions"}>
                                <Icon
                                    icon="Edit"
                                    size="16px"
                                    enableHover={true}
                                />
                            </Tooltip>
                        </div>
                    </div>
                    <SidePanelInfinity
                        title={"Edit Question"}
                        ref={editQuestionRef}
                        saveButtonText="Save & Add"
                        width="720px"
                        isOffer
                        onSave={async () => {
                            let isFlagSuccessFull =
                                await questionRef.current.onSaveQuestionHandler();

                            if (isFlagSuccessFull) {
                                const pageName =
                                    window.location.pathname.split("/")[2];
                                await dispatch(
                                    fetchQuestionsData({ noPagination: true })
                                ).then(res => {
                                    const updatedQuestionData =
                                        res?.payload?.data || [];
                                    let newCopy = items.map((obj, idx) => {
                                        if (
                                            obj.id === parentActiveDetail.id ||
                                            obj.id ===
                                                parentActiveDetail?.properties
                                                    ?.question_details?.id
                                        ) {
                                            let properties = {
                                                properties: {
                                                    question_details:
                                                        updatedQuestionData.find(
                                                            item =>
                                                                item.id ===
                                                                obj.properties
                                                                    .question_details
                                                                    .id
                                                        ),
                                                },
                                            };
                                            return {
                                                ...parentActiveDetail,
                                                ...properties,
                                                id: parentActiveDetail?.id
                                                    ? parentActiveDetail?.id
                                                    : obj.properties
                                                          .question_details.id,
                                            };
                                        } else return obj;
                                    });
                                    const activeItem = newCopy.find(
                                        obj =>
                                            obj.id === question.id ||
                                            obj.id ===
                                                question.properties
                                                    .question_details.id
                                    );

                                    if (
                                        typeof setActiveDetailState ===
                                        "function"
                                    ) {
                                        setActiveDetailState(activeItem);
                                    }

                                    editQuestionRef.current.close();
                                });
                            }
                        }}
                        disableSaveBtn={
                            readOnly ||
                            loading ||
                            mainLoader ||
                            unsavedChanges ||
                            !isFlowLive
                        }
                        showLoader={loading || mainLoader}
                        showHistoryCallback={() =>
                            questionRef?.current?.showHistory()
                        }
                        navigateCallback={() =>
                            questionRef?.current?.navigateCallback()
                        }
                    >
                        <>
                            <CreateFlowQuestion
                                id={
                                    question?.question
                                        ? question?.id
                                        : question?.properties?.question_details
                                              ?.id
                                }
                                isSidePane={true}
                                ref={questionRef}
                                isDisabled={readOnly}
                                updateButtonCheck={val => {
                                    if (val !== unsavedChanges) {
                                        setUnsavedChanges(val);
                                    }
                                }}
                            />
                        </>
                    </SidePanelInfinity>
                </>
            )}
            <div
                className={`dp-parent dp-parent-ver-center ${styles.questionContainer} ${styles.questionTitle}`}
            >
                <div className={styles.key}>Question Title: </div>
                <div
                    className={styles.questionValue}
                    title={parentActiveDetail.question}
                >
                    {parentActiveDetail?.question
                        ? parentActiveDetail?.question
                        : parentActiveDetail?.properties?.question_details
                              ?.question}
                </div>
            </div>
            <div
                className={`dp-parent dp-parent-hor-space-between dp-parent-ver-center mb-20`}
            >
                {!inRightPanel && (
                    <div
                        className={`dp-parent dp-parent-hor-space-between dp-parent-ver-center ${styles.questionContainer}`}
                    >
                        <div className={styles.key}>Question Name: </div>
                        <div
                            className={styles.questionValue}
                            title={question.question}
                        >
                            {question?.question
                                ? question?.question
                                : question?.properties?.question_details?.name}
                        </div>
                    </div>
                )}

                {tcpaWrite && readView && tcpaLocalOptions?.enable && (
                    <div
                        className={`circleIconColor cursorPointer`}
                        onClick={() => setReadView(false)}
                    >
                        <Icon icon="Edit" size="16px" enableHover={true} />
                    </div>
                )}
                {editOptions && (
                    <div
                        className={`dp-parent gap-20 ${styles["edit-options"]}`}
                    >
                        {!readOnly && <DragHandle></DragHandle>}

                        <div
                            className={`${styles.option} circleIconColor`}
                            onClick={() => {
                                editDetail(question);
                            }}
                        >
                            <Icon icon="Edit" size="16px" enableHover={true} />
                        </div>

                        <div
                            className={`${styles.option} dp-parent circleIconColor`}
                            onClick={async () => {
                                await editDetail(question, true);
                                await layoutOpenRef.current.open();
                            }}
                        >
                            <Icon icon="View" size="16px" enableHover={true} />
                        </div>

                        {!readOnly && (
                            <div
                                className={`${styles.option} circleIconColor circleIconColor--delete`}
                                onClick={() => {
                                    question?.id
                                        ? setDeletePopup(question)
                                        : removeQuestion(question);
                                }}
                            >
                                <Icon
                                    icon="Delete"
                                    size="16px"
                                    enableHover={true}
                                />
                            </div>
                        )}
                    </div>
                )}
                {deletePopup === question && (
                    <div className={styles.deletePopup}>
                        <PopupTargettingParams
                            setName={question}
                            Rfunction={removeQuestion}
                            setShowPopup={setDeletePopup}
                            customText={`Do you wish to delete this item?`}
                        />
                    </div>
                )}
            </div>
            {!inRightPanel && (
                <>
                    <div className={`dp-parent dp-parent-ver-center mb-20`}>
                        <div className={`${styles.layoutKey}`}>
                            Layout Name :
                        </div>
                        <div
                            className={`${styles.value} ${styles.layoutValue}`}
                            title={
                                question?.layoutConfig
                                    ? question?.layoutConfig?.name
                                    : "-"
                            }
                        >
                            {question?.layoutConfig
                                ? question?.layoutConfig?.name
                                : "-"}
                        </div>
                    </div>
                    <div className={`dp-parent dp-parent-ver-center mb-20`}>
                        <div className={styles.key}>Question Type : </div>
                        <div
                            className={styles.value}
                            title={question?.properties?.question_details?.type}
                        >
                            {question?.properties?.question_details?.type}
                        </div>
                    </div>
                    <div className={styles.tcpaDetail}>
                        <div
                            className={`${styles.additionalDetails} ${styles.customTcpa}`}
                        >
                            <span className={styles.tcpaHead}>TCPA :</span>{" "}
                            <span className={styles.tcpaHeadValue}>
                                {question?.tcpa?.length > 0 ||
                                question?.tcpa?.some(_ => _.isActive)
                                    ? "Added"
                                    : "-"}
                            </span>
                        </div>
                        <div className={styles.additionalDetails}>
                            <span className={styles.tcpaHead}>Has Offer :</span>{" "}
                            <span className={styles.tcpaHeadValue}>
                                {!isEmpty(question?.properties?.configInfo)
                                    ? "Added"
                                    : "-"}
                            </span>
                        </div>
                        <div className={styles.additionalDetails}>
                            <span className={styles.tcpaHead}>
                                Hnp Category :
                            </span>{" "}
                            <span className={styles.tcpaHeadValue}>
                                {question?.hnpLeadIds?.length > 0
                                    ? "Added"
                                    : "-"}
                            </span>
                        </div>
                    </div>
                </>
            )}
            {inRightPanel ? (
                <>
                    <div
                        className={`dp-parent dp-parent-ver-center mb-20 ${styles.questionWrapper}`}
                    >
                        <div className={styles.key}>Question Name: </div>
                        <div
                            className={styles.questionValue}
                            title={parentActiveDetail.question}
                        >
                            {parentActiveDetail?.question
                                ? parentActiveDetail?.question
                                : parentActiveDetail?.properties
                                      ?.question_details?.name}
                        </div>
                    </div>
                    <div className={`dp-parent dp-parent-ver-center mb-20`}>
                        <div className={styles.key}>Question Type: </div>
                        <div
                            className={styles.value}
                            title={
                                parentActiveDetail?.properties?.question_details
                                    ?.type
                            }
                        >
                            {
                                parentActiveDetail?.properties?.question_details
                                    ?.type
                            }
                        </div>
                    </div>
                    <div className={`dp-parent dp-parent-ver-center mb-20`}>
                        <div className={styles.key}>Choices: </div>
                        <div className={`${styles.value} ${styles.valueMulti}`}>
                            {parentActiveDetail?.answers
                                ? parentActiveDetail?.answers?.map(_ => (
                                      <div key={_.answer}>
                                          <span></span>
                                          {_.answer}
                                      </div>
                                  ))
                                : parentActiveDetail?.properties?.question_details?.answers.map(
                                      _ => (
                                          <div key={_.answer}>
                                              <span></span>
                                              {_.answer}
                                          </div>
                                      )
                                  )}
                        </div>
                    </div>

                    {parentActiveDetail?.properties?.question_details?.tag && (
                        <div className={`dp-parent dp-parent-ver-center mb-20`}>
                            <div className={styles.key}>Parent Question: </div>
                            <div
                                className={styles.value}
                                title={
                                    parentActiveDetail?.properties
                                        ?.question_details?.tag
                                }
                            >
                                {
                                    parentActiveDetail?.properties
                                        ?.question_details?.tag
                                }
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>{null}</>
            )}
            <SidePanelInfinity
                title={"Edit Collection Item"}
                ref={editDetailsRef}
                saveButtonText="Save & Add"
                onSave={() => {
                    clickSaveandAdd(activeDetail);
                }}
                onClose={cancelTcpa}
                disableSaveBtn={adTypeDataLoading || readOnly}
                showLoader={adTypeDataLoading}
                width="1300px"
                dialogContentClassName={styles.panelNoPadding}
            >
                <div className={`dp-parent w-100 ${styles.itemContainer}`}>
                    <div className={`pt-20 pb-20 pr-20 ${styles.itemWrapper}`}>
                        <DetailWrap
                            inRightPanel
                            question={question}
                            index={index}
                            dataIndex={dataIndex}
                            selectedQuestionId={selectedQuestionId}
                            surveyPathId={surveyPathId}
                            actions={actions}
                            className={className}
                            readOnly={readOnly}
                            editOptions={false}
                            removeQuestion={removeQuestion}
                            editQuestion={editQuestion}
                            tcpaWrite={true}
                            checkboxControl={checkboxControl}
                            templates={templates}
                            layouts={layouts}
                            updateLayouts={updateLayouts}
                            inContainer={inContainer}
                            defaultContainerLayout={defaultContainerLayout}
                            parentActiveDetail={activeDetail}
                            setActiveDetailState={data => {
                                setActiveDetail(data);
                            }}
                            selectedFlowDomain={selectedFlowDomain}
                            isFlowLive={isFlowLive}
                        ></DetailWrap>
                        {
                            // <div className="mb-20">
                            //     <div className="dp-parent dp-parent-hor-space-between dp-child-ver-center">
                            //         <p className={`${styles.tcpaHeader}`}>
                            //             Template Name
                            //         </p>
                            //         {!readOnly &&
                            //             activeDetail.properties?.template?.id && (
                            //                 <div
                            //                     className="dp-parent gap-5 cursorPointer circleIconColor"
                            //                     onClick={() => {
                            //                         setTimeout(() => {
                            //                             templateOpenRef.current.open();
                            //                             dispatch(
                            //                                 fetchTemplate(
                            //                                     activeDetail
                            //                                         .properties
                            //                                         .template?.id
                            //                                 )
                            //                             );
                            //                         });
                            //                         setViewIsClick(true);
                            //                     }}
                            //                 >
                            //                     <Icon icon={"View"} size="18px" />
                            //                     Edit
                            //                 </div>
                            //             )}
                            //     </div>
                            //     <Select
                            //         options={templates}
                            //         idField="id"
                            //         keyField="name"
                            //         label="Select Template"
                            //         selectedId={
                            //             activeDetail.properties?.template?.id
                            //                 ? parseInt(
                            //                       activeDetail.properties?.template
                            //                           ?.id
                            //                   )
                            //                 : null
                            //         }
                            //         onSelect={option => {
                            //             if (option?.id) {
                            //                 setActiveDetail({
                            //                     ...activeDetail,
                            //                     properties: {
                            //                         ...activeDetail.properties,
                            //                         template: {
                            //                             id: option.id,
                            //                             properties: {
                            //                                 header1:
                            //                                     "Register,  Apply",
                            //                             },
                            //                         },
                            //                     },
                            //                 });
                            //             }
                            //         }}
                            //         showCreate
                            //         onLoadSelectReturn={false}
                            //         onCreate={item => {
                            //             setCreatePhasePopup(true);
                            //             templateOpenRef.current.open();
                            //             setTimeout(() => {
                            //                 dispatch(
                            //                     templateActions.setTemplateName(
                            //                         item.name
                            //                     )
                            //                 );
                            //             });
                            //         }}
                            //         isDisabled={readOnly}
                            //     />
                            // </div>
                        }

                        {
                            <div className="mb-20">
                                <div className={`${styles.tcpaHeader}`}>
                                    <p>Ad Unit Config</p>
                                </div>
                                <div>
                                    <Button
                                        onClick={() => {
                                            setEditLinkOut({
                                                data: [],
                                                index: 0,
                                            });
                                            addAdUnitRef?.current?.open();
                                        }}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={readOnly}
                                    >
                                        Add Config
                                    </Button>
                                </div>

                                {linkoutOffer?.length > 0 && (
                                    <div className="mt-20">
                                        {linkoutOffer?.map((_, index) => {
                                            let selectedAds =
                                                !isEmpty(_.targettingParams) &&
                                                _.targettingParams.value?.map(
                                                    element => {
                                                        return {
                                                            ...element,
                                                            value: element.value[0]?.value?.filter(
                                                                _ =>
                                                                    _.linkOutQuestion
                                                            ),
                                                        };
                                                    }
                                                );
                                            let initialTagData =
                                                !isEmpty(selectedAds) &&
                                                selectedAds?.filter(
                                                    _ => !isEmpty(_.value)
                                                );
                                            let finalTagData = [];
                                            !isEmpty(initialTagData) &&
                                                initialTagData[0]?.value.forEach(
                                                    element => {
                                                        finalTagData.push({
                                                            valueOne:
                                                                question
                                                                    ?.properties
                                                                    ?.question_details
                                                                    ?.id,
                                                            valueName:
                                                                question
                                                                    ?.properties
                                                                    ?.question_details
                                                                    ?.name,
                                                            valueSecond:
                                                                question?.properties?.question_details?.answers?.find(
                                                                    _ =>
                                                                        _.key ===
                                                                        element.value
                                                                )?.answer ||
                                                                element.value,
                                                        });
                                                    }
                                                );
                                            let answersSelected =
                                                !isEmpty(finalTagData) &&
                                                finalTagData?.map(
                                                    _ => _.valueSecond
                                                );
                                            let finalTargettingData =
                                                getFinalTargetingData(_);
                                            let targetingsAdded = findByValue(
                                                finalTargettingData,
                                                apiData
                                            );
                                            let finalData = {
                                                adUnitId: _.adUnitId,
                                                targettingParams: {
                                                    type: "OR",
                                                    value: finalTargettingData,
                                                },
                                            };

                                            return (
                                                <>
                                                    <AdUnitCard
                                                        data={finalData}
                                                        adUnitList={adUnits}
                                                        adTypeDataLoading={
                                                            adTypeDataLoading
                                                        }
                                                        answersSelected={
                                                            answersSelected
                                                        }
                                                        targetingsAdded={
                                                            targetingsAdded
                                                        }
                                                        setLinkoutOffer={
                                                            setLinkoutOffer
                                                        }
                                                        setEditLinkOut={
                                                            setEditLinkOut
                                                        }
                                                        linkoutOffer={
                                                            linkoutOffer
                                                        }
                                                        index={index}
                                                        addAdUnitRef={
                                                            addAdUnitRef
                                                        }
                                                        readOnly={readOnly}
                                                    />
                                                </>
                                            );
                                        })}
                                    </div>
                                )}

                                <SidePanelInfinity
                                    title={"Ad Unit Config"}
                                    ref={addAdUnitRef}
                                    saveButtonText="Save"
                                    disableSaveBtn={
                                        readOnly || loading || mainLoader
                                    }
                                    onSave={() => {
                                        let adUnitData =
                                            linkOutRef?.current.onSave();
                                        if (!isEmpty(adUnitData)) {
                                            let updatedLinkOut = [];
                                            if (!isEmpty(linkoutOffer)) {
                                                updatedLinkOut = [
                                                    ...linkoutOffer,
                                                ];
                                                updatedLinkOut[
                                                    editLinkOut?.index
                                                ] = adUnitData;
                                            }
                                            isEmpty(editLinkOut?.data) &&
                                            !isEmpty(linkoutOffer)
                                                ? setLinkoutOffer([
                                                      adUnitData,
                                                      ...linkoutOffer,
                                                  ])
                                                : isEmpty(linkoutOffer)
                                                ? setLinkoutOffer([adUnitData])
                                                : setLinkoutOffer(
                                                      updatedLinkOut
                                                  );
                                            addAdUnitRef?.current?.close();
                                        }
                                    }}
                                >
                                    <LinkOutOffer
                                        adUnitList={adUnits}
                                        questionDetails={
                                            question?.properties
                                                ?.question_details
                                        }
                                        linkoutOffer={editLinkOut?.data}
                                        adTypeDataLoading={adTypeDataLoading}
                                        readOnly={readOnly}
                                        ref={linkOutRef}
                                    />
                                </SidePanelInfinity>
                            </div>
                        }

                        {
                            <div className="mb-20">
                                <div className="dp-parent dp-parent-hor-space-between dp-child-ver-center">
                                    <p className={`${styles.tcpaHeader}`}>
                                        Layout
                                    </p>
                                    {!isEmpty(
                                        activeDetail?.layoutConfig?.name
                                    ) ? (
                                        <p
                                            className={`${styles.sideTitle} ${styles.sideEditTitle} mb-10`}
                                            onClick={() => {
                                                layoutOpenRef.current.open();
                                            }}
                                        >
                                            <Icon
                                                icon="EditLayoutProperties"
                                                size="16px"
                                                enableHover={true}
                                            />
                                            {activeDetail?.layoutConfig
                                                ?.configurations?.length > 0
                                                ? " Layout Features"
                                                : "View Preview"}
                                        </p>
                                    ) : null}
                                </div>
                                <Select
                                    options={layoutDropdownTypeData}
                                    idField="name"
                                    keyField="name"
                                    label="Select Layout"
                                    selectedId={
                                        activeDetail?.layoutConfig?.name
                                            ? layoutDropdownTypeData.find(
                                                  (obj, idx) =>
                                                      obj.name ===
                                                      activeDetail?.layoutConfig
                                                          ?.name
                                              )
                                                ? activeDetail?.layoutConfig
                                                      ?.name
                                                : null
                                            : null
                                    }
                                    onSelect={selectLayout}
                                    onLoadSelectReturn={false}
                                    isDisabled={readOnly}
                                    portal={false}
                                    CustomRender={item => {
                                        return (
                                            <div className="dp-parent gap-10 w-100">
                                                <div> {item.option.name}</div>
                                                {item.option.configurations
                                                    .length > 0 && (
                                                    <div className="iconInsideDiv">
                                                        <Icon
                                                            icon="Customise"
                                                            size="16px"
                                                            className="circleIconColor"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                    closeIcon={true}
                                />
                            </div>
                        }

                        <div className={`${styles.tcpaHeader}`}>
                            <p>Add TCPA</p>
                        </div>
                        {activeDetail?.tcpa?.length > 0 &&
                            activeDetail?.tcpa?.map((tcpa, index) => {
                                return (
                                    <FlowTcpa
                                        key={tcpa.tempId}
                                        checkboxControl={checkboxControl}
                                        tcpaData={tcpa}
                                        isReadOnly={readOnly}
                                        onChange={data =>
                                            updateActiveTcpa(tcpa.tempId, data)
                                        }
                                        onDelete={() => deleteTcpa(tcpa.tempId)}
                                        activeDetail={activeDetail}
                                        editorCustomOptions={{
                                            macro: true,
                                            partners: false,
                                            skip: true,
                                        }}
                                        showPosition
                                    />
                                );
                            })}
                        {
                            <div ref={addTcpaBtnRef}>
                                <Button
                                    onClick={addTcpa}
                                    btnTheme={ButtonTypes.primaryHover_btn}
                                    disable={readOnly}
                                >
                                    Add TCPA
                                </Button>
                            </div>
                        }
                        {hasOfferComponent(question)}
                        <div className={`${styles.inputWrapper} mt-20`}>
                            <label
                                className={styles.inputLabel}
                                htmlFor="answers"
                            >
                                HNP Lead trigger:
                            </label>
                            <div className={styles.dropdownWrapper}>
                                <Select
                                    label={
                                        hnpLeads?.length > 0 ? null : "Select"
                                    }
                                    position={"top"}
                                    options={
                                        collectionState.createCollection
                                            .hnpCategories
                                    }
                                    keyField={"hnpCategory"}
                                    idField={"hnpId"}
                                    multiSelect={true}
                                    selectedIds={
                                        //temp parse int added, need to change from backend
                                        hnpLeads
                                            ? hnpLeads.map(_ => parseInt(_))
                                            : []
                                    }
                                    showOptionWithKeyValue={true}
                                    onSelect={leads => {
                                        setHnpLeads(leads.map(_ => _.hnpId));
                                    }}
                                    portal={false}
                                    isDisabled={readOnly}
                                />
                            </div>
                        </div>
                        {hnpData?.length > 0 && (
                            <div className="mt-20">
                                {hnpData.map(_ => {
                                    return (
                                        <HnPData
                                            hnpData={_}
                                            setActiveHnpId={setActiveHnpId}
                                            editHnpSidePanelRef={
                                                editHnpSidePanelRef
                                            }
                                            setHnpLeads={setHnpLeads}
                                            apiData={apiData}
                                            readOnly={readOnly}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className={`overflowHidden dp-child-50`}>
                        <LayoutEditComp
                            vertical={createCollectionState.vertical}
                            selectedFlowDomain={selectedFlowDomain}
                            defaultConfigs={[]}
                            // hidePreviewHeader={true}
                            // individualLayoutData={individualLayoutData}
                            item={previewItem}
                            // showConfigSelect={showConfigSelect}
                            // addConfigList={addConfigList}
                            // setShowConfigSelect={data => {
                            //     setShowConfigSelect(data);
                            // }}
                        />
                    </div>
                </div>
            </SidePanelInfinity>
            <SidePanelInfinity
                ref={templateOpenRef}
                title={`${viewIsClick ? "Edit" : "Create"} Template`}
                onSave={() => {
                    saveFunctionCallRef.current.onSaveHandler();
                    setTimeout(() => {
                        dispatch(fetchAllTemplate()).then(res => {
                            setTemplates(get(res, "payload", []));
                        });
                    }, 1000);
                    templateOpenRef.current.close();
                    setViewIsClick(false);
                    setCreatePhasePopup(false);
                }}
                onClose={() => {
                    setViewIsClick(false);
                    setCreatePhasePopup(false);
                }}
                disableSaveBtn={
                    createTemplateState.name == "" ||
                    createTemplateState.configuration?.length === 0 ||
                    readOnly ||
                    !isFlowLive
                }
                children={
                    <CreateTemplate
                        editPhaseInPopUp={viewIsClick ? true : false}
                        ref={saveFunctionCallRef}
                        showInPopup={true}
                        createPhaseInPopup={createPhasePopup}
                    />
                }
            ></SidePanelInfinity>
            <SidePanelInfinity
                ref={layoutOpenRef}
                title={"Edit Layout Features"}
                width={"93%"}
                dialogContentClassName={styles.popupStyle}
                onSave={() => {
                    let isValid = true;
                    individualLayoutData[0]?.configurations?.length &&
                        individualLayoutData[0]?.configurations.forEach(
                            (item, index) => {
                                if (
                                    item.type === "string" ||
                                    item.type === "richText"
                                ) {
                                    if (!item?.data) {
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Error",
                                                message: `Please enter default value for Key ${item.key}.`,
                                            })
                                        );
                                        isValid = false;
                                    }
                                }
                                if (item.type === "number") {
                                    let intData = parseInt(item?.data);
                                    if (intData === "" || intData === null) {
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Error",
                                                message: `Please enter default value for Key ${item.key}.`,
                                            })
                                        );
                                        isValid = false;
                                    }
                                    if (
                                        typeof intData === "number" &&
                                        (intData > parseInt(item.max) ||
                                            intData < parseInt(item.min))
                                    ) {
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Error",
                                                message: `Please enter value between ${item.min} and ${item.max} for Key ${item.key}.`,
                                            })
                                        );
                                        isValid = false;
                                    }
                                }
                            }
                        );

                    if (isValid) {
                        if (typeof updateLayouts === "function") {
                            setLayoutDataCopy(individualLayoutData);
                            cancelLayoutEdit = false;
                            dispatch(
                                collectionActions.setItems(
                                    items.map(_ => {
                                        if (_.id === activeDetail.id) {
                                            return {
                                                ..._,
                                                layoutConfig:
                                                    individualLayoutData,
                                            };
                                        } else return _;
                                    })
                                )
                            );

                            layoutOpenRef.current.close();
                        }
                    }
                }}
                disableSaveBtn={readOnly || layoutDefaultConfig?.length === 0}
                onClose={() => {
                    if (!!cancelLayoutEdit) {
                        setIndividualLayoutData(layoutDataCopy);
                    }
                }}
            >
                <LayoutEditComp
                    vertical={createCollectionState.vertical}
                    selectedFlowDomain={selectedFlowDomain}
                    defaultConfigs={layoutDefaultConfig}
                    individualLayoutData={individualLayoutData}
                    updatedLayout={data => {
                        setIndividualLayoutData(data);
                        setActiveDetail({
                            ...activeDetail,
                            layoutConfig: individualLayoutData[0],
                        });
                    }}
                    item={previewItem}
                    ondelete={item => {
                        setIndividualLayoutData([
                            {
                                ...individualLayoutData[0],
                                configurations:
                                    individualLayoutData[0]?.configurations?.filter(
                                        _ => _.key !== item.key
                                    ),
                            },
                        ]);
                    }}
                    showConfigSelect={showConfigSelect}
                    addConfigList={addConfigList}
                    setShowConfigSelect={data => {
                        setShowConfigSelect(data);
                    }}
                    customOnSelect={item => {
                        setShowConfigSelect(false);
                        setIndividualLayoutData([
                            {
                                ...individualLayoutData[0],
                                configurations: [
                                    ...individualLayoutData[0]?.configurations,
                                    ...item,
                                ],
                            },
                        ]);
                    }}
                    onCancelHandler={e => {
                        setShowConfigSelect(false);
                    }}
                    readOnly={readOnly}
                />
            </SidePanelInfinity>
            <SidePanelInfinity
                title={`Edit HNP`}
                width={"645px"}
                ref={editHnpSidePanelRef}
                onSave={async () => {
                    const saveRes = await createFlowPageRef.current?.save();
                    if (saveRes) {
                        const resObject = saveRes.data.data;
                        setHnpData(_ => [
                            ..._.map(ele =>
                                ele.hnpId === resObject.hnpId ? resObject : ele
                            ),
                        ]);
                        await dispatch(fetchAllHnpCategories());
                        editHnpSidePanelRef.current.close();
                    }
                }}
                disableSaveBtn={readOnly}
            >
                <CreateFlowCategories
                    passedId={activeHnpId}
                    ref={createFlowPageRef}
                />
            </SidePanelInfinity>
        </div>
    );
};

const SortableItem = SortableElement(
    ({
        question,
        index,
        dataIndex,
        selectedQuestionId,
        surveyPathId,
        actions,
        className,
        readOnly,
        editOptions,
        removeQuestion,
        editQuestion,
        tcpaWrite,
        checkboxControl,
        templates,
        updateLayouts,
        layouts,
        inContainer,
        defaultContainerLayout,
        selectedFlowDomain = null,
        isFlowLive = false,
    }) => (
        <DetailWrap
            question={question}
            index={index}
            dataIndex={dataIndex}
            selectedQuestionId={selectedQuestionId}
            surveyPathId={surveyPathId}
            actions={actions}
            className={className}
            readOnly={readOnly}
            editOptions={editOptions}
            removeQuestion={removeQuestion}
            editQuestion={editQuestion}
            tcpaWrite={tcpaWrite}
            checkboxControl={checkboxControl}
            templates={templates}
            layouts={layouts}
            updateLayouts={updateLayouts}
            inContainer={inContainer}
            defaultContainerLayout={defaultContainerLayout}
            selectedFlowDomain={selectedFlowDomain}
            isFlowLive={isFlowLive}
        ></DetailWrap>
    )
);

const SortableList = SortableContainer(({ children }) => {
    return <div className="question-details">{children}</div>;
});

const DetailsList = ({
    selectedQuestionId,
    questionsList,
    editOptions,
    className,
    setQuestionList,
    actions,
    readOnly = false,
    surveyPathId = "",
    tcpaWrite = false,
    checkboxControl = false,
    upDateCardData,
    inContainer,
    defaultContainerLayout,
    deletedQuestions,
    selectedFlowDomain = null,
    isFlowLive = false,
}) => {
    const [editId, setEditId] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAllTemplate()).then(res => setTemplates(res.payload));
        dispatch(fetchAllLayout()).then(res =>
            setLayouts(get(res, "payload", []))
        );
        return () => {
            setTemplates([]);
            setLayouts([]);
        };
    }, []);

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        if (typeof setQuestionList == "function") {
            let initialSortList = await arrayMove(
                questionsList,
                oldIndex,
                newIndex
            );
            let updatedList = await JSON.parse(
                JSON.stringify(initialSortList)
            )?.map((_, index) => {
                return {
                    ..._,
                    rank: index + 1,
                };
            });
            await setQuestionList(updatedList);
        }
    };

    const removeQuestion = question => {
        let questionListClone = cloneDeep([...questionsList]);
        questionListClone.find(
            value => value.rank === question?.rank
        ).isActive = false;
        let updatedQuestionList = [];
        let questionsDeleted = [...deletedQuestions];
        let rank = 1;
        questionListClone.forEach(_ => {
            if (_?.isActive) {
                let updatedQuestion = {
                    ..._,
                    rank: rank,
                };
                updatedQuestionList.push(updatedQuestion);
                rank++;
            } else {
                let updatedQuestion = {
                    ..._,
                    rank: -1, //Setting this to -1 as need to update the isActive status to backend
                };
                // created a new reference for deleted questions as the select only gives selected questions
                questionsDeleted.push(updatedQuestion);
            }
        });
        setQuestionList(updatedQuestionList);
        dispatch(collectionActions.setDeletedQuestions(questionsDeleted));
    };

    const editQuestion = question => {
        setEditId(prevState => {
            return [...prevState, question.id];
        });
    };
    const saveQuestionHandler = question => {
        let prevState = cloneDeep(questionsList);
        const index = prevState.findIndex(ques => {
            return ques.id == question.id;
        });
        if (index !== -1) {
            prevState[index] = {
                ...prevState[index],
                questionName: question.questionName,
                questionTitle: question.questionTitle,
                optionList: question.optionList,
                hasOther: question.hasOther,
            };
        }
        setQuestionList(prevState);
        cancelQuestionHandler(question.id);
    };
    const cancelQuestionHandler = questionId => {
        setEditId(prevState => {
            return prevState.filter(id => {
                return id != questionId;
            });
        });
    };

    const updateLayouts = array => {
        setLayouts(array);
    };

    return (
        <>
            {editOptions && questionsList && (
                <SortableList onSortEnd={onSortEnd} useDragHandle>
                    {questionsList
                        ?.filter(_ => _.isActive)
                        ?.map((question, index) =>
                            editId.indexOf(question.id) == -1 ? (
                                question?.isActive ? (
                                    <SortableItem
                                        key={
                                            `sort-${question?.properties?.question_details?.name}` ||
                                            `sort-${question?.id}`
                                        }
                                        index={index}
                                        question={question}
                                        dataIndex={index + 1}
                                        selectedQuestionId={selectedQuestionId}
                                        surveyPathId={surveyPathId}
                                        actions={actions}
                                        className={className}
                                        readOnly={readOnly}
                                        editOptions={editOptions}
                                        templates={templates}
                                        layouts={layouts}
                                        updateLayouts={updateLayouts}
                                        removeQuestion={removeQuestion}
                                        inContainer={inContainer}
                                        defaultContainerLayout={
                                            defaultContainerLayout
                                        }
                                        selectedFlowDomain={selectedFlowDomain}
                                        isFlowLive={isFlowLive}
                                    />
                                ) : null
                            ) : (
                                <AddNewDetail
                                    question={question}
                                    className={styles["add-question-wrap"]}
                                    onSave={saveQuestionHandler}
                                    onCancel={cancelQuestionHandler.bind(
                                        null,
                                        question.id
                                    )}
                                    // key={question.id || question.questionName}
                                    actions={questionsActions}
                                ></AddNewDetail>
                            )
                        )}
                </SortableList>
            )}
            {!editOptions &&
                questionsList &&
                questionsList.map((question, index) => (
                    <DetailWrap
                        // key={index + (question.id || question.questionName)}
                        key={
                            `detailWrap-${question?.properties?.question_details?.name}` ||
                            `detailWrap-${question.id}`
                        }
                        question={question}
                        index={index}
                        selectedQuestionId={selectedQuestionId}
                        surveyPathId={surveyPathId}
                        actions={actions}
                        className={className}
                        readOnly={readOnly}
                        editOptions={editOptions}
                        removeQuestion={removeQuestion}
                        editQuestion={editQuestion}
                        tcpaWrite={tcpaWrite}
                        checkboxControl={checkboxControl}
                        layouts={layouts}
                        updateLayouts={updateLayouts}
                        inContainer={inContainer}
                        defaultContainerLayout={defaultContainerLayout}
                        selectedFlowDomain={selectedFlowDomain}
                        isFlowLive={isFlowLive}
                    ></DetailWrap>
                ))}
        </>
    );
};

const CollectionDetails = ({
    type,
    cardTitle = "Details",
    isReadOnly = false,
    isSidePanel = false,
    className,
    inContainer = false,
    defaultContainerLayout = {},
    selectedFlowDomain = null,
    isFlowLive = false,
}) => {
    const item = null;
    const configuration = null;
    const collectionState = useSelector(state => state.flowmanager.collection);
    const allQuestionsList = useSelector(
        state => state.flowmanager.questions.list
    );
    const items = get(collectionState, "createCollection.detailsList", []);
    const addItems = get(collectionState, "createCollection.addItems", []);
    const deletedQuestions = get(
        collectionState,
        "createCollection.deletedQuestions",
        []
    );
    const addItemsBtnRef = useRef();
    const dispatch = useDispatch();
    const urlParams = useParams();
    const surveyPaths = useSelector(state => state.listings.surveyPaths);
    const {
        createSurveyPath: { offerGroups = [], adUnits = [] },
    } = surveyPaths;

    const keywordDropdownOptions = [
        { id: "Logo url", label: "Logo url" },
        { id: "background Colour", label: "background Colour" },
    ];
    const detailsList = [];

    const addDetail = get(
        collectionState,
        "createCollection.itemsDisable",
        false
    );

    const collectionOptionData =
        get(configuration, "details", [])?.length > 0
            ? REGISTER_DETAIL_DROPDOWN.filter(
                  ele => !ele.id.includes(get(configuration, "details", []))
              )
            : REGISTER_DETAIL_DROPDOWN;

    const collectionDropdown = {
        className: `${styles.editDropdown}`,
        options: collectionOptionData,
        idField: "id",
        keyField: "label",
        label: "Search by name",
    };

    const keywordsInputJson = {
        type: "",
    };

    const keywordsDropDownJson = {
        className: ``,
        keyField: "label",
        idField: "id",
        position: "bottom",
        // options: keywordDropdownOptions,
        options: [],
        selectedID: null,
        label: "Select Keyword",
    };

    const addDefaultDetail = () => {
        dispatch(collectionActions.setDetailItems([...items]));
    };

    const addNewItems = _ => {
        addDefaultDetail();
        dispatch(collectionActions.setAddItems(true));
    };

    const questionAdded = newQuestion => {
        dispatch(questionsActions.addNewQuestion(newQuestion));
        dispatch(questionsActions.setAddQuestion(false));
    };

    useEffect(() => {
        if (addItems) dispatch(collectionActions.setAddItems(false));
        if (isEmpty(adUnits) && isEmpty(offerGroups))
            dispatch(fetchCreateSurveyPathData());
        return () => {
            dispatch(collectionActions.resetCollection());
        };
    }, []);

    const assignRank = data => {
        const getTcpaWithId = arr => {
            return JSON.parse(JSON.stringify(arr)).map(item => {
                item.tempId = uuidv4();
                return item;
            });
        };
        dispatch(
            collectionActions.setDetailItems(
                data.map((item, index) => {
                    return {
                        ...item,
                        rank: index + 1,
                        tcpa: getTcpaWithId(item?.tcpa || []),
                    };
                })
            )
        );
    };

    useEffect(() => {
        assignRank(items);
    }, [items.length]);

    const onAddItem = data => {
        dispatch(collectionActions.setDetailItems(data));
    };

    const scrollToButton = () => {
        setTimeout(() => {
            addItemsBtnRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }, 10);
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={`${!isSidePanel ? "flexHeightTab" : ""} row`}
        >
            <div className={`row`}>
                <p className={`headerTitle`}>{cardTitle}</p>
                <p className={`headerTitleRequired`}>Collection item</p>
                <div className={`${styles["add-new-question"]}`}>
                    {items.length > 0 && (
                        <div className={styles["question-list-wrap"]}>
                            <DetailsList
                                setQuestionList={list => {
                                    onAddItem(list);
                                }}
                                className={styles["question-details-card"]}
                                questionsList={items}
                                editOptions={true}
                                readOnly={isReadOnly}
                                inContainer={inContainer}
                                defaultContainerLayout={defaultContainerLayout}
                                deletedQuestions={deletedQuestions}
                                selectedFlowDomain={selectedFlowDomain}
                                isFlowLive={isFlowLive}
                            ></DetailsList>
                        </div>
                    )}
                </div>
                {!isReadOnly && (
                    <div ref={addItemsBtnRef} className="relative">
                        {addItems && (
                            <div className={styles["addItemsDropdownWrapper"]}>
                                <ReactSelect
                                    options={allQuestionsList || []}
                                    isMulti
                                    menuIsOpen
                                    selectedIds={
                                        items
                                            ?.filter(el => el?.isActive)
                                            ?.map(
                                                _ =>
                                                    _.properties
                                                        ?.question_details?.id
                                            ) || []
                                    }
                                    maxMenuHeight={214}
                                    listSelector={"name"}
                                    searchIdField={"id"}
                                    onCancelHandler={e => {
                                        dispatch(
                                            collectionActions.setAddItems(false)
                                        );
                                    }}
                                    customOnSelect={data => {
                                        //transform data as required
                                        let initialItems = [...items];
                                        let transformedData = data
                                            .map(itm => ({
                                                ...initialItems.find(
                                                    item =>
                                                        item?.properties
                                                            ?.question_details
                                                            ?.id === itm.id &&
                                                        item
                                                ),
                                                ...itm,
                                            }))
                                            ?.sort((a, b) => a.rank - b.rank)
                                            ?.map((ele, index) => ({
                                                ...items[index],
                                                properties: {
                                                    question_details: ele,
                                                    configInfo:
                                                        ele?.properties
                                                            ?.configInfo || {},
                                                    buttons: ["next"],
                                                    linkOutOffer:
                                                        createLinkOutData(
                                                            ele?.properties
                                                                ?.linkOutOffer
                                                        ) || null,
                                                },
                                                isActive: true,
                                                itemSetId: urlParams?.id,
                                            }));
                                        onAddItem(transformedData);
                                        scrollToButton();
                                    }}
                                />
                            </div>
                        )}
                        <Button
                            className={styles["add-question-button"]}
                            onClick={addNewItems}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={addDetail}
                        >
                            Add Items
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

const showTargettingDetails = (isRegister, answersSelected = [], data) => {
    if (isRegister) return !!data?.targettingParams?.value?.length;
    else
        return !!(
            data?.targettingParams?.value?.length > 0 &&
            answersSelected &&
            data?.targettingParams?.value?.length !== 1
        );
};

const isTargettingPresent = (answersSelected, data) => {
    return (
        answersSelected &&
        answersSelected?.includes("skip") &&
        data?.targettingParams?.value?.length === 1
    );
};

export const HnPData = ({
    hnpData,
    setActiveHnpId,
    editHnpSidePanelRef,
    setHnpLeads,
    apiData,
    readOnly,
}) => {
    const [showDetails, setShowDetails] = useState(false);

    let targetingsAdded = findByValue(hnpData.targettingParams?.value, apiData);
    return (
        <div className={styles.hnpData}>
            <div className={styles.hnpDetails}>
                <p className="mb-20">
                    ID - Name:{" "}
                    <span>
                        {hnpData.hnpId} - {hnpData.hnpCategory}
                    </span>
                </p>
            </div>
            <div
                className={`dp-parent dp-parent-hor-space-between
                }`}
            >
                <div>
                    <p className={showDetails ? "mb-10" : ""}>
                        Targetting Params :{" "}
                        {hnpData.targettingParams?.value?.length > 0
                            ? !showDetails && (
                                  <div
                                      className={`${
                                          !showDetails ? "fadeIn" : ""
                                      }
                                          ${
                                              !showDetails
                                                  ? styles.targettingValue
                                                  : ""
                                          }`}
                                  >
                                      <span className={styles.targetingsAdded}>
                                          {targetingsAdded[0]}
                                      </span>
                                      {targetingsAdded.length > 1 && (
                                          <span className={styles.more}>
                                              {" "}
                                              +{targetingsAdded.length - 1} more
                                          </span>
                                      )}
                                  </div>
                              )
                            : "-"}
                    </p>
                </div>
                {hnpData.targettingParams?.value?.length > 0 && (
                    <div
                        className={`${styles.btnViewDetails} ${
                            showDetails ? styles.open : ""
                        }`}
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                    >
                        {showDetails ? "Hide Details" : "View Details"}
                    </div>
                )}
            </div>

            {hnpData.targettingParams?.value?.length > 0 && showDetails && (
                <TargetingData
                    targettingParams={hnpData.targettingParams}
                    isReadOnly={true}
                />
            )}
            {!readOnly && (
                <div className={`${styles.iconWrap} dp-parent gap-20`}>
                    <div
                        onClick={async () => {
                            await setActiveHnpId(hnpData.hnpId);
                            editHnpSidePanelRef.current.open();
                        }}
                    >
                        <Tooltip
                            message={"Edit"}
                            position="top"
                            wrap
                            customWrapperClass="w-100"
                        >
                            <Icon
                                icon="Edit"
                                size="16px"
                                className="circleIconColor cursorPointer"
                                enableHover
                            />
                        </Tooltip>
                    </div>
                    <div
                        onClick={() => {
                            setHnpLeads(item =>
                                item.filter(ele => ele !== hnpData.hnpId)
                            );
                        }}
                    >
                        <Tooltip
                            message={"Delete"}
                            position="top"
                            wrap
                            customWrapperClass="w-100"
                        >
                            <Icon
                                icon="Delete"
                                size="16px"
                                className="circleIconColor circleIconColor--delete cursorPointer"
                                enableHover
                            />
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
};

export const AdUnitCard = ({
    data,
    adUnitList,
    adTypeDataLoading,
    showAnswers = true,
    answersSelected,
    targetingsAdded,
    setLinkoutOffer,
    setEditLinkOut,
    linkoutOffer,
    index,
    addAdUnitRef,
    isRegister = false,
    readOnly,
}) => {
    const [showDetails, setShowDetails] = useState(true);
    return (
        <div className={styles.hnpData}>
            <p
                className={
                    !isRegister ||
                    showTargettingDetails(isRegister, answersSelected, data)
                        ? "mb-20"
                        : ""
                }
            >
                <div className="dp-parent">
                    <span className={styles.adUnitKey}>Ad Unit : </span>
                    <span className={"pl-5"}>
                        {!isEmpty(
                            adUnitList?.filter(au => au.id === data.adUnitId)
                        ) ? (
                            adUnitList?.filter(au => au.id === data.adUnitId)[0]
                                .name
                        ) : adTypeDataLoading ? (
                            <div className={styles.adUnit}>
                                <Skeleton width={100} height={10} />
                            </div>
                        ) : (
                            "-"
                        )}
                    </span>
                </div>
            </p>
            {
                <>
                    {showAnswers && !isEmpty(answersSelected) && (
                        <p
                            className={
                                showTargettingDetails(
                                    isRegister,
                                    answersSelected,
                                    data
                                )
                                    ? "mb-20"
                                    : ""
                            }
                        >
                            Answers : <span>{answersSelected?.join(", ")}</span>
                        </p>
                    )}
                    {showTargettingDetails(
                        isRegister,
                        answersSelected,
                        data
                    ) && (
                        <div
                            className={`dp-parent dp-parent-hor-space-between ${
                                isTargettingPresent(answersSelected, data)
                                    ? ""
                                    : showDetails
                                    ? "mb-10"
                                    : ""
                            }`}
                        >
                            <div>
                                <p>
                                    Targetting Params :{" "}
                                    {data?.targettingParams?.value?.length > 0
                                        ? isTargettingPresent(
                                              answersSelected,
                                              data
                                          )
                                            ? "-"
                                            : !showDetails && (
                                                  <div
                                                      className={`${
                                                          !showDetails
                                                              ? "fadeIn"
                                                              : ""
                                                      }
                                                    ${
                                                        !showDetails
                                                            ? styles.targettingValue
                                                            : ""
                                                    }`}
                                                  >
                                                      <span
                                                          className={
                                                              styles.targetingsAdded
                                                          }
                                                      >
                                                          {targetingsAdded[0]}
                                                      </span>
                                                      {targetingsAdded.length >
                                                          1 && (
                                                          <span
                                                              className={
                                                                  styles.more
                                                              }
                                                          >
                                                              {" "}
                                                              +
                                                              {targetingsAdded.length -
                                                                  1}{" "}
                                                              more
                                                          </span>
                                                      )}
                                                  </div>
                                              )
                                        : "-"}
                                </p>
                            </div>
                            {showTargettingDetails(
                                isRegister,
                                answersSelected,
                                data
                            ) && (
                                <div
                                    className={`${styles.btnViewDetails} ${
                                        showDetails ? styles.open : ""
                                    }`}
                                    onClick={() => {
                                        setShowDetails(!showDetails);
                                    }}
                                >
                                    {showDetails
                                        ? "Hide Details"
                                        : "View Details"}
                                </div>
                            )}
                        </div>
                    )}
                </>
            }

            {data?.targettingParams?.value?.length > 0 && showDetails && (
                <TargetingData
                    targettingParams={data?.targettingParams}
                    isReadOnly={true}
                />
            )}
            {!readOnly && (
                <div className={`${styles.iconWrap} dp-parent gap-20`}>
                    <div
                        onClick={async () => {
                            let linkOutData = [...linkoutOffer];
                            let selectedData = linkOutData[index];
                            let finalTargettingData =
                                getFinalTargetingData(selectedData);
                            let finalData = {
                                adUnitId: selectedData.adUnitId,
                                targettingParams: {
                                    type: "OR",
                                    value: finalTargettingData,
                                },
                            };

                            setEditLinkOut({
                                data: [finalData],
                                index: index,
                            });
                            addAdUnitRef.current.open();
                        }}
                    >
                        <Tooltip
                            message={"Edit"}
                            position="top"
                            wrap
                            customWrapperClass="w-100"
                        >
                            <Icon
                                icon="Edit"
                                size="16px"
                                className="circleIconColor cursorPointer"
                                enableHover
                            />
                        </Tooltip>
                    </div>
                    <div
                        onClick={() => {
                            let updatedLinkOut = [...linkoutOffer];
                            updatedLinkOut?.splice(index, 1);
                            setLinkoutOffer(updatedLinkOut);
                        }}
                    >
                        <Tooltip
                            message={"Delete"}
                            position="top"
                            wrap
                            customWrapperClass="w-100"
                        >
                            <Icon
                                icon="Delete"
                                size="16px"
                                className="circleIconColor circleIconColor--delete cursorPointer"
                                enableHover
                            />
                        </Tooltip>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CollectionDetails;
