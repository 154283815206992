import s from "./StatusRenderer.module.scss";

const StatusRenderer = ({
    status,
    activeContent = "Active",
    inactiveContent = "Disabled",
    customClass = "",
    noText = false,
}) => {
    return (
        <div className={`${s.status} ${customClass}`}>
            <span
                className={
                    status === "live"
                        ? s.live
                        : status
                        ? s.active + " active "
                        : s.expired + " expired "
                }
            ></span>
            {!noText && <p>{status ? activeContent : inactiveContent}</p>}
        </div>
    );
};

export default StatusRenderer;
