import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { GIFTCARDS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "status",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "email",
        searchTerm: null,
        filterKey: FILTERS.URL_GENERATOR.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.URL_GENERATOR.filterOperator,
        filtersOperator: FILTERS.URL_GENERATOR.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "urlgenerator"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchGiftCardUrlDetails = createAsyncThunk(
    "listings/urlgenerator/giftcardUrldetails",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "urlGenerator.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "urlGenerator.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.URL_GENERATOR
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(urlGeneratorSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.urlGenerator.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.URL_GENERATOR,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.urlGenerator.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                GIFTCARDS.FETCH_URLGENERATOR_LIST,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch Gift Cards URL",
                        })
                    );
                    dispatch(
                        urlGeneratorSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(urlGeneratorActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metadata: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.URL_GENERATOR}`;
            dispatch(urlGeneratorSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new URL`;
            dispatch(urlGeneratorSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const deleteGiftCardUrl = createAsyncThunk(
    "dashboards/urlgenerator/deleteGiftCardUrl",
    async ({ email, giftCardId }) => {
        return await customFetch(
            `${GIFTCARDS.DELETE_GIFT_CARD_URL}email/${email}/giftcard/${giftCardId}`,
            "DELETE"
        );
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/urlGenerator/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.urlGenerator.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.DTO.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const urlGeneratorSlice = createSlice({
    name: "urlGenerator",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchGiftCardUrlDetails.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchGiftCardUrlDetails.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list = get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const urlGeneratorActions = urlGeneratorSlice.actions;
export default urlGeneratorSlice.reducer;
