import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { USER_JOURNEY } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import { mapUserJourneyListingData } from "../../utils/userJourneyUtils";

const initialDefaultDateRange = {
    startDate: startOfDay(subDays(new Date(), 6)),
    endDate: new Date(),
    key: "selection",
};

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        // rowsCount: -1, //Added this as per backend requirment to fetch all data
        pageNumber: 1,
        sortBy: "ts",
        isDesc: true,
        totalRowsCount: 0,
        searchTerm: null,
        filterKey: FILTERS.USER_JOURNEY.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.USER_JOURNEY.filterOperator,
        filtersOperator: FILTERS.USER_JOURNEY.operator,
        filters: [],
        date: initialDefaultDateRange,
        byPassSizeCheck: true, //Added this as per backend requirment
    },
    selectedUser: {},
    searchFilter: {
        filterApplied: getInitialTagData([], "userdashboard"),
    },
    tableError: MESSAGES.TABLE.userJourneyNoData,
};

export const fetchUserJourneyDetails = createAsyncThunk(
    "listings/userjourney",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "userJourney.pagination", {});
        let prevListData = get(listings, "userJourney.list", []);
        const params = paginationParams(state);
        const filters = get(
            listings,
            "userJourney.searchFilter.filterApplied",
            []
        );
        let tagFilters = get(
            listings,
            "userJourney.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.USER_JOURNEY
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "date",
            "sortby",
            "orderby",
            "byPassSizeCheck",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(userJourneySlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.userJourney.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.USER_JOURNEY,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.userJourney.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let finalPrevData = null;
        let data = !isEmpty(prevListData) ? finalPrevData : [];
        if (updatedParams?.filters?.length === 1 && prevFilters?.length === 2) {
            dispatch(userJourneyActions.setUserJourneyListData([]));
        } else if (!isEmpty(prevListData) && !isEmpty(prevFilters)) {
            finalPrevData = {
                data: prevListData,
                metadata: { totalFound: prevTotalRows },
            };
        }

        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            if (filters.length > 0) {
                const response = await customFetch(
                    USER_JOURNEY.FETCH_USER_JOURNEY_LIST,
                    "POST",
                    filterParamsData,
                    {},
                    {},
                    null,
                    null,
                    e => {
                        dispatchSearchError = false;
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message:
                                    e?.response?.data?.error ||
                                    "Failed to fetch User journey list",
                            })
                        );
                        dispatch(
                            userJourneySlice.actions.setTableError(
                                MESSAGES.TABLE.noDataFromApi
                            )
                        );
                    }
                );
                dispatch(
                    userJourneyActions.setFilter(filterParamsData?.filters)
                );
                data = get(response, "data.data", []);
            }
        } else {
            var finalData = {
                data: listingData,
                metadata: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        // data = [];
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.USER_JOURNEY} or try setting a different date range.`;
            dispatch(userJourneySlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data?.length) {
            var errorMessage = `${MESSAGES.TABLE.userJourneyNoData}`;
            dispatch(userJourneySlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/userJourney/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.userJourney.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.DTO.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const userJourneySlice = createSlice({
    name: "userJourney",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setDateRange(state, action) {
            state.pagination.date = action.payload;
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setTableData(state, action) {
            state.list = action.payload;
            state.tableError = MESSAGES.TABLE.userJourneyNoData;
        },
        setUser(state, action) {
            state.selectedUser = action.payload;
        },
        setTagsFilterData(state, action) {
            state.searchFilter.filterApplied = action.payload;
        },
        setUserJourneyListData(state, action) {
            state.list = action.payload;
            state.tableError = MESSAGES.TABLE.userJourneyNoData;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUserJourneyDetails.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchUserJourneyDetails.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            let userJourneyData = mapUserJourneyListingData(
                get(action.payload, "data", [])
            );
            state.list = userJourneyData;
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchUserJourneyDetails.rejected, (state, action) => {
            state.loading = false;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const userJourneyActions = userJourneySlice.actions;
export default userJourneySlice.reducer;
