import { Link, useHistory } from "react-router-dom";
import s from "./LinkRenderer.module.scss";

const LinkRenderer = ({ link, text, state, type, target, callBack = null }) => {
    return (
        <>
            {link && text && (
                <Link
                    target={target}
                    to={link}
                    className={`linkRenderer__link ${
                        type == "number" ? "numRender__common" : ""
                    }`}
                    onClick={callBack}
                >
                    {text}
                </Link>
            )}
        </>
    );
};

export default LinkRenderer;
