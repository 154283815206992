import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router";
import SelectedList from "../SelectedList/SelectedList";
import styles from "./TargettingParams.module.scss";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import TargettingParamsWrapperComp from "../../Shared/TargettingParamsWrapperComp/TargettingParamsWrapperComp";
import InputList from "../InputList/InputList";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import Icon from "../../Shared/Icon/Icon";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import useOutsideAlerter from "../../Shared/hooks/use-outside-alerter";
import Popup from "../../Shared/Popup/Popup";
import Input from "../../Shared/Input/Input";
import Select from "../../Shared/Select/Select";
import {
    ALLOWEDDAYSLIST,
    BROWSERSLIST,
    COUNTRIESLIST,
    DEVICESLIST,
    OPERATINGSYSTEMLIST,
    REGIONSLIST,
    KEYWORDSDROPDOWN,
    ABTESTDROPDOWN,
    UTMSOURCESDROPDOWNOPTIONS,
    USERSTATUSDROPDOWNOPTIONS,
    KEYWORDCATEGORIESDROPDOWNOPTIONS,
    CITYDROPDOWNOPTIONS,
} from "../../constants/targettingParamsConstants";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch, useSelector } from "react-redux";
import { get, clone, update, cloneDeep } from "lodash";
import TimeOfDay from "../TimeOfDay/TimeOfDay";
import {
    multiInputDataRefactorFromApi,
    multiInputDataRefactorToApi,
    inputDropdownDataRefactorFromApi,
    inputDropdownDataRefactorToApi,
    keyValsFromApi,
    keyValsToApi,
    getRandomId,
} from "../../utils/globalUtils";
import FeatureCopyToolbar from "../../components/FeatureCopyToolbar/FeatureCopyToolbar";

const TargettingParams = ({
    numberOfSets,
    targetParams = {},
    dropDownState = {},
    zipCodeState = {},
    clientIPsState = {},
    actions,
    pageId = null,
    hash = "",
    isExclusion = false,
    showBorderBottom = false,
    surveyList = [],
    readOnly = false,
    featureCopyData,
    spamDropdownApiData = [],
    keyValuesNewType = false,
}) => {
    const dispatch = useDispatch();
    const urlParams = useParams();
    // const [isTPAddedOnce, setIsTPAddedOnce] = useState(false);
    const [isTPAddedOnce, setIsTPAddedOnce] = useState({
        utmSources: false,
        keywords: false,
        ageRange: false,
        utmCampaigns: false,
        utmContents: false,
        utmMediums: false,
        utmTerms: false,
        publishers: false,
        jobTypes: false,
        ABTests: false,
        keyValues: false,
        surveyId: false,
        clientIPs: false,
        landingKeywords: false,
        landingJobTypes: false,
        visitCount: false,
        registeredVisitCount: false,
    });

    const utmSourcesRef = useRef(null);
    const SpamIpRef = useRef(null);
    const regionRef = useRef(null);
    const keywordsRef = useRef(null);
    const ageRangeRef = useRef(null);
    const gendersRef = useRef(null);
    const countriesRef = useRef(null);
    const zipCodesRef = useRef(null);
    const timeOfDayRef = useRef(null);
    const utmCampaignsRef = useRef(null);
    const utmContentsRef = useRef(null);
    const utmMediumsRef = useRef(null);
    const utmTermsRef = useRef(null);
    const publishersRef = useRef(null);
    const jobTypesRef = useRef(null);
    const duplicateUserRef = useRef(null);
    const operatingSystemsRef = useRef(null);
    const browsersRef = useRef(null);
    const devicesRef = useRef(null);
    const allowedDaysRef = useRef(null);
    const ABTestsRef = useRef(null);
    const keyValuesRef = useRef(null);
    const surveyIdRef = useRef(null);
    const clientIPRef = useRef(null);
    const landingKeywordsRef = useRef(null);
    const landingJobTypesRef = useRef(null);
    const visitCountRef = useRef(null);
    const registeredVisitCountRef = useRef(null);
    const userStatusRef = useRef(null);
    const keywordCategoriesRef = useRef(null);
    const cityRef = useRef(null);

    const { pathname } = useLocation();

    const { availableSet = [], genders = [] } = targetParams;

    const [dropdownDefaultState, setDropdownDefaultState] = useState("");

    // const surveyList = useSelector(
    //     state => state.listings.abtests.createAbTest.variants.surveyList
    // );

    const style = {
        color: "#41c3a9",
    };

    const setTotalState = data => {
        dispatch(
            actions.setTargetParams({
                page: pageId,
                data,
            })
        );
    };

    const setDropDownState = data => {
        JSON.stringify(data) != JSON.stringify(dropDownState) &&
            dispatch(
                actions.setDropDownState({
                    page: pageId,
                    data,
                })
            );
    };

    const addItemToToast = item =>
        dispatch(toastListActions.setToastList({ ...item }));

    const removeSet = id => {
        let availableSetClone = [...availableSet];
        const index = availableSetClone.indexOf(id);
        if (index > -1) {
            availableSetClone.splice(index, 1);
            setIsTPAddedOnce({ ...isTPAddedOnce, [id]: false });
            if (id === "ABTests") {
                setTotalState({
                    ...targetParams,
                    availableSet: availableSetClone,
                    keyVals: onResetAbTest(),
                });
            } else if (id === "keyValues") {
                setTotalState({
                    ...targetParams,
                    availableSet: availableSetClone,
                    keyVals: onResetKeyValues(),
                });
            } else if (id === "surveyId") {
                setTotalState({
                    ...targetParams,
                    availableSet: availableSetClone,
                    keyVals: onResetSurveyId(),
                });
            } else if (id === "spamCheck") {
                setTotalState({
                    ...targetParams,
                    availableSet: availableSetClone,
                    spamCheck: {
                        spamIP: false,
                        category: [],
                    },
                });
            } else {
                setTotalState({
                    ...targetParams,
                    availableSet: availableSetClone,
                    [id]: [],
                });
            }
        }
    };

    const addSet = set => {
        let availableSetClone = [...availableSet];
        const index = availableSet.indexOf(set);
        if (index == -1) {
            availableSetClone = [...availableSet, set];
        } else {
            availableSetClone = [...availableSet];
        }
        setTotalState({ ...targetParams, availableSet: availableSetClone });
        // setIsTPAddedOnce(true);
        let key = set;
        setIsTPAddedOnce({ ...isTPAddedOnce, [key]: !isTPAddedOnce.set });
        setTimeout(() => {
            getSetElement(set);
            setIsTPAddedOnce({ ...isTPAddedOnce, [key]: false });
            // setIsTPAddedOnce(false);
        }, 100);
    };

    const getSetElement = set => {
        switch (set) {
            case "utmSources":
                scrollToPosition(utmSourcesRef);
                break;
            case "spamCheck":
                scrollToPosition(SpamIpRef);
                break;
            case "region":
                scrollToPosition(regionRef);
                break;
            case "keywords":
                scrollToPosition(keywordsRef);
                break;
            case "userStatus":
                scrollToPosition(userStatusRef);
                break;
            case "keywordCategories":
                scrollToPosition(keywordCategoriesRef);
                break;
            case "city":
                scrollToPosition(cityRef);
                break;
            case "ageRange":
                scrollToPosition(ageRangeRef);
                break;
            case "genders":
                scrollToPosition(gendersRef);
                break;
            case "countries":
                scrollToPosition(countriesRef);
                break;
            case "zipCodes":
                scrollToPosition(zipCodesRef);
                break;
            case "timeOfDay":
                scrollToPosition(timeOfDayRef);
                break;
            case "utmCampaigns":
                scrollToPosition(utmCampaignsRef);
                break;
            case "utmContents":
                scrollToPosition(utmContentsRef);
                break;
            case "utmMediums":
                scrollToPosition(utmMediumsRef);
                break;
            case "utmTerms":
                scrollToPosition(utmTermsRef);
                break;
            case "publishers":
                scrollToPosition(publishersRef);
                break;
            case "jobTypes":
                scrollToPosition(jobTypesRef);
                break;
            case "duplicateUser":
                scrollToPosition(duplicateUserRef);
                break;
            case "os":
                scrollToPosition(operatingSystemsRef);
                break;
            case "browsers":
                scrollToPosition(browsersRef);
                break;
            case "devices":
                scrollToPosition(devicesRef);
                break;
            case "allowedDays":
                scrollToPosition(allowedDaysRef);
                break;
            case "ABTests":
                scrollToPosition(ABTestsRef);
                break;
            case "keyValues":
                scrollToPosition(keyValuesRef);
                break;
            case "surveyId":
                scrollToPosition(surveyIdRef);
                break;
            case "clientIPs":
                scrollToPosition(clientIPRef);
                break;
            case "landingKeywords":
                scrollToPosition(landingKeywordsRef);
                break;
            case "landingJobTypes":
                scrollToPosition(landingJobTypesRef);
                break;
            case "visitCount":
                scrollToPosition(visitCountRef);
                break;
            case "registeredVisitCount":
                scrollToPosition(registeredVisitCountRef);
                break;

            default:
                break;
        }
    };

    const scrollToPosition = elemenRef => {
        // elemenRef.current.scrollIntoView({
        //   behavior: "smooth",
        // });
        let headerOffset = 241;
        let elementPosition =
            elemenRef &&
            elemenRef.current &&
            elemenRef.current.getBoundingClientRect().top;
        let offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
        });

        // elemenRef.current.classList.add("addFocus")
        // setTimeout(() => {
        //   elemenRef.current.classList.remove("addFocus");
        // }, 2000);
    };

    const addGender = option => {
        let genderClone = [...genders];
        if (genderClone.indexOf(option) == -1) {
            genderClone = [...genderClone, option];
        } else {
            genderClone.splice(genderClone.indexOf(option), 1);
        }
        setTotalState({ ...targetParams, genders: genderClone });
    };

    const [details, setDetails] = useState();
    const [selectedListActiveItem, setSelectedListActiveItem] = useState("");

    // zipcode states
    // const [csvFile, setCsvFile] = useState();
    // const [csvFileName, setCsvFileName] = useState();
    // const [csvArray, setCsvArray] = useState([]);
    // const [zipcodeValues, setZipcodeValues] = useState([]);
    // const [zipCodeError, setZipCodeError] = useState(false);
    // const [onDropActiveZipCode, setonDropActiveZipCode] = useState(false);

    const selectAllRegions = () => {
        let copy = [];
        REGIONSLIST.map((ele, index) => {
            copy.push(ele.id);
        });
        //Remove the following condition once fixed from API
        if (pathname.includes("abtests")) {
            setTotalState({
                ...targetParams,
                regions: copy,
            });
        } else {
            setTotalState({
                ...targetParams,
                region: copy,
            });
        }
    };

    const selectAllCountries = () => {
        let copy = [];
        COUNTRIESLIST.map((ele, index) => {
            copy.push(ele.id);
        });
        setTotalState({
            ...targetParams,
            countries: copy,
        });
    };

    const selectAllBrowsers = () => {
        let copy = [];
        BROWSERSLIST.map((ele, index) => {
            copy.push(ele.id);
        });
        setTotalState({
            ...targetParams,
            browsers: copy,
        });
    };

    const selectAllOperatingSystems = () => {
        let copy = [];
        OPERATINGSYSTEMLIST.map((ele, index) => {
            copy.push(ele.id);
            // setSelectedOperatingSystems(copy);
        });
        setTotalState({
            ...targetParams,
            os: copy,
        });
    };

    const selectAllDevices = () => {
        let copy = [];
        DEVICESLIST.map((ele, index) => {
            copy.push(ele.id);
        });
        setTotalState({
            ...targetParams,
            devices: copy,
        });
    };

    const selectAllAllowedDays = () => {
        let copy = [];
        ALLOWEDDAYSLIST.map((ele, index) => {
            copy.push(ele.id);
        });
        setTotalState({
            ...targetParams,
            // selectedAllowedDays: copy,
            allowedDays: copy,
        });
    };

    const onResetAbTest = () => {
        let keyValsWithoutAbtest = targetParams.keyVals.filter(
            item => item.split(":")[0].indexOf("abt") == -1
        );
        return keyValsWithoutAbtest;
    };

    const onResetKeyValues = () => {
        let keyValuesResultsData = targetParams.keyVals.filter(
            item =>
                item.split(":")[0].indexOf("abt") != -1 ||
                item.split(":")[0].indexOf("surveyId") != -1
        );
        return keyValuesResultsData;
    };

    const onResetSurveyId = () => {
        let keyValsWithoutSurvey = targetParams.keyVals.filter(
            item => item.split(":")[0] != "surveyId"
        );
        return keyValsWithoutSurvey;
    };

    const onResetRegions = () => {
        //Remove the following condition once fixed from API
        if (pathname.includes("abtests")) {
            setTotalState({
                ...targetParams,
                regions: [],
            });
        } else {
            setTotalState({
                ...targetParams,
                region: [],
            });
        }
        setDetails(null);
    };

    const onResetBrowsers = () => {
        setTotalState({
            ...targetParams,
            browsers: [],
        });
        setDetails(null);
    };

    const onResetCountries = () => {
        setTotalState({
            ...targetParams,
            countries: [],
        });
        setDetails(null);
    };

    const onResetOperatingSystems = () => {
        setTotalState({
            ...targetParams,
            os: [],
        });
        setDetails(null);
    };

    const onResetDevices = () => {
        setTotalState({
            ...targetParams,
            devices: [],
        });
        setDetails(null);
    };

    const onResetAllowedDays = () => {
        setTotalState({
            ...targetParams,
            // selectedAllowedDays: [],
            allowedDays: [],
        });
        setDetails(null);
    };

    const onResetZipCode = () => {
        // setCsvFile();
        // setCsvFileName();
        // setCsvArray([]);
        // setZipcodeValues([]);
        // setZipCodeError(false);
        // setonDropActiveZipCode(false);
        dispatch(
            actions.setCsvFile({
                page: pageId,
                data: "",
            })
        );
        dispatch(
            actions.setCsvFileName({
                page: pageId,
                data: "",
            })
        );
        dispatch(
            actions.setCsvArray({
                page: pageId,
                data: [],
            })
        );
        dispatch(
            actions.setZipCodeError({
                page: pageId,
                data: false,
            })
        );
        dispatch(
            actions.setOnDropActiveZipCode({
                page: pageId,
                data: false,
            })
        );
        setTotalState({
            ...targetParams,
            zipCodes: [],
        });
    };

    const onSelectRegions = idx => {
        let selectedCopy = [];
        //Remove the following condition once fixed from API
        if (pathname.includes("abtests"))
            selectedCopy = Object.assign([], get(targetParams, "regions", []));
        else selectedCopy = Object.assign([], get(targetParams, "region", []));
        if (selectedCopy.indexOf(idx) == -1) selectedCopy.unshift(idx);
        else selectedCopy = selectedCopy.filter(s => s != idx);
        //Remove the following condition once fixed from API
        if (pathname.includes("abtests")) {
            setTotalState({
                ...targetParams,
                regions: selectedCopy,
            });
        } else {
            setTotalState({
                ...targetParams,
                region: selectedCopy,
            });
        }
    };

    const onSelectBrowsers = idx => {
        let selectedCopy = Object.assign([], get(targetParams, "browsers", []));
        if (selectedCopy.indexOf(idx) == -1) selectedCopy.unshift(idx);
        else selectedCopy = selectedCopy.filter(s => s != idx);
        setTotalState({
            ...targetParams,
            browsers: selectedCopy,
        });
    };

    const onSelectDevices = idx => {
        let selectedCopy = Object.assign([], get(targetParams, "devices", []));
        if (selectedCopy.indexOf(idx) == -1) selectedCopy.unshift(idx);
        else selectedCopy = selectedCopy.filter(s => s != idx);
        setTotalState({
            ...targetParams,
            devices: selectedCopy,
        });
    };

    const onSelectCountries = idx => {
        let selectedCopy = Object.assign(
            [],
            get(targetParams, "countries", [])
        );
        if (selectedCopy.indexOf(idx) == -1) selectedCopy.unshift(idx);
        else selectedCopy = selectedCopy.filter(s => s != idx);
        setTotalState({
            ...targetParams,
            countries: selectedCopy,
        });
    };

    const onSelectOperatingSystems = idx => {
        let selectedCopy = Object.assign([], get(targetParams, "os", []));
        if (selectedCopy.indexOf(idx) == -1) selectedCopy.unshift(idx);
        else selectedCopy = selectedCopy.filter(s => s != idx);
        setTotalState({
            ...targetParams,
            os: selectedCopy,
        });
    };

    const onSelectAllowedDays = idx => {
        let selectedCopy = Object.assign(
            [],
            // get(targetParams, "selectedAllowedDays", [])
            get(targetParams, "allowedDays", [])
        );
        if (selectedCopy.indexOf(idx) == -1) selectedCopy.unshift(idx);
        else selectedCopy = selectedCopy.filter(s => s != idx);

        let days = [
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
        ];
        let newArray = [];
        let newIndex = selectedCopy.map((item, id) => {
            return days.indexOf(item);
        });
        newIndex.sort(function (a, b) {
            return a - b;
        });
        newIndex.forEach(item => {
            newArray.push(days[item]);
        });
        setTotalState({
            ...targetParams,
            // selectedAllowedDays: selectedCopy,
            allowedDays: newArray,
        });
    };

    const onRowDeleteList = (
        e,
        idx,
        dataIndex,
        selectedListActiveItem,
        keyName,
        selectedData,
        setACtiveItem,
        listName
    ) => {
        e.stopPropagation();
        if (idx == selectedListActiveItem) {
            let currentIndex;
            for (let index = 0; index < selectedData.length; index++) {
                if (selectedData[index][keyName] == selectedListActiveItem) {
                    currentIndex = index;
                    break;
                }
            }

            let activeItem =
                currentIndex < selectedData.length - 1
                    ? currentIndex + 1
                    : currentIndex - 1;

            if (activeItem == -1) {
                resetList(listName);
                setACtiveItem("");
            } else {
                // console.log("false");
                setDetails(selectedData[activeItem][keyName]);
                setACtiveItem(selectedData[activeItem][keyName]);
            }
        }

        setSelectedList(listName, idx);
    };

    const setSelectedList = (listName, idx) => {
        switch (listName.toLowerCase()) {
            case "regions":
                //Remove the following condition once fixed from API
                if (pathname.includes("abtests")) {
                    return setTotalState({
                        ...targetParams,
                        regions: get(targetParams, "regions", []).filter(
                            s => s != idx
                        ),
                    });
                } else {
                    return setTotalState({
                        ...targetParams,
                        region: get(targetParams, "region", []).filter(
                            s => s != idx
                        ),
                    });
                }
            case "browsers":
                return setTotalState({
                    ...targetParams,
                    browsers: get(targetParams, "browsers", []).filter(
                        s => s != idx
                    ),
                });
            case "devices":
                return setTotalState({
                    ...targetParams,
                    devices: get(targetParams, "devices", []).filter(
                        s => s != idx
                    ),
                });
            case "allowed days":
                return setTotalState({
                    ...targetParams,
                    // selectedAllowedDays: get(
                    //   targetParams,
                    //   "selectedAllowedDays",
                    //   []
                    // ).filter((s) => s != idx),
                    allowedDays: get(targetParams, "allowedDays", []).filter(
                        s => s != idx
                    ),
                });
            case "countries":
                return setTotalState({
                    ...targetParams,
                    countries: get(targetParams, "countries", []).filter(
                        s => s != idx
                    ),
                });
            case "os":
                return setTotalState({
                    ...targetParams,
                    os: get(targetParams, "os", []).filter(s => s != idx),
                });
            default:
                break;
        }
    };

    const resetList = listName => {
        switch (listName) {
            case "region":
                return onResetRegions();
            case "browsers":
                return onResetBrowsers();
            case "devices":
                return onResetDevices();
            case "allowedDays":
                return onResetAllowedDays();
            case "countries":
                return onResetCountries();
            case "os":
                return onResetOperatingSystems();
            case "utmSources":
                return setTotalState({
                    ...targetParams,
                    utmSources: [],
                });
            case "userStatus":
                return setTotalState({
                    ...targetParams,
                    userStatus: [],
                });
            case "keywordCategories":
                return setTotalState({
                    ...targetParams,
                    keywordCategories: [],
                });
            case "city":
                return setTotalState({
                    ...targetParams,
                    city: [],
                });
            case "clientIPs":
                return setTotalState({
                    ...targetParams,
                    clientIPs: [],
                });
            case "keywords":
                // return setKeywordsValues([]);
                return setTotalState({
                    ...targetParams,
                    keywords: [],
                });
            case "ageRange":
                // return setAgeRanges([]);
                return setTotalState({
                    ...targetParams,
                    ageRange: [],
                });
            case "genders":
                // return setGender([]);
                return setTotalState({
                    ...targetParams,
                    genders: [],
                });
            case "zipCodes":
                return onResetZipCode();
            case "timeOfDay":
                // return setTimeRangeValues([]);
                return setTotalState({
                    ...targetParams,
                    timeOfDay: [],
                });
            case "utmCampaigns":
                // return setUtmCampaigns([]);
                return setTotalState({
                    ...targetParams,
                    utmCampaigns: [],
                });
            case "utmContents":
                return setTotalState({
                    ...targetParams,
                    utmContents: [],
                });
            case "utmMediums":
                // return setUtmMediums([]);
                return setTotalState({
                    ...targetParams,
                    utmMediums: [],
                });
            case "publishers":
                return setTotalState({
                    ...targetParams,
                    publishers: [],
                });
            case "jobTypes":
                return setTotalState({
                    ...targetParams,
                    jobTypes: [],
                });
            case "duplicateUser":
                return setTotalState({
                    ...targetParams,
                    duplicateUser: [],
                });
            case "utmTerms":
                return setTotalState({
                    ...targetParams,
                    utmTerms: [],
                });
            case "ABTests":
                return setTotalState({
                    ...targetParams,
                    keyVals: onResetAbTest(),
                });
            case "keyValues":
                return setTotalState({
                    ...targetParams,
                    keyVals: onResetKeyValues(),
                });
            case "surveyId":
                return setTotalState({
                    ...targetParams,
                    keyVals: onResetSurveyId(),
                });
            case "landingJobTypes":
                return setTotalState({
                    ...targetParams,
                    landingJobTypes: [],
                });
            case "landingKeywords":
                return setTotalState({
                    ...targetParams,
                    landingKeywords: [],
                });
            case "spamCheck":
                return setTotalState({
                    ...targetParams,
                    spamCheck: {
                        spamIP: false,
                        category: [],
                    },
                });

            default:
                break;
        }
    };

    const onSelectUtmSources = data => {
        setTotalState({
            ...targetParams,
            utmSources: data,
        });
    };

    const onSelectSpamIp = data => {
        if (typeof data === "boolean") {
            setTotalState({
                ...targetParams,
                spamCheck: {
                    ...targetParams.spamCheck,
                    spamIP: data,
                },
            });
        } else {
            setTotalState({
                ...targetParams,
                spamCheck: {
                    ...targetParams.spamCheck,
                    category: data,
                },
            });
        }
    };

    const onSelectUserStatusDropdown = data => {
        setTotalState({
            ...targetParams,
            userStatus: data,
        });
    };

    const onSelectKeywordCategoriesDropdown = data => {
        setTotalState({
            ...targetParams,
            keywordCategories: data,
        });
    };

    const onSelectCityDropdown = data => {
        setTotalState({
            ...targetParams,
            city: data,
        });
    };

    const onSelectRangeParams = (data, param) => {
        setTotalState({
            ...targetParams,
            [param]: multiInputDataRefactorToApi(data),
        });
    };

    const onSelectUtmCampaigns = data => {
        setTotalState({
            ...targetParams,
            utmCampaigns: data,
        });
    };

    const onSelectUtmContent = data => {
        setTotalState({
            ...targetParams,
            utmContents: data,
        });
    };

    const onSelectUtmMediums = data => {
        setTotalState({
            ...targetParams,
            utmMediums: data,
        });
    };

    const onSelectPublishers = data => {
        setTotalState({
            ...targetParams,
            publishers: data,
        });
    };

    const onSelectJobTypes = data => {
        setTotalState({
            ...targetParams,
            jobTypes: data,
        });
    };

    const onSelectLandingJobTypes = data => {
        setTotalState({
            ...targetParams,
            landingJobTypes: data,
        });
    };

    const onSelectLandingKeywords = data => {
        setTotalState({
            ...targetParams,
            landingKeywords: data,
        });
    };

    const onSelectDuplicateUser = data => {
        setTotalState({
            ...targetParams,
            duplicateUser: data,
        });
    };

    const onSelectUtmTerms = data => {
        setTotalState({
            ...targetParams,
            utmTerms: data,
        });
    };

    const onSelectKeywordsValues = data => {
        setTotalState({
            ...targetParams,
            keywords: inputDropdownDataRefactorToApi(data),
        });
    };

    const onSelectTimeOfDay = data => {
        let cloneTimeOfDay = cloneDeep(data);
        cloneTimeOfDay.forEach(val => {
            if (val.startTimeIsPm) {
                val.startTime =
                    Number(val.startTime.split(":")[0]) +
                    12 +
                    `:${val.startTime.split(":")[1]}`;
            }
            if (val.endTimeIsPm) {
                val.endTime =
                    Number(val.endTime.split(":")[0]) +
                    12 +
                    `:${val.endTime.split(":")[1]}`;
            }
            delete val.startTimeIsPm;
            delete val.endTimeIsPm;
        });
        setTotalState({
            ...targetParams,
            timeOfDay: cloneTimeOfDay,
        });
    };

    const convertTimeInto12HourFormat = val => {
        let clonedValue = cloneDeep(val);
        clonedValue.forEach(elem => {
            if (Number(elem.startTime.split(":")[0]) > 12) {
                elem.startTime =
                    Number(elem.startTime.split(":")[0]) -
                    12 +
                    `:${elem.startTime.split(":")[1]}`;
                elem.startTimeIsPm = true;
            } else {
                elem.startTimeIsPm = false;
            }
            if (Number(elem.endTime.split(":")[0]) > 12) {
                elem.endTime =
                    Number(elem.endTime.split(":")[0]) -
                    12 +
                    `:${elem.endTime.split(":")[1]}`;
                elem.endTimeIsPm = true;
            } else {
                elem.endTimeIsPm = false;
            }
        });
        return clonedValue;
    };

    const onSelectABTest = data => {
        let convertedData = keyValsToApi(data, keyValuesNewType, () => {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Same value already exist with same key name",
                })
            );
        });

        let apiData = targetParams.keyVals;
        let updatedData = [];

        for (let i = 0; i < convertedData.length; i++) {
            if (updatedData.indexOf(convertedData[i]) == -1)
                updatedData.push(convertedData[i]);
        }

        if (apiData?.length) {
            for (let i = 0; i < apiData.length; i++) {
                let newElement = apiData[i].toString().split(":");
                if (newElement[0].indexOf("abt") == -1) {
                    updatedData.push(apiData[i]);
                }
            }
        }

        // pathname.includes("abtests") ?
        // setTotalState({
        //   ...targetParams,
        //   keyVal: updatedData,
        // }) :
        // setTotalState({
        //   ...targetParams,
        //   keyVals: updatedData,
        // });

        setTotalState({
            ...targetParams,
            keyVals: updatedData,
        });
    };

    const onSelectUserStatus = data => {
        let convertedData = keyValsToApi(data);

        let apiData = targetParams.keyVals;
        let updatedData = [];
        for (let i = 0; i < convertedData.length; i++) {
            if (updatedData.indexOf(convertedData[i]) == -1)
                updatedData.push(convertedData[i]);
        }
        for (let i = 0; i < apiData.length; i++) {
            let newElement = apiData[i].toString().split(":");
            if (newElement[0].indexOf("abt") == -1) {
                updatedData.push(apiData[i]);
            }
        }

        setTotalState({
            ...targetParams,
            keyVals: updatedData,
        });
    };

    const onSelectKWCategory = data => {
        let convertedData = keyValsToApi(data);

        let apiData = targetParams.keyVals;
        let updatedData = [];
        for (let i = 0; i < convertedData.length; i++) {
            if (updatedData.indexOf(convertedData[i]) == -1)
                updatedData.push(convertedData[i]);
        }
        if (apiData?.length) {
            for (let i = 0; i < apiData.length; i++) {
                let newElement = apiData[i].toString().split(":");
                if (newElement[0].indexOf("abt") == -1) {
                    updatedData.push(apiData[i]);
                }
            }
        }

        setTotalState({
            ...targetParams,
            keyVals: updatedData,
        });
    };

    const onSelectkeyVals = data => {
        let convertedData = keyValsToApi(data, keyValuesNewType, () => {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Same value already exist with same key name",
                })
            );
        });
        let apiData = targetParams.keyVals;
        let updatedData = [];
        for (let i = 0; i < convertedData.length; i++) {
            if (updatedData.indexOf(convertedData[i]) == -1)
                updatedData.push(convertedData[i]);
        }

        if (apiData?.length) {
            for (let i = 0; i < apiData.length; i++) {
                let newElement = apiData[i].toString().split(":");
                if (
                    newElement[0].indexOf("abt") > -1 ||
                    newElement[0].indexOf("surveyId") > -1
                ) {
                    updatedData.push(apiData[i]);
                }
            }
        }

        // return pathname.includes("abtests") ?
        // setTotalState({
        //   ...targetParams,
        //   keyVal: updatedData,
        // }) :
        // setTotalState({
        //   ...targetParams,
        //   keyVals: updatedData,
        // });
        setTotalState({
            ...targetParams,
            keyVals: updatedData,
        });
    };

    const onSelectSurvey = data => {
        let updatedData = clone(targetParams.keyVals);
        let exists = updatedData.findIndex(
            item => item.split(":")[0] === "surveyId"
        );
        let selectedSurveyValues = data.map(item => item.id).toString();

        if (exists > -1 && selectedSurveyValues) {
            updatedData[exists] = "surveyId:" + selectedSurveyValues;
        } else if (selectedSurveyValues) {
            updatedData.push("surveyId:" + selectedSurveyValues);
        } else {
            if (exists > -1) updatedData.splice(exists, 1);
        }

        setTotalState({
            ...targetParams,
            keyVals: updatedData,
        });
    };

    const selectedSurveyIds = () => {
        if (targetParams.keyVals) {
            let surveyItem = targetParams.keyVals.find(
                item => item.split(":")[0] == "surveyId"
            );
            if (surveyItem) {
                let temp = surveyItem.split(":")[1].split(",");
                return temp?.map(item => parseInt(item));
            } else {
                return [];
            }
        } else {
            return [];
        }
    };

    const onSelectSetCsvFileName = data => {
        dispatch(
            actions.setCsvFileName({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetCsvFile = data => {
        dispatch(
            actions.setCsvFile({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetCsvArray = data => {
        dispatch(
            actions.setCsvArray({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetZipCodeError = data => {
        dispatch(
            actions.setZipCodeError({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetonDropActiveZipCode = data => {
        dispatch(
            actions.setOnDropActiveZipCode({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetZipcodeValues = data => {
        let updatedData = data.map(i => Number(i));
        setTotalState({
            ...targetParams,
            zipCodes: updatedData,
        });
    };

    const onSelectSetClientIPsError = data => {
        dispatch(
            actions.setClientIPsError({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetonDropActiveClientIPs = data => {
        dispatch(
            actions.setOnDropActiveClientIPs({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetClientIPsValues = data => {
        setTotalState({
            ...targetParams,
            clientIPs: data,
        });
    };

    const onSelectSetClientIPCsvFileName = data => {
        dispatch(
            actions.setClientIPCsvFileName({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetClientIPCsvFile = data => {
        dispatch(
            actions.setClientIPCsvFile({
                page: pageId,
                data,
            })
        );
    };

    const onSelectSetClientIPCsvArray = data => {
        dispatch(
            actions.setClientIPCsvArray({
                page: pageId,
                data,
            })
        );
    };

    const handleOptionClick = (title, e) => {
        if (title?.id) {
            setDropDownState({
                ...dropDownState,
                plusDropdownData: get(title, "firstName", ""),
            });
            addSet(get(title, "id", ""));
        }
    };

    useEffect(() => {
        const productListPlusDataCopy = numberOfSets
            ?.filter(sets => !availableSet?.includes(sets.id))
            ?.map(set => ({
                id: set.id,
                firstName: set.name,
            }));
        if (productListPlusDataCopy) {
            setDropDownState({
                ...dropDownState,
                productListPlusData: productListPlusDataCopy,
            });
        }
    }, [availableSet]);

    const showPlusDropdownHandler = () => {
        setDropDownState({ ...dropDownState, showPlusDropdown: true });
    };

    useEffect(() => {
        if (get(dropDownState, "plusDropdownData.length", 0) > 0) {
            addSet(get(dropDownState, "plusDropdownData", []));
            setDropDownState({
                ...dropDownState,
                showPlusDropdown: false,
                plusDropdownData: "",
            });
        }
    }, [get(dropDownState, "plusDropdownData", [])]);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setDropDownState({ ...dropDownState, showPlusDropdown: false });
    });

    const saveSetInputWrapper = useRef(null);
    const [saveState, setSaveState] = useState(false);
    const [openLoadDropdown, setOpenLoadDropdown] = useState(false);
    const [openLoadPopup, setOpenLoadPopup] = useState(false);
    const [openResetPopup, setOpenResetPopup] = useState(false);
    const [value, setValue] = useState("");
    const [newSelectValue, setNewSelectValue] = useState([]);
    const [isInputPopupError, setIsInputPopupError] = useState(false);

    const onOpenSaveSet = () => {
        setSaveState(true);
    };

    const onCancelSaveSet = () => {
        setSaveState(false);
        setValue("");
    };

    const onSaveInput = e => {
        e.preventDefault();
        if (newSelectValue.indexOf(value) === -1) {
            setIsInputPopupError(false);
            setNewSelectValue([...newSelectValue, value]);
            setSaveState(false);
            setValue("");
            addItemToToast({
                type: "Success",
                message: "Saved Successfully",
                autoClose: 5000,
            });
        } else {
            setIsInputPopupError(true);
        }
    };

    const loadWrapperRef = useRef(null);
    useOutsideAlerter(loadWrapperRef, () => {
        setOpenLoadDropdown(false);
    });

    const [loadedTitle, setLoadedTitle] = useState("");
    const onOpenLoadedDropdown = title => {
        setLoadedTitle(title);
        setOpenLoadDropdown(false);
        setOpenLoadPopup(true);
    };
    const onCloseLoadPopup = () => {
        setOpenLoadPopup(false);
    };

    const onCloseResetPopup = e => {
        e.stopPropagation();
        setOpenResetPopup(false);
    };

    const onSaveResetPopup = e => {
        e.stopPropagation();
        setOpenResetPopup(false);
        addItemToToast({
            type: "Error",
            message: "Reset Successful",
            autoClose: 5000,
            // autoClose: false,
        });
    };

    const onSaveLoadPopup = () => {
        setOpenLoadPopup(false);
        addItemToToast({
            type: "Success",
            message: "Loaded successfully",
            autoClose: 5000,
            // autoClose: false,
        });
    };

    const onLoadSet = () => {
        if (newSelectValue && newSelectValue.length > 0) {
            setOpenLoadDropdown(true);
        }
    };

    const onResetLoadPopup = () => {
        setOpenResetPopup(true);
    };

    const openLoadPopupMsg = (
        <p>
            Loading set <strong>{loadedTitle}</strong> will clear below tags, do
            you wish to go ahead?
        </p>
    );

    const openResetPopupMsg = (
        <p>Do you really wish to reset all the targetting param values?</p>
    );

    const loasSetTooltipMsg =
        newSelectValue && newSelectValue.length > 0
            ? "Load saved set of targeting params"
            : "Can not be load as load sets are empty";
    const validateRangeParams = (val1, val2, title) => {
        if (parseInt(val2) > parseInt(val1))
            return {
                isValid: true,
            };
        else {
            return {
                isValid: false,
                type: "toast",
                status: "error",
                text: `${title} start has to be smaller than ${title} end.`,
            };
        }
    };
    return (
        <div
            id={hash + "targettingParams"}
            data-hash-id={hash + "targettingParams"}
            className={`${styles.TargettingParamsContainer} ${
                showBorderBottom ? styles.borderBottom : ""
            }`}
        >
            {featureCopyData && (
                <FeatureCopyToolbar
                    displayText="Params"
                    featureCopyData={featureCopyData}
                    handleLoad={setTotalState}
                ></FeatureCopyToolbar>
            )}

            <div className={`flexHeightTab`}>
                <div className={`headerTitle`}>
                    {isExclusion ? "Exclusion" : "Inclusion"} Targetting Params
                </div>
                <div
                    className={`headerTitleRequired ${styles.headerTitleRequired} ${styles.loadSetContainer}`}
                >
                    Select {isExclusion ? "Exclusion " : ""}Targetting params{" "}
                    {/* <Button btnTheme="default_btn" label="Load Set" />
          <Button btnTheme="default_btn" label="Save Set" disable={true} /> */}
                    <div className={styles.loadSetLayer}>
                        <div ref={loadWrapperRef} onClick={e => onLoadSet(e)}>
                            <Tooltip message={loasSetTooltipMsg} position="top">
                                <Button
                                    className={styles.button}
                                    btnTheme={ButtonTypes.default_btn}
                                    disable={
                                        newSelectValue &&
                                        newSelectValue.length > 0
                                            ? false
                                            : true
                                    }
                                >
                                    Load Set
                                </Button>
                            </Tooltip>
                        </div>
                        {openLoadDropdown && (
                            <Select
                                placeholder="Search and select set"
                                options={newSelectValue}
                                onSelect={onOpenLoadedDropdown}
                                showSearch={true}
                                onLoadSelectReturn={false}
                                className={styles.customSelect}
                            />
                        )}
                        {openLoadPopup && (
                            <Popup
                                showHeader={false}
                                showFooter={false}
                                showConfirmBox={true}
                                saveButtonText={"Yes, Go ahead"}
                                cancelButtonText={"Cancel"}
                                closeOutside={true}
                                message={openLoadPopupMsg}
                                disableSave={false}
                                width={"332px"}
                                top={"0"}
                                left={"10px"}
                                portal={false}
                                // ref={``}
                                onClose={onCloseLoadPopup}
                                onSave={e => {
                                    onSaveLoadPopup();
                                }}
                                textAlign={"left"}
                            />
                        )}
                    </div>
                    <div className={styles.saveSetLayer}>
                        <Tooltip
                            message="Saved set of targeting params"
                            position="top"
                        >
                            <Button
                                className={styles.button}
                                btnTheme={ButtonTypes.default_btn}
                                // disable={true}
                                onClick={onOpenSaveSet}
                            >
                                Save Set
                            </Button>
                        </Tooltip>
                        {saveState && (
                            <Popup
                                showHeader={false}
                                showFooter={true}
                                // showConfirmBox={true}
                                closeOutside={true}
                                onClose={onCancelSaveSet}
                                onSave={onSaveInput}
                                disableSave={false}
                                width={"332px"}
                                // top={"0"}
                                left={"10px"}
                                bottom={"0"}
                                portal={false}
                                customClass={styles.savePopup}
                                showFooterBtnsEnd={true}
                                showWhiteFooter={true}
                            >
                                <div className={styles.innerPopUp}>
                                    <p className={"headerTitleRequired"}>
                                        Set Name
                                    </p>
                                    <form onSubmit={onSaveInput}>
                                        <Input
                                            ref={saveSetInputWrapper}
                                            placeholder="Enter set name"
                                            value={value}
                                            onChange={e =>
                                                setValue(e.target.value)
                                            }
                                            required={true}
                                        />
                                        {isInputPopupError && (
                                            <p className={styles.textError}>
                                                can not set with the existing
                                                name set
                                            </p>
                                        )}
                                    </form>
                                </div>
                            </Popup>
                        )}
                    </div>
                    <div
                        className={styles.resetSetLayer}
                        onClick={onResetLoadPopup}
                    >
                        <Tooltip
                            message="Reset set of targeting params"
                            position="top"
                        >
                            <Button
                                className={`${styles.button} ${styles.resetBtn}`}
                                btnTheme={ButtonTypes.default_btn}
                                // disable={true}
                            >
                                Reset Set
                            </Button>
                        </Tooltip>
                        {openResetPopup && (
                            <Popup
                                showHeader={false}
                                showFooter={false}
                                showConfirmBox={true}
                                saveButtonText={"Yes, Go ahead"}
                                cancelButtonText={"Cancel"}
                                closeOutside={true}
                                message={openResetPopupMsg}
                                disableSave={false}
                                width={"332px"}
                                top={"0"}
                                left={"10px"}
                                portal={false}
                                // ref={``}
                                onClose={e => onCloseResetPopup(e)}
                                onSave={e => onSaveResetPopup(e)}
                                textAlign={"left"}
                            />
                        )}
                    </div>
                </div>

                {/* <p className={`infoText`}>
          Targetting options in social media advertising are also sophisticated.
          It is possible to tailor ads around a user’s behaviors.
          <a href="#" className="">
            Learn more
          </a>
        </p> */}
                {/* {console.log(availableSet)} */}
                {availableSet && availableSet.length
                    ? availableSet.map((element, index) => {
                          let response = "";
                          const item = numberOfSets.find(
                              i => get(i, "id", "") == element
                          );

                          switch (element) {
                              case "utmSources":
                                  //   let utmSourcesInputJson = {
                                  //       activeRef: isTPAddedOnce["utmSources"]
                                  //           ? "firstRef"
                                  //           : "",
                                  //   };

                                  let utmSourcesDropdown = {
                                      className: `${styles.editDropdown}`,
                                      options: UTMSOURCESDROPDOWNOPTIONS.map(
                                          item => ({
                                              ...item,
                                              key: `${item.id} (${item.label})`,
                                              label: `${item.id}: ${item.label}`,
                                          })
                                      ),
                                      idField: "id",
                                      keyField: "label",
                                      label: "Search by Source Name or Value",
                                  };

                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          header={get(item, "name", "")}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={utmSourcesRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              title="UTMSource"
                                              type="singleDropdownCreateTags"
                                              dropDownJson={utmSourcesDropdown}
                                              mainState={get(
                                                  targetParams,
                                                  "utmSources",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectUtmSources(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "spamCheck":
                                  const spamDropdownOption =
                                      spamDropdownApiData?.map(item => ({
                                          ...item,
                                          id: item,
                                          label: item,
                                      }));

                                  const optionData =
                                      get(
                                          targetParams,
                                          "spamCheck.category",
                                          []
                                      )?.length > 0
                                          ? spamDropdownOption?.filter(
                                                ele =>
                                                    !ele.id.includes(
                                                        get(
                                                            targetParams,
                                                            "spamCheck.category",
                                                            []
                                                        )
                                                    )
                                            )
                                          : spamDropdownOption;

                                  let spamIpDropdown = {
                                      className: `${styles.editDropdown}`,
                                      options: optionData || [],
                                      idField: "id",
                                      keyField: "label",
                                      label: "Search by Categories",
                                      showCreate: false,
                                  };

                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          header={get(item, "name", "")}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={SpamIpRef}
                                          readOnly={readOnly}
                                          showToggleSwitch={true}
                                          toggleOnChange={bol => {
                                              onSelectSpamIp(bol);
                                          }}
                                          toggleSwitchState={get(
                                              targetParams,
                                              "spamCheck.spamIP",
                                              false
                                          )}
                                      >
                                          <InputList
                                              title="Spam Ip"
                                              type="singleDropdownCreateTags"
                                              dropDownJson={spamIpDropdown}
                                              mainState={get(
                                                  targetParams,
                                                  "spamCheck.category",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectSpamIp(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "region":
                              case "regions":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          className={`${styles.bodyPadding}`}
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={regionRef}
                                          readOnly={readOnly}
                                      >
                                          <SelectedList
                                              list={REGIONSLIST}
                                              keyName={"id"}
                                              valueName={"label"}
                                              selected={
                                                  pathname.includes("abtests")
                                                      ? get(
                                                            targetParams,
                                                            "regions",
                                                            []
                                                        )
                                                      : get(
                                                            targetParams,
                                                            "region",
                                                            []
                                                        )
                                              }
                                              onSelect={onSelectRegions}
                                              onRowDeleteList={onRowDeleteList}
                                              setDetails={setDetails}
                                              onReset={onResetRegions}
                                              selectListType="nonDragable"
                                              inputPlaceholder={
                                                  "Search a Region"
                                              }
                                              selectAll={selectAllRegions}
                                              type="two"
                                              // setSelected={setSelectedRegions}
                                              selectedListActiveItem={
                                                  selectedListActiveItem
                                              }
                                              setSelectedListActiveItem={
                                                  setSelectedListActiveItem
                                              }
                                              listName={"Regions"}
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;

                              case "keywords":
                                  let keyWordsDefaultState =
                                      !inputDropdownDataRefactorFromApi(
                                          get(targetParams, "keywords", [])
                                      ).length
                                          ? null
                                          : "";

                                  let keywordsDropDownJson = {
                                      className: `${styles.editDropdown}`,
                                      keyField: "label",
                                      idField: "id",
                                      position: "top",
                                      options: KEYWORDSDROPDOWN,
                                      selectedID: keyWordsDefaultState,
                                      label: "Select Match Type",
                                  };
                                  let keywordsInputJson = {
                                      activeRef: isTPAddedOnce["keywords"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          header={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={keywordsRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={keywordsInputJson}
                                              type="inputDropdown"
                                              title={"Keyword"}
                                              placeholderOne={
                                                  "Enter a Keyword*"
                                              }
                                              dropDownJson={
                                                  keywordsDropDownJson
                                              }
                                              mainState={inputDropdownDataRefactorFromApi(
                                                  get(
                                                      targetParams,
                                                      "keywords",
                                                      []
                                                  )
                                              )}
                                              setMainState={data =>
                                                  onSelectKeywordsValues(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "ageRange":
                                  let ageRangeInputJson = {
                                      type: "number",
                                      className: "label",
                                      secondInputType: "number",
                                      maxCharacter: 3,
                                      activeRef: isTPAddedOnce["ageRange"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          header={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={ageRangeRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={ageRangeInputJson}
                                              type="multipleInput"
                                              title="Age Range"
                                              placeholderOne={"Range start"}
                                              placeholderTwo={"Range end"}
                                              customValidation={
                                                  validateRangeParams
                                              }
                                              // mainState={["18-65", "1-4"]}
                                              mainState={multiInputDataRefactorFromApi(
                                                  get(
                                                      targetParams,
                                                      "ageRange",
                                                      []
                                                  )
                                              )}
                                              setMainState={data =>
                                                  onSelectRangeParams(
                                                      data,
                                                      "ageRange"
                                                  )
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;

                              case "visitCount":
                                  let visitCountInputJson = {
                                      type: "number",
                                      className: "label",
                                      secondInputType: "number",
                                      maxCharacter: 3,
                                      activeRef: isTPAddedOnce["visitCount"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          header={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={visitCountRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={visitCountInputJson}
                                              type="multipleInput"
                                              title="Visit Count"
                                              placeholderOne={"Range start"}
                                              placeholderTwo={"Range end"}
                                              customValidation={
                                                  validateRangeParams
                                              }
                                              mainState={multiInputDataRefactorFromApi(
                                                  get(
                                                      targetParams,
                                                      "visitCount",
                                                      []
                                                  )
                                              )}
                                              setMainState={data =>
                                                  onSelectRangeParams(
                                                      data,
                                                      "visitCount"
                                                  )
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;

                              case "registeredVisitCount":
                                  let registeredVisitCountInputJson = {
                                      type: "number",
                                      className: "label",
                                      secondInputType: "number",
                                      maxCharacter: 3,
                                      activeRef: isTPAddedOnce[
                                          "registeredVisitCount"
                                      ]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          header={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={registeredVisitCountRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={
                                                  registeredVisitCountInputJson
                                              }
                                              type="multipleInput"
                                              title="Registered Visit Count"
                                              placeholderOne={"Range start"}
                                              placeholderTwo={"Range end"}
                                              customValidation={
                                                  validateRangeParams
                                              }
                                              mainState={multiInputDataRefactorFromApi(
                                                  get(
                                                      targetParams,
                                                      "registeredVisitCount",
                                                      []
                                                  )
                                              )}
                                              setMainState={data =>
                                                  onSelectRangeParams(
                                                      data,
                                                      "registeredVisitCount"
                                                  )
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;

                              case "genders":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={gendersRef}
                                          readOnly={readOnly}
                                      >
                                          <div
                                              className={`dp-parent ${styles.CheckboxContainer}`}
                                          >
                                              <div
                                                  className={
                                                      styles.CheckboxWrapper
                                                  }
                                              >
                                                  <Checkbox
                                                      labelText="Male"
                                                      onChange={() => {
                                                          addGender("1");
                                                      }}
                                                      isChecked={
                                                          get(
                                                              targetParams,
                                                              "genders",
                                                              []
                                                          ).indexOf("1") !== -1
                                                      }
                                                      disabled={readOnly}
                                                  />
                                              </div>
                                              <div
                                                  className={
                                                      styles.CheckboxWrapper
                                                  }
                                              >
                                                  <Checkbox
                                                      labelText="Female"
                                                      onChange={() => {
                                                          addGender("2");
                                                      }}
                                                      isChecked={
                                                          get(
                                                              targetParams,
                                                              "genders",
                                                              []
                                                          ).indexOf("2") !== -1
                                                      }
                                                      disabled={readOnly}
                                                  />
                                              </div>
                                              <div
                                                  className={
                                                      styles.CheckboxWrapper
                                                  }
                                              >
                                                  <Checkbox
                                                      labelText="Others"
                                                      onChange={() => {
                                                          addGender("3");
                                                      }}
                                                      isChecked={
                                                          get(
                                                              targetParams,
                                                              "genders",
                                                              []
                                                          ).indexOf("3") !== -1
                                                      }
                                                      disabled={readOnly}
                                                  />
                                              </div>
                                          </div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "countries":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          className={`${styles.bodyPadding}`}
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={countriesRef}
                                          readOnly={readOnly}
                                      >
                                          <SelectedList
                                              list={COUNTRIESLIST}
                                              keyName={"id"}
                                              valueName={"label"}
                                              selected={get(
                                                  targetParams,
                                                  "countries",
                                                  []
                                              )}
                                              onSelect={onSelectCountries}
                                              onRowDeleteList={onRowDeleteList}
                                              setDetails={setDetails}
                                              onReset={onResetCountries}
                                              selectListType="nonDragable"
                                              inputPlaceholder={
                                                  "Search a Country"
                                              }
                                              selectAll={selectAllCountries}
                                              // setSelected={setSelectedCountries}
                                              selectedListActiveItem={
                                                  selectedListActiveItem
                                              }
                                              setSelectedListActiveItem={
                                                  setSelectedListActiveItem
                                              }
                                              listName={"Countries"}
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "zipCodes":
                                  let zipCodeInputJson = {
                                      type: "text",
                                      className: "label",
                                      secondInputType: "number",
                                      maxCharacter: 5,
                                      minCharacter: 5,
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={zipCodesRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              type="ZipCodeType"
                                              title="Zip Code"
                                              placeholderOne={"Enter Zip Code*"}
                                              defaultValue={[
                                                  "400041",
                                                  "300049",
                                              ]}
                                              inputJson={zipCodeInputJson}
                                              csvFileName={get(
                                                  zipCodeState,
                                                  "csvFileName",
                                                  ""
                                              )}
                                              setCsvFileName={data =>
                                                  onSelectSetCsvFileName(data)
                                              }
                                              csvFile={get(
                                                  zipCodeState,
                                                  "csvFile",
                                                  ""
                                              )}
                                              setCsvFile={data =>
                                                  onSelectSetCsvFile(data)
                                              }
                                              csvArray={get(
                                                  zipCodeState,
                                                  "csvArray",
                                                  ""
                                              )}
                                              setCsvArray={data =>
                                                  onSelectSetCsvArray(data)
                                              }
                                              error={get(
                                                  zipCodeState,
                                                  "zipCodeError",
                                                  ""
                                              )}
                                              setError={data =>
                                                  onSelectSetZipCodeError(data)
                                              }
                                              onDropActiveZipCode={get(
                                                  zipCodeState,
                                                  "onDropActiveZipCode",
                                                  ""
                                              )}
                                              setonDropActiveZipCode={data =>
                                                  onSelectSetonDropActiveZipCode(
                                                      data
                                                  )
                                              }
                                              mainState={get(
                                                  targetParams,
                                                  "zipCodes",
                                                  ""
                                              )}
                                              setMainState={data =>
                                                  onSelectSetZipcodeValues(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "timeOfDay":
                                  // const timeOfDay = get(targetParams, "timeOfDay", []);
                                  // console.log(timeOfDay);
                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          header={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={timeOfDayRef}
                                          readOnly={readOnly}
                                      >
                                          <TimeOfDay
                                              mainState={convertTimeInto12HourFormat(
                                                  get(
                                                      targetParams,
                                                      "timeOfDay",
                                                      []
                                                  )
                                              )}
                                              onAdd={obj =>
                                                  onSelectTimeOfDay(obj)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "utmCampaigns":
                                  let utmCampaignsInputJson = {
                                      activeRef: isTPAddedOnce["utmCampaigns"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={utmCampaignsRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={utmCampaignsInputJson}
                                              type="singleInput"
                                              title="New Campaign"
                                              placeholderOne={
                                                  "Enter UTM Campaigns"
                                              }
                                              defaultValue={get(
                                                  targetParams,
                                                  "utmCampaigns",
                                                  []
                                              )}
                                              mainState={get(
                                                  targetParams,
                                                  "utmCampaigns",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectUtmCampaigns(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "utmContents":
                                  let utmContentsInputJson = {
                                      activeRef: isTPAddedOnce["utmContents"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={utmContentsRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={utmContentsInputJson}
                                              type="singleInput"
                                              title="New Content"
                                              placeholderOne={
                                                  "Enter UTM Content"
                                              }
                                              defaultValue={get(
                                                  targetParams,
                                                  "utmContents",
                                                  []
                                              )}
                                              mainState={get(
                                                  targetParams,
                                                  "utmContents",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectUtmContent(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "utmMediums":
                                  let utmMediumsInputJson = {
                                      activeRef: isTPAddedOnce["utmMediums"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={utmMediumsRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={utmMediumsInputJson}
                                              type="singleInput"
                                              title="Mediums"
                                              placeholderOne={
                                                  "Enter UTM Mediums"
                                              }
                                              defaultValue={get(
                                                  targetParams,
                                                  "utmMediums",
                                                  []
                                              )}
                                              mainState={get(
                                                  targetParams,
                                                  "utmMediums",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectUtmMediums(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "utmTerms":
                                  let utmTermsInputJson = {
                                      activeRef: isTPAddedOnce["utmTerms"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={utmTermsRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={utmTermsInputJson}
                                              type="singleInput"
                                              title="Terms"
                                              placeholderOne={"Enter UTM Terms"}
                                              mainState={get(
                                                  targetParams,
                                                  "utmTerms",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectUtmTerms(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "publishers":
                                  let publishersInputJson = {
                                      activeRef: isTPAddedOnce["publishers"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={publishersRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={publishersInputJson}
                                              type="singleInput"
                                              title="Publishers"
                                              placeholderOne={
                                                  "Enter Publishers"
                                              }
                                              mainState={get(
                                                  targetParams,
                                                  "publishers",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectPublishers(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "jobTypes":
                                  let jobTypesInputJson = {
                                      activeRef: isTPAddedOnce["jobTypes"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={jobTypesRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={jobTypesInputJson}
                                              type="singleInput"
                                              title="Job Type"
                                              placeholderOne={"Enter job types"}
                                              mainState={get(
                                                  targetParams,
                                                  "jobTypes",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectJobTypes(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "landingJobTypes":
                                  let landingJobTypesInputJson = {
                                      activeRef: isTPAddedOnce[
                                          "landingJobTypes"
                                      ]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={landingJobTypesRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={
                                                  landingJobTypesInputJson
                                              }
                                              type="singleInput"
                                              title="Landing Job Type"
                                              placeholderOne={
                                                  "Enter landing job types"
                                              }
                                              mainState={get(
                                                  targetParams,
                                                  "landingJobTypes",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectLandingJobTypes(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "landingKeywords":
                                  let landingKeywordsInputJson = {
                                      activeRef: isTPAddedOnce[
                                          "landingKeywords"
                                      ]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={landingKeywordsRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={
                                                  landingKeywordsInputJson
                                              }
                                              type="singleInput"
                                              title="Landing Keywords"
                                              placeholderOne={
                                                  "Enter landing keywords"
                                              }
                                              mainState={get(
                                                  targetParams,
                                                  "landingKeywords",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectLandingKeywords(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              // case "duplicateUser":
                              //   let duplicateUserInputJson = {
                              //     activeRef: isTPAddedOnce["duplicateUser"] ? "firstRef" : "",
                              //   };
                              //   response = (
                              //     <TargettingParamsWrapperComp
                              //       header={get(item, "name", "")}
                              //       availableSet={availableSet}
                              //       item={item}
                              //       key={get(item, "name", "")}
                              //       Rfunction={removeSet}
                              //       resetFunction={resetList}
                              //       ref={duplicateUserRef}
                              //       readOnly={readOnly}
                              //     >
                              //       <InputList
                              //         inputJson={duplicateUserInputJson}
                              //         type="singleInput"
                              //         title="Duplicate User"
                              //         placeholderOne={"duplicateUser"}
                              //         mainState={get(targetParams, "duplicateUser", [])}
                              //         setMainState={(data) => onSelectDuplicateUser(data)}
                              //       />
                              //     </TargettingParamsWrapperComp>
                              //   );
                              //   break;
                              case "os":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          className={`${styles.bodyPadding}`}
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={operatingSystemsRef}
                                          readOnly={readOnly}
                                      >
                                          <SelectedList
                                              list={OPERATINGSYSTEMLIST}
                                              keyName={"id"}
                                              valueName={"label"}
                                              selected={get(
                                                  targetParams,
                                                  "os",
                                                  []
                                              )}
                                              onSelect={
                                                  onSelectOperatingSystems
                                              }
                                              onRowDeleteList={onRowDeleteList}
                                              setDetails={setDetails}
                                              onReset={onResetOperatingSystems}
                                              selectListType="nonDragable"
                                              type="two"
                                              inputPlaceholder={
                                                  "Search a Operating system"
                                              }
                                              selectAll={
                                                  selectAllOperatingSystems
                                              }
                                              // setSelected={setSelectedOperatingSystems}
                                              selectedListActiveItem={
                                                  selectedListActiveItem
                                              }
                                              setSelectedListActiveItem={
                                                  setSelectedListActiveItem
                                              }
                                              listName={"Operating Systems"}
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "browsers":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          className={`${styles.bodyPadding}`}
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={browsersRef}
                                          readOnly={readOnly}
                                      >
                                          <SelectedList
                                              list={BROWSERSLIST}
                                              keyName={"id"}
                                              valueName={"label"}
                                              selected={get(
                                                  targetParams,
                                                  "browsers",
                                                  []
                                              )}
                                              onSelect={onSelectBrowsers}
                                              onRowDeleteList={onRowDeleteList}
                                              setDetails={setDetails}
                                              onReset={onResetBrowsers}
                                              selectListType="nonDragable"
                                              inputPlaceholder={
                                                  "Search a Browser"
                                              }
                                              selectAll={selectAllBrowsers}
                                              // setSelected={setSelectedBrowsers}
                                              selectedListActiveItem={
                                                  selectedListActiveItem
                                              }
                                              setSelectedListActiveItem={
                                                  setSelectedListActiveItem
                                              }
                                              listName={"Browsers"}
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "devices":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          className={`${styles.bodyPadding}`}
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={devicesRef}
                                          readOnly={readOnly}
                                      >
                                          <SelectedList
                                              list={DEVICESLIST}
                                              keyName={"id"}
                                              valueName={"label"}
                                              selected={get(
                                                  targetParams,
                                                  "devices",
                                                  []
                                              )}
                                              onSelect={onSelectDevices}
                                              onRowDeleteList={onRowDeleteList}
                                              setDetails={setDetails}
                                              onReset={onResetDevices}
                                              selectListType="nonDragable"
                                              inputPlaceholder={
                                                  "Search a Device"
                                              }
                                              selectAll={selectAllDevices}
                                              // setSelected={setSelectedDevices}
                                              selectedListActiveItem={
                                                  selectedListActiveItem
                                              }
                                              setSelectedListActiveItem={
                                                  setSelectedListActiveItem
                                              }
                                              listName={"Devices"}
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "allowedDays":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          // className={`${styles.bodyPadding}`}
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={allowedDaysRef}
                                          readOnly={readOnly}
                                      >
                                          <SelectedList
                                              list={ALLOWEDDAYSLIST}
                                              keyName={"id"}
                                              valueName={"label"}
                                              // selected={get(targetParams, "selectedAllowedDays", [])}
                                              selected={get(
                                                  targetParams,
                                                  "allowedDays",
                                                  []
                                              )}
                                              onSelect={onSelectAllowedDays}
                                              onRowDeleteList={onRowDeleteList}
                                              setDetails={setDetails}
                                              onReset={onResetAllowedDays}
                                              selectListType="nonDragable"
                                              inputPlaceholder={"Search a Day"}
                                              selectAll={selectAllAllowedDays}
                                              // setSelected={setSelectedAllowedDays}
                                              selectedListActiveItem={
                                                  selectedListActiveItem
                                              }
                                              setSelectedListActiveItem={
                                                  setSelectedListActiveItem
                                              }
                                              listName={"Allowed Days"}
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "ABTests":
                                  let abTestDropDownJson = {
                                      className: `${styles.editDropdown}`,
                                      keyField: "label",
                                      idField: "id",
                                      position: "top",
                                      options: ABTESTDROPDOWN,
                                      selectedID: dropdownDefaultState
                                          ? dropdownDefaultState
                                          : null,
                                      label: "Select A/B Test Key*",
                                  };

                                  let ABTestsInputJson = {
                                      activeRef: isTPAddedOnce["ABTests"]
                                          ? "firstRef"
                                          : "",
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          header={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={ABTestsRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={ABTestsInputJson}
                                              dropDownJson={abTestDropDownJson}
                                              type="inputDropdownReverse"
                                              title={"A/B Test"}
                                              placeholderOne={
                                                  "Enter A/B Test Value*"
                                              }
                                              mainState={keyValsFromApi(
                                                  get(
                                                      targetParams,
                                                      "keyVals",
                                                      []
                                                  ),
                                                  "ABtest"
                                              )}
                                              // mainState={
                                              //   pathname.includes("abtests") ?
                                              //   keyValsFromApi(
                                              //     get(targetParams, "keyVal", []),
                                              //     "ABtest")  :
                                              //   keyValsFromApi(
                                              //   get(targetParams, "keyVals", []),
                                              //   "ABtest"
                                              // )}
                                              setMainState={data => {
                                                  onSelectABTest(data);
                                                  setDropdownDefaultState("");
                                              }}
                                              readOnly={readOnly}
                                              dropDownOptionToParent={val => {
                                                  setDropdownDefaultState(
                                                      val.label
                                                  );
                                              }}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "keyValues":
                                  let keyValuesInputJson = {
                                      activeRef: isTPAddedOnce["keyValues"]
                                          ? "firstRef"
                                          : "",
                                  };

                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          header={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={keyValuesRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              inputJson={keyValuesInputJson}
                                              type="multipleInput"
                                              title="Source"
                                              placeholderOne={"Key"}
                                              placeholderTwo={"Value"}
                                              mainState={keyValsFromApi(
                                                  get(
                                                      targetParams,
                                                      "keyVals",
                                                      []
                                                  ),
                                                  "keyVals"
                                              )}
                                              // mainState={
                                              //   pathname.includes("abtests") ?
                                              //   keyValsFromApi(
                                              //     get(targetParams, "keyVal", []),
                                              //     "keyVal")  :
                                              //   keyValsFromApi(
                                              //   get(targetParams, "keyVals", []),
                                              //   "keyVals"
                                              // )}

                                              setMainState={data =>
                                                  onSelectkeyVals(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "surveyId":
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={surveyIdRef}
                                          readOnly={readOnly}
                                      >
                                          <Select
                                              label={
                                                  selectedSurveyIds()?.length
                                                      ? null
                                                      : "Select Survey"
                                              }
                                              placeholder="Search Surveys"
                                              className={styles.select}
                                              multiSelect={true}
                                              options={surveyList}
                                              keyField={"name"}
                                              onSelect={onSelectSurvey}
                                              idField={"id"}
                                              portal={true}
                                              selectedIds={selectedSurveyIds()}
                                              isDisabled={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "clientIPs":
                                  let clientIPInputJson = {
                                      type: "text",
                                      className: "label",
                                      secondInputType: "number",
                                      maxCharacter: 16,
                                      minCharacter: 7,
                                  };
                                  response = (
                                      <TargettingParamsWrapperComp
                                          header={get(item, "name", "")}
                                          availableSet={availableSet}
                                          item={item}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={clientIPRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              type="ClientIPsType"
                                              title="Client IPs"
                                              placeholderOne={"Enter Client IP"}
                                              defaultValue={[
                                                  "8.8.8.8",
                                                  "8.8.4.4",
                                              ]}
                                              inputJson={clientIPInputJson}
                                              csvFileName={get(
                                                  clientIPsState,
                                                  "csvFileName",
                                                  ""
                                              )}
                                              setCsvFileName={data =>
                                                  onSelectSetClientIPCsvFileName(
                                                      data
                                                  )
                                              }
                                              csvFile={get(
                                                  clientIPsState,
                                                  "csvFile",
                                                  ""
                                              )}
                                              setCsvFile={data =>
                                                  onSelectSetClientIPCsvFile(
                                                      data
                                                  )
                                              }
                                              csvArray={get(
                                                  clientIPsState,
                                                  "csvArray",
                                                  ""
                                              )}
                                              setCsvArray={data =>
                                                  onSelectSetClientIPCsvArray(
                                                      data
                                                  )
                                              }
                                              error={get(
                                                  clientIPsState,
                                                  "clientIPsError",
                                                  ""
                                              )}
                                              setError={data =>
                                                  onSelectSetClientIPsError(
                                                      data
                                                  )
                                              }
                                              onDropActiveclientIPs={get(
                                                  clientIPsState,
                                                  "onDropActiveClientIPs",
                                                  ""
                                              )}
                                              setonDropActiveclientIPs={data =>
                                                  onSelectSetonDropActiveClientIPs(
                                                      data
                                                  )
                                              }
                                              mainState={get(
                                                  targetParams,
                                                  "clientIPs",
                                                  ""
                                              )}
                                              setMainState={data =>
                                                  onSelectSetClientIPsValues(
                                                      data
                                                  )
                                              }
                                              readOnly={readOnly}
                                          />
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "userStatus":
                                  const userStatusoptionData =
                                      get(targetParams, "userStatus", [])
                                          ?.length > 0
                                          ? USERSTATUSDROPDOWNOPTIONS.filter(
                                                ele =>
                                                    !get(
                                                        targetParams,
                                                        "userStatus",
                                                        []
                                                    ).includes(ele.id)
                                            )
                                          : USERSTATUSDROPDOWNOPTIONS;

                                  const userStatusDropdown = {
                                      className: `${styles.editDropdown}`,
                                      options: userStatusoptionData,
                                      idField: "id",
                                      keyField: "label",
                                      label: "Search by Name",
                                      showCreate: false,
                                  };

                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          header={get(item, "name", "")}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={userStatusRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              title="UserStatus"
                                              type="singleDropdownCreateTags"
                                              dropDownJson={userStatusDropdown}
                                              mainState={get(
                                                  targetParams,
                                                  "userStatus",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectUserStatusDropdown(
                                                      data
                                                  )
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "keywordCategories":
                                  const keywordCategoriesoptionData =
                                      get(targetParams, "keywordCategories", [])
                                          ?.length > 0
                                          ? KEYWORDCATEGORIESDROPDOWNOPTIONS.filter(
                                                ele =>
                                                    !ele.id.includes(
                                                        get(
                                                            targetParams,
                                                            "keywordCategories",
                                                            []
                                                        )
                                                    )
                                            )
                                          : KEYWORDCATEGORIESDROPDOWNOPTIONS;

                                  const keywordCategoriesDropdown = {
                                      className: `${styles.editDropdown}`,
                                      options: keywordCategoriesoptionData,
                                      idField: "id",
                                      keyField: "label",
                                      label: "Search by Name",
                                      showCreate: false,
                                  };

                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          header={get(item, "name", "")}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={keywordCategoriesRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              title="Source"
                                              type="singleDropdownCreateTags"
                                              dropDownJson={
                                                  keywordCategoriesDropdown
                                              }
                                              mainState={get(
                                                  targetParams,
                                                  "keywordCategories",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectKeywordCategoriesDropdown(
                                                      data
                                                  )
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              case "city":
                                  const cityoptionData =
                                      get(targetParams, "city", [])?.length > 0
                                          ? CITYDROPDOWNOPTIONS.filter(
                                                ele =>
                                                    !ele.id.includes(
                                                        get(
                                                            targetParams,
                                                            "city",
                                                            []
                                                        )
                                                    )
                                            )
                                          : CITYDROPDOWNOPTIONS;

                                  const cityDropdown = {
                                      className: `${styles.editDropdown}`,
                                      options: cityoptionData,
                                      idField: "id",
                                      keyField: "label",
                                      label: "Search by Name",
                                  };

                                  response = (
                                      <TargettingParamsWrapperComp
                                          availableSet={availableSet}
                                          item={item}
                                          header={get(item, "name", "")}
                                          key={get(item, "name", "")}
                                          Rfunction={removeSet}
                                          resetFunction={resetList}
                                          ref={cityRef}
                                          readOnly={readOnly}
                                      >
                                          <InputList
                                              title="Source"
                                              type="singleDropdownCreateTags"
                                              dropDownJson={cityDropdown}
                                              mainState={get(
                                                  targetParams,
                                                  "city",
                                                  []
                                              )}
                                              setMainState={data =>
                                                  onSelectCityDropdown(data)
                                              }
                                              readOnly={readOnly}
                                          />
                                          <div
                                              className={styles.popupWrapper}
                                          ></div>
                                      </TargettingParamsWrapperComp>
                                  );
                                  break;
                              default:
                                  break;
                          }
                          return response;
                      })
                    : ""}

                {!readOnly &&
                get(dropDownState, "productListPlusData", []).length > 0 ? (
                    <>
                        <div
                            className={`${styles.addMoreTargetting} dp-parent dp-parent-ver-center`}
                        >
                            <Icon icon="Info" size="14px" />
                            <p>Add more targeting parameters</p>
                        </div>

                        <div className={`dp-parent ${styles.setBtnWrapper}`}>
                            {/* <OutsideClickHandler
                onOutsideClick={() => {
                  closePlusDropDown()
                }}
              > */}
                            <div
                                className={styles.plusIconWrapper}
                                onClick={() => showPlusDropdownHandler()}
                                ref={wrapperRef}
                            >
                                <Icon
                                    icon="Plus"
                                    size="14px"
                                    style={style}
                                    // onClick={() => showPlusDropdownHandler()}
                                />
                            </div>
                            {get(dropDownState, "showPlusDropdown", false) && (
                                <div className={styles.plusDropdownWrapper}>
                                    <Select
                                        label={"Select Target Params"}
                                        placeholder="Search Target Params"
                                        className={styles.select}
                                        options={get(
                                            dropDownState,
                                            "productListPlusData",
                                            []
                                        )}
                                        keyField="firstName"
                                        onSelect={handleOptionClick}
                                        idField="id"
                                        isSelectOpen={true}
                                        portal={true}
                                        onSelectClose={() =>
                                            setDropDownState({
                                                ...dropDownState,
                                                showPlusDropdown: false,
                                            })
                                        }
                                        position={"top"}
                                    />
                                </div>
                            )}
                            {numberOfSets
                                .filter(element => {
                                    return !availableSet.includes(
                                        get(element, "id", "")
                                    );
                                })
                                .slice(0, 5)
                                .map((element, index) => {
                                    return (
                                        <Tooltip
                                            message="Click to add"
                                            position="top"
                                            key={
                                                index +
                                                "element" +
                                                getRandomId()
                                            }
                                        >
                                            <div
                                                className={styles.setBtn}
                                                onClick={() => {
                                                    addSet(
                                                        get(element, "id", "")
                                                    );
                                                }}
                                            >
                                                {get(element, "name", "")}
                                            </div>
                                        </Tooltip>
                                    );
                                })}
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default TargettingParams;
