import { get } from "lodash";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { FixedSizeList } from "react-window";
import Input from "../../Shared/Input/Input";
import ToggleValueSwitch from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import { getHourMins, getRandomId } from "../../utils/globalUtils";
import s from "./TimePicker.module.scss";
const { hours, minutes } = getHourMins();
const List = ({ data, selected, onSelect }) => {
    return (
        <FixedSizeList
            itemData={data}
            height={
                get(data, "length", 0) > 6 ? 180 : get(data, "length", 0) * 30
            }
            itemCount={get(data, "length", 0)}
            width={"100%"}
            itemSize={31}
            className={s.listWrap}
        >
            {({ index, style, data }) => (
                <Row
                    index={index}
                    style={style}
                    data={data}
                    selected={selected}
                    onSelect={onSelect}
                />
            )}
        </FixedSizeList>
    );
};
const Row = ({ index, style, data, selected, onSelect }) => (
    <div
        className={`${s.listItem} ${
            selected == get(data, `${index}`, "") ? s.selected : ""
        }`}
        key={index + get(data, `${index}`, "") + getRandomId()}
        onClick={() => onSelect(get(data, `${index}`, ""))}
        style={style}
    >
        {get(data, `${index}`, "")}
    </div>
);
const TimePicker = ({
    label = "",
    value: { hour, mins, isPM = false },
    setValue,
    toggleValueSwitchClass = "",
    timePickerInputClass = "",
    dropdownListClass = "",
    isDisabled = false,
}) => {
    const selectValue = (value, key) => {
        const val = {
            mins: selectedMin,
            hour: selectedHour,
            isPM: isPMSelected,
            [key]: value,
        };
        typeof setValue == "function" && setValue && setValue(val);
    };
    const [open, setOpen] = useState(false);
    const [selectedHour, setSelectedHour] = useState(hour);
    const [selectedMin, setSelectedMin] = useState(mins);
    const [isPMSelected, setisPMSelected] = useState(isPM);

    const handleOutSideClick = () => {
        if (open) setTimeout(() => setOpen(false));
    };

    return (
        <div className={`${s.wrapper}`}>
            {label && <label className={s.label}>{label}</label>}
            <div
                className={`${s.dropdownWrapper} ${
                    isDisabled ? s.disablePicker : ""
                }`}
            >
                <div className={s.labelWrapper}>
                    <div
                        className={`${s.inputWrapper} ${
                            label ? s.inputBorder : ""
                        }`}
                        onClick={() => setOpen(!open)}
                    >
                        <Input
                            maxWidth={`${s.input} ${
                                timePickerInputClass ? timePickerInputClass : ""
                            }`}
                            type="text"
                            disabled={true}
                            value={selectedHour}
                        />
                        :
                        <Input
                            maxWidth={`${s.input} ${
                                timePickerInputClass ? timePickerInputClass : ""
                            }`}
                            value={selectedMin}
                            type="text"
                            disabled={true}
                        />
                    </div>
                    {open && (
                        <OutsideClickHandler
                            onOutsideClick={handleOutSideClick}
                        >
                            <div
                                className={`${s.dropdownListWrapper} ${
                                    dropdownListClass ? dropdownListClass : ""
                                }`}
                            >
                                <List
                                    data={hours}
                                    selected={selectedHour}
                                    onSelect={id => {
                                        setSelectedHour(id);
                                        selectValue(id, "hour");
                                    }}
                                ></List>
                                <List
                                    data={minutes}
                                    selected={selectedMin}
                                    onSelect={id => {
                                        setSelectedMin(id);
                                        selectValue(id, "mins");
                                    }}
                                ></List>
                            </div>
                        </OutsideClickHandler>
                    )}
                </div>
                <ToggleValueSwitch
                    classNameWrapper={`${s.toggleSwitchContainer} ${
                        toggleValueSwitchClass ? toggleValueSwitchClass : ""
                    }`}
                    onChange={val => {
                        setisPMSelected(val);
                        selectValue(val, "isPM");
                    }}
                    leftLabel={"am"}
                    rightLabel={"pm"}
                    toggleState={isPMSelected}
                />
            </div>
        </div>
    );
};

export default TimePicker;
