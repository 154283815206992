import { useEffect } from "react";

export default function useOutsideAlerter(ref, setOption) {
    useEffect(() => {
        function handleClickOutside(event) {
            // console.log(event.target);
            if (ref.current && !ref.current.contains(event.target)) {
                // console.log(ref.current && !ref.current.contains(event.target));
                setOption(false);
            }
        }

        document.addEventListener("click", handleClickOutside);
        // document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
            // document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}
