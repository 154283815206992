import React, { Component } from "react";
import c from "./Button.module.scss";
import PropTypes from "prop-types";

export const ButtonTypes = {
    default_btn: "themBtn",
    primary_btn: "themBtnBg",
    primaryHover_btn: "themeDefaultBtn",
    delete_btn: "themeDeleteBtn",
    skeleton_btn: "themeSkeletonBtn",
};

export const buttonSize = {
    large: "largeBtn",
};

export const Button = ({
    btnTheme,
    label,
    onClick,
    disable,
    type,
    className,
    size,
    btnWithIcon,
    ...props
}) => {
    const btnSize = size != null ? c[size] : c["basic"];
    return (
        <button
            className={`${c.commonStyle} ${btnTheme ? c[btnTheme] : ""} ${
                className ? className : ""
            } ${btnWithIcon ? c.btnIcon : ""} ${btnSize}`}
            onClick={onClick}
            disabled={disable}
            type={type || "button"}
            {...props}
        >
            {label || props.children}
        </button>
    );
};

Button.propTypes = {
    /**
     *  Theme out of supported theme, refer storybook for all available themes
     */
    btnTheme: PropTypes.string,

    /**
     * Text to be displayed in the button
     */
    label: PropTypes.string,

    /**
     * Is it in disabled state ?
     */
    disable: PropTypes.bool,

    /**
     * Specify HTML button type
     */
    type: PropTypes.string,

    /**
     * Optional click handler
     */
    onClick: PropTypes.func,

    /**
     * Predefined size of button
     */
    size: PropTypes.string,

    /**
     * Specifies button with icon, no text
     */
    btnWithIcon: PropTypes.bool,
};

Button.defaultProps = {
    type: "button",
};

export default Button;
