import { combineReducers } from "redux";

import questions from "./questions";
import flows from "./flows";
import collection from "./collection";
import container from "./container";
import template from "./template";
// import campaign from "./campaign";
import categories from "./categories";
import layout from "./layout";
import siteconfig from "./siteconfig";

const flowmanager = combineReducers({
    flows,
    questions,
    collection,
    container,
    template,
    // campaign,
    categories,
    layout,
    siteconfig,
});

export default flowmanager;
