import Icon from "../../Shared/Icon/Icon";
import Select from "../../Shared/Select/Select";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import InputList from "../../components/InputList/InputList";
import styles from "../../components/FlowTargettingParams/FlowTargettingParams.module.scss";

import s from "./CreateFlow.module.scss";
import containerStyles from "../CreateContainer/CreateContainer.module.scss";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import {
    useFetchAvailableQuestions,
    useFetchAvailableTargetingParams,
    useFetchLayouts,
} from "../../generalApi/generalApi";
import { fetchTpKey } from "../../Shared/FlowRenderer/FlowRendererUtils";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import LayoutEditComp from "../../components/LayoutEditComp/LayoutEditComp";
import { toastListActions } from "../../store/toastList/toastList";
import TagRenderer from "../../components/Renderers/TagRenderer/TagRenderer";
import ToggleValueSwitch from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import Tooltip from "../../Shared/Tooltip/Tooltip";

const TargetingNode = forwardRef(
    (
        {
            forcedTpList = [],
            branches = [],
            readOnly = false,
            singleBranch = false,
            hideDelete = false,
            inlineMode = false,
            onChange = () => {},
            onDelete = () => {},
            editNode = null,
            allowedTp = [],
            onAnyChange = () => {},
            customAnswerAndSkipAnswerSupport = false,
            tagWrappable = false,
            incrementBranchName = false,
            hideHeader = false,
        },
        ref
    ) => {
        const [nodeBranches, setNodeBranches] = useState([]);
        const [name, setName] = useState(null);
        const [inputType, setInputType] = useState(null);
        const [tpType, setTpType] = useState(null);
        const [possibleValues, setPossibleValues] = useState([]);
        const { apiData = [], isLoading } = useFetchAvailableTargetingParams();

        const availableTargetingParams = useMemo(() => {
            if (forcedTpList.length) {
                return forcedTpList;
            }
            return apiData || [];
        }, [apiData, forcedTpList]);

        useEffect(() => {
            if (branches.length && availableTargetingParams.length) {
                let filteredBranches = branches.filter(
                    _ => _?.value?.length || inlineMode
                );
                if (filteredBranches?.length) {
                    const type = fetchTpKey(filteredBranches[0]);

                    const tp = availableTargetingParams.find(
                        _ => _.name === type
                    );
                    setTpType(tp?.uiCategory || null);
                    setName(tp?.name || null);
                    setInputType(tp?.type || null);
                    setPossibleValues(tp?.possibleValues || []);
                    setNodeBranches([
                        ...filteredBranches.map((_, idx) => ({
                            ..._,
                            layoutType: tp?.layoutType,
                            layoutSubType: tp?.layoutSubType,
                            type: tp?.name,
                            value: Array.isArray(_.value)
                                ? getNestedTpValues(
                                      _.value,
                                      filteredBranches,
                                      type
                                  )
                                : [_.value],

                            ...(incrementBranchName
                                ? {}
                                : { name: `Branch ${idx + 1}` }),
                        })),
                    ]);
                }
            }
        }, [branches, availableTargetingParams, incrementBranchName]);

        const nodeBranchesLength = useMemo(() => {
            const values = nodeBranches.reduce((accumulator, currentValue) => {
                return [...accumulator, ...currentValue.value];
            }, []);
            return values.length;
        }, [nodeBranches]);

        useImperativeHandle(ref, () => {
            return {
                getData: () => {
                    if (nodeBranches.some(_ => !_?.value?.length)) return [];
                    const filteredBranches = branches.filter(
                        _ => !_?.value?.length
                    );
                    return [...nodeBranches, ...filteredBranches.map(_ => _)];
                },
            };
        });

        const availableTpOptions = availableTargetingParams.filter(
            _ => !allowedTp.length || allowedTp.includes(_.name)
        );

        return (
            !isLoading && (
                <div
                    className={`${s.targetingFormWrapper} ${
                        inlineMode ? s.inline : ""
                    }`}
                >
                    {!inlineMode && (
                        <div className={"row"}>
                            <p className={`headerTitleRequired`}>Type*</p>
                            <Select
                                options={[
                                    ...(availableTpOptions.find(
                                        _ => _.uiCategory === "User Attribute"
                                    )
                                        ? [
                                              {
                                                  id: "User Attribute",
                                                  name: "User Attribute",
                                              },
                                          ]
                                        : []),
                                    ...(availableTpOptions.find(
                                        _ => _.uiCategory === "User Behaviour"
                                    )
                                        ? [
                                              {
                                                  id: "User Behaviour",
                                                  name: "User Behaviour",
                                              },
                                          ]
                                        : []),
                                ]}
                                keyField={"name"}
                                idField={"id"}
                                selectedId={tpType}
                                label={name ? null : "Select Type"}
                                onLoadSelectReturn={false}
                                onSelect={tp => {
                                    setTpType(tp.id);
                                    setName(null);
                                    setInputType(null);
                                    setPossibleValues([]);
                                    onAnyChange(true);
                                }}
                                isDisabled={!!editNode || readOnly}
                                showOptionToolTip={false}
                            />
                        </div>
                    )}
                    {!inlineMode && tpType && (
                        <div className={"row"}>
                            <p className={`headerTitleRequired`}>
                                Targeting Parameter*
                            </p>
                            <Select
                                options={availableTpOptions.filter(
                                    _ => _.uiCategory === tpType
                                )}
                                keyField={"opsName"}
                                idField={"name"}
                                selectedId={name}
                                label={name ? null : "Select Targeting"}
                                onLoadSelectReturn={false}
                                CustomRender={({ option }) => (
                                    <div className="dp-parent">
                                        <Icon
                                            icon={option?.icon}
                                            fallbackIcon="Default"
                                            size="18px"
                                            className="circleIconColor"
                                        />
                                        <span className="ml-5">
                                            {option.opsName}
                                        </span>
                                    </div>
                                )}
                                onSelect={tp => {
                                    setName(tp.name);
                                    setInputType(tp.type);
                                    setPossibleValues(tp.possibleValues || []);
                                    const obj = [
                                        {
                                            name: "Branch 1",
                                            type: tp.name,
                                            value: [],
                                        },
                                    ];
                                    onChange(obj);
                                    setNodeBranches(_ => obj);
                                }}
                                isDisabled={!!editNode || readOnly}
                            />
                        </div>
                    )}

                    {name && (
                        <>
                            <ul
                                className={`${s.branchList} ${
                                    inlineMode || singleBranch
                                        ? s.branchList__noAddBtn
                                        : ""
                                } ${readOnly ? s.readOnlyView : ""}`}
                            >
                                {!singleBranch && (
                                    <li className={s.branch}>
                                        <div className={s.branchHeader}>
                                            Default
                                        </div>
                                    </li>
                                )}

                                {nodeBranches.map((_, i) => (
                                    <li
                                        key={`key-${i}`}
                                        className={`${s.branch} deleteIconVisible`}
                                    >
                                        {!hideHeader && (
                                            <div
                                                className={`${s.branchHeader}`}
                                            >
                                                <div className="dp-parent dp-parent-ver-center gap-30">
                                                    <span>{_.name}: </span>
                                                    {_.type === "ans_2" && (
                                                        <div
                                                            className={`dp-parent dp-parent-ver-center gap-5 ${styles.typeWrap}`}
                                                        >
                                                            Type :
                                                            <ToggleValueSwitch
                                                                leftLabel="AND"
                                                                rightLabel="OR"
                                                                toggleState={
                                                                    _.connectionType !==
                                                                    "AND"
                                                                }
                                                                onChange={toggleState => {
                                                                    _.connectionType =
                                                                        toggleState
                                                                            ? "OR"
                                                                            : "AND";
                                                                    onChange(
                                                                        nodeBranches
                                                                    );
                                                                    onAnyChange(
                                                                        true
                                                                    );
                                                                }}
                                                                isDisabled={
                                                                    readOnly
                                                                }
                                                                classNameWrapper={
                                                                    styles.customToggleValueSwitch
                                                                }
                                                                compact
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {
                                                    <span
                                                        onClick={
                                                            !readOnly &&
                                                            !hideDelete
                                                                ? async () => {
                                                                      await setNodeBranches(
                                                                          s => {
                                                                              const filterArray =
                                                                                  [
                                                                                      ...s.filter(
                                                                                          b =>
                                                                                              b.name !==
                                                                                              _.name
                                                                                      ),
                                                                                  ];
                                                                              return incrementBranchName
                                                                                  ? filterArray
                                                                                  : filterArray.map(
                                                                                        (
                                                                                            item,
                                                                                            idx
                                                                                        ) => {
                                                                                            return {
                                                                                                ...item,
                                                                                                name: `Branch ${
                                                                                                    idx +
                                                                                                    1
                                                                                                }`,
                                                                                            };
                                                                                        }
                                                                                    );
                                                                          }
                                                                      );
                                                                      onDelete(
                                                                          i
                                                                      );
                                                                      onAnyChange(
                                                                          true
                                                                      );
                                                                  }
                                                                : null
                                                        }
                                                    >
                                                        {!readOnly &&
                                                            !hideDelete && (
                                                                <Tooltip
                                                                    message={
                                                                        "Delete"
                                                                    }
                                                                    position="top"
                                                                >
                                                                    <Icon
                                                                        enableHover={
                                                                            true
                                                                        }
                                                                        icon="Delete"
                                                                        size="18px"
                                                                        className="circleIconColor circleIconColor--delete"
                                                                        disable={
                                                                            readOnly
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                    </span>
                                                }
                                            </div>
                                        )}

                                        <div
                                            className={`mg-top ${
                                                hideHeader ? "p-10" : ""
                                            }`}
                                        >
                                            <PrintTp
                                                readOnly={readOnly}
                                                possibleValues={possibleValues}
                                                allBranches={nodeBranches}
                                                inputType={inputType}
                                                selectedBranch={_}
                                                setNodeBranches={async p => {
                                                    await setNodeBranches(p);
                                                    onChange(nodeBranches);
                                                    onAnyChange(true);
                                                }}
                                                customAnswerAndSkipAnswerSupport={
                                                    customAnswerAndSkipAnswerSupport
                                                }
                                                availableTpOptions={
                                                    availableTpOptions
                                                }
                                                tagWrappable={tagWrappable}
                                                incrementBranchName={
                                                    incrementBranchName
                                                }
                                            />
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            {!inlineMode && !singleBranch && (
                                <div
                                    className={`${
                                        s.addBranchBtn
                                    } link dp-parent-inline ${
                                        readOnly ? s.disableLink : ""
                                    } ${
                                        inputType === "checkBox" &&
                                        (possibleValues?.length ===
                                            nodeBranchesLength ||
                                            possibleValues?.length ===
                                                nodeBranches?.length ||
                                            nodeBranches.some(
                                                (item, idx) =>
                                                    item?.value?.length === 0
                                            ))
                                            ? s.addBranchBtnDisale
                                            : ""
                                    }`}
                                    onClick={
                                        !readOnly
                                            ? async () => {
                                                  await setNodeBranches(_ => [
                                                      ..._,
                                                      {
                                                          name: `Branch ${
                                                              _.length + 1
                                                          }`,
                                                          type: name,
                                                          value: [],
                                                      },
                                                  ]);

                                                  onChange(nodeBranches);
                                              }
                                            : null
                                    }
                                >
                                    + Add Branch
                                </div>
                            )}
                        </>
                    )}
                </div>
            )
        );
    }
);

const PrintTp = ({
    selectedBranch: element,
    inputType,
    allBranches = [],
    possibleValues = [],
    setNodeBranches,
    readOnly,
    customAnswerAndSkipAnswerSupport,
    availableTpOptions = [],
    tagWrappable = false,
    incrementBranchName = false,
}) => {
    const dispatch = useDispatch();

    const [tempValue, setTempValue] = useState([]);
    const [valueOne, setValueOne] = useState(element.value);
    const [valueTwo, setValueTwo] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [showConfigSelect, setShowConfigSelect] = useState(false);
    const { apiData: layouts = [], isLoading } = useFetchLayouts();
    const [file, setFile] = useState(null);
    var { apiData: questionList = [] } = useFetchAvailableQuestions();

    const [csvFileName, setcsvFileName] = useState("");
    const [csvArray, setcsvArray] = useState([]);
    const [csvFile, setcsvFile] = useState("");
    const [csvError, setcsvError] = useState(false);

    const layoutOpenRef = useRef();

    const singleInputTPBulkUpload = [
        "utm_term",
        "utm_campaign",
        "job_type",
        "user_status_current",
        "utm_medium",
        "ljt",
        "lkw",
        "kwcat",
        "TrafficCategory",
        "domain",
        "ps",
        "pub_source_type",
        "spam_flag",
        "ch",
        "test",
    ];

    let switchV = inputType;
    if (
        ["multiSelectEditable", "multiSelect"].includes(switchV) &&
        !possibleValues.length
    ) {
        switchV = "text";
    } else if (["integer"].includes(switchV)) {
        switchV = "number";
    }

    if (switchV === "singleSelect") {
        possibleValues = questionList;
    }

    switch (switchV) {
        case "singleSelect":
            let channelMapDropDownJson = {
                keyField: "name",
                idField: "id",
                options: possibleValues,
                selectedID: valueOne || null,
                label: valueOne === null ? "" : "Select Question",
                showSearch: true,
                onSelect: data => {
                    setValueOne(data);
                    setValueTwo(null);
                },
            };
            let channelMapDropDownJsonSecond = {
                keyField: "answer",
                idField: "key",
                label: "Select Answer",
                isDisabled: !valueOne || selectAll,
                options: valueOne?.answers || [],
                showSearch: true,
                showCreate: customAnswerAndSkipAnswerSupport,
                onSelect: data => {
                    setValueTwo(data);
                },
            };

            let multiSelectCheckbox = {
                labelText: "Any Answer",
                isChecked: selectAll,
                onChange: data => {
                    setSelectAll(data);
                },
            };

            return (
                <>
                    <InputList
                        type="multipleSelect"
                        dropDownJson={channelMapDropDownJson}
                        dropDownJsonSecond={channelMapDropDownJsonSecond}
                        multiSelectCheckbox={customAnswerAndSkipAnswerSupport}
                        multiSelectCheckboxJson={multiSelectCheckbox}
                        mainState={element.value.map((obj, index) => {
                            if (obj.valueSecond === "skip")
                                return {
                                    ...obj,
                                    valueSecond: "Any Answer",
                                };

                            return obj;
                        })}
                        // mainState={element.value}
                        setMainState={(data, type) => {
                            if (selectAll && type !== "delete") {
                                let newData = [...data];
                                newData.forEach((obj, idx) => {
                                    if (
                                        idx === newData.length - 1 ||
                                        obj.valueSecond === "Any Answer"
                                    ) {
                                        obj.valueSecond = "skip";
                                    }
                                });
                                element.value = newData;
                            } else {
                                // element.value = data;
                                element.value = data.map((obj, index) => {
                                    if (obj.valueSecond === "Any Answer")
                                        return {
                                            ...obj,
                                            valueSecond: "skip",
                                        };
                                    return obj;
                                });
                            }
                            setNodeBranches(_ => [..._]);
                            setValueOne(null);
                            setValueTwo(null);
                            setSelectAll(false);
                        }}
                        onDelete={data => {
                            setNodeBranches(_ => [..._]);
                        }}
                        readOnly={readOnly}
                        tagWrappable={tagWrappable}
                    />
                </>
            );

        case "multiSelectEditable":
        case "multiSelect":
        case "boolean":
            let options = possibleValues.filter(_ =>
                allBranches.every(n => !n.value.includes(_.id || _))
            );

            options =
                inputType === "boolean"
                    ? options.map(item => ({
                          id: item,
                          key: item ? "Yes" : "No",
                          label: item ? "Yes" : "No",
                      }))
                    : options.map(item => ({
                          ...item,
                          key: `${item.id} (${item.label})`,
                          label: `${item.id}: ${item.label}`,
                      }));

            let dropdownJson = {
                className: `${styles.editDropdown}`,
                options,
                idField: "id",
                keyField: "label",
                label: "Search by Name or Value",
                showCreate: switchV === "multiSelectEditable",
            };

            return (
                <InputList
                    title={element.type}
                    type="singleDropdownCreateTags"
                    dropDownJson={dropdownJson}
                    mainState={element.value}
                    setMainState={data => {
                        element.value = data;
                        setNodeBranches(_ => [..._]);
                    }}
                    readOnly={readOnly}
                    tagWrappable={tagWrappable}
                />
            );

        case "checkBox":
            return (
                <ul
                    className={`dp-parent ${styles.CheckboxContainer} ${
                        readOnly && `gap-10`
                    }`}
                >
                    {!readOnly
                        ? possibleValues.map(_ => (
                              <li className={`${styles.CheckboxWrapper} mr-10`}>
                                  <Checkbox
                                      labelText={_.label}
                                      onChange={e => {
                                          if (e) {
                                              element.value = [
                                                  ...element.value,
                                                  _.id,
                                              ];
                                          } else {
                                              element.value = [
                                                  ...element.value.filter(
                                                      n => n !== _.id
                                                  ),
                                              ];
                                          }
                                          setNodeBranches(_ => [..._]);
                                      }}
                                      isChecked={element.value.includes(_.id)}
                                      disabled={
                                          readOnly ||
                                          allBranches.some(
                                              n =>
                                                  element.name !== n.name &&
                                                  n.value.includes(_.id)
                                          )
                                      }
                                  />
                              </li>
                          ))
                        : allBranches[0]?.value?.map(ele => (
                              <TagRenderer type={"default"}>
                                  {typeof ele === "boolean"
                                      ? ele
                                          ? "Yes"
                                          : "No"
                                      : ele}
                              </TagRenderer>
                          ))}
                </ul>
            );
        case "range":
            let inputJson = {
                type: "number",
                className: "label",
                secondInputType: "number",
                maxCharacter: 3,
            };
            return (
                <InputList
                    title={element.type}
                    type="multipleInput"
                    inputJson={inputJson}
                    mainState={element.value}
                    placeholderOne={"Start"}
                    placeholderTwo={"End"}
                    setMainState={data => {
                        element.value = data;
                        setNodeBranches(_ => [..._]);
                    }}
                    readOnly={readOnly}
                    tagWrappable={tagWrappable}
                />
            );
        case "numberUpload":
            let uploadNumberJson = {
                type: "text",
                className: "label",
                secondInputType: "number",
                maxCharacter: 5,
                minCharacter: 5,
            };
            return (
                <InputList
                    type="ZipCodeType"
                    title={element.type}
                    placeholderOne={"Enter Zip Code*"}
                    defaultValue={["400041", "300049"]}
                    inputJson={uploadNumberJson}
                    csvFileName={file?.name || ""}
                    setCsvFileName={data =>
                        setFile(_ => ({ ..._, name: data }))
                    }
                    csvFile={file?.file || ""}
                    setCsvFile={data => setFile(_ => ({ ..._, file: data }))}
                    csvArray={file?.array || []}
                    setCsvArray={data => setFile(_ => ({ ..._, array: data }))}
                    error={file?.error || ""}
                    setError={data => setFile(_ => ({ ..._, error: data }))}
                    onDropActiveZipCode={file?.onDropActiveZipCode || ""}
                    setonDropActiveZipCode={data =>
                        setFile(_ => ({ ..._, onDropActiveZipCode: data }))
                    }
                    mainState={element.value}
                    setMainState={data => {
                        element.value = data;
                        setNodeBranches(_ => [..._]);
                        setFile(null);
                    }}
                    readOnly={readOnly}
                    tagWrappable={tagWrappable}
                />
            );
        case "layout":
            let selectedLayout = Array.isArray(element)
                ? element?.length && element[0].value
                : element?.value?.length && element.value[0];
            const selectedLayoutFromMasterList = layouts.find(
                obj => obj.name === selectedLayout?.name
            );
            if (selectedLayout) {
                selectedLayout = {
                    ...selectedLayout,
                    configurations: selectedLayout.configurations?.map(_ => ({
                        ...selectedLayoutFromMasterList?.configurations?.find(
                            c => c.key === _.key
                        ),
                        ..._,
                    })),
                };
            }

            return (
                <div className="mb-20">
                    <div className="dp-parent dp-parent-hor-space-between dp-child-ver-center">
                        {selectedLayoutFromMasterList?.name &&
                            selectedLayoutFromMasterList?.configurations
                                ?.length > 0 && (
                                <p
                                    className={`${containerStyles.sideTitle} ${containerStyles.sideEditTitle} mb-10 w-100`}
                                    onClick={() => {
                                        layoutOpenRef.current.open();
                                        setTempValue(selectedLayout);
                                    }}
                                >
                                    <Tooltip
                                        message={"Layout Properties"}
                                        position="top"
                                    >
                                        <Icon
                                            icon="EditLayoutProperties"
                                            size="16px"
                                            enableHover={true}
                                        />
                                    </Tooltip>
                                </p>
                            )}
                    </div>
                    <Select
                        loading={isLoading}
                        options={layouts.filter(
                            _ =>
                                _.type === element.layoutType &&
                                (!element.layoutSubType ||
                                    _.subType === element.layoutSubType)
                        )}
                        idField="name"
                        keyField="name"
                        label="Select Layout"
                        selectedId={selectedLayoutFromMasterList?.name || null}
                        onSelect={e => {
                            element.value = e;
                            setNodeBranches(_ => _);
                        }}
                        onLoadSelectReturn={false}
                        portal={false}
                        CustomRender={item => {
                            return (
                                <div className="dp-parent gap-10 w-100">
                                    <div> {item.option.name}</div>
                                    {item.option?.configurations?.length >
                                        0 && (
                                        <div className="iconInsideDiv">
                                            <Icon
                                                icon="Customise"
                                                size="16px"
                                                className="circleIconColor"
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        }}
                        isDisabled={readOnly}
                    />
                    <SidePanelInfinity
                        ref={layoutOpenRef}
                        title={"Edit Layout Features"}
                        width={"449px"}
                        dialogContentClassName={containerStyles.popupStyle}
                        onSave={() => {
                            let isValid = true;
                            !isEmpty(tempValue) &&
                                tempValue[0]?.configurations?.length > 0 &&
                                tempValue[0]?.configurations.forEach(
                                    (item, index) => {
                                        if (item.type === "string") {
                                            if (!item?.data) {
                                                dispatch(
                                                    toastListActions.setToastList(
                                                        {
                                                            type: "Error",
                                                            message: `Please enter default value for Key ${item.key}.`,
                                                        }
                                                    )
                                                );
                                                isValid = false;
                                            }
                                        }
                                        if (item.type === "number") {
                                            let intData = parseInt(item?.data);

                                            if (
                                                intData === "" ||
                                                intData === null
                                            ) {
                                                dispatch(
                                                    toastListActions.setToastList(
                                                        {
                                                            type: "Error",
                                                            message: `Please enter default value for Key ${item.key}.`,
                                                        }
                                                    )
                                                );
                                                isValid = false;
                                            }
                                            if (
                                                typeof intData === "number" &&
                                                (intData > parseInt(item.max) ||
                                                    intData <
                                                        parseInt(item.min))
                                            ) {
                                                dispatch(
                                                    toastListActions.setToastList(
                                                        {
                                                            type: "Error",
                                                            message: `Please enter value between ${item.min} and ${item.max} for Key ${item.key}.`,
                                                        }
                                                    )
                                                );
                                                isValid = false;
                                            }
                                        }
                                    }
                                );
                            if (isValid) {
                                element.value = tempValue;
                                setNodeBranches(_ => _);
                                layoutOpenRef.current.close();
                            }
                        }}
                        disableSaveBtn={readOnly}
                        onClose={() => {
                            setTempValue(null);
                        }}
                    >
                        {tempValue && (
                            <LayoutEditComp
                                defaultConfigs={
                                    selectedLayoutFromMasterList?.configurations ||
                                    []
                                }
                                individualLayoutData={[tempValue]}
                                updatedLayout={data => {
                                    setTempValue(data[0]);
                                }}
                                ondelete={item => {
                                    setTempValue(_ => ({
                                        ..._,
                                        configurations:
                                            _?.configurations?.filter(
                                                _ => _.key !== item.key
                                            ),
                                    }));
                                }}
                                onUpdatePreviewLink={() => {}}
                                showConfigSelect={showConfigSelect}
                                addConfigList={
                                    selectedLayoutFromMasterList?.configurations?.filter(
                                        _ =>
                                            !tempValue ||
                                            tempValue?.configurations?.findIndex(
                                                item => _.key === item.key
                                            ) === -1
                                    ) || []
                                }
                                setShowConfigSelect={data => {
                                    setShowConfigSelect(data);
                                }}
                                customOnSelect={item => {
                                    setTempValue(_ => ({
                                        ..._,
                                        configurations: [
                                            ..._.configurations,
                                            ...item,
                                        ],
                                    }));
                                    setShowConfigSelect(false);
                                }}
                                onCancelHandler={e => {
                                    setShowConfigSelect(false);
                                }}
                                readOnly={readOnly}
                            />
                        )}
                    </SidePanelInfinity>
                </div>
            );

        case "singleSelectOg":
            let dropdownJsonValue = {
                options: possibleValues,
                idField: "id",
                keyField: "label",
                label: "Select Ad Unit",
                selectedId: element?.value[0] || null,
            };

            return (
                <InputList
                    title="Ad unit"
                    type="singleDropdown"
                    dropDownJson={dropdownJsonValue}
                    mainState={possibleValues
                        .filter(_ => _.id === element.value[0])
                        .map(_ => _.label)}
                    setMainState={data => {
                        element.value = data;
                        setNodeBranches(_ => [..._]);
                    }}
                    readOnly={readOnly}
                />
            );
    }

    let inputJson = {
        type: switchV,
    };

    return (
        <InputList
            type="singleInput"
            title={
                availableTpOptions.find(_ => _.name === element.type)
                    ?.opsName || element?.type
            }
            placeholderOne={"Enter Value"}
            defaultValue={element.value}
            inputJson={inputJson}
            csvFileName={csvFileName}
            setCsvFileName={data => {
                setcsvFileName(data);
            }}
            csvFile={csvFile}
            setCsvFile={data => {
                setcsvFile(data);
            }}
            csvArray={csvArray}
            setCsvArray={data => {
                setcsvArray(data);
            }}
            error={csvError}
            setError={data => {
                setcsvError(data);
            }}
            mainState={element.value}
            setMainState={data => {
                element.value = data;
                setNodeBranches(_ => [..._]);
            }}
            readOnly={readOnly}
            singleInputBulkUpload={singleInputTPBulkUpload.includes(
                element.type
            )}
            tagWrappable={tagWrappable}
        />
    );
};

export const getNestedTpValues = (array, filteredBranches, type) => {
    const values = array?.map(v => {
        if (type === "ans_2" && v.value?.length === 1) {
            const qId = fetchTpKey(v, true);
            return {
                valueOne: parseInt(qId?.split("q_")[1]),
                valueSecond: v.value[0].value,
            };
        }
        if (v.type === "AND" && v.value?.length === 2) {
            return {
                valueOne: v.value[0].value,
                valueSecond: v.value[1].value,
            };
        }
        if (Array.isArray(v.value) && !v.value.length) {
            return;
        }
        return v.value;
    });

    return values.filter(_ => _ !== null && (_ !== undefined) & (_ !== "")); //we have to return false values
};

export default TargetingNode;
