import Card from "../../Shared/Card/Card";
import OfferWallBasics from "../../components/OfferWallBasics/OfferWallBasics";
import OfferWallSlots from "../../components/OfferWallSlots/OfferWallSlots";
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import { offerWall } from "../../constants/leftPanelConstants";
import s from "./CreateOfferWall.module.scss";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import { useDispatch, useSelector } from "react-redux";
import {
    createOfferWallData,
    createOfferWallActions,
    fetchOfferWallDataById,
    fetchSurveys,
    updateOfferWallData,
    fetchDealIds,
} from "../../store/listings/offerWall";
import { fetchAdCreatives } from "../../store/listings/adUnits";
import { get } from "lodash";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { toastListActions } from "../../store/toastList/toastList";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import TargettingParams from "../../components/TargettingParams/TargettingParams";
import { numberOfSetsOfferWall } from "../../constants/targettingParamsConstants";
import { fetchAllAdvertisers } from "../../store/listings/offlineConversion";

const CreateOfferWall = forwardRef(({ id, isSidePanel = true }, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { basics = {}, targettingParam = {} } = useSelector(state =>
        get(state, "listings.offerWall.createOfferWall", {})
    );
    const { name = "", maxSlotsToReturn } = basics;
    const offerWallData = useSelector(state => state.listings.offerWall);
    const { selectedSurveyIds = [] } = offerWallData.createOfferWall.basics;
    let data = useSelector(state =>
        get(state, `listings.offerWall.createOfferWall.slots`, {})
    );
    const { loading, mainLoader } = useSelector(
        state => state.listings.offerWall
    );
    const showLeftPanel = useSelector(
        state => state.globalConfig.showLeftPanel
    );
    const [showPrompt, setShowPrompt] = useState(true);
    const urlParams = useParams();
    const offerWallId = !isSidePanel ? id : urlParams?.id;
    const surveys = useSelector(state => state.listings.offerWall.surveyList);
    const creativesList = useSelector(
        state => state.listings.adUnits.createAdUnit.slots.adCreativesList
    );
    const [surveySelectError, setSurveySelectError] = useState(false);

    const authUser = useSelector(state => state.auth.user);
    const isReadOnly = !authUser.modules.includes(`Deals.OfferWall.Write`);

    useEffect(() => {
        const editPhaseData = async () => {
            dispatch(createOfferWallActions.loading(true));
            dispatch(createOfferWallActions.resetOfferWall());
            dispatch(fetchSurveys());
            dispatch(fetchAllAdvertisers());
            if (!creativesList.length) {
                await dispatch(fetchAdCreatives());
            }
            if (offerWallId) {
                await dispatch(fetchOfferWallDataById(offerWallId));
            } else {
                if (nameRef.current) nameRef.current.focus();
            }
            await dispatch(createOfferWallActions.loading(false));
        };
        editPhaseData();
        return () => {
            dispatch(createOfferWallActions.setOfferWallBasicError(false));
            dispatch(createOfferWallActions.setOfferPathSlotsError(false));
        };
    }, []);

    const nameRef = useRef(null);
    const onSaveOfferWallHandler = () => {
        const isFormValid = validateAllFields();
        if (isFormValid) {
            offerWallId ? updateOfferWall(offerWallId) : createOfferWall();
        }
    };
    const validateSlots = () => {
        let offerWallSlotsError = false;
        let allSoftDeleteOfferWall = false;
        const softDeletedMainSlotsIds = get(data, "deletedSlots", []).map(
            item => {
                return item.id;
            }
        );

        const softDeletedSlotsIds = get(data, "deletedSlotAds", []).map(
            item => {
                return item?.ad?.id;
            }
        );

        let isOfferWallSlotsValid = !!get(data, "selectedListItems", []).length;

        if (isOfferWallSlotsValid) {
            let length = get(data, "selectedListItems", []).length;
            let softDeletedLength = 0;
            get(data, "selectedListItems", []).forEach(element => {
                if (softDeletedMainSlotsIds.includes(element.id)) {
                    softDeletedLength = softDeletedLength + 1;
                }
            });

            if (length === softDeletedLength) isOfferWallSlotsValid = false;
        }
        const invalidSlots = get(data, "selectedListItems", []).filter(item => {
            if (item.type !== "OfferWall") return !item.editCreativeData.length;
        });
        const offerWallWithAllSoftDelete = get(
            data,
            "selectedListItems",
            []
        ).filter(item => {
            if (!softDeletedMainSlotsIds.includes(item.id)) {
                let creativeLength = item.editCreativeData.length;
                let softDeleteLength = 0;
                if (item.type !== "OfferWall" && item.editCreativeData.length) {
                    item.editCreativeData.forEach(element => {
                        if (softDeletedSlotsIds.includes(element.id)) {
                            softDeleteLength = softDeleteLength + 1;
                        }
                    });
                    if (creativeLength == softDeleteLength) return item;
                }
            }
        });

        if (offerWallWithAllSoftDelete.length > 0) {
            allSoftDeleteOfferWall = true;
        }

        if (
            !isOfferWallSlotsValid ||
            invalidSlots.length !== 0 ||
            allSoftDeleteOfferWall
        )
            offerWallSlotsError = true;
        dispatch(
            createOfferWallActions.setOfferPathSlotsError({
                page: "",
                data: offerWallSlotsError,
            })
        );
        return {
            isOfferWallSlotsValid,
            invalidSlots,
            allSoftDeleteOfferWall,
            offerWallWithAllSoftDelete,
        };
    };
    const validateAllFields = () => {
        const isOfferWallNameValid = nameRef.current.validate();
        const {
            isOfferWallSlotsValid,
            invalidSlots,
            allSoftDeleteOfferWall,
            offerWallWithAllSoftDelete,
        } = validateSlots();
        let offerWallBasicError = false;
        setSurveySelectError(selectedSurveyIds === null);
        if (
            isOfferWallNameValid &&
            selectedSurveyIds !== null &&
            isOfferWallSlotsValid &&
            invalidSlots.length === 0 &&
            !allSoftDeleteOfferWall
        ) {
            return true;
        } else {
            if (!isOfferWallNameValid || selectedSurveyIds === null)
                offerWallBasicError = true;
            if (
                !isOfferWallSlotsValid ||
                invalidSlots.length !== 0 ||
                allSoftDeleteOfferWall
            ) {
                if (!isOfferWallSlotsValid) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Enter atleast one offer wall slot",
                        })
                    );
                }
                if (invalidSlots.length || allSoftDeleteOfferWall) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "All slots must have atleast one creative",
                        })
                    );
                    let ids = offerWallWithAllSoftDelete.map(item => item.id);
                    dispatch(
                        createOfferWallActions.addSelectedListItems({
                            page: "",
                            data: get(data, "selectedListItems", []).map(
                                item => {
                                    if (
                                        (ids.includes(item.id) ||
                                            item.editCreativeData.length ===
                                                0) &&
                                        item.type !== "OfferWall"
                                    ) {
                                        return {
                                            ...item,
                                            showAccordionTable: true,
                                        };
                                    } else return item;
                                }
                            ),
                        })
                    );
                }
            }
        }
        dispatch(
            createOfferWallActions.setOfferWallBasicError(offerWallBasicError)
        );
    };

    useImperativeHandle(ref, () => {
        return {
            validateAllFields: validateAllFields,
        };
    });

    const createOfferWall = () => {
        dispatch(createOfferWallActions.setMainLoader(true));
        dispatch(createOfferWallData())
            .unwrap()
            .then(response => {
                setShowPrompt(false);
                if (response) {
                    dispatch(createOfferWallActions.setMainLoader(false));
                    dispatch(
                        toastListActions.setToastList({
                            type: "Success",
                            message: "Created Offer Wall successfully",
                        })
                    );
                    navigateToListing();
                }
            });
    };

    const updateOfferWall = id => {
        dispatch(createOfferWallActions.setMainLoader(true));
        dispatch(updateOfferWallData(id))
            .unwrap()
            .then(response => {
                setShowPrompt(false);
                if (response) {
                    dispatch(createOfferWallActions.setMainLoader(false));
                    dispatch(
                        toastListActions.setToastList({
                            type: "Success",
                            message: "Updated Offer Wall successfully",
                        })
                    );
                    navigateToListing();
                }
            });
    };

    const navigateToListing = () => {
        history.replace({ pathname: `/deals/offerwall/listings` });
        setShowPrompt(false);
    };

    return (
        <div className="w-100">
            {mainLoader && <LinearDeterminate />}

            {isSidePanel && (
                <Card className={"sticky-below-header"}>
                    <CreateSaveHeader
                        isBig={true}
                        title={`${offerWallId ? "Edit" : "Create"} Offer Wall`}
                        saveLabel={`${offerWallId ? "Save" : "Create"}`}
                        desc={
                            "Create Offer Wall which can be linked to various Surveys"
                        }
                        showPrompt={!isReadOnly && showPrompt}
                        onSave={!isReadOnly && onSaveOfferWallHandler}
                        loading={loading}
                    ></CreateSaveHeader>
                </Card>
            )}

            <div className="dp-parent">
                {isSidePanel && (
                    <LeftPanel
                        isEdit={!!offerWallId}
                        data={offerWall}
                        offset={160}
                        scrollOffset={155}
                        path={`listings.offerWall.createOfferWall`}
                        errorState={`error`}
                        loading={loading}
                    />
                )}
                <div
                    className={`rightPanel ${
                        !showLeftPanel ? "rightPanel__expanded" : ""
                    }  ${!isSidePanel ? "rightPanel__expanded" : ""}
                    ${!isSidePanel ? "pl-0" : ""}
                    `}
                >
                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            <Card>
                                <OfferWallBasics
                                    id={offerWallId}
                                    type="create"
                                    name={name}
                                    surveys={surveys}
                                    offerLimitValue={maxSlotsToReturn}
                                    selectedSurveyIds={selectedSurveyIds}
                                    surveySelectError={surveySelectError}
                                    allRefs={{ nameRef }}
                                    onChangeName={e => {
                                        dispatch(
                                            createOfferWallActions.setOfferWallName(
                                                e
                                            )
                                        );
                                        if (e?.length <= 0) {
                                            dispatch(
                                                createOfferWallActions.setOfferWallBasicError(
                                                    true
                                                )
                                            );
                                        }
                                    }}
                                    onSelectSurvey={survey => {
                                        dispatch(
                                            createOfferWallActions.setSelectedSurveyIds(
                                                get(survey, "id", null)
                                            )
                                        );
                                        setSurveySelectError(false);
                                        dispatch(
                                            createOfferWallActions.setOfferWallBasicError(
                                                false
                                            )
                                        );
                                    }}
                                    onChangeOfferLimit={e => {
                                        dispatch(
                                            createOfferWallActions.setOfferWallLimit(
                                                e
                                            )
                                        );
                                    }}
                                    // isSidePanel={isSidePanel}
                                    readOnly={isReadOnly}
                                />
                            </Card>
                            <Card>
                                <OfferWallSlots
                                    fetchDealIdsApi={fetchDealIds}
                                    creativesList={creativesList}
                                    action={createOfferWallActions}
                                    data={data}
                                    pageId={""}
                                    readOnly={isReadOnly}
                                    featureCopyData={{
                                        sourceId: [offerWallId],
                                        sourceName: name,
                                        sourcePage: "Offer Wall",
                                        sourcePath: ["Offer Wall", "Slots"],
                                        copiedData: get(
                                            data,
                                            "selectedListItems",
                                            []
                                        ).filter(
                                            selected =>
                                                !get(data, "deletedSlots", [])
                                                    .map(_ => _.id)
                                                    .includes(selected.id)
                                        ),
                                        type: "slots",
                                    }}
                                />
                            </Card>
                            {isSidePanel && (
                                <Card>
                                    <TargettingParams
                                        numberOfSets={numberOfSetsOfferWall}
                                        targetParams={get(
                                            targettingParam,
                                            "targetParams",
                                            {}
                                        )}
                                        dropDownState={get(
                                            targettingParam,
                                            "dropDownState",
                                            {}
                                        )}
                                        zipCodeState={get(
                                            targettingParam,
                                            "zipCodeState",
                                            {}
                                        )}
                                        actions={createOfferWallActions}
                                        hash={"offerPath"}
                                        readOnly={isReadOnly}
                                        featureCopyData={{
                                            sourceId: [offerWallId],
                                            sourceName: name,
                                            sourcePage: "Offer Wall",
                                            sourcePath: [
                                                "Offer Wall",
                                                "Targetting Params",
                                            ],
                                            copiedData: get(
                                                targettingParam,
                                                "targetParams",
                                                {}
                                            ),
                                            type: "params",
                                        }}
                                        keyValuesNewType={true}
                                    />
                                </Card>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export default CreateOfferWall;
