import axios from "axios";
import * as Sentry from "@sentry/react";

export default async function fetchAll(promises = []) {
    let response;
    try {
        response = await axios.all(promises);
    } catch (e) {
        Sentry.captureException(e);
        response = e;
    }
    return response;
}
