import { useState, useEffect, useRef, forwardRef } from "react";
import { useSelector } from "react-redux";
import FlowTargettingParams from "../../components/FlowTargettingParams/FlowTargettingParams";
import { numberOfSetsSurveyPath } from "../../constants/targettingParamsConstants";
import ContainerFlowRenderer from "../../Shared/FlowRenderer/ContainerFlowRenderer";
import noDataWithFilters from "../../assets/img/noDataWithFilters.svg";
import noDataWithFilters_Dark from "../../assets/img/noDataWithFilters-dark.svg";
import styles from "./ContainerPreview.module.scss";

export const ContainerPreview = forwardRef(
    (
        {
            id,
            containers,
            addNode,
            deleteNode,
            rules,
            startId,
            readOnly,
            vertical,
            featureGroupProperties = {},
        },
        ref
    ) => {
        const [container, setContainer] = useState();
        const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);
        useEffect(() => {
            if (containers.length) {
                let selectedContainer = containers.find(_ => _.id === id);
                setContainer(selectedContainer);
            }
        }, [id]);

        return (
            <>
                {container?.items?.length > 0 ? (
                    <ContainerFlowRenderer
                        featureGroupProperties={featureGroupProperties}
                        ref={ref}
                        startId={startId}
                        container={container}
                        addNode={addNode}
                        rules={rules}
                        deleteNode={deleteNode}
                        readOnly={readOnly}
                        vertical={vertical}
                    ></ContainerFlowRenderer>
                ) : (
                    <div className={styles.noContainerWrap}>
                        <img
                            src={
                                isDarkMode
                                    ? noDataWithFilters_Dark
                                    : noDataWithFilters
                            }
                            alt={"This container does not have any details"}
                        />

                        <div className={styles.txt}>
                            This container does not have any details
                        </div>
                    </div>
                )}
            </>
        );
    }
);

export default ContainerPreview;
