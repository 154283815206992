import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { DEALS, SURVEYS, HNP_URL, MAX_ENDPOINT } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import {
    getRandomId,
    getRequiredObject,
    isArrayEqual,
    getInitialRowsCount,
    mapTargettingParams,
    keyValsFromApi,
    getTargettingParams,
} from "../../utils/globalUtils";
import {
    setPaginationStartOffset,
    paginationParams,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import {
    adGroupPostApiData,
    adGroupPutApiData,
    adSlotsPostApiData,
    adSlotsPutApiData,
    deleteSlotsAdsApiData,
    deleteSlotsApiData,
    slotTypeSmallCase,
} from "../../utils/offerWallUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";

const initialTargetParamsState = {
    region: [],
    countries: [],
    browsers: [],
    devices: [],
    os: [],
    genders: [],
    utmMediums: [],
    utmSources: [],
    utmCampaigns: [],
    utmContents: [],
    ageRange: [],
    keywords: [],
    availableSet: ["utmSources", "region", "keywords", "ageRange"],
    keyVals: [],
    pagination: {
        startOffset: 0,
        rowsCount: 10,
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.OFFER_PATH.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.OFFER_PATH.filterOperator,
        filtersOperator: FILTERS.OFFER_PATH.operator,
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

const initialState = {
    list: [],
    updatedAt: new Date(),
    loading: false,
    mainLoader: false,
    surveyList: [],
    createOfferWall: {
        adSlotsData: [],
        basics: {
            name: "",
            error: false,
            // selectedSurveyIds: [],
            selectedSurveyIds: null,
            maxSlotsToReturn: "",
        },
        slots: {
            error: false,
            index: "",
            type: "",
            selectedListItems: [],
            editIndex: "",
            editType: "WeightGroup",
            editTrafficSplit: "",
            editId: "",
            toggle: false,
            weight: "",
            dealId: "",
            dedupeRules: [],
            revenueType: null,
            creative: null,
            editWeight: "",
            editDealId: "",
            editRevenueType: null,
            editCreative: null,
            selectedMode: null,
            selectedRowId: null,
            rowTypeMode: "",
            // toggleHistory: false,
            historyData: [],
            deletedSlots: [],
            deletedSlotAds: [],
            expandCollapseTable: false,
        },
        targettingParam: {
            targetParams: initialTargetParamsState,
            dropDownState: {
                plusDropdownData: "",
                showPlusDropdown: false,
                productList: [
                    {
                        id: 1,
                        firstName: "abcd",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: 2,
                        firstName: "eafgbh",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: 3,
                        firstName: "ijkl",
                        lastName: "rewardsurveyusa.com",
                    },
                    { id: 4, firstName: "mnop", lastName: "test@test.com" },
                    { id: 5, firstName: "qrst", lastName: "test@test.com" },
                    { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                    { id: 7, firstName: "yz", lastName: "test@test.com" },
                ],
                productListPlusData: [
                    {
                        id: "utmSources",
                        firstName: "UTM Sources",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: "region",
                        firstName: "Region",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: "keywords",
                        firstName: "Keywords",
                        lastName: "rewardsurveyusa.com",
                    },
                    {
                        id: "ageRange",
                        firstName: "Age Range",
                        lastName: "test@test.com",
                    },
                    {
                        id: "genders",
                        firstName: "Gender",
                        lastName: "test@test.com",
                    },
                    {
                        id: "countries",
                        firstName: "Countries",
                        lastName: "test@test.com",
                    },
                    {
                        id: "zipCodes",
                        firstName: "Zip Codes",
                        lastName: "test@test.com",
                    },
                    {
                        id: "timeOfDay",
                        firstName: "Time of Day",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmCampaigns",
                        firstName: "UTM Campaigns",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmMediums",
                        firstName: "UTM Mediums",
                        lastName: "test@test.com",
                    },
                    {
                        id: "os",
                        firstName: "Operating Systems",
                        lastName: "test@test.com",
                    },
                    {
                        id: "browsers",
                        firstName: "Browsers",
                        lastName: "test@test.com",
                    },
                    {
                        id: "devices",
                        firstName: "Devices",
                        lastName: "test@test.com",
                    },
                    {
                        id: "allowedDays",
                        firstName: "Allowed Days",
                        lastName: "test@test.com",
                    },
                    {
                        id: "keyVals",
                        firstName: "A/B Tests",
                        lastName: "test@test.com",
                    },
                ],
            },
            zipCodeState: {
                csvFileName: "",
                csvFile: "",
                csvArray: [],
                zipCodeError: false,
                onDropActiveZipCode: false,
                // zipCode: [],
            },
        },
    },
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.OFFER_WALL.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.OFFER_WALL.filterOperator,
        filtersOperator: FILTERS.OFFER_WALL.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "offerwall"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchSurveys = createAsyncThunk(
    "listings/offerwall/surveys",
    async (_, { dispatch }) => {
        const response = await customFetch(SURVEYS.FETCH_SURVEYS, "GET");
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Surveys",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const fetchDealIds = createAsyncThunk(
    "listings/offerWall/fetchDealIds",
    async (advertiserId, { dispatch }) => {
        const params = {
            url: `${MAX_ENDPOINT.GET_DEAL_ID}/${advertiserId}/sponsorshipdeals`,
            options: {
                headers: {
                    "Content-type": "application/json",
                },
                method: "GET",
            },
        };
        const response = await customFetch(HNP_URL.MAX_PROXY, "POST", params);

        return { data: response.data.data };
    }
);

export const fetchOfferWallData = createAsyncThunk(
    "listings/offerwall/fetchOfferWallListingData",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const pagination = getState().listings.offerWall.pagination;
        const params = paginationParams(pagination);
        const pageParamsList = ["offset", "limit"];
        let tagFilters = get(
            listings,
            "offerWall.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.OFFER_WALL
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(offerWallSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.offerWall.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.OFFER_WALL,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.offerWall.list", []);
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                DEALS.FETCH_OFFER_WALL,
                "POST",
                filterParamsData,
                {},
                null,
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch offer wall list",
                        })
                    );
                    dispatch(
                        offerWallSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(
                createOfferWallActions.setFilter(filterParamsData?.filters)
            );
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.OFFER_WALL}`;
            dispatch(offerWallSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Offer Wall`;
            dispatch(offerWallSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchOfferWallDataById = createAsyncThunk(
    "listings/offerwall/fetchOfferWallDataById",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${DEALS.GET_OFFER_WALL_BY_ID}/${id}`,
            "GET"
        );
        const creativesList =
            getState().listings.adUnits.createAdUnit.slots.adCreativesList;
        const data = get(response, "data.data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch offer wall data",
                })
            );
        return [data, creativesList];
    }
);

export const deleteOfferWallData = createAsyncThunk(
    "listings/offerwall/deleteOfferWall",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${DEALS.DELETE_OFFER_WALL}/${id}`,
            "DELETE"
        );
        if (get(response, "status", "") != 200)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete the offer wall",
                })
            );
        return response;
    }
);

export const updateOfferWallData = createAsyncThunk(
    "listings/offerwall/updateOfferWall",
    async (id, { dispatch, getState }) => {
        const offerWallState = getState().listings.offerWall.createOfferWall;
        const params = getOfferWallParams(offerWallState);
        const response = await customFetch(
            `${DEALS.UPDATE_OFFER_WALL}/${id}`,
            "PUT",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update the offer wall",
                })
            );
        } else {
            const data = {
                state: offerWallState,
                id: id,
            };

            dispatch(deleteAdSlot());
            dispatch(deleteOfferWallSlotsAds());
            const updateAdSlots = await dispatch(updateAdSlot(data)).unwrap();
            const obj = {
                slots: data.state.slots.selectedListItems,
                responses: updateAdSlots,
                existingData: data.state.adSlotsData,
            };
            dispatch(updateGroup(obj));
        }
        return response;
    }
);

export const createOfferWallData = createAsyncThunk(
    "listings/offerwall/createOfferWall",
    async (_, { dispatch, getState }) => {
        const offerWallState = getState().listings.offerWall.createOfferWall;
        const params = getOfferWallParams(offerWallState);
        const response = await customFetch(
            DEALS.CREATE_OFFER_WALL,
            "POST",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create the offer wall",
                })
            );
        } else {
            const data = {
                state: offerWallState,
                id: response.data.data.id,
            };
            dispatch(createAdSlot(data))
                .unwrap()
                .then(res => {
                    const obj = {
                        slots: data.state.slots.selectedListItems,
                        responses: res,
                    };
                    dispatch(createGroup(obj));
                });
        }
        return response;
    }
);

const createAdSlot = createAsyncThunk(
    "listings/offerwall/createAdSlots",
    async (data, { getState, dispatch }) => {
        const adSlots = data.state.slots.selectedListItems;
        if (adSlots && adSlots.length) {
            const postData = adSlotsPostApiData(adSlots, data.id);
            return await fetchAll(postData);
        }
        return;
    }
);

const updateAdSlot = createAsyncThunk(
    "listings/offerwall/updateAdSlots",
    async (data, { getState, dispatch }) => {
        const adSlots = data.state.slots.selectedListItems;
        if (adSlots && adSlots.length) {
            const slotsToBeDeleted =
                getState().listings.offerWall.createOfferWall.slots
                    .deletedSlots;

            let updatedAdSlots = adSlots.filter(adSlot =>
                slotsToBeDeleted.every(
                    deletedSlot => deletedSlot.rowId !== adSlot.rowId
                )
            );
            if (!isArrayEqual(adSlots, updatedAdSlots)) {
                updatedAdSlots = updatedAdSlots.map((item, idx) => ({
                    ...item,
                    index: idx + 1,
                    isEdit: true,
                }));
                dispatch(
                    offerWallSlice.actions.addSelectedListItems({
                        page: "",
                        data: updatedAdSlots,
                    })
                );
            }
            const createSlots = updatedAdSlots.filter(slot => slot.id === "-");
            const updateSlots = updatedAdSlots.filter(
                slot => slot.id !== "-" && slot.isEdit
            );
            const createSlotsApi = adSlotsPostApiData(createSlots, data.id);
            const updateSlotsApi = adSlotsPutApiData(updateSlots, data.id);
            return await fetchAll([...createSlotsApi, ...updateSlotsApi]);
        }
        return;
    }
);

const deleteAdSlot = createAsyncThunk(
    "listings/offerwall/deleteslots",
    async (_, { getState }) => {
        const slotsToBeDeleted =
            getState().listings.offerWall.createOfferWall.slots.deletedSlots;
        const deletedSlotsApi = deleteSlotsApiData(slotsToBeDeleted);
        return await fetchAll(deletedSlotsApi);
    }
);

const createGroup = createAsyncThunk(
    "listings/offerwall/creategroups",
    async (data, { dispatch }) => {
        const groupsApi = [];
        data.slots.forEach((slot, index) => {
            if (!slot.editCreativeData.length) return;
            const adGroupPostData = adGroupPostApiData(
                slot,
                index,
                data.responses
            );
            groupsApi.push(adGroupPostData);
        });
        return await fetchAll(groupsApi);
    }
);

const updateGroup = createAsyncThunk(
    "listings/offerwall/updategroups",
    async (data, { getState }) => {
        let groupsApi = [];
        data.slots.forEach((slot, index) => {
            if (slot.editCreativeData.length) {
                if (slot.id === "-") {
                    const adGroupApiData = adGroupPostApiData(
                        slot,
                        index,
                        data.responses
                    );
                    groupsApi.push(adGroupApiData);
                } else {
                    const existingSlotData = data.existingData.find(
                        data => data.id == slot.id
                    );
                    if (
                        !existingSlotData ||
                        !existingSlotData[slotTypeSmallCase[slot.type]]
                    ) {
                        const adGroupApiData = adGroupPostApiData(
                            slot,
                            index,
                            data.responses
                        );
                        groupsApi.push(adGroupApiData);
                    } else {
                        const adGroupApiData = adGroupPutApiData(
                            slot,
                            existingSlotData[slotTypeSmallCase[slot.type]]
                        );
                        groupsApi.push(adGroupApiData);
                    }
                }
            }
        });
        return await fetchAll(groupsApi);
    }
);

export const deleteOfferWallSlotsAds = createAsyncThunk(
    "listings/offerwall/deleteOfferWallSlotsAds",
    async (_, { getState }) => {
        const adsToBeDeleted =
            getState().listings.offerWall.createOfferWall.slots.deletedSlotAds;
        const deletedSlotAdsApi = deleteSlotsAdsApiData(adsToBeDeleted);
        return await fetchAll(deletedSlotAdsApi);
    }
);

const group = {
    WeightGroup: "wg",
};

const ads = {
    WeightGroup: "wa",
};

const groupAds = {
    WeightGroup: "weightedAds",
};

const getOfferWallParams = offerWallState => {
    const obj = {
        name: offerWallState.basics.name,
        sid: offerWallState.basics.selectedSurveyIds,
        maxSlotsToReturn:
            offerWallState.basics.maxSlotsToReturn === ""
                ? -1
                : offerWallState.basics.maxSlotsToReturn,
        targetParams: getTargettingParams(
            offerWallState.targettingParam.targetParams,
            true,
            null,
            true,
            true,
            true
        ),
    };
    return obj;
};

const changeToggle = (array, id) => {
    let expandAllFlag = null;
    let expandLength = 0;
    let collapseLength = 0;
    let selectedListItemsCopy = JSON.parse(JSON.stringify(array));
    selectedListItemsCopy.map(item => {
        if (item.rowId == id) {
            return (item.showAccordionTable = !item.showAccordionTable);
        }
    });
    selectedListItemsCopy.forEach(element => {
        if (element.showAccordionTable) {
            expandLength = expandLength + 1;
        }
        if (!element.showAccordionTable) {
            collapseLength = collapseLength + 1;
        }
    });
    if (expandLength === selectedListItemsCopy.length) expandAllFlag = true;
    if (collapseLength === selectedListItemsCopy.length) expandAllFlag = false;
    return { selectedListItemsCopy, expandAllFlag };
};

const param = {
    WeightGroup: "weight",
};

const mapCreatives = (slot, list) => {
    const ads = {
        WeightGroup: "weightGroup",
    };
    const groupType = ads[slot.type];
    if (slot[groupType]) {
        return slot[groupType][groupAds[slot.type]].map(data => {
            let getindex = list.findIndex(
                element => element.id == data.creativeId
            );
            return {
                id: data.id,
                // creative: data.creativeId,
                creative: `${
                    list[getindex] && list[getindex].key
                        ? list[getindex].key
                        : "-"
                }`,
                dedupeRules: data.dedupeKeys || [],
                dealId: data.dealId,
                [param[slot.type]]: data[param[slot.type]],
                revenueType: data.revType,
                innerRowId: getRandomId(),
                customKeyVal: data.customKeyVal || [],
            };
        });
    }
    return [];
};

export const onFilterApplied = createAsyncThunk(
    "listings/offerWall/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.offerWall.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.OFFER_WALL.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const offerWallSlice = createSlice({
    name: "offerWall",
    initialState,
    reducers: {
        setOfferWallName(state, action) {
            state.createOfferWall.basics.name = action.payload;
        },
        setOfferWallLimit(state, action) {
            state.createOfferWall.basics.maxSlotsToReturn = action.payload;
        },
        setOfferWallBasicError(state, action) {
            state.createOfferWall.basics.error = action.payload;
        },
        setOfferPathSlotsError(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.error = data;
        },
        addIndex(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.index = data;
        },
        addType(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.type = data;
        },
        addSelectedListItems(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.selectedListItems = data;
        },
        addEditIndex(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editIndex = data;
        },
        setEditType(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editType = data;
        },
        setEditTrafficSplit(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editTrafficSplit = data;
        },
        setEditId(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editId = data;
        },
        setToggle(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.toggle = data;
        },
        addWeight(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.weight = data;
        },
        addDealId(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.dealId = data;
        },
        addRevenueType(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.revenueType = data;
        },
        addCreative(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.creative = data;
        },
        editWeight(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editWeight = data;
        },
        editDealId(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editDealId = data;
        },
        editRevenueType(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editRevenueType = data;
        },
        editCreative(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.editCreative = data;
        },
        selectedMode(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.selectedMode = data;
        },
        selectedRowId(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.selectedRowId = data;
        },
        rowTypeMode(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.rowTypeMode = data;
        },
        toggleShowAccordionTable(state, action) {
            const { data, list } = action.payload;
            const { selectedListItemsCopy, expandAllFlag } = changeToggle(
                list,
                data
            );
            state.createOfferWall.slots.selectedListItems =
                selectedListItemsCopy;
            if (expandAllFlag != null) {
                state.createOfferWall.slots.expandCollapseTable = expandAllFlag;
            }
        },
        expandCollapseTableToggle(state, action) {
            const { page, data } = action.payload;
            state.createOfferWall.slots.expandCollapseTable = data;
        },
        expandCollapseTable(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.selectedListItems = data;
        },
        toggleHistory(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.toggleHistory = data;
        },
        historyData(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.historyData = data;
        },
        setTargetParams(state, action) {
            const { data } = action.payload;
            state.createOfferWall.targettingParam.targetParams = { ...data };
        },
        setDropDownState(state, action) {
            const { data } = action.payload;
            state.createOfferWall.targettingParam.dropDownState = { ...data };
        },
        setCsvFileName(state, action) {
            const { data } = action.payload;
            state.createOfferWall.targettingParam.zipCodeState.csvFileName =
                data;
        },
        setCsvFile(state, action) {
            const { data } = action.payload;
            state.createOfferWall.targettingParam.zipCodeState.csvFile = data;
        },
        setCsvArray(state, action) {
            const { data } = action.payload;
            state.createOfferWall.targettingParam.zipCodeState.csvArray = data;
        },
        setZipCodeError(state, action) {
            const { data } = action.payload;
            state.createOfferWall.targettingParam.zipCodeState.zipCodeError =
                data;
        },
        setOnDropActiveZipCode(state, action) {
            const { data } = action.payload;
            state.createOfferWall.targettingParam.zipCodeState.onDropActiveZipCode =
                data;
        },
        loading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        resetOfferWall(state) {
            state.createOfferWall.basics.selectedSurveyIds = null;
            state.createOfferWall.basics.name = "";
            state.createOfferWall.basics.maxSlotsToReturn = "";
            state.createOfferWall.slots.selectedListItems = [];
            state.createOfferWall.slots.deletedSlots = [];
            state.createOfferWall.slots.deletedSlotAds = [];
            state.createOfferWall.slots.index = "";
            state.createOfferWall.slots.type = "";
            state.createOfferWall.slots.expandCollapseTable = false;
            state.createOfferWall.targettingParam.targetParams =
                initialTargetParamsState;
        },
        setSurveys(state, action) {
            state.createOfferWall.basics.surveys = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSelectedSurveyIds(state, action) {
            state.createOfferWall.basics.selectedSurveyIds = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        revertDeleteSlots(state, action) {
            const { data } = action.payload;
            const index = state.createOfferWall.slots.deletedSlots.findIndex(
                _ => _.id === data.id
            );
            state.createOfferWall.slots.deletedSlots.splice(index, 1);
        },
        setDeletedSlots(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.deletedSlots.push(data);
        },
        revertDeleteSlotAds(state, action) {
            const { data, page } = action.payload;
            const index = state.createOfferWall.slots.deletedSlotAds.findIndex(
                _ => _.ad.id === data.id
            );
            state.createOfferWall.slots.deletedSlotAds.splice(index, 1);
        },
        setDeletedSlotAds(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.deletedSlotAds.push(data);
        },
        setHistoryData(state, action) {
            state.createOfferWall.slots.historyData = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
    },
    extraReducers: builder => {
        // builder.addCase(fetchSurveys.pending, state => {
        //     // state.loading = true;
        // });
        builder.addCase(fetchSurveys.fulfilled, (state, action) => {
            state.surveyList = action.payload.map(survey => ({
                id: survey.id,
                name: `${survey.id}: ${survey.name}`,
            }));
        });
        builder.addCase(fetchOfferWallData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchOfferWallData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        // builder.addCase(fetchOfferWallDataById.pending, (state, action) => {
        //     // state.loading = true;
        // });
        builder.addCase(fetchOfferWallDataById.fulfilled, (state, action) => {
            state.createOfferWall.adSlotsData =
                action.payload[0]?.offerWallAdSlots;
            state.createOfferWall.basics.name = action.payload[0]?.name;
            state.createOfferWall.basics.maxSlotsToReturn =
                action.payload[0]?.maxSlotsToReturn === -1
                    ? ""
                    : action.payload[0]?.maxSlotsToReturn;
            state.createOfferWall.basics.selectedSurveyIds =
                action.payload[0].sid;
            state.createOfferWall.slots.selectedListItems =
                action.payload[0]?.offerWallAdSlots
                    ?.sort((a, b) => a.slotIndex - b.slotIndex)
                    ?.map((slot, i) => ({
                        index: i + 1,
                        id: slot.id,
                        type: slot.type,
                        editCreativeData: mapCreatives(slot, action.payload[1]),
                        rowId: getRandomId(),
                    }))
                    .sort((a, b) => a.index - b.index);
            state.createOfferWall.targettingParam.targetParams =
                mapTargettingParams(action.payload[0]?.targetParams, true);
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const createOfferWallActions = offerWallSlice.actions;
export default offerWallSlice.reducer;
