import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { JTLOGO, MAIN } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import customFetch from "../../fetch/customFetch";
import { get, isEmpty } from "lodash";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    // updatedAt: new Date(),
    createQuestion: {
        basics: {
            question: "",
            subHeader: "",
            name: "",
            type: "",
            logoImageSrc: null,
            bannerImageSrc: null,
            answers: [],
            hasOther: false,
            error: false,
            tag: "",
        },
    },
    createQuestionBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.FLOW_QUESTIONS.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.FLOW_QUESTIONS.filterOperator,
        filtersOperator: FILTERS.FLOW_QUESTIONS.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "question"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    historyData: [],
    historyDataRaw: [],
    allQuestionList: [],
};

export const fetchQuestionsData = createAsyncThunk(
    "flowmanager/question/fetchQuestionsListingData",
    async ({ refreshClicked, noPagination }, { dispatch, getState }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "questions.pagination", {});
        const params = noPagination ? {} : paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "questions.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.FLOW_QUESTIONS
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(questionsSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "flowmanager.questions.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.FLOW_QUESTIONS,
                filterParamsList
            );
        }
        const listingData = get(getState(), "flowmanager.questions.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                MAIN.GET_ALL_QUESTIONS,
                "POST",
                filterParamsData,
                {},
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch questions",
                        })
                    );
                    dispatch(
                        questionsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(questionsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data.data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.FLOW_QUESTION}`;
            dispatch(questionsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Question`;
            dispatch(questionsSlice.actions.setTableError(errorMessage));
        }

        return data;
    }
);

export const createQuestions = createAsyncThunk(
    "flowmanager/question/create",
    async (params, { dispatch }) => {
        let isConcurreny = false;
        const response = await customFetch(
            MAIN.SAVE_QUESTION,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Question already modified while you were editing",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload question",
                        })
                    );
                }
                if (resData?.message?.includes("Data too long for column")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: resData.message,
                            autoClose: 5000,
                        })
                    );
                }

                isConcurreny = true;
            }
        );
        if (get(response, "data.status", false) !== "success" && !isConcurreny)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to create the segment! Please try again later!",
                })
            );
        return get(response, "data.status", false) === "success";
    }
);

export const updateQuestions = createAsyncThunk(
    "flowmanager/question/edit",
    async (params, { dispatch }) => {
        let isConcurreny = false;
        const response = await customFetch(
            MAIN.SAVE_QUESTION,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Question already modified while you were editing",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload question",
                        })
                    );
                }
                if (resData?.message?.includes("Data too long for column")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: resData.message,
                            autoClose: 5000,
                        })
                    );
                }
                isConcurreny = true;
            }
        );
        if (get(response, "data.status", false) !== "success" && !isConcurreny)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to update the segment! Please try again later!",
                })
            );
        return get(response, "data.status", false) === "success";
    }
);

export const fetchLogoListings = createAsyncThunk(
    "flowmanager/question/logoListings",
    async (params, { dispatch }) => {
        const response = await customFetch(JTLOGO.FETCH_JTLOGO_LIST, "POST", {
            prefix: "ragnarok/question/logos",
        });
        if (get(response, "success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch logo listings!",
                })
            );
        return get(response, "data.data", []);
    }
);

export const fetchBannerListings = createAsyncThunk(
    "flowmanager/question/bannerListings",
    async (params, { dispatch }) => {
        const response = await customFetch(JTLOGO.FETCH_JTLOGO_LIST, "POST", {
            prefix: "ragnarok/question/banner",
        });
        if (get(response, "success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch banner listings!",
                })
            );

        return get(response, "data.data", []);
    }
);

export const checkImageAvailable = createAsyncThunk(
    "flowmanager/question/checkImageAvailable",
    async ({ name, uploadType }, { getState, dispatch }) => {
        const response = await customFetch(
            `${JTLOGO.UPLOAD_IMAGE}/available?imageName=ragnarok/question/${
                uploadType === "logo" ? "logos" : "banners"
            }/${name}`,
            "GET"
        );
        return response;
    }
);

export const uploadNewImage = createAsyncThunk(
    "listings/jtLogo/uploadNewImage",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(JTLOGO.UPLOAD_IMAGE, "POST", params);
        return response;
    }
);

export const fetchQuestionData = createAsyncThunk(
    "flowmanager/question/questionData",
    async (id, { dispatch }) => {
        const response = await customFetch(`${MAIN.GET_QUESTION}/${id}`);
        if (get(response, "data.status", false) !== "success")
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch segment!",
                })
            );
        return response.data.data;
    }
);

export const deleteQuestion = createAsyncThunk(
    "flowmanager/question/delete",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${MAIN.DELETE_QUESTION}/${id}`,
            "POST",
            {},
            {},
            {},
            null,
            null,
            data => {
                const resData = data?.response?.data;
                if (resData && resData.includes("Cannot delete!")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Failed to delete, this question is mapped to some collection(s).`,
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to delete question.",
                        })
                    );
                }
            }
        );
        if (get(response, "data.status", false) === "success") {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Deleted successfully.",
                    autoClose: 5000,
                })
            );
        }
    }
);

export const onFilterApplied = createAsyncThunk(
    "flowmanager/question/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.questions.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.FLOW_QUESTION.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchQuestionsHistory = createAsyncThunk(
    "flowmanager/question/questionHistory",
    async id => {
        const response = await customFetch(
            `${MAIN.FETCH_QUESTION_HISTORY}/${id}`
        );
        return get(response, "data", []);
    }
);

const questionsSlice = createSlice({
    name: "questions",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setCreateQuestionId(state, action) {
            state.createQuestion.basics.id = action.payload;
        },
        setCreateQuestionTitle(state, action) {
            state.createQuestion.basics.question = action.payload;
            state.createQuestion.basics.error = action.payload?.trim() == "";
        },
        setCreateQuestionName(state, action) {
            state.createQuestion.basics.name = action.payload;
            // state.createQuestion.basics.error = action.payload.trim() == "";
        },
        setCreateQuestionSubHeader(state, action) {
            state.createQuestion.basics.subHeader = action.payload;
        },
        setCreateQuestionLogo(state, action) {
            state.createQuestion.basics.logoImageSrc = action.payload;
        },
        setCreateQuestionBannerImge(state, action) {
            state.createQuestion.basics.bannerImageSrc = action.payload;
        },
        setCreateQuestionType(state, action) {
            state.createQuestion.basics.type = action.payload;
        },
        setCreateQuestionHasOther(state, action) {
            state.createQuestion.basics.hasOther = action.payload;
        },
        setCreateQuestionAnswers(state, action) {
            state.createQuestion.basics.answers = action.payload;
        },
        setCreateQuestionTag(state, action) {
            state.createQuestion.basics.tag = action?.payload;
        },
        setQuestionBasicError(state, action) {
            state.createQuestion.basics.error = action.payload;
        },
        resetQuestions(state) {
            state.createQuestion.basics = {
                question: "",
                name: "",
                subHeader: "",
                type: "",
                logoImageSrc: null,
                bannerImageSrc: null,
                answers: [],
                hasOther: false,
                error: false,
                tag: "",
            };
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },

        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchQuestionsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchQuestionsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            // state.updatedAt = new Date();
        });
        builder.addCase(fetchQuestionData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchQuestionData.fulfilled, (state, action) => {
            if (action.payload) {
                const {
                    id,
                    question,
                    subHeader,
                    name,
                    type,
                    hasOther,
                    answers,
                    logoImageSrc,
                    bannerImageSrc,
                    updatedAt,
                    tag,
                } = action.payload;
                state.createQuestion.basics.id = id;
                state.createQuestion.basics.question = question;
                state.createQuestion.basics.subHeader = subHeader;
                state.createQuestion.basics.name = name;
                state.createQuestion.basics.type = type;
                state.createQuestion.basics.hasOther = hasOther;
                state.createQuestion.basics.answers = answers;
                state.createQuestion.basics.logoImageSrc = logoImageSrc;
                state.createQuestion.basics.bannerImageSrc = bannerImageSrc;
                state.createQuestion.basics.updatedAt = updatedAt;
                state.createQuestion.basics.tag = tag;
                state.createQuestionBackup = state.createQuestion;
            }
            state.loading = false;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const questionsActions = questionsSlice.actions;
export default questionsSlice.reducer;
