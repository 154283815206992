import { get } from "lodash";

export const getFeatureMap = featuremapList => {
    return featuremapList?.map(element => ({
        key: element.key,
        scope: get(element, "scope", ""),
        type: element.type,
        description: get(element, "description", ""),
        screenshoturl: get(element, "screenshoturl", ""),
        choice: get(element, "choice", []),
        value: get(element, "value", []),
        valuekind: get(element, "valuekind", ""),
    }));
};

export const getFixedFeatureMap = featuremapList => {
    return featuremapList?.map(element => ({
        key: element.key,
        scope: get(element, "scope", ""),
        type: element.type,
        description: get(element, "description", ""),
        screenshoturl: get(element, "screenshoturl", ""),
        choice: get(element, "choice", []),
        value: get(element, "value", []).filter(val =>
            get(element, "choice", []).includes(val)
        ),
        valuekind: get(element, "valuekind", ""),
    }));
};
