export const PIMacros = [
    {
        text: "Email",
        id: "%%email%%",
    },
    // {
    //     text: "ZIP",
    //     id: "%%zip%%",
    // },
    {
        text: "User Id",
        id: "%%uid%%",
    },
    {
        text: "Session Id",
        id: "%%csid%%",
    },
    {
        text: "Phone Number",
        id: "%%phone-number%%",
    },
];

export const PIMacrosSlotLevel = [
    {
        text: "Email",
        id: "%%email%%",
    },
    {
        text: "User Id",
        id: "%%uid%%",
    },
    {
        text: "Session Id",
        id: "%%csid%%",
    },
    {
        text: "Phone Number",
        id: "%%phone-number%%",
    },
    {
        text: "User IP",
        id: "%%user-ip%%",
    },
];

export const offerGroupPIMacros = [
    {
        text: "Email",
        id: "%%email%%",
    },
    {
        text: "User Id",
        id: "%%user_id%%",
    },
    {
        text: "Session Id",
        id: "%%cs_id%%",
    },
    {
        text: "Phone Number",
        id: "%%phone_number%%",
    },
];

export const offerGroupSlotPIMacros = [
    {
        text: "Email",
        id: "%%email%%",
    },
    {
        text: "User Id",
        id: "%%user_id%%",
    },
    {
        text: "Session Id",
        id: "%%cs_id%%",
    },
    {
        text: "Phone Number",
        id: "%%phone_number%%",
    },
    {
        text: "User IP",
        id: "%%user-ip%%",
    },
];

export const Actions = [
    {
        text: "Impression",
        id: "ad_seen",
    },
    {
        text: "Click",
        id: "ad_clk",
    },
];

export const OfferActions = [
    {
        text: "Impression",
        id: "ad_seen",
    },
    {
        text: "Click",
        id: "ad_clk",
    },
    {
        text: "Conversion",
        id: "acv",
    },
];

export const OfferGroupActions = [
    {
        text: "HNP Lead Success",
        id: "hnp_lead_success",
        preselected: true,
    },
    {
        text: "HNP Lead Failure",
        id: "hnp_lead_failure",
    },
];

export const AdunitActions = [
    {
        text: "Impression",
        id: "ad_seen",
    },
    {
        text: "Click",
        id: "ad_clk",
    },
    {
        text: "Conversion",
        id: "acv",
    },
    {
        text: "Serp Click",
        id: "grat_avail",
    },
    {
        text: "Serp Impression",
        id: "grat_imp",
    },
];

export const OfferpathEntityMacros = [
    {
        text: "Offer Path ID",
        id: "%%offer-path-id%%",
    },
];

export const OfferEntityMacros = [
    {
        text: "Campaign ID",
        id: "%%campaign-id%%",
    },
    {
        text: "Advertiser ID",
        id: "%%advertiser-id%%",
    },
    {
        text: "Offer Path ID",
        id: "%%offer-path-id%%",
    },
    {
        text: "Deal ID",
        id: "%%dealId%%",
    },
    {
        text: "Job Title",
        id: "%%job-title%%",
    },
    {
        text: "Job Company",
        id: "%%job-company%%",
    },
];

export const OfferWallOfferEntityMacros = [
    {
        text: "Campaign ID",
        id: "%%campaign-id%%",
    },
    {
        text: "Advertiser ID",
        id: "%%advertiser-id%%",
    },
    {
        text: "Deal ID",
        id: "%%dealId%%",
    },
];

export const CreativesEntityMacros = [
    {
        text: "Campaign ID",
        id: "%%campaign-id%%",
    },
    {
        text: "Advertiser ID",
        id: "%%advertiser-id%%",
    },
    {
        text: "Offer Path ID",
        id: "%%offer-path-id%%",
    },
    {
        text: "Creative ID",
        id: "%%creative-id%%",
        // preselected: true,
        // disabled: true,
    },
    {
        text: "Job Title",
        id: "%%job-title%%",
    },
    {
        text: "Job Company",
        id: "%%job-company%%",
    },
];

export const OfferGroupEntityMacros = [
    {
        text: "Campaign ID",
        id: "%%campaign-id%%",
    },
    {
        text: "Advertiser ID",
        id: "%%advertiser-id%%",
    },
];
