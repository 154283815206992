import { useSortable } from "@dnd-kit/sortable";
import Icon from "../Icon/Icon";

const DragHandle = ({ id, disableSort, customClass }) => {
    const { attributes, listeners } = useSortable({
        id: id,
        disabled: disableSort,
    });

    return (
        <div
            {...listeners}
            {...attributes}
            className={`dp-parent circleIconColor circleIconColor--darkHover cursorPointer ${
                customClass ? customClass : ""
            }`}
        >
            <Icon icon="Drag" size="16px" />
        </div>
    );
};

export default DragHandle;
