import React, { useEffect, useMemo, useRef, useState } from "react";
import s from "./ReactSelect.module.scss";
import Checkbox, { CheckboxIcon } from "../../Shared/Checkbox/Checkbox";
import { cloneDeep } from "lodash";
import Select, { components } from "react-select";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import Tooltip from "../Tooltip/Tooltip";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";

const ReactSelect = props => {
    // prop reference -> https://react-select.com/props
    const [input, setInput] = useState("");
    const [selectAll, setSelectAll] = useState(false);
    const [tempSelected, setTempSelected] = useState(null);
    const [uniqueOptions, setUniqueOptions] = useState(
        props.options?.length - props.selectedIds?.length > 1
            ? props.selectedIds?.length
            : 0
    );
    const selectRef = useRef();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleChange = items => {
        if (tempSelected === null && items.some(_ => _.selectAll)) {
            //select all when nothing is selected
            setSelectAll(true);
            setTempSelected(props.options);
        } else if (
            tempSelected &&
            items.length === uniqueOptions &&
            !items.some(_ => _.selectAll) &&
            selectAll
        ) {
            //unselect all when all options are selected
            setSelectAll(false);
            setTempSelected(null);
        } else if (
            tempSelected &&
            items.some(_ => _.selectAll) &&
            items.length !== uniqueOptions
        ) {
            //unselect all when all options aren't selected
            setSelectAll(false);
            setTempSelected(null);
        } else {
            setTempSelected(
                items.filter(_ => _[props.searchIdField] !== "Select All")
            );
        }
    };

    const getValue = () => {
        if (tempSelected?.length > -1) return tempSelected;
        else {
            return props.options.filter(item =>
                props.selectedIds?.includes(item[props.searchIdField])
            );
        }
    };

    const optionsList = useMemo(() => {
        // remove selected options from dropdown. Removed by default, should be set as false explicitly.
        let tempOptions = [
            ...(props.hideSelectedOptions === false
                ? props.options.filter(_ =>
                      props.selectedIds?.includes(_[props.searchIdField])
                  )
                : []),
            ...props.options.filter(
                _ => !props.selectedIds?.includes(_[props.searchIdField])
            ),
        ];

        if (props.showSelectAll) {
            tempOptions = [
                {
                    [props.listSelector]: "Select All",
                    [props.searchIdField]: "Select All",
                    selectAll: true,
                },
                ...tempOptions,
            ];
        }

        return tempOptions;
    }, [props]);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
        }),
        control: (provided, state) => ({
            ...provided,
            borderBottom: "0",
        }),
        menu: (provided, state) => ({
            ...provided,
            position: "relative",
            margin: "0",
            boxShadow: "none",
            borderRadius: "0",
        }),
    };

    return (
        <div
            className={`select__container ${
                props.showLabel ? "select__containerWithLabel" : ""
            } `}
        >
            <Select
                ref={selectRef}
                placeholder="Search"
                hideSelectedOptions={false}
                controlShouldRenderValue={false}
                getOptionLabel={opt => opt[props.listSelector]}
                getOptionValue={opt => opt[props.searchIdField]}
                //default props which can be overridden^
                {...props}
                tempSelected={tempSelected}
                setInput={setInput}
                setTempSelected={setTempSelected}
                menuOpen={menuOpen}
                selectRef={selectRef}
                closeMenuOnSelect={props.closeMenuOnSelect || !props.isMulti}
                autoFocus={!props.showLabel}
                options={optionsList}
                components={{
                    ...(props.isMulti
                        ? { Option: Option, MenuList: MenuList }
                        : {}),
                    ...(props.showLabel ? { Placeholder: Placeholder } : {}),
                }}
                styles={customStyles}
                classNamePrefix="select"
                onChange={e => handleChange(e)}
                value={getValue()}
                menuShouldScrollIntoView={true}
                inputValue={input}
                onInputChange={(value, action) => {
                    if (action.action === "input-change") setInput(value);
                }}
                onMenuOpen={() => setMenuOpen(true)}
                onMenuClose={() => {
                    setMenuOpen(false);
                    setTempSelected(null);
                    setInput("");
                }}
            />
        </div>
    );
};

const Option = data => {
    const props = data.selectProps;
    return (
        <components.Option {...data}>
            <div className={`${data.label === "Select All" && "selectAll"}`}>
                <Tooltip
                    message={data.label}
                    position="top"
                    wrap
                    customWrapperClass={"select__optionTooltipWrap"}
                >
                    <span>{data.label}</span>
                </Tooltip>
                {data.label === "Select All" ? (
                    <>
                        {props.tempSelected?.length === props.options.length ? (
                            <Checkbox
                                isChecked={true}
                                className="option__checkbox"
                            />
                        ) : props.tempSelected?.length >
                          props.selectedIds?.length ? (
                            <Checkbox
                                className="option__checkbox option__checkbox--deselect"
                                isChecked={true}
                                icon={CheckboxIcon.minus}
                            />
                        ) : (
                            <Checkbox
                                className="option__checkbox"
                                isChecked={false}
                            />
                        )}
                    </>
                ) : (
                    <Checkbox
                        isChecked={data.isSelected}
                        className="option__checkbox"
                    />
                )}
            </div>
        </components.Option>
    );
};

const Placeholder = data => {
    const props = data.selectProps;

    const getLabel = () => {
        if (props.showLabel) {
            if (props.menuOpen) {
                return props.placeholder || "Search";
            } else {
                return props.selectedIds.length > 0
                    ? props?.selectedIds.length + ` Selected`
                    : props.label || "Select";
            }
        } else {
            props.placeholder || "Search";
        }
    };

    const getTooltip = () => {
        const temp = props?.options?.filter(_ =>
            props.selectedIds?.includes(_[props?.searchIdField])
        );
        return temp?.map(_ => _[props?.listSelector]).join(", ");
    };

    return (
        <components.Placeholder {...data}>
            <Tooltip
                message={getTooltip()}
                hideTooltip={!props.selectedIds.length}
                wrap
            >
                <div>{getLabel()}</div>
            </Tooltip>
            {props?.isLoading && (
                <LinearDeterminate
                    customClass={"select__loader"}
                ></LinearDeterminate>
            )}
        </components.Placeholder>
    );
};

const MenuList = data => {
    const props = data.selectProps;
    return (
        <>
            <components.MenuList {...data}>{data.children}</components.MenuList>
            <div
                className={`${s.confirmBox} dp-parent dp-parent-hor-end gap-20 p-20`}
            >
                <Button
                    onClick={e => {
                        props.setTempSelected(null);
                        props.setInput("");
                        props.selectRef?.current?.blur();
                        typeof props.onCancelHandler === "function" &&
                            props.onCancelHandler();
                    }}
                    btnTheme={ButtonTypes.default_btn}
                >
                    Cancel
                </Button>
                <Button
                    btnTheme={ButtonTypes.primary_btn}
                    onClick={e => {
                        typeof props.customOnSelect === "function" &&
                            props.customOnSelect(props.tempSelected);
                        typeof props.onCancelHandler === "function" &&
                            props.onCancelHandler();
                        props.setInput("");
                        props.selectRef?.current?.blur();
                    }}
                    disable={data.tempSelected === null}
                >
                    {props?.saveBtnText || "Apply"}
                </Button>
            </div>
        </>
    );
};

export default ReactSelect;
