import { isEmpty, isEqual, unset } from "lodash";
import {
    EntitySubType,
    EntityType,
} from "../../Shared/FlowRenderer/FlowRendererUtils";
import createFlowStyles from "./CreateFlow.module.scss";
import { fetchTpKey } from "../../Shared/FlowRenderer/FlowRendererUtils";
import Icon from "../../Shared/Icon/Icon";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import Skeleton from "react-loading-skeleton";

export const changeNameMapping = {
    nextSetId: "Next node changed",
};

export const findParentPosition = (parent, rules) => {
    if (!parent) {
        return {
            x: 0,
            y: 0,
        };
    }
    if (parent?.ruleMeta?.properties?.position) {
        return parent?.ruleMeta?.properties?.position;
    }
    const immediateParent = rules.find(_ => _.nextSetId === parent.ruleSetId);
    return findParentPosition(immediateParent, rules);
};

export const filtersToUserState = filters => {
    const userState = {};
    let filter = "";
    filters.forEach(f => {
        let value = "";
        if (f?.filterValues?.length && f?.filterValues[0]?.value?.length) {
            // value = f?.filterValues[0]?.value[0]?.id;
            value = f?.filterValues[0]?.value?.map(_ => _.id); // If backend supports array of values for multi select
        }
        if (isEmpty(f?.filterValues) && f?.filterTag[0]) {
            value = f?.filterTag[0]?.id;
        }
        filter = value;
        userState[f.id] = value;
    });
    return { userState, filter };
};

export const getCurrentNodeTree = (
    passedRules,
    storage,
    ruleSetId,
    id,
    node
) => {
    let curr = node
        ? node
        : passedRules
              .filter(_ => (id ? _.id === id : _.ruleSetId === ruleSetId))
              .filter(_ => _.isActive);

    if (node || (curr && curr.length === 1)) {
        let temp = node ? curr : curr[0];
        if (!storage.includes(temp)) storage.push(node ? curr : curr[0]);
        let directChildNodes = passedRules.filter(
            _ => _.ruleSetId === (node ? curr.nextSetId : curr[0]).nextSetId
        );
        if (directChildNodes.length > 0) {
            directChildNodes.forEach(el => {
                getCurrentNodeTree(passedRules, storage, el.nextSetId, el.id);
            });
        }
    } else if (curr && curr.length > 1) {
        curr.forEach(el => {
            getCurrentNodeTree(passedRules, storage, el.nextSetId, el.id);
        });
    }
};

const GenerateLegendsLoader = ({ count, hideTextLoader = false }) => {
    return Array(count)
        .fill("dummy")
        .map(_ => {
            return (
                <div className={createFlowStyles.legendsLoader}>
                    <Skeleton width={20} height={20} />
                    {!hideTextLoader && <Skeleton width={50} height={13} />}
                </div>
            );
        });
};

export const FlowLegends = ({
    legends = ["container", "values", "tp", "split", "error"],
    loading,
    flowActionBtns = [],
}) => {
    return (
        <div className={`flow-legends ${createFlowStyles.legendListWrap}`}>
            <ul className={createFlowStyles.legendList}>
                {!loading ? (
                    <>
                        {legends.includes("container") && (
                            <li className={createFlowStyles.container}>
                                Container
                            </li>
                        )}
                        {legends.includes("values") && (
                            <li className={createFlowStyles.values}>Values</li>
                        )}
                        {legends.includes("tp") && (
                            <li className={createFlowStyles.targeting}>
                                Targetting Parameters
                            </li>
                        )}
                        {legends.includes("split") && (
                            <li className={createFlowStyles.split}>Split</li>
                        )}
                        {legends.includes("flow") && (
                            <li className={createFlowStyles.flow}>Flow</li>
                        )}
                        {legends.includes("error") && (
                            <li className={createFlowStyles.error}>Error</li>
                        )}
                        {legends.includes("added") && (
                            <li className={createFlowStyles.added}>Added</li>
                        )}
                        {legends.includes("removed") && (
                            <li className={createFlowStyles.removed}>
                                Removed
                            </li>
                        )}
                        {legends.includes("edited") && (
                            <li className={createFlowStyles.edited}>Edited</li>
                        )}
                    </>
                ) : (
                    <div className={createFlowStyles.legendsLoaderWrapper}>
                        <GenerateLegendsLoader count={legends.length} />
                    </div>
                )}
            </ul>
            {flowActionBtns.length > 0 && (
                <ul>
                    {!loading ? (
                        <>
                            {flowActionBtns.map(_ => (
                                <li
                                    onClick={() => _.callback()}
                                    className="cursorPointer"
                                >
                                    {_.tooltip ? (
                                        <Tooltip
                                            message={_.tooltip}
                                            position="left"
                                        >
                                            <Icon
                                                size="16px"
                                                icon={_.icon}
                                            ></Icon>
                                        </Tooltip>
                                    ) : (
                                        <Icon size="16px" icon={_.icon}></Icon>
                                    )}
                                </li>
                            ))}
                        </>
                    ) : (
                        <div className={createFlowStyles.legendsLoaderWrapper}>
                            <GenerateLegendsLoader
                                count={flowActionBtns.length}
                                hideTextLoader
                            />
                        </div>
                    )}
                </ul>
            )}
        </div>
    );
};

export const getFlowComparison = (
    oldRules = [],
    newRules = [],
    skipAbSort = false
) => {
    const ignoreKeys = [
        "disabled",
        "isSaved",
        "name",
        "type",
        "value",
        "highlight",
        "isFilteredOut",
        "ruleMeta.abId",
        "ruleMeta.id",
        "ruleMeta.abKey",
        "ruleMeta.isActive",
        "ruleMeta.createdAt",
        "ruleMeta.updatedAt",
        "ruleMeta.properties",
        "ruleSetMeta.abId",
        "ruleSetMeta.id",
        "ruleSetMeta.abKey",
        "ruleSetMeta.isActive",
        "ruleSetMeta.createdAt",
        "ruleSetMeta.updatedAt",
        "ruleSetMeta.properties",
    ];

    const oldFlowMap = {};
    const newFlowMap = {};
    const ruleSetIds = new Set([]);

    oldRules.map(_ => {
        if (!skipAbSort && _.entitySubType === "AB_TEST") {
            oldFlowMap[_.ruleSetId] = {
                entitySubType: "AB_TEST",
                entityType: "ROUTER",
                ruleSetId: _.ruleSetId,
            };
            ruleSetIds.add(_.ruleSetId);
        } else {
            oldFlowMap[_.id] = _;
        }
    });

    newRules.map(_ => {
        if (!skipAbSort && _.entitySubType === "AB_TEST") {
            newFlowMap[_.ruleSetId] = {
                entitySubType: "AB_TEST",
                entityType: "ROUTER",
                ruleSetId: _.ruleSetId,
            };
            ruleSetIds.add(_.ruleSetId);
        } else {
            newFlowMap[_.id] = _;
        }
    });

    const parentsWithDeletedChildren = {};
    Object.keys(oldFlowMap).forEach(id => {
        if (oldFlowMap[id].nextSetId && !newFlowMap[id]?.nextSetId) {
            parentsWithDeletedChildren[id] = oldFlowMap[id].nextSetId;
        }
    });

    //rules not present in new array
    let deletedRules = Object.keys(oldFlowMap).filter(
        id =>
            !newFlowMap[id] ||
            (!newFlowMap[id].isActive && oldFlowMap[id]?.isActive)
    );

    //rules not present in old array
    let addedRules = Object.keys(newFlowMap).filter(
        id =>
            (!oldFlowMap[id] && newFlowMap[id].isActive) ||
            (newFlowMap[id].isActive && !oldFlowMap[id].isActive)
    );

    let updatedRules = [];

    Object.keys(oldFlowMap).filter(id => {
        if (
            newFlowMap[id] &&
            !objectCompare(oldFlowMap[id], newFlowMap[id], ignoreKeys)
        ) {
            updatedRules.push(newFlowMap[id]);
        }
    });

    const updatedEndCr = updatedRules.filter(
        rule =>
            rule.entityType === EntityType.ACTIONS &&
            rule.entitySubType === EntitySubType.end
    );

    const updatedEndCrRs = updatedEndCr.map(rule => rule.ruleSetId);
    const updatedContainersFromCR = newRules.filter(_ =>
        updatedEndCrRs.includes(_.nextSetId)
    );
    updatedRules = [
        ...updatedRules.map(_ => _.id?.toString()),
        ...updatedContainersFromCR.map(_ => _.id?.toString()),
    ];

    if (!skipAbSort) {
        ruleSetIds?.forEach(rId => {
            const oldTemp = oldRules.filter(_ => _.ruleSetId === rId);
            const newTemp = newRules.filter(_ => _.ruleSetId === rId);
            const abComparison = getFlowComparison(oldTemp, newTemp, true);
            const {
                deletedRules: abDeletedRules,
                addedRules: abAddedRules,
                updatedRules: abUpdatedRules,
                parentsWithDeletedChildren: abParentsWithDeletedChildren,
            } = abComparison;

            deletedRules = [...deletedRules, ...abDeletedRules];
            addedRules = [...addedRules, ...abAddedRules];
            updatedRules = [...updatedRules, ...abUpdatedRules];
        });
    }
    return {
        deletedRules,
        addedRules,
        updatedRules,
        parentsWithDeletedChildren,
    };
};

const objectCompare = (obj1, obj2, ignoreKeys, previousKey = "") => {
    if (
        typeof obj1 === "object" &&
        typeof obj2 === "object" &&
        isEmpty(obj1) &&
        isEmpty(obj2)
    ) {
        return true;
    }

    if (Array.isArray(obj1)) {
        return obj1?.length === obj2?.length;
    }

    return Object.keys(obj1).every(k1 => {
        if (ignoreKeys.includes(previousKey + k1)) return true;
        if (
            typeof obj1[k1] === "object" &&
            typeof obj2[k1] === "object" &&
            obj1[k1] &&
            obj2[k1]
        ) {
            return objectCompare(obj1[k1], obj2[k1], ignoreKeys, k1 + ".");
        }

        if (
            typeof obj1[k1] === "object" &&
            typeof obj2[k1] === "object" &&
            !obj1[k1] &&
            !obj2[k1]
        ) {
            return true;
        }

        if (typeof obj2[k1] === "undefined") {
            return true;
        }

        return obj1[k1] === obj2[k1];
    });
};

export const flowRulesToNormalRules = (flowRules = []) => {
    const rules = [];
    flowRules.forEach(rule => {
        if (rule.isActive) {
            const tpKey = fetchTpKey(rule.targettingParams);

            if (
                tpKey !== "back" ||
                (tpKey === "back" && rule.entitySubType === "LOGICAL")
            ) {
                rules.push({
                    ...rule,
                });
            }
        }
    });

    return [rules];
};

export const handleDeleteAndCreateNode = async (
    data,
    handleUpdateNode,
    setOpenDrawer
) => {
    if (Array.isArray(data)) {
        await handleUpdateNode([
            ...data.filter(_ => _.id).map(_ => ({ ..._, toBeDeleted: true })),
            ...data
                .filter(_ => !_.toBeDeleted)
                .map(_ => {
                    delete _.isActive;
                    return {
                        ..._,
                        id: undefined,
                        diversifyID: undefined,
                        toBeDeleted: false,
                        ruleMeta: {
                            ..._.ruleMeta,
                            id: undefined,
                            abId: undefined,
                            abKey: undefined,
                        },
                        ruleSetMeta: {
                            ..._.ruleSetMeta,
                            id: undefined,
                            abId: undefined,
                            abKey: undefined,
                        },
                    };
                }),
        ]);
    }
    setOpenDrawer(false);
};

export const resetEntitySubtypesOnChange = (
    editNode,
    data,
    rules,
    setRules
) => {
    if (!isEqual(editNode, data)) {
        setRules(
            rules.map(_ => {
                if (
                    _.ruleSetId === editNode.nextSetId &&
                    ["skip", "next", "goto"].includes(_.entitySubType)
                )
                    _.isActive = false;
                return _;
            })
        );
    }
};

export const findStartNode = (currRule, rules, oldRuleMap = null) => {
    const ruleMap = oldRuleMap || {};
    if (!oldRuleMap) {
        rules.map(_ => {
            if (_.nextSetId) {
                ruleMap[_.nextSetId] = _.ruleSetId;
            }
        });
    }
    const parentRuleSet = ruleMap[currRule?.ruleSetId];
    return !parentRuleSet
        ? currRule
        : findStartNode(
              rules.find(_ => _.ruleSetId === parentRuleSet),
              rules,
              ruleMap
          );
};
