export const surveys = [
    {
        parent: { title: "Create Survey", icon: "CreateSurvey" },
        children: [
            {
                title: "Basics",
                hash: "surveyBasics",
                // path: null,
            },
        ],
    },
];
export const featureSets = [
    {
        parent: { title: "Feature Sets", icon: "FeatureSet" },
        children: [{ title: "Feature Sets", hash: "featureSets" }],
    },
];

export const surveyPaths = [
    {
        parent: { title: "Survey Paths", icon: "SurveyPath" },
        children: [
            {
                title: "Basics",
                hash: "surveyPathBasics",
                path: "basics",
            },
            {
                title: "Question Bank",
                hash: "questionBank",
                path: "questionBank",
            },
            {
                title: "Targetting Params",
                hash: "surveyPathtargettingParams",
                path: "targettingParam",
            },
        ],
    },
];
export const questions = [
    {
        parent: { title: "Question", icon: "SurveyPath" },
        children: [
            {
                title: "Basics",
                hash: "questionBasics",
                path: "basics",
            },
            { title: "Sub-Questions ", hash: "questions", path: "questions" },
        ],
    },
];

export const flowQuestion = [
    {
        parent: { title: "Question", icon: "SurveyPath" },
        children: [
            {
                title: "Basics",
                hash: "questionBasics",
                path: "basics",
            },
        ],
    },
];

export const offerPaths = [
    {
        parent: { title: "Offer Paths", icon: "OfferPath" },
        children: [
            {
                title: "Basics",
                hash: "offerPathBasics",
                path: "basics",
            },
            {
                title: "Offer Path Slots",
                hash: "offerPathSlots",
                path: "slots",
            },
            {
                title: "Targetting Params",
                hash: "offerPathtargettingParams",
                path: "targettingParams",
            },
        ],
    },
];

export const adUnits = [
    {
        parent: { title: "Ad Units", icon: "AdUnit" },
        children: [
            { title: "Basics", hash: "adUnitBasics", path: "basics" },
            { title: "Offers", hash: "adUnitSlots", path: "slots" },
        ],
    },
];
export const featureMaps = [
    {
        parent: { title: "Feature", icon: "AdUnit" },
        children: [
            {
                title: "Basics",
                hash: "featureMapBasics",
                path: "basics",
            },
            {
                title: "Properties",
                hash: "featureMapProperties",
                path: "properties",
            },
        ],
    },
];

export const offerGroups = [
    {
        parent: { title: "Offer Group", icon: "OfferGroup" },
        children: [
            {
                title: "Basics",
                hash: "offerGroupBasics",
                path: "basics",
            },
            { title: "Offers", hash: "offers", path: "offers" },
        ],
    },
];

export const hostAndPost = [
    {
        parent: {
            title: "Posting Spec",
            icon: "LeadGeneration",
        },
        children: [
            { title: "Basics", hash: "leadBasics", path: "basics" },
            {
                title: "Lead Request",
                hash: "leadRequest",
                path: "leadReqError",
            },
        ],
    },
];

export const featureSetPanelData = [
    {
        parent: { title: "Feature Set", icon: "SurveyPath" },
        children: [
            {
                title: "Basics",
                hash: "featureSetBasics",
                path: "basics",
            },
            {
                title: "Features",
                hash: "featureMapBasics",
                path: "featureMaps",
            },
            {
                title: "Targetting Params",
                path: `targettingParams`,
                nestedChildren: [
                    {
                        title: "Inclusion Params",
                        hash: "featureSettargettingParams",
                        path: `targettingParams`,
                    },
                    {
                        title: "Exclusion Params",
                        hash: "featureSetexclusiontargettingParams",
                        path: "exclusionParams",
                    },
                ],
            },
        ],
    },
];

export const giftCards = [
    {
        parent: { title: "Promo", icon: "AdUnit" },
        children: [
            {
                title: "Basics",
                hash: "giftCardBasics",
                path: "basics",
            },
            {
                title: "Offer Walls",
                hash: "offerWallSlots",
                path: "slots",
            },
        ],
    },
];

export const userAccessControl = [
    {
        parent: { title: "User Access Control", icon: "User" },
        children: [
            {
                title: "Users",
                hash: "/settings/users",
                path: "/settings/users",
            },
            {
                title: "User Groups",
                hash: "/settings/user-group",
                path: "/settings/user-group",
            },
        ],
    },
];

export const offerWall = [
    {
        parent: { title: "Offer Wall", icon: "OfferPath" },
        children: [
            {
                title: "Basics",
                hash: "offerWallBasics",
                path: "basics",
            },
            {
                title: "Offer Wall Slots",
                hash: "offerWallSlots",
                path: "slots",
            },
            {
                title: "Targetting Params",
                hash: "offerPathtargettingParams",
                path: "targettingParams",
            },
        ],
    },
];
