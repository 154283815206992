import { get } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import TableFlex from "../TableFlex/TableFlex";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import EditOfferSlots from "../EditOfferPathSlots/EditOfferSlots";
import Input from "../../Shared/Input/Input";
import Select from "../../Shared/Select/Select";
import PanelGrid from "../../Shared/PanelGrid/PanelGrid";
import { getRandomId } from "../../utils/globalUtils";
import { useDispatch, useSelector } from "react-redux";
import s from "./OfferWallSlotsInnerTable.module.scss";
import { useParams } from "react-router-dom";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import { toastListActions } from "../../store/toastList/toastList";
import AddDedupeRule from "../AddDedupeRule/AddDedupeRule";
import Popup from "../../Shared/Popup/Popup";
import {
    OfferWallOfferEntityMacros,
    OfferActions,
} from "../../constants/dedupeConstants";
import noDataImg from "../../assets/img/nooffers.svg";
import noDataImgDark from "../../assets/img/nooffers-dark.svg";
import ToggleValueSwitch, {
    SIZES,
} from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import Icon from "../../Shared/Icon/Icon";
import { analytics } from "../../firebase/config";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";

const OfferWallSlotsInnerTable = ({
    fetchDealIdsApi,
    item,
    creativesList,
    revenueList,
    action,
    data,
    pageId,
    deletedSlotsAds,
    readOnly = false,

    childPropName = "editCreativeData",
    enableDedupe = false,
    entityName = "Offer",
    showWarningMessage = false,
    enableSwitch = true,
}) => {
    const dispatch = useDispatch();
    const tableConfig = {
        stickyHeader: true,
    };

    const editDealIdRef = useRef(null);
    const editWeightRef = useRef(null);
    const weightRef = useRef(null);
    const dealIDRef = useRef(null);

    const [parentData, setParentData] = useState("");
    const [accData, setAccData] = useState([]);
    const [innerTableEditId, setInnerTableEditId] = useState([]);

    const [weight, setWeight] = useState("");
    const [dealId, setDealId] = useState("");
    const [creative, setCreative] = useState(null);
    const [revenueType, setRevenueType] = useState(null);
    const [creativeError, setCreativeError] = useState(false);
    const [revenueTypeError, setRevenueTypeError] = useState(false);
    const [openAddRuleSideBar, setOpenAddRuleSideBar] = useState(false);
    const [tableView, setTableView] = useState(
        localStorage.getItem("offersWallTableView") === "true"
    );
    const [openSideDrawer, setOpenSideDrawer] = useState(false);

    const [editWeight, setEditWeight] = useState("");
    const [editDealId, setEditDealId] = useState("");
    const [editCreative, setEditCreative] = useState(null);
    const [editRevenueType, setEditRevenueType] = useState(null);
    const urlParams = useParams();
    const tableData = { tableError: "Data don't exist for this id" };

    const modules = useSelector(state => state.auth.user.modules);

    const advertiserList = useSelector(
        state => state.listings.offlineConversion.advertisersList
    );

    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);

    useEffect(() => {
        let innerData = get(data, "selectedListItems", []).findIndex(
            data => data.rowId == item.rowId
        );
        setParentData(get(data, `selectedListItems[${innerData}]`, ""));
        if (
            get(data.selectedListItems[innerData], "editCreativeData", "") !=
                null &&
            get(data.selectedListItems[innerData], "editCreativeData", "")
                .length > 0
        ) {
            const deletedSlotsAds_Id = deletedSlotsAds.map(
                e => e.ad.innerRowId
            );
            let totalWeight = 0;
            data.selectedListItems[innerData].editCreativeData.map(element => {
                if (!deletedSlotsAds_Id.includes(element.innerRowId)) {
                    totalWeight = totalWeight + Number(element.weight);
                }
            });

            let editCreativeDataCopy = JSON.parse(
                JSON.stringify(
                    get(
                        data,
                        `selectedListItems[${innerData}].editCreativeData`,
                        []
                    )
                )
            );
            editCreativeDataCopy.map(element => {
                if (!deletedSlotsAds_Id.includes(element.innerRowId)) {
                    element["weightPercent"] =
                        (element.weight / totalWeight) * 100;
                }
            });
            setAccData(editCreativeDataCopy);
        }
    }, [item, deletedSlotsAds]);

    useEffect(() => {
        if (innerTableEditId.length > 0 && accData.length > 0) {
            const actualIndex = accData.findIndex(
                obj => obj.innerRowId == innerTableEditId[0].innerRowId
            );
            let obj = accData[actualIndex];
            setEditWeight(get(obj, `${groupAds[parentData["type"]]}`, ""));
            setEditDealId(get(obj, "dealId", ""));

            let revenueValue = revenueList.filter(
                item => item.text == get(obj, "revenueType", null)
            );
            let creativeValue = creativesList.filter(
                item => item.key == get(obj, "creative", null)
            );

            setEditCreative(get(creativeValue[0], "key", creativeValue[0]));
            setEditRevenueType(get(revenueValue[0], "key", revenueValue[0]));
        }
    }, [innerTableEditId, accData]);

    const selectRevenueDropdown = data => {
        setRevenueType(get(data, "key", ""));
        setRevenueTypeError(false);
    };

    const selectCreativeDropdown = data => {
        setCreative(get(data, "key", ""));
        setCreativeError(false);
    };

    const onEdit = data => {
        setInnerTableEditId([data]);
        setOpenSideDrawer(true);
    };

    const cancelEdit = () => {
        setInnerTableEditId([]);
        setOpenSideDrawer(false);
    };

    const onDeleteItem = obj => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        let itemIndex = selectedListItemsCopy.findIndex(
            element => element.rowId == get(item, "rowId", null)
        );

        if (urlParams.id && obj.id !== "-") {
            const params = {
                page: pageId,
                data: {
                    ad: obj,
                    type: selectedListItemsCopy[itemIndex].type,
                },
            };
            dispatch(action.setDeletedSlotAds(params));
        } else {
            let editCreativeData = get(
                selectedListItemsCopy,
                `${itemIndex}.editCreativeData`,
                []
            );
            let subHeaderIndex = editCreativeData.findIndex(
                element => element.innerRowId == obj.innerRowId
            );
            editCreativeData.splice(subHeaderIndex, 1);
            dispatch(
                action.addSelectedListItems({
                    page: pageId,
                    data: selectedListItemsCopy,
                })
            );
        }
    };

    const editDedupeRules = dedupeRules => {
        if (innerTableEditId.length > 0) {
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            let itemIndex = selectedListItemsCopy.findIndex(
                data => data.rowId == get(item, "rowId", null)
            );

            let actualIndex = selectedListItemsCopy[itemIndex][
                childPropName
            ].findIndex(
                element => element.innerRowId == innerTableEditId[0].innerRowId
            );

            selectedListItemsCopy[itemIndex][childPropName][actualIndex] = {
                ...selectedListItemsCopy[itemIndex][childPropName][actualIndex],
                isEdit: true,
                dedupeRules: dedupeRules,
            };
            dispatch(
                action.addSelectedListItems({
                    page: pageId,
                    data: selectedListItemsCopy,
                })
            );
        }
    };

    const onAddSubheaderItem = (obj, id) => {
        if (innerTableEditId.length > 0) {
            if (editWeight != "" && editDealId != "") {
                let selectedListItemsCopy = JSON.parse(
                    JSON.stringify(get(data, "selectedListItems", []))
                );
                let itemIndex = selectedListItemsCopy.findIndex(
                    data => data.rowId == get(item, "rowId", null)
                );

                let actualIndex = selectedListItemsCopy[
                    itemIndex
                ].editCreativeData.findIndex(
                    element =>
                        element.innerRowId == innerTableEditId[0].innerRowId
                );

                selectedListItemsCopy[itemIndex].editCreativeData[actualIndex] =
                    {
                        ...selectedListItemsCopy[itemIndex].editCreativeData[
                            actualIndex
                        ],
                        dealId: editDealId,
                        revenueType: editRevenueType,
                        creative: editCreative,
                        [groupAds[parentData["type"]]]: editWeight,
                        isEdit: urlParams.id == null ? false : true,
                    };

                if (
                    editWeight.toString().indexOf(".") == -1 &&
                    editDealId.indexOf(".") == -1
                ) {
                    dispatch(
                        action.setOfferWallSlotsError({
                            page: pageId,
                            data: false,
                        })
                    );
                    dispatch(
                        action.addSelectedListItems({
                            page: pageId,
                            data: selectedListItemsCopy,
                        })
                    );
                    dispatch(
                        action.setEditId({
                            page: pageId,
                            data: "",
                        })
                    );
                    setEditWeight("");
                    setEditDealId("");
                    setEditCreative(null);
                    setEditRevenueType(null);
                } else {
                    if (editWeight.indexOf(".") !== -1) {
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: "Weight must not be in decimal",
                            })
                        );
                    }
                    if (editDealId.indexOf(".") !== -1) {
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: "Deal Id must not be in decimal",
                            })
                        );
                    }
                }
            } else {
                if (editWeight == "") editWeightRef.current.validate();
                if (editDealId == "") editDealIdRef.current.validate();
            }
        } else {
            if (
                weight != "" &&
                weight.indexOf(".") == -1 &&
                dealId != "" &&
                dealId.indexOf(".") == -1 &&
                creative != null &&
                revenueType != null
            ) {
                let selectedListItemsCopy = JSON.parse(
                    JSON.stringify(get(data, "selectedListItems", []))
                );
                let itemIndex = selectedListItemsCopy.findIndex(
                    data => data.rowId == item.rowId
                );
                let editCreativeData = get(
                    selectedListItemsCopy,
                    `${itemIndex}.editCreativeData`,
                    []
                );

                editCreativeData.push({
                    dealId: dealId,
                    [groupAds[parentData["type"]]]: weight,
                    revenueType: revenueType,
                    creative: creative,
                    id: null ? "-" : "-",
                    innerRowId: getRandomId(),
                });
                dispatch(
                    action.setOfferWallSlotsError({
                        page: pageId,
                        data: false,
                    })
                );
                dispatch(
                    action.addSelectedListItems({
                        page: pageId,
                        data: selectedListItemsCopy,
                    })
                );
            } else {
                if (weight == "") weightRef.current.validate();
                if (dealId == "") dealIDRef.current.validate();
                if (creative == null) setCreativeError(true);
                if (revenueType == null) setRevenueTypeError(true);

                if (weight.indexOf(".") !== -1) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Weight must not be in decimal",
                        })
                    );
                }

                if (dealId.indexOf(".") !== -1) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Deal Id must not be in decimal",
                        })
                    );
                }
            }
        }
    };

    const dedupeColumn = item => {
        const readOnly = !modules.includes(`Deals.OfferWall.Deduping.Write`);
        return (
            <span
                onClick={() => {
                    if (!readOnly) {
                        setInnerTableEditId([item]);
                        setOpenAddRuleSideBar(true);
                    }
                }}
                className={`${!readOnly ? "link" : ""}`}
            >
                {item.dedupeRules && item.dedupeRules.length
                    ? `${item.dedupeRules.length} Rule Added`
                    : readOnly
                    ? `-`
                    : `Add Rule`}
            </span>
        );
    };

    const groupAds = {
        WeightGroup: "weight",
        PriorityGroup: "priority",
        RtbGroup: "disposition",
    };

    const accordionColumns = [
        {
            text: "Id",
            key: "id",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                return item.id && item.id !== "-" ? (
                    item.id
                ) : (
                    <TagRenderer>New</TagRenderer>
                );
            },
        },
        {
            text: `${
                groupAds[parentData["type"]] != "weight"
                    ? groupAds[parentData["type"]]
                    : `${groupAds[parentData["type"]]}(%)`
            }`,
            key: groupAds[parentData["type"]],
            disableCopyText: true,
            CustomRender: ({ col, item }) => {
                return item.weight
                    ? item.weight && item.weightPercent
                        ? `${item.weight} (${item.weightPercent.toFixed(2)}%)`
                        : item.weight
                    : item[groupAds[parentData["type"]]];
            },
            type: 0,
        },
        {
            text: "Deal Id",
            key: "dealId",
            disableCopyText: true,
            CustomRender: ({ col, item }) => {
                return item.dealId ? item.dealId : "";
            },
            type: 0,
        },
        {
            text: "Select Revenue Type",
            key: "revenueType",
            disableCopyText: true,
            CustomRender: ({ col, item }) => {
                return item.revenueType ? item.revenueType : "";
            },
            type: 5,
        },
        {
            text: "Select Creative",
            key: "creative",
            disableCopyText: true,
            CustomRender: ({ col, item }) => {
                return item.creative ? item.creative : "";
            },
            type: 5,
        },
        ...[
            {
                text: "Deduping",
                key: "creative",
                disableCopyText: true,
                CustomRender: ({ item }) => {
                    return dedupeColumn(item);
                },
                type: 5,
            },
        ].filter(
            _ =>
                modules.indexOf(`Deals.OfferWall.Deduping.Read`) > -1 &&
                enableDedupe
        ),
        {
            text: "",
            key: "",
            CustomRender: ({ col, item }) => {
                return (
                    <ActionButtons
                        item={item}
                        editCallback={onEdit}
                        isDeleted={
                            get(data, "deletedSlotAds", []).findIndex(
                                _ => _.ad.id === item.id
                            ) !== -1
                        }
                        revertCallback={obj => {
                            dispatch(
                                action.revertDeleteSlotAds({
                                    page: pageId,
                                    data: obj,
                                })
                            );
                        }}
                        deleteCallback={obj => {
                            onDeleteItem(obj);
                        }}
                        readOnly={readOnly}
                    />
                );
            },
            bulkActions: true,
        },
    ];

    const weightInputJson = {
        className: "",
        type: "number",
        placeholder: "",
        ref: editWeightRef,
    };

    const dealInputJson = {
        className: "",
        type: "text",
        placeholder: "",
        ref: editDealIdRef,
    };

    const revenueDropDownJson = {
        className: `${s.editDropdown}`,
        keyField: "text",
        idField: "key",
        position: "top",
        selectedIdField: get(editRevenueType, "key", editRevenueType),
    };

    const creativeDropDownJson = {
        className: `${s.editDropdown}`,
        keyField: "key",
        idField: "key",
        position: "top",
        selectedIdField: editCreative,
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (typeof setAccData == "function") {
            setAccData(arrayMove(accData, oldIndex, newIndex));
        }
    };

    const handleDealIdChange = e => {
        if (innerTableEditId.length === 0) {
            setDealId(e.dealName);
            setRevenueType(e.campaignBillingType?.toLowerCase());
        } else {
            setEditDealId(e.dealName);
            if (e.campaignBillingType?.toLowerCase() !== editRevenueType) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Warning",
                        autoClose: 3000,
                        message: `Revenue type changed from ${editRevenueType?.toUpperCase()} to ${
                            e.campaignBillingType
                        }`,
                    })
                );
                setEditRevenueType(e.campaignBillingType.toLowerCase());
            }
        }
    };

    return (
        <div className="accordionTableWrap">
            {/* <PanelGrid className={s.accordianAddTableWrapper}>
                <div className={s.header}>
                    <div className={`${s.subHeader} ${s.flexGrow}`}>
                        {groupAds[parentData["type"]]}
                    </div>
                    <div className={s.subHeader}>Deal Id</div>
                    <div className={s.subHeader}>Select revenue type</div>
                    <div className={s.subHeader}>Select creative</div>
                </div>
                <div className={s.body}>
                    <div className={s.input}>
                        <Input
                            ref={weightRef}
                            type="number"
                            value={weight}
                            placeholder={`Enter ${
                                groupAds[parentData["type"]]
                            }`}
                            onChange={e => {
                                setWeight(e.target.value);
                            }}
                            required={true}
                            disabled={readOnly}
                        />
                    </div>

                    <div className={s.input}>
                        <Input
                            ref={dealIDRef}
                            type="text"
                            value={dealId}
                            placeholder="Enter ID"
                            onChange={e => setDealId(e.target.value)}
                            required={true}
                            disabled={readOnly}
                        />
                    </div>
                    <div className={s.customSelectContainerLayer}>
                        <Select
                            label={"Select Revenue Type"}
                            options={revenueList}
                            keyField="text"
                            onSelect={selectRevenueDropdown}
                            idField={"key"}
                            position={"top"}
                            isError={revenueTypeError}
                            onLoadSelectReturn={false}
                            isDisabled={readOnly}
                        />
                    </div>
                    <div className={s.secondCustomSelectContainerLayer}>
                        <Select
                            label={"Select Creative"}
                            options={creativesList}
                            keyField={"key"}
                            onSelect={selectCreativeDropdown}
                            idField={"id"}
                            isError={creativeError}
                            onLoadSelectReturn={false}
                            position={"top"}
                            isDisabled={readOnly}
                        />
                    </div>
                    <Button
                        className={`${s.themBtn} ${s.marginLeft}`}
                        btnTheme={ButtonTypes.primaryHover_btn}
                        onClick={onAddSubheaderItem}
                        disable={readOnly}
                    >
                        Add
                    </Button>
                </div>
            </PanelGrid> */}
            <div className={s.innerHeader}>
                <div
                    className={`dp-parent dp-parent-ver-center ${
                        accData.length === 0 ? s.noDataContainer : ""
                    }`}
                >
                    <span className="mr-10">
                        {accData.length > 0 &&
                            `${accData.length} Offer${
                                accData.length > 1 ? "s" : ""
                            } added`}
                    </span>
                    <div className={s.noDataGraphicsContainer}>
                        <img src={isDarkMode ? noDataImgDark : noDataImg} />
                        <div>Looks like no {entityName}s are added.</div>
                    </div>
                    {!readOnly && (
                        <Button
                            btnTheme={ButtonTypes.primaryHover_btn}
                            onClick={() => {
                                setOpenSideDrawer(true);
                            }}
                            className={`mr-10 ${
                                accData.length === 0 ? "mb-10" : ""
                            }`}
                        >
                            Add {entityName}
                        </Button>
                    )}
                    {showWarningMessage &&
                        accData.length > 0 &&
                        !hasOfferWallWithNoTargetingParams(
                            accData,
                            deletedSlotsAds,
                            offerWallList
                        ) && (
                            <InfoText theme={"info"}>
                                <div className={s.inlineTooltip}>
                                    Please select atleast 1{" "}
                                    <Tooltip
                                        message={
                                            "Offer wall with no targetting parameters to act as a fall back. "
                                        }
                                    >
                                        <div className="bold underline">
                                            Fall Back
                                        </div>
                                    </Tooltip>{" "}
                                    Offer Wall.
                                </div>
                            </InfoText>
                        )}
                </div>
                {accData.length > 0 && enableSwitch && (
                    <ToggleValueSwitch
                        onChange={responseType => {
                            // analytics.logEvent("offers", {
                            //     action: "toggle table view",
                            //     value: responseType ? "table" : "grid",
                            // });

                            localStorage.setItem(
                                "offersWallTableView",
                                responseType
                            );
                            setTableView(responseType);
                        }}
                        leftLabel={<Icon size="18px" icon="GridView" />}
                        rightLabel={<Icon size="18px" icon="ListView" />}
                        toggleState={enableSwitch ? tableView : true}
                        compact={SIZES.SMALL}
                        size={"small"}
                    ></ToggleValueSwitch>
                )}
            </div>
            {accData.length &&
            accData.length > 0 &&
            (tableView || !enableSwitch) ? (
                <TableFlex
                    columns={accordionColumns}
                    data={accData}
                    isPagination={false}
                    showBorder={true}
                    showFakeScroll={false}
                    deletedIds={deletedSlotsAds.map(_ => _.ad.id)}
                    tableData={tableData}
                    config={tableConfig}
                    stickyHeaderClass={"stickyTableNestedHead"}
                ></TableFlex>
            ) : (
                <SortableList onSortEnd={onSortEnd} useDragHandle>
                    {accData.map((item, index) => (
                        <SortableItem key={index} index={index}>
                            <li
                                key={index}
                                className={`${s.offerCardItem} ${
                                    get(data, "deletedSlotAds", []).findIndex(
                                        _ => _.ad.id === item.id
                                    ) !== -1
                                        ? s.deleted
                                        : ""
                                }`}
                            >
                                <div className={s.row}>
                                    <label>
                                        {groupAds[parentData["type"]] !==
                                        "weight"
                                            ? groupAds[parentData["type"]]
                                            : `${groupAds[parentData["type"]]}`}
                                        :{" "}
                                    </label>
                                    <span className={s.value}>
                                        {item.weight
                                            ? item.weight && item.weightPercent
                                                ? `${
                                                      item.weight
                                                  } (${item.weightPercent.toFixed(
                                                      2
                                                  )}%)`
                                                : item.weight
                                            : item[
                                                  groupAds[parentData["type"]]
                                              ]}
                                    </span>
                                    <div className={s.offerCardActionBar}>
                                        <ActionButtons
                                            item={item}
                                            editCallback={onEdit}
                                            isDeleted={
                                                get(
                                                    data,
                                                    "deletedSlotAds",
                                                    []
                                                ).findIndex(
                                                    _ => _.ad.id === item.id
                                                ) !== -1
                                            }
                                            revertCallback={obj => {
                                                dispatch(
                                                    action.revertDeleteSlotAds({
                                                        page: pageId,
                                                        data: obj,
                                                    })
                                                );
                                            }}
                                            deleteCallback={obj => {
                                                onDeleteItem(obj);
                                            }}
                                            showHistoryIcon={
                                                item.id && item.id !== "-"
                                                    ? true
                                                    : false
                                            }
                                            historyCallback={obj => {
                                                historyDataAction(
                                                    historyInnerTableApi,
                                                    obj,
                                                    dispatch,
                                                    action
                                                );
                                            }}
                                            readOnly={readOnly}
                                        />
                                        {/* Until fixed */}
                                        {/* <DragHandle></DragHandle> */}
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <label>ID: </label>
                                    <span className={s.value}>
                                        {item.id && item.id !== "-" ? (
                                            item.id
                                        ) : (
                                            <TagRenderer>New</TagRenderer>
                                        )}
                                    </span>
                                </div>
                                <div className={s.row}>
                                    <label>Deal ID: </label>
                                    <span className={s.value}>
                                        {item.dealId}
                                    </span>
                                </div>
                                <div className={s.row}>
                                    <label>Revenue Type: </label>
                                    <span className={s.value}>
                                        {item.revenueType}
                                    </span>
                                </div>
                                <div className={s.row}>
                                    <label>Creative: </label>
                                    <span className={s.value}>
                                        {item.creative}
                                    </span>
                                </div>
                                {modules.indexOf(
                                    `SurveyManager.OfferPath.Deduping.Read`
                                ) > -1 &&
                                    enableDedupe && (
                                        <div className={s.row}>
                                            <label>Dedupe: </label>
                                            <span className={s.value}>
                                                {dedupeColumn(item)}
                                            </span>
                                        </div>
                                    )}

                                <CreativeRender
                                    height={125}
                                    id={item.creative.split(":")[0]}
                                ></CreativeRender>
                            </li>
                        </SortableItem>
                    ))}
                </SortableList>
            )}
            {openAddRuleSideBar && innerTableEditId.length ? (
                <Popup
                    bodyMaxHeight="calc(100vh - 65px)"
                    title="Add Dedupe"
                    headerPadding={"20px"}
                    showOverlay
                    width="640px"
                    showFooter={false}
                    closeOutside={false}
                    onClose={() => {
                        cancelEdit();
                        setOpenAddRuleSideBar(false);
                    }}
                >
                    <AddDedupeRule
                        actionMacros={OfferActions}
                        prefix={"offer"}
                        dedupeRules={innerTableEditId[0]?.dedupeRules}
                        onSave={dedupeRules => {
                            setOpenAddRuleSideBar(false);
                            editDedupeRules(dedupeRules);
                        }}
                        onCancel={() => {
                            cancelEdit();
                            setOpenAddRuleSideBar(false);
                        }}
                        readOnly={
                            modules.indexOf(
                                `Deals.OfferWall.Deduping.Write`
                            ) === -1
                        }
                        entityMacros={OfferWallOfferEntityMacros}
                    ></AddDedupeRule>
                </Popup>
            ) : null}

            {openSideDrawer ? (
                <Popup
                    bodyMaxHeight="calc(100vh - 65px)"
                    title={`${
                        innerTableEditId.length
                            ? "Edit Offer Wall"
                            : "Add Offer Wall"
                    }`}
                    showOverlay
                    width="640px"
                    showFooter={false}
                    onSave={() => onAddSubheaderItem()}
                    onClose={() => {
                        cancelEdit();
                    }}
                    closeOutside={false}
                >
                    {/* <div className={`row`}>
                        <p className={`headerTitleRequired`}>Id</p>
                    </div>
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>{`${
                            groupAds[parentData["type"]] != "weight"
                                ? groupAds[parentData["type"]]
                                : `${groupAds[parentData["type"]]}(%)`
                        }`}</p>
                        <div>
                            <EditOfferSlots
                                type={0}
                                onChange={async data => {
                                    await setEditWeight(data);
                                    editWeightRef.current.focus();
                                }}
                                inputState={editWeight}
                                inputJson={weightInputJson}
                            />
                        </div>
                    </div>
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>Deal Id</p>
                        <div>
                            <EditOfferSlots
                                type={0}
                                onChange={async data => {
                                    await setEditDealId(data);
                                    editDealIdRef.current.focus();
                                }}
                                inputState={editDealId}
                                inputJson={dealInputJson}
                            />
                        </div>
                    </div>
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>
                            Select Revenue Type
                        </p>
                        <div>
                            <EditOfferSlots
                                type={5}
                                onChange={data => {
                                    setEditRevenueType(get(data, "key", ""));
                                }}
                                data={revenueList}
                                dropDownJson={revenueDropDownJson}
                            />
                        </div>
                    </div>
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>Select Creative</p>
                        <div>
                            <EditOfferSlots
                                type={5}
                                onChange={data => {
                                    setEditCreative(get(data, "key", ""));
                                }}
                                data={creativesList}
                                dropDownJson={creativeDropDownJson}
                            />
                        </div>
                    </div>
                    <div className={s.popUpFooter}>
                        <Button
                            btnTheme={ButtonTypes.default_btn}
                            onClick={() => {
                                cancelEdit();
                                setOpenSideDrawer(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            btnTheme={ButtonTypes.primary_btn}
                            onClick={() => {
                                onAddSubheaderItem();
                                setOpenSideDrawer(false);
                            }}
                            disable={readOnly}
                        >
                            Confirm
                        </Button>
                    </div> */}
                    <div className={s.editContainer}>
                        <div className={s.body}>
                            <div className={`row`}>
                                <p className={`headerTitleRequired capitalize`}>
                                    {groupAds[parentData["type"]] != "Weight"
                                        ? groupAds[parentData["type"]]
                                        : `${groupAds[parentData["type"]]}`}
                                    *
                                </p>
                                <p className={`infoText`}>Weight</p>
                                <Input
                                    ref={
                                        innerTableEditId.length === 0
                                            ? weightRef
                                            : editWeightRef
                                    }
                                    type="number"
                                    value={
                                        innerTableEditId.length === 0
                                            ? weight
                                            : editWeight
                                    }
                                    placeholder={`Enter ${
                                        groupAds[parentData["type"]]
                                    }`}
                                    onChange={e => {
                                        if (innerTableEditId.length === 0) {
                                            setWeight(e.target.value);
                                        } else {
                                            setEditWeight(e.target.value);
                                        }
                                    }}
                                    required={true}
                                    disabled={readOnly}
                                />
                            </div>
                            {offerWallList.length > 0 && (
                                <div className={`row`}>
                                    <p className={`headerTitleRequired`}>
                                        Offer Wall*
                                    </p>
                                    <p className={`infoText`}>Offer Wall</p>

                                    <Select
                                        label={"Select Offer Wall"}
                                        options={offerWallList}
                                        selectedId={
                                            innerTableEditId.length === 0
                                                ? []
                                                : editOfferWall
                                        }
                                        keyField="name"
                                        portal={false}
                                        CustomRender={OfferWallSelectOption}
                                        onSelect={data => {
                                            if (innerTableEditId.length === 0) {
                                                selectOfferWallDropdown(data);
                                            } else {
                                                setEditOfferWall(
                                                    get(data, "id", "")
                                                );
                                            }
                                        }}
                                        idField={"id"}
                                        defaultOptionHeight={60}
                                        isError={offerWallError}
                                        onLoadSelectReturn={false}
                                        isDisabled={readOnly}
                                    />
                                </div>
                            )}
                            {fetchDealIdsApi && (
                                <div className={`row`}>
                                    <p className={`headerTitleRequired`}>
                                        Deal Id*
                                    </p>
                                    <p className={`infoText`}>Deal Id</p>
                                    <div className="flex">
                                        <div className="dp-child-50 mr-10">
                                            <Select
                                                label={"Select Advertiser"}
                                                options={advertiserList}
                                                keyField="name"
                                                portal={false}
                                                onSelect={e => {
                                                    setLoader(true);
                                                    setDealIdList([]);
                                                    dispatch(
                                                        fetchDealIdsApi(e.id)
                                                    )
                                                        .unwrap()
                                                        .then(res => {
                                                            setDealIdList(
                                                                res.data
                                                            );
                                                            setLoader(false);
                                                        });
                                                }}
                                                idField="key"
                                                onLoadSelectReturn={false}
                                                isDisabled={readOnly}
                                            />
                                        </div>
                                        <div className="dp-child-50">
                                            {dealIdList.length ? (
                                                <Select
                                                    label={"Select Deal ID"}
                                                    options={dealIdList}
                                                    keyField={"dealName"}
                                                    portal={false}
                                                    CustomRender={
                                                        ProfileSelectOption
                                                    }
                                                    onSelect={
                                                        handleDealIdChange
                                                    }
                                                    idField="dealName"
                                                    onLoadSelectReturn={false}
                                                    isError={false}
                                                    isDisabled={readOnly}
                                                    defaultOptionHeight={60}
                                                    isSelectOpen={true}
                                                />
                                            ) : (
                                                <Tooltip
                                                    message={
                                                        "To change deal Id, please select its Advertiser first."
                                                    }
                                                >
                                                    <div
                                                        className={`${
                                                            s.labelDealId
                                                        } ${
                                                            !(innerTableEditId.length ===
                                                            0
                                                                ? dealId
                                                                : editDealId)
                                                                ? s.placeholder
                                                                : ""
                                                        }`}
                                                    >
                                                        {loader && (
                                                            <LinearDeterminate
                                                                customClass={
                                                                    s.loader
                                                                }
                                                            />
                                                        )}
                                                        {innerTableEditId.length ===
                                                        0
                                                            ? dealId ||
                                                              "Select Deal Id"
                                                            : editDealId ||
                                                              "Select Deal Id"}
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {revenueList.length > 0 && (
                                <div className={`row`}>
                                    <p className={`headerTitleRequired`}>
                                        Revenue Type*
                                    </p>
                                    <p className={`infoText`}>Revenue Type</p>

                                    <Select
                                        label={"Select Revenue Type"}
                                        options={revenueList}
                                        selectedId={
                                            innerTableEditId.length === 0
                                                ? revenueType
                                                : editRevenueType
                                        }
                                        keyField="text"
                                        portal={false}
                                        onSelect={data => {
                                            if (innerTableEditId.length === 0) {
                                                selectRevenueDropdown(data);
                                            } else {
                                                setEditRevenueType(
                                                    get(data, "key", "")
                                                );
                                            }
                                        }}
                                        idField={"key"}
                                        isError={revenueTypeError}
                                        onLoadSelectReturn={false}
                                        isDisabled={readOnly}
                                    />
                                </div>
                            )}
                            {creativesList.length > 0 && (
                                <div className={`row`}>
                                    <p className={`headerTitleRequired`}>
                                        Creative*
                                    </p>
                                    <p className={`infoText`}>Creative</p>
                                    <Select
                                        label={"Select Creative"}
                                        options={creativesList}
                                        keyField={"key"}
                                        portal={false}
                                        selectedId={
                                            innerTableEditId.length === 0
                                                ? []
                                                : parseInt(
                                                      editCreative?.split(
                                                          ":"
                                                      )[0]
                                                  )
                                        }
                                        onSelect={data => {
                                            if (innerTableEditId.length === 0) {
                                                selectCreativeDropdown(data);
                                            } else {
                                                setEditCreative(
                                                    get(data, "key", "")
                                                );
                                            }
                                        }}
                                        isError={creativeError}
                                        idField={"id"}
                                        onLoadSelectReturn={false}
                                        isDisabled={readOnly}
                                    />
                                    <div className="mt-10">
                                        {innerTableEditId.length === 0
                                            ? creative && (
                                                  <CreativeRender
                                                      id={
                                                          creative?.split(
                                                              ":"
                                                          )[0]
                                                      }
                                                  ></CreativeRender>
                                              )
                                            : editCreative && (
                                                  <CreativeRender
                                                      id={
                                                          editCreative?.split(
                                                              ":"
                                                          )[0]
                                                      }
                                                  ></CreativeRender>
                                              )}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={s.footer}>
                            <Button
                                btnTheme={ButtonTypes.default_btn}
                                onClick={() => {
                                    cancelEdit();
                                    setDealIdList([]);
                                    setOpenSideDrawer(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                btnTheme={ButtonTypes.primary_btn}
                                onClick={() => onAddSubheaderItem()}
                                disable={readOnly}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Popup>
            ) : null}
        </div>
    );
};

const SortableList = SortableContainer(({ children }) => {
    return <ul className={s.offerCardContainer}>{children}</ul>;
});

const SortableItem = SortableElement(props => props.children);

const DragHandle = SortableHandle(() => (
    <div className={`${s.dragIcon} circleIconColor circleIconColor--darkHover`}>
        <Icon icon="Drag" size="18px" />
    </div>
));

const hasOfferWallWithNoTargetingParams = (
    item,
    deletedSlotAds,
    offerWallList
) => {
    return (
        item
            .filter(ow => {
                return deletedSlotAds.findIndex(_ => _.ad.id === ow.id) === -1;
            })
            .findIndex(_ => {
                const targetParams = offerWallList.find(
                    ow => _.offerWall === ow.id
                )?.targetParams;
                if (targetParams) {
                    const temp = Object.keys(targetParams).reduce((p, _) => {
                        if (targetParams[_]?.length) {
                            p.push(_);
                        }
                        return p;
                    }, []);

                    return temp.length === 0;
                }
                return true;
            }) > -1
    );
};

const ProfileSelectOption = ({ option }) => {
    return (
        <div className={s.dealIdDropdownWrapper}>
            <div className={s.dealNameLabel}>
                <StatusRenderer status={option.campaignStatus} noText={true} />
                <span>{option.dealName}</span>
            </div>
            <div className={s.multiValue}>
                <span>{option.campaignName}</span>
            </div>
        </div>
    );
};

const OfferWallSelectOption = ({ option }) => {
    return (
        <div className={s.dealIdDropdownWrapper}>
            <div className={s.dealNameLabel}>
                <span>
                    {option.id}:{option.name}
                </span>
            </div>
            <div className={s.multiValue}>
                <Icon
                    size="18px"
                    icon="TargettingParams"
                    enableHover={true}
                    className="mr-5"
                />{" "}
                <span className="capitalize">
                    {activeTargetingParams(option.targetParams)}
                </span>
            </div>
        </div>
    );
};

const activeTargetingParams = targetParams => {
    const temp = Object.keys(targetParams).reduce((p, _) => {
        if (targetParams[_]?.length) {
            p.push(_);
        }
        return p;
    }, []);

    // const temp = Object.keys(targetParams)
    //     .filter(_ => targetParams[_]?.length)
    //     .map(
    //         _ => ({
    //             key: _,
    //             value: targetParams[_].join(", "),
    //         }),
    //         []
    //     );

    if (temp.length) {
        return `${temp[0]} ${temp.length > 1 ? `+${temp.length - 1}` : ""}`;
        // return (
        //     <CustomTooltipRenderer
        //         customRenderer={() => {
        //             return <span>test</span>;
        //         }}
        //         data={temp}
        //     >
        //         {temp[0]} {temp.length > 1 ? `+${temp.length - 1}` : ""}
        //     </CustomTooltipRenderer>
        // );
        // return (
        //     <ConfigRenderer
        //         item={{
        //             child: temp,
        //         }}
        //         colName1={"key"}
        //         colName2={"value"}
        //         headerTitle1={"Targeting"}
        //         headerTitle2={"Value"}
        //         arrayKeyName={"child"}
        //         firstRenderData={"normal"}
        //         eachRowDynamicClass={"rowId"}
        //         centreAlign={true}
        //     />
        // );
    }
    return "N/A";
};

export default OfferWallSlotsInnerTable;
