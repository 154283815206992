import { combineReducers } from "redux";
import roas from "./roas";
import urlGenerator from "./urlgenerator";
import feedManager from "./feedmanager";
import advertiser from "./advertiser";
import campaign from "./campaign";

const dashboards = combineReducers({
    roas,
    urlGenerator,
    feedManager,
    advertiser,
    campaign,
});

export default dashboards;
