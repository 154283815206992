import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef,
} from "react";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import c from "./Card.module.scss";

const Card = forwardRef(
    (
        {
            className,
            children,
            showTitle = false,
            title = "",
            dataHashId,
            error = false,
            headerClass,
            bodyClass,
            CustomRender,
            defaultClose = false,
            onTitleClick,
            singleItem,
            showInfoText = false,
            infoText = "",
            showHeaderBorder = false,
        },
        ref
    ) => {
        const [show, setShow] = useState(!defaultClose);
        const cardRef = useRef(null);
        useEffect(() => {
            if (defaultClose) {
                setShow(false);
            } else {
                setShow(true);
            }
        }, [defaultClose]);

        useImperativeHandle(ref, () => ({
            open: () => setShow(true),
            scrollToCard: (offset = 0) => {
                const scrollDist =
                    window.pageYOffset +
                    cardRef?.current?.getBoundingClientRect()?.top;
                window.scrollTo({
                    top: scrollDist - offset,
                    behavior: "smooth",
                });
            },
        }));

        return (
            <div
                className={`${c.cardContainer} ${
                    !show && error ? c.error : ""
                } ${className ? className : ""}`}
                ref={cardRef}
            >
                {CustomRender && <CustomRender></CustomRender>}

                {(showTitle || showInfoText) && (
                    <div
                        className={`${c.header} ${
                            showInfoText ? c.displayFlex : ""
                        } ${showHeaderBorder && show ? c.border : ""} `}
                    >
                        {showTitle && (
                            <div
                                className={`${c.titleWrapper} ${
                                    showInfoText ? c.showBrandColor : ""
                                } ${headerClass ? headerClass : ""}`}
                                onClick={() => {
                                    setShow(!show);
                                    if (onTitleClick) onTitleClick();
                                    // onChangeClosed(singleItem);
                                }}
                            >
                                <Icon
                                    icon={show ? "Minus" : "Plus"}
                                    className={c.icon}
                                    size="13px"
                                />
                                <h3
                                    data-hash-id={dataHashId ? dataHashId : ""}
                                    id={dataHashId ? dataHashId : ""}
                                    className="headerTitle"
                                >
                                    {title || ""}
                                </h3>
                                {error && !show ? (
                                    <Tooltip
                                        message="This section has some errors!"
                                        position="right"
                                    >
                                        <Icon
                                            className={c.error}
                                            icon={"ErrorFilled"}
                                            size="16px"
                                        />
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                        {showInfoText && (
                            <div className={c.infoWrapper}>
                                <div className={c.infoIcon}>
                                    <Icon icon="Info" size="18px" />
                                </div>
                                <div className={c.infoText}>{infoText}</div>
                            </div>
                        )}
                    </div>
                )}

                {showTitle ? (
                    <div
                        className={`${c.childWrapper} ${show ? "" : c.hide} ${
                            bodyClass ? bodyClass : ""
                        }`}
                    >
                        {children}
                    </div>
                ) : (
                    children
                )}
            </div>
        );
    }
);
export default Card;
