import React from "react";
import Icon from "../../Shared/Icon/Icon";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import s from "./DummySelect.module.scss";

const DummySelect = ({ value, onClick, labelStyle = false }) => {
    return (
        <div className={s.dummySelect}>
            <div className="dp-parent dp-parent-ver-center">
                <TagRenderer className={s.deprecateTag} type={"deprecated"}>
                    Deprecated
                </TagRenderer>
                <Tooltip message={value} position="top">
                    <div className={`${labelStyle ? s.labelText : ""}`}>
                        {value}
                    </div>
                </Tooltip>
            </div>
            <div onClick={onClick}>
                <Tooltip message={"Clear"} position="top">
                    <Icon
                        icon={"CloseDefault"}
                        enableHover={true}
                        size="14px"
                    />
                </Tooltip>
            </div>
        </div>
    );
};

export default DummySelect;
