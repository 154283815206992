import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import { REPORTING } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { get } from "lodash";
import {
    mapAdvertiser,
    mapReportMetrics,
    reportMetricsParams,
    validateMetricsParams,
} from "../../utils/reportUtils";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import endOfYesterday from "date-fns/endOfYesterday";
import { isEqualDate } from "../../utils/dateUtils";

const initialDefaultDateRange = {
    startDate: startOfDay(subDays(new Date(), 7)),
    endDate: endOfYesterday(),
    key: "selection",
};

const initialState = {
    list: [],
    advertisersList: [],
    selectedAdvertisers: [],
    retriggeredAdvList: [],
    granularityIsHour: false,
    updatedAt: new Date(),
    loading: false,
    mainLoader: false,
    dateRange: initialDefaultDateRange,
    graphData: {
        datasets: [],
    },
    pagination: {
        startOffset: 0,
        rowsCount: 10,
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        // filterKey: FILTERS.SURVEY.filterKey,
        // filterTerm: null,
        // filterOperator: FILTERS.SURVEY.filterOperator,
        // filtersOperator: FILTERS.SURVEY.operator,
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchReportingMetricsData = createAsyncThunk(
    "reportingMetrics/fetchreportingmetrics",
    async (_, { getState, dispatch }) => {
        const params = reportMetricsParams(
            getState().listings.reportingMetrics
        );

        const response = await customFetch(
            REPORTING.FETCH_REPORTING_METRICS,
            "GET",
            params
        );
        if (response?.status >= 400) {
            dispatch(
                reportingMetricsActions.setTableError(
                    MESSAGES.TABLE.noDataFromApi
                )
            );
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Reports!",
                })
            );
            return null;
        }
        return get(response, "data.data", []);
    }
);

export const fetchAllAdvertisers = createAsyncThunk(
    "reportingMetrics/fetchAllAdvertisers",
    async (_, { dispatch }) => {
        const response = await customFetch(REPORTING.FETCH_ALL_ADVERTISERS);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Advertisers!",
                })
            );
        }
        return response.data.constructor === Array ? response.data : [];
    }
);

export const validateMetrics = createAsyncThunk(
    "reportingMetrics/validateMetrics",
    async (metric, { dispatch }) => {
        const params = validateMetricsParams(metric);
        const response = await customFetch(
            `${REPORTING.VALIDATE_METRICS}?advertisers=${metric.advertiser}`,
            "POST",
            params
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Validate Metrics!",
                })
            );
        }
        return response;
    }
);

export const setReTriggeredMetrics = createAsyncThunk(
    "reportingMetrics/setRetriggeredList",
    async (_, { getState, dispatch }) => {
        const state = getState().listings.reportingMetrics;
        const advMetricList = state.list;
        const list = advMetricList.map(adv => {
            if (
                state.retriggeredAdvList.some(
                    reTriggerAdv =>
                        reTriggerAdv.advertiser === adv.advertiser &&
                        isEqualDate(reTriggerAdv.startTime, adv.startTime) &&
                        isEqualDate(reTriggerAdv.endTime, adv.endTime)
                )
            ) {
                return { ...adv, reTriggered: true };
            }
            return adv;
        });
        dispatch(reportingMetricsActions.setAdvMetrics(list));
        return;
    }
);

const reportingMetrics = createSlice({
    name: "reportingMetrics",
    initialState,
    reducers: {
        setSelectedAdvertisers(state, action) {
            state.selectedAdvertisers = action.payload.map(adv => adv.name);
        },
        setDateRange(state, action) {
            state.dateRange = action.payload;
        },
        setGranularity(state, action) {
            state.granularityIsHour = action.payload;
        },
        setGraphData(state, action) {
            state.graphData = { datasets: action.payload };
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetState() {
            return { ...initialState };
        },
        setAdvMetrics(state, action) {
            state.list = action.payload;
        },
        resetRetriggeredAdvList(state, action) {
            state.retriggeredAdvList = [];
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchReportingMetricsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(
            fetchReportingMetricsData.fulfilled,
            (state, action) => {
                state.loading = false;
                if (action.payload && !action.payload.length)
                    state.tableError =
                        MESSAGES.TABLE.noDataForSelectedDateRange;
                state.list = action.payload
                    ? mapReportMetrics(action.payload)
                    : [];
            }
        );
        builder.addCase(fetchAllAdvertisers.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAllAdvertisers.fulfilled, (state, action) => {
            state.loading = false;
            state.advertisersList = mapAdvertiser(action.payload);
            state.selectedAdvertisers = action.payload;
        });
        builder.addCase(validateMetrics.pending, (state, action) => {
            const adv = action?.meta?.arg;
            state.retriggeredAdvList = [
                ...state.retriggeredAdvList,
                {
                    advertiser: adv.advertiser,
                    startTime: adv.startTime,
                    endTime: adv.endTime,
                },
            ];
            state.list = state.list.map(advMetric => {
                if (advMetric.processId === adv?.processId) {
                    return { ...advMetric, validate: true };
                }
                return advMetric;
            });
        });
    },
});

export const reportingMetricsActions = reportingMetrics.actions;
export default reportingMetrics.reducer;
