import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import { DEALS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { get } from "lodash";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";

const initialState = {
    list: [],
    updatedAt: new Date(),
    loading: false,
    createCategory: {
        name: "",
        loading: false,
        minimumDeals: "",
        mainLoader: false,
        showPrompt: true,
        category: null,
        slots: {
            offerWallName: null,
            id: null,
            slotsError: false,
            priority: "",
            selectedListItems: [],
            isEdit: false,
        },
    },
    offerWallList: [],
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.DEAL_CATEGORY.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.DEAL_CATEGORY.filterOperator,
        filtersOperator: FILTERS.DEAL_CATEGORY.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "collectionDeals"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchDealCategoriesData = createAsyncThunk(
    "deals/categories/listings",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const pagination = get(listings, "collectionDeals.pagination", {});
        const params = paginationParams(pagination);
        let tagFilters = get(
            listings,
            "collectionDeals.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.DEAL_CATEGORY
        );
        const filterParamsList = [
            "filters",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(collectionDeals.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.collectionDeals.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.DEAL_CATEGORY,
                filterParamsList
            );
        }
        const listingData = get(
            getState(),
            "listings.collectionDeals.list",
            []
        );
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                DEALS.FETCH_DEAL_CATEGORIES_LIST,
                "POST",
                filterParamsData,
                {},
                null,
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch Deal Categories",
                        })
                    );
                    dispatch(
                        collectionDealsActions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(
                collectionDealsActions.setFilter(filterParamsData?.filters)
            );
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metadata: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            const errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.DEAL_CATEGORY}`;
            dispatch(collectionDealsActions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            const errorMessage = `${MESSAGES.TABLE.noData} Create a new Deal Category`;
            dispatch(collectionDealsActions.setTableError(errorMessage));
        }
        return data;
    }
);

export const createDealCategory = createAsyncThunk(
    "deals/dealCategory/createdealcategory",
    async (_, { getState, dispatch }) => {
        const catName = getState().listings.collectionDeals.createCategory.name;
        const params = { name: catName };
        const response = await customFetch(
            DEALS.CREATE_DEAL_CATEGORY,
            "POST",
            params
        );
        if (response == null || response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create Deal Category!",
                })
            );
        }
        return get(response, "data", null);
    }
);

export const updateDealCategory = createAsyncThunk(
    "deals/dealCategory/updatedealcategory",
    async (id, { getState, dispatch }) => {
        const catName = getState().listings.collectionDeals.createCategory.name;
        const params = { name: catName };
        const response = await customFetch(
            `${DEALS.UPDATE_DEAL_CATEGORY}/${id}`,
            "PUT",
            params
        );
        if (response == null || response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update Deal Category!",
                })
            );
        }
        return get(response, "data", null);
    }
);

export const fetchDealCategory = createAsyncThunk(
    "deals/dealCategory/fetchDealCategory",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${DEALS.FETCH_DEAL_CATEGORY}/${id}`
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Deal Category!",
                })
            );
        }
        return get(response, "data", []);
    }
);

export const deleteDealCategory = createAsyncThunk(
    "deals/dealCategory/deletedealcategory",
    async id => {
        const response = await customFetch(
            `${DEALS.DELETE_DEAL_CATEGORY}/${id}`,
            "DELETE"
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete Gift Cards!",
                })
            );
        }
        return get(response, "data", []);
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/collectionDeals/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.collectionDeals.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.DEAL_CATEGORY.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchDealOfferWallList = createAsyncThunk(
    "listings/collectionDeals/offerwallList",
    async (_, { dispatch }) => {
        const response = await customFetch(DEALS.FETCH_OFFERWALL_LIST);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Offer Wall List!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

const collectionDeals = createSlice({
    name: "collectionDeals",
    initialState,
    reducers: {
        setCategoryName(state, action) {
            state.createCategory.name = action.payload;
        },
        setMinimumDeals(state, action) {
            state.createCategory.minimumDeals = action.payload;
        },
        setOfferWallId(state, action) {
            state.createCategory.slots.id = action.payload;
        },
        setOfferWallName(state, action) {
            state.createCategory.slots.offerWallName = action.payload;
        },
        setSlotsError(state, action) {
            state.createCategory.slots.slotsError = action.payload;
        },
        setPriority(state, action) {
            state.createCategory.slots.priority = action.payload;
        },
        setIsEdit(state, action) {
            state.createCategory.slots.isEdit = action.payload;
        },
        setSelectedListItems(state, action) {
            state.createCategory.slots.selectedListItems = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetCategory(state) {
            state.createCategory = initialState.createCategory;
        },
        setMainLoader(state, action) {
            state.createCategory.mainLoader = action.payload;
        },
        setShowPrompt(state, action) {
            state.createCategory.showPrompt = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchDealCategoriesData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchDealCategoriesData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list = get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchDealCategory.pending, state => {
            state.createCategory.loading = true;
        });
        builder.addCase(fetchDealCategory.fulfilled, (state, action) => {
            state.createCategory.loading = false;
            state.createCategory.name = action.payload.name;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(fetchDealOfferWallList.fulfilled, (state, action) => {
            state.offerWallList = action.payload;
        });
    },
});

export const collectionDealsActions = collectionDeals.actions;
export default collectionDeals.reducer;
