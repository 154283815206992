import styles from "./Pagination.module.scss";
import React, { useEffect, useState } from "react";
import Icon from "../../Shared/Icon/Icon";
import ReactDOM from "react-dom";
import { windowDimensions } from "../../utils/globalUtils";
import { useSelector } from "react-redux";

const showCountList =
    windowDimensions().height > 900 ? [15, 30, 50, 100] : [10, 25, 50, 100];

const Pagination = ({
    currentPage = 1,
    totalPages = 1,
    showCount = 10,
    totalRowsCount = null,
    onPageChange,
    onCountChange,
}) => {
    const [pageNos, setPageNos] = useState([]);
    let startPos = null;
    let endPos = null;

    const { showLeftNav } = useSelector(state => state.globalConfig);

    useEffect(() => {
        startPos = currentPage - 2 > 0 ? currentPage - 2 : 1;
        endPos = null;
        if (startPos + 4 <= totalPages) endPos = startPos + 4;
        else {
            endPos = totalPages;
            startPos = totalPages - 4 > 0 ? totalPages - 4 : 1;
        }
        setPageNos(() => {
            return Array(endPos - startPos + 1)
                .fill()
                .map((elem, index) => startPos + index);
        });
    }, [currentPage, totalPages]);
    const changeCount = count => {
        if (typeof onCountChange === "function") onCountChange(count);
    };
    const changePage = page => {
        if (typeof onPageChange === "function") onPageChange(page);
    };
    return (
        <>
            <div
                className={`${styles["table-footer-wrap"]} ${
                    showCount > 10 ? styles["showBottomSpace"] : ""
                }`}
            >
                <div className={"dp-parent dp-parent-hor-space-between "}>
                    <div className={"dp-parent dp-parent-ver-center"}>
                        <ul className={`dp-parent ${styles.pagination}`}>
                            <li
                                className={`${styles.start} ${
                                    currentPage === 1 || currentPage - 2 < 1
                                        ? styles.disabled
                                        : ""
                                }`}
                                onClick={changePage.bind(null, 1)}
                            >
                                <Icon icon="ArrowLast" size="9px" />
                            </li>
                            <li
                                className={`${styles.prev} ${
                                    currentPage === 1 ? styles.disabled : ""
                                }`}
                                onClick={changePage.bind(
                                    null,
                                    currentPage - 1 > 0 ? currentPage - 1 : 1
                                )}
                            >
                                <Icon icon="DropDownArrow" size="9px" />
                            </li>
                            {pageNos &&
                                pageNos.map(elem => (
                                    <li
                                        onClick={changePage.bind(null, elem)}
                                        className={`${
                                            elem == currentPage
                                                ? styles.active
                                                : ""
                                        }`}
                                        key={elem}
                                    >
                                        {elem}
                                    </li>
                                ))}
                            <li
                                className={`${styles.next} ${
                                    currentPage === totalPages ||
                                    totalRowsCount === 0 ||
                                    totalRowsCount === "" ||
                                    totalRowsCount === -1
                                        ? styles.disabled
                                        : ""
                                }`}
                                onClick={changePage.bind(
                                    null,
                                    currentPage + 1 < totalPages
                                        ? currentPage + 1
                                        : totalPages
                                )}
                            >
                                <Icon icon="DropDownArrow" size="9px" />
                            </li>
                            <li
                                className={`${styles.end} ${
                                    currentPage === totalPages ||
                                    currentPage > totalPages - 2
                                        ? styles.disabled
                                        : ""
                                }`}
                                onClick={changePage.bind(null, totalPages)}
                            >
                                <Icon icon="ArrowLast" size="9px" />
                            </li>
                        </ul>
                        {totalRowsCount === 0 ||
                        totalRowsCount === "" ||
                        totalRowsCount === -1 ? null : (
                            <p className={styles["pages-description"]}>
                                Showing {(currentPage - 1) * showCount + 1} -{" "}
                                {totalRowsCount != null &&
                                totalRowsCount < currentPage * showCount
                                    ? totalRowsCount
                                    : currentPage * showCount}{" "}
                                of{" "}
                                {totalRowsCount != null
                                    ? totalRowsCount
                                    : totalPages * showCount}
                            </p>
                        )}
                    </div>
                    <div
                        className={`dp-parent dp-parent-ver-center ${styles["show-count-wrap"]}`}
                    >
                        <p>Show Rows : </p>
                        <ul className={`dp-parent`}>
                            {showCountList &&
                                showCountList.map((elem, index) => (
                                    <li
                                        onClick={changeCount.bind(null, elem)}
                                        key={index}
                                        className={`${
                                            elem == showCount
                                                ? styles.active
                                                : ""
                                        }`}
                                    >
                                        {elem}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            {ReactDOM.createPortal(
                showCount > 10 && (
                    <div className={`${styles["bottomSpace"]}`}></div>
                ),
                document.body
            )}
        </>
    );
};

export default Pagination;
