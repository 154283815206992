import { defaultInitialStatusFilter } from "./filterUtils";

export const initialFilterData = [
    {
        ...defaultInitialStatusFilter,
        page: "roas",
    },
];

export const conversionUrlOptions = [
    {
        url: "https://www.cp2trk.com/?nid=1062&transaction_id={etid}",
        id: 1,
    },
];
