import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cloneDeep, forEach, get, keys } from "lodash";
import { HNP_URL, SURVEYS, MAX_ENDPOINT } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import {
    mapTargettingParams,
    keyValsFromApi,
    getRandomId,
} from "../../utils/globalUtils";
import { toastListActions } from "../toastList/toastList";

const initialTargetParamsState = {
    region: [],
    countries: [],
    browsers: [],
    devices: [],
    os: [],
    genders: [],
    utmMediums: [],
    utmSources: [],
    utmCampaigns: [],
    utmContents: [],
    ageRange: [],
    keywords: [],
    availableSet: ["utmSources", "region", "keywords", "ageRange"],
    keyVals: [],
    zipCodes: [],
    timeOfDay: [],
    allowedDays: [],
    landingKeywords: [],
    landingJobTypes: [],
    // spamCheck: {
    //     spamIP: false,
    //     category: [],
    // },
    jobTypes: [],
    visitCount: [],
    registeredVisitCount: [],
    userStatus: [],
    keywordCategories: [],
    city: [],
};

const initialOfferPathState = {
    isNewOfferPath: false,
    adSlotsData: [],
    basics: {
        name: null,
        weight: "",
        status: true,
        error: false,
        weightError: false,
        pathNameError: false,
        option: null,
        surveys: [],
        maxSlotsToReturn: "",
        enableAutoOfferPath: false,
        explorationWeight: "",
        maxSlotsToReturnError: false,
        explorationWeightError: false,
    },
    dedupeRules: [],
    slots: {
        error: false,
        index: "",
        type: "",
        trafficSplit: "",
        offerWall: "",
        offerWallTemplate: "",
        selectedListItems: [],
        editIndex: "",
        editType: "WeightGroup",
        editTrafficSplit: "",
        editOfferWallTemplate: "",
        editId: "",
        toggle: false,
        weight: "",
        dealId: "",
        dedupeRules: [],
        customKeyVal: [],
        revenueType: null,
        creative: null,
        editWeight: "",
        editDealId: "",
        editRevenueType: null,
        editCreative: null,
        selectedMode: null,
        selectedRowId: null,
        rowTypeMode: "",
        expandCollapseTable: false,
        // toggleHistory: false,
        // historyData: [],
        deletedSlots: [],
        deletedSlotAds: [],
    },
    targettingParam: {
        targetParams: initialTargetParamsState,
        dropDownState: {
            plusDropdownData: "",
            showPlusDropdown: false,
            productList: [
                { id: 1, firstName: "abcd", lastName: "myjobscornesssr.com" },
                { id: 2, firstName: "eafgbh", lastName: "realdealsweeps.com" },
                { id: 3, firstName: "ijkl", lastName: "rewardsurveyusa.com" },
                { id: 4, firstName: "mnop", lastName: "test@test.com" },
                { id: 5, firstName: "qrst", lastName: "test@test.com" },
                { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                { id: 7, firstName: "yz", lastName: "test@test.com" },
            ],
            productListPlusData: [
                {
                    id: "utmSources",
                    firstName: "UTM Sources",
                    lastName: "myjobscornesssr.com",
                },
                {
                    id: "region",
                    firstName: "Region",
                    lastName: "realdealsweeps.com",
                },
                {
                    id: "keywords",
                    firstName: "Keywords",
                    lastName: "rewardsurveyusa.com",
                },
                {
                    id: "ageRange",
                    firstName: "Age Range",
                    lastName: "test@test.com",
                },
                {
                    id: "genders",
                    firstName: "Gender",
                    lastName: "test@test.com",
                },
                {
                    id: "countries",
                    firstName: "Countries",
                    lastName: "test@test.com",
                },
                {
                    id: "zipCodes",
                    firstName: "Zip Codes",
                    lastName: "test@test.com",
                },
                {
                    id: "timeOfDay",
                    firstName: "Time of Day",
                    lastName: "test@test.com",
                },
                {
                    id: "utmCampaigns",
                    firstName: "UTM Campaigns",
                    lastName: "test@test.com",
                },
                {
                    id: "utmContents",
                    firstName: "UTM Contents",
                    lastName: "test@test.com",
                },
                {
                    id: "utmMediums",
                    firstName: "UTM Mediums",
                    lastName: "test@test.com",
                },
                {
                    id: "os",
                    firstName: "Operating Systems",
                    lastName: "test@test.com",
                },
                {
                    id: "browsers",
                    firstName: "Browsers",
                    lastName: "test@test.com",
                },
                {
                    id: "devices",
                    firstName: "Devices",
                    lastName: "test@test.com",
                },
                {
                    id: "allowedDays",
                    firstName: "Allowed Days",
                    lastName: "test@test.com",
                },
                {
                    id: "keyVals",
                    firstName: "A/B Tests",
                    lastName: "test@test.com",
                },
                {
                    id: "landingKeywords",
                    firstName: "Landing Keywords",
                    lastName: "test@test.com",
                },
                {
                    id: "landingJobTypes",
                    firstName: "Landing Job Types",
                    lastName: "test@test.com",
                },
                {
                    id: "jobTypes",
                    firstName: "Job Types",
                    lastName: "test@test.com",
                },
                {
                    id: "visitCount",
                    firstName: "Visit Count",
                    lastName: "test@test.com",
                },
                {
                    id: "registeredVisitCount",
                    firstName: "Registered Visit Count",
                    lastName: "test@test.com",
                },
            ],
        },
        zipCodeState: {
            csvFileName: "",
            csvFile: "",
            csvArray: [],
            zipCodeError: false,
            onDropActiveZipCode: false,
            // zipCode: [],
        },
    },
};
const initialState = {
    pages: {
        "Page 1": {
            ...initialOfferPathState,
        },
    },
    list: [],
    pageOrder: ["Page 1"],
    selectedPageId: "Page 1",
    deletedPages: {},
    historyData: [],
    mainLoader: false,
};

export const adsHistory = createAsyncThunk(
    "survey/offerpaths/adshistory",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${SURVEYS.FETCH_HISTORY_LOG}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const historyInnerTable = createAsyncThunk(
    "survey/offerpaths/historyInnerTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${SURVEYS.FETCH_HISTORY_INNERTABLE_LOG}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchOfferPathData = createAsyncThunk(
    "survey/offerPath/fetchOfferPathData",
    async (_, { dispatch }) => {
        const response = await customFetch(SURVEYS.GET_OFFER_PATH_LIST, "GET");
        const data = get(response, "data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch offer paths list!",
                })
            );
        return data;
    }
);

export const fetchDealIds = createAsyncThunk(
    "survey/offerPath/fetchDealIds",
    async (advertiserId, { dispatch }) => {
        const params = {
            url: `${MAX_ENDPOINT.GET_DEAL_ID}/${advertiserId}/sponsorshipdeals`,
            options: {
                headers: {
                    "Content-type": "application/json",
                },
                method: "GET",
            },
        };
        const response = await customFetch(HNP_URL.MAX_PROXY, "POST", params);

        return { data: response?.data?.data };
    }
);

export const fetchOfferPathDataById = createAsyncThunk(
    "survey/offerPaths/fetchOfferPathDataById",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(SURVEYS.FETCH_OFFER_PATH, "POST", {
            filters: [
                {
                    k: "surveyId",
                    v: +id,
                    op: "=",
                },
            ],
        });
        const creativesList =
            getState().listings.adUnits.createAdUnit.slots.adCreativesList;
        const data = get(response, "data.data", []);
        if (response === undefined || response?.status >= 400)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch offer path data!",
                })
            );
        return [data, creativesList];
    }
);

const groupAds = {
    WeightGroup: "weightedAds",
    PriorityGroup: "priorityAds",
    RtbGroup: "rtbAds",
};

const param = {
    WeightGroup: "weight",
    PriorityGroup: "priority",
    RtbGroup: "disposition",
};

const mapCreatives = (slot, list) => {
    const ads = {
        WeightGroup: "weightGroup",
        PriorityGroup: "priorityGroup",
        RtbGroup: "rtbGroup",
    };
    const groupType = ads[slot.type];
    if (slot[groupType]) {
        return slot[groupType][groupAds[slot.type]].map(data => {
            let getindex = list.findIndex(
                element => element.id == data.creativeId
            );
            return {
                id: data.id,
                // creative: data.creativeId,
                creative: `${
                    list[getindex] && list[getindex].key
                        ? list[getindex].key
                        : "-"
                }`,
                dedupeRules: data.dedupeKeys || [],
                dealId: data.dealId,
                [param[slot.type]]: data[param[slot.type]],
                revenueType: data.revType,
                innerRowId: getRandomId(),
                customKeyVal: data.customKeyVal || [],
            };
        });
    }
    if (slot?.type === "OfferWall" && slot?.adsCreative) {
        return [
            {
                creative: `${slot?.adsCreative?.id}: ${slot?.adsCreative?.name}`,
            },
        ];
    }
    return [];
};

const getOfferPathDataByID = (list, creativesList) => {
    let pages = {};
    let pageOrder = [];
    list.length > 0 &&
        list.map((element, index) => {
            pages[`Page ${index + 1}`] = {
                ...JSON.parse(JSON.stringify(initialOfferPathState)),
            };
            pages[`Page ${index + 1}`].adSlotsData = element.offerPathAdSlots;
            pages[`Page ${index + 1}`].basics.name = element.id;
            pages[`Page ${index + 1}`].name = element.name;
            pages[`Page ${index + 1}`].basics.weight = element.weight;
            pages[`Page ${index + 1}`].basics.status = element.isActive;
            pages[`Page ${index + 1}`].basics.maxSlotsToReturn =
                element.maxSlotsToReturn === -1 ? "" : element.maxSlotsToReturn;
            pages[`Page ${index + 1}`].basics.enableAutoOfferPath =
                element.enableAutoOfferPath;
            pages[`Page ${index + 1}`].basics.explorationWeight =
                element.explorationWeight;
            pages[`Page ${index + 1}`].basics.selectedSurveyIds =
                keyValsFromApi(element.targetParams.keyVals, "survey");
            pages[`Page ${index + 1}`].dedupeRules = element.dedupeKeys || [];
            pages[`Page ${index + 1}`].slots.selectedListItems =
                element.offerPathAdSlots
                    .map(slot => ({
                        index: slot.slotIndex,
                        trafficSplit: slot.trafficSplit,
                        id: slot.id,
                        type: slot.type,
                        editCreativeData: mapCreatives(slot, creativesList),
                        rowId: getRandomId(),
                        ...(slot?.type === "RtbGroup" &&
                            slot?.rtbGroup?.dynamicSortFunc && {
                                rtbGroupId: slot?.rtbGroup?.id,
                                dynamicSortFunc: slot?.rtbGroup.dynamicSortFunc,
                            }),
                        ...(slot?.type === "OfferWall" &&
                            slot?.offerWall &&
                            slot?.adsCreative && {
                                offerWall: `${slot?.offerWall?.id}: ${slot?.offerWall?.name}`,
                                adsCreative: `${slot?.adsCreative?.id}: ${slot?.adsCreative?.name}`,
                            }),
                    }))
                    .sort((a, b) => a.index - b.index);

            pages[`Page ${index + 1}`].targettingParam.targetParams =
                mapTargettingParams(element.targetParams, true);
            pageOrder.unshift(`Page ${index + 1}`);
        });
    return [pages, pageOrder];
};

const changeToggle = (array, id) => {
    let expandAllFlag = null;
    let expandLength = 0;
    let collapseLength = 0;

    let selectedListItemsCopy = JSON.parse(JSON.stringify(array));
    selectedListItemsCopy.map(item => {
        if (item.rowId == id) {
            return (item.showAccordionTable = !item.showAccordionTable);
        }
        // else {
        //   return (item.showAccordionTable = false);
        // }
    });
    selectedListItemsCopy.forEach(element => {
        if (element.showAccordionTable) {
            expandLength = expandLength + 1;
        }
        if (!element.showAccordionTable) {
            collapseLength = collapseLength + 1;
        }
    });
    if (expandLength == selectedListItemsCopy.length) expandAllFlag = true;
    if (collapseLength == selectedListItemsCopy.length) expandAllFlag = false;
    return { selectedListItemsCopy, expandAllFlag };
};

const offerPathSlice = createSlice({
    name: "offerpath",
    initialState,
    reducers: {
        setMainLoader(state, action) {
            // const { page, data } = action.payload;
            state.mainLoader = action.payload;
        },
        setIsNewOfferPath(state, action) {
            const { page, data } = action.payload;
            state.pages[page].isNewOfferPath = data;
        },
        setOfferPathLimit(state, action) {
            const { page, data } = action.payload;
            state.pages[page].basics.maxSlotsToReturn = data;
        },
        setOfferPathLimitError(state, action) {
            const { page, data } = action.payload;
            state.pages[page].basics.maxSlotsToReturnError = data;
        },
        checkForOverAllError(state, action) {
            const page = action.payload;

            if (
                state.pages[page].basics.pathNameError ||
                state.pages[page].basics.weightError
            ) {
                state.pages[page].basics.error = true;
            } else {
                if (state.pages[page].basics.enableAutoOfferPath) {
                    if (
                        state.pages[page].basics.explorationWeight === "" ||
                        state.pages[page].basics.explorationWeight > 100 ||
                        state.pages[page].basics.maxSlotsToReturn === ""
                    ) {
                        state.pages[page].basics.error = true;
                        if (
                            state.pages[page].basics.explorationWeight === "" ||
                            state.pages[page].basics.explorationWeight > 100
                        ) {
                            state.pages[
                                page
                            ].basics.explorationWeightError = true;
                        }
                        if (state.pages[page].basics.maxSlotsToReturn === "") {
                            state.pages[
                                page
                            ].basics.maxSlotsToReturnError = true;
                        }
                    } else {
                        state.pages[page].basics.error = false;
                    }
                } else {
                    state.pages[page].basics.error = false;
                }
            }
        },
        setOfferPathBasicError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].basics.error = data;
        },
        setOfferPathName(state, action) {
            const { page, offerPath, creativesList } = action.payload;
            state.pages[page].basics.name = get(offerPath, "id", "");
            state.pages[page].basics.maxSlotsToReturn =
                get(offerPath, "maxSlotsToReturn", -1) === -1
                    ? ""
                    : get(offerPath, "maxSlotsToReturn", -1);
            state.pages[page].basics.pathNameError =
                typeof get(offerPath, "id", "") == "string" &&
                get(offerPath, "id", "").trim() == "";
            state.pages[page].name = get(offerPath, "name", "");
            state.pages[page].basics.option = offerPath;
            state.pages[page].adSlotsData = get(
                offerPath,
                "offerPathAdSlots",
                []
            );
            state.pages[page].basics.selectedSurveyIds = keyValsFromApi(
                get(offerPath, "targetParams.keyVals", []),
                "survey"
            );
            state.pages[page].slots.selectedListItems =
                offerPath?.offerPathAdSlots?.map(slot => ({
                    index: slot.slotIndex,
                    trafficSplit: slot.trafficSplit,
                    id: slot.id,
                    type: slot.type,
                    editCreativeData: mapCreatives(slot, creativesList),
                    rowId: getRandomId(),
                    // isEdit: true,
                }));

            state.pages[page].targettingParam.targetParams =
                mapTargettingParams(get(offerPath, "targetParams", ""), true);
        },
        setOfferPathWeight(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].basics.weight = payload;
            state.pages[page].basics.weightError = payload === "";
        },
        setOfferPathStatus(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].basics.status = payload;
        },
        setAutoOfferPath(state, action) {
            const { page, data } = action.payload;
            state.pages[page].basics.enableAutoOfferPath = data;
        },
        setExplorationValue(state, action) {
            const { page, data } = action.payload;
            state.pages[page].basics.explorationWeight = data;
        },
        setExplorationWeightError(state, action) {
            const { page, data } = action.payload;
            state.pages[page].basics.explorationWeightError = data;
        },
        addOfferPathTab(state, action) {
            state.pages[action.payload] = {
                ...initialOfferPathState,
                isNewTab: true,
            };
            state.pageOrder.push(action.payload);
            state.selectedPageId = action.payload;
        },
        setSelectedPageId(state, action) {
            state.selectedPageId = action.payload;
        },
        revertDeletePageId(state, action) {
            const id = state.pages[action.payload].basics.name;
            const filteredKeys = Object.keys(state.deletedPages).filter(_ => {
                const name = get(state.deletedPages, `${_}.basics.name`, null);
                return name !== id;
            });
            let obj = {};
            filteredKeys.forEach(_ => {
                const randomId = getRandomId();
                obj = {
                    ...obj,
                    [randomId]: state.deletedPages[_],
                };
            });
            state.deletedPages = { ...obj };
        },
        deletePageId(state, action) {
            let page = action.payload;
            if (get(state.pages[page], "basics.error", false))
                state.pages[page].basics.error = false;
            if (get(state.pages[page], "slots.error", false))
                state.pages[page].slots.error = false;
            if (!state.pages[action.payload].isNewTab) {
                const randomId = getRandomId();
                state.deletedPages = {
                    ...state.deletedPages,
                    [randomId]: state.pages[action.payload],
                };
            } else {
                delete state.pages[action.payload];
                state.pageOrder = state.pageOrder.filter(
                    id => id != action.payload
                );
            }
        },
        setOfferPathSlotsError(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.error = data;
        },
        addIndex(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.index = data;
        },
        addType(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.type = data;
        },
        addTrafficSplit(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.trafficSplit = data;
        },
        addSelectedListItems(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.selectedListItems = data;
        },
        addEditIndex(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editIndex = data;
        },
        setEditType(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editType = data;
        },
        setEditTrafficSplit(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editTrafficSplit = data;
        },
        setEditOfferWallTemplate(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editOfferWallTemplate = data;
        },
        setEditId(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editId = data;
        },
        setToggle(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.toggle = data;
        },
        addWeight(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.weight = data;
        },
        addDealId(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.dealId = data;
        },
        addRevenueType(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.revenueType = data;
        },
        addCreative(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.creative = data;
        },
        editWeight(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editWeight = data;
        },
        editDealId(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editDealId = data;
        },
        editRevenueType(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editRevenueType = data;
        },
        editCreative(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.editCreative = data;
        },
        selectedMode(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.selectedMode = data;
        },
        selectedRowId(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.selectedRowId = data;
        },
        rowTypeMode(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.rowTypeMode = data;
        },
        toggleShowAccordionTable(state, action) {
            const { page, data, list } = action.payload;
            const { selectedListItemsCopy, expandAllFlag } = changeToggle(
                list,
                data
            );
            state.pages[page].slots.selectedListItems = selectedListItemsCopy;
            if (expandAllFlag != null) {
                state.pages[page].slots.expandCollapseTable = expandAllFlag;
            }
        },
        expandCollapseTableToggle(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.expandCollapseTable = data;
        },
        expandCollapseTable(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.selectedListItems = data;
        },
        toggleHistory(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.toggleHistory = data;
        },
        historyData(state, action) {
            const { page, data } = action.payload;
            state.pages[page].slots.historyData = data;
        },
        setTargetParams(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.targetParams = { ...data };
        },
        setDropDownState(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.dropDownState = { ...data };
        },
        setCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.csvFileName = data;
        },
        setCsvFile(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.csvFile = data;
        },
        setCsvArray(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.csvArray = data;
        },
        setZipCodeError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.zipCodeError = data;
        },
        setOnDropActiveZipCode(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.onDropActiveZipCode =
                data;
        },
        setOfferPathNameError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].basics.pathNameError = data;
        },
        setOfferPathWeightError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].basics.weightError = data;
        },
        resetOfferPath(state, action) {
            state.pages = {
                "Page 1": {
                    ...initialOfferPathState,
                    isNewTab: true,
                },
            };
            state.pageOrder = ["Page 1"];
            state.selectedPageId = "Page 1";
            state.deletedPages = {};
        },
        revertDeleteSlots(state, action) {
            const { data, page } = action.payload;
            const index = state.pages[page].slots.deletedSlots.findIndex(
                _ => _.id === data.id
            );
            state.pages[page].slots.deletedSlots.splice(index, 1);
        },
        setDeletedSlots(state, action) {
            const { data, page } = action.payload;
            state.pages[page].slots.deletedSlots.push(data);
        },

        revertDeleteSlotAds(state, action) {
            const { data, page } = action.payload;
            const index = state.pages[page].slots.deletedSlotAds.findIndex(
                _ => _.ad.id === data.id
            );
            state.pages[page].slots.deletedSlotAds.splice(index, 1);
        },
        setDeletedSlotAds(state, action) {
            const { data, page } = action.payload;
            state.pages[page].slots.deletedSlotAds.push(data);
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        updateOfferWallData(state, action) {
            const { data, page } = action.payload;
            const { field, value } = data;
            state.pages[page].slots[field] = value;
        },
        resetOfferWallData(state, action) {
            const { page } = action.payload;
            state.pages[page].slots.offerWall = "";
            state.pages[page].slots.offerWallTemplate = "";
            state.pages[page].slots.editOfferWallTemplate = "";
        },
        setDedupe(state, action) {
            const { data, page } = action.payload;
            state.pages[page].dedupeRules = data;
        },
        setPagesData(state, action) {
            state.pages = action.payload;
        },
        setPageOrderData(state, action) {
            state.pageOrder = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchOfferPathData.fulfilled, (state, action) => {
            state.list = action.payload.data;
        });
        builder.addCase(fetchOfferPathDataById.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchOfferPathDataById.fulfilled, (state, action) => {
            state.loading = false;
            if (action.payload[0].length > 0) {
                const editData = getOfferPathDataByID(
                    action.payload[0],
                    action.payload[1]
                );
                state.pages = editData[0];
                state.pageOrder = editData[1];
            }
        });
    },
});

export const offerPathActions = offerPathSlice.actions;
export default offerPathSlice.reducer;
