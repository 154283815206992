import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { HNP_URL } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.OFFER_GROUP.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.OFFER_GROUP.filterOperator,
        filtersOperator: FILTERS.OFFER_GROUP.operator,
        filters: [],
    },
    createOfferGroup: {
        basics: {
            type: null,
            delay: null,
            hnpCategoryId: null,
            name: null,
            error: false,
        },
        offerGroup: {
            keyVals: {
                suppression: {
                    list: [],
                    enabled: false,
                },
            },
            dedupConfig: [],
        },
        offers: {
            data: [],
            error: false,
        },
        hnpCreativesList: [],
        updatedHnpCreativesList: [],
        editPhaseHnpCreativesList: [],
        offerGroupList: [],
        hnpCategoryList: [],
        isCategoryError: false,
        isGroupTypeError: false,
        isSuppressionError: false,
        mainLoader: false,
        loading: false,
        showPrompt: true,
        deletedOffers: [],
        historyData: [],
        historyDataRaw: [],
    },
    suppressionOptions: [],
    searchFilter: {
        filterApplied: getInitialTagData([], "offergroups"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const offersGroupHistory = createAsyncThunk(
    "listings/offergroups/offerhistory",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${HNP_URL.GET_OFFERS_HISTORY}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const historyListingsTable = createAsyncThunk(
    "listings/offergroups/historyOfferGroupListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${HNP_URL.FETCH_HISTORY_LISTINGS}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchHnpCategories = createAsyncThunk(
    "listings/offergroups/fetchHnpCategoes",
    async (_, { dispatch }) => {
        const response = await customFetch(HNP_URL.GET_CATEGORIES_LIST, "GET");
        const data = get(response, "data.data", []);
        if (data.length == 0) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch hnp Categories!",
                })
            );
        }
        return data;
    }
);

export const fetchHnpCreatives = createAsyncThunk(
    "listings/offergroups/fetchHnpCreatives",
    async (_, { dispatch }) => {
        const response = await customFetch(
            HNP_URL.GET_LEAD_DEFINITION_LIST,
            "GET"
        );
        const data = get(response, "data.data", []);
        if (data.length == 0) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch hnp Creatives!",
                    autoClose: 5000,
                })
            );
        }
        return data;
    }
);

export const fetchOfferGroupsList = createAsyncThunk(
    "listings/offergroups/fetchoffergroups",
    async (_, { dispatch }) => {
        const response = await customFetch(
            HNP_URL.GET_OFFER_GROUPS_LIST,
            "GET"
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Offergroup List!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const createOfferGroup = createAsyncThunk(
    "listings/offerGroups/createOfferGroup",
    async (params, { dispatch }) => {
        dispatch(offerGroupsActions.setMainLoader(true));
        const response = await customFetch(
            HNP_URL.CREATE_OFFER_GROUP,
            "POST",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create Offer group!",
                })
            );
        } else {
            dispatch(createOffers(response.data.data.id));
        }

        dispatch(offerGroupsActions.setMainLoader(false));
        return response;
    }
);

export const updateOfferGroup = createAsyncThunk(
    "listings/offerGroups/updateOfferGroup",
    async ({ id, params }, { dispatch, getState }) => {
        dispatch(offerGroupsActions.setMainLoader(true));
        const response = await customFetch(
            `${HNP_URL.UPDATE_OFFER_GROUP}/${id}`,
            "PUT",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update Offer group!",
                })
            );
        } else {
            await dispatch(deleteOffers());
            await dispatch(updateOffers(response.data.data.id));
        }
        dispatch(offerGroupsActions.setMainLoader(false));
        return response;
    }
);

const createOffers = createAsyncThunk(
    "listings/offerGroups/createoffers",
    async (id, { getState, dispatch }) => {
        const offers =
            getState().listings.offerGroups.createOfferGroup.offers.data;
        const offersApi = offers.map(offer =>
            customFetch(HNP_URL.CREATE_OFFERS, "POST", offerParams(offer, id))
        );
        const responses = await fetchAll(offersApi);
        const failedResLength = responses.filter(
            res => res.status === 500
        ).length;
        if (failedResLength) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to add ${failedResLength} Offer(s), Selected Creative is already mapped to some Offer`,
                })
            );
        }
        return responses;
    }
);

const updateOffers = createAsyncThunk(
    "listings/offergroups/updateoffers",
    async (id, { getState, dispatch }) => {
        const offers =
            getState().listings.offerGroups.createOfferGroup.offers.data;
        const createOffers = offers.filter(offer => offer.id === "-");
        const updateOffers = offers.filter(
            offer => offer.id !== "-" && offer.isEdit
        );
        const updateOffersApi = await updateOffers.map(offer =>
            customFetch(
                `${HNP_URL.CREATE_OFFERS}/${offer.id}`,
                "PUT",
                offerParams(offer, id)
            )
        );
        let updateResponse = await fetchAll([...updateOffersApi]);
        let failedResLength = await updateResponse.filter(
            res => res.status === 500
        ).length;
        if (failedResLength) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to add ${failedResLength} Offer(s), Selected Creative is already mapped to some Offer.`,
                })
            );
        }
        const createOffersApi = createOffers.map(offer =>
            customFetch(HNP_URL.CREATE_OFFERS, "POST", offerParams(offer, id))
        );
        const postResponse = await fetchAll([...createOffersApi]);
        failedResLength = await postResponse.filter(res => res.status === 500)
            .length;
        if (failedResLength) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to add ${failedResLength} Offer(s), Selected Creative is already mapped to some Offer.`,
                })
            );
        }

        let responses = [...updateResponse, ...postResponse];
        return responses;
    }
);

const deleteOffers = createAsyncThunk(
    "listings/offergroups/deleteoffers",
    async (_, { getState }) => {
        const offersToBeDeleted =
            getState().listings.offerGroups.createOfferGroup.deletedOffers;
        const deletedOffers = deleteOffersApi(offersToBeDeleted);
        let deleteResponse = await fetchAll([...deletedOffers]);
        let failedResLength = await deleteResponse.filter(
            res => res.status === 500
        ).length;
        if (failedResLength) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete ${failedResLength} Offer(s).`,
                })
            );
        }
        return deleteResponse;
    }
);

const offerParams = (offer, id) => {
    let dedupConfigCopy = null;
    if (offer?.dedupConfig?.length && offer?.dedupConfig != null) {
        let copyDedupe = JSON.parse(JSON.stringify(offer.dedupConfig));
        dedupConfigCopy = copyDedupe;
    }
    const obj = {
        dealId: offer.dealId,
        delay: +offer.delay,
        disposition: +offer.disposition,
        hnpCreativeId: offer.creative.id,
        hnpOfferGroupId: id,
        dedupConfig: dedupConfigCopy,
    };
    if (offer.id && offer.id !== "-" && offer.isEdit) {
        obj["id"] = offer.id;
    }
    return obj;
};

const deleteOffersApi = (offers, header = null) => {
    return offers.map(offer =>
        customFetch(
            `${HNP_URL.DELETE_OFFERS_ROW}/${offer.id}`,
            "DELETE",
            {},
            header
        )
    );
};

export const fetchOfferGroup = createAsyncThunk(
    "listings/offerGroups/fetchOfferGroup",
    async (id, { getState, dispatch }) => {
        dispatch(offerGroupsActions.setCreateOfferGroupLoading(true));
        const response = await customFetch(
            `${HNP_URL.GET_OFFER_GROUP}/${id}`,
            "GET"
        );
        if (
            isEmpty(get(response, "data.data", {})) ||
            get(response, "status", "") != 200
        )
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Offer Group Data!",
                    autoClose: 5000,
                })
            );
        dispatch(
            offerGroupsActions.setCreateOfferGroupOfferGroup(
                get(response, "data.data", {})
            )
        );
    }
);

export const deleteOfferGroup = createAsyncThunk(
    "listings/offerGroups/deleteOfferGroup",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${HNP_URL.DELETE_OFFER_GROUP}/${id}`,
            "DELETE"
        );
        if (get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Successfully deleted the offer group with id - ${id}`,
                })
            );
            dispatch(fetchOfferGroupsData());
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete the offer group with id - ${id}`,
                })
            );
        }
        return response;
    }
);

export const fetchOfferGroupsData = createAsyncThunk(
    "listings/offerGroups/fetchOfferGroupsListingData",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "offerGroups.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "offerGroups.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.OFFER_GROUP
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        const listingData = get(getState(), "listings.offerGroups.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                HNP_URL.FETCH_OFFER_GROUPS_LIST,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch Offer groups",
                        })
                    );
                    dispatch(
                        offerGroupsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(offerGroupsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }

        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.OFFER_GROUPS}`;
            dispatch(offerGroupsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData}, Create a new Offer Group`;
            dispatch(offerGroupsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchSuppressionListOptions = createAsyncThunk(
    "listings/offerGroups/suppressionListOptions",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            HNP_URL.FETCH_SUPPRESSION_LIST,
            "GET"
        );
        if (get(response, "data.success", false)) {
            dispatch(
                offerGroupsActions.setSuppressionListOptions(response.data.data)
            );
        }
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/offerGroups/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.offerGroups.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.OFFER_GROUP.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const offerGroupsSlice = createSlice({
    name: "offerGroups",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        setCreateOfferGroupBasicsData(state, action) {
            state.createOfferGroup.basics = { ...action.payload };
        },
        setCreateOfferGroupIsCategoryError(state, action) {
            state.createOfferGroup.isCategoryError = action.payload;
        },
        setCreateOfferGroupIsGroupTypeError(state, action) {
            state.createOfferGroup.isGroupTypeError = action.payload;
        },
        setCreateOfferGroupIsSuppressionError(state, action) {
            state.createOfferGroup.isSuppressionError = action.payload;
        },
        setCreateOfferGroupBasicError(state, action) {
            state.createOfferGroup.basics.error = action.payload;
        },
        setCreateOfferGroupOffersError(state, action) {
            state.createOfferGroup.offers.error = action.payload;
        },
        setMainLoader(state, action) {
            state.createOfferGroup.mainLoader = action.payload;
        },
        setCreateOfferGroupLoading(state, action) {
            state.createOfferGroup.loading = action.payload;
        },
        setCreateOfferGroupShowPrompt(state, action) {
            state.createOfferGroup.showPrompt = action.payload;
        },
        setCreateOfferGroupOfferGroup(state, action) {
            const default_keyvals = state.createOfferGroup.offerGroup.keyVals;
            let copyObject = structuredClone(action.payload);
            if (copyObject.dedupConfig === null) {
                copyObject.dedupConfig = [];
            }

            copyObject.hnpOffers?.forEach((element, idx) => {
                if (element.dedupConfig === null) {
                    element.dedupConfig = [];
                }
            });
            state.createOfferGroup.offerGroup = {
                ...copyObject,
            };
            if (state.createOfferGroup.offerGroup.keyVals === null)
                state.createOfferGroup.offerGroup.keyVals = default_keyvals;
        },
        setCreateOfferGroupOffers(state, action) {
            state.createOfferGroup.offers.data = action.payload;
        },
        resetData(state, action) {
            state.createOfferGroup = initialState.createOfferGroup;
        },
        revertDeleteOffers(state, action) {
            const { data } = action.payload;
            const index = state.createOfferGroup.deletedOffers.findIndex(
                _ => _.id === data.id
            );
            state.createOfferGroup.deletedOffers.splice(index, 1);
        },
        setDeletedOffers(state, action) {
            state.createOfferGroup.deletedOffers.push(action.payload);
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setSuppressionToggleValue(state, action) {
            state.createOfferGroup.offerGroup.keyVals.suppression.enabled =
                action.payload;
        },
        setSuppressionListOptions(state, action) {
            state.suppressionOptions = action.payload;
        },
        setSuppressionSelectedValues(state, action) {
            state.createOfferGroup.offerGroup.keyVals.suppression.list =
                action.payload;
        },
        setCreativesList(state, action) {
            state.createOfferGroup.hnpCreativesList = action.payload;
        },
        setUpdatedHnpCreativesList(state, action) {
            state.createOfferGroup.updatedHnpCreativesList = action.payload;
        },
        setEditPhaseHnpCreativesList(state, action) {
            state.createOfferGroup.editPhaseHnpCreativesList = action.payload;
        },
        setHistoryData(state, action) {
            state.createOfferGroup.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createOfferGroup.historyDataRaw = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setDedupe(state, action) {
            state.createOfferGroup.offerGroup.dedupConfig = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchHnpCategories.fulfilled, (state, action) => {
            state.createOfferGroup.hnpCategoryList = action.payload;
        });
        builder.addCase(fetchOfferGroupsList.fulfilled, (state, action) => {
            state.createOfferGroup.offerGroupList = action.payload;
        });
        builder.addCase(fetchHnpCreatives.fulfilled, (state, action) => {
            state.createOfferGroup.hnpCreativesList = action.payload;
        });
        builder.addCase(fetchOfferGroupsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchOfferGroupsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const offerGroupsActions = offerGroupsSlice.actions;
export default offerGroupsSlice.reducer;
