import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty, isNull, uniq } from "lodash";
import { CONFIGURATIONS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    updatedAt: new Date(),
    loading: false,
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "key",
        isDesc: false,
        totalRowsCount: 0,
        filterKey: FILTERS.FEATURE_MAP.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.FEATURE_MAP.filterOperator,
        filtersOperator: FILTERS.FEATURE_MAP.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "featuremaps"),
    },
    createFeatureMap: {
        basics: {
            name: "",
            desc: "",
            src: null,
            imageSrc: "", //introduced for save and confirm
            fileInfo: "",
            descError: false,
        },
        properties: {
            type: null,
            scope: null,
            valuekind: null,
            encodeValue: false,
            choice: [],
            scopeError: false,
            typeError: false,
            valuekindError: false,
        },
        mainLoader: false,
        loading: false,
        showPrompt: true,
        historyData: [],
        historyDataRaw: [],
    },
    createFeatureMapBackup: {},
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const historyListingsTable = createAsyncThunk(
    "listings/featureMap/historyFeatureMapListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${CONFIGURATIONS.FETCH_HISTORY_LISTINGS_FEATUREMAP}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const deleteFeatureMap = createAsyncThunk(
    "listings/featuremaps/deleteFeatureMap",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.DELETE_FEATURE_MAP}/${id}`,
            "DELETE"
        );
        if (get(response, "status", "") == 204) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Successfully deleted the feature map with name - ${id}`,
                })
            );
            dispatch(fetchFeatureMapList());
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete the feature map with name - ${id}`,
                })
            );
        }
        return response;
    }
);

export const fetchFeatureMapList = createAsyncThunk(
    "listings/featuremaps/fetchFeatureMapList",
    async (_, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "featuremaps.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "featuremaps.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.FEATURE_MAP
        );
        const filterParamsList = [
            "filters",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        let dispatchSearchError = true;
        const response = await customFetch(
            CONFIGURATIONS.FETCH_FEATURE_MAP,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch Features",
                    })
                );
                dispatch(
                    featureMapsSlice.actions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );
        const data = get(response, "data", []);
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.FEATURE_MAP}`;
            dispatch(featureMapsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Feature`;
            dispatch(featureMapsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const createAndUpdateFeatureMap = createAsyncThunk(
    "listings/featuremaps/createAndUpdateFeatureMap",
    async ({ id = null, params }, { getState, dispatch }) => {
        dispatch(featureMapsActions.setMainLoader(true));
        const response = await customFetch(
            id != null
                ? `${CONFIGURATIONS.UPDATE_FEATURE_MAP}/${id}`
                : CONFIGURATIONS.CREATE_FEATURE_MAP,
            id != null ? "PUT" : "POST",
            params
        );
        if (response && !isNull(get(response, "data.key", null))) {
            dispatch(featureMapsActions.setShowPrompt(false));
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `${
                        id != null ? "Updated" : "Created"
                    } Feature Successfully`,
                    autoClose: 5000,
                })
            );
        } else
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to ${
                        id != null ? "update" : "create"
                    } feature map!`,
                    autoClose: 5000,
                })
            );
        dispatch(featureMapsActions.setMainLoader(false));
        return response;
    }
);

export const fetchFeatureMapById = createAsyncThunk(
    "listings/featuremaps/fetchFeatureMapById",
    async (id, { dispatch, rejectWithValue }) => {
        dispatch(featureMapsActions.setMainLoader(true));
        dispatch(featureMapsActions.setLoading(true));
        const response = await customFetch(
            `${CONFIGURATIONS.GET_FEATURE_MAP_DATA}/${id}`
        );
        if (
            !(
                get(response, "status", "") == 200 &&
                !isEmpty(get(response, "data", {}))
            )
        ) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch the feature map data!",
                })
            );
            rejectWithValue({});
        }
        dispatch(featureMapsActions.setLoading(false));
        dispatch(featureMapsActions.setMainLoader(false));
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/featuremaps/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.featuremaps.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.FEATURE_MAP.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const featureMapsSlice = createSlice({
    name: "featureMaps",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        setMainLoader(state, action) {
            state.createFeatureMap.mainLoader = action.payload;
        },
        setLoading(state, action) {
            state.createFeatureMap.loading = action.payload;
        },
        setShowPrompt(state, action) {
            state.createFeatureMap.showPrompt = action.payload;
        },
        setBasicsName(state, action) {
            state.createFeatureMap.basics.name = action.payload;
        },
        setBasicsDesc(state, action) {
            state.createFeatureMap.basics.desc = action.payload;
        },
        setBasicsSrc(state, action) {
            state.createFeatureMap.basics.src = action.payload;
            state.createFeatureMap.basics.imageSrc = action.payload || "";
        },
        setBasicsImageSrc(state, action) {
            state.createFeatureMap.basics.imageSrc = action.payload || "";
        },
        setBasicsFileInfo(state, action) {
            state.createFeatureMap.basics.fileInfo = action.payload;
        },
        setPropertiesType(state, action) {
            state.createFeatureMap.properties.type = action.payload;
        },
        setPropertiesScope(state, action) {
            state.createFeatureMap.properties.scope = action.payload;
        },
        setPropertiesValueKind(state, action) {
            state.createFeatureMap.properties.valuekind = action.payload;
        },
        setPropertiesEncode(state, action) {
            state.createFeatureMap.properties.encodeValue = action.payload;
        },
        setPropertiesChoice(state, action) {
            state.createFeatureMap.properties.choice = action.payload;
        },
        addPropertiesChoice(state, action) {
            state.createFeatureMap.properties.choice = uniq([
                ...state.createFeatureMap.properties.choice,
                action.payload,
            ]);
        },
        removePropertiesChoice(state, action) {
            state.createFeatureMap.properties.choice =
                state.createFeatureMap.properties.choice.filter(
                    id => id != action.payload
                );
        },
        setBasicsDescError(state, action) {
            state.createFeatureMap.basics.descError = action.payload;
        },
        setPropertiesScopeError(state, action) {
            state.createFeatureMap.properties.scopeError = action.payload;
        },
        setPropertiesTypeError(state, action) {
            state.createFeatureMap.properties.typeError = action.payload;
        },
        setPropertiesValueKindError(state, action) {
            state.createFeatureMap.properties.valuekindError = action.payload;
        },
        setFeatureMapData(state, action) {},
        resetData(state, action) {
            state.createFeatureMap = initialState.createFeatureMap;
            state.createFeatureMapBackup = initialState.createFeatureMap;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setHistoryData(state, action) {
            state.createFeatureMap.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createFeatureMap.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchFeatureMapList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchFeatureMapList.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchFeatureMapById.fulfilled, (state, action) => {
            const {
                key = "",
                type = null,
                valuekind = null,
                choice = [],
                description = "",
                screenshoturl = null,
                scope = null,
            } = get(action, "payload.data", {});
            state.createFeatureMap.basics.name = key || "";
            state.createFeatureMap.basics.src = screenshoturl || null;
            state.createFeatureMap.basics.imageSrc = screenshoturl || "";
            state.createFeatureMap.basics.desc = description || "";
            state.createFeatureMap.properties.type = type || null;
            state.createFeatureMap.properties.valuekind = valuekind || null;
            state.createFeatureMap.properties.scope = scope || null;
            state.createFeatureMap.properties.choice = choice || [];
            state.createFeatureMapBackup = state.createFeatureMap;
        });
        builder.addCase(fetchFeatureMapById.rejected, (state, action) => {
            state.createFeatureMap = initialState.createFeatureMap;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const featureMapsActions = featureMapsSlice.actions;
export default featureMapsSlice.reducer;
