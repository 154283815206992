import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";

import Icon from "../Icon/Icon";
import styles from "./SidePanelInfinity.module.scss";
import { sidePanelListActions } from "../../store/SidePanelInfinity/sidePanelInfinity";
import { getRandomId } from "../../utils/globalUtils";
import { createTheme, ThemeProvider } from "@mui/material";
import Button, { ButtonTypes } from "../Button/Button";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { BUTTON } from "../../constants/buttonLabel";
import Tooltip from "../../Shared/Tooltip/Tooltip";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const SidePanelInfinity = forwardRef(
    (
        {
            children,
            title,
            width = "640px",
            outsideClickClose = false,
            MainContentProps = {},
            onClose,
            onSave,
            cancelButtonText = "Cancel",
            saveButtonText = "Save",
            disableSaveBtn = false,
            saveBtnTheme = ButtonTypes.primaryHover_btn,
            noContentPadding,
            showLoader = false,
            allowOverflow = false,
            dialogContentClassName = "",
            showHistoryCallback,
            navigateCallback,
            showSaveBtnTooltip = false,
            saveBtnToolTipMsg = "Save",
        },
        ref
    ) => {
        const [open, setOpen] = useState(false);
        const sidePanelList = useSelector(state => state.sidePanelInfinity);
        const dispatch = useDispatch();
        const sidePanelId = useRef(getRandomId());
        const [translateX, setTranslateX] = useState("0px");

        const theme = createTheme({
            zIndex: {
                mobileStepper: 949,
                fab: 959,
                speedDial: 969,
                appBar: 979,
                drawer: 989,
                modal: 999,
                snackbar: 1009,
                tooltip: 1019,
            },
        });
        const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);

        useEffect(() => {
            const currSidePanelPos = sidePanelList.findIndex(
                sidePanel => sidePanel.id === sidePanelId.current
            );
            if (currSidePanelPos !== -1) {
                setTranslateX(
                    -(sidePanelList.length - (currSidePanelPos + 1)) * 100 +
                        "px"
                );
            }
        }, [sidePanelList]);

        const handleClickOpen = () => {
            if (!open) {
                setOpen(true);
                dispatch(
                    sidePanelListActions.addSidePanel({
                        id: sidePanelId.current,
                    })
                );
            }
        };

        const handleClose = () => {
            if (open) {
                setOpen(false);
                dispatch(
                    sidePanelListActions.removeSidePanel(sidePanelId.current)
                );
                typeof onClose === "function" && onClose(false);
            }
        };

        useImperativeHandle(ref, () => {
            return {
                open: handleClickOpen,
                close: handleClose,
            };
        });

        return (
            <ThemeProvider theme={theme}>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={outsideClickClose ? handleClose : null}
                    TransitionComponent={Transition}
                    scroll={"paper"}
                    style={{
                        width: width,
                        right: 0,
                        left: "inherit",
                        transition: "all 0.3s ease",
                    }}
                    TransitionProps={{
                        style: {
                            transform: `translateX(${translateX})`,
                            // borderLeft: "1px solid #EAF0F3",
                            boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.05)",
                        },
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: "none",
                            background: isDarkMode
                                ? "rgba(27, 27, 27, 1)"
                                : "rgba(255, 255, 255, 1)",
                            color: isDarkMode ? "#d2e2f2" : "#0d2238",
                        },
                    }}
                >
                    <div className={styles["side-panel-header"]}>
                        {showLoader && (
                            <LinearDeterminate customClass={styles.loader} />
                        )}
                        <div>{title}</div>
                        <div className="dp-parent">
                            {typeof navigateCallback === "function" ? (
                                <div
                                    className={`${
                                        styles["side-panel-navigate"]
                                    } mr-20
                                    ${showLoader ? styles.loading : ""}
                                    `}
                                    onClick={navigateCallback}
                                >
                                    <Tooltip
                                        message={BUTTON.NEW_TAB.tooltip}
                                        position="bottom"
                                    >
                                        <Icon
                                            size={"16px"}
                                            icon={"Export"}
                                            className="circleIconColor circleIconColor--darkHover"
                                            disable={showLoader}
                                        />
                                    </Tooltip>
                                </div>
                            ) : null}
                            {typeof showHistoryCallback === "function" ? (
                                <div
                                    className={`${
                                        styles["side-panel-history"]
                                    } mr-20
                                    ${showLoader ? styles.loading : ""}
                                    `}
                                    onClick={showHistoryCallback}
                                >
                                    <Tooltip
                                        message={BUTTON.HISTORY.tooltip}
                                        position="bottom"
                                    >
                                        <Icon
                                            size={"18px"}
                                            icon={"Time"}
                                            className="circleIconColor circleIconColor--darkHover"
                                            disable={showLoader}
                                        />
                                    </Tooltip>
                                </div>
                            ) : null}

                            <div
                                className={`${styles["side-panel-close"]} ${
                                    showLoader ? styles.loading : ""
                                }`}
                                onClick={handleClose}
                            >
                                <Tooltip
                                    message={BUTTON.CLOSE.tooltip}
                                    position="bottom"
                                >
                                    <Icon
                                        size={"16px"}
                                        icon={"CloseDefault"}
                                        className="circleIconColor circleIconColor--darkHover"
                                        disable={showLoader}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <DialogContent
                        className={`${styles["side-panel-content"]} ${
                            noContentPadding ? "p-0" : ""
                        } ${allowOverflow ? styles.allowOverflow : ""}
                        } ${
                            dialogContentClassName ? dialogContentClassName : ""
                        }`}
                        id={"sidePanelBody"}
                        {...MainContentProps}
                    >
                        {children}
                    </DialogContent>
                    <div className={styles["side-panel-footer"]}>
                        <Button
                            btnTheme={ButtonTypes.default_btn}
                            className={styles["button"]}
                            onClick={() => {
                                setOpen(false);
                                handleClose();
                                // Commenting this since onClose is getting called twice
                                // typeof onClose === "function" && onClose(false);
                            }}
                        >
                            {cancelButtonText}
                        </Button>
                        {onSave ? (
                            showSaveBtnTooltip ? (
                                <Tooltip
                                    message={saveBtnToolTipMsg}
                                    position="top"
                                    customClass={styles.toolTipAlignWithSaveBtn}
                                    wrap={true}
                                >
                                    <Button
                                        className={styles["button"]}
                                        btnTheme={saveBtnTheme}
                                        onClick={onSave}
                                        disable={disableSaveBtn}
                                    >
                                        {saveButtonText}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Button
                                    className={styles["button"]}
                                    btnTheme={saveBtnTheme}
                                    onClick={onSave}
                                    disable={disableSaveBtn}
                                >
                                    {saveButtonText}
                                </Button>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </Dialog>
            </ThemeProvider>
        );
    }
);

export default SidePanelInfinity;
