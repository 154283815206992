import s from "./ConfirmAndSave.module.scss";
import Icon from "../../Shared/Icon/Icon";
import { useEffect, useState } from "react";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { getComparisonData } from "../../utils/historyPopUpUtils";
import ChangedContentRenderer from "../History/ChangedContentRenderer";
import { capitalizeString, isElementEmpty } from "../../utils/globalUtils";
import Popup from "../../Shared/Popup/Popup";
import customFetch from "../../fetch/customFetch";
import { MAIN } from "../../constants/url";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import LinearDeterminate from "../LinearDeterminate/LinearDeterminate";
import { isEmpty, isEqual } from "lodash";
import Skeleton from "react-loading-skeleton";
import { confirmAndSaveTabs } from "../../constants/confirmSaveConstants";
import FlowViewOnly from "../../Pages/FlowViewOnly/FlowViewOnly";
import { FlowLegends } from "../../Pages/CreateFlow/FlowUtils";
import InfoText from "../../Shared/InfoText/InfoText";
import DependencyItem, { DependencyTable } from "./DependencyItem";
import nothingFound from "../../assets/img/nothingFound.svg";

const ConfirmAndSave = ({
    entityId,
    entityType,
    dataUpdated,
    dataBackup,
    config,
    saveCallback,
    closeCallback,
    hideComparision = false,
    dependencyData,
    defaultAction,
    loading = false,
    isConfirm = false,
    width = null,
    bodyMaxHeight = null,
    isMultiStep = false,
    subTitle = "",
    flowData = {},
    globalRulesStartId,
    fallBackStartId,
    noChanges = false,
}) => {
    const [changedKeyNames, setChangedKeyNames] = useState([]);
    const [oldData, setOldData] = useState([]);
    const [newData, setNewData] = useState([]);
    const [dependency, setDependency] = useState({});
    const [disableSave, setDisableSave] = useState(false);
    const [loadingDependencies, setLoadingDependencies] = useState(false);
    const [action, setAction] = useState(
        defaultAction
            ? defaultAction
            : window.location.href.includes("/create")
            ? "Create"
            : "Save"
    );
    const [actionName, setActionName] = useState("Next");
    const [activeStep, setActiveStep] = useState(1);

    useEffect(async () => {
        if (dataBackup && dataUpdated && config) {
            const data = getComparisonData(
                dataBackup,
                dataUpdated,
                config,
                true
            );
            setChangedKeyNames(data[0]);
            setNewData(data[1]);
            setOldData(data[2]);
        }
        if (entityId && entityType) {
            setLoadingDependencies(true);
            const dependencies = await customFetch(
                `${MAIN.DEPENDENCY}&targetComponentType=${entityType}&targetComponentId=${entityId}`
            );
            setLoadingDependencies(false);

            const siteDependencies =
                dependencies?.data?.data?.dependencyTrees?.filter(
                    _ => _.type === "SITE"
                ) || [];
            const flowDependencies =
                dependencies?.data?.data?.dependencyTrees?.filter(
                    _ => _.type === "FLOW"
                ) || [];
            const containerDependency =
                dependencies?.data?.data?.dependencyTrees?.filter(
                    _ => _.type === "CONTAINER"
                ) || [];
            const collectionDependency =
                dependencies?.data?.data?.dependencyTrees?.filter(
                    _ => _.type === "COLLECTION"
                ) || [];
            setDependency({
                allDependencies: [
                    ...siteDependencies,
                    ...flowDependencies,
                    ...containerDependency,
                    ...collectionDependency,
                ].sort(a => (a.isLive ? -1 : 1)),
                siteDependencies,
                flowDependencies,
                containerDependency,
                collectionDependency,
            });
        }
        if (dependencyData) {
            setDependency(dependencyData);
        }
    }, []);

    const DependencyDiffItem = ({ item, entityType }) => {
        const [showTable, setShowTable] = useState(true);
        const [showDiff, setShowDiff] = useState(false);
        const [showDetails, setShowDetails] = useState(true);
        const [active, setActive] = useState(confirmAndSaveTabs[0]);

        const getName = () => {
            if (entityType === "QUESTION" || entityType === "LAYOUT")
                return item?.basics?.name;
            else return item?.name;
        };

        return (
            <>
                <div className={s.dependencyItem}>
                    <div className="p-20 pb-0">
                        {isConfirm && (
                            <div>
                                <div className="dp-parent dp-parent-hor-space-between mb-10">
                                    <div>
                                        {capitalizeString(entityType)} :{" "}
                                        <span className={s.typeValue}>
                                            {getName()}
                                        </span>
                                    </div>
                                    <div className="dp-parent dp-parent-no-shrink gap-30">
                                        <div
                                            className={`${s.btnViewDetails} ${
                                                showDetails ? s.open : ""
                                            }`}
                                            onClick={() => {
                                                setShowDetails(!showDetails);
                                            }}
                                        >
                                            {showDetails
                                                ? "Hide Details"
                                                : "View Details"}
                                        </div>
                                    </div>
                                </div>

                                <div className={`${s.details}`}>
                                    {loadingDependencies || loading ? (
                                        <LinearDeterminate></LinearDeterminate>
                                    ) : dependency?.allDependencies?.length ? (
                                        <div>
                                            <ul className="pb-10 dp-parent">
                                                {dependency?.siteDependencies
                                                    ?.length > 0 && (
                                                    <li className="mr-10">
                                                        <TagRenderer
                                                            type={"default"}
                                                            className={
                                                                "dependencyTag"
                                                            }
                                                        >
                                                            <Icon
                                                                icon={"Browser"}
                                                                size="14px"
                                                                className="mr-5"
                                                            />
                                                            {
                                                                dependency
                                                                    ?.siteDependencies
                                                                    ?.length
                                                            }{" "}
                                                            Domain
                                                            {dependency
                                                                ?.siteDependencies
                                                                ?.length > 1
                                                                ? "s"
                                                                : ""}
                                                        </TagRenderer>
                                                    </li>
                                                )}
                                                {dependency?.flowDependencies
                                                    ?.length > 0 && (
                                                    <li className="mr-10">
                                                        <TagRenderer
                                                            type={"default"}
                                                            className={
                                                                "dependencyTag"
                                                            }
                                                        >
                                                            <Icon
                                                                icon={"Flow"}
                                                                size="14px"
                                                                className="mr-5"
                                                            />
                                                            {
                                                                dependency
                                                                    ?.flowDependencies
                                                                    ?.length
                                                            }{" "}
                                                            Flow
                                                            {dependency
                                                                ?.flowDependencies
                                                                ?.length > 1
                                                                ? "s"
                                                                : ""}
                                                        </TagRenderer>
                                                    </li>
                                                )}
                                                {dependency?.containerDependency
                                                    ?.length > 0 && (
                                                    <li className="mr-10">
                                                        <TagRenderer
                                                            type={"default"}
                                                            className={
                                                                "dependencyTag"
                                                            }
                                                        >
                                                            <Icon
                                                                icon={
                                                                    "Container"
                                                                }
                                                                size="14px"
                                                                className="mr-5"
                                                            />
                                                            {
                                                                dependency
                                                                    ?.containerDependency
                                                                    ?.length
                                                            }{" "}
                                                            Independent
                                                            Container
                                                            {dependency
                                                                ?.containerDependency
                                                                ?.length > 1
                                                                ? "s"
                                                                : ""}
                                                        </TagRenderer>
                                                    </li>
                                                )}
                                                {dependency
                                                    ?.collectionDependency
                                                    ?.length > 0 && (
                                                    <li className="mr-10">
                                                        <TagRenderer
                                                            type={"default"}
                                                            className={
                                                                "dependencyTag"
                                                            }
                                                        >
                                                            <Icon
                                                                icon={
                                                                    "Collection"
                                                                }
                                                                size="14px"
                                                                className="mr-5"
                                                            />
                                                            {
                                                                dependency
                                                                    ?.collectionDependency
                                                                    ?.length
                                                            }{" "}
                                                            Independent
                                                            Collection
                                                            {dependency
                                                                ?.collectionDependency
                                                                ?.length > 1
                                                                ? "s"
                                                                : ""}
                                                        </TagRenderer>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    ) : null}
                                </div>

                                {showDetails && (
                                    <div className={`${s.buttonGroupHeader}`}>
                                        <div className={`${s.buttonGroup}`}>
                                            {confirmAndSaveTabs?.map(tab => (
                                                <div
                                                    className={`${s.tab} ${
                                                        tab === active
                                                            ? s.active
                                                            : ""
                                                    } ${
                                                        loadingDependencies
                                                            ? s.disable
                                                            : ""
                                                    }`}
                                                    key={tab}
                                                    onClick={
                                                        !loadingDependencies
                                                            ? () =>
                                                                  setActive(tab)
                                                            : null
                                                    }
                                                >
                                                    {tab}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {showDetails && (
                        <div className={s.dependencyTableWrap}>
                            <div
                                className={`${s.dependencyTable}
                                ${!showTable && !showDiff ? s.hide : ""}
                            ${active !== "Dependency" ? "p-20" : ""}
                            ${isEqual(newData, oldData) ? s.noTransition : ""}
                            `}
                            >
                                {showTable &&
                                !loadingDependencies &&
                                !loading ? (
                                    <>
                                        {active === "Dependency" && (
                                            <DependencyTable
                                                baseItem={dependency}
                                                columnsArr={"columns2"}
                                            />
                                        )}
                                        {active === "Differentiation" &&
                                        !isEqual(newData, oldData) ? (
                                            <ChangedContentRenderer
                                                keysObj={changedKeyNames}
                                                newData={newData}
                                                oldData={oldData}
                                                config={config}
                                            />
                                        ) : active !== "Dependency" ? (
                                            <div>
                                                No differences found, you can
                                                proceed saving the changes.
                                            </div>
                                        ) : null}
                                    </>
                                ) : (
                                    <div className="p-20">
                                        <Skeleton width={350} height={38} />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    };

    const gotoNext = () => {
        setActionName("Publish");
        setActiveStep(2);
    };

    const publishAction = (setDisableSave, saveCallback) => {
        setDisableSave(true);
        saveCallback();
    };

    const gotoPrevious = () => {
        setActionName("Next");
        setActiveStep(1);
    };

    const handleOnClickStep = val => {
        if (val === 1) gotoPrevious();
        if (val === 2) gotoNext();
    };

    const getEntityTypeText = val => {
        let entityType = val;
        if (val === "HNP_CATEGORY") entityType = "FLOW CATEGORY";
        if (val === "TEMPLATE") entityType = "FEATURE GROUP";
        return entityType;
    };

    return (
        <Popup
            portal={true}
            title={`Review & ${action}`}
            showHeader={false}
            showFooter={false}
            showOverlay
            customClass={s.popupBox}
            minWidth="800px"
            bodyPadding="0"
            bodyOverflow="hidden"
            onClose={() => closeCallback()}
            width={width}
            closeOutside={false}
        >
            <div
                className={`${s.header} dp-parent dp-parent-ver-center dp-parent-hor-space-between`}
            >
                <div>
                    <span>Review & {action}</span>
                    {subTitle && <span className={s.subTitle}>{subTitle}</span>}
                </div>
                <div className={s.closeIcon} onClick={() => closeCallback()}>
                    <Icon
                        icon="CloseDefault"
                        size="14px"
                        className="circleIconColor circleIconColor--darkHover"
                    />
                </div>
            </div>
            {entityId && entityType && !isConfirm ? (
                <div className={` p-20`}>
                    <div className={`mb-20 ${s.dependencyInfo}`}>
                        Saving this{" "}
                        <span className={s.dependencyKey}>
                            {entityType === "HNP_CATEGORY"
                                ? "FLOW CATEGORY"
                                : entityType}
                        </span>{" "}
                        will further Reflect on:
                    </div>
                    {!loadingDependencies ? (
                        <div className={s.dependencyDetailsWrapper}>
                            {dependency?.allDependencies?.length ? (
                                <>
                                    <ul className="p-10 dp-parent">
                                        {dependency?.flowDependencies?.length >
                                            0 && (
                                            <li className="mr-10">
                                                <TagRenderer
                                                    type={"default"}
                                                    className={"dependencyTag"}
                                                >
                                                    <Icon
                                                        icon={"Flow"}
                                                        size="14px"
                                                        className="mr-5"
                                                    />
                                                    {
                                                        dependency
                                                            ?.flowDependencies
                                                            ?.length
                                                    }{" "}
                                                    Flow
                                                    {dependency
                                                        ?.flowDependencies
                                                        ?.length > 1
                                                        ? "s"
                                                        : ""}
                                                </TagRenderer>
                                            </li>
                                        )}
                                        {dependency?.containerDependency
                                            ?.length > 0 && (
                                            <li className="mr-10">
                                                <TagRenderer
                                                    type={"default"}
                                                    className={"dependencyTag"}
                                                >
                                                    <Icon
                                                        icon={"Container"}
                                                        size="14px"
                                                        className="mr-5"
                                                    />
                                                    {
                                                        dependency
                                                            ?.containerDependency
                                                            ?.length
                                                    }{" "}
                                                    Independent Container
                                                    {dependency
                                                        ?.containerDependency
                                                        ?.length > 1
                                                        ? "s"
                                                        : ""}
                                                </TagRenderer>
                                            </li>
                                        )}
                                        {dependency?.collectionDependency
                                            ?.length > 0 && (
                                            <li className="mr-10">
                                                <TagRenderer
                                                    type={"default"}
                                                    className={"dependencyTag"}
                                                >
                                                    <Icon
                                                        icon={"Collection"}
                                                        size="14px"
                                                        className="mr-5"
                                                    />
                                                    {
                                                        dependency
                                                            ?.collectionDependency
                                                            ?.length
                                                    }{" "}
                                                    Independent Collection
                                                    {dependency
                                                        ?.collectionDependency
                                                        ?.length > 1
                                                        ? "s"
                                                        : ""}
                                                </TagRenderer>
                                            </li>
                                        )}
                                    </ul>
                                    <div
                                        className={`${s.dependencyTable} p-20`}
                                    >
                                        <DependencyTable
                                            baseItem={dependency}
                                            columnsArr={"columns2"}
                                            showBorder
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className="p-20">
                                    This {getEntityTypeText(entityType)} is not
                                    mapped anywhere, you can proceed saving the
                                    changes.
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <LinearDeterminate></LinearDeterminate>
                            <div>
                                <Skeleton width={"100%"} height={120} />
                            </div>
                        </>
                    )}
                </div>
            ) : null}

            {isMultiStep && !entityType && entityId && !noChanges && (
                <div>
                    <div
                        id="multi-step-form-container"
                        className={s.multiStepForm}
                    >
                        {!loading ? (
                            <ul
                                className={`${s.formStepper} ${s.formStepperHorizontal}`}
                            >
                                <li
                                    className={`${
                                        activeStep === 1
                                            ? s.formStepperActive
                                            : ""
                                    } ${s.formStepperList}
                                ${
                                    activeStep === 2
                                        ? s.formStepperCompleted
                                        : ""
                                }
                                `}
                                    step="1"
                                >
                                    <a
                                        className="mx-2"
                                        onClick={e => {
                                            let stepOne =
                                                document.getElementById(
                                                    "step1"
                                                );
                                            e.stopPropagation();
                                            handleOnClickStep(
                                                +stepOne?.dataset?.step
                                            );
                                        }}
                                    >
                                        <span
                                            id="step1"
                                            data-step={"1"}
                                            className={`${s.formStepperCircle}`}
                                        >
                                            <span>1</span>
                                        </span>
                                        <div className={s.label}>
                                            Review Dependency & Differences
                                        </div>
                                    </a>
                                </li>
                                {/* <!-- Step 2 --> */}
                                <li
                                    className={` ${
                                        activeStep !== 2
                                            ? s.formStepperUnfinished
                                            : ""
                                    } ${s.formStepperList}
                                ${activeStep === 2 ? s.formStepperActive : ""}
                                `}
                                    step="2"
                                >
                                    <a
                                        className="mx-2"
                                        onClick={e => {
                                            let stepTwo =
                                                document.getElementById(
                                                    "step2"
                                                );
                                            e.stopPropagation();
                                            handleOnClickStep(
                                                +stepTwo?.dataset?.step
                                            );
                                        }}
                                    >
                                        <span
                                            id="step2"
                                            data-step={"2"}
                                            className={`${
                                                s.formStepperCircle
                                            } ${
                                                activeStep !== 2
                                                    ? s.textMuted
                                                    : ""
                                            }`}
                                        >
                                            <span>2</span>
                                        </span>
                                        <div
                                            className={`${s.label} ${
                                                activeStep !== 2
                                                    ? s.textMuted
                                                    : ""
                                            }`}
                                        >
                                            Review Flow Changes
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        ) : (
                            <div className="m-10">
                                <Skeleton width={"100%"} height={120} />
                            </div>
                        )}
                    </div>
                </div>
            )}

            {!entityType && entityId && (
                <div
                    className={` ${entityId ? s.content : s.contentNormal} ${
                        s.dependencyDataWrapper
                    } p-20
                    ${!loading ? s.contentOverflow : ""}
                    `}
                    style={{ height: bodyMaxHeight }}
                >
                    {!loading ? (
                        <div
                            className={`${!loading ? s.publishWrapper : ""} ${
                                activeStep === 1 ? "p-20" : ""
                            }
                            ${
                                isMultiStep && noChanges
                                    ? s.noChangesWrapper
                                    : ""
                            }
                            `}
                        >
                            {loading ? (
                                <LinearDeterminate></LinearDeterminate>
                            ) : !isEmpty(dependencyData) ||
                              !isEmpty(dependency) ? (
                                <>
                                    {activeStep === 1 && (
                                        <div
                                            className={`${s.dependencyInfo} mb-20`}
                                        >
                                            Confirm updates and their respective
                                            reactions. Publishing this{" "}
                                            <span className={s.dependencyKey}>
                                                Flow
                                            </span>{" "}
                                            will update below listed entities:
                                        </div>
                                    )}
                                    {!isEmpty(dependencyData) &&
                                        activeStep === 1 &&
                                        dependencyData?.map(_ => {
                                            return (
                                                <div key={JSON.stringify(_)}>
                                                    <DependencyItem
                                                        item={_}
                                                        loadingDependencies={
                                                            loadingDependencies
                                                        }
                                                        loading={loading}
                                                    />
                                                </div>
                                            );
                                        })}
                                </>
                            ) : activeStep === 1 && !noChanges ? (
                                <div>
                                    No dependencies found, you can proceed
                                    saving the changes.
                                </div>
                            ) : activeStep === 1 ? (
                                <div className={s.noChanges}>
                                    <div>
                                        <img src={nothingFound} />
                                    </div>
                                    <div className={s.selectonText}>
                                        No Dependencies or changes found to
                                        publish
                                    </div>
                                </div>
                            ) : null}
                            {actionName === "Publish" &&
                                activeStep === 2 &&
                                isMultiStep &&
                                !isEmpty(flowData) &&
                                !isEmpty(flowData?.prodRules) &&
                                !noChanges && (
                                    <div className={`${s.flowContainer} `}>
                                        <InfoText
                                            message={
                                                "Below are saved changes from the beta version which will be published to production. Any local unsaved modifications are not included."
                                            }
                                            theme={"info"}
                                            showInfoIcon={true}
                                            className={s.defaultMsgPopup}
                                        />
                                        <div className={s.flowWrap}>
                                            <FlowViewOnly
                                                rules={flowData?.prodRules}
                                                startId={flowData?.flowStartId}
                                                containers={flowData.containers}
                                                globalRulesStartId={
                                                    globalRulesStartId
                                                }
                                                fallBackStartId={
                                                    fallBackStartId
                                                }
                                                showStartNodeGlobalBackFallBack={
                                                    true
                                                }
                                            />
                                        </div>
                                        <FlowLegends
                                            legends={[
                                                "added",
                                                "removed",
                                                "edited",
                                            ]}
                                            loading={flowData?.loading}
                                        ></FlowLegends>
                                    </div>
                                )}
                        </div>
                    ) : (
                        <Skeleton width={"100%"} height={"100%"} />
                    )}
                </div>
            )}

            {isConfirm && !isMultiStep && (
                <>
                    <div className="p-20">
                        <div className={`${s.dependencyInfo}`}>
                            Saving this{" "}
                            <span className={s.dependencyKey}>
                                {getEntityTypeText(entityType)}
                            </span>{" "}
                            will further Reflect on:
                        </div>
                    </div>
                    <div
                        className={`${s.contentNormal} 
                        ${!loading ? s.contentOverflow : ""}
                        ${s.dependencyDataWrapper} p-20 pt-0`}
                    >
                        {loading ? (
                            <LinearDeterminate></LinearDeterminate>
                        ) : !isEmpty([newData]) ? (
                            <>
                                {[newData]?.map(_ => {
                                    return (
                                        <DependencyDiffItem
                                            item={_}
                                            entityType={getEntityTypeText(
                                                entityType
                                            )}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <div>
                                No dependencies found, you can proceed saving
                                the changes.
                            </div>
                        )}
                    </div>
                </>
            )}

            {!hideComparision && !dependencyData && (
                <div
                    className={`${entityId ? s.content : s.contentNormal}
                ${!loading ? s.contentOverflow : ""}
                p-20`}
                >
                    {isElementEmpty(
                        <ChangedContentRenderer
                            keysObj={changedKeyNames}
                            newData={newData}
                            oldData={oldData}
                            config={config}
                        />
                    ) ? (
                        "No changes"
                    ) : (
                        <ChangedContentRenderer
                            keysObj={changedKeyNames}
                            newData={newData}
                            oldData={oldData}
                            config={config}
                        />
                    )}
                </div>
            )}

            <div
                className={`${s.footer} dp-parent dp-parent-hor-space-between dp-parent-hor-end gap-20 p-20`}
            >
                <div>
                    {activeStep === 2 && isMultiStep && !noChanges && (
                        <Button
                            onClick={() => {
                                gotoPrevious();
                            }}
                            btnTheme={ButtonTypes.default_btn}
                            disable={loadingDependencies || loading}
                        >
                            Previous
                        </Button>
                    )}
                </div>
                <div className="dp-parent dp-parent-ver-center dp-parent-hor-end gap-20">
                    <Button onClick={() => closeCallback()}>Cancel</Button>
                    {!isMultiStep && (
                        <Button
                            onClick={() => {
                                setDisableSave(true);
                                saveCallback();
                            }}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={
                                disableSave ||
                                loadingDependencies ||
                                loading ||
                                (!hideComparision &&
                                    isElementEmpty(
                                        <ChangedContentRenderer
                                            keysObj={changedKeyNames}
                                            newData={newData}
                                            oldData={oldData}
                                            config={config}
                                        />
                                    ))
                            }
                        >
                            Confirm & {action}
                        </Button>
                    )}
                    {isMultiStep && !noChanges && (
                        <Button
                            onClick={() => {
                                actionName === "Next"
                                    ? gotoNext()
                                    : publishAction(
                                          setDisableSave,
                                          saveCallback
                                      );
                            }}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={
                                disableSave ||
                                loadingDependencies ||
                                loading ||
                                (!hideComparision &&
                                    isElementEmpty(
                                        <ChangedContentRenderer
                                            keysObj={changedKeyNames}
                                            newData={newData}
                                            oldData={oldData}
                                            config={config}
                                        />
                                    ))
                            }
                        >
                            {actionName}
                        </Button>
                    )}
                </div>
            </div>
        </Popup>
    );
};

export default ConfirmAndSave;
