import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { UAC } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { MESSAGES } from "../../constants/messages";
import { FILTERS } from "../../constants/listingSearch";
import { toastListActions } from "../toastList/toastList";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getInitialRowsCount,
    getRequiredObject,
} from "../../utils/globalUtils";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    updatedAt: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.USERS_LIST.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.USERS_LIST.filterOperator,
        filtersOperator: FILTERS.USERS_LIST.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "users"),
    },
    createUsers: {
        displayName: "",
        email: "",
        status: "active",
        userGroups: [],
        removedUserGroups: [],
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchUserByEmailId = createAsyncThunk(
    "listings/uac/fetchUserByEmail",
    async (email, { getState, dispatch }) => {
        const response = await customFetch(
            `${UAC.FETCH_USERS_LIST}/${email}`,
            "GET",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch Users Details",
                    })
                );
            }
        );
        const data = get(response, "data", []);
        return data;
    }
);

export const fetchUsersList = createAsyncThunk(
    "listings/uac/fetchUsersList",
    async (fetchAll, { getState, dispatch }) => {
        const pagination = get(getState(), "uac.users.pagination", {});
        const params = paginationParams(pagination);
        const { uac } = getState();
        let tagFilters = get(uac, "users.searchFilter.filterApplied", []);
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.USERS_LIST
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        // const filterParamsData = getRequiredObject(
        //     filterParamsList,
        //     fetchAll ? {} : params
        // );
        const filterParamsData = getRequiredObject(
            filterParamsList,
            fetchAll ? {} : updatedParams
        );
        let dispatchSearchError = true;
        const response = await customFetch(
            UAC.FETCH_USERS_LIST,
            "POST",
            filterParamsData,
            {},
            null,
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch Users List",
                    })
                );
                dispatch(
                    usersSlice.actions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );

        const data = get(response, "data", []);
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.USERS_LIST}`;
            dispatch(usersSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData}. Create a new User`;
            dispatch(usersSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const updateUserByEmailId = createAsyncThunk(
    "listings/uac/updateUserByEmailId",
    async (id, { getState, dispatch }) => {
        let params = getState().uac.users.createUsers;
        params = {
            ...params,
            userGroups: params.userGroups
                .map(_ => _.id)
                .filter(_ => !params.removedUserGroups.includes(_)),
        };
        delete params.removedUserGroups; //don't need to save this

        const response = await customFetch(
            `${UAC.UPDATE_USER_LIST_BY_EMAIL}/${id}`,
            "PUT",
            params,
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to Update User",
                    })
                );
            }
        );
        return get(response, "data", []);
    }
);

export const toggleUserStatus = createAsyncThunk(
    "listings/uac/toggleUserStatus",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(
            `${UAC.UPDATE_USER_LIST_BY_EMAIL}/${params.email}`,
            "PUT",
            params,
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: ` Couldn't ${
                            actionItem.status == "inactive"
                                ? "Enable"
                                : "Disable"
                        } ${actionItem.displayName}`,
                        autoClose: 5000,
                    })
                );
            }
        );
        const data = get(response, "status", []);
        return data;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/userAccessControl/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { uac } = getState();
        let initialData = uac.users.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.USERS.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const usersSlice = createSlice({
    name: "userAccessControl",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setName(state, action) {
            state.createUsers.displayName = action.payload;
        },
        setEmail(state, action) {
            state.createUsers.email = action.payload;
        },
        setStatus(state, action) {
            state.createUsers.status = action.payload;
        },
        setUserGroups(state, action) {
            state.createUsers.userGroups = action.payload;
        },
        setRemovedUserGroups(state, action) {
            state.createUsers.removedUserGroups = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetUser(state, action) {
            state.createUsers = initialState.createUsers;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUsersList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchUserByEmailId.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchUserByEmailId.fulfilled, (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    loading: false,
                    createUsers: {
                        displayName: action.payload.displayName,
                        email: action.payload.email,
                        status: action.payload.status,
                        userGroups: action.payload.userGroups,
                        removedUserGroups: [],
                    },
                };
            }
        });
        builder.addCase(fetchUsersList.fulfilled, (state, action) => {
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
