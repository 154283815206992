import React, {
    memo,
    useState,
    useRef,
    useEffect,
    forwardRef,
    useImperativeHandle,
} from "react";
// styles import
import s from "./SelectedList.module.scss";
// children component imports
import Icon from "../../Shared/Icon/Icon";
import Input from "../../Shared/Input/Input";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import Tooltip from "../../Shared/Tooltip/Tooltip";
// external package import
import { get, cloneDeep } from "lodash-es";
import Fuse from "fuse.js";
import { FixedSizeList, areEqual } from "react-window";

import {
    SortableContainer,
    SortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import { getRandomId, search } from "../../utils/globalUtils";
import SelectInWrapper from "../SelectedList/SelectInWrapper/SelectInWrapper";
import StatusRenderer from "../Renderers/StatusRenderer/StatusRenderer";
import { logos } from "../../constants/targettingParamsConstants";
import { useSelector } from "react-redux";

const SelectedList = forwardRef(
    (
        {
            list = [],
            selected = [],
            onSelect,
            keyName,
            valueName,
            selectListType,
            onRowListSelect,
            onRowDeleteList,
            onClickIsTrue,
            onReset,
            selectAll,
            inputPlaceholder,
            selectedListActiveItem,
            setSelectedListActiveItem,
            onSortEnd,
            listName,
            selectedDetails,
            updateListHeight,
            readOnly = false,
        },
        ref
    ) => {
        const selectListTypes = {
            dragable: "dragable",
            nonDragable: "nonDragable",
            featMap: "featMap",
            featureMap: "featureMap",
            featSet: "featSet",
        };
        const [open, setOpen] = useState(false);
        const [searchTerm, setSearchTerm] = useState("");
        const [showDropdown, setShowDropdown] = useState(false);

        const inputRef = useRef(null);
        const [clearSearch, setClearSearch] = useState(false);

        const darkMode = useSelector(state => state.globalConfig.isDarkMode);

        const onSearchTerm = e => {
            setSearchTerm(e.target.value);
            if (e.target.value.length > 0) {
                setClearSearch(true);
            } else {
                setClearSearch(false);
            }
        };

        const [height, setHeight] = useState(null);
        const databoxRef = useRef(null);

        useImperativeHandle(ref, () => ({
            openAddDropdown: () => {
                setShowDropdown(true);
            },
        }));

        useEffect(() => {
            if (databoxRef.current) {
                const resizeObserver = new ResizeObserver(entries =>
                    setHeight(entries[0].target.clientHeight)
                );
                resizeObserver.observe(databoxRef.current);
                setHeight(databoxRef.current.getBoundingClientRect().height);
                return () => {
                    resizeObserver.disconnect();
                };
            }
        }, [databoxRef]);

        const onClearSearch = () => {
            setClearSearch(false);
            setSearchTerm("");
            // inputRef.current.focus();
        };

        SelectedList.handleClickOutside = event => setOpen(false);

        const style = {
            color: "#CAD1D4",
        };
        // Left checkbox JSX
        const Row = memo(({ data, index, style }) => {
            const status = get(data, `${index}.status`, "");
            const value = (
                <div className="dp-parent-inline">
                    {status && (
                        <StatusRenderer
                            status={status === "active"}
                            activeContent={""}
                            inactiveContent={""}
                        />
                    )}

                    {keyName
                        ? get(data, `${index}.${valueName}`, "")
                        : data[index]}
                </div>
            );
            const key = keyName
                ? get(data, `${index}.${keyName}`, "")
                : data[index];
            const isChecked = selected.includes(key);

            const customIcon = darkMode
                ? get(data, `${index}.whiteIcon`) || get(data, `${index}.icon`)
                : get(data, `${index}.icon`, "");
            // const customIcon = get(data, `${index}.icon`, "");
            const logo = get(data, `${index}.logo`, false);
            return (
                <>
                    <Checkbox
                        style={style}
                        onChange={() => (
                            setSelectedListActiveItem &&
                                setSelectedListActiveItem(key),
                            onSelect(
                                keyName
                                    ? get(data[index], keyName, "")
                                    : data[index],
                                isChecked,
                                selectedData,
                                keyName,
                                item => {
                                    setSelectedListActiveItem &&
                                        setSelectedListActiveItem(item);
                                }
                            )
                        )}
                        isChecked={isChecked}
                        labelText={value}
                        className="ellipsisText"
                        disabled={readOnly}
                        rowReverse={true}
                        customIcon={customIcon}
                        logo={logo}
                    />
                </>
            );
        }, areEqual);

        // Selected Jsx starts here
        const DragHandle = sortableHandle(() => (
            <span className="icon dragHandleIcon">
                {<Icon icon="Drag" size="18px" />}
            </span>
        ));

        const SortableItem = SortableElement(
            ({ items, style, id, readOnly = false }) => (
                <li
                    className={`${s.dropdown_item} draggableSelectedList ${
                        keyName
                            ? get(items, keyName, "") == selected
                                ? s.selected
                                : ""
                            : items == selected
                            ? s.selected
                            : ""
                    } ${
                        selectListType == selectListTypes.dragable ||
                        selectListType == selectListTypes.featMap
                            ? selectedListActiveItem ==
                              (keyName ? items[keyName] : items)
                                ? "active"
                                : ""
                            : ""
                    } `}
                    // style={style}
                    style={{ listStyleType: "none" }}
                    key={
                        getRandomId() +
                        (keyName ? items[valueName] : items) +
                        getRandomId() +
                        id +
                        "selected"
                    }
                    title={keyName ? items[valueName] : items}
                    onClick={
                        onClickIsTrue
                            ? () => (
                                  onRowListSelect(
                                      keyName ? get(items, keyName, "") : items
                                  ),
                                  get(items, keyName, ""),
                                  setSelectedListActiveItem(
                                      keyName ? items[keyName] : items
                                  )
                              )
                            : null
                    }
                >
                    {selectListType == selectListTypes.dragable && (
                        <DragHandle />
                    )}
                    <span className="dataBoxListTitle">
                        {keyName ? items[valueName] : items}
                    </span>
                    {!readOnly && (
                        <span
                            className="icon"
                            onClick={e => {
                                selectListType == selectListTypes.featMap ||
                                selectListType == selectListTypes.dragable
                                    ? onRowDeleteList(
                                          e,
                                          keyName
                                              ? get(items, keyName, "")
                                              : items,
                                          items,
                                          selectedListActiveItem,
                                          keyName,
                                          selectedData,
                                          item => {
                                              setSelectedListActiveItem(item);
                                          },
                                          listName
                                      )
                                    : onSelect(
                                          keyName
                                              ? get(items, keyName, "")
                                              : items
                                      );
                            }}
                        >
                            {
                                <Icon
                                    icon="Remove"
                                    size="18px"
                                    enableHover={true}
                                />
                            }
                        </span>
                    )}
                </li>
            )
        );

        const SortableList = SortableContainer(
            ({ items, readOnly = false }) => (
                <>
                    <ul style={{ listStyleType: "none" }}>
                        {items.map((item, index) => (
                            <SortableItem
                                items={item}
                                style={style}
                                id={index}
                                index={index}
                                key={`item-${getRandomId()}-${index}-${getRandomId()}-${index}`}
                            />
                        ))}
                    </ul>
                </>
            ),
            areEqual
        );
        const RowSelected = memo(
            ({ data, index, style }) => (
                <div
                    className={`${s.dropdown_item} draggableSelectedList ${
                        keyName
                            ? data[index][keyName] === selected
                                ? s.selected
                                : ""
                            : data[index] === selected
                            ? s.selected
                            : ""
                    } ${
                        selectListType === selectListTypes.dragable ||
                        selectListType === selectListTypes.featMap ||
                        selectListType === selectListTypes.featSet
                            ? selectedListActiveItem ===
                              (keyName ? data[index][keyName] : data[index])
                                ? "active"
                                : ""
                            : ""
                    } ${
                        (selectListType === selectListTypes.featureMap ||
                            selectListType === selectListTypes.featureSet) &&
                        selectedDetails ===
                            (keyName
                                ? get(data, `${index}.${keyName}`, "")
                                : get(data, index, ""))
                            ? "active"
                            : ""
                    }`}
                    style={style}
                    key={
                        (keyName ? data[index][valueName] : data[index]) +
                        getRandomId() +
                        index +
                        getRandomId() +
                        "selected"
                    }
                    title={keyName ? data[index][valueName] : data[index]}
                    onClick={
                        onClickIsTrue
                            ? () => (
                                  onRowListSelect(
                                      keyName
                                          ? get(data[index], keyName, "")
                                          : data[index]
                                  ),
                                  get(data[index], keyName, ""),
                                  setSelectedListActiveItem(
                                      keyName
                                          ? data[index][keyName]
                                          : data[index]
                                  )
                              )
                            : null
                    }
                >
                    {!readOnly &&
                        selectListType === selectListTypes.dragable && (
                            <span className="icon">
                                {<Icon icon="Drag" size="18px" />}
                            </span>
                        )}

                    {get(data[index], "status", "") && (
                        <StatusRenderer
                            status={get(data[index], "status", "") === "active"}
                            activeContent={""}
                            inactiveContent={""}
                        />
                    )}

                    <span className="dataBoxListTitle">
                        {keyName ? data[index][valueName] : data[index]}
                    </span>
                    {selectListType === selectListTypes.featMap ||
                    selectListType === selectListTypes.featureMap ? (
                        // (data[index].dataDetails.keyWarning == false) ? (
                        data[index].isError ? (
                            // !data[index].dataDetails.selectedValues ? (
                            // warnUpdate ? (
                            <div className="icon warning">
                                <Tooltip
                                    message="Select a single or multiple value to confirm feature mapping"
                                    position="top"
                                    variant="light"
                                    wrap={true}
                                >
                                    <Icon
                                        icon="Error"
                                        size="18px"
                                        className="circleIconColor--error"
                                    />
                                </Tooltip>
                            </div>
                        ) : (
                            ""
                        )
                    ) : (
                        ""
                    )}
                    {!readOnly && (
                        <span
                            className="icon"
                            onClick={e => {
                                selectListType === selectListTypes.featMap ||
                                selectListType === selectListTypes.dragable ||
                                selectListType === selectListTypes.featureMap ||
                                selectListType === selectListTypes.featSet
                                    ? onRowDeleteList(
                                          e,
                                          keyName
                                              ? get(data[index], keyName, "")
                                              : data[index],
                                          data[index],
                                          selectedListActiveItem,
                                          keyName,
                                          selectedData,
                                          item => {
                                              setSelectedListActiveItem(item);
                                          },
                                          listName
                                      )
                                    : onSelect(
                                          keyName
                                              ? get(data[index], keyName, "")
                                              : data[index]
                                      );
                            }}
                        >
                            {
                                <Icon
                                    icon="Remove"
                                    size="18px"
                                    enableHover={true}
                                />
                            }
                        </span>
                    )}
                </div>
            ),
            areEqual
        );

        const selectedData = cloneDeep(selected).map(itemId => {
            const element = list.find(
                dItem => (keyName ? get(dItem, keyName, "") : dItem) === itemId
            );
            return !!element && typeof element === "object" ? element : {};
        });

        const data = search(
            searchTerm,
            selectListType === selectListTypes.featSet ? selectedData : list,
            keyName ? [valueName] : []
        );

        return (
            <div
                className={`fadeIn ${s.selectListContainer} ${
                    open ? s.active : ""
                } ${
                    selectListType === selectListTypes.dragable
                        ? s.dragable
                        : ""
                } ${
                    selectListType === selectListTypes.featMap ||
                    selectListType === selectListTypes.featureMap ||
                    selectListType === selectListTypes.featSet
                        ? s.featMapListLayer
                        : ""
                } 
      ${selectListType === selectListTypes.featSet ? s.featSet : ""}
      ${clearSearch ? s.filterSearchHeight : ""}`}
                onClick={() => {
                    if (selectListType != selectListType.featSet)
                        setOpen(!open);
                }}
            >
                {!readOnly && selectListType === selectListTypes.nonDragable && (
                    <div className={s.searchWrapper}>
                        <div className={s.searchContainer}>
                            <div
                                className={`dp-parent ${s.inputWrapper} ${
                                    clearSearch ? s.clearSearchInput : ""
                                }`}
                            >
                                <Input
                                    type="text"
                                    placeholder={inputPlaceholder || "Search"}
                                    value={searchTerm || ""}
                                    onChange={e => onSearchTerm(e)}
                                    inputRef={inputRef}
                                    customIcon="Search"
                                />
                                {clearSearch && (
                                    <div
                                        className={`${s.icon} ${s.clearSearch}`}
                                        onClick={onClearSearch}
                                    >
                                        <Icon
                                            icon="CloseDefault"
                                            size="15px"
                                            style={style}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div className={`${s.dropdownWrapper} flex`}>
                    {!readOnly && (
                        <div className={s.dropdownList}>
                            {selectListType === selectListTypes.nonDragable ? (
                                <div
                                    className={`${s.dropdownListTitle} dp-parent`}
                                >
                                    <div className={s.selectedNumber}>
                                        Suggested {listName ? listName : "List"}
                                        <span>
                                            {list.length
                                                ? `(${list.length})`
                                                : `(${0})`}
                                        </span>
                                    </div>
                                    {!readOnly && (
                                        <div
                                            className={`${s.resetText} ${
                                                get(data, "length", 0) ===
                                                selectedData.length
                                                    ? s.textDisabled
                                                    : ""
                                            }`}
                                            onClick={() => selectAll()}
                                        >
                                            Include All
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className={s.searchWrapper}>
                                    <div className={s.searchContainer}>
                                        <div
                                            className={`dp-parent ${
                                                s.inputWrapper
                                            } ${
                                                clearSearch
                                                    ? s.clearSearchInput
                                                    : ""
                                            }`}
                                        >
                                            <Input
                                                type="text"
                                                placeholder={
                                                    inputPlaceholder || "Search"
                                                }
                                                value={searchTerm || ""}
                                                onChange={e => onSearchTerm(e)}
                                                inputRef={inputRef}
                                                outerBoxClass={
                                                    s.inputBoxWrapper
                                                }
                                                customIcon="Search"
                                            />
                                            {clearSearch && (
                                                <div
                                                    className={`${s.icon} ${s.clearSearch}`}
                                                    onClick={onClearSearch}
                                                >
                                                    <Icon
                                                        icon="CloseDefault"
                                                        size="15px"
                                                        style={style}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectListType != selectListTypes.featSet ? (
                                <div className={s.dataBox}>
                                    {data && data.length ? (
                                        <FixedSizeList
                                            itemData={data}
                                            height={
                                                get(data, "length", 0) > 6
                                                    ? updateListHeight &&
                                                      !!height
                                                        ? height
                                                        : selectListType ===
                                                              selectListTypes.featMap ||
                                                          selectListType ===
                                                              selectListTypes.featureMap
                                                        ? 437
                                                        : 317
                                                    : get(data, "length", 0) *
                                                      46
                                            }
                                            // height={
                                            //   get(data, "length", 0) > 6 ? 315 : get(data, "length", 0) * 46
                                            // }
                                            // height={422}
                                            itemCount={get(data, "length", 0)}
                                            width={"100%"}
                                            itemSize={31}
                                            className={s.list}
                                        >
                                            {Row}
                                        </FixedSizeList>
                                    ) : (
                                        <div className={"noResults"}>
                                            No {listName ? listName : "results"}{" "}
                                            found
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div ref={databoxRef} className={s.dataBox}>
                                    <div
                                        className={`${s.subhead} dp-parent dp-parent-hor-space-between dp-parent-ver-center`}
                                    >
                                        <div className={s.selectedCount}>
                                            Feature Sets added
                                            <span>
                                                {selectedData &&
                                                selectedData.length
                                                    ? `(${selectedData.length})`
                                                    : `(${0})`}
                                            </span>
                                        </div>
                                        <div
                                            className={s.add}
                                            onClick={() =>
                                                setShowDropdown(!showDropdown)
                                            }
                                        >
                                            Add +
                                        </div>
                                    </div>

                                    {showDropdown && (
                                        <SelectInWrapper
                                            label={"Add"}
                                            list={list}
                                            keyField={"name"}
                                            idField={"id"}
                                            multiSelect={true}
                                            placeholder={"Search feature set"}
                                            portal={false}
                                            isSelectOpen={true}
                                            onSelectClose={() =>
                                                setShowDropdown(false)
                                            }
                                            onSelect={item =>
                                                onSelect(item, selectedData)
                                            }
                                            selectedIds={selected}
                                            selectedData={selectedData}
                                        />
                                    )}
                                    {data && data.length && data[0].id ? (
                                        <FixedSizeList
                                            itemData={data}
                                            height={
                                                get(data, "length", 0) > 6
                                                    ? updateListHeight &&
                                                      !!height
                                                        ? height
                                                        : selectListType ===
                                                              selectListTypes.featMap ||
                                                          selectListType ===
                                                              selectListTypes.featureMap
                                                        ? 437
                                                        : selectListType ===
                                                          selectListTypes.featSet
                                                        ? 419
                                                        : 317
                                                    : get(data, "length", 0) *
                                                      46
                                            }
                                            itemCount={get(data, "length", 0)}
                                            width={"100%"}
                                            itemSize={46}
                                            className="List"
                                        >
                                            {RowSelected}
                                        </FixedSizeList>
                                    ) : (
                                        <div className={"noResults"}>
                                            No {listName ? listName : "results"}{" "}
                                            found
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    {selectListType != selectListTypes.featSet && (
                        <div className={`${s.dropdownList} ${s.checkedList}`}>
                            <div className={`${s.dropdownListTitle} dp-parent`}>
                                <div className={s.selectedNumber}>
                                    Selected
                                    <span>
                                        {selectedData && selectedData.length
                                            ? `(${selectedData.length})`
                                            : `(${0})`}
                                    </span>
                                </div>
                                {!readOnly && (
                                    <div
                                        // onClick={() => (type === "featMap" ? onReset() : "")}
                                        onClick={() => onReset()}
                                        className={`${s.resetText} ${
                                            selectedData &&
                                            selectedData.length > 0
                                                ? ""
                                                : s.textDisabled
                                        }`}
                                        // className={s.resetText}
                                    >
                                        Reset
                                    </div>
                                )}
                            </div>
                            <div
                                ref={databoxRef}
                                className={`${s.dataBox} ${
                                    selectListType === selectListTypes.dragable
                                        ? s.quesBankDataBox
                                        : ""
                                }`}
                            >
                                {selectListType === selectListTypes.dragable ? (
                                    selectedData && selectedData.length > 0 ? (
                                        <SortableList
                                            useDragHandle={true}
                                            items={selectedData}
                                            onSortEnd={onSortEnd}
                                            // useDragHandle
                                        />
                                    ) : (
                                        <div className={"noResults"}>
                                            No {listName ? listName : "items"}{" "}
                                            selected
                                        </div>
                                    )
                                ) : selectedData && selectedData.length ? (
                                    <FixedSizeList
                                        itemData={selectedData}
                                        height={
                                            get(selectedData, "length", 0) > 6
                                                ? updateListHeight && !!height
                                                    ? height
                                                    : selectListType ===
                                                          selectListTypes.featMap ||
                                                      selectListType ===
                                                          selectListTypes.featureMap
                                                    ? 437
                                                    : 317
                                                : get(
                                                      selectedData,
                                                      "length",
                                                      0
                                                  ) * 46
                                        }
                                        itemCount={get(
                                            selectedData,
                                            "length",
                                            0
                                        )}
                                        width={"100%"}
                                        itemSize={46}
                                        className="List"
                                    >
                                        {RowSelected}
                                    </FixedSizeList>
                                ) : (
                                    <div className={"noResults"}>
                                        No {listName ? listName : "items"}{" "}
                                        selected
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default SelectedList;
