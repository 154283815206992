import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import Card from "../../Shared/Card/Card";
import React, {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    useCallback,
} from "react";
import FlowQuestionBasics from "../../components/FlowQuestionBasics/FlowQuestionBasics";
import { useDispatch, useSelector } from "react-redux";
import {
    createQuestions,
    fetchQuestionData,
    questionsActions,
    updateQuestions,
    fetchLogoListings,
    fetchBannerListings,
    checkImageAvailable,
    uploadNewImage,
    fetchQuestionsHistory,
} from "../../store/flowmanager/questions";
import { get } from "lodash-es";
import { useHistory, useParams } from "react-router";
import { toastListActions } from "../../store/toastList/toastList";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import InfoText, { InfoTextTypes } from "../../Shared/InfoText/InfoText";
import styles from "./CreateFlowQuestion.module.scss";
import Popup from "../../Shared/Popup/Popup";
import Icon from "../../Shared/Icon/Icon";
import Input from "../../Shared/Input/Input";
import { debounce, isEmpty } from "lodash";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { cloneDeep } from "lodash";
import { urlValidationRegex } from "../../constants/validationUtils";
import History from "../../components/History/History";
import { flowQuestionsConfig } from "../../constants/historyConstants";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import { questionConfirmConfig } from "../../constants/confirmSaveConstants";
import { getComparisonData } from "../../utils/historyPopUpUtils";
import ConfirmAndSave from "../../components/ConfirmAndSave/ConfirmAndSave";
import { fetchAllQuestions } from "../../generalApi/generalApi";
import { useQueryClient } from "react-query";
import {
    deepObjectComparison,
    dependencyApi,
    getPreviewObj,
    isArrayEqual,
} from "../../utils/globalUtils";
import { getHTMLTextData } from "../../utils/globalUtils";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import LayoutEditComp from "../../components/LayoutEditComp/LayoutEditComp";

const CreateFlowQuestion = forwardRef(
    ({ id = null, isSidePane = false, updateButtonCheck = null }, ref) => {
        const createQuestion = useSelector(
            state => state.flowmanager.questions.createQuestion
        );
        const { loading, mainLoader, historyData, historyDataRaw } =
            useSelector(state => state.flowmanager.questions);
        const createQuestionBackup = useSelector(
            state => state.flowmanager.questions.createQuestionBackup
        );
        const isCreateFlowQuestion =
            window.location.pathname.split("/")[3] === "create";

        const type = get(createQuestion, "basics.type", "");
        const answers = get(createQuestion, "basics.answers", []);
        const basicsData = get(createQuestion, "basics", {});

        const dispatch = useDispatch();
        const [showPrompt, setShowPrompt] = useState(true);

        const urlParams = useParams();
        const history = useHistory();
        const queryClient = useQueryClient();
        const authUser = useSelector(state => state.auth.user);
        const isReadOnly = !authUser.modules.includes(
            `FlowManager.Question.Write`
        );
        const writeAccessAvailable = authUser.modules.includes(
            `FlowManager.Flow.LiveAccess.Read`
        );
        const questionTitleRef = useRef();
        const questionNameRef = useRef();
        const inputRef = useRef();
        const saveConfirmCallbacks = useRef(null);
        const questionPreview = useRef(null);
        const [typeError, setTypeError] = useState(false);

        const [logoDropDownData, setLogoDropDownData] = useState([]);
        const [logoName, setLogoName] = useState({});
        const [logoBase64, setLogoBase64] = useState("");
        const [logoNameExist, setLogoNameExist] = useState(false);
        const [logoMaxSizeExceed, setLogoMaxSizeExceed] = useState(false);
        const [showSaveConfirm, setShowSaveConfirm] = useState(false);
        const [formChanges, setFormChanges] = useState(false);

        const [bannerDropDownData, setBannerDropDownData] = useState([]);
        const [bannerName, setBannerName] = useState({});
        const [bannerBase64, setBannerBase64] = useState("");
        const [bannerNameExist, setBannerNameExist] = useState(false);
        const [bannerMaxSizeExceed, setBannerMaxSizeExceed] = useState(false);

        const [toggleLogoPopUp, setToggleLogoPopUp] = useState(false);
        const [inputLoader, setInputLoader] = useState("");
        const [popUpType, setPopUpType] = useState("");
        const [unsavedChanges, setUnsavedChanges] = useState(false);
        const [initialQuestion, setInitialQuestion] = useState({});
        const [subTitleLength, setSubTitleLength] = useState(0);
        const [titleLength, setTitleLength] = useState(0);

        const [liveAccess, setLiveAccess] = useState(false);

        const [layoutPreviewObject, setLayoutPreviewObject] = useState({});

        const { apiData: allQuestionsList = [], isLoading: questionLoading } =
            fetchAllQuestions();

        const showConfirmAndSave = async () => {
            return new Promise((resolve, reject) => {
                saveConfirmCallbacks.current = {
                    resolve,
                    reject,
                };
                setShowSaveConfirm(true);
            });
        };

        const onSaveQuestionHandler = async () => {
            let allowCrud = true;
            const logoImageSrc = get(
                createQuestion,
                "basics.logoImageSrc",
                null
            );
            const bannerImageSrc = get(
                createQuestion,
                "basics.bannerImageSrc",
                null
            );
            const updatedBasicsData = get(createQuestion, "basics", {});
            const basicsTagData = get(createQuestion, "basics.tag", null);

            const isQuestionTitleValid = questionTitleRef.current.validate();
            const isQuestionNameValid = questionNameRef.current.validate();
            const isLogoValid = urlValidationRegex.test(logoImageSrc);
            const isBannerValid = urlValidationRegex.test(bannerImageSrc);

            if (
                isQuestionTitleValid &&
                isQuestionNameValid &&
                type.length &&
                answers.length
            ) {
                const params = {
                    ...updatedBasicsData,
                    name: createQuestion.basics.name?.replaceAll(" ", "_"),
                    tag: basicsTagData,
                };
                setShowPrompt(false);

                if (logoImageSrc !== null) {
                    if (!isLogoValid) {
                        allowCrud = false;
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: "Please enter valid logo link ",
                            })
                        );
                    }
                }
                if (bannerImageSrc !== null) {
                    if (!isBannerValid) {
                        allowCrud = false;
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: "Please enter valid banner link ",
                            })
                        );
                    }
                }
                if (allowCrud) {
                    if (createQuestion?.basics?.id) {
                        allowCrud = await showConfirmAndSave();
                    }
                    if (allowCrud) {
                        id || urlParams.id
                            ? await updateSegment(params)
                            : await createSegment(params);
                    }
                }
            } else {
                if (!isQuestionTitleValid) {
                    // questionTitleRef.current.focus();
                    dispatch(questionsActions.setQuestionBasicError(true));
                }
                if (!isQuestionNameValid) {
                    questionNameRef.current.focus();
                    dispatch(questionsActions.setQuestionBasicError(true));
                }
                if (setTypeError(type.length === 0)) {
                    setTypeError(true);
                }
                if (!answers.length) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Add atleast one answer.",
                        })
                    );
                }

                allowCrud = false;
            }

            return allowCrud;
        };

        const showHistory = () => {
            historyDataAction(
                fetchQuestionsHistory,
                id ? { id: id } : urlParams,
                dispatch,
                questionsActions
            );
        };

        const navigateCallback = () => {
            window.open(`/flowmanager/question/edit/${id}`, "_blank");
        };

        const answersEdited = updatedAnswers => {
            if (!isArrayEqual(initialQuestion?.answers, updatedAnswers))
                updateButtonCheck(false);
            else updateButtonCheck(true);
        };

        const isQuestionEdited = () => {
            if (isSidePane) {
                setUnsavedChanges(true);
                const updatedBasicsData = get(createQuestion, "basics", {});
                const basicsTagData = get(createQuestion, "basics.tag", null);
                const params = {
                    ...updatedBasicsData,
                    name: createQuestion.basics.name?.replaceAll(" ", "_"),
                    tag: basicsTagData,
                };
                delete params.error;
                if (!unsavedChanges) updateButtonCheck(true);
                else if (deepObjectComparison(initialQuestion, params))
                    updateButtonCheck(true);
                else updateButtonCheck(!unsavedChanges);
            }
        };

        useImperativeHandle(ref, () => ({
            onSaveQuestionHandler: onSaveQuestionHandler,
            showHistory: showHistory,
            navigateCallback: navigateCallback,
            isQuestionEdited: isQuestionEdited,
        }));

        const getLogoListings = () => {
            dispatch(fetchLogoListings()).then(res => {
                const data = get(res, "payload", []).map((obj, idx) => {
                    let array = obj.name.split("/");
                    return {
                        ...obj,
                        shortName: array[array.length - 1],
                    };
                });
                setLogoDropDownData(data || []);
            });
        };

        const getBannerListings = () => {
            dispatch(fetchBannerListings()).then(res => {
                const data = get(res, "payload", []).map((obj, idx) => {
                    let array = obj.name.split("/");
                    return {
                        ...obj,
                        shortName: array[array.length - 1],
                    };
                });
                setBannerDropDownData(data || []);
            });
        };

        useEffect(() => {
            if (writeAccessAvailable || isCreateFlowQuestion) {
                setLiveAccess(true);
            }
            if (!writeAccessAvailable && createQuestion?.basics?.id) {
                dependencyApi("QUESTION", createQuestion?.basics?.id, () => {
                    setLiveAccess(false);
                }).then(res => {
                    const dependencyList = res?.data?.data?.dependencyList;
                    if (!isEmpty(dependencyList)) {
                        setLiveAccess(
                            !dependencyList?.FLOW?.some(
                                _ => _.isLive === true
                            ) || true
                        );
                    } else {
                        setLiveAccess(true);
                    }
                });
            }
        }, [writeAccessAvailable, createQuestion?.basics?.id]);

        useEffect(() => {
            dispatch(questionsActions.resetQuestions());
            getLogoListings();
            getBannerListings();
            setUnsavedChanges(false);
            // if (questionTitleRef.current) questionTitleRef.current.focus();
            if (id || urlParams.id) {
                dispatch(questionsActions.setLoading(true));
                dispatch(fetchQuestionData(id || urlParams.id))
                    .unwrap()
                    .then(response => {
                        if (
                            response !== null &&
                            Object.keys(response).length !== 0
                        ) {
                            delete response.createdAt;
                            delete response.isActive;
                            setInitialQuestion(response);
                            const rawQuestionSubTitle = getHTMLTextData(
                                response?.subHeader?.trim()
                            );
                            setSubTitleLength(rawQuestionSubTitle?.length);
                            const rawQuestionTitle = getHTMLTextData(
                                response?.question?.trim()
                            );
                            setTitleLength(rawQuestionTitle?.length);
                        } else {
                            setShowPrompt(false);
                            navigateToListing();
                        }
                    });
            }
        }, []);

        useEffect(() => {
            setFormChanges(
                getComparisonData(
                    createQuestion,
                    createQuestionBackup,
                    questionConfirmConfig,
                    true
                )[0]?.length > 0 || false
            );
        }, [createQuestion]);

        useEffect(() => {
            if (type.length > 0) setTypeError(false);
        }, [type]);

        const createSegment = async params => {
            dispatch(questionsActions.setMainLoader(true));
            let finalParams = cloneDeep(params);
            let updatedAnswers = params?.answers?.map((obj, i) => ({
                ...obj,
                intent: obj?.intent === false ? -1 : 1,
            }));
            finalParams.answers = updatedAnswers;
            await dispatch(createQuestions(finalParams))
                .unwrap()
                .then(response => {
                    dispatch(questionsActions.setMainLoader(false));
                    if (response) {
                        queryClient.removeQueries("fetchAllQuestions", {
                            exact: true,
                        });
                        dispatch(
                            toastListActions.setToastList({
                                type: "Success",
                                message: "Created Question Successfully",
                            })
                        );
                        !isSidePane && navigateToListing();
                    }
                });
        };

        const updateSegment = async params => {
            dispatch(questionsActions.setMainLoader(true));
            let finalParams = cloneDeep(params);
            //here update the intent if 0 to -1 as 0 is default value in backend and needs to be updated to -1 on save
            let updatedAnswers = params?.answers?.map((obj, i) => ({
                ...obj,
                intent: obj?.intent <= 0 || obj?.intent === false ? -1 : 1,
            }));
            finalParams.answers = updatedAnswers;
            await dispatch(updateQuestions(finalParams))
                .unwrap()
                .then(response => {
                    dispatch(questionsActions.setMainLoader(false));
                    if (response) {
                        dispatch(
                            toastListActions.setToastList({
                                type: "Success",
                                message: "Updated Question Successfully",
                            })
                        );
                        !isSidePane && navigateToListing();
                    }
                });
        };

        const setDataImgUpload = (file, uploadType = "logo") => {
            if (file[0]) {
                let getFileType = file[0].name.split(".");
                let fileTypeName = getFileType[0];
                if (uploadType === "logo") {
                    setLogoName({
                        path: file[0].name,
                        name: fileTypeName,
                    });
                } else {
                    setBannerName({
                        path: file[0].name,
                        name: fileTypeName,
                    });
                }
                let reader = new FileReader();
                reader.readAsDataURL(file[0]);
                reader.onload = function () {
                    if (uploadType === "logo") {
                        setLogoBase64(reader.result);
                    } else {
                        setBannerBase64(reader.result);
                    }
                };
                setToggleLogoPopUp(true);
                checkImgExist(file[0].name, uploadType);
            }
        };

        const checkImgExist = (name, uploadType = "logo") => {
            setInputLoader(true);
            dispatch(checkImageAvailable({ name, uploadType }))
                .unwrap()
                .then(response => {
                    const details = get(response, "data", "");
                    if (!details?.available) {
                        // when you can't upload
                        if (uploadType === "logo") {
                            setLogoNameExist(true);
                        } else {
                            setBannerNameExist(true);
                        }
                        setInputLoader("");
                    } else {
                        // when you can upload
                        if (uploadType === "logo") {
                            setLogoNameExist(false);
                        } else {
                            setBannerNameExist(false);
                        }
                        setInputLoader(false);
                    }
                });
        };

        const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);

        function handleDebounceFn(inputValue, name, popUpType) {
            let extenionType = name?.path?.split(".").pop();
            if (popUpType === "logo") {
                setLogoName({
                    path: `${inputValue}.${extenionType}`,
                    name: inputValue,
                });
            } else {
                setBannerName({
                    path: `${inputValue}.${extenionType}`,
                    name: inputValue,
                });
            }

            checkImgExist(`${inputValue}.${extenionType}`, popUpType);
        }

        function handleChange(event, popUpType) {
            if (event.target.value.length > 0) {
                debounceFn(
                    event.target.value,
                    popUpType === "logo" ? logoName : bannerName,
                    popUpType
                );
            } else {
                setInputLoader("");
            }
        }

        const uploadImage = popUpType => {
            if (inputRef.current.validate()) {
                let params;
                if (popUpType === "logo") {
                    params = {
                        imageName: `ragnarok/question/logos/${logoName.path}`,
                        base64Image: logoBase64.split(",").pop(),
                    };
                } else {
                    params = {
                        imageName: `ragnarok/question/banners/${bannerName.path}`,
                        base64Image: bannerBase64.split(",").pop(),
                    };
                }
                // setPopUpLoader(true);
                dispatch(uploadNewImage(params))
                    .unwrap()
                    .then(res => {
                        const status = get(res, "data.success", false);
                        if (status) {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Success",
                                    message: `${
                                        popUpType === "logo" ? "Logo" : "Banner"
                                    } Image uploaded successfully!`,
                                })
                            );
                            if (popUpType === "logo") {
                                getLogoListings();
                                dispatch(
                                    questionsActions.setCreateQuestionLogo(
                                        `ragnarok/question/logos/${logoName.name}`
                                    )
                                );
                            } else {
                                getBannerListings();
                                dispatch(
                                    questionsActions.setCreateQuestionBannerImge(
                                        `ragnarok/question/banners/${bannerName.name}`
                                    )
                                );
                            }
                        } else {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message: `Failed to upload ${
                                        popUpType === "logo" ? "Logo" : "Banner"
                                    } Image!`,
                                })
                            );
                        }

                        closePopUp();
                    });
            } else {
                inputRef.current.validate();
            }
        };

        const closePopUp = () => {
            setToggleLogoPopUp(false);
            setPopUpType("");
            if (popUpType === "logo") {
                setLogoName({});
                setLogoBase64("");
                setLogoNameExist(false);
                setLogoMaxSizeExceed(false);
            } else {
                setBannerName({});
                setBannerBase64("");
                setBannerNameExist(false);
                setBannerMaxSizeExceed(false);
            }
        };

        const navigateToListing = () => {
            history.replace({ pathname: `/flowmanager/question/listings` });
        };

        const toggleHistoryVisibilty = () => {
            dispatch(questionsActions.setHistoryData([]));
            dispatch(questionsActions.setHistoryDataRaw([]));
        };

        const showQuestionPreview = () => {
            const previewData = getPreviewObj(
                createQuestion.basics,
                "",
                {},
                {},
                true
            );
            setLayoutPreviewObject(previewData);

            questionPreview?.current?.open();
        };

        return (
            <div className="max-width-container">
                {showSaveConfirm && (
                    <ConfirmAndSave
                        hideComparision={true}
                        entityType="QUESTION"
                        entityId={createQuestion?.basics?.id}
                        dataUpdated={createQuestion}
                        dataBackup={createQuestionBackup}
                        config={questionConfirmConfig}
                        saveCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(true);
                        }}
                        closeCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(false);
                        }}
                        isConfirm={true}
                    />
                )}
                {mainLoader && !isSidePane && <LinearDeterminate />}

                {isSidePane && (
                    <div className={styles.infoText}>
                        <InfoText
                            theme={InfoTextTypes.INFO}
                            message={
                                "Editing this questions may effect multiple flows."
                            }
                            closeAble={false}
                        ></InfoText>
                    </div>
                )}

                {!isSidePane && (
                    <Card className={"sticky-below-header"}>
                        <CreateSaveHeader
                            isBig={true}
                            title={`${
                                urlParams.id ? "Edit" : "Create"
                            } Question`}
                            saveLabel={`${urlParams.id ? "Save" : "Create"}`}
                            desc={
                                "Create Question Group consisting of multiple questions that can be further linked to various survey paths "
                            }
                            showPrompt={!isReadOnly && showPrompt}
                            onSave={!isReadOnly && onSaveQuestionHandler}
                            saveBtn={mainLoader || !liveAccess}
                            showHamburgerBtn={false}
                            loading={loading}
                            showHistoryIcon={urlParams.id ? true : false}
                            showHistory={() => {
                                historyDataAction(
                                    fetchQuestionsHistory,
                                    urlParams,
                                    dispatch,
                                    questionsActions
                                );
                            }}
                            flowBtns={[
                                {
                                    key: "View Preview",
                                    icon: "EditLayoutProperties",
                                    callback: () => {
                                        showQuestionPreview();
                                    },
                                },
                            ]}
                        ></CreateSaveHeader>
                    </Card>
                )}

                <div>
                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            <Card className="card-padding">
                                <FlowQuestionBasics
                                    title="Basics"
                                    basicsData={basicsData}
                                    onChangeTitle={data => {
                                        const rawQuestionTitle =
                                            getHTMLTextData(data?.trim());
                                        isQuestionEdited();
                                        setTitleLength(
                                            rawQuestionTitle?.length
                                        );
                                        dispatch(
                                            questionsActions.setCreateQuestionTitle(
                                                data
                                            )
                                        );
                                    }}
                                    onChangeSubHeader={data => {
                                        const rawQuestionSubTitle =
                                            getHTMLTextData(data?.trim());
                                        isQuestionEdited();
                                        setSubTitleLength(
                                            rawQuestionSubTitle?.length
                                        );
                                        dispatch(
                                            questionsActions.setCreateQuestionSubHeader(
                                                data
                                            )
                                        );
                                    }}
                                    subTitleLength={subTitleLength}
                                    titleLength={titleLength}
                                    onChangeName={data => {
                                        isQuestionEdited();
                                        dispatch(
                                            questionsActions.setCreateQuestionName(
                                                data
                                            )
                                        );
                                    }}
                                    onChangeType={data => {
                                        isQuestionEdited();
                                        dispatch(
                                            questionsActions.setCreateQuestionType(
                                                data
                                            )
                                        );
                                    }}
                                    onChangeAnswers={data => {
                                        typeof updateButtonCheck ===
                                            "function" && answersEdited(data);
                                        dispatch(
                                            questionsActions.setCreateQuestionAnswers(
                                                data
                                            )
                                        );
                                        isQuestionEdited();
                                    }}
                                    onChangeHasOther={data => {
                                        isQuestionEdited();
                                        dispatch(
                                            questionsActions.setCreateQuestionHasOther(
                                                data
                                            )
                                        );
                                    }}
                                    onChangeLogoImg={data => {
                                        isQuestionEdited();
                                        dispatch(
                                            questionsActions.setCreateQuestionLogo(
                                                // data.name
                                                data
                                            )
                                        );
                                    }}
                                    onChangeBannerImg={data => {
                                        isQuestionEdited();
                                        dispatch(
                                            questionsActions.setCreateQuestionBannerImge(
                                                // data.name
                                                data
                                            )
                                        );
                                    }}
                                    onChangeTag={data => {
                                        isQuestionEdited();
                                        dispatch(
                                            questionsActions.setCreateQuestionTag(
                                                data
                                            )
                                        );
                                    }}
                                    fileUploaderActionLogo={files => {
                                        setPopUpType("logo");
                                        setDataImgUpload(files, "logo");
                                    }}
                                    maxSizeExceedFunctionLogo={val => {
                                        setLogoMaxSizeExceed(val);
                                    }}
                                    fileUploaderActionBanner={files => {
                                        setPopUpType("banner");
                                        setDataImgUpload(files, "banner");
                                    }}
                                    maxSizeExceedFunctionBanner={val => {
                                        setBannerMaxSizeExceed(val);
                                    }}
                                    ref={{
                                        title: questionTitleRef,
                                        name: questionNameRef,
                                    }}
                                    readOnly={isReadOnly}
                                    typeError={typeError}
                                    logoDropDownData={logoDropDownData}
                                    bannerDropDownData={bannerDropDownData}
                                    logoImageSrc={
                                        createQuestion.basics.logoImageSrc
                                    }
                                    bannerImageSrc={
                                        createQuestion.basics.bannerImageSrc
                                    }
                                    allQuestionsList={allQuestionsList?.data}
                                    questionLoading={questionLoading}
                                />
                            </Card>

                            {toggleLogoPopUp && (
                                <div className={styles.popUpContainer}>
                                    <Popup
                                        title="Upload Logos Image"
                                        showFooter={false}
                                        showHeader={false}
                                        // onClose={() => closePopUp()}
                                        bodyPadding={0}
                                        minWidth={530}
                                    >
                                        <div className={styles.popUpWrapper}>
                                            <div className={styles.popUpHeader}>
                                                <div
                                                    className={`${styles.text} `}
                                                >
                                                    Upload{" "}
                                                    {popUpType === "logo"
                                                        ? "Logo"
                                                        : "Banner"}{" "}
                                                    Image
                                                </div>
                                                <div
                                                    className={styles.crossIcon}
                                                    onClick={() => closePopUp()}
                                                >
                                                    <Icon
                                                        icon="CloseDefault"
                                                        size="11px"
                                                    />
                                                </div>
                                            </div>
                                            <div className={`row `}>
                                                <p
                                                    className={`headerTitleRequired`}
                                                >
                                                    Name*
                                                </p>
                                                <p className={`infoText`}>
                                                    Unique Name associated with
                                                    the Feature
                                                </p>

                                                <Input
                                                    required={true}
                                                    type="text"
                                                    value={
                                                        popUpType === "logo"
                                                            ? logoName.name
                                                            : bannerName.name
                                                    }
                                                    placeholder="Enter Image Name"
                                                    onChange={e => {
                                                        handleChange(
                                                            e,
                                                            popUpType
                                                        );
                                                    }}
                                                    maxWidth="maxChildContainer"
                                                    ref={inputRef}
                                                    checkAvailabilityLoader={
                                                        true
                                                    }
                                                    loader={inputLoader}
                                                    // disabled={editImg}
                                                />

                                                {(logoNameExist ||
                                                    bannerNameExist) && (
                                                    <div
                                                        className={
                                                            styles.errMsg
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.errorMsgText
                                                            }
                                                        >
                                                            <Icon
                                                                icon="ErrorFilled"
                                                                size="12px"
                                                            />
                                                            Image name already
                                                            exist
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={`row `}>
                                                <p
                                                    className={`headerTitleRequired`}
                                                >
                                                    {popUpType === "logo"
                                                        ? "Logo"
                                                        : "Banner"}{" "}
                                                </p>
                                                <p className={`infoText`}>
                                                    An Image associated with a
                                                    specific logo
                                                </p>
                                                <div className={`row`}>
                                                    <div
                                                        className={
                                                            styles.imgContainer
                                                        }
                                                    >
                                                        <div
                                                            className={`${styles.imgWrapper} `}
                                                        >
                                                            {/* <FileUploader
                                                            // setFiles={file => {
                                                            //     setDataImgUpload(
                                                            //         file
                                                            //     );
                                                            // }}
                                                            showHeader={false}
                                                            label={
                                                                "Click to choose Image"
                                                            }
                                                            // downloadTemplate={ZIPCODE}
                                                            templateFilename={
                                                                "zipcodes_template.csv"
                                                            }
                                                            showInfoText={false}
                                                            // dropContainer={fileDropRef}
                                                            showDownloadTemplate={
                                                                false
                                                            }
                                                            showPreview={true}
                                                            maxFiles={1}
                                                            acceptedFileformat={
                                                                "image/png,image/webp"
                                                            }
                                                            customClass={
                                                                "imageUploadDropzone"
                                                            }
                                                            defaultImg={
                                                                imageFormat
                                                            }
                                                            fileInfo={imgInfo}
                                                            maxSize={1048576}
                                                            withPreviewClassAdd={
                                                                false
                                                            }
                                                            // maxSizeExceed={val => {
                                                            //     dispatch(
                                                            //         logoLibraryActions.setImgSizeBigger(
                                                            //             val
                                                            //         )
                                                            //     );
                                                            //     uploadBtnDisableEnable(
                                                            //         val
                                                            //     );
                                                            // }}
                                                            deleteBtnRequired={
                                                                false
                                                            }
                                                        /> */}
                                                            <img
                                                                src={
                                                                    popUpType ===
                                                                    "logo"
                                                                        ? logoBase64
                                                                        : bannerBase64
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    {(logoMaxSizeExceed ||
                                                        bannerMaxSizeExceed) && (
                                                        <div
                                                            className={
                                                                styles.errMsg
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    styles.errorMsgText
                                                                }
                                                            >
                                                                <Icon
                                                                    icon="ErrorFilled"
                                                                    size="12px"
                                                                />
                                                                File size is
                                                                larger than 1 MB
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.popUpFooter}>
                                                <div
                                                    className={
                                                        styles.footerBtnWrapper
                                                    }
                                                >
                                                    <Button
                                                        btnTheme={
                                                            ButtonTypes.default_btn
                                                        }
                                                        onClick={() =>
                                                            closePopUp()
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        btnTheme={
                                                            ButtonTypes.primary_btn
                                                        }
                                                        onClick={() =>
                                                            uploadImage(
                                                                popUpType
                                                            )
                                                        }
                                                        disable={
                                                            popUpType === "logo"
                                                                ? logoNameExist ||
                                                                  logoMaxSizeExceed
                                                                : bannerNameExist ||
                                                                  bannerMaxSizeExceed
                                                        }
                                                    >
                                                        {/* {popUpLoader
                                                            ? "Uploading"
                                                            : "Upload"} */}
                                                        Upload
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Popup>
                                </div>
                            )}
                        </>
                    )}
                </div>
                {historyData && historyData.length > 0 ? (
                    <History
                        data={historyData}
                        rawData={historyDataRaw}
                        closeHistory={toggleHistoryVisibilty}
                        historyConfig={flowQuestionsConfig}
                    />
                ) : null}

                <SidePanelInfinity
                    ref={questionPreview}
                    title={"View Question Preview"}
                    width={"93%"}
                    dialogContentClassName={"popupStyle"}
                >
                    <LayoutEditComp
                        selectedFlowDomain={"realdealsweeps"}
                        defaultConfigs={[]}
                        item={layoutPreviewObject}
                        readOnly={isReadOnly}
                    />
                </SidePanelInfinity>
            </div>
        );
    }
);
export default CreateFlowQuestion;
