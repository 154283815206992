import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Button, { ButtonTypes } from "../Button/Button";
import { FileDrop } from "react-file-drop";
import Icon from "../../Shared/Icon/Icon";
import { toastListActions } from "../../store/toastList/toastList";
import styles from "./CsvFileReader.module.scss";
import { isValidIP } from "../../utils/globalUtils";
import { v4 as uuidv4 } from "uuid";

export default function CsvReader({
    type,
    csvFileName,
    setCsvFileName,
    csvFile,
    setCsvFile,
    csvArray,
    setCsvArray,
    error,
    setError,
    onDropActiveZipCode,
    setonDropActiveZipCode,

    mainState,
    setMainState,
    readOnly = false,
    showUploadMsg = true,
    disable = false,
    characterLimit = null,
    dynamicInputAndCsvUploaderType = false,
    // zipcodeValues,
    // setZipcodeValues
}) {
    const inputTag = useRef(null);
    const dispatch = useDispatch();

    const processCSV = (str, filename, delim = ",") => {
        try {
            const headers = str
                .slice(0, str.indexOf("\n"))
                // .replace(/\r?\n|\r/g, " ")
                .replace(/['"]+/g, "")
                .trim()
                .split(delim);
            const rows = str
                .slice(str.indexOf("\n") + 1)
                .replace(/['"]+/g, "")
                .split("\n");

            const newArray = rows.map(row => {
                const values = row.split(delim);
                const eachObject = headers.reduce((obj, header, i) => {
                    obj[header] = values[i];
                    return obj;
                }, {});
                return eachObject;
            });

            const characterLimitUpload = [
                ...newArray.map((element, index) => {
                    var size = Object.keys(element).length;
                    let trimmedData = element[headers[0]]
                        .replace(/\r?\n|\r/g, " ")
                        .trim();
                    if (trimmedData !== "") return trimmedData;
                }),
                ...mainState,
            ];

            if (characterLimit === null) {
                executeUpload(newArray, filename, headers);
            } else if (
                characterLimitUpload.join(",").replaceAll(",", "")?.length <=
                characterLimit
            ) {
                executeUpload(newArray, filename, headers);
            } else {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Csv file exceeds character limit.",
                    })
                );
            }
        } catch (error) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Error while uploading csv.",
                })
            );
        }
    };

    const executeUpload = (newArray, filename, headers) => {
        let valueCopy = [];
        let kwMap = []; //used for keyword mapping in site config
        let searchTermExists = false; //used for keyword mapping in site config
        newArray.map((element, index) => {
            var size = Object.keys(element)?.length;
            let trimmedData = element[headers[0]]
                .replace(/\r?\n|\r/g, " ")
                .trim();
            let trimmedData1 = ""; //used for keyword mapping in site config
            if (headers[1]) {
                //used for keyword mapping in site config
                trimmedData1 = element
                    ? element[headers[1]]?.replace(/\r?\n|\r/g, " ")?.trim()
                    : "";
            }

            if (type === "ClientIPsType") {
                if (dynamicInputAndCsvUploaderType) {
                    if (size == 1) {
                        if (!mainState.includes(parseInt(trimmedData))) {
                            valueCopy.push(trimmedData);
                        }
                        setError(false);
                    } else {
                        setError(true);
                    }
                } else {
                    if (size == 1 && isValidIP(trimmedData)) {
                        if (!mainState.includes(parseInt(trimmedData))) {
                            valueCopy.push(trimmedData);
                        }
                        setError(false);
                    } else {
                        setError(true);
                    }
                }
            } else if (type === "singleInput" || type === "singleDropdown") {
                if (size == 1) {
                    if (!mainState.includes(trimmedData)) {
                        if (trimmedData !== "") valueCopy.push(trimmedData);
                    }
                    setError(false);
                } else {
                    setError(true);
                }
            } else if (type === "keywordMapping") {
                //used for keyword mapping in site config
                if (size === 2 && trimmedData && trimmedData1) {
                    if (
                        mainState.every(_ => !_.valueOne.includes(trimmedData))
                    ) {
                        kwMap.push({
                            valueOne: [trimmedData?.toLowerCase()],
                            valueSecond: trimmedData1?.toLowerCase(),
                            rowId: uuidv4(),
                        });
                    } else {
                        searchTermExists = true;
                    }
                    setError(false);
                } else {
                    setError(true);
                }
            } else {
                if (
                    size == 1 &&
                    element[headers[0]] &&
                    trimmedData.length == 5
                ) {
                    if (!mainState.includes(trimmedData)) {
                        valueCopy.push(trimmedData);
                    }
                    setError(false);
                } else if (
                    element[headers[0]] == "" &&
                    trimmedData.length == 5
                ) {
                    setError(false);
                } else {
                    setError(true);
                }
            }
        });
        setCsvFileName(filename);
        setCsvArray(valueCopy);
        if (valueCopy.length) {
            let newZipcodes = [];
            valueCopy.map(zipcode => {
                if (mainState.indexOf(zipcode) == -1) {
                    newZipcodes.push(zipcode);
                }
            });
            const mainStateCopy = [...newZipcodes, ...mainState];
            setMainState(mainStateCopy);
        }
        if (kwMap.length) {
            //used for keyword mapping in site config
            const mainStateCopy = [...kwMap, ...mainState];
            setMainState(mainStateCopy);
            setCsvArray(kwMap);
        }
        if (searchTermExists) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Existing search keywords haven't been uploaded, pls review the data once.",
                })
            );
        }
        inputTag.current.value = "";
    };

    const submit = e => {
        // let filename = e.target.files[0].name || e[0].name;
        // const file = e.target.files[0] || e[0];

        let filename =
            e && e.target && e.target.files
                ? e.target.files[0].name
                : e[0].name;
        let file = e && e.target && e.target.files ? e.target.files[0] : e[0];

        const reader = new FileReader();

        reader.onload = function (e) {
            const text = e.target.result;
            if (typeof filename == "undefined") {
                setError(true);
            } else {
                processCSV(text, filename); // plugged in here
            }
        };
        reader.readAsText(file);
    };

    const onFileUpload = () => {
        inputTag.current.click();
    };

    const defaultState = () => {
        setCsvFileName("");
        setCsvFile("");
        setCsvArray("");
        setMainState(mainState); //added to cause re-render and show updated data
        setError(false);
    };

    const onFrameActive = () => {
        console.log("entered");
    };

    const onFrameDeactive = () => {
        console.log("exit");
    };

    useEffect(() => {
        if (error) {
            setCsvFileName("");
            setCsvFile("");
            setCsvArray("");
        }
    }, [error]);

    return (
        <form
            id={styles.csvForm}
            className={disable && styles.disableComponent}
        >
            <input
                ref={inputTag}
                type="file"
                accept=".csv"
                id="csvFile"
                onChange={e => {
                    submit(e);
                }}
            ></input>
            <FileDrop
                onTargetClick={onFileUpload}
                onDrop={e => {
                    submit(e);
                }}
                // onDragOver={(e) => {
                //   onFrameActive();
                // }}
                // onDragLeave={(e) => {
                //   onFrameDeactive();
                // }}
            ></FileDrop>
            <br />
            {!csvArray.length ? (
                <Button
                    type="submit"
                    btnTheme={ButtonTypes.primaryHover_btn}
                    disable={readOnly}
                    onClick={e => {
                        e.preventDefault();
                        onFileUpload();
                    }}
                >
                    Upload
                </Button>
            ) : (
                <>
                    <div className={styles.uploadSuccessWrapper}>
                        {showUploadMsg && (
                            <div className={styles.uploadSuccessText}>
                                <Icon icon="Check" size="14px" /> Uploaded
                                successfully
                            </div>
                        )}
                        <Button
                            type="submit"
                            btnTheme={ButtonTypes.default_btn}
                            disable={readOnly}
                            onClick={e => {
                                e.preventDefault();
                                defaultState();
                            }}
                        >
                            Close
                        </Button>
                    </div>
                </>
            )}
        </form>
    );
}
