import React, { useState, useEffect } from "react";
import Icon from "../Icon/Icon";
import c from "./Toast.module.scss";
import ReactDOM from "react-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const ToastItem = ({ item, getOffset, deleteToast, list }) => {
    let interval;
    const { id, type, message, autoClose = 5000, updatedAt } = item;
    const icon =
        type === "Error"
            ? "Error"
            : type === "Success"
            ? "Check"
            : type === "Warning"
            ? "Error" // ? "Warning"
            : "Info";
    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);
    const updateToast = () => {
        const prev = moment(updatedAt, "DD-MM-YYYY HH:mm");
        const now = moment(new Date());
        if (now.diff(prev) >= autoClose) {
            deleteToast(id);
        }
    };
    useEffect(() => {
        if (autoClose) {
            if (interval) clearInterval(interval);
            interval = setInterval(updateToast, 1000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [updatedAt]);
    return (
        <div className={c.toastWrapper}>
            {ReactDOM.createPortal(
                <div
                    style={getOffset}
                    className={`dp-parent dp-parent-ver-center  ${
                        c.toastLayer
                    } ${type ? c[type] : ""} ${
                        item.position ? c[item.position] : ""
                    } `}
                >
                    <div
                        className={`dp-parent dp-parent-ver-center dp-parent-hor-center ${c.iconWrapper}`}
                    >
                        <Icon
                            icon={`${isDarkMode ? icon + "Filled" : icon}`}
                            size="20px"
                        />
                    </div>
                    <div className={c.labelWrapper}>{message}</div>
                    <div
                        className={`dp-parent dp-parent-ver-center dp-parent-hor-center ${c.iconClose}`}
                        onClick={() => deleteToast(id)}
                    >
                        <Icon icon="CloseDefault" size="14px" />
                    </div>
                </div>,
                // document.body
                document.getElementById("toastPortal")
            )}
        </div>
    );
};

export default ToastItem;
