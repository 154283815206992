import React, { useRef, useEffect, forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import Card from "../../Shared/Card/Card";
import Input from "../../Shared/Input/Input";
import s from "./CreateDealOfferWallItem.module.scss";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import { cloneDeep, get } from "lodash";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import PanelGrid from "../../Shared/PanelGrid/PanelGrid";
import Select from "../../Shared/Select/Select";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import Icon from "../../Shared/Icon/Icon";
import { getRandomId } from "../../utils/globalUtils";
import Table from "../../Shared/Table/Table";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { collectionActions } from "../../store/flowmanager/collection";
import Popup from "../../Shared/Popup/Popup";
import CreateOfferWall from "../CreateOfferWall/CreateOfferWall";
import {
    createOfferWallActions,
    updateOfferWallData,
} from "../../store/listings/offerWall";
import { toastListActions } from "../../store/toastList/toastList";
import { windowDimensions } from "../../utils/globalUtils";

const CreateDealOfferWallItem = forwardRef(
    (
        {
            data,
            action,
            isSidePanel = false,
            onChangeDealCategory,
            onChangeMinimumDeals,
            isReadOnly,
            onSetSlots,
        },
        ref
    ) => {
        const urlParams = useParams();
        const dispatch = useDispatch();
        const history = useHistory();
        const { loading, mainLoader, showPrompt, slots } = useSelector(
            state => state.listings.collectionDeals.createCategory
        );
        const collectionItems = useSelector(
            state => state.flowmanager.collection.createCollection.items
        );
        // const items = get(collectionState, "createCollection.items", []);

        const minimumDealRef = useRef(null);
        const editOfferPanelRef = useRef(null);
        const offerWallList = useSelector(
            state => state.listings.collectionDeals.offerWallList
        );
        const allDealCategoryList = useSelector(
            state => state.listings.dealCategories.list
        );
        const priorityRef = useRef(null);
        const editOfferWallRef = useRef(null);
        const createOfferWallRef = useRef(null);
        const [dealCategoryError, setDealCategoryError] = useState(false);
        const [minimumDealError, setMinimumDealError] = useState(false);
        const [deletePopUp, setDeletePopUp] = useState(false);
        const [popupTopPosition, setPopupTopPosition] = useState();
        const [editItem, setEditItem] = useState({});
        const [offerWallId, setOfferWallId] = useState("");

        const cbFunction = (name, e) => {
            if (typeof eval(name) === "function") {
                return eval(name)(e);
            } else {
                return eval(name);
            }
        };

        const onEdit = obj => {
            setEditItem(obj);
            editOfferPanelRef.current.open();
            dispatch(action.setIsEdit(true));
            dispatch(action.setOfferWallId(obj?.id));
            dispatch(action.setOfferWallName(obj?.offerWallName));
            dispatch(action.setPriority(obj?.priority));
        };

        const onDeleteItem = deleteData => {
            setDeletePopUp(deleteData);
        };

        const removeSlot = deleteData => {
            let updatedItems = cloneDeep(
                get(data, "properties.offerWallId", [])
            );
            const indexToUpdate = updatedItems?.findIndex(
                x => x.rowId === deleteData.rowId
            );
            updatedItems.splice(indexToUpdate, 1);

            let initialCollectionItems = cloneDeep(collectionItems);
            let finalItems = cloneDeep(data);
            const itemToUpdate = initialCollectionItems?.findIndex(
                _ => _.id === finalItems.id
            );
            initialCollectionItems[itemToUpdate].properties.offerWallId = [
                ...updatedItems,
            ];
            dispatch(collectionActions.setItems(initialCollectionItems));
        };

        const confirmPopupMsg = <p>Do you wish to delete this slot?</p>;

        const columns = [
            {
                text: "Offer Wall",
                key: "id",
                disableCopyText: true,
                CustomRender: ({ item }) => {
                    return (
                        <div
                            className={s.offerLink}
                            onClick={() => {
                                setOfferWallId(item?.id);
                                editOfferWallRef.current.open();
                            }}
                        >
                            {item?.offerWallName}
                        </div>
                    );
                },
            },
            {
                text: "Priority",
                key: "priority",
            },
            {
                text: "",
                key: "",
                CustomRender: ({ item }) => {
                    return (
                        <>
                            <>
                                <ActionButtons
                                    item={item}
                                    editCallback={async item => {
                                        onEdit(item);
                                    }}
                                    deleteCallback={item => {
                                        let topPos = document
                                            .querySelector(".edit" + item.id)
                                            .getBoundingClientRect().top;
                                        let slotTopPosition = document
                                            .querySelector(".slotTable")
                                            .getBoundingClientRect().top;
                                        setPopupTopPosition(topPos - 64 + "px");
                                        onDeleteItem(item);
                                    }}
                                    readOnly={isReadOnly}
                                />
                            </>
                            {deletePopUp === item && (
                                <div className={s.popUp}>
                                    <Popup
                                        showHeader={false}
                                        showFooter={false}
                                        showConfirmBox={true}
                                        saveButtonText={"Yes, Go ahead"}
                                        cancelButtonText={"Cancel"}
                                        closeOutside={true}
                                        message={confirmPopupMsg}
                                        disableSave={false}
                                        width={"332px"}
                                        portal={true}
                                        containerId={document.querySelector(
                                            ".slotTable"
                                        )}
                                        textAlign={"left"}
                                        onClose={() => setDeletePopUp(null)}
                                        onSave={removeSlot}
                                        customClass={s.popupChannel}
                                        top={popupTopPosition}
                                    />
                                </div>
                            )}
                        </>
                    );
                },
                bulkActions: true,
            },
        ];

        const onSaveHandler = () => {
            if (!minimumDealRef.current.validate())
                return minimumDealRef.current.focus();
        };

        const onAddListItem = () => {
            if (
                slots?.id &&
                slots?.offerWallName &&
                slots?.priority &&
                slots?.priority.indexOf(".") === -1
            ) {
                setDealCategoryError(false);
                const dataJson = {
                    priority: slots?.priority,
                    // offerWalls: [],
                    offerWallName: slots?.offerWallName,
                    id: slots?.id,
                    rowId: getRandomId(),
                    dataId: "-",
                };
                let selectedListItemsCopy = JSON.parse(
                    JSON.stringify(get(data, "properties.offerWallId", []))
                );
                if (
                    selectedListItemsCopy.findIndex(
                        item => item.dataId === dataJson.rowId
                    ) === -1
                ) {
                    selectedListItemsCopy.push(dataJson);
                    dispatch(
                        action.setSelectedListItems(selectedListItemsCopy)
                    );
                    dispatch(action.setPriority(""));
                    dispatch(action.setOfferWallName(""));
                    dispatch(action.setOfferWallId(""));
                    // dispatch(action.setOfferWalls([]));
                    dispatch(action.setSlotsError(false));
                    onSetSlots(selectedListItemsCopy);
                    // setMinimumDealError(false);
                }
            } else {
                dispatch(action.setSlotsError(true));
                // setMinimumDealError(true);
                if (get(slots, "id", "") === "") setDealCategoryError(true);
                if (get(slots, "priority", "") === "")
                    priorityRef.current.validate();
                if (get(data, "properties.minDeals", "") === "")
                    minimumDealRef.current.validate();
            }
        };

        const selectDealCategory_Dropdown = obj => {
            dispatch(action.setOfferWallId(obj.id));
            dispatch(action.setOfferWallName(obj.name));
            setDealCategoryError(false);
        };

        const activeTargetingParams = targetParams => {
            const temp = Object.keys(targetParams).reduce((p, _) => {
                if (targetParams[_]?.length) {
                    p.push(_);
                }
                return p;
            }, []);

            if (temp.length) {
                return `${temp[0]} ${
                    temp.length > 1 ? `+${temp.length - 1}` : ""
                }`;
            }
            return "N/A";
        };

        const onEditOffer = () => {
            let initialItem = cloneDeep(data);
            let updatedItems = cloneDeep(
                get(data, "properties.offerWallId", [])
            );
            const indexToUpdate = updatedItems?.findIndex(
                x => x.rowId === editItem.rowId
            );
            updatedItems[indexToUpdate] = {
                ...updatedItems[indexToUpdate],
                ...slots,
            };
            let updatedOfferWallItem = {
                ...initialItem,
                properties: {
                    ...initialItem.properties,
                    offerWallId: [...updatedItems],
                },
            };

            let initialCollectionItems = cloneDeep(collectionItems);
            let finalItems = cloneDeep(data);
            const itemToUpdate = initialCollectionItems?.findIndex(
                _ => _.id === finalItems.id
            );

            initialCollectionItems[itemToUpdate] = updatedOfferWallItem;
            dispatch(collectionActions.setItems(initialCollectionItems));
            dispatch(action.setIsEdit(false));
            dispatch(action.setOfferWallId(""));
            dispatch(action.setOfferWallName(""));
            dispatch(action.setPriority(""));
            editOfferPanelRef.current.close();
        };

        const updateOfferWall = async id => {
            let isValid = await createOfferWallRef.current.validateAllFields();
            if (isValid) {
                dispatch(createOfferWallActions.setMainLoader(true));
                dispatch(updateOfferWallData(id))
                    .unwrap()
                    .then(async response => {
                        if (response) {
                            setTimeout(async () => {
                                dispatch(
                                    createOfferWallActions.setMainLoader(false)
                                );
                                dispatch(
                                    toastListActions.setToastList({
                                        type: "Success",
                                        message:
                                            "Updated Offer Wall Successfully",
                                    })
                                );
                                await editOfferWallRef.current.close();
                            }, 1000);
                        }
                    });
            }
        };

        return (
            <div className={`${s.mainParent}`}>
                {mainLoader && <LinearDeterminate />}

                {loading ? (
                    <SkeletonLoader />
                ) : (
                    <>
                        {!isSidePanel && (
                            <Card className={`sticky-below-header ${s.header}`}>
                                <CreateSaveHeader
                                    onSave={!isReadOnly && onSaveHandler}
                                    isBig={true}
                                    title={`${
                                        urlParams.id ? "Edit" : "Create"
                                    } Deal Category`}
                                    saveLabel={`${
                                        urlParams.id ? "Save" : "Create"
                                    }`}
                                    desc={
                                        "Create deal category for associating with offer walls and gift cards"
                                    }
                                    showPrompt={!isReadOnly && showPrompt}
                                    showHamburgerBtn={false}
                                    loading={loading}
                                ></CreateSaveHeader>
                            </Card>
                        )}
                        <div>
                            <div className={"row"}>
                                <p className={"headerTitle"}>Basics</p>
                                <p className={`headerTitleRequired`}>
                                    Deal Category
                                </p>
                                <p className={`infoText`}>
                                    Unique Name associated with the deal
                                    category
                                </p>
                                <Select
                                    label={"Select"}
                                    options={allDealCategoryList}
                                    keyField={"name"}
                                    onSelect={onChangeDealCategory}
                                    idField={"name"}
                                    onLoadSelectReturn={false}
                                    selectedId={
                                        get(data, "properties.category", null)
                                            ? get(
                                                  data,
                                                  "properties.category",
                                                  null
                                              )
                                            : null
                                    }
                                    // isError={dealCategoryError}
                                    position={"bottom"}
                                    isDisabled={isReadOnly}
                                />
                            </div>

                            <div className={"row"}>
                                <p className={`headerTitleRequired`}>
                                    Minimum Deals*
                                </p>
                                <p className={`infoText`}>
                                    Minimum deals associated with the deal
                                    category
                                </p>
                                <div className={s.minimumDealInput}>
                                    <Input
                                        ref={minimumDealRef}
                                        type={"text"}
                                        placeholder={"Enter Minimum Deals"}
                                        className="maxChildContainer"
                                        value={get(
                                            data,
                                            "properties.minDeals",
                                            ""
                                        )}
                                        onChange={onChangeMinimumDeals}
                                        required={true}
                                        disabled={isReadOnly}
                                        type={"number"}
                                        maxWidth={"640px"}
                                        // isError={minimumDealError}
                                    />
                                </div>
                            </div>
                            <div
                                data-hash-id="offerWallSlots"
                                id="offerWallSlots"
                            >
                                <div className={`headerTitle`}>Offer Wall</div>
                                <p className={`infoText`}>
                                    Slots can be added on the basis of deal
                                    category, minimum deals & offer walls.
                                </p>
                                <PanelGrid
                                    className={`${s.resetPadding} ${s.panelWidth}`}
                                >
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault();
                                        }}
                                    >
                                        <div className={s.header}>
                                            <div className={s.subHeader}>
                                                Offer Wall*
                                            </div>
                                            <div className={s.subHeader}>
                                                Priority*
                                            </div>
                                        </div>
                                        <div className={s.body}>
                                            <div className={s.toggleSwitch}>
                                                <Select
                                                    label={"Select"}
                                                    options={offerWallList?.map(
                                                        _ => {
                                                            return {
                                                                ..._,
                                                                name:
                                                                    _.id +
                                                                    ": " +
                                                                    _.name,
                                                            };
                                                        }
                                                    )}
                                                    selectedId={
                                                        !get(
                                                            slots,
                                                            "isEdit",
                                                            false
                                                        ) &&
                                                        get(slots, "id", "")
                                                            ? get(
                                                                  slots,
                                                                  "id",
                                                                  ""
                                                              )
                                                            : null
                                                    }
                                                    keyField="name"
                                                    portal={false}
                                                    onSelect={
                                                        selectDealCategory_Dropdown
                                                    }
                                                    idField={"id"}
                                                    position={
                                                        windowDimensions()
                                                            .height > 900
                                                            ? "bottom"
                                                            : "top"
                                                    }
                                                    isError={dealCategoryError}
                                                    onLoadSelectReturn={false}
                                                    isDisabled={isReadOnly}
                                                />
                                            </div>
                                            <div className={s.input}>
                                                <Input
                                                    ref={priorityRef}
                                                    type="number"
                                                    value={
                                                        !get(
                                                            slots,
                                                            "isEdit",
                                                            false
                                                        )
                                                            ? get(
                                                                  slots,
                                                                  "priority",
                                                                  ""
                                                              )
                                                            : ""
                                                    }
                                                    placeholder="Priority"
                                                    required={true}
                                                    onChange={e =>
                                                        dispatch(
                                                            action.setPriority(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    disabled={isReadOnly}
                                                />
                                            </div>
                                            <Button
                                                className={`${s.themBtn} ${s.marginLeft}`}
                                                btnTheme={
                                                    ButtonTypes.primaryHover_btn
                                                }
                                                disable={isReadOnly}
                                                type="submit"
                                                onClick={onAddListItem}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </form>
                                </PanelGrid>
                                {get(data, "properties.offerWallId", [])
                                    .length > 0 ? (
                                    <div className={`slotTable`}>
                                        <Table
                                            tableData={{ loading: loading }}
                                            columns={columns}
                                            data={get(
                                                data,
                                                "properties.offerWallId",
                                                []
                                            )}
                                            showBorder={true}
                                            callback={cbFunction}
                                            additionalProps={{ data: data }}
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </>
                )}
                <SidePanelInfinity
                    title={"Edit Offer"}
                    ref={editOfferPanelRef}
                    disableSaveBtn={isReadOnly}
                    onSave={onEditOffer}
                >
                    <div className={s.editContainer}>
                        <div>
                            {offerWallList.length > 0 && (
                                <div className={`row`}>
                                    <div
                                        className={`${s.offerWallTitle} headerTitleRequired`}
                                    >
                                        <p
                                            className={`headerTitleRequired mb-0`}
                                        >
                                            Offer Wall*
                                        </p>

                                        <span
                                            onClick={() => {
                                                editOfferWallRef.current.open();
                                                setOfferWallId("");
                                            }}
                                            className={s.editText}
                                        >
                                            <Icon icon={"Edit"} size="14px" />
                                            Edit
                                        </span>
                                    </div>

                                    <p className={`infoText`}>Offer Wall</p>

                                    <Select
                                        label={"Select"}
                                        options={offerWallList?.map(_ => {
                                            return {
                                                ..._,
                                                name: _.id + ": " + _.name,
                                            };
                                        })}
                                        selectedId={
                                            get(slots, "id", "")
                                                ? get(slots, "id", "")
                                                : null
                                        }
                                        keyField="name"
                                        portal={false}
                                        onSelect={selectDealCategory_Dropdown}
                                        idField={"id"}
                                        position={"bottom"}
                                        isError={dealCategoryError}
                                        onLoadSelectReturn={false}
                                        isDisabled={isReadOnly}
                                    />
                                </div>
                            )}
                            <div className={`row`}>
                                <p className={`headerTitleRequired capitalize`}>
                                    Priority*
                                </p>
                                <p className={`infoText`}>
                                    Priority of offer wall slots
                                </p>
                                <Input
                                    ref={priorityRef}
                                    type="number"
                                    value={get(slots, "priority", "")}
                                    placeholder="Priority"
                                    required={true}
                                    onChange={e =>
                                        dispatch(
                                            action.setPriority(e.target.value)
                                        )
                                    }
                                    disabled={isReadOnly}
                                />
                            </div>
                        </div>
                    </div>
                </SidePanelInfinity>
                <SidePanelInfinity
                    ref={editOfferWallRef}
                    title={"Edit Offer Wall"}
                    width={"95%"}
                    onSave={() => {
                        const updateId = offerWallId
                            ? offerWallId
                            : get(slots, "id", "");
                        updateOfferWall(updateId);
                    }}
                >
                    <CreateOfferWall
                        id={offerWallId ? offerWallId : get(slots, "id", "")}
                        isSidePanel={false}
                        ref={createOfferWallRef}
                    />
                </SidePanelInfinity>
            </div>
        );
    }
);

export default CreateDealOfferWallItem;
