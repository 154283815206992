import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { JTLOGO } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { getInitialRowsCount } from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { getRequiredObject } from "../../utils/globalUtils";
import { MESSAGES } from "../../constants/messages";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "name",
        isDesc: false,
        totalRowsCount: 0,
        // filterKey: FILTERS.MEDIA_LIBRARY.filterKey,
        filterTerm: null,
        prefix: null,
        // filterOperator: FILTERS.MEDIA_LIBRARY.filterOperator,
        // filtersOperator: FILTERS.MEDIA_LIBRARY.operator,
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    logoLibrary: {
        popup: {
            name: "",
            imgAlreadyExistFlag: false,
            imgInfo: "",
            imageFormat: "",
            imgSizeBigger: false,
            updateImg: false,
            error: true,
            showPrompt: true,
            mainLoader: false,
        },
    },
};

export const fetchLogoLibraryList = createAsyncThunk(
    "listings/jtLogo/fetchJTLogo",
    async (noPagination, { getState, dispatch }) => {
        const pagination = getState().listings.mediaLibrary.pagination;
        const params = noPagination ? {} : paginationParams(pagination);

        const filterParamsList = ["offset", "limit", "prefix"];
        const filterParamsData = getRequiredObject(filterParamsList, params);
        let dispatchSearchError = true;
        const response = await customFetch(
            `${JTLOGO.FETCH_JTLOGO_LIST}`,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch JT Logo",
                    })
                );
            }
        );
        const data = get(response, "data", []);
        if (dispatchSearchError && pagination.filterTerm) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.FEATURE_SETS}`;
            dispatch(logoLibraryActions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Upload a new Image`;
            dispatch(logoLibraryActions.setTableError(errorMessage));
        }
        return data;
    }
);

export const checkImageAvailable = createAsyncThunk(
    "listings/jtLogo/checkImageAvailable",
    async (_, { getState, dispatch }) => {
        const getImgName =
            getState().listings.mediaLibrary.logoLibrary.popup.imgInfo[0].name;
        const response = await customFetch(
            `${JTLOGO.UPLOAD_IMAGE}/available?imageName=${getImgName}`,
            "GET"
        );
        return response;
    }
);

export const uploadNewImage = createAsyncThunk(
    "listings/jtLogo/uploadNewImage",
    async (_, { getState, dispatch }) => {
        const getMediaLibraryData =
            getState().listings.mediaLibrary.logoLibrary.popup;
        const params = headersData(getMediaLibraryData);
        const response = await customFetch(JTLOGO.UPLOAD_IMAGE, "POST", params);
        return response;
    }
);

export const replaceImage = createAsyncThunk(
    "listings/jtLogo/replaceImage",
    async (_, { getState, dispatch }) => {
        const getMediaLibraryData =
            getState().listings.mediaLibrary.logoLibrary.popup;
        const params = headersData(getMediaLibraryData);
        const getImgName = get(getMediaLibraryData, "imgInfo[0].name", "");
        const response = await customFetch(
            `${JTLOGO.UPLOAD_IMAGE}?imageName=${getImgName}`,
            "PUT",
            params
        );
        return response;
    }
);

const headersData = obj => {
    return {
        imageName: `${obj.imgInfo[0].name}`,
        base64Image: obj.imageFormat.split(",").pop(),
    };
};

const logoLibrarySlice = createSlice({
    name: "mediaLibrary",
    initialState,
    reducers: {
        setImgName(state, action) {
            state.logoLibrary.popup.name = action.payload;
        },
        setImageInfo(state, action) {
            state.logoLibrary.popup.imgInfo = action.payload;
        },
        setimageFormat(state, action) {
            state.logoLibrary.popup.imageFormat = action.payload;
        },
        setImgSizeBigger(state, action) {
            state.logoLibrary.popup.imgSizeBigger = action.payload;
        },
        setImgExist(state, action) {
            state.logoLibrary.popup.imgAlreadyExistFlag = action.payload;
        },
        setUpdateImg(state, action) {
            state.logoLibrary.popup.updateImg = action.payload;
        },
        setShowPrompt(state, action) {
            state.createFeatureSet.basics.showPrompt = action.payload;
        },
        loading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.createFeatureSet.basics.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
            state.pagination.prefix = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchLogoLibraryList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchLogoLibraryList.fulfilled, (state, action) => {
            state.list = action.payload.data?.filter(
                _ => _.name && _.name[0] !== "."
            );
            state.loading = false;
            state.updatedAt = new Date();
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
        });
    },
});

export const logoLibraryActions = logoLibrarySlice.actions;
export default logoLibrarySlice.reducer;
