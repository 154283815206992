import { SURVEYS } from "../constants/url";
import customFetch from "../fetch/customFetch";
import { getRandomId } from "./globalUtils";
import {
    groupCreateAdUnit,
    groupId,
    groupUpdate,
    slotAds,
    slotType,
    slotTypeSmallCase,
} from "./offerPathUtils";

export const slotsPostApiData = (state, id, header = null) => {
    const type = state.basics.type;
    const slots = state.slots.selectedListItems;
    if (!slots.length) return [];
    const adUnitSlots = slots.map(slot => slotParams(slot, type));
    let params;
    if (type === "RtbGroup") {
        params = {
            offerPathAdSlotId: id,
            rtbGroup: {
                rtbAds: adUnitSlots,
            },
        };
    } else {
        params = {
            offerPathAdSlotId: id,
            [slotAds[type]]: adUnitSlots,
        };
    }
    return customFetch(
        `${SURVEYS.ADUNIT_SLOT}/${groupCreateAdUnit[type]}`,
        "POST",
        params,
        header
    );
};

export const slotsPutApiData = (state, id, header = null) => {
    const type = state.basics.type;
    const group = state.existingData[slotTypeSmallCase[type]];
    if (group == null) {
        return slotsPostApiData(state, id);
    }

    const slots = state.slots.selectedListItems;
    const createSlots = slots.filter(slot => slot.id === "-");
    const updateSlots = slots.filter(slot => slot.id !== "-" && slot.isEdit);
    const createSlotsApi = createSlots.map(slot =>
        customFetch(
            `${SURVEYS.ADUNIT_SLOT}/${groupUpdate[type]}`,
            "POST",
            updateSlotParams(slot, group.id, type),
            header
        )
    );
    const updateSlotsApi = updateSlots.map(slot =>
        customFetch(
            `${SURVEYS.ADUNIT_SLOT}/${groupUpdate[type]}/${slot.id}`,
            "PUT",
            slotParams(slot, type),
            header
        )
    );

    return [...createSlotsApi, ...updateSlotsApi];
};

export const slotsDeleteApiData = (state, header = null, initialType) => {
    const type = state.basics.type;
    const oldSlot = state.basics.initialType;
    const slotsToBeDeleted = oldSlot
        ? state.deletedSlots[0]
        : state.deletedSlots;

    return slotsToBeDeleted.map(slot =>
        customFetch(
            `${SURVEYS.ADUNIT_SLOT}/${groupUpdate[oldSlot ? oldSlot : type]}/${
                slot.id
            }`,
            "DELETE",
            {},
            header
        )
    );
};

const slotParams = (slot, type) => {
    const obj = {
        dealId: slot.dealId,
        revType: slot.revenueType,
        [slotType[type]]: slot[slotType[type]],
        creativeId: +slot.creative.split(":")[0],
        dedupeKeys: slot.dedupeRules,
        customKeyVal: slot.customKeyVal,
    };
    if (slot.id !== "-") {
        obj["id"] = slot.id;
    }

    return obj;
};

const updateSlotParams = (slot, id, type) => {
    return { ...slotParams(slot, type), [groupId[type]]: id };
};

export const mapAdUnitSlots = (adUnit, adCreativesList) => {
    const type = adUnit.type;
    const group = adUnit[slotTypeSmallCase[type]];
    if (group == null) return [];
    let totalWeight = 0;
    group[slotAds[type]].map(slot => {
        if (slot.weight) totalWeight = totalWeight + Number(slot.weight);
    });
    // return group[slotAds[type]].map((slot) => ({
    //   weight: slot.weight,
    //   dealId: slot.dealId,
    //   revenueType: slot.revType,
    //   creative: getCreative(slot.creativeId, adCreativesList),
    //   id: slot.id,
    //   rowId: getRandomId(),
    // }));
    return group[slotAds[type]].map(slot => {
        if (slot.weight) {
            let value = (slot.weight / totalWeight) * 100;
            return {
                [slotType[type]]: slot[slotType[type]],
                weightPercent: [value],
                dealId: slot.dealId,
                revenueType: slot.revType,
                creative: getCreative(slot.creativeId, adCreativesList),
                id: slot.id,
                rowId: getRandomId(),
                dedupeRules: slot.dedupeKeys || [],
                customKeyVal: slot.customKeyVal || [],
            };
        } else {
            return {
                [slotType[type]]: slot[slotType[type]],
                dealId: slot.dealId,
                revenueType: slot.revType,
                creative: getCreative(slot.creativeId, adCreativesList),
                id: slot.id,
                rowId: getRandomId(),
                dedupeRules: slot.dedupeKeys || [],
                customKeyVal: slot.customKeyVal || [],
            };
        }
    });
};

const getCreative = (creativeId, creatives) => {
    const creative = creatives.find(c => c.id === creativeId);
    return creative?.key;
};

export const historyApiTypeConstant = {
    PriorityGroup: "priorityAd",
    WeightGroup: "weightedAd",
    RtbGroup: "rtbAd",
};
