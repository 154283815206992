import {
    numberOfSetsOfferPath,
    numberOfSetsSurveyPath,
    numberOfSetsFeatureSets,
    numberOfSetsABTest,
    ALLOWEDDAYSLIST,
    DEVICESLIST,
    USERSTATUSDROPDOWNOPTIONS,
    REGIONSLIST,
    UTMSOURCESDROPDOWNOPTIONS,
    COUNTRIESLIST,
} from "./targettingParamsConstants.js";

const allTargetParamsKeyMap = {};
const allTargetParamsValMap = {};

const temp = [
    ...numberOfSetsOfferPath,
    ...numberOfSetsSurveyPath,
    ...numberOfSetsFeatureSets,
    ...numberOfSetsABTest,
    { id: "operatingSystems", name: "Operating Systems" },
    { id: "keyVals", name: "Key Values" },
].forEach(elem => {
    allTargetParamsKeyMap[elem.id] = elem.name;
});

const temp1 = {
    allowedDays: ALLOWEDDAYSLIST,
    devices: DEVICESLIST,
    userStatus: USERSTATUSDROPDOWNOPTIONS,
    region: REGIONSLIST,
    utmSources: UTMSOURCESDROPDOWNOPTIONS,
    countries: COUNTRIESLIST,
    genders: [
        { id: 1, label: "Male" },
        { id: 2, label: "Female" },
        { id: 3, label: "Others" },
    ],
};

Object.keys(temp1).map(key => {
    const obj = {};
    temp1[key].map(_ => {
        obj[_.id] = _.label;
    });
    allTargetParamsValMap[key] = obj;
});

export const questionsConfig = {
    text: {
        heading: "Questions",
        icon: "Question",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "elements",
            label: "Sub Questions",
            renderer: "array",
        },
    ],
};

export const adsConfig = {
    text: {
        heading: "Ads",
        icon: "AdUnit",
    },
    paths: [
        {
            id: "adUnitId",
            label: "Ad Unit Id",
            renderer: "text",
        },
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "weightGroup",
            label: "Offers",
            renderer: "object",
            ignoreList: ["createdAt", "updatedAt"],
        },
        {
            id: "id",
            label: "Id",
            renderer: "text",
        },
        {
            id: "type",
            label: "Type",
            renderer: "text",
        },
        {
            id: "trafficSplit",
            label: "Traffic Split",
            renderer: "text",
        },
        {
            id: "offerPathId",
            label: "Offer Path Id",
            renderer: "text",
        },
        {
            id: "creativeId",
            label: "Creative Id",
            renderer: "text",
        },

        {
            id: "dealId",
            label: "Deal Id",
            renderer: "text",
        },
        {
            id: "revType",
            label: "Revenue Type",
            renderer: "text",
        },
        {
            id: "weight",
            label: "Weight",
            renderer: "text",
        },
    ],
};

export const abtestsConfig = {
    text: {
        heading: "A/B Tests",
        icon: "ABTest",
    },
    paths: [
        {
            id: "id",
            label: "Id",
            renderer: "text",
        },
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "status",
            label: "Status",
            renderer: "text",
        },
        {
            id: "abtest",
            label: "AB Test",
            renderer: "text",
        },
        {
            id: "weight",
            label: "Weight",
            renderer: "text",
        },
        {
            id: "split",
            label: "Split",
            renderer: "text",
        },
        {
            id: "targeting",
            label: "Inclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
        {
            id: "exclusionTargeting",
            label: "Exclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
    ],
};

export const surveyConfig = {
    text: {
        heading: "Survey Configs",
        icon: "Configuration",
    },
    paths: [
        {
            id: "version",
            label: "Version",
            renderer: "text",
        },
        {
            id: "lander",
            label: "Lander",
            renderer: "text",
        },
        {
            id: "domain",
            label: "Product",
            renderer: "text",
        },
        {
            id: "abtMap",
            label: "A/B Test Channel Mapping",
            renderer: "array",
            innerPaths: [],
        },
        {
            id: "featureMap",
            label: "Features",
            renderer: "array",
            innerPaths: ["value", "choice"],
        },
        {
            id: "featureSets",
            label: "Feature Sets",
            renderer: "array",
            innerPaths: [],
        },
    ],
};

export const leadDefinitionConfig = {
    text: {
        heading: "Posting Spec",
        icon: "LeadGeneration",
    },
    paths: [
        {
            id: "id",
            label: "Id",
            renderer: "text",
        },
        {
            id: "leadDef",
            label: "Posting Spec",
            renderer: "object",
        },
        {
            id: "prepingDef",
            label: "Preping Data",
            renderer: "object",
        },
        {
            id: "authDef",
            label: "Auth Data",
            renderer: "object",
        },
    ],
};

export const offersConfig = {
    text: {
        heading: "Offer Groups",
        icon: "OfferGroup",
    },
    paths: [
        {
            id: "id",
            label: "Id",
            renderer: "text",
        },
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "type",
            label: "Type",
            renderer: "text",
        },
        {
            id: "hnpCategoryId",
            label: "HNP Category Id",
            renderer: "text",
        },
        {
            id: "dedupConfig",
            label: "Dedupe Rules",
            renderer: "array",
        },
        {
            id: "delay",
            label: "Delay",
            renderer: "text",
        },
        {
            id: "description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "keyVals",
            label: "Key Vals",
            renderer: "object",
        },
        {
            id: "dealId",
            label: "Deal Id",
            renderer: "text",
        },
        {
            id: "disposition",
            label: "Disposition",
            renderer: "text",
        },
        {
            id: "hnpCreativeId",
            label: "HNP Creative Id",
            renderer: "text",
        },
        {
            id: "hnpOfferGroup",
            label: "HNP Offer Group",
            renderer: "object",
        },
    ],
};

export const featuresConfig = {
    text: {
        heading: "Features",
        icon: "FeatureMap",
    },
    paths: [
        {
            id: "key",
            label: "Name",
            renderer: "text",
        },
        {
            id: "description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "screenshoturl",
            label: "Screenshot URL",
            renderer: "text",
        },

        {
            id: "type",
            label: "Property type",
            renderer: "text",
        },
        {
            id: "scope",
            label: "Property scope",
            renderer: "text",
        },
        {
            id: "valuekind",
            label: "Property value type",
            renderer: "text",
        },
        {
            id: "choice",
            label: "Property choice",
            renderer: "array",
            innerPaths: [],
        },
        {
            id: "value",
            label: "Property value",
            renderer: "array",
            innerPaths: [],
        },
    ],
};

export const featureSetsConfig = {
    text: {
        heading: "Feature Sets",
        icon: "FeatureSet",
    },
    paths: [
        {
            id: "id",
            label: "ID",
            renderer: "text",
        },
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "status",
            label: "Set Visibility",
            renderer: "text",
        },
        {
            id: "featureMap",
            label: "Features",
            renderer: "array",
            innerPaths: ["value", "choice"],
        },
        {
            id: "targeting",
            label: "Inclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
        {
            id: "exclusionTargeting",
            label: "Exclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
    ],
};

export const surveyPathConfig = {
    text: {
        heading: "Manage Survey Paths",
        icon: "SurveyPath",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "surveyId",
            label: "Survey Id",
            renderer: "text",
        },
        {
            id: "segments",
            label: "Question Bank Set",
            renderer: "array",
            ignoreList: ["updatedAt", "createdAt"],
        },
        {
            id: "targetParams",
            label: "Inclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            valueMapping: allTargetParamsValMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
    ],
};

export const offerPathConfig = {
    text: {
        heading: "Manage Offer Paths",
        icon: "OfferPath",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "sid",
            label: "Survey Ids",
            renderer: "text",
        },
        {
            id: "weight",
            label: "Weight",
            renderer: "text",
        },
        {
            id: "isActive",
            label: "Status",
            renderer: "text",
        },
        {
            id: "dedupeKeys",
            label: "Dedupe Rules",
            renderer: "array",
        },
        {
            id: "offerPathAdSlots",
            label: "Offer Path Slots",
            renderer: "array",
            ignoreList: ["updatedAt", "createdAt"],
        },
        {
            id: "targetParams",
            label: "Inclusion Targetting Params",
            renderer: "object",
            keyMapping: allTargetParamsKeyMap,
            ignoreList: [
                "pagination",
                "tableError",
                "availableSet",
                "duplicateUser",
            ],
        },
        {
            id: "slotIndex",
            label: "Slot Index",
            renderer: "text",
        },
        {
            id: "id",
            label: "Id",
            renderer: "text",
        },
        {
            id: "type",
            label: "Type",
            renderer: "text",
        },
        {
            id: "trafficSplit",
            label: "Traffic Split",
            renderer: "text",
        },
        {
            id: "adUnitId",
            label: "Ad Unit Id",
            renderer: "text",
        },
        {
            id: "offerPathId",
            label: "Offer Path Id",
            renderer: "text",
        },
        {
            id: "creativeId",
            label: "Creative Id",
            renderer: "text",
        },

        {
            id: "dealId",
            label: "Deal Id",
            renderer: "text",
        },
        {
            id: "revType",
            label: "Revenue Type",
            renderer: "text",
        },
    ],
};

export const tmsConfig = {
    text: {
        heading: "TMS",
        icon: "tms",
    },
    paths: [
        {
            id: "pubCode",
            label: "pub Code",
            renderer: "text",
        },
        {
            id: "campaign",
            label: "Campaign",
            renderer: "text",
        },
        {
            id: "ps",
            label: "ps",
            renderer: "text",
        },
        {
            id: "exclusionPS",
            label: "Exclusion PS",
            renderer: "text",
        },
        {
            id: "conversionEvents",
            label: "Conversion Events",
            renderer: "object",
        },
        {
            id: "margin",
            label: "Margin",
            renderer: "text",
        },
        {
            id: "cpa",
            label: "cpa",
            renderer: "text",
        },
        {
            id: "conversionURL",
            label: "Conversion URL",
            renderer: "text",
        },
        {
            id: "status",
            label: "Status",
            renderer: "text",
        },
        {
            id: "orgConv",
            label: "Organic Conversion",
            renderer: "text",
        },
    ],
};

export const flowConfig = {
    text: {
        heading: "Flow",
        icon: "Flow",
    },
    paths: [
        {
            id: "rules",
            label: "Rules",
            renderer: "array",
        },
        {
            id: "flow",
            label: "Flow details",
            renderer: "object",
            ignoreList: [
                "updatedAt",
                "createdAt",
                "flowRouterId",
                "ruleSetId",
                "isActive",
            ],
        },
        {
            id: "updatedAt",
            label: "Updated At",
            renderer: "text",
        },
    ],
};

export const siteHistoryConfig = {
    text: {
        heading: "Site Config",
        icon: "SiteConfig",
    },
    paths: [
        {
            id: "rules",
            label: "Rules",
            renderer: "array",
        },
        {
            id: "site",
            label: "Flow details",
            renderer: "object",
            ignoreList: [
                "updatedAt",
                "createdAt",
                "flowRouterId",
                "ruleSetId",
                "isActive",
            ],
        },
        {
            id: "updatedAt",
            label: "Updated At",
            renderer: "text",
        },
    ],
};

export const collectionConfig = {
    text: {
        heading: "Collection",
        icon: "Collection",
    },
    paths: [
        {
            id: "containerType",
            label: "Type",
            renderer: "text",
        },
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "items",
            label: "Collection Items",
            renderer: "array",
            ignoreList: ["updatedAt", "createdAt"],
        },
    ],
};

export const templateConfig = {
    text: {
        heading: "Feature Group",
        icon: "Template",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "properties.entryIntLayoutConfig",
            label: "Entry Layout",
            renderer: "object",
        },
        {
            id: "properties.exitIntLayoutConfig",
            label: "Exit Layout",
            renderer: "object",
        },
        {
            id: "properties.exitLoader",
            label: "Exit Loader",
            renderer: "object",
        },
        {
            id: "properties.listingsInBgLayoutConfig",
            label: "Listings Layout",
            renderer: "object",
        },
        {
            id: "properties.pushNotificationConfig",
            label: "Push Notification Layout",
            renderer: "object",
        },
    ],
};

export const flowQuestionsConfig = {
    text: {
        heading: "Questions",
        icon: "Question",
    },
    paths: [
        {
            id: "name",
            label: "Question Name",
            renderer: "text",
        },
        {
            id: "question",
            label: "Question Title",
            renderer: "text",
        },
        {
            id: "subHeader",
            label: "Subtitle",
            renderer: "text",
        },
        {
            id: "logoImageSrc",
            label: "Logo Image URL",
            renderer: "text",
        },
        {
            id: "bannerImageSrc",
            label: "Banner Image URL",
            renderer: "text",
        },
        {
            id: "type",
            label: "Question Type",
            renderer: "text",
        },
        {
            id: "answers",
            label: "Answers",
            renderer: "array",
            ignoreList: ["updatedAt", "createdAt", "id"],
        },
        {
            id: "tag",
            label: "Parent Question",
            renderer: "text",
        },
    ],
};

export const layoutConfig = {
    text: {
        heading: "Layout",
        icon: "Layout",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "previewLink",
            label: "Preview Link",
            renderer: "text",
        },
        {
            id: "version",
            label: "Version",
            renderer: "text",
        },
        {
            id: "description",
            label: "Description",
            renderer: "text",
        },
        {
            id: "tags",
            label: "Tags",
            renderer: "flatArray",
            overrideNewCheck: true,
        },
        {
            id: "configurations.key",
            label: "Key",
            renderer: "string",
        },
        {
            id: "configurations.description",
            label: "Config Description",
            renderer: "string",
        },
        {
            id: "configurations.data",
            label: "Default Choice",
            renderer: "array",
        },
        {
            id: "configurations.choices",
            label: "Choices",
            renderer: "array",
        },
        {
            id: "configurations.required",
            label: "Required",
            renderer: "string",
        },
        {
            id: "configurations.isMultiSelect",
            label: "Is Multi select",
            renderer: "string",
        },
        {
            id: "configurations.max",
            label: "Max",
            renderer: "string",
        },
        {
            id: "configurations.min",
            label: "Is Multi select",
            renderer: "string",
        },
    ],
};

export const containerConfig = {
    text: {
        heading: "Container",
        icon: "Container",
    },
    paths: [
        {
            id: "name",
            label: "Name",
            renderer: "text",
        },
        {
            id: "templateId",
            label: "Feature Group Id",
            renderer: "text",
        },
        {
            id: "collectionId",
            label: "Collection Id",
            renderer: "text",
        },
        {
            id: "containerLayoutConfig.name",
            label: "Container layout",
            renderer: "text",
        },
        {
            id: "layoutConfig.name",
            label: "Fallback layout",
            renderer: "text",
        },
    ],
};

export const siteConfigHistoryConfig = {
    text: {
        heading: "Site Config",
        icon: "SiteConfig",
    },
    paths: [
        {
            id: "domain",
            label: "Domain",
            renderer: "text",
        },
    ],
};

export const flowCategoryConfig = {
    text: {
        heading: "Flow Category",
        icon: "Categories",
    },
    paths: [
        {
            id: "hnpCategory",
            label: "Name",
            renderer: "text",
        },
        {
            id: "targettingParams",
            label: "Targetting params",
            renderer: "object",
        },
    ],
};

export const advertiserConfig = {
    text: {
        heading: "Advertiser",
        icon: "AdUnit",
    },
    paths: [
        {
            id: "id",
            label: "ID",
            renderer: "text",
        },
        {
            id: "name",
            label: "Advertiser Name",
            renderer: "text",
        },
        {
            id: "revenueSource",
            label: "Revenue Source",
            renderer: "text",
        },
        {
            id: "defaultScrubFactor",
            label: "Default Scrub Factor",
            renderer: "text",
        },
        {
            id: "prepingDef",
            label: "Default Prepping",
            renderer: "object",
        },
        {
            id: "prepings",
            label: "Campaign Prepping",
            renderer: "array",
        },
    ],
};
