import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import Toast from "./Shared/Toast/Toast";
import ToastNew from "./Shared/Toast/ToastNew";
import store from "./store/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import FeatureCopyWidget from "./components/FeatureCopyWidget/FeatureCopyWidget";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import "normalize.css";
import "./styles/style_variables.css";
import "./styles/styles.scss";
import "./styles/icomoon.css";

(process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging") &&
    import("./styles/staging.scss");

process.env.NODE_ENV === "beta" && import("./styles/beta.scss");

process.env.NODE_ENV === "production" && import("./styles/production.scss");

(process.env.NODE_ENV === "production" || process.env.NODE_ENV === "beta") &&
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY,
        integrations: [new BrowserTracing()],
        sampleRate: 1.0,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
        ignoreErrors: [
            "ChunkLoadError",
            "Unexpected token",
            "/Loading chunk/",
            "/Loading CSS chunk/",
        ],
    });

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
            <ToastContainer />
        </BrowserRouter>
    </Provider>
);

export const showToast = (props = {}) => {
    const container = document.createElement("div");
    document.body.appendChild(container);
    const handleClose = () => {
        container.parentNode?.removeChild(container);
    };
    ReactDOM?.render(<ToastNew {...props} onClose={handleClose} />, container);
};

const toast = (
    <Provider store={store}>
        <Toast />
    </Provider>
);

const featureCopyWidget = (
    <Provider store={store}>
        <FeatureCopyWidget />
    </Provider>
);

injectStyle(); //react-toastify styles
ReactDOM.render(app, document.getElementById("root"));
ReactDOM.render(toast, document.getElementById("toastPortal"));
ReactDOM.render(
    featureCopyWidget,
    document.getElementById("featureCopyWidget")
);
