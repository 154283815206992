import React, { useState, useEffect, useRef } from "react";
import ToastItem from "./ToastItem";
import { useDispatch, useSelector } from "react-redux";
import { toastListActions } from "../../store/toastList/toastList";
import { get } from "lodash";
import { getRandomId } from "../../utils/globalUtils";

export const modeType = {
    success: "Success",
    info: "Info",
    warning: "Warning",
    error: "Error",
};
export const position = {
    topLeft: "topLeft",
    topRight: "topRight",
    bottomLeft: "bottomLeft",
    bottomRight: "bottomRight",
    center: "center",
};

const Toast = props => {
    const list = useSelector(state => state.toastList);
    const dispatch = useDispatch();

    const getOffset = index => {
        return {
            bottom: `${(list.length - index) * 74}px`,
            right: 20,
        };
    };
    return list.map((item, index) => (
        <ToastItem
            item={item}
            getOffset={getOffset(index)}
            deleteToast={id => dispatch(toastListActions.deleteToast(id))}
            list={list}
            key={index + getRandomId()}
        />
    ));
};

export default Toast;
