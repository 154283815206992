import s from "./ToastNew.module.scss";
import { toast } from "react-toastify";
import Icon from "../Icon/Icon";

const getIcon = type => {
    return type === "Error"
        ? "CloseFilled"
        : type === "Success"
        ? "Check"
        : type === "Warning"
        ? "WarningFilled"
        : "InfoFilled";
};

const getTitle = type => {
    return type === "Error"
        ? "Something went wrong!"
        : type === "Success"
        ? "Successful!"
        : type === "Warning"
        ? "Warning"
        : "Info";
};

const ToastNew = ({
    actionBtnText,
    cancelBtnText,
    onActionBtn,
    onCancelBtn,
    ...props
}) => {
    const ToastBody = () => {
        return (
            <div
                className={`${s.toastLayer} ${
                    props.type ? s[props.type] : ""
                } `}
            >
                <div className="dp-parent">
                    <div className={s.iconWrapper}>
                        <Icon icon={getIcon(props.type)} size="20px" />
                    </div>
                    <div className={s.labelWrapper}>
                        <div className={s.title}>
                            {props.title || getTitle(props.type)}
                        </div>
                        {props.message && (
                            <div className={s.message}>{props.message}</div>
                        )}
                    </div>
                    <div className={s.iconClose}>
                        <Icon icon="CloseDefault" size="14px" />
                    </div>
                </div>
                {typeof onActionBtn === "function" && (
                    <div className="dp-parent gap-30 mt-20 ml-30">
                        <div
                            className={s.btnAction}
                            onClick={() => {
                                onActionBtn();
                            }}
                        >
                            {actionBtnText || "View"}
                        </div>
                        <div
                            className={s.btnCancel}
                            onClick={() => {
                                typeof onCancelBtn === "function" &&
                                    onCancelBtn();
                            }}
                        >
                            {cancelBtnText || "Cancel"}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    toast(ToastBody, {
        pauseOnFocusLoss: true,
        position: "top-right",
        autoClose: props.autoClose || 5000,
        pauseOnHover: true,
        closeButton: false,
        onClose: props.onClose,
    });

    return "";
};

export default ToastNew;
