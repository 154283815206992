import React, { useEffect, useState } from "react";
import Tooltip from "../Tooltip/Tooltip";
import styles from "./ToggleValueSwitch.module.scss";

export const SIZES = {
    SMALL: "small",
};
const ToggleValueSwitch = ({
    leftLabel,
    rightLabel,
    toggleState = false,
    onChange,
    isDisabled,
    isTooltip = false,
    tooltipMsg,
    className,
    classNameWrapper,
    compact = false,
    size,
}) => {
    const [toggle, setToggle] = useState(toggleState);
    const toggleStateHandler = () => {
        if (typeof onChange === "function") onChange(!toggle);
        setToggle(!toggle);
    };
    useEffect(() => {
        if (typeof toggleState !== "undefined") setToggle(toggleState);
    }, [toggleState]);

    let innerWrapper = (
        <div
            className={`${styles.wrapper} ${size} ${
                classNameWrapper ? classNameWrapper : ""
            } ${compact ? styles.compact : ""}`}
        >
            <span
                className={`${styles.activator} ${
                    !toggle ? styles.toggleValueSwitchActive : ""
                } ${toggle ? className : ""}`}
            ></span>
            <label
                className={`${!toggle ? styles.toggleValueSwitchActive : ""}`}
            >
                {leftLabel}
                <input
                    type="radio"
                    // id={leftLabel}
                    // name="switchToggle"
                    value={leftLabel}
                    onChange={e => (!isDisabled ? toggleStateHandler(e) : "")}
                    checked={!toggle}
                ></input>
            </label>

            <label
                className={`${toggle ? styles.toggleValueSwitchActive : ""} ${
                    isDisabled ? styles.disabled : ""
                } `}
            >
                {rightLabel}
                <input
                    type="radio"
                    // id={rightLabel}
                    // name="switchToggle"
                    value={rightLabel}
                    onChange={e => (!isDisabled ? toggleStateHandler(e) : "")}
                    checked={toggle}
                />
            </label>
        </div>
    );

    return (
        <form
            className={`${styles.switchField} ${
                isDisabled ? styles.toggleValueSwitchDisabled : ""
            } dp-parent`}
        >
            {isTooltip ? (
                <Tooltip message={tooltipMsg} position="top">
                    {innerWrapper}
                </Tooltip>
            ) : (
                innerWrapper
            )}
        </form>
    );
};

export default ToggleValueSwitch;
