import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { useDispatch } from "react-redux";
import { isEqual } from "lodash";
import React, { memo, useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import CopyRenderer from "../../components/Renderers/CopyRenderer/CopyRenderer";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import Pagination from "./Pagination";
import { MESSAGES } from "../../constants/messages";
import CustomMessagePage from "../../Pages/CustomMessagePage/CustomMessagePage";
import { usePrevious } from "../hooks/use-previous";
import { getPageName } from "../../utils/localStorageUtils";
import Icon from "../../Shared/Icon/Icon";
import { propEqualComparision } from "../../utils/globalUtils";

import styles from "./Table.module.scss";
import ModifyColumn from "./ModifyColumn";
import { windowDimensions } from "../../utils/globalUtils";

const table = createColumnHelper();

const CustomRender = ({ row, callback, column, ...props }) => {
    return (
        column.CustomRender({
            col: column,
            item: row.original,
            cb: callback,
            ...props,
        }) || "-"
    );
};

const Table = memo(
    ({
        columns = [],
        data = [],
        defaultAccordionExpanded = true,
        AccordionComponent,
        additionalAccordionProps = {},
        actions,
        tableHeaderOffset = 0,
        stickyHeader = false,
        stickyHeaderClass = null,
        tableData = {},
        tableEditRowId = "",
        // localStatePropsForRerender = {},
        deletedIds = [],
        enableModifyColumns = false,

        gridView = false,
        gridViewList,

        // Refactor/Rename below props later
        onRefresh,
        actionBtnHandler,
        actionBtnText,
        btnTheme,
        callback,
        showBorder = false,
        innerScroll = false,
        innerTableHeight = "",
        manualExpandKey,
        additionalProps,
        CustomErrorMessageComponent,
        isStickyAndNoCustomHeaderStyle = true,
    }) => {
        const tableRef = useRef(null);
        const tableHeaderRef = useRef(null);
        const tableContainer = useRef(null);
        const processedColumnsRef = useRef([]);
        const previousColumns = usePrevious(processedColumnsRef.current);
        const [compactActionBtns, setcompactActionBtns] = useState(false);
        const [showRefresh, setShowRefresh] = useState(false);
        const [columnOrder, setColumnOrder] = useState([]);
        const [columnVisibility, setColumnVisibility] = useState({});
        const pageName = getPageName();

        const modifyColumnsVisible = useSelector(
            state => state.globalConfig.showModifyColumnsPanel
        );

        const {
            loading = false,
            tableError: customErrorMsg = "",
            pagination = {},
            customRowCountList = [],
        } = tableData;

        // Changing processed columns only when length of columns changes
        processedColumnsRef.current = useMemo(() => {
            // Return old columns if lengh of columns is same
            // if (processedColumnsRef.current?.length === columns?.length)
            //     return processedColumnsRef.current;

            const getColData = column => {
                return {
                    header: column.text,
                    cell: ({ row, getValue }) => {
                        const state = useSelector(state => state);
                        const cell = column.CustomRender ? (
                            <CustomRender
                                row={row}
                                callback={callback}
                                column={column}
                                getState={() => state}
                            />
                        ) : (
                            getValue() || "-"
                        );
                        return (
                            <div key={row.id} className={styles.cellContent}>
                                {column.disableCopyText || !column.key ? (
                                    cell
                                ) : (
                                    <CopyRenderer>{cell}</CopyRenderer>
                                )}
                            </div>
                        );
                    },
                    valueType: column.valueType,
                    bulkActions: column.bulkActions || false,
                    styles: column.styles,
                    headerClassName: column.headerClassName,
                    disableCopyText: column.disableCopyText,
                    isSortable: column.isSortable || false,
                };
            };

            return columns.map(column =>
                table.accessor(
                    column.key || "action",
                    column.columns
                        ? {
                              header: column.text,
                              columns: column.columns.map(col =>
                                  getColData(col)
                              ),
                          }
                        : getColData(column)
                )
            );
        }, [columns, callback, additionalProps]);

        const instance = useReactTable({
            data,
            columns: processedColumnsRef.current,
            state: {
                expanded: true,
                columnVisibility,
                columnOrder,
            },
            getExpandedRowModel: () => true,
            getSubRows: row => row.subRows,
            getCoreRowModel: getCoreRowModel(),
            getExpandedRowModel: getExpandedRowModel(),
            onColumnVisibilityChange: setColumnVisibility,
            onColumnOrderChange: setColumnOrder,
        });

        useEffect(() => {
            // Default state for accordion
            instance.toggleAllRowsExpanded(defaultAccordionExpanded);

            window.addEventListener("resize", controlActionBtns);
            return () => {
                window.removeEventListener("resize", controlActionBtns);
            };
        }, []);

        useEffect(() => {
            (customErrorMsg &&
                customErrorMsg.includes(MESSAGES.TABLE.noDataFromApi)) ||
            !customErrorMsg
                ? setShowRefresh(true)
                : setShowRefresh(false);
        }, [customErrorMsg]);

        const setHeaderOffset = () => {
            if (tableHeaderRef.current) {
                if (isStickyAndNoCustomHeaderStyle) {
                    tableHeaderRef.current.style.transform = `translateY(${
                        document.documentElement.scrollTop > tableHeaderOffset
                            ? document.documentElement.scrollTop -
                              (tableHeaderOffset !== 0
                                  ? tableHeaderOffset + 5
                                  : tableHeaderOffset)
                            : 0
                    }px)`;
                } else {
                    tableHeaderRef.current.style.transform = `translateY(0)px)`;
                }
            }
        };

        useEffect(() => {
            if (stickyHeader) {
                setHeaderOffset();
                window.addEventListener("scroll", setHeaderOffset);
            }

            return () => {
                if (stickyHeader) {
                    window.removeEventListener("scroll", setHeaderOffset);
                }
            };
        }, [tableHeaderOffset]);

        const controlActionBtns = () => {
            if (
                tableRef?.current?.clientWidth >
                    tableContainer?.current?.clientWidth &&
                !compactActionBtns
            ) {
                setcompactActionBtns(true);
            } else if (
                tableRef?.current?.clientWidth <
                    tableContainer?.current?.clientWidth &&
                compactActionBtns
            ) {
                setcompactActionBtns(false);
            }
        };

        const verticalScrollPresent = () => {
            return pagination && pagination.totalRowsCount > 0
                ? tableContainer?.current?.clientHeight > innerHeight - 219
                : false;
        };

        const [messageShow, setMessageShow] = useState(false);
        useEffect(() => {
            if (data?.length > 0) {
                setMessageShow(true);
            }
            controlActionBtns();
            const getPath = window.location.pathname;
            if (
                getPath.includes("listings") &&
                localStorage.getItem("tableColumnsData") &&
                JSON.parse(localStorage.getItem("tableColumnsData"))[pageName]
            ) {
                const pageSavedData = JSON.parse(
                    localStorage.getItem("tableColumnsData")
                )[pageName];
                if (pageSavedData.visible) {
                    instance.setColumnVisibility(pageSavedData.visible);
                }
                if (pageSavedData.order) {
                    instance.setColumnOrder(pageSavedData.order);
                }
            }
        }, [data, columns]);

        //pagination
        const {
            pageNumber = null,
            rowsCount = null,
            isDesc = false,
            sortBy = null,
            totalRowsCount = null,
        } = pagination;
        const [selectedCount, setSelectedCount] = useState(10);
        const [activePage, setActivePage] = useState(1);
        const [totalPages, setTotalPages] = useState(15);
        const leftNavExpanded = useSelector(
            state => state.globalConfig.showLeftNav
        );

        useEffect(() => {
            if (totalRowsCount != null) {
                setTotalPages(Math.ceil(totalRowsCount / rowsCount));
            }
            if (pageNumber) setActivePage(pageNumber);
            if (rowsCount) setSelectedCount(rowsCount);
        }, [pageNumber, rowsCount, totalRowsCount]);
        const onSortChangeHandler = col => {
            if (
                actions &&
                typeof actions === "function" &&
                onRefresh &&
                typeof onRefresh === "function"
            ) {
                actions("setSortBy", col);
                actions("setIsDesc", !isDesc);
                onRefresh(true);
            }
        };
        const countChangeHandler = count => {
            if (actions && typeof actions === "function") {
                actions("setPageNumber", 1);
            }
            setSelectedCount(count);
            if (
                actions &&
                typeof actions === "function" &&
                onRefresh &&
                typeof onRefresh === "function"
            ) {
                actions("setRowsCount", count);
                onRefresh(true);
            }
        };
        const pageChangeHandler = page => {
            setActivePage(page);

            if (
                pageName === "userdashboard" &&
                actions &&
                typeof actions === "function" &&
                onRefresh &&
                typeof onRefresh === "function"
            ) {
                actions("setPageNumber", page);
                onForceRefresh();
            } else if (
                actions &&
                typeof actions === "function" &&
                onRefresh &&
                typeof onRefresh === "function"
            ) {
                actions("setPageNumber", page);
                onRefresh(true);
            }
        };

        return (
            <>
                <div
                    className={`${styles.tableContainer} ${
                        showBorder ? styles.withBorder : ""
                    } ${verticalScrollPresent() ? styles.bottomPadding : ""} ${
                        innerScroll ? styles.innerScroll : ""
                    }`}
                    style={
                        innerTableHeight
                            ? { height: `${innerTableHeight - 120}px` }
                            : {}
                    }
                    ref={tableContainer}
                >
                    <>
                        {messageShow &&
                            (data.length === undefined || data.length === 0) &&
                            !loading && (
                                <>
                                    {CustomErrorMessageComponent && (
                                        <CustomErrorMessageComponent />
                                    )}
                                    {!CustomErrorMessageComponent && (
                                        <CustomMessagePage
                                            imgFile={
                                                customErrorMsg ||
                                                MESSAGES.TABLE.noDataFromApi
                                            }
                                            message={
                                                customErrorMsg ||
                                                MESSAGES.TABLE.noDataFromApi
                                            }
                                            showRefresh={true}
                                            onRefresh={onRefresh}
                                            showActionBtn={true}
                                            actionBtnHandler={actionBtnHandler}
                                            actionBtnText={actionBtnText}
                                            btnTheme={btnTheme}
                                        />
                                    )}
                                </>
                            )}
                    </>

                    {loading && (
                        <ul>
                            <SkeletonLoader
                                type={gridView ? "gridViewListing" : "listing"}
                                rowsCount={
                                    windowDimensions().height > 900 ? 15 : 10
                                }
                            />
                        </ul>
                    )}
                    {!loading &&
                        data.length > 0 &&
                        (gridView ? (
                            <ul className={styles["gridTableContainer"]}>
                                {data?.map((element, index) => {
                                    return gridViewList(element, index);
                                })}
                            </ul>
                        ) : (
                            <table className={styles.table} ref={tableRef}>
                                <thead
                                    className={`${styles.thead} ${
                                        stickyHeader ? styles.stickyHeader : ""
                                    } ${
                                        stickyHeader && stickyHeaderClass
                                            ? stickyHeaderClass
                                            : ""
                                    }${
                                        innerScroll
                                            ? styles.stickyWithScroll
                                            : ""
                                    }`}
                                    ref={tableHeaderRef}
                                >
                                    {instance
                                        .getHeaderGroups()
                                        .map(headerGroup => (
                                            <tr
                                                className={styles.row}
                                                key={headerGroup.id}
                                            >
                                                {headerGroup.headers.map(
                                                    (header, i) => (
                                                        <th
                                                            className={`
                                                        ${styles.cell} 
                                                        ${
                                                            columns[i]
                                                                ?.className
                                                                ? styles[
                                                                      columns[i]
                                                                          ?.className
                                                                  ]
                                                                : ""
                                                        }
                                                        ${
                                                            columns[i]
                                                                ?.headerClassName
                                                                ? styles[
                                                                      columns[i]
                                                                          ?.headerClassName
                                                                  ]
                                                                : ""
                                                        }${
                                                                columns[i]
                                                                    ?.customHeaderClassName
                                                                    ? columns[i]
                                                                          .customHeaderClassName
                                                                    : ""
                                                            } ${
                                                                header.column
                                                                    .columnDef
                                                                    ?.bulkActions
                                                                    ? "actionBtns"
                                                                    : ""
                                                            } ${
                                                                header.column
                                                                    .columnDef
                                                                    ?.bulkActions &&
                                                                compactActionBtns
                                                                    ? "actionBtns--compact"
                                                                    : ""
                                                            } `}
                                                            key={header.id + i}
                                                            colSpan={
                                                                header.colSpan
                                                            }
                                                            style={{
                                                                ...header.column
                                                                    .columnDef
                                                                    ?.styles,
                                                            }}
                                                        >
                                                            <div
                                                                className={`dp-parent dp-parent-ver-center ${
                                                                    header
                                                                        .column
                                                                        .columnDef
                                                                        ?.valueType ===
                                                                    "number"
                                                                        ? "numRender__common"
                                                                        : ""
                                                                }`}
                                                            >
                                                                <span
                                                                    className={
                                                                        !header
                                                                            .column
                                                                            .columnDef
                                                                            ?.disableCopyText
                                                                            ? header
                                                                                  .column
                                                                                  .columnDef
                                                                                  ?.valueType ===
                                                                              "number"
                                                                                ? "pr-15"
                                                                                : "pl-5"
                                                                            : ""
                                                                    }
                                                                >
                                                                    {header.isPlaceholder
                                                                        ? null
                                                                        : flexRender(
                                                                              header
                                                                                  .column
                                                                                  .columnDef
                                                                                  .header,
                                                                              header.getContext()
                                                                          )}
                                                                </span>
                                                                {header.column
                                                                    .columnDef
                                                                    ?.isSortable &&
                                                                    data.length >
                                                                        0 && (
                                                                        <div
                                                                            className={`sort ${
                                                                                !isDesc
                                                                                    ? styles[
                                                                                          "sort-des"
                                                                                      ]
                                                                                    : styles[
                                                                                          "sort-asc"
                                                                                      ]
                                                                            }`}
                                                                        >
                                                                            <div
                                                                                onClick={() => {
                                                                                    const sortKeyName =
                                                                                        columns[
                                                                                            i
                                                                                        ]
                                                                                            ?.sortByKey;
                                                                                    onSortChangeHandler(
                                                                                        sortKeyName !=
                                                                                            null
                                                                                            ? sortKeyName
                                                                                            : header
                                                                                                  .column
                                                                                                  .id
                                                                                    );
                                                                                }}
                                                                                className={`${styles.icon} dp-parent`}
                                                                            >
                                                                                <Icon
                                                                                    icon="ColumnSort"
                                                                                    size="13px"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                </thead>
                                <tbody className={`${styles.tbody} t-body`}>
                                    {instance.getRowModel().rows.map(row => (
                                        <React.Fragment
                                            key={`${row.index} + t-body`}
                                        >
                                            <tr
                                                className={`${styles.row} ${
                                                    row?.original?.rowId ===
                                                    tableEditRowId
                                                        ? styles.expandRow
                                                        : ""
                                                } ${
                                                    deletedIds?.includes(
                                                        row.original.id
                                                    )
                                                        ? `${styles.deletedRow} table__deletedRow`
                                                        : ""
                                                } t-row 
                                                ${
                                                    row?.original?.rowColor
                                                        ?.type
                                                        ? styles[
                                                              row?.original
                                                                  ?.rowColor
                                                                  ?.type
                                                          ]
                                                        : ""
                                                }`}
                                                key={row.id}
                                            >
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell, i) => (
                                                        <td
                                                            style={{
                                                                ...cell.column
                                                                    .columnDef
                                                                    ?.styles,
                                                            }}
                                                            className={`${
                                                                styles.cell
                                                            } 
                                                    ${
                                                        styles[
                                                            columns[i]
                                                                ?.className
                                                        ]
                                                    }
                                                    ${
                                                        styles[
                                                            columns[i]
                                                                ?.bodyClassName
                                                        ]
                                                    }
                                                    ${
                                                        row.getVisibleCells()
                                                            .length ===
                                                        i + 1
                                                            ? styles.lastCell
                                                            : ""
                                                    } ${
                                                                cell.column
                                                                    .columnDef
                                                                    ?.valueType ===
                                                                "number"
                                                                    ? "text-right"
                                                                    : ""
                                                            } ${
                                                                cell.column
                                                                    .columnDef
                                                                    ?.bulkActions
                                                                    ? "actionBtns"
                                                                    : ""
                                                            } ${
                                                                cell.column
                                                                    .columnDef
                                                                    ?.bulkActions &&
                                                                compactActionBtns
                                                                    ? "actionBtns--compact"
                                                                    : ""
                                                            }
                                                            ${
                                                                row?.original
                                                                    ?.rowId ===
                                                                tableEditRowId
                                                                    ? styles.expandCell
                                                                    : ""
                                                            }
                                                            
                                                            
                                                            `}
                                                            key={cell.id + i}
                                                        >
                                                            {flexRender(
                                                                cell.column
                                                                    .columnDef
                                                                    .cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    ))}
                                            </tr>
                                            {(!manualExpandKey ||
                                                row.original[
                                                    manualExpandKey
                                                ]) &&
                                                row.getIsExpanded() &&
                                                AccordionComponent && (
                                                    <tr
                                                        className={`${styles.row} ${styles.accordionRow}`}
                                                        key={`${row.id}-nested`}
                                                    >
                                                        <td
                                                            className={
                                                                styles.cell
                                                            }
                                                            key={`${row.id}-nested-cell`}
                                                            colSpan={
                                                                row.getVisibleCells()
                                                                    .length
                                                            }
                                                        >
                                                            <AccordionComponent
                                                                parentTableRef={
                                                                    tableRef
                                                                }
                                                                row={
                                                                    row.original
                                                                }
                                                                additionalAccordionProps={
                                                                    additionalAccordionProps
                                                                }
                                                            ></AccordionComponent>
                                                        </td>
                                                    </tr>
                                                )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        ))}
                </div>
                {typeof pagination === "object" &&
                    Object.keys(pagination).length > 0 &&
                    totalRowsCount > data.length / rowsCount && (
                        <Pagination
                            currentPage={activePage}
                            showCount={selectedCount}
                            totalPages={totalPages}
                            totalRowsCount={totalRowsCount}
                            onCountChange={countChangeHandler}
                            onPageChange={pageChangeHandler}
                            customClass={`${styles.paginationWrap} ${
                                leftNavExpanded ? styles.leftNavExpanded : ""
                            }`}
                            customRowCountList={customRowCountList}
                        ></Pagination>
                    )}

                {enableModifyColumns && modifyColumnsVisible && (
                    <ModifyColumn
                        allColumns={instance.getAllLeafColumns()}
                        handleModifiedColumns={data => {
                            instance.setColumnVisibility(data.visible);
                            instance.setColumnOrder(data.order);
                        }}
                    />
                )}
            </>
        );
    },
    (prevProps, nextProps) => {
        if (
            prevProps.columns.length === nextProps.columns.length &&
            isEqual(prevProps.data, nextProps.data) &&
            isEqual(prevProps.additionalProps, nextProps.additionalProps) &&
            isEqual(
                prevProps.additionalAccordionProps,
                nextProps.additionalAccordionProps
            ) &&
            propEqualComparision(prevProps, nextProps, [
                "tableData",
                "gridView",
                "tableHeaderOffset",
            ])
        ) {
            return true; // props are equal
        }
        return false;
    }
);

// Table.propTypes = {

//     /**
//      *  Theme out of supported theme, refer storybook for all available themes
//      */
//     columns = [],
//     data = [],
//     defaultAccordionExpanded = true,
//     AccordionComponent,
//     loading = true,

//     // Refactor/Rename below props later
//     onRefresh,
//     actionBtnHandler,
//     actionBtnText,
//     btnTheme,
//     customErrorMsg,
//     callback,
//     showBorder = false,

//     // Additionsl Props for rerender check
//     additionalProps = null,
//     /**
//      *  Theme out of supported theme, refer storybook for all available themes
//      */
//     btnTheme: PropTypes.string,

//     /**
//      * Text to be displayed in the button
//      */
//     label: PropTypes.string,

//     /**
//      * Is it in disabled state ?
//      */
//     disable: PropTypes.bool,

//     /**
//      * Specify HTML button type
//      */
//     type: PropTypes.string,

//     /**
//      * Optional click handler
//      */
//     onClick: PropTypes.func,

//     /**
//      * Predefined size of button
//      */
//     size: PropTypes.string,

//     /**
//      * Specifies button with icon, no text
//      */
//     btnWithIcon: PropTypes.bool,
// };

// Button.defaultProps = {
//     type: "button",
// };

export default Table;
