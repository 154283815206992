import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { SURVEYS } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.SURVEY.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.SURVEY.filterOperator,
        filtersOperator: FILTERS.SURVEY.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "surveys"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchSurveysData = createAsyncThunk(
    "listings/surveys/fetchSurveyListingData",
    async (refreshClicked, { dispatch, getState }) => {
        const pagination = get(getState(), "listings.surveys.pagination", {});
        const params = paginationParams(pagination);

        const { listings } = getState();
        let tagFilters = get(
            listings,
            "surveys.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.SURVEY
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(surveysSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.surveys.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.SURVEY,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.surveys.list", []);
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                SURVEYS.FETCH_SURVEYS_NEW,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch surveys",
                        })
                    );
                    dispatch(
                        surveysSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(surveysActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.SURVEY}`;
            dispatch(surveysSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Survey`;
            dispatch(surveysSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const deleteSurveyData = createAsyncThunk(
    "listings/surveys/deleteSurveyListingData",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.DELETE_SURVEY}/${id}`,
            "DELETE"
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete survey!",
                })
            );
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/surveys/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.surveys.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.SURVEY.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const surveysSlice = createSlice({
    name: "surveys",
    initialState,
    reducers: {
        setSurveys(state, action) {
            state.list = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSurveysData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchSurveysData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []).map(survey => ({
                id: survey.id,
                name: survey.name,
                createdAt: survey.createdAt,
                key: `${survey.id}: ${survey.name}`,
            }));
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const surveysActions = surveysSlice.actions;
export default surveysSlice.reducer;
