import style from "./CompactFormContainer.module.scss";
import { Button, ButtonTypes } from "../Button/Button";
import { useRef } from "react";

export const CompactFormContainer = ({
    onSave,
    onCancel,
    saveText = "Save",
    cancelText = "Cancel",
    ...props
}) => {
    const formRef = useRef(null);
    return (
        <div className={style.compactFormWrapper}>
            <div className={style.compactFormContainer}>{props.children}</div>
            <div className={style.footer}>
                {onCancel && (
                    <Button
                        btnTheme={ButtonTypes.primaryHover_btn}
                        onClick={onCancel}
                    >
                        {cancelText}
                    </Button>
                )}
                {onSave && (
                    <Button
                        btnTheme={ButtonTypes.primary_btn}
                        onClick={() => {
                            onSave();
                        }}
                    >
                        {saveText}
                    </Button>
                )}
            </div>
        </div>
    );
};
