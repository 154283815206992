import s from "./DateRangeAndTimePicker.module.scss";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import { useImperativeHandle, useState, forwardRef } from "react";
import Button, { ButtonTypes } from "../Button/Button";
import TimePicker from "../../components/TimePicker/TimePicker";
import {
    shortDateFormat,
    timePickerValue,
    updateTime,
} from "../../utils/dateUtils";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { endOfDay } from "date-fns";

const DateRangeAndTimePicker = forwardRef(
    (
        {
            range = {},
            showTimePicker = true,
            showCancelApply = true,
            staticRanges = defaultStaticRanges,
            maxDate,
            minDate,
            onSaveHandler,
            onClose,
            showDateLabel = true,
            showTimeLabel = true,
            customClass = "",
            xAxisCenter = false,
            allowSetTimeToggle = true,
        },
        ref
    ) => {
        // For reference: https://github.com/hypeserver/react-date-range

        const [isTimePickerEnabled, setIsTimePickerEnabled] =
            useState(allowSetTimeToggle);
        const [dateRange, setDateRange] = useState(range);
        const [startTime, setStartTime] = useState(
            timePickerValue(range.startDate)
        );
        const [endTime, setEndTime] = useState(timePickerValue(range.endDate));

        const handleTime = (constraint, time, range = dateRange) => {
            if (constraint === timePicker.startDate) setStartTime(time);
            if (constraint === timePicker.endDate) setEndTime(time);
            const updatedDate = updateTime(range[constraint], time);
            const updatedRange = {
                ...range,
                [constraint]: updatedDate,
            };
            setDateRange(updatedRange);
        };

        const onApply = () => {
            onSaveHandler(dateRange);
            onClose();
        };

        const onChangeRange = range => {
            const startDate = updateTime(range.startDate, startTime);
            const endDate = updateTime(range.endDate, endTime);
            const updatedDate = {
                ...range,
                startDate: startDate,
                endDate: endDate,
            };
            setDateRange(updatedDate);
        };

        useImperativeHandle(ref, () => {
            return {
                getDateRange: () => dateRange,
            };
        });

        return (
            <div
                className={`${s.dateRangePicker} ${
                    xAxisCenter ? s.horCenter : ""
                } ${customClass}`}
            >
                {showTimePicker && (
                    <div className={`flex ${s.timePicker} timeCustom`}>
                        {showTimeLabel && (
                            <div className={`flex ${s.setTimeToggle}`}>
                                <label>Set Time</label>
                                <ToggleSwitch
                                    isToggleOn={isTimePickerEnabled}
                                    setIsToggleOn={setIsTimePickerEnabled}
                                />
                            </div>
                        )}
                        <div className={`${s.monthAndTime} monthAndTimeCustom`}>
                            {showDateLabel && (
                                <div
                                    className={`${s.monthLabel} monthLabelCustom`}
                                >
                                    <label>
                                        {shortDateFormat(dateRange.startDate)}
                                    </label>
                                </div>
                            )}
                            {showTimeLabel && (
                                <TimePicker
                                    value={startTime}
                                    setValue={time =>
                                        handleTime(timePicker.startDate, time)
                                    }
                                    isDisabled={!isTimePickerEnabled}
                                    toggleValueSwitchClass={
                                        s.toggleValueSwitchWrapper
                                    }
                                    timePickerInputClass={
                                        s.timePickerInputClass
                                    }
                                    dropdownListClass={
                                        s.timePickerDropdownClass
                                    }
                                />
                            )}

                            <label className={s.separator}>to</label>
                            {showDateLabel && (
                                <div
                                    className={`${s.monthLabel} monthLabelCustom`}
                                >
                                    <label>
                                        {shortDateFormat(dateRange.endDate)}
                                    </label>
                                </div>
                            )}
                            {showTimeLabel && (
                                <TimePicker
                                    value={endTime}
                                    setValue={time =>
                                        handleTime(timePicker.endDate, time)
                                    }
                                    isDisabled={!isTimePickerEnabled}
                                    toggleValueSwitchClass={
                                        s.toggleValueSwitchWrapper
                                    }
                                    timePickerInputClass={
                                        s.timePickerInputClass
                                    }
                                    dropdownListClass={
                                        s.timePickerDropdownClass
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}
                <DateRangePicker
                    ranges={[dateRange]}
                    onChange={range => onChangeRange(range.selection)}
                    showSelectionPreview={true}
                    rangeColors={["#41c3a9"]}
                    maxDate={maxDate}
                    minDate={minDate}
                    inputRanges={[]}
                    showDateDisplay={false}
                    staticRanges={staticRanges}
                    fixedHeight={true}
                />
                {showCancelApply && (
                    <div className={`flex ${s.btnWrapper}`}>
                        <span className={s.leftpanel}></span>
                        <div className={s.actionBtn}>
                            <Button
                                onClick={onClose}
                                className={s.button}
                                btnTheme={ButtonTypes.default_btn}
                            >
                                {"Cancel"}
                            </Button>
                            <Button
                                className={s.button}
                                btnTheme={ButtonTypes.primary_btn}
                                // disable={saveBtn}
                                onClick={onApply}
                            >
                                {"Apply"}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
);

const timePicker = {
    startDate: "startDate",
    endDate: "endDate",
};

export default DateRangeAndTimePicker;
