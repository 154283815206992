import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    isLoggedIn: false,
    allowedDomains: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuthInfo: (state, action) => {
            state.user = {
                name: action.payload.name,
                emailId: action.payload.emailId,
                photoURL: action.payload.photoURL,
                userGroups: action.payload.userGroups,
                isSuperAdmin: action.payload.isSuperAdmin,
                modules: action.payload.modules,
            };
            state.isLoggedIn = true;
        },
        setUserLogOutState: state => {
            state.user = null;
            state.isLoggedIn = false;
        },
        setAllowedDomains: (state, action) => {
            state.allowedDomains = action.payload;
        },
    },
});
export const authActions = authSlice.actions;
export default authSlice.reducer;
