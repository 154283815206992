import React, {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useMemo,
    useImperativeHandle,
} from "react";
import { useHistory, Prompt } from "react-router-dom";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import Card from "../../Shared/Card/Card";
import Icon from "../../Shared/Icon/Icon";
import c from "./CreateSaveHeader.module.scss";
import { BUTTON } from "../../constants/buttonLabel";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { globalConfigActions } from "../../store/globalConfig/globalConfig";
import Skeleton from "react-loading-skeleton";
import OutsideClickHandler from "react-outside-click-handler";
import Select from "../../Shared/Select/Select";
import Input from "../../Shared/Input/Input";
import { capitalizeString } from "../../utils/globalUtils";
import StatusRenderer from "../Renderers/StatusRenderer/StatusRenderer";

const CreateSaveHeader = forwardRef(
    (
        {
            isBig,
            title,
            desc,
            id,
            name,
            setName,
            onCancel,
            onSave,
            showPrompt = true,
            saveBtn = false,
            cancelLabel = "Cancel",
            saveLabel = "Save",
            showHistoryIcon = false,
            showHistory,
            showHamburgerBtn = true,
            hideShadow = true,
            loading = false,
            hideBtns = false,
            cloneListing,
            actions = [],
            actionCallback = () => {},
            flowBtns,
            flowViewSwitch = {},
            customWrapperClass,
            readOnly,
            isFlowLive = false,
            customButtonComponent,
            publishHandler,
            showPublishButton = false,
            customMiddleButtonComponent,
        },
        ref
    ) => {
        const history = useHistory();
        const dispatch = useDispatch();
        const urlLocation = history.location.pathname.split("/");
        const [offSetValue, setOffSetValue] = useState("");
        const [editMode, setEditMode] = useState(false);
        const inputRef = useRef(null);
        const onScrollPos = () => {
            setOffSetValue(window.pageYOffset > 19);
        };
        const url = window.location.pathname?.split("/");
        const isFlow = url[1] === "flowmanager" && !url.includes("siteconfig");

        useEffect(() => {
            window.addEventListener("scroll", onScrollPos);
            return () => {
                window.removeEventListener("scroll", onScrollPos);
            };
        }, [offSetValue]);

        // useImperativeHandle(ref, () => {
        //     return {
        //         focusName: () => {
        //             // setEditMode(true);
        //             // setTimeout(() => {
        //             //     inputRef.current?.focus();
        //             // }, 0);
        //         },
        //     };
        // });

        useImperativeHandle(ref, () => ({
            closeMoreDropdown: closeMoreDropdown,
        }));

        const cancelHandler = () => {
            if (typeof onCancel === "function") onCancel();
            else history.push(`/${urlLocation[1]}/${urlLocation[2]}/listings`);
        };
        const saveHandler = () => {
            if (typeof onSave === "function") onSave();
        };
        const showLeftPanel = useSelector(
            state => state.globalConfig.showLeftPanel
        );
        const [showMoreBtns, setShowMoreBtns] = useState(false);

        const iconDropdownOptions = useMemo(() => {
            return [
                ...(cloneListing
                    ? [{ key: "Clone", icon: "Clone", callback: cloneListing }]
                    : []),
                ...(showHistoryIcon
                    ? [
                          {
                              key: BUTTON.HISTORY.label,
                              icon: "Time",
                              callback: showHistory,
                          },
                      ]
                    : []),
                ...(flowBtns ? flowBtns : []),
            ];
        }, [flowBtns, showHistoryIcon, cloneListing]);

        const OptionsWithIcon = ({ option }) => {
            return (
                <div
                    className={`circleIconColor dp-parent gap-10 w-100 ${c.customOption}`}
                >
                    <Icon icon={option.icon} size="16px" enableHover={true} />
                    <span>{option.key}</span>
                </div>
            );
        };

        const closeMoreDropdown = () => {
            setShowMoreBtns(false);
        };

        return (
            <>
                <Prompt
                    when={showPrompt}
                    message={() =>
                        "You've some unsaved changes. Are you sure you want to cancel?"
                    }
                ></Prompt>

                <div
                    className={`${offSetValue ? "addBorderRadiusBottom" : ""} ${
                        customWrapperClass ? customWrapperClass : ""
                    }`}
                >
                    <Card
                        className={`${c.CreateSaveHeaderBox} ${
                            hideShadow ? c.noShadow : ""
                        } resetMarginBottom`}
                    >
                        <div className="dp-parent dp-parent-ver-center flex-auto">
                            {showHamburgerBtn && (
                                <div
                                    className={`sidePanelToggle__hamburger ${
                                        !showLeftPanel
                                            ? "sidePanelToggle__hamburger--expanded"
                                            : ""
                                    }`}
                                    onClick={() =>
                                        dispatch(
                                            globalConfigActions.toggleshowLeftPanel()
                                        )
                                    }
                                >
                                    <Tooltip
                                        message={
                                            showLeftPanel
                                                ? "Hide side panel"
                                                : "Show side panel"
                                        }
                                        position="top"
                                    >
                                        <Icon
                                            icon="HamburgerMenu"
                                            size="18px"
                                        />
                                    </Tooltip>
                                </div>
                            )}

                            <div
                                className={`dp-parent dp-parent-col dp-parent-hor-center ${
                                    isBig && !editMode ? c["big-header"] : ""
                                } ${readOnly ? "pointer-none" : ""}`}
                            >
                                <h2
                                    className={`headerTitleBold dp-parent dp-parent-ver-center`}
                                >
                                    {isFlowLive && (
                                        <StatusRenderer
                                            status={"live"}
                                            noText={true}
                                        />
                                    )}
                                    <div className="dp-parent">
                                        {id && (
                                            <span className="dp-parent dp-parent-ver-center mr-10 dp-parent-no-shrink">
                                                {" "}
                                                {id} :{" "}
                                            </span>
                                        )}
                                        <div>
                                            {!loading ? (
                                                editMode ? (
                                                    <Input
                                                        className="dp-parent-inline"
                                                        onblur={e => {
                                                            setEditMode(false);
                                                        }}
                                                        onChange={e => {
                                                            setName(
                                                                e.target.value
                                                            );
                                                        }}
                                                        autoFocus={true}
                                                        value={name || ""}
                                                        placeholder="Untitled Flow"
                                                        required={true}
                                                    />
                                                ) : (
                                                    title || (
                                                        <div
                                                            className="dp-parent"
                                                            onClick={() => {
                                                                if (!editMode) {
                                                                    setEditMode(
                                                                        true
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <Tooltip
                                                                message={
                                                                    name ||
                                                                    "Untitled Flow"
                                                                }
                                                                position="bottom"
                                                                wrap
                                                            >
                                                                <div
                                                                    className={`${c.flowName} ellipsis`}
                                                                >
                                                                    {name ||
                                                                        "Untitled Flow"}
                                                                </div>
                                                            </Tooltip>

                                                            <div
                                                                className={
                                                                    c.editNameIcon
                                                                }
                                                            >
                                                                <Icon
                                                                    className="ml-10 circleIconColor"
                                                                    icon="Edit"
                                                                    size={
                                                                        "16px"
                                                                    }
                                                                    enableHover
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            ) : (
                                                <Skeleton
                                                    width={120}
                                                    height={30}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </h2>
                                {!!desc && <p className={`infoText`}>{desc}</p>}
                            </div>
                            {!loading ? (
                                <FlowActionList
                                    actions={actions}
                                    actionCallback={actionCallback}
                                ></FlowActionList>
                            ) : (
                                <>
                                    {actions?.length > 0 && (
                                        <ul
                                            className={`dp-parent dp-parent-ver-center dp-parent-hor-center flex-auto ml-30  ${c.skeletonWrapper}`}
                                        >
                                            <li
                                                className={`${c.skeletonWrapper} p-5 mr-20`}
                                            >
                                                <Skeleton
                                                    width={26}
                                                    height={26}
                                                />
                                            </li>

                                            <li
                                                className={`${c.skeletonWrapper} p-5 mr-20`}
                                            >
                                                <Skeleton
                                                    width={26}
                                                    height={26}
                                                />
                                            </li>

                                            <li
                                                className={`${c.skeletonWrapper} p-5 mr-20`}
                                            >
                                                <Skeleton
                                                    width={26}
                                                    height={26}
                                                />
                                            </li>
                                        </ul>
                                    )}
                                </>
                            )}
                        </div>
                        {!hideBtns &&
                            (!loading ? (
                                <div
                                    className={`flex button-list ${c.bttnWrapper}`}
                                >
                                    {Object.keys(flowViewSwitch).length > 1 && (
                                        <div className={c.switchViewWrapper}>
                                            <Select
                                                notooltip
                                                width={120}
                                                hideOutline
                                                idField="id"
                                                keyField="label"
                                                options={[
                                                    {
                                                        id: "tableView",
                                                        label: "Table View",
                                                    },
                                                    {
                                                        id: "flowView",
                                                        label: "Flow View",
                                                    },
                                                ]}
                                                selectedId={
                                                    flowViewSwitch.treeView
                                                        ? "tableView"
                                                        : "flowView"
                                                }
                                                onSelect={item => {
                                                    if (
                                                        item &&
                                                        typeof flowViewSwitch.setTreeView ===
                                                            "function" &&
                                                        flowViewSwitch.treeView !==
                                                            (item.id ===
                                                                "tableView")
                                                    ) {
                                                        flowViewSwitch.setTreeView(
                                                            item.id ===
                                                                "tableView"
                                                        );
                                                    }
                                                }}
                                                className={c.select}
                                                classNameWhenOpen={
                                                    c.select__open
                                                }
                                                CustomRender={({ option }) => {
                                                    return (
                                                        <div
                                                            className={`dp-parent dp-parent-hor-space-between w-100`}
                                                        >
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {(flowViewSwitch.treeView &&
                                                                option.id ===
                                                                    "tableView") ||
                                                            (!flowViewSwitch.treeView &&
                                                                option.id ===
                                                                    "flowView") ? (
                                                                <Icon
                                                                    icon="Tick"
                                                                    size="16px"
                                                                    color="#41C3A9"
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}
                                    {iconDropdownOptions.length > 1 && (
                                        <div className={c.moreBtnWrapper}>
                                            <Button
                                                onClick={e => {
                                                    setShowMoreBtns(
                                                        !showMoreBtns
                                                    );
                                                }}
                                                className={`${c.more} flex`}
                                                btnTheme={
                                                    ButtonTypes.default_btn
                                                }
                                            >
                                                <Icon
                                                    icon="More"
                                                    size="16px"
                                                    className={c.editIcon}
                                                    enableHover={true}
                                                />
                                            </Button>
                                            {showMoreBtns && (
                                                <div
                                                    className={`${
                                                        c.moreBtnSelect
                                                    } ${
                                                        flowBtns
                                                            ? c.moreBtnSelect__flow
                                                            : ""
                                                    }`}
                                                >
                                                    <OutsideClickHandler
                                                        onOutsideClick={() => {
                                                            setShowMoreBtns(
                                                                false
                                                            );
                                                        }}
                                                    >
                                                        <Select
                                                            width={142}
                                                            keyField={"key"}
                                                            options={
                                                                iconDropdownOptions
                                                            }
                                                            onSelect={item => {
                                                                if (
                                                                    typeof item?.callback ===
                                                                    "function"
                                                                ) {
                                                                    setShowMoreBtns(
                                                                        false
                                                                    );

                                                                    item.callback();
                                                                }
                                                            }}
                                                            isSelectOpen={true}
                                                            CustomRender={
                                                                OptionsWithIcon
                                                            }
                                                            portal={false}
                                                            hideOutline={true}
                                                            label={"Icon"} //to prevent calling onselect on first load
                                                        />
                                                    </OutsideClickHandler>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {iconDropdownOptions.length === 1 && (
                                        <Button
                                            onClick={
                                                iconDropdownOptions[0].callback
                                            }
                                            className={`${c.button} flex`}
                                            btnTheme={ButtonTypes.default_btn}
                                        >
                                            <Icon
                                                icon={
                                                    iconDropdownOptions[0].icon
                                                }
                                                size="16px"
                                                className={c.editIcon}
                                                enableHover={true}
                                            />
                                            <span>
                                                {iconDropdownOptions[0].key}
                                            </span>
                                        </Button>
                                    )}

                                    <Button
                                        onClick={cancelHandler}
                                        className={c.button}
                                        btnTheme={ButtonTypes.default_btn}
                                    >
                                        {cancelLabel}
                                    </Button>
                                    {customMiddleButtonComponent &&
                                        customMiddleButtonComponent()}
                                    {onSave && (
                                        <Tooltip
                                            message={
                                                "Live permission is not granted"
                                            }
                                            position="left"
                                            hideTooltip={
                                                isFlow ? !saveBtn : true
                                            }
                                        >
                                            <Button
                                                className={c.button}
                                                btnTheme={
                                                    ButtonTypes.primary_btn
                                                }
                                                disable={saveBtn}
                                                onClick={saveHandler}
                                            >
                                                {saveLabel}
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {id && showPublishButton && (
                                        <Button
                                            className={`${c.button} ${c.publishButton} prodAction`}
                                            btnTheme={ButtonTypes.primary_btn}
                                            disable={saveBtn}
                                            onClick={() => {
                                                publishHandler();
                                            }}
                                        >
                                            Publish
                                        </Button>
                                    )}
                                    {customButtonComponent &&
                                        customButtonComponent()}
                                </div>
                            ) : (
                                <div className={`flex ${c.skeletonWrapper}`}>
                                    {Object.keys(flowViewSwitch).length > 1 && (
                                        <div className={c.skeletonWrapper}>
                                            <Skeleton width={120} height={40} />
                                        </div>
                                    )}
                                    {iconDropdownOptions?.length > 0 && (
                                        <div className={c.skeletonWrapper}>
                                            <Skeleton width={85} height={40} />
                                        </div>
                                    )}
                                    <div className={c.skeletonWrapper}>
                                        <Skeleton width={85} height={40} />
                                    </div>
                                    {onSave && (
                                        <div className={c.skeletonWrapper}>
                                            <Skeleton width={85} height={40} />
                                        </div>
                                    )}
                                </div>
                            ))}
                    </Card>
                </div>
            </>
        );
    }
);

export const FlowActionList = ({ actions, actionCallback }) => {
    return (
        <ul className="control-buttons dp-parent dp-parent-ver-center dp-parent-hor-center flex-auto">
            {actions.map(action => (
                <li
                    key={action.id}
                    onClick={() => actionCallback(action.id)}
                    className={`${action.enabled ? "" : c.disabled} mr-20 ${
                        c.actionItem
                    }`}
                >
                    <Tooltip
                        message={capitalizeString(
                            action.text || action.id || null
                        )}
                        position="top"
                    >
                        {action.type === "SWITCH" ? (
                            <div
                                className={`${c.actionLayoutIconWrapper} ${
                                    action.value
                                        ? c.actionLayoutIconWrapperActive
                                        : ""
                                }`}
                            >
                                <Icon icon={action.icon} size={"16px"} />
                            </div>
                        ) : (
                            <Icon icon={action.icon} size={"16px"} />
                        )}
                    </Tooltip>
                </li>
            ))}
        </ul>
    );
};

export default CreateSaveHeader;
