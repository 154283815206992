import { getDurationFromSec } from "./dateUtils";
import { defaultActivityFilter } from "./filterUtils";

export const isActionButtonDisabled = (data, isNote = false) => {
    if (isNote) {
        const { note } = data;
        if (note.trim().length) return true;
    } else if (!isNote) {
        const { giftCardId, giftCardStatus, comment } = data;
        if (giftCardId && comment.trim().length) return true;
    } else return false;
};

export const createParentTableData = data => {
    var parentTableData = [];
    Array.isArray(data) &&
        data?.filter(item => {
            var i = parentTableData.findIndex(x => x.sid == item.sid); // Using sid as this is mapped data
            if (i <= -1) {
                parentTableData.push(item);
            }
            return null;
        });
    return parentTableData;
};

export const createSessionIdList = data => {
    var sessionList = [];
    data?.forEach(element => {
        sessionList.push({ id: element?.sid, name: element?.sid }); // Using sid as this is mapped data
    });
    return sessionList;
};

export const mapGiftCardData = async (data, giftCardList = []) => {
    return data?.map(giftCardData => ({
        sid: typeof giftCardData.sid === "string" ? giftCardData.sid : "-",
        email: giftCardData.email,
        createdAt: giftCardData.createdAt,
        updatedAt: giftCardData.updatedAt,
        status: giftCardData.status,
        giftCardId: giftCardData.giftCardId,
        comment: giftCardData.comment,
        giftCardName: giftCardList.find(
            element => element.id === giftCardData.giftCardId
        )?.name,
    }));
};

export const mapUserJourneyListingData = data => {
    return Array.isArray(data)
        ? data?.map(ele => ({
              ts: ele.ts?.value ? ele?.ts?.value : ele?.ts,
              email: ele.email,
              first_name: ele.first_name,
              last_name: ele.last_name,
              dob: ele.dob?.value ? ele?.dob?.value : ele?.dob,
              phone_number: ele.phone_number,
              gender: ele.gender,
              address: ele.address,
              city: ele.city,
              zip: ele.zip,
              domain: ele.domain,
          }))
        : [];
};

export const mapUserJourneyDetailListingData = (
    data,
    giftCardList = [],
    durations = {},
    acvEvents
) => {
    Object.keys(durations).forEach(key => {
        durations[key] = getDurationFromSec(durations[key]);
    });
    return Array.isArray(data)
        ? data?.map(ele => ({
              ts: ele.ts?.value ? ele?.ts?.value : ele?.ts,
              sid: ele.cs_id || ele?.sid,
              ip: ele.ip,
              referrer: ele.referrer,
              ua: ele.ua,
              //   giftCardName: ele.giftCardName,
              giftCardName: giftCardList.find(
                  element => element.id === ele.giftcard_id
              )?.name,
              giftCardID: ele.giftcard_id,
              Campaign: ele.Campaign,
              Publisher_Campaign: ele.PublisherCampaign,
              Publisher: ele.Publisher,
              Activity: ele.Activity,
              advid: ele.advid,
              cmpid: ele.cmpid,
              Section: ele.Section,
              category: ele.category,
              sourceURL: ele.TrackingUrl,
              Advertiser: ele.Advertiser,
              clickId: ele?.uuid || ele?.clickId || "-",
              clid: ele?.uuid || ele?.clickId || "-",
              crid: ele.crid,
              Question: ele.Question,
              Answer: ele.Answer,
              zip: ele.zip,
              duration: durations?.[ele.cs_id] || "-",
              // validate: acvEvents.findIndex(_ => _.clid === ele?.uuid) > -1 ? true : false
              validate:
                  acvEvents.findIndex(
                      _ => _.clickId === ele?.uuid || _.clickId === ele?.clickId
                  ) > -1
                      ? true
                      : false,
          }))
        : [];
};

export const flagOptions = [
    {
        key: "unassigned",
        name: "Unassigned",
        color: "#74838F",
    },
    {
        key: "verified",
        name: "Verified",
        color: "#418CC3",
    },
    {
        key: "fulfilled",
        name: "Fulfilled",
        color: "#41C3A9",
    },
    {
        key: "fradulent",
        name: "Fraudulent",
        color: "#E76E6E",
    },
];

// {
//     "evt_id": "zip_sub",
//     "Activity": "Zip Submit"
// },
//Backend mappings for reference
// const activityMappings = [
//     ["lp_imp", "Landing Impression"],
//     ["hnp_lead_send", "Lead Send"],
//     ["hnp_lead_success", "Lead Success"],
//     ["hnp_lead_failure", "Lead failure"],
//     ["email_sub", "Email Submitted"],
//     ["usr_reg", "Registration"],
//     ["survey_quest_imp", "Survey Question Seen"],
//     ["sv_ans", "Survey Question Answered"],
//     ["slot_imp_v", "Offer Seen"],
//     ["ad_clk", "Ad Clicked"],
//     ["grat_imp", "Listings Seen"],
//     ["grat_avail", "Listing Clicked"],
//     ["ad_conv", "Ad Conversions"],
//     ["sweeps_entry", "Sweeps Entry"],
//     ["entry_confirmed", "Sweeps Entry Confirmed"],
//     ["unsubscribe", "Unsubscribe"],
// ];

const graphDataRewards = [
    {
        label: "Landing",
        isPresent: false,
        apiValue: "Landing Impression",
    },
    {
        label: "Zip Submit",
        isPresent: false,
        apiValue: "Zip Submit",
    },
    {
        label: "Email Submit",
        isPresent: false,
        apiValue: "Email Submitted",
    },
    {
        label: "Registration",
        isPresent: false,
        apiValue: "Registration",
    },
    {
        label: "Survey Impression",
        isPresent: false,
        apiValue: "Survey Impression",
    },
    {
        label: "Survey Completion",
        isPresent: false,
        apiValue: "Survey Question Answered",
    },
    {
        label: "Offer Path Impression",
        isPresent: false,
        apiValue: "Offer Seen",
    },
    {
        label: "Offer Path Completion",
        isPresent: false,
        apiValue: "Offer Path Completion", //Verify
    },
    {
        label: "Offer Wall Impression",
        isPresent: false,
        apiValue: "Offer Wall Impression",
    },
    {
        label: "Gift Card Fulfilled",
        isPresent: false,
        apiValue: "Gift Card Fulfilled",
    },
];

// If gift card is not applicable remove offerwall impression and gift card fulfilled in graph and on gift cards ones remove listing impression
const graphData = [
    {
        label: "Landing",
        isPresent: false,
        apiValue: "Landing Impression",
    },
    {
        label: "Zip Submit",
        isPresent: false,
        apiValue: "Zip Submit",
    },
    {
        label: "Email Submit",
        isPresent: false,
        apiValue: "Email Submitted",
    },
    {
        label: "Registration",
        isPresent: false,
        apiValue: "Registration",
    },
    {
        label: "Survey Impression",
        isPresent: false,
        apiValue: "Survey Impression",
    },
    {
        label: "Survey Completion",
        isPresent: false,
        apiValue: "Survey Question Answered",
    },
    {
        label: "Offer Path Impression",
        isPresent: false,
        apiValue: "Offer Seen",
    },
    {
        label: "Offer Path Completion",
        isPresent: false,
        apiValue: "Offer Path Completion", //Verify
    },
    {
        label: "Listing Impression",
        isPresent: false,
        apiValue: "Listings Seen", //Verify
    },
];

export const createGraphData = (data = [], showGiftCardData = false) => {
    let initialGraphData = showGiftCardData ? graphDataRewards : graphData;
    let apiValueArray = initialGraphData.map(a => a.apiValue);
    let finalArray = [];
    data?.forEach(ele => {
        if (apiValueArray.includes(ele?.Activity)) {
            finalArray.push(ele.Activity);
        }
    });
    const updatedData = initialGraphData?.map(p =>
        finalArray?.includes(p.apiValue) ? { ...p, isPresent: true } : p
    );
    return updatedData.map(a => a.isPresent);
};

export const initialFilterData = [
    {
        ...defaultActivityFilter,
        page: "userJourney",
    },
];
