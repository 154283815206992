import { memo, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSingleAdCreative } from "../../store/listings/adUnits";
import style from "./CreativeRender.module.scss";

import Popup from "../../Shared/Popup/Popup";
import SpinnerLoader from "../../Shared/SpinnerLoader/SpinnerLoader";

export const CreativeRender = memo(
    ({ id, code = null, height = 300, width = "100%", enableZoom = true }) => {
        const dispatch = useDispatch();
        const [src, setSrc] = useState("");
        const containerRef = useRef(null);
        const [showPreview, setShowPreview] = useState(false);
        const [popupExtraProps, setPopupExtraProps] = useState(null);
        const [loading, setLoading] = useState(false);

        useEffect(async () => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 1000);

            if (!id) {
                setSrc(code);
            } else if (id) {
                const res = await dispatch(fetchSingleAdCreative(id));
                if (res?.payload?.code) setSrc(res.payload.code);
            }
        }, [id, code]);

        const calculatePreviewPosition = e => {
            try {
                const document = e.target.contentWindow?.document;

                const bodyRect = window.document.body.getBoundingClientRect();
                const elemRect = containerRef.current.getBoundingClientRect();

                const top = elemRect.top - bodyRect.top;
                const left = elemRect.left + elemRect.width;

                if (document) {
                    const loader = document.getElementById("offer-path-loader");
                    const wrapper =
                        document.getElementById("offer-path-wrapper");
                    if (loader && wrapper) {
                        loader.style.visibility = "hidden";
                        wrapper.style.visibility = "visible";
                    }
                    const iframeHeight = document.body.scrollHeight;
                    const iframeWidth = document.body.scrollWidth;
                    e.target.style.height = iframeHeight + "px";
                    e.target.style.width = iframeWidth + "px";
                    const tempProps = {
                        width: iframeWidth + "px",
                        bodyPadding: "0",
                        bodyMaxHeight: iframeHeight + "px",
                    };
                    if (window.innerWidth < iframeWidth + left) {
                        tempProps.left =
                            left - iframeWidth - elemRect.width + "px";
                    } else {
                        tempProps.left = left + "px";
                    }

                    tempProps.top = top - iframeHeight / 2 + "px";

                    setPopupExtraProps(tempProps);
                    return {
                        iframeHeight,
                        iframeWidth,
                    };
                }
            } catch (e) {
                setSrc(
                    `<h1 style=" color: red;font-size: 16px;text-align: center;display: block;min-height: 150px;display: block; padding-top: 50%;">Unable to render preview for this creative</h1>`
                );
                return {
                    iframeHeight: 300,
                    iframeWidth: 300,
                };
            }
        };

        const renderIframe = (src, transform = false) => {
            return (
                <iframe
                    srcDoc={src}
                    style={{ visibility: transform ? "hidden" : "visible" }}
                    onLoad={e => {
                        const { iframeHeight } = calculatePreviewPosition(e);
                        if (transform) {
                            const scale =
                                height < iframeHeight
                                    ? height / iframeHeight
                                    : 1;
                            e.target.style.transform = `translateX(-50%) scale(${scale})`;
                            e.target.style.visibility = `visible`;
                        }
                    }}
                ></iframe>
            );
        };
        return (
            src && (
                <>
                    <div
                        className={style.container}
                        style={{ height: height + "px", width: width }}
                        ref={containerRef}
                        onMouseEnter={e => {
                            setShowPreview(true);
                        }}
                        onMouseLeave={() => {
                            setShowPreview(false);
                        }}
                    >
                        <div
                            className={`${style.overlay} ${
                                enableZoom ? style.zoomAvailable : ""
                            }`}
                            onClick={e => e.stopPropagation()}
                        ></div>
                        {!loading && renderIframe(src, true)}
                        {loading && (
                            <div className={style.loaderWrapper}>
                                <SpinnerLoader />
                            </div>
                        )}
                    </div>
                    {enableZoom && showPreview && popupExtraProps && (
                        <Popup
                            showHeader={false}
                            showFooter={false}
                            showConfirmBox={false}
                            closeOutside={true}
                            portal={true}
                            {...popupExtraProps}
                            overlayCustomClass={style.popupClass}
                        >
                            {renderIframe(src)}
                        </Popup>
                    )}
                </>
            )
        );
    }
);
