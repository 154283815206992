import s from "./TreeView.module.scss";
import "../../styles/styles.scss";
import { getRandomId } from "../../utils/globalUtils";

import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";

const TreeViewComponent = ({
    selected = [],
    list = [],
    callBack,
    actions,
    onFilterApplied,
    filterApplied,
    onClickValues,
    onClickSecondParam,
}) => {
    const dispatch = useDispatch();

    function TreeWrap({ data }) {
        return (
            <TreeView
                aria-label="Module Permissions"
                expanded={[data?.id?.toString()]}
                sx={{
                    height: 200,
                    flexGrow: 1,
                    maxWidth: 400,
                    overflowY: "auto",
                }}
            >
                <CustomTreeHeader data={data}></CustomTreeHeader>
            </TreeView>
        );
    }

    function CustomTreeHeader(props) {
        const { data, parents = [] } = props;
        const consolidatedId = [...parents, data.id];

        return (
            <TreeItem
                nodeId={data.id}
                label={
                    <div className={s.moduleRow}>
                        <div
                            className={`${s.moduleCol}
                            ${!isEmpty(data.children) ? s.onlyHeader : ""}
                            `}
                            onClick={
                                !data.children || data.children.length === 0
                                    ? async () => {
                                          let initialData = data;
                                          initialData["type"] = "create";
                                          let initialFilterData = filterApplied;
                                          const foundTag =
                                              initialFilterData.some(
                                                  el => el.id === initialData.id
                                              );
                                          if (!foundTag) {
                                              await dispatch(
                                                  onFilterApplied(initialData)
                                              );
                                              await callBack(data);
                                          } else {
                                              const element =
                                                  initialFilterData.find(
                                                      el =>
                                                          el.id ===
                                                          initialData.id
                                                  );
                                              if (element?.id === "Status")
                                                  onClickSecondParam(
                                                      element?.filterTag[0]
                                                  );
                                              else onClickValues(element);
                                          }
                                      }
                                    : null
                            }
                        >
                            {data.name}
                        </div>
                    </div>
                }
            >
                {data.children &&
                    data.children?.map(_ => (
                        <TreeView
                            aria-label="Module Permissions"
                            expanded={[_?.id?.toString()]}
                            key={getRandomId()}
                        >
                            <CustomTreeHeader
                                parents={consolidatedId}
                                data={_}
                            ></CustomTreeHeader>
                        </TreeView>
                    ))}
            </TreeItem>
        );
    }

    return (
        <>
            <div className={s.maxWidth}>
                <div
                    id="TreeViewComponent"
                    className={`${s.contentWrap} contentWrap`}
                >
                    {list && list.length
                        ? list.map((item, index) => (
                              <TreeWrap data={item} key={getRandomId()} />
                          ))
                        : null}
                </div>
            </div>
        </>
    );
};
export default TreeViewComponent;
