import { useDispatch } from "react-redux";
import DateRangeAndTimePicker from "../../Shared/DateRangeAndTimePicker/DateRangeAndTimePicker";
import { defaultDateFormat, onlyDateLabel } from "../../utils/dateUtils";
import s from "./DateTimePicker.module.scss";
import { useState } from "react";
import Icon from "../../Shared/Icon/Icon";
import { useEffect } from "react";
import { createStaticRanges, defaultStaticRanges } from "react-date-range";
import startOfDay from "date-fns/startOfDay";
import endOfYesterday from "date-fns/endOfYesterday";
import subDays from "date-fns/subDays";
import OutsideClickHandler from "react-outside-click-handler";

const DateTimePicker = ({
    showTimePicker = true,
    showTimeLabel = true,
    customClass = "",
    dateTimeLabel = true,
    dateRange,
    actions,
    refreshData,
    onChangeDate = null,
    customIcon = null,
    setDateRange,
    maxDate,
    minDate,
    xAxisCenter = false,
    allowSetTimeToggle,
}) => {
    const [staticRanges, setStaticRanges] = useState(defaultStaticRanges);
    useEffect(() => {
        if (!defaultStaticRanges.some(range => range.label === "Last 7 Days")) {
            const last7DaysRange = createStaticRanges([
                {
                    label: "Last 7 Days",
                    range: () => ({
                        startDate: startOfDay(subDays(new Date(), 7)),
                        endDate: endOfYesterday(),
                    }),
                },
            ]);
            staticRanges.splice(2, 0, ...last7DaysRange);
            setStaticRanges(staticRanges);
        }
    }, []);

    const dispatch = useDispatch();
    const dateRangeLabel = dateTimeLabel
        ? `${defaultDateFormat(dateRange.startDate)} - ${defaultDateFormat(
              dateRange.endDate
          )}`
        : `${onlyDateLabel(dateRange.startDate)} - ${onlyDateLabel(
              dateRange.endDate
          )}`;
    const [showDatePicker, setShowDatePicker] = useState(false);

    const updateRange = range => {
        onChangeDate && typeof onChangeDate === "function"
            ? onChangeDate(range)
            : typeof setDateRange === "function"
            ? setDateRange(range)
            : dispatch(actions.setDateRange(range));
    };

    const dateRangeSaveHandler = range => {
        updateRange(range);
        onChangeDate && typeof onChangeDate === "function"
            ? null
            : refreshData && typeof refreshData === "function"
            ? dispatch(refreshData())
            : null;
    };

    const toggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    return (
        <div className={s.wrapper}>
            <OutsideClickHandler
                onOutsideClick={() => setShowDatePicker(false)}
            >
                <div
                    className={`${s.dateRangePickerLabel}
                    ${
                        showDatePicker ? s.dateRangePickerFocus : ""
                    } dateRangePickerLabel`}
                    onClick={toggleDatePicker}
                >
                    <div className={s.calendarIcon}>
                        <Icon
                            icon={customIcon || "CalendarFilled"}
                            size="14px"
                        />
                    </div>
                    <label>{dateRangeLabel}</label>
                </div>
                {showDatePicker && (
                    <DateRangeAndTimePicker
                        range={dateRange}
                        staticRanges={staticRanges}
                        maxDate={maxDate}
                        minDate={minDate}
                        onSaveHandler={dateRangeSaveHandler}
                        onClose={() => setShowDatePicker(false)}
                        showTimePicker={showTimePicker}
                        showTimeLabel={showTimeLabel}
                        customClass={customClass}
                        xAxisCenter={xAxisCenter}
                        allowSetTimeToggle={allowSetTimeToggle}
                    />
                )}
            </OutsideClickHandler>
        </div>
    );
};

export default DateTimePicker;
