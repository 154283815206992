import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function SortableItem(props) {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id, disabled: props.disableSort });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return props.dragHandle ? (
        <div ref={setNodeRef} style={style} key={props.id}>
            {props.children}
        </div>
    ) : (
        <div
            ref={setNodeRef}
            style={style}
            key={props.id}
            {...attributes}
            {...listeners}
        >
            {props.children}
        </div>
    );
}
