import * as React from "react";
// import Box from '@mui/material/Box';
import LinearProgress from "@mui/material/LinearProgress";
import s from "./LinearDeterminate.module.scss";

export default function LinearDeterminate({ customClass }) {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress(oldProgress => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 40;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className={`${s.wrapper} ${customClass ? customClass : ""}`}>
            <LinearProgress variant="indeterminate" />
        </div>
    );
}
