import IcoMoon from "react-icomoon";
import { isElementEmpty } from "../../utils/globalUtils";

const iconSet = require("./selection.json");

const Icon = ({
    icon,
    fallbackIcon,
    enableHover = false,
    disable = false,
    size = "16px",
    ...props
}) => {
    const hoverIconElem = (
        <IcoMoon
            iconSet={iconSet}
            icon={`${icon}Filled`}
            size={size}
            {...props}
        />
    );
    const iconElem = (
        <IcoMoon iconSet={iconSet} icon={icon} size={size} {...props} />
    );

    const renderIcon = () =>
        !isElementEmpty(iconElem) ? (
            iconElem
        ) : (
            <IcoMoon
                iconSet={iconSet}
                icon={fallbackIcon}
                size={size}
                {...props}
            />
        );

    const renderHoverIcon = () =>
        !isElementEmpty(hoverIconElem) ? hoverIconElem : iconElem;

    const disableStyle = {
        cursor: disable ? "not-allowed" : null,
        opacity: disable ? 0.5 : null,
    };

    return (
        <i
            className={`dp-parent-inline ${enableHover ? "icon-wrapper" : ""}`}
            style={disableStyle}
        >
            <i className="icon-normal">{renderIcon()}</i>
            <i className="icon-hover" style={{ display: "none" }}>
                {renderHoverIcon()}
            </i>
        </i>
    );
};

export default Icon;
