import TableFlex from "../../components/TableFlex/TableFlex";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import s from "./ChannelMapTable.module.scss";
import Popup from "../../Shared/Popup/Popup";
import { useState } from "react";
import Table from "../../Shared/Table/Table";

const ChannelMapTable = ({
    data,
    onDeleteItem,
    showPopup,
    setShowPopup,
    onDeleteConfirmation,
    valueToDelete,
    readOnly = false,
}) => {
    const tableData = { tableError: "Channel Map not present" };
    const [popupTopPosition, setPopupTopPosition] = useState();

    const columns = [
        {
            text: "A/B Test",
            // key: "valueOne",
            key: "valueName", // Currently using this as map is implemented on client side
        },
        {
            text: "Channel",
            key: "valueSecond",
        },
        {
            text: "",
            key: "",
            CustomRender: ({ item }) => {
                return (
                    <>
                        <>
                            <ActionButtons
                                item={item}
                                isKey={"valueOne"}
                                deleteCallback={item => {
                                    let topPos = document
                                        .querySelector(".edit" + item.valueOne)
                                        .getBoundingClientRect().top;
                                    let abTestTopPosition = document
                                        .querySelector(".channelTable")
                                        .getBoundingClientRect().top;
                                    setPopupTopPosition(
                                        topPos - abTestTopPosition - 64 + "px"
                                    );
                                    onDeleteItem(item);
                                }}
                                readOnly={readOnly}
                            />
                        </>
                        {showPopup === item.valueOne && (
                            <div className={s.popUp}>
                                <Popup
                                    showHeader={false}
                                    showFooter={false}
                                    showConfirmBox={true}
                                    saveButtonText={"Yes, Go ahead"}
                                    cancelButtonText={"Cancel"}
                                    closeOutside={true}
                                    message={confirmPopupMsg}
                                    disableSave={false}
                                    width={"332px"}
                                    portal={true}
                                    containerId={document.querySelector(
                                        ".channelTable"
                                    )}
                                    textAlign={"left"}
                                    onClose={() => setShowPopup(null)}
                                    onSave={onDeleteConfirmation}
                                    customClass={s.popupChannel}
                                    top={popupTopPosition}
                                />
                            </div>
                        )}
                    </>
                );
            },
            bulkActions: true,
        },
    ];

    const confirmPopupMsg = (
        <p>
            This will delete the channel map with channel{" "}
            <strong>{valueToDelete?.valueSecond}</strong>, do you wish to go
            ahead?
        </p>
    );

    return (
        <>
            <div className={`${s.table} channelTable`}>
                <Table
                    columns={columns}
                    data={data}
                    showBorder={true}
                    additionalProps={{
                        data,
                        showPopup,
                    }}
                />
            </div>
        </>
    );
};

export default ChannelMapTable;
