import { combineReducers } from "redux";
import adUnit from "./adunit";
import surveyPath from "./surveypath";
import offerPath from "./offerpath";
import surveyBasics, { surveyBasicsActions } from "./surveybasics";
import featureSets, { updateFeatureSet } from "./featureSets";
import customFetch from "../../fetch/customFetch";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty, isEqual } from "lodash";
import { CREATE_SURVEY, SURVEYS } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import { fetchSurveysData } from "../listings/surveys";
import { offerPathActions } from "../survey/offerpath";
import { surveyPathActions } from "../survey/surveypath";
import { featureSetsActions } from "../survey/featureSets";
import {
    getOfferPathParams,
    getSurveyPathParams,
} from "../../utils/surveyUtils";
import fetchAll from "../../fetch/fetchAll";
import {
    adGroupPostApiData,
    adGroupPutApiData,
    adSlotsPostApiData,
    adSlotsPutApiData,
    deleteSlotsAdsApiData,
    deleteSlotsApiData,
    slotTypeSmallCase,
} from "../../utils/offerPathUtils";

export const resetSurvey = createAsyncThunk(
    "survey/resetSurvey",
    async (_, thunkApi) => {
        const { dispatch } = thunkApi;
        dispatch(surveyBasicsActions.resetSurveyBasics());
        dispatch(offerPathActions.resetOfferPath());
        dispatch(surveyPathActions.resetSurveyPath());
        dispatch(featureSetsActions.resetFeatureSet());
    }
);

export const fetchSurveyData = createAsyncThunk(
    "survey/fetchSurveyData",
    async (id, { dispatch }) => {
        const response = await customFetch(`${CREATE_SURVEY}/${id}`, "GET");
        if (
            get(response, "data.data.id", null) != null &&
            get(response, "data.success", false)
        ) {
            const { id, name = "" } = get(response, "data.data", {});
            dispatch(surveyBasicsActions.setEditId(id));
            dispatch(surveyBasicsActions.setSurveyBasicsName(name));
        } else
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch survey data details!",
                })
            );
        return response;
    }
);

export const createSurvey = createAsyncThunk(
    "survey/createSurvey",
    async (id = null, { getState, dispatch }) => {
        const { survey } = getState();
        const name = get(survey, "surveyBasics.surveyName", "");
        dispatch(surveyBasicsActions.setMainLoader(true));
        const response = await customFetch(
            id ? `${CREATE_SURVEY}/${id}` : CREATE_SURVEY,
            id ? "PATCH" : "POST",
            { name },
            { "content-type": "application/json" }
        );
        const surveyId = get(response, "data.data.id", null);
        if (
            get(response, "data.data.id", null) != null &&
            get(response, "data.success", false)
        ) {
            dispatch(surveyBasicsActions.setMainLoader(false));
            id
                ? dispatch(updateSurveyPath(id))
                : dispatch(createSurveyPath(surveyId));
            id
                ? dispatch(updateOfferPath(id))
                : dispatch(createOfferPath(surveyId));
            dispatch(updateFeatureSet(surveyId));
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Survey: ${name} ${
                        id ? "updated" : "created"
                    } successfully`,
                    // message: `${
                    //   id ? "Updated" : "Created"
                    // } the survey with name - ${name}`,
                })
            );
            dispatch(fetchSurveysData());
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to ${
                        id ? "update" : "create"
                    } the survey! Please try again later!`,
                })
            );
        }
        dispatch(surveyBasicsActions.setMainLoader(false));
        return response;
    }
);

const createSurveyPath = createAsyncThunk(
    "surveys/createsurveypath",
    async (surveyId, { getState, dispatch }) => {
        const { survey } = getState();
        const surveyPathParams = getSurveyPathParams(
            survey.surveyPath.pages,
            surveyId
        );
        const surveyPathApi = surveyPathParams.map(params =>
            customFetch(SURVEYS.CREATE_SURVEY_PATH_DETAILS, "POST", params)
        );

        const response = await fetchAll(surveyPathApi);

        response.forEach(res => {
            const pagesData = getState().survey.surveyPath.pages;
            const selectedPageIndex = Object.keys(pagesData).findIndex(item => {
                return pagesData[item].name === res.data.data.name;
            });
            saveSubQuestionMapping(
                getState,
                dispatch,
                res.data.data.id,
                Object.keys(pagesData)[selectedPageIndex]
            );
        });

        return response;
    }
);

const updateSurveyPath = createAsyncThunk(
    "surveys/updateSurveyPath",
    async (surveyId, { getState, dispatch }) => {
        const { survey } = getState();
        const surveyPathParams = getSurveyPathParams(
            survey.surveyPath.pages,
            surveyId
        );
        const surveyPathApi = surveyPathParams.map(params =>
            customFetch(
                params.id
                    ? SURVEYS.UPDATE_SURVEY_PATH_DETAILS
                    : SURVEYS.CREATE_SURVEY_PATH_DETAILS,
                params.id ? "PUT" : "POST",
                params
            )
        );
        const deleteSurveyPathApi = survey.surveyPath.deletedPageIds.map(id =>
            customFetch(`${SURVEYS.DELETE_SURVEY_PATH_DETAILS}/${id}`, "DELETE")
        );

        const response = await fetchAll([
            ...surveyPathApi,
            ...deleteSurveyPathApi,
        ]);

        response.forEach(res => {
            const pagesData = getState().survey.surveyPath.pages;
            const selectedPageIndex = Object.keys(pagesData).findIndex(item => {
                return pagesData[item].name === res.data.data.name;
            });
            saveSubQuestionMapping(
                getState,
                dispatch,
                res.data.data.id,
                Object.keys(pagesData)[selectedPageIndex]
            );
        });
        return response;
    }
);

const saveSubQuestionMapping = (
    getState,
    dispatch,
    surveyPathId,
    selectedPageName
) => {
    const quesBank =
        getState().survey.surveyPath.pages[selectedPageName].questionBank;
    const itemsArray = JSON.parse(
        JSON.stringify(quesBank.itemsArr).replaceAll(
            "{{newSurveyPathId}}",
            surveyPathId
        )
    );

    itemsArray.forEach(item => {
        //save tcpa data
        item.questionsList.forEach(ques => {
            if (ques.tcpa[surveyPathId] && ques.tcpa[surveyPathId].dirty) {
                const tcpaData = ques.tcpa[surveyPathId];
                delete tcpaData.dirty;
                tcpaData.segmentId = item.id;
                tcpaData.surveyPathID = surveyPathId;
                (async () => {
                    const response = await customFetch(
                        `${SURVEYS.UPDATE_TCPA}`,
                        "POST",
                        tcpaData
                    );
                    if (!get(response, "data.success", false))
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: `Failed to update TCPA for segment id ${item.id}`,
                            })
                        );
                })();
            }
        });

        //save questions data
        const selectedQuestions = quesBank.selected;
        if (item.dirty && selectedQuestions.includes(item.id)) {
            item.data.pages[0].elements = item.data.pages[0].elements.map(
                item => {
                    delete item.tcpa;
                    delete item.dirty;
                    return item;
                }
            );
            const subQuestionObj = { data: item.data, name: item.data.title };
            (async () => {
                const response = await customFetch(
                    `${SURVEYS.UPDATE_SEGMENT}/${item.id}`,
                    "POST",
                    subQuestionObj
                );

                if (!get(response, "data.success", false))
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Failed to update the segment! Please try again later!",
                        })
                    );
            })();
        }
    });
};

const createOfferPath = createAsyncThunk(
    "surveys/createofferpath",
    async (surveyId, { getState, dispatch }) => {
        const { survey } = getState();
        const offerPathParams = getOfferPathParams(
            survey.offerPath.pages,
            surveyId
        );
        const offerPathApi = offerPathParams.map(params =>
            customFetch(
                params.id
                    ? `${SURVEYS.UPDATE_OFFER_PATH}/${params.id}`
                    : SURVEYS.CREATE_OFFER_PATH,
                params.id ? "PUT" : "POST",
                params
            )
        );
        const responses = await fetchAll(offerPathApi);
        dispatch(handleUpdateSlots(responses))
            .unwrap()
            .then(res => {});
        // return responses;
    }
);

const updateOfferPath = createAsyncThunk(
    "surveys/upddateofferpath",
    async (surveyId, { getState, dispatch }) => {
        const { survey } = getState();
        const offerPathParams = getOfferPathParams(
            survey.offerPath.pages,
            surveyId,
            true
        );
        let unMapOfferPathParams = [];
        if (!isEmpty(survey.offerPath.deletedPages)) {
            unMapOfferPathParams = getOfferPathParams(
                survey.offerPath.deletedPages,
                surveyId,
                true,
                true
            );
        }
        const offerPathApi = offerPathParams.map(params =>
            customFetch(
                params.id
                    ? `${SURVEYS.UPDATE_OFFER_PATH}/${params.id}`
                    : SURVEYS.CREATE_OFFER_PATH,
                params.id ? "PUT" : "POST",
                params
            )
        );
        const deleteOfferPathApi = unMapOfferPathParams.map(params =>
            customFetch(
                `${SURVEYS.UPDATE_OFFER_PATH}/${params.id}`,
                "PUT",
                params
            )
        );
        if (deleteOfferPathApi.length) await fetchAll(deleteOfferPathApi);
        const responses = await fetchAll(offerPathApi);
        dispatch(handleUpdateSlots(responses))
            .unwrap()
            .then(res => {});
    }
);

const handleCreateSlots = createAsyncThunk(
    "surveys/handleCreateSlots",
    async (data, { getState, dispatch }) => {
        const offerPathStates = getState().survey.offerPath.pages;
        const slotsArr = Object.keys(offerPathStates).map(
            key => offerPathStates[key].slots.selectedListItems
        );
        data.forEach((res, index) => {
            const data = {
                state: slotsArr[index],
                id: res.data.data.id,
            };
            dispatch(createAdSlot(data))
                .unwrap()
                .then(res => {
                    const obj = {
                        slots: slotsArr[index],
                        responses: res,
                    };
                    dispatch(createGroup(obj));
                });
        });
    }
);

const handleUpdateSlots = createAsyncThunk(
    "surveys/handleUpdateSlots",
    async (data, { getState, dispatch }) => {
        const offerPathStates = getState().survey.offerPath.pages;
        const slotsArr = Object.keys(offerPathStates).map(
            key => offerPathStates[key].slots.selectedListItems
        );
        const slotsData = Object.keys(offerPathStates).map(
            key => offerPathStates[key].adSlotsData
        );
        data.forEach((res, index) => {
            const data = {
                state: slotsArr[index],
                id: res.data.data.id,
            };
            dispatch(updateAdSlot(data))
                .unwrap()
                .then(res => {
                    const obj = {
                        slots: slotsArr[index],
                        responses: res,
                        existingData: slotsData[index],
                    };
                    dispatch(updateGroup(obj));
                });
        });
        dispatch(deleteAdSlot());
        dispatch(deleteOfferPathSlotsAds());
    }
);

const createAdSlot = createAsyncThunk(
    "surveys/createOfferPath",
    async (data, { getState }) => {
        const adSlots = data.state;
        if (adSlots && adSlots.length) {
            const postData = adSlotsPostApiData(adSlots, data.id);
            return await fetchAll(postData);
        }
        return;
    }
);

const updateAdSlot = createAsyncThunk(
    "surveys/offerpaths/updateAdSlots",
    async (data, { getState, dispatch }) => {
        const adSlots = data.state;
        if (adSlots && adSlots.length) {
            const createSlots = adSlots.filter(slot => slot.id === "-");
            const updateSlots = adSlots.filter(
                slot => slot.id !== "-" && slot.isEdit
            );
            const createSlotsApi = adSlotsPostApiData(createSlots, data.id);
            const updateSlotsApi = adSlotsPutApiData(updateSlots, data.id);
            return await fetchAll([...createSlotsApi, ...updateSlotsApi]);
        }
        return;
    }
);

const deleteAdSlot = createAsyncThunk(
    "surveys/offerpaths/deleteAdSlots",
    async (_, { getState }) => {
        const offerPathStates = getState().survey.offerPath.pages;
        let slotsToBeDeleted = [];
        Object.keys(offerPathStates).forEach(key => {
            slotsToBeDeleted = [
                ...slotsToBeDeleted,
                ...offerPathStates[key].slots.deletedSlots,
            ];
        });
        const deletedSlotsApi = deleteSlotsApiData(slotsToBeDeleted);
        return await fetchAll(deletedSlotsApi);
    }
);

const createGroup = createAsyncThunk(
    "surveys/offerpaths/creategroups",
    async (data, { getState, dispatch }) => {
        const groupsApi = [];
        data.slots.forEach((slot, index) => {
            if (!slot.editCreativeData.length || slot?.type === "OfferWall")
                return;
            const adGroupPostData = adGroupPostApiData(
                slot,
                index,
                data.responses
            );
            groupsApi.push(adGroupPostData);
        });
        return await fetchAll(groupsApi);
    }
);

const updateGroup = createAsyncThunk(
    "surveys/offerpaths/updategroups",
    async (data, { getState, dispatch }) => {
        let groupsApi = [];
        data.slots.forEach((slot, index) => {
            if (slot.editCreativeData.length && slot?.type !== "OfferWall") {
                if (slot.id === "-") {
                    const adGroupApiData = adGroupPostApiData(
                        slot,
                        index,
                        data.responses
                    );
                    groupsApi.push(adGroupApiData);
                } else {
                    const existingSlotData = data.existingData.find(
                        data => data.id == slot.id
                    );
                    if (
                        !existingSlotData ||
                        !existingSlotData[slotTypeSmallCase[slot.type]]
                    ) {
                        const adGroupApiData = adGroupPostApiData(
                            slot,
                            index,
                            data.responses
                        );
                        groupsApi.push(adGroupApiData);
                    } else {
                        const adGroupApiData = adGroupPutApiData(
                            slot,
                            existingSlotData[slotTypeSmallCase[slot.type]]
                        );
                        groupsApi.push(adGroupApiData);
                    }
                }
            }
        });
        return await fetchAll(groupsApi);
    }
);

export const deleteOfferPathSlotsAds = createAsyncThunk(
    "listings/offerpaths/deleteOfferPathSlotsAds",
    async (_, { getState }) => {
        const offerPathStates = getState().survey.offerPath.pages;
        let adsToBeDeleted = [];
        Object.keys(offerPathStates).forEach(key => {
            adsToBeDeleted = [
                ...adsToBeDeleted,
                ...offerPathStates[key].slots.deletedSlotAds,
            ];
        });
        const deletedSlotAdsApi = deleteSlotsAdsApiData(adsToBeDeleted);
        return await fetchAll(deletedSlotAdsApi);
    }
);

const survey = combineReducers({
    adUnit,
    surveyPath,
    offerPath,
    surveyBasics,
    featureSets,
});

export default survey;
