import { isEmpty } from "lodash";
import { MAIN } from "./url";
import customFetch from "../fetch/customFetch";

export const targettingList = async () => {
    const response = await customFetch(
        `${MAIN.GET_ALL_TARGETINGS}`,
        "GET",
        {}
    ).then(res => res?.data);
    const userAttributeTps = response?.data["User Attribute"] || [];
    const userBehaviourTps = response?.data["User Behaviour"] || [];
    return [...userAttributeTps, ...userBehaviourTps];
};

export const newTP = [
    {
        id: 20,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "utm_source",
        opsName: "Publisher",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: "17",
                label: "ResultsGeneration",
            },
            {
                id: "pdmo",
                label: "pdmo",
            },
            {
                id: "18",
                label: "ViceroLLC",
            },
            {
                id: "google",
                label: "google",
            },
            {
                id: "5",
                label: "All-Inbox(Email)",
            },
            {
                id: "jobsinsdr",
                label: "jobsinsdr",
            },
            {
                id: "upward",
                label: "upward",
            },
            {
                id: "110",
                label: "Skenzo",
            },
            {
                id: "39",
                label: "Adworks",
            },
            {
                id: "33",
                label: "PushnamiLLC",
            },
            {
                id: "7",
                label: "INTELLIGENTDELIVERYCORP.",
            },
            {
                id: "31",
                label: "VoltronInteractive",
            },
            {
                id: "bing",
                label: "bing",
            },
            {
                id: "xlns",
                label: "xlns",
            },
            {
                id: "93",
                label: "ApptnessMediaGroupLLC",
            },
            {
                id: "6",
                label: "All-Inbox(SMS)",
            },
            {
                id: "21",
                label: "Reticular",
            },
            {
                id: "28",
                label: "717Ads",
            },
            {
                id: "44",
                label: "VHMnetworkLLC",
            },
            {
                id: "108",
                label: "FinunitedCareers",
            },
            {
                id: "35",
                label: "Fluent,LLC",
            },
            {
                id: "facebook",
                label: "facebook",
            },
            {
                id: "xutg",
                label: "xutg",
            },
            {
                id: "34",
                label: "JoblovinLLC",
            },
            {
                id: "62",
                label: "LocalStaffingLLC",
            },
            {
                id: "xrg",
                label: "xrg",
            },
            {
                id: "xatb",
                label: "xatb",
            },
            {
                id: "pgmtc",
                label: "pgmtc",
            },
            {
                id: "151",
                label: "BluewaterDigital",
            },
            {
                id: "75",
                label: "PopularMarketingLLC",
            },
            {
                id: "taboola",
                label: "taboola",
            },
            {
                id: "71",
                label: "PJCenterprise",
            },
            {
                id: "xleco",
                label: "xleco",
            },
            {
                id: "xmtro",
                label: "xmtro",
            },
            {
                id: "xnavg",
                label: "xnavg",
            },
            {
                id: "112",
                label: "DigipropzLLC",
            },
            {
                id: "pdmo1",
                label: "pdmo1",
            },
            {
                id: "69",
                label: "DMS",
            },
            {
                id: "xjobb",
                label: "xjobb",
            },
            {
                id: "40",
                label: "Castline",
            },
            {
                id: "xup",
                label: "xup",
            },
            {
                id: "125",
                label: "yepads.com",
            },
            {
                id: "xret",
                label: "xret",
            },
            {
                id: "19",
                label: "SurfinMouse",
            },
            {
                id: "55",
                label: "ConversionMarketingLLC",
            },
            {
                id: "pdmo2",
                label: "pdmo2",
            },
            {
                id: "52",
                label: "FlyrimTechCorp",
            },
            {
                id: "51",
                label: "NotifyAI,LLC",
            },
            {
                id: "xup_static",
                label: "xup_static",
            },
            {
                id: "43",
                label: "SilverTap",
            },
            {
                id: "37",
                label: "OfferBlueprint,LLC",
            },
            {
                id: "78",
                label: "FlexMarketingGroup",
            },
            {
                id: "144",
                label: "ActiveSun",
            },
            {
                id: "r3i",
                label: "r3i",
            },
            {
                id: "fim",
                label: "fim",
            },
            {
                id: "xgi",
                label: "xgi",
            },
            {
                id: "169",
                label: "JobbleInc.",
            },
            {
                id: "59",
                label: "QuantumResponseNetwork",
            },
            {
                id: "119",
                label: "Ifficient,Inc",
            },
            {
                id: "26",
                label: "EdvisorsNetwork,Inc.-ScholarshipPoints",
            },
            {
                id: "xjov",
                label: "xjov",
            },
            {
                id: "138",
                label: "ConvertingAdss.r.o.",
            },
            {
                id: "xjov1",
                label: "xjov1",
            },
            {
                id: "29",
                label: "AddictedAffiliateLLC",
            },
            {
                id: "xjg",
                label: "xjg",
            },
            {
                id: "85",
                label: "MGID",
            },
            {
                id: "46",
                label: "PushPros",
            },
            {
                id: "xct",
                label: "xct",
            },
            {
                id: "xvhm",
                label: "xvhm",
            },
            {
                id: "25",
                label: "EdvisorsNetwork,Inc.-RewardFish",
            },
            {
                id: "68",
                label: "HasTraffic.com",
            },
            {
                id: "117",
                label: "Perform[cb],LLC",
            },
            {
                id: "xjob",
                label: "xjob",
            },
            {
                id: "digitalmoses",
                label: "digitalmoses",
            },
            {
                id: "15",
                label: "WhatIfMedia",
            },
            {
                id: "170",
                label: "HasOffers",
            },
            {
                id: "120",
                label: "TheSternGroup",
            },
            {
                id: "66",
                label: "VerizonMedia",
            },
            {
                id: "xwhj",
                label: "xwhj",
            },
            {
                id: "49",
                label: "rollerads",
            },
            {
                id: "rg",
                label: "rg",
            },
            {
                id: "xfbg",
                label: "xfbg",
            },
            {
                id: "ntfyai",
                label: "ntfyai",
            },
            {
                id: "ls",
                label: "ls",
            },
            {
                id: "xvcp",
                label: "xvcp",
            },
            {
                id: "QATest",
                label: "QATest",
            },
            {
                id: "xapp",
                label: "xapp",
            },
            {
                id: "ppr",
                label: "ppr",
            },
            {
                id: "testp",
                label: "testp",
            },
            {
                id: "86",
                label: "intango.com",
            },
            {
                id: "64",
                label: "LaunchPotato",
            },
            {
                id: "157",
                label: "InterestMedia",
            },
            {
                id: "bjo",
                label: "bjo",
            },
            {
                id: "fsm",
                label: "fsm",
            },
            {
                id: "91",
                label: "RXMG",
            },
            {
                id: "129",
                label: "DigitalHub",
            },
            {
                id: "awks",
                label: "awks",
            },
            {
                id: "xadn",
                label: "xadn",
            },
            {
                id: "facebook2",
                label: "facebook2",
            },
            {
                id: "xapp_static",
                label: "xapp_static",
            },
            {
                id: "ret",
                label: "ret",
            },
            {
                id: "vhm",
                label: "vhm",
            },
            {
                id: "st",
                label: "st",
            },
            {
                id: "74",
                label: "SeccoSquared,LLC",
            },
            {
                id: "jo",
                label: "jo",
            },
            {
                id: "clm",
                label: "clm",
            },
            {
                id: "pn",
                label: "pn",
            },
            {
                id: "57",
                label: "Shift44",
            },
            {
                id: "{affiliate_id}",
                label: "{affiliate_id}",
            },
            {
                id: "13",
                label: "Zapptastic,LLC",
            },
            {
                id: "mnet",
                label: "mnet",
            },
            {
                id: "40",
                label: "castline",
            },
            {
                id: "80",
                label: "PuzzLLC",
            },
            {
                id: "upward",
                label: "Upward",
            },
            {
                id: "2",
                label: "TestPartner2",
            },
            {
                id: "QATest",
                label: "qatest",
            },
            {
                id: "mjh",
                label: "mjh",
            },
            {
                id: "gQSeBnzGW",
                label: "gQSeBnzGW",
            },
            {
                id: "xappcast_test",
                label: "xappcast_test",
            },
            {
                id: "sh44",
                label: "sh44",
            },
            {
                id: "gQSeBnzG",
                label: "gQSeBnzG",
            },
            {
                id: "imc",
                label: "imc",
            },
            {
                id: "xser",
                label: "xser",
            },
            {
                id: "16",
                label: "Flatiron",
            },
            {
                id: "bf",
                label: "bf",
            },
            {
                id: "xmjh",
                label: "xmjh",
            },
            {
                id: "j2m",
                label: "j2m",
            },
            {
                id: "jg",
                label: "jg",
            },
            {
                id: "--sanitized--",
                label: "--sanitized--",
            },
            {
                id: "pub",
                label: "pub",
            },
            {
                id: "50",
                label: "CitadelMarketingGroup",
            },
            {
                id: "121",
                label: "FacebookOrganic",
            },
            {
                id: "xjtm",
                label: "xjtm",
            },
            {
                id: "apptness",
                label: "apptness",
            },
            {
                id: "use",
                label: "use",
            },
            {
                id: "attb",
                label: "attb",
            },
            {
                id: "79",
                label: "LocalJobSeeker.com",
            },
            {
                id: "quora",
                label: "quora",
            },
            {
                id: "32",
                label: "TurtleLeads",
            },
            {
                id: "vicero",
                label: "vicero",
            },
            {
                id: "upward,reticular",
                label: "upward,reticular",
            },
            {
                id: "id",
                label: "id",
            },
            {
                id: "xappcast",
                label: "xappcast",
            },
            {
                id: "gs://cr-jobs-xmlfeed/feeds/upstream/",
                label: "gs://cr-jobs-xmlfeed/feeds/upstream/",
            },
            {
                id: "google,https://localjobsinc.com",
                label: "google,https://localjobsinc.com",
            },
            {
                id: "toboola",
                label: "toboola",
            },
            {
                id: "144,amazon",
                label: "144,amazon",
            },
            {
                id: "jobsinswhyamigettingbadgasmileager",
                label: "jobsinswhyamigettingbadgasmileager",
            },
            {
                id: 88,
                label: "CreekviewDigital,LLC",
            },
            {
                id: "mgemail",
                label: "mgemail",
            },
            {
                id: "abc",
                label: "abc",
            },
            {
                id: "fbcomments",
                label: "fbcomments",
            },
            {
                id: "{{osource}}",
                label: "{{osource}}",
            },
            {
                id: "cmsms",
                label: "cmsms",
            },
            {
                id: "bing,bing",
                label: "bing,bing",
            },
            {
                id: "mnpush",
                label: "mnpush",
            },
            {
                id: "xjadx",
                label: "xjadx",
            },
            {
                id: "homesite",
                label: "homesite",
            },
            {
                id: "InboxAlly",
                label: "InboxAlly",
            },
            {
                id: "2sms",
                label: "2sms",
            },
            {
                id: "twoclickcmsms",
                label: "twoclickcmsms",
            },
            {
                id: "perform",
                label: "perform",
            },
            {
                id: "testqa",
                label: "testqa",
            },
            {
                id: "new_serp_neuvoo",
                label: "new_serp_neuvoo",
            },
            {
                id: "shk",
                label: "shk",
            },
            {
                id: "prasad",
                label: "prasad",
            },
            {
                id: "connexus-dynamic",
                label: "connexus-dynamic",
            },
            {
                id: "nocsms",
                label: "nocsms",
            },
            {
                id: "QATest123",
                label: "QATest123",
            },
            {
                id: "google,google",
                label: "google,google",
            },
            {
                id: "54",
                label: "ClicksoLLC",
            },
            {
                id: "70",
                label: "AvantoMedia",
            },
            {
                id: "digital%20moses",
                label: "digital%20moses",
            },
            {
                id: "twsms",
                label: "twsms",
            },
            {
                id: "10dlc",
                label: "10dlc",
            },
            {
                id: "talroo",
                label: "talroo",
            },
            {
                id: "new_serp_cmp",
                label: "new_serp_cmp",
            },
            {
                id: "xcb",
                label: "xcb",
            },
            {
                id: "53",
                label: "FRSTMedia",
            },
            {
                id: "QATest123",
                label: "qatest123",
            },
            {
                id: "inter",
                label: "inter",
            },
            {
                id: "newjobscanner",
                label: "newjobscanner",
            },
            {
                id: "bnsms",
                label: "bnsms",
            },
            {
                id: "pdmo2%2Cupward",
                label: "pdmo2%2Cupward",
            },
            {
                id: "undefined",
                label: "undefined",
            },
            {
                id: "talrootesting",
                label: "talrootesting",
            },
            {
                id: "goog/",
                label: "goog/",
            },
            {
                id: "talrootesting1",
                label: "talrootesting1",
            },
            {
                id: "63",
                label: "JobnamicLLC",
            },
            {
                id: "72",
                label: "clixflow.com",
            },
            {
                id: "124",
                label: "zappian.com",
            },
            {
                id: "tmsms",
                label: "tmsms",
            },
            {
                id: "mjc",
                label: "mjc",
            },
            {
                id: "161",
                label: "mobtraction.com",
            },
            {
                id: "email",
                label: "email",
            },
            {
                id: "check",
                label: "check",
            },
            {
                id: "164",
                label: "TrafficCompany",
            },
            {
                id: "145",
                label: "Tagadamedia",
            },
            {
                id: "167",
                label: "SparkRevenue",
            },
            {
                id: "111",
                label: "Shift44(Sweeps)",
            },
            {
                id: "115",
                label: "PushnamiLLC(Sweeps)",
            },
            {
                id: "126",
                label: "Point2web",
            },
            {
                id: "177",
                label: "PInchme.comInc",
            },
            {
                id: "135",
                label: "MediaDevoted1Aps",
            },
            {
                id: "102",
                label: "MaderaDigital(Sweeps)",
            },
            {
                id: "105",
                label: "LaunchPotato(Sweeps)",
            },
            {
                id: "149",
                label: "LEADID",
            },
            {
                id: "141",
                label: "Horizon2Media",
            },
            {
                id: "159",
                label: "GiraffeMediaGroup",
            },
            {
                id: "146",
                label: "Fluent,LLC(Sweeps/Promos)",
            },
            {
                id: "98",
                label: "Flatiron(Sweeps)",
            },
            {
                id: "122",
                label: "FizzylabsCorporation",
            },
            {
                id: "97",
                label: "DigitalMediaSolutionsGroup",
            },
            {
                id: "160",
                label: "DigiDumB.V.",
            },
            {
                id: "158",
                label: "Arroyomedia",
            },
            {
                id: "128",
                label: "AdMaven",
            },
            {
                id: "252",
                label: "252",
            },
            {
                id: "11",
                label: "www.nextclickpartners.com",
            },
            {
                id: "174",
                label: "tapp.co",
            },
            {
                id: "null",
                label: "null",
            },
            {
                id: "gcs",
                label: "gcs",
            },
            {
                id: "136",
                label: "VIPResponse",
            },
            {
                id: "82",
                label: "RealDealSweeps",
            },
            {
                id: "171",
                label: "RDS_Email",
            },
            {
                id: "QATest",
                label: "QAtest",
            },
            {
                id: "QATesst",
                label: "QATesst",
            },
            {
                id: "162",
                label: "MarketcallInc",
            },
            {
                id: "152",
                label: "LoudingAds",
            },
            {
                id: "165",
                label: "JvanHMediaB.V./LeadXchange",
            },
            {
                id: "168",
                label: "APPSTRENDINGMEDIAPVT.LTD.Brand",
            },
            {
                id: "22",
                label: "FreestyleMedia",
            },
        ],
        type: "multiSelectEditable",
        created_at: "2022-12-23T21:00:25.389Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "UTMSource",
        ranking: 1,
    },
    {
        id: 21,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "utm_campaign",
        opsName: "Pub Campaign",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelectEditable",
        created_at: "2022-12-23T21:00:25.399Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "UTMCampaign",
        ranking: 2,
    },
    {
        id: 27,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "job_type",
        opsName: "Employer Intent",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelectEditable",
        created_at: "2022-12-23T21:00:25.432Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "JobType",
        ranking: 3,
    },
    {
        id: 23,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "utm_term",
        opsName: "Search Intent",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelectEditable",
        created_at: "2022-12-23T21:00:25.407Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "UTMTerm",
        ranking: 4,
    },
    {
        id: 31,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isKeywordEqualsJobType",
        opsName: "Search Intent equals Employer Intent",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.448Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 5,
    },
    {
        id: 32,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isKeywordPresent",
        opsName: "Search Intent available",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.452Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 6,
    },
    {
        id: 33,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isLandingJTPresent",
        opsName: "Landing Employer Intent available",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.461Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 7,
    },
    {
        id: 34,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isUrlJtPresent",
        opsName: "URL Employer available",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.465Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 8,
    },
    {
        id: 35,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isKeywordEqualsBrand",
        opsName: "Search Intent equals employer name",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.469Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 9,
    },
    {
        id: 36,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isJobTypeEqualsBrand",
        opsName: "isJobTypeEqualsBrand",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.473Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 10,
    },
    {
        id: 37,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isNonDefaultJobTypePresent",
        opsName: "Employer Non Default Present",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.484Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 11,
    },
    {
        id: 38,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isPIIFound",
        opsName: "isPIIFound",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.488Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 12,
    },
    {
        id: 39,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isPIIReg2Found",
        opsName: "isPIIReg2Found",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.492Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 13,
    },
    {
        id: 9,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "user_status_current",
        opsName: "User Status - Path Start",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.275Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "UserStatusCurrent",
        ranking: 14,
    },
    {
        id: 18,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "utm_medium",
        opsName: "Traffic Medium",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelectEditable",
        created_at: "2022-12-23T21:00:25.372Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "UTMMedium",
        ranking: 15,
    },
    {
        id: 8,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "user_status",
        opsName: "User Status - Landing",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: "reg_same_device",
                label: "Registered: Same Browser-Device",
            },
            {
                id: "new",
                label: "New",
            },
            {
                id: "reg_cross_browser_device",
                label: "Registered: Cross Browser-Device",
            },
            {
                id: "subscribed",
                label: "Subscribed",
            },
            {
                id: "non_reg",
                label: "Non Registered",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.269Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "UserStatus",
        ranking: 16,
    },
    {
        id: 25,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "ljt",
        opsName: "Landing Employer Intent",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelectEditable",
        created_at: "2022-12-23T21:00:25.416Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "LandingJobType",
        ranking: 17,
    },
    {
        id: 24,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "lkw",
        opsName: "Landing Search Intent",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelectEditable",
        created_at: "2022-12-23T21:00:25.411Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "LandingKeyword",
        ranking: 18,
    },
    {
        id: 29,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "kwcat",
        opsName: "Search Intent Category (Non-XML)",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.440Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "KeywordCategory",
        ranking: 19,
    },
    {
        id: 30,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "TrafficCategory",
        opsName: "Search Intent Category (XML)",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: null,
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.444Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: null,
        ranking: 20,
    },
    {
        id: 1,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "gender",
        opsName: "Gender",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: 1,
                label: "Male",
            },
            {
                id: 2,
                label: "Female",
            },
            {
                id: 3,
                label: "Others",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T13:50:12.882Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "Gender",
        ranking: null,
    },
    {
        id: 2,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "country",
        opsName: "Country",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: "US",
                icon: "Country-USA",
                logo: true,
                label: "USA",
            },
            {
                id: "GB",
                icon: "Country-UnitedKingdom",
                logo: true,
                label: "UK",
            },
            {
                id: "IN",
                icon: "Country-India",
                logo: true,
                label: "India",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.221Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "Country",
        ranking: null,
    },
    {
        id: 3,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "state",
        opsName: "State",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: "AL",
                label: "Alabama",
            },
            {
                id: "AK",
                label: "Alaska",
            },
            {
                id: "AS",
                label: "AmericanSamoa",
            },
            {
                id: "AZ",
                label: "Arizona",
            },
            {
                id: "AR",
                label: "Arkansas",
            },
            {
                id: "CA",
                label: "California",
            },
            {
                id: "CO",
                label: "Colorado",
            },
            {
                id: "CT",
                label: "Connecticut",
            },
            {
                id: "DE",
                label: "Delaware",
            },
            {
                id: "DC",
                label: "DistrictOfColumbia",
            },
            {
                id: "FM",
                label: "FederatedStatesOfMicronesia",
            },
            {
                id: "FL",
                label: "Florida",
            },
            {
                id: "GA",
                label: "Georgia",
            },
            {
                id: "GU",
                label: "Guam",
            },
            {
                id: "HI",
                label: "Hawaii",
            },
            {
                id: "ID",
                label: "Idaho",
            },
            {
                id: "IL",
                label: "Illinois",
            },
            {
                id: "IN",
                label: "Indiana",
            },
            {
                id: "IA",
                label: "Iowa",
            },
            {
                id: "KS",
                label: "Kansas",
            },
            {
                id: "KY",
                label: "Kentucky",
            },
            {
                id: "LA",
                label: "Louisiana",
            },
            {
                id: "ME",
                label: "Maine",
            },
            {
                id: "MH",
                label: "MarshallIslands",
            },
            {
                id: "MD",
                label: "Maryland",
            },
            {
                id: "MA",
                label: "Massachusetts",
            },
            {
                id: "MI",
                label: "Michigan",
            },
            {
                id: "MN",
                label: "Minnesota",
            },
            {
                id: "MS",
                label: "Mississippi",
            },
            {
                id: "MO",
                label: "Missouri",
            },
            {
                id: "MT",
                label: "Montana",
            },
            {
                id: "NE",
                label: "Nebraska",
            },
            {
                id: "NV",
                label: "Nevada",
            },
            {
                id: "NH",
                label: "NewHampshire",
            },
            {
                id: "NJ",
                label: "NewJersey",
            },
            {
                id: "NM",
                label: "NewMexico",
            },
            {
                id: "NY",
                label: "NewYork",
            },
            {
                id: "NC",
                label: "NorthCarolina",
            },
            {
                id: "ND",
                label: "NorthDakota",
            },
            {
                id: "MP",
                label: "NorthernMarianaIslands",
            },
            {
                id: "OH",
                label: "Ohio",
            },
            {
                id: "OK",
                label: "Oklahoma",
            },
            {
                id: "OR",
                label: "Oregon",
            },
            {
                id: "PW",
                label: "Palau",
            },
            {
                id: "PA",
                label: "Pennsylvania",
            },
            {
                id: "PR",
                label: "PuertoRico",
            },
            {
                id: "RI",
                label: "RhodeIsland",
            },
            {
                id: "SC",
                label: "SouthCarolina",
            },
            {
                id: "SD",
                label: "SouthDakota",
            },
            {
                id: "TN",
                label: "Tennessee",
            },
            {
                id: "TX",
                label: "Texas",
            },
            {
                id: "UT",
                label: "Utah",
            },
            {
                id: "VT",
                label: "Vermont",
            },
            {
                id: "VI",
                label: "VirginIslands",
            },
            {
                id: "VA",
                label: "Virginia",
            },
            {
                id: "WA",
                label: "Washington",
            },
            {
                id: "WV",
                label: "WestVirginia",
            },
            {
                id: "WI",
                label: "Wisconsin",
            },
            {
                id: "WY",
                label: "Wyoming",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.232Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "State",
        ranking: null,
    },
    {
        id: 4,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "region",
        opsName: "Region",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: "AL",
                label: "Alabama",
            },
            {
                id: "AK",
                label: "Alaska",
            },
            {
                id: "AS",
                label: "AmericanSamoa",
            },
            {
                id: "AZ",
                label: "Arizona",
            },
            {
                id: "AR",
                label: "Arkansas",
            },
            {
                id: "CA",
                label: "California",
            },
            {
                id: "CO",
                label: "Colorado",
            },
            {
                id: "CT",
                label: "Connecticut",
            },
            {
                id: "DE",
                label: "Delaware",
            },
            {
                id: "DC",
                label: "DistrictOfColumbia",
            },
            {
                id: "FM",
                label: "FederatedStatesOfMicronesia",
            },
            {
                id: "FL",
                label: "Florida",
            },
            {
                id: "GA",
                label: "Georgia",
            },
            {
                id: "GU",
                label: "Guam",
            },
            {
                id: "HI",
                label: "Hawaii",
            },
            {
                id: "ID",
                label: "Idaho",
            },
            {
                id: "IL",
                label: "Illinois",
            },
            {
                id: "IN",
                label: "Indiana",
            },
            {
                id: "IA",
                label: "Iowa",
            },
            {
                id: "KS",
                label: "Kansas",
            },
            {
                id: "KY",
                label: "Kentucky",
            },
            {
                id: "LA",
                label: "Louisiana",
            },
            {
                id: "ME",
                label: "Maine",
            },
            {
                id: "MH",
                label: "MarshallIslands",
            },
            {
                id: "MD",
                label: "Maryland",
            },
            {
                id: "MA",
                label: "Massachusetts",
            },
            {
                id: "MI",
                label: "Michigan",
            },
            {
                id: "MN",
                label: "Minnesota",
            },
            {
                id: "MS",
                label: "Mississippi",
            },
            {
                id: "MO",
                label: "Missouri",
            },
            {
                id: "MT",
                label: "Montana",
            },
            {
                id: "NE",
                label: "Nebraska",
            },
            {
                id: "NV",
                label: "Nevada",
            },
            {
                id: "NH",
                label: "NewHampshire",
            },
            {
                id: "NJ",
                label: "NewJersey",
            },
            {
                id: "NM",
                label: "NewMexico",
            },
            {
                id: "NY",
                label: "NewYork",
            },
            {
                id: "NC",
                label: "NorthCarolina",
            },
            {
                id: "ND",
                label: "NorthDakota",
            },
            {
                id: "MP",
                label: "NorthernMarianaIslands",
            },
            {
                id: "OH",
                label: "Ohio",
            },
            {
                id: "OK",
                label: "Oklahoma",
            },
            {
                id: "OR",
                label: "Oregon",
            },
            {
                id: "PW",
                label: "Palau",
            },
            {
                id: "PA",
                label: "Pennsylvania",
            },
            {
                id: "PR",
                label: "PuertoRico",
            },
            {
                id: "RI",
                label: "RhodeIsland",
            },
            {
                id: "SC",
                label: "SouthCarolina",
            },
            {
                id: "SD",
                label: "SouthDakota",
            },
            {
                id: "TN",
                label: "Tennessee",
            },
            {
                id: "TX",
                label: "Texas",
            },
            {
                id: "UT",
                label: "Utah",
            },
            {
                id: "VT",
                label: "Vermont",
            },
            {
                id: "VI",
                label: "VirginIslands",
            },
            {
                id: "VA",
                label: "Virginia",
            },
            {
                id: "WA",
                label: "Washington",
            },
            {
                id: "WV",
                label: "WestVirginia",
            },
            {
                id: "WI",
                label: "Wisconsin",
            },
            {
                id: "WY",
                label: "Wyoming",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.240Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "Region",
        ranking: null,
    },
    {
        id: 5,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "city",
        opsName: "City",
        description: null,
        regularAccess: false,
        implemented: true,
        possibleValues: [
            {
                id: "NewYork",
                label: "NewYork",
            },
            {
                id: "LosAngeles",
                label: "LosAngeles",
            },
            {
                id: "Chicago",
                label: "Chicago",
            },
            {
                id: "Houston",
                label: "Houston",
            },
            {
                id: "Phoenix",
                label: "Phoenix",
            },
            {
                id: "Philadelphia",
                label: "Philadelphia",
            },
            {
                id: "SanAntonio",
                label: "SanAntonio",
            },
            {
                id: "SanDiego",
                label: "SanDiego",
            },
            {
                id: "Dallas",
                label: "Dallas",
            },
            {
                id: "SanJose",
                label: "SanJose",
            },
            {
                id: "Austin",
                label: "Austin",
            },
            {
                id: "Jacksonville",
                label: "Jacksonville",
            },
            {
                id: "FortWorth",
                label: "FortWorth",
            },
            {
                id: "Columbus",
                label: "Columbus",
            },
            {
                id: "Indianapolis",
                label: "Indianapolis",
            },
            {
                id: "Charlotte",
                label: "Charlotte",
            },
            {
                id: "SanFrancisco",
                label: "SanFrancisco",
            },
            {
                id: "Seattle",
                label: "Seattle",
            },
            {
                id: "Denver",
                label: "Denver",
            },
            {
                id: "OklahomaCity",
                label: "OklahomaCity",
            },
            {
                id: "Nashville",
                label: "Nashville",
            },
            {
                id: "ElPaso",
                label: "ElPaso",
            },
            {
                id: "Washington",
                label: "Washington",
            },
            {
                id: "Boston",
                label: "Boston",
            },
            {
                id: "LasVegas",
                label: "LasVegas",
            },
            {
                id: "Portland",
                label: "Portland",
            },
            {
                id: "Detroit",
                label: "Detroit",
            },
            {
                id: "Louisville",
                label: "Louisville",
            },
            {
                id: "Memphis",
                label: "Memphis",
            },
            {
                id: "Baltimore",
                label: "Baltimore",
            },
            {
                id: "Milwaukee",
                label: "Milwaukee",
            },
            {
                id: "Albuquerque",
                label: "Albuquerque",
            },
            {
                id: "Fresno",
                label: "Fresno",
            },
            {
                id: "Tucson",
                label: "Tucson",
            },
            {
                id: "Sacramento",
                label: "Sacramento",
            },
            {
                id: "Mesa",
                label: "Mesa",
            },
            {
                id: "KansasCity",
                label: "KansasCity",
            },
            {
                id: "Atlanta",
                label: "Atlanta",
            },
            {
                id: "Omaha",
                label: "Omaha",
            },
            {
                id: "ColoradoSprings",
                label: "ColoradoSprings",
            },
            {
                id: "Raleigh",
                label: "Raleigh",
            },
            {
                id: "VirginiaBeach",
                label: "VirginiaBeach",
            },
            {
                id: "LongBeach",
                label: "LongBeach",
            },
            {
                id: "Miami",
                label: "Miami",
            },
            {
                id: "Oakland",
                label: "Oakland",
            },
            {
                id: "Minneapolis",
                label: "Minneapolis",
            },
            {
                id: "Tulsa",
                label: "Tulsa",
            },
            {
                id: "Bakersfield",
                label: "Bakersfield",
            },
            {
                id: "Wichita",
                label: "Wichita",
            },
            {
                id: "Arlington",
                label: "Arlington",
            },
            {
                id: "Aurora",
                label: "Aurora",
            },
            {
                id: "Tampa",
                label: "Tampa",
            },
            {
                id: "NewOrleans",
                label: "NewOrleans",
            },
            {
                id: "Cleveland",
                label: "Cleveland",
            },
            {
                id: "Anaheim",
                label: "Anaheim",
            },
            {
                id: "Honolulu",
                label: "Honolulu",
            },
            {
                id: "Henderson",
                label: "Henderson",
            },
            {
                id: "Stockton",
                label: "Stockton",
            },
            {
                id: "Lexington",
                label: "Lexington",
            },
            {
                id: "CorpusChristi",
                label: "CorpusChristi",
            },
            {
                id: "Riverside",
                label: "Riverside",
            },
            {
                id: "SantaAna",
                label: "SantaAna",
            },
            {
                id: "Orlando",
                label: "Orlando",
            },
            {
                id: "Irvine",
                label: "Irvine",
            },
            {
                id: "Cincinnati",
                label: "Cincinnati",
            },
            {
                id: "Newark",
                label: "Newark",
            },
            {
                id: "SaintPaul",
                label: "SaintPaul",
            },
            {
                id: "Pittsburgh",
                label: "Pittsburgh",
            },
            {
                id: "Greensboro",
                label: "Greensboro",
            },
            {
                id: "St.Louis",
                label: "St.Louis",
            },
            {
                id: "Lincoln",
                label: "Lincoln",
            },
            {
                id: "Plano",
                label: "Plano",
            },
            {
                id: "Anchorage",
                label: "Anchorage",
            },
            {
                id: "Durham",
                label: "Durham",
            },
            {
                id: "JerseyCity",
                label: "JerseyCity",
            },
            {
                id: "Chandler",
                label: "Chandler",
            },
            {
                id: "ChulaVista",
                label: "ChulaVista",
            },
            {
                id: "Buffalo",
                label: "Buffalo",
            },
            {
                id: "NorthLasVegas",
                label: "NorthLasVegas",
            },
            {
                id: "Gilbert",
                label: "Gilbert",
            },
            {
                id: "Madison",
                label: "Madison",
            },
            {
                id: "Reno",
                label: "Reno",
            },
            {
                id: "Toledo",
                label: "Toledo",
            },
            {
                id: "FortWayne",
                label: "FortWayne",
            },
            {
                id: "Lubbock",
                label: "Lubbock",
            },
            {
                id: "St.Petersburg",
                label: "St.Petersburg",
            },
            {
                id: "Laredo",
                label: "Laredo",
            },
            {
                id: "Irving",
                label: "Irving",
            },
            {
                id: "Chesapeake",
                label: "Chesapeake",
            },
            {
                id: "Winston–Salem",
                label: "Winston–Salem",
            },
            {
                id: "Glendale",
                label: "Glendale",
            },
            {
                id: "Scottsdale",
                label: "Scottsdale",
            },
            {
                id: "Garland",
                label: "Garland",
            },
            {
                id: "Boise",
                label: "Boise",
            },
            {
                id: "Norfolk",
                label: "Norfolk",
            },
            {
                id: "Spokane",
                label: "Spokane",
            },
            {
                id: "Fremont",
                label: "Fremont",
            },
            {
                id: "Richmond",
                label: "Richmond",
            },
            {
                id: "SantaClarita",
                label: "SantaClarita",
            },
            {
                id: "SanBernardino",
                label: "SanBernardino",
            },
            {
                id: "BatonRouge",
                label: "BatonRouge",
            },
            {
                id: "Hialeah",
                label: "Hialeah",
            },
            {
                id: "Tacoma",
                label: "Tacoma",
            },
            {
                id: "Modesto",
                label: "Modesto",
            },
            {
                id: "PortSt.Lucie",
                label: "PortSt.Lucie",
            },
            {
                id: "Huntsville",
                label: "Huntsville",
            },
            {
                id: "DesMoines",
                label: "DesMoines",
            },
            {
                id: "MorenoValley",
                label: "MorenoValley",
            },
            {
                id: "Fontana",
                label: "Fontana",
            },
            {
                id: "Frisco",
                label: "Frisco",
            },
            {
                id: "Rochester",
                label: "Rochester",
            },
            {
                id: "Yonkers",
                label: "Yonkers",
            },
            {
                id: "Fayetteville",
                label: "Fayetteville",
            },
            {
                id: "Worcester",
                label: "Worcester",
            },
            {
                id: "Columbus",
                label: "Columbus",
            },
            {
                id: "CapeCoral",
                label: "CapeCoral",
            },
            {
                id: "McKinney",
                label: "McKinney",
            },
            {
                id: "LittleRock",
                label: "LittleRock",
            },
            {
                id: "Oxnard",
                label: "Oxnard",
            },
            {
                id: "Amarillo",
                label: "Amarillo",
            },
            {
                id: "Augusta",
                label: "Augusta",
            },
            {
                id: "SaltLakeCity",
                label: "SaltLakeCity",
            },
            {
                id: "Montgomery",
                label: "Montgomery",
            },
            {
                id: "Birmingham",
                label: "Birmingham",
            },
            {
                id: "GrandRapids",
                label: "GrandRapids",
            },
            {
                id: "GrandPrairie",
                label: "GrandPrairie",
            },
            {
                id: "OverlandPark",
                label: "OverlandPark",
            },
            {
                id: "Tallahassee",
                label: "Tallahassee",
            },
            {
                id: "HuntingtonBeach",
                label: "HuntingtonBeach",
            },
            {
                id: "SiouxFalls",
                label: "SiouxFalls",
            },
            {
                id: "Peoria",
                label: "Peoria",
            },
            {
                id: "Knoxville",
                label: "Knoxville",
            },
            {
                id: "Glendale",
                label: "Glendale",
            },
            {
                id: "Vancouver",
                label: "Vancouver",
            },
            {
                id: "Providence",
                label: "Providence",
            },
            {
                id: "Akron",
                label: "Akron",
            },
            {
                id: "Brownsville",
                label: "Brownsville",
            },
            {
                id: "Mobile",
                label: "Mobile",
            },
            {
                id: "NewportNews",
                label: "NewportNews",
            },
            {
                id: "Tempe",
                label: "Tempe",
            },
            {
                id: "Shreveport",
                label: "Shreveport",
            },
            {
                id: "Chattanooga",
                label: "Chattanooga",
            },
            {
                id: "FortLauderdale",
                label: "FortLauderdale",
            },
            {
                id: "Aurora",
                label: "Aurora",
            },
            {
                id: "ElkGrove",
                label: "ElkGrove",
            },
            {
                id: "Ontario",
                label: "Ontario",
            },
            {
                id: "Salem",
                label: "Salem",
            },
            {
                id: "Cary",
                label: "Cary",
            },
            {
                id: "SantaRosa",
                label: "SantaRosa",
            },
            {
                id: "RanchoCucamonga",
                label: "RanchoCucamonga",
            },
            {
                id: "Eugene",
                label: "Eugene",
            },
            {
                id: "Oceanside",
                label: "Oceanside",
            },
            {
                id: "Clarksville",
                label: "Clarksville",
            },
            {
                id: "GardenGrove",
                label: "GardenGrove",
            },
            {
                id: "Lancaster",
                label: "Lancaster",
            },
            {
                id: "Springfield",
                label: "Springfield",
            },
            {
                id: "PembrokePines",
                label: "PembrokePines",
            },
            {
                id: "FortCollins",
                label: "FortCollins",
            },
            {
                id: "Palmdale",
                label: "Palmdale",
            },
            {
                id: "Salinas",
                label: "Salinas",
            },
            {
                id: "Hayward",
                label: "Hayward",
            },
            {
                id: "Corona",
                label: "Corona",
            },
            {
                id: "Paterson",
                label: "Paterson",
            },
            {
                id: "Murfreesboro",
                label: "Murfreesboro",
            },
            {
                id: "Macon",
                label: "Macon",
            },
            {
                id: "Lakewood",
                label: "Lakewood",
            },
            {
                id: "Killeen",
                label: "Killeen",
            },
            {
                id: "Springfield",
                label: "Springfield",
            },
            {
                id: "Alexandria",
                label: "Alexandria",
            },
            {
                id: "KansasCity",
                label: "KansasCity",
            },
            {
                id: "Sunnyvale",
                label: "Sunnyvale",
            },
            {
                id: "Hollywood",
                label: "Hollywood",
            },
            {
                id: "Roseville",
                label: "Roseville",
            },
            {
                id: "Charleston",
                label: "Charleston",
            },
            {
                id: "Escondido",
                label: "Escondido",
            },
            {
                id: "Joliet",
                label: "Joliet",
            },
            {
                id: "Jackson",
                label: "Jackson",
            },
            {
                id: "Bellevue",
                label: "Bellevue",
            },
            {
                id: "Surprise",
                label: "Surprise",
            },
            {
                id: "Naperville",
                label: "Naperville",
            },
            {
                id: "Pasadena",
                label: "Pasadena",
            },
            {
                id: "Pomona",
                label: "Pomona",
            },
            {
                id: "Bridgeport",
                label: "Bridgeport",
            },
            {
                id: "Denton",
                label: "Denton",
            },
            {
                id: "Rockford",
                label: "Rockford",
            },
            {
                id: "Mesquite",
                label: "Mesquite",
            },
            {
                id: "Savannah",
                label: "Savannah",
            },
            {
                id: "Syracuse",
                label: "Syracuse",
            },
            {
                id: "McAllen",
                label: "McAllen",
            },
            {
                id: "Torrance",
                label: "Torrance",
            },
            {
                id: "Olathe",
                label: "Olathe",
            },
            {
                id: "Visalia",
                label: "Visalia",
            },
            {
                id: "Thornton",
                label: "Thornton",
            },
            {
                id: "Fullerton",
                label: "Fullerton",
            },
            {
                id: "Gainesville",
                label: "Gainesville",
            },
            {
                id: "Waco",
                label: "Waco",
            },
            {
                id: "WestValleyCity",
                label: "WestValleyCity",
            },
            {
                id: "Warren",
                label: "Warren",
            },
            {
                id: "Lakewood",
                label: "Lakewood",
            },
            {
                id: "Hampton",
                label: "Hampton",
            },
            {
                id: "Dayton",
                label: "Dayton",
            },
            {
                id: "Columbia",
                label: "Columbia",
            },
            {
                id: "Orange",
                label: "Orange",
            },
            {
                id: "CedarRapids",
                label: "CedarRapids",
            },
            {
                id: "Stamford",
                label: "Stamford",
            },
            {
                id: "Victorville",
                label: "Victorville",
            },
            {
                id: "Pasadena",
                label: "Pasadena",
            },
            {
                id: "Elizabeth",
                label: "Elizabeth",
            },
            {
                id: "NewHaven",
                label: "NewHaven",
            },
            {
                id: "Miramar",
                label: "Miramar",
            },
            {
                id: "Kent",
                label: "Kent",
            },
            {
                id: "SterlingHeights",
                label: "SterlingHeights",
            },
            {
                id: "Carrollton",
                label: "Carrollton",
            },
            {
                id: "CoralSprings",
                label: "CoralSprings",
            },
            {
                id: "Midland",
                label: "Midland",
            },
            {
                id: "Norman",
                label: "Norman",
            },
            {
                id: "Athens",
                label: "Athens",
            },
            {
                id: "SantaClara",
                label: "SantaClara",
            },
            {
                id: "Columbia",
                label: "Columbia",
            },
            {
                id: "Fargo",
                label: "Fargo",
            },
            {
                id: "Pearland",
                label: "Pearland",
            },
            {
                id: "SimiValley",
                label: "SimiValley",
            },
            {
                id: "Meridian",
                label: "Meridian",
            },
            {
                id: "Topeka",
                label: "Topeka",
            },
            {
                id: "Allentown",
                label: "Allentown",
            },
            {
                id: "ThousandOaks",
                label: "ThousandOaks",
            },
            {
                id: "Abilene",
                label: "Abilene",
            },
            {
                id: "Vallejo",
                label: "Vallejo",
            },
            {
                id: "Concord",
                label: "Concord",
            },
            {
                id: "RoundRock",
                label: "RoundRock",
            },
            {
                id: "Arvada",
                label: "Arvada",
            },
            {
                id: "Clovis",
                label: "Clovis",
            },
            {
                id: "PalmBay",
                label: "PalmBay",
            },
            {
                id: "Independence",
                label: "Independence",
            },
            {
                id: "Lafayette",
                label: "Lafayette",
            },
            {
                id: "AnnArbor",
                label: "AnnArbor",
            },
            {
                id: "Rochester",
                label: "Rochester",
            },
            {
                id: "Hartford",
                label: "Hartford",
            },
            {
                id: "CollegeStation",
                label: "CollegeStation",
            },
            {
                id: "Fairfield",
                label: "Fairfield",
            },
            {
                id: "Wilmington",
                label: "Wilmington",
            },
            {
                id: "NorthCharleston",
                label: "NorthCharleston",
            },
            {
                id: "Billings",
                label: "Billings",
            },
            {
                id: "WestPalmBeach",
                label: "WestPalmBeach",
            },
            {
                id: "Berkeley",
                label: "Berkeley",
            },
            {
                id: "Cambridge",
                label: "Cambridge",
            },
            {
                id: "Clearwater",
                label: "Clearwater",
            },
            {
                id: "WestJordan",
                label: "WestJordan",
            },
            {
                id: "Evansville",
                label: "Evansville",
            },
            {
                id: "Richardson",
                label: "Richardson",
            },
            {
                id: "BrokenArrow",
                label: "BrokenArrow",
            },
            {
                id: "Richmond",
                label: "Richmond",
            },
            {
                id: "LeagueCity",
                label: "LeagueCity",
            },
            {
                id: "Manchester",
                label: "Manchester",
            },
            {
                id: "Lakeland",
                label: "Lakeland",
            },
            {
                id: "Carlsbad",
                label: "Carlsbad",
            },
            {
                id: "Antioch",
                label: "Antioch",
            },
            {
                id: "Westminster",
                label: "Westminster",
            },
            {
                id: "HighPoint",
                label: "HighPoint",
            },
            {
                id: "Provo",
                label: "Provo",
            },
            {
                id: "Lowell",
                label: "Lowell",
            },
            {
                id: "Elgin",
                label: "Elgin",
            },
            {
                id: "Waterbury",
                label: "Waterbury",
            },
            {
                id: "Springfield",
                label: "Springfield",
            },
            {
                id: "Gresham",
                label: "Gresham",
            },
            {
                id: "Murrieta",
                label: "Murrieta",
            },
            {
                id: "Lewisville",
                label: "Lewisville",
            },
            {
                id: "LasCruces",
                label: "LasCruces",
            },
            {
                id: "Lansing",
                label: "Lansing",
            },
            {
                id: "Beaumont",
                label: "Beaumont",
            },
            {
                id: "Odessa",
                label: "Odessa",
            },
            {
                id: "Pueblo",
                label: "Pueblo",
            },
            {
                id: "Peoria",
                label: "Peoria",
            },
            {
                id: "Downey",
                label: "Downey",
            },
            {
                id: "PompanoBeach",
                label: "PompanoBeach",
            },
            {
                id: "MiamiGardens",
                label: "MiamiGardens",
            },
            {
                id: "Temecula",
                label: "Temecula",
            },
            {
                id: "Everett",
                label: "Everett",
            },
            {
                id: "CostaMesa",
                label: "CostaMesa",
            },
            {
                id: "Ventura",
                label: "Ventura",
            },
            {
                id: "Sparks",
                label: "Sparks",
            },
            {
                id: "SantaMaria",
                label: "SantaMaria",
            },
            {
                id: "SugarLand",
                label: "SugarLand",
            },
            {
                id: "Greeley",
                label: "Greeley",
            },
            {
                id: "SouthFulton",
                label: "SouthFulton",
            },
            {
                id: "Dearborn",
                label: "Dearborn",
            },
            {
                id: "Concord",
                label: "Concord",
            },
            {
                id: "Edison",
                label: "Edison",
            },
            {
                id: "Tyler",
                label: "Tyler",
            },
            {
                id: "SandySprings",
                label: "SandySprings",
            },
            {
                id: "WestCovina",
                label: "WestCovina",
            },
            {
                id: "GreenBay",
                label: "GreenBay",
            },
            {
                id: "Centennial",
                label: "Centennial",
            },
            {
                id: "JurupaValley",
                label: "JurupaValley",
            },
            {
                id: "ElMonte",
                label: "ElMonte",
            },
            {
                id: "Allen",
                label: "Allen",
            },
            {
                id: "Hillsboro",
                label: "Hillsboro",
            },
            {
                id: "Menifee",
                label: "Menifee",
            },
            {
                id: "Nampa",
                label: "Nampa",
            },
            {
                id: "SpokaneValley",
                label: "SpokaneValley",
            },
            {
                id: "RioRancho",
                label: "RioRancho",
            },
            {
                id: "Brockton",
                label: "Brockton",
            },
            {
                id: "ElCajon",
                label: "ElCajon",
            },
            {
                id: "Burbank",
                label: "Burbank",
            },
            {
                id: "Inglewood",
                label: "Inglewood",
            },
            {
                id: "Renton",
                label: "Renton",
            },
            {
                id: "Davie",
                label: "Davie",
            },
            {
                id: "Rialto",
                label: "Rialto",
            },
            {
                id: "Boulder",
                label: "Boulder",
            },
            {
                id: "SouthBend",
                label: "SouthBend",
            },
            {
                id: "Woodbridge",
                label: "Woodbridge",
            },
            {
                id: "Vacaville",
                label: "Vacaville",
            },
            {
                id: "WichitaFalls",
                label: "WichitaFalls",
            },
            {
                id: "Lee's Summit",
                label: "Lee's Summit",
            },
            {
                id: "Edinburg",
                label: "Edinburg",
            },
            {
                id: "Chico",
                label: "Chico",
            },
            {
                id: "SanMateo",
                label: "SanMateo",
            },
            {
                id: "Bend",
                label: "Bend",
            },
            {
                id: "Goodyear",
                label: "Goodyear",
            },
            {
                id: "Buckeye",
                label: "Buckeye",
            },
            {
                id: "DalyCity",
                label: "DalyCity",
            },
            {
                id: "Fishers",
                label: "Fishers",
            },
            {
                id: "Quincy",
                label: "Quincy",
            },
            {
                id: "Davenport",
                label: "Davenport",
            },
            {
                id: "Hesperia",
                label: "Hesperia",
            },
            {
                id: "NewBedford",
                label: "NewBedford",
            },
            {
                id: "Lynn",
                label: "Lynn",
            },
            {
                id: "Carmel",
                label: "Carmel",
            },
            {
                id: "Longmont",
                label: "Longmont",
            },
            {
                id: "Tuscaloosa",
                label: "Tuscaloosa",
            },
            {
                id: "Norwalk",
                label: "Norwalk",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.250Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "City",
        ranking: null,
    },
    {
        id: 6,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "zip",
        opsName: "Zip",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: null,
        type: "numberUpload",
        created_at: "2022-12-23T21:00:25.260Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "ZipCode",
        ranking: null,
    },
    {
        id: 7,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "age",
        opsName: "Age Group",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [18, 100],
        type: "range",
        created_at: "2022-12-23T21:00:25.265Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "AgeRange",
        ranking: null,
    },
    {
        id: 10,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "visitCount",
        opsName: "Visit Count",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [0, 100],
        type: "integer",
        created_at: "2022-12-23T21:00:25.279Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "VisitCount",
        ranking: null,
    },
    {
        id: 11,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "visit_count_post_reg",
        opsName: "Visit Count since registration",
        description: null,
        regularAccess: false,
        implemented: false,
        possibleValues: [0, 100],
        type: "integer",
        created_at: "2022-12-23T21:00:25.295Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "VisitCountAfterRange",
        ranking: null,
    },
    {
        id: 12,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "userAge",
        opsName: "User Age",
        description: null,
        regularAccess: false,
        implemented: true,
        possibleValues: null,
        type: "integer",
        created_at: "2022-12-23T21:00:25.303Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "UserAge",
        ranking: null,
    },
    {
        id: 13,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "browsers",
        opsName: "Browser",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: "SamsungBrowser",
                icon: "Browser-Samsung",
                logo: true,
                label: "SamsungBrowser",
            },
            {
                id: "Chrome",
                icon: "Browser-Chrome",
                logo: true,
                label: "Chrome",
            },
            {
                id: "GoogleSearchApp",
                icon: "Browser-GoogleSearch",
                logo: true,
                label: "GoogleSearchApp",
            },
            {
                id: "MicrosoftEdge",
                icon: "Browser-MicrosoftEdge",
                logo: true,
                label: "MicrosoftEdge",
            },
            {
                id: "Safari",
                icon: "Browser-Safari",
                logo: true,
                label: "Safari",
            },
            {
                id: "Facebook",
                icon: "Browser-Facebook",
                logo: true,
                label: "Facebook",
            },
            {
                id: "ChromeWebview",
                icon: "Browser-ChromeWebview",
                logo: true,
                label: "ChromeWebview",
            },
            {
                id: "Firefox",
                icon: "Browser-Firefox",
                logo: true,
                label: "Firefox",
            },
            {
                id: "DuckDuckGoPrivacyBrowser",
                icon: "Browser-DuckDuckGo",
                logo: true,
                label: "DuckDuckGoPrivacyBrowser",
            },
            {
                id: "InstagramApp",
                icon: "Browser-Instagram",
                logo: true,
                label: "InstagramApp",
            },
            {
                id: "HeadlessChrome",
                icon: "Browser-HeadlessChrome",
                logo: true,
                label: "HeadlessChrome",
            },
            {
                id: "Pinterest",
                icon: "Browser-Pinterest",
                logo: true,
                label: "Pinterest",
            },
            {
                id: "Opera",
                icon: "Browser-Opera",
                logo: true,
                label: "Opera",
            },
            {
                id: "MIUIBrowser",
                icon: "Browser-MIUIBroswer",
                logo: true,
                label: "MIUIBrowser",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.321Z",
        updated_at: "2022-12-23T21:00:25.321Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 14,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "devices",
        opsName: "Device",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: "smartphone",
                icon: "Phone",
                label: "Smartphone",
            },
            {
                id: "desktop",
                icon: "Desktop",
                label: "Desktop",
            },
            {
                id: "phablet",
                icon: "Phablet",
                label: "Phablet",
            },
            {
                id: "tablet",
                icon: "Tablet",
                label: "Tablet",
            },
            {
                id: "portablemediaplayer",
                icon: "MusicPlayer",
                label: "PortableMediaPlayer",
            },
            {
                id: "television",
                icon: "TV",
                label: "Television",
            },
            {
                id: "console",
                icon: "Console",
                label: "Console",
            },
            {
                id: "featurephone",
                icon: "FeaturePhone",
                label: "FeaturePhone",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.342Z",
        updated_at: "2022-12-23T21:00:25.342Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 15,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "evs",
        opsName: "Email Verified",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.356Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "Email",
        ranking: null,
    },
    {
        id: 16,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "pvs",
        opsName: "Phone Verified",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.361Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "Phone1",
        ranking: null,
    },
    {
        id: 17,
        uiCategory: "User Attribute",
        category: "User Property",
        name: "os",
        opsName: "Operating System",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: "Android",
                icon: "OS-Android",
                logo: true,
                label: "Android",
            },
            {
                id: "iOS",
                icon: "OS-IOS",
                logo: true,
                label: "iOS",
                whiteIcon: "OS-IOSWhite",
            },
            {
                id: "Windows",
                icon: "OS-Windows",
                logo: true,
                label: "Windows",
            },
            {
                id: "Mac",
                icon: "OS-Apple",
                logo: true,
                label: "Mac",
                whiteIcon: "OS-AppleWhite",
            },
            {
                id: "ChromeOS",
                icon: "OS-Chrome",
                logo: true,
                label: "ChromeOS",
            },
            {
                id: "Ubuntu",
                icon: "OS-Ubuntu",
                logo: true,
                label: "Ubuntu",
            },
            {
                id: "GNU/Linux",
                icon: "OS-Linux",
                logo: true,
                label: "GNU/Linux",
            },
            {
                id: "KaiOS",
                icon: "OS-kaios",
                logo: true,
                label: "KaiOS",
            },
            {
                id: "PlayStation",
                icon: "OS-PlayStation",
                logo: true,
                label: "PlayStation",
            },
            {
                id: "Tizen",
                icon: "OS-Tizen",
                logo: true,
                label: "Tizen",
            },
            {
                id: "WindowsPhone",
                icon: "OS-WindowsPhone",
                logo: true,
                label: "WindowsPhone",
            },
            {
                id: "Fedora",
                icon: "OS-Fedora",
                logo: true,
                label: "Fedora",
            },
            {
                id: "Symbian^3",
                icon: "OS-Symbian",
                logo: true,
                label: "Symbian^3",
                whiteIcon: "OS-SymbianWhite",
            },
            {
                id: "SymbianOSSeries60",
                icon: "OS-Series6",
                logo: true,
                label: "SymbianOSSeries60",
                whiteIcon: "OS-Series6White",
            },
            {
                id: "Solaris",
                icon: "OS-Solaris",
                logo: true,
                label: "Solaris",
                whiteIcon: "OS-SolarisWhite",
            },
            {
                id: "BlackBerryTabletOS",
                icon: "OS-BlackBerry",
                logo: true,
                label: "BlackBerryTabletOS",
                whiteIcon: "OS-BlackBerryWhite",
            },
            {
                id: "SailfishOS",
                icon: "OS-Sailfish",
                logo: true,
                label: "SailfishOS",
                whiteIcon: "OS-SailfishWhite",
            },
            {
                id: "NetBSD",
                icon: "OS-NetBSD",
                logo: true,
                label: "NetBSD",
            },
            {
                id: "FirefoxOS",
                icon: "Browser-Firefox",
                logo: true,
                label: "FirefoxOS",
            },
            {
                id: "Debian",
                icon: "OS-Debian",
                logo: true,
                label: "Debian",
                whiteIcon: "OS-DebianWhite",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.366Z",
        updated_at: "2022-12-23T21:00:25.366Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 19,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "domain",
        opsName: "Domain",
        description: null,
        regularAccess: false,
        implemented: true,
        possibleValues: null,
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.382Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "Domain",
        ranking: null,
    },
    {
        id: 22,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "ps",
        opsName: "Pub Sub Source",
        description: null,
        regularAccess: false,
        implemented: true,
        possibleValues: null,
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.403Z",
        updated_at: "2023-01-13T11:33:16.000Z",
        isActive: true,
        icon: "Publisher",
        ranking: null,
    },
    {
        id: 26,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "pub_source_type",
        opsName: "Pub Traffic Type",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: null,
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.421Z",
        updated_at: "2022-12-23T21:00:25.421Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 28,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "spam_flag",
        opsName: "Spam Flag",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: null,
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.436Z",
        updated_at: "2022-12-23T21:00:25.436Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 40,
        uiCategory: "User Attribute",
        category: "Time Property",
        name: "Week number",
        opsName: "Week number",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: null,
        type: "integer",
        created_at: "2022-12-23T21:00:25.497Z",
        updated_at: "2022-12-23T21:00:25.497Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 41,
        uiCategory: "User Attribute",
        category: "Time Property",
        name: "Day of Week",
        opsName: "Day of Week",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: "monday",
                label: "Monday",
            },
            {
                id: "tuesday",
                label: "Tuesday",
            },
            {
                id: "wednesday",
                label: "Wednesday",
            },
            {
                id: "thursday",
                label: "Thursday",
            },
            {
                id: "friday",
                label: "Friday",
            },
            {
                id: "saturday",
                label: "Saturday",
            },
            {
                id: "sunday",
                label: "Sunday",
            },
        ],
        type: "multiSelect",
        created_at: "2022-12-23T21:00:25.501Z",
        updated_at: "2022-12-23T21:00:25.501Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 42,
        uiCategory: "User Attribute",
        category: "Time Property",
        name: "Hour of day",
        opsName: "Hour of day",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [0, 23],
        type: "range",
        created_at: "2022-12-23T21:00:25.505Z",
        updated_at: "2022-12-23T21:00:25.505Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 50,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "isValidGiftCard",
        opsName: "isValidGiftCard",
        description: null,
        regularAccess: false,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2023-01-11T07:31:33.605Z",
        updated_at: "2023-01-13T11:13:14.000Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 51,
        uiCategory: "User Attribute",
        category: "Traffic Property",
        name: "ch",
        opsName: "Channel",
        description: null,
        regularAccess: false,
        implemented: false,
        possibleValues: null,
        type: "multiSelectEditable",
        created_at: "2023-01-11T10:25:41.020Z",
        updated_at: "2023-01-11T10:25:41.020Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 43,
        uiCategory: "User Behaviour",
        category: "Flow Property",
        name: "s2o",
        opsName: "Skip Button Click",
        description: null,
        regularAccess: true,
        implemented: true,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.508Z",
        updated_at: "2023-01-12T16:07:38.000Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 46,
        uiCategory: "User Behaviour",
        category: "Flow Property",
        name: "islrdr",
        opsName: "Back Button Traffic",
        description: null,
        regularAccess: true,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2022-12-23T21:00:25.520Z",
        updated_at: "2023-01-12T16:07:38.000Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 48,
        uiCategory: "User Behaviour",
        category: "Flow Property",
        name: "ans_2",
        opsName: "Survey Question + Answer",
        description: null,
        regularAccess: false,
        implemented: false,
        possibleValues: null,
        type: "singleSelect",
        created_at: "2022-12-26T09:08:05.168Z",
        updated_at: "2023-01-13T11:45:37.000Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
    {
        id: 52,
        uiCategory: "User Behaviour",
        category: "User Property",
        name: "custom_back",
        opsName: "Custom Back",
        description: null,
        regularAccess: false,
        implemented: false,
        possibleValues: [
            {
                id: true,
                label: "Yes",
            },
            {
                id: false,
                label: "No",
            },
        ],
        type: "checkBox",
        created_at: "2023-01-23T10:21:37.950Z",
        updated_at: "2023-01-23T10:21:37.950Z",
        isActive: true,
        icon: null,
        ranking: null,
    },
]
    .filter(
        el => el?.type === "multiSelectEditable" || el?.type === "multiSelect"
    )
    ?.filter(el => !isEmpty(el?.possibleValues));
