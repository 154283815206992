import { checkDay, removeIgnoredItems } from "./globalUtils";
import { toastListActions } from "../store/toastList/toastList";
import _, { cloneDeep, get, isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { getFlowComparison } from "../Pages/CreateFlow/FlowUtils";
import { EntityType } from "../Shared/FlowRenderer/FlowRendererUtils";

export const getHistoryData = historyDataArray => {
    const sortedHistory = [...historyDataArray].sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
    });

    const groupingHistoryData = sortedHistory.reduce((groups, data) => {
        const date = data.date;
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(data);
        return groups;
    }, {});
    const modifiedHistoryData = Object.keys(groupingHistoryData).map(
        (date, groupIndex) => {
            return {
                date: checkDay(date),
                data: groupingHistoryData[date].map((_, index) => ({
                    historyId: uuidv4(),
                    isFirstLogItem: index === 0 && groupIndex === 0,
                    ..._,
                })),
            };
        }
    );

    return modifiedHistoryData;
};

export const findHistClass = (
    id = 0,
    deletedRules = [],
    addedRules = [],
    updatedRules = []
) => {
    if (deletedRules.includes(id.toString())) return "isDeleted";
    if (addedRules.includes(id.toString())) return "isAdded";
    if (updatedRules.includes(id.toString())) return "isUpdated";
};

export const historyDataAction = (
    historyListingsTable,
    obj,
    dispatch,
    store,
    flow = false,
    idField = "id"
) => {
    dispatch(store.setMainLoader(true));
    dispatch(historyListingsTable(obj[idField])).then(list => {
        const rawData = flow
            ? list?.payload?.map((item, i) => {
                  const {
                      deletedRules,
                      addedRules,
                      updatedRules,
                      parentsWithDeletedChildren,
                  } = getFlowComparison(
                      list?.payload[i + 1]?.data?.rules,
                      item?.data?.rules
                  );
                  return {
                      ...item,
                      data: {
                          ...item.data,
                          flow: item.data.flow,
                          rules: item.data.rules?.map(_ => {
                              const histClass = findHistClass(
                                  _.id,
                                  deletedRules,
                                  addedRules,
                                  updatedRules
                              );
                              return {
                                  ..._,
                                  histClass,
                                  ...(parentsWithDeletedChildren[_.id] &&
                                  (!_.isActive ||
                                      _.entityType === EntityType.FLOW_ROUTER)
                                      ? {
                                            nextSetId:
                                                parentsWithDeletedChildren[
                                                    _.id
                                                ],
                                        }
                                      : {}),
                                  ...(histClass === "isDeleted"
                                      ? { isActive: true }
                                      : {}),
                              };
                          }),
                          details: item.data.flow || item.data.site,
                          updatedAt: item.updatedAt,
                      },
                      date: new Date(item.updatedAt)?.toDateString(),
                  };
              })
            : list?.payload?.data?.map(item => ({
                  ...item,
                  date: new Date(item.updatedAt)?.toDateString(),
              }));
        if (rawData?.length > 0) {
            dispatch(store.setHistoryDataRaw(rawData));
            dispatch(store.setHistoryData(getHistoryData(rawData)));
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `No change logs found with id ${obj.id}`,
                })
            );
        }
        dispatch(store.setMainLoader(false));
    });
};

export const getType = data => {
    if (data === null) return "null";
    if (typeof data === undefined) return "undefined";
    else if (Array.isArray(data)) return "array";
    else if (typeof data === "object") return "object";
    else if (typeof data === "string") return "string";
    else if (typeof data === "number") return "number";
    else if (typeof data === "boolean") return "boolean";
};

export const getComparisonData = (
    oldData,
    newData,
    config,
    overrideNewCheck = false //to show new value even if it is null or blank
) => {
    const updatedKeys = [];
    if (newData && oldData && config) {
        Object.keys(config.paths).forEach(path => {
            const newDataFromPath = removeIgnoredItems(
                get(newData, config.paths[path].id, ""),
                config.paths[path].ignoreList
            );
            const oldDataFromPath = removeIgnoredItems(
                get(oldData, config.paths[path].id, ""),
                config.paths[path].ignoreList
            );
            if (
                !isEqual(oldDataFromPath, newDataFromPath) &&
                (newDataFromPath ||
                    newDataFromPath === "" ||
                    newDataFromPath === null ||
                    newDataFromPath === false || //added this as false status were not rendered
                    newDataFromPath === 0 ||
                    (getType(newDataFromPath) === "array" &&
                        newDataFromPath?.length === 0) ||
                    overrideNewCheck ||
                    config.paths[path]?.overrideNewCheck)
            ) {
                if (overrideNewCheck || config.paths[path]?.overrideNewCheck) {
                    updatedKeys.push(config.paths[path]);
                } else if (
                    //shows new value only if it is valid
                    (getType(newDataFromPath) === "array" &&
                        newDataFromPath.length >= 0) ||
                    (getType(newDataFromPath) === "object" &&
                        Object.keys(newDataFromPath.length > 0)) ||
                    (getType(newDataFromPath) !== "array" &&
                        getType(newDataFromPath) !== "object")
                ) {
                    updatedKeys.push(config.paths[path]);
                }
            }
        });
        return [updatedKeys, newData, oldData];
    }
};

export const getTypeOfChange = (oldData, newData) => {
    if (
        oldData === null ||
        oldData === undefined ||
        oldData === "" ||
        (Array.isArray(oldData) && oldData.length === 0) ||
        (typeof oldData === "object" && Object.keys(oldData).length === 0) ||
        !oldData
    ) {
        return "Added";
    } else if (
        newData === null ||
        newData === undefined ||
        newData === "" ||
        (Array.isArray(newData) && newData.length === 0) ||
        (typeof newData === "object" && Object.keys(newData).length === 0) ||
        !newData
    ) {
        return "Removed";
    } else if (!!oldData && !!newData) {
        return "Updated";
    }
};
