import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SURVEYS } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import customFetch from "../../fetch/customFetch";
import { get, isEmpty } from "lodash";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    updatedAt: new Date(),
    createQuestion: {
        basics: {
            name: "",
            error: false,
        },
        questions: {
            questionList: [],
            addQuestion: true,
            error: false,
        },
        historyData: [],
        historyDataRaw: [],
    },
    createQuestionBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.QUESTIONS.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.QUESTIONS.filterOperator,
        filtersOperator: FILTERS.QUESTIONS.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "questions"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const historyListingsTable = createAsyncThunk(
    "listings/questions/historyAdUnitListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${SURVEYS.FETCH_HISTORY_LISTINGS_QUESTIONS}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchQuestionData = createAsyncThunk(
    "surveyDetails/questions/questionData",
    async (id, { dispatch }) => {
        const response = await customFetch(`${SURVEYS.GET_SEGMENTS}/${id}`);
        const data = get(response, "data.data", {});
        if (isEmpty(data))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch segment!",
                })
            );
        return data;
    }
);

export const fetchQuestionsData = createAsyncThunk(
    "listings/questions/fetchQuestionsListingData",
    async (refreshClicked, { dispatch, getState }) => {
        const { listings } = getState();
        const state = get(listings, "questions.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "questions.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.QUESTIONS
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(questionsSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.questions.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.QUESTIONS,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.questions.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                SURVEYS.FETCH_SEGMENTS,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch segments",
                        })
                    );
                    dispatch(
                        questionsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(questionsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.QUESTIONS}`;
            dispatch(questionsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Question`;
            dispatch(questionsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const createQuestions = createAsyncThunk(
    "surveyDetails/questions/create",
    async (params, { dispatch }) => {
        const response = await customFetch(
            SURVEYS.CREATE_SEGMENT,
            "POST",
            params
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to create the segment! Please try again later!",
                })
            );
        return response;
    }
);

export const updateQuestions = createAsyncThunk(
    "surveyDetails/questions/edit",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.UPDATE_SEGMENT}/${get(params, "id", null)}`,
            "POST",
            get(params, "question", {})
        );
        const data = get(response, "status", []);
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to update the segment! Please try again later!",
                })
            );
        return response;
    }
);

export const deleteQuestion = createAsyncThunk(
    "surveyDetails/questions/delete",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.DELETE_SEGMENT}/${id}`,
            "DELETE"
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to delete the segment! Please try again later!",
                })
            );
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/questions/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.questions.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.SURVEY_PATH.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const questionsSlice = createSlice({
    name: "questions",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setCreateQuestionName(state, action) {
            state.createQuestion.basics.name = action.payload;
            state.createQuestion.basics.error = action.payload?.trim() == "";
        },
        addNewQuestion(state, action) {
            state.createQuestion.questions.questionList.push(action.payload);
        },
        setQuestionList(state, action) {
            state.createQuestion.questions.questionList = action.payload;
            // state.createQuestion.questions.error =
            //   typeof action.payload == "object" &&
            //   Array.isArray(action.payload) &&
            //   action.payload.length > 0;
        },
        setAddQuestion(state, action) {
            state.createQuestion.questions.addQuestion = action.payload;
        },
        setQuestionBasicError(state, action) {
            state.createQuestion.basics.error = action.payload;
        },
        setQuestionsError(state, action) {
            state.createQuestion.questions.error = action.payload;
        },
        resetQuestions(state) {
            state.createQuestion.basics.name = "";
            state.createQuestion.basics.error = false;
            state.createQuestion.questions.questionList = [];
            state.createQuestion.questions.error = false;
            state.createQuestionBackup = state.createQuestion;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setHistoryData(state, action) {
            state.createQuestion.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createQuestion.historyDataRaw = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setCreateQuestionBackup(state, action) {
            state.createQuestionBackup = action.payload;
        },
        // loading(state, action) {
        //   state.loading = action.payload;
        // },
        // setMainLoader(state, action) {
        //   state.mainLoader = action.payload;
        // },
    },
    extraReducers: builder => {
        builder.addCase(fetchQuestionsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchQuestionsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const questionsActions = questionsSlice.actions;
export default questionsSlice.reducer;
