export const fontSizeInWords = [
    {
        className: "x-small",
        fontSize: 10,
        displayText: "X-Small",
    },
    {
        className: "small",
        fontSize: 12,
        displayText: "Small",
    },
    {
        className: "medium",
        fontSize: 14,
        displayText: "Medium",
    },
    {
        className: "large",
        fontSize: 16,
        displayText: "Large",
    },
    {
        className: "x-large",
        fontSize: 18,
        displayText: "X-Large",
    },
    {
        className: "xx-large",
        fontSize: 20,
        displayText: "XX-Large",
    },
];

export const fontSizes = [
    {
        fontSize: 8,
        displayText: "8",
    },
    {
        fontSize: 9,
        displayText: "9",
    },
    {
        fontSize: 10,
        displayText: "10",
    },
    {
        fontSize: 11,
        displayText: "11",
    },
    {
        fontSize: 12,
        displayText: "12",
    },
    {
        fontSize: 14,
        displayText: "14",
    },
    {
        fontSize: 16,
        displayText: "16",
    },
    {
        fontSize: 18,
        displayText: "18",
    },
    {
        fontSize: 24,
        displayText: "24",
    },
    {
        fontSize: 30,
        displayText: "30",
    },
    {
        fontSize: 36,
        displayText: "36",
    },
    {
        fontSize: 48,
        displayText: "48",
    },
    {
        fontSize: 60,
        displayText: "60",
    },
];
