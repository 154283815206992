import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { FEEDMANGER } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { firestore } from "../../firebase/config";
import {
    deliveryTypeDetails,
    getJobStatus,
    jobSourceOptions,
    jobSourcesExtras,
    publisherFeedParams,
    selectedJobSourceOptions,
    mapPlotSources,
} from "../../utils/feedManagerUtils";
import { toastListActions } from "../toastList/toastList";

const initialState = {
    loading: false,
    mainLoader: false,
    publisherName: "",
    feedType: "normal",
    publisherCode: "",
    deliveryTypes: [],
    everflowUrl: "",
    apiDetails: {},
    redirectURLOptions: [],
    bidCategoryOptions: [],
    bidCategoryExternalOptions: [],
    reDirectURL: "",
    jobSourceOptions: [],
    jobSourceOptionsArbitrage: [],
    selectedJobSourceKeys: [],
    feedUrl: "",
    jobStatus: "",
    jobSourcesTableData: [],
    bidToggle: false,
};

export const getAdvertiserSource = createAsyncThunk(
    "dashboards/feedmanager/getAdvertiserSource",
    async (_, { getState, dispatch }) => {
        const response = await customFetch(
            FEEDMANGER.GET_ALL_ADVERTISER_SOURCES,
            "GET",
            {}
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to load data! Please Refresh the page`,
                })
            );
            return null;
        }
        return get(response, "data.data", []);
    }
);

export const getFeedRedirectUrls = createAsyncThunk(
    "dashboards/feedmanager/getFeedRedirectUrls",
    async (_, { getState, dispatch }) => {
        const response = await firestore
            .collection("feed-redirect-urls")
            .get()
            .catch(e => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: `Failed to load data! Please Refresh the page`,
                    })
                );
                return null;
            });
        const data = [];
        response.forEach(_ => {
            data.push(_.data());
        });
        return data;
    }
);

export const getBidCategoryList = createAsyncThunk(
    "dashboards/feedmanager/getBidCategoryList",
    async (_, { getState, dispatch }) => {
        const response2 = await firestore
            .collection("feed-category")
            .get()
            .catch(e => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: `Failed to load data! Please Refresh the page`,
                    })
                );
                return null;
            });

        const optionForExternal = [];
        response2.forEach(_ => {
            optionForExternal.push(_.data());
        });

        const response = await customFetch(
            FEEDMANGER.GET_BID_CATEGORIES_OPTIONS,
            "GET",
            {}
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to load data! Please Refresh the page`,
                })
            );
            return null;
        }
        const data = [];
        get(response, "data.data", []).forEach(_ => {
            if (_.category != null) {
                data.push({
                    displayName: _.category,
                    id: _.category,
                });
            }
        });
        return [optionForExternal, data];
    }
);

export const createPublisherFeed = createAsyncThunk(
    "dashboards/feedmanager/createfeed",
    async (_, { getState, dispatch }) => {
        const params = publisherFeedParams(getState().dashboards.feedManager);
        const response = await customFetch(
            FEEDMANGER.CREATE_PUBLISHER_FEED,
            "POST",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to Create Publisher Feed`,
                })
            );
        }
        return get(response, "data.data", null);
    }
);

export const updatePublisherFeed = createAsyncThunk(
    "dashboards/feedmanager/updatefeed",
    async (id, { getState, dispatch }) => {
        const params = publisherFeedParams(getState().dashboards.feedManager);
        const response = await customFetch(
            `${FEEDMANGER.CREATE_PUBLISHER_FEED}/${id}`,
            "PUT",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to Update Publisher Feed`,
                })
            );
        }
        return get(response, "data.data", null);
    }
);

export const getPublisherFeed = createAsyncThunk(
    "dashboards/feedmanager/getPublisherfeed",
    async (id, { getState, dispatch }) => {
        const jobSourceOptionsDropdown =
            getState().dashboards.feedManager.jobSourceOptions;
        const response = await customFetch(
            `${FEEDMANGER.GET_PUBLISHER_FEED}/${id}`,
            "GET",
            {}
        );
        if (response.status > 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Fetch Publisher Feed",
                })
            );
            return null;
        }
        return [get(response, "data.data", {}), jobSourceOptionsDropdown];
    }
);

const feedManagerSlice = createSlice({
    name: "feedManager",
    initialState,
    reducers: {
        updateState(state, action) {
            return { ...state, [action.payload.field]: action.payload.data };
        },
        resetState(state) {
            return { ...initialState };
        },
    },
    extraReducers: builder => {
        builder.addCase(getAdvertiserSource.fulfilled, (state, action) => {
            if (action.payload) {
                const advertiserSources = action.payload.map(
                    (advertiser, index) => ({
                        ...jobSourcesExtras,
                        id: advertiser.id,
                        text: advertiser.name,
                        key: advertiser.name,
                        isFirstChild: index === 0,
                    })
                );
                return {
                    ...state,
                    jobSourceOptionsArbitrage: [
                        ...jobSourceOptions.filter(
                            element => element.key == "external"
                        ),
                        ...advertiserSources
                            .filter(element => element.key != "Predict Leads")
                            .map((element, index) => ({
                                ...element,
                                isFirstChild: index === 0,
                            })),
                    ],
                    jobSourceOptions: [
                        ...jobSourceOptions,
                        ...advertiserSources,
                    ],
                };
            }
        });
        builder.addCase(getFeedRedirectUrls.fulfilled, (state, action) => {
            if (action.payload && action.payload.length) {
                return {
                    ...state,
                    redirectURLOptions: action.payload,
                };
            }
        });
        builder.addCase(getBidCategoryList.fulfilled, (state, action) => {
            if (action.payload && action.payload.length) {
                return {
                    ...state,
                    bidCategoryExternalOptions: action.payload[1],
                    bidCategoryOptions: action.payload[0],
                };
            }
        });
        builder.addCase(getPublisherFeed.fulfilled, (state, action) => {
            if (action.payload[0]) {
                const { updatedJobOptions, selectedOptionKeys } =
                    selectedJobSourceOptions(
                        action.payload[0],
                        state.jobSourceOptions
                    );
                return {
                    ...state,
                    jobStatus: getJobStatus(action.payload[0].createdAt),
                    publisherName: action.payload[0].publisherName,
                    publisherCode: action.payload[0].publisherCode,
                    deliveryTypes: action.payload[0].deliveryTypes,
                    everflowUrl:
                        action.payload[0].everflowUrl == null
                            ? ""
                            : action.payload[0].everflowUrl,
                    feedUrl: action.payload[0].feedUrl
                        ? action.payload[0].feedUrl
                        : "",
                    jobSourceOptions: updatedJobOptions,
                    selectedJobSourceKeys: mapPlotSources(
                        action.payload[0].sources,
                        action.payload[1]
                    )[1],
                    apiDetails: deliveryTypeDetails(action.payload[0]),
                    jobSourcesTableData: mapPlotSources(
                        action.payload[0].sources,
                        action.payload[1]
                    )[0],
                    feedType: action.payload[0].type,
                    bidToggle: action.payload[0].useBid,
                    reDirectURL:
                        action.payload[0].redirectionUrl == null
                            ? ""
                            : action.payload[0].redirectionUrl,
                };
            }
        });
    },
});

export const feedManagerActions = feedManagerSlice.actions;
export default feedManagerSlice.reducer;
