import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import s from "./CustomTooltipRenderer.module.scss";

const CustomTooltipRenderer = ({
    data, //mandatory to show tooltip
    customRenderer: CustomRenderer,
    children,
    customClass,
    position = "left",
    showAfterCount = 0,
    handlePromptMouseLeave = false,
    closeOnClick = false,
    portalElement,
    customClientRect = null,
    topOffset = 0,
    leftOffset = 0,
}) => {
    const [showConfigDetails, setShowConfigDetails] = useState(false);
    const [configData, setConfigData] = useState(false);
    const [popupActive, setPopupActive] = useState(false);
    const [popupPosition, setPopupPosition] = useState(position);
    const ref = useRef(null);
    const { pathname } = useLocation();

    const handleMouseEnter = () => {
        if (data?.length > showAfterCount) {
            const rect = ref.current?.getBoundingClientRect();
            let width = rect.width;
            let topPos = rect?.top + 10;
            topPos = topOffset !== 0 ? topPos + topOffset : topPos;
            let rightPos = rect?.left + width;
            let bottomPos = window.innerHeight - rect?.bottom;
            let leftPos = bottomPos < 160 ? rect?.left + 50 : rect?.left;
            leftPos = leftOffset !== 0 ? leftPos + leftOffset : leftPos;

            bottomPos < 160
                ? setPopupPosition("top")
                : setPopupPosition(position);

            setConfigData({
                data: data,
                topPosition: customClientRect?.top || topPos,
                leftPosition:
                    customClientRect?.left - customClientRect?.width ||
                    (popupPosition === "right" ? leftPos + width : leftPos),
                rightPosition: rightPos,
                bottomPosition: bottomPos,
            });
            setShowConfigDetails(true);
        }
    };

    const handleMouseLeave = () => {
        if (!popupActive) {
            setShowConfigDetails(false);
        }
    };

    const getLinkClass = () => {
        return data?.length > showAfterCount ? "popUp_link" : "";
    };

    const getPositionStyles = () => {
        const styles = {};
        switch (popupPosition) {
            case "bottom":
                styles.bottom = configData.bottomPosition + "px";
                styles.left = configData.leftPosition + "px";
                break;
            case "topLeft":
                styles.top = configData.topPosition + "px";
                styles.left = configData.rightPosition + "px";
                break;
            default:
                styles.top = configData.topPosition + "px";
                styles.left = configData.leftPosition + "px";
                break;
        }
        return styles;
    };

    useEffect(() => {
        if (handlePromptMouseLeave && pathname) {
            if (pathname.includes("listings")) {
                setPopupActive(false);
                setShowConfigDetails(false);
            }
        }
    }, [pathname]);

    return (
        <div
            ref={ref}
            className={`${getLinkClass()} ${
                showConfigDetails ? "popUp_linkActive" : ""
            } ${customClass ? customClass : ""}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {showConfigDetails &&
                ReactDOM.createPortal(
                    <div
                        className={`${s.wrapper} ${s[popupPosition]} pointer-all fadeIn`}
                        style={getPositionStyles()}
                        onMouseEnter={() => {
                            setPopupActive(true);
                        }}
                        onMouseLeave={() => {
                            setPopupActive(false);
                            setShowConfigDetails(false);
                        }}
                        onMouseUp={e => {
                            e.stopPropagation();
                        }}
                        onClick={e => {
                            if (closeOnClick) {
                                setPopupActive(false);
                                setShowConfigDetails(false);
                            }
                            e.stopPropagation();
                        }}
                    >
                        <CustomRenderer />
                    </div>,
                    portalElement || document.body
                )}
        </div>
    );
};

export default CustomTooltipRenderer;
