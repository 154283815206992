import styles from "./PopupTargettingParams.module.scss";

import Button, { ButtonTypes } from "../../Shared/Button/Button";

const PopupTargettingParams = ({
    setName,
    Rfunction,
    setShowPopup,
    nameOnPopUp,
    actionText = "delete",
    customText,
    readOnly,
}) => {
    const goAhead = () => {
        if (typeof Rfunction === "function") Rfunction(setName);
        if (typeof setShowPopup === "function") setShowPopup(false);
    };

    return (
        <>
            <div className={styles.PopupTargettingParams_wrapper}>
                <div className={styles.textWrapper}>
                    {customText ? (
                        customText
                    ) : (
                        <p>
                            Do you wish to {actionText}{" "}
                            <span>{nameOnPopUp}</span> param?
                        </p>
                    )}
                </div>
                <div className={`${styles.popupBtnWrapper} dp-parent`}>
                    <Button
                        className={styles.button}
                        btnTheme={ButtonTypes.default_btn}
                        onClick={() => {
                            setShowPopup(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={styles.button}
                        btnTheme={ButtonTypes.primaryHover_btn}
                        onClick={() => {
                            goAhead();
                        }}
                        disable={readOnly}
                    >
                        Yes, Go ahead
                    </Button>
                </div>
            </div>
        </>
    );
};

export default PopupTargettingParams;
