import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import styles from "./FlowTcpa.module.scss";
import Icon from "../../Shared/Icon/Icon";
import ToggleValueSwitch from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import RichTextBox from "../../Shared/RichTextBox/RichTextBox";
import Select from "../../Shared/Select/Select";
import {
    INTENT_DROPDOWN,
    VISIBILITY_DROPDOWN,
    POSITION_DROPDOWN,
} from "../../constants/collectionConstants";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import InputList from "../InputList/InputList";
import { isEqual } from "lodash";

const FlowTcpa = ({
    checkboxControl,
    tcpaData,
    isReadOnly,
    onChange,
    onDelete,
    emailPartnerData = [],
    phonePartnerData = [],
    templates = [],
    showIntent = false,
    showVisibility = false,
    showPartners = false,
    editorCustomOptions = {
        macro: true,
        partners: true,
        skip: true,
    },
    intentMandatory = false,
    customIntentOptions = null, //if this list is present then intent is considered as mandatory, this can be changed later via new prop as per need
    allowGlobalSelection = false,
    showPosition = false,
}) => {
    const dispatch = useDispatch();
    const defaultTcpaObj = {
        html: "",
        jornaya: true,
        trustedform: true,
        implicit: true,
        required: false,
        isPreselected: false,
        intent: [],
        visibility: ["onLoad"],
        marketingPartners: [],
    };
    const [tcpaLocalOptions, setTcpaLocalOptions] = useState(tcpaData);
    const [readView, setReadView] = useState(false);
    const [deleteTcpa, showDeleteTcpa] = useState(false);
    const [templateList, setTemplateList] = useState(templates);
    const [partnerDataList, setPartnerDataList] = useState([]);
    const [selectedPartners, setSelectedPartners] = useState([]);

    const [csvFileName, setcsvFileName] = useState("");
    const [csvArray, setcsvArray] = useState([]);
    const [csvFile, setcsvFile] = useState("");
    const [csvError, setcsvError] = useState(false);

    const textEditor = useRef();

    useEffect(() => {
        if (!isEqual(tcpaData, tcpaLocalOptions)) {
            onChange(tcpaLocalOptions);
        }
    }, [tcpaLocalOptions]);

    useEffect(async () => {
        if (tcpaLocalOptions?.intent?.length > 0) {
            await handlePartnerList(tcpaLocalOptions.intent);
        }
    }, [tcpaLocalOptions.intent, phonePartnerData, emailPartnerData]);

    useEffect(() => {
        if (
            tcpaLocalOptions?.intent?.includes("email") ||
            tcpaLocalOptions?.intent?.includes("mobileNo")
        ) {
            setSelectedPartners(
                partnerDataList.length > 0
                    ? tcpaLocalOptions.marketingPartners
                    : []
            );
        } else {
            setSelectedPartners(tcpaLocalOptions.marketingPartners || []);
        }
    }, [partnerDataList, tcpaLocalOptions.marketingPartners]);

    const handlePartnerList = selectedItems => {
        if (
            selectedItems?.includes("email") &&
            selectedItems?.includes("mobileNo")
        ) {
            setPartnerDataList([...emailPartnerData, ...phonePartnerData]);
            return;
        }
        if (selectedItems?.includes("mobileNo")) {
            setPartnerDataList(phonePartnerData);
            filterSelectedPartners(
                phonePartnerData,
                emailPartnerData,
                selectedItems
            );
            return;
        }
        if (selectedItems?.includes("email")) {
            setPartnerDataList(emailPartnerData);
            filterSelectedPartners(
                emailPartnerData,
                phonePartnerData,
                selectedItems
            );
            return;
        }
        if (selectedItems?.join(", ")?.includes("simpleOptin")) {
            //also handles for simpleOptin1 and simpleOptin2
            setPartnerDataList([]);
            filterSelectedPartners(
                [],
                [...phonePartnerData, ...emailPartnerData],
                selectedItems,
                true
            );
            return;
        }
        setPartnerDataList([]);
        setTcpaLocalOptions({
            ...tcpaLocalOptions,
            marketingPartners: [],
        });
    };

    const filterSelectedPartners = (
        partnerArray,
        discardPartnerArray,
        intent,
        discardAllPartnerArray = false
    ) => {
        const avoidTemp = discardPartnerArray.map(_ => _.label);
        let temp = [];
        if (!discardAllPartnerArray) {
            temp = partnerArray.map(_ => _.label);
        } else {
            temp = [];
        }

        setTcpaLocalOptions({
            ...tcpaLocalOptions,
            marketingPartners: tcpaLocalOptions?.marketingPartners?.filter(
                item => temp.includes(item) || !avoidTemp.includes(item)
            ),
            intent: intent,
        });

        setcsvFileName("");
        setcsvArray([]);
        setcsvFile("");
        setcsvError(false);
    };

    return (
        <div
            className={`${styles.tcpaBox} ${
                isReadOnly || readView ? styles.tcpaBox__readOnly : ""
            } ${styles.internalSections} flowTcpaBox`}
        >
            {allowGlobalSelection && (
                <div
                    className={
                        tcpaLocalOptions.useGlobal ? "m-20" : "m-20  mb-0"
                    }
                >
                    <ToggleValueSwitch
                        onChange={responseType => {
                            setTcpaLocalOptions({
                                ...defaultTcpaObj,
                                useGlobal: responseType,
                                intent: [],
                                tempId: tcpaLocalOptions.tempId,
                            });
                        }}
                        leftLabel={"Custom"}
                        rightLabel={"Global"}
                        toggleState={tcpaLocalOptions.useGlobal}
                        isDisabled={isReadOnly}
                    ></ToggleValueSwitch>
                </div>
            )}
            {!isReadOnly && (
                <div
                    className={`circleIconColor circleIconColor--delete ${styles.deleteIcon}`}
                    onClick={() =>
                        tcpaData.id ? showDeleteTcpa(true) : onDelete()
                    }
                >
                    <Icon icon="Delete" size="16px" enableHover={true} />
                </div>
            )}
            {deleteTcpa && (
                <div className={styles.deletePopup}>
                    <PopupTargettingParams
                        setName={tcpaData}
                        Rfunction={onDelete}
                        setShowPopup={showDeleteTcpa}
                        customText={`Do you wish to delete this TCPA?`}
                    />
                </div>
            )}
            {!tcpaLocalOptions.useGlobal && (
                <div className={`${styles.textEditor} mb-20`}>
                    <div className="dp-parent dp-parent-ver-center dp-parent-hor-space-between ">
                        <div className={styles.key}>
                            Text* {readView && ":"}
                        </div>
                    </div>
                    {isReadOnly || readView ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: tcpaLocalOptions.html,
                            }}
                        ></div>
                    ) : (
                        <RichTextBox
                            htmlContent={
                                tcpaLocalOptions?.html
                                    ? tcpaLocalOptions?.html
                                    : ""
                            }
                            ref={textEditor}
                            customOnBlur={data =>
                                data
                                    ? setTcpaLocalOptions({
                                          ...tcpaLocalOptions,
                                          html: data,
                                      })
                                    : setTcpaLocalOptions({
                                          ...tcpaLocalOptions,
                                          html: "",
                                      })
                            }
                            customOptions={editorCustomOptions}
                        />
                    )}
                </div>
            )}
            <div
                className={`dp-parent dp-parent-hor-space-between flex-wrap gap-20 ${styles.options}`}
            >
                {!tcpaLocalOptions.useGlobal && checkboxControl && (
                    <div className="dp-parent w-100 gap-20">
                        <div
                            className={`dp-parent dp-parent-col ${styles.tcpaBox__optin}`}
                        >
                            <div className={styles.key}>
                                Opt-In Type {readView && ":"}
                            </div>
                            <div
                                className={`${styles.value} dp-parent flex-wrap`}
                            >
                                {isReadOnly || readView ? (
                                    <p>
                                        {tcpaLocalOptions.implicit
                                            ? "Implicit"
                                            : "Explicit"}
                                    </p>
                                ) : (
                                    <ToggleValueSwitch
                                        leftLabel={"Implicit"}
                                        rightLabel={"Explicit"}
                                        toggleState={!tcpaLocalOptions.implicit}
                                        onChange={val => {
                                            setTcpaLocalOptions({
                                                ...tcpaLocalOptions,
                                                implicit: !val,
                                                required: !val
                                                    ? false
                                                    : tcpaLocalOptions.required,
                                                isPreselected: !val
                                                    ? false
                                                    : tcpaLocalOptions.isPreselected,
                                            });
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div
                            className={`dp-parent dp-parent-col gap-10 ${styles.dropdownWrap__requiredToggle}`}
                        >
                            <div className={styles.key}>Preselected</div>
                            <div className="dp-parent gap-10">
                                <div>Preselected :</div>
                                <div>
                                    {isReadOnly || readView ? (
                                        <p>
                                            {tcpaLocalOptions.isPreselected
                                                ? "Yes"
                                                : "No"}
                                        </p>
                                    ) : (
                                        <ToggleSwitch
                                            isToggleOn={
                                                tcpaLocalOptions.isPreselected
                                            }
                                            setIsToggleOn={val => {
                                                setTcpaLocalOptions({
                                                    ...tcpaLocalOptions,
                                                    isPreselected: val,
                                                });
                                            }}
                                            disabled={
                                                tcpaLocalOptions.implicit
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className={`dp-parent dp-parent-col gap-10 ${styles.dropdownWrap__requiredToggle}`}
                        >
                            <div className={styles.key}>Mark as Required</div>
                            <div className="dp-parent gap-10">
                                <div>Mark as Required :</div>
                                <div>
                                    {isReadOnly || readView ? (
                                        <p>
                                            {tcpaLocalOptions.required
                                                ? "Yes"
                                                : "No"}
                                        </p>
                                    ) : (
                                        <ToggleSwitch
                                            isToggleOn={
                                                tcpaLocalOptions.required
                                            }
                                            setIsToggleOn={val => {
                                                setTcpaLocalOptions({
                                                    ...tcpaLocalOptions,
                                                    required: val,
                                                });
                                            }}
                                            disabled={
                                                tcpaLocalOptions.implicit
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {!tcpaLocalOptions.useGlobal && (
                    <div
                        className={`${styles.internalSections__trackingTool} ${styles.dropdownWrap} dp-parent dp-parent-col`}
                    >
                        <div className={styles.key}>
                            Tracking Tool {readView && ":"}
                        </div>
                        <div className={`${styles.value} dp-parent flex-wrap`}>
                            {isReadOnly || readView ? (
                                <p>
                                    {(tcpaLocalOptions.jornaya
                                        ? "Jornaya"
                                        : "") +
                                        (tcpaLocalOptions.jornaya &&
                                        tcpaLocalOptions.trustedform
                                            ? " & "
                                            : "") +
                                        (tcpaLocalOptions.trustedform
                                            ? "Trusted Form"
                                            : "") +
                                        (!tcpaLocalOptions.jornaya &&
                                        !tcpaLocalOptions.trustedform
                                            ? " - "
                                            : "")}
                                </p>
                            ) : (
                                <>
                                    <Checkbox
                                        onChange={() =>
                                            setTcpaLocalOptions({
                                                ...tcpaLocalOptions,
                                                jornaya:
                                                    !tcpaLocalOptions.jornaya,
                                            })
                                        }
                                        isChecked={tcpaLocalOptions.jornaya}
                                        labelText={"Jornaya"}
                                    ></Checkbox>
                                    <Checkbox
                                        onChange={() =>
                                            setTcpaLocalOptions({
                                                ...tcpaLocalOptions,
                                                trustedform:
                                                    !tcpaLocalOptions.trustedform,
                                            })
                                        }
                                        isChecked={tcpaLocalOptions.trustedform}
                                        labelText={"Trusted Form"}
                                    ></Checkbox>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {showPosition && (
                    <div className={styles.dropdownWrap}>
                        <p className={`${styles.key} mb-10`}>
                            Position*
                            {tcpaLocalOptions.useGlobal ? "*" : ""}
                        </p>
                        <Select
                            label="Select Position"
                            options={POSITION_DROPDOWN}
                            idField="id"
                            keyField="label"
                            selectedId={tcpaLocalOptions?.position}
                            onSelect={val => {
                                setTcpaLocalOptions({
                                    ...tcpaLocalOptions,
                                    position: val?.id || "bottom",
                                });
                            }}
                            isDisabled={isReadOnly}
                        />
                    </div>
                )}

                {showIntent && (
                    <div className={styles.dropdownWrap}>
                        <p className={`${styles.key} mb-10`}>
                            Intent
                            {tcpaLocalOptions.useGlobal || intentMandatory
                                ? "*"
                                : ""}
                        </p>
                        <Select
                            onLoadSelectReturn={false}
                            options={INTENT_DROPDOWN}
                            idField="id"
                            keyField="label"
                            multiSelect={!tcpaLocalOptions.useGlobal}
                            onSelect={async valArr => {
                                const selectedArrIds = Array.isArray(valArr)
                                    ? valArr.map(_ => _.id)
                                    : [valArr?.id] || null;
                                if (
                                    tcpaLocalOptions?.intent !== selectedArrIds
                                ) {
                                    await setTcpaLocalOptions({
                                        ...tcpaLocalOptions,
                                        intent: selectedArrIds,
                                    });
                                }
                            }}
                            isDisabled={isReadOnly}
                            selectedIds={tcpaLocalOptions?.intent}
                            {...customIntentOptions}
                            {...(customIntentOptions
                                ? {
                                      options: [
                                          ...(tcpaLocalOptions.useGlobal
                                              ? customIntentOptions.options.filter(
                                                    _ =>
                                                        !_.id?.includes(
                                                            "simpleOptin"
                                                        )
                                                )
                                              : customIntentOptions.options),
                                      ],
                                  }
                                : {})}
                        />
                    </div>
                )}
                {!tcpaLocalOptions.useGlobal && showVisibility && (
                    <div className="w-100">
                        <p className={`${styles.key} mb-10`}>Visibility</p>
                        <Select
                            options={VISIBILITY_DROPDOWN}
                            idField="id"
                            keyField="label"
                            selectedId={
                                tcpaLocalOptions?.visibility
                                    ? tcpaLocalOptions?.visibility[0]
                                    : null
                            }
                            onSelect={val => {
                                if (val?.id) {
                                    setTcpaLocalOptions({
                                        ...tcpaLocalOptions,
                                        visibility: [val.id],
                                    });
                                }
                            }}
                            isDisabled={isReadOnly}
                        />
                    </div>
                )}

                {!tcpaLocalOptions.useGlobal && showPartners && (
                    <div className="w-100">
                        <p className={`${styles.key} mb-10`}>
                            Marketing Partner Data
                        </p>
                        <InputList
                            type="singleDropdown"
                            title={"Marketing Partners"}
                            placeholderOne={"Enter Value"}
                            csvFileName={csvFileName}
                            setCsvFileName={data => {
                                setcsvFileName(data);
                            }}
                            csvFile={csvFile}
                            setCsvFile={data => {
                                setcsvFile(data);
                            }}
                            csvArray={csvArray}
                            setCsvArray={data => {
                                setcsvArray(data);
                            }}
                            error={csvError}
                            setError={data => {
                                setcsvError(data);
                            }}
                            mainState={selectedPartners}
                            setMainState={valArr => {
                                if (Array.isArray(valArr)) {
                                    setTcpaLocalOptions({
                                        ...tcpaLocalOptions,
                                        marketingPartners: valArr?.map(
                                            _ => _?.id || _
                                        ),
                                    });
                                }
                            }}
                            readOnly={isReadOnly}
                            singleInputBulkUpload={true}
                            tagWrappable={true}
                            dropDownJson={{
                                multiSelect: true,
                                singleInputBulkUpload: true,
                                options: partnerDataList,
                                idField: "id",
                                position: "top",
                                keyField: "label",
                                showSearch: true,
                                selectedIds: selectedPartners,
                                disableComponent:
                                    tcpaLocalOptions?.intent?.length === 0,
                            }}
                            characterLimitForUpload={3000}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default FlowTcpa;
