import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { CONFIGURATIONS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";

const initialState = {
    list: [],
    selectedItems: [], //also contains soft deleted items
    selectedItemsOnly: [], //only selected items
    savedItems: [],
    selectedListActiveItem: "",
    updateData: [],
    activeList: [],
    deletedItems: [],
};

export const fetchFeatureSets = createAsyncThunk(
    "surveys/featuresets",
    async (id = null, { dispatch }) => {
        const response = await customFetch(
            CONFIGURATIONS.GET_FEATURE_SETS,
            "POST",
            {
                limit: 1000,
                offset: 0,
            }
        );
        let data = get(response, "data.data", []);
        if (!data.length) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Feature sets list",
                })
            );
        }
        return data;
    }
);

export const updateFeatureSet = createAsyncThunk(
    "surveys/featuresets/update",
    async (id, { getState, dispatch }) => {
        const params = updateSurveyIdFeatureSetParams(
            getState().survey.featureSets.updateData.map(_ => ({
                ..._,
                surveyId: id,
            }))
        );
        const response = await customFetch(
            `${CONFIGURATIONS.MODIFY_SURVEY_FEATURE_SETS}`,
            "PUT",
            params
        );
        return get(response, "data.data", null);
    }
);

const updateSurveyIdFeatureSetParams = obj => {
    return obj;
};
const featureSetsSlice = createSlice({
    name: "featureSets",
    initialState,
    reducers: {
        setSelectedItems(state, action) {
            state.selectedItems = action.payload;
            state.selectedItemsOnly = action.payload.filter(
                _ => !state.deletedItems.includes(_)
            );
        },
        setDeletedItems(state, action) {
            state.deletedItems = action.payload;
            state.selectedItemsOnly = state.selectedItems.filter(
                _ => !action.payload.includes(_)
            );
        },
        setSavedItems(state, action) {
            state.savedItems = action.payload;
        },
        setSelectedListActiveItem(state, action) {
            state.selectedListActiveItem = action.payload;
        },
        setUpdateData(state, action) {
            state.updateData = action.payload;
        },
        resetFeatureSet(state, action) {
            state.selectedItems = [];
            state.selectedItemsOnly = [];
            state.selectedListActiveItem = null;
            state.updateData = [];
            state.deletedItems = [];
        },
        setSelectedByIds(state, action) {
            state.selectedItems = action.payload;
            state.selectedItemsOnly = action.payload.filter(
                _ => !state.deletedItems.includes(_)
            );
            state.selectedListActiveItem = state.selectedItems[0];
        },
        setSelectedBySurveyId(state, action) {
            state.list.forEach(fsItem => {
                const includedSurveyIds =
                    fsItem.targeting.keyVals
                        ?.find(_ => _.key === "surveyId")
                        ?.value?.split(",") || [];
                const excludedSurveyIds =
                    fsItem.exclusionTargeting.keyVals
                        ?.find(_ => _.key === "surveyId")
                        ?.value?.split(",") || [];
                if (
                    (includedSurveyIds?.indexOf(action.payload) !== -1 &&
                        excludedSurveyIds?.indexOf(action.payload) === -1) ||
                    !includedSurveyIds.length
                ) {
                    state.selectedItems.push(fsItem.id);
                }
            });
            state.selectedItemsOnly = state.selectedItems;
            state.selectedListActiveItem = state.selectedItems.length
                ? state.selectedItems[0]
                : "";
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchFeatureSets.fulfilled, (state, action) => {
            state.list = action.payload;
            state.activeList = state.list.filter(fsItem => {
                return fsItem.status === "active";
            });
        });
    },
});

export const featureSetsActions = featureSetsSlice.actions;
export default featureSetsSlice.reducer;
