import React, { useState, useRef } from "react";
import s from "./Tooltip.module.scss";
import { createPortal } from "react-dom";
import { configureScope } from "@sentry/react";

const Tooltip = ({
    delay,
    hideTooltip,
    width,
    message,
    position = "top",
    children,
    mouseleave,
    variant,
    customClass,
    wrap = false,
    customWrapperClass,
    moreThanOneValue = false,
}) => {
    const mount = document.body;
    let timer = null;
    const [displayTooltip, setDisplayTooltip] = useState(false);

    const hideTooltipRender = () => {
        if (delay) {
            clearTimeout(timer);
        }
        setDisplayTooltip(false);
    };

    const showTooltip = () => {
        if (delay) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                setDisplayTooltip(true);
            }, 1000);
        } else {
            setDisplayTooltip(true);
        }
    };
    const tooltipRef = useRef(null);
    let offset =
        tooltipRef && tooltipRef.current
            ? tooltipRef.current.getBoundingClientRect()
            : "";

    const validateTopPosition = position => {
        switch (position) {
            case "top":
                return offset.top + window.pageYOffset;
            case "bottom":
                return offset.top + window.pageYOffset + offset.height + 10;
            case "left":
                return offset.top + window.pageYOffset + offset.height / 2;
            case "right":
                return offset.top + window.pageYOffset + offset.height / 2;
            default: {
                return offset.top + window.pageYOffset;
            }
        }
    };
    const validateLeftPosition = position => {
        switch (position) {
            case "top":
                return offset.x + offset.width / 2 - 5;
            case "bottom":
                return offset.x + offset.width / 2 - 5;
            case "left":
                return offset.x - 10;
            case "right":
                return offset.x + offset.width;
            default: {
                return offset.x + offset.width / 2 - 5;
            }
        }
    };

    const style = offset
        ? {
              left: validateLeftPosition(position),
              top: validateTopPosition(position),
              // top: offset.top + window.pageYOffset,
              // top: offset.top + window.pageYOffset - offset.height,
              // top: offset.y + window.scrollY + offset.height,
          }
        : {};

    const variantLight = {
        background: "rgba(255, 255, 255, 0.9)",
        border: "1px solid #F1F1F1",
        borderRadius: "16px",
        boxShadow: "0px 32px 32px -24px rgba(0, 0, 0, 0.04)",
        color: "#74838F",
        fontSize: "14px",
    };

    return (
        <div
            data-id={"tooltip"}
            className={`${s["tooltip"]} ${
                customWrapperClass ? customWrapperClass : ""
            }`}
            ref={tooltipRef}
            onMouseLeave={hideTooltipRender}
        >
            {displayTooltip &&
                (!hideTooltip
                    ? createPortal(
                          <div
                              style={{ ...style, width: width }}
                              onMouseEnter={hideTooltipRender}
                              className={`${s["tooltip-bubble"]} fadeIn ${
                                  s["tooltip-" + position]
                              } ${customClass ? customClass : ""}`}
                          >
                              <div
                                  className={`${s["tooltip-message"]} ${
                                      wrap ? s["tooltip-message__wrap"] : ""
                                  } ${variant ? s["variantLight"] : null} ${
                                      moreThanOneValue
                                          ? s["tooltip_MoreThanOneValue"]
                                          : null
                                  }`}
                                  style={variant ? variantLight : null}
                              >
                                  {message}
                              </div>
                          </div>,
                          mount
                      )
                    : "")}

            <div
                className={s["tooltip-trigger"]}
                onMouseLeave={mouseleave ? hideTooltipRender : () => void 0}
                onMouseOver={showTooltip}
            >
                {children}
            </div>
        </div>
    );
};

export default Tooltip;
