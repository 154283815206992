import { useEffect, useRef, useState } from "react";
import styles from "./Devices.module.scss";
export const Devices = ({ children, use, retrigger }) => {
    const container = useRef();
    const wrapper = useRef();

    const [scale, setScale] = useState(1);

    useEffect(() => {
        window.addEventListener("resize", refreshScale);
        return () => {
            window.removeEventListener("resize", refreshScale);
        };
    }, []);

    useEffect(() => {
        refreshScale();
    }, [use, retrigger]);

    const refreshScale = () => {
        setScale(calculateScale());
    };

    const calculateScale = () => {
        if (container.current && wrapper.current) {
            const wrapperReact = wrapper.current.getBoundingClientRect();

            // Height and width of the devices with their respective borders
            let width = 1600;
            let height = 1020;
            if (use === "mobile") {
                width = 423;
                height = 877;
            } else if (use == "tablet") {
                width = 626;
                height = 948;
            }

            const widthScale = wrapperReact.width / width;
            const heightScale = wrapperReact.height / height;

            if (wrapperReact.width <= width || wrapperReact.height <= height) {
                return widthScale < heightScale ? widthScale : heightScale;
            }
        }

        return scale;
    };

    const decideDevice = () => {
        switch (use) {
            case "desktop":
                return (
                    <div
                        className={`${styles["marvel-device"]} ${styles["macbook"]}`}
                    >
                        <div className={styles["top-bar"]}></div>
                        <div className={styles["camera"]}></div>
                        <div className={styles["screen"]}>{children}</div>
                        <div className={styles["bottom-bar"]}></div>
                    </div>
                );
            case "mobile":
                return (
                    <div
                        className={`${styles["marvel-device"]} ${styles["iphone8"]}`}
                    >
                        <div className={styles["top-bar"]}></div>
                        <div className={styles["sleep"]}></div>
                        <div className={styles["volume"]}></div>
                        <div className={styles["camera"]}></div>
                        <div className={styles["sensor"]}></div>
                        <div className={styles["speaker"]}></div>
                        <div className={styles["screen"]}>{children}</div>
                        <div className={styles["home"]}></div>
                        <div className={styles["bottom-bar"]}></div>
                    </div>
                );

            case "tablet":
                return (
                    <div
                        className={`${styles["marvel-device"]} ${styles["ipad"]} ${styles["silver"]}`}
                    >
                        <div className={styles["camera"]}></div>
                        <div className={styles["screen"]}>{children}</div>
                        <div className={styles["home"]}></div>
                    </div>
                );
        }
    };
    return (
        <div className={styles["wrapper"]} ref={wrapper}>
            <div
                className={styles["container"]}
                style={{
                    transform: `translateX(-50%) scale(${scale})`,
                    transformOrigin: "top ",
                }}
            >
                <section ref={container} className={styles["block"]}>
                    {decideDevice()}
                </section>
            </div>
        </div>
    );
};
