import React, { useState, useEffect, useMemo, memo } from "react";
import s from "./HeaderAccordian.module.scss";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Icon from "../../../Shared/Icon/Icon";
import CustomTooltipRenderer from "../../Renderers/CustomTooltipRenderer/CustomTooltipRenderer";
import Tooltip from "../../../Shared/Tooltip/Tooltip";

const HeaderAccordian = ({
    data,
    index,
    showAcc,
    setShowAccFunction,
    pathname,
}) => {
    const { showLeftNav, isDarkMode } = useSelector(
        state => state.globalConfig
    );

    const iconConstant = {
        "Flow Manager": {
            filled: "FlowFilled",
            normal: "Flow",
        },
        "Survey Manager": {
            filled: "SurveyManagerFilled",
            normal: "SurveyManager",
        },
        "Host & Post": {
            filled: "HostNPostFilled",
            normal: "HostNPost",
        },
        Deals: {
            filled: "DealsFilled",
            normal: "Deals",
        },
        Configurations: {
            filled: "ConfigurationFilled",
            normal: "Configuration",
        },
        "Control Centre": {
            filled: "ControlCenterFilled",
            normal: "ControlCenter",
        },
        Reporting: {
            filled: "ReportingFilled",
            normal: "Reporting",
        },
    };

    const liDropDownPopup = data => {
        return (
            <div className={s.dropDownPopUpWrapper}>
                <div className={s.headerPopUp}>{data.text}</div>
                <div className={s.subMenuWrapper}>
                    {data?.subMenu.map((item, idx) => {
                        return (
                            <div key={item.text + idx}>
                                {!item?.subMenu && (
                                    <Tooltip
                                        message={item.titleText || null}
                                        position="top"
                                        hideTooltip={!item.titleText}
                                    >
                                        <NavLink
                                            activeClassName={s.active}
                                            target={item.target}
                                            className={
                                                pathname.includes(
                                                    data.path +
                                                        "/" +
                                                        item.path.split("/")[1]
                                                )
                                                    ? s.active
                                                    : ""
                                            }
                                            to={data.path + item.path}
                                            key={item.text + idx}
                                        >
                                            <div className="dp-parent-inline dp-parent-ver-center">
                                                <span>{item.text}</span>
                                                {item.target && (
                                                    <Icon
                                                        icon={"Export"}
                                                        className={"ml-5"}
                                                        size={"12px"}
                                                    />
                                                )}
                                            </div>
                                        </NavLink>
                                    </Tooltip>
                                )}
                                {item?.subMenu?.map((element, index) => {
                                    return (
                                        <div key={element.text + idx}>
                                            <Tooltip
                                                message={
                                                    element.titleText || null
                                                }
                                                position="top"
                                                hideTooltip={!element.titleText}
                                            >
                                                <NavLink
                                                    activeClassName={s.active}
                                                    target={element.target}
                                                    className={
                                                        pathname.includes(
                                                            element.path.split(
                                                                "/"
                                                            )[3]
                                                        ) &&
                                                        pathname.includes(
                                                            item?.text
                                                                .split(" ")
                                                                .join("")
                                                        )
                                                            ? s.active
                                                            : ""
                                                    }
                                                    to={
                                                        data.path + element.path
                                                    }
                                                    key={element.text + index}
                                                >
                                                    <div className="dp-parent-inline dp-parent-ver-center">
                                                        <span>
                                                            {element.text}
                                                        </span>
                                                        {element.target && (
                                                            <Icon
                                                                icon={"Export"}
                                                                className={
                                                                    "ml-5"
                                                                }
                                                                size={"12px"}
                                                            />
                                                        )}
                                                    </div>
                                                </NavLink>
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const menuLinkIcon =
        iconConstant[data.text][
            pathname.includes(data.path) ? "filled" : "normal"
        ];

    return (
        <li
            key={index + data.text}
            className={`m-link m-link${index}  ${s.liWrapper} ${
                !showLeftNav ? s.leftNavIsCollapsed : ""
            }`}
        >
            <CustomTooltipRenderer
                data={`${!showLeftNav ? [data] : ""}`}
                customRenderer={() => liDropDownPopup(data)}
                position="right"
                handlePromptMouseLeave={true}
            >
                <div
                    onClick={() =>
                        setShowAccFunction(
                            showAcc == data.text ? "" : data.text
                        )
                    }
                    className={`${s.menuLink} ${
                        showAcc == data.text ? s.menuLinkActive : ""
                    } ${!showLeftNav ? s.leftNavCollapseMenuLink : ""} `}
                >
                    {pathname.includes(data.path) && (
                        <div className={s.borderLine}></div>
                    )}
                    <div
                        className={`${s.textWrapper} ${
                            pathname.includes(data.path) ? s.iconActive : ""
                        }`}
                    >
                        <div className={s.tooltipImgWrapper}>
                            <Icon icon={menuLinkIcon} size="18px" />
                        </div>

                        <div className={s.text}>{data.text}</div>
                    </div>

                    <div
                        className={`${s.arrowIconWrapper} ${
                            showAcc == data.text ? s.arrowIconActive : ""
                        }`}
                    >
                        <Icon icon={"DropDownArrow"} size="12px" />
                    </div>
                </div>
            </CustomTooltipRenderer>

            {showLeftNav && (
                <div
                    className={`${s.LiLink} ${
                        showAcc == data.text ? s.LiLinkActive : ""
                    } `}
                >
                    {data?.subMenu.map((item, idx) => {
                        return (
                            <div key={item.text + idx}>
                                <div className={`${s.subMenuLink} `}>
                                    <Tooltip
                                        message={item.titleText || null}
                                        position="top"
                                        hideTooltip={!item.titleText}
                                    >
                                        <NavLink
                                            activeClassName={s.active}
                                            target={item.target}
                                            className={
                                                pathname.includes(
                                                    data.path +
                                                        "/" +
                                                        item.path.split("/")[1]
                                                )
                                                    ? s.active
                                                    : ""
                                            }
                                            to={data.path + item.path}
                                        >
                                            <div className="dp-parent-inline dp-parent-ver-center">
                                                <span>{item.text}</span>
                                                {item.target && (
                                                    <Icon
                                                        icon={"Export"}
                                                        className={"ml-5"}
                                                        size={"12px"}
                                                    />
                                                )}
                                            </div>
                                        </NavLink>
                                    </Tooltip>
                                </div>

                                {item?.subMenu?.map((element, index) => {
                                    return (
                                        <div
                                            className={`${s.subMenuLink} ${s.subMenuLinkChild}`}
                                            key={element.text + idx}
                                        >
                                            <Tooltip
                                                message={
                                                    element.titleText || null
                                                }
                                                position="top"
                                                hideTooltip={!element.titleText}
                                            >
                                                <NavLink
                                                    activeClassName={s.active}
                                                    target={element.target}
                                                    className={
                                                        pathname.includes(
                                                            element.path.split(
                                                                "/"
                                                            )[3]
                                                        ) &&
                                                        pathname.includes(
                                                            item?.text
                                                                .split(" ")
                                                                .join("")
                                                        )
                                                            ? s.active
                                                            : ""
                                                    }
                                                    to={
                                                        data.path + element.path
                                                    }
                                                >
                                                    <div className="dp-parent-inline dp-parent-ver-center">
                                                        <span>
                                                            {element.text}
                                                        </span>
                                                        {element.target && (
                                                            <Icon
                                                                icon={"Export"}
                                                                className={
                                                                    "ml-5"
                                                                }
                                                                size={"14px"}
                                                            />
                                                        )}
                                                    </div>
                                                </NavLink>
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            )}
        </li>
    );
};

export default HeaderAccordian;
