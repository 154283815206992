import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef,
    memo,
} from "react";
import Select from "../Select/Select";
import { EditorState, convertToRaw, Modifier, ContentState } from "draft-js";
import { Editor } from "@pinging/react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { SketchPicker } from "react-color";

import "!style-loader!css-loader!sass-loader!./RichTextBox.scss";
import "!style-loader!css-loader!sass-loader!@pinging/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EmptyImage from "../../assets/img/1x1.png";

import { fontSizeInWords, fontSizes } from "../../utils/richTextUtils";

const getSelectedText = editorState => {
    var selectionState = editorState.getSelection();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var start = selectionState.getStartOffset();
    var end = selectionState.getEndOffset();
    return currentContentBlock.getText().slice(start, end);
};

const ColorPic = ({ expanded, onExpandEvent, onChange, doCollapse }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [sketchPickerColor, setSketchPickerColor] = useState({
        r: "241",
        g: "112",
        b: "19",
        a: "1",
    });

    const stopPropagation = event => {
        event.stopPropagation();
    };

    const onColorChange = color => {
        setSketchPickerColor(color.hex);
    };

    const outsideClick = () => {
        setDisplayColorPicker(false);
        onChange("color", sketchPickerColor);
        doCollapse();
    };

    const openColorTool = () => {
        setDisplayColorPicker(true);
        onExpandEvent();
    };

    const renderPicker = () => {
        return (
            <div>
                {displayColorPicker && (
                    <div onClick={stopPropagation}>
                        <div className={"backdrop"} onClick={outsideClick} />
                        <div className={"rich-text-colorpicker"}>
                            <SketchPicker
                                color={sketchPickerColor}
                                onChange={onColorChange}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return (
        <div
            aria-haspopup="true"
            aria-expanded={expanded}
            aria-label="rdw-color-picker"
            className={"rdw-inline-wrapper custom-icon-wrapper"}
        >
            <div
                onClick={openColorTool}
                className={"rdw-option-wrapper"}
                title={"Colorpicker"}
            >
                <img src={EmptyImage} alt="color" />
            </div>
            {expanded ? renderPicker() : undefined}
        </div>
    );
};

const CustomSelect = ({
    config,
    currentState,
    onChange,
    expanded,
    doExpand,
    doCollapse,
}) => {
    const selectWrapperClass = [
        "rich-text-select-wrapper",
        `rich-text-select-${config.title.toLowerCase()}`,
        `rdw-${config.title.toLowerCase()}-dropdown`,
        "rdw-dropdown-wrapper",
    ];
    const onSelect = selectedItem => {
        setSelectedId(selectedItem);
        onChange(selectedItem);
    };
    const defaultSelected = {
        fontFamily: "Inherit",
        fontSize: 12,
    };
    const label = `rdw-${Object.keys(currentState)[0]}-dropdown`;
    const customRenderer = useRef(null);
    const idField = config.title === "fontSize" ? "fontSize" : null;
    const keyField = config.title === "fontSize" ? "displayText" : null;
    const [selectedId, setSelectedId] = useState(defaultSelected[config.title]);
    if (config.title === "fontFamily") {
        customRenderer.current = ({ option }) => {
            return <div style={{ fontFamily: option }}>{option}</div>;
        };
    }
    useEffect(() => {
        if (
            !currentState[config.title] &&
            String(selectedId).toLowerCase() !==
                String(defaultSelected[config.title]).toLowerCase()
        ) {
            setSelectedId(prevState => {
                return null;
            });
            setSelectedId(prevState => {
                return defaultSelected[config.title];
            });
        } else if (
            currentState[config.title] &&
            String(currentState[config.title]).toLowerCase() !==
                String(selectedId).toLowerCase()
        ) {
            setSelectedId(currentState[config.title]);
        }
    }, [currentState]);
    return (
        <div
            className={selectWrapperClass.join(" ")}
            aria-haspopup="true"
            aria-expanded={expanded}
            aria-label={label}
        >
            <Select
                options={config.options}
                selectedId={selectedId}
                onSelect={onSelect}
                portal={false}
                showSearch={false}
                onLoadSelectReturn={false}
                autoFocus={false}
                className={"rich-text-select"}
                onSelectOpen={doExpand}
                onSelectClose={doCollapse}
                CustomRender={customRenderer.current}
                idField={idField}
                keyField={keyField}
                showOptionToolTip={false}
            />
        </div>
    );
};

const Macro = ({ editorState, onChange, modalHandler }) => {
    const [expanded, setExpanded] = useState(false);
    const signalExpanded = useRef(false);
    const options = [
        {
            text: "First Name",
            value: "%%FIRST_NAME%%",
        },
        {
            text: "Last Name",
            value: "%%LAST_NAME%%",
        },
        {
            text: "Phone No",
            value: "%%PHONE%%",
        },
        {
            text: "Email",
            value: "%%EMAIL%%",
        },
        {
            text: "Domain",
            value: "%%DOMAIN%%",
        },
    ];

    useEffect(() => {
        modalHandler.registerCallBack(expandCollapse);
        return () => {
            modalHandler.deregisterCallBack(expandCollapse);
        };
    }, []);

    const onMacroSelect = selectedItem => {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            selectedItem?.value,
            editorState.getCurrentInlineStyle()
        );
        onChange(
            EditorState.push(editorState, contentState, "insert-characters")
        );
    };

    const onExpandEvent = () => {
        signalExpanded.current = !expanded;
    };

    const expandCollapse = () => {
        setExpanded(signalExpanded.current);
        signalExpanded.current = false;
    };

    const doExpand = () => {
        setExpanded(true);
    };

    const doCollapse = () => {
        setExpanded(false);
    };

    return (
        <div
            // className={"macro-wrapper custom-icon-wrapper rdw-inline-wrapper"}
            className={"custom-icon-wrapper rdw-inline-wrapper"}
            onClick={onExpandEvent}
            aria-haspopup="true"
            aria-expanded={expanded}
        >
            <div className="rdw-option-wrapper" title={"Macro"}>
                <img src={EmptyImage} alt="Macro" />
            </div>
            {expanded && (
                <div className={"rich-text-select-wrapper"}>
                    <Select
                        label={"Macro"}
                        placeholder={"Search Lander"}
                        keyField={"text"}
                        idField={"value"}
                        options={options}
                        selectedId={null}
                        onSelect={onMacroSelect}
                        className={"partialHidden"}
                        isSelectOpen={true}
                        onLoadSelectReturn={false}
                        onSelectClose={doCollapse}
                        hideLabel={true}
                        overlayClassName="macroSelect"
                    />
                </div>
            )}
        </div>
    );
};

const Partners = ({ editorState, onChange, modalHandler }) => {
    const onPartnerClick = () => {
        var selectedText = getSelectedText(editorState);

        if (selectedText.includes("<Partners>")) {
            selectedText = selectedText
                .replace("<Partners>", "")
                .replace("</Partners>", "");
        } else {
            selectedText = `<Partners>${selectedText}</Partners>`;
        }

        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            selectedText,
            editorState.getCurrentInlineStyle()
        );

        onChange(EditorState.push(editorState, contentState, "insert-text"));
    };

    return (
        <div
            className={"custom-icon-wrapper rdw-inline-wrapper"}
            onClick={onPartnerClick}
        >
            <div className="rdw-option-wrapper" title={"Partners"}>
                P
            </div>
        </div>
    );
};

const Skip = ({ editorState, onChange, modalHandler }) => {
    const onSkipClick = () => {
        var selectedText = getSelectedText(editorState);

        if (selectedText.includes("<Skip>")) {
            selectedText = selectedText
                .replace("<Skip>", "")
                .replace("</Skip>", "");
        } else {
            selectedText = `<Skip>${selectedText}</Skip>`;
        }

        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            selectedText,
            editorState.getCurrentInlineStyle()
        );

        onChange(EditorState.push(editorState, contentState, "insert-text"));
    };

    return (
        <div
            className={"custom-icon-wrapper rdw-inline-wrapper skipButton"}
            onClick={onSkipClick}
        >
            <div className="rdw-option-wrapper" title={"Skip"}>
                S
            </div>
        </div>
    );
};

const RichTextBox = memo(
    forwardRef(
        (
            {
                options = {
                    fontFamily: true,
                    blockType: false,
                    fontSize: true,
                    inline: true,
                    list: false,
                    textAlign: false,
                    colorPicker: true,
                    link: true,
                    embedded: false,
                    emoji: false,
                    image: false,
                    remove: false,
                    history: false,
                },
                customOptions = {
                    macro: true,
                    partners: false,
                    skip: false,
                },
                fontStyleOptions = {
                    bold: true,
                    italic: true,
                    underline: true,
                    strikethrough: true,
                    monospace: false,
                    superscript: true,
                    subscript: true,
                },

                fontFamilyOptions = [
                    "Inherit",
                    "Arial",
                    "Times New Roman",
                    "Times",
                    "Courier New",
                    "Courier",
                    "Verdana",
                    "Georgia",
                    "Palatino",
                    "Garamond",
                    "Bookman",
                    "Tahoma",
                    "Trebuchet MS",
                    "Impact",
                    "Comic Sans MS",
                    "Poppins",
                ],
                htmlContent,
                customOnBlur,
                isFontSizesInNumber = false,
                containDropdown = false,
            },
            ref
        ) => {
            const [editorState, setEditorState] = useState(
                EditorState.createEmpty()
            );
            const [toolBarOptions, setToolBarOptions] = useState([]);
            const [fontStyle, setFontStyle] = useState([]);
            const [customOptionsTool, setCustomOptionsTool] = useState([]);
            const [textBoxWrapperClass, setTextBoxWrapperClass] = useState([
                "rich-text-box-wrapper",
            ]);
            const customOptionsComponent = useRef({
                macro: <Macro />,
                partners: <Partners />,
                skip: <Skip />,
            });
            const fontClassName = useRef("small");
            useEffect(() => {
                if (htmlContent) {
                    let text = htmlContent;

                    if (customOptions.partners || customOptions.skip) {
                        let tempEle = document.createElement("div");
                        tempEle.innerHTML = htmlContent;

                        if (
                            customOptions.skip &&
                            tempEle.querySelector(".tcpa_skip")
                        ) {
                            text = text.replace(
                                `<span class='tcpa_skip'>${
                                    tempEle.querySelector(".tcpa_skip")
                                        .innerHTML
                                }</span>`,
                                `&lt;Skip&gt;${
                                    tempEle.querySelector(".tcpa_skip")
                                        .innerHTML
                                }&lt;/Skip&gt; `
                            );
                        }

                        if (
                            customOptions.partners &&
                            tempEle.querySelector(".tcpa_partners")
                        ) {
                            text = text.replace(
                                `<span class='tcpa_partners'>${
                                    tempEle.querySelector(".tcpa_partners")
                                        .innerHTML
                                }</span>`,
                                `&lt;Partners&gt;${
                                    tempEle.querySelector(".tcpa_partners")
                                        .innerHTML
                                }&lt;/Partners&gt; `
                            );
                        }
                    }

                    const contentBlock = htmlToDraft(text);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(
                            contentBlock.contentBlocks
                        );
                        const editorStateTemp =
                            EditorState.createWithContent(contentState);
                        setEditorState(editorStateTemp);
                    }
                }

                setToolBarOptions(convertJsonToArray(options));
                setFontStyle(convertJsonToArray(fontStyleOptions));
                setCustomOptionsTool(() => {
                    let options = convertJsonToArray(customOptions);
                    options = options.map(
                        option => customOptionsComponent.current[option]
                    );
                    return options;
                });
            }, []);

            useEffect(() => {
                if (htmlContent === "" || htmlContent === null) {
                    setEditorState(EditorState.createEmpty());
                }
            }, [htmlContent]);

            useImperativeHandle(ref, () => {
                return {
                    value: getValue,
                    validate: validateInput,
                    removeError: removeError,
                };
            });

            const convertJsonToArray = obj => {
                let tempStr = [];
                for (let option in obj) {
                    if (obj[option]) {
                        tempStr.push(option);
                    }
                }
                return tempStr;
            };

            const onEditorStateChange = content => {
                setEditorState(content);
                if (textBoxWrapperClass.indexOf("error") !== -1) {
                    validateInput();
                }
            };

            const getValue = () => {
                const replaceMap = {
                    "&lt;Partners&gt;": "<span class='tcpa_partners'>",
                    "&lt;/Partners&gt;": "</span>",
                    "&lt;Skip&gt;": "<span class='tcpa_skip'>",
                    "&lt;/Skip&gt;": "</span>",
                };
                let temp = draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                );
                Object.keys(replaceMap).map(key => {
                    if (temp.includes(key)) {
                        temp = temp.replace(key, replaceMap[key]);
                    }
                });

                return {
                    html: temp,
                    fontClassName: fontClassName.current,
                };
            };

            const validateInput = () => {
                let htmlContent = document.createElement("div");
                htmlContent.innerHTML = getValue().html;
                if (htmlContent.textContent.trim().length < 1) {
                    if (textBoxWrapperClass.indexOf("error") === -1) {
                        setTextBoxWrapperClass(prevState => {
                            prevState.push("error");
                            return prevState;
                        });
                        return false;
                    }
                } else {
                    removeError();
                    return true;
                }
            };

            const removeError = () => {
                setTextBoxWrapperClass(prevState => {
                    if (prevState.indexOf("error") !== -1) {
                        prevState.splice(prevState.indexOf("error"), 1);
                    }
                    return prevState;
                });
            };

            const CustomSelectFontSize = useRef(({ onChange, ...props }) => {
                const onChangeCustom = selectedItem => {
                    fontClassName.current = selectedItem.className;
                    onChange(selectedItem.fontSize);
                };
                return <CustomSelect {...props} onChange={onChangeCustom} />;
            });

            const dataToParent = () => {
                validateInput();
                if (typeof customOnBlur === "function") {
                    customOnBlur(validateInput() ? getValue().html : null);
                }
            };

            return (
                <div
                    className={`richTextInternalWrapper ${
                        containDropdown ? "containDropdown" : ""
                    }`}
                >
                    {toolBarOptions.length && fontStyle.length && (
                        <Editor
                            editorState={editorState}
                            wrapperClassName={textBoxWrapperClass.join(" ")}
                            editorClassName="rich-text-box"
                            toolbarCustomButtons={customOptionsTool}
                            onEditorStateChange={onEditorStateChange}
                            onBlur={() => {
                                dataToParent();
                            }}
                            onContentStateChange={() => {
                                dataToParent();
                            }}
                            stripPastedStyles={true}
                            toolbar={{
                                options: toolBarOptions,
                                fontSize: {
                                    options: isFontSizesInNumber
                                        ? fontSizes
                                        : fontSizeInWords,
                                    component: CustomSelectFontSize.current,
                                    title: "fontSize",
                                },
                                fontFamily: {
                                    options: fontFamilyOptions,
                                    component: CustomSelect,
                                    title: "fontFamily",
                                },
                                inline: {
                                    options: fontStyle,
                                    bold: {
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                    underline: {
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                    italic: {
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                    strikethrough: {
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                    superscript: {
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                    subscript: {
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                },
                                link: {
                                    defaultTargetOption: "_blank",
                                    link: {
                                        inDropdown: true,
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                    unlink: {
                                        icon: EmptyImage,
                                        className: "inline-style-icon",
                                    },
                                },
                                colorPicker: {
                                    component: ColorPic,
                                },
                            }}
                        />
                    )}
                </div>
            );
        }
    )
);

export default RichTextBox;
