import { useEffect, useRef, useState } from "react";
import { Actions, PIMacros } from "../../constants/dedupeConstants";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { CompactFormContainer } from "../../Shared/CompactFormContainer/CompactFormContainer";
import Icon from "../../Shared/Icon/Icon";
import Input from "../../Shared/Input/Input";
import InputWithActionBtn from "../../Shared/InputWithActionBtn/InputWithActionBtn";
import Select from "../../Shared/Select/Select";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import FeatureCopyToolbar from "../FeatureCopyToolbar/FeatureCopyToolbar";
import ActionButtons from "../Renderers/ActionButtons/ActionButtons";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import style from "./AddDedupeRule.module.scss";

const AddDedupeRule = ({
    dedupeRules = [],
    onSave,
    onCancel,
    prefix = "",
    readOnly,
    entityMacros = [],
    actionMacros = Actions,
    featureCopyToolbarKeys,
    PIMacrosArray = PIMacros,
}) => {
    const [prefixValue, setPrefixValue] = useState("");
    const [suffixValue, setSuffixValue] = useState("");
    const [readOnlyKey, setReadOnlyKey] = useState(false);
    const [localDedupeRules, setLocalDedupeRules] = useState([]);
    const [selectedPIMacros, setSelectedPIMacros] = useState([]);
    const [selectedEntityMacros, setSelectedEntityMacros] = useState([]);
    const [selectedAction, setSelectedAction] = useState([]);
    const [duration, setDuration] = useState(null);
    const [durationUnit, setDurationUnit] = useState("Days");

    const piMacrosRef = useRef(null);
    const entityMacrosRef = useRef(null);
    const actionMarcosRef = useRef(null);
    const durationRef = useRef(null);

    const [showAddRuleForm, setShowAddRuleForm] = useState(false);

    const isOfferGroupDedupe = prefix === "hnpog" || prefix === "hnpoffer";

    useEffect(() => {
        if (dedupeRules?.length === 0) {
            setShowAddRuleForm(true);
        } else {
            setLocalDedupeRules(dedupeRules);
        }
    }, [dedupeRules]);

    const preselectValues = () => {
        if (actionMacros?.length > 0) {
            const preselectedActionMacrosFound = actionMacros.filter(
                obj => obj.preselected
            );
            setSelectedAction(preselectedActionMacrosFound);
        }
        if (entityMacros?.length > 0) {
            const preselectedEntityMacrosFound = entityMacros.filter(obj => {
                obj.preselected;
            });
            setSelectedEntityMacros(preselectedEntityMacrosFound);
        }
    };

    useEffect(() => {
        preselectValues();
    }, [entityMacros, actionMacros]);

    const reset = () => {
        setPrefixValue("");
        setSuffixValue("");
        setSelectedPIMacros([]);
        setDuration([]);
        setDurationUnit("Days");
        preselectValues();
    };

    const parseRule = rule => {
        const pi = PIMacrosArray.filter(_ => rule.key.indexOf(_.id) > -1).map(
            _ => _.text
        );
        const entity = entityMacros
            .filter(_ => rule.key.indexOf(_.id) > -1)
            .map(_ => _.text);
        const actions = actionMacros
            .filter(_ => rule.evtIds.indexOf(_.id) > -1)
            .map(_ => _.text);
        const duration = {
            type: "Hours",
            value: Math.round(rule.ttl / 3600),
        };

        const { prefixVal, suffixVal, readOnly } = offerGrpDedupeKeys(rule);

        return {
            pi,
            entity,
            actions,
            duration,
            readOnly,
            prefixVal,
            suffixVal,
        };
    };

    const offerGrpDedupeKeys = rule => {
        const isPrefixExist =
            PIMacrosArray.filter(_ => rule.key[2].indexOf(_.id) > -1)
                ?.length === 0;
        const isSuffixExist =
            entityMacros.filter(
                _ => rule.key[rule.key.length - 1].indexOf(_.id) > -1
            ).length === 0;

        let prefixVal;
        let suffixVal;
        let readOnly;

        if (isPrefixExist) {
            prefixVal = rule.key[2]?.replaceAll("%", "");
        }
        if (isSuffixExist) {
            suffixVal = rule.key[rule.key.length - 1]?.replaceAll("%", "");
        }
        readOnly = rule.readOnly;

        return { prefixVal, suffixVal, readOnly };
    };

    const onAdd = () => {
        const isValidDuration = durationRef.current.validate();
        const isValidPIMacro = piMacrosRef.current.checkValidity();
        const isValidEntityMacro = entityMacrosRef.current.checkValidity();
        const isValidActionMacro = isOfferGroupDedupe
            ? true
            : actionMarcosRef.current.checkValidity();
        if (
            isValidDuration &&
            isValidPIMacro &&
            isValidEntityMacro &&
            isValidActionMacro
        ) {
            setShowAddRuleForm(false);
            const keys = [
                prefix,
                ...(prefixValue !== "" ? [`%%${prefixValue}%%`] : []),
                ...selectedPIMacros.map(_ => _.id),
                ...selectedEntityMacros.map(_ => _.id),
                ...(suffixValue !== "" ? [`%%${suffixValue}%%`] : []),
            ]
                .join("~-~")
                .split("~");
            setLocalDedupeRules([
                {
                    key: keys,
                    evtIds: selectedAction.map(_ => _.id),
                    ttl: duration,
                    ...(isOfferGroupDedupe ? {} : { isNew: true }),
                    ...(isOfferGroupDedupe ? { readOnly: readOnlyKey } : {}),
                },
                ...localDedupeRules,
            ]);
            reset();
        }
    };

    const setCopiedDedupes = dedupes => {
        setLocalDedupeRules([...dedupes, ...localDedupeRules]);
    };

    return (
        <div>
            {!showAddRuleForm && (
                <>
                    <Button
                        btnTheme={ButtonTypes.primaryHover_btn}
                        className={`mb-20 ${style.addNewRuleBtn}`}
                        disable={showAddRuleForm}
                        onClick={() => setShowAddRuleForm(true)}
                    >
                        <Icon icon="Plus" size="14px" />
                        Add New Rule
                    </Button>
                </>
            )}

            <FeatureCopyToolbar
                displayText="dedupe"
                featureCopyData={{
                    sourceId: [],
                    sourceName: featureCopyToolbarKeys?.sourceName || "",
                    sourcePage: featureCopyToolbarKeys?.sourcePage || "",
                    sourcePath: featureCopyToolbarKeys?.sourcePath || [],
                    copiedData: localDedupeRules,
                    type: featureCopyToolbarKeys?.type || "",
                }}
                handleLoad={setCopiedDedupes}
                customClass={!showAddRuleForm && style.featureToolbarContainer}
            />

            <div
                className={`${style.rulesContainer} ${
                    showAddRuleForm && style.noData_rulesContainer
                }`}
            >
                {showAddRuleForm && (
                    <CompactFormContainer onSave={onAdd} saveText={"Add"}>
                        {isOfferGroupDedupe && (
                            <div className={`row`}>
                                <p className={`headerTitleRequired`}>Prefix</p>
                                <p className={`infoText`}>PI</p>
                                <Input
                                    type="text"
                                    value={prefixValue}
                                    placeholder="Enter prefix value"
                                    onChange={e => {
                                        setPrefixValue(e.target.value);
                                    }}
                                    maxWidth="maxChildContainer"
                                />
                            </div>
                        )}
                        <div className={`row`}>
                            <p className={`headerTitleRequired`}>
                                Personal Information*
                            </p>
                            <p className={`infoText`}>PI</p>
                            <Select
                                ref={piMacrosRef}
                                required={true}
                                portal={false}
                                options={PIMacrosArray}
                                keyField={"text"}
                                onSelect={e => {
                                    setSelectedPIMacros(e);
                                }}
                                multiSelect={true}
                                idField={"id"}
                                selectedIds={selectedPIMacros.map(_ => _.id)}
                                isDisabled={readOnly}
                            />
                        </div>
                        <div className={`row`}>
                            <p className={`headerTitleRequired`}>Entity*</p>
                            <p className={`infoText`}>Entity</p>
                            <Select
                                ref={entityMacrosRef}
                                required={true}
                                portal={false}
                                options={entityMacros}
                                keyField={"text"}
                                onSelect={e => {
                                    setSelectedEntityMacros(e);
                                }}
                                multiSelect={true}
                                idField={"id"}
                                selectedIds={selectedEntityMacros.map(
                                    _ => _.id
                                )}
                                isDisabled={readOnly}
                            />
                        </div>
                        <div className={`row`}>
                            <p className={`headerTitleRequired`}>
                                Action{isOfferGroupDedupe ? "" : "*"}
                            </p>
                            <p className={`infoText`}>Action</p>
                            <Select
                                ref={actionMarcosRef}
                                operator={"or"}
                                required={!isOfferGroupDedupe}
                                portal={false}
                                options={actionMacros}
                                keyField={"text"}
                                onSelect={e => {
                                    setSelectedAction(e);
                                }}
                                multiSelect={true}
                                idField={"id"}
                                selectedIds={selectedAction.map(_ => _.id)}
                                isDisabled={
                                    readOnly ||
                                    actionMacros.some(obj => obj.disabled)
                                }
                            />
                        </div>
                        {isOfferGroupDedupe && (
                            <div className={`row`}>
                                <p className={`headerTitleRequired`}>Suffix</p>
                                <p className={`infoText`}>PI</p>
                                <Input
                                    type="text"
                                    value={suffixValue}
                                    placeholder="Enter suffix value"
                                    onChange={e => {
                                        setSuffixValue(e.target.value);
                                    }}
                                    maxWidth="maxChildContainer"
                                />
                            </div>
                        )}
                        <div className={`row`}>
                            <p className={`headerTitleRequired`}>Duration*</p>
                            <p className={`infoText`}>Duration of expiry</p>

                            <InputWithActionBtn
                                type="ToggleSwitch"
                                ref={durationRef}
                                inputData={{
                                    type: "number",
                                    placeholder: "0",
                                    required: true,
                                    className: "maxChildContainer",
                                    disabled: readOnly,
                                    value:
                                        duration /
                                        (durationUnit === "Hours"
                                            ? 3600
                                            : 86400),
                                    onChange: e => {
                                        if (e && !isNaN(e)) {
                                            setDuration(
                                                e *
                                                    (durationUnit === "Hours"
                                                        ? 3600
                                                        : 86400)
                                            );
                                        }
                                    },
                                }}
                                btnData={{
                                    onChange: e => {
                                        setDurationUnit(e ? "Days" : "Hours");
                                    },
                                    leftLabel: "Hours",
                                    rightLabel: "Days",
                                    toggleState: durationUnit === "Days",
                                }}
                            />
                        </div>
                        {isOfferGroupDedupe && (
                            <div className={`row`}>
                                <p className={`headerTitleRequired`}>
                                    Read only value
                                </p>
                                <p className={`infoText`}>Duration of expiry</p>
                                <ToggleSwitch
                                    isToggleOn={readOnlyKey}
                                    setIsToggleOn={setReadOnlyKey}
                                    // disabled={readOnly}
                                />
                            </div>
                        )}
                    </CompactFormContainer>
                )}

                <ul className={style.ruleList}>
                    {localDedupeRules.map((rule, index) => {
                        const data = parseRule(rule);
                        return (
                            <li key={index}>
                                {((index === 0 && showAddRuleForm) ||
                                    index !== 0) && (
                                    <div className={style.divider}>or</div>
                                )}
                                <div className={style.ruleItem} key={index}>
                                    {rule.isNew && (
                                        <TagRenderer className="mb-10">
                                            New
                                        </TagRenderer>
                                    )}
                                    <div className={style.actionContainer}>
                                        <ActionButtons
                                            item={rule}
                                            deleteCallback={() => {
                                                const temp = [
                                                    ...localDedupeRules,
                                                ];
                                                temp.splice(index, 1);
                                                setLocalDedupeRules(temp);
                                            }}
                                        ></ActionButtons>
                                    </div>

                                    {isOfferGroupDedupe && (
                                        <div className={style.ruleRow}>
                                            <label>Prefix: </label>
                                            <span className="value">
                                                {data?.prefixVal}
                                            </span>
                                        </div>
                                    )}
                                    <div className={style.ruleRow}>
                                        <label>Personal Information: </label>
                                        <span className="value">
                                            {data.pi.join(" and ")}
                                        </span>
                                    </div>
                                    <div className={style.ruleRow}>
                                        <label>Entity: </label>
                                        <span className="value">
                                            {data.entity.join(" and ")}
                                        </span>
                                    </div>
                                    <div className={style.ruleRow}>
                                        <label>Action: </label>
                                        <span className="value">
                                            {data.actions.join(" or ") || "-"}
                                        </span>
                                    </div>
                                    {isOfferGroupDedupe && (
                                        <div className={style.ruleRow}>
                                            <label>Suffix: </label>
                                            <span className="value">
                                                {data?.suffixVal}
                                            </span>
                                        </div>
                                    )}
                                    <div className={style.ruleRow}>
                                        <label>Duration: </label>
                                        <span className="value">
                                            {data.duration.value}{" "}
                                            {data.duration.type}
                                        </span>
                                    </div>
                                    {isOfferGroupDedupe && (
                                        <div className={style.ruleRow}>
                                            <label>Read only: </label>
                                            <span className="value">
                                                {data.readOnly.toString()}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className={style.footer}>
                <Button
                    btnTheme={ButtonTypes.default_btn}
                    onClick={() => {
                        reset();
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    btnTheme={ButtonTypes.primary_btn}
                    onClick={() => onSave(localDedupeRules)}
                >
                    Confirm
                </Button>
            </div>
        </div>
    );
};

export default AddDedupeRule;
