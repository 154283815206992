import React, {
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
    useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import Card from "../../Shared/Card/Card";
import Input from "../../Shared/Input/Input";
import Textarea from "../../Shared/Textarea/Textarea";
import s from "./CreateCollection.module.scss";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import { cloneDeep, get, isEmpty } from "lodash";
import {
    collectionActions,
    createUpdateCollection,
    fetchCollectionById,
    fetchAllHnpCategories,
    fetchCollectionHistory,
} from "../../store/flowmanager/collection";
import { fetchQuestionsData } from "../../store/flowmanager/questions";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import Select from "../../Shared/Select/Select";
import CollectionDetails, {
    HnPData,
} from "../../components/CollectionDetails/CollectionDetails";
import RegisterDetails from "../../components/RegisterDetails/RegisterDetails";
import { createOfferPathsActions } from "../../store/listings/offerPaths";
import { createOfferWallActions } from "../../store/listings/offerWall";
import { fetchOfferPathData } from "../../store/survey/offerpath";
import { fetchOfferWallList } from "../../store/listings/giftCards";
import { fetchAllAdvertisers } from "../../store/listings/offlineConversion";
import { adUnitsActions, fetchAdCreatives } from "../../store/listings/adUnits";
import OfferPathDetails from "../../components/OfferPathDetails/OfferPathDetails";
import { offerPathActions } from "../../store/survey/offerpath";
import { containerActions } from "../../store/flowmanager/container";
import { questionsActions } from "../../store/listings/questions";
import { toastListActions } from "../../store/toastList/toastList";
import OfferWallDetails from "../../components/OfferWallDetails/OfferWallDetails";
import { collectionSelectList } from "../../utils/collectionUtils";
import { usePrevious } from "../../Shared/hooks/use-previous";
import { fetchAllLayout } from "../../store/flowmanager/layout";
import ListingDetails from "../../components/ListingDetails/ListingDetails";
import { fetchAdUnitsData } from "../../store/listings/adUnits";
import History from "../../components/History/History";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import { collectionConfig } from "../../constants/historyConstants";
import { getComparisonData } from "../../utils/historyPopUpUtils";
import { collectionConfirmConfig } from "../../constants/confirmSaveConstants";
import ConfirmAndSave from "../../components/ConfirmAndSave/ConfirmAndSave";
import {
    dealCategoriesActions,
    fetchDealCategoriesData,
} from "../../store/listings/dealCategories";
import { fetchDealOfferWallList } from "../../store/listings/collectionDeals";
import DealOfferWallDetails from "../../components/DealOfferWallDetails/DealOfferWallDetails";
import GratificationDetails from "../../components/GratificationDetails/GratificationDetails";
import ThirdPartyDetails from "../../components/ThirdPartyDetails/ThirdPartyDetails";
import { urlValidationRegex } from "../../constants/validationUtils";
import {
    dependencyApi,
    getUrlParams,
    removeIgnoredItems,
} from "../../utils/globalUtils";
import {
    reactQueryApiCall,
    useFetchAvailableTargetingParams,
} from "../../generalApi/generalApi";
import { SITE_CONFIG } from "../../constants/url";
import CreateFlowCategories from "../CreateFlowCategories/CreateFlowCategories";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { v4 as uuidv4 } from "uuid";

const descriptionMaxLength = 255;

const CreateCollection = forwardRef(
    (
        {
            showInPopup = false,
            editPhaseInPopUp = false,
            createPhaseInPopup = false,
            collectionIdFromParent,
            inContainer = false,
            changeCollectionItem,
            isClonePanel = false,
            selectedFlowDomain = null,
            isTableView,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const saveConfirmCallbacks = useRef(null);

        const collectionState = useSelector(
            state => state.flowmanager.collection
        );
        const createCollection = useSelector(
            state => state.flowmanager.collection.createCollection
        );
        const createCollectionBackup = useSelector(
            state => state.flowmanager.collection.createCollectionBackup
        );
        const hnpCategories = useSelector(
            state => state.flowmanager.collection.createCollection.hnpCategories
        );

        const filterData = (createCollection, createCollectionBackup) => {
            //Added this as temp ID was seen in Confirm and Save
            let oldCreateCollection = createCollection;
            if (!isEmpty(oldCreateCollection)) {
                let cloneArr = { ...oldCreateCollection };
                oldCreateCollection = {
                    ...cloneArr,
                    items: cloneArr?.items?.map(_ => {
                        return {
                            ..._,
                            tcpa: removeIgnoredItems(_.tcpa, ["tempId"]),
                        };
                    }),
                };
            }

            let newCreateCollection = createCollectionBackup;
            if (!isEmpty(newCreateCollection)) {
                let cloneArr = { ...newCreateCollection };
                newCreateCollection = {
                    ...cloneArr,
                    items: cloneArr?.items?.map(_ => {
                        return {
                            ..._,
                            tcpa: removeIgnoredItems(_.tcpa, ["tempId"]),
                        };
                    }),
                };
            }

            return { oldCreateCollection, newCreateCollection };
        };

        const { oldCreateCollection, newCreateCollection } = filterData(
            createCollection,
            createCollectionBackup
        );

        const isCreate =
            window.location.pathname.split("/")[3] === "create" &&
            window.location.pathname.split("/")[2] !== "flow";
        const isCollection =
            window.location.pathname.split("/")[2] === "collection";

        const isCreateCollection =
            (window.location.pathname.split("/")[3] === "create" &&
                window.location.pathname.split("/")[2] !== "flow") ||
            createPhaseInPopup;

        const {
            vertical,
            type,
            name,
            id,
            description,
            mainLoader,
            selectedOfferPath = [],
            selectedOfferWall = [],
            editPhaseVerticalNotSelected,
        } = useSelector(state => state.flowmanager.collection.createCollection);
        const prevType = usePrevious(type ? type : {});
        const creativesList = useSelector(
            state => state.listings.adUnits.createAdUnit.slots.adCreativesList
        );

        let selectedPageId = "Page 1";
        const offerPath = useSelector(state => state.survey.offerPath);
        const list = get(offerPath, "list", []);
        const { createOfferWall } = useSelector(
            state => state.listings.giftCards
        );
        const offerWallList = get(createOfferWall, "slots.offerWallList", []);
        const { loading, historyData, historyDataRaw } = collectionState;
        const detailsList = get(
            collectionState,
            "createCollection.detailsList",
            []
        );
        const itemsList = get(createCollection, "items", []);
        const pageName = window.location.pathname.split("/")[2];

        const offerId = get(
            collectionState,
            "createCollection.selectedOfferPathId",
            null
        );
        const offerWallId = get(
            collectionState,
            "createCollection.selectedOfferWallId",
            null
        );
        const adUnitId = get(
            collectionState,
            "createCollection.selectedAdUnitId",
            null
        );
        const offerWallSlots = get(
            collectionState,
            "createCollection.offerWallSlots",
            null
        );
        const {
            destinationTab = false,
            focusTab = false,
            thirdPartyUrl,
            enableParamForwarding = false,
        } = createCollection;
        const thirdPartyData = {
            thirdPartyUrl,
            destinationTab,
            focusTab,
            enableParamForwarding,
        };

        const authUser = useSelector(state => state.auth.user);
        const isReadOnly =
            !authUser.modules.includes(`FlowManager.Collection.Write`) ||
            isClonePanel ||
            isTableView;

        const writeAccessAvailable = authUser.modules.includes(
            `FlowManager.Flow.LiveAccess.Read`
        );

        const containerDefaultLayout = useSelector(
            state => state.flowmanager.container.createContainer.layout
        );
        const getGratifcationType = get(
            createCollection,
            "gratificationType",
            "AdUnit"
        );
        const urlParams = useParams();
        const history = useHistory();
        const nameRef = useRef(null);
        const idRef = useRef(null);
        const editHnpSidePanelRef = useRef(null);
        const createFlowPageRef = useRef(null);
        const typeList = collectionSelectList;
        const [showPrompt, setShowPrompt] = useState(true);
        const [showSaveConfirm, setShowSaveConfirm] = useState(false);
        const [formChanges, setFormChanges] = useState(false);
        const [adUnitError, setAdUnitError] = useState(false);
        const [urlError, setUrlError] = useState(false);
        const [verticalError, setVerticalError] = useState(false);
        const [hnpData, setHnpData] = useState([]);
        const [hnpLeads, setHnpLeads] = useState([]);
        const [activeHnpId, setActiveHnpId] = useState(null);
        const { apiData = [] } = useFetchAvailableTargetingParams();

        const [liveAccess, setLiveAccess] = useState(false);

        const { apiData: apiSiteConfigData, isLoading: siteConfigLoader } =
            reactQueryApiCall("siteConfigListData", "SiteConfig List", {
                url: SITE_CONFIG.GET_ALL_CONFIGS,
                method: "POST",
            });

        const siteConfigList = useMemo(() => {
            const verticalList = Array.from(
                new Set(apiSiteConfigData?.data?.map(a => a.vertical))
            ).map(ele => {
                return {
                    name: ele.charAt(0).toUpperCase() + ele.slice(1),
                    id: ele,
                };
            });
            return verticalList || [];
        }, [apiSiteConfigData]);

        const editPhaseData = async () => {
            dispatch(createOfferPathsActions.loading(true));
            dispatch(createOfferPathsActions.resetOfferPath());

            if (!creativesList.length) {
                await dispatch(fetchAdCreatives());
            }
            // if (urlParams.id) {
            //     await dispatch(fetchOfferPathDataById(urlParams.id));
            // }
            await dispatch(createOfferPathsActions.loading(false));
        };

        const editOfferWallData = async () => {
            dispatch(createOfferWallActions.loading(true));
            dispatch(createOfferWallActions.resetOfferWall());

            if (!creativesList.length) {
                await dispatch(fetchAdCreatives());
            }
            // if (urlParams.id) {
            //     await dispatch(fetchOfferPathDataById(urlParams.id));
            // }
            await dispatch(createOfferWallActions.loading(false));
        };

        const selectType = async type => {
            await dispatch(collectionActions.setCollectionType(type));
            if (type?.name !== prevType?.name && isCreate && isCollection) {
                await dispatch(collectionActions.resetDetails());
            }
            if (type?.id === "OFFER") {
                setCardTitle("Details");
                setDetailType("OFFER");
                setShowDetails(true);
                dispatch(fetchAllAdvertisers());
                dispatch(fetchOfferPathData());
                if (!creativesList.length) {
                    await dispatch(fetchAdCreatives());
                }
                editPhaseData();
            } else if (type?.id === "OFFER_WALL") {
                setCardTitle("Offer Wall Slots");
                setDetailType("OFFER_WALL");
                setShowDetails(true);
                dispatch(fetchAllAdvertisers());
                dispatch(fetchOfferWallList());
                // if (!creativesList.length) {
                //     await dispatch(fetchAdCreatives());
                // }
                editOfferWallData();
            } else if (type?.id === "REGISTER") {
                setCardTitle("Register");
                setDetailType("REGISTER");
                setShowDetails(true);
            } else if (type?.id === "DETAILS_CONFIRMATION") {
                setCardTitle("Details Confirmation");
                setDetailType("DETAILS_CONFIRMATION");
                setShowDetails(true);
            } else if (type?.id === "SURVEY") {
                setCardTitle("Details");
                setDetailType("SURVEY");
                setShowDetails(true);
            } else if (type?.id === "LISTINGS") {
                setCardTitle("Details");
                setDetailType("LISTINGS");
                setShowDetails(true);
            } else if (type?.id === "GIFT_CARD_VIEWER") {
                setCardTitle("");
                setDetailType("GIFT_CARD_VIEWER");
                setShowDetails(false);
            } else if (type?.id === "GENERIC_PATH_OFFER_WALL") {
                setCardTitle("");
                setDetailType("GENERIC_PATH_OFFER_WALL");
                setShowDetails(false);
            } else if (
                type?.id === "GRATIFICATION" ||
                type?.id === "STANDALONE" ||
                type?.id === "CONFIRMATION"
            ) {
                setCardTitle("Details");
                setDetailType(type?.id);
                setShowDetails(true);
            } else if (type?.id === "DEALS_OFFERWALL") {
                setCardTitle("Details");
                setDetailType("DEALS_OFFERWALL");
                setShowDetails(true);
            } else if (type?.id === "NO_OFFER") {
                setCardTitle("");
                setDetailType("NO_OFFER");
                setShowDetails(false);
            } else if (type?.id === "THIRD_PARTY") {
                setCardTitle("Item Details");
                setDetailType("THIRD_PARTY");
                setShowDetails(true);
            } else if (type?.id === "NO_JOBS") {
                setCardTitle("");
                setDetailType("NO_JOBS");
                setShowDetails(false);
            } else {
                setCardTitle("");
                setDetailType("");
                setShowDetails(false);
            }
        };
        const [cardTitle, setCardTitle] = useState("Details");
        const [detailType, setDetailType] = useState("");
        const [showDetails, setShowDetails] = useState(false);

        const navigateToListing = () => {
            history.replace({ pathname: `/flowmanager/collection/listings` });
        };

        const oncreateCollection = async () => {
            await dispatch(createUpdateCollection())
                .unwrap()
                .then(response => {
                    if (response && !showInPopup) {
                        setShowPrompt(false);
                        if (!showInPopup) navigateToListing();
                    }
                    if (showInPopup && response) {
                        dispatch(
                            containerActions.setCollection(response?.data?.id)
                        );
                    }
                });
        };

        const validateAllFields = () => {
            let isValid = true;
            const isCollectionNameValid = nameRef.current.validate();
            const isDescValid = description.length < descriptionMaxLength;
            let isPositionValid = true;
            let isPositionNegative = false;
            let isPositionDuplicate = false;
            let isItemsAdded = true;
            let isMinDealsValid = true;
            let isOfferSlotsValid = true;
            let isAdUnitAdded = true;
            let isOfferWallSlotsAdded = true;
            let isThirdPartyUrlValid = true;
            let isVerticalAdded = false;
            if (!isDescValid) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: `Description should be less than ${descriptionMaxLength} chars`,
                    })
                );
            }
            if (vertical === "" || vertical === null) {
                setVerticalError(true);
                isVerticalAdded = false;
            } else {
                setVerticalError(false);
                isVerticalAdded = true;
            }
            if (detailType === "LISTINGS") {
                let initialItems = cloneDeep(detailsList);
                if (
                    !isEmpty(initialItems) &&
                    initialItems[0]?.properties?.auBasedPriorityListings?.some(
                        _ => _.position.toString().includes("-")
                    )
                ) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Position can't be negative`,
                        })
                    );
                    isPositionNegative = true;
                } else isPositionNegative = false;
                if (
                    !isEmpty(initialItems) &&
                    initialItems[0]?.properties?.auBasedPriorityListings?.some(
                        _ => !_.position || _.position === 0
                    )
                ) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Position can't be empty or zero`,
                        })
                    );
                    isPositionValid = false;
                } else isPositionValid = true;
                let positionArray =
                    !isEmpty(initialItems) &&
                    initialItems[0]?.properties?.auBasedPriorityListings?.map(
                        item => {
                            return item?.position;
                        }
                    );
                positionArray = positionArray
                    ? positionArray?.filter(v => v !== 0)
                    : [];
                isPositionDuplicate = positionArray?.some((item, idx) => {
                    return positionArray.indexOf(item) !== idx;
                });
                if (isPositionDuplicate) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Duplicate positions`,
                        })
                    );
                }
            }
            if (detailType === "DEALS_OFFERWALL") {
                let initialItems = cloneDeep(
                    itemsList?.filter(_ => _.rank !== -1)
                );
                if (isEmpty(initialItems)) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Item list cannot be empty`,
                        })
                    );
                    isItemsAdded = false;
                } else isItemsAdded = true;
                if (initialItems?.some(_ => isEmpty(_.properties.minDeals))) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Minimum deals cannot be empty`,
                        })
                    );
                    isMinDealsValid = false;
                } else isMinDealsValid = true;
                if (
                    initialItems?.some(_ => isEmpty(_.properties.offerWallId))
                ) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Offer wall slots cannot be empty`,
                        })
                    );
                    isOfferSlotsValid = false;
                } else isOfferSlotsValid = true;
            }
            if (
                detailType === "GRATIFICATION" ||
                detailType === "STANDALONE" ||
                detailType === "CONFIRMATION"
            ) {
                if (getGratifcationType === "AdUnit") {
                    if (!adUnitId) {
                        setAdUnitError(true);
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: `Please select ad unit`,
                            })
                        );
                        isAdUnitAdded = false;
                    } else {
                        isAdUnitAdded = true;
                        setAdUnitError(false);
                    }
                }
                if (getGratifcationType === "OfferWall") {
                    if (isEmpty(offerWallSlots)) {
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: `Please add atleast one slot`,
                            })
                        );
                        isOfferWallSlotsAdded = false;
                    } else isOfferWallSlotsAdded = true;
                }
            }
            if (detailType === "THIRD_PARTY") {
                const isUrlValid = urlValidationRegex.test(thirdPartyUrl);
                if (!isUrlValid) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Please enter valid Url",
                        })
                    );
                    isThirdPartyUrlValid = false;
                    setUrlError(true);
                } else {
                    setUrlError(false);
                    isThirdPartyUrlValid = true;
                }
            }
            if (
                !isCollectionNameValid ||
                !isDescValid ||
                !isPositionValid ||
                isPositionNegative ||
                isPositionDuplicate ||
                !isItemsAdded ||
                !isMinDealsValid ||
                !isOfferSlotsValid ||
                !isAdUnitAdded ||
                !isOfferWallSlotsAdded ||
                !isThirdPartyUrlValid ||
                !isVerticalAdded
            )
                isValid = false;
            return isValid;
        };

        const onSaveHandler = async () => {
            let isFormValid = validateAllFields();
            if (isFormValid) {
                let confirm = true;
                if (createCollection.id) {
                    confirm = await showConfirmAndSave();
                }
                if (confirm) {
                    await oncreateCollection();
                    isFormValid = true;
                }
            }
            return isFormValid;
        };

        const showConfirmAndSave = async () => {
            return new Promise((resolve, reject) => {
                saveConfirmCallbacks.current = {
                    resolve,
                    reject,
                };
                setShowSaveConfirm(true);
            });
        };

        const showHistory = () => {
            historyDataAction(
                fetchCollectionHistory,
                id ? { id: id } : urlParams,
                dispatch,
                collectionActions
            );
        };

        const navigateCallback = () => {
            window.open(`/flowmanager/collection/edit/${id}`, "_blank");
        };

        useImperativeHandle(ref, () => ({
            onSaveHandler: onSaveHandler,
            showHistory: showHistory,
            navigateCallback: navigateCallback,
        }));

        useEffect(() => {
            if (writeAccessAvailable || isCreateCollection) {
                setLiveAccess(true);
            }
            if (!writeAccessAvailable && id) {
                dependencyApi("COLLECTION", id, () => {
                    setLiveAccess(false);
                }).then(res => {
                    const dependencyList = res?.data?.data?.dependencyList;
                    if (!isEmpty(dependencyList)) {
                        setLiveAccess(
                            !dependencyList?.FLOW?.some(
                                _ => _.isLive === true
                            ) || true
                        );
                    } else {
                        setLiveAccess(true);
                    }
                });
            }
        }, [writeAccessAvailable, id]);

        useEffect(() => {
            !urlParams.id && dispatch(collectionActions.resetCollection());
            dispatch(collectionActions.setLoading(true));

            const fetchCollection = async () => {
                let layoutData = await dispatch(fetchAllLayout()).then(res => {
                    return (res && res.payload) || [];
                });
                if (urlParams.id && !editPhaseInPopUp) {
                    if (!createPhaseInPopup) {
                        setShowPrompt(false);
                        await dispatch(
                            fetchCollectionById({
                                id: urlParams.id,
                                history,
                                layoutData,
                            })
                        );
                        setShowPrompt(true);
                    }
                }
                if (editPhaseInPopUp) {
                    if (!createPhaseInPopup) {
                        await dispatch(
                            fetchCollectionById({
                                id: collectionIdFromParent,
                                history,
                                layoutData,
                            })
                        );
                    }
                }
                await dispatch(fetchAllHnpCategories());
                await dispatch(collectionActions.setLoading(false));
            };

            fetchCollection();

            return () => {
                dispatch(collectionActions.resetCollection());
            };
        }, []);

        useEffect(() => {
            const { oldCreateCollection, newCreateCollection } = filterData(
                createCollection,
                createCollectionBackup
            );
            setFormChanges(
                getComparisonData(
                    oldCreateCollection,
                    newCreateCollection,
                    collectionConfirmConfig,
                    true
                )[0]?.length > 0 || false
            );
        }, [createCollection]);

        useEffect(() => {
            const changeType = async type => {
                if (type?.name && type?.id) {
                    if (
                        (!urlParams?.id && !editPhaseInPopUp) ||
                        (!collectionIdFromParent && editPhaseInPopUp)
                    ) {
                        await dispatch(collectionActions.setDetailItems([]));
                    }
                    await dispatch(collectionActions.setTcpaOptions([]));
                    await dispatch(collectionActions.setHasOfferDetails([]));
                    await selectType(type);
                }
            };
            if (!isEmpty(type) && type?.name && type?.id) {
                changeType(type);
            }
            if (type?.id === "SURVEY") {
                dispatch(fetchQuestionsData({ noPagination: true }));
            }
            if (type?.id === "LISTINGS") {
                dispatch(fetchAdUnitsData({ noPagination: true }));
                let initialItems = cloneDeep(detailsList);
                if (
                    !isEmpty(initialItems) &&
                    !isEmpty(initialItems[0]?.hnpLeads)
                ) {
                    setHnpLeads(initialItems[0]?.hnpLeads.map(_ => _.hnpId));
                }
            }
            if (type?.id === "DEALS_OFFERWALL") {
                dispatch(fetchDealCategoriesData({ noPagination: true }));
                dispatch(fetchDealOfferWallList());
            }
            if (
                type?.id === "GRATIFICATION" ||
                type?.id === "STANDALONE" ||
                type?.id === "CONFIRMATION"
            ) {
                dispatch(fetchAdUnitsData({ noPagination: true }));
            }
        }, [type]);

        useEffect(() => {
            if (type?.id === "LISTINGS") {
                let hnpSelectedData =
                    collectionState?.createCollection?.hnpCategories?.filter(
                        _ => hnpLeads?.includes(_.hnpId)
                    ) || [];
                setHnpData(hnpSelectedData);
                let initialItems = cloneDeep(detailsList);
                if (isEmpty(initialItems)) {
                    initialItems = [{ rank: 1, isActive: true }];
                }
                initialItems[0].hnpLeads = hnpSelectedData;
                initialItems[0].hnpLeadIds = !isEmpty(hnpLeads)
                    ? hnpLeads?.map(_ => parseInt(_))
                    : [];
                dispatch(collectionActions.setDetailItems(initialItems));
            }
        }, [hnpLeads, hnpCategories]);

        const toggleHistoryVisibilty = () => {
            dispatch(collectionActions.setHistoryData([]));
            dispatch(collectionActions.setHistoryDataRaw([]));
        };

        const onChangeUrl = val => {
            if (val?.trim()?.length) {
                setUrlError(false);
            } else {
                setUrlError(true);
            }
            dispatch(collectionActions.setThirdPartyURL(val));
        };

        return (
            <>
                {showSaveConfirm && (
                    <ConfirmAndSave
                        hideComparision={true}
                        entityType="COLLECTION"
                        entityId={id}
                        dataUpdated={oldCreateCollection}
                        dataBackup={newCreateCollection}
                        config={collectionConfirmConfig}
                        saveCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(true);
                        }}
                        closeCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(false);
                        }}
                        isConfirm={true}
                    />
                )}
                <div className={s.createCategoriesWrapper}>
                    {(mainLoader ||
                        (detailType === "OFFER" && isEmpty(list)) ||
                        (detailType === "OFFER_WALL" &&
                            isEmpty(offerWallList))) &&
                        !showInPopup && <LinearDeterminate />}

                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            {!showInPopup && (
                                <Card className={`sticky-below-header`}>
                                    <CreateSaveHeader
                                        // Temp disabling to avoid testing
                                        // saveBtn={!formChanges}
                                        saveBtn={!liveAccess}
                                        onSave={!isReadOnly && onSaveHandler}
                                        isBig={true}
                                        title={`${
                                            isCreate || !urlParams?.id
                                                ? "Create"
                                                : "Edit"
                                        } Collection`}
                                        saveLabel={`${
                                            isCreate || !urlParams?.id
                                                ? "Create"
                                                : "Save"
                                        }`}
                                        desc={"Create collection"}
                                        showPrompt={!isReadOnly && showPrompt}
                                        showHamburgerBtn={false}
                                        loading={loading}
                                        showHistoryIcon={
                                            urlParams.id ? true : false
                                        }
                                        showHistory={showHistory}
                                    ></CreateSaveHeader>
                                </Card>
                            )}
                            <Card
                                className={`card-padding ${
                                    showInPopup && s.noCard
                                }`}
                            >
                                <div>
                                    <h3
                                        data-hash-id="collectionBasics"
                                        id="collectionBasics"
                                        className="headerTitle"
                                    >
                                        Basics
                                    </h3>
                                    {id && (
                                        <div className={`row`}>
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                ID
                                            </p>
                                            <p className={`infoText`}>
                                                ID associated with a collection
                                            </p>
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                {id}
                                            </p>
                                        </div>
                                    )}
                                    <div className={"form-row"}>
                                        <div className="headerTitleRequired">
                                            Vertical*
                                        </div>
                                        <p className={`infoText`}>
                                            Select the type of vertical
                                        </p>

                                        <div className={s.dropdownWrapper}>
                                            <Select
                                                options={siteConfigList}
                                                selectedId={
                                                    vertical === ""
                                                        ? null
                                                        : vertical
                                                }
                                                label={"Select Vertical"}
                                                keyField={"name"}
                                                idField={"id"}
                                                onSelect={vertical => {
                                                    dispatch(
                                                        collectionActions.setCollectionVertical(
                                                            vertical?.id
                                                        )
                                                    );
                                                    setVerticalError(false);
                                                }}
                                                onLoadSelectReturn={false}
                                                isError={verticalError}
                                                isDisabled={
                                                    isReadOnly
                                                        ? isReadOnly
                                                        : editPhaseVerticalNotSelected
                                                        ? false
                                                        : editPhaseInPopUp
                                                        ? editPhaseInPopUp
                                                        : createPhaseInPopup
                                                        ? false
                                                        : (urlParams.id &&
                                                              !showInPopup) ||
                                                          !isCreate
                                                        ? true
                                                        : false
                                                }
                                                showOptionToolTip={false}
                                            />
                                        </div>
                                    </div>
                                    <div className={"form-row"}>
                                        <p className="headerTitleRequired">
                                            Type*
                                        </p>
                                        <p className="infoText">
                                            Its an unique identifier name for
                                            the collection
                                        </p>
                                        <Select
                                            label={type || "Select type"}
                                            options={typeList}
                                            // options={
                                            //     showInPopup
                                            //         ? typeListForSidePane
                                            //         : typeList
                                            // }
                                            keyField="name"
                                            onSelect={type => {
                                                selectType(type);
                                            }}
                                            onLoadSelectReturn={false}
                                            selectedId={type?.id}
                                            idField="id"
                                            isDisabled={
                                                isReadOnly ||
                                                !isCreate ||
                                                pageName === "container"
                                            }
                                            showOptionToolTip={false}
                                        />
                                    </div>
                                    <div className={"form-row"}>
                                        <p className="headerTitleRequired">
                                            Name*
                                        </p>
                                        <p className="infoText">
                                            Its an unique identifier name for
                                            the collection
                                        </p>
                                        <Input
                                            ref={nameRef}
                                            className="maxChildContainer"
                                            placeholder="Enter name"
                                            required={true}
                                            onChange={e =>
                                                dispatch(
                                                    collectionActions.setCollectionName(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            value={name}
                                            disabled={isReadOnly}
                                        ></Input>
                                    </div>

                                    <div className={"form-row"}>
                                        <p className="headerTitleRequired">
                                            Description
                                        </p>
                                        <Textarea
                                            className="maxChildContainer"
                                            placeholder="Enter description"
                                            required={true}
                                            onChange={e =>
                                                dispatch(
                                                    collectionActions.setCollectionDesc(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            value={description}
                                            rows={3}
                                            disabled={isReadOnly}
                                            isError={
                                                description?.length >
                                                descriptionMaxLength
                                            }
                                        ></Textarea>
                                    </div>

                                    {detailType === "LISTINGS" && (
                                        <>
                                            <div className={"form-row"}>
                                                <p className="headerTitleRequired">
                                                    HNP Lead Trigger
                                                </p>
                                                <p className="infoText">
                                                    HnP lead trigger selected
                                                    values
                                                </p>
                                                <Select
                                                    label={
                                                        hnpLeads?.length > 0
                                                            ? null
                                                            : "Select"
                                                    }
                                                    options={
                                                        hnpCategories || []
                                                    }
                                                    keyField={"hnpCategory"}
                                                    idField={"hnpId"}
                                                    multiSelect={true}
                                                    selectedIds={
                                                        hnpLeads
                                                            ? hnpLeads.map(_ =>
                                                                  parseInt(_)
                                                              )
                                                            : []
                                                    }
                                                    showOptionWithKeyValue={
                                                        true
                                                    }
                                                    showSearch={true}
                                                    onSelect={leads => {
                                                        setHnpLeads(
                                                            leads.map(
                                                                _ => _.hnpId
                                                            )
                                                        );
                                                    }}
                                                    portal={false}
                                                    isDisabled={isReadOnly}
                                                />
                                            </div>
                                            {hnpData?.length > 0 && (
                                                <div
                                                    className={`${s.hnpDetails}`}
                                                >
                                                    {hnpData.map((_, index) => {
                                                        return (
                                                            <div
                                                                key={`hnp_${index}`}
                                                                className="mt-20"
                                                            >
                                                                <HnPData
                                                                    hnpData={_}
                                                                    setActiveHnpId={
                                                                        setActiveHnpId
                                                                    }
                                                                    editHnpSidePanelRef={
                                                                        editHnpSidePanelRef
                                                                    }
                                                                    setHnpLeads={
                                                                        setHnpLeads
                                                                    }
                                                                    apiData={
                                                                        apiData
                                                                    }
                                                                    readOnly={
                                                                        isReadOnly
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Card>

                            {detailType && showDetails && (
                                <Card className={`${showInPopup && s.noCard}`}>
                                    {(detailType === "REGISTER" ||
                                        detailType ===
                                            "DETAILS_CONFIRMATION") && (
                                        <RegisterDetails
                                            // detailsListState={detailsList}
                                            actions={collectionActions}
                                            checkboxControl={true}
                                            isSidePanel={showInPopup}
                                            isReadOnly={isReadOnly}
                                            inContainer={inContainer}
                                            defaultContainerLayout={
                                                containerDefaultLayout
                                            }
                                            selectedFlowDomain={
                                                selectedFlowDomain
                                            }
                                        />
                                    )}

                                    {detailType === "OFFER" && (
                                        <OfferPathDetails
                                            cardTitle={cardTitle}
                                            isReadOnly={isReadOnly}
                                            isSidePanel={showInPopup}
                                            isLoading={
                                                detailType === "OFFER" &&
                                                isEmpty(list)
                                            }
                                        />
                                    )}
                                    {detailType === "OFFER_WALL" && (
                                        <OfferWallDetails
                                            type="create"
                                            list={offerWallList}
                                            selectedOfferPath={
                                                selectedOfferWall
                                            }
                                            onCreateNewOfferPath={val => {
                                                dispatch(
                                                    offerPathActions.setIsNewOfferPath(
                                                        {
                                                            page: selectedPageId,
                                                            data: val,
                                                        }
                                                    )
                                                );
                                            }}
                                            createOption={
                                                offerPath.pages[selectedPageId]
                                                    ?.basics.option
                                            }
                                            highlightNameError={
                                                offerPath.pages[selectedPageId]
                                                    ?.basics.pathNameError
                                            }
                                            id={offerWallId}
                                            handleOfferWall={offerPath => {
                                                dispatch(
                                                    collectionActions.setSelectedOfferWallDetails(
                                                        offerPath
                                                    )
                                                );
                                                dispatch(
                                                    collectionActions.setSelectedOfferWallId(
                                                        offerPath?.id
                                                    )
                                                );
                                            }}
                                            isSidePanel={showInPopup}
                                            readOnly={isReadOnly}
                                        />
                                    )}
                                    {detailType === "SURVEY" && (
                                        <CollectionDetails
                                            // questionsList={detailsList}
                                            cardTitle={cardTitle}
                                            isReadOnly={isReadOnly}
                                            isSidePanel={showInPopup}
                                            inContainer={inContainer}
                                            defaultContainerLayout={
                                                containerDefaultLayout
                                            }
                                            selectedFlowDomain={
                                                selectedFlowDomain
                                            }
                                            isFlowLive={liveAccess}
                                        />
                                    )}
                                    {detailType === "LISTINGS" && (
                                        <ListingDetails
                                            // questionsList={detailsList}
                                            cardTitle={cardTitle}
                                            isReadOnly={isReadOnly}
                                            isSidePanel={showInPopup}
                                        />
                                    )}
                                    {detailType === "DEALS_OFFERWALL" && (
                                        <DealOfferWallDetails
                                            cardTitle={cardTitle}
                                            isReadOnly={isReadOnly}
                                            isSidePanel={showInPopup}
                                        />
                                    )}
                                    {(detailType === "GRATIFICATION" ||
                                        detailType === "STANDALONE" ||
                                        detailType === "CONFIRMATION") && (
                                        <GratificationDetails
                                            cardTitle={cardTitle}
                                            isReadOnly={isReadOnly}
                                            isSidePanel={showInPopup}
                                            adUnitId={adUnitId}
                                            adUnitError={adUnitError}
                                            setAdUnitError={val =>
                                                setAdUnitError(val)
                                            }
                                            handleAdUnit={adUnit => {
                                                dispatch(
                                                    collectionActions.setSelectedAdUnitId(
                                                        adUnit?.adUnitId
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                    {detailType === "THIRD_PARTY" && (
                                        <ThirdPartyDetails
                                            cardTitle={cardTitle}
                                            isReadOnly={isReadOnly}
                                            action={collectionActions}
                                            onChangeUrl={onChangeUrl}
                                            data={thirdPartyData}
                                            urlError={urlError}
                                            isSidePanel={showInPopup}
                                        />
                                    )}
                                </Card>
                            )}
                        </>
                    )}
                </div>
                {historyData && historyData.length > 0 ? (
                    <History
                        data={historyData}
                        rawData={historyDataRaw}
                        closeHistory={toggleHistoryVisibilty}
                        historyConfig={collectionConfig}
                    />
                ) : null}
                <SidePanelInfinity
                    title={`Edit HNP`}
                    width={"645px"}
                    ref={editHnpSidePanelRef}
                    onSave={async () => {
                        const saveRes = await createFlowPageRef.current?.save();
                        if (saveRes) {
                            const resObject = saveRes.data.data;
                            setHnpData(_ => [
                                ..._.map(ele =>
                                    ele.hnpId === resObject.hnpId
                                        ? resObject
                                        : ele
                                ),
                            ]);
                            await dispatch(fetchAllHnpCategories());
                            editHnpSidePanelRef.current.close();
                        }
                    }}
                    disableSaveBtn={isReadOnly}
                >
                    <CreateFlowCategories
                        passedId={activeHnpId}
                        ref={createFlowPageRef}
                    />
                </SidePanelInfinity>
            </>
        );
    }
);

export default CreateCollection;
