import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import ReactFlow, {
    addEdge,
    Background,
    ConnectionLineType,
    Controls,
    MiniMap,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useStore,
} from "reactflow";
import dagre from "@pinging/dagrejs";
import style from "./FlowRenderer.module.scss";
import Icon from "../Icon/Icon";
import { Handle, MarkerType } from "reactflow";
import TargetingNode from "../../Pages/CreateFlow/TargetingNode";
import SidePanelInfinity from "../SidePanelInfinity/SidePanelInfinity";
import {
    fetchTpObj,
    getNodeDimensions,
    NodeAction,
    NodeRenderType,
    renderTargetingNode,
    beautifyTargettingParams,
    EntityType,
    EntitySubType,
} from "./FlowRendererUtils";
import { useFetchAvailableTargetingParams } from "../../generalApi/generalApi";
import Select from "../Select/Select";
import TagRenderer from "../../components/Renderers/TagRenderer/TagRenderer";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch, useSelector } from "react-redux";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { getTP } from "../../Pages/CreateFlow/AddNode";
import { getPreviewObj } from "../../utils/globalUtils";
import Tooltip from "../Tooltip/Tooltip";
import LayoutEditComp from "../../components/LayoutEditComp/LayoutEditComp";
import { CustomEdge } from "../../Pages/CreateFlow/CustomEdges";

export const ContainerType = {
    REGISTER: "REGISTER",
    SURVEY: "SURVEY",
    OFFER: "OFFER",
    LISTINGS: "LISTINGS",
};

const NODE_CATEGORIES = {
    start: "start",
    end: "end",
    item: "item",
    rule: "rule",
};

const LINK_CATEGORIES = {
    withoutArrow: "without_arrow",
    withArrow: "with_arrow",
};

const DEFAULT_NODE_ID_OFFSET = -5002;
const START_NODE_ID = -5000;
const END_NODE_ID = -5001;
const NEW_RULE_ID_OFFSET = -1;
const DEFAULT_TARGETTING_PARAM = (
    <div className={`${style.nodeWrapper} ${style.targeting}`}>
        <div className={style.legend}></div>
        <div className={style.content}>
            <div className={style.header}>
                <Icon
                    className={"mr-5"}
                    icon={"TargettingParams"}
                    size="12px"
                />
                Targetings
            </div>
            <div className={`${style.value}`}>Default</div>
        </div>
    </div>
);

export function isEmptyObj(obj) {
    if (obj == null || obj == undefined) return true;
    return Object.keys(obj).length == 0;
}

const getLayoutedElements = (nodes, edges, direction = "TB") => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction, align: "UL" });

    let nodeWidth = 300;
    let nodeHeight = 90;

    nodes.forEach(node => {
        // [nodeWidth, nodeHeight] = getNodeDimensions(node);
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach(edge => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach(node => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? "left" : "top";
        node.sourcePosition = isHorizontal ? "right" : "bottom";

        [nodeWidth, nodeHeight] = getNodeDimensions(node);

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    [],
    []
);

const extractItemIdFromTargettingParams = targettingParams => {
    return targettingParams.currItemId
        ? parseInt(targettingParams.currItemId)
        : undefined;
};

const ContainerFlowRenderer = forwardRef(
    (
        {
            container,
            startId,
            rules: propRules,
            addNode,
            readOnly,
            deleteNode,
            vertical,
            featureGroupProperties = {},
        },
        ref
    ) => {
        const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
        const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
        const isTarget = useStore(state => state.connectionNodeId);
        const { apiData: availableTargetings = [] } =
            useFetchAvailableTargetingParams();

        const tragetingParamRef = useRef(null);
        const targetingSidePanelRef = useRef(null);
        const flowWrapperRef = useRef(null);
        const previewItemRef = useRef(null);

        const dispatch = useDispatch();

        const [targetingBranches, setTargetingBranches] = useState([]);
        const [targetSource, setTargetSource] = useState(null);
        const [items, setItems] = useState(null);
        const [previewItem, setPreviewItem] = useState(null);
        const [skipContainer, setSkipContainer] = useState(false);
        const [isSaving, setIsSaving] = useState(false);

        const previewUrl = useMemo(() => {
            if (previewItem) {
                const data = getPreviewObj(
                    previewItem,
                    container?.type,
                    container?.containerLayoutConfig,
                    container?.templateId === null ||
                        container?.templateId === ""
                        ? featureGroupProperties
                        : container?.templateDto?.properties
                );
                return data;
            }
        }, [previewItem]);

        useEffect(() => {
            if (availableTargetings.length) {
                const [node, edges, items] = generateRules();
                const { nodes: layoutedNodes, edges: layoutedEdges } =
                    getLayoutedElements(node, edges, "TB");
                setNodes([...layoutedNodes]);
                setEdges([...layoutedEdges]);
                setItems([...items]);
            }
        }, [propRules, container, isTarget, availableTargetings]);

        useImperativeHandle(ref, () => {
            return {
                openNode: rule => {
                    openTargetingNode(rule);
                },
            };
        });

        function getNodeText(item) {
            const { rank, properties } = item;
            let itemText = "";

            if (properties?.question_details) {
                itemText = `${properties?.question_details?.id} : ${properties?.question_details?.name}`;
            } else if (properties?.fields) {
                itemText = `${properties?.fields?.join(", ")}`;
            }

            item.name = `#${rank} ${itemText}`;
            return (
                <>
                    <div className={`${style.nodeWrapper} ${style.preview}`}>
                        {isTarget && (
                            <Handle
                                type="target"
                                position="left"
                                isValidConnection={connection =>
                                    connection.source === "some-id"
                                }
                                className={style.targetHandle}
                            />
                        )}
                        <div className={style.previewContent}>
                            <div className={style.rank}>{rank}</div>
                            <ul>
                                <li>{itemText}</li>
                            </ul>
                        </div>
                        <NodeAction
                            rule={item}
                            prefix={""}
                            openDetails={previewItem => {
                                setPreviewItem(previewItem);
                                previewItemRef.current.open();
                            }}
                            portalElement={flowWrapperRef.current}
                            goto={async rule => {
                                await setTargetSource(_ => ({
                                    ..._,
                                    source: rule.id,
                                }));
                                targetingSidePanelRef?.current?.open();
                            }}
                            readOnly={readOnly}
                        />
                    </div>
                </>
            );
        }

        function createNode(id, text, category, colour, extraData, nodeType) {
            let root = {
                style: {
                    width: "300px",
                },
            };
            root.nodeType = nodeType;
            root.data = {
                label: text,
            };
            root.id = id;
            root.category = category;
            if (extraData) {
                root.extraData = extraData;
            }
            if (colour) {
                root.color = colour;
            }

            return root;
        }

        function createItemNode(item) {
            return createNode(
                item.id,
                getNodeText(item),
                NODE_CATEGORIES.item,
                undefined,
                undefined,
                NodeRenderType.PREVIEW_CONTAINER
            );
        }

        function generateRules() {
            let nodeData = [];
            let linkData = [];
            let items = JSON.parse(JSON.stringify(container.items));
            let rules = propRules;
            items = items.sort(
                (a, b) =>
                    parseInt(a.rank.toString()) - parseInt(b.rank.toString())
            );

            initItemNodes(items, nodeData, linkData);

            initRuleNodes(rules, items, nodeData, linkData);

            return [nodeData, linkData, items];
        }

        function initItemNodes(sortedItems, nodeData, linkData) {
            sortedItems.forEach(i => nodeData.push(createItemNode(i)));

            if (sortedItems.length) {
                nodeData.push({
                    id: START_NODE_ID,
                    category: NODE_CATEGORIES.start,
                    nodeType: NodeRenderType.START_END,
                    data: {
                        label: (
                            <div
                                className={`${style.start} ${style.nodeWrapper}`}
                            >
                                <span>Start</span>
                            </div>
                        ),
                    },
                });
                nodeData.push({
                    id: -1000,
                    category: NODE_CATEGORIES.end,
                    nodeType: NodeRenderType.START_END,
                    data: {
                        label: (
                            <div
                                className={`${style.start}  ${style.nodeWrapper} `}
                            >
                                <div className={`${style.psuedoContainer}`}>
                                    <Tooltip
                                        message={
                                            "Rules mapped here are applied to all Items"
                                        }
                                        position="top"
                                    >
                                        <Icon
                                            icon={"InfoFilled"}
                                            size={"12px"}
                                            className={"mr-5"}
                                        ></Icon>
                                    </Tooltip>
                                    <span>All Items</span>
                                    <NodeAction
                                        rule={{}}
                                        portalElement={flowWrapperRef.current}
                                        goto={async () => {
                                            await setTargetSource(_ => ({
                                                ..._,
                                                source: null,
                                            }));
                                            setSkipContainer(true);
                                            targetingSidePanelRef?.current?.open();
                                        }}
                                        readOnly={readOnly}
                                    />
                                </div>
                            </div>
                        ),
                    },
                });
                nodeData.push({
                    id: END_NODE_ID,
                    category: NODE_CATEGORIES.end,
                    nodeType: NodeRenderType.START_END,
                    data: {
                        label: (
                            <div
                                className={`${style.start} ${style.nodeWrapper} ${style.end}`}
                            >
                                {isTarget && (
                                    <Handle
                                        type="target"
                                        position="left"
                                        isValidConnection={connection =>
                                            connection.source === "some-id"
                                        }
                                        className={style.targetHandle}
                                    />
                                )}
                                <span>End</span>
                            </div>
                        ),
                    },
                });
                addLinkNode(
                    nodeData,
                    linkData,
                    START_NODE_ID,
                    sortedItems[0].id,
                    DEFAULT_TARGETTING_PARAM,
                    DEFAULT_NODE_ID_OFFSET - sortedItems.length,
                    NODE_CATEGORIES.rule
                );
                addLinkNode(
                    nodeData,
                    linkData,
                    sortedItems[sortedItems.length - 1].id,
                    END_NODE_ID,
                    DEFAULT_TARGETTING_PARAM,
                    DEFAULT_NODE_ID_OFFSET - sortedItems.length - 1,
                    NODE_CATEGORIES.rule
                );
            }

            sortedItems.forEach((i, index) => {
                if (index + 1 < sortedItems.length)
                    addLinkNode(
                        nodeData,
                        linkData,
                        sortedItems[index].id,
                        sortedItems[index + 1].id,
                        DEFAULT_TARGETTING_PARAM,
                        DEFAULT_NODE_ID_OFFSET - index,
                        NODE_CATEGORIES.rule
                    );
            });
        }

        function openTargetingNode(rule) {
            const option = fetchTpObj(
                rule.targettingParams,
                availableTargetings
            );
            setSkipContainer(!rule.entityId);

            setTargetSource({
                target: rule.entityId,
                source: rule.targettingParams?.currItemId,
            });
            setTargetingBranches([
                {
                    ...rule,
                    name: option.opsName,
                    value: rule.targettingParams?.value || [],
                },
            ]);
            targetingSidePanelRef?.current?.open();
        }

        function createRuleData(rule, items, nodeData, linkData) {
            let parsedTargettingParams;
            try {
                parsedTargettingParams = rule.targettingParams || {};
            } catch (err) {}

            let lData;
            const destItemIndex = items
                .map(i => i.id)
                .lastIndexOf(rule.entityId);

            const targetingNode = (
                <div
                    className={`${style.nodeWrapper} ${style.router} ${
                        style.routerIncreasedHeight
                    } ${!rule.entityId ? style.cr : ""}`}
                    onClick={() => {
                        openTargetingNode(rule);
                    }}
                >
                    {isTarget && (
                        <Handle
                            type="target"
                            position="left"
                            className={style.targetHandle}
                        />
                    )}

                    <div
                        className={`${style.tpContent} ${style.tpContent__previewContainer}`}
                    >
                        <div className="dp-parent dp-parent-ver-center">
                            {renderTargetingNode(
                                rule.targettingParams,
                                availableTargetings
                            )}
                        </div>

                        <div className={style.text}>
                            {beautifyTargettingParams(
                                rule.targettingParams,
                                availableTargetings,
                                true,
                                rule.possibleValues || []
                            )}
                        </div>

                        <NodeAction
                            rule={rule}
                            prefix={""}
                            deleteNode={deleteNode}
                            portalElement={flowWrapperRef.current}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            );
            if (parsedTargettingParams) {
                const sourceItemId = extractItemIdFromTargettingParams(
                    parsedTargettingParams
                );
                const sourceItemIndex = items
                    .map(i => i.id)
                    .lastIndexOf(sourceItemId);
                if (sourceItemIndex > -1 && destItemIndex > -1) {
                    lData = {
                        ...rule,
                        source: items[sourceItemIndex].id,
                        target: items[destItemIndex].id,
                        category: NODE_CATEGORIES.rule,
                    };
                } else if (sourceItemIndex > -1) {
                    lData = {
                        ...rule,
                        source: items[sourceItemIndex].id,
                        target: END_NODE_ID,
                        category: NODE_CATEGORIES.rule,
                    };
                } else if (destItemIndex > -1) {
                    lData = {
                        ...rule,
                        source: -1000,
                        target: items[destItemIndex].id,
                        category: NODE_CATEGORIES.rule,
                    };
                } else {
                    lData = {
                        ...rule,
                        source: -1000,
                        target: END_NODE_ID,
                        category: NODE_CATEGORIES.rule,
                    };
                }
            }

            if (lData) {
                addLinkNode(
                    nodeData,
                    linkData,
                    lData.source,
                    lData.target,
                    targetingNode,
                    lData.id,
                    lData.category
                );
            }
        }

        function createLinkData(
            source,
            target,
            category,
            colour = "black",
            width
        ) {
            return {
                source: source,
                target: target,
                category: category,
                type: "custom",
            };
        }

        function addLinkNode(
            nodeData,
            linkData,
            source,
            target,
            text,
            id,
            category
        ) {
            const linkArr = [];
            let ruleNodeData;

            if (text == DEFAULT_TARGETTING_PARAM) {
                linkData.push(
                    createLinkData(
                        source,
                        target,
                        LINK_CATEGORIES.withoutArrow,
                        "black",
                        2.0
                    )
                );
            } else {
                ruleNodeData = createNode(
                    id,
                    text,
                    category,
                    "#dbd9d3",
                    {
                        fromNodeId: source,
                        toNodeId: target,
                    },
                    NodeRenderType.CONTAINER
                );

                linkData.push(
                    createLinkData(
                        source,
                        ruleNodeData.id,
                        LINK_CATEGORIES.withoutArrow,
                        "green",
                        1.0
                    )
                );
                linkData.push(
                    createLinkData(
                        ruleNodeData.id,
                        target,
                        LINK_CATEGORIES.withArrow,
                        "green",
                        1.0
                    )
                );
                nodeData.push(ruleNodeData);
            }

            linkData.push(...linkArr);
        }

        function initRuleNodes(rules, sortedItems, nodeData, linkData) {
            const itemRules = rules.filter(
                r =>
                    r.ruleSetId == startId &&
                    r.entityType == EntityType.ACTIONS &&
                    (r.entitySubType == EntitySubType.goto ||
                        r.entitySubType == EntitySubType.next)
                // && items.map((i) => i.id).includes(r.entityId)
            );
            itemRules.forEach(r =>
                createRuleData(r, sortedItems, nodeData, linkData)
            );
        }

        const onConnect = useCallback(params => {
            const existingConditionalRoutes = propRules.find(
                _ =>
                    _.entityType === EntityType.ACTIONS &&
                    _.entitySubType === EntitySubType.goto &&
                    _.id === parseInt(params.source)
            );
            if (existingConditionalRoutes) {
                existingConditionalRoutes.entityId = parseInt(params.target);
                addNode(existingConditionalRoutes);
            } else {
                const source = parseInt(params.source);
                const target = parseInt(params.target);
                if (target < 0) {
                    setSkipContainer(true);
                }
                setTargetSource({
                    source: source > 0 ? source : null,
                    target: target > 0 ? target : null,
                });
                if (!source && !target) {
                    setSkipContainer(true);
                }
                targetingSidePanelRef?.current?.open();
            }
        }, []);

        const EdgeTypes = useMemo(
            () => ({
                custom: props => {
                    return (
                        <CustomEdge
                            parentRef={flowWrapperRef}
                            readOnly={readOnly}
                            id={props.id}
                            key={props.id}
                            {...props}
                            noCustomFunctionForLinesPlot={true}
                        />
                    );
                },
            }),
            [edges, readOnly]
        );

        return (
            <>
                <div className={style.flowWrapper} ref={flowWrapperRef}>
                    <ReactFlow
                        nodes={nodes.map(_ => ({
                            ..._,
                            id: _?.id?.toString(),
                        }))}
                        edges={edges.map(_ => ({
                            ..._,
                            source: _?.source?.toString(),
                            target: _?.target?.toString(),
                        }))}
                        minZoom={0.1}
                        edgeTypes={EdgeTypes}
                        defaultEdgeOptions={{
                            style: {
                                stroke: 2,
                            },
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                            },
                        }}
                        fitView
                        {...{
                            onNodesChange,
                            onConnect,
                        }}
                    >
                        <Background />
                        {!readOnly && <Controls />}
                    </ReactFlow>
                    <SidePanelInfinity
                        disableSaveBtn={
                            readOnly ||
                            (!skipContainer && !targetSource?.target)
                        }
                        onSave={
                            !readOnly
                                ? async () => {
                                      setIsSaving(true);
                                      let data =
                                          tragetingParamRef.current.getData();
                                      if (!data || !data.length) {
                                          dispatch(
                                              toastListActions.setToastList({
                                                  type: "Error",
                                                  message:
                                                      "Cannot add empty targeting",
                                              })
                                          );
                                          return;
                                      }
                                      data = data.map(_ => ({
                                          ..._,
                                          entityId:
                                              targetSource?.target &&
                                              !skipContainer
                                                  ? parseInt(
                                                        targetSource.target
                                                    )
                                                  : _.entityId || null,
                                          ruleSetId: parseInt(startId),
                                          entitySubType: !skipContainer
                                              ? EntitySubType.goto
                                              : EntitySubType.next,
                                          entityType: EntityType.ACTIONS,
                                          nextSetId: _.nextSetId || null,
                                          id: _.id || null,
                                          targettingParams:
                                              _.value && _.type
                                                  ? {
                                                        currItemId:
                                                            targetSource?.source
                                                                ? parseInt(
                                                                      targetSource.source
                                                                  )
                                                                : _
                                                                      .targettingParams
                                                                      ?.currItemId ||
                                                                  null,
                                                        type: "OR",
                                                        value: _.value.map(
                                                            v => {
                                                                if (
                                                                    typeof v ===
                                                                    "object"
                                                                ) {
                                                                    if (
                                                                        v.valueOne &&
                                                                        v.valueSecond
                                                                    ) {
                                                                        return {
                                                                            type: "AND",
                                                                            value: getTP(
                                                                                _.type,
                                                                                v,
                                                                                availableTargetings
                                                                            ),
                                                                        };
                                                                    }
                                                                }

                                                                return {
                                                                    key: _.type,
                                                                    operator:
                                                                        "EQUAL",
                                                                    type: "EXPRESSION",
                                                                    value: v,
                                                                };
                                                            }
                                                        ),
                                                    }
                                                  : {},
                                      }));
                                      await addNode(data[0]);
                                      setTargetingBranches([]);
                                      setTargetSource(null);
                                      setSkipContainer(false);
                                      setIsSaving(false);
                                      targetingSidePanelRef?.current?.close();
                                  }
                                : null
                        }
                        onClose={() => {
                            setTargetingBranches([]);
                            setTargetSource(null);
                            setSkipContainer(false);
                        }}
                        title={"Conditional Redirect"}
                        width={"500px"}
                        ref={targetingSidePanelRef}
                        saveButtonText={
                            targetingBranches.length ? "Update" : "Add"
                        }
                        showLoader={isSaving}
                    >
                        <>
                            <div>
                                <div className={"row"}>
                                    <p className={`headerTitleRequired`}>
                                        Source*
                                    </p>
                                    <Select
                                        options={items}
                                        keyField={"name"}
                                        idField={"id"}
                                        isDisabled={true}
                                        selectedId={targetSource?.source}
                                        label={
                                            targetSource?.source
                                                ? null
                                                : "All Items"
                                        }
                                        onLoadSelectReturn={false}
                                        onSelect={item => {
                                            setTargetSource(_ => ({
                                                ..._,
                                                source: item.id,
                                            }));
                                        }}
                                    />
                                </div>
                                <div className={"row"}>
                                    <p
                                        className={`headerTitleRequired dp-parent dp-parent-hor-space-between`}
                                    >
                                        Target*
                                        {/* Temp for making some other fixes live */}
                                        <ToggleSwitch
                                            isToggleOn={skipContainer}
                                            setIsToggleOn={e => {
                                                setSkipContainer(e);
                                                if (!e) {
                                                    setTargetSource(_ => ({
                                                        ..._,
                                                        target: null,
                                                    }));
                                                }
                                            }}
                                            setText="Skip Container"
                                            disabled={
                                                readOnly ||
                                                !targetSource?.source
                                            }
                                        >
                                            Skip Container
                                        </ToggleSwitch>
                                    </p>
                                    <Select
                                        options={items}
                                        keyField={"name"}
                                        idField={"id"}
                                        selectedId={targetSource?.target}
                                        isDisabled={readOnly || skipContainer}
                                        label={
                                            targetSource?.target
                                                ? null
                                                : "Select Target"
                                        }
                                        onLoadSelectReturn={false}
                                        onSelect={item => {
                                            setTargetSource(_ => ({
                                                ..._,
                                                target: item.id,
                                            }));
                                        }}
                                    />
                                </div>
                                {(targetSource?.target || skipContainer) && (
                                    <>
                                        <div className={"row pt-10"}>
                                            <p className={`headerTitle`}>
                                                Select Condition
                                            </p>
                                            <div className={"row"}>
                                                <TargetingNode
                                                    ref={tragetingParamRef}
                                                    branches={targetingBranches}
                                                    singleBranch={true}
                                                    hideDelete={true}
                                                    customAnswerAndSkipAnswerSupport={
                                                        false
                                                    }
                                                    editNode={
                                                        targetingBranches.length
                                                    }
                                                    readOnly={readOnly}
                                                ></TargetingNode>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    </SidePanelInfinity>
                    <SidePanelInfinity
                        onClose={() => {
                            setPreviewItem(null);
                        }}
                        title={"Item Details"}
                        width={"500px"}
                        ref={previewItemRef}
                    >
                        <div className={style.itemDetailsWrapper}>
                            {previewItem?.properties?.question_details && (
                                <ul>
                                    <li>
                                        <label>Question Title : </label>
                                        <span>
                                            {
                                                previewItem?.properties
                                                    ?.question_details?.question
                                            }
                                        </span>
                                    </li>
                                    <li>
                                        <label>Question Name : </label>
                                        <span>
                                            {
                                                previewItem?.properties
                                                    ?.question_details?.name
                                            }
                                        </span>
                                    </li>
                                    <li>
                                        <label>Answers : </label>
                                        <ul>
                                            {previewItem?.properties?.question_details?.answers?.map(
                                                ans => (
                                                    <li>
                                                        <TagRenderer
                                                            type={"default"}
                                                        >
                                                            {ans.answer}
                                                        </TagRenderer>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            )}
                            <div className={style.previewContainer}>
                                <div className={style.iframeHolder}>
                                    <LayoutEditComp
                                        vertical={vertical}
                                        defaultConfigs={[]}
                                        item={previewUrl}
                                        hidePreviewHeader={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </SidePanelInfinity>
                </div>
            </>
        );
    }
);

export default forwardRef((props, ref) => (
    <ReactFlowProvider>
        <ContainerFlowRenderer ref={ref} {...props} />
    </ReactFlowProvider>
));
