import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import { DEALS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { cloneDeep, get } from "lodash";
import {
    getRandomId,
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";

const initialState = {
    list: [],
    updatedAt: new Date(),
    loading: false,
    mainLoader: false,
    descError: false,
    leftPanel: {
        basics: { error: false },
        slots: { error: false },
    },
    createOfferWall: {
        slots: {
            dealCategoryList: [],
            dealCategoryUpdatedList: [],
            offerWallList: [],
            minDeals: "",
            categoryID: "",
            offerWalls: [],
            categoryName: "",
            selectedListItems: [],
            editMindeals: "",
            editCategoryID: "",
            editOfferWalls: [],
            editCategoryName: "",
            editId: [],
            deletedSlots: [],
            deletedSlotAds: [],
            expandCollapseTable: false,
        },
    },
    createGiftCard: {
        description: "",
        displayImgURL: "",
        name: "",
        orgName: "",
        rewardValue: "",
        dealConfigs: [],
    },
    page: {
        loading: false,
        mainLoader: false,
        showPrompt: true,
    },
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.GIFT_CARDS.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.GIFT_CARDS.filterOperator,
        filtersOperator: FILTERS.GIFT_CARDS.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "giftcards"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const mapGiftCardData = data => {
    let initialData = cloneDeep(data);
    initialData?.data?.forEach(_ => {
        var count = 0;
        for (const item of _.dealConfigs) {
            count += item.minDeals * 1;
        }
        (_.mediaUrl = _.displayImgURL), (_.minDeals = count);
    });
    return initialData;
};

export const fetchGiftCardsData = createAsyncThunk(
    "deals/giftcards/listings",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const pagination = get(listings, "giftCards.pagination", {});
        const params = paginationParams(pagination);
        let tagFilters = get(
            listings,
            "giftCards.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.GIFT_CARDS
        );
        const filterParamsList = [
            "filters",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(giftCards.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.giftCards.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.GIFT_CARDS,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.giftCards.list", []);
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                DEALS.FETCH_GIFT_CARDS_LIST,
                "POST",
                filterParamsData,
                {},
                null,
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch Gift cards",
                        })
                    );
                    dispatch(
                        giftCardsActions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(giftCardsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metadata: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            const errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.GIFT_CARDS}`;
            dispatch(giftCardsActions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            const errorMessage = `${MESSAGES.TABLE.noData} Create a new Promo`;
            dispatch(giftCardsActions.setTableError(errorMessage));
        }
        data = mapGiftCardData(data);
        return data;
    }
);

export const deleteGiftCard = createAsyncThunk(
    "deals/giftcards/deleteGiftCard",
    async id => {
        const response = await customFetch(
            `${DEALS.DELETE_GIFT_CARD}/${id}`,
            "DELETE"
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete Promo.",
                })
            );
        }
        return get(response, "data", []);
    }
);

export const fetchGiftCard = createAsyncThunk(
    "deals/giftcards/fetchgiftcard",
    async (id, { getState, dispatch }) => {
        const categoryDropdown =
            getState().listings.giftCards.createOfferWall.slots
                .dealCategoryUpdatedList;
        const response = await customFetch(`${DEALS.FETCH_GIFT_CARD}/${id}`);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Promo.",
                })
            );
        }
        return [get(response, "data", []), categoryDropdown];
    }
);

export const createGiftCard = createAsyncThunk(
    "deals/giftcards/creategiftcard",
    async (_, { getState, dispatch }) => {
        const data = getState().listings.giftCards.createGiftCard;
        const slots =
            getState().listings.giftCards.createOfferWall.slots
                .selectedListItems;
        const response = await customFetch(
            DEALS.CREATE_GIFT_CARD,
            "POST",
            createParams(data, slots, "create")
        );
        if (response == null || response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create Promo",
                })
            );
        }
        return get(response, "data", null);
    }
);

export const updateGiftCard = createAsyncThunk(
    "deals/giftcards/creategiftcard",
    async (id, { getState, dispatch }) => {
        const data = getState().listings.giftCards.createGiftCard;
        const slots =
            getState().listings.giftCards.createOfferWall.slots
                .selectedListItems;
        const deletedSlots =
            getState().listings.giftCards.createOfferWall.slots.deletedSlots;
        const deletedSlotAds =
            getState().listings.giftCards.createOfferWall.slots.deletedSlotAds;
        const response = await customFetch(
            `${DEALS.UPDATE_GIFT_CARD}/${id}`,
            "PUT",
            createParams(data, slots, "update", deletedSlots, deletedSlotAds)
        );
        if (response == null || response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update Promo",
                })
            );
        }
        return get(response, "data", null);
    }
);

const createParams = (
    data,
    slotData,
    type,
    deletedSlots = [],
    deletedSlotAds = []
) => {
    let params = {};
    params.description = data.description;
    params.displayImgURL = data.displayImgURL;
    params.name = data.name;
    params.orgName = data.orgName;
    params.rewardValue = `$${data.rewardValue}`;
    if (type == "create") {
        params.dealConfigs =
            slotData.length == 0
                ? []
                : slotData.map(element => ({
                      minDeals: !isNaN(element.minDeals)
                          ? parseInt(element.minDeals)
                          : element.minDeals,
                      categoryID: element.categoryID,
                      offerWalls: element.offerWalls.map(_ => ({
                          id: _.offerWall,
                          priority: _.priority,
                      })),
                      categoryName: element.categoryName,
                  }));
    } else {
        if (slotData.length == 0) {
            params.dealConfigs = [];
        } else {
            if (deletedSlots.length == 0) {
                params.dealConfigs = slotData.map(element => ({
                    minDeals: !isNaN(element.minDeals)
                        ? parseInt(element.minDeals)
                        : element.minDeals,
                    categoryID: element.categoryID,
                    offerWalls: element.offerWalls
                        .filter(obj => {
                            return deletedSlotAds.findIndex(
                                _ => _.ad.id === obj.id
                            );
                        })
                        .map(_ => ({
                            id: _.offerWall,
                            priority: _.priority,
                        })),
                    categoryName: element.categoryName,
                }));
            } else {
                const deletedSlotsId = deletedSlots.map(element => {
                    return element.categoryID;
                });
                const slotDataArray = slotData.filter(element => {
                    if (!deletedSlotsId.includes(element.categoryID)) {
                        return {
                            minDeals: !isNaN(element.minDeals)
                                ? parseInt(element.minDeals)
                                : element.minDeals,
                            categoryID: element.categoryID,
                            offerWalls: element.offerWalls
                                .filter(obj => {
                                    return deletedSlotAds.findIndex(
                                        _ => _.ad.id === obj.id
                                    );
                                })
                                .map(_ => ({
                                    id: _.offerWall,
                                    priority: _.priority,
                                })),
                            categoryName: element.categoryName,
                        };
                    }
                });
                params.dealConfigs = slotDataArray;
            }
        }
    }
    return params;
};

export const fetchOfferWallList = createAsyncThunk(
    "listings/deals/giftcards/offerwalllist",
    async (_, { dispatch }) => {
        const response = await customFetch(DEALS.FETCH_OFFERWALL_LIST);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Offer Wall List!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const fetchDealCategoryList = createAsyncThunk(
    "listings/deals/giftcards/dealCategorylist",
    async (_, { dispatch }) => {
        const response = await customFetch(DEALS.FETCH_DEALCATEGORY_LIST);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Deal Category List!",
                })
            );
        }
        return get(response, "data", []);
    }
);

const getRewardValue = value => {
    const result = value.split("$");
    return result.length === 1 ? result[0] : result[1];
};

export const onFilterApplied = createAsyncThunk(
    "listings/giftCards/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.giftCards.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.GIFT_CARD.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const changeToggle = (array, id) => {
    let expandAllFlag = null;
    let expandLength = 0;
    let collapseLength = 0;
    let selectedListItemsCopy = JSON.parse(JSON.stringify(array));
    selectedListItemsCopy.map(item => {
        if (item.rowId == id) {
            return (item.showAccordionTable = !item.showAccordionTable);
        }
    });
    selectedListItemsCopy.forEach(element => {
        if (element.showAccordionTable) {
            expandLength = expandLength + 1;
        }
        if (!element.showAccordionTable) {
            collapseLength = collapseLength + 1;
        }
    });
    if (expandLength === selectedListItemsCopy.length) expandAllFlag = true;
    if (collapseLength === selectedListItemsCopy.length) expandAllFlag = false;
    return { selectedListItemsCopy, expandAllFlag };
};

const giftCards = createSlice({
    name: "giftCards",
    initialState,
    reducers: {
        setMinDeals(state, action) {
            state.createOfferWall.slots.minDeals = action.payload;
        },
        setOfferWalls(state, action) {
            state.createOfferWall.slots.offerWalls = action.payload;
        },
        setCategoryName(state, action) {
            state.createOfferWall.slots.categoryName = action.payload;
        },
        setCategoryId(state, action) {
            state.createOfferWall.slots.categoryID = action.payload;
        },
        setSelectedListItems(state, action) {
            state.createOfferWall.slots.selectedListItems = action.payload;
        },
        addSelectedListItems(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.selectedListItems = data;
        },
        setEditId(state, action) {
            state.createOfferWall.slots.editId = action.payload;
        },
        setEditMinDeals(state, action) {
            state.createOfferWall.slots.editMindeals = action.payload;
        },
        setEditCategoryID(state, action) {
            state.createOfferWall.slots.editCategoryID = action.payload;
        },
        setEditOfferWalls(state, action) {
            state.createOfferWall.slots.editOfferWalls = action.payload;
        },
        setEditCategoryName(state, action) {
            state.createOfferWall.slots.editCategoryName = action.payload;
        },
        setDeletedSlots(state, action) {
            state.createOfferWall.slots.deletedSlots = action.payload;
        },
        revertDeleteSlots(state, action) {
            const { data } = action.payload;
            const index = state.createOfferWall.slots.deletedSlots.findIndex(
                _ => _.id === data.id
            );
            state.createOfferWall.slots.deletedSlots.splice(index, 1);
        },
        updateCategoryIdDropdown(state, action) {
            state.createOfferWall.slots.dealCategoryUpdatedList =
                action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setOfferPathSlotsError(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.error = data;
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        updateState(state, action) {
            state.createGiftCard = {
                ...state.createGiftCard,
                [action.payload.field]: action.payload.value,
            };
        },
        revertDeleteSlotAds(state, action) {
            const { data, page } = action.payload;
            const index = state.createOfferWall.slots.deletedSlotAds.findIndex(
                _ => _.ad.id === data.id
            );
            state.createOfferWall.slots.deletedSlotAds.splice(index, 1);
        },
        setDeletedSlotAds(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.deletedSlotAds.push(data);
        },
        resetState(state) {
            state.page.showPrompt = true;
            state.createGiftCard = initialState.createGiftCard;
            state.descError = false;
            state.leftPanel = initialState.leftPanel;
            state.createOfferWall.slots.selectedListItems = [];
            state.createOfferWall.slots.deletedSlots = [];
            state.createOfferWall.slots.minDeals = "";
            state.createOfferWall.slots.categoryID = "";
            state.createOfferWall.slots.offerWalls = [];
            state.createOfferWall.slots.categoryName = [];
            state.createOfferWall.slots.expandCollapseTable = false;
        },
        setCreatePageLoader(state, action) {
            state.page.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.page.mainLoader = action.payload;
        },
        setShowPrompt(state, action) {
            state.page.showPrompt = action.payload;
        },
        setDescError(state, action) {
            state.descError = action.payload;
        },
        setBasicsError(state, action) {
            state.leftPanel.basics.error = action.payload;
        },
        setSlotsError(state, action) {
            state.leftPanel.slots.error = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        toggleShowAccordionTable(state, action) {
            const { data, list } = action.payload;
            const { selectedListItemsCopy, expandAllFlag } = changeToggle(
                list,
                data
            );
            state.createOfferWall.slots.selectedListItems =
                selectedListItemsCopy;
            if (expandAllFlag != null) {
                state.createOfferWall.slots.expandCollapseTable = expandAllFlag;
            }
        },
        expandCollapseTableToggle(state, action) {
            const { page, data } = action.payload;
            state.createOfferWall.slots.expandCollapseTable = data;
        },
        expandCollapseTable(state, action) {
            const { data } = action.payload;
            state.createOfferWall.slots.selectedListItems = data;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchGiftCardsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchGiftCardsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list = get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchGiftCard.fulfilled, (state, action) => {
            const gc = action.payload[0];
            const params = {
                description: gc.description,
                displayImgURL: gc.displayImgURL,
                name: gc.name,
                orgName: gc.orgName,
                rewardValue: getRewardValue(gc.rewardValue),
                dealConfigs: gc.dealConfigs,
            };
            state.createGiftCard = params;
            if (gc.dealConfigs.length > 0) {
                const categoryIds = [];
                const dealList = action.payload[1];
                const dealListIds = dealList.map(obj => {
                    return obj.id;
                });
                const data = gc.dealConfigs
                    .map(element => {
                        categoryIds.push(element.categoryID);

                        return {
                            ...element,
                            type: "PriorityGroup",
                            offerWalls: element.offerWalls.map((i, index) => {
                                if (typeof i === "object") {
                                    return {
                                        id: getRandomId(),
                                        offerWall: i.id,
                                        priority: i.priority,
                                        innerRowId: getRandomId(),
                                    };
                                } else {
                                    return {
                                        id: getRandomId(),
                                        offerWall: i.toString(),
                                        priority:
                                            element.offerWalls.length - index,
                                        innerRowId: getRandomId(),
                                    };
                                }
                            }),
                            rowId: getRandomId(),
                            id: getRandomId(),
                        };
                    })
                    .filter(element =>
                        dealListIds.includes(element.categoryID)
                    );

                const updatedDealCategory_Dropdown = dealList.filter(
                    element => !categoryIds.includes(element.id)
                );
                state.createOfferWall.slots.dealCategoryUpdatedList =
                    updatedDealCategory_Dropdown;
                state.createOfferWall.slots.selectedListItems = data;
            }
        });
        builder.addCase(fetchOfferWallList.pending, state => {
            // state.loading = true;
        });
        builder.addCase(fetchOfferWallList.fulfilled, (state, action) => {
            // state.loading = false;
            state.createOfferWall.slots.offerWallList = action.payload;
        });
        builder.addCase(fetchDealCategoryList.pending, state => {
            // state.loading = true;
        });
        builder.addCase(fetchDealCategoryList.fulfilled, (state, action) => {
            // state.loading = false;
            state.createOfferWall.slots.dealCategoryList = action.payload;
            state.createOfferWall.slots.dealCategoryUpdatedList =
                action.payload;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const giftCardsActions = giftCards.actions;
export default giftCards.reducer;
