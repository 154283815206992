import { get, isEmpty } from "lodash";
import React, { useEffect, useState, useRef, memo } from "react";
import TableFlex from "../TableFlex/TableFlex";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import EditOfferSlots from "../EditOfferPathSlots/EditOfferSlots";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import PanelGrid from "../../Shared/PanelGrid/PanelGrid";
import Input from "../../Shared/Input/Input";
import { getRandomId, propEqualComparision } from "../../utils/globalUtils";
// import History from "../../components/History/History";
import { usePrevious } from "../../Shared/hooks/use-previous";
import { useDispatch } from "react-redux";
import Select from "../../Shared/Select/Select";
import s from "./OfferPathSlots.module.scss";
import OfferPathSlotsInnerTable from "./OfferPathSlotsInnerTable";
import { useParams } from "react-router-dom";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import { toastListActions } from "../../store/toastList/toastList";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import Icon from "../../Shared/Icon/Icon";
import CopyRenderer from "../Renderers/CopyRenderer/CopyRenderer";
import FeatureCopyToolbar from "../FeatureCopyToolbar/FeatureCopyToolbar";
import CustomTooltipRenderer from "../../components/Renderers/CustomTooltipRenderer/CustomTooltipRenderer";
import tooltipStyles from "../../components/Renderers/CustomTooltipRenderer/CustomTooltipRenderer.module.scss";
import Table from "../../Shared/Table/Table";
import Popup from "../../Shared/Popup/Popup";
import { SURVEYS } from "../../constants/url";
import { reactQueryApiCall } from "../../generalApi/generalApi";
// import Table from "../../Shared/Table/Table";
// import { offerPathConfig } from "../../constants/historyConstants";

let editTrafficSplitValue = "";

const OfferPathSlots = ({
    fetchDealIdsApi,
    creativesList,
    offerWallsList,
    offerPathAdSlots,
    action,
    data,
    pageId,
    historyApi,
    // historyDataState,
    // historyDataRaw,
    historyInnerTableApi,
    readOnly = false,
    cloneSlot,
    featureCopyData,
}) => {
    const dispatch = useDispatch();
    const tableConfig = {
        stickyHeader: false,
    };

    const innerTableRefs = {
        weightRef: useRef(null),
        dealIdRef: useRef(null),
    };

    const trafficRef = useRef(null);
    const editTrafficRef = useRef(null);

    const prevOfferPathAdSlots = usePrevious(offerPathAdSlots);
    const params = useParams();

    const [typeError, setTypeError] = useState(false);
    const [offerWallError, setOfferWallError] = useState(false);
    const [offerWallTemplateError, setOfferWallTemplateError] = useState(false);
    // const [expandAll, setExpandAll] = useState(false);

    const [bidMultiplier, setBidMultiplier] = useState(1);
    const [scoreMultiplier, setScoreMultiplier] = useState(0);
    const [editBidMultiPlier, setEditBidMultiplier] = useState(null);
    const [editScoreMultiplier, setEditScoreMultiplier] = useState(null);

    const typeList = [
        { id: 1, key: "WeightGroup", text: "WeightGroup" },
        { id: 2, key: "PriorityGroup", text: "PriorityGroup" },
        { id: 2, key: "RtbGroup", text: "RtbGroup" },
        { id: 4, key: "OfferWall", text: "OfferWall" },
    ];

    const revenueList = [
        { id: 1, text: "cpc", key: "cpc" },
        { id: 2, text: "cpm", key: "cpm" },
        { id: 3, text: "cpa", key: "cpa" },
        { id: 4, text: "cpl", key: "cpl" },
    ];

    const { apiData: offerPathKeyList } = reactQueryApiCall(
        "offerPathKeys",
        "Offer path keys for mapping",
        {
            url: SURVEYS.GET_OFFER_KEYS,
            method: "GET",
        }
    );

    useEffect(() => {
        if (
            JSON.stringify(prevOfferPathAdSlots) !==
            JSON.stringify(offerPathAdSlots)
        ) {
            dispatch(
                action.addSelectedListItems({
                    page: pageId,
                    data: offerPathAdSlots.map(ops => ({
                        index: ops.slotIndex,
                        type: ops.type,
                        trafficSplit: ops.trafficSplit,
                        id: ops.id,
                    })),
                })
            );
        }
    }, [offerPathAdSlots]);

    const columns = [
        {
            text: "Index",
            key: "index",
            CustomRender: ({ col, item }) => {
                return <p>{item.index ? item.index : ""}</p>;
            },
            type: 0,
        },
        {
            text: "Id",
            key: "id",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                return item.id && item.id !== "-" ? (
                    <CopyRenderer>
                        <p>{item.id}</p>
                    </CopyRenderer>
                ) : (
                    <TagRenderer>New</TagRenderer>
                );
            },
        },
        {
            text: "Type",
            key: "type",
            CustomRender: ({ col, item }) => {
                if (!item.type) return null;
                const offerWall =
                    item?.offerWall && item?.type === "OfferWall"
                        ? `: ${item.offerWall}`
                        : "";

                let localEditBidMultiPlier = 1;
                let localEditScoreMultiplier = 0;

                if (item?.dynamicSortFunc && item?.type === "RtbGroup") {
                    if (data.editId && data.editId[0].rowId == item.rowId) {
                        localEditBidMultiPlier = editBidMultiPlier;
                        localEditScoreMultiplier = editScoreMultiplier;
                    } else {
                        const variables = item.dynamicSortFunc.split("+");
                        if (variables.length >= 2) {
                            localEditBidMultiPlier = parseFloat(
                                variables[0].split("*")[1] || "1"
                            );

                            localEditScoreMultiplier = parseFloat(
                                variables[1].split("*")[1] || "0"
                            );
                        }
                    }
                }

                const dynamicSortFunc = item?.type === "RtbGroup" && (
                    <>
                        {" : "}
                        <MultiplierPopup
                            readOnly={readOnly}
                            bidMultiplier={localEditBidMultiPlier}
                            scoreMultiplier={localEditScoreMultiplier}
                            onSave={({ bid, score }) => {
                                setEditBidMultiplier(bid);
                                setEditScoreMultiplier(score);
                            }}
                            editable={
                                data.editId &&
                                data.editId[0].rowId == item.rowId
                            }
                        />
                    </>
                );

                return (
                    <p>
                        {item.type} {offerWall} {dynamicSortFunc}
                    </p>
                );
            },
            type: 5,
        },
        {
            text: "Traffic Split",
            key: "trafficSplit",
            disableCopyText: true,
            CustomRender: ({ col, item }) => {
                if (data.editId && data.editId[0].rowId == item.rowId) {
                    let inputJson = {
                        className: "",
                        type: "number",
                        placeholder: "",
                        ref: editTrafficRef,
                    };
                    return (
                        <EditOfferSlots
                            type={get(col, "type", "")}
                            onChange={async data => {
                                editTrafficSplitValue = data;
                            }}
                            inputState={editTrafficSplitValue}
                            inputJson={inputJson}
                        />
                    );
                } else {
                    return (
                        <p>
                            {item.trafficSplit || item.trafficSplit === 0
                                ? item.trafficSplit
                                : ""}
                        </p>
                    );
                }
            },
            type: 0,
        },
        {
            text: "Creatives/Templates",
            key: "creatives",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                let dynamicColName2 =
                    item.type === "RtbGroup"
                        ? "disposition"
                        : item.type === "PriorityGroup"
                        ? "priority"
                        : "weight";

                let copyItem = JSON.parse(JSON.stringify(item));
                if (item.type === "PriorityGroup") {
                    copyItem.editCreativeData = copyItem.editCreativeData?.sort(
                        (a, b) => a.priority - b.priority
                    );
                } else if (item.type === "RtbGroup") {
                    copyItem.editCreativeData = copyItem.editCreativeData?.sort(
                        (a, b) => b.disposition - a.disposition
                    );
                } else {
                    copyItem.editCreativeData = copyItem.editCreativeData?.sort(
                        (a, b) => b.weight - a.weight
                    );
                }

                const templateSelectJson = {
                    keyField: "key",
                    idField: "key",
                    selectedIdField: data?.editOfferWallTemplate,
                };

                if (
                    data.editId &&
                    data.editId[0].rowId == item.rowId &&
                    item.type === "OfferWall"
                ) {
                    return (
                        <EditOfferSlots
                            type={5}
                            onChange={async data => {
                                onSelectOfferWallTemplate(data);
                                await dispatch(
                                    action.setEditOfferWallTemplate({
                                        page: pageId,
                                        data: data?.key,
                                    })
                                );
                            }}
                            data={creativesList}
                            dropDownJson={templateSelectJson}
                        />
                    );
                }

                const arrayKeyName = "editCreativeData";
                const firstRenderData = "normal";
                const colName1 = "creative";
                const colName2 = dynamicColName2;
                const headerTitle1 = "Creative";
                const headerTitle2 = dynamicColName2;
                const centreAlign = true;

                const ConfigDetailPopup = () => {
                    const [configData, setConfigData] = useState({
                        data: item[arrayKeyName],
                    });
                    useEffect(() => {
                        let width = 0;
                        document
                            .querySelectorAll(".detailName")
                            .forEach(item => {
                                if (item.clientWidth > width)
                                    width = item.clientWidth;
                            });
                        document
                            .querySelectorAll(".detailName")
                            .forEach(
                                item =>
                                    (item.style.width =
                                        Math.ceil(width / 10) * 10 + "px")
                            );
                    }, [item]);

                    return (
                        <div>
                            <div className={tooltipStyles.externalHeader1}>
                                <div className="detailName">{headerTitle1}</div>
                                <div
                                    className={`${
                                        tooltipStyles.externalChannel
                                    } ${
                                        centreAlign
                                            ? tooltipStyles.centreAlign
                                            : ""
                                    }`}
                                >
                                    {headerTitle2}
                                </div>
                            </div>
                            {configData && (
                                <div className={tooltipStyles.externalBody1}>
                                    {configData?.data?.map((row, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={tooltipStyles.row}
                                            >
                                                <div
                                                    className={`${tooltipStyles.name} detailName dp-parent dp-parent-ver-center`}
                                                >
                                                    {!isEmpty(row[colName1]) &&
                                                        creativesList?.some(
                                                            _ =>
                                                                _.id ===
                                                                    parseInt(
                                                                        row[
                                                                            colName1
                                                                        ]?.split(
                                                                            ":"
                                                                        )[0]
                                                                    ) &&
                                                                _.isDeprecated
                                                        ) && (
                                                            <TagRenderer
                                                                className={
                                                                    s.deprecateTag
                                                                }
                                                                type={
                                                                    "deprecated"
                                                                }
                                                            >
                                                                Deprecated
                                                            </TagRenderer>
                                                        )}
                                                    <CopyRenderer>
                                                        <p>
                                                            {row[colName1]
                                                                ? row[colName1]
                                                                : "-"}
                                                        </p>
                                                    </CopyRenderer>
                                                </div>
                                                <div
                                                    className={`${
                                                        tooltipStyles.value
                                                    } ${
                                                        tooltipStyles.externalNumber
                                                    } ${
                                                        centreAlign
                                                            ? tooltipStyles.centreAlign
                                                            : ""
                                                    }`}
                                                >
                                                    <CopyRenderer>
                                                        <p>
                                                            {row[colName2]
                                                                ? row[colName2]
                                                                : "-"}
                                                        </p>
                                                    </CopyRenderer>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                };

                const getFirstData = () => {
                    if (firstRenderData === "colonSeperated") {
                        const firstValue = get(
                            item,
                            `${arrayKeyName}[0][${colName1}]`,
                            "-"
                        );
                        const secondValue = get(
                            item,
                            `${arrayKeyName}[0][${colName2}]`,
                            "-"
                        );
                        let combinedValue = "-";
                        if (firstValue === "-" && secondValue === "-")
                            combinedValue = "-";
                        else combinedValue = `${firstValue} : ${secondValue}`;
                        return combinedValue;
                    } else {
                        const firstValue = get(
                            item,
                            `${arrayKeyName}[0][${colName1}]`,
                            "-"
                        );
                        return firstValue;
                    }
                };

                return (
                    <>
                        {creativesList?.some(
                            _ =>
                                _.id ===
                                    parseInt(getFirstData()?.split(":")[0]) &&
                                _.isDeprecated
                        ) && (
                            <TagRenderer
                                className={s.deprecateTag}
                                type={"deprecated"}
                            >
                                Deprecated
                            </TagRenderer>
                        )}
                        <CustomTooltipRenderer
                            data={item[arrayKeyName]}
                            customRenderer={ConfigDetailPopup}
                        >
                            <span className="ml-5">{getFirstData()}</span>
                            {item[arrayKeyName]?.length > 1 && (
                                <span className={tooltipStyles.details}>
                                    +{item[arrayKeyName].length - 1}
                                </span>
                            )}
                        </CustomTooltipRenderer>
                    </>
                );
            },
        },
        {
            className: "sticky",
            styles: {
                right: 0,
                textAlign: "right",
            },
            text: () => {
                if (get(data, "selectedListItems", []).length > 1) {
                    return (
                        <div
                            className={s.collapseBtnWarpper}
                            onClick={() => {
                                dispatch(
                                    action.expandCollapseTableToggle({
                                        page: pageId,
                                        data: !data.expandCollapseTable,
                                    })
                                );
                                dispatch(
                                    action.setEditId({
                                        page: pageId,
                                        data: "",
                                    })
                                );
                            }}
                        >
                            <Icon
                                icon={
                                    !data.expandCollapseTable
                                        ? "Maximize"
                                        : "Minimize"
                                }
                                size="16px"
                                className="mr-5"
                            />
                            <span>
                                {data.expandCollapseTable
                                    ? "Collapse All"
                                    : "Expand All"}
                            </span>
                        </div>
                    );
                }
            },
            key: "",
            CustomRender: ({ col, item }) => {
                if (data.editId && data.editId[0].rowId == item.rowId) {
                    return (
                        <div className={s.saveBtnWrapper}>
                            <Button
                                btnTheme={ButtonTypes.primaryHover_btn}
                                onClick={() => onAddListItem()}
                            >
                                Save
                            </Button>
                            <Button
                                btnTheme={ButtonTypes.default_btn}
                                onClick={() => cancelEdit()}
                            >
                                Cancel
                            </Button>
                        </div>
                    );
                } else {
                    const clone = () => {
                        if (cloneSlot && typeof cloneSlot === "function") {
                            cloneSlot(item);
                        }
                    };
                    return (
                        <>
                            <ActionButtons
                                isDeleted={
                                    get(data, "deletedSlots", []).findIndex(
                                        _ => _.id === item.id
                                    ) !== -1
                                }
                                revertCallback={obj => {
                                    dispatch(
                                        action.revertDeleteSlots({
                                            page: pageId,
                                            data: obj,
                                        })
                                    );
                                }}
                                item={item}
                                editCallback={onEdit}
                                deleteCallback={obj => {
                                    onDeleteItem(obj);
                                }}
                                {...(item.type != "OfferWall"
                                    ? { viewVariantCallback: openDropdown }
                                    : {})}
                                viewChildTableText="View Creatives"
                                hideChildTableText="Hide Creatives"
                                showHistoryIcon={
                                    item.id && item.id !== "-" ? true : false
                                }
                                historyCallback={obj => {
                                    historyDataAction(
                                        historyApi,
                                        obj,
                                        dispatch,
                                        action
                                    );
                                }}
                                cloneCallback={clone}
                                readOnly={readOnly}
                            />
                        </>
                    );
                }
            },
            bulkActions: true,
            clickFunctionality: true,
        },
    ];

    const toggleHistoryVisibilty = () => {
        dispatch(action.setHistoryData([]));
    };

    const openDropdown = object => {
        dispatch(
            action.toggleShowAccordionTable({
                page: pageId,
                data: object.rowId,
                list: get(data, "selectedListItems", []),
            })
        );
        timer = setTimeout(() => {
            // commenting out scroll to since its not scrolling to right element, fix later
            // scrollToElem(editTableRef);
        });
    };

    const validateOfferWall = () => {
        if (data.type !== "OfferWall") return true;
        if (
            data.type === "OfferWall" &&
            data.offerWall &&
            data.offerWallTemplate
        )
            return true;
        setOfferWallErrors();
        return false;
    };

    const setOfferWallErrors = () => {
        if (data.offerWall === "" || data.offerWall == null)
            setOfferWallError(true);
        if (data.offerWallTemplate === "" || data.offerWallTemplate == null)
            setOfferWallTemplateError(true);
    };

    const onAddListItem = () => {
        dispatch(
            action.setOfferPathSlotsError({
                page: pageId,
                data: false,
            })
        );
        if (
            data.type &&
            (data.trafficSplit || data.trafficSplit === 0) &&
            data.trafficSplit.indexOf(".") == -1 &&
            data.trafficSplit.indexOf("-") == -1 &&
            validateOfferWall()
        ) {
            const dataJson = {
                index: get(data, "selectedListItems", []).length + 1,
                type: get(data, "type", "WeightGroup"),
                trafficSplit: get(data, "trafficSplit", ""),
                id: null ? "-" : "-",
                rowId: getRandomId(),
                editCreativeData: data?.offerWallTemplate
                    ? [{ creative: data?.offerWallTemplate }]
                    : [],
                ...(data?.type === "OfferWall" && {
                    offerWall: data?.offerWall,
                    adsCreative: data?.offerWallTemplate,
                }),
                ...(data?.type !== "OfferWall" && {
                    showAccordionTable: true,
                }),
                ...(data?.type === "RtbGroup" && {
                    dynamicSortFunc: `job.bid*${bidMultiplier} + job.score*${scoreMultiplier}`,
                }),
            };

            // Reseting to default;
            setBidMultiplier(1);
            setScoreMultiplier(0);

            let updatedSelectedListItems =
                data?.selectedListItems?.map(slot => ({
                    ...slot,
                    showAccordionTable: false,
                })) || [];

            updatedSelectedListItems = [...updatedSelectedListItems, dataJson];

            onDragReOrderColumn(updatedSelectedListItems);

            dispatch(
                action.addType({
                    page: pageId,
                    data: null,
                })
            );

            dispatch(
                action.addTrafficSplit({
                    page: pageId,
                    data: "",
                })
            );

            dispatch(
                action.resetOfferWallData({
                    page: pageId,
                })
            );
        } else if (get(data, "editId", "") != "") {
            let selectedListItemsCopy = get(
                data,
                "selectedListItems",
                []
            ).slice();
            const objIndex = selectedListItemsCopy.findIndex(
                obj => obj.rowId == data.editId[0].rowId
            );

            selectedListItemsCopy[objIndex] = {
                ...selectedListItemsCopy[objIndex],
                type: get(data, "editType.key", "WeightGroup"),
                trafficSplit:
                    editTrafficSplitValue != ""
                        ? editTrafficSplitValue
                        : get(data, "editTrafficSplit", ""),
                isEdit: params.id == null ? false : true,
                ...(data?.editType?.key === "OfferWall" && {
                    editCreativeData: [
                        { creative: data?.editOfferWallTemplate },
                    ],
                    adsCreative: data?.editOfferWallTemplate,
                }),
                ...(data?.editType?.key === "RtbGroup" &&
                    editBidMultiPlier !== null &&
                    editScoreMultiplier !== null && {
                        dynamicSortFunc: `job.bid*${editBidMultiPlier} + job.score*${editScoreMultiplier}`,
                    }),
            };
            if (
                (editTrafficSplitValue || editTrafficSplitValue === 0) &&
                !editTrafficSplitValue.toString().includes(".") &&
                !editTrafficSplitValue.toString().includes("-")
            ) {
                dispatch(
                    action.addSelectedListItems({
                        page: pageId,
                        data: selectedListItemsCopy,
                    })
                );

                dispatch(
                    action.setEditId({
                        page: pageId,
                        data: "",
                    })
                );

                dispatch(
                    action.setEditTrafficSplit({
                        page: pageId,
                        data: "",
                    })
                );

                // Reseting to default;
                setEditBidMultiplier(null);
                setEditScoreMultiplier(null);
            } else {
                if (editTrafficSplitValue.toString().includes(".")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Traffic Split cannot be decimal",
                        })
                    );
                }

                if (editTrafficSplitValue.toString().includes("-")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Traffic Split cannot be negative",
                        })
                    );
                }
            }
        } else {
            dispatch(
                action.setOfferPathSlotsError({ page: pageId, data: true })
            );
            trafficRef.current.validate();
            if (data.type == "" || data.type == null) setTypeError(true);
            if (
                data.trafficSplit.indexOf(".") !== -1 ||
                data.trafficSplit.indexOf("-") !== -1
            ) {
                if (data.trafficSplit.includes(".")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Traffic Split cannot be decimal",
                        })
                    );
                }
                if (data.trafficSplit.includes("-")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Traffic Split cannot be negative",
                        })
                    );
                }
            }
        }
    };

    const onDragReOrderColumn = updatedColumn => {
        const selectedListItemsCopy = updatedColumn.map((item, idx) => ({
            ...item,
            index: idx + 1,
            isEdit: true,
        }));
        dispatch(
            action.addSelectedListItems({
                page: pageId,
                data: selectedListItemsCopy,
            })
        );
    };

    const onDeleteItem = obj => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        if (params.id && obj.id !== "-") {
            dispatch(
                action.setDeletedSlots({
                    page: pageId,
                    data: obj,
                })
            );
        } else {
            let itemIndex = selectedListItemsCopy.findIndex(
                element => element.rowId == obj.rowId
            );
            selectedListItemsCopy.splice(itemIndex, 1);
            dispatch(
                action.addSelectedListItems({
                    page: pageId,
                    data: selectedListItemsCopy,
                })
            );

            dispatch(
                action.selectedMode({
                    page: pageId,
                    data: null,
                })
            );
            onDragReOrderColumn(selectedListItemsCopy); //moved in else scope to prevent add selected list call on soft delete
        }
    };

    const editTableRef = useRef(null);
    const onEdit = editData => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        let itemIndex = selectedListItemsCopy.findIndex(
            element => element.rowId == get(editData, "rowId", null)
        );
        selectedListItemsCopy[itemIndex].showAccordionTable = false;

        dispatch(
            action.setEditId({
                page: pageId,
                data: [editData],
            })
        );
        dispatch(
            action.addSelectedListItems({
                page: pageId,
                data: selectedListItemsCopy,
            })
        );
        dispatch(
            action.rowTypeMode({
                page: pageId,
                data: "parent",
            })
        );
        timer = setTimeout(() => {
            // commenting out scroll to since its not scrolling to right element, fix later
            // scrollToElem(editTableRef);
        });
    };

    const cancelEdit = () => {
        // Reseting to default;
        setEditBidMultiplier(null);
        setEditScoreMultiplier(null);

        dispatch(
            action.setEditId({
                page: pageId,
                data: "",
            })
        );
    };

    let timer = null;
    useEffect(() => {
        return () => {
            clearTimeout(timer);
            clearTimeout(focusTimer);
        };
    }, []);

    const checkHistoryData = () => {
        return data.historyData && data.historyData.length > 0;
    };

    // Edit: to add it in the array format instead

    const focusTimer = () =>
        setTimeout(() => {
            trafficRef.current.focus();
        });

    const selectTypeDropdown = data => {
        dispatch(
            action.addType({
                page: pageId,
                data: get(data, "text", ""),
            })
        );
        if (get(data, "text", "") !== "rtbGroup") {
            focusTimer();
        }
        setTypeError(false);
    };

    const onSelectOfferWall = offerWall => {
        setOfferWallError(false);
        dispatch(
            action.updateOfferWallData({
                page: pageId,
                data: {
                    field: "offerWall",
                    value: offerWall?.name,
                },
            })
        );
    };

    const onSelectOfferWallTemplate = template => {
        setOfferWallTemplateError(false);
        dispatch(
            action.updateOfferWallData({
                page: pageId,
                data: {
                    field: "offerWallTemplate",
                    value: template?.key,
                },
            })
        );
    };

    useEffect(() => {
        if (get(data, "editId", "") != "") {
            if (get(data, "rowTypeMode", "") == "parent") {
                const objIndex = get(data, "selectedListItems", []).findIndex(
                    obj => obj.rowId == data.editId[0].rowId
                );

                let getDropDownData = typeList.filter(
                    element =>
                        element.key ==
                        get(
                            data,
                            `selectedListItems[${objIndex}].type`,
                            "WeightGroup"
                        )
                );

                dispatch(
                    action.setEditType({
                        page: pageId,
                        data: getDropDownData[0],
                    })
                );

                dispatch(
                    action.setEditTrafficSplit({
                        page: pageId,
                        data: get(
                            data,
                            `selectedListItems[${objIndex}].trafficSplit`,
                            ""
                        ),
                    })
                );

                editTrafficSplitValue = get(
                    data,
                    `selectedListItems[${objIndex}].trafficSplit`,
                    ""
                );

                if (getDropDownData[0].key === "OfferWall") {
                    dispatch(
                        action.setEditOfferWallTemplate({
                            page: pageId,
                            data: data?.selectedListItems[objIndex]
                                ?.adsCreative,
                        })
                    );
                }
                if (getDropDownData[0].key === "RtbGroup") {
                    const formula = get(
                        data,
                        `selectedListItems[${objIndex}].dynamicSortFunc`,
                        "job.bid*1 + job.score*0"
                    );
                    const variables = formula.split("+");
                    if (variables.length >= 2) {
                        setEditBidMultiplier(
                            parseFloat(variables[0].split("*")[1] || "1")
                        );
                        setEditScoreMultiplier(
                            parseFloat(variables[1].split("*")[1] || "0")
                        );
                    }
                }
            }
        }
    }, [data.editId, data.rowTypeMode]);

    useEffect(() => {
        if (data?.selectedListItems?.length > 0) {
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            if (data.expandCollapseTable) {
                selectedListItemsCopy.map(item => {
                    if (
                        item.type != "OfferWall" &&
                        item?.rowId !== data.editId[0]?.rowId
                    )
                        return (item.showAccordionTable = true);
                });
            } else {
                selectedListItemsCopy.map(item => {
                    return (item.showAccordionTable = false);
                });
            }

            dispatch(
                action.expandCollapseTable({
                    page: pageId,
                    data: selectedListItemsCopy,
                })
            );
        }
    }, [data.expandCollapseTable, data.editId]);

    const setSlots = slots => {
        featureCopyData?.copiedData.forEach(obj => {
            if (obj.id !== "-") {
                dispatch(
                    action.setDeletedSlots({
                        page: pageId,
                        data: obj,
                    })
                );
            }
        });

        dispatch(
            action.addSelectedListItems({
                page: pageId,
                data: [...slots],
            })
        );
    };

    return (
        <div
            id="offerPathSlots"
            data-hash-id="offerPathSlots"
            className={`flexHeightTab`}
        >
            <div className={`headerTitle`}>Slots</div>
            <div className={`headerTitleRequired`}>Add offer path slots</div>
            <p className={`infoText`}>
                Slots can be added on the basis of slot index, type & traffic
                split.&nbsp;
                {/* <a href="#" className="">
          Learn more
        </a> */}
            </p>
            {featureCopyData && (
                <FeatureCopyToolbar
                    displayText="Slots"
                    featureCopyData={featureCopyData}
                    handleLoad={setSlots}
                    readOnly={readOnly}
                />
            )}
            <PanelGrid
                className={`${s.offerTable} ${
                    data?.type === "OfferWall" || data?.type === "RtbGroup"
                        ? s.maxWidth
                        : ""
                }`}
            >
                <form
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <div className={s.header}>
                        <div className={s.col}>Type</div>
                        {data?.type === "RtbGroup" && (
                            <div className={`${s.col} text-center`}>
                                Optimisation Formula
                            </div>
                        )}
                        <div className={`${s.col}`}>Traffic Split*</div>
                        {data?.type === "OfferWall" && (
                            <>
                                <div className={`${s.col} ${s.offerSelect}`}>
                                    Offer Wall*
                                </div>
                                <div className={`${s.col} ${s.offerSelect}`}>
                                    Template*
                                </div>
                            </>
                        )}
                        <div className={`${s.col} ${s.addBtn}`}></div>
                    </div>
                    <div className={s.row}>
                        <div className={`${s.col} ${s.select}`}>
                            <Select
                                label={"Select Type"}
                                options={typeList}
                                keyField={"text"}
                                onSelect={selectTypeDropdown}
                                idField={"key"}
                                onLoadSelectReturn={false}
                                selectedId={
                                    get(data, "type", null) == ""
                                        ? null
                                        : get(data, "type", null)
                                }
                                isError={typeError}
                                isDisabled={readOnly}
                            />
                        </div>
                        {data?.type === "RtbGroup" && (
                            <div
                                className={`${s.col} dp-parent dp-parent-ver-center dp-parent-hor-center`}
                            >
                                <MultiplierPopup
                                    bidMultiplier={bidMultiplier}
                                    scoreMultiplier={scoreMultiplier}
                                    onSave={({ bid, score }) => {
                                        setBidMultiplier(bid);
                                        setScoreMultiplier(score);
                                    }}
                                    onDropdownToggle={open => {
                                        if (open) {
                                            cancelEdit();
                                        }
                                    }}
                                />
                            </div>
                        )}

                        <div className={`${s.col}`}>
                            <Input
                                ref={trafficRef}
                                type="number"
                                value={get(data, "trafficSplit", "")}
                                placeholder="Enter Split"
                                required={true}
                                onChange={e =>
                                    dispatch(
                                        action.addTrafficSplit({
                                            page: pageId,
                                            data: e.target.value,
                                        })
                                    )
                                }
                                disabled={readOnly}
                            />
                        </div>
                        {data?.type === "OfferWall" && (
                            <>
                                <div className={`${s.col} ${s.offerSelect}`}>
                                    <Select
                                        label={"Select OfferWall"}
                                        options={offerWallsList}
                                        keyField={"name"}
                                        onSelect={onSelectOfferWall}
                                        idField={"id"}
                                        onLoadSelectReturn={false}
                                        selectedId={null}
                                        isError={offerWallError}
                                        isDisabled={readOnly}
                                    />
                                </div>
                                <div className={`${s.col} ${s.offerSelect}`}>
                                    <Select
                                        label={"Select Template"}
                                        options={creativesList}
                                        keyField={"key"}
                                        onSelect={onSelectOfferWallTemplate}
                                        idField={"id"}
                                        onLoadSelectReturn={false}
                                        selectedId={null}
                                        isError={offerWallTemplateError}
                                        isDisabled={readOnly}
                                    />
                                </div>
                            </>
                        )}
                        <div className={`${s.col} ${s.addBtn}`}>
                            <Button
                                className={`${s.themBtn}`}
                                btnTheme={ButtonTypes.primaryHover_btn}
                                disable={readOnly}
                                type="submit"
                                onClick={onAddListItem}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </form>
            </PanelGrid>

            {get(data, "selectedListItems", []).length > 0 && (
                // <Table
                //     loading={false}
                //     columns={columns}
                //     data={get(data, "selectedListItems", [])}
                //     AccordionComponent={object => (
                //         <div
                //             style={{
                //                 maxWidth:
                //                     object.parentTableRef.current?.getBoundingClientRect()
                //                         .width,
                //             }}
                //         >
                //             <OfferPathSlotsInnerTable
                //                 fetchDealIdsApi={fetchDealIdsApi}
                //                 item={object.row}
                //                 creativesList={creativesList}
                //                 revenueList={revenueList}
                //                 action={action}
                //                 data={data}
                //                 pageId={pageId}
                //                 deletedSlotsAds={get(
                //                     data,
                //                     "deletedSlotAds",
                //                     []
                //                 )}
                //                 historyInnerTableApi={historyInnerTableApi}
                //                 readOnly={readOnly}
                //                 enableDedupe={true}
                //             />
                //         </div>
                //     )}
                // />
                <TableFlex
                    isPagination={false}
                    config={tableConfig}
                    columns={columns}
                    data={get(data, "selectedListItems", [])}
                    uniqueKey="id"
                    showBorder={true}
                    hnpTable={true}
                    headerBgColor={"#fafcfc"}
                    deletedIds={get(data, "deletedSlots", []).map(_ => _.id)}
                    AccordionComponent={object => (
                        <OfferPathSlotsInnerTable
                            fetchDealIdsApi={fetchDealIdsApi}
                            item={object.row}
                            creativesList={creativesList}
                            revenueList={revenueList}
                            action={action}
                            data={data}
                            pageId={pageId}
                            deletedSlotsAds={get(data, "deletedSlotAds", [])}
                            historyInnerTableApi={historyInnerTableApi}
                            readOnly={readOnly}
                            enableDedupe={true}
                            featureCopyToolbarKeys={{
                                sourceName: "Offer path Slots",
                                sourcePage: "Offer Path",
                                sourcePath: ["Offer Path", "Slots", "Dedupe"],
                                type: "offer Path Dedupe",
                            }}
                            offerPathKeyList={offerPathKeyList}
                        />
                    )}
                    editObject={get(data, "editId", "")}
                    editObjectSelector={"rowId"}
                    isEdit={false}
                    frozen={0}
                    sortableRows={!readOnly && true}
                    rowSortCallback={onDragReOrderColumn}
                    ref={editTableRef}
                    stickyHeaderClass={"stickyTableHead"}
                ></TableFlex>
            )}
            {/* {historyDataState && historyDataState.length > 0 ? (
                <History
                    data={historyDataState}
                    rawData={historyDataRaw}
                    closeHistory={toggleHistoryVisibilty}
                    historyConfig={offerPathConfig}
                />
            ) : null} */}
        </div>
    );
};

const MultiplierPopup = memo(
    ({
        readOnly = false,
        editable = true,
        bidMultiplier,
        scoreMultiplier,
        onSave,
        onDropdownToggle = () => null,
    }) => {
        const [multiplierDropdownOpen, setMultiplierDropdownOpen] =
            useState(false);

        const [bidMultiplierPercentage, setBidMultiplierPercentage] = useState(
            bidMultiplier * 100
        );
        const [scoreMultiplierPercentage, setScoreMultiplierPercentage] =
            useState(scoreMultiplier * 100);

        const ref = useRef(null);

        const getPopupPosition = () => {
            const rect = ref.current.getBoundingClientRect();
            return {
                top: rect.y + window.scrollY + 20,
                left: rect.left,
            };
        };

        useEffect(() => {
            onDropdownToggle(multiplierDropdownOpen);
        }, [multiplierDropdownOpen]);

        return (
            <>
                {multiplierDropdownOpen && (
                    <Popup
                        showHeader={true}
                        showFooter={false}
                        showConfirmBox={true}
                        saveButtonText={"Done"}
                        cancelButtonText={"Cancel"}
                        closeOutside={true}
                        disableSave={readOnly}
                        width={"320px"}
                        textAlign={"left"}
                        title={"Change Formula"}
                        portal={true}
                        customClass={s.multiplierFormContainer}
                        {...getPopupPosition()}
                        onClose={() => setMultiplierDropdownOpen(false)}
                        onSave={() => {
                            onSave({
                                bid: bidMultiplierPercentage / 100,
                                score: scoreMultiplierPercentage / 100,
                            });
                            setMultiplierDropdownOpen(false);
                        }}
                        headerPadding={"20px"}
                        CustomRender={FormulaForm}
                        customRenderProps={{
                            bidMultiplierPercentage,
                            setBidMultiplierPercentage,
                            scoreMultiplierPercentage,
                            setScoreMultiplierPercentage,
                            readOnly,
                        }}
                    ></Popup>
                )}
                <span
                    ref={ref}
                    className={`${editable ? "link" : ""} italic ${
                        s.formulaDisplay
                    }`}
                    onClick={() => {
                        if (editable) {
                            setMultiplierDropdownOpen(true);
                        }
                    }}
                >
                    Bid * {bidMultiplier} + <br></br> Relevance Score *{" "}
                    {scoreMultiplier}
                </span>
            </>
        );
    }
);

const FormulaForm = memo(
    ({
        bidMultiplierPercentage,
        setBidMultiplierPercentage,
        scoreMultiplierPercentage,
        setScoreMultiplierPercentage,
        readOnly,
    }) => {
        return (
            <>
                <div className={s.formula}>
                    Formula:{" "}
                    <span className="italic">
                        Bid * {bidMultiplierPercentage / 100} + Relevance Score
                        * {scoreMultiplierPercentage / 100}
                    </span>
                </div>
                <div className={s.multiplierForm}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Bid Weight </td>
                                <td>
                                    <Input
                                        type={"number"}
                                        placeholder="Enter bid multiplier"
                                        value={bidMultiplierPercentage}
                                        valueType="percentage"
                                        onChange={e => {
                                            const value = e.target.value;
                                            setBidMultiplierPercentage(value);
                                            const remainingScore = 100 - value;
                                            setScoreMultiplierPercentage(
                                                remainingScore
                                            );
                                        }}
                                        disabled={readOnly}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Relevance Score Weight </td>
                                <td>
                                    <Input
                                        type={"number"}
                                        valueType="percentage"
                                        placeholder="Enter score multiplier"
                                        value={scoreMultiplierPercentage}
                                        onChange={e => {
                                            const value = e.target.value;
                                            setScoreMultiplierPercentage(value);
                                            const remainingScore = 100 - value;
                                            setBidMultiplierPercentage(
                                                remainingScore
                                            );
                                        }}
                                        disabled={readOnly}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    },
    (next, prev) => {
        return propEqualComparision(next, prev, [
            "bidMultiplierPercentage",
            "scoreMultiplierPercentage",
        ]);
    }
);

export default OfferPathSlots;
