import { useState } from "react";
import Icon from "../../Shared/Icon/Icon";
import Input from "../../Shared/Input/Input";
import c from "./CustomSearchInput.scss";

const CustomSearchInput = props => {
    const [searchedTerm, setSearchedTerm] = useState();

    const returnSearchResult = e => {
        let value = e?.target?.value?.trim()?.toLowerCase() || "";
        if (value !== "") {
            const newList = props?.data?.map(elemLevel1 => {
                return {
                    ...elemLevel1,
                    data: elemLevel1?.data?.filter(elemLevel2 => {
                        return elemLevel2.updatedBy.name
                            .toLowerCase()
                            .includes(value);
                    }),
                };
            });
            props.searchResult(newList);
        } else {
            props.searchResult(props.data);
        }
    };

    const handleOnChange = data => {
        props.handleSearch && typeof props.handleSearch === "function"
            ? props.handleSearch(data)
            : returnSearchResult(data);
    };

    return (
        <div className={`${c.input_wrap} dp-parent`}>
            <Input
                {...props}
                onChange={e => {
                    setSearchedTerm(e.target.value.trim().toLowerCase());
                    handleOnChange(e);
                }}
                value={searchedTerm}
                className={c.customSearchInput}
                customIcon="Search"
                customIconClass={c.inputIcon}
            />
            {searchedTerm && (
                <div
                    className={`${c.clearIcon} circleIconColor`}
                    onClick={() => {
                        setSearchedTerm("");
                        handleOnChange("");
                    }}
                >
                    <Icon icon="CloseDefault" size="12px" />
                </div>
            )}
        </div>
    );
};

export default CustomSearchInput;
