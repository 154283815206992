import { get } from "lodash";
import React, { useEffect, useState } from "react";
import Icon from "../Icon/Icon";
import LogoComponent from "../Logo/LogoComponent";
import c from "./Checkbox.module.scss";
import Tooltip from "../Tooltip/Tooltip";

export const CheckboxIcon = {
    tick: "Tick",
    minus: "Minus",
};

const Checkbox = ({
    id,
    name,
    labelText,
    isChecked = false,
    onChange,
    disabled,
    className,
    labelClassName,
    style = {},
    onMouseEnter,
    icon = CheckboxIcon.tick,
    rowReverse = false,
    customIcon,
    logo = false,
    positionTopLeftAligned = false,
    iconClassWrapper,
    labelTextClass,
    iconSize = "15",
}) => {
    const [isCheck, setIsCheck] = useState(isChecked);
    useEffect(() => {
        if (typeof isChecked !== "undefined") setIsCheck(isChecked);
    }, [isChecked]);
    const onChangeHandler = e => {
        e.stopPropagation();
        if (!disabled) {
            if (typeof onChange === "function") onChange(!isCheck);
            setIsCheck(!isCheck);
        }
    };

    const labelValue = get(labelText, "props.children[1]", "");

    const labelString = get(labelText, "props.children[1]", "").toString();

    return (
        <div style={style} className={`${className ? className : ""}`}>
            <input
                type="checkbox"
                id={id}
                name={name}
                checked={isCheck}
                onChange={onChangeHandler}
                disabled={disabled}
            />
            <label
                htmlFor={name}
                onClick={onChangeHandler}
                className={`${c.checkBoxLabel} dp-parent-inline ${
                    labelClassName ? labelClassName : ""
                } ${rowReverse ? c.rowReverseLabel : ""}`}
                onMouseEnter={onMouseEnter}
            >
                <div className={`checkbox-container`}>
                    <div className={`${c.checkBox} flex createPathLayer`}>
                        <div className={`${c.icon} dp-parent`}>
                            <Icon icon={icon} size="12px" enableHover={true} />
                        </div>
                    </div>
                </div>

                {labelText && (
                    <Tooltip
                        message={
                            typeof labelValue === "string" ||
                            typeof labelValue === "number"
                                ? labelString
                                : ""
                        }
                        position="top"
                        customWrapperClass="w-100"
                        hideTooltip={
                            (typeof labelValue === "string" ||
                                typeof labelValue === "number") &&
                            labelValue.length >= 1
                                ? false
                                : true
                        }
                        wrap
                    >
                        <div
                            className={`${c.labelText} labelText ${
                                rowReverse ? c.rowReverse : ""
                            } ${labelTextClass ? labelTextClass : ""}`}
                        >
                            {customIcon != "" && (
                                <div
                                    className={`${c.reverseRowIconWrapper} ${
                                        iconClassWrapper ? iconClassWrapper : ""
                                    }`}
                                >
                                    {logo ? (
                                        // <img src={customIcon} />
                                        <LogoComponent
                                            name={customIcon}
                                            size={18}
                                        />
                                    ) : (
                                        <Icon
                                            icon={customIcon}
                                            size={`${iconSize}px`}
                                        />
                                    )}
                                </div>
                            )}
                            {labelText}
                        </div>
                    </Tooltip>
                )}
            </label>
        </div>
    );
};

export default Checkbox;
