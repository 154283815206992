import React from "react";
import s from "./AppLoader.module.scss";

const AppLoader = () => {
    return (
        <div className={s.container}>
            <svg
                width="30"
                height="38"
                viewBox="0 0 30 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id="Group 10">
                    <g id="Group 4">
                        <path
                            id="Subtract"
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M21.9488 27.7163H21.9497V29.6614C21.9497 31.7179 23.1232 33.6181 25.0281 34.6464C25.9833 35.162 27.0495 35.419 28.1154 35.4176C28.2252 35.4174 28.3315 35.4158 28.4343 35.4127C27.1508 36.9914 25.1932 38.0001 23 38.0001H7C3.13401 38.0001 0 34.8661 0 31.0001V15.6709C0 12.7397 1.80162 10.2293 4.35791 9.18667V29.6424C4.35791 31.6989 5.53137 33.5991 7.43631 34.6274C9.34125 35.6555 11.6882 35.6555 13.5931 34.6274C15.498 33.5991 16.6715 31.6989 16.6715 29.6424V17.0529H16.6725C16.6725 16.1715 17.1755 15.3571 17.9918 14.9166C18.8081 14.4759 19.8141 14.4759 20.6304 14.9166C21.4075 15.3359 21.9006 16.0941 21.9463 16.9263C21.9479 16.9621 21.9488 16.9979 21.9488 17.0339V27.7163ZM7.87608 8.6709H23C26.866 8.6709 30 11.8049 30 15.6709V31.0001C30 31.1509 29.9952 31.3007 29.9858 31.4492C29.9677 31.4297 29.9578 31.4194 29.9578 31.4194C29.8004 31.5643 29.6222 31.6919 29.4258 31.7979C28.6095 32.2386 27.6035 32.2386 26.7872 31.7979C25.9709 31.3572 25.4679 30.5428 25.4679 29.6614V17.0529C25.4679 16.9574 25.4654 16.8621 25.4603 16.7673C25.3638 14.8142 24.2109 13.0326 22.3885 12.0489C20.4836 11.0207 18.1367 11.0207 16.2317 12.0489C14.3268 13.0772 13.1533 14.9774 13.1533 17.0339V29.6424C13.1533 30.5238 12.6503 31.3382 11.834 31.7787C11.0177 32.2194 10.0117 32.2194 9.19539 31.7787C8.37908 31.3382 7.87608 30.5238 7.87608 29.6424V8.6709Z"
                            fill="#41C3A9"
                        />
                        <path
                            className={s.ball}
                            id="Vector"
                            d="M3.85102 5.7463C4.49661 6.37765 5.37241 6.7323 6.28563 6.7323V6.73114C7.19866 6.73114 8.07426 6.37669 8.71985 5.74553C9.36565 5.11457 9.72841 4.2586 9.7288 3.36613C9.7288 2.47332 9.36603 1.61711 8.72024 0.985764C8.07465 0.354607 7.19886 -4.19915e-05 6.28563 -4.19915e-05C5.37241 -4.19915e-05 4.49661 0.354607 3.85102 0.985764C3.20523 1.61711 2.84247 2.47332 2.84247 3.36613C2.84247 4.25874 3.20523 5.11514 3.85102 5.7463Z"
                            fill="#41C3A9"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default AppLoader;
