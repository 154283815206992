import { useState, useRef, useEffect } from "react";
import Accordion from "../../Shared/Accordion/Accordion";
import Icon from "../../Shared/Icon/Icon";
import { isElementEmpty } from "../../utils/globalUtils";
import CustomSearchInput from "../CustomSearchInput/CustomSearchInput";
import s from "./History.scss";
import ChangedContentRenderer from "./ChangedContentRenderer";
import DateTimePicker from "../../components/DateTimePicker/DateTimePicker";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import {
    getHistoryData,
    getComparisonData,
    getType,
    getTypeOfChange,
} from "../../utils/historyPopUpUtils.js";
import Select from "../../Shared/Select/Select";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import Popup from "../../Shared/Popup/Popup";
import { dateFormat3 } from "../../utils/dateUtils";
import { isEqual } from "lodash";
import InfoText from "../../Shared/InfoText/InfoText";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";

const History = ({
    historyConfig,
    data,
    rawData,
    closeHistory,
    flow = false,
    readOnly,
}) => {
    const [historyData, setHistoryData] = useState(data);
    const [historyDataForSearch, setHistoryDataForSearch] = useState(data);
    const [closePopup, setClosePopup] = useState(false);
    const [activeFlowViewItem, setActiveFlowViewItem] = useState(null);

    const [filteredRawData, setFilteredRawData] = useState(
        JSON.parse(JSON.stringify(rawData)).map(item => {
            Object.keys(item.data || [])?.map(key => {
                if (key && item.data[key]) {
                    const configObj = historyConfig.paths.find(
                        p => p.id === key
                    );
                    if (
                        configObj?.ignoreList &&
                        configObj.renderer === "object"
                    ) {
                        Object.keys(item.data[key]).forEach(k => {
                            if (configObj.ignoreList.includes(k)) {
                                delete item.data[key][k];
                            }
                        });
                    }

                    if (
                        configObj?.ignoreList &&
                        configObj.renderer === "array"
                    ) {
                        item.data[key] = item.data[key].map(item => {
                            Object.keys(item).forEach(k => {
                                if (configObj.ignoreList.includes(k)) {
                                    delete item[k];
                                }
                            });
                            return item;
                        });
                    }
                }
            });
            return item;
        })
    );

    const [dateRange, setDateRange] = useState({
        startDate:
            new Date(
                filteredRawData[filteredRawData.length - 1]?.updatedAt
            )?.setHours(0, 0, 0, 0) || startOfDay(subDays(new Date(), 30)),
        endDate: new Date(),
        key: "selection",
    });

    const localTimezone = useRef(
        new Date()
            .toLocaleDateString(undefined, {
                day: "2-digit",
                timeZoneName: "long",
            })
            .substring(4)
            .match(/\b(\w)/g)
            .join("")
    );

    useEffect(() => {
        //to prevent page scroll when history is open
        document.querySelector("body").style.overflow = "hidden";
        return () => {
            document.querySelector("body").style.overflow = "auto";
        };
    }, []);

    useEffect(() => {
        setHistoryData(
            getHistoryData(
                filteredRawData.filter(item => {
                    const dateObj = new Date(item.updatedAt);
                    return (
                        dateObj > dateRange?.startDate &&
                        dateObj < dateRange?.endDate
                    );
                })
            )
        );
        setHistoryDataForSearch(
            getHistoryData(
                filteredRawData.filter(item => {
                    const dateObj = new Date(item.updatedAt);
                    return (
                        dateObj > dateRange?.startDate &&
                        dateObj < dateRange?.endDate
                    );
                })
            )
        );
    }, [dateRange]);

    useEffect(() => {
        if (closePopup) {
            setTimeout(() => {
                closeHistory();
            }, 200);
        }
    }, [closePopup]);

    const getSearchResult = updatedList => {
        setHistoryData(updatedList);
    };

    return (
        <div
            className={`${s.historyOverlay} ${flow ? s.isFlow : ""}`}
            id="historyOverlay"
            onClick={e => {
                if (!flow && e.target.id === "historyOverlay")
                    setClosePopup(true);
            }}
        >
            <div className={`${s.wrapper} ${closePopup ? s.exit : ""}`}>
                <div className={`${s.heading} dp-parent dp-parent-ver-center`}>
                    <div
                        className={s.closeIcon}
                        onClick={() => setClosePopup(true)}
                    >
                        <Icon icon="CloseDefault" size="16px" />
                    </div>
                    <div className={s.headText}>History</div>
                </div>
                <div className={s.innerWrapper}>
                    <div
                        className={`${s.topSection} dp-parent dp-parent-hor-space-between gap-10`}
                    >
                        <CustomSearchInput
                            data={historyDataForSearch}
                            placeholder="Search for author name"
                            searchResult={getSearchResult}
                        />
                        <div
                            className={`${s.datepickerWrap} datePickerInHistory`}
                        >
                            <DateTimePicker
                                showDateLabel={true}
                                showTimeLabel={false}
                                dateTimeLabel={false}
                                dateRange={dateRange}
                                customIcon="Time"
                                setDateRange={setDateRange}
                            />
                        </div>
                    </div>
                    <div className={s.dataWrapper}>
                        {historyData?.map((list, index) => {
                            if (
                                list.data?.length > 0 &&
                                !isElementEmpty(
                                    <DateContainer
                                        dateGroup={list.data}
                                        activeFlowViewItem={activeFlowViewItem}
                                        setActiveFlowViewItem={
                                            setActiveFlowViewItem
                                        }
                                        filteredRawData={filteredRawData}
                                        historyConfig={historyConfig}
                                        flow={flow}
                                        localTimezone={localTimezone}
                                    />
                                )
                            ) {
                                return (
                                    <DateData
                                        key={index}
                                        list={list}
                                        index={index}
                                        activeFlowViewItem={activeFlowViewItem}
                                        setActiveFlowViewItem={
                                            setActiveFlowViewItem
                                        }
                                        filteredRawData={filteredRawData}
                                        historyConfig={historyConfig}
                                        flow={flow}
                                        localTimezone={localTimezone}
                                        readOnly={readOnly}
                                    />
                                );
                            }
                        })}

                        {historyData?.length === 0
                            ? "No data for selected date range"
                            : historyData?.every(_ => _.data.length === 0) &&
                              "No user found"}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default History;

const DateGroupInfo = ({ dateGroup = [] }) => {
    const users = new Set();
    dateGroup?.forEach(item => {
        users.add(item.updatedBy.name);
    });
    return (
        <div
            className={`${s.dateGroupInfo} dp-parent dp-parent-ver-center gap-5`}
        >
            <Icon icon="Replace" size="16px" color="#74838F" />
            <span>{dateGroup.length}</span>
            {dateGroup.length > 1 ? "Updates" : "Update"} by
            <span>{users.size}</span>
            {users.size > 1 ? "Users" : "User"}
        </div>
    );
};

const DateData = ({
    list,
    index,
    activeFlowViewItem,
    setActiveFlowViewItem,
    filteredRawData,
    historyConfig,
    flow,
    localTimezone,
    readOnly,
}) => {
    const [visibleLogItems, setVisibleLogItems] = useState([]);
    return (
        <div className={s.dateGroupWrap} key={index}>
            <Accordion
                type="small"
                accordContainer={
                    <DateContainer
                        dateGroup={list.data}
                        visibleLogItems={visibleLogItems}
                        setVisibleLogItems={setVisibleLogItems}
                        activeFlowViewItem={activeFlowViewItem}
                        setActiveFlowViewItem={setActiveFlowViewItem}
                        filteredRawData={filteredRawData}
                        historyConfig={historyConfig}
                        flow={flow}
                        localTimezone={localTimezone}
                        readOnly={readOnly}
                    />
                }
                showContent
                key={list.date + index}
                accordContainerKey={list.date + index}
                accordianClass={s.dateWrap}
                accordianClassOpen={s.dateWrap__open}
            >
                <div>{list.date}</div>
            </Accordion>
            <DateGroupInfo dateGroup={visibleLogItems} />
        </div>
    );
};

const DateContainer = ({
    dateGroup = [],
    visibleLogItems,
    setVisibleLogItems,
    activeFlowViewItem,
    setActiveFlowViewItem,
    filteredRawData,
    historyConfig,
    flow,
    localTimezone,
    readOnly,
}) => {
    const visibleItemsLocal = [];

    const dateGroupData = dateGroup.map((item, index) => {
        const newIndex = filteredRawData?.findIndex(
            _ => _.updatedAt === item.updatedAt
        );
        const oldIndex = newIndex + 1;
        const newData = filteredRawData[newIndex]?.data || [];
        const oldData = filteredRawData[oldIndex]?.data || [];
        const data = getComparisonData(oldData, newData, historyConfig);

        if (data && data[0].length !== 0) {
            visibleItemsLocal.push(item);
        }

        return (
            <ChangeLogItem
                item={item}
                data={data}
                key={index}
                activeFlowViewItem={activeFlowViewItem}
                setActiveFlowViewItem={setActiveFlowViewItem}
                flow={flow}
                localTimezone={localTimezone}
                historyConfig={historyConfig}
                readOnly={readOnly}
            />
        );
    });

    useEffect(() => {
        if (
            typeof setVisibleLogItems === "function" &&
            visibleItemsLocal.length > 0 &&
            !isEqual(visibleLogItems, visibleItemsLocal)
        ) {
            setVisibleLogItems(visibleItemsLocal);
        }
    }, [dateGroupData]);

    return dateGroupData || null;
};

const ChangeLogItem = ({
    item,
    data,
    activeFlowViewItem,
    setActiveFlowViewItem,
    flow,
    localTimezone,
    historyConfig,
    readOnly,
}) => {
    const [showDetails, setShowDetails] = useState(false);
    const [changedKeyNames, setChangedKeyNames] = useState([]);
    const [oldData, setOldData] = useState([]);
    const [newData, setNewData] = useState([]);
    const time = new Date(item.updatedAt);
    const [flowActions, setFlowActions] = useState(false);
    const [showRestorePopup, setShowRestorePopup] = useState(false);
    const [nameRandomNum, setNameRandomNum] = useState(
        Math.floor(Math.random() * 6) + 1
    );

    useEffect(() => {
        setChangedKeyNames(
            flow ? data[0].filter(_ => _.id === "flow") : data[0]
        );
        setNewData(data[1]);
        setOldData(data[2]);
    }, []);
    if (data && data[0].length === 0) return null;

    return (
        <>
            {showDetails && (
                <div
                    className={`${s.oustideCard} ${s.histMb10} dp-parent dp-parent-hor-space-between dp-parent-ver-center`}
                >
                    <p>Updates</p>
                    <div
                        className={`${s.showLess} dp-parent gap-5`}
                        onClick={() => setShowDetails(false)}
                    >
                        <Icon icon="UpArrow" size="10px" />
                        Show Less
                    </div>
                </div>
            )}

            <div
                className={`${s.dateContent} ${
                    showDetails ? s.showDetails : ""
                } ${flow ? s.isFlow : ""} ${
                    activeFlowViewItem?.historyId === item?.historyId ||
                    (item.isFirstLogItem && !activeFlowViewItem)
                        ? s.activeFlow
                        : ""
                } dp-parent dp-parent-col gap-15`}
                onClick={async () => {
                    if (flow && !flowActions) {
                        await setActiveFlowViewItem(item);
                        setTimeout(() => {
                            flow.viewFlow({
                                historyRules: item.data.rules?.filter(
                                    _ => _.isActive
                                ),
                                details: item.data.details,
                            });
                        }, 10);
                    }
                }}
            >
                <div
                    className={`${s.header} dp-parent dp-parent-hor-space-between dp-parent-ver-center`}
                >
                    <div className="dp-parent dp-parent-ver-center fontMedium">
                        <NameInitials
                            email={item.updatedBy.email}
                            nameRandomNum={nameRandomNum}
                        />
                        <p className="ml-10">By {item.updatedBy.name}</p>
                    </div>
                    <div>
                        {time.toLocaleTimeString("en-US") + " "}
                        {localTimezone.current}
                    </div>
                </div>

                <>
                    {showDetails ? (
                        <ChangedContentRenderer
                            keysObj={changedKeyNames}
                            newData={newData}
                            oldData={oldData}
                            config={historyConfig}
                        />
                    ) : !flow ? (
                        <ChangedKeys
                            pretext={getTypeOfChange(oldData, newData)}
                            keysObj={changedKeyNames}
                            historyConfig={historyConfig}
                        />
                    ) : null}
                    {!showDetails && !!changedKeyNames.length && (
                        <span
                            className={s.viewDetails}
                            onClick={() => setShowDetails(!showDetails)}
                        >
                            View all details
                        </span>
                    )}
                </>
                {!readOnly &&
                    flow &&
                    !item.isFirstLogItem &&
                    activeFlowViewItem?.historyId === item?.historyId && (
                        <div className={s.flowActionWrap}>
                            <div
                                className={s.icon}
                                onClick={() => setShowRestorePopup(true)}
                            >
                                <Tooltip message="Restore flow">
                                    <Icon
                                        icon="Refresh"
                                        size="16px"
                                        className="circleIconColor"
                                    />
                                </Tooltip>
                            </div>
                            {
                                //have to enable this when multiple options are shown
                                /* <div
                                className={s.icon}
                                onClick={() => setFlowActions(true)}
                            >
                                <Tooltip message="More Options">
                                    <Icon
                                        icon="More"
                                        size="16px"
                                        className="circleIconColor"
                                    />
                                </Tooltip>
                            </div>
                            {flowActions && (
                                <Select
                                    width={150}
                                    options={[
                                        {
                                            label: "Restore",
                                            id: 1,
                                            callback: data => {
                                                setShowRestorePopup(true);
                                            },
                                        },
                                        // {
                                        //     label: "Copy to new flow",
                                        //     id: 2,
                                        //     callback: (data, details = null) => {
                                        //         flow.copyToNewFlow(data, details);
                                        //     },
                                        // },
                                    ]}
                                    keyField="label"
                                    idField="id"
                                    onSelect={e => {
                                        if (
                                            e &&
                                            typeof e.callback === "function"
                                        ) {
                                            e.callback(
                                                item.data.rules?.filter(
                                                    _ => _.isActive
                                                ),
                                                item.data.details
                                            );
                                            setFlowActions(false);
                                        }
                                    }}
                                    onSelectClose={() => {
                                        setFlowActions(false);
                                    }}
                                    onLoadSelectReturn={false}
                                    isSelectOpen
                                    hideLabel
                                />
                            )} */
                            }
                        </div>
                    )}
                {flow && item.isFirstLogItem && (
                    <div
                        className={`${s.flowActionWrap} dp-parent dp-parent-ver-center dp-parent-hor-end`}
                    >
                        <TagRenderer className={s.currentTag}>
                            Current
                        </TagRenderer>
                    </div>
                )}
                {showRestorePopup && (
                    <Popup
                        title="Restore"
                        minWidth="400px"
                        saveButtonText="Yes, Go ahead"
                        bodyPadding="20px"
                        customClass={s.restoreConfirmPopup}
                        showActionButtonsAtEnd
                        showWhiteFooter
                        showOverlay
                        onClose={() => setShowRestorePopup(false)}
                        onSave={() =>
                            flow.restoreFlow({
                                historyRules:
                                    activeFlowViewItem.data.rules?.filter(
                                        _ => _.isActive
                                    ),
                                details: activeFlowViewItem.data.details,
                                time: new Date(item.updatedAt),
                            })
                        }
                    >
                        Your current state will revert to the version from{" "}
                        {dateFormat3(activeFlowViewItem.date)} at{" "}
                        {new Date(
                            activeFlowViewItem.updatedAt
                        ).toLocaleTimeString()}
                        . Do you wish to go ahead?
                    </Popup>
                )}
            </div>
        </>
    );
};

const ChangedKeys = ({
    pretext,
    keysObj = [],
    showCount = 3,
    historyConfig,
}) => {
    const labels = keysObj?.map(key => {
        const obj = historyConfig.paths.find(_ => _.id === key.id);
        if (obj) return obj.label;
    });
    return (
        <div
            className={`${s.changedKeyWrapper} dp-parent flex-wrap gap-10 fontMedium`}
        >
            {pretext && <span className="pl-0">{pretext}</span>}
            {showCount && labels.length > showCount ? (
                <>
                    {labels.map((_, index) => {
                        if (index < showCount)
                            return (
                                <span className={s.changedKeys} key={index}>
                                    {_}
                                </span>
                            );
                    })}
                    <span className={s.more}>
                        +{labels.length - showCount} more
                    </span>
                </>
            ) : (
                labels.map((_, index) => (
                    <span className={s.changedKeys} key={index}>
                        {_}
                    </span>
                ))
            )}
        </div>
    );
};

const NameInitials = ({ email, nameRandomNum }) => {
    const initialsArray = email.split("@intent-labs.com")[0].split(".");
    return (
        <div
            className={`${s.nameInitials} ${
                s[`nameInitials__bg${nameRandomNum}`]
            } dp-parent dp-parent-hor-center dp-parent-ver-center`}
        >
            {initialsArray[0].charAt(0) +
                (initialsArray[1] ? initialsArray[1]?.charAt(0) : "")}
        </div>
    );
};
