import { SURVEYS, DEALS } from "../constants/url";
import customFetch from "../fetch/customFetch";

export const adSlotsPostApiData = (adSlots, id, header = {}) => {
    return adSlots.map(slot => {
        const params = {
            offerWallId: id,
            slotIndex: slot.index,
            type: slot.type,
        };
        return customFetch(DEALS.CREATE_AD_SLOT, "POST", params, header);
    });
};

export const adSlotsPutApiData = (adSlots, id, header = {}) => {
    return adSlots.map(slot => {
        const params = {
            id: slot.id,
            offerWallId: id,
            slotIndex: slot.index,
            type: slot.type,
        };
        return customFetch(
            `${DEALS.CREATE_AD_SLOT}/${slot.id}`,
            "PUT",
            params,
            header
        );
    });
};

export const adGroupPostApiData = (slot, index, responses, header = {}) => {
    const creatives = slot.editCreativeData.map(creative => ({
        creativeId: +creative.creative.split(":")[0],
        dealId: creative.dealId,
        dedupeKeys: creative.dedupeRules,
        [slotType[slot.type]]: creative[slotType[slot.type]],
        revType: creative.revenueType,
        customKeyVal:
            creative?.customKeyVal?.map(_ => ({
                key: _.key,
                value: _.value,
                dataType: _.dataType,
            })) || [],
    }));
    const id = responses.find(_ => _.data?.data?.slotIndex === slot.index)?.data
        ?.data?.id;
    const params = {
        offerWallAdSlotId: slot.id !== "-" ? slot.id : id,
        [slotAds[slot.type]]: creatives,
    };
    return customFetch(
        `${SURVEYS.CREATE_AD_GROUP}/${groupCreate[slot.type]}`,
        "POST",
        params,
        header
    );
};

export const adGroupPutApiData = (slot, group, header = {}) => {
    const filteredCreatives = slot.editCreativeData.filter(
        c => c.id === "-" || c.isEdit
    );
    if (filteredCreatives.length) {
        const creativesObj = filteredCreatives.map(creative => {
            const obj = {
                creativeId: +creative.creative.split(":")[0],
                dealId: creative.dealId,
                dedupeKeys: creative.dedupeRules,
                [slotType[slot.type]]: creative[slotType[slot.type]],
                revType: creative.revenueType,
                [groupId[slot.type]]: group.id,
                customKeyVal:
                    creative?.customKeyVal?.map(_ => ({
                        key: _.key,
                        value: _.value,
                        dataType: _.dataType,
                    })) || [],
            };
            if (creative.id !== "-") {
                obj["id"] = creative.id;
            }
            return obj;
        });

        return creativesObj.map(c => {
            const path = c.id
                ? `${SURVEYS.CREATE_AD_GROUP}/${groupUpdate[slot.type]}/${c.id}`
                : `${SURVEYS.CREATE_AD_GROUP}/${groupUpdate[slot.type]}`;
            const requestType = `${c.id ? "PUT" : "POST"}`;
            return customFetch(path, requestType, c, header);
        });
    }
};

export const deleteSlotsApiData = (slots, header = {}) => {
    return slots.map(slot =>
        customFetch(`${DEALS.CREATE_AD_SLOT}/${slot.id}`, "DELETE", {}, header)
    );
};

export const deleteSlotsAdsApiData = (data, header = {}) => {
    return data.map(item =>
        customFetch(
            `${SURVEYS.DELETE_SLOT_AD}/${[groupUpdate[item.type]]}/${
                item.ad.id
            }`,
            "DELETE",
            {},
            header
        )
    );
};

export const slotType = {
    WeightGroup: "weight",
};

export const slotAds = {
    WeightGroup: "weightedAds",
};

export const groupCreate = {
    WeightGroup: "wg",
};

export const groupUpdate = {
    WeightGroup: "wa",
};

export const slotTypeSmallCase = {
    WeightGroup: "weightGroup",
};

export const groupId = {
    WeightGroup: "weightGroupId",
};
