import React from "react";

const LogoComponent = ({ name, size, className }) => (
    <svg className={className || ""} width={size} height={size}>
        <use href={`#${name}`} />
    </svg>
);

LogoComponent.defaultProps = {
    size: 30,
    color: "black",
};

export default LogoComponent;
