import {
    assign,
    cloneDeep,
    entries,
    flattenDeep,
    get,
    isEmpty,
    keys,
    throttle,
    isEqual,
    xorWith,
    uniq,
} from "lodash";
import Fuse from "fuse.js";
import Skeleton from "react-loading-skeleton";
import ReactDOMServer from "react-dom/server";
import { manipulateKeyVals } from "./dataManipulationUtils";
import { getType } from "./historyPopUpUtils";
import { v4 as uuidv4 } from "uuid";
import { MAIN } from "../constants/url";
import customFetch from "../fetch/customFetch";

export const clearUndefinedKeys = (array, checkProperties) => {
    return array.map(_ => {
        checkProperties.forEach(key => {
            if (!_[key]) {
                delete _[key];
            }
        });
        return _;
    });
};

export const isElementEmpty = children => {
    return !Boolean(ReactDOMServer.renderToStaticMarkup(children));
};

export const propEqualComparision = (prev, next, keys) => {
    return keys.every(key => prev[key] === next[key]);
};

export function DayToNumber(day) {
    switch (day) {
        case "Sunday":
            return 0;
        case "Monday":
            return 1;
        case "Tuesday":
            return 2;
        case "Wednesday":
            return 3;
        case "Thursday":
            return 4;
        case "Friday":
            return 5;
        case "Saturday":
            return 6;
        default:
            return 0;
    }
}
export function getHourMins() {
    let hours = [],
        minutes = [],
        i = 0;
    for (i = 1; i <= 12; i++) {
        hours.push((i < 10 ? "0" : "") + i);
    }
    for (i = 0; i < 60; i++) {
        minutes.push((i < 10 ? "0" : "") + i);
    }
    return { hours, minutes };
}
export function NumberToDay(num) {
    switch (num) {
        case 0:
            return "Sunday";
        case 1:
            return "Monday";
        case 2:
            return "Tuesday";
        case 3:
            return "Wednesday";
        case 4:
            return "Thursday";
        case 5:
            return "Friday";
        case 6:
            return "Saturday";
        default:
            return "Monday";
    }
}
export function openLink(link, isTargetBlank = true) {
    let a = document.createElement("a");
    if (isTargetBlank) a.target = "_blank";
    a.href = link;
    a.click();
}
export function checkDay(date) {
    const givenDate = new Date(date).setHours(0, 0, 0, 0);
    const todaysDate = new Date().setHours(0, 0, 0, 0);
    return givenDate === todaysDate
        ? "today"
        : todaysDate - givenDate === 86400000
        ? "yesterday"
        : new Date(date).toDateString();
}
export function isSameDay(firstDate, secondDate) {
    const dateOne = new Date(firstDate).setUTCHours(0, 0, 0, 0);
    const dateTwo = new Date(secondDate).setUTCHours(0, 0, 0, 0);
    return dateOne === dateTwo ? true : false;
}
export function checkIfEnterKey(e) {
    if (e) return (e.keyCode ? e.keyCode : e.which) === 13;
    return false;
}
export function getElemPos(elem) {
    if (elem) {
        var rect = elem.getBoundingClientRect();
        var win = window;
        var docElem = elem.ownerDocument.documentElement;
        return {
            top: rect.top + win.pageYOffset - docElem.clientTop,
            left: rect.left + win.pageXOffset - docElem.clientLeft,
            height: rect.height,
            width: rect.width,
        };
    }
    return null;
}

export function getTransformPositions(element) {
    if (!element) {
        return {
            left: 0,
            top: 0,
            scale: 1,
            height: 0,
            width: 0,
        };
    }
    var style = window.getComputedStyle(element);
    const matrix = new WebKitCSSMatrix(style.transform);
    return {
        height: element.getBoundingClientRect().height,
        width: element.getBoundingClientRect().width,
        left: matrix.m41,
        top: matrix.m42,
        scale: matrix.m11,
    };
}
export function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}
export function getLastTabName(tabs = {}, selectedTab) {
    tabs = keys(tabs);
    const [startName, index] = selectedTab.split(" ");
    const tabNumbers = tabs.map(tab => {
        const [startName, index] = tab.split(" ");
        return index;
    });
    tabNumbers.sort();
    const newTabNumber = tabNumbers.findIndex(tab => tab == Number(index));
    let lastTab = "";
    if (tabNumbers[newTabNumber + 1]) {
        lastTab = startName + " " + tabNumbers[newTabNumber + 1];
    } else {
        lastTab = startName + " " + tabNumbers[newTabNumber - 1];
    }
    return lastTab;
}

export function getNewTabName(tabs = {}) {
    tabs = keys(tabs);
    const [startName] = tabs
        .filter(t => t.toLowerCase() != "help")[0]
        .split(" ");
    const tabNumbers = tabs
        .filter(t => !isNaN(t.split(" ")[1]) && t.split(" ")[1])
        .map(tab => {
            const [startName, index] = tab.split(" ");
            return Number(index);
        });
    const lastTab = tabNumbers.sort((a, b) => a - b).pop();

    return startName + " " + (Number(lastTab) + 1);
}

export function scrollTo(id, offset = 215, container = window) {
    const el = document.getElementById(id);
    let scrollPos;
    if (container && el) {
        const viewportOffset = el.getBoundingClientRect();
        // these are relative to the viewport, i.e. the window
        const top = viewportOffset.top + container.scrollY;
        scrollPos = top - offset;
        container.scrollTo({
            top: scrollPos,
            left: 0,
            behavior: "smooth",
        });
    }
    return scrollPos;
}

export function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
        var reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
        el.className = el.className.replace(reg, " ");
    }
}

export function addClass(el, className) {
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += " " + className;
}

export function hasClass(el, className) {
    if (el.classList) return el.classList.contains(className);
    return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
}

export function convertRequestParamsTableData(data) {
    let apiData = {};
    for (let i = 0; i < data.length; i++) {
        const {
            name = "",
            isJson = false,
            isNesting = false,
            child = [],
        } = data[i];
        apiData[name] = isNesting
            ? isJson
                ? convertRequestParamsTableData(child)
                : convertToArray(child)
            : finalObj(data[i]);
    }
    return apiData;
}

function finalObj(data) {
    const { type, value, format, mandatory } = data;
    return assign({}, { type, value, format, mandatory });
}

function convertToArray(data = []) {
    let obj = [];
    for (let i = 0; i < data.length; i++) {
        const { isJson = false, isNesting = false, child = [] } = data[i];
        obj.push(
            isNesting
                ? isJson
                    ? convertRequestParamsTableData(child)
                    : convertToArray(child)
                : finalObj(data[i])
        );
    }
    return obj;
}

export function convertRequestParamsGetData(data) {
    let tableData = [];
    tableData = transform(data);
    return tableData;
}

function transform(object, parentId = null, level = 1) {
    if (Array.isArray(object)) {
        let objectCopy = JSON.parse(JSON.stringify(object));
        for (let i = 0; i < objectCopy.length; i++) {
            const value = objectCopy[i];
            const id = getRandomId();
            objectCopy[i] = assign(
                {
                    name: i,
                    id,
                    parentId,
                    level,
                    isNesting: keys(value).every(
                        k => typeof value[k] == "object"
                    ),
                    isJson: typeof value === "object" && !Array.isArray(value),
                    // isOpen: true,
                },
                value &&
                    typeof value === "object" &&
                    keys(value).every(k => typeof value[k] == "object")
                    ? { child: transform(value, id, level + 1) }
                    : {
                          ...value,
                          paramValues:
                              get(value, "value", "") != ""
                                  ? convertStringToArray(
                                        get(value, "value", "")
                                    )
                                  : [],
                      }
            );
        }
        return objectCopy;
    } else {
        return entries(object).map(([key, value]) => {
            const id = getRandomId();
            return assign(
                {
                    name: key,
                    id,
                    parentId,
                    level,
                    isNesting: keys(value).every(
                        k => typeof value[k] == "object"
                    ),
                    isJson: typeof value === "object" && !Array.isArray(value),
                    // isOpen: true,
                },
                value &&
                    typeof value === "object" &&
                    keys(value).every(k => typeof value[k] == "object")
                    ? { child: transform(value, id, level + 1) }
                    : {
                          ...value,
                          paramValues:
                              get(value, "value", "") != ""
                                  ? convertStringToArray(
                                        get(value, "value", "")
                                    )
                                  : [],
                      }
            );
        });
    }
}

export function getRandomId() {
    return uuidv4();
}

export function convertStringToArray(str) {
    if (str) {
        if (typeof str != "string") {
            str = str.toString();
        }
        str = str.replace(
            new RegExp(/\%\%(.*?)\%\%/gs),
            function (match, capture) {
                return match + "@@@@";
            }
        );
        return flattenDeep(
            str
                .split("@@@@")
                .filter(i => !!i)
                .map(i => {
                    let index = i.indexOf("%%");
                    if (index > 0)
                        return [i.substring(0, index), i.substring(index)];
                    return i;
                })
        ).map((item, index) => {
            let typeV = new RegExp(/%%(.*?)%%/g).test(item),
                data = {};
            data.type = typeV ? "V" : "S";
            let newValue = typeV ? item.substring(2, item.length - 2) : item;
            data.selectedItem = {
                id: index,
                key: newValue.toLowerCase(),
                text: newValue,
            };
            return data;
        });
    }
}

export function convertArrayToString(values = []) {
    return values.reduce((acc, value) => {
        let val = get(value, "selectedItem.text", "");
        acc += get(value, "type", "") == "V" ? "%%" + val + "%%" : val;
        return acc;
    }, "");
}

export const findObjects = (obj, targetProp, targetValue, finalResults) => {
    function getTargetObject(theObject) {
        let result = null;
        if (theObject instanceof Array) {
            for (let i = 0; i < theObject.length; i++) {
                getTargetObject(theObject[i]);
            }
        } else {
            for (let prop in theObject) {
                if (theObject.hasOwnProperty(prop)) {
                    if (prop === targetProp) {
                        if (theObject[prop] === targetValue) {
                            finalResults.push(theObject);
                        }
                    }
                    if (
                        theObject[prop] instanceof Object ||
                        theObject[prop] instanceof Array
                    ) {
                        getTargetObject(theObject[prop]);
                    }
                }
            }
        }
    }
    getTargetObject(obj);
};

export const validateWebsiteUrl = websiteUrl => {
    var res = websiteUrl.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
};

export function search(searchTerm = "", data = [], key = ["name"]) {
    if (searchTerm.length == 0) return data;
    data = Object.values(data);
    const fuseOptions = {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 50,
        maxPatternLength: 12,
        minMatchCharLength: 1,
    };
    if (key.length > 0) fuseOptions.keys = [...key];
    const fuse = new Fuse(data, fuseOptions);
    const res = searchTerm
        ? fuse.search(searchTerm).map(character => character.item)
        : data;
    return res;
}

export const skeletonLoader = array => {
    return array.map((element, index) => {
        return (
            <div
                key={`${getRandomId()}-${element}-${getRandomId()}-${index}-${getRandomId()}`}
            >
                <Skeleton height={element} />
            </div>
        );
    });
};

export const getElementPositionAndSize = elementRef => {
    return elementRef.current.getBoundingClientRect();
};

export const multiInputDataRefactorFromApi = array => {
    let valueContainer = [];
    array.map((element, index) => {
        let newElement = element.toString().split("-");
        let outPut = {
            valueOne: newElement[0],
            valueSecond: newElement[1],
        };
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const multiInputDataRefactorToApi = array => {
    let valueContainer = [];
    array.map((element, index) => {
        let outPut = `${element.valueOne}-${element.valueSecond}`;
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const inputDropdownDataRefactorFromApi = array => {
    let valueContainer = [];
    array.map((element, index) => {
        let outPut = {
            valueOne: element.keyword,
            valueSecond: element.matchType,
        };
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const inputDropdownDataRefactorToApi = array => {
    let valueContainer = [];
    array.map((element, index) => {
        let outPut = {
            keyword: element.valueOne,
            matchType: element.valueSecond,
        };
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const keyValsFromApi = (array, type) => {
    let ABtestContainer = [];
    let keyValuesContainer = [];
    let surveyContainer = [];
    if (array && array.length) {
        array.map((element, index) => {
            if (typeof element != "object") {
                let newElement = element.toString().split(":");
                let decodedData = decodeValue(newElement[1]);

                if (newElement[0].indexOf("abt") > -1) {
                    let outPut = {
                        valueOne: newElement[0],
                        valueSecond: decodedData,
                    };
                    ABtestContainer.push(outPut);
                } else if (newElement[0].indexOf("surveyId") > -1) {
                    let data = decodedData.split(" ").map(i => Number(i));
                    surveyContainer = data;
                } else {
                    let outPut = {
                        valueOne: newElement[0],
                        valueSecond: decodedData,
                    };
                    keyValuesContainer.push(outPut);
                }
            } else {
                if (element.key.indexOf("abt") > -1) {
                    let outPut = {
                        valueOne: element.key,
                        valueSecond: element.value,
                    };
                    ABtestContainer.push(outPut);
                } else if (element.key.indexOf("surveyId") > -1) {
                    let data = element.value.split(" ").map(i => Number(i));
                    surveyContainer = data;
                } else {
                    let outPut = {
                        valueOne: element.key,
                        valueSecond: element.value,
                    };
                    keyValuesContainer.push(outPut);
                }
            }
        });
    }

    if (type == "ABtest") return ABtestContainer;
    else if (type == "survey") return surveyContainer;
    else return keyValuesContainer;
};

export const keyValsToApi = (array, newType = false, sameNameAlreadyEsists) => {
    let valueContainer = [];
    array.map((element, index) => {
        let outPut = `${element.valueOne}:${element.valueSecond}`;
        if (newType) {
            let ifIndexExist = valueContainer.findIndex((item, idx) => {
                return item.split(":")[0] == element.valueOne;
            });

            if (ifIndexExist == -1) {
                valueContainer.push(outPut);
            } else {
                // key of the particular valueContainer in which the value is being inserted
                let getValueContainerKey = [valueContainer[ifIndexExist]];

                let duplicateValueExist = getValueContainerKey.findIndex(
                    (item, idx) => {
                        return item
                            .split(":")[1]
                            .split(",")
                            .includes(element.valueSecond.trim());
                    }
                );

                if (duplicateValueExist == -1) {
                    valueContainer[ifIndexExist] = `${
                        valueContainer[ifIndexExist].split(":")[0]
                    }:${
                        valueContainer[ifIndexExist].split(":")[1]
                    },${element.valueSecond.trim()}`;
                } else {
                    sameNameAlreadyEsists();
                }
            }
        } else {
            valueContainer.push(outPut);
        }
    });
    return valueContainer;
};

export const getTargettingParams = (
    targetParams,
    surveyIds = [],
    overrideSurveyIds = false,
    toBeDeletedSurveyId = null,
    toEncode = false,
    keyValuesNewType = false
) => {
    const obj = {};
    for (const [key, value] of Object.entries(targetParams)) {
        if (value.constructor === Array) {
            obj[key] = value.length ? value : null;
        } else {
            obj[key] = value ? value : null;
        }
    }

    // we have to override surveyIds in keyvals for individual offerpath case only, rest in all the places we should
    // append the surveyids
    if (surveyIds.length) {
        const surveyIdKeyVals = `surveyId:${surveyIds.join(" ")}`;
        if (obj["keyVals"]) {
            let existingSurveyIds = obj["keyVals"].filter(_ =>
                _.includes("surveyId")
            );

            if (existingSurveyIds.length) {
                obj["keyVals"] = obj["keyVals"].filter(
                    value => !value.includes("surveyId")
                );
                if (existingSurveyIds[0].includes(surveyIds[0])) surveyIds = [];
                existingSurveyIds = surveyIds.length
                    ? `${existingSurveyIds} ${surveyIds.join(" ")}`
                    : `${existingSurveyIds}`;
            }
            if (toBeDeletedSurveyId) {
                existingSurveyIds = existingSurveyIds.replace(
                    `${toBeDeletedSurveyId}`,
                    ""
                );
            }

            const newSurveyIds =
                existingSurveyIds.length && !overrideSurveyIds
                    ? existingSurveyIds
                    : surveyIdKeyVals;
            obj["keyVals"] = [...obj["keyVals"], newSurveyIds];
        } else {
            obj["keyVals"] = [surveyIdKeyVals];
        }
    }
    if (toEncode) {
        obj["keyVals"] = encodeKeyVals(obj["keyVals"], keyValuesNewType);
    }

    return obj;
};

export const encodeKeyVals = (data, keyValuesNewType = false) => {
    return manipulateKeyVals(data, keyValuesNewType);
};

export const OBJtoXML = obj => {
    var xml = "";
    for (var prop in obj) {
        xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
        if (obj[prop] instanceof Array) {
            for (var array in obj[prop]) {
                xml += "<" + prop + ">";
                xml += OBJtoXML(new Object(obj[prop][array]));
                xml += "</" + prop + ">";
            }
        } else if (typeof obj[prop] == "object") {
            xml += OBJtoXML(new Object(obj[prop]));
        } else {
            xml += obj[prop];
        }
        xml += obj[prop] instanceof Array ? "" : "</" + prop + ">";
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
    return xml;
};

export const mapTargettingParams = (targetParams, keyValueNewType = false) => {
    const obj = { availableSet: [] };
    if (targetParams) {
        for (const [key, value] of Object.entries(targetParams)) {
            if (value === null) {
                // if (key === "spamCheck") {
                //     obj[key] = {
                //         spamIP: false,
                //         category: [],
                //     };
                // } else {
                //     obj[key] = [];
                // }

                if (key !== "spamCheck") {
                    obj[key] = [];
                }
                //  Remove the above if condition if spamIp is again needed.
            } else {
                if (key !== "spamCheck") {
                    obj[key] = value;
                }
                //  Remove the above if condition if spamIp is again needed.
                let newKeyVal = [];
                if (key === "keyVals") {
                    obj[key].forEach(item => {
                        let keyValues = item?.toString()?.split(":");
                        let itemKey = keyValues[0];
                        let updatedValue = "";
                        let decodedValue = "";
                        if (
                            typeof item === "string" &&
                            !isEmpty(keyValues[1])
                        ) {
                            if (keyValueNewType) {
                                let valueArray = keyValues[1].split(" ");
                                if (valueArray.length > 1) {
                                    let text = "";
                                    valueArray.forEach((element, idx) => {
                                        if (idx == valueArray.length - 1) {
                                            text += decodeURI(element);
                                        } else {
                                            text += decodeURI(element) + ",";
                                        }
                                    });

                                    updatedValue = `${keyValues[0]}:${text}`;
                                    newKeyVal.push(updatedValue);
                                } else {
                                    let ifExistIndex = newKeyVal.findIndex(
                                        (item, idx) => {
                                            return (
                                                item.split(":")[0] ==
                                                keyValues[0]
                                            );
                                        }
                                    );
                                    if (ifExistIndex == -1) {
                                        updatedValue = `${
                                            keyValues[0]
                                        }:${decodeURI(keyValues[1])}`;
                                        newKeyVal.push(updatedValue);
                                    } else {
                                        newKeyVal[ifExistIndex] = `${
                                            newKeyVal[ifExistIndex].split(
                                                ":"
                                            )[0]
                                        }:${
                                            newKeyVal[ifExistIndex].split(
                                                ":"
                                            )[1]
                                        },${decodeURI(keyValues[1]).trim()}`;
                                    }
                                }
                            } else {
                                decodedValue = decodeValue(keyValues[1]);
                                updatedValue = `${keyValues[0]}:${decodedValue}`;
                                newKeyVal.push(updatedValue);
                            }
                        } else {
                            newKeyVal = Array.isArray(item) ? item : [item];
                        }

                        if (
                            itemKey.indexOf("abt") !== -1 &&
                            !obj.availableSet.includes("ABTests")
                        ) {
                            obj.availableSet.push("ABTests");
                        }

                        if (
                            itemKey.indexOf("abt") === -1 &&
                            itemKey.indexOf("surveyId") === -1 &&
                            !obj.availableSet.includes("keyValues")
                        ) {
                            obj.availableSet.push("keyValues");
                        }
                    });
                    obj[key] = newKeyVal;
                } else {
                    if (key !== "spamCheck") {
                        obj.availableSet.push(key);
                    }
                    //  Remove the above if condition if spamIp is again needed.
                }
                // else if (key === "spamCheck") {
                //     obj[key] = {
                //         spamIP:
                //             obj[key].spamIP !== ""
                //                 ? obj[key].spamIP === "true"
                //                 : "false",
                //         category:
                //             obj[key].category != null ? obj[key].category : [],
                //     };

                //     if (obj[key].category?.length > 0) {
                //         obj.availableSet.push(key);
                //     }
                // }
            }
        }
    } else {
        obj.availableSet = [];
    }
    return obj;
};

export const mapABTargettingParams = targetParams => {
    const obj = { availableSet: [] };
    if (targetParams) {
        for (const [key, value] of Object.entries(targetParams)) {
            if (value === null) {
                if (key !== "spamCheck") {
                    obj[key] = [];
                }
                //  Remove the above if condition if spamIp is again needed.

                // if (key === "spamCheck") {
                //     obj[key] = {
                //         spamIP: false,
                //         category: [],
                //     };
                // } else {
                //     obj[key] = [];
                // }
            } else {
                if (key !== "spamCheck") {
                    obj[key] = value;
                }
                //  Remove the above if condition if spamIp is again needed.
                if (key === "keyVals") {
                    let stringArray = [];

                    obj[key].forEach(item => {
                        let itemKey = item.key;

                        if (
                            itemKey.indexOf("abt") !== -1 &&
                            !obj.availableSet.includes("ABTests")
                        ) {
                            obj.availableSet.push("ABTests");
                        }

                        if (
                            itemKey.indexOf("surveyId") !== -1 &&
                            !obj.availableSet.includes("surveyId")
                        ) {
                            obj.availableSet.push("surveyId");
                        }

                        if (
                            itemKey.indexOf("abt") === -1 &&
                            itemKey.indexOf("surveyId") === -1 &&
                            !obj.availableSet.includes("keyValues")
                        ) {
                            obj.availableSet.push("keyValues");
                        }
                    });

                    obj[key].map(pair => {
                        stringArray.push(pair.key + ":" + pair.value);
                    });
                    obj["keyVals"] = stringArray;
                } else {
                    if (key !== "spamCheck") {
                        obj.availableSet.push(key);
                    }
                    //  Remove the above if condition if spamIp is again needed.
                }

                // else if (key === "spamCheck") {
                //     obj[key] = {
                //         spamIP:
                //             obj[key].spamIP !== ""
                //                 ? obj[key].spamIP === "true"
                //                 : "false",
                //         category:
                //             obj[key].category != null ? obj[key].category : [],
                //     };
                //     if (obj[key].category?.length > 0) {
                //         obj.availableSet.push(key);
                //     }
                // }
            }
        }
    } else {
        obj.availableSet = [];
    }
    return obj;
};

export const getFeatureMapItem = (array, scopeChoices) => {
    let data = [];
    array.length > 0 &&
        array.map(item => {
            data.push({
                ...item,
                isError:
                    item.valuekind === "default value" ||
                    (item.value && item.value.length > 0)
                        ? false
                        : true,
                scopeChoices: scopeChoices,
            });
        });
    return data;
};

export const mapAbTestEditPage = (
    array,
    obj,
    listData,
    scopeChoices = [
        "global",
        "landing",
        "survey",
        "offers",
        "offer path",
        "listings",
        "fulfillment",
    ]
) => {
    let editedData = {};
    let pageOrder = [];
    array.length > 0 &&
        array.map((element, index) => {
            editedData[`Page ${index + 1}`] = {
                ...JSON.parse(JSON.stringify(obj)),
            };
            editedData[`Page ${index + 1}`].basics.name = element.name;
            editedData[`Page ${index + 1}`].name = element.name;
            editedData[`Page ${index + 1}`].basics.weight = element.weight;
            editedData[`Page ${index + 1}`].basics.id = element.id;
            editedData[`Page ${index + 1}`].targettingParam.targetParams =
                mapABTargettingParams(element.targeting);
            editedData[
                `Page ${index + 1}`
            ].exclusionTargettingParam.targetParams = mapABTargettingParams(
                element.exclusionTargeting
            );
            editedData[`Page ${index + 1}`].featureMaps.updatedList =
                feedFeatureMapListData(element.featureMap, listData);
            // editedData[`Page ${index + 1}`].featureMaps.savedList = getFeatureMapItem(element.featureMap);
            if (element.featureMap != null) {
                editedData[`Page ${index + 1}`].featureMaps.savedList =
                    getFeatureMapItem(element.featureMap, scopeChoices);

                let selectedKeys = getFeatureMapItem(
                    element.featureMap,
                    scopeChoices
                ).map(item => {
                    return item.key;
                });
                editedData[`Page ${index + 1}`].featureMaps.selected =
                    selectedKeys;
                editedData[
                    `Page ${index + 1}`
                ].featureMaps.selectedListActiveItem =
                    selectedKeys[selectedKeys.length - 1];
                editedData[`Page ${index + 1}`].featureMaps.selectedDetails =
                    selectedKeys[selectedKeys.length - 1];
            }
            pageOrder.unshift(`Page ${index + 1}`);
        });

    return { editedData, pageOrder };
};

export const hnpParameterTypes = [
    {
        id: 1,
        key: "text",
        text: "Text",
    },
    {
        id: 2,
        key: "number",
        text: "Number",
    },
    {
        id: 3,
        key: "date",
        text: "Date",
    },
    {
        id: 4,
        key: "boolean",
        text: "Boolean",
    },
];
/**
 *
 *
 * @param {*} requiredFields - Fields which we want in the final object. Array of strings
 * @param {*} initialObj - Entire initial object from which we want to get the required object
 * @return {*} Returns new object with only the required fields else {}
 */
export const getRequiredObject = (requiredFields, initialObj) => {
    const filteredObject = Object.keys(initialObj)
        .filter(key => requiredFields.includes(key))
        .reduce((obj, key) => {
            obj[key] = initialObj[key];
            return obj;
        }, {});
    return filteredObject;
};

export const scrollToElem = (elemenRef, OffsetValue = 300) => {
    let headerOffset = OffsetValue;
    let elementPosition = elemenRef.current.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
    });
};

/**
 *
 *
 * @param {*} obj - Object in which the field is to be checked
 * @param {*} key - Key for targetting the required element in the above object
 * @return {*} Returns true if the value is "", undefined OR null
 */
const isEmptyValue = (obj, key) => {
    return obj[key] === "" || obj[key] === undefined || obj[key] === null;
};

/**
 *
 *
 * @param {*} data - Data which is to be transformed(Array of objects)
 * @return {*} Returns new array with empty values replaced by -
 */
export const transformData = data => {
    if (data.length) {
        let arr = [...data];
        let updatedData = [];
        arr.map(obj => {
            Object.keys(obj).forEach(key => {
                if (Array.isArray(obj[key])) {
                    transformData(obj[key]);
                }

                if (isEmptyValue(obj, key)) {
                    let newObj = { ...obj };
                    newObj[key] = "-";
                    obj = { ...newObj };
                }
            });
            updatedData.push(obj);
            return obj;
        });
        return updatedData;
    } else return data;
};

export const paralax = (arr, topOffset) => {
    const temp = arr.map(_ => {
        return {
            id: _,
            top: document.getElementById(_).getBoundingClientRect().top,
            bottom: document.getElementById(_).getBoundingClientRect().bottom,
            height: document.getElementById(_).getBoundingClientRect().height,
        };
    });
    let previourScrollY = 0;
    const callback = throttle(e => {
        if (window.scrollY > previourScrollY) {
            temp.forEach((item, index) => {
                if (
                    window.scrollY >
                        item.top -
                            topOffset -
                            temp[index > 0 ? index - 1 : 0].height * 0.2 &&
                    window.scrollY < item.top - topOffset
                ) {
                    scrollTo(item.id, 127);
                    return;
                }
            });
        }
        previourScrollY = window.scrollY;
    }, 100);

    window.addEventListener("scroll", callback);

    return {
        destroy: () => {
            window.removeEventListener("scroll", callback);
        },
    };
};
export const feedFeatureMapListData = (featureMapToBeFeed, featureMapList) => {
    let clonedFeatureMap = cloneDeep(featureMapToBeFeed);
    let clonedList = cloneDeep(featureMapList);
    clonedFeatureMap &&
        clonedFeatureMap.forEach(val => {
            for (let i = 0; i < clonedList.length; i++) {
                if (val.key === clonedList[i].key) {
                    clonedList[i]["value"] = val.value;
                    clonedList[i]["scope"] = val.scope;
                    if (val.value && val.value.length > 0) {
                        clonedList[i].isError = false;
                    }
                    break;
                }
            }
        });
    return clonedList;
};

export const setTestLeadDefaultValues = val => {
    val = val.toString();
    let defaultValues = {
        dob: "02-01-1977",
        dob_month: "02",
        dob_day: "01",
        dob_year: "1977",
        zip: "98204",
        zip_code: "10024",
        email: "john.sobieski@themail.com",
        fname: "John",
        first_name: "John",
        lname: "Sobieski",
        last_name: "Sobieski",
        region: "NY",
        utm_source: "ls",
        utm_campaign: "gigspremium",
        utm_content: "none",
        gender: "Male",
        ipaddress: "8.8.8.8",
        public_ip: "8.8.8.8",
        user_ip: "173.252.95.3",
        city: "Albany",
        country: "US",
        uuid: "2b559a35065c408497d7992155695ced",
        age: "44",
        job_type: "amazon",
        utm_term: "warehouse Jobs",
        utm_medium: "cpc",
        std: "2bc358802f2a4d6f8b6728de87c5aa28",
        keyword: "stocker",
        phone: "5417543010",
        phone_number: "5417543010",
        phone_code: "541",
        phone_prefix: "754",
        phone_suffix: "3010",
        geo_ip: "156.146.37.218",
        geo_state: "NY",
        geo_city: "New York",
        address: "12/3",
        address2: "West Park",
        md5email: "6d45453bbf6b373340b40942fc460e15",
        theme_color: "#ffb04",
        theme_gradient: "linear-gradient(to bottom, #ffb044, #ffe42a)",
        theme_text_color: "#fff",
        theme_border: "#ff2222",
        theme_border_radius: "2px",
        theme_font_size: "12px",
        theme_font_weight: "400",
        theme_height: "100px",
        theme_width: "768px",
        opid: "3",
        subid: "1006",
        sub_source_id: "toppos3",
        encoded_source: "cGdtdGMtMTA5Nzc5",
    };

    if (val.includes("http")) {
        return val;
    }
    let modifiedValueInitial = val.toLowerCase();
    let modifiedValue = val
        .toLowerCase()
        .split("%%")
        .filter(n => n);

    let initialModifiedValueSplit = val
        .toLowerCase()
        .split("%%")
        .filter(n => n);
    let macrosKeys = Object.keys(defaultValues);
    let storeSeparators = [];
    let i = -1;
    [modifiedValue, storeSeparators] = _.partition(
        modifiedValue,
        item => i++ % 2
    ); // We can make this dynamic or based on the flag for first operator, but needs to be reworked. (For now will work with normal flow)

    if (!isEmpty(storeSeparators)) {
        let storeValue = "";

        initialModifiedValueSplit.forEach(value => {
            let cleanValue = value;

            // The below regex checks for special characters
            const specialChars = /^[^a-zA-Z0-9]+$/;

            // The below logic is not dependent on storeSeparators for adding % sign, instead will use the above regex to do so.
            storeValue += macrosKeys.includes(cleanValue)
                ? defaultValues[cleanValue]
                : specialChars.test(cleanValue)
                ? cleanValue
                : storeSeparators.includes(cleanValue)
                ? specialChars.test(cleanValue)
                    ? cleanValue
                    : `%%${cleanValue}%%`
                : `%%${cleanValue}%%`;
        });
        return storeValue;
    }

    return defaultValues.hasOwnProperty(modifiedValueInitial.replace(/%%/g, ""))
        ? defaultValues[modifiedValueInitial.replace(/%%/g, "")]
        : val;
};

export const manipulaTestLeadData = (
    inputValue,
    previousValue,
    objectData,
    onTestLeadChange
) => {
    if (
        inputValue &&
        inputValue.trim() !== "" &&
        !isEqual(previousValue, inputValue)
    ) {
        let paramTypeIndex = hnpParameterTypes.findIndex(
            val => val.key === objectData["type"]
        );
        let modifiedData = cloneDeep(objectData["paramValues"][0]);
        modifiedData["selectedItem"]["key"] = inputValue;
        modifiedData["selectedItem"]["text"] = inputValue;
        modifiedData["type"] = "S";
        onTestLeadChange(
            hnpParameterTypes[paramTypeIndex],
            null,
            [modifiedData],
            true,
            [objectData]
        );
    }
};

export const roundOffNumber = (num, decimals, withComma = false) => {
    if (isNaN(num) || !num) {
        return num;
    } else {
        if (withComma) {
            return Number(num)?.toLocaleString("en-US", {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            });
        } else {
            return Number(num)?.toFixed(decimals);
        }
    }
};

export const handleJSONString = (str = "") => {
    let result = "";
    try {
        result = decodeURIComponent(str);
    } catch (e) {
        result = str;
    }
    return result;
};

export const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

/**
 * Use to decode the value sent using decodeURI
 *
 * @param {*} value (string value which needs to be decoded)
 * @return {*} decodedValue
 */
export const decodeValue = (value = "") => {
    let decodedValue = "";
    try {
        decodedValue = decodeURI(value);
    } catch (err) {
        decodedValue = value;
    }
    return decodedValue;
};

export const windowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
};

export const getInitialRowsCount = () => {
    return windowDimensions().height > 900 ? 15 : 10;
};

export const dynamicSort = property => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
};

export const capitalizeString = string => {
    if (typeof string === "string") {
        return string
            ?.toLowerCase()
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.substring(1))
            .join(" ");
    } else return string;
};

export const isValidIP = IP => {
    return /^(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(IP);
};

//Function to check if Id has atleast 1 char and can be alphanumeric
export const isValidId = id => {
    var res = id?.match(/^(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/);
    if (res == null) return false;
    else return true;
};

//Function to check if Id has atleast 1 char and can be alphanumeric and special characters _ and -
export const isValidIdPublisher = id => {
    var res = id?.match(/^(?=.*[a-zA-Z])([a-zA-Z0-9_-]+)$/);
    if (res == null) return false;
    else return true;
};

export const clearLocalStorage = () => {
    localStorage.removeItem("pinData");
    localStorage.removeItem("recentSearch");
    localStorage.removeItem("recentFilter");
    localStorage.removeItem("featureClipboard");
};

export const getTwoDigits = num => {
    //to return number in 2 digits, used with date and month
    return ("0" + num).slice(-2);
};

export const validateNumberInput = elementRef => {
    if (elementRef.current.value() === "") {
        return false;
    } else {
        if (elementRef.current.value().toString !== "") return true;
        else return false;
    }
};

export const getPreviewObj = (
    item,
    entitySubType,
    outerLayout = {},
    featureGroupProperties = {},
    flowQuestionPreview = false
) => {
    let obj = {
        rule: {
            ...(flowQuestionPreview
                ? {
                      mock: 1,
                      container: {
                          type: "SURVEY",
                          mock: 1,
                          items: [
                              {
                                  properties: {
                                      question_details: {
                                          type: item.type,
                                          answers: item.answers,
                                          hasOther: item.hasOther,
                                          question: item.question,
                                          subHeader: item.subHeader,
                                          logoImageSrc: item.logoImageSrc,
                                          bannerImageSrc: item.bannerImageSrc,
                                      },
                                      mock: 1,
                                  },
                                  mock: 1,
                              },
                          ],
                      },
                  }
                : {
                      entityType: "CONTAINER",
                      entitySubType: entitySubType,
                      container: {
                          items: [
                              ...(item?.default
                                  ? [
                                        {
                                            layoutConfig: item.layout || {},
                                            mock: 1,
                                        },
                                    ]
                                  : [
                                        {
                                            rank: item.rank,
                                            properties: item.properties || {
                                                fields: ["zipCode"],
                                            },
                                            tcpa: item.tcpa || [],
                                            hnpLeads: item.hnpLeads || [],
                                            hnpLeadIds: item.hnpLeadIds || [],
                                            layoutConfig:
                                                item.layoutConfig || {},
                                        },
                                    ]),
                          ],
                          type: entitySubType,
                          templateDto: {
                              properties: {
                                  ...(isEmpty(outerLayout)
                                      ? {}
                                      : { containerLayoutConfig: outerLayout }),
                                  ...(isEmpty(featureGroupProperties)
                                      ? {}
                                      : featureGroupProperties),
                              },
                          },
                      },
                  }),
        },

        config: {},
        userState: {
            mock: 1,
        },
        containerRules: [],
    };

    return obj;
};

export const getQueryVariable = variable => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
};

export const removeIgnoredItems = (data, ignoreList = []) => {
    let temp = cloneDeep(data);

    if (getType(temp) === "array") {
        temp = temp.map(_ => removeIgnoredItems(_, ignoreList));
    }
    if (getType(temp) === "object") {
        Object.keys(temp).forEach(_ => {
            if (ignoreList.includes(_)) delete temp[_];
        });
    }
    return temp;
};

export const deepObjectComparison = (obj1, obj2) => {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
        return Object.keys(obj1).every(
            key => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
        );
    }
    return false;
};

export const getHTMLTextData = text => {
    var span = document.createElement("span");
    span.innerHTML = typeof text === "string" ? text : "";
    return span.textContent || span.innerText;
};

export const dependencyApi = async (entityType, entityId, errorFunction) => {
    const dependencies = await customFetch(
        `${MAIN.DEPENDENCY}&targetComponentType=${entityType}&targetComponentId=${entityId}`,
        "GET",
        {},
        {},
        {},
        null,
        null,
        () => {
            if (typeof errorFunction === "function") errorFunction();
        }
    );
    return dependencies;
};

export const getUrlParams = url => {
    const params = {};
    const queryStringStart = url?.indexOf("?");
    const queryString =
        queryStringStart !== -1 ? url.substring(queryStringStart + 1) : "";

    const paramPairs = queryString.split("&");
    paramPairs.forEach(pair => {
        if (pair) {
            const [key, value] = pair.split("=");
            params[key] = value?.replace(/\+/g, " ");
        }
    });
    return params;
};
