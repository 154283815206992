import React, { useEffect } from "react";
import { sendSentryException } from "./utils/sentryUtils";
import CustomMessagePage from "./Pages/CustomMessagePage/CustomMessagePage";
import { useHistory } from "react-router-dom";

export function ErrorFallback({ error }) {
    // Handle failed lazy loading of a JS/CSS chunk.
    console.error(error, error.name, error.message, "error");

    const setWithExpiry = (key, value, ttl) => {
        const item = {
            value: value,
            expiry: new Date().getTime() + ttl,
        };
        sessionStorage.setItem(key, JSON.stringify(item));
    };

    const getWithExpiry = key => {
        const itemString = window.sessionStorage.getItem(key);
        if (!itemString) return null;

        const item = JSON.parse(itemString);
        const isExpired = new Date().getTime() > item.expiry;

        if (isExpired) {
            sessionStorage.removeItem(key);
            return null;
        }

        return item.value;
    };

    useEffect(() => {
        sendSentryException(error);
        const chunkFailedMessage = /ChunkLoadError/;
        if (error?.name && chunkFailedMessage.test(error.name)) {
            if (!getWithExpiry("chunk_failed")) {
                setWithExpiry("chunk_failed", "true", 60000);
                window.location.reload();
            }
        }
    }, [error]);

    return (
        <>
            <CustomMessagePage
                imgFile={"Error Msg"}
                fullHeight={true}
                message={
                    <>
                        <p>Oops, Something went wrong.</p>
                        We're working on getting this fixed as soon as we can.
                        Please contact admin if the problem persists.
                    </>
                }
                showActionBtn={true}
                actionBtnHandler={() => window.history.back()}
                actionBtnText={"Go Back"}
            />
        </>
    );
}
