import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import {
    SCRUB_DASHBOARD,
    OFFLINE_CONVERSION,
    HNP_URL,
    USER_JOURNEY,
} from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { get, isEmpty } from "lodash";
import {
    mapAdvertiser,
    mapPublisher,
    mapScrubDashboardData,
    mapCampaign,
} from "../../utils/scrubDashboardUtils";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import { FILTERS } from "../../constants/listingSearch";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { createTagUtil } from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { uploadDateFormat } from "../../utils/dateUtils";

const initialDefaultDateRange = {
    startDate: startOfDay(subDays(new Date(), 7)),
    endDate: new Date(),
    key: "selection",
};

export const typeList = [
    {
        id: "offerpath",
        name: "offerpath",
    },
    {
        id: "offerwall",
        name: "offerwall",
    },
    {
        id: "entry confirmation",
        name: "entry confirmation",
    },
    {
        id: "SERP",
        name: "SERP",
    },
    {
        id: "data",
        name: "Data",
    },
    {
        id: "landing",
        name: "Landing",
    },
];

const initialState = {
    list: [],
    isPublisherScrub: false,
    advertisersList: [],
    publisherList: [],
    campaignList: [],
    domainList: [],
    typeList: typeList,
    dsCost: "",
    fetchedDSCost: "",
    advCost: "",
    rpcValue: "",
    total_clicks: "",
    scrubRatio: "",
    discountFactor: 0,
    index: null,
    selectedAdvertiserId: null,
    selectedAdvertiserName: null,
    selectedPublisherId: null,
    selectedPublisherName: null,
    selectedDate: new Date(),
    selectedCampaignId: null,
    selectedCampaignName: null,
    selectedCampaignIds: [],
    selectedCampaignNames: [],
    selectedDomainId: null,
    selectedDomainName: null,
    selectedVertical: null,
    selectedType: null,
    product: null,
    timeZone: null,
    lookBack: null,
    updatedAt: new Date(),
    loading: false,
    advLoader: false,
    mainLoader: false,
    isValid: false,
    uploadLoader: false,
    formDate: initialDefaultDateRange,
    maxDate: new Date(),
    minDate: new Date(),
    isDSCostFetched: false,
    isInputLoading: false,
    showFireEvents: false,
    dateArray: [],
    viewType: "Auto Calculated scrub",
    revenueTableList: [],
    rowColor: null,
    rowType: {},
    initialRowType: {},
    showRevenueTable: false,
    revenueTable: [],
    selectedScrubType: null,
    // formSelectedDate: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "updatedAt",
        isDesc: false,
        totalRowsCount: 0,
        type: "advertiser",
        filterKey: FILTERS.ADVERTISER_SCRUB_DASHBOARD.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.ADVERTISER_SCRUB_DASHBOARD.filterOperator,
        filtersOperator: FILTERS.ADVERTISER_SCRUB_DASHBOARD.operator,
        entityType: "scrub_action",
        isPrefixOperator: true,
    },
    publisherPopup: {
        id: "",
        name: "",
        isSameId: false,
    },
    campaignPopup: {
        publisherId: "",
        campaignId: "",
        campaignName: "",
        isSameCombination: false,
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchScrubDashboardData = createAsyncThunk(
    "scrubDashboard/fetchScrubDashboard",
    async (_, { getState, dispatch }) => {
        const pagination = get(
            getState(),
            "listings.scrubDashboard.pagination",
            {}
        );
        const params = paginationParams(pagination);
        const { listings } = getState();
        const isPublisherScrub = get(
            listings,
            "scrubDashboard.isPublisherScrub",
            false
        );
        const loading = get(listings, "scrubDashboard.loading", false);

        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        const filterParamsData = getRequiredObject(filterParamsList, params);
        let dispatchSearchError = true;
        const response = await customFetch(
            SCRUB_DASHBOARD.CHANGE_LOG_FILTER,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            e => {
                dispatchSearchError = false;
                const isNoSearchresults =
                    e?.response?.data === "No change logs found for filters"
                        ? true
                        : false;
                if (!isNoSearchresults)
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch scrub dashboard data",
                        })
                    );
                dispatch(
                    scrubDashboardSlice.actions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );
        let data = get(response, "data", []);
        // Using direct filterterm to show message as the API give 404 and no response
        if (pagination.filterTerm) {
            let columnName = isPublisherScrub
                ? MESSAGES.TABLE_COLUMNS.PUBLISHER_SCRUB_DASHBOARD
                : MESSAGES.TABLE_COLUMNS.ADVERTISER_SCRUB_DASHBOARD;
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${columnName}`;
            dispatch(scrubDashboardSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length && !loading) {
            var errorMessage = `${MESSAGES.TABLE.scrubDashboardNoData}`;
            dispatch(scrubDashboardSlice.actions.setTableError(errorMessage));
        }
        if (!isPublisherScrub) {
            const advertiserList = get(
                listings,
                "scrubDashboard.advertisersList",
                []
            );
            if (!isEmpty(advertiserList)) {
                data = await mapScrubDashboardData(
                    get(response, "data.data", []),
                    advertiserList,
                    "advertiser"
                );
                return { ...response?.data, data };
            } else {
                data = await mapScrubDashboardData(
                    get(response, "data.data", []),
                    [],
                    "advertiser"
                );
                return { ...response?.data, data };
            }
        } else {
            const publisherList = get(
                listings,
                "scrubDashboard.publisherList",
                []
            );
            if (!isEmpty(publisherList)) {
                data = await mapScrubDashboardData(
                    get(response, "data.data", []),
                    publisherList,
                    "publisher"
                );
                return { ...response?.data, data };
            } else {
                data = await mapScrubDashboardData(
                    get(response, "data.data", []),
                    [],
                    "publisher"
                );
                return { ...response?.data, data };
            }
        }
    }
);

export const fetchAllAdvertisers = createAsyncThunk(
    "scrubDashboard/fetchAllAdvertisers",
    async (_, { dispatch }) => {
        const response = await customFetch(
            OFFLINE_CONVERSION.FETCH_ALL_ADVERTISERS,
            "POST"
        );
        if (response?.status >= 400 || !response) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Advertisers",
                })
            );
        }
        const data = get(response, "data.data", []);
        return data;
    }
);

export const fetchAllPublishers = createAsyncThunk(
    "scrubDashboard/fetchAllPublishers",
    async (_, { dispatch }) => {
        const response = await customFetch(
            SCRUB_DASHBOARD.FETCH_PUBLISHERS,
            "POST"
        );
        if (response?.status >= 400 || !response) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Publishers",
                })
            );
        }
        const data = get(response, "data.data", []);
        return data;
    }
);

export const fetchAllCampaigns = createAsyncThunk(
    "scrubDashboard/fetchAllCampaigns",
    async (_, { getState, dispatch }) => {
        const isPublisherScrub = get(
            getState(),
            "listings.scrubDashboard.isPublisherScrub",
            false
        );
        let url = "";
        isPublisherScrub
            ? (url = SCRUB_DASHBOARD.FETCH_PUBLISHER_ALL_CAMPAIGNS)
            : (url = SCRUB_DASHBOARD.FETCH_ALL_CAMPAIGNS);
        const response = await customFetch(
            url,
            isPublisherScrub ? "GET" : "POST"
        );
        if (response?.status >= 400 || !response) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch campaign list",
                })
            );
        }
        const data = isPublisherScrub
            ? get(response, "data", [])
            : get(response, "data.data", []);
        return data;
    }
);

export const fetchCampaign = createAsyncThunk(
    "scrubDashboard/fetchCampaign",
    async (sourceId, { getState, dispatch }) => {
        const advertiser = get(
            getState(),
            "listings.scrubDashboard.selectedAdvertiserId",
            {}
        );
        const isPublisherScrub = get(
            getState(),
            "listings.scrubDashboard.isPublisherScrub",
            false
        );
        var campaignURL = `${OFFLINE_CONVERSION.FETCH_CAMPAIGN}=${sourceId}`;
        isPublisherScrub
            ? (campaignURL = `${SCRUB_DASHBOARD.GET_CAMPAIGN_FOR_PUBLISHER}/${sourceId}/campaigns`)
            : (campaignURL = `${OFFLINE_CONVERSION.FETCH_CAMPAIGN}=${sourceId}`);

        let response = null;
        if (isPublisherScrub) {
            if (sourceId) {
                response = await customFetch(campaignURL, "GET");
            } else response = [];
        } else {
            const params = {
                url: campaignURL,
                options: {
                    headers: {
                        "Content-type": "application/json",
                    },
                    method: "GET",
                },
            };
            response = advertiser
                ? await customFetch(HNP_URL.MAX_PROXY, "POST", params)
                : null;
        }
        let data = null;
        if (isPublisherScrub) {
            data = { data: get(response, "data", []), isPublisherScrub: true };
        } else data = get(response, "data.data", []);
        return data;
    }
);

export const postScrubDashboardData = createAsyncThunk(
    "scrubDashboard/postScrubDashboardData",
    async (
        { scrubData, dateParams, isPublisherScrub },
        { getState, dispatch }
    ) => {
        const params = dateParams;
        let postURL = "";
        isPublisherScrub
            ? (postURL = SCRUB_DASHBOARD.POST_PUBLISHER_SCRUB_DASHBOARD_DATA)
            : (postURL = SCRUB_DASHBOARD.POST_ADVERTISER_SCRUB_DASHBOARD_DATA);
        const response = await customFetch(
            postURL,
            "PUT",
            scrubData,
            null,
            { params: params },
            null,
            null,
            null
        );

        if (get(response, "status", "") >= 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Scrub applied successfully`,
                })
            );
            dispatch(fetchScrubDashboardData(isPublisherScrub));
        } else {
            let errorMessage = get(response, "error", []);
            !isEmpty(errorMessage) && errorMessage[0]
                ? (errorMessage = errorMessage[0])
                : (errorMessage = "Failed to apply scrub data.");
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: errorMessage,
                })
            );
        }
        return response;
    }
);

export const fireDummyEvent = createAsyncThunk(
    "scrubDashboard/fireDummyEvent",
    async ({ dummyEventData, isPublisherScrub }, { getState, dispatch }) => {
        let postURL = "";
        isPublisherScrub
            ? (postURL = SCRUB_DASHBOARD.FIRE_COST_DUMMY_EVENT)
            : (postURL = SCRUB_DASHBOARD.FIRE_REVENUE_DUMMY_EVENT);
        const response = await customFetch(
            postURL,
            "POST",
            dummyEventData,
            null,
            null,
            null,
            null,
            null
        );
        if (get(response, "status", "") >= 400) {
            let errorMessage = get(response, "error", []);
            !isEmpty(errorMessage) && errorMessage[0]
                ? (errorMessage = errorMessage[0])
                : (errorMessage = "Failed to fire dummy event.");
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: errorMessage,
                })
            );
        }
        return response;
    }
);

export const setValidState = createAsyncThunk(
    "scrubDashboard/setValidState",
    async (_, {}) => {
        return;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/scrubDashboard/onFilterApplied",
    async (params, { getState }) => {
        const { listings } = getState();
        let initialData = listings.scrubDashboard.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.SCRUB_DASHBOARD.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const addNewPublisher = createAsyncThunk(
    "listings/scrubDashboard/addNewPublisher",
    async (publisherData, { getState }) => {
        const response = await customFetch(
            SCRUB_DASHBOARD.CREATE_PUBLISHER,
            "POST",
            publisherData,
            null,
            null,
            null,
            null,
            null
        );
        return { response };
    }
);

export const addNewCampaign = createAsyncThunk(
    "listings/scrubDashboard/addNewCampaign",
    async (campaignData, { getState }) => {
        const { name, id, pubid } = campaignData;
        const createCampaignData = {
            cmpid: id,
            name: name,
        };
        const campaignURL = `${SCRUB_DASHBOARD.CREATE_PUBLISHER}/${pubid}/campaign`;
        const response = await customFetch(
            campaignURL,
            "POST",
            createCampaignData,
            null,
            null,
            null,
            null,
            null
        );
        return { response };
    }
);

export const checkPublisherPresent = createAsyncThunk(
    "listings/scrubDashboard/checkIfPublisherPresent",
    async (id, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const response = await customFetch(
            `${SCRUB_DASHBOARD.CHECK_PUBLISHER}/${id}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        // return response;
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

export const checkPublisherCampaignPresent = createAsyncThunk(
    "listings/scrubDashboard/checkPublisherCampaignPresent",
    async ({ publisherId, campaignId }, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const response = await customFetch(
            `${SCRUB_DASHBOARD.CHECK_PUBLISHER}/${publisherId}/campaign/${campaignId}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

export const fetchScrubData = createAsyncThunk(
    "listings/scrubDashboard/fetchScrubData",
    async ({ adv, campaign }, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const dateRange = get(
            getState(),
            "listings.scrubDashboard.formDate",
            {}
        );
        const startDate = uploadDateFormat(dateRange?.startDate);
        const endDate = uploadDateFormat(dateRange?.endDate);
        const response = await customFetch(
            `${SCRUB_DASHBOARD.GET_ADVERTISER_DATA_STUDIO_REVENUE}?srcid=${adv}&cmpid=${campaign}&startDate=${startDate}&endDate=${endDate}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

export const fetchPublisherScrubData = createAsyncThunk(
    "listings/scrubDashboard/fetchPublisherScrubData",
    async ({ publisher, campaign }, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const dateRange = get(
            getState(),
            "listings.scrubDashboard.formDate",
            {}
        );
        const startDate = uploadDateFormat(dateRange?.startDate);
        const endDate = uploadDateFormat(dateRange?.endDate);
        let responseURL = "";
        if (campaign) {
            responseURL = `${SCRUB_DASHBOARD.GET_PUBLISHER_DATA_STUDIO_COST}?srcid=${publisher}&cmpid=${campaign}&startDate=${startDate}&endDate=${endDate}`;
        } else {
            responseURL = `${SCRUB_DASHBOARD.GET_PUBLISHER_DATA_STUDIO_COST}?srcid=${publisher}&startDate=${startDate}&endDate=${endDate}`;
        }
        const response = await customFetch(
            responseURL,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

export const fetchDomainList = createAsyncThunk(
    "listings/scrubDashboard/fetchDomainList",
    async (_, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        let domainListURL = USER_JOURNEY.FETCH_DOMAIN_LIST;
        const campaign = get(
            getState(),
            "listings.scrubDashboard.selectedCampaignName",
            {}
        );
        const isPublisherScrub = get(
            getState(),
            "listings.scrubDashboard.isPublisherScrub",
            false
        );
        if (campaign) {
            // For now commenting this as with campaign name everytime the data is coming []
            // domainListURL = `${
            //     USER_JOURNEY.FETCH_DOMAIN_LIST
            // }/${campaign}?is_advertiser=${!isPublisherScrub}`;
            domainListURL = USER_JOURNEY.FETCH_DOMAIN_LIST;
        } else {
            domainListURL = USER_JOURNEY.FETCH_DOMAIN_LIST;
        }
        const response = await customFetch(
            domainListURL,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

const scrubDashboardSlice = createSlice({
    name: "scrubDashboard",
    initialState,
    reducers: {
        setType(state, action) {
            state.isPublisherScrub = action.payload;
        },
        setIndex(state, action) {
            state.index = action.payload;
        },
        setDataStudioCost(state, action) {
            state.dsCost = action.payload;
        },
        setFetchedDataStudioCost(state, action) {
            state.fetchedDSCost = action.payload;
        },
        setRPCValue(state, action) {
            state.rpcValue = action.payload;
        },
        setTotalClicks(state, action) {
            state.total_clicks = action.payload;
        },
        setAdvertiserCost(state, action) {
            state.advCost = action.payload;
        },
        setScrubRatio(state, action) {
            state.scrubRatio = action.payload;
        },
        setDiscountFactor(state, action) {
            state.discountFactor = action.payload;
        },
        setSelectedAdvertiserId(state, action) {
            state.selectedAdvertiserId = action.payload;
        },
        setSelectedAdvertiserName(state, action) {
            state.selectedAdvertiserName = action.payload;
        },
        setSelectedPublisherId(state, action) {
            state.selectedPublisherId = action.payload;
        },
        setSelectedPublisherName(state, action) {
            state.selectedPublisherName = action.payload;
        },
        setSelectedCampaignId(state, action) {
            state.selectedCampaignId = action.payload;
        },
        setSelectedCampaignName(state, action) {
            state.selectedCampaignName = action.payload;
        },
        setSelectedCampaignIds(state, action) {
            state.selectedCampaignIds = action.payload;
        },
        setSelectedCampaignNames(state, action) {
            state.selectedCampaignNames = action.payload;
        },
        setSelectedDomainId(state, action) {
            state.selectedDomainId = action.payload;
        },
        setSelectedDomainName(state, action) {
            state.selectedDomainName = action.payload;
        },
        setSelectedVertical(state, action) {
            state.selectedVertical = action.payload;
        },
        setSelectedType(state, action) {
            state.selectedType = action.payload;
        },
        setCampaign(state, action) {
            state.campaign = action.payload;
        },
        setDateRange(state, action) {
            state.pagination.date = action.payload;
            state.formDate = action.payload;
        },
        setFormDate(state, action) {
            state.pagination.date = action.payload;
            state.formDate = action.payload;
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setPaginationType(state, action) {
            state.pagination.type = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        setIsValid(state, action) {
            state.isValid = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setCampaignList(state, action) {
            state.campaignList = action.payload;
        },
        setViewType(state, action) {
            state.viewType = action.payload;
        },
        setMaxDate(state, action) {
            state.maxDate = action.payload;
        },
        setMinDate(state, action) {
            state.minDate = action.payload;
        },
        setRevenueTableList(state, action) {
            state.revenueTableList = action.payload;
        },
        setRowColor(state, action) {
            state.rowColor = action.payload;
        },
        setRowType(state, action) {
            state.rowType = action.payload;
        },
        setInitialRowType(state, action) {
            state.initialRowType = action.payload;
        },
        setRevenueTable(state, action) {
            state.revenueTable = action.payload;
        },
        setScrubType(state, action) {
            state.selectedScrubType = action.payload;
        },
        resetList(state) {
            state.pagination.pageNumber = 1;
            state.pagination.startOffset = 0;
            state.list = [];
            state.tableError = null;
            state.loading = true;
        },
        resetDate(state) {
            state.formDate = initialDefaultDateRange;
        },
        resetState(state) {
            state.list = [];
            state.selectedAdvertiserId = null;
            state.selectedAdvertiserName = null;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
            state.selectedCampaignId = null;
            state.selectedCampaignName = null;
            state.selectedCampaignIds = [];
            state.selectedCampaignNames = [];
            state.selectedDomainId = null;
            state.selectedVertical = null;
            state.selectedType - null;
            state.selectedDomainName = null;
            // state.selectedDate = new Date();
            state.campaign = null;
            state.product = null;
            state.timeZone = null;
            state.dsCost = "";
            state.rpcValue = "";
            state.total_clicks = "";
            state.fetchedDSCost = "";
            state.advCost = "";
            state.scrubRatio = "";
            state.discountFactor = "";
            state.revenueTableList = [];
            // state.formDate = initialDefaultDateRange;
            state.isInputLoading = false;
            state.showFireEvents = false;
            state.dateArray = [];
            state.viewType = "Auto Calculated scrub";
            state.rowColor = null;
            state.initialRowType = {};
            state.rowType = {};
            state.campaignList = [];
            state.revenueTable = [];
            state.selectedScrubType = null;
        },
        resetAdvPublisherDropdown(state) {
            state.selectedAdvertiserId = null;
            state.selectedAdvertiserName = null;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
            state.selectedScrubType = null;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setPublisherId(state, action) {
            state.publisherPopup.id = action.payload;
        },
        setPublisherName(state, action) {
            state.publisherPopup.name = action.payload;
        },
        setIsSameId(state, action) {
            state.publisherPopup.isSameId = action.payload;
        },
        resetPublisherPopup(state) {
            state.publisherPopup.name = "";
            state.publisherPopup.id = "";
            state.publisherPopup.isSameId = false;
        },

        resetPublisherSelectedValue(state) {
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
        },
        resetCampaignSelectedValue(state) {
            state.selectedCampaignId = null;
            state.selectedCampaignName = null;
            state.selectedCampaignIds = [];
            state.selectedCampaignNames = [];
            state.campaign = null;
        },
        setCampaignId(state, action) {
            state.campaignPopup.campaignId = action.payload;
        },
        setCampaignName(state, action) {
            state.campaignPopup.campaignName = action.payload;
        },
        setPublisherRefInCampaign(state, action) {
            state.campaignPopup.publisherId = action.payload;
            // state.campaignPopup.publisherId = action.payload
        },
        setIsSameCombination(state, action) {
            state.campaignPopup.isSameCombination = action.payload;
        },
        resetCampaignPopup(state) {
            state.campaignPopup.campaignName = "";
            state.campaignPopup.campaignId = "";
            state.campaignPopup.publisherId = "";
            state.campaignPopup.isSameCombination = false;
            // state.campaignList = [];
        },
        setAdvertiserList(state, action) {
            state.advertisersList = action.payload;
        },
        setPublisherList(state, action) {
            state.publisherList = action.payload;
        },
        setCampaignList(state, action) {
            state.campaignList = action.payload;
        },
        setIsDSCostFetched(state, action) {
            state.isDSCostFetched = action.payload;
        },
        setShowFireEvents(state, action) {
            state.showFireEvents = action.payload;
        },
        setShowRevenueTable(state, action) {
            state.showRevenueTable = action.payload;
        },
        setDateArray(state, action) {
            state.dateArray = action.payload;
        },
        setIsInputLoading(state, action) {
            state.isInputLoading = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        resetSidePopUpData(state) {
            state.selectedAdvertiserId = null;
            state.selectedAdvertiserName = null;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
            state.selectedCampaignId = null;
            state.selectedCampaignName = null;
            state.selectedCampaignIds = [];
            state.selectedCampaignNames = [];
            state.selectedDomainId = null;
            state.selectedVertical = null;
            state.selectedType = null;
            state.selectedDomainName = null;
            // state.selectedDate = new Date();
            state.campaign = null;
            state.product = null;
            state.timeZone = null;
            state.dsCost = "";
            state.advCost = "";
            state.rpcValue = "";
            state.total_clicks = "";
            state.scrubRatio = "";
            state.discountFactor = "";
            state.revenueTableList = [];
            // state.formDate = initialDefaultDateRange;
            state.isInputLoading = false;
            state.showFireEvents = false;
            state.dateArray = [];
            state.viewType = "Auto Calculated scrub";
            state.rowColor = null;
            state.initialRowType = {};
            state.rowType = {};
            state.campaignList = [];
            state.revenueTable = [];
            state.selectedScrubType = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchScrubDashboardData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchScrubDashboardData.fulfilled, (state, action) => {
            state.loading = false;
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.updatedAt = new Date();
        });
        builder.addCase(fetchScrubDashboardData.rejected, state => {
            state.loading = false;
        });
        builder.addCase(fetchAllAdvertisers.pending, state => {
            state.advLoader = true;
        });
        builder.addCase(fetchAllAdvertisers.fulfilled, (state, action) => {
            state.advLoader = false;
            state.advertisersList = mapAdvertiser(action.payload);
        });
        builder.addCase(fetchAllAdvertisers.rejected, state => {
            state.advLoader = false;
        });
        builder.addCase(fetchAllPublishers.fulfilled, (state, action) => {
            state.publisherList = mapPublisher(action.payload);
        });
        builder.addCase(fetchAllCampaigns.fulfilled, (state, action) => {
            const isPublisherScrub = get(state, "isPublisherScrub", false);
            state.campaignList = isPublisherScrub
                ? mapCampaign(action.payload, "publisher", true)
                : mapCampaign(action.payload, "advertiser", true);
        });
        builder.addCase(fetchCampaign.fulfilled, (state, action) => {
            const isPublisherScrub = get(state, "isPublisherScrub", false);
            state.campaignList = isPublisherScrub
                ? mapCampaign(action.payload?.data, "publisher")
                : mapCampaign(action.payload, "advertiser");
        });
        builder.addCase(postScrubDashboardData.pending, state => {
            state.mainLoader = true;
            state.uploadLoader = true;
            state.isInputLoading = true;
        });
        builder.addCase(postScrubDashboardData.fulfilled, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(postScrubDashboardData.rejected, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(setValidState.fulfilled, (state, action) => {
            state.isValid = action.payload;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(addNewPublisher.pending, state => {
            state.advLoader = true;
        });
        builder.addCase(addNewPublisher.fulfilled, (state, action) => {
            state.advLoader = false;
        });
        builder.addCase(addNewPublisher.rejected, state => {
            state.advLoader = false;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
        });
        builder.addCase(fetchDomainList.fulfilled, (state, action) => {
            state.domainList = action.payload?.data?.data;
        });
        builder.addCase(fireDummyEvent.pending, state => {
            state.mainLoader = true;
            state.uploadLoader = true;
            state.isInputLoading = true;
        });
        builder.addCase(fireDummyEvent.fulfilled, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(fireDummyEvent.rejected, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(fetchScrubData.pending, state => {
            state.isInputLoading = true;
        });
        builder.addCase(fetchScrubData.fulfilled, state => {
            state.isInputLoading = false;
        });
        builder.addCase(fetchScrubData.rejected, state => {
            state.isInputLoading = false;
        });
        builder.addCase(fetchPublisherScrubData.pending, state => {
            state.isInputLoading = true;
        });
        builder.addCase(fetchPublisherScrubData.fulfilled, state => {
            state.isInputLoading = false;
        });
        builder.addCase(fetchPublisherScrubData.rejected, state => {
            state.isInputLoading = false;
        });
    },
});

export const scrubDashboardActions = scrubDashboardSlice.actions;
export default scrubDashboardSlice.reducer;
