import Icon from "../../Shared/Icon/Icon";
import Select from "../../Shared/Select/Select";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import s from "../CreateFlowCategories/CreateFlowCategories.module.scss";
import {
    useFetchAvailableQuestions,
    useFetchAvailableTargetingParams,
    useFetchLayouts,
} from "../../generalApi/generalApi";
import { getRandomId } from "../../utils/globalUtils";
import TargetingNode from "./TargetingNode";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { isEmpty } from "lodash";
import { fetchTpKey } from "../../Shared/FlowRenderer/FlowRendererUtils";

const TargetingData = forwardRef(
    ({ targettingParams = {}, isReadOnly = false }, ref) => {
        const [orList, setOrList] = useState([
            { tp: {}, ref: { current: null } },
        ]);

        useEffect(() => {
            setOrList(
                targettingParams?.type === "OR" &&
                    Array.isArray(targettingParams?.value)
                    ? targettingParams.value.map(v => ({
                          id: getRandomId(),
                          tp: v,
                          ref: { current: null },
                      }))
                    : [
                          {
                              id: getRandomId(),
                              tp: targettingParams,
                              ref: { current: null },
                          },
                      ]
            );
        }, [targettingParams]);

        return (
            <ul
                className={`${s.logicalGroupList} ${
                    isReadOnly ? s.readOnlyView : ""
                }`}
            >
                {orList
                    ?.filter(
                        _ =>
                            !(
                                !isEmpty(_?.tp?.value) &&
                                !isEmpty(_?.tp?.value[0]?.value) &&
                                _?.tp?.value[0]?.value[0].linkOutQuestion
                            )
                    )
                    ?.map(item => (
                        <li key={item.id}>
                            {Object.keys(item.tp)?.length > 0 && (
                                <span className={s.groupLabel}>And Group</span>
                            )}
                            {!isReadOnly && (
                                <div className={s.actionsContainer}>
                                    <Icon
                                        icon={"Delete"}
                                        size={"16px"}
                                        enableHover={true}
                                        onClick={() => {
                                            setOrList(_ => [
                                                ..._.filter(
                                                    v => v.id !== item.id
                                                ),
                                            ]);
                                        }}
                                    />
                                </div>
                            )}
                            <TargetingWrapper
                                targettingParams={item.tp}
                                ref={item.ref}
                                readOnly={isReadOnly}
                            />
                        </li>
                    ))}
                {!isReadOnly && (
                    <li
                        className="link cursorPointer"
                        onClick={() =>
                            setOrList(_ => [
                                ..._,
                                {
                                    id: getRandomId(),
                                    tp: {},
                                    ref: {
                                        current: null,
                                    },
                                },
                            ])
                        }
                    >
                        <Icon icon={"Plus"} size={"14px"} className="mr-5" />
                        Add And Group
                    </li>
                )}
            </ul>
        );
    }
);

export default TargetingData;

export const TargetingWrapper = forwardRef(
    (
        {
            targettingParams,
            forcedTpList = [],
            addLabel = "Add Targeting",
            onChange = () => {},
            readOnly,
        },
        ref
    ) => {
        const [tpList, setTpList] = useState([]);
        const [addedKeys, setAddedKeys] = useState([]);
        const [openDropdown, setOpenDropdown] = useState(false);

        const { apiData = [], isLoading } = useFetchAvailableTargetingParams();

        const availableTargetingParams = useMemo(() => {
            if (forcedTpList.length) {
                return forcedTpList;
            }
            return apiData || [];
        }, [apiData, forcedTpList]);

        useEffect(() => {
            if (targettingParams?.value?.length) {
                setTpList([...targettingParams.value]);
            }
        }, [targettingParams]);

        useEffect(() => {
            setAddedKeys(tpList.map(_ => fetchTpKey(_?.value[0])));
        }, [tpList]);

        useImperativeHandle(ref, () => {
            return {
                getData: () => ({
                    type: "AND",
                    value: tpList,
                }),
            };
        });

        return (
            <ul>
                {tpList?.map((obj, i) => {
                    const key = fetchTpKey(obj);
                    const tp = availableTargetingParams.find(
                        _ => _.name === key
                    );
                    const branches = [
                        {
                            name: tp?.opsName,
                            value: Array.isArray(obj?.value)
                                ? obj?.value
                                : obj?.key
                                ? [
                                      {
                                          key: obj?.key,
                                          value: [obj?.value],
                                      },
                                  ]
                                : [],
                            connectionType: obj?.type,
                        },
                    ];
                    return (
                        <>
                            {!isEmpty(tp) && (
                                <>
                                    <li>
                                        <TargetingNode
                                            forcedTpList={
                                                availableTargetingParams
                                            }
                                            branches={branches}
                                            inlineMode={true}
                                            onDelete={() => {
                                                setTpList(_ => [
                                                    ..._.filter(
                                                        (item, index) =>
                                                            i !== index
                                                    ),
                                                ]);

                                                onChange();
                                            }}
                                            singleBranch={true}
                                            readOnly={readOnly}
                                            onChange={branches => {
                                                if (branches?.length) {
                                                    setTpList(_ =>
                                                        _.map((tp, j) => {
                                                            if (i === j) {
                                                                return {
                                                                    ...tp,
                                                                    value: branches[0]
                                                                        ?.value
                                                                        ?.length
                                                                        ? branches[0]?.value?.map(
                                                                              v => {
                                                                                  if (
                                                                                      typeof v ===
                                                                                      "object"
                                                                                  ) {
                                                                                      if (
                                                                                          v.valueOne &&
                                                                                          v.valueSecond
                                                                                      ) {
                                                                                          let tpData =
                                                                                              getTP(
                                                                                                  branches[0]
                                                                                                      .type,
                                                                                                  v,
                                                                                                  availableTargetingParams
                                                                                              );

                                                                                          if (
                                                                                              v.valueSecond ===
                                                                                              "skip"
                                                                                          ) {
                                                                                              let copyData =
                                                                                                  [
                                                                                                      ...tpData,
                                                                                                  ];
                                                                                              copyData.forEach(
                                                                                                  (
                                                                                                      obj,
                                                                                                      idx
                                                                                                  ) => {
                                                                                                      (obj.value =
                                                                                                          v.valueSecond),
                                                                                                          (obj.operator =
                                                                                                              "NOT EQUAL");
                                                                                                  }
                                                                                              );

                                                                                              tpData =
                                                                                                  copyData;
                                                                                          }

                                                                                          return {
                                                                                              type: "AND",
                                                                                              value: tpData,
                                                                                          };
                                                                                      }
                                                                                  }
                                                                                  return {
                                                                                      key: branches[0]
                                                                                          .type,
                                                                                      operator:
                                                                                          "EQUAL",
                                                                                      type: "EXPRESSION",
                                                                                      value: v,
                                                                                  };
                                                                              }
                                                                          )
                                                                        : [
                                                                              {
                                                                                  key: branches[0]
                                                                                      .type,
                                                                                  operator:
                                                                                      "EQUAL",
                                                                                  type: "EXPRESSION",
                                                                                  value: branches[0]
                                                                                      ?.value,
                                                                              },
                                                                          ],
                                                                };
                                                            }
                                                            return tp;
                                                        })
                                                    );
                                                }
                                                onChange();
                                            }}
                                            customAnswerAndSkipAnswerSupport={
                                                true
                                            }
                                            tagWrappable
                                            incrementBranchName
                                        />
                                    </li>
                                    {readOnly && i !== tpList.length - 1 && (
                                        <span
                                            className={`${s.andText} ${
                                                readOnly ? "ml-10" : ""
                                            }`}
                                        >
                                            And
                                        </span>
                                    )}
                                </>
                            )}
                        </>
                    );
                })}
                {!readOnly && (
                    <li className="relative">
                        <Button
                            onClick={() => setOpenDropdown(true)}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={readOnly}
                        >
                            <Icon className="mr-5" icon="Plus" size="14px" />
                            {addLabel}
                        </Button>

                        {openDropdown && (
                            <div className={s.plusDropdownWrapper}>
                                <Select
                                    options={availableTargetingParams.filter(
                                        _ => !addedKeys.includes(_.name)
                                    )}
                                    keyField={"opsName"}
                                    idField={"name"}
                                    onLoadSelectReturn={false}
                                    isSelectOpen={true}
                                    portal={false}
                                    hideLabel={true}
                                    hideOutline={true}
                                    onSelectClose={() => setOpenDropdown(false)}
                                    CustomRender={({ option }) => (
                                        <>
                                            <Icon
                                                icon={option?.icon}
                                                fallbackIcon="Default"
                                                size="18px"
                                            />
                                            <span className="ml-5">
                                                {option.opsName}
                                            </span>
                                        </>
                                    )}
                                    onSelect={obj => {
                                        setOpenDropdown(false);
                                        setTpList(_ => [
                                            ..._,
                                            {
                                                type: "OR",
                                                value: [
                                                    {
                                                        key: obj.name,
                                                        type: "EXPRESSION",
                                                        value: [],
                                                    },
                                                ],
                                            },
                                        ]);
                                        onChange();
                                    }}
                                    isDisabled={readOnly}
                                />
                            </div>
                        )}
                    </li>
                )}
            </ul>
        );
    }
);
