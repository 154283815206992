import { remove, get } from "lodash";
import React, { useEffect, useState } from "react";
import { TIMEZONESLIST } from "../../constants/targettingParamsConstants";
import Select from "../../Shared/Select/Select";
import Icon from "../../Shared/Icon/Icon";
import TimePicker from "../TimePicker/TimePicker";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import s from "./TimeOfDay.module.scss";
// import * as moment from "moment";
import moment from "moment-timezone";
import { getRandomId } from "../../utils/globalUtils";
import { useDispatch } from "react-redux";
import { toastListActions } from "../../store/toastList/toastList";

const timezones = TIMEZONESLIST.map(city => ({
    id: city.id,
    label: `${city.id} ${moment.tz(city.id).format("Z z")}`,
}));

const TimeOfDay = ({ mainState, onAdd, readOnly = false }) => {
    const style = {
        color: "#CAD1D5",
    };
    //onAdd will add all the data in the required format and send it to parent component
    const [timeZone, setTimeZone] = useState(null);
    const [startTime, setStartTime] = useState({
        hour: "10",
        mins: "10",
        isPM: false,
    });
    const [endTime, setEndTime] = useState({
        hour: "10",
        mins: "10",
        isPM: false,
    });

    const dispatch = useDispatch();
    const addItemToToast = item =>
        dispatch(toastListActions.setToastList({ ...item }));

    const addHandler = () => {
        if (timeZone) {
            if (JSON.stringify(startTime) != JSON.stringify(endTime)) {
                let updatedState = [...mainState];
                updatedState.push({
                    TZ: timeZone,
                    startTime: `${startTime.hour}:${startTime.mins}`,
                    endTime: `${endTime.hour}:${endTime.mins}`,
                    startTimeIsPm: startTime.isPM,
                    endTimeIsPm: endTime.isPM,
                });
                onAdd(updatedState);
                setTimeZone(null);
            } else {
                addItemToToast({
                    type: "Error",
                    message: "Start Time and End Time cannot be same.",
                    autoClose: 5000,
                });
            }
        } else {
            setTimeZoneError(true);
        }
    };

    const removeValue = (index, element) => {
        var array = [...mainState]; // make a separate copy of the array
        if (index !== -1) {
            let results = remove(array, n => {
                return n !== element;
            });
            onAdd(results);
        }
    };
    const [timeZoneError, setTimeZoneError] = useState(false);
    return (
        <>
            <section className={s.timeOfDayContainer}>
                {!readOnly && (
                    <>
                        <Select
                            label={"Select Timezone"}
                            options={timezones}
                            keyField="label"
                            idField="id"
                            onSelect={item => {
                                setTimeZone(get(item, "id", ""));
                                setTimeZoneError(false);
                            }}
                            showSearch={true}
                            onLoadSelectReturn={false}
                            selectedId={timeZone}
                            isError={timeZoneError}
                            isDisabled={readOnly}
                        />
                        <div className={s.timeWrapper}>
                            <div className={s.startTimeWrapper}>
                                <TimePicker
                                    label={"Start Time:"}
                                    value={startTime}
                                    setValue={setStartTime}
                                    isDisabled={readOnly}
                                />
                            </div>
                            <div className={s.endTimeWrapper}>
                                <TimePicker
                                    label={"End Time:"}
                                    value={endTime}
                                    setValue={setEndTime}
                                    isDisabled={readOnly}
                                />
                            </div>
                        </div>
                        <div className={s.addBtnWrapper}>
                            <Button
                                btnTheme={ButtonTypes.primaryHover_btn}
                                onClick={addHandler}
                                disable={readOnly}
                            >
                                Add
                            </Button>
                        </div>
                    </>
                )}

                {mainState.length > 0 && (
                    <ul className={s.labelWrapper}>
                        {/* {mainState.length > 0 && */}
                        {mainState.map((element, index) => {
                            return (
                                <li
                                    key={`${getRandomId()}-${index}-${getRandomId()}`}
                                >
                                    <div className={s.elementOne}>
                                        <span>TZ : </span>
                                        <span>{element.TZ}</span>
                                    </div>
                                    <div className={s.elementSecond}>
                                        <span>Start Time : </span>
                                        <span>
                                            {element.startTime}&nbsp;
                                            {element.startTimeIsPm
                                                ? "PM"
                                                : "AM"}
                                        </span>
                                    </div>
                                    <div className={s.elementSecond}>
                                        <span>End Time : </span>
                                        <span>
                                            {element.endTime}&nbsp;
                                            {element.endTimeIsPm ? "PM" : "AM"}
                                        </span>
                                    </div>
                                    {!readOnly && (
                                        <div
                                            onClick={() =>
                                                removeValue(index, element)
                                            }
                                            className={`${s.icon} ${s.crossIcon}`}
                                        >
                                            <Icon
                                                icon="CloseFilled"
                                                size="14px"
                                                style={style}
                                            />
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </section>
        </>
    );
};

export default TimeOfDay;
