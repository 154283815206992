import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { CONFIGURATIONS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import { toastListActions } from "../toastList/toastList";

const initialState = {
    surveyName: "",
    productDomain: {
        selected: null,
        list: [],
    },
    showConfiguration: false,
    lander: {
        selected: null,
        list: [],
    },
    featureMap: {
        selected: [],
        list: [],
        details: null,
        onEditSection: false,
        buttonDisabled: true,
        editJoinList: [],
        joinListData: [],
        apiValue: [],
        // valWarnState: false,
        selectedListActiveItem: "",
        isCheck: false,
        cardObject: "",
        itemsObj: [],
    },
    configuration: {
        list: [],
    },
    error: false,
    editId: null,
    mainLoader: false,
    loading: false,
};

export const fetchSurveyBasicsData = createAsyncThunk(
    "surveyBasics/fetchSurveyBasicsData",
    async (_, { dispatch }) => {
        const responses = await fetchAll([
            customFetch(CONFIGURATIONS.GET_DOMAINS, "GET"), //response.data
            customFetch(CONFIGURATIONS.GET_LANDERS, "GET"), //response.data
            customFetch(CONFIGURATIONS.GET_CONFIGS, "GET"), //response.data
        ]);
        if (
            get(responses, "0.data.length", 0) == 0 ||
            get(responses, "1.data.length", 0) == 0 ||
            get(responses, "2.data.length", 0) == 0
        )
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all required data!",
                })
            );
        return responses;
    }
);

const surveyBasicsSlice = createSlice({
    name: "surveyBasics",
    initialState,
    reducers: {
        setSurveyBasicsName(state, action) {
            state.surveyName = action.payload;
            state.error = action.payload.trim() === "";
        },
        setSelectedProductDomain(state, action) {
            state.productDomain.selected = action.payload;
        },
        setFeatureMapList(state, action) {
            state.featureMap.list = action.payload;
        },
        setShowConfiguration(state, action) {
            state.showConfiguration = action.payload;
        },
        setSelectedFeatureMap(state, action) {
            state.featureMap.selected = action.payload;
        },
        setFeatureMapDetails(state, action) {
            state.featureMap.details = action.payload;
        },
        setOnEditSection(state, action) {
            state.featureMap.onEditSection = action.payload;
        },
        setButtonDisabled(state, action) {
            state.featureMap.buttonDisabled = action.payload;
        },
        setEditJoinList(state, action) {
            state.featureMap.editJoinList = action.payload;
        },
        setJoinListData(state, action) {
            state.featureMap.joinListData = action.payload;
        },
        setApiValue(state, action) {
            state.featureMap.apiValue = action.payload;
        },
        setValWarnState(state, action) {
            state.featureMap.valWarnState = action.payload;
        },
        setSelectedListActiveItem(state, action) {
            state.featureMap.selectedListActiveItem = action.payload;
        },
        setIsCheck(state, action) {
            state.featureMap.isCheck = action.payload;
        },
        setCardObject(state, action) {
            state.featureMap.cardObject = action.payload;
        },
        setItemsObj(state, action) {
            state.featureMap.itemsObj = action.payload;
        },
        setIsError(state, action) {
            state.featureMap.itemsObj[action.payload.index].isError =
                action.payload.value;
        },
        setSurveyNameError(state, action) {
            state.error = action.payload;
        },
        setEditId(state, action) {
            state.editId = action.payload;
        },
        resetSurveyBasics(state, action) {
            state.surveyName = "";
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSurveyBasicsData.fulfilled, (state, action) => {
            state.configuration.list = get(action.payload, `2.data`, []);
            state.productDomain.list = get(action.payload, `0.data`, []);
            state.lander.list = get(action.payload, `1.data`, []);
        });
    },
});

export const surveyBasicsActions = surveyBasicsSlice.actions;
export default surveyBasicsSlice.reducer;
