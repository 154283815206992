import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SITE_CONFIG } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import customFetch from "../../fetch/customFetch";
import _, { get, isEmpty } from "lodash";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    updatedAt: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.SITE_CONFIG.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.SITE_CONFIG.filterOperator,
        filtersOperator: FILTERS.SITE_CONFIG.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "siteconfig"),
    },
    createSearchFilter: {
        filterApplied: getInitialTagData([], "siteconfig"),
    },
    tableError: "",
    historyData: [],
    historyDataRaw: [],
};

export const fetchSiteConfig = createAsyncThunk(
    "flowmanager/siteconfig/fetchSiteConfig",
    async (id, { dispatch }) => {
        const response = await customFetch(`${SITE_CONFIG.GET_CONFIG}/${id}`);
        const data = get(response, "data.data", {});
        if (isEmpty(data))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Site Config!",
                })
            );
        return data;
    }
);

export const fetchSiteConfigs = createAsyncThunk(
    "flowmanager/siteconfig/fetchListingData",
    async (refreshClicked, { dispatch, getState }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "siteconfig.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "siteconfig.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.SITE_CONFIG
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(siteConfigSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "flowmanager.siteconfig.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.SITE_CONFIG,
                filterParamsList
            );
        }
        const listingData = get(getState(), "flowmanager.siteconfig.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                SITE_CONFIG.GET_ALL_CONFIGS,
                "POST",
                filterParamsData,
                {},
                {},
                {},
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch site configs",
                        })
                    );
                    dispatch(
                        siteConfigSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(siteConfigActions.setFilter(filterParamsData?.filters));
            data = get(response, "data.data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.QUESTIONS}`;
            dispatch(siteConfigSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new site config`;
            dispatch(siteConfigSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const createSiteConfig = createAsyncThunk(
    "flowmanager/siteconfig/create",
    async (params, { dispatch }) => {
        const response = customFetch(
            SITE_CONFIG.SAVE_CONFIG,
            "POST",
            params,
            {},
            {},
            {},
            null,
            data => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message:
                            data?.response?.data &&
                            data.response.data.message?.includes(
                                "Duplicate entry"
                            )
                                ? "Failed to create. Domain or short code name already exists."
                                : "Failed to create site config! Please try again later!",
                    })
                );
            }
        );
        return response;
    }
);

export const updateSiteConfig = createAsyncThunk(
    "flowmanager/siteconfig/edit",
    async (params, { dispatch }) => {
        const response = customFetch(
            SITE_CONFIG.SAVE_CONFIG,
            "POST",
            params,
            {},
            {},
            {},
            null,
            data => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message:
                            data?.response?.data &&
                            data.response.data.message?.includes(
                                "Duplicate entry"
                            )
                                ? "Failed to update. Domain or short code name already exists."
                                : "Failed to update site config! Please try again later!",
                    })
                );

                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Site config already modified while you were editing",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload site config",
                        })
                    );
                }
            }
        );
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "flowmanager/siteconfig/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.siteconfig.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        let filterParamsData = FILTER_PARAMS.SITE_CONFIG.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const onCreateFilterApplied = createAsyncThunk(
    "flowmanager/siteconfig/onCreateFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData =
            flowmanager.siteconfig.createSearchFilter.filterApplied;
        let filterApplied = [...initialData];

        let filterParamsData = FILTER_PARAMS.SITE_CONFIG.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchSiteConfigHistory = createAsyncThunk(
    "flowmanager/siteconfig/fetchHistory",
    async id => {
        const response = await customFetch(
            `${SITE_CONFIG.FETCH_SITECONFIG_HISTORY}/${id}`
        );
        return get(response, "data", []);
    }
);

const siteConfigSlice = createSlice({
    name: "siteconfig",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },

        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSiteConfigs.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchSiteConfigs.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(onCreateFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.createSearchFilter.filterApplied = filterApplied;
        });
    },
});

export const siteConfigActions = siteConfigSlice.actions;
export default siteConfigSlice.reducer;
