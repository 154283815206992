import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import { CONTAINERS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { get } from "lodash";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";

const initialState = {
    list: [],
    updatedAt: new Date(),
    loading: false,
    createContainer: {
        vertical: "",
        type: "",
        name: "",
        template: null,
        collections: "",
        id: "",
        loading: false,
        mainLoader: false,
        showPrompt: true,
        outerLayout: "",
        outerLayoutCopy: "",
        layout: "",
        layoutCopy: "",
        collectionItem: {},
        defaultLayout: false,
        selectedCollectionItems: [],
        editPhaseVerticalNotSelected: false,
        featureGroupProperties: {},
    },
    createContainerBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.OFFER_PATH.filterKey,
        filterTerm: null,
        // prefix: null,
        filterOperator: FILTERS.OFFER_PATH.filterOperator,
        filtersOperator: FILTERS.OFFER_PATH.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "container"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    historyData: [],
    historyDataRaw: [],
};

export const fetchContainerData = createAsyncThunk(
    "flowmanager/container/listings",
    async ({ refreshClicked, noPagination }, { dispatch, getState }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "container.pagination", {});
        const params = noPagination ? {} : paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "container.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.CONTAINER
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(container.actions.setPageNumber(1));
            const paginationNew = noPagination
                ? {}
                : get(getState(), "flowmanager.container.pagination", {});
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.CONTAINER,
                filterParamsList
            );
        }
        const listingData = get(getState(), "flowmanager.container.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                `${CONTAINERS.FETCH_CONTAINERS_LIST}`,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to load Container List",
                        })
                    );
                    dispatch(
                        container.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(containerActions.setFilter(filterParamsData?.filters));
            data = get(response, "data.data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.CONTAINER}`;
            dispatch(container.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new container`;
            dispatch(container.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const createUpdateContainer = createAsyncThunk(
    "flowmanager/container/createContainer",
    async (_, { getState, dispatch }) => {
        let isConcurreny = false;
        const reduxData = getState().flowmanager.container.createContainer;
        const params = postUpdateObject(reduxData);
        const response = await customFetch(
            CONTAINERS.CREATE_CONTAINER,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Container already modified while you were editing.",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload Container.",
                        })
                    );
                }

                isConcurreny = true;
            }
        );

        if ((response == null || response?.status >= 400) && !isConcurreny) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to ${
                        reduxData != "" ? "create" : "update"
                    } Container!`,
                })
            );
        }
        return get(response, "data", null);
    }
);

export const deleteContainer = createAsyncThunk(
    "flowmanager/container/deleteContainer",
    async (obj, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONTAINERS.DELETE_CONTAINER}/${obj.id}`,
            "POST",
            {},
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.includes("Cannot delete!")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Failed to delete, this container is mapped to some flow(s).`,
                        })
                    );
                }
            }
        );
        return get(response, "data", null);
    }
);

export const cloneContainer = createAsyncThunk(
    "flowmanager/container/clone",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${CONTAINERS.CLONE}/${params.id}`,
            "POST",
            {
                cloneTemplate: params.cloneTemplate,
                cloneCollection: params.cloneCollection,
            },
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to clone container.",
                    })
                );
            }
        );
        if (get(response, "data.status", false) === "success") {
            return response.data.data;
        }
    }
);

const postUpdateObject = data => {
    let obj = {};
    obj.name = data.name;
    obj.templateId =
        data?.template === "" || data?.template === null ? null : data.template;
    obj.layoutConfig = data?.layout || null;
    obj.containerLayoutConfig = data?.outerLayout || null;
    obj.collectionId = data.collections;
    obj.type = data.type;
    obj.vertical = data.vertical;
    if (data?.updatedAt) obj.updatedAt = data.updatedAt;
    if (data.id != "") obj.id = data.id;
    return obj;
};

export const fetchContainer = createAsyncThunk(
    "flowmanager/container/fetchContainer",
    async (obj, { dispatch }) => {
        let params = {
            filters: [
                {
                    k: "id",
                    op: "=",
                    v: obj.id,
                },
            ],
        };
        const response = await customFetch(
            `${CONTAINERS.FETCH_CONTAINER}`,
            "POST",
            params
        );

        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch container!",
                })
            );
        }
        return [get(response, "data.data.data", []), obj];
    }
);

export const fetchDefaultContainerLayout = createAsyncThunk(
    "flowmanager/container/fetchDefaultContainerLayout",
    async ({ type, job }, { dispatch }) => {
        const response = await customFetch(
            `${CONTAINERS.FETCH_DEFAULT_LAYOUT}?type=${type}&vertical=${job}`,
            "POST"
        );

        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch default container layout!",
                })
            );
        }
        return get(response, "data", []);
    }
);

export const onFilterApplied = createAsyncThunk(
    "flowmanager/container/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.container.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.OFFER_PATH.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchContainerHistory = createAsyncThunk(
    "flowmanager/container/containerHistory",
    async id => {
        const response = await customFetch(
            `${CONTAINERS.FETCH_CONTAINER_HISTORY}/${id}`
        );
        return get(response, "data", []);
    }
);

const container = createSlice({
    name: "container",
    initialState,
    reducers: {
        setContainerVertical(state, action) {
            state.createContainer.vertical = action.payload;
        },
        setContainerType(state, action) {
            state.createContainer.type = action.payload;
        },
        setContainerName(state, action) {
            state.createContainer.name = action.payload;
        },
        setContainerList(state, action) {
            state.list = action.payload;
        },
        setTemplate(state, action) {
            state.createContainer.template = action.payload;
        },
        setTemplateProperties(state, action) {
            state.createContainer.featureGroupProperties = action.payload;
        },
        setLayout(state, action) {
            state.createContainer.layout = action.payload;
        },
        setLayoutCopy(state, action) {
            state.createContainer.layoutCopy = action.payload;
        },
        setOuterLayout(state, action) {
            state.createContainer.outerLayout = action.payload;
        },
        setOuterLayoutCopy(state, action) {
            state.createContainer.outerLayoutCopy = action.payload;
        },
        setCollection(state, action) {
            state.createContainer.collections = action.payload;
        },
        setCollectionItem(state, action) {
            state.createContainer.collectionItem = action.payload;
        },
        setSelectedCollectionItems(state, action) {
            state.createContainer.selectedCollectionItems = action.payload;
        },
        setDefaultLayout(state, action) {
            state.createContainer.defaultLayout = action.payload;
        },
        setId(state, action) {
            state.createContainer.id = action.payload;
        },
        setCreateContainerLoading(state, action) {
            state.createContainer.loading = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
            state.pagination.prefix = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetContainer(state) {
            state.createContainer = initialState.createContainer;
        },
        setMainLoader(state, action) {
            state.createContainer.mainLoader = action.payload;
        },
        setShowPrompt(state, action) {
            state.createContainer.showPrompt = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchContainerData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchContainerData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchContainer.pending, state => {
            // state.createContainer.loading = true;
        });
        builder.addCase(fetchContainer.fulfilled, (state, action) => {
            state.createContainer.loading = false;
            if (action.payload[0][0]) {
                const {
                    vertical,
                    name,
                    type,
                    collectionId,
                    isActive,
                    templateId,
                    id,
                    layoutConfig,
                    containerLayoutConfig,
                    items,
                    updatedAt,
                    templateDto,
                } = action.payload[0][0];
                state.createContainer.type = type;
                state.createContainer.vertical = vertical;
                state.createContainer.name = name;
                state.createContainer.collections = collectionId;
                state.createContainer.isActive = isActive;
                state.createContainer.id = id;
                state.createContainer.updatedAt = updatedAt;

                if (templateId === null || templateId === "") {
                    state.createContainer.featureGroupProperties = {};
                } else {
                    state.createContainer.featureGroupProperties =
                        templateDto.properties || {};
                }

                if (vertical === "" || vertical === null) {
                    state.createContainer.editPhaseVerticalNotSelected = true;
                } else {
                    state.createContainer.editPhaseVerticalNotSelected = false;
                }

                if (action.payload[1]?.templateData?.length) {
                    const data = action.payload[1].templateData.filter(
                        element => element.id == templateId
                    );
                    if (data[0]) {
                        state.createContainer.template = templateId;
                    }
                }
                if (layoutConfig?.name) {
                    let data = action.payload[1].layoutData.filter(
                        _ => _.name === layoutConfig?.name
                    );

                    if (data?.length > 0) {
                        if (layoutConfig?.configurations?.length > 0) {
                            let dataCopy = JSON.parse(
                                JSON.stringify(layoutConfig)
                            );
                            dataCopy.configurations =
                                dataCopy?.configurations.map((item, i) => ({
                                    ...data[0].configurations.find(
                                        element =>
                                            element.key === item.key && element
                                    ),
                                    ...item,
                                }));
                            state.createContainer.layout = dataCopy;
                            state.createContainer.layoutCopy = dataCopy;
                        } else {
                            state.createContainer.layout = layoutConfig;
                            state.createContainer.layoutCopy = layoutConfig;
                        }
                    } else {
                        state.createContainer.layout = {};
                    }
                }

                if (containerLayoutConfig?.name) {
                    let data = action.payload[1].layoutData.filter(
                        _ => _.name === containerLayoutConfig?.name
                    );
                    if (data?.length > 0) {
                        if (containerLayoutConfig?.configurations?.length > 0) {
                            let dataCopy = JSON.parse(
                                JSON.stringify(containerLayoutConfig)
                            );
                            dataCopy.configurations =
                                dataCopy?.configurations.map((item, i) => ({
                                    ...data[0].configurations.find(
                                        element =>
                                            element.key === item.key && element
                                    ),
                                    ...item,
                                }));
                            state.createContainer.outerLayout = dataCopy;
                            state.createContainer.outerLayoutCopy = dataCopy;
                        } else {
                            state.createContainer.outerLayout =
                                containerLayoutConfig;
                            state.createContainer.outerLayoutCopy =
                                containerLayoutConfig;
                        }
                    } else {
                        state.createContainer.outerLayout = {};
                    }
                }

                if (items?.length) {
                    state.createContainer.selectedCollectionItems = items;
                    state.createContainer.defaultLayout = false;
                } else {
                    state.createContainer.selectedCollectionItems = [];
                    state.createContainer.defaultLayout = true;
                }
            }
            state.createContainerBackup = state.createContainer;
            // state.createContainer.loading = false;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const containerActions = container.actions;
export default container.reducer;
