import Select from "../../Shared/Select/Select";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import CreateContainer, {
    SelectWithCloneOption,
} from "../CreateContainer/CreateContainer";
import {
    useImperativeHandle,
    forwardRef,
    useRef,
    useState,
    useEffect,
    useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    containerActions,
    fetchContainerData,
    cloneContainer,
    fetchContainer,
} from "../../store/flowmanager/container";
import TargetingNode from "./TargetingNode";
import s from "./AddNode.module.scss";
import abImg from "../../assets/img/flow_ab.svg";
import tpImg from "../../assets/img/flow_container.svg";
import contImg from "../../assets/img/flow_tp.svg";
import flowStartImg from "../../assets/img/flow_start.svg";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { toastListActions } from "../../store/toastList/toastList";
import SplitNode from "./SplitNode";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { collectionSelectList } from "../../utils/collectionUtils";
import {
    fetchContainerListWithData,
    reactQueryApiCall,
    useFetchAvailableTargetingParams,
} from "../../generalApi/generalApi";
import {
    EntityType,
    fetchTpKey,
    FlowRouterRelativeSubTypes,
} from "../../Shared/FlowRenderer/FlowRendererUtils";
import PopupTargettingParams from "../../components/PopupTargettingParams/PopupTargettingParams";
import { getPreviewObj, skeletonLoader } from "../../utils/globalUtils";
import Skeleton from "react-loading-skeleton";
import Icon from "../../Shared/Icon/Icon";
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material";
import { FlowSubTypes } from "./Settings";
import { flushSync } from "react-dom";
import LayoutEditComp from "../../components/LayoutEditComp/LayoutEditComp";
import { itemTypeData } from "../../utils/containerUtl";
import { CONTAINERS, MAIN } from "../../constants/url";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import SelectWithActionBtn from "../../Shared/SelectWithActionBtn/SelectWithActionBtn";
import { useQueryClient } from "react-query";
import customFetch from "../../fetch/customFetch";
import { get, isEmpty } from "lodash";

export const AddNode = forwardRef(
    (
        {
            loading,
            editNode,
            isReadOnly,
            rules,
            rulesMeta,
            onSave,
            onClose,
            setLoading,
            additionalContainerOptions = false,
            allowedTp = [],
            nodeOptions = ["CONTAINER", "LOGICAL", "AB_TEST"],
            hideDestinationCreate = false,
            goBackText,
            onGoBack,
            selectedFlowDomain = null,
            selectedFlowVertical = "",
            featureGroupProperties = {},
            isFlowLive = false,
        },
        ref
    ) => {
        const queryClient = useQueryClient();
        const history = useHistory();
        const tragetingParamRef = useRef(null);
        const splitRef = useRef(null);

        const containerSidePanelRef = useRef(null);
        const containerRef = useRef(null);
        const layoutOpenRef = useRef(null);
        const formRef = useRef(null);

        const dispatch = useDispatch();
        const [type, setType] = useState(null);
        const [isTargetingEdit, setIsTargetingEdit] = useState(false);

        const [flowId, setFlowId] = useState(editNode?.entityId || null);

        const [containerType, setContainerType] = useState(null);
        const [container, setContainer] = useState(null);

        const [splitType, setSplitType] = useState(null);
        const [targetings, setTargetings] = useState(null);
        const [targetingBranches, setTargetingBranches] = useState([]);

        const [splitBranches, setSplitBranches] = useState([]);
        const [backupSplitBranches, setBackupSplitBranches] = useState([]);
        const [abSplitTotal, setAbSplitTotal] = useState(0);

        const [editContainerView, setEditContainerView] = useState(false);
        const [createPhasePopup, setCreatePhasePopup] = useState(false);
        const [isSaving, setIsSaving] = useState(false);

        const [confirmCancel, setConfirmCancel] = useState(false);
        const [unsavedChanges, setUnsavedChanges] = useState(false);

        const [previewItem, setPreviewItem] = useState({});
        const [defaultLayout, setDefaultLayout] = useState(false);
        const [collectionItem, setCollectionItem] = useState({});
        const [selectedCollectionItem, setSelectedCollectionItem] = useState(
            []
        );
        const [containerObj, setContainerObj] = useState({});
        const [cloneContainerObj, setCloneContainerObj] = useState(null);

        const { apiData: availableTargetingParams = [] } =
            useFetchAvailableTargetingParams();

        const {
            apiData: apiContainerData,
            isLoading: containerLoading = false,
        } = fetchContainerListWithData();

        const containers = useMemo(() => {
            return apiContainerData?.data || [];
        }, [apiContainerData]);

        const { apiData: apiFlowData } = reactQueryApiCall(
            "flowListData",
            "Flow List",
            {
                url: MAIN.PROD_GET_ALL_FLOWSDETAILS,
                method: "GET",
            },
            {
                staleTime: 60 * 1000,
            }
        );
        const allFlowDetails = useMemo(() => {
            return (
                apiFlowData?.map(_ => ({
                    ..._,
                    label: `${_.id} - ${
                        _.displayName === null ? "N/A" : _.displayName
                    }`,
                })) || []
            );
        }, [apiFlowData]);

        const createContainerState = useSelector(
            state => state.flowmanager.container.createContainer
        );

        useEffect(async () => {
            setType(editNode?.entityType);
            setContainerType(editNode?.entitySubType);
            setContainer(editNode?.entityId);
            setSplitType(editNode?.entitySubType);
            setTargetings(editNode?.targettingParams);
            setIsTargetingEdit(editNode?.isTargetingEdit);
            let tpBranches = [];
            let splitBranchesLocal = [
                {
                    name: "Control:",
                    diversification: 1,
                    ruleMeta: {
                        abType: "RULE",
                        name: "",
                        description: "",
                    },
                    ruleSetId: null,
                    targettingParams: {
                        type: "AB SPLIT",
                        operator: "EQUAL",
                        value: 50,
                        key: "split",
                    },
                    entitySubType: "AB_TEST",
                    entityType: "ROUTER",
                },
                {
                    name: "Variant:",
                    diversification: 1,
                    ruleMeta: {
                        abType: "RULE",
                        name: "",
                        description: "",
                    },
                    ruleSetId: null,
                    targettingParams: {
                        type: "AB SPLIT",
                        operator: "EQUAL",
                        value: 50,
                        key: "split",
                    },
                    entitySubType: "AB_TEST",
                    entityType: "ROUTER",
                },
            ];
            if (editNode?.entitySubType === "AB_TEST") {
                const branches = rules.filter(
                    _ =>
                        _.entityType === "ROUTER" &&
                        _.ruleSetId === editNode.ruleSetId
                );

                splitBranchesLocal = branches.map((_, i) => ({
                    ...(splitBranchesLocal[i] || splitBranchesLocal[1]),
                    ..._,
                    ruleMeta: {
                        ...splitBranchesLocal[i]?.ruleMeta,
                        ..._.ruleMeta,
                        name: _.ruleMeta?.name?.includes(":")
                            ? _.ruleMeta.name.split(":")[1]
                            : _.ruleMeta.name,
                    },
                    name: _.ruleMeta?.name?.includes(":")
                        ? _.ruleMeta.name.split(":")[0] + ":"
                        : (splitBranchesLocal[i] || splitBranchesLocal[1]).name,
                }));
            }
            if (editNode?.entitySubType === "LOGICAL") {
                const branches = rules.filter(
                    _ =>
                        _.entityType === "ROUTER" &&
                        _.ruleSetId === editNode.ruleSetId
                );
                tpBranches = branches.map((_, i) => ({
                    id: _.id,
                    nextSetId: _.nextSetId,
                    value: Array.isArray(_.targettingParams?.value)
                        ? _.targettingParams?.value
                        : _.targettingParams?.key
                        ? [
                              {
                                  key: _.targettingParams?.key,
                                  value: [_.targettingParams?.value],
                              },
                          ]
                        : [],
                    name: `Branch ${i + 1}`,
                    connectionType: _?.targettingParams?.type || "OR",
                }));
            }
            setBackupSplitBranches(
                JSON.parse(JSON.stringify(splitBranchesLocal))
            );
            setSplitBranches(splitBranchesLocal);
            setTargetingBranches(tpBranches);
        }, [editNode]);

        useEffect(() => {
            if (container != null && container !== "") {
                previewLinkFunction(container);
            }
        }, [container, containers]);

        useImperativeHandle(ref, () => {
            return {
                getData: getData,
            };
        });

        const getData = () => {
            const common = {
                ...(editNode ? { id: editNode.id } : null),
                entityType: type,
            };
            if (type === "CONTAINER") {
                return {
                    ...editNode,
                    ...common,
                    entityType: type,
                    entitySubType: containerType,
                    entityId: FlowRouterRelativeSubTypes.includes(containerType)
                        ? null
                        : container,
                };
            } else if (type === "FLOW_START") {
                return {
                    ...editNode,
                    ...common,
                    entitySubType: containerType,
                    entityId: flowId,
                    entityType: type,
                };
            } else {
                if (splitType === "LOGICAL") {
                    const result = tragetingParamRef.current
                        .getData()
                        .map(_ => ({
                            ..._,
                            ...editNode,
                            diversifyID: null,
                            diversification: 1,
                            entitySubType: "LOGICAL",
                            entityType: "ROUTER",
                            nextSetId: _.nextSetId || null,
                            id: _.id || null,
                            targettingParams:
                                _.value && _.type
                                    ? {
                                          type: _.connectionType || "OR",
                                          value: _.value.map(v => {
                                              if (typeof v === "object") {
                                                  if (
                                                      v.valueOne &&
                                                      v.valueSecond
                                                  ) {
                                                      return {
                                                          type: "AND",
                                                          value: getTP(
                                                              _.type,
                                                              v,
                                                              availableTargetingParams
                                                          ),
                                                      };
                                                  }
                                              }

                                              return {
                                                  key: _.type,
                                                  operator: "EQUAL",
                                                  type: "EXPRESSION",
                                                  value: v,
                                              };
                                          }),
                                      }
                                    : {},
                            value: _.value,
                        }));
                    if (
                        result?.filter(_ => _.targettingParams?.value?.length)
                            ?.length
                    ) {
                        let tpAllValues = [];
                        result.forEach(_ => {
                            tpAllValues = [..._.value, ...tpAllValues];
                        });
                        const tpAllValuesSet = new Set(tpAllValues);

                        if (
                            tpAllValues.length ===
                            Array.from(tpAllValuesSet).length
                        ) {
                            return result;
                        } else {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message:
                                        "Cannot add same targeting in different branches",
                                })
                            );
                        }
                    } else {
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: "Cannot add empty targeting",
                            })
                        );
                    }
                }
                if (splitType === "AB_TEST") {
                    const result = splitRef.current?.getData();
                    if (result) {
                        const total = result?.reduce(
                            (p, c) => {
                                return {
                                    value:
                                        p.value +
                                        parseInt(c.targettingParams.value),
                                };
                            },
                            {
                                value: 0,
                            }
                        );
                        if (total.value !== 100) {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message: "Split total should be 100%",
                                })
                            );
                            return;
                        }

                        return result;
                    }
                }
            }
        };

        const previewLinkFunction = (containerId = "") => {
            if (containerId === "") {
                layoutOpenRef.current.open();
            } else {
                setDefaultLayout(false);
                const containerData = containers.find(
                    obj => obj.id === (containerId || container)
                );
                setContainerObj(containerData);
                let itemData;
                if (containerData?.items?.length > 0) {
                    itemData = {
                        ...containerData.items[0],
                        layoutConfig: containerData.items[0]?.layoutConfig
                            ? containerData.items[0].layoutConfig
                            : containerData?.layoutConfig,
                    };
                    setSelectedCollectionItem(containerData?.items);
                } else {
                    itemData = {
                        layout: containerData?.layoutConfig,
                        default: true,
                    };

                    setDefaultLayout(true);
                    setSelectedCollectionItem([]);
                }
                setCollectionItem(itemData);

                const obj = getPreviewObj(
                    itemData,
                    containerData?.type,
                    containerData?.containerLayoutConfig,
                    containerData?.templateId === null ||
                        containerData?.templateId === ""
                        ? featureGroupProperties
                        : containerData?.templateDto?.properties
                );
                setPreviewItem(obj);
            }
        };

        const fetchIndividualContainer = async id => {
            let params = {
                filters: [
                    {
                        k: "id",
                        op: "=",
                        v: id,
                    },
                ],
            };

            const response = await customFetch(
                `${CONTAINERS.FETCH_CONTAINER}`,
                "POST",
                params
            );

            if (response?.status >= 400) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch container!",
                    })
                );
            }
            return get(response, "data.data.data", []);
        };

        return (
            <div ref={formRef} className={s.formWrap}>
                <div className={s.sidePanelheader}>
                    {!loading ? (
                        <p>
                            {editNode ? "Edit" : "New"}{" "}
                            {splitType === "AB_TEST"
                                ? "A/B Split"
                                : splitType === "LOGICAL"
                                ? "Targeting"
                                : "Node"}
                        </p>
                    ) : (
                        <div>{skeletonLoader([25])}</div>
                    )}
                    {splitType === "AB_TEST" && (
                        <div
                            className={`${s.totalCount} ${
                                abSplitTotal !== 100 ? s.totalCount__error : ""
                            }`}
                        >
                            ({abSplitTotal}/100)
                        </div>
                    )}
                    {!loading && (
                        <span
                            className={`${s.closeIcon} ${
                                rules.length === 0 || isSaving ? s.disabled : ""
                            } `}
                            onClick={() => onClose()}
                        >
                            <Icon
                                icon="CloseDefault"
                                size="16px"
                                className="circleIconColor circleIconColor--darkHover"
                            />
                        </span>
                    )}
                    {goBackText && rules.length === 0 && (
                        <div
                            className={`${s.backToListing} flex gap-5`}
                            onClick={onGoBack}
                        >
                            <Tooltip message={goBackText} position="bottom">
                                <Icon
                                    icon="BackToListing"
                                    size="20px"
                                    enableHover
                                    className="circleIconColor"
                                />
                            </Tooltip>
                        </div>
                    )}
                </div>
                <div className={`${s.content} addNodeContent`}>
                    {isSaving && <LinearDeterminate></LinearDeterminate>}
                    <div>
                        {!editNode && !type && (
                            <>
                                <ul className={s.nodeTypeList}>
                                    {!loading ? (
                                        <>
                                            {!hideDestinationCreate &&
                                                nodeOptions.includes(
                                                    "CONTAINER"
                                                ) && (
                                                    <li
                                                        onClick={() => {
                                                            setType(
                                                                "CONTAINER"
                                                            );
                                                        }}
                                                    >
                                                        <div>
                                                            <img
                                                                src={contImg}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div
                                                                className={
                                                                    s.header
                                                                }
                                                            >
                                                                Container
                                                            </div>
                                                            <p>
                                                                Add new
                                                                Container of the
                                                                type Register,
                                                                Survey,
                                                                OfferWall etc.
                                                                on the Flow
                                                            </p>
                                                        </div>
                                                    </li>
                                                )}
                                            {nodeOptions.includes(
                                                "LOGICAL"
                                            ) && (
                                                <li
                                                    onClick={() => {
                                                        setType("ROUTER");
                                                        setSplitType("LOGICAL");
                                                    }}
                                                >
                                                    <div>
                                                        <img src={tpImg} />
                                                    </div>
                                                    <div>
                                                        <div
                                                            className={s.header}
                                                        >
                                                            Targeting Parameter
                                                        </div>
                                                        <p>
                                                            Split the flow in
                                                            multiple branches
                                                            based on user
                                                            attributes and
                                                            behaviour
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                            {nodeOptions.includes(
                                                "AB_TEST"
                                            ) && (
                                                <li
                                                    onClick={() => {
                                                        setType("ROUTER");
                                                        setSplitType("AB_TEST");
                                                    }}
                                                >
                                                    <div>
                                                        <img src={abImg} />
                                                    </div>
                                                    <div>
                                                        <div
                                                            className={s.header}
                                                        >
                                                            A/B Split
                                                        </div>
                                                        <p>
                                                            Create an A/B Test
                                                            with control and
                                                            variant buckets.
                                                            Weight and flow can
                                                            be customised for
                                                            each bucket.
                                                        </p>
                                                    </div>
                                                </li>
                                            )}
                                            {!hideDestinationCreate &&
                                                nodeOptions.includes(
                                                    "FLOW_START"
                                                ) && (
                                                    <li
                                                        onClick={() => {
                                                            setType(
                                                                "FLOW_START"
                                                            );
                                                            setFlowId(null);
                                                        }}
                                                    >
                                                        <div>
                                                            <img
                                                                src={
                                                                    flowStartImg
                                                                }
                                                            />
                                                        </div>
                                                        <div>
                                                            <div
                                                                className={
                                                                    s.header
                                                                }
                                                            >
                                                                Flow
                                                            </div>
                                                            <p>
                                                                Add an existing
                                                                flow
                                                            </p>
                                                        </div>
                                                    </li>
                                                )}
                                        </>
                                    ) : (
                                        <>
                                            <div className="mb-20">
                                                {skeletonLoader([150])}
                                            </div>
                                            <div className="mb-20">
                                                {skeletonLoader([150])}
                                            </div>
                                            <div className="mb-20">
                                                {skeletonLoader([150])}
                                            </div>
                                        </>
                                    )}
                                </ul>
                            </>
                        )}
                        {type === "CONTAINER" && !isTargetingEdit && (
                            <>
                                {additionalContainerOptions && (
                                    <div className={"row"}>
                                        <p className={`headerTitleRequired`}>
                                            Destination*
                                        </p>
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={
                                                    containerType ===
                                                        FlowSubTypes.PREV ||
                                                    containerType ===
                                                        FlowSubTypes.CURRENT
                                                        ? containerType
                                                        : FlowSubTypes.NEXT
                                                }
                                                onChange={val => {
                                                    setUnsavedChanges(true);
                                                    setContainerType(
                                                        val.target.value
                                                    );
                                                    setContainer(null);
                                                }}
                                            >
                                                <FormControlLabel
                                                    value={FlowSubTypes.PREV}
                                                    className="w-100"
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: "#CAD1D4",
                                                                "&.Mui-checked":
                                                                    {
                                                                        color: "#41c3a9",
                                                                    },
                                                                "&.Mui-disabled":
                                                                    {
                                                                        color: "#a8aeb1",
                                                                        cursor: "not-allowed",
                                                                        pointerEvents:
                                                                            "auto",
                                                                    },
                                                            }}
                                                        />
                                                    }
                                                    label="Previous Container"
                                                    sx={{
                                                        "&.Mui-disabled": {
                                                            color: "#a8aeb1",
                                                            cursor: "not-allowed",
                                                            pointerEvents:
                                                                "auto",
                                                        },
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value={FlowSubTypes.CURRENT}
                                                    className="w-100"
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: "#CAD1D4",
                                                                fontSize:
                                                                    "12px",
                                                                "&.Mui-checked":
                                                                    {
                                                                        color: "#41c3a9",
                                                                    },
                                                                "&.Mui-disabled":
                                                                    {
                                                                        color: "#a8aeb1",
                                                                        cursor: "not-allowed",
                                                                        pointerEvents:
                                                                            "auto",
                                                                    },
                                                            }}
                                                        />
                                                    }
                                                    label="Stay on same Container"
                                                    sx={{
                                                        "&.Mui-disabled": {
                                                            color: "#a8aeb1",
                                                            cursor: "not-allowed",
                                                            pointerEvents:
                                                                "auto",
                                                        },
                                                    }}
                                                />
                                                <FormControlLabel
                                                    value="next"
                                                    className="w-100"
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: "#CAD1D4",
                                                                "&.Mui-checked":
                                                                    {
                                                                        color: "#41c3a9",
                                                                    },
                                                                "&.Mui-disabled":
                                                                    {
                                                                        color: "#a8aeb1",
                                                                        cursor: "not-allowed",
                                                                        pointerEvents:
                                                                            "auto",
                                                                    },
                                                            }}
                                                        />
                                                    }
                                                    label="Specific Container"
                                                    sx={{
                                                        "&.Mui-disabled": {
                                                            color: "#a8aeb1",
                                                            cursor: "not-allowed",
                                                            pointerEvents:
                                                                "auto",
                                                        },
                                                    }}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                )}
                                {(!additionalContainerOptions ||
                                    (containerType !== FlowSubTypes.PREV &&
                                        containerType !==
                                            FlowSubTypes.CURRENT)) && (
                                    <div className={"row"}>
                                        <p className={`headerTitleRequired`}>
                                            Container Type*
                                        </p>
                                        <p className={`infoText`}>
                                            Type of step you wish to continue
                                            the flow with.
                                        </p>
                                        <Select
                                            label={"Select Container Type"}
                                            options={collectionSelectList}
                                            keyField={"name"}
                                            idField={"id"}
                                            selectedId={containerType}
                                            multiSelect={false}
                                            onSelect={e => {
                                                setContainerType(e.id);
                                                setUnsavedChanges(true);
                                                setContainer(null);
                                            }}
                                            required={true}
                                            onLoadSelectReturn={false}
                                            isDisabled={isReadOnly}
                                            showOptionToolTip={false}
                                        />
                                    </div>
                                )}
                                {containerType &&
                                    containerType !== FlowSubTypes.NEXT &&
                                    containerType !== FlowSubTypes.PREV &&
                                    containerType !== FlowSubTypes.CURRENT && (
                                        <>
                                            <div className={"row"}>
                                                <p
                                                    className={`headerTitleRequired dp-parent dp-parent-hor-space-between`}
                                                >
                                                    Container*
                                                    <div className="flex gap-20">
                                                        {(containerType ===
                                                            "REGISTER" ||
                                                            containerType ===
                                                                "SURVEY" ||
                                                            containerType ===
                                                                "DETAILS_CONFIRMATION") &&
                                                            container &&
                                                            containers?.length >
                                                                0 &&
                                                            !containerLoading && (
                                                                <span
                                                                    className={
                                                                        s.containerEditLink
                                                                    }
                                                                    onClick={() => {
                                                                        previewLinkFunction();
                                                                    }}
                                                                >
                                                                    Preview
                                                                </span>
                                                            )}
                                                        {container &&
                                                            containers?.length >
                                                                0 &&
                                                            !containerLoading && (
                                                                <span
                                                                    className={
                                                                        s.containerEditLink
                                                                    }
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            containerActions.setId(
                                                                                container
                                                                            )
                                                                        );
                                                                        setTimeout(
                                                                            () => {
                                                                                containerSidePanelRef.current.open();
                                                                            },
                                                                            100
                                                                        );
                                                                        setEditContainerView(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </span>
                                                            )}
                                                    </div>
                                                </p>
                                                <p className={`infoText`}>
                                                    Type of step you wish to
                                                    continue the flow with.
                                                </p>
                                                <Select
                                                    loading={
                                                        !containers?.length
                                                    }
                                                    label={"Select Container"}
                                                    options={containers
                                                        ?.filter(_ =>
                                                            containerType
                                                                ? _.type ===
                                                                  containerType
                                                                : true
                                                        )
                                                        ?.map(_ => {
                                                            return {
                                                                ..._,
                                                                name:
                                                                    _.id +
                                                                    ": " +
                                                                    _.name,
                                                            };
                                                        })}
                                                    selectedId={container}
                                                    keyField={"name"}
                                                    idField={"id"}
                                                    multiSelect={false}
                                                    onSelect={e => {
                                                        setContainer(e.id);
                                                        setUnsavedChanges(true);
                                                    }}
                                                    required={true}
                                                    onLoadSelectReturn={false}
                                                    showSearch={true}
                                                    showCreate={true}
                                                    onCreate={item => {
                                                        setCreatePhasePopup(
                                                            true
                                                        );
                                                        containerSidePanelRef.current.open();
                                                        dispatch(
                                                            containerActions.setContainerType(
                                                                containerType
                                                            )
                                                        );
                                                        dispatch(
                                                            containerActions.setContainerName(
                                                                item.name
                                                            )
                                                        );
                                                        setUnsavedChanges(true);
                                                    }}
                                                    isDisabled={isReadOnly}
                                                    CustomRender={option => (
                                                        <SelectWithCloneOption
                                                            option={
                                                                option.option
                                                            }
                                                            selector="name"
                                                            onClone={async () => {
                                                                setCloneContainerObj(
                                                                    option.option
                                                                );
                                                                dispatch(
                                                                    containerActions.setId(
                                                                        option
                                                                            .option
                                                                            .id
                                                                    )
                                                                );
                                                                setEditContainerView(
                                                                    true
                                                                );
                                                                containerSidePanelRef.current.open();
                                                                setUnsavedChanges(
                                                                    true
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </div>

                                            {container &&
                                                containerObj &&
                                                containers?.length > 0 &&
                                                !containerLoading &&
                                                Object.keys(previewItem)
                                                    ?.length > 0 &&
                                                selectedFlowDomain !== null &&
                                                (containerType === "REGISTER" ||
                                                    containerType ===
                                                        "SURVEY" ||
                                                    containerType ===
                                                        "DETAILS_CONFIRMATION") && (
                                                    <div className={"row"}>
                                                        <p
                                                            className={`headerTitleRequired dp-parent dp-parent-hor-space-between`}
                                                        >
                                                            Preview
                                                        </p>
                                                        <LayoutEditComp
                                                            vertical={
                                                                containerObj?.vertical ||
                                                                selectedFlowVertical
                                                            }
                                                            defaultConfigs={[]}
                                                            item={previewItem}
                                                            showDefaultMsg={
                                                                defaultLayout
                                                            }
                                                            hidePreviewHeader={
                                                                true
                                                            }
                                                            selectedFlowDomain={
                                                                selectedFlowDomain
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </>
                                    )}
                            </>
                        )}
                        {((type === "ROUTER" || isTargetingEdit) &&
                            (splitType === "LOGICAL" || isTargetingEdit) && (
                                <TargetingNode
                                    ref={tragetingParamRef}
                                    branches={targetingBranches}
                                    editNode={editNode}
                                    allowedTp={allowedTp}
                                    onAnyChange={setUnsavedChanges}
                                    customAnswerAndSkipAnswerSupport={false}
                                    readOnly={isReadOnly}
                                />
                            )) ||
                            (splitType === "AB_TEST" && (
                                <SplitNode
                                    ref={splitRef}
                                    branches={splitBranches}
                                    setLoading={setLoading}
                                    setAbTotal={setAbSplitTotal}
                                    rulesMeta={rulesMeta}
                                    onAnyChange={setUnsavedChanges}
                                    readOnly={isReadOnly}
                                />
                            ))}

                        {type === "FLOW_START" && (
                            <div className={"row"}>
                                {flowId !== null && (
                                    <div
                                        className={`dp-parent dp-parent-hor-space-between dp-child-ver-center maxChildContainer`}
                                    >
                                        <p className={`headerTitleRequired`}>
                                            Flow Id*
                                        </p>
                                        <div
                                            className={s.editWrapper}
                                            onClick={() => {
                                                const id =
                                                    allFlowDetails?.filter(
                                                        _ => _.id === flowId
                                                    )[0]?.id;
                                                window.open(
                                                    `/flowmanager/flow/edit/${id}`,
                                                    "_blank"
                                                );
                                            }}
                                        >
                                            <Icon
                                                icon="Edit"
                                                size="14px"
                                                enableHover={true}
                                            />
                                            Edit
                                        </div>
                                    </div>
                                )}
                                <Select
                                    label={
                                        flowId
                                            ? allFlowDetails
                                                  ?.filter(_ => _.id === flowId)
                                                  ?.map(_ => _.label)[0] || null
                                            : "Select Flow"
                                    }
                                    unstyledLabel
                                    portal={false}
                                    options={allFlowDetails}
                                    keyField={"label"}
                                    idField={"id"}
                                    selectedId={null}
                                    multiSelect={false}
                                    onSelect={e => {
                                        setFlowId(e.id);
                                        setUnsavedChanges(true);
                                    }}
                                    showSearch
                                    placeholder="Search by Flow Id or Name"
                                    onLoadSelectReturn={false}
                                    isDisabled={isReadOnly}
                                    loading={!allFlowDetails.length}
                                    selectClassName={"w-100"}
                                />
                            </div>
                        )}
                        <SidePanelInfinity
                            ref={containerSidePanelRef}
                            onSave={async () => {
                                setIsSaving(true);
                                if (cloneContainerObj) {
                                    containerSidePanelRef?.current?.close();
                                    const clonedItem = await dispatch(
                                        cloneContainer({
                                            id: cloneContainerObj.id,
                                            cloneTemplate:
                                                containerRef?.current
                                                    ?.shouldCloneTemplate,
                                            cloneCollection:
                                                containerRef?.current
                                                    ?.shouldCloneCollection,
                                        })
                                    ).then(res => res.payload);

                                    if (clonedItem) {
                                        const containeritem =
                                            await fetchIndividualContainer(
                                                clonedItem.id
                                            );
                                        queryClient.setQueryData(
                                            "containerListWithData",
                                            {
                                                data: [
                                                    ...containers,
                                                    ...containeritem,
                                                ],
                                            }
                                        );
                                        queryClient.setQueryData(
                                            "containerListData",
                                            {
                                                data: [
                                                    ...containers,
                                                    ...containeritem,
                                                ],
                                            }
                                        );
                                        setContainer(clonedItem.id);
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Success",
                                                message:
                                                    "Container cloned successfully.",
                                                autoClose: 5000,
                                            })
                                        );
                                    }
                                    setIsSaving(false);
                                } else {
                                    let container =
                                        await containerRef.current.onSaveHandler();
                                    if (container) {
                                        const containeritem =
                                            await fetchIndividualContainer(
                                                container.id
                                            );
                                        const containerIsEdited =
                                            await containers.some(
                                                item =>
                                                    item.id ===
                                                    containeritem[0].id
                                            );
                                        await queryClient.setQueryData(
                                            "containerListWithData",
                                            {
                                                data: containerIsEdited
                                                    ? [
                                                          ...containers.map(
                                                              (item, idx) => {
                                                                  if (
                                                                      item.id ===
                                                                      containeritem[0]
                                                                          .id
                                                                  )
                                                                      return containeritem[0];
                                                                  else
                                                                      return item;
                                                              }
                                                          ),
                                                      ]
                                                    : [
                                                          ...containers,
                                                          ...containeritem,
                                                      ],
                                            }
                                        );
                                        await queryClient.setQueryData(
                                            "containerListData",
                                            {
                                                data: containerIsEdited
                                                    ? [
                                                          ...containers.map(
                                                              (item, idx) => {
                                                                  if (
                                                                      item.id ===
                                                                      containeritem[0]
                                                                          .id
                                                                  )
                                                                      return containeritem[0];
                                                                  else
                                                                      return item;
                                                              }
                                                          ),
                                                      ]
                                                    : [
                                                          ...containers,
                                                          ...containeritem,
                                                      ],
                                            }
                                        );
                                        setContainer(container.id);
                                        containerSidePanelRef.current.close();
                                        setCreatePhasePopup(false);
                                        setEditContainerView(false);
                                    }
                                }
                                setIsSaving(false);
                            }}
                            onClose={() => {
                                setEditContainerView(false);
                                setCreatePhasePopup(false);
                                setCloneContainerObj(null);
                            }}
                            title={`${
                                cloneContainerObj
                                    ? "Clone & Select"
                                    : editContainerView
                                    ? "Edit"
                                    : "Create"
                            } Container`}
                            disableSaveBtn={
                                isReadOnly ||
                                createContainerState.loading ||
                                createContainerState.mainLoader ||
                                isSaving ||
                                !isFlowLive
                            }
                            saveButtonText={
                                cloneContainerObj
                                    ? "Confirm"
                                    : editContainerView
                                    ? "Save"
                                    : "Create"
                            }
                            showLoader={
                                createContainerState.loading ||
                                createContainerState.mainLoader ||
                                isSaving
                            }
                            showHistoryCallback={
                                editContainerView
                                    ? () => containerRef?.current?.showHistory()
                                    : null
                            }
                            navigateCallback={
                                editContainerView
                                    ? () =>
                                          containerRef?.current?.navigateCallback()
                                    : null
                            }
                        >
                            <CreateContainer
                                id={container}
                                ref={containerRef}
                                showInPopup={true}
                                editPhaseInPopUp={
                                    editContainerView || cloneContainerObj
                                        ? true
                                        : false
                                }
                                createPhaseInPopup={createPhasePopup}
                                isClonePanel={cloneContainerObj}
                                selectedFlowDomain={selectedFlowDomain}
                                isTableView={isReadOnly}
                                collectionUpdated={async () => {
                                    const containeritem =
                                        await fetchIndividualContainer(
                                            container
                                        );
                                    queryClient.setQueryData(
                                        "containerListWithData",
                                        {
                                            data: [
                                                ...containers.map(
                                                    (item, idx) => {
                                                        if (
                                                            item.id ===
                                                            containeritem[0].id
                                                        )
                                                            return containeritem[0];
                                                        else return item;
                                                    }
                                                ),
                                            ],
                                        }
                                    );
                                    queryClient.setQueryData(
                                        "containerListData",
                                        {
                                            data: [
                                                ...containers.map(
                                                    (item, idx) => {
                                                        if (
                                                            item.id ===
                                                            containeritem[0].id
                                                        )
                                                            return containeritem[0];
                                                        else return item;
                                                    }
                                                ),
                                            ],
                                        }
                                    );
                                }}
                                parentFeatureGroupProperties={
                                    featureGroupProperties
                                }
                            ></CreateContainer>
                        </SidePanelInfinity>
                        <SidePanelInfinity
                            ref={layoutOpenRef}
                            title={"Edit Layout Features"}
                            width={"93%"}
                            dialogContentClassName={"popupStyle"}
                            onClose={() => {}}
                            disableSaveBtn={true}
                        >
                            <LayoutEditComp
                                vertical={
                                    containerObj?.vertical ||
                                    selectedFlowVertical
                                }
                                defaultConfigs={[]}
                                item={previewItem}
                                showDefaultMsg={defaultLayout}
                                showitemsDropdown={selectedCollectionItem.map(
                                    (obj, idx) => {
                                        return {
                                            id: `Item-${obj.rank}`,
                                            name: `Item-${obj.rank}`,
                                        };
                                    }
                                )}
                                itemsDropdownId={`Item-${collectionItem.rank}`}
                                onChangeItem={data => {
                                    const getRank = data?.id?.split("-");
                                    let getCollectionItem =
                                        selectedCollectionItem.find(
                                            (obj, idx) =>
                                                obj?.rank?.toString() ===
                                                getRank[1]
                                        );
                                    let itemData = {
                                        ...getCollectionItem,
                                        layoutConfig:
                                            getCollectionItem?.layoutConfig
                                                ? getCollectionItem.layoutConfig
                                                : containerObj?.layoutConfig,
                                    };
                                    setCollectionItem(itemData);

                                    const obj = getPreviewObj(
                                        itemData,
                                        containerObj?.type,
                                        containerObj?.containerLayoutConfig
                                    );
                                    setPreviewItem(obj);
                                }}
                                selectedFlowDomain={selectedFlowDomain}
                            />
                        </SidePanelInfinity>
                    </div>
                </div>

                <div
                    className={`${s.footer} ${
                        !(type && !editNode) &&
                        !((!type || editNode) && rules.length > 0) &&
                        !(!editNode && type)
                            ? s.noTopBorder
                            : ""
                    }`}
                >
                    {confirmCancel && (
                        <div className={s.confirmCancelPopup}>
                            <PopupTargettingParams
                                Rfunction={() => {
                                    setType(null);
                                    setSplitType(null);
                                    setSplitBranches(
                                        JSON.parse(
                                            JSON.stringify(backupSplitBranches)
                                        )
                                    );
                                    setContainerType(null);
                                    setContainer(null);
                                    setUnsavedChanges(false);
                                }}
                                setShowPopup={() => {
                                    setConfirmCancel();
                                }}
                                customText={`By proceeding you will lose any unsaved changes. Do you wish to go back?`}
                            />
                        </div>
                    )}
                    {type && !editNode && (
                        <Button
                            btnTheme={ButtonTypes.default_btn}
                            onClick={() => {
                                if (unsavedChanges) {
                                    setConfirmCancel(true);
                                } else {
                                    setType(null);
                                    setSplitType(null);
                                    setSplitBranches(
                                        JSON.parse(
                                            JSON.stringify(backupSplitBranches)
                                        )
                                    );
                                    setContainerType(null);
                                    setContainer(null);
                                }
                            }}
                        >
                            Back
                        </Button>
                    )}

                    {(!type || editNode) && rules.length > 0 && (
                        <>
                            {!loading ? (
                                <Button
                                    btnTheme={ButtonTypes.default_btn}
                                    onClick={() => {
                                        onClose();
                                        setSplitBranches(
                                            JSON.parse(
                                                JSON.stringify(
                                                    backupSplitBranches
                                                )
                                            )
                                        );
                                    }}
                                    disable={!rules.length}
                                >
                                    {unsavedChanges
                                        ? "Discard & Close"
                                        : "Close"}
                                </Button>
                            ) : (
                                <div className={s.nodeFooterBtnLoader}>
                                    <Skeleton width={85} height={40} />
                                </div>
                            )}
                        </>
                    )}

                    {editNode && (
                        <Button
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={
                                isReadOnly ||
                                isSaving ||
                                containerLoading ||
                                (type === EntityType.CONTAINER &&
                                    (!containerType ||
                                        (!container &&
                                            !FlowRouterRelativeSubTypes.includes(
                                                containerType
                                            )))) ||
                                !unsavedChanges
                            }
                            onClick={async () => {
                                const data = getData();
                                if (data) {
                                    flushSync(() => setIsSaving(true));
                                    let saveType = "UPDATE";
                                    if (
                                        splitType === "AB_TEST" &&
                                        data.some(_ => !_.id) &&
                                        type !== EntityType.CONTAINER
                                    ) {
                                        saveType = "DELETECREATE";
                                    }
                                    await onSave(data, saveType);
                                    setIsSaving(false);
                                }
                            }}
                        >
                            Update
                        </Button>
                    )}
                    {!editNode && type && (
                        <>
                            {!loading ? (
                                <Button
                                    btnTheme={ButtonTypes.primaryHover_btn}
                                    disable={
                                        isReadOnly ||
                                        containerLoading ||
                                        (type === "CONTAINER" &&
                                        !isTargetingEdit
                                            ? isSaving ||
                                              (!container &&
                                                  !FlowRouterRelativeSubTypes.includes(
                                                      containerType
                                                  ))
                                            : isSaving) ||
                                        (type === "FLOW_START" && !flowId)
                                    }
                                    type="submit"
                                    onClick={async () => {
                                        const data = getData();
                                        if (data) {
                                            if (splitType === "AB_TEST") {
                                                const unsavedRuleSetName =
                                                    data.map(
                                                        _ =>
                                                            _.ruleSetMeta
                                                                ?.name || ""
                                                    );
                                                const localRuleSetName =
                                                    rules.map(
                                                        _ =>
                                                            _.ruleSetMeta
                                                                ?.name || ""
                                                    );
                                                if (
                                                    unsavedRuleSetName?.some(
                                                        _ =>
                                                            localRuleSetName.includes(
                                                                _
                                                            )
                                                    )
                                                ) {
                                                    dispatch(
                                                        toastListActions.setToastList(
                                                            {
                                                                type: "Error",
                                                                message:
                                                                    "A/b test with similar name already exists for this flow.",
                                                            }
                                                        )
                                                    );
                                                    return false;
                                                }
                                            }
                                            flushSync(() => setIsSaving(true));
                                            await onSave(data, "ADD");
                                            setIsSaving(false);
                                        }
                                    }}
                                >
                                    Add
                                </Button>
                            ) : (
                                <div className={s.nodeFooterBtnLoader}>
                                    <Skeleton width={85} height={40} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
);

export const getTP = (type, v, availableTargetingParams) => {
    const tp = availableTargetingParams.find(
        _ => _.name === fetchTpKey({ key: type })
    );
    if (tp.type === "range") {
        return [
            {
                key: type,
                operator: "GT",
                type: "EXPRESSION",
                value: v.valueOne,
            },
            {
                key: type,
                operator: "LT",
                type: "EXPRESSION",
                value: v.valueSecond,
            },
        ];
    }
    return [
        {
            key: `q_${v.valueOne}`,
            operator: "EQUAL",
            type: "EXPRESSION",
            value: v.valueSecond,
        },
    ];
};

export default AddNode;
