import { cloneDeep, get } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import TableFlex from "../TableFlex/TableFlex";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import Input from "../../Shared/Input/Input";
import Select from "../../Shared/Select/Select";
import { getRandomId } from "../../utils/globalUtils";
import { useDispatch, useSelector } from "react-redux";
import s from "./OfferPathSlotsInnerTable.module.scss";
import { useParams } from "react-router-dom";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import { toastListActions } from "../../store/toastList/toastList";
import Icon from "../../Shared/Icon/Icon";
import AddDedupeRule from "../AddDedupeRule/AddDedupeRule";

import { historyDataAction } from "../../utils/historyPopUpUtils";
import Popup from "../../Shared/Popup/Popup";
import { CreativeRender } from "../CreativeRender/CreativeRender";
import ToggleValueSwitch, {
    SIZES,
} from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import noDataImg from "../../assets/img/nooffers.svg";
import noDataImgDark from "../../assets/img/nooffers-dark.svg";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { analytics } from "../../firebase/config";
import StatusRenderer from "../Renderers/StatusRenderer/StatusRenderer";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import ConfigRenderer from "../Renderers/ConfigDetailRenderer/ConfigDetailRenderer";
import CustomTooltipRenderer from "../Renderers/CustomTooltipRenderer/CustomTooltipRenderer";
import TargettingParams from "../TargettingParams/TargettingParams";
import InfoText from "../../Shared/InfoText/InfoText";
import {
    OfferEntityMacros,
    OfferActions,
    PIMacrosSlotLevel,
} from "../../constants/dedupeConstants";
import ReactSelect from "../../Shared/ReactSelect/ReactSelect";
import { DisplayDataType } from "../LayoutEditComp/LayoutEditComp";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import Table from "../../Shared/Table/Table";
import { mergeByKey, checkKeyValue } from "../../utils/offerPathUtils";
import DummySelect from "../DummySelect/DummySelect";

const OfferPathSlotsInnerTable = ({
    fetchDealIdsApi,
    item,
    creativesList = [],
    revenueList = [],
    offerWallList = [],
    offerPathAdSlots,
    action,
    data,
    pageId,
    deletedSlotsAds,
    historyInnerTableApi,
    innerChildFocus,
    setInnerChildFocus,
    readOnly = false,
    childPropName = "editCreativeData",
    entityName = "Offer",
    enableDedupe = false,
    enableSwitch = true,
    showWarningMessage = false,
    featureCopyToolbarKeys,
    showOffersHistoryIcon = true,
    offerPathKeyList = [],
}) => {
    const dispatch = useDispatch();
    const tableConfig = {
        stickyHeader: true,
    };

    const editDealIdRef = useRef(null);
    const editWeightRef = useRef(null);
    const weightRef = useRef(null);
    const dealIDRef = useRef(null);

    const [parentData, setParentData] = useState("");
    const [accData, setAccData] = useState([]);
    const [innerTableEditId, setInnerTableEditId] = useState([]);

    const [weight, setWeight] = useState("");
    const [dealId, setDealId] = useState("");
    const [creative, setCreative] = useState(null);
    const [revenueType, setRevenueType] = useState(null);
    const [offerWall, setOfferWall] = useState(null);
    const [creativeError, setCreativeError] = useState(false);
    const [revenueTypeError, setRevenueTypeError] = useState(false);
    const [offerWallError, setOfferWallError] = useState(false);
    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const [openAddRuleSideBar, setOpenAddRuleSideBar] = useState(false);
    const [tableView, setTableView] = useState(
        localStorage.getItem("offersTableView") === "true"
    );
    const [editWeight, setEditWeight] = useState("");
    const [editDealId, setEditDealId] = useState("");
    const [editCreative, setEditCreative] = useState(null);
    const [editRevenueType, setEditRevenueType] = useState(null);
    const [editOfferWall, setEditOfferWall] = useState(null);
    const [showOfferKeySelect, setShowOfferKeySelect] = useState(false);
    const [offerKeysData, setOfferKeysData] = useState([]);
    const [mappingDeletePopup, setMappingDeletePopup] = useState([]);
    const [selectOpenOnChange, setSelectOpenOnChange] = useState(false);

    const [dealIdList, setDealIdList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [dealIdFailed, setDealIdFailed] = useState(false);
    const urlParams = useParams();
    const tableData = { tableError: "Data don't exist for this id" };

    const modules = useSelector(state => state.auth.user.modules);
    const advertiserList = useSelector(
        state => state.listings.offlineConversion.advertisersList
    );

    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);

    useEffect(() => {
        let innerData = get(data, "selectedListItems", []).findIndex(
            data => data.rowId == item.rowId
        );
        setParentData(get(data, `selectedListItems[${innerData}]`, ""));
        if (
            get(data.selectedListItems[innerData], childPropName, "") != null &&
            get(data.selectedListItems[innerData], childPropName, "").length > 0
        ) {
            const deletedSlotsAds_Id = deletedSlotsAds.map(
                e => e.ad.innerRowId
            );
            let totalWeight = 0;
            data.selectedListItems[innerData][childPropName].map(element => {
                if (!deletedSlotsAds_Id.includes(element.innerRowId)) {
                    totalWeight = totalWeight + Number(element.weight);
                }
            });

            let editCreativeDataCopy = JSON.parse(
                JSON.stringify(
                    get(
                        data,
                        `selectedListItems[${innerData}][${childPropName}]`,
                        []
                    )
                )
            );
            editCreativeDataCopy.map(element => {
                if (!deletedSlotsAds_Id.includes(element.innerRowId)) {
                    element["weightPercent"] =
                        (element.weight / totalWeight) * 100;
                }
            });

            setAccData(
                editCreativeDataCopy.sort((a, b) => {
                    if (groupAds[parentData["type"]] === "priority") {
                        return a.priority - b.priority;
                    } else if (groupAds[parentData["type"]] === "weight") {
                        return a.weight - b.weight;
                    }
                    return 0;
                })
            );
        }
    }, [item, deletedSlotsAds]);

    useEffect(() => {
        if (innerTableEditId.length > 0 && accData.length > 0) {
            const actualIndex = accData.findIndex(
                obj => obj.innerRowId == innerTableEditId[0].innerRowId
            );
            let obj = accData[actualIndex];
            setEditWeight(get(obj, `${groupAds[parentData["type"]]}`, ""));
            setEditDealId(get(obj, "dealId", ""));

            let revenueValue = revenueList.filter(
                item => item.text == get(obj, "revenueType", null)
            );
            let creativeValue = creativesList.filter(
                item => item.key == get(obj, "creative", null)
            );

            let offerWall = offerWallList.filter(
                item => item.id == get(obj, "offerWall", null)
            );

            setEditCreative(get(creativeValue[0], "key", creativeValue[0]));
            setEditRevenueType(get(revenueValue[0], "key", revenueValue[0]));
            setEditOfferWall(get(offerWall[0], "id", offerWall[0]));
        }
    }, [innerTableEditId, accData]);

    const selectRevenueDropdown = data => {
        setRevenueType(get(data, "key", ""));
        setRevenueTypeError(false);
    };

    const selectOfferWallDropdown = data => {
        setOfferWall(get(data, "id", ""));
        setOfferWallError(false);
    };

    const selectCreativeDropdown = data => {
        setCreative(get(data, "key", null));
        setCreativeError(false);
    };

    const onEdit = data => {
        setInnerTableEditId([data]);
        setOfferKeysData(data.customKeyVal);
        setDealIdFailed(false);
        setOpenSideDrawer(true);
    };

    const cancelEdit = () => {
        setInnerTableEditId([]);
    };

    const onDeleteItem = obj => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        let itemIndex = selectedListItemsCopy.findIndex(
            element => element.rowId == get(item, "rowId", null)
        );

        if (urlParams.id && obj.id !== "-") {
            const params = {
                page: pageId,
                data: {
                    ad: obj,
                    type: selectedListItemsCopy[itemIndex].type,
                },
            };
            dispatch(action.setDeletedSlotAds(params));
        } else {
            let editCreativeData = get(
                selectedListItemsCopy,
                `${itemIndex}.${childPropName}`,
                []
            );
            let subHeaderIndex = editCreativeData.findIndex(
                element => element.innerRowId == obj.innerRowId
            );
            editCreativeData.splice(subHeaderIndex, 1);
            dispatch(
                action.addSelectedListItems({
                    page: pageId,
                    data: selectedListItemsCopy,
                })
            );
        }
    };

    const editDedupeRules = dedupeRules => {
        if (innerTableEditId.length > 0) {
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            let itemIndex = selectedListItemsCopy.findIndex(
                data => data.rowId == get(item, "rowId", null)
            );

            let actualIndex = selectedListItemsCopy[itemIndex][
                childPropName
            ].findIndex(
                element => element.innerRowId == innerTableEditId[0].innerRowId
            );

            selectedListItemsCopy[itemIndex][childPropName][actualIndex] = {
                ...selectedListItemsCopy[itemIndex][childPropName][actualIndex],
                isEdit: true,
                dedupeRules: dedupeRules,
            };
            dispatch(
                action.addSelectedListItems({
                    page: pageId,
                    data: selectedListItemsCopy,
                })
            );
        }
    };

    const updateItemForTable = item => {
        let updatedData = cloneDeep(item);
        updatedData?.customKeyVal?.forEach(_ => {
            if (_.dataType === "boolean") {
                _.value = _?.value?.toString() || "false";
            }
        });
        return updatedData;
    };

    const onAddSubheaderItem = (obj, id) => {
        if (innerTableEditId.length > 0) {
            if (editWeight != "") {
                let selectedListItemsCopy = JSON.parse(
                    JSON.stringify(get(data, "selectedListItems", []))
                );
                let itemIndex = selectedListItemsCopy.findIndex(
                    data => data.rowId == get(item, "rowId", null)
                );

                let actualIndex = selectedListItemsCopy[itemIndex][
                    childPropName
                ].findIndex(
                    element =>
                        element.innerRowId == innerTableEditId[0].innerRowId
                );

                let editWeightToString = editWeight.toString();
                const isValidKeyValue = checkKeyValue(offerKeysData, dispatch);
                if (isValidKeyValue) {
                    selectedListItemsCopy[itemIndex][childPropName][
                        actualIndex
                    ] = {
                        ...selectedListItemsCopy[itemIndex][childPropName][
                            actualIndex
                        ],
                        dealId: editDealId,
                        revenueType: editRevenueType,
                        offerWall: editOfferWall,
                        creative: editCreative,
                        [groupAds[parentData["type"]]]: editWeight,
                        isEdit: urlParams.id == null ? false : true,
                        customKeyVal: offerKeysData,
                    };

                    if (
                        !editWeightToString.includes(".") &&
                        !editWeightToString.includes("-")
                    ) {
                        dispatch(
                            action.setOfferPathSlotsError({
                                page: pageId,
                                data: false,
                            })
                        );
                        dispatch(
                            action.addSelectedListItems({
                                page: pageId,
                                data: selectedListItemsCopy,
                            })
                        );
                        dispatch(
                            action.setEditId({
                                page: pageId,
                                data: "",
                            })
                        );
                        setEditWeight("");
                        setEditDealId("");
                        setEditCreative(null);
                        setEditRevenueType(null);
                        setEditOfferWall(null);
                    } else {
                        if (
                            editWeight.includes(".") ||
                            editWeight.includes("-")
                        ) {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message: (
                                        <div>
                                            {groupAds[parentData["type"]] ? (
                                                <span className="capitalize">
                                                    {
                                                        groupAds[
                                                            parentData["type"]
                                                        ]
                                                    }
                                                </span>
                                            ) : (
                                                "Weight"
                                            )}{" "}
                                            must not be in{" "}
                                            {editWeight.includes(".")
                                                ? "decimal"
                                                : "Negative"}
                                        </div>
                                    ),
                                })
                            );
                        }
                    }
                } else return false;
            } else {
                if (editWeight == "" && editWeightRef.current)
                    editWeightRef.current.validate();
                if (editDealId == "" && editDealIdRef.current)
                    editDealIdRef.current.validate();
            }
        } else {
            if (
                weight != "" &&
                weight.indexOf(".") == -1 &&
                weight.indexOf("-") == -1 &&
                (dealId != "" || !fetchDealIdsApi) &&
                (creative != null || !creativesList.length) &&
                (revenueType != null || !revenueList.length)
            ) {
                let selectedListItemsCopy = JSON.parse(
                    JSON.stringify(get(data, "selectedListItems", []))
                );
                let itemIndex = selectedListItemsCopy.findIndex(
                    data => data.rowId == item.rowId
                );
                let editCreativeData = get(
                    selectedListItemsCopy,
                    `${itemIndex}.${childPropName}`,
                    []
                );
                const isValidKeyValue = checkKeyValue(offerKeysData, dispatch);
                if (isValidKeyValue) {
                    editCreativeData.push({
                        dealId: dealId,
                        [groupAds[parentData["type"]]]: weight,
                        revenueType: revenueType,
                        creative: creative,
                        offerWall: offerWall,
                        id: null ? "-" : "-",
                        innerRowId: getRandomId(),
                        customKeyVal: offerKeysData,
                    });
                    dispatch(
                        action.setOfferPathSlotsError({
                            page: pageId,
                            data: false,
                        })
                    );
                    dispatch(
                        action.addSelectedListItems({
                            page: pageId,
                            data: selectedListItemsCopy,
                        })
                    );
                } else return false;
            } else {
                if (weight == "" && weightRef.current)
                    weightRef.current.validate();
                if (dealId == "" && dealIDRef.current)
                    dealIDRef.current.validate();
                if (creative == null && revenueList.length)
                    setCreativeError(true);
                if (revenueType == null && revenueList.length)
                    setRevenueTypeError(true);

                if (weight.indexOf(".") !== -1 || weight.indexOf("-") !== -1) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: (
                                <div>
                                    {groupAds[parentData["type"]] ? (
                                        <span className="capitalize">
                                            {groupAds[parentData["type"]]}
                                        </span>
                                    ) : (
                                        "Weight"
                                    )}{" "}
                                    must not be in{" "}
                                    {weight.includes(".")
                                        ? "decimal"
                                        : "Negative"}
                                </div>
                            ),
                        })
                    );
                }
            }
        }
    };

    const dedupeColumn = item => {
        const readOnly = !modules.includes(
            `SurveyManager.OfferPath.Deduping.Write`
        );
        return (
            <span
                onClick={() => {
                    if (!readOnly) {
                        setInnerTableEditId([item]);
                        setOpenAddRuleSideBar(true);
                    }
                }}
                className={`${!readOnly ? "link" : ""}`}
            >
                {item.dedupeRules && item.dedupeRules.length
                    ? `${item.dedupeRules.length} Rule Added`
                    : readOnly
                    ? `-`
                    : `Add Rule`}
            </span>
        );
    };

    const cloneOffers = obj => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        let itemIndex = selectedListItemsCopy.findIndex(
            data => data.rowId == get(item, "rowId", null)
        );

        let editCreativeData = get(
            selectedListItemsCopy,
            `${itemIndex}.${childPropName}`,
            []
        );

        editCreativeData.push({
            ...obj,
            id: "-",
            innerRowId: getRandomId(),
        });

        dispatch(
            action.addSelectedListItems({
                page: pageId,
                data: selectedListItemsCopy,
            })
        );
        dispatch(
            toastListActions.setToastList({
                type: "Success",
                message: "Offer cloned successfully. Save to update.",
            })
        );
    };

    const groupAds = {
        WeightGroup: "weight",
        PriorityGroup: "priority",
        RtbGroup: "disposition",
    };

    const accordionColumns = [
        {
            text: "Id",
            key: "id",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                return item.id && item.id !== "-" ? (
                    <p>{item.id}</p>
                ) : (
                    <TagRenderer>New</TagRenderer>
                );
            },
        },
        ...[
            {
                text: "Offer Wall",
                key: "offerWall",
                CustomRender: ({ item }) => {
                    // intentional == inside to support old mappings in string
                    const ow = offerWallList.find(_ => _.id == item.offerWall);
                    return ow ? `${ow?.id}:${ow?.name}` : "-";
                },
            },
        ].filter(_ => offerWallList.length),
        {
            text: `${
                groupAds[parentData["type"]] != "weight"
                    ? groupAds[parentData["type"]]
                    : `${groupAds[parentData["type"]]}(%)`
            }`,
            key: groupAds[parentData["type"]],
            type: 0,
            disableCopyText: true,
            CustomRender: ({ item }) => {
                return item.weight
                    ? item.weight && item.weightPercent
                        ? `${item.weight} (${item.weightPercent.toFixed(2)}%)`
                        : item.weight
                    : item[groupAds[parentData["type"]]];
            },
        },
        ...[
            {
                text: "Deal Id",
                key: "dealId",
                type: 0,
                disableCopyText: true,
            },
        ].filter(_ => fetchDealIdsApi),
        ...[
            {
                text: "Select Revenue Type",
                key: "revenueType",
                type: 5,
                disableCopyText: true,
            },
        ].filter(_ => revenueList.length),
        ...[
            {
                text: "Select Creative",
                key: "creative",
                type: 5,
                CustomRender: ({ item }) => {
                    return (
                        <div className="dp-parent dp-parent-ver-center">
                            {creativesList?.some(
                                _ =>
                                    _.id ===
                                        parseInt(
                                            item?.creative?.split(":")[0]
                                        ) && _.isDeprecated
                            ) && (
                                <TagRenderer
                                    className={s.deprecateTag}
                                    type={"deprecated"}
                                >
                                    Deprecated
                                </TagRenderer>
                            )}
                            <span className="ml-5">{item?.creative}</span>
                        </div>
                    );
                },
            },
        ].filter(_ => creativesList.length),
        {
            text: "Key Value",
            key: "customKeyVal",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                let updatedItem = updateItemForTable(item);
                return (
                    <div>
                        {item?.customKeyVal?.length ? (
                            <CustomTooltipRenderer
                                position={"top"}
                                data={updatedItem?.customKeyVal}
                                customRenderer={() => (
                                    <Table
                                        data={updatedItem?.customKeyVal}
                                        columns={[
                                            {
                                                text: "Key",
                                                key: "key",
                                                disableCopyText: true,
                                            },
                                            {
                                                text: "Value",
                                                key: "value",
                                                disableCopyText: true,
                                            },
                                        ]}
                                    />
                                )}
                            >
                                {updatedItem.customKeyVal.length + " Added"}
                            </CustomTooltipRenderer>
                        ) : (
                            "N/A"
                        )}
                    </div>
                );
            },
        },
        ...[
            {
                text: "Deduping",
                key: "creative",
                disableCopyText: true,
                CustomRender: ({ item }) => {
                    return dedupeColumn(item);
                },
                type: 5,
            },
        ].filter(
            _ =>
                modules.indexOf(`SurveyManager.OfferPath.Deduping.Read`) > -1 &&
                enableDedupe
        ),
        {
            text: "",
            key: "",
            CustomRender: ({ item }) => {
                const additionalProps = historyInnerTableApi
                    ? {
                          historyCallback: obj => {
                              historyDataAction(
                                  historyInnerTableApi,
                                  obj,
                                  dispatch,
                                  action
                              );
                          },
                      }
                    : null;
                return (
                    <ActionButtons
                        item={item}
                        editCallback={onEdit}
                        isDeleted={
                            get(data, "deletedSlotAds", []).findIndex(
                                _ => _.ad.id === item.id
                            ) !== -1
                        }
                        revertCallback={obj => {
                            dispatch(
                                action.revertDeleteSlotAds({
                                    page: pageId,
                                    data: obj,
                                })
                            );
                        }}
                        deleteCallback={obj => {
                            onDeleteItem(obj);
                        }}
                        showHistoryIcon={
                            item.id && item.id !== "-" ? true : false
                        }
                        {...additionalProps}
                        readOnly={readOnly}
                        cloneCallback={cloneOffers}
                    />
                );
            },
            bulkActions: true,
        },
    ];

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (typeof setAccData == "function") {
            setAccData(arrayMove(accData, oldIndex, newIndex));
        }
    };

    const handleDealIdChange = e => {
        if (innerTableEditId.length === 0) {
            setDealId(e.dealName);
            setRevenueType(e.campaignBillingType?.toLowerCase());
        } else {
            setEditDealId(e.dealName);
            if (e.campaignBillingType?.toLowerCase() !== editRevenueType) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Warning",
                        autoClose: 3000,
                        message: `Revenue type changed from ${editRevenueType?.toUpperCase()} to ${
                            e.campaignBillingType
                        }`,
                    })
                );
                setEditRevenueType(e.campaignBillingType.toLowerCase());
            }
        }
    };

    return (
        <div className="accordionTableWrap">
            <div className={s.innerHeader}>
                <div
                    className={`dp-parent dp-parent-ver-center ${
                        accData.length === 0 ? s.noDataContainer : ""
                    }`}
                >
                    <span className="mr-10">
                        {accData.length > 0 &&
                            `${accData.length} Offer${
                                accData.length > 1 ? "s" : ""
                            } added`}
                    </span>
                    <div className={s.noDataGraphicsContainer}>
                        <img src={isDarkMode ? noDataImgDark : noDataImg} />
                        <div>Looks like no {entityName}s are added.</div>
                    </div>
                    {!readOnly && (
                        <Button
                            btnTheme={ButtonTypes.primaryHover_btn}
                            onClick={() => {
                                setOpenSideDrawer(true);
                                setOfferKeysData([]);
                            }}
                            className={`mr-10 ${
                                accData.length === 0 ? "mb-10" : ""
                            }`}
                        >
                            Add {entityName}
                        </Button>
                    )}
                    {showWarningMessage &&
                        accData.length > 0 &&
                        !hasOfferWallWithNoTargetingParams(
                            accData,
                            deletedSlotsAds,
                            offerWallList
                        ) && (
                            <InfoText theme={"info"}>
                                <div className={s.inlineTooltip}>
                                    Please select atleast 1{" "}
                                    <Tooltip
                                        message={
                                            "Offer wall with no targetting parameters to act as a fall back. "
                                        }
                                    >
                                        <div className="bold underline">
                                            Fall Back
                                        </div>
                                    </Tooltip>{" "}
                                    Offer Wall.
                                </div>
                            </InfoText>
                        )}
                </div>
                {accData.length > 0 && enableSwitch && (
                    <ToggleValueSwitch
                        onChange={responseType => {
                            analytics.logEvent("offers", {
                                action: "toggle table view",
                                value: responseType ? "table" : "grid",
                            });

                            localStorage.setItem(
                                "offersTableView",
                                responseType
                            );
                            setTableView(responseType);
                        }}
                        leftLabel={<Icon size="18px" icon="GridView" />}
                        rightLabel={<Icon size="18px" icon="ListView" />}
                        toggleState={enableSwitch ? tableView : true}
                        compact={SIZES.SMALL}
                        size={"small"}
                    ></ToggleValueSwitch>
                )}
            </div>
            {accData.length > 0 && (tableView || !enableSwitch) ? (
                <TableFlex
                    // until sort fixed
                    sortableRows={
                        false && groupAds[parentData["type"]] === "priority"
                    }
                    rowSortCallback={e => {
                        // e.forEach((item, index) => {
                        //     item.priority = index + 1;
                        // });
                    }}
                    columns={accordionColumns}
                    data={accData}
                    isPagination={false}
                    showBorder={true}
                    showFakeScroll={false}
                    editObject={innerTableEditId}
                    deletedIds={deletedSlotsAds.map(_ => _.ad.id)}
                    editObjectSelector={"innerRowId"}
                    config={tableConfig}
                    tableData={tableData}
                    stickyHeaderClass={"stickyTableNestedHead"}
                ></TableFlex>
            ) : (
                <SortableList onSortEnd={onSortEnd} useDragHandle>
                    {accData.map((item, index) => (
                        <SortableItem key={index} index={index}>
                            <li
                                key={index}
                                className={`${s.offerCardItem} ${
                                    get(data, "deletedSlotAds", []).findIndex(
                                        _ => _.ad.id === item.id
                                    ) !== -1
                                        ? s.deleted
                                        : ""
                                }`}
                            >
                                <div className={s.row}>
                                    <label>
                                        {groupAds[parentData["type"]] !==
                                        "weight"
                                            ? groupAds[parentData["type"]]
                                            : `${groupAds[parentData["type"]]}`}
                                        :{" "}
                                    </label>
                                    <span className={s.value}>
                                        {item.weight
                                            ? item.weight && item.weightPercent
                                                ? `${
                                                      item.weight
                                                  } (${item.weightPercent.toFixed(
                                                      2
                                                  )}%)`
                                                : item.weight
                                            : item[
                                                  groupAds[parentData["type"]]
                                              ]}
                                    </span>
                                    <div className={s.offerCardActionBar}>
                                        <ActionButtons
                                            item={item}
                                            editCallback={onEdit}
                                            isDeleted={
                                                get(
                                                    data,
                                                    "deletedSlotAds",
                                                    []
                                                ).findIndex(
                                                    _ => _.ad.id === item.id
                                                ) !== -1
                                            }
                                            revertCallback={obj => {
                                                dispatch(
                                                    action.revertDeleteSlotAds({
                                                        page: pageId,
                                                        data: obj,
                                                    })
                                                );
                                            }}
                                            deleteCallback={obj => {
                                                onDeleteItem(obj);
                                            }}
                                            showHistoryIcon={
                                                item.id &&
                                                item.id !== "-" &&
                                                showOffersHistoryIcon
                                                    ? true
                                                    : false
                                            }
                                            historyCallback={obj => {
                                                historyDataAction(
                                                    historyInnerTableApi,
                                                    obj,
                                                    dispatch,
                                                    action
                                                );
                                            }}
                                            cloneCallback={cloneOffers}
                                            readOnly={readOnly}
                                        />
                                        {/* Until fixed */}
                                        {/* <DragHandle></DragHandle> */}
                                    </div>
                                </div>
                                <div className={s.row}>
                                    <label>ID: </label>
                                    <span className={s.value}>
                                        {item.id && item.id !== "-" ? (
                                            item.id
                                        ) : (
                                            <TagRenderer>New</TagRenderer>
                                        )}
                                    </span>
                                </div>
                                <div className={s.row}>
                                    <label>Deal ID: </label>
                                    <span className={s.value}>
                                        {item.dealId}
                                    </span>
                                </div>
                                <div className={s.row}>
                                    <label>Revenue Type: </label>
                                    <span className={s.value}>
                                        {item.revenueType}
                                    </span>
                                </div>
                                <div className={s.row}>
                                    <label>Creative: </label>
                                    <>
                                        {creativesList?.some(
                                            _ =>
                                                _.id ===
                                                    parseInt(
                                                        item?.creative?.split(
                                                            ":"
                                                        )[0]
                                                    ) && _.isDeprecated
                                        ) && (
                                            <TagRenderer
                                                className={s.deprecateTag}
                                                type={"deprecated"}
                                            >
                                                Deprecated
                                            </TagRenderer>
                                        )}
                                        <span className={`${s.value}  ml-5`}>
                                            {item.creative}
                                        </span>
                                    </>
                                </div>
                                {modules.indexOf(
                                    `SurveyManager.OfferPath.Deduping.Read`
                                ) > -1 &&
                                    enableDedupe && (
                                        <div className={s.row}>
                                            <label>Dedupe: </label>
                                            <span className={s.value}>
                                                {dedupeColumn(item)}
                                            </span>
                                        </div>
                                    )}

                                <CreativeRender
                                    height={125}
                                    id={item.creative.split(":")[0]}
                                ></CreativeRender>
                            </li>
                        </SortableItem>
                    ))}
                </SortableList>
            )}

            {openAddRuleSideBar && innerTableEditId.length ? (
                <Popup
                    bodyMaxHeight="calc(100vh - 65px)"
                    title="Add Dedupe"
                    headerPadding={"20px"}
                    showOverlay
                    width="640px"
                    showFooter={false}
                    closeOutside={false}
                    onClose={() => {
                        cancelEdit();
                        setOpenAddRuleSideBar(false);
                    }}
                >
                    <AddDedupeRule
                        actionMacros={OfferActions}
                        prefix={"offer"}
                        dedupeRules={innerTableEditId[0]?.dedupeRules}
                        onSave={dedupeRules => {
                            setOpenAddRuleSideBar(false);
                            editDedupeRules(dedupeRules);
                        }}
                        onCancel={() => {
                            cancelEdit();
                            setOpenAddRuleSideBar(false);
                        }}
                        readOnly={
                            modules.indexOf(
                                `SurveyManager.OfferPath.Deduping.Write`
                            ) === -1
                        }
                        entityMacros={OfferEntityMacros}
                        featureCopyToolbarKeys={featureCopyToolbarKeys}
                        PIMacrosArray={PIMacrosSlotLevel}
                    ></AddDedupeRule>
                </Popup>
            ) : null}

            {openSideDrawer ? (
                <Popup
                    bodyMaxHeight="calc(100vh - 65px)"
                    title={`${
                        innerTableEditId.length ? "Edit Offer" : "Add Offer"
                    }`}
                    showOverlay
                    width="640px"
                    showFooter={false}
                    onSave={() => {}}
                    onClose={() => {
                        cancelEdit();
                        setDealIdList([]);
                        setOpenSideDrawer(false);
                    }}
                    closeOutside={false}
                >
                    <div className={s.editContainer}>
                        <div className={s.body}>
                            <div className={`row`}>
                                <p className={`headerTitleRequired capitalize`}>
                                    {groupAds[parentData["type"]] != "Weight"
                                        ? groupAds[parentData["type"]]
                                        : `${groupAds[parentData["type"]]}`}
                                    *
                                </p>
                                <p className={`infoText`}>Weight</p>
                                <Input
                                    ref={
                                        innerTableEditId.length === 0
                                            ? weightRef
                                            : editWeightRef
                                    }
                                    type="number"
                                    value={
                                        innerTableEditId.length === 0
                                            ? weight
                                            : editWeight
                                    }
                                    placeholder={`Enter ${
                                        groupAds[parentData["type"]]
                                    }`}
                                    onChange={e => {
                                        if (innerTableEditId.length === 0) {
                                            setWeight(e.target.value);
                                        } else {
                                            setEditWeight(e.target.value);
                                        }
                                    }}
                                    required={true}
                                    disabled={readOnly}
                                />
                            </div>
                            {offerWallList.length > 0 && (
                                <div className={`row`}>
                                    <p className={`headerTitleRequired`}>
                                        Offer Wall*
                                    </p>
                                    <p className={`infoText`}>Offer Wall</p>

                                    <Select
                                        label={"Select Offer Wall"}
                                        options={offerWallList}
                                        selectedId={
                                            innerTableEditId.length === 0
                                                ? []
                                                : editOfferWall
                                        }
                                        keyField="name"
                                        portal={false}
                                        CustomRender={OfferWallSelectOption}
                                        onSelect={data => {
                                            if (innerTableEditId.length === 0) {
                                                selectOfferWallDropdown(data);
                                            } else {
                                                setEditOfferWall(
                                                    get(data, "id", "")
                                                );
                                            }
                                        }}
                                        idField={"id"}
                                        defaultOptionHeight={60}
                                        isError={offerWallError}
                                        onLoadSelectReturn={false}
                                        isDisabled={readOnly}
                                    />
                                </div>
                            )}
                            {fetchDealIdsApi && (
                                <div className={`row`}>
                                    <div className="flex">
                                        <div className="dp-child-50 mr-10">
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                Advertiser
                                            </p>
                                            <p className={`infoText`}>
                                                Advertiser
                                            </p>
                                        </div>
                                        <div className="dp-child-50">
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                Deal Id*
                                            </p>
                                            <p className={`infoText`}>
                                                Deal Id
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="dp-child-50 mr-10">
                                            <Select
                                                label={"Select Advertiser"}
                                                options={advertiserList}
                                                keyField="name"
                                                portal={false}
                                                onSelect={e => {
                                                    setLoader(true);
                                                    setDealIdList([]);
                                                    setDealIdFailed(false);
                                                    dispatch(
                                                        fetchDealIdsApi(e.id)
                                                    )
                                                        .unwrap()
                                                        .then(res => {
                                                            setDealIdList(
                                                                res.data
                                                            );
                                                            setLoader(false);

                                                            if (
                                                                res?.data
                                                                    ?.length ==
                                                                    0 &&
                                                                res?.status >=
                                                                    400
                                                            ) {
                                                                setDealIdFailed(
                                                                    true
                                                                );

                                                                dispatch(
                                                                    toastListActions.setToastList(
                                                                        {
                                                                            type: "Error",
                                                                            message:
                                                                                "Failed to fetch deal id api please enter manually",
                                                                        }
                                                                    )
                                                                );
                                                            }
                                                        });
                                                }}
                                                idField="key"
                                                onLoadSelectReturn={false}
                                                isDisabled={readOnly}
                                            />
                                        </div>
                                        <div className="dp-child-50">
                                            {dealIdList.length ? (
                                                <Select
                                                    label={"Select Deal ID"}
                                                    options={dealIdList}
                                                    keyField={"dealName"}
                                                    portal={false}
                                                    CustomRender={
                                                        ProfileSelectOption
                                                    }
                                                    onSelect={
                                                        handleDealIdChange
                                                    }
                                                    idField="dealName"
                                                    onLoadSelectReturn={false}
                                                    isError={false}
                                                    isDisabled={readOnly}
                                                    defaultOptionHeight={60}
                                                    isSelectOpen={true}
                                                />
                                            ) : dealIdFailed ? (
                                                <Input
                                                    value={
                                                        innerTableEditId.length ===
                                                        0
                                                            ? dealId
                                                            : editDealId
                                                    }
                                                    placeholder="Enter Deal Id"
                                                    required={true}
                                                    onChange={e => {
                                                        if (
                                                            innerTableEditId.length >
                                                            0
                                                        ) {
                                                            setEditDealId(
                                                                e.target.value
                                                            );
                                                        } else {
                                                            setDealId(
                                                                e.target.value
                                                            );
                                                        }
                                                    }}
                                                    // disabled={readOnly}
                                                />
                                            ) : (
                                                <Tooltip
                                                    message={
                                                        "To change deal Id, please select its Advertiser first."
                                                    }
                                                >
                                                    <div
                                                        className={`${
                                                            s.labelDealId
                                                        } ${
                                                            !(innerTableEditId.length ===
                                                            0
                                                                ? dealId
                                                                : editDealId)
                                                                ? s.placeholder
                                                                : ""
                                                        }`}
                                                    >
                                                        {loader && (
                                                            <LinearDeterminate
                                                                customClass={
                                                                    s.loader
                                                                }
                                                            />
                                                        )}
                                                        {innerTableEditId.length ===
                                                        0
                                                            ? dealId ||
                                                              "Select Deal Id"
                                                            : editDealId ||
                                                              "Select Deal Id"}
                                                    </div>
                                                </Tooltip>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {revenueList.length > 0 && (
                                <div className={`row`}>
                                    <p className={`headerTitleRequired`}>
                                        Revenue Type*
                                    </p>
                                    <p className={`infoText`}>Revenue Type</p>

                                    <Select
                                        label={"Select Revenue Type"}
                                        options={revenueList}
                                        selectedId={
                                            innerTableEditId.length === 0
                                                ? revenueType
                                                : editRevenueType
                                        }
                                        keyField="text"
                                        portal={false}
                                        onSelect={data => {
                                            if (innerTableEditId.length === 0) {
                                                selectRevenueDropdown(data);
                                            } else {
                                                setEditRevenueType(
                                                    get(data, "key", "")
                                                );
                                            }
                                        }}
                                        idField={"key"}
                                        isError={revenueTypeError}
                                        onLoadSelectReturn={false}
                                        isDisabled={readOnly}
                                    />
                                </div>
                            )}
                            {creativesList.length > 0 && (
                                <div className={`row`}>
                                    <p className={`headerTitleRequired`}>
                                        Creative*
                                    </p>
                                    <p className={`infoText`}>Creative</p>
                                    {creativesList?.some(
                                        _ =>
                                            _.id ===
                                                parseInt(
                                                    editCreative?.split(":")[0]
                                                ) && !_.isDeprecated
                                    ) || editCreative === null ? (
                                        <Select
                                            label={"Select Creative"}
                                            options={creativesList?.filter(
                                                _ => !_.isDeprecated
                                            )}
                                            keyField={"key"}
                                            portal={false}
                                            selectedId={
                                                innerTableEditId.length === 0
                                                    ? null
                                                    : editCreative?.split(
                                                          ":"
                                                      )[0]
                                                    ? parseInt(
                                                          editCreative?.split(
                                                              ":"
                                                          )[0]
                                                      )
                                                    : null
                                            }
                                            onSelect={data => {
                                                setSelectOpenOnChange(false);
                                                if (
                                                    innerTableEditId?.length ===
                                                    0
                                                ) {
                                                    selectCreativeDropdown(
                                                        data
                                                    );
                                                } else {
                                                    setEditCreative(
                                                        get(data, "key", "")
                                                    );
                                                }
                                            }}
                                            isError={creativeError}
                                            idField={"id"}
                                            onLoadSelectReturn={false}
                                            isDisabled={readOnly}
                                            isSelectOpen={selectOpenOnChange}
                                        />
                                    ) : (
                                        <>
                                            <DummySelect
                                                value={editCreative}
                                                onClick={() => {
                                                    setSelectOpenOnChange(true);
                                                    selectCreativeDropdown(
                                                        null
                                                    );
                                                    setCreative(null);
                                                    setEditCreative(null);
                                                }}
                                            />
                                        </>
                                    )}
                                    <div
                                        className={`mt-20 p-20 ${s.creativeWrap}`}
                                    >
                                        {innerTableEditId.length === 0
                                            ? creative && (
                                                  <CreativeRender
                                                      id={
                                                          creative?.split(
                                                              ":"
                                                          )[0]
                                                      }
                                                  ></CreativeRender>
                                              )
                                            : editCreative && (
                                                  <CreativeRender
                                                      id={
                                                          editCreative?.split(
                                                              ":"
                                                          )[0]
                                                      }
                                                  ></CreativeRender>
                                              )}
                                    </div>
                                </div>
                            )}
                            <div className={s.keyHeading}>Key Value</div>
                            <div style={{ position: "relative" }}>
                                <Button
                                    btnTheme={ButtonTypes.primaryHover_btn}
                                    onClick={() => setShowOfferKeySelect(true)}
                                    disable={
                                        readOnly ||
                                        offerKeysData?.length ===
                                            offerPathKeyList?.length
                                    }
                                >
                                    Add Key Value
                                </Button>
                                {showOfferKeySelect && (
                                    <div className={s.dropdownWrap}>
                                        <ReactSelect
                                            showSelectAll
                                            isMulti
                                            maxMenuHeight={200}
                                            menuIsOpen
                                            options={offerPathKeyList}
                                            listSelector={"key"}
                                            searchIdField={"key"}
                                            selectedIds={
                                                offerKeysData?.map(
                                                    _ => _.key
                                                ) || []
                                            }
                                            onCancelHandler={e => {
                                                setShowOfferKeySelect(false);
                                            }}
                                            customOnSelect={items => {
                                                const obj = {};
                                                offerKeysData.forEach(_ => {
                                                    obj[_.id] = _;
                                                });
                                                let initialData =
                                                    cloneDeep(offerKeysData);
                                                let mergedArray = mergeByKey(
                                                    items?.map(_ => ({
                                                        ..._,
                                                        ...obj[_.id],
                                                    })) || [],
                                                    initialData || []
                                                );
                                                setOfferKeysData(mergedArray);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                            {offerKeysData?.length
                                ? offerKeysData?.map(_ => {
                                      return (
                                          <div
                                              key={_.key}
                                              className={`${s.keyValWrap} mt-20 p-10`}
                                          >
                                              <div
                                                  className={`${s.deleteIcon} circleIconColor circleIconColor--delete`}
                                              >
                                                  <Tooltip
                                                      message="Delete"
                                                      position="top"
                                                  >
                                                      <Icon
                                                          icon="Delete"
                                                          size="18px"
                                                          enableHover={true}
                                                          onClick={() =>
                                                              setMappingDeletePopup(
                                                                  _.key
                                                              )
                                                          }
                                                      />
                                                  </Tooltip>
                                              </div>
                                              {mappingDeletePopup === _.key && (
                                                  <div
                                                      className={s.deletePopup}
                                                  >
                                                      <PopupTargettingParams
                                                          setShowPopup={() =>
                                                              setMappingDeletePopup(
                                                                  false
                                                              )
                                                          }
                                                          Rfunction={() =>
                                                              setOfferKeysData(
                                                                  offerKeysData.filter(
                                                                      o =>
                                                                          o.key !==
                                                                          _.key
                                                                  )
                                                              )
                                                          }
                                                          customText={`This will delete this mapping. Do you wish to proceed?`}
                                                          readOnly={readOnly}
                                                      />
                                                  </div>
                                              )}
                                              <div className="mb-10">
                                                  <span className="headerTitleRequired">
                                                      Key:
                                                  </span>{" "}
                                                  {_.key}
                                              </div>

                                              <DisplayDataType
                                                  element={{
                                                      ..._,
                                                      type: _.dataType,
                                                      data: _.value,
                                                  }}
                                                  hideDesc
                                                  customOnChange={(
                                                      data,
                                                      val
                                                  ) => {
                                                      _.value = val;
                                                  }}
                                              />
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                        <div className={s.footer}>
                            <Button
                                btnTheme={ButtonTypes.default_btn}
                                onClick={() => {
                                    cancelEdit();
                                    setDealIdList([]);
                                    setOpenSideDrawer(false);
                                    setShowOfferKeySelect(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                btnTheme={ButtonTypes.primary_btn}
                                onClick={() => onAddSubheaderItem()}
                                disable={readOnly}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                </Popup>
            ) : null}
        </div>
    );
};

const SortableList = SortableContainer(({ children }) => {
    return <ul className={s.offerCardContainer}>{children}</ul>;
});

const SortableItem = SortableElement(props => props.children);

const DragHandle = SortableHandle(() => (
    <div className={`${s.dragIcon} circleIconColor circleIconColor--darkHover`}>
        <Icon icon="Drag" size="18px" />
    </div>
));
const hasOfferWallWithNoTargetingParams = (
    item,
    deletedSlotAds,
    offerWallList
) => {
    return (
        item
            .filter(ow => {
                return deletedSlotAds.findIndex(_ => _.ad.id === ow.id) === -1;
            })
            .findIndex(_ => {
                const targetParams = offerWallList.find(
                    ow => _.offerWall === ow.id
                )?.targetParams;
                if (targetParams) {
                    const temp = Object.keys(targetParams).reduce((p, _) => {
                        if (targetParams[_]?.length) {
                            p.push(_);
                        }
                        return p;
                    }, []);

                    return temp.length === 0;
                }
                return true;
            }) > -1
    );
};
const ProfileSelectOption = ({ option }) => {
    return (
        <div className={s.dealIdDropdownWrapper}>
            <div className={s.dealNameLabel}>
                <StatusRenderer status={option.campaignStatus} noText={true} />
                <span>{option.dealName}</span>
            </div>
            <div className={s.multiValue}>
                <span>{option.campaignName}</span>
            </div>
        </div>
    );
};

const OfferWallSelectOption = ({ option }) => {
    return (
        <div className={s.dealIdDropdownWrapper}>
            <div className={s.dealNameLabel}>
                <span>
                    {option.id}:{option.name}
                </span>
            </div>
            <div className={s.multiValue}>
                <Icon
                    size="18px"
                    icon="TargettingParams"
                    enableHover={true}
                    className="mr-5"
                />{" "}
                <span className="capitalize">
                    {activeTargetingParams(option.targetParams)}
                </span>
            </div>
        </div>
    );
};

const activeTargetingParams = targetParams => {
    const temp = Object.keys(targetParams).reduce((p, _) => {
        if (targetParams[_]?.length) {
            p.push(_);
        }
        return p;
    }, []);

    // const temp = Object.keys(targetParams)
    //     .filter(_ => targetParams[_]?.length)
    //     .map(
    //         _ => ({
    //             key: _,
    //             value: targetParams[_].join(", "),
    //         }),
    //         []
    //     );

    if (temp.length) {
        return `${temp[0]} ${temp.length > 1 ? `+${temp.length - 1}` : ""}`;
        // return (
        //     <CustomTooltipRenderer
        //         customRenderer={() => {
        //             return <span>test</span>;
        //         }}
        //         data={temp}
        //     >
        //         {temp[0]} {temp.length > 1 ? `+${temp.length - 1}` : ""}
        //     </CustomTooltipRenderer>
        // );
        // return (
        //     <ConfigRenderer
        //         item={{
        //             child: temp,
        //         }}
        //         colName1={"key"}
        //         colName2={"value"}
        //         headerTitle1={"Targeting"}
        //         headerTitle2={"Value"}
        //         arrayKeyName={"child"}
        //         firstRenderData={"normal"}
        //         eachRowDynamicClass={"rowId"}
        //         centreAlign={true}
        //     />
        // );
    }
    return "N/A";
};

export default OfferPathSlotsInnerTable;
