import React from "react";
import s from "./SelectInWrapper.module.scss";
import Select from "../../../Shared/Select/Select";

const SelectInWrapper = ({
    list,
    keyField,
    idField,
    multiSelect,
    placeholder,
    portal,
    isSelectOpen,
    onSelectClose,
    onSelect,
    selectedIds,
    selectedData,
}) => {
    return (
        <div className={s.customSelect}>
            <Select
                options={list}
                keyField={keyField}
                idField={idField}
                multiSelect={multiSelect}
                placeholder={placeholder}
                isSelectOpen={isSelectOpen}
                onSelectClose={() => onSelectClose(false)}
                onSelect={item => onSelect(item, selectedData)}
                selectedIds={selectedIds}
                portal={false}
                hideLabel={true}
                hideOutline={true}
            />
        </div>
    );
};

export default SelectInWrapper;
