import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { HNP_URL } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.CATEGORIES.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.CATEGORIES.filterOperator,
        filtersOperator: FILTERS.CATEGORIES.operator,
        filters: [],
    },
    createCategory: {
        name: "",
        desc: "",
        loading: false,
        mainLoader: false,
        showPrompt: true,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "categories"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const getHnpCategoryData = createAsyncThunk(
    "listings/categories/getHnpCategoryData",
    async (id, { getState, dispatch }) => {
        dispatch(categoriesActions.setLoading(true));
        const response = await customFetch(
            `${HNP_URL.GET_CATEGORY_DATA}/${id}`
        );
        const data = get(response, "data.data", {});
        if (!isEmpty(data))
            dispatch(categoriesActions.setName(get(data, "name", "")));
        else
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch hnp Category Data!",
                    autoClose: 5000,
                })
            );
        dispatch(categoriesActions.setLoading(false));
    }
);

export const createCategory = createAsyncThunk(
    "listings/categories/createCategory",
    async (data, { getState, dispatch }) => {
        dispatch(categoriesActions.setMainLoader(true));
        const response = await customFetch(
            HNP_URL.CREATE_HNP_CATEGORY,
            "POST",
            data
        );
        if (response && get(response, "data.success", false)) {
            dispatch(categoriesActions.setMainLoader(false));
            dispatch(categoriesActions.setShowPrompt(false));
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Created Lead Category Successfully",
                    autoClose: 5000,
                })
            );
        }
        if (!response || !response.data || !response.data.success)
            dispatch(categoriesActions.setMainLoader(false));
        return response;
    }
);

export const updateCategory = createAsyncThunk(
    "listings/categories/updateCategory",
    async ({ data, id }, { getState, dispatch }) => {
        dispatch(categoriesActions.setMainLoader(true));
        const response = await customFetch(
            `${HNP_URL.CREATE_HNP_CATEGORY}/${id}`,
            "PUT",
            data
        );
        if (response && get(response, "data.success", false)) {
            dispatch(categoriesActions.setMainLoader(false));
            dispatch(categoriesActions.setShowPrompt(false));
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Updated Lead Category Successfully",
                })
            );
        }
        return response;
    }
);

export const fetchCategoriesData = createAsyncThunk(
    "listings/categories/fetchCategoriesListingData",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "categories.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "categories.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.CATEGORIES
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(categoriesSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.categories.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.CATEGORIES,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.categories.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                HNP_URL.FETCH_CATEGORIES_LIST,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch hnp categories",
                        })
                    );
                    dispatch(
                        categoriesSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(categoriesActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.CATEGORIES}`;
            dispatch(categoriesSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Category`;
            dispatch(categoriesSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const deleteCategory = createAsyncThunk(
    "listings/leadDefinition/deleteCategory",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${HNP_URL.DELETE_HNP_CATEGORY}/${id}`,
            "DELETE"
        );
        if (get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Successfully deleted the category with id - ${id}`,
                })
            );
            dispatch(fetchCategoriesData());
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete the category with id - ${id}`,
                })
            );
        }
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/categories/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.categories.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.CATEGORIES.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const categoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        //create category actions
        setName(state, action) {
            state.createCategory.name = action.payload;
        },
        setDesc(state, action) {
            state.createCategory.desc = action.payload;
        },
        setShowPrompt(state, action) {
            state.createCategory.showPrompt = action.payload;
        },
        setLoading(state, action) {
            state.createCategory.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.createCategory.mainLoader = action.payload;
        },
        resetData(state, action) {
            state.createCategory = initialState.createCategory;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCategoriesData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchCategoriesData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const categoriesActions = categoriesSlice.actions;
export default categoriesSlice.reducer;
