import React, {
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
    useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import Card from "../../Shared/Card/Card";
import Input from "../../Shared/Input/Input";
import s from "./CreateContainer.module.scss";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import { get, isEmpty } from "lodash";
import {
    containerActions,
    createUpdateContainer,
    fetchContainer,
    fetchContainerHistory,
    fetchDefaultContainerLayout,
} from "../../store/flowmanager/container";
import { fetchAllTemplate } from "../../store/flowmanager/template";
import {
    cloneCollection,
    collectionActions,
    fetchAllCollection,
    fetchAllHnpCategories,
    fetchCollectionById,
} from "../../store/flowmanager/collection";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { toastListActions } from "../../store/toastList/toastList";
import Select from "../../Shared/Select/Select";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import Icon from "../../Shared/Icon/Icon";
import TreeViewComponent from "../../components/TreeView/TreeView";
import CreateCollection from "../CreateCollection/CreateCollection";
import fetchAll from "../../fetch/fetchAll";
import { collectionSelectList } from "../../utils/collectionUtils";
import { fetchAllLayout } from "../../store/flowmanager/layout";
import LayoutEditComp from "../../components/LayoutEditComp/LayoutEditComp";
import { dependencyApi, getPreviewObj } from "../../utils/globalUtils";
import { useQueryClient } from "react-query";
import ConfirmAndSave from "../../components/ConfirmAndSave/ConfirmAndSave";
import { containerConfirmConfig } from "../../constants/confirmSaveConstants";
import { getComparisonData } from "../../utils/historyPopUpUtils";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import History from "../../components/History/History";
import { containerConfig } from "../../constants/historyConstants";
import { reactQueryApiCall } from "../../generalApi/generalApi";
import { TEMPLATE } from "../../constants/url";
import { SITE_CONFIG } from "../../constants/url";

const CreateContainer = forwardRef(
    (
        {
            showInPopup = false,
            editPhaseInPopUp = false,
            createPhaseInPopup = false,
            id,
            isClonePanel,
            selectedFlowDomain = null,
            isTableView,
            collectionUpdated = () => {},
            parentFeatureGroupProperties = {},
        },
        ref
    ) => {
        // description is currently disabled coz desc is not implemented on api side, uncomment all description code once api get implemnted
        const dispatch = useDispatch();
        const isCreate =
            window.location.pathname.split("/")[3] === "create" &&
            window.location.pathname.split("/")[2] !== "flow";

        const isCreateContainer =
            (window.location.pathname.split("/")[3] === "create" &&
                window.location.pathname.split("/")[2] !== "flow") ||
            createPhaseInPopup;
        const {
            vertical,
            name,
            type,
            template,
            collections,
            loading,
            mainLoader,
            showPrompt,
            outerLayout,
            outerLayoutCopy,
            layout,
            layoutCopy,
            collectionItem,
            defaultLayout,
            selectedCollectionItems,
            editPhaseVerticalNotSelected,
            featureGroupProperties,
        } = useSelector(state => state.flowmanager.container.createContainer);

        const { loading: collectionLoader, createCollection } = useSelector(
            state => state.flowmanager.collection
        );
        const createContainerState = useSelector(
            state => state.flowmanager.container.createContainer
        );
        const createContainerBackupState = useSelector(
            state => state.flowmanager.container.createContainerBackup
        );

        const createTemplateState = useSelector(
            state => state.flowmanager.template.createTemplate
        );

        const { historyData, historyDataRaw } = useSelector(
            state => state.flowmanager.container
        );

        const urlParams = useParams();
        const history = useHistory();
        const queryClient = useQueryClient();
        const containerRef = useRef(null);

        const saveConfirmCallbacks = useRef(null);

        const collectionOpenRef = useRef(null);
        const collectionSaveFunctionCallRef = useRef(null);

        const layoutOpenRef = useRef(null);

        const layoutCompRef = useRef(null);

        const [verticalError, setVerticalError] = useState(false);
        const [typeError, setTypeError] = useState(false);
        const [collectionsError, setCollectionsError] = useState(false);
        const [containerLayoutError, setContainerLayoutError] = useState(false);

        const [showSaveConfirm, setShowSaveConfirm] = useState(false);
        const [templateDropdownData, setTemplateDropdownData] = useState([]);
        const [collectionDropdownData, setCollectionDropdownData] = useState(
            []
        );
        const [collectionDropdownTypeData, setCollectionDropdownTypeData] =
            useState([]);
        const [layoutDropdownData, setLayoutDropdownData] = useState([]);

        const [layoutDropdownTypeData, setLayoutDropdownTypeData] = useState(
            []
        );

        const [outerLayoutDropdownTypeData, setOuterLayoutDropdownTypeData] =
            useState([]);

        const [individualLayoutData, setIndividualLayoutData] = useState([
            layout,
        ]);

        const [layoutDefaultConfig, setLayoutDefaultConfig] = useState([]);
        const [addConfigList, setAddConfigList] = useState([]);
        const [showConfigSelect, setShowConfigSelect] = useState(false);

        const [viewIsClickCollection, setViewIsClickCollection] =
            useState(false);
        const [createPhasePopupCollection, setCreatePhasePopupCollection] =
            useState(false);
        const [collectionCloneObj, setCollectionCloneObj] = useState(null);

        const [previewItem, setPreviewItem] = useState({});
        const [formChanges, setFormChanges] = useState(false);
        const [shouldCloneTemplate, setShouldCloneTemplate] = useState(true);
        const [shouldCloneCollection, setShouldCloneCollection] =
            useState(true);

        // state for container level layout
        const [
            individualContainerLayoutData,
            setIndividualContainerLayoutData,
        ] = useState([outerLayout]);
        const [containerLayoutDefaultConfig, setContainerLayoutDefaultConfig] =
            useState([]);
        const [addConfigListContainer, setAddConfigListContainer] = useState(
            []
        );
        const [showConfigSelectContainer, setShowConfigSelectContainer] =
            useState(false);

        const [isContainerLayoutActive, setIsContainerLayoutActive] =
            useState(false);

        const [containerLevelLayout, setContainerLevelLayout] = useState(false);
        const [dropdownListLoader, setDropdownListLoader] = useState(false);

        const [liveAccess, setLiveAccess] = useState(false);

        const authUser = useSelector(state => state.auth.user);
        const isReadOnly =
            !authUser.modules.includes(`FlowManager.Container.Write`) ||
            isClonePanel ||
            isTableView;

        const writeAccessAvailable = authUser.modules.includes(
            `FlowManager.Flow.LiveAccess.Read`
        );

        let cancelLayoutEdit = true;

        const { apiData: apiSiteConfigData, isLoading: siteConfigLoader } =
            reactQueryApiCall("siteConfigListData", "SiteConfig List", {
                url: SITE_CONFIG.GET_ALL_CONFIGS,
                method: "POST",
            });

        const siteConfigList = useMemo(() => {
            const verticalList = Array.from(
                new Set(apiSiteConfigData?.data?.map(a => a.vertical))
            ).map(ele => {
                return {
                    name: ele.charAt(0).toUpperCase() + ele.slice(1),
                    id: ele,
                };
            });
            return verticalList || [];
        }, [apiSiteConfigData]);

        useEffect(() => {
            if (writeAccessAvailable || isCreateContainer) {
                setLiveAccess(true);
            }
            if (!writeAccessAvailable && createContainerState.id) {
                dependencyApi("CONTAINER", createContainerState.id, () => {
                    setLiveAccess(false);
                }).then(res => {
                    const dependencyList = res?.data?.data?.dependencyList;
                    if (!isEmpty(dependencyList)) {
                        setLiveAccess(
                            !dependencyList?.FLOW.some(_ => _.isLive === true)
                        );
                    } else {
                        setLiveAccess(true);
                    }
                });
            }
        }, [writeAccessAvailable, createContainerState]);

        useEffect(() => {
            setFormChanges(
                getComparisonData(
                    createContainerState,
                    createContainerBackupState,
                    containerConfirmConfig,
                    true
                )[0]?.length > 0 || false
            );
        }, [createContainerState]);

        useEffect(() => {
            setIndividualLayoutData([layout]);
            setIndividualContainerLayoutData([outerLayout]);
        }, [layout, outerLayout, containerLevelLayout]);

        useEffect(() => {
            if (!isEmpty(collectionItem)) {
                const data = getPreviewObj(
                    collectionItem,
                    type,
                    outerLayout,
                    featureGroupProperties
                );
                setPreviewItem(data);
            }
        }, [collectionItem, outerLayout, featureGroupProperties]);

        useEffect(() => {
            if (type !== "" && collectionDropdownData?.length) {
                setCollectionDropdownTypeData(
                    collectionDropdownData.filter(
                        (obj, idx) =>
                            (obj.containerType === type &&
                                obj.vertical === vertical) ||
                            obj.id === collections
                    )
                );
            }

            if (type !== "" && layoutDropdownData?.length) {
                const containerLayout = layoutDropdownData.filter(
                    (obj, idx) => obj.type === type && obj.subType === "OUTER"
                );
                const fallBackLayout = layoutDropdownData.filter(
                    (obj, idx) => obj.type === type && obj.subType === "INNER"
                );
                setLayoutDropdownTypeData(fallBackLayout);
                setOuterLayoutDropdownTypeData(containerLayout);
                if (!editPhaseInPopUp && !urlParams?.id) {
                    if (vertical !== "") {
                        applyDefaultLayout(containerLayout, type, vertical);
                    } else {
                        dispatch(containerActions?.setOuterLayout(""));
                        dispatch(containerActions?.setOuterLayoutCopy(""));
                    }
                }
            }
        }, [type, collectionDropdownData, layoutDropdownData, vertical]);

        useEffect(() => {
            setAddConfigList(
                layoutDefaultConfig?.filter(
                    _ =>
                        !individualLayoutData[0]?.configurations
                            ?.map(_ => _.key)
                            .includes(_.key)
                )
            );
            setAddConfigListContainer(
                containerLayoutDefaultConfig?.filter(
                    _ =>
                        !individualContainerLayoutData[0]?.configurations
                            ?.map(_ => _.key)
                            .includes(_.key)
                )
            );
        }, [
            layoutDefaultConfig,
            individualLayoutData,
            containerLayoutDefaultConfig,
            individualContainerLayoutData,
        ]);

        useEffect(() => {
            if (containerRef.current) containerRef.current.focus();

            return () => {
                dispatch(containerActions.resetContainer()); //set initial state on unmount to avoid edit page changes on create page
            };
        }, []);

        useEffect(async () => {
            if (editPhaseInPopUp || (urlParams.id && !showInPopup)) {
                await dispatch(
                    containerActions.setCreateContainerLoading(true)
                );
            }
            const {
                templateDropdownData,
                collectionDropdownData,
                layoutDropdownData,
            } = await getDropDownInfo();
            if (urlParams.id && !editPhaseInPopUp) {
                if (!createPhaseInPopup && !createPhasePopupCollection)
                    await dispatch(
                        fetchContainer({
                            id: urlParams.id,
                            templateData: templateDropdownData,
                            collectionData: collectionDropdownData,
                            layoutData: layoutDropdownData,
                        })
                    );

                await dispatch(
                    containerActions.setCreateContainerLoading(false)
                );
            }
        }, [urlParams.id]);

        useEffect(async () => {
            if (createContainerState.id && editPhaseInPopUp) {
                await dispatch(
                    containerActions.setCreateContainerLoading(true)
                );
                const {
                    templateDropdownData,
                    collectionDropdownData,
                    layoutDropdownData,
                } = await getDropDownInfo();

                if (editPhaseInPopUp) {
                    if (!createPhaseInPopup && !createPhasePopupCollection)
                        await dispatch(
                            fetchContainer({
                                id: createContainerState.id,
                                templateData: templateDropdownData,
                                collectionData: collectionDropdownData,
                                layoutData: layoutDropdownData,
                            })
                        );

                    await dispatch(
                        containerActions.setCreateContainerLoading(false)
                    );
                }
            }
        }, [createContainerState.id]);

        useEffect(() => {
            if ((isCreate || createPhaseInPopup) && type !== "") {
                dispatch(containerActions.setDefaultLayout(true));
                dispatch(
                    containerActions.setCollectionItem({
                        layout: layout,
                        default: true,
                    })
                );
            }
        }, [createPhaseInPopup, isCreate, type]);

        useEffect(() => {
            // This is when container is opened from inside the flow
            if (isEmpty(featureGroupProperties)) {
                if (!isEmpty(parentFeatureGroupProperties)) {
                    dispatch(
                        containerActions.setTemplateProperties(
                            parentFeatureGroupProperties
                        )
                    );
                }
            }
        }, [parentFeatureGroupProperties, featureGroupProperties]);

        const getDropDownInfo = async () => {
            let templateDropdownData = [];
            let collectionDropdownData = [];
            let layoutDropdownData = [];

            setDropdownListLoader(true);

            await fetchAll([
                dispatch(fetchAllTemplate()),
                dispatch(fetchAllCollection()),
                dispatch(fetchAllLayout()),
            ]).then(res => {
                templateDropdownData = get(res[0], "payload", []);
                setTemplateDropdownData(get(res[0], "payload", []));
                collectionDropdownData = get(res[1], "payload", []);
                setCollectionDropdownData(get(res[1], "payload", []));
                layoutDropdownData = get(res[2], "payload", []);
                setLayoutDropdownData(get(res[2], "payload", []));

                setDropdownListLoader(false);
            });
            return {
                templateDropdownData,
                collectionDropdownData,
                layoutDropdownData,
            };
        };

        const showConfirmAndSave = async () => {
            return new Promise((resolve, reject) => {
                saveConfirmCallbacks.current = {
                    resolve,
                    reject,
                };
                setShowSaveConfirm(true);
            });
        };

        const onSaveHandler = async () => {
            let isFormValid = false;
            if (
                name != "" &&
                type != "" &&
                template != "" &&
                collections !== "" &&
                outerLayout !== "" &&
                outerLayout !== undefined &&
                vertical !== "" &&
                vertical !== null
            ) {
                let confirm = true;
                if (createContainerState.id) {
                    confirm = await showConfirmAndSave();
                }
                if (confirm) {
                    const res = await createContainerFunction();
                    return res?.data;
                }
                isFormValid = false; //return false when not hitting API
            } else {
                isFormValid = false;
                if (type == "") {
                    setTypeError(true);
                }

                if (collections == "") {
                    setCollectionsError(true);
                }
                if (outerLayout === "" || outerLayout === undefined) {
                    setContainerLayoutError(true);
                }
                if (vertical === "" || vertical === null) {
                    setVerticalError(true);
                }
                return false;
            }
            return isFormValid;
        };

        const showHistory = () => {
            historyDataAction(
                fetchContainerHistory,
                id
                    ? { id: id }
                    : editPhaseInPopUp && createContainerState?.id
                    ? { id: createContainerState.id } //This is for clone and select in flow without Id
                    : urlParams,
                dispatch,
                containerActions,
                true
            );
        };

        const navigateCallback = () => {
            let editId =
                id || (editPhaseInPopUp && createContainerState?.id)
                    ? createContainerState.id //This is for clone and select in flow without Id
                    : urlParams?.id;
            window.open(`/flowmanager/container/edit/${editId}`, "_blank");
        };

        useImperativeHandle(ref, () => ({
            onSaveHandler: onSaveHandler,
            shouldCloneTemplate: shouldCloneTemplate,
            shouldCloneCollection: shouldCloneCollection,
            showHistory: showHistory,
            navigateCallback: navigateCallback,
        }));

        const createContainerFunction = async data => {
            dispatch(containerActions.setMainLoader(true));
            const { payload: response } = await dispatch(
                createUpdateContainer()
            );
            dispatch(containerActions.setShowPrompt(false));
            dispatch(containerActions.setMainLoader(false));
            if (
                response &&
                get(response, "status", false) == "success" &&
                !showInPopup
            ) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Success",
                        message: `Successfully ${
                            !urlParams.id ? "Created" : "Edited"
                        } Container`,
                    })
                );
                queryClient.removeQueries("containerListData", {
                    exact: true,
                });
                queryClient.removeQueries("containerListWithData", {
                    exact: true,
                });
                dispatch(collectionActions.resetCollection());
                navigateToListings();
            }

            if (
                response &&
                get(response, "status", false) == "success" &&
                showInPopup
            ) {
                await dispatch(containerActions.setId(response?.data?.id));
                dispatch(
                    toastListActions.setToastList({
                        type: "Success",
                        message: `Successfully ${
                            editPhaseInPopUp ? "Edited" : "Created"
                        } Container`,
                    })
                );
                dispatch(collectionActions.resetCollection());
                queryClient.removeQueries("containerListData", {
                    exact: true,
                });
                queryClient.removeQueries("containerListWithData", {
                    exact: true,
                });
            }
            return response;
        };

        const navigateToListings = () => {
            history.replace({ pathname: `/flowmanager/container/listings` });
        };

        const typeListDropdownData = collectionSelectList;

        const selectType = data => {
            dispatch(containerActions?.setLayout(""));
            dispatch(containerActions?.setLayoutCopy(""));
            setLayoutDefaultConfig([]);

            dispatch(containerActions.setContainerType(data.id));
            setTypeError(false);
        };

        const selectLayout = data => {
            let selectedLayoutData = null;
            let layoutData = JSON.parse(
                JSON.stringify(
                    layoutDropdownData.filter(_ => _.name === data?.name)
                )
            );
            selectedLayoutData = layoutData;
            delete selectedLayoutData[0]?.id;
            dispatch(containerActions?.setLayout(selectedLayoutData[0]));
            dispatch(containerActions?.setLayoutCopy(selectedLayoutData[0]));

            setLayoutDefaultConfig(
                layoutDropdownData.find(
                    _ => _.name === selectedLayoutData[0]?.name
                )?.configurations || []
            );
        };

        const selectContainerLayout = data => {
            let selectedLayoutData = null;
            let layoutData = JSON.parse(
                JSON.stringify(
                    layoutDropdownData.filter(_ => _.name === data?.name)
                )
            );
            delete layoutData[0]?.id;
            selectedLayoutData = layoutData;
            dispatch(containerActions?.setOuterLayout(selectedLayoutData[0]));
            dispatch(
                containerActions?.setOuterLayoutCopy(selectedLayoutData[0])
            );

            setContainerLayoutDefaultConfig(
                layoutDropdownData.find(
                    _ => _.name === selectedLayoutData[0]?.name
                )?.configurations || []
            );

            setContainerLayoutError(false);
        };

        const selectCollection = data => {
            dispatch(containerActions.setCollection(data.id));
            setCollectionsError(false);
        };

        const setDefaultItemType = defaultLayoutClick => {
            dispatch(
                containerActions.setCollectionItem({
                    layout: defaultLayoutClick ? layout : outerLayout,
                    default: true,
                })
            );
            dispatch(containerActions.setSelectedCollectionItems([]));
            dispatch(containerActions.setDefaultLayout(true));
        };

        const fetchIndividualCollection = async (
            defaultLayoutClick = false
        ) => {
            if (collections) {
                await dispatch(
                    fetchCollectionById({
                        id: collections,
                        history,
                        layoutData: layoutDropdownTypeData,
                    })
                ).then(res => {
                    const items = res?.payload?.data?.items || [];
                    if (items?.length) {
                        if (defaultLayoutClick) {
                            dispatch(
                                containerActions.setCollectionItem({
                                    ...items[0],
                                    layoutConfig: layout,
                                })
                            );
                        } else {
                            dispatch(
                                containerActions.setCollectionItem({
                                    ...items[0],
                                    ...(items[0]?.layoutConfig
                                        ? {}
                                        : { layoutConfig: layout }),
                                })
                            );
                        }

                        dispatch(
                            containerActions.setSelectedCollectionItems(items)
                        );
                        dispatch(containerActions.setDefaultLayout(false));
                    } else {
                        setDefaultItemType(defaultLayoutClick);
                    }
                    dispatch(collectionActions.resetCollection());
                });
            } else {
                setDefaultItemType(defaultLayoutClick);
            }

            await dispatch(fetchAllHnpCategories());
        };

        const viewPropertiesFunctionCall = defaultLayoutClick => {
            layoutOpenRef.current.open();
            if (defaultLayoutClick) {
                setLayoutDefaultConfig(
                    layoutDropdownData?.find(_ => _.name === layout?.name)
                        ?.configurations || []
                );
            } else {
                setIsContainerLayoutActive(true);
                setContainerLayoutDefaultConfig(
                    layoutDropdownData?.find(_ => _.name === outerLayout?.name)
                        ?.configurations || []
                );
            }
            fetchIndividualCollection(defaultLayoutClick);
        };

        const changeCollectionItem = rank => {
            let getItem = selectedCollectionItems.find(
                (obj, idx) => obj?.rank?.toString() === rank
            );

            const getLayout = getItem?.layoutConfig;

            if (containerLevelLayout) {
                dispatch(
                    containerActions.setCollectionItem({
                        ...getItem,
                        ...(getLayout && !isEmpty(getLayout)
                            ? {}
                            : { layoutConfig: layout }),
                    })
                );
            } else {
                dispatch(
                    containerActions.setCollectionItem({
                        ...getItem,
                        layoutConfig: layout,
                    })
                );
            }
        };

        const toggleHistoryVisibilty = () => {
            dispatch(containerActions.setHistoryData([]));
            dispatch(containerActions.setHistoryDataRaw([]));
        };

        const applyDefaultLayout = (
            containerLayoutList,
            containerType,
            containerVertical
        ) => {
            dispatch(
                fetchDefaultContainerLayout({
                    type: containerType,
                    job: containerVertical,
                })
            ).then(res => {
                const selectedContainerLayout =
                    containerLayoutList?.find(
                        ele => ele?.name === res?.payload?.data?.containerLayout
                    ) || "";

                dispatch(
                    containerActions?.setOuterLayout(selectedContainerLayout)
                );
                dispatch(
                    containerActions?.setOuterLayoutCopy(
                        selectedContainerLayout
                    )
                );
                setContainerLayoutDefaultConfig(
                    selectedContainerLayout?.configurations || []
                );

                if (selectedContainerLayout !== "") {
                    setContainerLayoutError(false);
                }

                if (selectedContainerLayout === "") {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Type and Vertical mismatch.",
                            autoClose: 5000,
                        })
                    );
                }
            });
        };

        return (
            <>
                {mainLoader && !showInPopup && <LinearDeterminate />}
                {showSaveConfirm && (
                    <ConfirmAndSave
                        hideComparision={true}
                        entityType="CONTAINER"
                        entityId={createContainerState.id}
                        dataUpdated={createContainerState}
                        dataBackup={createContainerBackupState}
                        config={containerConfirmConfig}
                        saveCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(true);
                        }}
                        closeCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(false);
                        }}
                        isConfirm={true}
                    />
                )}
                <div className={s.createCatContainerspper}>
                    {mainLoader && !showInPopup && <LinearDeterminate />}
                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            {!showInPopup && (
                                <Card
                                    className={`sticky-below-header ${s.header}`}
                                >
                                    <CreateSaveHeader
                                        onSave={!isReadOnly && onSaveHandler}
                                        // Temp disabling to avoid testing
                                        // saveBtn={!formChanges}
                                        isBig={true}
                                        title={`${
                                            urlParams.id ? "Edit" : "Create"
                                        } Container`}
                                        saveLabel={`${
                                            urlParams.id ? "Save" : "Create"
                                        }`}
                                        desc={
                                            "Create lead category for advertiser"
                                        }
                                        showPrompt={!isReadOnly && showPrompt}
                                        showHamburgerBtn={false}
                                        loading={loading}
                                        showHistoryIcon={
                                            urlParams.id ? true : false
                                        }
                                        saveBtn={mainLoader || !liveAccess}
                                        showHistory={showHistory}
                                    ></CreateSaveHeader>
                                </Card>
                            )}
                            <Card
                                className={`card-padding ${
                                    showInPopup && s.noCard
                                }`}
                            >
                                <div>
                                    {isClonePanel && (
                                        <p className="header-title-no-bold mb-20">
                                            By confirming, you will also clone
                                            the below checked template and
                                            collection. If you do not wish to
                                            clone any of the selected items,
                                            please uncheck and proceed with the
                                            originally mapped items.
                                        </p>
                                    )}
                                    <h3
                                        data-hash-id="leadBasics"
                                        id="leadBasics"
                                        className="headerTitle"
                                    >
                                        Basics
                                    </h3>
                                    {urlParams.id && !showInPopup && (
                                        <div className={`row`}>
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                ID
                                            </p>
                                            <p className={`infoText`}>
                                                ID associated with a Lead
                                                Category
                                            </p>
                                            <p className={s.idWrapper}>
                                                {urlParams.id}
                                            </p>
                                        </div>
                                    )}

                                    {createPhaseInPopup ? (
                                        ""
                                    ) : editPhaseInPopUp ? (
                                        <div className={`row`}>
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                ID
                                            </p>
                                            <p className={`infoText`}>
                                                ID associated with a Lead
                                                Category
                                            </p>
                                            <p className={s.idWrapper}>
                                                {createContainerState.id}
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}

                                    <div className={"form-row"}>
                                        <div className="headerTitleRequired">
                                            Vertical*
                                        </div>
                                        <p className={`infoText`}>
                                            Select the type of vertical
                                        </p>

                                        <div className={s.dropdownWrapper}>
                                            <Select
                                                options={siteConfigList}
                                                selectedId={
                                                    vertical === ""
                                                        ? null
                                                        : vertical
                                                }
                                                label={"Select Vertical"}
                                                keyField={"name"}
                                                idField={"id"}
                                                onSelect={vertical => {
                                                    dispatch(
                                                        containerActions.setContainerVertical(
                                                            vertical?.id
                                                        )
                                                    );
                                                    setVerticalError(false);

                                                    //reset collection
                                                    dispatch(
                                                        containerActions.setCollection(
                                                            ""
                                                        )
                                                    );

                                                    if (type !== "") {
                                                        applyDefaultLayout(
                                                            outerLayoutDropdownTypeData,
                                                            type,
                                                            vertical?.id
                                                        );
                                                    }
                                                }}
                                                onLoadSelectReturn={false}
                                                isError={verticalError}
                                                isDisabled={
                                                    isReadOnly
                                                        ? isReadOnly
                                                        : editPhaseVerticalNotSelected
                                                        ? false
                                                        : editPhaseInPopUp
                                                        ? editPhaseInPopUp
                                                        : createPhaseInPopup
                                                        ? false
                                                        : (urlParams.id &&
                                                              !showInPopup) ||
                                                          !isCreate
                                                        ? true
                                                        : false
                                                }
                                                showOptionToolTip={false}
                                            />
                                        </div>
                                    </div>

                                    <div className={"form-row"}>
                                        <div className="headerTitleRequired">
                                            Type*
                                        </div>
                                        <p className={`infoText`}>
                                            Select the type of feature mapping
                                        </p>

                                        <div className={s.dropdownWrapper}>
                                            <Select
                                                options={typeListDropdownData}
                                                selectedId={
                                                    type == "" ? null : type
                                                }
                                                label={"Select Type"}
                                                keyField={"name"}
                                                idField={"id"}
                                                onSelect={selectType}
                                                onLoadSelectReturn={false}
                                                isError={typeError}
                                                isDisabled={
                                                    isReadOnly
                                                        ? isReadOnly
                                                        : editPhaseInPopUp
                                                        ? editPhaseInPopUp
                                                        : (urlParams.id &&
                                                              !showInPopup) ||
                                                          !isCreate
                                                        ? true
                                                        : false
                                                }
                                                showOptionToolTip={false}
                                            />
                                        </div>
                                    </div>
                                    <div className={"form-row"}>
                                        <p className="headerTitleRequired">
                                            Name*
                                        </p>
                                        <p className="infoText">
                                            Its an unique identifier name for
                                            the category
                                        </p>
                                        <Input
                                            ref={containerRef}
                                            className="maxChildContainer"
                                            placeholder="Enter container name"
                                            required={true}
                                            onChange={e =>
                                                dispatch(
                                                    containerActions.setContainerName(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            value={name}
                                            disabled={isReadOnly}
                                        ></Input>
                                    </div>

                                    <div className={"form-row"}>
                                        <div
                                            className={`dp-parent dp-parent-hor-space-between dp-child-ver-center headerTitleRequired maxChildContainer`}
                                        >
                                            <p className={`${s.sideTitle}`}>
                                                Container Layout*
                                                <Tooltip
                                                    message={
                                                        "Templates Configurations are moved inside Container Layout Properties section, click Advanced Configs to add/edit Template configs"
                                                    }
                                                    position="top"
                                                    variant
                                                    wrap={true}
                                                    customClass={s.toolTipClass}
                                                >
                                                    <div
                                                        className={`${s.infoIconWrap} circleIconColor circleIconColor--darkHover`}
                                                    >
                                                        <Icon
                                                            icon="Info"
                                                            size="18"
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </p>

                                            {!isEmpty(outerLayout) ? (
                                                <p
                                                    className={`${s.sideTitle} ${s.sideEditTitle}`}
                                                    onClick={async () => {
                                                        viewPropertiesFunctionCall(
                                                            false
                                                        );

                                                        setContainerLevelLayout(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <Icon
                                                        icon="EditLayoutProperties"
                                                        size="16px"
                                                        enableHover={true}
                                                    />
                                                    Layout Features
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <p className={`infoText`}>
                                            Select the layout for container
                                        </p>
                                        <div className={s.dropdownWrapper}>
                                            <Select
                                                options={
                                                    outerLayoutDropdownTypeData
                                                }
                                                selectedId={
                                                    typeof outerLayout ===
                                                        "object" &&
                                                    !isEmpty(outerLayout)
                                                        ? outerLayoutDropdownTypeData.find(
                                                              (obj, idx) =>
                                                                  obj.name ===
                                                                  outerLayout?.name
                                                          )
                                                            ? outerLayout?.name
                                                            : null
                                                        : null
                                                }
                                                label={"Select Layout"}
                                                keyField={"name"}
                                                idField={"name"}
                                                onSelect={selectContainerLayout}
                                                showSearch={true}
                                                position={"bottom"}
                                                onLoadSelectReturn={false}
                                                isDisabled={
                                                    isReadOnly || type == ""
                                                }
                                                CustomRender={item => {
                                                    return (
                                                        <div className="dp-parent gap-10 w-100">
                                                            <div>
                                                                {" "}
                                                                {
                                                                    item.option
                                                                        .name
                                                                }
                                                            </div>
                                                            {item.option
                                                                .configurations
                                                                .length > 0 && (
                                                                <div className="iconInsideDiv">
                                                                    <Icon
                                                                        icon="Customise"
                                                                        size="16px"
                                                                        className="circleIconColor"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                                isError={containerLayoutError}
                                            />
                                        </div>
                                    </div>

                                    <div className={"form-row"}>
                                        <div
                                            className={`headerTitleRequired maxChildContainer ${s.templateTitle}`}
                                        >
                                            Collections*
                                            {collections !== "" &&
                                                !isClonePanel && (
                                                    <span
                                                        onClick={() => {
                                                            // setCreatePhasePopupCollection(
                                                            //     true
                                                            // );
                                                            collectionOpenRef.current.open();
                                                            // dispatch(
                                                            //     fetchTemplate(
                                                            //         template
                                                            //     )
                                                            // );
                                                            setViewIsClickCollection(
                                                                true
                                                            );
                                                        }}
                                                        className={s.editBtn}
                                                    >
                                                        <Icon
                                                            icon={"Edit"}
                                                            size="14px"
                                                            enableHover={true}
                                                        />
                                                        Edit
                                                    </span>
                                                )}
                                            {isClonePanel && (
                                                <Checkbox
                                                    onChange={
                                                        setShouldCloneCollection
                                                    }
                                                    isChecked={
                                                        shouldCloneCollection
                                                    }
                                                    labelText={
                                                        "Clone Collection"
                                                    }
                                                    disabled={
                                                        !isClonePanel &&
                                                        isReadOnly
                                                    }
                                                    labelClassName={
                                                        "checkboxLabelContainer"
                                                    }
                                                ></Checkbox>
                                            )}
                                        </div>
                                        <p className={`infoText`}>
                                            Select the type of feature mapping
                                        </p>
                                        <div className={s.dropdownWrapper}>
                                            <Select
                                                options={collectionDropdownTypeData?.map(
                                                    _ => {
                                                        return {
                                                            ..._,
                                                            name:
                                                                _.id +
                                                                ": " +
                                                                _.name,
                                                        };
                                                    }
                                                )}
                                                selectedId={
                                                    collections == ""
                                                        ? null
                                                        : collectionDropdownTypeData.find(
                                                              (obj, idx) =>
                                                                  obj.id ===
                                                                  collections
                                                          )
                                                        ? collections
                                                        : null
                                                }
                                                label={"Select Collections"}
                                                keyField={"name"}
                                                idField={"id"}
                                                onSelect={selectCollection}
                                                position="top"
                                                onLoadSelectReturn={false}
                                                isError={collectionsError}
                                                isDisabled={
                                                    isReadOnly || type == ""
                                                }
                                                showSearch={true}
                                                showCreate={true}
                                                onCreate={item => {
                                                    setViewIsClickCollection(
                                                        false
                                                    );
                                                    setCreatePhasePopupCollection(
                                                        true
                                                    );
                                                    collectionOpenRef.current.open();
                                                    setTimeout(async () => {
                                                        dispatch(
                                                            collectionActions.setCollectionType(
                                                                {
                                                                    id: type,
                                                                    name: type,
                                                                }
                                                            )
                                                        );
                                                        dispatch(
                                                            collectionActions.setCollectionName(
                                                                item.name
                                                            )
                                                        );
                                                        dispatch(
                                                            collectionActions.setCollectionVertical(
                                                                vertical
                                                            )
                                                        );
                                                        await setCollectionsError(
                                                            false
                                                        );
                                                    });
                                                }}
                                                loading={
                                                    !!type && dropdownListLoader
                                                }
                                                CustomRender={option => (
                                                    <SelectWithCloneOption
                                                        option={option.option}
                                                        selector="name"
                                                        onClone={() => {
                                                            // setCollectionSelectedBackup(
                                                            //     collections
                                                            // );
                                                            setCollectionCloneObj(
                                                                option.option
                                                            );
                                                            collectionOpenRef.current.open();
                                                        }}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className={"form-row"}>
                                        <div
                                            className={`dp-parent dp-parent-hor-space-between dp-child-ver-center headerTitleRequired maxChildContainer`}
                                        >
                                            <p className={`${s.sideTitle}`}>
                                                Fallback Collection Item Layout
                                            </p>

                                            {!isEmpty(layout) ? (
                                                <p
                                                    className={`${s.sideTitle} ${s.sideEditTitle}`}
                                                    onClick={async () => {
                                                        viewPropertiesFunctionCall(
                                                            true
                                                        );

                                                        setContainerLevelLayout(
                                                            false
                                                        );
                                                    }}
                                                >
                                                    <Icon
                                                        icon="EditLayoutProperties"
                                                        size="16px"
                                                        enableHover={true}
                                                    />
                                                    Layout Features
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <p className={`infoText`}>
                                            Select the layout for container
                                        </p>
                                        <div className={s.dropdownWrapper}>
                                            <Select
                                                options={layoutDropdownTypeData}
                                                selectedId={
                                                    typeof layout ===
                                                        "object" &&
                                                    !isEmpty(layout)
                                                        ? layoutDropdownTypeData.find(
                                                              (obj, idx) =>
                                                                  obj.name ===
                                                                  layout?.name
                                                          )
                                                            ? layout?.name
                                                            : null
                                                        : null
                                                }
                                                label={"Select Layout"}
                                                keyField={"name"}
                                                idField={"name"}
                                                onSelect={selectLayout}
                                                showSearch={true}
                                                position={"top"}
                                                onLoadSelectReturn={false}
                                                isDisabled={
                                                    isReadOnly || type == ""
                                                }
                                                CustomRender={item => {
                                                    return (
                                                        <div className="dp-parent gap-10 w-100">
                                                            <div>
                                                                {" "}
                                                                {
                                                                    item.option
                                                                        .name
                                                                }
                                                            </div>
                                                            {item.option
                                                                .configurations
                                                                .length > 0 && (
                                                                <div className="iconInsideDiv">
                                                                    <Icon
                                                                        icon="Customise"
                                                                        size="16px"
                                                                        className="circleIconColor"
                                                                    />
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }}
                                                closeIcon={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            <SidePanelInfinity
                                ref={collectionOpenRef}
                                title={`${
                                    collectionCloneObj
                                        ? "Clone & Select"
                                        : viewIsClickCollection
                                        ? "Edit"
                                        : "Create"
                                } Collection`}
                                saveButtonText={
                                    collectionCloneObj
                                        ? "Confirm"
                                        : viewIsClickCollection
                                        ? "Save"
                                        : "Create"
                                }
                                width={"70%"}
                                onSave={async () => {
                                    if (collectionCloneObj) {
                                        collectionOpenRef?.current?.close();
                                        dispatch(
                                            containerActions.setMainLoader(true)
                                        );
                                        const clonedItem = await dispatch(
                                            cloneCollection(
                                                collectionCloneObj.id
                                            )
                                        ).then(res => res.payload);
                                        if (clonedItem) {
                                            await dispatch(
                                                fetchAllCollection()
                                            ).then(res => {
                                                setCollectionDropdownData(
                                                    get(res, "payload", [])
                                                );
                                            });
                                            selectCollection(clonedItem);
                                            dispatch(
                                                toastListActions.setToastList({
                                                    type: "Success",
                                                    message:
                                                        "Collection cloned successfully.",
                                                    autoClose: 5000,
                                                })
                                            );
                                        }
                                        dispatch(
                                            containerActions.setMainLoader(
                                                false
                                            )
                                        );
                                    } else {
                                        let saveSuccessfulFlag =
                                            await collectionSaveFunctionCallRef.current.onSaveHandler();
                                        if (saveSuccessfulFlag) {
                                            setTimeout(async () => {
                                                setTimeout(async () => {
                                                    await dispatch(
                                                        fetchAllCollection()
                                                    ).then(res => {
                                                        setCollectionDropdownData(
                                                            get(
                                                                res,
                                                                "payload",
                                                                []
                                                            )
                                                        );

                                                        if (
                                                            typeof collectionUpdated ===
                                                            "function"
                                                        ) {
                                                            collectionUpdated(
                                                                get(
                                                                    res,
                                                                    "payload",
                                                                    []
                                                                )
                                                            );
                                                        }
                                                    });

                                                    await fetchIndividualCollection();

                                                    setCreatePhasePopupCollection(
                                                        false
                                                    );
                                                }, 0);
                                            }, 1000);
                                            collectionOpenRef?.current?.close();
                                        }
                                    }
                                }}
                                disableSaveBtn={
                                    isReadOnly ||
                                    collectionLoader ||
                                    createCollection.mainLoader ||
                                    !liveAccess
                                }
                                showLoader={
                                    collectionLoader ||
                                    createCollection.mainLoader
                                }
                                onClose={() => {
                                    setCreatePhasePopupCollection(false);
                                    setCollectionCloneObj(null);
                                    // setCollectionSelectedBackup(null);
                                }}
                                children={
                                    <CreateCollection
                                        collectionIdFromParent={
                                            collectionCloneObj?.id ||
                                            collections
                                        }
                                        editPhaseInPopUp={
                                            viewIsClickCollection ||
                                            collectionCloneObj ||
                                            vertical
                                        }
                                        ref={collectionSaveFunctionCallRef}
                                        showInPopup={true}
                                        createPhaseInPopup={
                                            createPhasePopupCollection
                                        }
                                        inContainer={true}
                                        changeCollectionItem={rank => {
                                            changeCollectionItem(rank);
                                        }}
                                        isClonePanel={collectionCloneObj}
                                        selectedFlowDomain={selectedFlowDomain}
                                        isTableView={isTableView}
                                    />
                                }
                                showHistoryCallback={
                                    viewIsClickCollection
                                        ? () =>
                                              collectionSaveFunctionCallRef?.current?.showHistory()
                                        : null
                                }
                                navigateCallback={
                                    viewIsClickCollection
                                        ? () =>
                                              collectionSaveFunctionCallRef?.current?.navigateCallback()
                                        : null
                                }
                            ></SidePanelInfinity>

                            <SidePanelInfinity
                                ref={layoutOpenRef}
                                title={"Edit Layout Features"}
                                // width={"93%"}
                                width={
                                    type === "REGISTER" ||
                                    type === "SURVEY" ||
                                    type === "DETAILS_CONFIRMATION"
                                        ? "95%"
                                        : "449px"
                                }
                                dialogContentClassName={s.popupStyle}
                                onSave={() => {
                                    let isValid = true;
                                    const layoutEditCompData =
                                        layoutCompRef?.current?.getData();

                                    const templatesList =
                                        layoutEditCompData?.templateList;

                                    const layoutEdittemplateId =
                                        layoutEditCompData?.templateId;

                                    const newlySavedTemplate =
                                        templatesList.find(
                                            data =>
                                                data.id === layoutEdittemplateId
                                        )?.properties;

                                    dispatch(
                                        containerActions.setTemplateProperties(
                                            newlySavedTemplate
                                        )
                                    );
                                    dispatch(
                                        containerActions.setTemplate(
                                            layoutEdittemplateId === "" ||
                                                layoutEdittemplateId === null
                                                ? undefined
                                                : layoutEdittemplateId
                                        )
                                    );
                                    if (isContainerLayoutActive) {
                                        individualContainerLayoutData[0]
                                            ?.configurations?.length &&
                                            individualContainerLayoutData[0]?.configurations.forEach(
                                                (item, index) => {
                                                    if (
                                                        item.type ===
                                                            "string" ||
                                                        item.type === "richText"
                                                    ) {
                                                        if (!item?.data) {
                                                            dispatch(
                                                                toastListActions.setToastList(
                                                                    {
                                                                        type: "Error",
                                                                        message: `Please enter default value for Key ${item.key}.`,
                                                                    }
                                                                )
                                                            );
                                                            isValid = false;
                                                        }
                                                    }
                                                    if (
                                                        item.type === "number"
                                                    ) {
                                                        let intData = parseInt(
                                                            item?.data
                                                        );
                                                        if (
                                                            intData === "" ||
                                                            intData === null
                                                        ) {
                                                            dispatch(
                                                                toastListActions.setToastList(
                                                                    {
                                                                        type: "Error",
                                                                        message: `Please enter default value for Key ${item.key}.`,
                                                                    }
                                                                )
                                                            );
                                                            isValid = false;
                                                        }
                                                        if (
                                                            typeof intData ===
                                                                "number" &&
                                                            (intData >
                                                                parseInt(
                                                                    item.max
                                                                ) ||
                                                                intData <
                                                                    parseInt(
                                                                        item.min
                                                                    ))
                                                        ) {
                                                            dispatch(
                                                                toastListActions.setToastList(
                                                                    {
                                                                        type: "Error",
                                                                        message: `Please enter value between ${item.min} and ${item.max} for Key ${item.key}.`,
                                                                    }
                                                                )
                                                            );
                                                            isValid = false;
                                                        }
                                                    }
                                                }
                                            );

                                        if (isValid) {
                                            dispatch(
                                                containerActions?.setOuterLayout(
                                                    individualContainerLayoutData[0]
                                                )
                                            );
                                            dispatch(
                                                containerActions?.setOuterLayoutCopy(
                                                    individualContainerLayoutData[0]
                                                )
                                            );
                                            cancelLayoutEdit = false;
                                            layoutOpenRef.current.close();
                                            setIsContainerLayoutActive(false);
                                        }
                                    } else {
                                        individualLayoutData[0]?.configurations
                                            ?.length &&
                                            individualLayoutData[0]?.configurations.forEach(
                                                (item, index) => {
                                                    if (
                                                        item.type === "string"
                                                    ) {
                                                        if (!item?.data) {
                                                            dispatch(
                                                                toastListActions.setToastList(
                                                                    {
                                                                        type: "Error",
                                                                        message: `Please enter default value for Key ${item.key}.`,
                                                                    }
                                                                )
                                                            );
                                                            isValid = false;
                                                        }
                                                    }
                                                    if (
                                                        item.type === "number"
                                                    ) {
                                                        let intData = parseInt(
                                                            item?.data
                                                        );
                                                        if (
                                                            intData === "" ||
                                                            intData === null
                                                        ) {
                                                            dispatch(
                                                                toastListActions.setToastList(
                                                                    {
                                                                        type: "Error",
                                                                        message: `Please enter default value for Key ${item.key}.`,
                                                                    }
                                                                )
                                                            );
                                                            isValid = false;
                                                        }
                                                        if (
                                                            typeof intData ===
                                                                "number" &&
                                                            (intData >
                                                                parseInt(
                                                                    item.max
                                                                ) ||
                                                                intData <
                                                                    parseInt(
                                                                        item.min
                                                                    ))
                                                        ) {
                                                            dispatch(
                                                                toastListActions.setToastList(
                                                                    {
                                                                        type: "Error",
                                                                        message: `Please enter value between ${item.min} and ${item.max} for Key ${item.key}.`,
                                                                    }
                                                                )
                                                            );
                                                            isValid = false;
                                                        }
                                                    }
                                                }
                                            );

                                        if (isValid) {
                                            dispatch(
                                                containerActions?.setLayout(
                                                    individualLayoutData[0]
                                                )
                                            );
                                            dispatch(
                                                containerActions?.setLayoutCopy(
                                                    individualLayoutData[0]
                                                )
                                            );
                                            cancelLayoutEdit = false;
                                            layoutOpenRef.current.close();
                                        }
                                    }
                                    setContainerLevelLayout(true);
                                }}
                                onClose={() => {
                                    if (!!cancelLayoutEdit) {
                                        if (isContainerLayoutActive) {
                                            setIndividualContainerLayoutData([
                                                outerLayoutCopy,
                                            ]);
                                            dispatch(
                                                containerActions?.setOuterLayout(
                                                    outerLayoutCopy
                                                )
                                            );
                                        } else {
                                            setIndividualLayoutData([
                                                layoutCopy,
                                            ]);
                                            dispatch(
                                                containerActions?.setLayout(
                                                    layoutCopy
                                                )
                                            );
                                        }
                                        setPreviewItem({});
                                    }
                                    setIsContainerLayoutActive(false);
                                    setContainerLevelLayout(false);
                                }}
                                // disableSaveBtn={
                                //     isReadOnly ||
                                //     (isContainerLayoutActive
                                //         ? containerLayoutDefaultConfig?.length ===
                                //               0 || createTemplateState.loading
                                //         : layoutDefaultConfig?.length === 0)
                                // }
                                disableSaveBtn={
                                    isReadOnly ||
                                    (isContainerLayoutActive
                                        ? createTemplateState.loading
                                        : layoutDefaultConfig?.length === 0)
                                }
                            >
                                <LayoutEditComp
                                    vertical={vertical}
                                    defaultConfigs={
                                        isContainerLayoutActive
                                            ? containerLayoutDefaultConfig
                                            : layoutDefaultConfig
                                    }
                                    individualLayoutData={
                                        isContainerLayoutActive
                                            ? individualContainerLayoutData
                                            : individualLayoutData
                                    }
                                    // item={previewItem}
                                    item={
                                        type === "REGISTER" ||
                                        type === "SURVEY" ||
                                        type === "DETAILS_CONFIRMATION"
                                            ? previewItem
                                            : {}
                                    }
                                    showDefaultMsg={defaultLayout}
                                    updatedLayout={data => {
                                        if (isContainerLayoutActive) {
                                            dispatch(
                                                containerActions?.setOuterLayout(
                                                    data[0]
                                                )
                                            );
                                        } else {
                                            dispatch(
                                                containerActions?.setLayout(
                                                    data[0]
                                                )
                                            );
                                        }
                                    }}
                                    ondelete={item => {
                                        if (isContainerLayoutActive) {
                                            dispatch(
                                                containerActions?.setOuterLayout(
                                                    {
                                                        ...individualContainerLayoutData[0],
                                                        configurations:
                                                            individualContainerLayoutData[0]?.configurations?.filter(
                                                                _ =>
                                                                    _.key !==
                                                                    item.key
                                                            ),
                                                    }
                                                )
                                            );
                                        } else {
                                            dispatch(
                                                containerActions?.setLayout({
                                                    ...individualLayoutData[0],
                                                    configurations:
                                                        individualLayoutData[0]?.configurations?.filter(
                                                            _ =>
                                                                _.key !==
                                                                item.key
                                                        ),
                                                })
                                            );
                                        }
                                    }}
                                    showConfigSelect={
                                        isContainerLayoutActive
                                            ? showConfigSelectContainer
                                            : showConfigSelect
                                    }
                                    addConfigList={
                                        isContainerLayoutActive
                                            ? addConfigListContainer
                                            : addConfigList
                                    }
                                    setShowConfigSelect={data => {
                                        if (isContainerLayoutActive) {
                                            setShowConfigSelectContainer(data);
                                        } else {
                                            setShowConfigSelect(data);
                                        }
                                    }}
                                    customOnSelect={item => {
                                        if (isContainerLayoutActive) {
                                            setShowConfigSelectContainer(false);
                                            dispatch(
                                                containerActions?.setOuterLayout(
                                                    {
                                                        ...individualContainerLayoutData[0],
                                                        configurations: [
                                                            ...individualContainerLayoutData[0]
                                                                ?.configurations,
                                                            ...item,
                                                        ],
                                                    }
                                                )
                                            );
                                        } else {
                                            setShowConfigSelect(false);
                                            dispatch(
                                                containerActions?.setLayout({
                                                    ...individualLayoutData[0],
                                                    configurations: [
                                                        ...individualLayoutData[0]
                                                            ?.configurations,
                                                        ...item,
                                                    ],
                                                })
                                            );
                                        }
                                    }}
                                    onCancelHandler={e => {
                                        if (isContainerLayoutActive) {
                                            setShowConfigSelectContainer(false);
                                        } else {
                                            setShowConfigSelect(false);
                                        }
                                    }}
                                    showitemsDropdown={selectedCollectionItems.map(
                                        (obj, idx) => {
                                            return {
                                                id: `Item-${obj.rank}`,
                                                name: `Item-${obj.rank}`,
                                            };
                                        }
                                    )}
                                    itemsDropdownId={`Item-${collectionItem.rank}`}
                                    onChangeItem={data => {
                                        const getRank = data?.id?.split("-");
                                        changeCollectionItem(getRank[1]);
                                    }}
                                    readOnly={isReadOnly}
                                    selectedFlowDomain={selectedFlowDomain}
                                    showTemplate={isContainerLayoutActive}
                                    ref={layoutCompRef}
                                    selectedTemplateId={template}
                                />
                            </SidePanelInfinity>
                        </>
                    )}
                </div>
                {historyData && historyData.length > 0 ? (
                    <History
                        data={historyData}
                        rawData={historyDataRaw}
                        closeHistory={toggleHistoryVisibilty}
                        historyConfig={containerConfig}
                    />
                ) : null}
            </>
        );
    }
);

export default CreateContainer;

export const SelectWithCloneOption = ({ option, selector, onClone }) => {
    return (
        <div
            className={`dp-parent dp-parent-hor-space-between cloneSelectWrapper`}
        >
            <div className={`ellipsis ${s.optionText}`}>{option[selector]}</div>
            <div
                className="dp-parent"
                onClick={e => {
                    e.stopPropagation();
                    onClone();
                }}
                onMouseDown={e => e.stopPropagation()}
            >
                <Tooltip message={"Clone & Select"}>
                    <Icon
                        icon="Clone"
                        size="16px"
                        enableHover={true}
                        className="circleIconColor"
                    />
                </Tooltip>
            </div>
        </div>
    );
};
