import React from "react";
import Select from "../../Shared/Select/Select";
import styles from "./ListingDetails.module.scss";
import { useState, useEffect, useRef } from "react";
import _, { get, isEmpty, cloneDeep } from "lodash";
import { questionsActions } from "../../store/listings/questions";
import { REGISTER_DETAIL_DROPDOWN } from "../../constants/collectionConstants";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import Icon from "../../Shared/Icon/Icon";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch, useSelector } from "react-redux";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import { useParams } from "react-router-dom";
import { collectionActions } from "../../store/flowmanager/collection";
import CreateAdUnit from "../../Pages/CreateAdUnit/CreateAdUnits";
import Input from "../../Shared/Input/Input";
import {
    adUnitsActions,
    updateAdUnitDetails,
    addAdUnitDetails,
} from "../../store/listings/adUnits";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import ReactSelect from "../../Shared/ReactSelect/ReactSelect";
import RichTextBox from "../../Shared/RichTextBox/RichTextBox";
import { getHTMLTextData } from "../../utils/globalUtils";

const DetailWrap = ({
    inRightPanel = false,
    question,
    index,
    dataIndex,
    readOnly,
    editOptions,
    removeAdUnit,
}) => {
    const editAdunitRef = useRef();
    const createAdUnitRef = useRef(null);
    const editDetailsRef = useRef(null);
    const dispatch = useDispatch();
    const textEditor = useRef(null);
    const postionInputRef = useRef(null);

    const [activeDetail, setActiveDetail] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);
    const [adUnitId, setAdUnitId] = useState(null);
    const [editDetailData, setEditDetailData] = useState({});

    const collectionState = useSelector(state => state.flowmanager.collection);
    const items = get(collectionState, "createCollection.detailsList", []);
    const [isCreate, setIsCreate] = useState(false);

    const createNewAdUnit = async () => {
        let isValid = await createAdUnitRef.current.validateAllFields();

        if (isValid) {
            dispatch(adUnitsActions.setMainLoader(true));
            dispatch(addAdUnitDetails())
                .unwrap()
                .then(async response => {
                    if (response) {
                        await dispatch(adUnitsActions.setMainLoader(false));
                        await dispatch(
                            toastListActions.setToastList({
                                type: "Success",
                                message: "Created Ad Unit successfully",
                            })
                        );
                        await editAdunitRef.current.close();
                        await setIsCreate(false);
                    }
                });
        }
    };
    const editExistingAdUnit = async value => {
        await setIsCreate(false);
    };

    const cancelEdit = () => {
        setActiveDetail({});
    };

    const editAdunitDetail = async detail => {
        setAdUnitId(detail?.id);
        editExistingAdUnit(detail);
        editAdunitRef.current.open();
    };

    const editDetail = data => {
        setEditDetailData(data);
        editDetailsRef.current.open();
    };

    const updateAdUnit = async id => {
        let isValid = await createAdUnitRef.current.validateAllFields();
        if (isValid) {
            dispatch(adUnitsActions.setMainLoader(true));
            dispatch(updateAdUnitDetails(id))
                .unwrap()
                .then(async response => {
                    if (response) {
                        dispatch(adUnitsActions.setMainLoader(false));
                        dispatch(
                            toastListActions.setToastList({
                                type: "Success",
                                message: "Updated Ad Unit Successfully",
                            })
                        );
                        await editAdunitRef.current.close();
                    }
                });
        }
    };

    return (
        <div
            className={`${
                !inRightPanel
                    ? styles["adunits-details-wrap"]
                    : styles["adunits-details-wrap-sidepanel"]
            }`}
            data-index={dataIndex ? dataIndex : index + 1}
        >
            <div
                className={`dp-parent dp-parent-hor-space-between dp-parent-ver-center mb-20`}
            >
                <div
                    className={`dp-parent dp-parent-hor-space-between dp-parent-ver-center ${styles.adUnitContainer}`}
                >
                    <div
                        className={`${styles.additionalDetails} ${styles.adDetails}`}
                    >
                        <div className={styles.key}>ID: </div>
                        <div
                            className={styles.questionValue}
                            title={question.question}
                        >
                            {question?.id
                                ? question?.id
                                : question?.properties
                                      ?.auBasedPriorityListings[0]?.id}
                        </div>
                    </div>
                    <div
                        className={`${styles.additionalDetails} ${styles.nameWidth}`}
                    >
                        <span className={styles.adUnitHead}>
                            Ad Unit Name :
                        </span>{" "}
                        <span className={`${styles.adUnitValue}`}>
                            {question?.adUnitId
                                ? question?.name
                                : question?.properties
                                      ?.auBasedPriorityListings[0]?.name}
                        </span>
                    </div>
                </div>
                {editOptions && (
                    <div
                        className={`dp-parent gap-20 ${styles["edit-options"]}`}
                    >
                        <div
                            className={`${styles.option} circleIconColor`}
                            onClick={() => {
                                editDetail(question);
                            }}
                        >
                            <Icon icon="Edit" size="16px" enableHover={true} />
                        </div>

                        {!readOnly && (
                            <div
                                className={`${styles.option} circleIconColor circleIconColor--delete`}
                                onClick={() => {
                                    question?.id
                                        ? setDeletePopup(question)
                                        : removeAdUnit(question);
                                }}
                            >
                                <Icon
                                    icon="Delete"
                                    size="16px"
                                    enableHover={true}
                                />
                            </div>
                        )}
                    </div>
                )}
                {deletePopup === question && (
                    <div className={styles.deletePopup}>
                        <PopupTargettingParams
                            setName={question}
                            Rfunction={removeAdUnit}
                            setShowPopup={setDeletePopup}
                            customText={`Do you wish to delete this item?`}
                        />
                    </div>
                )}
            </div>
            {!inRightPanel && (
                <div className={styles.adUnitDetail}>
                    <div
                        className={`${styles.additionalDetails} ${styles.customDetail}`}
                    >
                        <span className={styles.adUnitHead}>Ad Unit ID :</span>{" "}
                        <span
                            className={`${styles.adUnitValue} ${styles.idWidth}`}
                        >
                            {question?.adUnitId
                                ? question?.adUnitId
                                : question?.properties
                                      ?.auBasedPriorityListings[0]?.adUnitId}
                        </span>
                    </div>
                </div>
            )}
            <div className={`${styles.positionDetail} gap-40`}>
                <div
                    className={`${styles.additionalDetails} dp-parent dp-parent-ver-center`}
                >
                    <span
                        className={`${styles.adUnitHead} ${styles.positionHead}`}
                    >
                        Position :
                    </span>{" "}
                    <div className={`${styles.positionInput} dp-parent`}>
                        <span>
                            {question?.position ||
                                question?.properties?.auBasedPriorityListings[0]
                                    ?.position}
                        </span>
                    </div>
                </div>
                <div
                    className={`${styles.additionalDetails} dp-parent dp-parent-ver-center gap-10`}
                >
                    <p className="headerTitleRequired mb-0">JT Ad Unit :</p>
                    <span>
                        {question.JTAdUnitEnabled ||
                        question?.properties?.auBasedPriorityListings[0]
                            ?.JTAdUnitEnabled ||
                        false
                            ? "Enabled"
                            : "Disabled"}
                    </span>
                </div>
                <div
                    className={`${styles.additionalDetails} dp-parent dp-parent-ver-center gap-10`}
                >
                    <p className="headerTitleRequired mb-0">Banner Text :</p>
                    <span>{question?.bannerText ? "Added" : "Not Added"}</span>
                </div>
            </div>
            <SidePanelInfinity
                title={`Edit Item`}
                ref={editDetailsRef}
                onSave={() => {
                    const htmlContent =
                        textEditor?.current?.value().html ||
                        editDetailData.bannerText;

                    const checkIfTextExist = getHTMLTextData(
                        htmlContent?.trim()
                    );

                    let dataCopy = JSON.parse(JSON.stringify(editDetailData));

                    if (checkIfTextExist?.length) {
                        dataCopy = {
                            ...dataCopy,
                            bannerText: htmlContent,
                        };
                    } else {
                        delete dataCopy?.bannerText;
                    }

                    setEditDetailData(dataCopy);

                    const noFieldEmpty =
                        postionInputRef.current.validate() &&
                        postionInputRef.current.value() > 0 &&
                        !postionInputRef.current
                            .value()
                            .toString()
                            ?.includes(".");

                    if (noFieldEmpty) {
                        let initialItems = JSON.parse(JSON.stringify(items));
                        let updatedData =
                            initialItems[0]?.properties?.auBasedPriorityListings?.map(
                                (_, index) => {
                                    if (_.id === dataCopy.id) {
                                        return {
                                            ...dataCopy,
                                        };
                                    } else {
                                        return { ..._ };
                                    }
                                }
                            );

                        let updatedAdUnitItems = initialItems?.map(_ => ({
                            ..._,
                            properties: {
                                ..._.properties,
                                auBasedPriorityListings: updatedData,
                            },
                        }));

                        dispatch(
                            collectionActions.setDetailItems(updatedAdUnitItems)
                        );
                        editDetailsRef.current.close();
                        setEditDetailData({});
                    } else {
                        if (postionInputRef.current.value()?.toString() === "")
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message: `Position cannot be empty`,
                                })
                            );

                        if (
                            postionInputRef.current.value() === 0 ||
                            postionInputRef.current.value() === "0"
                        )
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message: `Position value cannot be zero`,
                                })
                            );
                        if (
                            postionInputRef.current
                                .value()
                                ?.toString()
                                ?.includes(".")
                        )
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message: `Position value cannot be Decimal`,
                                })
                            );
                    }
                }}
                onClose={() => {
                    setEditDetailData({});
                }}
                disableSaveBtn={readOnly}
                width={"550px"}
            >
                <div className={styles.detailWrapper}>
                    <div className={styles.dataSection}>
                        <h3 className={styles.headerTitle}>Details</h3>
                        <div className={"form-row"}>
                            <p className="headerTitleRequired">Position*</p>
                            <p className="infoText">
                                Its an unique identifier name for the category
                            </p>
                            <Input
                                className="maxChildContainer"
                                required={true}
                                value={editDetailData.position}
                                onChange={e => {
                                    setEditDetailData({
                                        ...editDetailData,
                                        position: +e.target.value,
                                    });
                                }}
                                type={"number"}
                                ref={postionInputRef}
                                readOnly={readOnly}
                                disabled={readOnly}
                            ></Input>
                        </div>
                        <div className="form-row">
                            <p className="headerTitleRequired">Banner Text</p>
                            {readOnly ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: editDetailData?.bannerText,
                                    }}
                                ></div>
                            ) : (
                                <RichTextBox
                                    htmlContent={editDetailData?.bannerText}
                                    ref={textEditor}
                                />
                            )}
                        </div>
                        <div className="form-row">
                            <p className="headerTitleRequired">JT Ad Unit</p>
                            <ToggleSwitch
                                disabled={readOnly}
                                isToggleOn={editDetailData.JTAdUnitEnabled}
                                setText={true}
                                setIsToggleOn={val => {
                                    setEditDetailData({
                                        ...editDetailData,
                                        JTAdUnitEnabled: val,
                                    });
                                }}
                            />
                        </div>

                        <h3 className={`${styles.headerTitle} dp-parent`}>
                            Ad Unit Detail{" "}
                            <div
                                className={`${styles.editIcon} circleIconColor`}
                                onClick={() => {
                                    editAdunitDetail(question);
                                }}
                            >
                                <Icon
                                    icon="Edit"
                                    size="16px"
                                    enableHover={true}
                                />
                            </div>
                        </h3>
                        <div className={"form-row"}>
                            <p className="headerTitleRequired">Ad Unit</p>
                            <p className="infoText">
                                Its an unique identifier name for the category
                            </p>
                            <Input
                                className="maxChildContainer"
                                required={true}
                                value={`${editDetailData.name} : ${editDetailData.adUnitId}`}
                                disabled={true}
                            ></Input>
                        </div>
                    </div>
                </div>
            </SidePanelInfinity>
            <SidePanelInfinity
                title={`${adUnitId ? "Edit" : "Create"} Ad Unit`}
                ref={editAdunitRef}
                onSave={() => {
                    adUnitId ? updateAdUnit(adUnitId) : createNewAdUnit();
                }}
                onClose={cancelEdit}
                disableSaveBtn={readOnly}
                width={"95%"}
            >
                <div className={styles.adUnitWrapper}>
                    {adUnitId && !isCreate ? (
                        <CreateAdUnit
                            id={adUnitId}
                            isSidePanel={true}
                            ref={createAdUnitRef}
                        />
                    ) : (
                        <CreateAdUnit
                            id={null}
                            isSidePanel={true}
                            ref={createAdUnitRef}
                        />
                    )}
                </div>
            </SidePanelInfinity>
        </div>
    );
};

const SortableItem = SortableElement(
    ({
        question,
        index,
        dataIndex,
        selectedQuestionId,
        surveyPathId,
        actions,
        className,
        readOnly,
        editOptions,
        removeAdUnit,
    }) => (
        <DetailWrap
            question={question}
            index={index}
            dataIndex={dataIndex}
            selectedQuestionId={selectedQuestionId}
            surveyPathId={surveyPathId}
            actions={actions}
            className={className}
            readOnly={readOnly}
            editOptions={editOptions}
            removeAdUnit={removeAdUnit}
        ></DetailWrap>
    )
);

const SortableList = SortableContainer(({ children }) => {
    return <div className="question-details">{children}</div>;
});

const DetailsList = ({
    selectedQuestionId,
    questionsList,
    editOptions,
    className,
    setQuestionList,
    actions,
    readOnly = false,
    surveyPathId = "",
}) => {
    const [editId, setEditId] = useState([]);

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        if (typeof setQuestionList == "function") {
            let initialSortList = await arrayMove(
                questionsList[0]?.properties?.auBasedPriorityListings,
                oldIndex,
                newIndex
            );
            let updatedItems = cloneDeep(questionsList);
            updatedItems[0].properties.auBasedPriorityListings =
                initialSortList;
            await setQuestionList(updatedItems);
        }
    };

    const removeAdUnit = adUnit => {
        let adUnitsListInitial = cloneDeep([...questionsList]);
        let adUnitsInitial =
            adUnitsListInitial[0]?.properties?.auBasedPriorityListings;
        let updatedAdUnit = adUnitsInitial.filter(_ => _.id !== adUnit.id);
        let finalAdUnitsList = adUnitsListInitial?.map(_ => ({
            ..._,
            properties: {
                ..._.properties,
                auBasedPriorityListings: updatedAdUnit,
            },
        }));
        setQuestionList(finalAdUnitsList);
    };

    return (
        <>
            {editOptions && questionsList && (
                <SortableList onSortEnd={onSortEnd}>
                    {questionsList
                        ?.filter(_ => _.isActive)[0]
                        ?.properties?.auBasedPriorityListings?.map(
                            (question, index) =>
                                editId.indexOf(question.id) == -1 ? (
                                    question?.id ? (
                                        <SortableItem
                                            key={`sort-${question?.id}`}
                                            index={index}
                                            question={question}
                                            dataIndex={index + 1}
                                            selectedQuestionId={
                                                selectedQuestionId
                                            }
                                            surveyPathId={surveyPathId}
                                            actions={actions}
                                            className={className}
                                            readOnly={readOnly}
                                            editOptions={editOptions}
                                            removeAdUnit={removeAdUnit}
                                            disabled={true}
                                        />
                                    ) : null
                                ) : null
                        )}
                </SortableList>
            )}
            {!editOptions &&
                questionsList &&
                questionsList?.properties?.auBasedPriorityListings?.map(
                    (question, index) => (
                        <DetailWrap
                            key={
                                `detailWrap-${question.properties?.auBasedPriorityListings[0]?.id}` ||
                                `detailWrap-${question.id}`
                            }
                            question={question}
                            index={index}
                            selectedQuestionId={selectedQuestionId}
                            surveyPathId={surveyPathId}
                            actions={actions}
                            className={className}
                            readOnly={readOnly}
                            editOptions={editOptions}
                            removeAdUnit={removeAdUnit}
                        ></DetailWrap>
                    )
                )}
        </>
    );
};

const ListingDetails = ({
    type,
    cardTitle = "Details",
    isReadOnly = false,
    isSidePanel = false,
}) => {
    const configuration = null;
    const collectionState = useSelector(state => state.flowmanager.collection);
    const allAdUnitList = useSelector(state => state.listings.adUnits.list);
    const items = get(collectionState, "createCollection.detailsList", []);
    const addItems = get(collectionState, "createCollection.addItems", []);
    const dispatch = useDispatch();
    const urlParams = useParams();
    const addItemsBtnRef = useRef();

    const addDetail = get(
        collectionState,
        "createCollection.itemsDisable",
        false
    );

    const collectionOptionData =
        get(configuration, "details", [])?.length > 0
            ? REGISTER_DETAIL_DROPDOWN.filter(
                  ele => !ele.id.includes(get(configuration, "details", []))
              )
            : REGISTER_DETAIL_DROPDOWN;

    const addDefaultDetail = () => {
        dispatch(collectionActions.setDetailItems([...items]));
    };

    const addNewItems = _ => {
        addDefaultDetail();
        dispatch(collectionActions.setAddItems(true));
    };

    const questionAdded = newQuestion => {
        dispatch(questionsActions.addNewQuestion(newQuestion));
        dispatch(questionsActions.setAddQuestion(false));
    };

    useEffect(() => {
        if (addItems) dispatch(collectionActions.setAddItems(false));

        return () => {
            dispatch(collectionActions.resetCollection());
        };
    }, []);

    const assignRank = data => {
        dispatch(
            collectionActions.setDetailItems(
                data.map(item => {
                    return {
                        ...item,
                    };
                })
            )
        );
    };

    useEffect(() => {
        assignRank(items);
    }, [items.length]);

    const onAddItem = data => {
        dispatch(collectionActions.setDetailItems(data));
    };

    const scrollToButton = () => {
        setTimeout(() => {
            addItemsBtnRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }, 10);
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={`${!isSidePanel ? "flexHeightTab" : ""} row`}
        >
            <div className={`row`}>
                <p className={`headerTitle`}>{cardTitle}</p>
                <p className={`headerTitleRequired`}>Collection item</p>
                <div className={`${styles["add-new-adunit"]}`}>
                    {items.length > 0 && (
                        <div className={styles["adunit-list-wrap"]}>
                            <DetailsList
                                setQuestionList={list => {
                                    onAddItem(list);
                                }}
                                className={styles["question-details-card"]}
                                questionsList={items}
                                editOptions={true}
                                readOnly={isReadOnly}
                            ></DetailsList>
                        </div>
                    )}
                </div>
                {!isReadOnly && (
                    <div ref={addItemsBtnRef} className="relative">
                        {addItems && (
                            <div className={styles["addItemsDropdownWrapper"]}>
                                <ReactSelect
                                    options={allAdUnitList || []}
                                    isMulti
                                    menuIsOpen
                                    selectedIds={
                                        !isEmpty(items)
                                            ? items[0]?.properties?.auBasedPriorityListings?.map(
                                                  _ => _.id
                                              )
                                            : []
                                    }
                                    maxMenuHeight={214}
                                    listSelector={"name"}
                                    searchIdField={"id"}
                                    onCancelHandler={e => {
                                        dispatch(
                                            collectionActions.setAddItems(false)
                                        );
                                    }}
                                    customOnSelect={data => {
                                        let initialItemList = cloneDeep(items);
                                        let initialItemsAdUnit =
                                            !isEmpty(initialItemList) &&
                                            initialItemList[0]?.properties
                                                ?.auBasedPriorityListings;
                                        let initialData1 = cloneDeep(data);

                                        initialData1 = initialData1.map(_ => ({
                                            ..._,
                                            position:
                                                !isEmpty(initialItemsAdUnit) &&
                                                !isEmpty(
                                                    initialItemsAdUnit?.find(
                                                        el => el?.id === _.id
                                                    )
                                                )
                                                    ? initialItemsAdUnit?.find(
                                                          el => el?.id === _.id
                                                      ).position
                                                    : _.position || 0,
                                            JTAdUnitEnabled:
                                                initialItemsAdUnit?.length > 0
                                                    ? initialItemsAdUnit?.find(
                                                          item =>
                                                              item.id === _.id
                                                      )?.JTAdUnitEnabled ||
                                                      false
                                                    : false,

                                            ...(initialItemsAdUnit?.length >
                                                0 &&
                                            initialItemsAdUnit?.find(
                                                item => item.id === _.id
                                            )?.bannerText
                                                ? {
                                                      bannerText:
                                                          initialItemsAdUnit?.find(
                                                              item =>
                                                                  item.id ===
                                                                  _.id
                                                          )?.bannerText,
                                                  }
                                                : {}),
                                        }));

                                        let adUnitList =
                                            (!isEmpty(initialItemList) &&
                                                initialItemList[0]?.properties
                                                    ?.auBasedPriorityListings) ||
                                            [];
                                        adUnitList?.push(data.at(-1));

                                        if (isEmpty(initialItemList)) {
                                            initialItemList.push({
                                                properties: {
                                                    auBasedPriorityListings:
                                                        adUnitList?.map(el => ({
                                                            ...el,
                                                            position:
                                                                el?.position ||
                                                                0,
                                                            JTAdUnitEnabled: false,
                                                        })),
                                                },
                                                isActive: true,
                                                rank: 1,
                                            });
                                        }

                                        let transformedData1 =
                                            initialItemList?.map(_ => ({
                                                ..._,
                                                properties: {
                                                    auBasedPriorityListings:
                                                        initialData1,
                                                },
                                            }));
                                        onAddItem(transformedData1);
                                        scrollToButton();
                                    }}
                                />
                            </div>
                        )}
                        <Button
                            className={styles["add-question-button"]}
                            onClick={addNewItems}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={addDetail}
                        >
                            Add Items
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListingDetails;
