import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { ADS } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    createCreative: {
        basics: {
            name: "",
            code: "<!-- Enter your code here -->",
        },
        selectedTemplateId: "default",
        dedupeRules: [],
        error: false,
        isDeprecated: false,
    },
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.SURVEY.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.SURVEY.filterOperator,
        filtersOperator: FILTERS.SURVEY.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "creative"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchCreativeData = createAsyncThunk(
    "listings/creative/fetchCreativeListingData",
    async ({ refreshClicked, customFilter }, { dispatch, getState }) => {
        const pagination = get(getState(), "listings.creative.pagination", {});
        const params = !isEmpty(customFilter)
            ? customFilter
            : paginationParams(pagination);
        const { listings } = getState();
        let tagFilters = get(
            listings,
            "creative.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.CATEGORIES
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(creativeSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.creative.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.CREATIVE,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.creative.list", []);
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                ADS.FETCH_CREATIVE,
                "POST",
                !isEmpty(customFilter) ? customFilter : filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch creatives",
                        })
                    );
                    dispatch(
                        creativeSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(creativeActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.CREATIVE}`;
            dispatch(creativeSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Creative`;
            dispatch(creativeSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/creatives/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.creative.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.CREATIVES.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const deleteCreativeData = createAsyncThunk(
    "listings/creative/deleteCreativeListingData",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${ADS.DELETE_AD_CREATIVES}/${id}`,
            "DELETE"
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete creative",
                })
            );
        return response;
    }
);

export const createCreative = createAsyncThunk(
    "listings/creative/createCreative",
    async (id, { getState, dispatch }) => {
        const data = get(getState(), "listings.creative.createCreative", {});
        const response = await customFetch(
            `${ADS.CREATE_AD_CREATIVE}`,
            "POST",
            creativeDataFormat(data)
        );
    }
);

export const updateCreative = createAsyncThunk(
    "listings/creative/updateCreative",
    async (id, { getState, dispatch }) => {
        const data = get(getState(), "listings.creative.createCreative", {});
        const response = await customFetch(
            `${ADS.UPDATE_AD_CREATIVES}/${id}`,
            "PUT",
            creativeDataFormat(data)
        );
    }
);

export const fetchCreativeDataById = createAsyncThunk(
    "listings/creative/fetchCreativeDataById",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${ADS.GET_SINGLE_AD_CREATIVE}/${id}`,
            "GET"
        );
        if (
            get(response, "data.data.id", null) != null &&
            get(response, "data.success", false)
        ) {
        } else
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch creative details",
                })
            );
        return response;
    }
);

const creativeSlice = createSlice({
    name: "creative",
    initialState,
    reducers: {
        resetState() {
            return { ...initialState };
        },
        setCreativeCode(state, action) {
            state.createCreative.basics.code = action.payload;
        },
        setCreativeBasicsName(state, action) {
            state.createCreative.basics.name = action.payload;
            state.createCreative.error = action.payload.trim() === "";
        },
        setCreativeDeprecated(state, action) {
            state.createCreative.basics.isDeprecated = action.payload;
        },
        setTemplateType(state, action) {
            state.createCreative.selectedTemplateId = action.payload;
        },
        setCreatives(state, action) {
            state.list = action.payload;
        },
        setDedupe(state, action) {
            state.createCreative.dedupeRules = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCreativeData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchCreativeDataById.pending, state => {
            state.loading = true;
        });
        builder.addCase(createCreative.pending, state => {
            state.loading = true;
        });
        builder.addCase(updateCreative.pending, state => {
            state.loading = true;
        });
        builder.addCase(createCreative.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(updateCreative.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(fetchCreativeData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchCreativeDataById.fulfilled, (state, action) => {
            const data = get(action.payload, "data.data", {});
            state.loading = false;
            state.createCreative.basics = {
                name: data.name,
                code: data.code,
                isDeprecated: data.isDeprecated,
            };
            state.createCreative.dedupeRules = data.dedupeKeys || [];
            state.createCreative.selectedTemplateId = data.templateType;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

const creativeDataFormat = data => {
    return {
        name: data.basics.name,
        code: data.basics.code,
        isDeprecated: data.basics.isDeprecated,
        dedupeKeys: data.dedupeRules,
        templateType: data.selectedTemplateId,
    };
};

export const creativeActions = creativeSlice.actions;
export default creativeSlice.reducer;
