import { get } from "lodash";
import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export const listingtDateFormat = date => {
    return `${moment(date).format("MMMM Do YYYY, h:mm:ss a")}`;
};

export const shortListingtDateFormat = date => {
    return `${moment(date).format("MMM Do YY, h:mm:ss a")}`;
};

export const defaultDateFormat = date => {
    return `${moment(date).format("MMM Do YYYY, h:mm a")}`;
};

export const onlyDateLabel = date => {
    return `${moment(date).format("DD MMM YYYY")}`;
};

export const utcISO8601Format = date => {
    return `${moment.utc(date).format()}`;
};

export const shortDateFormat = date => {
    return `${moment(date).format("D MMM YYYY")}`;
};

export const defaultShortDateFormat = date => {
    return `${moment(date).format("MMM Do YYYY")}`;
};

export const dateFormat2 = date => {
    return `${moment(date).format("dddd, MMMM D, YYYY")}`;
};

export const dateFormat3 = date => {
    return `${moment(date).format("MMMM DD, YYYY")}`;
};

export const timeFormat = (date, timeConstraint) => {
    return `${moment(date).format(timeConstraint)}`;
};

export const dateTimeAgoFormat = date => {
    return `${moment(new Date(date), "DD-MM-YYYY HH:mm").fromNow()}`;
};

export const dateFormat4 = date => {
    return `${moment(date).format("DD MMM YY, h:mm a")}`;
};

export const updateTime = (date, time) => {
    const hour = +get(time, "hour", "00");
    let updatedDate = moment(date).set({
        hour: hour,
        minute: +get(time, "mins", "00"),
    });
    if (get(time, "isPM", false) && hour !== 12) {
        updatedDate.add(12, "hours");
    }
    if (!get(time, "isPM", false) && hour === 12) {
        updatedDate.add(-12, "hours");
    }
    return new Date(updatedDate);
};

export const timePickerValue = date => {
    return {
        hour: timeFormat(date, "hh"),
        mins: timeFormat(date, "mm"),
        isPM: timeFormat(date, "a") === "pm",
    };
};

export const isEqualDate = (date1, date2, granularity = "day") => {
    return moment(date1).isSame(date2, granularity);
};

export const addDate = (date, value, granularity = "day") => {
    return moment(date).add(value, granularity);
};

export const calendarDateFormat = date => {
    return `${moment(date).format("DD MMM YYYY")}`;
};

export const uploadDateFormat = date => {
    return `${moment(date).format("YYYY-MM-DD")}`;
};

export const numberShortDateFormat = date => {
    return `${moment(date).format("MM/DD/YY")}`;
};

export const numberShortDateFormatWithTime = date => {
    return `${moment(date).format("MM/DD/YY, h:mm:ss a")}`;
};

export const getDurationFromSec = sec => {
    var duration = moment.duration(sec, "seconds");
    return duration.format("H[h] m[m] s[s]");
};

export const timeConvertMinToHrs = num => {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return hours + ":" + minutes;
};

export const convertDateToISOformat = date => {
    const outputFormat = "YYYY-MM-DDTHH:mm:ss[Z]";
    const timeStamp = date.getTime();
    const offset = date.getTimezoneOffset() * 60 * 1000;
    return moment(new Date(timeStamp - offset))
        .utc()
        .format(outputFormat);
};

export const convertStringDateToDate = date => {
    const dateArray = date?.split("-");
    let dateForCalendar = new Date(
        dateArray[0],
        dateArray[1] - 1,
        dateArray[2]
    );
    return dateForCalendar;
};
