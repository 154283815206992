import { combineReducers } from "redux";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, cloneDeep, isEmpty } from "lodash";
import { CONFIGURATIONS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    feedFeatureMapListData,
    getFeatureMapItem,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { toastListActions } from "../toastList/toastList";
import { getFeatureMap, getFixedFeatureMap } from "../../utils/featuremapUtils";
import { MESSAGES } from "../../constants/messages";
import { getRequiredObject } from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    abTestDetails,
    createChannelMapData,
    defaultChannels,
    inputDropdownDataRefactorToApi,
} from "../../utils/surveyConfigUtils";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { featureSetsActions } from "../survey/featureSets";

export const fetchSurveyConfigsList = createAsyncThunk(
    "listings/surveyConfigs/fetchSurveyConfigsList",
    async (_, { dispatch, getState }) => {
        const { listings } = getState();
        const state = get(listings, "config.surveyConfigs.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "config.surveyConfigs.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.SURVEY_CONFIG
        );
        const filterParamsList = [
            "filters",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        let dispatchSearchError = true;
        const response = await customFetch(
            CONFIGURATIONS.FETCH_SURVEY_CONFIG,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch Survey configs",
                    })
                );
                dispatch(
                    surveyConfigsSlice.actions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );
        const data = get(response, "data", []);
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.SURVEY_CONFIG}`;
            dispatch(surveyConfigsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Survey Config`;
            dispatch(surveyConfigsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchSurveyConfigsCreatePageList = createAsyncThunk(
    "listings/surveyConfigs/fetchSurveyConfigsCreatePageList",
    async (_, { dispatch, getState }) => {
        const responses = await fetchAll([
            customFetch(CONFIGURATIONS.GET_LANDERS, "GET"),
            customFetch(CONFIGURATIONS.GET_DOMAINS, "GET"),
            customFetch(CONFIGURATIONS.GET_FEATUREMAP_LIST_DATA, "POST", {
                limit: 1000,
                offset: 0,
            }),
            customFetch(CONFIGURATIONS.GET_AB_TESTS, "GET"),
        ]);
        const landers = get(responses, "0.data", []);
        const products = get(responses, "1.data", []);
        const featureMapList = get(responses, "2.data.data", []);

        // Replace this once API response is integrated
        const channelResponse = defaultChannels();
        const channelData = get(channelResponse, "data", []).map(channel => ({
            id: channel,
            name: channel,
        }));
        const channelMaps = channelData;
        const abTests = get(responses, "3.data", []);

        if (landers.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch landers list!",
                })
            );
        if (products.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch products list!",
                })
            );
        if (featureMapList.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch feature map list!",
                })
            );
        if (abTests.length === 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch A/B Test list",
                })
            );
        return { landers, products, featureMapList, abTests, channelMaps };
    }
);

export const fetchSurveyConfigData = createAsyncThunk(
    "listings/surveyConfigs/fetchSurveyConfigData",
    async (version, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "config.surveyConfigs", {});
        const response = await customFetch(
            `${CONFIGURATIONS.GET_SAVED_CONFIG}/${version}`,
            "GET"
        );
        const data = get(response, "data", []);
        dispatch(featureSetsActions.setSelectedByIds(data.featureSets || []));
        if (data.length == 0) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Config data!",
                    autoClose: 5000,
                })
            );
        }
        return [data, state];
    }
);

export const deleteSurveyConfig = createAsyncThunk(
    "listings/surveyConfigs/deleteSurveyConfig",
    async (id, { dispatch, getState }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.DELETE_SURVEY_CONFIG}/${id}`,
            "DELETE"
        );
        if (get(response, "status", "") == 204) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Successfully deleted the configuration with version - ${id}`,
                })
            );
            dispatch(fetchSurveyConfigsList());
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete the configuration with version - ${id}`,
                })
            );
        }
        return response;
    }
);

export const createSurveyConfig = createAsyncThunk(
    "listings/surveyConfigs/createSurveyConfig",
    async (obj, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "config.surveyConfigs", {});
        const params = getSurveyConfigData(state, getState);
        const response =
            obj.method == "POST"
                ? await customFetch(
                      CONFIGURATIONS.CREATE_SURVEY_CONFIG,
                      "POST",
                      params
                  )
                : await customFetch(
                      `${CONFIGURATIONS.CREATE_SURVEY_CONFIG}/${obj.version}`,
                      "PUT",
                      params,
                      {},
                      {},
                      null,
                      null,
                      err => {
                          let errorRes = err.response?.data?.Details;
                          if (
                              errorRes &&
                              errorRes.split("feature")[2]?.split("; ")[0]
                          ) {
                              dispatch(surveyConfigActions.setFixMapping(true));
                              dispatch(
                                  surveyConfigActions.setFixedUpdatedList()
                              );
                              dispatch(
                                  toastListActions.setToastList({
                                      type: "Error",
                                      message: `Failed to save. Deleted values from some feature(s) have been removed.`,
                                      autoClose: false,
                                  })
                              );
                              dispatch(
                                  toastListActions.setToastList({
                                      type: "Error",
                                      message: `Try saving again to go ahead.`,
                                      autoClose: false,
                                  })
                              );
                          }
                      }
                  );
        const data = get(response, "data", []);
        if (response && response.status == 201) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: ` ${
                        obj.method === "POST" ? "Created" : "Updated"
                    } Config Successfully`,
                })
            );
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to ${
                        obj.method === "POST" ? "create" : "Update"
                    } Config`,
                })
            );
        }
        return data;
    }
);

export const getSurveyConfigData = (data, getState) => {
    const featureSetsData = getState().listings.config.configFeatureSet;
    return {
        version: get(data, "configBasics.name", ""),
        domain: get(data, "configBasics.product", ""),
        lander: get(data, "configBasics.lander", ""),
        featureMap: getState().listings.config.surveyConfigs.fixMapping
            ? getFixedFeatureMap(data.featureMaps.savedList)
            : getFeatureMap(data.featureMaps.savedList),
        abtMap: get(data, "channelMapData", []),
        featureSets: featureSetsData.selectedItems.filter(
            id => !featureSetsData.deletedItems.includes(id)
        ),
    };
};

export const getInitialUpdatedABTestChannel = createAsyncThunk(
    "listings/offergroups/initialUpdatedABTest",
    async (_, { getState }) => {
        const { channelMapData, abTests, channelMaps } =
            getState().listings.config.surveyConfigs;
        let initialChannels = channelMapData;
        const abtPresent = initialChannels?.map(ab => ab.abtID).flat();
        const channelPresent = initialChannels
            ?.map(channel => channel.channel)
            .flat();
        let finalPayload = {
            abTestUpdatedList: [],
            channelUpdatedList: [],
        };
        let abTestInitialCopy = abTests;
        let channelInitialCopy = channelMaps;

        let updatedAbTest = abTestInitialCopy?.filter(el => {
            return !abtPresent?.includes(el.id);
        });
        let updatedChannel = channelInitialCopy?.filter(el => {
            return !channelPresent?.includes(el.id);
        });
        finalPayload.abTestUpdatedList = updatedAbTest;
        finalPayload.channelUpdatedList = updatedChannel;
        return finalPayload;
    }
);

export const historyListingsTable = createAsyncThunk(
    "listings/surveyConfig/historySurveyConfigListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${CONFIGURATIONS.FETCH_HISTORY_LISTINGS_SURVEYCONFIG}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

const scopeChoices = [
    "global",
    "landing",
    "survey",
    "offers",
    "offer path",
    "listings",
    "fulfillment",
];

const initialState = {
    list: [],
    landers: { list: [] },
    products: { list: [] },
    updatedAt: new Date(),
    loading: false,
    searchTerm: "",
    mainLoader: false,
    featureMapListData: [],
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "version",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.SURVEY_CONFIG.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.SURVEY_CONFIG.filterOperator,
        filtersOperator: FILTERS.SURVEY_CONFIG.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "surveyconfigs"),
    },
    configBasics: {
        name: "",
        lander: null,
        product: null,
    },
    featureMaps: {
        updatedList: [],
        selected: [],
        selectedDetails: null,
        selectedListActiveItem: "",
        savedList: [],
    },
    featureSets: [],
    channelMapData: [],
    abTests: { list: [] },
    channelMaps: { list: [] },
    updatedABTests: { list: [] },
    updatedChannels: { list: [] },
    tableError: MESSAGES.TABLE.noDataFromApi,
    historyData: [],
    historyDataRaw: [],
    createSurveyConfig: {
        configBasics: { name: "", lander: null, product: null },
        channelMapData: [],
        featureMaps: {
            updatedList: [],
            selected: [],
            selectedDetails: null,
            selectedListActiveItem: "",
            savedList: [],
        },
        featureSets: [],
    },
    createSurveyConfigBackup: {
        configBasics: { name: "", lander: null, product: null },
        channelMapData: [],
        featureMaps: {
            updatedList: [],
            selected: [],
            selectedDetails: null,
            selectedListActiveItem: "",
            savedList: [],
        },
        featureSets: [],
    },
    fixMapping: false,
};

export const onFilterApplied = createAsyncThunk(
    "listings/surveyConfigs/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData =
            listings.config.surveyConfigs.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.SURVEY_CONFIG.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const surveyConfigsSlice = createSlice({
    name: "surveyConfigs",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        setConfigName(state, action) {
            state.configBasics.name = action.payload;
        },
        setConfigLander(state, action) {
            state.configBasics.lander = action.payload;
        },
        setConfigProduct(state, action) {
            state.configBasics.product = action.payload;
        },
        //feature map
        setVariantFeatureMapSelected(state, action) {
            state.featureMaps.selected = action.payload;
        },
        setVariantFeatureMapSelectedDetails(state, action) {
            state.featureMaps.selectedDetails = action.payload;
        },
        setSelectedListActiveItem(state, action) {
            state.featureMaps.selectedListActiveItem = action.payload;
        },
        setSavedList(state, action) {
            state.featureMaps.savedList = action.payload || [];
        },
        setUpdatedList(state, action) {
            state.featureMaps.updatedList = action.payload;
        },
        setFixedUpdatedList(state, action) {
            state.featureMaps.updatedList = state.featureMaps.updatedList?.map(
                item => ({
                    ...item,
                    value:
                        item.value?.filter(val => item.choice?.includes(val)) ||
                        [],
                    isError:
                        item.value?.filter(val => item.choice?.includes(val))
                            ?.length > 0
                            ? false
                            : true,
                })
            );
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        resetData(state, action) {
            state.configBasics.name = "";
            state.configBasics.lander = null;
            state.configBasics.product = null;
            state.channelMapData = [];

            let featMap = {
                updatedList: [],
                selected: [],
                selectedDetails: null,
                selectedListActiveItem: "",
                savedList: [],
            };
            state.featureMaps = featMap;
            state.createSurveyConfig = initialState.createSurveyConfig;
            state.createSurveyConfigBackup =
                initialState.createSurveyConfigBackup;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setChannelMapData(state, action) {
            state.channelMapData = action.payload;
        },
        setABTest(state, action) {
            state.abTests = action.payload;
        },
        setChannel(state, action) {
            state.channelMaps = action.payload;
        },
        setUpdatedABTest(state, action) {
            state.updatedABTests = action.payload;
        },
        setUpdatedChannel(state, action) {
            state.updatedChannels = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
        setCreateSurveyConfig(state, action) {
            state.createSurveyConfig = {
                configBasics: state.configBasics,
                channelMapData: state.channelMapData,
                featureMaps: state.featureMaps,
                featureSets: state.featureSets,
            };
        },
        setFixMapping(state, action) {
            state.fixMapping = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSurveyConfigsList.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchSurveyConfigsList.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []).map(config => ({
                version: config.version,
                lander: config.lander,
                createdAt: config.createdAt,
                abTest: abTestDetails(config.abtMap),
            }));
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchSurveyConfigsCreatePageList.pending, state => {
            state.loading = true;
        });
        builder.addCase(
            fetchSurveyConfigsCreatePageList.fulfilled,
            (state, action) => {
                const {
                    landers,
                    products,
                    featureMapList,
                    abTests,
                    channelMaps,
                } = action.payload;
                let updateList = cloneDeep(featureMapList);
                updateList = updateList.map(val => {
                    return {
                        ...val,
                        isError:
                            val.valuekind === "default value" ||
                            (val.value && val.value.length > 0)
                                ? false
                                : true,
                        scopeChoices: scopeChoices,
                    };
                });
                state.landers.list = landers;
                state.products.list = products;
                state.featureMaps.updatedList = [...updateList];
                state.featureMapListData = [...updateList];
                state.abTests = abTests;
                state.channelMaps = channelMaps;
                state.loading = false;
                state.createSurveyConfigBackup.featureMaps = state.featureMaps;
            }
        );
        builder.addCase(fetchSurveyConfigData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchSurveyConfigData.fulfilled, (state, action) => {
            state.configBasics.name = action.payload[0].version;
            state.configBasics.lander = action.payload[0].lander;
            state.configBasics.product = action.payload[0].domain;
            let tempChannelMapData = createChannelMapData(
                action.payload[0].abtMap,
                action.payload[1].abTests
            );
            let finalChannelMapData =
                inputDropdownDataRefactorToApi(tempChannelMapData);
            state.channelMapData = finalChannelMapData;
            if (action.payload[0].featureMap != null) {
                let selectedKeys = getFeatureMapItem(
                    action.payload[0].featureMap,
                    scopeChoices
                ).map(item => {
                    return item.key;
                });
                let featMap = {
                    updatedList: [...action.payload[1].featureMapListData],
                    selected: selectedKeys,
                    selectedDetails: selectedKeys[selectedKeys.length - 1],
                    selectedListActiveItem:
                        selectedKeys[selectedKeys.length - 1],
                    savedList: getFeatureMapItem(
                        action.payload[0].featureMap,
                        scopeChoices
                    ),
                };
                featMap.updatedList = feedFeatureMapListData(
                    action.payload[0].featureMap,
                    featMap.updatedList
                );
                state.featureMaps = featMap;
            }
            state.featureSets = action.payload[0].featureSets;
            state.createSurveyConfig = {
                configBasics: state.configBasics,
                channelMapData: state.channelMapData,
                featureMaps: state.featureMaps,
            };
            state.createSurveyConfigBackup = {
                configBasics: state.configBasics,
                channelMapData: state.channelMapData,
                featureMaps: state.featureMaps,
                featureSets: state.featureSets || [],
            };
            state.loading = false;
        });
        builder.addCase(
            getInitialUpdatedABTestChannel.fulfilled,
            (state, action) => {
                state.loading = false;
                const { abTestUpdatedList, channelUpdatedList } =
                    action.payload;
                state.updatedABTests = abTestUpdatedList;
                state.updatedChannels = channelUpdatedList;
            }
        );
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const surveyConfigActions = surveyConfigsSlice.actions;

export default surveyConfigsSlice.reducer;
