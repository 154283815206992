export const ALLOWEDDAYSLIST = [
    { id: "monday", label: "Monday" },
    { id: "tuesday", label: "Tuesday" },
    { id: "wednesday", label: "Wednesday" },
    { id: "thursday", label: "Thursday" },
    { id: "friday", label: "Friday" },
    { id: "saturday", label: "Saturday" },
    { id: "sunday", label: "Sunday" },
];

export const BROWSERSLIST = [
    {
        id: "Samsung Browser",
        label: "Samsung Browser",
        icon: "Browser-Samsung",
        logo: true,
    },
    { id: "Chrome", label: "Chrome", icon: "Browser-Chrome", logo: true },
    {
        id: "Google Search App",
        label: "Google Search App",
        icon: "Browser-GoogleSearch",
        logo: true,
    },
    {
        id: "Microsoft Edge",
        label: "Microsoft Edge",
        icon: "Browser-MicrosoftEdge",
        logo: true,
    },
    { id: "Safari", label: "Safari", icon: "Browser-Safari", logo: true },
    { id: "Facebook", label: "Facebook", icon: "Browser-Facebook", logo: true },
    {
        id: "Chrome Webview",
        label: "Chrome Webview",
        icon: "Browser-ChromeWebview",
        logo: true,
    },
    { id: "Firefox", label: "Firefox", icon: "Browser-Firefox", logo: true },
    {
        id: "DuckDuckGo Privacy Browser",
        label: "DuckDuckGo Privacy Browser",
        icon: "Browser-DuckDuckGo",
        logo: true,
    },
    {
        id: "Instagram App",
        label: "Instagram App",
        icon: "Browser-Instagram",
        logo: true,
    },
    {
        id: "Headless Chrome",
        label: "Headless Chrome",
        icon: "Browser-HeadlessChrome",
        logo: true,
    },
    {
        id: "Pinterest",
        label: "Pinterest",
        icon: "Browser-Pinterest",
        logo: true,
    },
    { id: "Opera", label: "Opera", icon: "Browser-Opera", logo: true },
    {
        id: "MIUI Browser",
        label: "MIUI Browser",
        icon: "Browser-MIUIBroswer",
        logo: true,
    },
];

export const COUNTRIESLIST = [
    { id: "US", label: "USA", icon: "Country-USA", logo: true },
    { id: "GB", label: "UK", icon: "Country-UnitedKingdom", logo: true },
    { id: "IN", label: "India", icon: "Country-India", logo: true },
];

export const DEVICESLIST = [
    { id: "smartphone", label: "Smartphone", icon: "Phone" },
    { id: "desktop", label: "Desktop", icon: "Desktop" },
    { id: "phablet", label: "Phablet", icon: "Phablet" },
    { id: "tablet", label: "Tablet", icon: "Tablet" },
    {
        id: "portable media player",
        label: "Portable Media Player",
        icon: "MusicPlayer",
    },
    { id: "television", label: "Television", icon: "TV" },
    { id: "console", label: "Console", icon: "Console" },
    { id: "feature phone", label: "Feature Phone", icon: "FeaturePhone" },
];

export const OPERATINGSYSTEMLIST = [
    { id: "Android", label: "Android", icon: "OS-Android", logo: true },
    {
        id: "iOS",
        label: "iOS",
        icon: "OS-IOS",
        whiteIcon: "OS-IOSWhite",
        logo: true,
    },
    { id: "Windows", label: "Windows", icon: "OS-Windows", logo: true },
    {
        id: "Mac",
        label: "Mac",
        icon: "OS-Apple",
        whiteIcon: "OS-AppleWhite",
        logo: true,
    },
    { id: "Chrome OS", label: "Chrome OS", icon: "OS-Chrome", logo: true },
    { id: "Ubuntu", label: "Ubuntu", icon: "OS-Ubuntu", logo: true },
    { id: "GNU/Linux", label: "GNU/Linux", icon: "OS-Linux", logo: true },
    { id: "KaiOS", label: "KaiOS", icon: "OS-kaios", logo: true },
    {
        id: "PlayStation",
        label: "PlayStation",
        icon: "OS-PlayStation",
        logo: true,
    },
    { id: "Tizen", label: "Tizen", icon: "OS-Tizen", logo: true },
    {
        id: "Windows Phone",
        label: "Windows Phone",
        icon: "OS-WindowsPhone",
        logo: true,
    },
    { id: "Fedora", label: "Fedora", icon: "OS-Fedora", logo: true },
    {
        id: "Symbian^3",
        label: "Symbian^3",
        icon: "OS-Symbian",
        whiteIcon: "OS-SymbianWhite",
        logo: true,
    },
    {
        id: "Symbian OS Series 60",
        label: "Symbian OS Series 60",
        icon: "OS-Series6",
        whiteIcon: "OS-Series6White",
        logo: true,
    },
    {
        id: "Solaris",
        label: "Solaris",
        icon: "OS-Solaris",
        whiteIcon: "OS-SolarisWhite",
        logo: true,
    },
    {
        id: "BlackBerry Tablet OS",
        label: "BlackBerry Tablet OS",
        icon: "OS-BlackBerry",
        whiteIcon: "OS-BlackBerryWhite",
        logo: true,
    },
    {
        id: "Sailfish OS",
        label: "Sailfish OS",
        icon: "OS-Sailfish",
        whiteIcon: "OS-SailfishWhite",
        logo: true,
    },
    { id: "NetBSD", label: "NetBSD", icon: "OS-NetBSD", logo: true },
    {
        id: "Firefox OS",
        label: "Firefox OS",
        icon: "Browser-Firefox",
        logo: true,
    },
    {
        id: "Debian",
        label: "Debian",
        icon: "OS-Debian",
        whiteIcon: "OS-DebianWhite",
        logo: true,
    },
];

export const REGIONSLIST = [
    { id: "AL", label: "Alabama" },
    { id: "AK", label: "Alaska" },
    { id: "AS", label: "American Samoa" },
    { id: "AZ", label: "Arizona" },
    { id: "AR", label: "Arkansas" },
    { id: "CA", label: "California" },
    { id: "CO", label: "Colorado" },
    { id: "CT", label: "Connecticut" },
    { id: "DE", label: "Delaware" },
    { id: "DC", label: "District Of Columbia" },
    { id: "FM", label: "Federated States Of Micronesia" },
    { id: "FL", label: "Florida" },
    { id: "GA", label: "Georgia" },
    { id: "GU", label: "Guam" },
    { id: "HI", label: "Hawaii" },
    { id: "ID", label: "Idaho" },
    { id: "IL", label: "Illinois" },
    { id: "IN", label: "Indiana" },
    { id: "IA", label: "Iowa" },
    { id: "KS", label: "Kansas" },
    { id: "KY", label: "Kentucky" },
    { id: "LA", label: "Louisiana" },
    { id: "ME", label: "Maine" },
    { id: "MH", label: "Marshall Islands" },
    { id: "MD", label: "Maryland" },
    { id: "MA", label: "Massachusetts" },
    { id: "MI", label: "Michigan" },
    { id: "MN", label: "Minnesota" },
    { id: "MS", label: "Mississippi" },
    { id: "MO", label: "Missouri" },
    { id: "MT", label: "Montana" },
    { id: "NE", label: "Nebraska" },
    { id: "NV", label: "Nevada" },
    { id: "NH", label: "New Hampshire" },
    { id: "NJ", label: "New Jersey" },
    { id: "NM", label: "New Mexico" },
    { id: "NY", label: "New York" },
    { id: "NC", label: "North Carolina" },
    { id: "ND", label: "North Dakota" },
    { id: "MP", label: "Northern Mariana Islands" },
    { id: "OH", label: "Ohio" },
    { id: "OK", label: "Oklahoma" },
    { id: "OR", label: "Oregon" },
    { id: "PW", label: "Palau" },
    { id: "PA", label: "Pennsylvania" },
    { id: "PR", label: "Puerto Rico" },
    { id: "RI", label: "Rhode Island" },
    { id: "SC", label: "South Carolina" },
    { id: "SD", label: "South Dakota" },
    { id: "TN", label: "Tennessee" },
    { id: "TX", label: "Texas" },
    { id: "UT", label: "Utah" },
    { id: "VT", label: "Vermont" },
    { id: "VI", label: "Virgin Islands" },
    { id: "VA", label: "Virginia" },
    { id: "WA", label: "Washington" },
    { id: "WV", label: "West Virginia" },
    { id: "WI", label: "Wisconsin" },
    { id: "WY", label: "Wyoming" },
];

export const TIMEZONESLIST = [
    { id: "America/New_York", label: "America/New_York (UTC -04:00)" },
    { id: "America/Chicago", label: "America/Chicago (UTC -05:00)" },
    { id: "America/Denver", label: "America/Denver (UTC -06:00)" },
    { id: "America/Phoenix", label: "America/Phoenix (UTC -07:00)" },
    { id: "America/Los_Angeles", label: "America/Los_Angeles (UTC -07:00)" },
    { id: "America/Anchorage", label: "America/Anchorage (UTC -08:00)" },
    { id: "Pacific/Honolulu", label: "Pacific/Honolulu (UTC -10:00)" },
    { id: "Europe/London", label: "Europe/London (UTC +01:00)" },
    { id: "Asia/Kolkata", label: "Asia/Kolkata (UTC +5:30)" },
];

export const KEYWORDSDROPDOWN = [
    { id: "exact", label: "exact" },
    { id: "broad", label: "broad" },
];

export const ABTESTDROPDOWN = [
    { id: "abt", label: "abt" },
    { id: "abt1", label: "abt1" },
    { id: "abt2", label: "abt2" },
    { id: "abt3", label: "abt3" },
    { id: "abt4", label: "abt4" },
    { id: "abt5", label: "abt5" },
    { id: "abt6", label: "abt6" },
    { id: "abt7", label: "abt7" },
    { id: "abt8", label: "abt8" },
    { id: "abt9", label: "abt9" },
    { id: "abt10", label: "abt10" },
    { id: "abt11", label: "abt11" },
    { id: "abt12", label: "abt12" },
    { id: "abt13", label: "abt13" },
    { id: "abt14", label: "abt14" },
    { id: "abt15", label: "abt15" },
    { id: "abt16", label: "abt16" },
    { id: "abt17", label: "abt17" },
    { id: "abt18", label: "abt18" },
    { id: "abt19", label: "abt19" },
    { id: "abt20", label: "abt20" },
    { id: "abt21", label: "abt21" },
    { id: "abt22", label: "abt22" },
    { id: "abt23", label: "abt23" },
    { id: "abt24", label: "abt24" },
    { id: "abt25", label: "abt25" },
    { id: "abt26", label: "abt26" },
    { id: "abt27", label: "abt27" },
    { id: "abt28", label: "abt28" },
    { id: "abt29", label: "abt29" },
    { id: "abt30", label: "abt30" },
    { id: "abt31", label: "abt31" },
    { id: "abt32", label: "abt32" },
    { id: "abt33", label: "abt33" },
    { id: "abt34", label: "abt34" },
    { id: "abt35", label: "abt35" },
    { id: "abt36", label: "abt36" },
    { id: "abt37", label: "abt37" },
    { id: "abt38", label: "abt38" },
    { id: "abt39", label: "abt39" },
    { id: "abt40", label: "abt40" },
    { id: "abt41", label: "abt41" },
    { id: "abt42", label: "abt42" },
    { id: "abt43", label: "abt43" },
    { id: "abt44", label: "abt44" },
    { id: "abt45", label: "abt45" },
    { id: "abt46", label: "abt46" },
    { id: "abt47", label: "abt47" },
    { id: "abt48", label: "abt48" },
    { id: "abt49", label: "abt49" },
    { id: "abt50", label: "abt50" },
];

export const flowTargetingParams = [
    {
        id: "ans",
        name: "Answer",
        icon: "Addnotes",
    },
    {
        id: "utm_source",
        name: "UTM Sources",
        icon: "UTMSource",
    },
    {
        id: "utm_campaign",
        name: "UTM Campaigns",
        icon: "Campaign",
    },
    {
        id: "utm_term",
        name: "UTM Terms",
        icon: "Campaign",
    },
    {
        id: "utm_medium",
        name: "UTM Medium",
        icon: "UTMMedium",
    },
    {
        id: "gender",
        name: "Gender",
        icon: "Gender",
    },
    {
        id: "country",
        name: "Country",
        icon: "Country",
    },
    {
        id: "job_type",
        name: "Job Types",
        icon: "JobType",
    },
    {
        id: "lkw",
        name: "Landing Keywords",
        icon: "LandingKeyword",
    },
    {
        id: "user_status",
        name: "User Status",
        icon: "UserStatus",
    },
    {
        id: "keyword",
        name: "Keywords",
        icon: "KeyWord",
    },
];

export const numberOfSets = [
    { id: "utmSources", name: "UTM Sources", icon: "UTMSource" },
    { id: "region", name: "Region", icon: "Region" },
    { id: "keywords", name: "Keywords", icon: "KeyWord" },
    { id: "ageRange", name: "Age Range", icon: "AgeRange" },
    { id: "genders", name: "Gender", icon: "Gender" },
    { id: "countries", name: "Countries", icon: "Country" },
    { id: "zipCodes", name: "Zip Codes", icon: "ZipCode" },
    { id: "timeOfDay", name: "Time of Day", icon: "TimeOfDay" },
    { id: "utmCampaigns", name: "UTM Campaigns", icon: "Campaign" },
    { id: "utmMediums", name: "UTM Mediums", icon: "UTMMedium" },
    { id: "os", name: "Operating Systems", icon: "OperatingSystem" },
    { id: "browsers", name: "Browsers", icon: "Browser" },
    { id: "devices", name: "Devices", icon: "Device" },
    { id: "allowedDays", name: "Allowed Days", icon: "AllowedDays" },
    { id: "ABTests", name: "A/B Tests", icon: "ABTest" },
    { id: "keyValues", name: "Key Values", icon: "KeyValue" },
];

export const numberOfSetsOfferPath = [
    ...numberOfSets,
    { id: "jobTypes", name: "Job Type", icon: "JobType" },
    // { id: "spamCheck", name: "Spam Check", icon: "SpamIP" },
    { id: "landingKeywords", name: "Landing Keyword", icon: "LandingKeyword" },
    { id: "landingJobTypes", name: "Landing Job Type", icon: "LandingJobType" },
    { id: "visitCount", name: "Visit Count", icon: "VisitCount" },
    {
        id: "registeredVisitCount",
        name: "Visit Count after registration",
        icon: "VisitCountAfterRegistrationForward",
    },
    { id: "userStatus", name: "User Status", icon: "UserStatus" },
    {
        id: "keywordCategories",
        name: "Keyword Categories",
        icon: "KeywordCategory",
    },
    { id: "city", name: "City", icon: "City" },
    { id: "utmContents", name: "UTM Contents", icon: "Campaign" },
];

export const numberOfSetsSurveyPath = [
    ...numberOfSets,
    { id: "userStatus", name: "User Status", icon: "UserStatus" },
    {
        id: "keywordCategories",
        name: "Keyword Categories",
        icon: "KeywordCategory",
    },
    { id: "city", name: "City", icon: "City" },
    // { id: "spamCheck", name: "Spam Check", icon: "SpamIP" },
    { id: "utmContents", name: "UTM Contents", icon: "Campaign" },
];

export const numberOfSetsFeatureSets = [
    { id: "utmSources", name: "UTM Sources", icon: "UTMSource" },
    { id: "clientIPs", name: "Client IP", icon: "ClientIP" },
    // { id: "region", name: "Region", icon: "Region"  },
    //   { id: "keywords", name: "Keywords", icon: "KeyWord" },
    { id: "ageRange", name: "Age Range", icon: "AgeRange" },
    { id: "genders", name: "Gender", icon: "Gender" },
    { id: "countries", name: "Countries", icon: "Country" },
    { id: "zipCodes", name: "Zip Codes", icon: "ZipCode" },
    // { id: "timeOfDay", name: "Time of Day", icon: "TimeOfDay" },
    { id: "utmCampaigns", name: "UTM Campaigns", icon: "Campaign" },
    { id: "utmMediums", name: "UTM Mediums", icon: "UTMMedium" },
    { id: "os", name: "Operating Systems", icon: "OperatingSystem" },
    { id: "browsers", name: "Browsers", icon: "Browser" },
    { id: "devices", name: "Devices", icon: "Device" },
    // { id: "allowedDays", name: "Allowed Days", icon: "AllowedDays" },
    { id: "ABTests", name: "A/B Tests", icon: "ABTest" },
    { id: "keyValues", name: "Key Values", icon: "KeyValue" },
    { id: "surveyId", name: "Survey Id", icon: "SurveyID" },
    // { id: "spamCheck", name: "Spam Check", icon: "SpamIP" },
];

export const numberOfSetsABTest = [
    { id: "utmSources", name: "UTM Sources", icon: "UTMSource" },
    { id: "clientIPs", name: "Client IP", icon: "ClientIP" },
    { id: "regions", name: "Region", icon: "Region" },
    // { id: "keywords", name: "Keywords", icon: "KeyWord" },
    { id: "ageRange", name: "Age Range", icon: "AgeRange" },
    { id: "genders", name: "Gender", icon: "Gender" },
    { id: "countries", name: "Countries", icon: "Country" },
    { id: "zipCodes", name: "Zip Codes", icon: "ZipCode" },
    // { id: "timeOfDay", name: "Time of Day", icon: "TimeOfDay" },
    { id: "utmCampaigns", name: "UTM Campaigns", icon: "Campaign" },
    { id: "utmMediums", name: "UTM Mediums", icon: "UTMMedium" },
    { id: "os", name: "Operating Systems", icon: "OperatingSystem" },
    { id: "browsers", name: "Browsers", icon: "Browser" },
    { id: "devices", name: "Devices", icon: "Device" },
    // { id: "allowedDays", name: "Allowed Days", icon: "AllowedDays"},
    { id: "ABTests", name: "A/B Tests", icon: "ABTest" },
    { id: "keyValues", name: "Key Values", icon: "KeyValue" },
    { id: "surveyId", name: "Survey Id", icon: "SurveyID" },
    { id: "publishers", name: "Publishers", icon: "Publisher" },
    { id: "jobTypes", name: "Job Types", icon: "JobType" },
    // { id: "duplicateUser", name: "Duplicate User" },
    { id: "utmTerms", name: "UTM Terms", icon: "UTMTerm" },
    // { id: "spamCheck", name: "Spam Check", icon: "SpamIP" },
    { id: "utmContents", name: "UTM Contents", icon: "Campaign" },
];

export const numberOfSetsOfferWall = [
    ...numberOfSets,
    { id: "utmContents", name: "UTM Contents", icon: "Campaign" },
];

export const UTMSOURCESDROPDOWNOPTIONS = [
    {
        label: "Results Generation",
        id: "17",
    },
    {
        label: "pdmo",
        id: "pdmo",
    },
    {
        label: "Vicero LLC",
        id: "18",
    },
    {
        label: "google",
        id: "google",
    },
    {
        label: "All-Inbox (Email)",
        id: "5",
    },
    {
        label: "jobsinsdr",
        id: "jobsinsdr",
    },
    {
        label: "upward",
        id: "upward",
    },
    {
        label: "Skenzo",
        id: "110",
    },
    {
        label: "Adworks",
        id: "39",
    },
    {
        label: "Pushnami LLC",
        id: "33",
    },
    {
        label: "INTELLIGENT DELIVERY CORP.",
        id: "7",
    },
    {
        label: "Voltron Interactive",
        id: "31",
    },
    {
        label: "bing",
        id: "bing",
    },
    {
        label: "xlns",
        id: "xlns",
    },
    {
        label: "Apptness Media Group LLC",
        id: "93",
    },
    {
        label: "All-Inbox (SMS)",
        id: "6",
    },
    {
        label: "Reticular",
        id: "21",
    },
    {
        label: "717 Ads",
        id: "28",
    },
    {
        label: "VHMnetwork LLC",
        id: "44",
    },
    {
        label: "Finunited Careers",
        id: "108",
    },
    {
        label: "Fluent, LLC",
        id: "35",
    },
    {
        label: "facebook",
        id: "facebook",
    },
    {
        label: "xutg",
        id: "xutg",
    },
    {
        label: "Joblovin LLC",
        id: "34",
    },
    {
        label: "Local Staffing LLC",
        id: "62",
    },
    {
        label: "xrg",
        id: "xrg",
    },
    {
        label: "xatb",
        id: "xatb",
    },
    {
        label: "pgmtc",
        id: "pgmtc",
    },
    {
        label: "Bluewater Digital",
        id: "151",
    },
    {
        label: "Popular Marketing LLC",
        id: "75",
    },
    {
        label: "taboola",
        id: "taboola",
    },
    {
        label: "PJ Centerprise",
        id: "71",
    },
    {
        label: "xleco",
        id: "xleco",
    },
    {
        label: "xmtro",
        id: "xmtro",
    },
    {
        label: "xnavg",
        id: "xnavg",
    },
    {
        label: "Digipropz LLC",
        id: "112",
    },
    {
        label: "pdmo1",
        id: "pdmo1",
    },
    {
        label: "DMS",
        id: "69",
    },
    {
        label: "xjobb",
        id: "xjobb",
    },
    {
        label: "Castline",
        id: "40",
    },
    {
        label: "xup",
        id: "xup",
    },
    {
        label: "yepads.com",
        id: "125",
    },
    {
        label: "xret",
        id: "xret",
    },
    {
        label: "Surfin Mouse",
        id: "19",
    },
    {
        label: "Conversion Marketing LLC",
        id: "55",
    },
    {
        label: "pdmo2",
        id: "pdmo2",
    },
    {
        label: "Flyrim Tech Corp",
        id: "52",
    },
    {
        label: "NotifyAI, LLC",
        id: "51",
    },
    {
        label: "xup_static",
        id: "xup_static",
    },
    {
        label: "SilverTap",
        id: "43",
    },
    {
        label: "Offer Blueprint, LLC",
        id: "37",
    },
    {
        label: "Flex Marketing Group",
        id: "78",
    },
    {
        label: "ActiveSun",
        id: "144",
    },
    {
        label: "r3i",
        id: "r3i",
    },
    {
        label: "fim",
        id: "fim",
    },
    {
        label: "xgi",
        id: "xgi",
    },
    {
        label: "Jobble Inc.",
        id: "169",
    },
    {
        label: "Quantum Response Network",
        id: "59",
    },
    {
        label: "Ifficient, Inc",
        id: "119",
    },
    {
        label: "Edvisors Network, Inc. - ScholarshipPoints",
        id: "26",
    },
    {
        label: "xjov",
        id: "xjov",
    },
    {
        label: "Converting Ads s.r.o.",
        id: "138",
    },
    {
        label: "xjov1",
        id: "xjov1",
    },
    {
        label: "Addicted Affiliate LLC",
        id: "29",
    },
    {
        label: "xjg",
        id: "xjg",
    },
    {
        label: "MGID",
        id: "85",
    },
    {
        label: "PushPros",
        id: "46",
    },
    {
        label: "xct",
        id: "xct",
    },
    {
        label: "xvhm",
        id: "xvhm",
    },
    {
        label: "Edvisors Network, Inc. - RewardFish",
        id: "25",
    },
    {
        label: "HasTraffic.com",
        id: "68",
    },
    {
        label: "Perform[cb], LLC",
        id: "117",
    },
    {
        label: "xjob",
        id: "xjob",
    },
    {
        label: "digitalmoses",
        id: "digitalmoses",
    },
    {
        label: "WhatIf Media",
        id: "15",
    },
    {
        label: "HasOffers",
        id: "170",
    },
    {
        label: "The Stern Group",
        id: "120",
    },
    {
        label: "Verizon Media",
        id: "66",
    },
    {
        label: "xwhj",
        id: "xwhj",
    },
    {
        label: "rollerads",
        id: "49",
    },
    {
        label: "rg",
        id: "rg",
    },
    {
        label: "xfbg",
        id: "xfbg",
    },
    {
        label: "ntfyai",
        id: "ntfyai",
    },
    {
        label: "ls",
        id: "ls",
    },
    {
        label: "xvcp",
        id: "xvcp",
    },
    {
        label: "QATest",
        id: "QATest",
    },
    {
        label: "xapp",
        id: "xapp",
    },
    {
        label: "ppr",
        id: "ppr",
    },
    {
        label: "testp",
        id: "testp",
    },
    {
        label: "intango.com",
        id: "86",
    },
    {
        label: "Launch Potato",
        id: "64",
    },
    {
        label: "Interest Media",
        id: "157",
    },
    {
        label: "bjo",
        id: "bjo",
    },
    {
        label: "fsm",
        id: "fsm",
    },
    {
        label: "RXMG",
        id: "91",
    },
    {
        label: "Digital Hub",
        id: "129",
    },
    {
        label: "awks",
        id: "awks",
    },
    {
        label: "xadn",
        id: "xadn",
    },
    {
        label: "facebook2",
        id: "facebook2",
    },
    {
        label: "xapp_static",
        id: "xapp_static",
    },
    {
        label: "ret",
        id: "ret",
    },
    {
        label: "vhm",
        id: "vhm",
    },
    {
        label: "st",
        id: "st",
    },
    {
        label: "Secco Squared, LLC",
        id: "74",
    },
    {
        label: "jo",
        id: "jo",
    },
    {
        label: "clm",
        id: "clm",
    },
    {
        label: "pn",
        id: "pn",
    },
    {
        label: "Shift 44",
        id: "57",
    },
    {
        label: "{affiliate_id}",
        id: "{affiliate_id}",
    },
    {
        label: "Zapptastic, LLC",
        id: "13",
    },
    {
        label: "mnet",
        id: "mnet",
    },
    {
        label: "castline",
        id: "40",
    },
    {
        label: "Puzz LLC",
        id: "80",
    },
    {
        label: "Upward",
        id: "upward",
    },
    {
        label: "Test Partner 2",
        id: "2",
    },
    {
        label: "qatest",
        id: "QATest",
    },
    {
        label: "mjh",
        id: "mjh",
    },
    {
        label: "gQSeBnzGW",
        id: "gQSeBnzGW",
    },
    {
        label: "xappcast_test",
        id: "xappcast_test",
    },
    {
        label: "sh44",
        id: "sh44",
    },
    {
        label: "gQSeBnzG",
        id: "gQSeBnzG",
    },
    {
        label: "imc",
        id: "imc",
    },
    {
        label: "xser",
        id: "xser",
    },
    {
        label: "Flatiron",
        id: "16",
    },
    {
        label: "bf",
        id: "bf",
    },
    {
        label: "xmjh",
        id: "xmjh",
    },
    {
        label: "j2m",
        id: "j2m",
    },
    {
        label: "jg",
        id: "jg",
    },
    {
        label: "--sanitized--",
        id: "--sanitized--",
    },
    {
        label: "pub",
        id: "pub",
    },
    {
        label: "Citadel Marketing Group",
        id: "50",
    },
    {
        label: "Facebook Organic",
        id: "121",
    },
    {
        label: "xjtm",
        id: "xjtm",
    },
    {
        label: "apptness",
        id: "apptness",
    },
    {
        label: "use",
        id: "use",
    },
    {
        label: "attb",
        id: "attb",
    },
    {
        label: "LocalJobSeeker.com",
        id: "79",
    },
    {
        label: "quora",
        id: "quora",
    },
    {
        label: "Turtle Leads",
        id: "32",
    },
    {
        label: "vicero",
        id: "vicero",
    },
    {
        label: "upward,reticular",
        id: "upward,reticular",
    },
    {
        label: "id",
        id: "id",
    },
    {
        label: "xappcast",
        id: "xappcast",
    },
    {
        label: "gs://cr-jobs-xmlfeed/feeds/upstream/",
        id: "gs://cr-jobs-xmlfeed/feeds/upstream/",
    },
    {
        label: "google,https://localjobsinc.com",
        id: "google,https://localjobsinc.com",
    },
    {
        label: "toboola",
        id: "toboola",
    },
    {
        label: "144,amazon",
        id: "144,amazon",
    },
    {
        label: "jobsinswhy am i getting bad gas mileager",
        id: "jobsinswhy am i getting bad gas mileager",
    },
    {
        label: "Creekview Digital, LLC",
        id: 88,
    },
    {
        label: "mgemail",
        id: "mgemail",
    },
    {
        label: "abc",
        id: "abc",
    },
    {
        label: "fbcomments",
        id: "fbcomments",
    },
    {
        label: "{{osource}}",
        id: "{{osource}}",
    },
    {
        label: "cmsms",
        id: "cmsms",
    },
    {
        label: "bing,bing",
        id: "bing,bing",
    },
    {
        label: "mnpush",
        id: "mnpush",
    },
    {
        label: "xjadx",
        id: "xjadx",
    },
    {
        label: "homesite",
        id: "homesite",
    },
    {
        label: "InboxAlly",
        id: "InboxAlly",
    },
    {
        label: "2sms",
        id: "2sms",
    },
    {
        label: "twoclickcmsms",
        id: "twoclickcmsms",
    },
    {
        label: "perform",
        id: "perform",
    },
    {
        label: "testqa",
        id: "testqa",
    },
    {
        label: "new_serp_neuvoo",
        id: "new_serp_neuvoo",
    },
    {
        label: "shk",
        id: "shk",
    },
    {
        label: "prasad",
        id: "prasad",
    },
    {
        label: "connexus-dynamic",
        id: "connexus-dynamic",
    },
    {
        label: "nocsms",
        id: "nocsms",
    },
    {
        label: "QATest123",
        id: "QATest123",
    },
    {
        label: "google,google",
        id: "google,google",
    },
    {
        label: "Clickso LLC",
        id: "54",
    },
    {
        label: "Avanto Media",
        id: "70",
    },
    {
        label: "digital%20moses",
        id: "digital%20moses",
    },
    {
        label: "twsms",
        id: "twsms",
    },
    {
        label: "10dlc",
        id: "10dlc",
    },
    {
        label: "talroo",
        id: "talroo",
    },
    {
        label: "new_serp_cmp",
        id: "new_serp_cmp",
    },
    {
        label: "xcb",
        id: "xcb",
    },
    {
        label: "FRST Media",
        id: "53",
    },
    {
        label: "qatest123",
        id: "QATest123",
    },
    {
        label: "inter",
        id: "inter",
    },
    {
        label: "newjobscanner",
        id: "newjobscanner",
    },
    {
        label: "bnsms",
        id: "bnsms",
    },
    {
        label: "pdmo2%2Cupward",
        id: "pdmo2%2Cupward",
    },
    {
        label: "undefined",
        id: "undefined",
    },
    {
        label: "talrootesting",
        id: "talrootesting",
    },
    {
        label: "goog/",
        id: "goog/",
    },
    {
        label: "talrootesting1",
        id: "talrootesting1",
    },
    {
        label: "Jobnamic LLC",
        id: "63",
    },
    {
        label: "clixflow.com",
        id: "72",
    },
    {
        label: "zappian.com",
        id: "124",
    },
    {
        label: "tmsms",
        id: "tmsms",
    },
    {
        label: "mjc",
        id: "mjc",
    },
    {
        label: "mobtraction.com",
        id: "161",
    },
    {
        label: "email",
        id: "email",
    },
    {
        label: "check",
        id: "check",
    },
    {
        label: "Traffic Company",
        id: "164",
    },
    {
        label: "Tagadamedia",
        id: "145",
    },
    {
        label: "Spark Revenue",
        id: "167",
    },
    {
        label: "Shift 44 (Sweeps)",
        id: "111",
    },
    {
        label: "Pushnami LLC (Sweeps)",
        id: "115",
    },
    {
        label: "Point2web",
        id: "126",
    },
    {
        label: "PInchme.com Inc",
        id: "177",
    },
    {
        label: "Media Devoted 1 Aps",
        id: "135",
    },
    {
        label: "Madera Digital (Sweeps)",
        id: "102",
    },
    {
        label: "Launch Potato (Sweeps)",
        id: "105",
    },
    {
        label: "LEAD ID",
        id: "149",
    },
    {
        label: "Horizon2Media",
        id: "141",
    },
    {
        label: "Giraffe Media Group",
        id: "159",
    },
    {
        label: "Fluent, LLC (Sweeps/Promos)",
        id: "146",
    },
    {
        label: "Flatiron (Sweeps)",
        id: "98",
    },
    {
        label: "Fizzylabs Corporation",
        id: "122",
    },
    {
        label: "Digital Media Solutions Group",
        id: "97",
    },
    {
        label: "DigiDum B.V.",
        id: "160",
    },
    {
        label: "Arroyomedia",
        id: "158",
    },
    {
        label: "AdMaven",
        id: "128",
    },
    {
        label: "252",
        id: "252",
    },
    {
        label: "www.nextclickpartners.com",
        id: "11",
    },
    {
        label: "tapp.co",
        id: "174",
    },
    {
        label: "null",
        id: "null",
    },
    {
        label: "gcs",
        id: "gcs",
    },
    {
        label: "VIP Response",
        id: "136",
    },
    {
        label: "Real Deal Sweeps",
        id: "82",
    },
    {
        label: "RDS_Email",
        id: "171",
    },
    {
        label: "QAtest",
        id: "QATest",
    },
    {
        label: "QATesst",
        id: "QATesst",
    },
    {
        label: "Marketcall Inc",
        id: "162",
    },
    {
        label: "Louding Ads",
        id: "152",
    },
    {
        label: "JvanH Media B.V. / LeadXchange",
        id: "165",
    },
    {
        label: "APPSTRENDING MEDIA PVT. LTD. Brand",
        id: "168",
    },
    {
        label: "Freestyle Media",
        id: "22",
    },
];

export const USERSTATUSDROPDOWNOPTIONS = [
    {
        label: "Registered: Same Browser-Device",
        id: "reg_same_device",
    },
    {
        label: "New",
        id: "new",
    },
    {
        label: "Registered: Cross Browser-Device",
        id: "reg_cross_browser_device",
    },
    {
        label: "Subscribed",
        id: "subscribed",
    },
    {
        label: "Non Registered",
        id: "non_reg",
    },
];

export const KEYWORDCATEGORIESDROPDOWNOPTIONS = [
    {
        label: "Generic",
        id: "Generic",
    },
    {
        label: "None",
        id: "None",
    },
    {
        label: "Healthcare",
        id: "Healthcare",
    },
    {
        label: "Travel",
        id: "Travel",
    },
    {
        label: "Technology",
        id: "Technology",
    },
    {
        label: "Postal Services",
        id: "Postal Services",
    },
    {
        label: "Retail",
        id: "Retail",
    },
    {
        label: "Govt Jobs",
        id: "Govt Jobs",
    },
    {
        label: "Support Service",
        id: "Support Service",
    },
    {
        label: "Security",
        id: "Security",
    },
    {
        label: "Pet Care",
        id: "Pet Care",
    },
    {
        label: "Service Jobs",
        id: "Service Jobs",
    },
    {
        label: "Finance",
        id: "Finance",
    },
    {
        label: "Education",
        id: "Education",
    },
    {
        label: "Delivery",
        id: "Delivery",
    },
    {
        label: "Construction",
        id: "Construction",
    },
    {
        label: "Hospitality",
        id: "Hospitality",
    },
    {
        label: "Child care",
        id: "Child care",
    },
    {
        label: "Transportation",
        id: "Transportation",
    },
    {
        label: "Restaurant",
        id: "Restaurant",
    },
    {
        label: "Operations",
        id: "Operations",
    },
    {
        label: "Manufacturing",
        id: "Manufacturing",
    },
    {
        label: "Entertainment",
        id: "Entertainment",
    },
    {
        label: "Management",
        id: "Management",
    },
    {
        label: "HR",
        id: "HR",
    },
    {
        label: "Legal",
        id: "Legal",
    },
    {
        label: "Social Service",
        id: "Social Service",
    },
    {
        label: "Automotive",
        id: "Automotive",
    },
    {
        label: "Sales",
        id: "Sales",
    },
    {
        label: "Marketing",
        id: "Marketing",
    },
    {
        label: "Sports",
        id: "Sports",
    },
    {
        label: "Engineering",
        id: "Engineering",
    },
    {
        label: "Real estate",
        id: "Real estate",
    },
    {
        label: "Fitness",
        id: "Fitness",
    },
    {
        label: "Agriculture",
        id: "Agriculture",
    },
    {
        label: "Fashion",
        id: "Fashion",
    },
];

export const CITYDROPDOWNOPTIONS = [
    {
        id: "New York",
        label: "New York",
    },
    {
        id: "Los Angeles",
        label: "Los Angeles",
    },
    {
        id: "Chicago",
        label: "Chicago",
    },
    {
        id: "Houston",
        label: "Houston",
    },
    {
        id: "Phoenix",
        label: "Phoenix",
    },
    {
        id: "Philadelphia",
        label: "Philadelphia",
    },
    {
        id: "San Antonio",
        label: "San Antonio",
    },
    {
        id: "San Diego",
        label: "San Diego",
    },
    {
        id: "Dallas",
        label: "Dallas",
    },
    {
        id: "San Jose",
        label: "San Jose",
    },
    {
        id: "Austin",
        label: "Austin",
    },
    {
        id: "Jacksonville",
        label: "Jacksonville",
    },
    {
        id: "Fort Worth",
        label: "Fort Worth",
    },
    {
        id: "Columbus",
        label: "Columbus",
    },
    {
        id: "Indianapolis",
        label: "Indianapolis",
    },
    {
        id: "Charlotte",
        label: "Charlotte",
    },
    {
        id: "San Francisco",
        label: "San Francisco",
    },
    {
        id: "Seattle",
        label: "Seattle",
    },
    {
        id: "Denver",
        label: "Denver",
    },
    {
        id: "Oklahoma City",
        label: "Oklahoma City",
    },
    {
        id: "Nashville",
        label: "Nashville",
    },
    {
        id: "El Paso",
        label: "El Paso",
    },
    {
        id: "Washington",
        label: "Washington",
    },
    {
        id: "Boston",
        label: "Boston",
    },
    {
        id: "Las Vegas",
        label: "Las Vegas",
    },
    {
        id: "Portland",
        label: "Portland",
    },
    {
        id: "Detroit",
        label: "Detroit",
    },
    {
        id: "Louisville",
        label: "Louisville",
    },
    {
        id: "Memphis",
        label: "Memphis",
    },
    {
        id: "Baltimore",
        label: "Baltimore",
    },
    {
        id: "Milwaukee",
        label: "Milwaukee",
    },
    {
        id: "Albuquerque",
        label: "Albuquerque",
    },
    {
        id: "Fresno",
        label: "Fresno",
    },
    {
        id: "Tucson",
        label: "Tucson",
    },
    {
        id: "Sacramento",
        label: "Sacramento",
    },
    {
        id: "Mesa",
        label: "Mesa",
    },
    {
        id: "Kansas City",
        label: "Kansas City",
    },
    {
        id: "Atlanta",
        label: "Atlanta",
    },
    {
        id: "Omaha",
        label: "Omaha",
    },
    {
        id: "Colorado Springs",
        label: "Colorado Springs",
    },
    {
        id: "Raleigh",
        label: "Raleigh",
    },
    {
        id: "Virginia Beach",
        label: "Virginia Beach",
    },
    {
        id: "Long Beach",
        label: "Long Beach",
    },
    {
        id: "Miami",
        label: "Miami",
    },
    {
        id: "Oakland",
        label: "Oakland",
    },
    {
        id: "Minneapolis",
        label: "Minneapolis",
    },
    {
        id: "Tulsa",
        label: "Tulsa",
    },
    {
        id: "Bakersfield",
        label: "Bakersfield",
    },
    {
        id: "Wichita",
        label: "Wichita",
    },
    {
        id: "Arlington",
        label: "Arlington",
    },
    {
        id: "Aurora",
        label: "Aurora",
    },
    {
        id: "Tampa",
        label: "Tampa",
    },
    {
        id: "New Orleans",
        label: "New Orleans",
    },
    {
        id: "Cleveland",
        label: "Cleveland",
    },
    {
        id: "Anaheim",
        label: "Anaheim",
    },
    {
        id: "Honolulu",
        label: "Honolulu",
    },
    {
        id: "Henderson",
        label: "Henderson",
    },
    {
        id: "Stockton",
        label: "Stockton",
    },
    {
        id: "Lexington",
        label: "Lexington",
    },
    {
        id: "Corpus Christi",
        label: "Corpus Christi",
    },
    {
        id: "Riverside",
        label: "Riverside",
    },
    {
        id: "Santa Ana",
        label: "Santa Ana",
    },
    {
        id: "Orlando",
        label: "Orlando",
    },
    {
        id: "Irvine",
        label: "Irvine",
    },
    {
        id: "Cincinnati",
        label: "Cincinnati",
    },
    {
        id: "Newark",
        label: "Newark",
    },
    {
        id: "Saint Paul",
        label: "Saint Paul",
    },
    {
        id: "Pittsburgh",
        label: "Pittsburgh",
    },
    {
        id: "Greensboro",
        label: "Greensboro",
    },
    {
        id: "St. Louis",
        label: "St. Louis",
    },
    {
        id: "Lincoln",
        label: "Lincoln",
    },
    {
        id: "Plano",
        label: "Plano",
    },
    {
        id: "Anchorage",
        label: "Anchorage",
    },
    {
        id: "Durham",
        label: "Durham",
    },
    {
        id: "Jersey City",
        label: "Jersey City",
    },
    {
        id: "Chandler",
        label: "Chandler",
    },
    {
        id: "Chula Vista",
        label: "Chula Vista",
    },
    {
        id: "Buffalo",
        label: "Buffalo",
    },
    {
        id: "North Las Vegas",
        label: "North Las Vegas",
    },
    {
        id: "Gilbert",
        label: "Gilbert",
    },
    {
        id: "Madison",
        label: "Madison",
    },
    {
        id: "Reno",
        label: "Reno",
    },
    {
        id: "Toledo",
        label: "Toledo",
    },
    {
        id: "Fort Wayne",
        label: "Fort Wayne",
    },
    {
        id: "Lubbock",
        label: "Lubbock",
    },
    {
        id: "St. Petersburg",
        label: "St. Petersburg",
    },
    {
        id: "Laredo",
        label: "Laredo",
    },
    {
        id: "Irving",
        label: "Irving",
    },
    {
        id: "Chesapeake",
        label: "Chesapeake",
    },
    {
        id: "Winston–Salem",
        label: "Winston–Salem",
    },
    {
        id: "Glendale",
        label: "Glendale",
    },
    {
        id: "Scottsdale",
        label: "Scottsdale",
    },
    {
        id: "Garland",
        label: "Garland",
    },
    {
        id: "Boise",
        label: "Boise",
    },
    {
        id: "Norfolk",
        label: "Norfolk",
    },
    {
        id: "Spokane",
        label: "Spokane",
    },
    {
        id: "Fremont",
        label: "Fremont",
    },
    {
        id: "Richmond",
        label: "Richmond",
    },
    {
        id: "Santa Clarita",
        label: "Santa Clarita",
    },
    {
        id: "San Bernardino",
        label: "San Bernardino",
    },
    {
        id: "Baton Rouge",
        label: "Baton Rouge",
    },
    {
        id: "Hialeah",
        label: "Hialeah",
    },
    {
        id: "Tacoma",
        label: "Tacoma",
    },
    {
        id: "Modesto",
        label: "Modesto",
    },
    {
        id: "Port St. Lucie",
        label: "Port St. Lucie",
    },
    {
        id: "Huntsville",
        label: "Huntsville",
    },
    {
        id: "Des Moines",
        label: "Des Moines",
    },
    {
        id: "Moreno Valley",
        label: "Moreno Valley",
    },
    {
        id: "Fontana",
        label: "Fontana",
    },
    {
        id: "Frisco",
        label: "Frisco",
    },
    {
        id: "Rochester",
        label: "Rochester",
    },
    {
        id: "Yonkers",
        label: "Yonkers",
    },
    {
        id: "Fayetteville",
        label: "Fayetteville",
    },
    {
        id: "Worcester",
        label: "Worcester",
    },
    {
        id: "Columbus",
        label: "Columbus",
    },
    {
        id: "Cape Coral",
        label: "Cape Coral",
    },
    {
        id: "McKinney",
        label: "McKinney",
    },
    {
        id: "Little Rock",
        label: "Little Rock",
    },
    {
        id: "Oxnard",
        label: "Oxnard",
    },
    {
        id: "Amarillo",
        label: "Amarillo",
    },
    {
        id: "Augusta",
        label: "Augusta",
    },
    {
        id: "Salt Lake City",
        label: "Salt Lake City",
    },
    {
        id: "Montgomery",
        label: "Montgomery",
    },
    {
        id: "Birmingham",
        label: "Birmingham",
    },
    {
        id: "Grand Rapids",
        label: "Grand Rapids",
    },
    {
        id: "Grand Prairie",
        label: "Grand Prairie",
    },
    {
        id: "Overland Park",
        label: "Overland Park",
    },
    {
        id: "Tallahassee",
        label: "Tallahassee",
    },
    {
        id: "Huntington Beach",
        label: "Huntington Beach",
    },
    {
        id: "Sioux Falls",
        label: "Sioux Falls",
    },
    {
        id: "Peoria",
        label: "Peoria",
    },
    {
        id: "Knoxville",
        label: "Knoxville",
    },
    {
        id: "Glendale",
        label: "Glendale",
    },
    {
        id: "Vancouver",
        label: "Vancouver",
    },
    {
        id: "Providence",
        label: "Providence",
    },
    {
        id: "Akron",
        label: "Akron",
    },
    {
        id: "Brownsville",
        label: "Brownsville",
    },
    {
        id: "Mobile",
        label: "Mobile",
    },
    {
        id: "Newport News",
        label: "Newport News",
    },
    {
        id: "Tempe",
        label: "Tempe",
    },
    {
        id: "Shreveport",
        label: "Shreveport",
    },
    {
        id: "Chattanooga",
        label: "Chattanooga",
    },
    {
        id: "Fort Lauderdale",
        label: "Fort Lauderdale",
    },
    {
        id: "Aurora",
        label: "Aurora",
    },
    {
        id: "Elk Grove",
        label: "Elk Grove",
    },
    {
        id: "Ontario",
        label: "Ontario",
    },
    {
        id: "Salem",
        label: "Salem",
    },
    {
        id: "Cary",
        label: "Cary",
    },
    {
        id: "Santa Rosa",
        label: "Santa Rosa",
    },
    {
        id: "Rancho Cucamonga",
        label: "Rancho Cucamonga",
    },
    {
        id: "Eugene",
        label: "Eugene",
    },
    {
        id: "Oceanside",
        label: "Oceanside",
    },
    {
        id: "Clarksville",
        label: "Clarksville",
    },
    {
        id: "Garden Grove",
        label: "Garden Grove",
    },
    {
        id: "Lancaster",
        label: "Lancaster",
    },
    {
        id: "Springfield",
        label: "Springfield",
    },
    {
        id: "Pembroke Pines",
        label: "Pembroke Pines",
    },
    {
        id: "Fort Collins",
        label: "Fort Collins",
    },
    {
        id: "Palmdale",
        label: "Palmdale",
    },
    {
        id: "Salinas",
        label: "Salinas",
    },
    {
        id: "Hayward",
        label: "Hayward",
    },
    {
        id: "Corona",
        label: "Corona",
    },
    {
        id: "Paterson",
        label: "Paterson",
    },
    {
        id: "Murfreesboro",
        label: "Murfreesboro",
    },
    {
        id: "Macon",
        label: "Macon",
    },
    {
        id: "Lakewood",
        label: "Lakewood",
    },
    {
        id: "Killeen",
        label: "Killeen",
    },
    {
        id: "Springfield",
        label: "Springfield",
    },
    {
        id: "Alexandria",
        label: "Alexandria",
    },
    {
        id: "Kansas City",
        label: "Kansas City",
    },
    {
        id: "Sunnyvale",
        label: "Sunnyvale",
    },
    {
        id: "Hollywood",
        label: "Hollywood",
    },
    {
        id: "Roseville",
        label: "Roseville",
    },
    {
        id: "Charleston",
        label: "Charleston",
    },
    {
        id: "Escondido",
        label: "Escondido",
    },
    {
        id: "Joliet",
        label: "Joliet",
    },
    {
        id: "Jackson",
        label: "Jackson",
    },
    {
        id: "Bellevue",
        label: "Bellevue",
    },
    {
        id: "Surprise",
        label: "Surprise",
    },
    {
        id: "Naperville",
        label: "Naperville",
    },
    {
        id: "Pasadena",
        label: "Pasadena",
    },
    {
        id: "Pomona",
        label: "Pomona",
    },
    {
        id: "Bridgeport",
        label: "Bridgeport",
    },
    {
        id: "Denton",
        label: "Denton",
    },
    {
        id: "Rockford",
        label: "Rockford",
    },
    {
        id: "Mesquite",
        label: "Mesquite",
    },
    {
        id: "Savannah",
        label: "Savannah",
    },
    {
        id: "Syracuse",
        label: "Syracuse",
    },
    {
        id: "McAllen",
        label: "McAllen",
    },
    {
        id: "Torrance",
        label: "Torrance",
    },
    {
        id: "Olathe",
        label: "Olathe",
    },
    {
        id: "Visalia",
        label: "Visalia",
    },
    {
        id: "Thornton",
        label: "Thornton",
    },
    {
        id: "Fullerton",
        label: "Fullerton",
    },
    {
        id: "Gainesville",
        label: "Gainesville",
    },
    {
        id: "Waco",
        label: "Waco",
    },
    {
        id: "West Valley City",
        label: "West Valley City",
    },
    {
        id: "Warren",
        label: "Warren",
    },
    {
        id: "Lakewood",
        label: "Lakewood",
    },
    {
        id: "Hampton",
        label: "Hampton",
    },
    {
        id: "Dayton",
        label: "Dayton",
    },
    {
        id: "Columbia",
        label: "Columbia",
    },
    {
        id: "Orange",
        label: "Orange",
    },
    {
        id: "Cedar Rapids",
        label: "Cedar Rapids",
    },
    {
        id: "Stamford",
        label: "Stamford",
    },
    {
        id: "Victorville",
        label: "Victorville",
    },
    {
        id: "Pasadena",
        label: "Pasadena",
    },
    {
        id: "Elizabeth",
        label: "Elizabeth",
    },
    {
        id: "New Haven",
        label: "New Haven",
    },
    {
        id: "Miramar",
        label: "Miramar",
    },
    {
        id: "Kent",
        label: "Kent",
    },
    {
        id: "Sterling Heights",
        label: "Sterling Heights",
    },
    {
        id: "Carrollton",
        label: "Carrollton",
    },
    {
        id: "Coral Springs",
        label: "Coral Springs",
    },
    {
        id: "Midland",
        label: "Midland",
    },
    {
        id: "Norman",
        label: "Norman",
    },
    {
        id: "Athens",
        label: "Athens",
    },
    {
        id: "Santa Clara",
        label: "Santa Clara",
    },
    {
        id: "Columbia",
        label: "Columbia",
    },
    {
        id: "Fargo",
        label: "Fargo",
    },
    {
        id: "Pearland",
        label: "Pearland",
    },
    {
        id: "Simi Valley",
        label: "Simi Valley",
    },
    {
        id: "Meridian",
        label: "Meridian",
    },
    {
        id: "Topeka",
        label: "Topeka",
    },
    {
        id: "Allentown",
        label: "Allentown",
    },
    {
        id: "Thousand Oaks",
        label: "Thousand Oaks",
    },
    {
        id: "Abilene",
        label: "Abilene",
    },
    {
        id: "Vallejo",
        label: "Vallejo",
    },
    {
        id: "Concord",
        label: "Concord",
    },
    {
        id: "Round Rock",
        label: "Round Rock",
    },
    {
        id: "Arvada",
        label: "Arvada",
    },
    {
        id: "Clovis",
        label: "Clovis",
    },
    {
        id: "Palm Bay",
        label: "Palm Bay",
    },
    {
        id: "Independence",
        label: "Independence",
    },
    {
        id: "Lafayette",
        label: "Lafayette",
    },
    {
        id: "Ann Arbor",
        label: "Ann Arbor",
    },
    {
        id: "Rochester",
        label: "Rochester",
    },
    {
        id: "Hartford",
        label: "Hartford",
    },
    {
        id: "College Station",
        label: "College Station",
    },
    {
        id: "Fairfield",
        label: "Fairfield",
    },
    {
        id: "Wilmington",
        label: "Wilmington",
    },
    {
        id: "North Charleston",
        label: "North Charleston",
    },
    {
        id: "Billings",
        label: "Billings",
    },
    {
        id: "West Palm Beach",
        label: "West Palm Beach",
    },
    {
        id: "Berkeley",
        label: "Berkeley",
    },
    {
        id: "Cambridge",
        label: "Cambridge",
    },
    {
        id: "Clearwater",
        label: "Clearwater",
    },
    {
        id: "West Jordan",
        label: "West Jordan",
    },
    {
        id: "Evansville",
        label: "Evansville",
    },
    {
        id: "Richardson",
        label: "Richardson",
    },
    {
        id: "Broken Arrow",
        label: "Broken Arrow",
    },
    {
        id: "Richmond",
        label: "Richmond",
    },
    {
        id: "League City",
        label: "League City",
    },
    {
        id: "Manchester",
        label: "Manchester",
    },
    {
        id: "Lakeland",
        label: "Lakeland",
    },
    {
        id: "Carlsbad",
        label: "Carlsbad",
    },
    {
        id: "Antioch",
        label: "Antioch",
    },
    {
        id: "Westminster",
        label: "Westminster",
    },
    {
        id: "High Point",
        label: "High Point",
    },
    {
        id: "Provo",
        label: "Provo",
    },
    {
        id: "Lowell",
        label: "Lowell",
    },
    {
        id: "Elgin",
        label: "Elgin",
    },
    {
        id: "Waterbury",
        label: "Waterbury",
    },
    {
        id: "Springfield",
        label: "Springfield",
    },
    {
        id: "Gresham",
        label: "Gresham",
    },
    {
        id: "Murrieta",
        label: "Murrieta",
    },
    {
        id: "Lewisville",
        label: "Lewisville",
    },
    {
        id: "Las Cruces",
        label: "Las Cruces",
    },
    {
        id: "Lansing",
        label: "Lansing",
    },
    {
        id: "Beaumont",
        label: "Beaumont",
    },
    {
        id: "Odessa",
        label: "Odessa",
    },
    {
        id: "Pueblo",
        label: "Pueblo",
    },
    {
        id: "Peoria",
        label: "Peoria",
    },
    {
        id: "Downey",
        label: "Downey",
    },
    {
        id: "Pompano Beach",
        label: "Pompano Beach",
    },
    {
        id: "Miami Gardens",
        label: "Miami Gardens",
    },
    {
        id: "Temecula",
        label: "Temecula",
    },
    {
        id: "Everett",
        label: "Everett",
    },
    {
        id: "Costa Mesa",
        label: "Costa Mesa",
    },
    {
        id: "Ventura",
        label: "Ventura",
    },
    {
        id: "Sparks",
        label: "Sparks",
    },
    {
        id: "Santa Maria",
        label: "Santa Maria",
    },
    {
        id: "Sugar Land",
        label: "Sugar Land",
    },
    {
        id: "Greeley",
        label: "Greeley",
    },
    {
        id: "South Fulton",
        label: "South Fulton",
    },
    {
        id: "Dearborn",
        label: "Dearborn",
    },
    {
        id: "Concord",
        label: "Concord",
    },
    {
        id: "Edison",
        label: "Edison",
    },
    {
        id: "Tyler",
        label: "Tyler",
    },
    {
        id: "Sandy Springs",
        label: "Sandy Springs",
    },
    {
        id: "West Covina",
        label: "West Covina",
    },
    {
        id: "Green Bay",
        label: "Green Bay",
    },
    {
        id: "Centennial",
        label: "Centennial",
    },
    {
        id: "Jurupa Valley",
        label: "Jurupa Valley",
    },
    {
        id: "El Monte",
        label: "El Monte",
    },
    {
        id: "Allen",
        label: "Allen",
    },
    {
        id: "Hillsboro",
        label: "Hillsboro",
    },
    {
        id: "Menifee",
        label: "Menifee",
    },
    {
        id: "Nampa",
        label: "Nampa",
    },
    {
        id: "Spokane Valley",
        label: "Spokane Valley",
    },
    {
        id: "Rio Rancho",
        label: "Rio Rancho",
    },
    {
        id: "Brockton",
        label: "Brockton",
    },
    {
        id: "El Cajon",
        label: "El Cajon",
    },
    {
        id: "Burbank",
        label: "Burbank",
    },
    {
        id: "Inglewood",
        label: "Inglewood",
    },
    {
        id: "Renton",
        label: "Renton",
    },
    {
        id: "Davie",
        label: "Davie",
    },
    {
        id: "Rialto",
        label: "Rialto",
    },
    {
        id: "Boulder",
        label: "Boulder",
    },
    {
        id: "South Bend",
        label: "South Bend",
    },
    {
        id: "Woodbridge",
        label: "Woodbridge",
    },
    {
        id: "Vacaville",
        label: "Vacaville",
    },
    {
        id: "Wichita Falls",
        label: "Wichita Falls",
    },
    {
        id: "Lee's Summit",
        label: "Lee's Summit",
    },
    {
        id: "Edinburg",
        label: "Edinburg",
    },
    {
        id: "Chico",
        label: "Chico",
    },
    {
        id: "San Mateo",
        label: "San Mateo",
    },
    {
        id: "Bend",
        label: "Bend",
    },
    {
        id: "Goodyear",
        label: "Goodyear",
    },
    {
        id: "Buckeye",
        label: "Buckeye",
    },
    {
        id: "Daly City",
        label: "Daly City",
    },
    {
        id: "Fishers",
        label: "Fishers",
    },
    {
        id: "Quincy",
        label: "Quincy",
    },
    {
        id: "Davenport",
        label: "Davenport",
    },
    {
        id: "Hesperia",
        label: "Hesperia",
    },
    {
        id: "New Bedford",
        label: "New Bedford",
    },
    {
        id: "Lynn",
        label: "Lynn",
    },
    {
        id: "Carmel",
        label: "Carmel",
    },
    {
        id: "Longmont",
        label: "Longmont",
    },
    {
        id: "Tuscaloosa",
        label: "Tuscaloosa",
    },
    {
        id: "Norwalk",
        label: "Norwalk",
    },
];

export const GENDERS = [
    {
        isChecked: false,
        label: "Male",
        icon: "Male",
    },
    {
        isChecked: false,
        label: "Female",
        icon: "Female",
    },
];

export const FIELD_VERIFICATION = [
    {
        isChecked: false,
        label: "Email verified",
        icon: "Email",
        key: "evs",
    },
    {
        isChecked: false,
        label: "Phone verified",
        icon: "Phone",
        key: "pvs",
    },
];

export const BROWSER_LIST_DATA = [
    {
        name: "Google Chrome",
        isIncluded: true,
    },
    {
        name: "Mozilla Firefox",
        isIncluded: true,
    },
    {
        name: "Internet Explorer",
        isIncluded: true,
    },
    {
        name: "Safari",
        isIncluded: true,
    },
    {
        name: "Internet Edge",
        isIncluded: true,
    },
];
