import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import listings from "./listings/listings";
import auth from "./login/auth-slice";
import survey from "./survey/survey";
import toastList from "./toastList/toastList";
import dashboards from "./dashboards/dashboards";
import menulinks from "./menulinks/menulinks";
import uac from "./uac/uac";
import releaseNotes from "./releaseNotes/releaseNotes";
import globalConfig from "./globalConfig/globalConfig";
import flowmanager from "./flowmanager/flowmanager";
import sidePanelInfinity from "./SidePanelInfinity/sidePanelInfinity";

const reducer = {
    auth,
    survey,
    listings,
    toastList,
    dashboards,
    menulinks,
    uac,
    releaseNotes,
    globalConfig,
    flowmanager,
    sidePanelInfinity,
};
const preloadedState = {};

const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(thunkMiddleware),
    devTools: true,
    preloadedState,
});

export default store;
