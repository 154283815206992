import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import React, { Suspense, lazy, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import ProtectedRoute from "./components/protectedRoute";
import Button, { ButtonTypes } from "./Shared/Button/Button";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";
import customFetch from "./fetch/customFetch";
import LinearDeterminate from "./components/LinearDeterminate/LinearDeterminate";
import { toastListActions } from "./store/toastList/toastList";
import { auth, firestore } from "./firebase/config";
import { authActions } from "./store/login/auth-slice";
import * as Sentry from "@sentry/react";
import { fetchMenu } from "./store/menulinks/links";
import { UAC } from "./constants/url";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import { titlePathMap } from "./constants/titlePathMap";
import { InlineSVGs } from "./components/InlineSVGs/InlineSVGs";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppLoader from "./components/AppLoader/AppLoader";
import { globalConfigActions } from "./store/globalConfig/globalConfig";

const HNP = lazy(() => import("./Pages/HNPListings/HNPListings"));
const Login = lazy(() => import("./Pages/Login/Login"));
const Listings = lazy(() => import("./Pages/ListingsWrap/Listings"));
const FlowManagerRoutes = lazy(() =>
    import("./Pages/FlowManagerRoutes/FlowManagerRoutes")
);
const Dashboard = lazy(() =>
    import("./Pages/DashboardListings/DashboardListings")
);
const ConfigurationListings = lazy(() =>
    import("./Pages/ConfigurationListings/ConfigurationListings")
);
const ReportingMetrics = lazy(() =>
    import("./Pages/ReportingMetricsListings/ReportingMetricsListings")
);
const Deals = lazy(() => import("./Pages/DealsListings/DealsListings"));
const PageNotFound = lazy(() => import("./Pages/404Page/404Page"));
const ReleaseNotes = lazy(() => import("./Pages/ReleaseNotes/ReleaseNotes"));
const UACModule = lazy(() =>
    import("./Pages/UserAccessControl/UserAccessControl")
);

const queryClient = new QueryClient();

const App = () => {
    const bodyRef = useRef();
    const dispatch = useDispatch();
    const data = useSelector(state => state.menulinks.links.menulinks);

    const [showFeatures, setShowFeatures] = useState(false);
    const [loading, setLoading] = useState(true);
    const [defaultPathItem, setDefaultPathItem] = useState(null);
    const [isFirebaseDown, setIsFirebaseDown] = useState(false);

    const history = useHistory();
    const { state, pathname } = useLocation();

    const inLineSvgStyle = {
        display: "block",
        height: 0,
    };

    const setPageTitle = () => {
        let currentPath = window.location.pathname;
        let pageTitle = "";
        Object.keys(titlePathMap).forEach(pathKey => {
            if (currentPath.includes(pathKey)) {
                pageTitle = "- " + titlePathMap[pathKey];
            }
        });
        return pageTitle;
    };

    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);

    useEffect(() => {
        const bodyElement = bodyRef?.current?.offsetParent;
        bodyElement?.setAttribute("data-theme", isDarkMode ? "darkMode" : "");
    }, [isDarkMode]);

    useEffect(() => {
        const userPref = localStorage.getItem("darkMode");
        const isSystemDark =
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches;
        const showDark = userPref != null ? userPref === "true" : isSystemDark;
        dispatch(globalConfigActions.setDarkMode(showDark));

        setLoading(true);
        firestore
            .collection("allowed-domains")
            .get()
            .then(snapshot => {
                const allowedDomains = snapshot.docs.map(companyList => {
                    return companyList.data()["email-domain"];
                });
                dispatch(authActions.setAllowedDomains(allowedDomains));
                if (
                    window.location.hash === "#retry" ||
                    window.location.hash === "#logout"
                ) {
                    logoutUser();
                } else {
                    onAuthChange(allowedDomains);
                }
                setIsFirebaseDown(false);
            })
            .catch(error => {
                setLoading(false);
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Something went wrong. Please login again",
                    })
                );
                setIsFirebaseDown(true);
            });

        if (window.location.hash === "#clonedListing") {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Cloned successfully.",
                    autoClose: 10000,
                })
            );
            window.history.pushState(
                "",
                document.title,
                window.location.pathname
            );
        }
    }, []);

    useEffect(() => {
        if (data?.length) {
            setDefaultPathItem(
                data.find(
                    elem =>
                        (process.env.NODE_ENV === "development"
                            ? elem.isStagingDefault
                            : elem.isProdDefault) === true
                ) || data[0]
            );
        }
    }, [data]);

    const storeAuthDataRedirect = async loginData => {
        const res = await customFetch(
            `${UAC.FETCH_USERS_LIST}/${loginData.email}`,
            "GET"
        );
        let modules = [];
        loginData.userGroups = res?.data?.userGroups?.map(_ => {
            _.modules.forEach(module => {
                if (!modules.includes()) {
                    modules.push(module);
                }
            });
            return _.id;
        });
        loginData.modules = modules;
        loginData.isSuperAdmin = res?.data?.isSuperAdmin;
        await dispatch(
            authActions.setAuthInfo({
                name: loginData.displayName,
                emailId: loginData.email,
                photoURL: loginData.photoURL,
                modules: loginData.modules,
                isSuperAdmin: loginData.isSuperAdmin,
            })
        );
        await dispatch(fetchMenu());
        Sentry.setUser({
            username: loginData?.displayName,
            email: loginData?.email,
        });
        if (state) {
            history.replace(get(state, "from.pathname", "/"));
        }
    };

    const onAuthChange = allowedDomains => {
        authUser().then(
            async user => {
                if (validateDomain(user.email, allowedDomains)) {
                    await storeAuthDataRedirect(user);
                } else {
                    onInvalidDomain();
                }
                setLoading(false);
            },
            error => {
                setLoading(false);
                logoutUser();
            }
        );
    };

    const authUser = () => {
        return new Promise(function (resolve, reject) {
            auth.onAuthStateChanged(user => {
                if (user) {
                    resolve(user);
                    user.getIdToken();
                } else {
                    reject("Error");
                }
            });
        });
    };

    const validateDomain = (email, validDomains) => {
        const domain = email.split("@")[1];
        return validDomains.includes(domain);
    };

    const onInvalidDomain = () => {
        logoutUser();
        if (isFirebaseDown) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Firebase is down, Please try after sometime!",
                })
            );
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Please login from valid domain",
                })
            );
        }
    };

    const logoutUser = () => {
        auth.signOut();
        dispatch(authActions.setUserLogOutState());
        Sentry.setUser(null);
        const additionalHash =
            window.location.hash === "#email" ? window.location.hash : "";
        history.replace({ pathname: `/login`, hash: additionalHash });
        setLoading(false);
    };

    if (!window.canRunAds) {
        return (
            <div className="flex bodyContainer dp-parent-col">
                <h1 className="headerTitleBold">
                    Looks like you're using an ad blocker
                </h1>
                <h5 className="header-title-no-bold">
                    Please disable ad blocker or simply disable ad blocker for
                    this site and refresh to continue using the app.
                </h5>
                <Button
                    btnTheme={ButtonTypes.primaryHover_btn}
                    onClick={() => {
                        location.reload();
                    }}
                >
                    Refresh
                </Button>
            </div>
        );
    }

    return (
        <div ref={bodyRef}>
            <ThemeProvider
                theme={createTheme({
                    palette: {
                        mode: isDarkMode ? "dark" : "light",
                    },
                    MuiSlider: {
                        styleOverrides: {
                            thumb: {
                                color: "yellow",
                            },
                            track: {
                                color: "red",
                            },
                            rail: {
                                color: "black",
                            },
                        },
                    },
                })}
            >
                {loading ? (
                    <AppLoader />
                ) : (
                    <QueryClientProvider client={queryClient}>
                        <Layout>
                            <div
                                style={
                                    (pathname.includes("listings") ||
                                        pathname.includes(
                                            "automationdashboard"
                                        ) ||
                                        pathname.includes("login")) &&
                                    !pathname.includes("users") &&
                                    !pathname.includes("user-group") &&
                                    !pathname.includes("release")
                                        ? inLineSvgStyle
                                        : {}
                                }
                            >
                                <InlineSVGs></InlineSVGs>
                            </div>
                            <Helmet>
                                <title>Intent Labs {setPageTitle()}</title>
                            </Helmet>
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <Suspense fallback={null}>
                                    <Switch>
                                        <Route
                                            path="/login"
                                            component={Login}
                                        />
                                        <ProtectedRoute
                                            path="/surveydetails"
                                            component={Listings}
                                            module="SurveyManager"
                                        />
                                        <ProtectedRoute
                                            path="/flowmanager"
                                            component={FlowManagerRoutes}
                                            module="FlowManager"
                                        />
                                        <ProtectedRoute
                                            path="/configurations"
                                            component={ConfigurationListings}
                                            module="Configurations"
                                        />
                                        <ProtectedRoute
                                            path="/hnp"
                                            component={HNP}
                                            module="HNP"
                                        />
                                        <ProtectedRoute
                                            path="/dashboards"
                                            component={Dashboard}
                                            module="Dashboard"
                                        />
                                        <ProtectedRoute
                                            path="/reporting"
                                            component={ReportingMetrics}
                                            module="Reporting"
                                        />
                                        <ProtectedRoute
                                            path="/deals"
                                            component={Deals}
                                            module="Deals"
                                        />
                                        <ProtectedRoute
                                            path="/settings"
                                            component={UACModule}
                                            module="UAC"
                                        />

                                        <ProtectedRoute
                                            path="/release"
                                            component={ReleaseNotes}
                                            module="Releases"
                                        />
                                        <ProtectedRoute exact path="/">
                                            <Redirect
                                                to={
                                                    defaultPathItem?.path || "/"
                                                }
                                            />
                                        </ProtectedRoute>
                                        <Route
                                            path="/login"
                                            component={Login}
                                        />
                                        <Route component={PageNotFound} />
                                    </Switch>
                                </Suspense>
                            </ErrorBoundary>
                        </Layout>
                        {/* React query devtools */}
                        {/* <ReactQueryDevtools initialIsOpen /> */}
                    </QueryClientProvider>
                )}
            </ThemeProvider>
        </div>
    );
};

export default withRouter(App);
