import s from "./ConfirmAndSave.module.scss";
import Icon from "../../Shared/Icon/Icon";
import { useRef, useState } from "react";
import { getComparisonData } from "../../utils/historyPopUpUtils";
import ChangedContentRenderer from "../History/ChangedContentRenderer";
import { capitalizeString } from "../../utils/globalUtils";
import Table from "../../Shared/Table/Table";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import LinearDeterminate from "../LinearDeterminate/LinearDeterminate";
import LinkRenderer from "../Renderers/LinkRenderer/LinkRenderer";
import CustomTooltipRenderer from "../Renderers/CustomTooltipRenderer/CustomTooltipRenderer";
import StatusRenderer from "../Renderers/StatusRenderer/StatusRenderer";
import { isEmpty, isEqual } from "lodash";
import Skeleton from "react-loading-skeleton";
import {
    confirmAndSaveTabs,
    publishTabs,
} from "../../constants/confirmSaveConstants";

const findByType = (type, item, parentArray = []) => {
    if (!item) return <span>-</span>;
    if (item?.type === type) {
        let count = -1;

        parentArray.forEach(_ => {
            if (_.type === type) {
                count += 1;
            } else if (_.dependencies?.length) {
                _.dependencies.forEach(d => {
                    count += 1;
                });
            }
        });
        return {
            ...item?.metaData,
            id: item.id,
            count,
            isLive: item.isLive,
            dependencies: parentArray,
        };
    } else {
        if (!isEmpty(item?.dependencies))
            return findByType(type, item.dependencies[0], parentArray);
    }
};

export const DependencyTable = ({
    baseItem,
    columnsArr,
    showBorder = false,
    noDomain,
}) => {
    const columns1 = useRef([
        {
            id: "id",
            text: "Container",
            CustomRender: ({ item }) => {
                const currentObj = findByType(
                    "CONTAINER",
                    item,
                    baseItem.dependencies
                );
                if (!currentObj?.name) return "-";
                return (
                    <LinkRenderer
                        target={"_blank"}
                        text={currentObj.id + ":" + currentObj?.name}
                        link={`/flowmanager/container/edit/${currentObj.id}`}
                    />
                );
            },
        },
        {
            id: "id",
            text: "Collection",
            CustomRender: ({ item }) => {
                const currentObj = findByType(
                    "COLLECTION",
                    item,
                    baseItem.dependencies
                );
                if (!currentObj?.name) return "-";

                return (
                    <LinkRenderer
                        target={"_blank"}
                        text={currentObj?.id + ":" + currentObj?.name}
                        link={`/flowmanager/collection/edit/${currentObj?.id}`}
                    />
                );
            },
        },
    ]);

    const columns2 = useRef([
        {
            text: "Domain",
            key: "id",
            disableCopyText: true,
            styles: {
                minWidth: "150px",
            },
            CustomRender: ({ item }) => {
                const currentObj = findByType("SITE", item, item.dependencies);
                if (!currentObj?.name) return "-";

                return (
                    <div className="dp-parent">
                        <LinkRenderer
                            target={"_blank"}
                            text={currentObj?.name + ":" + currentObj?.domain}
                            link={`/flowmanager/siteconfig/edit/${currentObj.id}`}
                        />
                    </div>
                );
            },
        },
        {
            text: "Flows",
            key: "id",
            disableCopyText: true,
            styles: {
                minWidth: "150px",
            },
            CustomRender: ({ item }) => {
                const currentObj = findByType("FLOW", item, item.dependencies);
                if (!currentObj?.name) return "-";
                const dc = () => {
                    return (
                        <div className={s.popupTableWrap}>
                            <DependencyTable baseItem={item} noDomain />
                        </div>
                    );
                };
                return (
                    <CustomTooltipRenderer
                        showAfterCount={1}
                        data={item.dependencies}
                        customRenderer={() => dc()}
                        position={"top"}
                    >
                        <div className="dp-parent">
                            <StatusRenderer
                                status={currentObj.isLive}
                                activeContent={""}
                                inactiveContent={""}
                            />
                            <LinkRenderer
                                target={"_blank"}
                                text={
                                    currentObj?.name +
                                    ":" +
                                    currentObj?.displayName
                                }
                                link={`/flowmanager/flow/edit/${currentObj.id}`}
                            />
                            <span className="link ml-5">
                                {currentObj?.count > 0 &&
                                    item?.dependencies[0]?.dependencies[0]
                                        ?.type !== "ITEM" &&
                                    `+${currentObj.count} more`}
                            </span>
                        </div>
                    </CustomTooltipRenderer>
                );
            },
        },
        {
            text: "Containers",
            key: "id",
            disableCopyText: true,
            styles: {
                minWidth: "150px",
            },
            CustomRender: ({ item }) => {
                const currentObj = findByType(
                    "CONTAINER",
                    item,
                    item.dependencies
                );

                if (!currentObj?.name) return "-";

                const dc = () => {
                    return (
                        <div className={s.popupTableWrap}>
                            <DependencyTable baseItem={item} noDomain />
                        </div>
                    );
                };

                return (
                    <CustomTooltipRenderer
                        showAfterCount={1}
                        data={item.dependencies}
                        customRenderer={() => dc()}
                        position={"top"}
                    >
                        <LinkRenderer
                            target={"_blank"}
                            text={currentObj?.id + ":" + currentObj?.name}
                            link={`/flowmanager/container/edit/${currentObj.id}`}
                        />
                        <span className="link ml-5">
                            {currentObj?.count > 0 &&
                                item?.dependencies[0]?.dependencies[0]?.type !==
                                    "ITEM" &&
                                `+${currentObj.count} more`}
                        </span>
                    </CustomTooltipRenderer>
                );
            },
        },
        {
            text: "Collections",
            key: "id",
            disableCopyText: true,
            styles: {
                minWidth: "150px",
            },
            CustomRender: ({ item }) => {
                const currentObj = findByType(
                    "COLLECTION",
                    item,
                    item.dependencies
                );

                if (!currentObj?.name) return "-";

                const dc = () => {
                    return <DependencyTable baseItem={item} noDomain />;
                };

                return (
                    <CustomTooltipRenderer
                        showAfterCount={1}
                        data={item.dependencies}
                        customRenderer={
                            item?.dependencies[0].type !== "ITEM" &&
                            item?.dependencies[0]?.dependencies[0].type !==
                                "ITEM" &&
                            item?.dependencies[0]?.dependencies[0]
                                ?.dependencies[0]?.type !== "ITEM"
                                ? dc
                                : () => {
                                      return null;
                                  }
                        }
                        position={"top"}
                    >
                        <LinkRenderer
                            target={"_blank"}
                            text={currentObj?.id + ":" + currentObj?.name}
                            link={`/flowmanager/collection/edit/${currentObj.id}`}
                        />

                        <span className="link">
                            {currentObj?.count > 0 &&
                                item?.dependencies[0].type !== "ITEM" &&
                                item?.dependencies[0]?.dependencies[0].type !==
                                    "ITEM" &&
                                item?.dependencies[0]?.dependencies[0]
                                    ?.dependencies[0]?.type !== "ITEM" &&
                                ` +${currentObj.count} more`}
                        </span>
                    </CustomTooltipRenderer>
                );
            },
        },
    ]);

    return !isEmpty(baseItem?.dependencies) ||
        !isEmpty(baseItem.allDependencies) ? (
        <Table
            CustomErrorMessageComponent={() => null}
            columns={
                columnsArr === "columns1"
                    ? columns1.current
                    : noDomain
                    ? columns2.current?.filter(_ => _.text !== "Domain")
                    : columns2.current
            }
            data={baseItem?.dependencies || baseItem.allDependencies}
            showBorder={showBorder}
        ></Table>
    ) : (
        <div className="p-20">
            No dependencies found, you can proceed saving the changes.
        </div>
    );
};

const DependencyItem = ({
    item,
    loadingDependencies,
    loading,
    isPublishHistory = false,
}) => {
    const [showTable, setShowTable] = useState(true);
    const [showDiff, setShowDiff] = useState(false);
    const iconMapping = {
        collectionDependency: "Collection",
        containerDependency: "Container",
        flowDependencies: "Flow",
    };
    const [showDetails, setShowDetails] = useState(false);
    const [active, setActive] = useState(
        isPublishHistory ? publishTabs[0] : confirmAndSaveTabs[0]
    );

    const data = getComparisonData(
        item.oldValue,
        item.newValue,
        item.diffConfig,
        true
    );
    const changedItems = (data && data[0]) || [];

    return (
        <div className={s.dependencyItem}>
            <div className={`p-20 ${!isPublishHistory ? "pb-0" : "pb-10"}`}>
                <div className="dp-parent dp-parent-hor-space-between mb-10">
                    <div>
                        {capitalizeString(item.entityType)}:{" "}
                        <span className={s.typeValue}>{item.name}</span>
                    </div>
                    <div className="dp-parent dp-parent-no-shrink gap-30">
                        <div
                            className={`${s.btnViewDetails} ${
                                showDetails ? s.open : ""
                            }`}
                            onClick={() => {
                                setShowDetails(!showDetails);
                            }}
                        >
                            {showDetails ? "Hide Details" : "View Details"}
                        </div>
                    </div>
                </div>

                {!isPublishHistory && (
                    <div className={`${s.details}`}>
                        {loadingDependencies || loading ? (
                            <LinearDeterminate></LinearDeterminate>
                        ) : (
                            item?.data && (
                                <div className="dp-parent gap-10">
                                    {Object.keys(item?.data).map(_ => {
                                        return iconMapping[_] &&
                                            item?.data[_]?.length > 0 ? (
                                            <div key={JSON.stringify(_)}>
                                                <TagRenderer
                                                    type={"noHover"}
                                                    className={"dependencyTag"}
                                                >
                                                    <Icon
                                                        icon={iconMapping[_]}
                                                        size="14px"
                                                        className="mr-5"
                                                    />
                                                    {item?.data[_].length}{" "}
                                                    {iconMapping[_]}
                                                    {item?.data[_].length > 1
                                                        ? "s"
                                                        : ""}
                                                </TagRenderer>
                                            </div>
                                        ) : null;
                                    })}
                                </div>
                            )
                        )}
                    </div>
                )}
                {showDetails && !isPublishHistory && (
                    <div className={`${s.buttonGroupHeader}`}>
                        <div className={`${s.buttonGroup}`}>
                            {confirmAndSaveTabs?.map(tab => (
                                <div
                                    className={`${s.tab} ${
                                        tab === active ? s.active : ""
                                    } ${loadingDependencies ? s.disable : ""}`}
                                    key={tab}
                                    onClick={
                                        !loadingDependencies
                                            ? () => setActive(tab)
                                            : null
                                    }
                                >
                                    {tab}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {showDetails && (
                <div
                    className={`${s.dependencyTableWrap}
                ${isPublishHistory ? s.onlyDifference : ""}`}
                >
                    <div
                        className={`${s.dependencyTable} 
                        ${isPublishHistory ? s.onlyDifferenceTab : ""}
                        ${active !== "Dependency" ? "p-20" : ""}
                        ${isPublishHistory ? "pt-0" : ""}
                        ${
                            isEqual(item.newValue, item.oldValue)
                                ? s.noTransition
                                : ""
                        }
                        `}
                    >
                        {showTable ? (
                            <>
                                {active === "Dependency" && (
                                    <DependencyTable
                                        baseItem={item?.data}
                                        columnsArr={"columns2"}
                                    />
                                )}
                                {active === "Differentiation" &&
                                !isEqual(item.newValue, item.oldValue) ? (
                                    <ChangedContentRenderer
                                        keysObj={changedItems}
                                        newData={item.newValue}
                                        oldData={item.oldValue}
                                        config={item.diffConfig}
                                        isPublishHistory={isPublishHistory}
                                    />
                                ) : active !== "Dependency" ? (
                                    <div>
                                        No differences found, you can proceed
                                        saving the changes.
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <div className="p-20">
                                <Skeleton width={350} height={38} />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DependencyItem;
