import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../fetch/customFetch";
import { ADS } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import { get, isEmpty } from "lodash";
import fetchAll from "../../fetch/fetchAll";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    mapAdUnitSlots,
    slotsDeleteApiData,
    slotsPostApiData,
    slotsPutApiData,
    slotParams,
    historyApiTypeConstant,
} from "../../utils/adunitUtils";
import { slotType } from "../../utils/offerPathUtils";
import { MESSAGES } from "../../constants/messages";
import { FILTERS } from "../../constants/listingSearch";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    // loading: false,
    updatedAt: new Date(),
    loading: false,
    mainLoader: false,
    createAdUnit: {
        basics: {
            name: "",
            type: null,
            status: false,
            error: false,
            surveys: [],
            selectedSurveyIds: [],
            adUnitId: "",
            initialType: null,
            dynamicSortFunc: "",
            bidMultiplier: 1,
            scoreMultiplier: 0,
            rtbGroupId: "",
        },
        slots: {
            error: false,
            weight: "",
            advId: null,
            editAdvId: null,
            dealId: "",
            revenueType: null,
            revenueTypeError: false,
            dealIdError: false,
            creative: null,
            creativeError: false,
            selectedListItems: [], //also contains soft deleted items
            selectedItems: [], //only contains selected items (used for save and confirm)
            editWeight: "",
            editDealId: "",
            editRevenueType: null,
            editCreative: null,
            editId: "",
            adCreativesList: [],
            revenueList: [],
            editDealIdError: false,
            editCreativeError: false,
            customKeyVal: [],
        },
        existingData: null,
        deletedSlots: [],
        historyData: [],
        historyDataRaw: [],
    },
    createAdUnitBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.SURVEY.filterKey,
        // filterTerm: "_",
        filterTerm: null, //Setting the filterTerm to null makes the tag search work
        filterOperator: FILTERS.SURVEY.filterOperator,
        filtersOperator: FILTERS.SURVEY.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "adunits"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

const creativeParams = { minimal: "true" };

export const adsHistory = createAsyncThunk(
    "listings/adunits/adshistory",
    async (id, { getState, dispatch }) => {
        const getAdUnitType =
            getState().listings.adUnits.createAdUnit.basics.type;
        const response = customFetch(
            `${ADS.GET_ADS_HISTORY}/${historyApiTypeConstant[getAdUnitType]}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const historyListingsTable = createAsyncThunk(
    "listings/adunits/historyAdUnitListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${ADS.FETCH_HISTORY_LISTINGS}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchAdUnitsData = createAsyncThunk(
    "listings/adUnits/fetchAdUnitsListingData",
    async ({ refreshClicked, noPagination }, { dispatch, getState }) => {
        const state = getState().listings.adUnits.pagination;
        const params = noPagination ? {} : paginationParams(state);
        const { listings } = getState();

        let tagFilters = get(
            listings,
            "adUnits.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.AD_UNIT
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(adUnitsSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.adUnits.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.AD_UNIT,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.adUnits.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                ADS.FETCH_AD_UNITS,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch Ad Units",
                        })
                    );
                    dispatch(
                        adUnitsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(adUnitsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError &&
                state.filterTerm &&
                state.filterTerm !== "_") || //remove this hard coded condition once API is filtering the data
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.AD_UNITS}`;
            dispatch(adUnitsSlice.actions.setTableError(errorMessage));
        } else if (
            dispatchSearchError &&
            !data.length &&
            state.filterTerm === "_" //remove this hard coded condition once API is filtering the data
        ) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Ad Unit`;
            dispatch(adUnitsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchAdUnitDetails = createAsyncThunk(
    "surveydetails/adunit/adUnitData",
    async (id, { dispatch }) => {
        const responses = await fetchAll([
            customFetch(`${ADS.GET_AD_UNIT_DATA}/${id}`, "GET"),
            customFetch(ADS.GET_AD_CREATIVES, "GET", creativeParams),
        ]);
        if (
            isEmpty(get(responses, `0.data.data`, {})) ||
            get(responses, `1.data.data`, []).length == 0
        ) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to fetch ${
                        get(responses, `1.data.data`, []).length == 0
                            ? "Ads Creatives"
                            : "Ad Unit"
                    }!`,
                    autoClose: 5000,
                })
            );
        }
        let responsesArray = [];
        responsesArray.push(get(responses, `0.data.data`, {}));
        responsesArray.push(get(responses, `1.data.data`, []));
        return responsesArray;
    }
);

export const fetchAdCreatives = createAsyncThunk(
    "surveydetails/adunit/fetchadcreative",
    async (_, { dispatch }) => {
        const response = await customFetch(
            ADS.GET_AD_CREATIVES,
            "GET",
            creativeParams
        );
        const data = get(response, "data.data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Ads Creatives!",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const fetchSingleAdCreative = createAsyncThunk(
    "surveydetails/adunit/fetchsingleadcreative",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${ADS.GET_SINGLE_AD_CREATIVE}/${id}`,
            "GET",
            creativeParams
        );
        const data = get(response, "data.data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Ads Creative!",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const addAdUnitDetails = createAsyncThunk(
    "surveydetails/adunit/create",
    async (_, { getState, dispatch }) => {
        const data = getAdUnitsParams(getState());
        const response = await customFetch(ADS.CREATE_AD_UNIT, "POST", data);
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create Ad Unit!",
                })
            );
        } else {
            if (data.type !== "RtbGroup")
                dispatch(createSlots(response.data.data.id));
        }
        return response;
    }
);

const createSlots = createAsyncThunk(
    "listings/adunits/createslots",
    async (id, { getState, dispatch }) => {
        const state = getState().listings.adUnits.createAdUnit;
        const slotsApi = slotsPostApiData(state, id);
        return await fetchAll([slotsApi]);
    }
);

export const updateAdUnitDetails = createAsyncThunk(
    "surveysdetails/adunit/update",
    async (id, { getState, dispatch }) => {
        const params = getAdUnitsParams(getState());
        const response = await customFetch(
            `${ADS.UPDATE_AD_UNIT}/${id}`,
            "PUT",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update Ad Unit!",
                })
            );
        } else {
            if (params.type !== "RtbGroup") {
                dispatch(updateSlots(response.data.data.id));
                dispatch(deleteSlots());
            }
        }
        return response;
    }
);

const updateSlots = createAsyncThunk(
    "listings/adunits/updateslots",
    async (id, { getState, dispatch }) => {
        const state = getState().listings.adUnits.createAdUnit;
        const slotsApi = slotsPutApiData(state, id);
        return await fetchAll(slotsApi);
    }
);

export const deleteSlots = createAsyncThunk(
    "listings/adunits/deleteslots",
    async (_, { getState }) => {
        const state = getState().listings.adUnits.createAdUnit;
        const slotsApi = slotsDeleteApiData(state);

        return await fetchAll(slotsApi);
    }
);

export const deleteAdUnitDetails = createAsyncThunk(
    "surveysdetails/adunit/delete",
    async id => {
        const response = await customFetch(
            `${ADS.DELETE_AD_UNIT}/${id}`,
            "DELETE"
        );
        return response;
    }
);

const getAdUnitsParams = state => {
    const adUnitState = state.listings.adUnits.createAdUnit;
    const type = adUnitState.basics.type;
    const slots = adUnitState.slots.selectedListItems;
    const adUnitSlots = slots?.map((slot, idx) => {
        const obj = {
            dealId: slot.dealId,
            revType: slot.revenueType,
            [slotType[type]]: slot[slotType[type]],
            creativeId: +slot.creative.split(":")[0],
            dedupeKeys: slot.dedupeRules,
            customKeyVal: slot.customKeyVal || [],
        };
        if (slot.id !== "-") {
            obj["id"] = slot.id;
        }

        return obj;
    });
    const getDeletedSlots = adUnitState.deletedSlots;
    const deletedIds = [];
    if (getDeletedSlots?.length > 0) {
        getDeletedSlots.forEach((item, idx) => {
            deletedIds.push(item.id);
        });
    }

    return {
        name: adUnitState.basics.name,
        type: adUnitState.basics.type,
        ...(type === "RtbGroup" && {
            rtbGroup: {
                ...(adUnitState.basics.rtbGroupId != "" && {
                    id: adUnitState.basics.rtbGroupId,
                }),
                dynamicSortFunc: adUnitState.basics.dynamicSortFunc,
                rtbAds:
                    deletedIds?.length > 0
                        ? adUnitSlots.filter(
                              val => !deletedIds.includes(val.id)
                          )
                        : adUnitSlots,
            },
        }),
    };
};

const mapCreativesList = creatives => {
    return creatives?.map(creative => ({
        id: creative.id,
        key: `${creative.id}: ${creative.name}`,
        isDeprecated: creative?.isDeprecated,
    }));
};

export const onFilterApplied = createAsyncThunk(
    "listings/adunits/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.adUnits.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        let filterParamsData = FILTER_PARAMS.SURVEY_PATH.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const adUnitsSlice = createSlice({
    name: "adUnits",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setAdUnitName(state, action) {
            state.createAdUnit.basics.name = action.payload;
            if (state.createAdUnit.basics.type != null) {
                state.createAdUnit.basics.error =
                    typeof action.payload == "string" &&
                    action.payload.trim() == "";
            }
        },
        setBidMultiplier(state, action) {
            state.createAdUnit.basics.bidMultiplier = action.payload;
        },
        setScoreMultiplier(state, action) {
            state.createAdUnit.basics.scoreMultiplier = action.payload;
        },
        setsortFunction(state, action) {
            state.createAdUnit.basics.dynamicSortFunc = action.payload;
        },
        setAdUnitBasicError(state, action) {
            state.createAdUnit.basics.error = action.payload;
        },
        setAdUnitSlotsError(state, action) {
            state.createAdUnit.slots.error = action.payload;
        },
        setOfferPathSlotsError(state, action) {
            state.createAdUnit.slots.error = action.payload;
        },
        setAdUnitType(state, action) {
            state.createAdUnit.basics.type = action.payload;
            if (state.createAdUnit.basics.name.length > 0) {
                state.createAdUnit.basics.error = false;
            }
        },
        setAdUnitInitialType(state, action) {
            state.createAdUnit.basics.initialType = action.payload;
            if (state.createAdUnit.basics.name.length > 0) {
                state.createAdUnit.basics.error = false;
            }
        },

        setAdUnitStatus(state, action) {
            state.createAdUnit.basics.status = action.payload;
        },
        addWeight(state, action) {
            state.createAdUnit.slots.weight = action.payload;
        },
        addDealId(state, action) {
            state.createAdUnit.slots.dealId = action.payload;
        },
        addAdvId(state, action) {
            state.createAdUnit.slots.advId = action.payload;
        },
        setEditAdvId(state, action) {
            state.createAdUnit.slots.editAdvId = action.payload;
        },
        addRevenueType(state, action) {
            state.createAdUnit.slots.revenueType = action.payload;
        },
        addRevenueTypeError(state, action) {
            state.createAdUnit.slots.revenueTypeError = action.payload;
        },
        setDealIdError(state, action) {
            state.createAdUnit.slots.dealIdError = action.payload;
        },
        addCreative(state, action) {
            state.createAdUnit.slots.creative = action.payload;
        },
        addCreativeError(state, action) {
            state.createAdUnit.slots.creativeError = action.payload;
        },
        addSelectedListItems(state, action) {
            state.createAdUnit.slots.selectedListItems = action.payload;
            state.createAdUnit.slots.selectedItems = action.payload.filter(
                _ =>
                    !state.createAdUnit.deletedSlots.find(
                        del => del.id === _.id
                    )
            );
        },
        editWeight(state, action) {
            state.createAdUnit.slots.editWeight = action.payload;
        },
        setEditDealId(state, action) {
            state.createAdUnit.slots.editDealId = action.payload;
        },
        editRevenueType(state, action) {
            state.createAdUnit.slots.editRevenueType = action.payload;
        },
        editCreative(state, action) {
            state.createAdUnit.slots.editCreative = action.payload;
        },
        setEditId(state, action) {
            state.createAdUnit.slots.editId = action.payload;
        },
        setEditDealIdError(state, action) {
            state.createAdUnit.slots.editDealIdError = action.payload;
        },
        setEditCreativeError(state, action) {
            state.createAdUnit.slots.editCreativeError = action.payload;
        },
        resetAdUnit(state) {
            state.createAdUnit.basics.name = "";
            state.createAdUnit.basics.type = null;
            state.createAdUnit.basics.error = false;
            state.createAdUnit.basics.rtbGroupId = "";
            state.createAdUnit.basics.dynamicSortFunc = "";
            state.createAdUnit.basics.bidMultiplier = 1;
            state.createAdUnit.basics.scoreMultiplier = 0;
            state.createAdUnit.slots.weight = "";
            state.createAdUnit.slots.dealId = "";
            state.createAdUnit.slots.error = false;
            state.createAdUnit.slots.weightGroupId = null;
            state.createAdUnit.slots.selectedListItems = [];
            state.createAdUnit.slots.selectedItems = [];
            state.createAdUnit.slots.revenueTypeError = false;
            state.createAdUnit.slots.dealIdError = false;
            state.createAdUnit.slots.creativeError = false;
            state.createAdUnit.slots.revenueType = null;
            state.createAdUnit.slots.creative = null;
            state.createAdUnit.existingData = null;
            state.createAdUnit.deletedSlots = [];
            state.createAdUnit.slots.editDealIdError = false;
            state.createAdUnit.slots.editDealId = "";
            state.createAdUnit.slots.customKeyVal = [];
            state.createAdUnit.slots.editCreativeError = false;
        },
        revertDeleteSlots(state, action) {
            const { data } = action.payload;
            const index = state.createAdUnit.deletedSlots.findIndex(
                _ => _.id === data.id
            );
            state.createAdUnit.deletedSlots.splice(index, 1);

            if (state.createAdUnit.deletedSlots.length === 0) {
                state.createAdUnit.slots.selectedItems =
                    state.createAdUnit.slots.selectedListItems;
            } else {
                state.createAdUnit.slots.selectedItems.push(data);
            }
        },
        setDeletedSlots(state, action) {
            state.createAdUnit.deletedSlots.push(action.payload);
            state.createAdUnit.slots.selectedItems =
                state.createAdUnit.slots.selectedItems.filter(
                    _ => _.id !== action.payload.id
                );
        },
        setRevenueList(state, action) {
            state.createAdUnit.slots.revenueList = action.payload;
        },
        loading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setWeightGroupId(state, action) {
            state.createAdUnit.slots.weightGroupId = action.payload;
        },
        setSurveys(state, action) {
            state.createAdUnit.basics.surveys = action.payload;
        },
        setSelectedSurveyIds(state, action) {
            state.createAdUnit.basics.selectedSurveyIds = action.payload;
        },
        setAdUnitId(state, action) {
            state.createAdUnit.basics.adunitId = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setHistoryData(state, action) {
            state.createAdUnit.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createAdUnit.historyDataRaw = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAdUnitsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAdUnitsData.fulfilled, (state, action) => {
            // state.pagination.totalRowsCount = action.payload.metaData.totalFound;
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            let listData = get(action.payload, "data", []);
            state.list = !isEmpty(listData) ? listData : []; // Added this as for long integers we are getting {} as data
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchAdCreatives.fulfilled, (state, action) => {
            state.createAdUnit.slots.adCreativesList = mapCreativesList(
                action.payload
            );
            state.createAdUnitBackup = state.createAdUnit;
        });
        builder.addCase(fetchAdUnitDetails.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAdUnitDetails.fulfilled, (state, action) => {
            if (action.payload[1]) {
                state.createAdUnit.slots.adCreativesList = mapCreativesList(
                    action.payload[1]
                );
            }
            const adUnit = action.payload[0];
            state.createAdUnit.existingData = adUnit;
            state.createAdUnit.basics.name = adUnit.name;
            state.createAdUnit.basics.adUnitId = adUnit.adUnitId;
            state.createAdUnit.basics.type = adUnit.type;
            state.createAdUnit.slots.selectedListItems = mapAdUnitSlots(
                adUnit,
                state.createAdUnit.slots.adCreativesList
            );
            state.createAdUnit.slots.selectedItems =
                state.createAdUnit.slots.selectedListItems;
            state.createAdUnitBackup = state.createAdUnit;
            state.loading = false;
            if (adUnit.type === "RtbGroup") {
                state.createAdUnit.basics.rtbGroupId = adUnit.rtbGroup.id;
                state.createAdUnit.basics.dynamicSortFunc =
                    adUnit.rtbGroup.dynamicSortFunc;
                const variables = adUnit.rtbGroup.dynamicSortFunc?.split("+");
                if (variables && variables.length >= 2) {
                    state.createAdUnit.basics.bidMultiplier = parseFloat(
                        variables[0].split("*")[1] || "1"
                    );
                    state.createAdUnit.basics.scoreMultiplier = parseFloat(
                        variables[1].split("*")[1] || "0"
                    );
                }
            }
        });
        builder.addCase(updateAdUnitDetails.rejected, (state, action) => {
            state.mainLoader = false;
            alert(`Failed to update ad unit`);
        });
        builder.addCase(addAdUnitDetails.rejected, (state, action) => {
            state.mainLoader = false;
            alert(`Failed to update ad unit`);
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const adUnitsActions = adUnitsSlice.actions;
export default adUnitsSlice.reducer;
