import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep, forEach, get, isEmpty, keys } from "lodash";
import { CONFIGURATIONS, ROAS_CONFIG } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { abTestParams, abTestVariantsParams } from "../../utils/abtestUtils";
import {
    mapAbTestEditPage,
    getInitialRowsCount,
    getRequiredObject,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { SURVEYS } from "../../constants/url";
import { MESSAGES } from "../../constants/messages";
import fetchAll from "../../fetch/fetchAll";
import { FILTERS } from "../../constants/listingSearch";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { defaultChannels } from "../../utils/surveyConfigUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { initialFilterData } from "../../utils/abtestUtils";

const initialTargetParamsState = {
    regions: [],
    countries: [],
    browsers: [],
    devices: [],
    os: [],
    genders: [],
    utmMediums: [],
    utmSources: [],
    clientIPs: [],
    utmCampaigns: [],
    utmContents: [],
    ageRange: [],
    keywords: [],
    availableSet: ["surveyId", "utmSources", "region", "ageRange"],
    keyVals: [],
    publishers: [],
    // spamCheck: {
    //     spamIP: false,
    //     category: [],
    // },
    jobTypes: [],
    utmTerms: [],
    zipCodes: [],
};

const scopeChoices = [
    "global",
    "landing",
    "survey",
    "offers",
    "offer path",
    "listings",
    "fulfillment",
];
// const choiceValue = [
//   { id: 0, value: "control" },
//   { id: 1, value: "engage" },
//   { id: 2, value: "power" },
//   { id: 3, value: "sigma" },
//   { id: 4, value: "network" },
//   { id: 5, value: "travel" },
//   { id: 6, value: "data" },
//   { id: 7, value: "bytes" },
//   { id: 8, value: "dynamic" },
//   { id: 9, value: "filter" },
// ];

const initialVariantState = {
    basics: {
        name: "",
        weight: "",
        id: null,
        error: false,
    },
    targettingParam: {
        targetParams: initialTargetParamsState,
        dropDownState: {
            plusDropdownData: "",
            showPlusDropdown: false,
            productList: [
                { id: 1, firstName: "abcd", lastName: "myjobscornesssr.com" },
                { id: 2, firstName: "eafgbh", lastName: "realdealsweeps.com" },
                { id: 3, firstName: "ijkl", lastName: "rewardsurveyusa.com" },
                { id: 4, firstName: "mnop", lastName: "test@test.com" },
                { id: 5, firstName: "qrst", lastName: "test@test.com" },
                { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                { id: 7, firstName: "yz", lastName: "test@test.com" },
            ],
            productListPlusData: [
                {
                    id: "utmSources",
                    firstName: "UTM Sources",
                    lastName: "myjobscornesssr.com",
                },
                {
                    id: "regions",
                    firstName: "Region",
                    lastName: "realdealsweeps.com",
                },
                {
                    id: "keywords",
                    firstName: "Keywords",
                    lastName: "rewardsurveyusa.com",
                },
                {
                    id: "ageRange",
                    firstName: "Age Range",
                    lastName: "test@test.com",
                },
                {
                    id: "genders",
                    firstName: "Gender",
                    lastName: "test@test.com",
                },
                {
                    id: "countries",
                    firstName: "Countries",
                    lastName: "test@test.com",
                },
                {
                    id: "zipCodes",
                    firstName: "Zip Codes",
                    lastName: "test@test.com",
                },
                {
                    id: "timeOfDay",
                    firstName: "Time of Day",
                    lastName: "test@test.com",
                },
                {
                    id: "utmCampaigns",
                    firstName: "UTM Campaigns",
                    lastName: "test@test.com",
                },
                {
                    id: "utmContents",
                    firstName: "UTM Contents",
                    lastName: "test@test.com",
                },
                {
                    id: "utmMediums",
                    firstName: "UTM Mediums",
                    lastName: "test@test.com",
                },
                {
                    id: "os",
                    firstName: "Operating Systems",
                    lastName: "test@test.com",
                },
                {
                    id: "browsers",
                    firstName: "Browsers",
                    lastName: "test@test.com",
                },
                {
                    id: "devices",
                    firstName: "Devices",
                    lastName: "test@test.com",
                },
                {
                    id: "allowedDays",
                    firstName: "Allowed Days",
                    lastName: "test@test.com",
                },
                {
                    id: "keyVals",
                    firstName: "A/B Tests",
                    lastName: "test@test.com",
                },
            ],
        },
        zipCodeState: {
            csvFileName: "",
            csvFile: "",
            csvArray: [],
            zipCodeError: false,
            onDropActiveZipCode: false,
            // zipCode: [],
        },
        clientIPsState: {
            csvFileName: "",
            csvFile: "",
            csvArray: [],
            clientIPsError: false,
            onDropActiveClientIPs: false,
        },
    },
    exclusionTargettingParam: {
        targetParams: initialTargetParamsState,
        dropDownState: {
            plusDropdownData: "",
            showPlusDropdown: false,
            productList: [
                { id: 1, firstName: "abcd", lastName: "myjobscornesssr.com" },
                { id: 2, firstName: "eafgbh", lastName: "realdealsweeps.com" },
                { id: 3, firstName: "ijkl", lastName: "rewardsurveyusa.com" },
                { id: 4, firstName: "mnop", lastName: "test@test.com" },
                { id: 5, firstName: "qrst", lastName: "test@test.com" },
                { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                { id: 7, firstName: "yz", lastName: "test@test.com" },
            ],
            productListPlusData: [
                {
                    id: "utmSources",
                    firstName: "UTM Sources",
                    lastName: "myjobscornesssr.com",
                },
                {
                    id: "regions",
                    firstName: "Region",
                    lastName: "realdealsweeps.com",
                },
                {
                    id: "keywords",
                    firstName: "Keywords",
                    lastName: "rewardsurveyusa.com",
                },
                {
                    id: "ageRange",
                    firstName: "Age Range",
                    lastName: "test@test.com",
                },
                {
                    id: "genders",
                    firstName: "Gender",
                    lastName: "test@test.com",
                },
                {
                    id: "countries",
                    firstName: "Countries",
                    lastName: "test@test.com",
                },
                {
                    id: "zipCodes",
                    firstName: "Zip Codes",
                    lastName: "test@test.com",
                },
                {
                    id: "timeOfDay",
                    firstName: "Time of Day",
                    lastName: "test@test.com",
                },
                {
                    id: "utmCampaigns",
                    firstName: "UTM Campaigns",
                    lastName: "test@test.com",
                },
                {
                    id: "utmMediums",
                    firstName: "UTM Mediums",
                    lastName: "test@test.com",
                },
                {
                    id: "os",
                    firstName: "Operating Systems",
                    lastName: "test@test.com",
                },
                {
                    id: "browsers",
                    firstName: "Browsers",
                    lastName: "test@test.com",
                },
                {
                    id: "devices",
                    firstName: "Devices",
                    lastName: "test@test.com",
                },
                {
                    id: "allowedDays",
                    firstName: "Allowed Days",
                    lastName: "test@test.com",
                },
                {
                    id: "keyVals",
                    firstName: "A/B Tests",
                    lastName: "test@test.com",
                },
            ],
        },
        zipCodeState: {
            csvFileName: "",
            csvFile: "",
            csvArray: [],
            zipCodeError: false,
            onDropActiveZipCode: false,
            // zipCode: [],
        },
        clientIPsState: {
            csvFileName: "",
            csvFile: "",
            csvArray: [],
            clientIPsError: false,
            onDropActiveClientIPs: false,
        },
    },
    featureMaps: {
        updatedList: [],
        selected: [],
        selectedDetails: null,
        selectedListActiveItem: "",
        savedList: [],
        error: false,
    },
};

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "name",
        isDesc: false,
        totalRowsCount: 0,
        filterKey: FILTERS.AB_TEST.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.AB_TEST.filterOperator,
        filtersOperator: FILTERS.AB_TEST.operator,
        filterTerm2: "active",
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData(initialFilterData, "abtests"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    featureMapListData: [],
    createAbTest: {
        basics: {
            name: "",
            status: false,
            // surveyId: null,
            error: false,
            showPrompt: true,
            mainLoader: false,
            uuid: "",
            channelMapData: [],
            channelMapDataInitial: {},
            configs: [],
            channelMaps: [],
            updatedConfigs: [],
            updatedChannels: [],
        },
        variants: {
            pages: {
                "Page 1": {
                    ...initialVariantState,
                },
            },
            pageOrder: ["Page 1"],
            selectedPageId: "Page 1",
            deletedPageIds: [],
            surveyList: [],
        },
        historyData: [],
        historyDataRaw: [],
    },
};

export const historyListingsTable = createAsyncThunk(
    "listings/abtests/historyAbtestListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${CONFIGURATIONS.FETCH_ABTEST_HISTORY}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const historyListingsVariantTable = createAsyncThunk(
    "listings/abtests/historyAbtestListingsVariantTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${CONFIGURATIONS.FETCH_ABTEST_VARIANT_HISTORY}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchFeatureMapListData = createAsyncThunk(
    "listings/abtests/fetchFeatureMapListData",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            CONFIGURATIONS.GET_FEATUREMAP_LIST_DATA,
            "POST",
            {
                limit: 1000,
                offset: 0,
            }
        );
        const { listings } = getState();
        const state = get(listings, "abtests.createAbTest.variants.pages", {});

        const data = get(response, "data", []);
        return [data, state];
    }
);

export const fetchAbTestsData = createAsyncThunk(
    "listings/abtests/abtestsListingData",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const pagination = get(listings, "abtests.pagination", {});
        const params = paginationParams(pagination);
        // temp status filter for a quick fix for status filter
        const statusFilter = get(
            listings,
            "abtests.pagination.filterTerm2",
            "active"
        );
        let tagFilters = get(
            listings,
            "abtests.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.AB_TEST
        );

        const filterParamsList = [
            "filters",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(abTestsSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.abtests.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.AB_TEST,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.abtests.list", []);
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                CONFIGURATIONS.FETCH_AB_TESTS,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch A/B Tests",
                        })
                    );
                    dispatch(
                        abTestsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(abTestsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metadata: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.AB_TEST}`;
            dispatch(abTestsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new A/B Test`;
            dispatch(abTestsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchAbTestsDataById = createAsyncThunk(
    "listings/abtests/abtestsListingDataById",
    async (obj, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.GET_AB_TEST_ID}/${obj.id}/variants`,
            "GET"
        );
        const { listings } = getState();
        const state = get(listings, "abtests.featureMapListData", {});
        const data = get(response, "data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch A/B Tests!",
                    autoClose: 5000,
                })
            );
        return [data, state, obj];
    }
);

export const fetchAbTestsDataName = createAsyncThunk(
    "listings/abtests/abtestsName",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.GET_AB_TEST_ID_NEW}/${id}`,
            "GET"
        );
        const data = get(response, "data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch A/B Tests!",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const disableAbTestData = createAsyncThunk(
    "listings/abtests/disableAbtestData",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.GET_AB_TEST}/${params.id}`,
            "PUT",
            params
        );
        const data = get(response, "status", []);
        if (data != 201)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to ${
                        params.status == "disabled" ? "disable" : "enable"
                    } A/B Test!`,
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const fetchAbTestVariants = createAsyncThunk(
    "listings/abtests/abtestVariants",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.GET_AB_TEST}/${id}/variants`,
            "GET"
        );
        const data = get(response, "data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch A/B Test Variants!",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const deleteAbTestVariant = createAsyncThunk(
    "listings/abtests/deleteAbtestVariant",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.MODIFY_AB_TEST_VARIANT}/${params.id}`,
            "DELETE"
        );
        const data = get(response, "status", []);
        if (data != 204)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete A/B Test Variant",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const modifyAbTest = createAsyncThunk(
    "lisitngs/abtests/modifyAbTest",
    async (id, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "abtests.createAbTest.basics", {});
        const params = abTestParams(state, true);
        const response = await customFetch(
            `${CONFIGURATIONS.MODIFY_ABTEST}/${id}`,
            "PUT",
            params
        );
        const data = get(response, "data", []);
        if (data.length === 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to edit A/B Test",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const createAbTest = createAsyncThunk(
    "lisitngs/abtests/createAbTest",
    async (_, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "abtests.createAbTest.basics", {});
        const params = abTestParams(state);
        const response = await customFetch(
            CONFIGURATIONS.CREATE_ABTEST,
            "POST",
            params
        );
        const data = get(response, "data", []);
        if (data.length === 0 || response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: data?.Details
                        ? data.Details
                        : "Failed to create A/B Test",
                    autoClose: 5000,
                })
            );
        }
        return data;
    }
);

export const createAbTestVariants = createAsyncThunk(
    "listings/abtests/createAbTest",
    async (method, { getState, dispatch }) => {
        const params = abTestVariantsParams(getState());
        const updateApi = customFetch(
            CONFIGURATIONS.MODIFY_AB_TEST_VARIANTS,
            method,
            params
        );

        const { listings } = getState();
        const deletedPageIds = get(
            listings,
            "abtests.createAbTest.variants.deletedPageIds",
            {}
        );
        const deleteApis = deletedPageIds.map(id =>
            customFetch(
                `${CONFIGURATIONS.DELETE_AB_TEST_VARIANTS}/${id}`,
                "DELETE"
            )
        );

        return await fetchAll([updateApi, ...deleteApis]);
    }
);

export const cloneAbTestVariant = createAsyncThunk(
    "listings/abtests/cloneAbTestVariant",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.CLONE_AB_TEST_VARIANT}`,
            "POST",
            { name: params[1] + "_clone", id: params[0] }
        );
        if (response?.status >= 400)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to clone survey path!",
                })
            );
        return response;
    }
);

export const updateAbTestVariant = createAsyncThunk(
    "listings/abtests/updateAbtestVariant",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.MODIFY_AB_TEST_VARIANT}/${params.id}`,
            "PUT",
            params
        );
        const data = get(response, "status", []);
        if (data != 201)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update A/B Test Variant",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const fetchAllSurveysData = createAsyncThunk(
    "listings/abtests/createAbTest",
    async (_, { dispatch, getState }) => {
        const response = await customFetch(SURVEYS.FETCH_SURVEYS_NEW, "POST");
        if (response.length === 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch surveys!",
                })
            );
        return response;
    }
);

export const getInitialUpdatedConfigChannel = createAsyncThunk(
    "listings/abtests/createAbTest/initialUpdatedConfig",
    async (_, { getState }) => {
        const { configs, channelMaps, channelMapDataInitial } =
            getState().listings.abtests.createAbTest.basics;
        let initialChannels = [];
        let tempConfigChannel = channelMapDataInitial;
        let configArray = Object.keys(tempConfigChannel);
        let channelArray = Object.values(tempConfigChannel);
        let finalConfigChannels = [];
        configArray.forEach((v, i) => {
            var obj = {};
            obj.configID = v;
            obj.channel = channelArray[i];
            finalConfigChannels.push(obj);
        });
        initialChannels = finalConfigChannels;
        const configPresent = initialChannels?.map(ab => ab.configID).flat();
        const channelPresent = initialChannels
            ?.map(channel => channel.channel)
            .flat();
        let finalPayload = {
            configsUpdatedList: [],
            channelUpdatedList: [],
        };
        let configsInitialCopy = configs;
        let channelInitialCopy = channelMaps;
        let updatedConfigs = configsInitialCopy?.filter(el => {
            return !configPresent?.includes(el.id);
        });
        let updatedChannel = channelInitialCopy?.filter(el => {
            return !channelPresent?.includes(el.id);
        });
        finalPayload.configsUpdatedList = updatedConfigs;
        finalPayload.channelUpdatedList = updatedChannel;
        return finalPayload;
    }
);

export const fetchABTestCreatePageList = createAsyncThunk(
    "listings/abtests/createAbTest/fetchABTestCreatePageList",
    async (_, { getState }) => {
        const response = await customFetch(
            CONFIGURATIONS.GET_SURVEY_CONFIGS_LIST,
            "GET"
        );
        const configsInitial = get(response, "data", []);

        const configs = configsInitial.map(el => ({
            id: el.version,
            name: el.version,
            abtMap: el.abtMap,
        }));
        // Replace this once API response is integrated
        const channelResponse = defaultChannels();
        const channelData = get(channelResponse, "data", []).map(channel => ({
            id: channel,
            name: channel,
        }));
        const channelMaps = channelData;
        if (configs.length === 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Configs list",
                })
            );
        return { configs, channelMaps };
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/abtests/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.abtests.searchFilter.filterApplied;
        let filterApplied = [...initialData];
        let filterParamsData = FILTER_PARAMS.AB_TEST.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);
        return { filterApplied };
    }
);

const abTestsSlice = createSlice({
    name: "abTests",
    initialState,
    reducers: {
        setPagesData(state, action) {
            state.createAbTest.variants.pages = action.payload;
        },
        setPageOrderData(state, action) {
            state.createAbTest.variants.pageOrder = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        //basics actions
        setAbTestBasicsName(state, action) {
            state.createAbTest.basics.name = action.payload;
            state.createAbTest.basics.error = !state.createAbTest.basics.name;
        },
        setAbTestBasicsStatus(state, action) {
            state.createAbTest.basics.status = action.payload;
        },
        setAbTestUiiD(state, action) {
            state.createAbTest.basics.uuid = action.payload;
        },
        setShowPrompt(state, action) {
            state.createAbTest.basics.showPrompt = action.payload;
        },
        setChannelMapData(state, action) {
            state.createAbTest.basics.channelMapData = action.payload;
        },
        setConfigs(state, action) {
            state.createAbTest.basics.configs = action.payload;
        },
        setChannel(state, action) {
            state.createAbTest.basics.channelMaps = action.payload;
        },
        setUpdatedConfigs(state, action) {
            state.createAbTest.basics.updatedConfigs = action.payload;
        },
        setUpdatedChannel(state, action) {
            state.createAbTest.basics.updatedChannels = action.payload;
        },
        setHistoryData(state, action) {
            state.createAbTest.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createAbTest.historyDataRaw = action.payload;
        },
        //variants
        addVariantTab(state, action) {
            const { data, page } = action.payload;
            let copyData = JSON.parse(JSON.stringify(initialVariantState));
            copyData.featureMaps.updatedList = data;
            state.createAbTest.variants.pages[page] = {
                ...copyData,
                isNewTab: true,
            };
            state.createAbTest.variants.selectedPageId = page;
            state.createAbTest.variants.pageOrder.push(page);
        },
        setSelectedPageId(state, action) {
            state.createAbTest.variants.selectedPageId = action.payload;
        },

        revertDeletePageId(state, action) {
            const id =
                state.createAbTest.variants.pages[action.payload].basics.id;
            state.createAbTest.variants.deletedPageIds.splice(
                state.createAbTest.variants.deletedPageIds.indexOf(id),
                1
            );
        },
        deletePageId(state, action) {
            if (!state.createAbTest.variants.pages[action.payload].isNewTab) {
                state.createAbTest.variants.deletedPageIds.push(
                    state.createAbTest.variants.pages[action.payload].basics.id
                );
            } else {
                delete state.createAbTest.variants.pages[action.payload];
                state.createAbTest.variants.pageOrder =
                    state.createAbTest.variants.pageOrder.filter(
                        id => id != action.payload
                    );
            }
        },
        //basics
        setVariantName(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].basics.name = data;
            state.createAbTest.variants.pages[page].name = data;
            state.createAbTest.variants.pages[page].basics.error =
                !state.createAbTest.variants.pages[page].basics.name;
        },
        setVariantWeight(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].basics.weight = data;
            state.createAbTest.variants.pages[page].basics.error =
                !state.createAbTest.variants.pages[page].basics.weight;
        },
        setVariantLander(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].basics.lander.selected =
                data;
        },
        setVariantProduct(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].basics.product.selected =
                data;
        },
        //targetParams
        setTargettingParamsTargetParams(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.targetParams = {
                ...data,
            };
        },
        setTargettingParamsDropDownState(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.dropDownState = {
                ...data,
            };
        },
        setTargettingParamsCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.zipCodeState.csvFileName = data;
        },
        setTargettingParamsCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.zipCodeState.csvFile = data;
        },
        setTargettingParamsCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.zipCodeState.csvArray = data;
        },
        setTargettingParamsZipCodeError(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.zipCodeState.zipCodeError = data;
        },
        setTargettingParamsOnDropActiveZipCode(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.zipCodeState.onDropActiveZipCode = data;
        },

        setTargettingParamsClientIPsError(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.clientIPsState.clientIPsError = data;
        },
        setTargettingParamsOnDropActiveClientIPs(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.clientIPsState.onDropActiveClientIPs = data;
        },
        setTargettingClientIPCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.clientIPsState.csvFileName = data;
        },
        setTargettingClientIPCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.clientIPsState.csvFile = data;
        },
        setTargettingClientIPCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].targettingParam.clientIPsState.csvArray = data;
        },

        //exclusion target params
        setExclusionTargettingParamsTargetParams(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.targetParams = {
                ...data,
            };
        },
        setExclusionTargettingParamsDropDownState(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.dropDownState = {
                ...data,
            };
        },
        setExclusionTargettingParamsCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.zipCodeState.csvFileName = data;
        },
        setExclusionTargettingParamsCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.zipCodeState.csvFile = data;
        },
        setExclusionTargettingParamsCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.zipCodeState.csvArray = data;
        },
        setExclusionTargettingParamsZipCodeError(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.zipCodeState.zipCodeError = data;
        },
        setExclusionTargettingParamsOnDropActiveZipCode(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.zipCodeState.onDropActiveZipCode = data;
        },
        setExclusionTargettingParamsClientIPsError(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.clientIPsState.clientIPsError = data;
        },
        setExclusionTargettingParamsOnDropActiveClientIPs(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.clientIPsState.onDropActiveClientIPs = data;
        },
        setExclusionTargettingClientIPCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.clientIPsState.csvFileName = data;
        },
        setExclusionTargettingClientIPCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.clientIPsState.csvFile = data;
        },
        setExclusionTargettingClientIPCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].exclusionTargettingParam.clientIPsState.csvArray = data;
        },

        //feature map
        setVariantFeatureMapSelected(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].featureMaps.selected = data;
        },
        setVariantFeatureMapSelectedDetails(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].featureMaps.selectedDetails = data;
        },
        setSelectedListActiveItem(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[
                page
            ].featureMaps.selectedListActiveItem = data;
        },
        setSavedList(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].featureMaps.savedList =
                data;
        },
        setUpdatedList(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].featureMaps.updatedList =
                data;
        },
        toggleShowAccordionTable(state, action) {
            state.list = state.list.map(item => {
                if (item.id === action.payload)
                    item.showAccordionTable = !item.showAccordionTable;
                return item;
            });
        },
        setAbTestError(state, action) {
            state.createAbTest.basics.error = action.payload;
        },
        setVariantsError(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].basics.error = data;
        },
        setFeatureMapsError(state, action) {
            const { data, page } = action.payload;
            state.createAbTest.variants.pages[page].featureMaps.error = data;
        },
        resetAbTest(state) {
            state.createAbTest.basics.name = "";
            state.createAbTest.basics.status = false;
            state.createAbTest.basics.error = false;
            state.createAbTest.basics.showPrompt = true;
            state.createAbTest.basics.channelMapData = [];
            state.createAbTest.basics.channelMapDataInitial = {};
            state.createAbTest.basics.configs = [];
            state.createAbTest.basics.channelMaps = [];
            state.createAbTest.basics.updatedConfigs = [];
            state.createAbTest.basics.updatedChannels = [];
            // state.createAbTest.variants.pages["Page 1"] = { ...initialVariantState };
            state.createAbTest.variants.pages = {
                "Page 1": {
                    ...initialVariantState,
                    isNewTab: true,
                },
            };
            state.createAbTest.variants.pageOrder = ["Page 1"];
            state.createAbTest.variants.selectedPageId = "Page 1";
            state.createAbTest.variants.deletedPageIds = [];
        },
        setMainLoader(state, action) {
            state.createAbTest.basics.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        // Temp code for quick second filter
        setFilter2Value(state, action) {
            state.pagination.filterTerm2 = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        // setAllSurveyIds(state, action) {
        //   state.createAbTest.variants.surveyList = action.payload
        //   debugger;
        // }
    },
    extraReducers: builder => {
        builder.addCase(fetchAbTestsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAbTestsData.fulfilled, (state, action) => {
            // state.list = action.payload;
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        // builder.addCase(getInitialAbTestData.fulfilled, (state, action) => {
        //   keys(state.createAbTest.variants.pages).map((page) => {
        //     // state.createAbTest.variants.pages[page].basics.lander.list =
        //     //   action.payload;
        //   });
        // });
        builder.addCase(fetchAbTestsDataName.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAbTestsDataName.fulfilled, (state, action) => {
            state.loading = false;
            state.createAbTest.basics.name = action.payload.name;
            state.createAbTest.basics.status =
                action.payload.status == "active" ? true : false;
            state.createAbTest.basics.channelMapDataInitial =
                action.payload?.config_channel_map;

            let initialChannels = [];
            let tempConfigChannel = action.payload?.config_channel_map;
            let configArray = Object.keys(tempConfigChannel);
            let channelArray = Object.values(tempConfigChannel);
            let finalConfigChannels = [];
            configArray.forEach((v, i) => {
                var obj = {};
                obj.configID = v;
                obj.channel = channelArray[i];
                finalConfigChannels.push(obj);
            });
            initialChannels = finalConfigChannels;
            state.createAbTest.basics.channelMapData = finalConfigChannels;
        });
        builder.addCase(fetchAbTestsDataById.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAbTestsDataById.fulfilled, (state, action) => {
            //  mapAbTestEditPage(action.payload,initialVariantState)
            if (action.payload[0].length > 0) {
                let { editedData, pageOrder = [] } = mapAbTestEditPage(
                    action.payload[0],
                    initialVariantState,
                    action.payload[1]
                );
                if (action.payload[2].newVariant) {
                    let len = Object.keys(editedData).length;
                    let newPage = `Page ${len + 1}`;
                    let newObject = {
                        ...editedData,
                    };
                    newObject[newPage] = { ...initialVariantState };
                    let newPageOrder = [...pageOrder];
                    newPageOrder.unshift(newPage);
                    state.createAbTest.variants.pages = newObject;
                    state.createAbTest.variants.pageOrder = newPageOrder;
                    state.createAbTest.variants.selectedPageId = newPage;
                } else {
                    state.createAbTest.variants.pages = editedData;
                    state.createAbTest.variants.pageOrder = pageOrder;
                    if (action.payload[2].variantID != "") {
                        action.payload[0].map((element, index) => {
                            if (element.id == action.payload[2].variantID) {
                                state.createAbTest.variants.selectedPageId = `Page ${
                                    index + 1
                                }`;
                            }
                        });
                    }
                }
            }
            state.loading = false;
        });
        builder.addCase(fetchFeatureMapListData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchFeatureMapListData.fulfilled, (state, action) => {
            let updateList = cloneDeep(action.payload[0].data);
            if (!isEmpty(updateList)) {
                updateList =
                    updateList &&
                    updateList.map(val => {
                        return {
                            ...val,
                            isError:
                                val.valuekind === "default value" ||
                                (val.value && val.value.length > 0)
                                    ? false
                                    : true,
                            scopeChoices: scopeChoices,
                        };
                    });
                state.createAbTest.variants.pages[
                    "Page 1"
                ].featureMaps.updatedList = [...updateList];
                state.featureMapListData = [...updateList];
            }
            state.loading = false;
        });
        builder.addCase(fetchAllSurveysData.fulfilled, (state, action) => {
            state.createAbTest.variants.surveyList = action.payload;
        });
        builder.addCase(fetchABTestCreatePageList.pending, state => {
            state.loading = true;
        });
        builder.addCase(
            fetchABTestCreatePageList.fulfilled,
            (state, action) => {
                const { configs, channelMaps } = action.payload;
                state.createAbTest.basics.configs = configs;
                state.createAbTest.basics.channelMaps = channelMaps;
                state.loading = false;
            }
        );
        builder.addCase(
            getInitialUpdatedConfigChannel.fulfilled,
            (state, action) => {
                state.loading = false;
                const { configsUpdatedList, channelUpdatedList } =
                    action.payload;
                state.createAbTest.basics.updatedConfigs = configsUpdatedList;
                state.createAbTest.basics.updatedChannels = channelUpdatedList;
            }
        );
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const abTestsActions = abTestsSlice.actions;
export default abTestsSlice.reducer;
