import React, { useEffect, useRef, useState } from "react";
import Card from "../../Shared/Card/Card";
import s from "./SkeletonLoader.module.scss";
import Skeleton from "react-loading-skeleton";
import { getRandomId, skeletonLoader } from "../../utils/globalUtils";

const SkeletonLoader = ({
    dataArray,
    customClass,
    type = "form",
    rowsCount = 10,
}) => {
    const skeletonList = array => {
        return array.map((element, index) => {
            return (
                <div key={`${getRandomId()}-${index}-${getRandomId()}`}>
                    <Skeleton height={element} />
                </div>
            );
        });
    };

    const decideLoader = type => {
        switch (type) {
            case "form":
                return (
                    <div
                        className={`${s.rightPanel} ${s.rightPanelPaddingLeftZero} w-100`}
                    >
                        <Card
                            className={`${s.skeletonCard} ${s.skeletonTitleCard}`}
                        >
                            {skeletonList([28, 18])}
                        </Card>

                        <Card
                            className={`${s.skeletonCard} ${s.skeletonCardTwo}`}
                        >
                            {skeletonList([
                                28, 18, 18, 28, 28, 28, 28, 28, 28, 28, 28,
                            ])}
                        </Card>

                        <Card
                            className={`${s.skeletonCard} ${s.skeletonCardThree}`}
                        >
                            {skeletonLoader([18, 150, 309])}
                        </Card>
                    </div>
                );
            case "singleForm":
                return (
                    <div
                        className={`${s.rightPanel} ${s.rightPanelPaddingLeftZero} w-100`}
                    >
                        <Card
                            className={`${s.skeletonCard} ${s.normalSkeletonCard}`}
                        >
                            {skeletonList([28, 28, 28, 28, 28])}
                        </Card>
                    </div>
                );

            case "listing":
                return (
                    <div className={s.tableLoaderWrapper}>
                        <div>{skeletonList(Array(rowsCount).fill(18))}</div>
                        <div>{skeletonList(Array(rowsCount).fill(18))}</div>
                        <div>{skeletonList(Array(rowsCount).fill(18))}</div>
                    </div>
                );
            case "gridViewListing":
                return (
                    <div className={`${s.gridListWrapper}`}>
                        <Card
                            className={`${s.skeletonCard} ${s.gridListViewSkeletonCard}`}
                        >
                            {skeletonList([
                                350, 350, 350, 350, 350, 350, 350, 350, 350,
                                350,
                            ])}
                        </Card>
                    </div>
                );

            case "graph":
                return (
                    <div
                        className={`${s.graphLoaderWrapper} ${
                            customClass ? customClass : ""
                        }`}
                    >
                        <div className={s.loaderImg}></div>
                        <div className={s.circle}></div>
                    </div>
                );

            default:
                return <div>loading...</div>;
        }
    };

    return (
        <div className={`${s.mainParent} dp-parent`}>
            <>
                {/* <Card className={`${s.skeletonCard} ${s.skeletonCardLeftPanel}`}>
          {skeletonList([10, 10, 10, 10, 10])}
        </Card> */}
                {decideLoader(type)}
            </>
        </div>
    );
};

export default SkeletonLoader;
