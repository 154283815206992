import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _, { cloneDeep, get, uniq } from "lodash";
import { USER_JOURNEY, GIFTCARDS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import { getRequiredObject } from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import { createTagUtil, getUpdatedParams } from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import {
    createParentTableData,
    createSessionIdList,
    initialFilterData,
    mapGiftCardData,
} from "../../utils/userJourneyUtils";
import { flagOptions } from "../../utils/userJourneyUtils";
import { getDateBetweenArray } from "../../utils/filterUtils";
import { mapUserJourneyDetailListingData } from "../../utils/userJourneyUtils";
import Fuse from "fuse.js";
import { CLICK_EVENTS } from "../../components/UserDetailsTable/UserDetailsTable";
import { unionBy } from "lodash";

const initialDefaultDateRange = {
    startDate: startOfDay(subDays(new Date(), 1)),
    endDate: new Date(),
    key: "selection",
};

const initialState = {
    list: [],
    unfilteredList: [],
    mainLoader: false,
    filteredList: [],
    parentList: [],
    loading: true,
    detailLoading: false,
    gcDetailLoading: true,
    updatedAt: new Date(),
    searchTerm: "",
    isNotePresent: false,
    giftCardsList: [],
    pagination: {
        startOffset: 0,
        rowsCount: -1, //Added this as per backend requirment
        pageNumber: 1,
        sortBy: "ts",
        isDesc: true,
        totalRowsCount: 0,
        searchTerm: null,
        filterKey: FILTERS.USER_JOURNEY_DETAIL.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.USER_JOURNEY_DETAIL.filterOperator,
        filtersOperator: FILTERS.USER_JOURNEY_DETAIL.operator,
        filters: [],
        date: initialDefaultDateRange, //Update this to date once backend supports date
        email: "",
        domain: "",
    },
    searchFilter: {
        filterApplied: getInitialTagData(initialFilterData, "userjourney"),
    },
    giftPopup: {
        giftCardId: null,
        giftCardStatus: null,
        sessionId: null,
        comment: "",
    },
    dtoPopup: {
        id: "",
    },
    notePopup: {
        note: "",
    },
    status: {
        key: "unassigned",
        name: "Unassigned",
        color: "#74838F",
    },
    selectedUser: {},
    giftCardData: [],
    giftCardList: [],
    sessionList: [],
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchUserJourneyDetailList = createAsyncThunk(
    "listings/userjourneyDetail/fetchUserJourneyDetailList",
    async (refreshClicked, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "userJourneyDetail.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "userJourneyDetail.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.USER_JOURNEY_DETAIL
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
            "date",
            "email",
            "domain",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        const listingData = get(
            getState(),
            "listings.userJourneyDetail.list",
            []
        );
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                USER_JOURNEY.FETCH_USER_JOURNEY_DETAIL,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                e => {
                    dispatchSearchError = false;
                    let message = "Failed to fetch User journey details list";
                    if (
                        e?.response?.data?.error?.includes(
                            "Upper Limit is  15GB"
                        )
                    ) {
                        message = "Upper limit for query is 15GB";
                        dispatch(
                            userJourneyDetailSlice.actions.setTableError(
                                MESSAGES.TABLE.noDataFromApiUpperLimit
                            )
                        );
                    } else {
                        dispatch(
                            userJourneyDetailSlice.actions.setTableError(
                                MESSAGES.TABLE.noDataFromApi
                            )
                        );
                    }
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: message,
                        })
                    );
                }
            );
            dispatch(
                userJourneyDetailActions.setFilter(filterParamsData?.filters)
            );
            data = get(response, "data.data", []);
        } else {
            data = listingData;
        }

        let additionalEvents = await dispatch(
            checkConversions({
                sessionId: null,
                clids: uniq(
                    data?.userJourney
                        ?.filter(_ => CLICK_EVENTS.includes(_.Activity))
                        .map(_ => _.uuid)
                ),
            })
        );
        if (additionalEvents?.payload?.data) {
            additionalEvents = additionalEvents?.payload?.data;
            const arr = [];
            Object.keys(additionalEvents).forEach(e => {
                if (additionalEvents[e] && additionalEvents[e].length) {
                    additionalEvents[e].forEach(_ => {
                        const additionalData = data?.userJourney?.find(
                            item => item.uuid === _.clid
                        );
                        arr.push({
                            ...additionalData,
                            ..._,
                            Activity: "acv",
                            clickId: _?.clid,
                        });
                    });
                }
            });
            arr.forEach(_ => data?.userJourney?.unshift(_));
        }

        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.USER_JOURNEY_DETAIL}`;
            dispatch(
                userJourneyDetailSlice.actions.setTableError(errorMessage)
            );
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.userJourneyDetailNoData}`;
            dispatch(
                userJourneyDetailSlice.actions.setTableError(errorMessage)
            );
        }
        return data;
    }
);

export const fetchUserDetail = createAsyncThunk(
    "listings/userjourneyDetail/fetchUserDetail",
    async (_, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "userJourney.pagination", {});
        const userJourneyDetailPagination = get(
            listings,
            "userJourneyDetail.pagination",
            {}
        );
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "userJourney.searchFilter.filterApplied",
            []
        );

        let getDateRangeArray = getDateBetweenArray(
            get(userJourneyDetailPagination, "date", "")
        );
        let emailValue = get(userJourneyDetailPagination, "email", "");
        let domainValue = get(userJourneyDetailPagination, "domain", "");

        let filtersArray = [
            { k: "date", v: getDateRangeArray, op: "between" },
            { k: "email", op: "ilike", v: `%${emailValue}%` },
            {
                k: "domain",
                v: `%${domainValue}%`,
                op: "ilike",
            },
        ];
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.USER_JOURNEY
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
            "date",
            "email",
            "byPassSizeCheck",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        let updatedFilters = {
            ...filterParamsData,
            offset: 0,
            filters: filtersArray,
        };
        let data = [];
        const response = await customFetch(
            USER_JOURNEY.FETCH_USER_JOURNEY_LIST,
            "POST",
            updatedFilters,
            {},
            {},
            null,
            null,
            e => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message:
                            e?.response?.data?.error ||
                            "Failed to fetch User data",
                    })
                );
            }
        );
        data = get(response, "data.data.data", []);
        return !isEmpty(data) ? data[0] : {};
    }
);

const filterData = (list = [], filterApplied) => {
    let filteredList = JSON.parse(JSON.stringify(list));
    const fuseFilterObj = filterApplied
        .map(_ => {
            const key = _.id;
            if (_.filterValues && _.filterValues.length) {
                if (_.filterValues[0].isEnabled) {
                    const values =
                        _.filterValues[0].value.map(n => n.name) || [];
                    if (!values.length) return null;
                    return values.map(v => ({ [key]: v })).flat();
                }
            }
            return null;
        })
        .filter(_ => _);
    if (fuseFilterObj.length) {
        const options = {
            includeScore: true,
            threshold: 0,
            keys: ["Activity"],
        };
        const fuse = new Fuse(filteredList, options);
        filteredList = fuse
            .search({
                $or: fuseFilterObj[0],
            })
            .map(_ => _.item);
    }
    return filteredList;
};

export const onFilterApplied = createAsyncThunk(
    "listings/userjourneyDetail/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.userJourneyDetail.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.DTO.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const getUserDetails = createAsyncThunk(
    "listings/userjourneyDetail/getUserDetails",
    async (email, { dispatch }) => {
        const response = await customFetch(
            `${USER_JOURNEY.GET_USER_STATUS}/${email}`,
            "GET",
            null,
            {},
            {},
            null,
            null,
            e => {
                let message = "Failed to fetch User journey details list";
                //Not getting response obj so using 404 in error
                if (
                    e
                        ?.toString()
                        ?.includes("Request failed with status code 404")
                ) {
                    message = "";
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                e?.response?.error ||
                                "Failed to fetch user status and note",
                        })
                    );
                }
            }
        );
        const data = get(response, "data.data", []);
        return data;
    }
);

const updateGiftCardTableData = async (data, giftCardData) => {
    // Initial session details list
    let initialGC = data;
    let uniqueObjArray = [
        ...new Map(initialGC.map(item => [item["sid"], item])).values(),
    ];
    let fulfilledGiftCardsFromData = data.filter(
        ele => ele.Activity === "Gift Card Fulfilled"
    );
    const initialMerge = uniqueObjArray.map(t1 => ({
        ...t1,
        ...fulfilledGiftCardsFromData.find(t2 => t2.sid === t1.sid),
    }));
    let giftCardFullfillmentAPIList = giftCardData;
    let updatedGiftCardList = [];
    updatedGiftCardList = giftCardFullfillmentAPIList.map(t1 => ({
        ...t1,
        ...initialMerge.find(t2 => t2.sid === t1.sessionId),
    }));
    let mergedGiftCardData = unionBy(
        updatedGiftCardList,
        initialMerge,
        "sid"
    ).filter(element => element?.sid);
    return mergedGiftCardData;
};

export const getGiftCardDetails = createAsyncThunk(
    "listings/userjourneyDetail/getGiftCardDetails",
    async (email, { dispatch, getState }) => {
        const { listings } = getState();
        const response = await customFetch(
            `${USER_JOURNEY.GIFT_CARDS_FOR_USER}/${email}`,
            "GET",
            null,
            {},
            {},
            null,
            null,
            e => {
                let message = "Failed to fetch  gift card details list";
                //Not getting response obj so using 404 in error
                if (
                    e
                        ?.toString()
                        ?.includes("Request failed with status code 404")
                ) {
                    message = "";
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                e?.response?.error ||
                                "Failed to fetch gift card details",
                        })
                    );
                }
            }
        );
        let data = get(response, "data.data", []);
        const giftCardList = get(
            listings,
            "userJourneyDetail.giftCardList",
            []
        );
        data = await mapGiftCardData(
            get(response, "data.data", []),
            giftCardList
        );

        // Currently commenting as the data is different for Gift card list
        const journeyData = get(listings, "userJourneyDetail.list", []);
        const updatedData = !isEmpty(journeyData)
            ? await updateGiftCardTableData(journeyData, data)
            : data;
        return updatedData;
    }
);

export const createStatus = createAsyncThunk(
    "listings/userjourneyDetail/createStatus",
    async (userData, { dispatch }) => {
        const response = await customFetch(
            `${USER_JOURNEY.GET_USER_STATUS}`,
            "POST",
            userData
        );
        const data = get(response, "data", []);
        if (response?.status > 400 || response === undefined) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update user details",
                })
            );
            return null;
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "User details updated",
                })
            );
        }
        return data;
    }
);

export const updateStatus = createAsyncThunk(
    "listings/userjourneyDetail/updateStatus",
    async (userData, { dispatch }) => {
        const response = await customFetch(
            `${USER_JOURNEY.GET_USER_STATUS}/${userData?.email}`,
            "PUT",
            userData,
            null,
            {},
            null,
            null,
            null
        );
        const data = get(response, "data", []);
        if (response?.status > 400 || response === undefined) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update user details",
                })
            );
            return null;
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "User details updated",
                })
            );
        }
        return data;
    }
);

export const addAndFullfillGiftCard = createAsyncThunk(
    "listings/userjourneyDetail/addAndFullfillGiftCard",
    async (giftCardData, { dispatch }) => {
        const response = await customFetch(
            `${USER_JOURNEY.GIFT_CARDS_FOR_USER}`,
            "POST",
            giftCardData,
            {},
            {},
            null,
            null,
            e => {
                let message = "Failed to add and fulfill gift card";
                if (e?.response?.data?.error?.includes("ALREADY_EXISTS")) {
                    message = "Gift card already exists";
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: message,
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: e?.response?.data?.error || message,
                        })
                    );
                }
            }
        );
        const data = get(response, "data", []);
        return data;
    }
);

export const updateFullfillmentCard = createAsyncThunk(
    "listings/userjourneyDetail/updateFullfillmentCard",
    async (giftCardData, { dispatch }) => {
        let { email, sessionId, giftCardId, comment, status } = giftCardData;
        // let finalGCRevertObject = {
        //     status: status,
        //     comment: comment,
        // };
        const response = await customFetch(
            `${USER_JOURNEY.GIFT_CARDS_FOR_USER}/${email}/${sessionId}/${giftCardId}`,
            "PUT",
            giftCardData
        );
        const data = get(response, "data", []);
        if (response?.status > 400 || response === undefined) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to revert gift card fulfillment",
                })
            );
            return null;
        }
        return data;
    }
);

export const applyConversion = createAsyncThunk(
    "listings/userjourneyDetail/applyConversion",
    async (rowData, { dispatch, getState }) => {
        const { advid, cmpid, clickId } = rowData;
        const response = await customFetch(
            `${USER_JOURNEY.APPLY_CONVERSION}?advid=${advid}&cmpid=${cmpid}&clid=${clickId}&is_derived=true`,
            "GET",
            null,
            {},
            {},
            null,
            null,
            e => {
                let message = "Failed to apply conversion";
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: e?.response?.error || message,
                    })
                );
            }
        );
        const { listings } = getState();
        const data = get(listings, "userJourneyDetail.unfilteredList", []);
        let additionalEvents = await dispatch(
            checkConversions({
                sessionId: null,
                clids: uniq(
                    data
                        ?.filter(_ => CLICK_EVENTS.includes(_.Activity))
                        .map(_ => _.uuid)
                ),
            })
        );
        if (additionalEvents?.payload?.data) {
            additionalEvents = additionalEvents?.payload?.data;
            const arr = [];
            Object.keys(additionalEvents).forEach(e => {
                if (additionalEvents[e] && additionalEvents[e].length) {
                    additionalEvents[e].forEach(_ => {
                        const additionalData = data?.userJourney?.find(
                            item => item.uuid === _.clid
                        );
                        arr.push({
                            ...additionalData,
                            ..._,
                            Activity: "acv",
                            clickId: _?.clid,
                        });
                    });
                }
            });
            arr.forEach(_ => data?.userJourney?.unshift(_));
        }
        if (response?.data?.success) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Conversion Applied",
                })
            );
        }
        return { rowData, data };
    }
);

export const checkConversions = createAsyncThunk(
    "listings/userjourneyDetail/checkConversion",
    async (rowData, { dispatch, getState }) => {
        const { sessionId, clids } = rowData;
        const response = await customFetch(
            `${USER_JOURNEY.CHECK_CONVERSION}`,
            "POST",
            {
                clids,
            },
            {},
            {},
            null,
            null,
            e => {
                let message = "Failed to fetch conversion event";
                //Not getting response obj so using 404 in error
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: e?.response?.error || message,
                    })
                );
            }
        );
        return response?.data;
    }
);

export const fetchGiftCardUrlDetail = createAsyncThunk(
    "listings/userjourneyDetail/fetchGiftCardUrlDetail",
    async (urlData, { dispatch }) => {
        const response = await customFetch(
            `${GIFTCARDS.GET_GIFTCARDURL_LIST}email/${urlData.email}/giftcard/${urlData.giftCardId}`,
            "GET"
        );
        const data = get(response, "data", []);
        return data;
    }
);

const userJourneyDetailSlice = createSlice({
    name: "userJourneyDetail",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setDateRange(state, action) {
            state.pagination.date = action.payload;
        },
        setUserEmail(state, action) {
            state.pagination.email = action.payload;
        },
        setUserDomain(state, action) {
            state.pagination.domain = action.payload;
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setPopUpNote(state, action) {
            state.notePopup.note = action.payload;
        },
        setGiftCardId(state, action) {
            state.giftPopup.giftCardId = action.payload;
        },
        setGiftCardStatus(state, action) {
            state.giftPopup.giftCardStatus = action.payload;
        },
        setGiftCardSession(state, action) {
            state.giftPopup.sessionId = action.payload;
        },
        setGiftCardComment(state, action) {
            state.giftPopup.comment = action.payload;
        },
        setGiftCardPopUpData(state, action) {
            state.giftPopup.giftCardId = action.payload?.giftCardId || null;
            state.giftPopup.sessionId = action.payload?.sessionId || null;
            state.giftPopup.comment = action.payload?.comment || "";
            state.giftPopup.giftCardStatus =
                action.payload?.giftCardStatus || null;
        },
        resetData(state) {
            state.notePopup.note = "";
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setDetailLoading(state, action) {
            state.detailLoading = action.payload;
        },
        setGCDetailLoading(state, action) {
            state.gcDetailLoading = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        setIsNotePresent(state, action) {
            state.isNotePresent = action.payload;
        },
        setGiftCardList(state, action) {
            state.giftCardList = action.payload;
        },
        resetFulfillmentPopup(state) {
            (state.giftPopup.giftCardId = null),
                (state.giftPopup.giftCardStatus = null),
                (state.giftPopup.sessionId = null),
                (state.giftPopup.comment = "");
        },
        resetUserDetailList(state) {
            state.list = [];
            state.unfilteredList = [];
            state.searchFilter.filterApplied = getInitialTagData(
                [],
                "userjourney"
            );
            state.filteredList = [];
            state.parentList = [];
            state.selectedUser = {};
        },
        setClonedItem(state, action) {
            state.list = action.payload;
            state.filteredList = filterData(
                state.list,
                state.searchFilter.filterApplied
            );
        },
        setUser(state, action) {
            state.selectedUser = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setGiftCardData(state, action) {
            state.giftCardData = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUserJourneyDetailList.pending, state => {
            state.loading = true; //add separate loader for user details API
            state.gcDetailLoading = true;
        });
        builder.addCase(
            fetchUserJourneyDetailList.fulfilled,
            (state, action) => {
                let tempData = get(action, "payload.userJourney", []);
                state.unfilteredList = tempData;
                let filter_ACV_Events = !isEmpty(tempData)
                    ? tempData?.filter(_ => _.Activity !== "acv")
                    : [];
                let acv_Events = !isEmpty(tempData)
                    ? tempData?.filter(_ => _.Activity === "acv")
                    : [];

                let userJourneyDetailsList = mapUserJourneyDetailListingData(
                    filter_ACV_Events,
                    state.giftCardList,
                    get(action, "payload.durations", {}),
                    acv_Events
                );
                state.list = userJourneyDetailsList;
                state.filteredList = filterData(
                    userJourneyDetailsList,
                    state.searchFilter.filterApplied
                );
                // state.list = get(action, "payload", []);
                let parentTableData = createParentTableData(
                    userJourneyDetailsList
                );
                let sessionList = createSessionIdList(parentTableData);

                state.parentList = parentTableData?.map(v => ({
                    ...v,
                    showAccordionTable: true,
                }));

                state.sessionList = sessionList;
                state.loading = false;
                state.gcDetailLoading = false;
                state.updatedAt = new Date();
            }
        );
        builder.addCase(fetchUserJourneyDetailList.rejected, state => {
            state.loading = false; //add separate loader for user details API
            state.gcDetailLoading = false;
        });
        builder.addCase(fetchUserDetail.pending, state => {
            state.detailLoading = true;
        });
        builder.addCase(fetchUserDetail.fulfilled, (state, action) => {
            state.selectedUser = get(action, "payload", {});
            state.detailLoading = false;
        });
        builder.addCase(fetchUserDetail.rejected, (state, action) => {
            state.detailLoading = false;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
            state.filteredList = filterData(state.list, filterApplied);
        });
        builder.addCase(getUserDetails.pending, state => {
            // state.loading = true;
        });
        builder.addCase(getUserDetails.fulfilled, (state, action) => {
            // state.loading = false;
            // ("fulfilled");
            var statusObject = flagOptions.find(itm => {
                return itm.key === action.payload?.status;
            });
            state.status = statusObject;
            state.notePopup.note = action.payload?.note;
            state.isNotePresent = action.payload.hasOwnProperty("note")
                ? true
                : false;
        });
        builder.addCase(getGiftCardDetails.pending, state => {
            // state.gcDetailLoading = true;
        });
        builder.addCase(getGiftCardDetails.fulfilled, (state, action) => {
            // state.gcDetailLoading = false;
            state.giftCardData = action.payload;
            // state.detailLoading = false;
        });
        builder.addCase(getGiftCardDetails.rejected, (state, action) => {
            // state.gcDetailLoading = false;
            // state.detailLoading = false;
        });
        builder.addCase(createStatus.fulfilled, state => {
            state.isNotePresent = true;
        });
        builder.addCase(applyConversion.fulfilled, (state, action) => {
            let { rowData, data } = action.payload;
            state.list = [
                {
                    ...rowData,
                    ts: {
                        value: new Date(),
                    },
                    Activity: "acv",
                },
                ...state.list,
            ];
            // This is added as currently we are filtering the acv events so to keep a track of previous
            // conversion events fired and to get the visibility of button we have created this unfilteredList
            // If in future we don't want to filter this events as per business team requirement we can remove this and
            // also explore the additionalProps way to rerender the table.
            state.unfilteredList = [
                {
                    ...rowData,
                    ts: {
                        value: new Date(),
                    },
                    Activity: "acv",
                },
                ...data,
            ];
            let tempData = cloneDeep(state.unfilteredList);
            let filter_ACV_Events = !isEmpty(tempData)
                ? tempData?.filter(_ => _.Activity !== "acv")
                : [];

            let acv_Events = !isEmpty(tempData)
                ? tempData?.filter(_ => _.Activity === "acv")
                : [];

            let tempACVUpdate = filter_ACV_Events;
            if (!isEmpty(acv_Events)) {
                let clickIds = acv_Events.map(_ => _.clickId);
                tempACVUpdate.map(_ => {
                    if (clickIds.includes(_?.clickId)) {
                        ({ ..._, validate: true });
                    } else {
                        ({ ..._ });
                    }
                });
            }

            let userJourneyDetailsList = mapUserJourneyDetailListingData(
                tempACVUpdate,
                state.giftCardList,
                get(action, "payload.durations", {}),
                acv_Events
            );
            state.list = userJourneyDetailsList;
            state.filteredList = filterData(
                userJourneyDetailsList,
                state.searchFilter.filterApplied
            );
            let parentTableData = createParentTableData(userJourneyDetailsList);
            let sessionList = createSessionIdList(parentTableData);
            state.parentList = parentTableData?.map(v => ({
                ...v,
                showAccordionTable: true,
            }));

            state.sessionList = sessionList;
            state.updatedAt = new Date();
        });
    },
});

export const userJourneyDetailActions = userJourneyDetailSlice.actions;
export default userJourneyDetailSlice.reducer;
