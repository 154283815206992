export const MESSAGES = {
    TABLE: {
        noDataOnSearch: "No results found, try searching by ",
        noDataFromApi: "Oops, failed to fetch",
        noDataPresent: "No results found",
        noData: "Looks like there is no data",
        caseSensitive: "(case sensitive)",
        offlineConversionNoData:
            "Enter Data & upload CSV file to generate listing.",
        scrubDashboardNoData: "Enter Data & Scrub Rate to generate listing.",
        noDataForSelectedDateRange:
            "No results found for the selected date range. Try setting a different date range.",
        exact: "(exact)",
        userJourneyNoData: "Apply Filters to fetch user data.",
        userJourneyDetailNoData:
            "No data found for the selected date range. Try setting a different date range",
        userJourneyNoDataForFilter:
            "There are no records matching your selected filters.",
        noDataFromApiUpperLimit:
            "Upper limit of 15GB reached. Try setting a different date range.",
        clearedAllData: "No data present to proceed further.",
    },
    TABLE_COLUMNS: {
        SURVEY: "Name",
        SURVEY_PATH: "Name",
        FLOW: "Name",
        QUESTIONS: "Name",
        // OFFER_PATHS: "ID, Name or Survey ID",
        OFFER_PATHS: "Name",
        AD_UNITS: "Name",
        LEAD_DEFINITION: "Name",
        OFFER_GROUPS: "Name",
        CATEGORIES: "Name",
        FEATURE_MAP: "Name",
        AB_TEST: "Name",
        SURVEY_CONFIG: "Config Name",
        ROAS: "Campaign",
        URL_GENERATOR: "User email",
        FEED_MANAGER: "Publisher Name or Publisher Code",
        FEATURE_SETS: "Name",
        GIFT_CARDS: "Reward Name",
        DEAL_CATEGORY: "Deal Category",
        OFFER_WALL: "Name",
        USERS_LIST: "Name or Email id",
        USER_GROUP: "Name",
        OFFLINE_CONVERSION: "advertiser name",
        MEDIA_LIBRARY: "Logo Name",
        CREATIVE: "Name",
        JT: "Title",
        ADVERTISER_SCRUB_DASHBOARD: "Advertiser ID",
        PUBLISHER_SCRUB_DASHBOARD: "Publisher ID",
        USER_JOURNEY: "User information",
        USER_JOURNEY_DETAIL: "User Information",
        CAMPAIGN: "Campaign Name",
        BULK_SCRUB: "Error flag",
        CREATE_FLOW: "Components",
        CONTAINER: "Name",
        TEMPLATE: "Name",
        FLOW_QUESTION: "Title",
        LAYOUT: "Name",
        ADVERTISER: "Name",
        SITE_CONFIG: "Name",
    },
    HEADER: {
        SURVEY: "Surveys",
        SURVEY_PATH: "Survey Paths",
        QUESTIONS: "Questions",
        OFFER_PATHS: "Offer Paths",
        AD_UNITS: "Ad Units",
        LEAD_DEFINITION: "Posting Spec",
        OFFER_GROUPS: "Offer Groups",
        CATEGORIES: "Lead Categories",
        FEATURE_MAP: "Features",
        AB_TEST: "A/B Tests",
        SURVEY_CONFIG: "Survey Configs",
        ROAS: "TMS",
        DTO: "DTO",
        FEED_MANAGER: "Feed Manager",
        FEATURE_SETS: "Feature Sets",
        GIFT_CARDS: "Promos",
        DEAL_CATEGORY: "Deal Categories",
        OFFER_WALL: "Offer Walls",
        USERS_LIST: "Users",
        USER_GROUP: "User Groups",
        OFFLINE_CONVERSION: "Offline Conversion",
        LOGO: "Logo",
        JTCONFIG: "Job Type Configs",
        SCRUB_DASHBOARD: "Scrub Dashboard",
        CREATIVES: "Creatives",
        USER_JOURNEY: "User Journey",
        USER_JOURNEY_DETAIL: "User journey detail",
        FLOW: "flow",
        BULK_SCRUB: "Bulk scrub",
        CREATE_FLOW: "Flow",
        LAYOUT: "Layout",
        CAMPAIGN: "Campaign",
        CONTAINER: "Container",
        TEMPLATE: "Feature Group",
        ADVERTISER: "Advertiser",
        SITE_CONFIG: "Site Config",
    },
    ERROR: {
        firebase_token_expire:
            "ERR in firebase auth verification: Firebase ID token has expired",
    },
};
