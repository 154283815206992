import {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import ReactFlow, {
    addEdge,
    Background,
    ConnectionLineType,
    Controls,
    MiniMap,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    MarkerType,
    Handle,
    useStore,
    useKeyPress,
    useReactFlow,
    useNodesInitialized,
    useStoreApi,
} from "reactflow";
import { reactQueryApiCall } from "../../generalApi/generalApi";
import style from "./FlowRenderer.module.scss";
import Icon from "../Icon/Icon";
import emptyFlowImg from "../../assets/img/empty-flow.svg";
import { flowTargetingParams } from "../../constants/targettingParamsConstants";
import { CustomEdge } from "../../Pages/CreateFlow/CustomEdges";
import { usePrevious } from "../hooks/use-previous";
import { useSelector } from "react-redux";
import {
    useFetchAvailableQuestions,
    useFetchAvailableTargetingParams,
} from "../../generalApi/generalApi";
import _, { debounce, isEmpty, uniqBy } from "lodash";
import Skeleton from "react-loading-skeleton";
import { capitalizeString, clearUndefinedKeys } from "../../utils/globalUtils";
import Tooltip from "../Tooltip/Tooltip";
import { MAIN } from "../../constants/url";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import ToggleValueSwitch from "../ToggleValueSwitch/ToggleValueSwitch";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { flowActions } from "../../store/flowmanager/flows";
import CustomTooltipRenderer from "../../components/Renderers/CustomTooltipRenderer/CustomTooltipRenderer";
import Table from "../Table/Table";
import {
    getLayoutedElements,
    getSingleChildNodes,
    EntityType,
    NodeTypes,
    EntitySubType,
    NodeRenderType,
    FlowRouterRelativeSubTypes,
    EntityIcon,
    EntityName,
    NodeAction,
    ContainerTypesWithPreview,
    fetchTpKey,
    renderTargetingNode,
    beautifyTargettingParams,
    RouterTypes,
    KeyTypes,
    findNextSetId,
    createLinks,
    getCustomClientRect,
} from "./FlowRendererUtils";
import NumberRenderer from "../../components/Renderers/NumberRenderer/NumberRenderer";
import { getCurrentNodeTree } from "../../Pages/CreateFlow/FlowUtils";

const FlowRenderer = memo(
    forwardRef(
        (
            {
                loading,
                editPhase = "",
                rules: propRules = [],
                containers = [],
                startId,
                startNodeText = "Start",
                openNode,
                addNode,
                openPreview,
                showPasteIcon,
                disablePasteIcon,
                copyCurrentTree,
                copyNode,
                loadCopiedNodes,
                readOnly,
                deleteNode,

                onConnect,
                editNode,
                autoLayout,
                onClick = () => {},
                updatePositions = () => {},
                deleteAllNodes,
                containerAsDestination = false,
                isHistory,
                filteredRules = [],
                pdfDownloadTriggered = -1,
                showFiltered = false,
                addFilter,
                showAddFilter,
                removeFilter,
                disConnectFlow = () => {},
                expandNodesCount = () => {},
                hideExpandCollapse = false,
                cloneAndCopy,
                statsMap = {},
                showStartNodeHistory = false,
                globalRulesStartId,
                fallBackStartId,
                litigatorRulesStartId,
                nodeRuleCountObject = {},
                startNodeSecondaryStyle = false,
                publishVersion = "",
                disconnectedNodes,
                containerAsLastNode = false,
                showDummyStartNode = false,
            },
            ref
        ) => {
            const [nodes, setNodes, onNodesChange] = useNodesState([]);
            const [edges, setEdges, onEdgesChange] = useEdgesState([]);
            const nodesInitialized = useNodesInitialized();
            const store = useStoreApi();

            const { apiData: availableTargetingParams = [] } =
                useFetchAvailableTargetingParams();
            const { apiData: questionList = [] } = useFetchAvailableQuestions();
            const isTarget = useStore(state => state.connectionNodeId);
            const flowWrapperRef = useRef(null);
            const nodePositions = useRef({});
            const reactFlowInstance = useReactFlow();
            const isDarkMode = useSelector(
                state => state.globalConfig.isDarkMode
            );
            const flows = useSelector(state => state.flowmanager.flows);
            const [hiddenNodes, setHiddenNodes] = useState([]);
            const [extraCollapseRules, setExtraCollapseRules] = useState([]);
            const [direction, setDirection] = useState("TB");
            const [align, setAlign] = useState();
            const [
                disconnectedNodeTreeRuleSetIds,
                setDisconnectedNodeTreeRuleSetIds,
            ] = useState([]);
            const filterApplied = useSelector(
                state =>
                    state.flowmanager.flows.createSearchFilter.filterApplied
            );
            const { apiData: apiFlowData, isLoading: flowsLoading } =
                reactQueryApiCall("flowListData", "Flow List", {
                    url: MAIN.GET_ALL_FLOWSDETAILS,
                    method: "GET",
                });
            const allFlowDetails = useMemo(() => {
                return apiFlowData || [];
            }, [apiFlowData]);

            useEffect(() => {
                if (typeof expandNodesCount === "function") {
                    expandNodesCount(hiddenNodes);
                }
            }, [hiddenNodes]);

            useEffect(() => {
                if (disconnectedNodes?.nodes?.length) {
                    let temp = [];
                    disconnectedNodes?.nodes.map(node => {
                        getCurrentNodeTree(rules, temp, node.ruleSetId);
                    });
                    highlightNode(disconnectedNodes?.nodes[0]?.ruleSetId);
                    setDisconnectedNodeTreeRuleSetIds(
                        temp.map(_ => _.ruleSetId)
                    );
                } else {
                    setDisconnectedNodeTreeRuleSetIds([]);
                }
            }, [disconnectedNodes]);

            const rules = useMemo(() => {
                const propRulesCopy = JSON.parse(JSON.stringify(propRules));
                let tempFilteredRules = propRulesCopy.filter(_ =>
                    filteredRules.includes(_.id)
                );
                const tempFilteredRuleSetIDs = tempFilteredRules.map(
                    _ => _.ruleSetId
                );
                tempFilteredRules = propRulesCopy.filter(_ =>
                    tempFilteredRuleSetIDs.includes(_.ruleSetId)
                );
                return showFiltered ? tempFilteredRules : propRulesCopy;
            }, [propRules, filteredRules, showFiltered, filterApplied]);

            const EdgeTypes = useMemo(
                () => ({
                    custom: props => {
                        return (
                            <CustomEdge
                                parentRef={flowWrapperRef}
                                readOnly={readOnly}
                                id={props.id}
                                key={props.id}
                                {...props}
                                disablePasteIcon={disablePasteIcon}
                            />
                        );
                    },
                }),
                [disablePasteIcon, edges, readOnly]
            );
            useEffect(() => {
                const setRules = () => {
                    if (availableTargetingParams.length) {
                        const { nodeInternals } = store.getState();
                        const updatedNodes = Array.from(nodeInternals.values());

                        const [nodes, edges] = generateRules(
                            [...rules, ...extraCollapseRules],
                            filteredRules,
                            updatedNodes
                        );

                        const { nodes: layoutedNodes, edges: layoutedEdges } =
                            getLayoutedElements(
                                nodes,
                                edges,
                                direction,
                                align,
                                autoLayout,
                                nodePositions.current,
                                rules,
                                showFiltered
                            );

                        setNodes([
                            ...clearUndefinedKeys(layoutedNodes, [
                                "height",
                                "width",
                            ]),
                        ]);
                        setEdges([...layoutedEdges]);
                    }
                };
                availableTargetingParams?.length && setRules();
            }, [
                rules,
                containers,
                startId,
                isTarget,
                showPasteIcon,
                disablePasteIcon,
                availableTargetingParams,
                autoLayout,
                editNode,
                isDarkMode,
                allFlowDetails,
                hiddenNodes,
                extraCollapseRules,
                isHistory,
                readOnly,
                filteredRules,
                showFiltered,
                align,
                direction,
                filterApplied,
                statsMap,
                nodesInitialized,
                disconnectedNodes,
                disconnectedNodeTreeRuleSetIds,
            ]);

            useEffect(() => {
                if (pdfDownloadTriggered > 0) {
                    reactFlowInstance.fitView({ padding: 0.3 });
                }
            }, [pdfDownloadTriggered]);

            useImperativeHandle(ref, () => {
                return {
                    highlightStartNode: ruleId => {
                        highlightNode(ruleId || startId);
                    },
                    tidy: () => {
                        const { nodes: layoutedNodes, edges: layoutedEdges } =
                            getLayoutedElements(
                                nodes,
                                edges,
                                "TB",
                                undefined,
                                autoLayout,
                                nodePositions.current,
                                rules,
                                filteredRules,
                                showFiltered
                            );
                        setNodes([
                            ...clearUndefinedKeys(layoutedNodes, [
                                "height",
                                "width",
                            ]),
                        ]);
                        setEdges([...layoutedEdges]);
                    },
                    getNodePositions: () => {
                        return nodePositions.current;
                    },
                    getHiddenNodes: () => {
                        return hiddenNodes;
                    },
                    expandAll: () => {
                        extraCollapseRules.forEach(_ => expandCollapse(_));
                    },
                    focusNode: id => {
                        const node =
                            nodes.find(_ => _.id === "rule:" + id) ||
                            nodes.find(_ => _.id === "set:" + id);
                        if (node) {
                            setViewportCenter({
                                x: node?.position?.x - node.width / 2,
                                ...node.position,
                            });
                        }
                    },
                };
            });

            const highlightNode = ruleId => {
                const rule = nodes.find(_ => _.ruleSetId === ruleId);
                if (rule?.position) {
                    setViewportCenter(rule.position);
                }
            };

            function simplifyText(text) {
                text = text || "";
                return text
                    .replaceAll('"', "")
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replaceAll(":", "=");
            }

            async function setViewportCenter(position) {
                if (position) {
                    const { x = 0, y = 0 } = position;
                    setTimeout(() => {
                        reactFlowInstance.setCenter(x, y, {
                            duration: 200,
                            zoom: 1,
                        });
                    }, 10); //added 10ms since centering wasn't working in site config
                }
            }

            function expandCollapse(rule) {
                if (hiddenNodes.includes(Math.abs(rule.id))) {
                    expand(rule);
                } else {
                    collapse(rule);
                }
            }

            function collapse(rule) {
                const [filteredIds, filteredRules, rulesWithMultipleParent] =
                    getSingleChildNodes(rule, rules, hiddenNodes);
                const extraRules = rulesWithMultipleParent.length
                    ? rulesWithMultipleParent.map(_ => ({
                          id: -rule.id,
                          entityType: EntityType.COLLAPSED,
                          entitySubType: rule.entityType,
                          ruleSetId: rule.ruleSetId,
                          nextSetId: _.ruleSetId,
                      }))
                    : [
                          {
                              id: -rule.id,
                              entityType: EntityType.COLLAPSED,
                              entitySubType: rule.entityType,
                              ruleSetId: rule.ruleSetId,
                          },
                      ];
                setExtraCollapseRules(_ => [
                    ..._.filter(ecr => !filteredIds.includes(Math.abs(ecr.id))),
                    ...extraRules,
                ]);
                setHiddenNodes(_ => [..._, ...filteredIds]);
            }

            function expand(rule) {
                const ruleId = Math.abs(rule.id);
                const [filteredIds, filteredRules, rulesWithMultipleParent] =
                    getSingleChildNodes(
                        { ...rule, id: ruleId },
                        rules,
                        hiddenNodes
                    );
                setHiddenNodes(_ => [
                    ..._.filter(id => !filteredIds.includes(id)),
                ]);
                setExtraCollapseRules(_ => [
                    ..._.filter(cr => cr.id !== -ruleId),
                ]);
                const collapsedChildren = extraCollapseRules.filter(_ =>
                    filteredIds.includes(_.id)
                );
                setExtraCollapseRules(_ => [
                    ..._.filter(cr => !collapsedChildren.includes(cr.id)),
                ]);
                collapsedChildren.forEach(cc =>
                    collapse(rules.find(r => r.id === Math.abs(cc.id)))
                );
            }

            const getNodeName = nodeRule => {
                let nodeName = "node";
                if (nodeRule?.entityType === "CONTAINER")
                    nodeName = "container";
                if (
                    nodeRule?.entityType === "ROUTER" &&
                    nodeRule?.entitySubType === "LOGICAL"
                )
                    nodeName = "targeting";
                if (
                    nodeRule?.entityType === "ROUTER" &&
                    nodeRule?.entitySubType === "AB_TEST"
                )
                    nodeName = "A/B split";
                return nodeName;
            };

            function createChartNode(r, nextNode, updatedNodes) {
                let nodeName = getNodeName(r);
                let root = { category: NodeTypes.RULE };
                root.hidden = hiddenNodes.includes(r.id);

                const nextNodeForAdd =
                    rules.find(
                        _ =>
                            _.ruleSetId === r.nextSetId &&
                            _?.entityType === EntityType.ACTIONS &&
                            _.entitySubType === EntitySubType.end
                    ) || r;
                const isNextCR =
                    r.entityType === EntityType.ACTIONS &&
                    r.entitySubType === EntitySubType.next;

                const parentCount =
                    rules.filter(_ => _.nextSetId === r.ruleSetId)?.length || 0;

                if (r.entityType == EntityType.CONTAINER) {
                    root.nodeType = NodeRenderType.CONTAINER;
                    root.data = {
                        label:
                            r.entitySubType?.toLowerCase() +
                            " (" +
                            r.entityId +
                            ")\n",
                    };

                    const container = containers.find(_ => _.id === r.entityId);

                    const dropRate =
                        Math.round(
                            (statsMap[r.id]?.drop /
                                statsMap[r.id]?.impressions) *
                                100
                        ) || 0;

                    const outGoingCount =
                        statsMap[r.id]?.dropArr?.reduce(
                            (partialSum, obj) => partialSum + obj.imp,
                            0
                        ) || 0;
                    root.data = {
                        label: (
                            <>
                                {!containerAsDestination &&
                                    !containerAsLastNode && (
                                        <Handle
                                            type="source"
                                            position="right"
                                            id="next"
                                        />
                                    )}

                                {!containerAsDestination &&
                                    !containerAsLastNode && (
                                        <Handle
                                            type="source"
                                            position="left"
                                            id="next"
                                        />
                                    )}
                                <div
                                    className={`nodeWrapper
                                            ${style[r.histClass]}
                                            ${
                                                FlowRouterRelativeSubTypes.includes(
                                                    r.entitySubType
                                                )
                                                    ? style.singleLine
                                                    : ""
                                            }
                                            ${
                                                containerAsDestination
                                                    ? style.destination
                                                    : ""
                                            } ${style.nodeWrapper} ${
                                        r.isFilteredOut ? style.blur : ""
                                    } ${style.container} ${style.highlight} ${
                                        r?.ruleSetId === editNode?.ruleSetId
                                            ? style.activeNode
                                            : ""
                                    }${
                                        disconnectedNodeTreeRuleSetIds.includes(
                                            r?.ruleSetId
                                        )
                                            ? style.isDeleted
                                            : ""
                                    }`}
                                    onClick={() => {
                                        if (typeof openNode === "function") {
                                            openNode(r);
                                            setViewportCenter(root.position);
                                        }
                                    }}
                                >
                                    {!hideExpandCollapse &&
                                        r?.nextSetId &&
                                        (parentCount === 1 ||
                                            r?.ruleSetId === startId) && (
                                            <div
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    expandCollapse(r);
                                                }}
                                                className={`${style.expandCollapse}`}
                                            >
                                                <Tooltip
                                                    message={"Collapse"}
                                                    position="top"
                                                >
                                                    <Icon
                                                        size="10px"
                                                        icon={"DropDownArrow"}
                                                    />
                                                </Tooltip>
                                            </div>
                                        )}
                                    {isTarget && (
                                        <Handle
                                            id="target"
                                            type="target"
                                            position="left"
                                            isValidConnection={connection =>
                                                connection.source === "some-id"
                                            }
                                            onConnect={params =>
                                                console.log(
                                                    "handle onConnect",
                                                    params
                                                )
                                            }
                                            className={style.targetHandle}
                                        />
                                    )}
                                    <div className={style.content}>
                                        <div className={style.header}>
                                            <Icon
                                                className={"mr-5"}
                                                icon={
                                                    EntityIcon[r.entitySubType]
                                                }
                                                fallbackIcon="Default"
                                                size="12px"
                                            />
                                            <p>
                                                <Tooltip
                                                    message={capitalizeString(
                                                        EntityName[
                                                            r.entitySubType
                                                        ] || r.entitySubType
                                                    )}
                                                    wrap
                                                >
                                                    {capitalizeString(
                                                        EntityName[
                                                            r.entitySubType
                                                        ] || r.entitySubType
                                                    )}
                                                </Tooltip>
                                            </p>

                                            {(capitalizeString(
                                                EntityName[r.entitySubType] ||
                                                    r.entitySubType
                                            ) === "Register" ||
                                                capitalizeString(
                                                    EntityName[
                                                        r.entitySubType
                                                    ] || r.entitySubType
                                                ) === "Survey") &&
                                                nodeRuleCountObject[
                                                    r.ruleSetId
                                                ] != null && (
                                                    <Tooltip
                                                        message={
                                                            "View Details under more options to see all the rules"
                                                        }
                                                        position="top"
                                                    >
                                                        <div
                                                            className={
                                                                style.rulesCountWrapper
                                                            }
                                                        >
                                                            <Icon
                                                                className={
                                                                    "mr-5"
                                                                }
                                                                icon={
                                                                    "FlowRules"
                                                                }
                                                                fallbackIcon="Default"
                                                                size="14px"
                                                            />

                                                            {
                                                                nodeRuleCountObject[
                                                                    r.ruleSetId
                                                                ]
                                                            }
                                                        </div>
                                                    </Tooltip>
                                                )}
                                        </div>
                                        {r.entityId && (
                                            <div
                                                className={`${style.value} relative`}
                                            >
                                                {container?.name || (
                                                    <LinearDeterminate
                                                        customClass={
                                                            style.loader
                                                        }
                                                    ></LinearDeterminate>
                                                )}
                                            </div>
                                        )}
                                        {statsMap && statsMap[r.id] && (
                                            <>
                                                <div
                                                    className={`${style.stats} dp-parent gap-10`}
                                                >
                                                    <CustomTooltipRenderer
                                                        position={"top"}
                                                        data={
                                                            statsMap[r.id]
                                                                ?.impArr
                                                        }
                                                        customRenderer={() => (
                                                            <div className="dp-parent dp-parent-col">
                                                                <p
                                                                    className={
                                                                        style.statsTableHeader
                                                                    }
                                                                >
                                                                    Incoming
                                                                </p>
                                                                <div
                                                                    className={
                                                                        style.statsTableWrap
                                                                    }
                                                                >
                                                                    <Table
                                                                        data={statsMap[
                                                                            r.id
                                                                        ]?.impArr?.map(
                                                                            pid => {
                                                                                return {
                                                                                    ...pid,
                                                                                    name:
                                                                                        containers.find(
                                                                                            _ =>
                                                                                                _.id ===
                                                                                                pid.entityId
                                                                                        )
                                                                                            ?.name ||
                                                                                        "N/A",
                                                                                };
                                                                            }
                                                                        )}
                                                                        columns={[
                                                                            {
                                                                                text: "Container Name",
                                                                                key: "name",
                                                                                disableCopyText: true,
                                                                                CustomRender:
                                                                                    ({
                                                                                        item,
                                                                                    }) => {
                                                                                        return (
                                                                                            <div
                                                                                                className={
                                                                                                    item.name !==
                                                                                                    "N/A"
                                                                                                        ? style.statsTableRowText
                                                                                                        : ""
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    const node =
                                                                                                        nodes.find(
                                                                                                            _ =>
                                                                                                                _.id ===
                                                                                                                "rule:" +
                                                                                                                    item.id
                                                                                                        );
                                                                                                    const rule =
                                                                                                        rules.find(
                                                                                                            _ =>
                                                                                                                _.id ===
                                                                                                                item.id
                                                                                                        );
                                                                                                    if (
                                                                                                        node &&
                                                                                                        rule
                                                                                                    ) {
                                                                                                        setViewportCenter(
                                                                                                            node.position
                                                                                                        );
                                                                                                        if (
                                                                                                            typeof openNode ===
                                                                                                            "function"
                                                                                                        ) {
                                                                                                            openNode(
                                                                                                                rule
                                                                                                            );
                                                                                                        }
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                {
                                                                                                    item.name
                                                                                                }
                                                                                            </div>
                                                                                        );
                                                                                    },
                                                                            },
                                                                            {
                                                                                text: "Impressions",
                                                                                key: "imp",
                                                                                disableCopyText: true,
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        portalElement={flowWrapperRef?.current?.querySelector(
                                                            ".react-flow__viewport"
                                                        )}
                                                        customClientRect={getCustomClientRect(
                                                            flowWrapperRef?.current,
                                                            flowWrapperRef?.current?.querySelector(
                                                                `.react-flow__node[data-id='${
                                                                    "rule:" +
                                                                    r?.id
                                                                }']`
                                                            ),
                                                            80
                                                        )}
                                                    >
                                                        <div
                                                            className={`${
                                                                style.impression
                                                            } ${
                                                                statsMap[r.id]
                                                                    .impressions >
                                                                0
                                                                    ? ""
                                                                    : style.noImpression
                                                            } dp-parent-inline dp-parent-col gap-5 p-5`}
                                                        >
                                                            <div className="dp-parent dp-parent-ver-center gap-5">
                                                                <Icon
                                                                    icon="Incoming"
                                                                    size="12px"
                                                                    color="#0d2238"
                                                                />
                                                                In
                                                            </div>
                                                            <NumberRenderer
                                                                item={
                                                                    statsMap[
                                                                        r.id
                                                                    ]
                                                                        ?.impressions ||
                                                                    0
                                                                }
                                                                intlFormat
                                                            />
                                                        </div>
                                                    </CustomTooltipRenderer>
                                                    {statsMap[r.id]
                                                        .impressions > 0 && (
                                                        <>
                                                            <CustomTooltipRenderer
                                                                position={"top"}
                                                                data={
                                                                    statsMap[
                                                                        r.id
                                                                    ]?.dropArr
                                                                }
                                                                customRenderer={() => (
                                                                    <div className="dp-parent dp-parent-col">
                                                                        <p
                                                                            className={
                                                                                style.statsTableHeader
                                                                            }
                                                                        >
                                                                            Outgoing
                                                                        </p>
                                                                        <div
                                                                            className={
                                                                                style.statsTableWrap
                                                                            }
                                                                        >
                                                                            <Table
                                                                                data={statsMap[
                                                                                    r
                                                                                        .id
                                                                                ]?.dropArr?.map(
                                                                                    pid => {
                                                                                        return {
                                                                                            ...pid,
                                                                                            name:
                                                                                                containers.find(
                                                                                                    _ =>
                                                                                                        _.id ===
                                                                                                        pid.entityId
                                                                                                )
                                                                                                    ?.name ||
                                                                                                "N/A",
                                                                                        };
                                                                                    }
                                                                                )}
                                                                                columns={[
                                                                                    {
                                                                                        text: "Container Name",
                                                                                        key: "name",
                                                                                        disableCopyText: true,
                                                                                        CustomRender:
                                                                                            ({
                                                                                                item,
                                                                                            }) => {
                                                                                                return (
                                                                                                    <div
                                                                                                        className={
                                                                                                            item.name !==
                                                                                                            "N/A"
                                                                                                                ? style.statsTableRowText
                                                                                                                : ""
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            const node =
                                                                                                                nodes.find(
                                                                                                                    _ =>
                                                                                                                        _.id ===
                                                                                                                        "rule:" +
                                                                                                                            item.id
                                                                                                                );
                                                                                                            const rule =
                                                                                                                rules.find(
                                                                                                                    _ =>
                                                                                                                        _.id ===
                                                                                                                        item.id
                                                                                                                );
                                                                                                            if (
                                                                                                                node &&
                                                                                                                rule
                                                                                                            ) {
                                                                                                                setViewportCenter(
                                                                                                                    node.position
                                                                                                                );
                                                                                                                if (
                                                                                                                    typeof openNode ===
                                                                                                                    "function"
                                                                                                                ) {
                                                                                                                    openNode(
                                                                                                                        rule
                                                                                                                    );
                                                                                                                }
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            item.name
                                                                                                        }
                                                                                                    </div>
                                                                                                );
                                                                                            },
                                                                                    },
                                                                                    {
                                                                                        text: "Impressions",
                                                                                        key: "imp",
                                                                                        disableCopyText: true,
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                portalElement={flowWrapperRef?.current?.querySelector(
                                                                    ".react-flow__viewport"
                                                                )}
                                                                customClientRect={getCustomClientRect(
                                                                    flowWrapperRef?.current,
                                                                    flowWrapperRef?.current?.querySelector(
                                                                        `.react-flow__node[data-id='${
                                                                            "rule:" +
                                                                            r?.id
                                                                        }']`
                                                                    ),
                                                                    80
                                                                )}
                                                            >
                                                                <div
                                                                    className={`${style.forward} dp-parent-inline dp-parent-col gap-5 p-5`}
                                                                >
                                                                    <div className="dp-parent dp-parent-ver-center gap-5">
                                                                        <Icon
                                                                            icon="Outgoing"
                                                                            size="12px"
                                                                        />
                                                                        Out
                                                                    </div>
                                                                    <NumberRenderer
                                                                        item={
                                                                            outGoingCount
                                                                        }
                                                                        postFix={
                                                                            dropRate !==
                                                                            0
                                                                                ? " (" +
                                                                                  (100 -
                                                                                      dropRate) +
                                                                                  "%)"
                                                                                : ""
                                                                        }
                                                                        intlFormat
                                                                    />
                                                                </div>
                                                            </CustomTooltipRenderer>
                                                            <div
                                                                className={`${style.drop} dp-parent-inline dp-parent-col gap-5 p-5`}
                                                            >
                                                                <div className="dp-parent dp-parent-ver-center gap-5">
                                                                    <Icon
                                                                        icon="Drop"
                                                                        size="12px"
                                                                        color="#E76E6E"
                                                                    />
                                                                    Drop
                                                                </div>
                                                                <NumberRenderer
                                                                    item={
                                                                        statsMap[
                                                                            r.id
                                                                        ]
                                                                            ?.drop ||
                                                                        0
                                                                    }
                                                                    postFix={
                                                                        dropRate !==
                                                                        0
                                                                            ? " (" +
                                                                              dropRate +
                                                                              "%)"
                                                                            : ""
                                                                    }
                                                                    intlFormat
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {statsMap[r.id]?.impressions >
                                                    0 && (
                                                    <div
                                                        className={
                                                            style.statsVisual
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                style.statsVisual__impression
                                                            }
                                                            style={{
                                                                height:
                                                                    100 -
                                                                    dropRate +
                                                                    "%",
                                                            }}
                                                        ></div>
                                                        {dropRate !== 0 && (
                                                            <div
                                                                className={
                                                                    style.statsVisual__drop
                                                                }
                                                                style={{
                                                                    height:
                                                                        dropRate +
                                                                        "%",
                                                                }}
                                                            ></div>
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {(container?.name ||
                                        FlowRouterRelativeSubTypes.includes(
                                            r.entitySubType
                                        )) && (
                                        <NodeAction
                                            containerAsDestination={
                                                containerAsDestination
                                            }
                                            position={root.position}
                                            rule={r}
                                            openPreview={
                                                ContainerTypesWithPreview.includes(
                                                    r.entitySubType
                                                )
                                                    ? openPreview
                                                    : null
                                            }
                                            deleteNode={deleteNode}
                                            copyCurrentTree={
                                                !containerAsDestination &&
                                                copyCurrentTree
                                            }
                                            copyNode={
                                                !containerAsDestination &&
                                                copyNode
                                            }
                                            portalElement={
                                                flowWrapperRef.current
                                            }
                                            deleteAllNodes={deleteAllNodes}
                                            isHistory={isHistory}
                                            readOnly={readOnly}
                                            addFilter={addFilter}
                                            containers={containers}
                                            showAddFilter={
                                                !isEmpty(filterApplied)
                                                    ? !filterApplied?.some(
                                                          _ => _.ruleId === r.id
                                                      )
                                                    : true
                                            }
                                            filterSupported={
                                                // r.entityType === "CONTAINER" ||
                                                r.entitySubType === "LOGICAL" ||
                                                !isEmpty(
                                                    r?.targettingParams?.value
                                                )
                                            }
                                            removeFilter={removeFilter}
                                            filterApplied={filterApplied}
                                            showFiltered={showFiltered}
                                            cloneAndCopy={cloneAndCopy}
                                            nodeName={nodeName}
                                        ></NodeAction>
                                    )}

                                    {(!nextNodeForAdd.nextSetId ||
                                        !nextNode ||
                                        nextNode?.entityType ===
                                            EntityType.ACTIONS) &&
                                        !containerAsLastNode && (
                                            <>
                                                <div
                                                    className={`${
                                                        style.nodeAddAction
                                                    } ${
                                                        disconnectedNodeTreeRuleSetIds?.includes(
                                                            nextNode?.ruleSetId
                                                        )
                                                            ? style.disconnectedNode
                                                            : ""
                                                    }`}
                                                    onClick={e =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    <span
                                                        onClick={
                                                            !readOnly
                                                                ? () =>
                                                                      addNode(
                                                                          nextNodeForAdd
                                                                      )
                                                                : null
                                                        }
                                                    >
                                                        <Icon
                                                            className={
                                                                style.icon
                                                            }
                                                            icon="Add"
                                                            size="14px"
                                                            enableHover={
                                                                !readOnly
                                                            }
                                                            disable={readOnly}
                                                        />
                                                    </span>
                                                    {showPasteIcon && (
                                                        <span
                                                            onClick={() =>
                                                                !readOnly &&
                                                                !disablePasteIcon &&
                                                                loadCopiedNodes(
                                                                    nextNodeForAdd
                                                                )
                                                            }
                                                        >
                                                            <Icon
                                                                className={`${
                                                                    style.icon
                                                                } ${
                                                                    disablePasteIcon
                                                                        ? style.icon__disabled
                                                                        : ""
                                                                }`}
                                                                icon="Paste"
                                                                size="14px"
                                                                enableHover={
                                                                    !readOnly
                                                                }
                                                                disable={
                                                                    readOnly ||
                                                                    disablePasteIcon
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                </div>
                                {
                                    <Handle
                                        type="source"
                                        position="bottom"
                                        id="normal"
                                    />
                                }
                            </>
                        ),
                    };
                } else if (
                    r.entityType === EntityType.ROUTER ||
                    r.entityType === EntityType.ACTIONS
                ) {
                    root.nodeType =
                        r.entitySubType === "AB_TEST"
                            ? NodeRenderType.AB
                            : NodeRenderType.TP;
                    const parsedTargettingParams = r.targettingParams || {};
                    const key = fetchTpKey(parsedTargettingParams);
                    let possibleValues = [];
                    if (key === "ans_2") {
                        const qId = parseInt(
                            fetchTpKey(parsedTargettingParams, true)?.split(
                                "q_"
                            )[1]
                        );
                        possibleValues =
                            questionList
                                .find(_ => _.id === qId)
                                ?.answers?.map(_ => ({
                                    id: _.key,
                                    label: "Id: " + qId,
                                })) || [];
                    }
                    const tpLabel = renderTargetingNode(
                        parsedTargettingParams,
                        availableTargetingParams,
                        !isNextCR
                    );
                    let text = beautifyTargettingParams(
                        parsedTargettingParams,
                        availableTargetingParams,
                        isDarkMode,
                        possibleValues
                    );

                    root.data = {
                        label: (
                            <div
                                className={`nodeWrapper ${style[r.histClass]} ${
                                    style.nodeWrapper
                                } ${r.isFilteredOut ? style.blur : ""} ${
                                    r.entitySubType === "AB_TEST"
                                        ? style.ab + " ab"
                                        : style.targeting + " targeting"
                                } ${text === "" ? style.default : ""} ${
                                    r.highlight ? style.highlight : ""
                                } ${
                                    r?.ruleSetId === editNode?.ruleSetId
                                        ? style.activeNode
                                        : ""
                                } ${
                                    disconnectedNodeTreeRuleSetIds.includes(
                                        r?.ruleSetId
                                    )
                                        ? style.isDeleted
                                        : ""
                                }`}
                                onClick={() => {
                                    if (typeof openNode === "function") {
                                        openNode(r);
                                        setViewportCenter(root.position);
                                    }
                                }}
                            >
                                <div className={style.content}>
                                    {r.entitySubType === "AB_TEST" && (
                                        <div className={style.header}>
                                            <Icon
                                                icon={"OperatorFilled"}
                                                size="12px"
                                                className="mr-5"
                                            />
                                            <p>
                                                <Tooltip
                                                    message={capitalizeString(
                                                        r?.ruleMeta?.name ||
                                                            "Variant"
                                                    )}
                                                    wrap
                                                >
                                                    {r?.ruleMeta?.name ||
                                                        "Variant"}
                                                </Tooltip>
                                            </p>
                                        </div>
                                    )}
                                    <div className="dp-parent dp-parent-ver-center">
                                        <div
                                            className={`${style.value} ${style.noEllipsis}`}
                                        >
                                            {isNextCR ? (
                                                <span>{tpLabel} </span>
                                            ) : text == "" ? (
                                                "default"
                                            ) : (
                                                text
                                            )}
                                        </div>

                                        {r?.diversification > 1 && (
                                            <div
                                                className={`dp-parent dp-parent-ver-center gap-5 ${style.diversifyLabel}`}
                                            >
                                                <Icon
                                                    icon="Split"
                                                    size="11px"
                                                    color="#74838f"
                                                />
                                                <span>
                                                    {r?.diversification}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {text !== "" && (
                                    <NodeAction
                                        containerAsDestination={
                                            containerAsDestination
                                        }
                                        rules={rules}
                                        position={root.position}
                                        rule={r}
                                        // copyCurrentTree={copyCurrentTree}
                                        portalElement={flowWrapperRef.current}
                                        readOnly={readOnly}
                                        deleteNode={
                                            r.entitySubType === "AB_TEST" ||
                                            text === ""
                                                ? undefined
                                                : deleteNode
                                        }
                                        deleteAllNodes={
                                            r.entitySubType === "AB_TEST" ||
                                            text === "" ||
                                            !r.nextSetId
                                                ? undefined
                                                : deleteAllNodes
                                        }
                                        isHistory={isHistory}
                                        containers={containers}
                                        addFilter={addFilter}
                                        showAddFilter={
                                            !isEmpty(filterApplied)
                                                ? !filterApplied?.some(
                                                      _ => _.ruleId === r.id
                                                  )
                                                : true
                                        }
                                        filterSupported={
                                            r.entitySubType === "LOGICAL" &&
                                            !isEmpty(r?.targettingParams?.value)
                                        }
                                        availableTargetingParams={
                                            availableTargetingParams
                                        }
                                        removeFilter={removeFilter}
                                        filterApplied={filterApplied}
                                        showFiltered={showFiltered}
                                        nodeName={nodeName}
                                    />
                                )}
                                {(!r.nextSetId ||
                                    !nextNode ||
                                    nextNode?.entityType ===
                                        EntityType.ACTIONS) && (
                                    <>
                                        <div
                                            className={`${
                                                style.nodeAddAction
                                            } ${
                                                readOnly
                                                    ? style.nodeAddDisable
                                                    : ""
                                            }`}
                                            onClick={e => e.stopPropagation()}
                                        >
                                            <span
                                                onClick={
                                                    !readOnly
                                                        ? () => addNode(r)
                                                        : null
                                                }
                                            >
                                                <Icon
                                                    className={style.icon}
                                                    icon="Add"
                                                    enableHover={true}
                                                    size="14px"
                                                />
                                            </span>
                                            {showPasteIcon && (
                                                <span
                                                    onClick={() =>
                                                        !disablePasteIcon &&
                                                        loadCopiedNodes(r)
                                                    }
                                                >
                                                    <Icon
                                                        className={`${
                                                            style.icon
                                                        } ${
                                                            disablePasteIcon
                                                                ? style.icon__disabled
                                                                : ""
                                                        }`}
                                                        icon="Paste"
                                                        enableHover={true}
                                                        size="14px"
                                                        disable={
                                                            disablePasteIcon
                                                        }
                                                    />
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ),
                    };
                } else if (r.entityType === EntityType.FLOW_START) {
                    const flowItem = allFlowDetails.find(
                        _ => _.id === r.entityId
                    );
                    root.nodeType = NodeRenderType.FLOW_START;
                    root.data = {
                        label: (
                            <div
                                className={`nodeWrapper ${
                                    style[r?.histClass]
                                } hideBottomHandle
                                           ${style.nodeWrapper} ${
                                    style.flow_start
                                } ${
                                    r?.ruleSetId === editNode?.ruleSetId
                                        ? style.activeNode
                                        : ""
                                }`}
                                onClick={() => {
                                    if (typeof openNode === "function") {
                                        openNode(r);
                                        setViewportCenter(root.position);
                                    }
                                }}
                            >
                                {isTarget && (
                                    <Handle
                                        id="target"
                                        type="target"
                                        position="left"
                                        isValidConnection={connection =>
                                            connection.source === "some-id"
                                        }
                                        onConnect={params =>
                                            console.log(
                                                "handle onConnect",
                                                params
                                            )
                                        }
                                        className={style.targetHandle}
                                    />
                                )}
                                <div className={style.content}>
                                    <div className={style.header}>
                                        <Icon
                                            className={"mr-5"}
                                            icon={EntityIcon[r.entityType]}
                                            fallbackIcon="Default"
                                            size="12px"
                                        />
                                        <p>
                                            <Tooltip
                                                message={capitalizeString(
                                                    EntityName[r.entityType] ||
                                                        r.entityType
                                                )}
                                                wrap
                                            >
                                                {capitalizeString(
                                                    EntityName[r.entityType] ||
                                                        r.entityType
                                                )}
                                            </Tooltip>
                                        </p>
                                    </div>
                                    <div className={`${style.value} relative`}>
                                        {flowItem?.id ? (
                                            `${flowItem?.id}
                                            ${
                                                flowItem?.displayName !== null
                                                    ? ": " +
                                                      flowItem?.displayName
                                                    : ": N/A"
                                            }`
                                        ) : (
                                            <LinearDeterminate
                                                customClass={style.loader}
                                            ></LinearDeterminate>
                                        )}
                                    </div>
                                </div>
                                {flowItem?.id && (
                                    <NodeAction
                                        position={root.position}
                                        rule={r}
                                        deleteNode={deleteNode}
                                        portalElement={flowWrapperRef.current}
                                        openPreview={openPreview}
                                        readOnly={readOnly}
                                        nodeName={nodeName}
                                    ></NodeAction>
                                )}
                            </div>
                        ),
                    };
                } else if (r.entityType === EntityType.COLLAPSED) {
                    root.nodeType = NodeRenderType.COLLAPSED;
                    root.data = {
                        label: (
                            <div
                                className={`nodeWrapper ${style[r.histClass]} ${
                                    style.nodeWrapper
                                } ${style.collapsed}`}
                            >
                                <div
                                    onClick={e => {
                                        expandCollapse(r);
                                    }}
                                    className={style.expandCollapse}
                                >
                                    <Tooltip message={"Expand"} position="top">
                                        <Icon
                                            className={`${style.icon} ${style.rotate}`}
                                            size="10px"
                                            icon={"DropDownArrow"}
                                        />
                                    </Tooltip>
                                </div>
                                Collapsed
                            </div>
                        ),
                    };
                } else {
                    let text = simplifyText(JSON.stringify(r.targettingParams));
                    root.data = {
                        label: !text ? "default" : text,
                    };

                    if (r.entityType == RouterTypes.AB_TEST) {
                        root.data = {
                            label: text == "" ? "default" : text,
                        };
                    }
                }
                root.ruleSetId = r.ruleSetId;
                const id = KeyTypes.RULE + r.id;
                root.id = id;
                const existingNode = updatedNodes.find(_ => _.id === id) || {};
                root.position = existingNode.position;
                root.positionAbsolute = existingNode.positionAbsolute;
                root.height = existingNode.height;
                root.width = existingNode.width;
                return root;
            }

            function createNodes(ruleSetMapping, updatedNodes) {
                let newData = [];

                if (rules.length) {
                    const id = KeyTypes.SET + -1;
                    const existingNode =
                        updatedNodes.find(_ => _.id === id) || {};
                    newData.push({
                        ...existingNode,
                        ruleSetId: -1,
                        id: id,
                        category: NodeTypes.START,
                        type: "input",
                        deletable: true,
                        nodeType: NodeRenderType.START_END,
                        data: {
                            label: (
                                <div
                                    className={`${style.start} nodeWrapper   ${
                                        style.nodeWrapper
                                    } ${
                                        startNodeSecondaryStyle &&
                                        style.nodeStyleSecondary
                                    }`}
                                    onClick={() =>
                                        typeof openNode === "function" &&
                                        openNode(null)
                                    }
                                >
                                    {containerAsDestination && (
                                        <div
                                            className={`${style.psuedoContainer} ${style.pullLeft}`}
                                            onClick={e => e.stopPropagation()}
                                        >
                                            <div className={style.line}></div>
                                            <Tooltip
                                                message={
                                                    "Back action performed on any containers in the flow"
                                                }
                                                position="top"
                                            >
                                                <Icon
                                                    icon={"InfoFilled"}
                                                    size={"12px"}
                                                    className={"mr-5"}
                                                ></Icon>
                                            </Tooltip>
                                            All Containers
                                        </div>
                                    )}
                                    <span>{startNodeText}</span>
                                </div>
                            ),
                        },
                    });

                    if (showStartNodeHistory) {
                        if (
                            globalRulesStartId !== -2 &&
                            rules.some(_ => _.ruleSetId === globalRulesStartId)
                        ) {
                            const browserId = KeyTypes.SET + -2;
                            const browserExistingNode =
                                updatedNodes.find(_ => _.id === browserId) ||
                                {};
                            newData.push({
                                ...browserExistingNode,
                                ruleSetId: -2,
                                id: browserId,
                                category: NodeTypes.START,
                                type: "input",
                                deletable: true,
                                nodeType: NodeRenderType.START_END,

                                data: {
                                    label: (
                                        <div
                                            className={`${style.nodeWrapper} ${style.nodeStyleSecondary} `}
                                            onClick={() =>
                                                typeof openNode ===
                                                    "function" && openNode(null)
                                            }
                                        >
                                            <span>Global Back Rules</span>
                                        </div>
                                    ),
                                },
                            });
                        }

                        if (
                            fallBackStartId !== -3 &&
                            rules.some(_ => _.ruleSetId === fallBackStartId)
                        ) {
                            const fallBackId = KeyTypes.SET + -3;
                            const fallBackExistingNode =
                                updatedNodes.find(_ => _.id === fallBackId) ||
                                {};
                            newData.push({
                                ...fallBackExistingNode,
                                ruleSetId: -3,
                                id: fallBackId,
                                category: NodeTypes.START,
                                type: "input",
                                deletable: true,
                                nodeType: NodeRenderType.START_END,

                                data: {
                                    label: (
                                        <div
                                            className={`${style.nodeWrapper}  ${style.nodeStyleSecondary}`}
                                            onClick={() =>
                                                typeof openNode ===
                                                    "function" && openNode(null)
                                            }
                                        >
                                            <span>FallBack Container</span>
                                        </div>
                                    ),
                                },
                            });
                        }

                        if (
                            litigatorRulesStartId !== -4 &&
                            rules.some(
                                _ => _.ruleSetId === litigatorRulesStartId
                            )
                        ) {
                            const browserId = KeyTypes.SET + -4;
                            const browserExistingNode =
                                updatedNodes.find(
                                    _ => _.ruleSetId === litigatorRulesStartId
                                ) || {};
                            newData.push({
                                ...browserExistingNode,
                                ruleSetId: -4,
                                id: browserId,
                                category: NodeTypes.START,
                                type: "input",
                                deletable: true,
                                nodeType: NodeRenderType.START_END,

                                data: {
                                    label: (
                                        <div
                                            className={`${style.nodeWrapper} ${style.nodeStyleSecondary} `}
                                            onClick={() =>
                                                typeof openNode ===
                                                    "function" && openNode(null)
                                            }
                                        >
                                            <span>Litigator Rules</span>
                                        </div>
                                    ),
                                },
                            });
                        }
                    }
                }

                Object.keys(ruleSetMapping).forEach((rset, i) => {
                    let rs = parseInt(rset);
                    let nodeName =
                        !isEmpty(ruleSetMapping[rs]) &&
                        !isEmpty(ruleSetMapping[rs][0]) &&
                        getNodeName(ruleSetMapping[rs][0]);

                    const addRouterNode = ruleSetMapping[rs].some(
                        r =>
                            r.entityType === EntityType.ROUTER ||
                            (r.entityType === EntityType.ACTIONS &&
                                r.entitySubType === EntitySubType.next)
                    );

                    if (ruleSetMapping[rs].length && addRouterNode) {
                        const id = KeyTypes.SET + rs;
                        const existingNode =
                            updatedNodes.find(_ => _.id === id) || {};
                        let rsetNode = {
                            ...existingNode,
                            ruleSetId: rs,
                            id: id,
                            category: NodeTypes.RULE_SET,
                            style: {
                                backgroundSize: "32px",
                                borderRadius: "8px",
                            },
                        };
                        const isAbNode = ruleSetMapping[rs].every(
                            r => r.entitySubType === RouterTypes.AB_TEST
                        );

                        const isTargetingNode = ruleSetMapping[rs].some(
                            r =>
                                r.entitySubType === RouterTypes.LOGIC ||
                                r.entitySubType === EntitySubType.next
                        );

                        const parentCount =
                            rules.filter(_ => _.nextSetId === rs)?.length || 0;

                        if (isAbNode) {
                            rsetNode.nodeType = NodeRenderType.AB_ROUTER;
                            const unFilteredNode =
                                ruleSetMapping[rs].find(
                                    _ => !_.isFilteredOut
                                ) || ruleSetMapping[rs][0];

                            rsetNode.hidden = hiddenNodes.includes(
                                unFilteredNode.id
                            );
                            rsetNode.data = {
                                label: (
                                    <>
                                        <div
                                            className={`${
                                                style.nodeWrapper
                                            } nodeWrapper ${
                                                style[unFilteredNode.histClass]
                                            } ${
                                                unFilteredNode.isFilteredOut
                                                    ? style.blur
                                                    : ""
                                            } ${style.abRouter} abRouter ${
                                                unFilteredNode?.ruleSetId ===
                                                editNode?.ruleSetId
                                                    ? style.activeNode
                                                    : ""
                                            } ${
                                                disconnectedNodeTreeRuleSetIds.includes(
                                                    unFilteredNode?.ruleSetId
                                                )
                                                    ? style.isDeleted
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                if (
                                                    typeof openNode ===
                                                    "function"
                                                ) {
                                                    openNode(unFilteredNode);
                                                    setViewportCenter(
                                                        rsetNode.position
                                                    );
                                                }
                                            }}
                                        >
                                            {!hideExpandCollapse &&
                                                unFilteredNode?.nextSetId &&
                                                (parentCount === 1 ||
                                                    unFilteredNode?.ruleSetId ===
                                                        startId) && (
                                                    <div
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            expandCollapse(
                                                                unFilteredNode
                                                            );
                                                        }}
                                                        className={
                                                            style.expandCollapse
                                                        }
                                                    >
                                                        <Tooltip
                                                            message={"Collapse"}
                                                            position="top"
                                                        >
                                                            <Icon
                                                                size="10px"
                                                                icon={
                                                                    "DropDownArrow"
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                )}

                                            {isTarget && (
                                                <Handle
                                                    id="target"
                                                    type="target"
                                                    position="left"
                                                    isValidConnection={connection =>
                                                        console.log(connection)
                                                    }
                                                    onConnect={params =>
                                                        console.log(
                                                            "handle onConnect",
                                                            params
                                                        )
                                                    }
                                                    className={
                                                        style.targetHandle
                                                    }
                                                />
                                            )}
                                            <Icon
                                                className={style.icon}
                                                icon="ABTestNodeFilled"
                                                size={"18px"}
                                            />
                                            <NodeAction
                                                containerAsDestination={
                                                    containerAsDestination
                                                }
                                                rules={rules}
                                                position={rsetNode.position}
                                                rule={unFilteredNode}
                                                prefix={"set:"}
                                                copyCurrentTree={
                                                    copyCurrentTree
                                                }
                                                copyNode={copyNode}
                                                portalElement={
                                                    flowWrapperRef.current
                                                }
                                                deleteNode={() =>
                                                    deleteNode(
                                                        ruleSetMapping[rs]
                                                    )
                                                }
                                                deleteAllNodes={() =>
                                                    deleteAllNodes(
                                                        ruleSetMapping[rs]
                                                    )
                                                }
                                                readOnly={readOnly}
                                                isHistory={isHistory}
                                                nodeName={nodeName}
                                            />
                                        </div>
                                    </>
                                ),
                            };
                        } else if (isTargetingNode) {
                            rsetNode.nodeType = NodeRenderType.TP_ROUTER;
                            const node =
                                ruleSetMapping[rs].find(
                                    _ => _.targettingParams?.type
                                ) || ruleSetMapping[rs][0];

                            const unFilteredNode =
                                ruleSetMapping[rs].find(
                                    _ => !_.isFilteredOut
                                ) || ruleSetMapping[rs][0];
                            const isNextCR =
                                node?.entityType === EntityType.ACTIONS &&
                                node?.entitySubType === EntitySubType.next;
                            const tpText = renderTargetingNode(
                                node?.targettingParams,
                                availableTargetingParams,
                                !isNextCR
                            );

                            rsetNode.hidden = hiddenNodes.includes(node.id);
                            rsetNode.data = {
                                label: (
                                    <>
                                        <div
                                            className={`${
                                                style.nodeWrapper
                                            } nodeWrapper ${
                                                style[unFilteredNode.histClass]
                                            } ${
                                                unFilteredNode?.isFilteredOut
                                                    ? style.blur
                                                    : ""
                                            }
                            ${
                                node?.entityType === EntityType.ACTIONS
                                    ? style.cr
                                    : ""
                            }
                             ${style.router} router ${
                                                node?.highlight
                                                    ? style.highlight
                                                    : ""
                                            } ${
                                                node?.ruleSetId ===
                                                editNode?.ruleSetId
                                                    ? style.activeNode
                                                    : ""
                                            } ${
                                                disconnectedNodeTreeRuleSetIds.includes(
                                                    node?.ruleSetId
                                                )
                                                    ? style.isDeleted
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                if (
                                                    typeof openNode ===
                                                    "function"
                                                ) {
                                                    openNode(node);
                                                    setViewportCenter(
                                                        rsetNode.position
                                                    );
                                                }
                                            }}
                                        >
                                            {!hideExpandCollapse &&
                                                node.nextSetId &&
                                                (parentCount === 1 ||
                                                    node?.ruleSetId ===
                                                        startId) && (
                                                    <div
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            expandCollapse(
                                                                node
                                                            );
                                                        }}
                                                        className={
                                                            style.expandCollapse
                                                        }
                                                    >
                                                        <Tooltip
                                                            message={"Collapse"}
                                                            position="top"
                                                        >
                                                            <Icon
                                                                size="10px"
                                                                icon={
                                                                    "DropDownArrow"
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                )}

                                            {isTarget && (
                                                <Handle
                                                    id="target"
                                                    type="target"
                                                    position="left"
                                                    isValidConnection={connection =>
                                                        console.log(connection)
                                                    }
                                                    onConnect={params =>
                                                        console.log(
                                                            "handle onConnect",
                                                            params
                                                        )
                                                    }
                                                    className={
                                                        style.targetHandle
                                                    }
                                                />
                                            )}

                                            <div className={style.tpContent}>
                                                {isNextCR
                                                    ? "SKIP CONTAINER"
                                                    : tpText}
                                                <NodeAction
                                                    containerAsDestination={
                                                        containerAsDestination
                                                    }
                                                    rules={rules}
                                                    position={rsetNode.position}
                                                    rule={node}
                                                    prefix={"set:"}
                                                    copyCurrentTree={
                                                        !isNextCR &&
                                                        copyCurrentTree
                                                    }
                                                    copyNode={
                                                        !isNextCR && copyNode
                                                    }
                                                    deleteNode={
                                                        typeof deleteNode ===
                                                        "function"
                                                            ? () => {
                                                                  const toBeDeleted =
                                                                      isNextCR
                                                                          ? node
                                                                          : ruleSetMapping[
                                                                                rs
                                                                            ];
                                                                  deleteNode(
                                                                      toBeDeleted
                                                                  );
                                                              }
                                                            : null
                                                    }
                                                    portalElement={
                                                        flowWrapperRef.current
                                                    }
                                                    deleteAllNodes={
                                                        typeof deleteAllNodes ===
                                                        "function"
                                                            ? () =>
                                                                  deleteAllNodes(
                                                                      isNextCR
                                                                          ? node
                                                                          : ruleSetMapping[
                                                                                rs
                                                                            ]
                                                                  )
                                                            : null
                                                    }
                                                    readOnly={readOnly}
                                                    isHistory={isHistory}
                                                    nodeName={nodeName}
                                                />
                                            </div>
                                        </div>
                                    </>
                                ),
                            };
                        } else {
                            // rsetNode.type = "group";
                            rsetNode.style = {
                                height: "1px",
                                pointerEvents: "none",
                                data: {
                                    label: <div>Collapse</div>,
                                },
                            };
                        }
                        newData.push(rsetNode);
                    }
                    ruleSetMapping[rs].forEach(r => {
                        if (
                            r.entityType !== EntityType.ACTIONS ||
                            (r.entityType === EntityType.ACTIONS &&
                                r.entitySubType === EntitySubType.next)
                        ) {
                            let nextNode = rules.find(
                                _ => _.ruleSetId === r.nextSetId
                            );
                            nextNode = findNextSetId(nextNode, ruleSetMapping);
                            newData.push(
                                createChartNode(r, nextNode, updatedNodes)
                            );
                        }
                    });
                });

                return newData;
            }

            function generateRules(rules, filteredRules = [], updatedNodes) {
                let ruleSetMapping = {};

                rules.forEach(r => {
                    r.highlight = filteredRules.includes(r.id);
                    r.isFilteredOut =
                        filteredRules.length && !filteredRules.includes(r.id);
                    nodePositions.current["set:" + r.ruleSetId] =
                        r.ruleSetMeta?.properties?.position;
                    nodePositions.current["rule:" + r.id] =
                        r.ruleMeta?.properties?.position;
                    let set = ruleSetMapping[r.ruleSetId] || [];
                    if (r.entitySubType != EntitySubType.goto) set.push(r);
                    ruleSetMapping[parseInt(r.ruleSetId)] = set;
                });

                const newData = createNodes(ruleSetMapping, updatedNodes);

                const newLinks = createLinks(
                    startId,
                    ruleSetMapping,
                    filteredRules,
                    readOnly,
                    isDarkMode,
                    showPasteIcon,
                    disablePasteIcon,
                    editNode,
                    addNode,
                    loadCopiedNodes,
                    hiddenNodes,
                    rules,
                    disConnectFlow,
                    globalRulesStartId,
                    fallBackStartId,
                    disconnectedNodeTreeRuleSetIds,
                    litigatorRulesStartId
                );

                const uniqueLinks = uniqBy(newLinks, "id");
                return [newData, uniqueLinks];
            }

            return (
                <>
                    <div
                        className={`${style.flowWrapper} fadeIn`}
                        ref={flowWrapperRef}
                    >
                        {!rules.length && (
                            <div className={style.emptyGraphics}>
                                {!loading ? (
                                    <>
                                        {!editPhase &&
                                            !readOnly &&
                                            (showDummyStartNode ? (
                                                <div
                                                    className={`${
                                                        style.start
                                                    } nodeWrapper cursorGrab  ${
                                                        style.nodeWrapper
                                                    } ${
                                                        startNodeSecondaryStyle &&
                                                        style.nodeStyleSecondary
                                                    }`}
                                                    onClick={() =>
                                                        typeof openNode ===
                                                            "function" &&
                                                        openNode(null)
                                                    }
                                                >
                                                    <span>{startNodeText}</span>
                                                </div>
                                            ) : (
                                                <>
                                                    <img
                                                        src={emptyFlowImg}
                                                        onClick={() =>
                                                            addNode()
                                                        }
                                                    />
                                                    <p>
                                                        Create your ideal user
                                                        flow here.
                                                    </p>
                                                </>
                                            ))}
                                    </>
                                ) : (
                                    <>
                                        {editPhase ? (
                                            <div className={style.editPhase}>
                                                <div
                                                    className={`${style.skeletonSquare} mb-30`}
                                                >
                                                    <Skeleton
                                                        width={100}
                                                        height={40}
                                                    />
                                                </div>
                                                {/* <div
                                                    className={style.longArrow}
                                                ></div> */}
                                                <div
                                                    className={`${style.skeletonParallelogram} mb-30`}
                                                >
                                                    <Skeleton
                                                        width={200}
                                                        height={40}
                                                    />
                                                </div>
                                                {/* <div
                                                    className={style.longArrow}
                                                ></div> */}
                                                <div
                                                    className={
                                                        style.skeletonRect
                                                    }
                                                >
                                                    <Skeleton
                                                        width={200}
                                                        height={60}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    className={`${style.circleSkeleton} mb-20`}
                                                >
                                                    <Skeleton
                                                        width={200}
                                                        height={200}
                                                    />
                                                </div>

                                                <div
                                                    className={
                                                        style.lineSkeleton
                                                    }
                                                >
                                                    <Skeleton
                                                        width={200}
                                                        height={26}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        )}

                        <ReactFlow
                            onPaneClick={e => {
                                onClick(e);
                                // setShowDropdown(false);
                            }}
                            onNodeClick={e => {
                                onClick(e);
                                // setShowDropdown(false);
                            }}
                            nodes={nodes}
                            edges={edges}
                            edgeTypes={EdgeTypes}
                            defaultEdgeOptions={{
                                type: "smoothstep",
                                borderRadius: 100,
                                style: {
                                    stroke: "#CAD1D4",
                                },
                                markerEnd: {
                                    type: MarkerType.Arrow,
                                    width: 80,
                                    height: 20,
                                    color: isDarkMode ? "#465158" : "#cad1d4",
                                },
                            }}
                            fitView
                            maxZoom={1.5}
                            minZoom={0.01}
                            onNodeDragStop={debounce((e, n) => {
                                nodePositions.current[n.id] = {
                                    x: n.position.x,
                                    y: n.position.y,
                                };
                                if (!autoLayout) {
                                    updatePositions(
                                        n.id,
                                        nodePositions.current[n.id]
                                    );
                                }
                            }, 200)}
                            onKeyUp={e => {
                                console.log(e);
                            }}
                            deleteKeyCode={[]}
                            {...{
                                onNodesChange,
                                onConnect,
                            }}
                        >
                            <Background
                                style={{
                                    background: "var(--bodyBg)",
                                }}
                            />
                            {!readOnly && <Controls />}
                        </ReactFlow>
                        {process.env.NODE_ENV === "production" &&
                            publishVersion &&
                            !loading && (
                                <div
                                    className={`prodFlowVersionNumber`}
                                    data-version={`V ${publishVersion}`}
                                ></div>
                            )}
                    </div>
                </>
            );
        }
    )
);

export default memo(
    forwardRef((props, ref) => (
        <ReactFlowProvider>
            <FlowRenderer ref={ref} {...props} />
        </ReactFlowProvider>
    ))
);
