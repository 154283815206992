import { get, isEmpty } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import TableFlex from "../TableFlex/TableFlex";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import PanelGrid from "../../Shared/PanelGrid/PanelGrid";
import Input from "../../Shared/Input/Input";
import { getRandomId, scrollToElem } from "../../utils/globalUtils";
import { usePrevious } from "../../Shared/hooks/use-previous";
import { useDispatch } from "react-redux";
import Select from "../../Shared/Select/Select";
import s from "./OfferWallSlots.module.scss";
import OfferWallSlotsInnerTable from "./OfferWallSlotsInnerTable";
import { useParams } from "react-router-dom";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import { toastListActions } from "../../store/toastList/toastList";
import EditOfferSlots from "../EditOfferPathSlots/EditOfferSlots";
import Icon from "../../Shared/Icon/Icon";
import CopyRenderer from "../Renderers/CopyRenderer/CopyRenderer";
import CustomTooltipRenderer from "../../components/Renderers/CustomTooltipRenderer/CustomTooltipRenderer";
import tooltipStyles from "../../components/Renderers/CustomTooltipRenderer/CustomTooltipRenderer.module.scss";
import OfferPathSlotsInnerTable from "../OfferPathSlots/OfferPathSlotsInnerTable";
import FeatureCopyToolbar from "../FeatureCopyToolbar/FeatureCopyToolbar";
import { SURVEYS } from "../../constants/url";
import { reactQueryApiCall } from "../../generalApi/generalApi";

const OfferWallSlots = ({
    fetchDealIdsApi,
    creativesList,
    offerWallAdSlots,
    action,
    data,
    pageId,
    readOnly = false,
    featureCopyData,
}) => {
    const dispatch = useDispatch();
    const tableConfig = {
        stickyHeader: false,
    };
    const editSlotIndexRef = useRef(null);

    const prevOfferWallAdSlots = usePrevious(offerWallAdSlots);
    const params = useParams();

    const [typeError, setTypeError] = useState(false);
    const [slotIndexError, setSlotIndexError] = useState(false);

    const typeList = [{ id: 1, key: "WeightGroup", text: "WeightGroup" }];

    const revenueList = [
        { id: 1, text: "cpc", key: "cpc" },
        { id: 2, text: "cpm", key: "cpm" },
        { id: 3, text: "cpa", key: "cpa" },
        { id: 4, text: "cpl", key: "cpl" },
    ];

    const { apiData: offerPathKeyList } = reactQueryApiCall(
        "offerPathKeys",
        "Offer path keys for mapping",
        {
            url: SURVEYS.GET_OFFER_KEYS,
            method: "GET",
        }
    );

    useEffect(() => {
        if (
            JSON.stringify(prevOfferWallAdSlots) !==
            JSON.stringify(offerWallAdSlots)
        ) {
            dispatch(
                action.addSelectedListItems({
                    page: pageId,
                    data: offerWallAdSlots.map(ops => ({
                        index: ops.slotIndex,
                        type: ops.type,
                        id: ops.id,
                    })),
                })
            );
        }
    }, [offerWallAdSlots]);

    const columns = [
        {
            text: "Index",
            key: "index",
            CustomRender: ({ col, item }) => {
                return <p>{item.index ? item.index : ""}</p>;
            },
            type: 0,
        },
        {
            text: "Id",
            key: "id",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                return item.id && item.id !== "-" ? (
                    <CopyRenderer>
                        <p>{item.id}</p>
                    </CopyRenderer>
                ) : (
                    <TagRenderer>New</TagRenderer>
                );
            },
        },
        {
            text: "Type",
            key: "type",
            CustomRender: ({ col, item }) => {
                if (data.editId && data.editId[0].rowId == item.rowId) {
                    let trafficSplitDropDownJson = {
                        className: `${s.editDropdown}`,
                        keyField: "text",
                        idField: "key",
                        position: "top",
                        selectedIdField: get(data, "editType.key", ""),
                    };
                    return <p>{item.type ? item.type : ""}</p>;
                } else {
                    return <p>{item.type ? item.type : ""}</p>;
                }
            },
            type: 5,
        },
        {
            text: "Creatives",
            key: "creatives",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                const dynamicColName2 = "weight";
                let copyItem = JSON.parse(JSON.stringify(item));
                copyItem.editCreativeData = copyItem.editCreativeData?.sort(
                    (a, b) => b.weight - a.weight
                );

                const arrayKeyName = "editCreativeData";
                const firstRenderData = "normal";
                const colName1 = "creative";
                const colName2 = dynamicColName2;
                const headerTitle1 = "Creative";
                const headerTitle2 = dynamicColName2;
                const centreAlign = true;

                const ConfigDetailPopup = () => {
                    const [configData, setConfigData] = useState({
                        data: item[arrayKeyName],
                    });
                    useEffect(() => {
                        let width = 0;
                        document
                            .querySelectorAll(".detailName")
                            .forEach(item => {
                                if (item.clientWidth > width)
                                    width = item.clientWidth;
                            });
                        document
                            .querySelectorAll(".detailName")
                            .forEach(
                                item =>
                                    (item.style.width =
                                        Math.ceil(width / 10) * 10 + "px")
                            );
                    }, [item]);

                    return (
                        <div>
                            <div className={tooltipStyles.externalHeader}>
                                <div className="detailName">{headerTitle1}</div>
                                <div
                                    className={`${
                                        tooltipStyles.externalChannel
                                    } ${
                                        centreAlign
                                            ? tooltipStyles.centreAlign
                                            : ""
                                    }`}
                                >
                                    {headerTitle2}
                                </div>
                            </div>
                            {configData && (
                                <div className={tooltipStyles.externalBody}>
                                    {configData?.data?.map((row, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={tooltipStyles.row}
                                            >
                                                <div
                                                    className={`${tooltipStyles.name} detailName dp-parent dp-parent-ver-center`}
                                                >
                                                    {!isEmpty(row[colName1]) &&
                                                        creativesList?.some(
                                                            _ =>
                                                                _.id ===
                                                                    parseInt(
                                                                        row[
                                                                            colName1
                                                                        ]?.split(
                                                                            ":"
                                                                        )[0]
                                                                    ) &&
                                                                _.isDeprecated
                                                        ) && (
                                                            <TagRenderer
                                                                className={
                                                                    s.deprecateTag
                                                                }
                                                                type={
                                                                    "deprecated"
                                                                }
                                                            >
                                                                Deprecated
                                                            </TagRenderer>
                                                        )}
                                                    <CopyRenderer>
                                                        <p>
                                                            {row[colName1]
                                                                ? row[colName1]
                                                                : "-"}
                                                        </p>
                                                    </CopyRenderer>
                                                </div>
                                                <div
                                                    className={`${
                                                        tooltipStyles.value
                                                    } ${
                                                        tooltipStyles.externalNumber
                                                    } ${
                                                        centreAlign
                                                            ? tooltipStyles.centreAlign
                                                            : ""
                                                    }`}
                                                >
                                                    <CopyRenderer>
                                                        <p>
                                                            {row[colName2]
                                                                ? row[colName2]
                                                                : "-"}
                                                        </p>
                                                    </CopyRenderer>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                };

                const getFirstData = () => {
                    if (firstRenderData === "colonSeperated") {
                        const firstValue = get(
                            item,
                            `${arrayKeyName}[0][${colName1}]`,
                            "-"
                        );
                        const secondValue = get(
                            item,
                            `${arrayKeyName}[0][${colName2}]`,
                            "-"
                        );
                        let combinedValue = "-";
                        if (firstValue === "-" && secondValue === "-")
                            combinedValue = "-";
                        else combinedValue = `${firstValue} : ${secondValue}`;
                        return combinedValue;
                    } else {
                        const firstValue = get(
                            item,
                            `${arrayKeyName}[0][${colName1}]`,
                            "-"
                        );
                        return firstValue;
                    }
                };

                return (
                    <>
                        {creativesList?.some(
                            _ =>
                                _.id ===
                                    parseInt(getFirstData()?.split(":")[0]) &&
                                _.isDeprecated
                        ) && (
                            <TagRenderer
                                className={s.deprecateTag}
                                type={"deprecated"}
                            >
                                Deprecated
                            </TagRenderer>
                        )}
                        <CustomTooltipRenderer
                            data={item[arrayKeyName]}
                            customRenderer={ConfigDetailPopup}
                        >
                            <span className="ml-5">{getFirstData()}</span>
                            {item[arrayKeyName]?.length > 1 && (
                                <span className={tooltipStyles.details}>
                                    +{item[arrayKeyName].length - 1}
                                </span>
                            )}
                        </CustomTooltipRenderer>
                    </>
                );
            },
        },
        {
            text: () => {
                if (get(data, "selectedListItems", []).length > 1) {
                    return (
                        <div
                            className={s.collapseBtnWarpper}
                            onClick={() =>
                                dispatch(
                                    action.expandCollapseTableToggle({
                                        page: pageId,
                                        data: !data.expandCollapseTable,
                                    })
                                )
                            }
                        >
                            <Icon
                                icon={
                                    !data.expandCollapseTable
                                        ? "Maximize"
                                        : "Minimize"
                                }
                                size="16px"
                                className="mr-5"
                            />
                            {data.expandCollapseTable
                                ? "Collapse All"
                                : "Expand All"}
                        </div>
                    );
                }
            },
            key: "",
            CustomRender: ({ col, item }) => {
                return (
                    <>
                        <ActionButtons
                            isDeleted={
                                get(data, "deletedSlots", []).findIndex(
                                    _ => _.id === item.id
                                ) !== -1
                            }
                            revertCallback={obj => {
                                dispatch(
                                    action.revertDeleteSlots({
                                        page: pageId,
                                        data: obj,
                                    })
                                );
                            }}
                            item={item}
                            deleteCallback={obj => {
                                onDeleteItem(obj);
                            }}
                            {...(item.type != "OfferWall"
                                ? { viewVariantCallback: openDropdown }
                                : {})}
                            viewChildTableText="View Creatives"
                            hideChildTableText="Hide Creatives"
                            readOnly={readOnly}
                        />
                    </>
                );
            },
            bulkActions: true,
            clickFunctionality: true,
        },
    ];

    const openDropdown = object => {
        dispatch(
            action.toggleShowAccordionTable({
                page: pageId,
                data: object.rowId,
                list: get(data, "selectedListItems", []),
            })
        );
        timer = setTimeout(() => {
            // commenting out scroll to since its not scrolling to right element, fix later
            // scrollToElem(editTableRef);
        });
    };

    const onAddListItem = () => {
        dispatch(
            action.setOfferPathSlotsError({
                page: pageId,
                data: false,
            })
        );
        let slotList = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        let indexCount = slotList.length + 1;
        dispatch(
            action.addIndex({
                page: pageId,
                data: indexCount,
            })
        );

        if (data?.type) {
            const dataJson = {
                index: get(data, "selectedListItems", []).length + 1,
                type: get(data, "type", "WeightGroup"),
                id: null ? "-" : "-",
                rowId: getRandomId(),
                editCreativeData: [],
            };
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            if (
                selectedListItemsCopy.findIndex(
                    item => item.id == dataJson.rowId
                ) == -1
            ) {
                selectedListItemsCopy.push(dataJson);
                let isOpen = true;
                for (let i = selectedListItemsCopy.length - 1; i >= 0; i--) {
                    if (
                        get(selectedListItemsCopy[i], "type", "WeightGroup") !=
                            "OfferWall" &&
                        isOpen
                    ) {
                        selectedListItemsCopy[i].showAccordionTable = true;
                        isOpen = false;
                    } else {
                        selectedListItemsCopy[i].showAccordionTable = false;
                    }
                }

                onDragReOrderColumn(selectedListItemsCopy);

                dispatch(
                    action.addType({
                        page: pageId,
                        data: null,
                    })
                );

                dispatch(
                    action.addIndex({
                        page: pageId,
                        data: "",
                    })
                );
            }
        } else if (get(data, "editId", "") != "") {
            let selectedListItemsCopy = get(
                data,
                "selectedListItems",
                []
            ).slice();
            const objIndex = selectedListItemsCopy.findIndex(
                obj => obj.rowId == data.editId[0].rowId
            );

            selectedListItemsCopy[objIndex] = {
                ...selectedListItemsCopy[objIndex],
                type: get(data, "editType.key", "WeightGroup"),
                index: get(data, "editIndex", ""),
                isEdit: params.id == null ? false : true,
            };

            if (data?.editIndex && +data.editIndex > 0) {
                dispatch(
                    action.addSelectedListItems({
                        page: pageId,
                        data: selectedListItemsCopy,
                    })
                );

                dispatch(
                    action.setEditId({
                        page: pageId,
                        data: "",
                    })
                );

                dispatch(
                    action.addEditIndex({
                        page: pageId,
                        data: "",
                    })
                );
            } else {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Please provide valid slot index (> 0)",
                    })
                );
            }
        } else {
            dispatch(
                action.setOfferPathSlotsError({ page: pageId, data: true })
            );
            if (data.type === "" || data.type === null) setTypeError(true);
        }
    };

    const onDragReOrderColumn = updatedColumn => {
        const selectedListItemsCopy = updatedColumn.map((item, idx) => ({
            ...item,
            index: idx + 1,
            isEdit: true,
        }));
        dispatch(
            action.addSelectedListItems({
                page: pageId,
                data: selectedListItemsCopy,
            })
        );
    };

    const onDeleteItem = obj => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        if (params.id && obj.id !== "-") {
            dispatch(
                action.setDeletedSlots({
                    page: pageId,
                    data: obj,
                })
            );
        } else {
            let itemIndex = selectedListItemsCopy.findIndex(
                element => element.rowId == obj.rowId
            );
            selectedListItemsCopy.splice(itemIndex, 1);

            dispatch(
                action.selectedMode({
                    page: pageId,
                    data: null,
                })
            );
        }
        onDragReOrderColumn(selectedListItemsCopy);
    };

    const editTableRef = useRef(null);
    const onEdit = editData => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        let itemIndex = selectedListItemsCopy.findIndex(
            element => element.rowId == get(editData, "rowId", null)
        );
        selectedListItemsCopy[itemIndex].showAccordionTable = false;

        dispatch(
            action.setEditId({
                page: pageId,
                data: [editData],
            })
        );
        dispatch(
            action.addSelectedListItems({
                page: pageId,
                data: selectedListItemsCopy,
            })
        );
        dispatch(
            action.rowTypeMode({
                page: pageId,
                data: "parent",
            })
        );
        timer = setTimeout(() => {
            // commenting out scroll to since its not scrolling to right element, fix later
            // scrollToElem(editTableRef);
        });
    };

    let timer = null;
    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const checkHistoryData = () => {
        return data.historyData && data.historyData.length > 0;
    };

    const selectTypeDropdown = data => {
        dispatch(
            action.addType({
                page: pageId,
                data: get(data, "text", ""),
            })
        );
        // focusTimer();
        setTypeError(false);
    };

    useEffect(() => {
        if (get(data, "editId", "") != "") {
            if (get(data, "rowTypeMode", "") == "parent") {
                const objIndex = get(data, "selectedListItems", []).findIndex(
                    obj => obj.rowId == data.editId[0].rowId
                );

                let getDropDownData = typeList.filter(
                    element =>
                        element.key ==
                        get(
                            data,
                            `selectedListItems[${objIndex}].type`,
                            "WeightGroup"
                        )
                );

                dispatch(
                    action.setEditType({
                        page: pageId,
                        data: getDropDownData[0],
                    })
                );

                dispatch(
                    action.addEditIndex({
                        page: pageId,
                        //Check if data can be obtained here
                        data: get(
                            data,
                            `selectedListItems[${objIndex}].index`,
                            ""
                        ),
                    })
                );
            }
        }
    }, [data.editId, data.rowTypeMode]);

    useEffect(() => {
        if (data.selectedListItems.length > 0) {
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            if (data.expandCollapseTable) {
                selectedListItemsCopy.map(item => {
                    if (item.type != "OfferWall")
                        return (item.showAccordionTable = true);
                });
            } else {
                selectedListItemsCopy.map(item => {
                    return (item.showAccordionTable = false);
                });
            }

            dispatch(
                action.expandCollapseTable({
                    page: pageId,
                    data: selectedListItemsCopy,
                })
            );
        }
    }, [data.expandCollapseTable]);

    const setSlots = slots => {
        featureCopyData?.copiedData.forEach(obj => {
            if (obj.id !== "-") {
                dispatch(
                    action.setDeletedSlots({
                        page: pageId,
                        data: obj,
                    })
                );
            }
        });

        dispatch(
            action.addSelectedListItems({
                page: pageId,
                data: [...slots],
            })
        );
    };

    return (
        <div
            id="offerWallSlots"
            data-hash-id="offerWallSlots"
            className={`flexHeightTab`}
        >
            <div className={`headerTitle`}>Offer Wall Slots</div>
            <div className={`headerTitleRequired`}>Add offer wall slots</div>
            <p className={`infoText`}>
                Slots can be added on the basis of slot index & type.&nbsp;
            </p>
            {featureCopyData && (
                <FeatureCopyToolbar
                    displayText="Slots"
                    featureCopyData={featureCopyData}
                    handleLoad={setSlots}
                    readOnly={readOnly}
                />
            )}
            <PanelGrid className={s.resetPadding}>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <div className={s.header}>
                        <div className={s.subHeader}>Slot Type</div>
                    </div>
                    <div className={s.body}>
                        <div className={s.toggleSwitch}>
                            <Select
                                label={"Select Type"}
                                options={typeList}
                                keyField={"text"}
                                onSelect={selectTypeDropdown}
                                idField={"key"}
                                onLoadSelectReturn={false}
                                selectedId={
                                    get(data, "type", null) == ""
                                        ? null
                                        : get(data, "type", null)
                                }
                                isError={typeError}
                                isDisabled={readOnly}
                            />
                        </div>

                        <Button
                            className={`${s.themBtn} ${s.marginLeft}`}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={readOnly}
                            type="submit"
                            onClick={onAddListItem}
                        >
                            Add
                        </Button>
                    </div>
                </form>
            </PanelGrid>

            {get(data, "selectedListItems", []).length > 0 && (
                <TableFlex
                    isPagination={false}
                    columns={columns}
                    config={tableConfig}
                    data={get(data, "selectedListItems", [])}
                    uniqueKey="id"
                    showBorder={true}
                    hnpTable={true}
                    headerBgColor={"#fafcfc"}
                    deletedIds={get(data, "deletedSlots", []).map(_ => _.id)}
                    AccordionComponent={object => (
                        <OfferPathSlotsInnerTable
                            fetchDealIdsApi={fetchDealIdsApi}
                            item={object.row}
                            creativesList={creativesList}
                            revenueList={revenueList}
                            action={action}
                            data={data}
                            pageId={pageId}
                            deletedSlotsAds={get(data, "deletedSlotAds", [])}
                            // historyInnerTableApi={historyInnerTableApi}
                            readOnly={readOnly}
                            enableDedupe={true}
                            featureCopyToolbarKeys={{
                                sourceName: "Offer Wall Slots",
                                sourcePage: "Offer Wall",
                                sourcePath: ["Offer Wall", "Slots", "Dedupe"],
                                type: "offer Wall Dedupe",
                            }}
                            showOffersHistoryIcon={false}
                            offerPathKeyList={offerPathKeyList}
                        />
                    )}
                    isEdit={false}
                    frozen={0}
                    sortableRows={true}
                    rowSortCallback={onDragReOrderColumn}
                    ref={editTableRef}
                    stickyHeaderClass={"stickyTableHead"}
                ></TableFlex>
            )}
        </div>
    );
};

export default OfferWallSlots;
