export const abTestDetails = abtMap => {
    return abtMap?.map(abt => ({
        abtID: abt.abtID,
        channel: abt.channel,
    }));
};

export const defaultChannels = () => {
    let initialChannels = ["abt", "random"];
    let abtChannelArray = Array.from({ length: 50 }, (_, i) => `abt${i + 1}`);
    let liveChannelArray = Array.from({ length: 50 }, (_, i) => `live${i + 1}`);
    let channels = [
        ...initialChannels,
        ...abtChannelArray,
        ...liveChannelArray,
    ];
    let response = {
        data: channels,
    };
    return response;
};

export const inputDropdownDataRefactorToApi = array => {
    let valueContainer = [];
    array?.map((element, index) => {
        let outPut = {
            abtID: element.valueOne,
            channel: element.valueSecond,
            variantName: "",
            abtName: element.valueName,
        };
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const selectDropdownDataRefactorFromApi = array => {
    let valueContainer = [];
    array?.map((element, index) => {
        let outPut = {
            valueOne: element.abtID,
            valueSecond: element.channel,
            valueName: element.abtName,
        };
        valueContainer.push(outPut);
    });
    return valueContainer;
};

export const createChannelMapData = (array, abTests) => {
    let finalChannelMapArray = [];
    const result = array?.map(a => ({
        ...a,
        ...abTests.find(abTest => abTest.id === a.abtID),
    }));
    result?.map((element, index) => {
        let outPut = {
            valueOne: element.abtID,
            valueSecond: element.channel,
            valueName: element.name,
        };
        finalChannelMapArray.push(outPut);
    });
    return finalChannelMapArray;
};
