import { collectionSelectList } from "./collectionUtils";

export const typeList = [
    ...collectionSelectList,
    {
        name: "Interstitial",
        id: "INTERSTITIAL",
    },
    {
        name: "Background",
        id: "BACKGROUND",
    },
    {
        name: "Loader",
        id: "LOADER",
    },
    {
        name: "Push Notification",
        id: "PUSHNOTIF",
    },
];

export const subTypes = [
    { id: "INNER", name: "Collection" },
    { id: "OUTER", name: "Container" },
    { id: "ENTRY", name: "Entry", type: "INTERSTITIAL" },
    { id: "EXIT", name: "Exit", type: "INTERSTITIAL" },
];

export const mapListingData = data => {
    return data?.map(_ => {
        let subType = _.subType;
        if (_.subType === "INNER") {
            subType = "Collection";
        } else if (_.subType === "OUTER") {
            subType = "Container";
        }
        return {
            ..._,
            subType: subType,
        };
    });
};
