import { SURVEYS } from "../constants/url";
import customFetch from "../fetch/customFetch";
import { defaultInitialStatusFilter } from "./filterUtils";
import { toastListActions } from "../store/toastList/toastList";

export const adSlotsPostApiData = (adSlots, id, header = {}) => {
    return adSlots.map(slot => {
        const params = {
            offerPathId: id,
            slotIndex: slot.index,
            trafficSplit: slot.trafficSplit,
            type: slot.type,
            ...(slot.offerWall && { offerWall: +slot.offerWall.split(":")[0] }),
            ...(slot.dynamicSortFunc && {
                rtbGroup: {
                    dynamicSortFunc: slot.dynamicSortFunc,
                },
            }),
            ...(slot.adsCreative && {
                adsCreative: +slot.adsCreative.split(":")[0],
            }),
        };
        return customFetch(SURVEYS.CREATE_AD_SLOT, "POST", params, header);
    });
};

export const adSlotsPutApiData = (adSlots, id, header = {}) => {
    return adSlots.map(slot => {
        const params = {
            id: slot.id,
            offerPathId: id,
            slotIndex: slot.index,
            trafficSplit: slot.trafficSplit,
            type: slot.type,
            ...(slot.offerWall && { offerWall: +slot.offerWall.split(":")[0] }),
            ...(slot.dynamicSortFunc && {
                rtbGroup: {
                    id: slot.rtbGroupId,
                    dynamicSortFunc: slot.dynamicSortFunc,
                },
            }),
            ...(slot.adsCreative && {
                adsCreative: +slot.adsCreative.split(":")[0],
            }),
        };
        return customFetch(
            `${SURVEYS.CREATE_AD_SLOT}/${slot.id}`,
            "PUT",
            params,
            header
        );
    });
};

export const adGroupPostApiData = (slot, index, responses, header = {}) => {
    const creatives = slot.editCreativeData.map(creative => ({
        creativeId: +creative.creative.split(":")[0],
        dealId: creative.dealId,
        dedupeKeys: creative.dedupeRules,
        [slotType[slot.type]]: creative[slotType[slot.type]],
        revType: creative.revenueType,
        customKeyVal:
            creative?.customKeyVal?.map(_ => ({
                key: _.key,
                value: _.value,
                dataType: _.dataType,
            })) || [],
    }));
    const data = responses.find(_ => _.data?.data?.slotIndex === slot.index)
        ?.data?.data;
    const params = {
        offerPathAdSlotId: slot.id !== "-" ? slot.id : data.id,
        [slotAds[slot.type]]: creatives,
        ...(data?.rtbGroup?.id && { id: data?.rtbGroup.id }),
        ...(slot.dynamicSortFunc && {
            dynamicSortFunc: slot.dynamicSortFunc,
        }),
    };
    return customFetch(
        `${SURVEYS.CREATE_AD_GROUP}/${groupCreate[slot.type]}`,
        "POST",
        params,
        header
    );
};

export const adGroupPutApiData = (slot, group, header = {}) => {
    const filteredCreatives = slot.editCreativeData.filter(
        c => c.id === "-" || c.isEdit
    );
    if (filteredCreatives.length) {
        const creativesObj = filteredCreatives.map(creative => {
            const obj = {
                creativeId: +creative.creative.split(":")[0],
                dealId: creative.dealId,
                dedupeKeys: creative.dedupeRules,
                [slotType[slot.type]]: creative[slotType[slot.type]],
                revType: creative.revenueType,
                [groupId[slot.type]]: group.id,
                customKeyVal:
                    creative?.customKeyVal?.map(_ => ({
                        key: _.key,
                        value: _.value,
                        dataType: _.dataType,
                    })) || [],
            };
            if (creative.id !== "-") {
                obj["id"] = creative.id;
            }
            return obj;
        });

        return creativesObj.map(c => {
            const path = c.id
                ? `${SURVEYS.CREATE_AD_GROUP}/${groupUpdate[slot.type]}/${c.id}`
                : `${SURVEYS.CREATE_AD_GROUP}/${groupUpdate[slot.type]}`;
            const requestType = `${c.id ? "PUT" : "POST"}`;
            return customFetch(path, requestType, c, header);
        });
    }
};

export const deleteSlotsApiData = (slots, header = {}) => {
    return slots.map(slot =>
        customFetch(
            `${SURVEYS.CREATE_AD_SLOT}/${slot.id}`,
            "DELETE",
            {},
            header
        )
    );
};

export const deleteSlotsAdsApiData = (data, header = {}) => {
    return data.map(item =>
        customFetch(
            `${SURVEYS.DELETE_SLOT_AD}/${[groupUpdate[item.type]]}/${
                item.ad.id
            }`,
            "DELETE",
            {},
            header
        )
    );
};

export const slotType = {
    WeightGroup: "weight",
    PriorityGroup: "priority",
    RtbGroup: "disposition",
};

export const slotAds = {
    WeightGroup: "weightedAds",
    PriorityGroup: "priorityAds",
    RtbGroup: "rtbAds",
};

export const groupCreate = {
    WeightGroup: "wg",
    PriorityGroup: "pg",
    RtbGroup: "rtbg",
};

export const groupUpdate = {
    WeightGroup: "wa",
    PriorityGroup: "pa",
    RtbGroup: "rtba",
};

export const groupCreateAdUnit = {
    WeightGroup: "wg",
    PriorityGroup: "pg",
    RtbGroup: "au",
};

export const slotTypeSmallCase = {
    WeightGroup: "weightGroup",
    PriorityGroup: "priorityGroup",
    RtbGroup: "rtbGroup",
};

export const groupId = {
    WeightGroup: "weightGroupId",
    PriorityGroup: "priorityGroupId",
    RtbGroup: "rtbGroupId",
};

export const initialFilterData = [
    {
        ...defaultInitialStatusFilter,
        page: "offerpaths",
    },
];

//merges two array with new keys also
export const mergeByKey = (...arrays) => {
    const merged = {};
    arrays?.forEach(data =>
        data.forEach(o => Object.assign((merged[o.key] ??= {}), o))
    );
    return Object.values(merged);
};

export const checkKeyValue = (offerKeysData, dispatch) => {
    offerKeysData?.map(ele => {
        if (ele.dataType === "boolean" && !("value" in ele)) {
            ele.value = false;
        }
        return ele;
    });
    if (
        offerKeysData
            ?.filter(_ => _.dataType !== "boolean")
            ?.some(_ => !_.value)
    ) {
        dispatch(
            toastListActions.setToastList({
                type: "Error",
                message: "Every key should have a value",
            })
        );
        return false;
    } else {
        return true;
    }
};
