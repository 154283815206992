// constant for product list, dump of product html in public folder
export const ProductList = [
    {
        name: "MJC",
        id: "mjc",
    },
    {
        name: "RDS",
        id: "rds",
    },
    {
        name: "GCS",
        id: "gcs",
    },
];

// constant for preview devices
export const DeviceTypes = ["mobile", "desktop", "tablet"];

export const DeviceIconMap = {
    desktop: "Desktop",
    mobile: "Phone",
    tablet: "Tablet",
};

// URL type macro list to show upload file button in textbox
export const UrlTypeMacros = [
    "%%url%%",
    "%%max-ad-main-image%%",
    "%%max-ad-icon%%",
    "%%max-ad-image%%",
    "%%job-logo%%",
];

// Not all macros will need to be replaced for preview, blacklisting those which are mostly system use.
export const BlackListedMacros = [
    "%%ZIP_CODE%%",
    "%%SID%%",
    "%%KEYWORD%%",
    "%%JOB_TYPE%%",
    "%%KW_OVER_JT%%",
    "%%JT_OVER_KW%%",
    "%%UTM_CONTENT%%",
    "%%FIRST_NAME%%",
    "%%LAST_NAME%%",
    "%%PHONE%%",
    "%%PHONE_CODE%%",
    "%%PHONE_PREFIX%%",
    "%%PHONE_SUFFIX%%",
    "%%EMAIL%%",
    "%%GEO_IP%%",
    "%%GEO_STATE%%",
    "%%GEO_CITY%%",
    "%%GENDER%%",
    "%%DOB%%",
    "%%DOB_MONTH%%",
    "%%DOB_DAY%%",
    "%%DOB_YEAR%%",
    "%%AGE%%",
    "%%ADDRESS%%",
    "%%ADDRESS2%%",
    "%%MD5EMAIL%%",
    "%%UTM_CAMPAIGN%%",
    "%%UTM_SOURCE%%",
    "%%THEME_COLOR%%",
    "%%THEME_GRADIENT%%",
    "%%THEME_TEXT_COLOR%%",
    "%%THEME_BORDER%%",
    "%%THEME_BORDER_RADIUS%%",
    "%%THEME_FONT_SIZE%%",
    "%%THEME_FONT_WEIGHT%%",
    "%%THEME_HEIGHT%%",
    "%%THEME_WIDTH%%",
    "%%OPID%%",
    "%%SUBID%%",
    "%%SUB_SOURCE_ID%%",
    "%%ENCODED_SOURCE%%",
    "%%USER_IP%%",
    "%%UTM_MEDIUM%%",
    "%%CLID%%",
    "%%IS_MOBILE%%",
];
