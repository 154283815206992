import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { FEEDMANGER } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import {
    deliveryTypeDetails,
    extJobDetails,
    getJobSources,
} from "../../utils/feedManagerUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "publisherName",
        searchTerm: null,
        filterKey: FILTERS.FEED_MANAGER.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.FEED_MANAGER.filterOperator,
        filtersOperator: FILTERS.FEED_MANAGER.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "feedmanager"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchAdvertiserSources = createAsyncThunk(
    "listings/feedmanager/fetchadvertisersources",
    async () => {}
);

export const fetchPublisherSources = createAsyncThunk(
    "listings/feedmanager/fetchpublishersources",
    async (_, { getState, dispatch }) => {
        const { listings } = getState();
        const pagination = get(listings, "feedManager.pagination", {});
        const params = paginationParams(pagination);
        let tagFilters = get(
            listings,
            "feedManager.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.FEED_MANAGER
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        let dispatchSearchError = true;
        const response = await customFetch(
            FEEDMANGER.FETCH_FEED_MANAGER_DATA,
            "POST",
            filterParamsData,
            null,
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch publisher sources",
                    })
                );
                dispatch(
                    feedManagerSlice.actions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );
        const data = get(response, "data", []);

        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.FEED_MANAGER}`;
            dispatch(feedManagerSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData}, Create a new Feed`;
            dispatch(feedManagerSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const deletePublisherFeed = createAsyncThunk(
    "dashboards/feedmanger/deletepublisherfeed",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${FEEDMANGER.DELETE_PUBLISHER_FEED}/${id}`,
            "DELETE",
            {}
        );
        if (response.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Delete Publisher Feed",
                })
            );
            return null;
        }
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/feedManager/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.feedManager.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.FEED_MANAGER.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const feedManagerSlice = createSlice({
    name: "feedManager",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchPublisherSources.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchPublisherSources.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            if (action.payload) {
                state.list = action.payload.data?.map(feed => ({
                    id: feed.id,
                    lastXMLGenerationDate: feed.lastXMLGenerationDate,
                    createdAt: feed.createdAt,
                    publisherName: feed.publisherName,
                    publisherCode: feed.publisherCode,
                    jobSources: getJobSources(feed),
                    deliveryTypes: feed.deliveryTypes.join(", ").toUpperCase(),
                    externalJobDetails: extJobDetails(
                        feed.sources.filter(
                            item => item.advertiserSource != null
                        )
                    ),
                    deliveryTypeDetails: deliveryTypeDetails(feed),
                }));
            }
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const feedManagerActions = feedManagerSlice.actions;
export default feedManagerSlice.reducer;
