import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import {
    REPORTING,
    OFFLINE_CONVERSION,
    HNP_URL,
    CONFIGURATIONS,
} from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { get, isEmpty } from "lodash";
import {
    mapAdvertiser,
    mapOfflineConversion,
} from "../../utils/offlineConversionUtils";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import { FILTERS } from "../../constants/listingSearch";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialDefaultDateRange = {
    startDate: startOfDay(subDays(new Date(), 60)),
    endDate: new Date(),
    key: "selection",
};

const initialState = {
    list: [],
    advertisersList: [],
    productList: [],
    campaignList: [],
    // isAppend: false,
    selectedAdvertiserId: null,
    selectedAdvertiserName: null,
    selectedDate: new Date(),
    campaign: null,
    product: null,
    timeZone: null,
    lookBack: null,
    updatedAt: new Date(),
    loading: false,
    advLoader: false,
    mainLoader: false,
    isValid: false,
    uploadLoader: false,
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "date",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.OFFLINE_CONVERSION.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.OFFLINE_CONVERSION.filterOperator,
        filtersOperator: FILTERS.OFFLINE_CONVERSION.operator,
        date: initialDefaultDateRange,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "offlineconversion"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchOfflineConversionData = createAsyncThunk(
    "offlineConversion/fetchOfflineConversion",
    async (_, { getState, dispatch }) => {
        const pagination = get(
            getState(),
            "listings.offlineConversion.pagination",
            {}
        );
        const params = paginationParams(pagination);
        const { listings } = getState();
        let tagFilters = get(
            listings,
            "offlineConversion.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.OFFLINE_CONVERSION
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "date",
            "sortby",
            "orderby",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        let dispatchSearchError = true;

        const response = await customFetch(
            OFFLINE_CONVERSION.FETCH_OFFLINE_CONVERSION_DATA,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch offline conversion data",
                    })
                );
                dispatch(
                    offlineConversionSlice.actions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );
        const data = get(response, "data", []);
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.OFFLINE_CONVERSION}`;
            dispatch(
                offlineConversionSlice.actions.setTableError(errorMessage)
            );
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.offlineConversionNoData}`;
            dispatch(
                offlineConversionSlice.actions.setTableError(errorMessage)
            );
        }
        return data;
    }
);

export const fetchAllAdvertisers = createAsyncThunk(
    "offlineConversion/fetchAllAdvertisers",
    async (_, { dispatch }) => {
        const response = await customFetch(
            OFFLINE_CONVERSION.FETCH_ALL_ADVERTISERS,
            "POST"
        );
        if (response?.status >= 400 || !response) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Advertisers",
                })
            );
        }
        const data = get(response, "data.data", []);
        return data;
    }
);

// export const fetchCampaign = createAsyncThunk(
//     "offlineConversion/fetchCampaign",
//     async (advertiserId, { getState, dispatch }) => {
//         const advertiser = get(
//             getState(),
//             "listings.offlineConversion.selectedAdvertiserId",
//             {}
//         );
//         var campaignURL = `${OFFLINE_CONVERSION.FETCH_CAMPAIGN}=${advertiserId}`;
//         const params = {
//             url: campaignURL,
//             options: {
//                 headers: {
//                     "Content-type": "application/json",
//                 },
//                 method: "GET",
//             },
//         };
//         const response = advertiser
//             ? await customFetch(HNP_URL.MAX_PROXY, "POST", params)
//             : null;
//         const data = get(response, "data.data", []);
//         return data;
//     }
// );

// export const fetchProduct = createAsyncThunk(
//     "offlineConversion/fetchProduct",
//     async (_, { dispatch }) => {
//         const response = await customFetch(CONFIGURATIONS.GET_DOMAINS, "GET");
//         if (response?.status >= 400 || !response) {
//             dispatch(
//                 toastListActions.setToastList({
//                     type: "Error",
//                     message: "Failed to fetch Product",
//                 })
//             );
//         }
//         const data = get(response, "data", []);
//         return data;
//     }
// );

export const uploadOfflineConversionData = createAsyncThunk(
    "offlineConversion/uploadOfflineConversionData",
    async (uploadData, { getState, dispatch }) => {
        const headers = {
            "content-type": "multipart/form-data",
        };
        let formData = new FormData();
        formData.append("report", uploadData.report);
        formData.append("advid", uploadData.advid);
        formData.append("advnm", uploadData.advnm);
        formData.append("date", uploadData.date);
        formData.append("tz", uploadData.tz);
        // formData.append("reset", uploadData.reset); //for now commenting as backend does not require the flag
        formData.append("created_by", uploadData.created_by);
        // formData.append("cmpid", uploadData.cmpid);
        // formData.append("domain", uploadData.domain);
        let errorData = "";
        const response = await customFetch(
            OFFLINE_CONVERSION.UPLOAD_OFFLINE_CONVERSION_DATA,
            "POST",
            formData,
            headers,
            {},
            null,
            null,
            e => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message:
                            e?.response?.data?.error?.message ||
                            "Failed to upload conversion data.",
                    })
                );
            }
        );

        if (get(response, "status", "") === 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `File successfully uploaded & sent for review`,
                })
            );
            dispatch(fetchOfflineConversionData());
        } else {
            if (get(response, "status", "") !== 422) {
                let errorMessage = get(response, "error", []);
                !isEmpty(errorMessage) && errorMessage[0]
                    ? (errorMessage = errorMessage[0])
                    : (errorMessage = "Failed to upload conversion data.");
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: errorMessage,
                    })
                );
            }
        }
        return response;
    }
);

export const setValidState = createAsyncThunk(
    "offlineConversion/setValidState",
    async (_, {}) => {
        return;
    }
);

export const fetchCSVdetails = createAsyncThunk(
    "offlineConversion/fetchCSVdetails",
    async (item, { getState, dispatch }) => {
        const { advertiser, date, timezone } = item;
        const response = await customFetch(
            `${OFFLINE_CONVERSION.GET_CSV_DOWNLOAD_DATA}/?advid=${advertiser}&date=${date}&tz=${timezone}`,
            "GET"
        );
        const data = get(response, "data", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to download the conversion data",
                    autoClose: 5000,
                })
            );
        return data;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/offlineConversion/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.offlineConversion.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.OFFLINE_CONVERSION.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const offlineConversionSlice = createSlice({
    name: "offlineConversion",
    initialState,
    reducers: {
        setIsAppend(state, action) {
            state.isAppend = action.payload;
        },
        setSelectedAdvertiserId(state, action) {
            state.selectedAdvertiserId = action.payload;
        },
        setSelectedAdvertiserName(state, action) {
            state.selectedAdvertiserName = action.payload;
        },
        setCampaign(state, action) {
            state.campaign = action.payload;
        },
        setProduct(state, action) {
            state.product = action.payload;
        },
        setDateRange(state, action) {
            state.pagination.date = action.payload;
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        setTimeZone(state, action) {
            state.timeZone = action.payload;
        },
        setLookBack(state, action) {
            state.lookBack = action.payload;
        },
        setIsValid(state, action) {
            state.isValid = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setCampaignList(state, action) {
            state.campaignList = action.payload;
        },
        resetState(state) {
            // state.isAppend = false;
            state.selectedAdvertiserId = null;
            state.selectedAdvertiserName = null;
            state.selectedDate = new Date();
            state.campaign = null;
            state.product = null;
            state.timeZone = null;
            state.lookBack = null;
            state.pagination.date = initialDefaultDateRange;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchOfflineConversionData.pending, state => {
            state.loading = true;
        });
        builder.addCase(
            fetchOfflineConversionData.fulfilled,
            (state, action) => {
                state.loading = false;
                state.pagination.totalRowsCount = get(
                    action.payload,
                    "metaData.totalFound",
                    ""
                );
                state.list = action.payload
                    ? mapOfflineConversion(get(action.payload, "data", []))
                    : [];
                state.updatedAt = new Date();
            }
        );
        builder.addCase(fetchOfflineConversionData.rejected, state => {
            state.loading = false;
        });
        builder.addCase(fetchAllAdvertisers.pending, state => {
            state.loading = true;
            state.advLoader = true;
        });
        builder.addCase(fetchAllAdvertisers.fulfilled, (state, action) => {
            state.loading = false;
            state.advLoader = false;
            state.advertisersList = mapAdvertiser(action.payload);
        });
        builder.addCase(fetchAllAdvertisers.rejected, state => {
            state.advLoader = false;
            state.loading = false;
        });
        // builder.addCase(fetchProduct.pending, state => {
        //     state.loading = true;
        // });
        // builder.addCase(fetchProduct.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.productList = action.payload;
        // });
        // builder.addCase(fetchProduct.rejected, state => {
        //     state.loading = false;
        // });
        // builder.addCase(fetchCampaign.fulfilled, (state, action) => {
        //     state.campaignList = action.payload;
        // });
        builder.addCase(uploadOfflineConversionData.pending, state => {
            state.mainLoader = true;
            state.uploadLoader = true;
        });
        builder.addCase(uploadOfflineConversionData.fulfilled, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
        });
        builder.addCase(uploadOfflineConversionData.rejected, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
        });
        builder.addCase(setValidState.fulfilled, (state, action) => {
            state.isValid = action.payload;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const offlineConversionActions = offlineConversionSlice.actions;
export default offlineConversionSlice.reducer;
