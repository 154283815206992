export const ZIPCODE = [
    { zipcode: 40005 },
    { zipcode: 12345 },
    { zipcode: 32087 },
];

export const OFFLINE_CONVERSION = [
    { clid: "644b786f8dc49a223612be636dca6a1c", cpa: 1 },
    { clid: "44b289f8dc49a636dca6a1c6223999be", cpa: 0.46 },
];

export const DEFAULT_TEMPLATE = [
    { zipcode: 40005 },
    { zipcode: 12345 },
    { zipcode: 32087 },
];

export const BULK_SCRUB_ADVERTISER = [
    {
        advertiser_id: 3679,
        campagin_id: 136919,
        scrub_date: 2022 - 11 - 28,
        adv_rev: 1.4,
        discount_factor_in_percent: 10,
        scrub_type: "DailyUpload",
    },
];

export const BULK_SCRUB_PUBLISHER = [
    {
        publisher_id: 115,
        campagin_id: 1234,
        scrub_date: 2022 - 11 - 28,
        pub_cost: 1.4,
        discount_factor_in_percent: 20,
        scrub_type: "MonthlyAdjustment",
    },
];
