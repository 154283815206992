import { get } from "lodash";
import { getRandomId } from "./globalUtils";
import * as moment from "moment";

export const getJobSources = feed => {
    let sources = [];
    const findCrawled = feed.sources.find(
        element => element.sourceType == "crawled"
    );
    const findStatic = feed.sources.find(
        element => element.sourceType == "static"
    );
    const findExternal = feed.sources.find(
        element => element.sourceType == "external"
    );
    if (findCrawled != null) sources.push("Crawled");
    if (findStatic != null) sources.push("Static");
    if (findExternal != null) sources.push("External");
    return sources.join(", ");
};

export const extJobDetails = extSources => {
    return extSources?.map(source => ({
        name: source.advertiserSource.name,
        minCpc: source.cpcFloor,
        maxCpc: source.cpcCeil,
    }));
};

export const deliveryTypeDetails = feed => {
    return {
        apiKey: feed?.publisherKey?.key,
        publisherId: feed?.publisherCode,
        feedId: feed?.id,
        apiUrl: get(feed, "apiUrl", null),
        xmlFeedUrl: get(feed, "feedUrl", null),
    };
};

export const getJobStatus = createdDate => {
    const today = moment();
    const yesterday = moment().subtract(1, "day");
    const tommorrow = moment().add(1, "day");
    const time = `7:00 PM EST`;
    if (moment(createdDate).isSame(yesterday, "day")) {
        return `available on ${today.format("MMMM Do YYYY")}, ${time}`;
    }
    if (moment(createdDate).isSame(today, "day")) {
        return `available on ${tommorrow.format("MMMM Do YYYY")}, ${time}`;
    }
    return "ready";
};

export const selectedJobSourceOptions = (feed, jobOptions) => {
    let selectedOptions = [];
    if (feed.sourceCrawled) {
        const option = jobOptions.find(option => option.key === "crawled");
        selectedOptions.push(option);
    }
    if (feed.sourceStatic) {
        const option = jobOptions.find(option => option.key === "static");
        selectedOptions.push({ ...option, toggleValue: feed.geoReplication });
    }
    if (feed.externalSources && feed.externalSources.length) {
        const externalOption = jobOptions.filter(op => op.key == "external");
        const externalSources = feed.externalSources.map(es => {
            const obj = jobOptions.find(
                option => option.id == es.advertiserSource.id
            );
            return {
                ...obj,
                inputValue: es.cpcFloor || "",
                inputValue2: es.cpcCeil || "",
            };
        });
        selectedOptions = [
            ...selectedOptions,
            ...externalOption,
            ...externalSources,
        ];
    }
    const updatedJobOptions = jobOptions.map(op => {
        const findOption = selectedOptions.find(
            selectedOp => selectedOp.key == op.key
        );
        return findOption ? findOption : op;
    });
    const selectedKeys = selectedOptions.map(op => op.key);
    return {
        updatedJobOptions: updatedJobOptions,
        selectedOptionKeys: selectedKeys,
    };
};

export const publisherFeedParams = obj => {
    return {
        publisherName: obj.publisherName,
        publisherCode: obj.publisherCode,
        deliveryTypes: obj.deliveryTypes,
        everflowUrl: obj.everflowUrl ? obj.everflowUrl : null,
        type: obj.feedType,
        useBid: obj.bidToggle,
        sources: mapCreateSources(obj.jobSourcesTableData),
        redirectionUrl: obj.reDirectURL ? obj.reDirectURL : null,
    };
};

const mapCreateSources = array => {
    let updatedArr = [];
    array.forEach(ele => {
        if (ele.data) {
            ele.data.forEach(d => updatedArr.push({ name: ele.name, ...d }));
        } else {
            updatedArr.push(ele);
        }
    });
    const sources = updatedArr.map(element => {
        let obj = {
            sourceType: element.name,
            bidType: element.bidType,
            bidValue: element.bidValue ? +element.bidValue : null,
        };

        if (element.category) {
            obj.category = element.category;
        }

        if (element.name == "static") {
            obj.geoReplication = element.geoReplication;
        }

        if (element.parent == "external") {
            obj.sourceType = "external";
            obj.cpcFloor = element.minCPC == "" ? null : Number(element.minCPC);
            obj.cpcCeil = element.maxCPC == "" ? null : Number(element.maxCPC);
            obj.advertiserSourceId = element.advertiserSourceId || element.id;
        }
        return obj;
    });
    return sources;
};

export const mapPlotSources = (array, dropDown) => {
    let sources = [];
    let staticData = [];
    let dynamicData = [];
    array.forEach(element => {
        if (element.sourceType == "crawled") {
            sources.push({
                name: element.sourceType,
                geoReplication: element.geoReplication,
                bidType: element.bidType == null ? "static" : element.bidType,
                bidValue: element.bidValue == null ? "" : element.bidValue,
            });
        } else if (element.sourceType == "static") {
            staticData.push({
                id: getRandomId(),
                geoReplication: element.geoReplication,
                bidType: element.bidType == null ? "static" : element.bidType,
                bidValue: element.bidValue == null ? "" : element.bidValue,
                category: element.category,
            });
        } else {
            dynamicData.push({
                id: element.id,
                advertiserSourceId: element.advertiserSourceId,
                name: element?.advertiserSource?.name,
                geoReplication: element.geoReplication,
                bidType: element.bidType == null ? "static" : element.bidType,
                bidValue: element.bidValue == null ? "" : element.bidValue,
                minCPC: element.cpcFloor == null ? "" : element.cpcFloor,
                maxCPC: element.cpcCeil == null ? "" : element.cpcCeil,
                category: element.category,
                parent: "external",
            });
        }
    });

    staticData = staticData.sort(_ => (_.category === "_default" ? -1 : 1));
    dynamicData = dynamicData.sort(_ => (_.category === "_default" ? -1 : 1));

    const dynamicArray = [];
    dynamicData.forEach(_ => {
        const temp = dynamicArray.find(obj => obj.name === _.name);
        if (temp) {
            temp.data.push(_);
        } else {
            dynamicArray.push({ name: _.name, data: [_] });
        }
    });

    sources = [...sources, ...dynamicArray];

    if (staticData.length) {
        sources = [...sources, { name: "static", data: staticData }];
    }

    let dropDownOptions = [];

    dropDown.forEach(element => {
        dropDownOptions.push(element.key);
    });

    let sortedTable = sortJobSourcesData(sources, dropDownOptions);

    let getKeys = [];
    sortedTable.forEach(item => {
        getKeys.push(item.name);
        item?.data?.forEach(element => {
            if (!getKeys.includes(element.parent)) getKeys.push(element.parent);
        });
    });
    return [sortedTable, getKeys];
};

export const sortJobSourcesData = (
    jobSourcesTableDataCopy,
    dropDownOptions
) => {
    let jobSourcesTableDataCopyRearranged = [];

    let newIndex = jobSourcesTableDataCopy.map((item, id) => {
        return dropDownOptions?.indexOf(item.name);
    });

    newIndex.sort(function (a, b) {
        return a - b;
    });

    newIndex.forEach(item => {
        let obj = jobSourcesTableDataCopy.find(
            element => element.name == dropDownOptions[item]
        );
        jobSourcesTableDataCopyRearranged.push(obj);
    });

    return jobSourcesTableDataCopyRearranged;
};

const jobSourceParams = (options, jobOptions) => {
    const obj = {};
    const externalSources = [];
    options.forEach(option => {
        const findOption = jobOptions.find(op => op.key == option);
        if (findOption.key === "crawled") {
            return (obj["sourceCrawled"] = true);
        }
        if (findOption.key === "static") {
            obj["sourceStatic"] = true;
            obj["geoReplication"] = findOption.toggleValue;
            return;
        }
        if (findOption.isChild) {
            externalSources.push({
                advertiserSourceId: findOption.id,
                cpcFloor: findOption.inputValue || null,
                cpcCeil: findOption.inputValue2 || null,
            });
        }
    });
    if (!obj.hasOwnProperty("sourceCrawled")) obj["sourceCrawled"] = false;
    if (!obj.hasOwnProperty("sourceStatic")) {
        obj["sourceStatic"] = false;
        obj["geoReplication"] = false;
    }
    return {
        ...obj,
        externalSources: externalSources.length ? externalSources : [],
    };
};

export const deliveryTypeOptions = [
    { id: 1, text: "API", key: "api" },
    { id: 2, text: "XML Feed", key: "xml_feed" },
];

export const jobSourceOptions = [
    { id: getRandomId(), text: "Crawled", key: "crawled" },
    {
        id: getRandomId(),
        text: "Static",
        key: "static",
        isExtra: true,
        label: "Geo Replication: ",
        isToggle: true,
        toggleValue: false,
    },
    {
        id: getRandomId(),
        text: "External",
        key: "external",
        isParent: true,
    },
];

export const jobSourcesExtras = {
    isExtra: true,
    isChild: true,
    parent: "external",
    label: "Min CPC: $",
    isInput: true,
    inputPlaceholder: "0",
    inputValue: "",
    label2: "Max CPC: $",
    isInput2: true,
    inputValue2: "",
    inputPlaceholder2: "0",
};

export const feedTypeOptions = [
    { id: 1, text: "Normal", key: "normal" },
    { id: 2, text: "Arbitrage", key: "arbitrage" },
];
