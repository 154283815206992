import _, { get, isEmpty } from "lodash";
import {
    getFilterValue,
    getFilterOperator,
    getDateBetweenArray,
    getScrubFilterValue,
} from "./filterUtils";

export const paginationParams = pagination => {
    if (!pagination || isEmpty(pagination)) return null;
    const params = {
        offset: pagination.startOffset,
        limit: pagination.rowsCount,
    };
    if (get(pagination, "sortBy", "") !== "") {
        params["sortby"] = get(pagination, "sortBy", "");
        if (get(pagination, "isDesc", false)) params["orderby"] = "desc";
    }
    if (get(pagination, "date", "") !== "") {
        let filters = [];
        let value = getDateBetweenArray(get(pagination, "date", ""));
        let pageName = window.location.pathname.split("/")[2];
        let result = {
            k: pageName === "scrubdashboard" ? "scrub_date" : "date",
            v: value,
            op: "between",
        };
        filters.push(result);
        //Special filter case for User journey details page
        if (get(pagination, "email", "") !== "") {
            let emailValue = get(pagination, "email", "");
            let result = {
                k: "email",
                v: emailValue,
                op: "=",
            };
            filters.push(result);
        }
        if (get(pagination, "domain", "") !== "") {
            let domainValue = get(pagination, "domain", "");
            let result = {
                k: "domain",
                v: domainValue,
                op: "=",
            };
            filters.push(result);
        }
        params["filters"] = filters;
    }
    if (
        (pagination.searchKey !== null || pagination.searchKey !== "") &&
        pagination.searchTerm &&
        pagination.searchTerm !== ""
    ) {
        params[pagination.searchKey] = pagination.searchTerm;
    }
    if (get(pagination, "prefix", "") !== "") {
        params["prefix"] = get(pagination, "prefix", "");
    }
    if (get(pagination, "entityType", "") !== "") {
        let filters = [];
        let value = get(pagination, "entityType", "");
        let result = {
            k: "entityType",
            v: value,
            op: "=",
        };
        filters.push(result);
        if (get(pagination, "isPrefixOperator", "") !== "") {
            let result = {
                k: "entityId",
                v: pagination?.type,
                op: "prefix",
            };
            filters.push(result);
        }
        params["filters"] = filters;
    }

    // Updated as filters can now be array of objects
    if (
        !_.isEmpty(pagination.filterKey) &&
        !_.isEmpty(pagination.filterOperator) &&
        pagination.filterTerm &&
        pagination.filterTerm.trim().length !== 0 &&
        pagination.filterTerm !== ""
    ) {
        let filters = [];
        pagination.filterKey.forEach((key, i) => {
            // let type = pagination?.type
            let value =
                key === "entityId"
                    ? getScrubFilterValue(
                          pagination.filterOperator[i],
                          pagination.filterTerm,
                          pagination?.type
                      )
                    : getFilterValue(
                          pagination.filterOperator[i],
                          pagination.filterTerm
                      );
            let filterOperator = getFilterOperator(
                pagination.filterOperator[i]
            );
            if (key === "date") {
                let value = getDateBetweenArray(get(pagination, "date", ""));
                let result = {
                    k: pagination.filterKey[i],
                    v: value,
                    op: filterOperator,
                };
                filters.push(result);
            }
            if (key === "entityType") {
                let value = get(pagination, "entityType", "");
                let result = {
                    k: "entityType",
                    v: value,
                    op: "=",
                };
                filters.push(result);
            }
            if (value && filterOperator !== ">=<" && key !== "entityType") {
                let result = {
                    k: pagination.filterKey[i],
                    v: value,
                    op: filterOperator,
                };
                filters.push(result);
            }
            //Special case for datastore filters
            if (value && filterOperator === ">=<") {
                let filter1 = {
                    k: pagination.filterKey[i],
                    v: value && value[0],
                    op: ">=",
                };
                filters.push(filter1);
                let filter2 = {
                    k: pagination.filterKey[i],
                    v: value && value[1],
                    op: "<",
                };
                filters.push(filter2);
            }
        });
        params["filters"] = filters;
        // params["filters_op"] =
        //     (pagination && pagination.filtersOperator) || "AND";
    }
    params["sortby"] = (pagination && pagination.sortBy) || "createdAt";
    params["orderby"] = pagination && pagination.isDesc ? "desc" : "asc";
    params["filters_op"] = (pagination && pagination.filtersOperator) || "AND";
    params["byPassSizeCheck"] =
        (pagination && pagination.byPassSizeCheck) || false;
    return params;
};

export const setPaginationStartOffset = (rowsCount, pageNumber) => {
    if (rowsCount === -1) return 0;
    else return rowsCount * pageNumber - rowsCount;
};

export const setTotalPages = (totalCount, rowsCount) => {
    if (rowsCount === -1) return 1;
    else return Math.ceil(totalCount / rowsCount);
};

export const paginationActions = (actions, action, param, dispatch) => {
    switch (action) {
        case "setSortBy":
            dispatch(actions.setSortBy(param));
            break;
        case "setIsDesc":
            dispatch(actions.setIsDesc(param));
            break;
        case "setPageNumber":
            dispatch(actions.setPageNumber(param));
            break;
        case "setRowsCount":
            dispatch(actions.setRowsCount(param));
            break;
    }
};
