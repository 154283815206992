import React, { forwardRef, useEffect, useRef, useState } from "react";
import Input from "../Input/Input";
import c from "./Select.module.scss";
import { FixedSizeList as List } from "react-window";
import Fuse from "fuse.js";
import Icon from "../Icon/Icon";
import ReactDOM from "react-dom";
import Checkbox, { CheckboxIcon } from "../../Shared/Checkbox/Checkbox";
import { get, isEmpty, isEqual } from "lodash";
import { usePrevious } from "../hooks/use-previous";
import OutsideClickHandler from "react-outside-click-handler";
import StatusRenderer from "../../components/Renderers/StatusRenderer/StatusRenderer";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import Tooltip from "../Tooltip/Tooltip";

const Select = forwardRef(
    (
        {
            label,
            unstyledLabel = false,
            placeholder = "Search Select",
            selectedId = null,
            options,
            idField,
            keyField = null,
            showSearch,
            onSelect,
            position = null,
            className,
            classNameWhenOpen,
            isDisabled = false,
            isError = false,
            onLoadSelectReturn = true,
            showCreate = false,
            onCreate,
            createOption,
            portal = true,
            multiSelect = false,
            selectedIds,
            isSelectOpen = false,
            showOptionWithKeyValue = false,
            CustomRender,
            onSelectClose,
            required = false,
            hideLabel = false,
            showSelectAll = false,
            hideOutline = false,
            CustomHeader,
            fixed = false,
            autoFocus = true,
            onSelectOpen,
            operator = "&",
            defaultOptionHeight = 38,
            createLabel = "Create",
            onPopUpCreate,
            popUpValue,
            extraOutsideHandler = false,
            width,
            portalElement,
            customClientRect,
            overlayClassName,
            selectClassName,
            notooltip = false,
            closeIcon = false,
            loading = false,
            showOptionToolTip = true,
            showSelectedToolTip = false,
            maintainSelectOrder = false,
            disableInputTextTrim = false,
            onClickClose,
        },
        hiddenInputRef
    ) => {
        const getSelectedOption = () => {
            if (!options?.length) return;
            if (label && selectedId == null) return label;
            if (createOption) return createOption[keyField];
            if (selectedId)
                return keyField
                    ? (options.find(option => option[idField] === selectedId) ||
                          {})[keyField]
                    : options.find(option => option === selectedId);
            return keyField ? options[0][keyField] : options[0];
        };

        const selectedOption = multiSelect ? null : getSelectedOption();
        const [isOpen, setIsOpen] = useState(isSelectOpen);
        const [offset, setOffset] = useState({});
        const [filteredOptions, setFilteredOptions] = useState(options);
        const [selectedValue, setSelectedValue] = useState("");
        const [searchTerm, setSearchTerm] = useState("");
        const [displaySearch, setDisplaySearch] = useState();
        const [multiSelectedOptions, setMultiSelectedOptions] = useState([]);
        const fuseOptions = { keys: keyField ? [keyField] : [] };
        const fuse = new Fuse(filteredOptions, fuseOptions);
        const wrapperRef = useRef(null);
        const prevSelectedIds = usePrevious(selectedIds ? selectedIds : []);
        const [parentIcon, setParentIcon] = useState(CheckboxIcon.tick);

        const [activeIndex, setActiveIndex] = useState(-1);
        const dropdownRef = useRef(null);
        const inputSearchRef = useRef(null);
        const touched = useRef(false);
        const [isTouched, setIsTouched] = useState(false);
        hiddenInputRef = hiddenInputRef || useRef(null);

        const detectDropdownPosition = () => {
            const offsets =
                customClientRect ||
                wrapperRef.current.getBoundingClientRect().top;
            const getInnerHeight = window.innerHeight;
            // If position is absent and the dropdown is the last element and we dont want to open it below
            if (position == null && getInnerHeight - offsets <= 150) {
                position = "top";
            }
        };

        useEffect(() => {
            extraOutsideHandler &&
                document
                    .querySelector("body")
                    .addEventListener("click", handleOutsideClick, {
                        capture: true,
                    });

            return () => {
                extraOutsideHandler &&
                    document
                        .querySelector("body")
                        .removeEventListener("click", handleOutsideClick, {
                            capture: true,
                        });
            };
        }, []);

        useEffect(() => {
            if (!isOpen) {
                if (options?.length) setFilteredOptions(options);
            }
        }, [options]);

        useEffect(() => {
            setDisplaySearch(
                showSearch != null ? showSearch : options?.length > 5
            );
            if (selectedOption) setSelectedValue(selectedOption);
            setFilteredOptions(options);
            if (isSelectOpen) {
                setIsOpen(isSelectOpen);
                detectDropdownPosition();
                setDropdownPostion();
            }
            if (
                typeof onSelect === "function" &&
                selectedOption &&
                onLoadSelectReturn
            ) {
                const option = keyField
                    ? options.find(
                          option => option[keyField] === selectedOption
                      )
                    : selectedOption;
                onSelect(option);
            }
        }, [selectedOption]);

        useEffect(() => {}, [isSelectOpen]);

        useEffect(() => {
            if (
                selectedIds &&
                (!isEqual(prevSelectedIds, selectedIds) ||
                    !multiSelectedOptions?.length)
            ) {
                let multiOptions = [];
                if (maintainSelectOrder) {
                    multiOptions =
                        selectedIds
                            ?.map(id => options?.find(_ => _[idField] === id))
                            ?.filter(_ => _) || [];
                } else {
                    multiOptions = options?.filter(option =>
                        selectedIds?.includes(option[idField])
                    );
                }

                label
                    ? setSelectedValue(label)
                    : setMultiSelectedValue(multiOptions);
                // if (typeof onSelect === "function") onSelect(multiOptions);
                setMultiSelectedOptions(multiOptions);
            }
        }, [selectedIds, loading]);

        useEffect(() => {
            if (!isOpen && touched.current) {
                setIsTouched(true);
            } else {
                touched.current = true;
            }
            window.addEventListener("keydown", onWindowKeyDown);
            return () => window.removeEventListener("keydown", onWindowKeyDown);
        }, [isOpen]);

        useEffect(() => {
            if (isOpen && options?.length && multiSelect) {
                if (Array.isArray(selectedIds)) {
                    let multiOptions = [];
                    const multiSelectedOptionIds = multiSelectedOptions.map(
                        _ => _.id
                    );
                    if (maintainSelectOrder) {
                        new Set([
                            ...selectedIds,
                            ...multiSelectedOptionIds,
                        ]).forEach(id => {
                            multiOptions.push(
                                options?.find(_ => _[idField] === id)
                            );
                        });
                    } else {
                        multiOptions = options?.filter(
                            option =>
                                selectedIds?.includes(option[idField]) ||
                                multiSelectedOptionIds?.includes(
                                    option[idField]
                                )
                        );
                    }
                    const array = selectedOptionOnTop(multiOptions);
                    setFilteredOptions([...array]);
                }
            }
        }, [isOpen]);

        useEffect(() => {
            if (displaySearch && isOpen && autoFocus && !isEmpty(options)) {
                inputSearchRef.current.focus();
            }
        }, [displaySearch, isOpen]);

        useEffect(() => {
            if (dropdownRef.current) {
                dropdownRef.current.scrollToItem(activeIndex);
            }
        }, [activeIndex]);

        const selectedOptionOnTop = multiSelectedOptions => {
            const indexMap = new Map();
            multiSelectedOptions?.forEach((element, index) => {
                if (element && element[idField]) {
                    indexMap.set(element[idField], index);
                }
            });
            let optionsCopy = [...options];
            return optionsCopy?.sort((obj1, obj2) => {
                const index1 = indexMap.get(obj1[idField]);
                const index2 = indexMap.get(obj2[idField]);
                if (index1 !== undefined && index2 !== undefined) {
                    return index1 - index2; // Maintain order based on options indices
                } else if (index1 !== undefined) {
                    return -1; // obj1 should come before obj2
                } else if (index2 !== undefined) {
                    return 1; // obj2 should come before obj1
                } else {
                    return 0; // Maintain original order for elements not present in multiSelectedOptions
                }
            });
        };

        const onWindowKeyDown = e => {
            if (isOpen && ["ArrowUp", "ArrowDown"].indexOf(e.code) > -1) {
                e.preventDefault();
            }
        };

        const handleKeyDown = event => {
            if (event.keyCode == 27) {
                handleDropdown(false);
                hiddenInputRef.current.blur();
            }
            if (event.code === "Tab") {
                handleOutsideClick();
            }
            if (event.keyCode == 13) {
                multiSelect
                    ? onClickMultiOptionItem(
                          filteredOptions[activeIndex],
                          activeIndex
                      )
                    : onClickOptionItem(filteredOptions[activeIndex]);
            }

            if (event.keyCode == 38 || event.keyCode == 40) {
                let strActiveIndex = activeIndex;
                if (event.keyCode == 38) {
                    if (strActiveIndex == 0 || strActiveIndex == -1) {
                        strActiveIndex = filteredOptions?.length - 1;
                    } else if (strActiveIndex > 0) {
                        strActiveIndex = activeIndex - 1;
                    }
                } else {
                    if (strActiveIndex >= filteredOptions?.length - 1) {
                        strActiveIndex = 0;
                    } else {
                        strActiveIndex = activeIndex + 1;
                    }
                }
                setActiveIndex(strActiveIndex);
            }
        };

        const handleMouseEnter = index => {
            setActiveIndex(index);
        };

        const handleDropdown = value => {
            if (!value) {
                setFilteredOptions(options);
                setSearchTerm("");
                setActiveIndex(-1);
            }
            if (value && typeof onSelectOpen === "function") {
                onSelectOpen();
            }
            setIsOpen(value);
        };

        const onClickSelect = event => {
            event.stopPropagation();
            handleDropdown(!isOpen);
            detectDropdownPosition();
            setDropdownPostion();
            if (autoFocus) {
                hiddenInputRef.current.focus();
            }
        };

        function getTranslateX(myElement) {
            if (!myElement) {
                return {
                    x: 1,
                    y: 1,
                    scale: 1,
                };
            }
            var style = window.getComputedStyle(myElement);
            const matrix = new WebKitCSSMatrix(style.transform);
            return {
                left: matrix.m41,
                top: matrix.m42,
                scale: matrix.m11,
            };
        }

        const setDropdownPostion = () => {
            const rect =
                customClientRect || wrapperRef.current.getBoundingClientRect();
            let dropdownHeight =
                (filteredOptions?.length > 4
                    ? 190
                    : 38 * filteredOptions?.length) + 5;
            setOffset({
                top: !portal
                    ? position === "top"
                        ? -dropdownHeight
                        : 50
                    : rect.top +
                      window.scrollY +
                      (position === "top" ? -dropdownHeight : rect.height + 5),
                left: !portal ? 0 : rect.left,
                width: width || rect.width,
            });
        };

        const handleInputValue = event => {
            const inputValue = event.target.value;
            if (inputValue !== searchTerm) {
                setDropdownPostion();
                setSearchTerm(inputValue);
                handleSearch(inputValue);
            }
        };

        const handleSearch = searchTerm => {
            if (searchTerm === "") return setFilteredOptions(options);
            const results = fuse.search(searchTerm);
            if (!isEmpty(results))
                setFilteredOptions(results.map(option => option.item));
            else setFilteredOptions([]);
            dropdownRef?.current?.scrollToItem(0);
        };

        const onClickSearchInput = event => {
            event.stopPropagation();
        };

        const onClickOptionItem = option => {
            if (option) {
                setSelectedValue(keyField ? option[keyField] : option);
                if (typeof onSelect === "function") {
                    setTimeout(
                        () =>
                            autoFocus &&
                            hiddenInputRef &&
                            hiddenInputRef.current &&
                            hiddenInputRef.current.focus()
                    );
                    onSelect(option);
                }
                handleDropdown(false);
            }
        };

        const setMultiSelectedValue = values => {
            if (!values.length) return setSelectedValue("");
            if (values.length === filteredOptions?.length && values.length < 2)
                return setSelectedValue("All Selected");
            if (values.length === 1)
                return setSelectedValue(values[0][keyField]);
            // if (values.length === 2)
            //     return setSelectedValue(
            //         `${values[0][keyField]} ${operator} ${values[1][keyField]}`
            //     );
            // if (values.length > 2)
            //     return setSelectedValue(
            //         `${values[0][keyField]} ${operator} ${
            //             values.length - 1
            //         } Selected`
            //     );
            if (values.length > 1)
                return setSelectedValue(`${values.length} Selected`);
        };

        const onClickMultiOptionItem = option => {
            const optionPresent = optionItemPresent(option);
            let selectedOptions = [];
            if (option?.isParent || option?.isChild) {
                selectedOptions = handleParentChild(option, optionPresent);
                setParentCheckboxIcon(selectedOptions);
            } else if (optionPresent) {
                selectedOptions = multiSelectedOptions.filter(
                    optionItem => optionItem[idField] != option[idField]
                );
            } else {
                selectedOptions = [...multiSelectedOptions, option];
            }
            handleMultiSelectedOption(selectedOptions);
            hiddenInputRef.current.focus();
        };

        const optionItemPresent = option => {
            return multiSelectedOptions.some(
                optionItem => optionItem[idField] == option[idField]
            );
        };

        const handleMultiSelectedOption = selectedOptions => {
            if (typeof onSelect === "function") {
                if (onSelect(selectedOptions) === "Error") {
                    const selectedValue = selectedOptions.filter(ele =>
                        selectedIds.includes(ele[idField])
                    );
                    setMultiSelectedOptions(selectedValue);
                    setMultiSelectedValue(selectedValue);
                } else {
                    setMultiSelectedOptions(selectedOptions);
                    setMultiSelectedValue(selectedOptions);
                }
            }
        };

        const handleParentChild = (option, optionPresent) => {
            let selectedOptions = [];
            if (optionPresent) {
                if (option?.isChild) {
                    selectedOptions = multiSelectedOptions.filter(
                        optionItem => optionItem[idField] != option[idField]
                    );
                    const findChildOption = selectedOptions.find(
                        op => op.isChild
                    );
                    if (!findChildOption) {
                        selectedOptions = selectedOptions.filter(
                            op => op.key != option.parent
                        );
                    }
                    return selectedOptions;
                }
                if (option?.isParent) {
                    selectedOptions = multiSelectedOptions.filter(
                        op => !op.isParent && !op.isChild
                    );
                    return selectedOptions;
                }
            } else {
                if (option?.isChild) {
                    const parentOption = filteredOptions.find(
                        op => op.key == option.parent
                    );
                    if (!optionItemPresent(parentOption)) {
                        selectedOptions = [
                            ...multiSelectedOptions,
                            parentOption,
                            option,
                        ];
                    } else {
                        selectedOptions = [...multiSelectedOptions, option];
                    }
                    return selectedOptions;
                }
                if (option?.isParent) {
                    const parentChildOptions = filteredOptions.filter(
                        op => op.isParent || op.isChild
                    );
                    return [...multiSelectedOptions, ...parentChildOptions];
                }
            }
            return multiSelectedOptions;
        };

        const setParentCheckboxIcon = selectedOptions => {
            const selectedKeys = selectedOptions.map(op => op.key);
            const allChildKeys = filteredOptions
                .filter(op => op.isChild)
                .map(op => op.key);
            const allChildPresent = allChildKeys.every(key =>
                selectedKeys.includes(key)
            );
            allChildPresent
                ? setParentIcon(CheckboxIcon.tick)
                : setParentIcon(CheckboxIcon.minus);
        };

        const onSelectAll = () => {
            let selectedOptions = [];
            if (isEqual(multiSelectedOptions, filteredOptions)) {
                selectedOptions = [];
            } else {
                selectedOptions = filteredOptions;
            }
            handleMultiSelectedOption(selectedOptions);
        };

        const MultiOptionItem = ({ index, style }) => {
            const item = get(filteredOptions, index, keyField ? {} : "");
            return (
                <div
                    className={`${c.multiOptionWrapper} ${
                        activeIndex == index ? c.active : ""
                    } ${CustomRender ? c.hasCustomRenderer : ""}`}
                    style={style}
                    tabIndex={index}
                    // onMouseEnter={() => handleMouseEnter(index)}
                >
                    {filteredOptions[index].isChild && (
                        <div
                            className={`${c.nestedLink} ${
                                filteredOptions[index].isFirstChild &&
                                c.firstChildLink
                            }`}
                        ></div>
                    )}
                    <Tooltip
                        message={`${
                            get(item, keyField, "")
                                ? get(item, keyField, "")
                                : ""
                        }`}
                        position="top"
                        wrap
                        hideTooltip={!showOptionToolTip}
                    >
                        <Checkbox
                            className={`form-rowParent ${c.list}`}
                            labelClassName={c.multiOptionList}
                            positionTopLeftAligned={true}
                            onChange={() =>
                                onClickMultiOptionItem(
                                    filteredOptions[index],
                                    index
                                )
                            }
                            labelText={
                                <div className="dp-parent-inline ellipsis">
                                    {get(item, "status", null) && (
                                        <StatusRenderer
                                            status={
                                                get(item, "status", null) ===
                                                "active"
                                            }
                                            activeContent={""}
                                            inactiveContent={""}
                                        />
                                    )}

                                    <div>
                                        {get(item, keyField, "") ||
                                            get(item, idField, "")}
                                    </div>
                                </div>
                            }
                            isChecked={() =>
                                optionItemPresent(filteredOptions[index])
                            }
                            icon={
                                filteredOptions[index].isParent
                                    ? parentIcon
                                    : CheckboxIcon.tick
                            }
                        ></Checkbox>
                    </Tooltip>
                </div>
            );
        };

        const OptionItem = ({ index, style }) => (
            <>
                {notooltip ? (
                    <div
                        style={style}
                        className={`${c.singleOptionList} ${
                            activeIndex == index ? c.active : ""
                        } ${
                            filteredOptions[index].isDisabledOption
                                ? c.disabledOption
                                : ""
                        } dp-parent-inline`}
                        onMouseDown={() =>
                            portal
                                ? onClickOptionItem(filteredOptions[index])
                                : null
                        }
                        onClick={() => {
                            !filteredOptions[index].isDisabledOption &&
                                onClickOptionItem(filteredOptions[index]);
                        }}
                        tabIndex={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                    >
                        {filteredOptions[index]["status"] && (
                            <StatusRenderer
                                status={
                                    filteredOptions[index]["status"] ===
                                    "active"
                                }
                                activeContent={""}
                                inactiveContent={""}
                            />
                        )}
                        {!CustomRender && (
                            <div className="ellipsis">
                                {keyField
                                    ? filteredOptions[index][keyField]
                                    : filteredOptions[index]}
                            </div>
                        )}
                        {CustomRender && (
                            <CustomRender
                                option={filteredOptions[index]}
                                index={index}
                            ></CustomRender>
                        )}
                    </div>
                ) : (
                    <div
                        style={style}
                        className={`${c.singleOptionList} ${
                            activeIndex == index ? c.active : ""
                        } ${
                            filteredOptions[index].isDisabledOption
                                ? c.disabledOption
                                : ""
                        } dp-parent-inline p-0`}
                        onMouseDown={() =>
                            portal
                                ? onClickOptionItem(filteredOptions[index])
                                : null
                        }
                        onClick={() => {
                            !filteredOptions[index].isDisabledOption &&
                                onClickOptionItem(filteredOptions[index]);
                        }}
                        tabIndex={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                    >
                        <Tooltip
                            message={
                                keyField
                                    ? filteredOptions[index][keyField]
                                    : filteredOptions[index][idField]
                            }
                            position="top"
                            wrap
                            customWrapperClass="w-100"
                            hideTooltip={!showOptionToolTip}
                        >
                            {filteredOptions[index]["status"] && (
                                <StatusRenderer
                                    status={
                                        filteredOptions[index]["status"] ===
                                        "active"
                                    }
                                    activeContent={""}
                                    inactiveContent={""}
                                />
                            )}
                            {!CustomRender && (
                                <div className="ellipsis p-10">
                                    {keyField
                                        ? filteredOptions[index][keyField]
                                        : filteredOptions[index]}
                                </div>
                            )}
                            {CustomRender && (
                                <div className="w-100 p-10">
                                    <CustomRender
                                        option={filteredOptions[index]}
                                        index={index}
                                    ></CustomRender>
                                </div>
                            )}
                        </Tooltip>
                    </div>
                )}
            </>
        );

        const handleCreateNewItem = () => {
            setSelectedValue(searchTerm);
            handleDropdown(false);
            if (typeof onCreate === "function") {
                if (keyField) {
                    const obj = { id: new Date().getTime() };
                    obj[keyField] = searchTerm;
                    return onCreate(obj);
                }
                return onCreate(searchTerm);
            }
        };

        const handleCreateNewItemPopUp = () => {
            setSelectedValue(searchTerm);
            handleDropdown(false);
            if (typeof onPopUpCreate === "function") {
                if (keyField) {
                    const obj = { id: new Date().getTime() };
                    obj[keyField] = searchTerm;
                    return onPopUpCreate(obj);
                }
                return onPopUpCreate(searchTerm);
            }
        };

        const handleOutsideClick = () => {
            if (isOpen) {
                setTimeout(() => handleDropdown(false));
            }
            if (typeof onSelectClose === "function") {
                onSelectClose();
            }
        };

        // use to remove memory leak cleanup error in console of targetting params add button select dropdown
        useEffect(() => {
            return () => {
                handleDropdown(false);
            };
        }, []);

        useEffect(() => {
            if (popUpValue?.name) setSelectedValue(popUpValue?.name);
        }, [popUpValue]);

        const toolTipDropDownValues = array => {
            let string = "";
            array.forEach((obj, idx) => {
                if (idx === 0) string = obj[keyField];
                else string = string + "," + "\n" + obj[keyField];
            });
            return string;
        };

        const dropdownContent = (
            <div className={overlayClassName}>
                <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                    <div
                        className={`${c.dropdown} ${fixed ? c.fixed : ""}`}
                        style={offset}
                    >
                        {CustomHeader && <CustomHeader />}
                        {displaySearch && (
                            <Input
                                className={c.inputSearch}
                                type="text"
                                value={searchTerm}
                                ref={inputSearchRef}
                                onClick={onClickSearchInput}
                                onChange={handleInputValue}
                                placeholder={placeholder}
                                onKeyDown={handleKeyDown}
                                disableTrim={disableInputTextTrim}
                            />
                        )}
                        {multiSelect && showSelectAll && (
                            <div className={`${c.multiOptionWrapper}`}>
                                <Checkbox
                                    className={`${c.list}`}
                                    labelClassName={c.multiOptionList}
                                    onChange={onSelectAll}
                                    labelText={"Select All"}
                                    isChecked={() =>
                                        filteredOptions?.length ===
                                        multiSelectedOptions?.length
                                    }
                                ></Checkbox>
                            </div>
                        )}
                        {!isEmpty(filteredOptions) ? (
                            <List
                                itemData={filteredOptions}
                                height={
                                    filteredOptions?.length > 4 ||
                                    filteredOptions?.length === 0
                                        ? 190
                                        : defaultOptionHeight *
                                          filteredOptions?.length
                                }
                                // height={190}
                                itemCount={filteredOptions?.length}
                                itemSize={defaultOptionHeight}
                                ref={dropdownRef}
                                className="dropdownwrapper"
                                children={
                                    multiSelect ? MultiOptionItem : OptionItem
                                }
                            ></List>
                        ) : (
                            <div
                                className={`noResults ${
                                    showCreate && isEmpty(options)
                                        ? "noData"
                                        : ""
                                }`}
                            >
                                No results found
                            </div>
                        )}
                        {showCreate &&
                            searchTerm &&
                            typeof onPopUpCreate !== "function" && (
                                <div
                                    className={c.createNewItem}
                                    onClick={handleCreateNewItem}
                                >
                                    + {createLabel} "{searchTerm}"
                                </div>
                            )}
                        {showCreate &&
                            isEmpty(options) &&
                            typeof onPopUpCreate === "function" && (
                                <div
                                    className={c.createNewItem}
                                    onClick={handleCreateNewItemPopUp}
                                >
                                    + {createLabel}
                                </div>
                            )}
                    </div>
                </OutsideClickHandler>
            </div>
        );
        return (
            <div
                className={`${c.select} ${isDisabled ? c.disabled : ""} ${
                    isTouched ? c.touched : null
                } ${hideLabel ? c.hideLabel : ""} ${
                    hideOutline ? c.hideOutline : ""
                } ${selectClassName ? selectClassName : ""} ${
                    loading ? c.loading : ""
                }
                ${isOpen && displaySearch && !hideLabel ? c.openWithSearch : ""}
                `}
            >
                {loading && (
                    <LinearDeterminate
                        customClass={c.loader}
                    ></LinearDeterminate>
                )}

                <input
                    className="partialHidden"
                    type="text"
                    ref={hiddenInputRef}
                    onKeyDown={e => {
                        if (e.code === "Tab") {
                            handleOutsideClick();
                        }
                    }}
                    required={required}
                    defaultValue={
                        selectedValue === label && !multiSelect
                            ? ""
                            : selectedValue
                    }
                    onInvalid={() => setIsTouched(true)}
                ></input>

                <div
                    ref={wrapperRef}
                    onKeyDown={handleKeyDown}
                    className={`${c.input} ${isOpen ? c.open : ""} ${
                        isOpen ? classNameWhenOpen : ""
                    } ${className ? className : ""} ${isError ? c.error : ""}`}
                    onClick={e => {
                        isOpen
                            ? onClickSelect(e, false)
                            : onClickSelect(e, true);
                    }}
                >
                    <div
                        className={`${c.isSelectedText} ${
                            selectedValue == label && !unstyledLabel
                                ? c.isLabelText
                                : ""
                        }`}
                    >
                        <Tooltip
                            message={
                                multiSelect
                                    ? toolTipDropDownValues(
                                          multiSelectedOptions
                                      )
                                    : selectedValue
                            }
                            position="top"
                            hideTooltip={
                                multiSelect
                                    ? !multiSelectedOptions?.length > 0
                                    : showSelectedToolTip
                                    ? !selectedValue?.length > 0 ||
                                      selectedValue === label
                                    : true
                            }
                            moreThanOneValue={multiSelect}
                            wrap
                        >
                            {selectedValue || "Select"}
                        </Tooltip>
                    </div>

                    {closeIcon && selectedId !== null ? (
                        <Icon
                            icon="CloseFilled"
                            size="14px"
                            onClick={
                                typeof onClickClose === "function"
                                    ? () => {
                                          setSelectedValue("");
                                          onClickClose();
                                      }
                                    : () => {
                                          setSelectedValue("");
                                          onSelect("");
                                      }
                            }
                        />
                    ) : (
                        <Icon
                            className={c.dropdownArrow}
                            icon="DropDownArrow"
                            size="14px"
                        />
                    )}
                </div>
                {portal
                    ? ReactDOM.createPortal(
                          isOpen && dropdownContent,
                          portalElement || document.body
                      )
                    : isOpen && dropdownContent}
            </div>
        );
    }
);

export default Select;
