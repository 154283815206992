import React, {
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import Card from "../../Shared/Card/Card";
import Input from "../../Shared/Input/Input";
import s from "./CreateTemplate.module.scss";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import { get, isEmpty } from "lodash";
import {
    templateActions,
    createUpdateTemplate,
    fetchTemplate,
    fetchTemplateData,
    fetchTemplateHistory,
} from "../../store/flowmanager/template";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { toastListActions } from "../../store/toastList/toastList";
import TargettingParamsWrapperComp from "../../Shared/TargettingParamsWrapperComp/TargettingParamsWrapperComp";
import InputList from "../../components/InputList/InputList";
import { containerActions } from "../../store/flowmanager/container";
import { TargetingWrapper } from "../CreateFlowCategories/CreateFlowCategories";
import { useQueryClient } from "react-query";
import History from "../../components/History/History";
import { templateConfig } from "../../constants/historyConstants";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import { templateConfirmConfig } from "../../constants/confirmSaveConstants";
import ConfirmAndSave from "../../components/ConfirmAndSave/ConfirmAndSave";
import { getComparisonData } from "../../utils/historyPopUpUtils";
import { dependencyApi } from "../../utils/globalUtils";

export const TemplateKeys = [
    {
        name: "exitIntLayoutConfig",
        opsName: "Exit Layout",
        type: "layout",
        layoutType: "INTERSTITIAL",
        layoutSubType: "EXIT",
    },
    {
        name: "entryIntLayoutConfig",
        opsName: "Entry Layout",
        type: "layout",
        layoutType: "INTERSTITIAL",
        layoutSubType: "ENTRY",
    },
    {
        name: "listingsInBgLayoutConfig",
        opsName: "Listing Layout",
        type: "layout",
        layoutType: "BACKGROUND",
    },
    {
        name: "pushNotificationConfig",
        opsName: "Push Notification Layout",
        type: "layout",
        layoutType: "PUSHNOTIF",
    },
    {
        name: "exitLoader",
        opsName: "Exit Loader",
        type: "layout",
        layoutType: "LOADER",
    },
];

const CreateTemplate = forwardRef(
    (
        {
            showInPopup = false,
            editPhaseInPopUp = false,
            createPhaseInPopup = false,
            newTemplateCreatedId = null,
            isClonePanel = false,
            isTableView,
        },
        ref
    ) => {
        // description is currently disabled coz desc is not implemented on api side, uncomment all description code once api get implemnted
        const dispatch = useDispatch();
        const { historyData, historyDataRaw } = useSelector(
            state => state.flowmanager.template
        );
        const saveConfirmCallbacks = useRef(null);

        const isCreateTemplate =
            (window.location.pathname.split("/")[3] === "create" &&
                window.location.pathname.split("/")[2] !== "flow") ||
            createPhaseInPopup;

        const createTemplate = useSelector(
            state => state.flowmanager.template.createTemplate
        );
        const createTemplateBackup = useSelector(
            state => state.flowmanager.template.createTemplateBackup
        );
        const { name, configuration, loading, mainLoader, showPrompt } =
            useSelector(state => state.flowmanager.template.createTemplate);
        const [configurationOptionSelected, setConfigurationOptionSelected] =
            useState("");
        const [configurationValue, setConfigurationValue] = useState([]);
        const [showSaveConfirm, setShowSaveConfirm] = useState(false);
        const [formChanges, setFormChanges] = useState(false);

        const [liveAccess, setLiveAccess] = useState(false);

        const urlParams = useParams();
        const history = useHistory();
        const adCategRef = useRef(null);
        const tpWrapperRef = useRef(null);
        const queryClient = useQueryClient();

        const containerState = useSelector(
            state => state.flowmanager.container.createContainer
        );

        let propertiesWithoutTemplate = "";

        useEffect(() => {
            if (adCategRef.current) adCategRef.current.focus();
            return () => {
                dispatch(templateActions.resetTemplate()); //set initial state on unmount to avoid edit page changes on create page
            };
        }, []);

        useEffect(() => {
            setFormChanges(
                getComparisonData(
                    createTemplate,
                    createTemplateBackup,
                    templateConfirmConfig,
                    true
                )[0]?.length > 0 || false
            );
        }, [createTemplate]);

        useEffect(() => {
            dispatch(templateActions.resetTemplate());
            if (urlParams.id && !editPhaseInPopUp) {
                if (!createPhaseInPopup) dispatch(fetchTemplate(urlParams.id));
            }
        }, [urlParams.id]);

        const showConfirmAndSave = async () => {
            return new Promise((resolve, reject) => {
                saveConfirmCallbacks.current = {
                    resolve,
                    reject,
                };
                setShowSaveConfirm(true);
            });
        };

        const checkIfAllLayoutIsSelected = () => {
            return configuration?.value?.some(item => {
                const type = TemplateKeys.find(
                    data => data?.name === item.value[0].key
                );
                propertiesWithoutTemplate = type;
                return item.value[0].value?.length === 0;
            });
        };

        const onSaveHandler = async () => {
            let isFormValid = false;

            if (
                adCategRef.current.validate() &&
                configuration?.value?.length &&
                !checkIfAllLayoutIsSelected()
            ) {
                let confirm = true;
                if (createTemplate.id) {
                    confirm = await showConfirmAndSave();
                }
                if (confirm) {
                    await createTemplateFunction();
                    isFormValid = true;
                }
            } else {
                if (!adCategRef.current.validate()) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Please add name`,
                        })
                    );
                }
                if (configuration?.value?.length === 0) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Please add configuration`,
                        })
                    );
                }
                if (checkIfAllLayoutIsSelected()) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Please add layout for ${propertiesWithoutTemplate?.opsName} Feature`,
                        })
                    );
                }
            }
            return isFormValid;
        };

        const showHistory = () => {
            historyDataAction(
                fetchTemplateHistory,
                containerState?.template
                    ? { id: containerState?.template }
                    : urlParams,
                dispatch,
                templateActions
            );
        };

        const navigateCallback = () => {
            window.open(
                `/flowmanager/template/edit/${containerState?.template}`,
                "_blank"
            );
        };

        useImperativeHandle(ref, () => ({
            onSaveHandler: onSaveHandler,
            showHistory: showHistory,
            navigateCallback: navigateCallback,
        }));

        const createTemplateFunction = async data => {
            dispatch(templateActions.setMainLoader(true));
            await dispatch(createUpdateTemplate())
                .unwrap()
                .then(response => {
                    dispatch(templateActions.setShowPrompt(false));
                    dispatch(templateActions.setMainLoader(false));
                    if (
                        response &&
                        get(response, "status", false) == "success"
                    ) {
                        if (showInPopup) {
                            dispatch(
                                containerActions.setTemplate(response?.data?.id)
                            );
                            typeof newTemplateCreatedId === "function" &&
                                newTemplateCreatedId(
                                    response?.data?.id || null
                                );
                        } else {
                            navigateToListings();
                        }

                        if (urlParams.id && !showInPopup) {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Success",
                                    message: `Successfully ${
                                        !urlParams.id ? "Created" : "Edited"
                                    } Feature Group`,
                                })
                            );
                        }

                        if (showInPopup) {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Success",
                                    message: `Successfully ${
                                        editPhaseInPopUp ? "Edited" : "Created"
                                    } Feature Group`,
                                })
                            );
                        }

                        queryClient.removeQueries("templateListData", {
                            exact: true,
                        });
                    }
                });
        };

        const navigateToListings = () => {
            history.replace({ pathname: `/flowmanager/template/listings` });
            dispatch(fetchTemplateData());
        };

        const authUser = useSelector(state => state.auth.user);
        const isReadOnly =
            !authUser.modules.includes(`FlowManager.Template.Write`) ||
            isClonePanel ||
            isTableView;

        const writeAccessAvailable = authUser.modules.includes(
            `FlowManager.Flow.LiveAccess.Read`
        );

        const keywordDropdownOptions = [
            { id: "logoUrl", label: "logoUrl" },
            { id: "background", label: "background" },
            { id: "uBasedPriorityListings", label: "uBasedPriorityListings" },
            { id: "sourceBasedListings", label: "sourceBasedListings" },
            { id: "header1", label: "header1" },
            { id: "header2", label: "header2" },
            { id: "ctaText", label: "ctaText" },
        ];

        useEffect(() => {
            if (configuration?.length) {
                let configurationArray = [];
                configuration.forEach((item, index) => {
                    configurationArray.push(item.valueOne);
                });
                setConfigurationValue(configurationArray);
            } else {
                setConfigurationValue([]);
            }
        }, [configuration]);

        useEffect(() => {
            if (writeAccessAvailable || isCreateTemplate) {
                setLiveAccess(true);
            }
            if (!writeAccessAvailable && createTemplate.id) {
                dependencyApi("TEMPLATE", createTemplate.id, () => {
                    setLiveAccess(false);
                }).then(res => {
                    const dependencyList = res?.data?.data?.dependencyList;
                    if (!isEmpty(dependencyList)) {
                        setLiveAccess(
                            !dependencyList?.FLOW?.some(
                                _ => _.isLive === true
                            ) || true
                        );
                    } else {
                        setLiveAccess(true);
                    }
                });
            }
        }, [writeAccessAvailable, createTemplate.id]);

        const keywordsDropDownJson = {
            className: ``,
            keyField: "label",
            idField: "id",
            position: "bottom",
            options: keywordDropdownOptions.filter(
                (item, idx) => !configurationValue.includes(item.label)
            ),
            selectedID: configurationOptionSelected
                ? configurationOptionSelected
                : null,
            label: "Select Keyword",
        };

        const keywordsInputJson = {
            type: configurationOptionSelected === "logoUrl" ? "url" : "",
        };

        const toggleHistoryVisibilty = () => {
            dispatch(templateActions.setHistoryData([]));
            dispatch(templateActions.setHistoryDataRaw([]));
        };

        return (
            <>
                {showSaveConfirm && (
                    <ConfirmAndSave
                        hideComparision={true}
                        entityType="TEMPLATE"
                        entityId={createTemplate.id}
                        dataUpdated={createTemplate}
                        dataBackup={createTemplateBackup}
                        config={templateConfirmConfig}
                        saveCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(true);
                        }}
                        closeCallback={() => {
                            setShowSaveConfirm(false);
                            saveConfirmCallbacks.current?.resolve(false);
                        }}
                        isConfirm={true}
                    />
                )}
                <div className={s.createCTemplaterapper}>
                    {mainLoader && !showInPopup && <LinearDeterminate />}

                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            {!showInPopup && (
                                <Card
                                    className={`sticky-below-header ${s.header}`}
                                >
                                    <CreateSaveHeader
                                        onSave={!isReadOnly && onSaveHandler}
                                        isBig={true}
                                        title={`${
                                            urlParams.id ? "Edit" : "Create"
                                        } Feature Group`}
                                        saveLabel={`${
                                            urlParams.id ? "Save" : "Create"
                                        }`}
                                        desc={"Create Feature Group"}
                                        showPrompt={showPrompt}
                                        showHamburgerBtn={false}
                                        loading={loading}
                                        // Temp disabling to avoid testing
                                        // saveBtn={mainLoader || !formChanges}
                                        saveBtn={mainLoader || !liveAccess}
                                        showHistoryIcon={
                                            urlParams.id ? true : false
                                        }
                                        showHistory={showHistory}
                                    ></CreateSaveHeader>
                                </Card>
                            )}
                            <Card
                                className={`card-padding ${
                                    showInPopup && s.noCard
                                }`}
                            >
                                <div>
                                    {!showInPopup && (
                                        <h3
                                            data-hash-id="template"
                                            id="template"
                                            className="headerTitle"
                                        >
                                            Basics
                                        </h3>
                                    )}
                                    {urlParams.id && !showInPopup && (
                                        <div className={`row`}>
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                ID
                                            </p>
                                            <p className={`infoText`}>
                                                ID associated with Template
                                            </p>
                                            <p className={s.idWrapper}>
                                                {urlParams.id}
                                            </p>
                                        </div>
                                    )}
                                    {editPhaseInPopUp && !createPhaseInPopup && (
                                        <div className={`row`}>
                                            <p
                                                className={`headerTitleRequired`}
                                            >
                                                ID
                                            </p>
                                            <p className={`infoText`}>
                                                ID associated with Feature Group
                                            </p>
                                            <p className={s.idWrapper}>
                                                {createTemplate?.id}
                                            </p>
                                        </div>
                                    )}
                                    <div className={"form-row"}>
                                        <p className="headerTitleRequired">
                                            Name*
                                        </p>
                                        <p className="infoText">
                                            Its an unique identifier name for
                                            the category
                                        </p>
                                        <Input
                                            disabled={
                                                isReadOnly || urlParams.id
                                            }
                                            ref={adCategRef}
                                            className="maxChildContainer"
                                            placeholder="Enter Template name"
                                            required={true}
                                            onChange={e =>
                                                dispatch(
                                                    templateActions.setTemplateName(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            value={name}
                                        ></Input>
                                    </div>
                                    <div className={`row ${s.maxWidth}`}>
                                        <p className="headerTitleRequired">
                                            Features
                                        </p>
                                        <p className="infoText">
                                            Features to be added in Feature
                                            Group
                                        </p>
                                        <div
                                            className={
                                                s.layoutPropertiesWrapper
                                            }
                                        >
                                            <TargetingWrapper
                                                addLabel={"Add Feature"}
                                                onChange={() => {
                                                    setTimeout(() => {
                                                        const data =
                                                            tpWrapperRef.current.getData();
                                                        dispatch(
                                                            templateActions.setConfiguration(
                                                                data
                                                            )
                                                        );
                                                    });
                                                }}
                                                forcedTpList={TemplateKeys}
                                                targettingParams={configuration}
                                                ref={tpWrapperRef}
                                                readOnly={isReadOnly}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </>
                    )}
                </div>
                {historyData && historyData.length > 0 ? (
                    <History
                        data={historyData}
                        rawData={historyDataRaw}
                        closeHistory={toggleHistoryVisibilty}
                        historyConfig={templateConfig}
                    />
                ) : null}
            </>
        );
    }
);

export default CreateTemplate;
