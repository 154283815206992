import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { toastListActions } from "../toastList/toastList";

import { MESSAGES } from "../../constants/messages";
import { FILTERS } from "../../constants/listingSearch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getInitialRowsCount,
    getRequiredObject,
} from "../../utils/globalUtils";
import customFetch from "../../fetch/customFetch";
import { UAC } from "../../constants/url";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    selectedRowIds: [],
    loading: false,
    updatedAt: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.USER_GROUP.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.USER_GROUP.filterOperator,
        filtersOperator: FILTERS.USER_GROUP.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "user-group"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    mainLoader: false,
    createUserGroup: {
        displayName: "",
        description: "",
        status: "active",
        createdBy: "",
        modules: [],
        users: [],
        removedUsers: [],
    },
};

export const fetchUserGroupsList = createAsyncThunk(
    "listings/uac/fetchUserGroupsList",
    async (fetchAll, { getState, dispatch }) => {
        const pagination = get(getState(), "uac.userGroups.pagination", {});
        const params = paginationParams(pagination);
        const { uac } = getState();
        let tagFilters = get(uac, "userGroups.searchFilter.filterApplied", []);
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.USER_GROUP
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        // const filterParamsData = getRequiredObject(
        //     filterParamsList,
        //     fetchAll ? {} : params
        // );
        const filterParamsData = getRequiredObject(
            filterParamsList,
            fetchAll ? {} : updatedParams
        );
        let dispatchSearchError = true;
        const response = await customFetch(
            UAC.FETCH_USER_GROUPS_LIST,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch User Group List",
                    })
                );
                dispatch(
                    userGroupsActions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );
        const data = get(response, "data", []);
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.USER_GROUP}`;
            dispatch(userGroupsActions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData}. Create a new User Group`;
            dispatch(userGroupsActions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchUserGroupsListById = createAsyncThunk(
    "listings/uac/fetchUserGroupsListById",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${UAC.FETCH_USER_GROUPS_LIST}/${id}`,
            "GET",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch User Group Details",
                    })
                );
            }
        );
        const data = get(response, "data", []);
        return data;
    }
);

export const createNewUserGroup = createAsyncThunk(
    "listings/uac/createNewUserGroup",
    async (_, { getState, dispatch }) => {
        let params = getState().uac.userGroups.createUserGroup;
        params = {
            ...params,
            users: params.users.filter(_ => !params.removedUsers.includes(_)),
        };
        delete params.removedUsers; //don't need to save this
        const response = await customFetch(
            UAC.CREATE_USER_GROUP,
            "POST",
            params,
            {},
            {},
            null,
            null,
            e => {
                if (e?.response?.data?.message) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: e.response.data.message,
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to Create User Group",
                        })
                    );
                }
            }
        );
        return get(response, "status", null);
    }
);

export const updateUserGroupById = createAsyncThunk(
    "listings/uac/updateUserGroupById",
    async (id, { getState, dispatch }) => {
        let params = getState().uac.userGroups.createUserGroup;
        params = {
            ...params,
            users: params.users.filter(_ => !params.removedUsers.includes(_)),
        };
        delete params.removedUsers; //don't need to save this
        const response = await customFetch(
            `${UAC.UPDATE_USER_GROUPS_LIST_BY_ID}/${id}`,
            "PUT",
            params,
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to Update User Group",
                    })
                );
            }
        );
        return get(response, "status", null);
    }
);

export const toggleUserGroupStatus = createAsyncThunk(
    "listings/uac/toggleUserGroupStatus",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(
            `${UAC.UPDATE_USER_GROUPS_LIST_BY_ID}/${params.id}`,
            "PUT",
            params,
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: ` Couldn't ${
                            actionItem.status == "inactive"
                                ? "Enable"
                                : "Disable"
                        } ${actionItem.displayName}`,
                        autoClose: 5000,
                    })
                );
            }
        );
        const data = get(response, "status", []);
        return data;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/userGroupsAccessControl/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { uac } = getState();
        let initialData = uac.userGroups.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.USER_GROUP.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const userGroups = createSlice({
    name: "userGroupsAccessControl",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setName(state, action) {
            state.createUserGroup.displayName = action.payload;
        },
        setDescription(state, action) {
            state.createUserGroup.description = action.payload;
        },
        setStatus(state, action) {
            state.createUserGroup.status = action.payload;
        },
        setModules(state, action) {
            state.createUserGroup.modules = action.payload;
        },
        setUsers(state, action) {
            state.createUserGroup.users = action.payload;
        },
        setRemovedUsers(state, action) {
            state.createUserGroup.removedUsers = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetUserGroup(state, action) {
            state.createUserGroup = initialState.createUserGroup;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUserGroupsList.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(fetchUserGroupsList.fulfilled, (state, action) => {
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
        });
        builder.addCase(toggleUserGroupStatus.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(fetchUserGroupsListById.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchUserGroupsListById.fulfilled, (state, action) => {
            if (action.payload) {
                return {
                    ...state,
                    loading: false,
                    createUserGroup: {
                        displayName: action.payload.displayName,
                        description: action.payload.description,
                        status: action.payload.status,
                        modules: action.payload.modules,
                        createdBy: action.payload.createdBy,
                        users: action.payload.users.map(_ => _.email),
                        removedUsers: [],
                    },
                };
            }
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const userGroupsActions = userGroups.actions;
export default userGroups.reducer;
