export const FILTERS = {
    //filterKey is the array of name of columns
    //filterOperator is the array of operators
    // operator is the operator which will be in between all the filters
    SURVEY: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    SURVEY_PATH: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    QUESTIONS: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    //commented as we are having default status tag now
    // OFFER_PATH: {
    //     filterKey: ["name", "id", "sid"],
    //     filterOperator: ["ilike", "=", "="],
    //     operator: "AND",
    // },
    OFFER_PATH: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    AD_UNIT: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    CREATIVE: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    OFFER_GROUP: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    LEAD_DEFINITION: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    CATEGORIES: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    JT: {
        filterKey: ["name"],
        filterOperator: ["ilike", "="],
        operator: "OR",
    },
    FEED_MANAGER: {
        filterKey: ["publisherName", "publisherCode"],
        filterOperator: ["ilike", "ilike"],
        operator: "OR",
    },
    AB_TEST: {
        filterKey: ["name"],
        filterOperator: ["like"],
        operator: "AND",
    },
    SURVEY_CONFIG: {
        filterKey: ["version"],
        filterOperator: ["like"], // for datastore cases
        operator: "OR",
    },
    FEATURE_MAP: {
        filterKey: ["key"],
        filterOperator: ["like"],
        operator: "OR",
    },
    ROAS_DASHBOARD: {
        filterKey: ["campaign"],
        filterOperator: ["like"],
        operator: "OR",
    },
    URL_GENERATOR: {
        filterKey: ["email_id"],
        filterOperator: ["ilike="], //Special case as GO service filters
        operator: "AND",
    },
    FEATURE_SETS: {
        filterKey: ["name"],
        filterOperator: ["like"],
        operator: "OR",
    },
    GIFT_CARDS: {
        filterKey: ["name"],
        filterOperator: ["ilike="],
        operator: "AND",
    },
    DEAL_CATEGORY: {
        filterKey: ["name"],
        filterOperator: ["ilike="],
        operator: "AND",
    },
    OFFER_WALL: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    OFFLINE_CONVERSION: {
        filterKey: ["advnm", "date"],
        filterOperator: ["string=", "between"],
        operator: "OR",
    },
    ADVERTISER_SCRUB_DASHBOARD: {
        filterKey: ["entityType", "entityId"],
        filterOperator: ["=", "prefix"],
        operator: "AND",
    },
    USER_JOURNEY: {
        filterKey: ["email_id"],
        filterOperator: ["ilike="],
        operator: "AND",
    },
    USER_JOURNEY_DETAIL: {
        filterKey: ["name"],
        filterOperator: ["like"],
        operator: "AND",
    },
    USERS_LIST: {
        filterKey: ["displayName", "email"],
        filterOperator: ["ilike", "ilike"],
        operator: "OR",
    },
    USER_GROUP: {
        filterKey: ["displayName"],
        filterOperator: ["ilike"],
        operator: "OR",
    },
    TEMPLATE: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    COLLECTION: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    FLOW_QUESTIONS: {
        filterKey: ["question"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    FLOW_LAYOUT: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    FLOW: {
        filterKey: ["displayName"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    CAMPAIGNS: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    FLOWCATEGORIES: {
        filterKey: ["hnpCategory"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    CONTAINER: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    ADVERTISER: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
    SITE_CONFIG: {
        filterKey: ["name"],
        filterOperator: ["ilike"],
        operator: "AND",
    },
};
