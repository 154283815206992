import s from "./TagRenderer.module.scss";
import PropTypes from "prop-types";
import Icon from "../../../Shared/Icon/Icon";

const TagRenderer = ({
    className,
    type,
    onClose,
    customIcon = null,
    showKeyValue = false,
    valueOne = "",
    valueTwo = "",
    isClickable = false,
    ...props
}) => {
    return (
        <div
            className={`${className} ${s.tagContainer} ${s[type]} ${
                showKeyValue && s.keyValueContainer
            }`}
            {...props}
        >
            {showKeyValue ? (
                <div
                    className={`dp-parent ${s.keyValueWrapper} ${
                        isClickable && s.keyValueClickable
                    }`}
                >
                    <div>{valueOne}</div>
                    {valueTwo !== "" && <div> : {`${valueTwo}`}</div>}
                </div>
            ) : (
                props.children
            )}
            {onClose && (
                <div className={s.close} onClick={onClose}>
                    <Icon
                        size={customIcon?.size || "14px"}
                        icon={customIcon?.name || "CloseFilled"}
                        enableHover={true}
                    />
                </div>
            )}
        </div>
    );
};

TagRenderer.propTypes = {
    /**
     * Type of tag
     */
    type: PropTypes.string,
};

TagRenderer.defaultProps = {
    type: "new",
};

export default TagRenderer;
