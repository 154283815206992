import axios from "axios";
import { isEmpty } from "lodash";
import { manipulateData } from "../utils/dataManipulationUtils";
import { getLatestToken } from "../utils/firebaseUtils";
import { sendSentryException } from "../utils/sentryUtils";

export default async function customFetch(
    url,
    method = "GET",
    data = {},
    customeHeaders = {},
    optionalParams = {},
    onDownloadProgress = null,
    cancelToken = null,
    errorCallback = null
) {
    const token = await getLatestToken();
    const headers = {
        "content-type": "application/json",
        Authorization: token,
        ...customeHeaders,
    };
    let response;
    let options = {
        method,
        url,
        headers,
    };
    data = manipulateData(url, data, method, false);

    if (method === "GET") options.params = { ...data };
    // if (method === "PUT") options.data = manipulateData(url, data)
    else options.data = data;
    if (onDownloadProgress) {
        options.onDownloadProgress = onDownloadProgress;
    }
    if (cancelToken) {
        options.cancelToken = cancelToken;
    }
    // condition to handle post calls with params and data
    if (!isEmpty(optionalParams)) {
        options = { ...options, ...optionalParams };
    }

    try {
        response = await axios(options);
        response = manipulateData(url, response, method, true);
    } catch (e) {
        sendSentryException(e);
        if (errorCallback && typeof errorCallback === "function") {
            //  e prints JS catch output whereas e.response prints network tab response
            errorCallback(e);
        }
    }
    return response;
}
