import Card from "../../Shared/Card/Card";
import OfferPathBasics from "../../components/OfferPathBasics/OfferPathBasics";
import OfferPathSlots from "../../components/OfferPathSlots/OfferPathSlots";
import TargettingParams from "../../components/TargettingParams/TargettingParams";
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import { offerPaths } from "../../constants/leftPanelConstants";
import s from "./CreateOfferPath.module.scss";
import LeftPanel from "../../components/LeftPanel/LeftPanel";
import { useDispatch, useSelector } from "react-redux";
import {
    createOfferPathData,
    createOfferPathsActions,
    fetchOfferPathDataById,
    fetchOfferPathsData,
    fetchSurveys,
    updateOfferPathData,
    historyListingsTable,
    fetchOfferWalls,
    cloneOfferPathData,
} from "../../store/listings/offerPaths";
import { fetchAdCreatives } from "../../store/listings/adUnits";
import { get } from "lodash";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { toastListActions } from "../../store/toastList/toastList";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { numberOfSetsOfferPath } from "../../constants/targettingParamsConstants";
import {
    adsHistory,
    historyInnerTable,
    fetchDealIds,
} from "../../store/listings/offerPaths";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import { getRandomId } from "../../utils/globalUtils";
import { fetchAllAdvertisers } from "../../store/listings/offlineConversion";
import { useFetchSpamIpDropdown } from "../../generalApi/generalApi";
import History from "../../components/History/History";
import { offerPathConfig } from "../../constants/historyConstants";
import ConfirmAndSave from "../../components/ConfirmAndSave/ConfirmAndSave";
import { offerPathConfirmConfig } from "../../constants/confirmSaveConstants";
import { getComparisonData } from "../../utils/historyPopUpUtils";

const CreateOfferPath = forwardRef(({ id, isSidePanel = true }, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const urlParams = useParams();
    const offerPathId = !isSidePanel ? id : urlParams?.id;
    const offerpaths = useSelector(state =>
        get(state, "listings.offerPaths", {})
    );
    const {
        basics = {},
        targettingParam = {},
        dedupeRules = [],
    } = offerpaths.createOfferPath;

    const {
        name = "",
        weight = "",
        status = false,
        maxSlotsToReturn,
        selectedSurveyIds = [],
        enableAutoOfferPath,
        explorationWeight,
    } = basics;
    let data = offerpaths.createOfferPath.slots;

    const { historyData = [], historyDataRaw = [] } = data;
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [formChanges, setFormChanges] = useState(false);
    useEffect(() => {
        setFormChanges(
            getComparisonData(
                offerpaths.createOfferPathBackup,
                offerpaths.createOfferPath,
                offerPathConfirmConfig,
                true
            )[0]?.length > 0 || false
        );
    }, [offerpaths.createOfferPath, offerpaths.createOfferPathBackup]);

    const { loading, mainLoader } = useSelector(
        state => state.listings.offerPaths
    );
    const [showPrompt, setShowPrompt] = useState(true);
    const surveys = useSelector(state => state.listings.offerPaths.surveyList);
    const creativesList = useSelector(
        state => state.listings.adUnits.createAdUnit.slots.adCreativesList
    );
    const offerWallsList = useSelector(
        state => state.listings.offerPaths.offerWallsList
    );
    const showLeftPanel = useSelector(
        state => state.globalConfig.showLeftPanel
    );

    const authUser = useSelector(state => state.auth.user);
    const isReadOnly = !authUser.modules.includes(
        `SurveyManager.OfferPath.Write`
    );

    // Fetching general api using react-query
    if (isSidePanel) {
        var { apiData, isLoading } = useFetchSpamIpDropdown();
    }

    useEffect(() => {
        const editPhaseData = async () => {
            dispatch(createOfferPathsActions.loading(true));
            dispatch(createOfferPathsActions.resetOfferPath());
            dispatch(fetchSurveys());
            dispatch(fetchOfferWalls());
            dispatch(fetchAllAdvertisers());

            if (!creativesList.length) {
                await dispatch(fetchAdCreatives());
            }
            if (offerPathId) {
                await dispatch(fetchOfferPathDataById(offerPathId));
            } else {
                if (nameRef.current) nameRef.current.focus();
            }
            await dispatch(createOfferPathsActions.loading(false));
        };
        editPhaseData();
        return () => {
            dispatch(createOfferPathsActions.setOfferPathBasicError(false));
            dispatch(createOfferPathsActions.setOfferPathSlotsError(false));
        };
    }, []);

    const nameRef = useRef(null);
    const weightRef = useRef(null);
    const onSaveOfferPathHandler = () => {
        const isFormValid = validateAllFields();
        if (isFormValid) {
            setShowSaveConfirm(true);
        }
    };
    const validateSlots = () => {
        let offerPathSlotsError = false;
        let allSoftDeleteOfferPath = false;

        const softDeletedMainSlotsIds = get(data, "deletedSlots", []).map(
            item => {
                return item.id;
            }
        );

        const softDeletedSlotsIds = get(data, "deletedSlotAds", []).map(
            item => {
                return item.ad.id;
            }
        );

        let isOfferPathSlotsValid = !!get(data, "selectedListItems", []).length;

        if (isOfferPathSlotsValid) {
            let length = get(data, "selectedListItems", []).length;
            let softDeletedLength = 0;
            get(data, "selectedListItems", []).forEach(element => {
                if (softDeletedMainSlotsIds.includes(element.id)) {
                    softDeletedLength = softDeletedLength + 1;
                }
            });

            if (length == softDeletedLength) isOfferPathSlotsValid = false;
        }

        const invalidSlots = get(data, "selectedListItems", []).filter(item => {
            if (item.type !== "OfferWall") return !item.editCreativeData.length;
        });

        const offerPathWithAllSoftDelete = get(
            data,
            "selectedListItems",
            []
        ).filter(item => {
            if (!softDeletedMainSlotsIds.includes(item.id)) {
                let creaiveLength = item.editCreativeData.length;
                let softDeleteLength = 0;
                if (item.type !== "OfferWall" && item.editCreativeData.length) {
                    item.editCreativeData.forEach(element => {
                        if (softDeletedSlotsIds.includes(element.id)) {
                            softDeleteLength = softDeleteLength + 1;
                        }
                    });
                    if (creaiveLength == softDeleteLength) return item;
                }
            }
        });

        if (offerPathWithAllSoftDelete.length > 0) {
            allSoftDeleteOfferPath = true;
        }

        if (
            !isOfferPathSlotsValid ||
            invalidSlots.length !== 0 ||
            allSoftDeleteOfferPath
        )
            offerPathSlotsError = true;
        dispatch(
            createOfferPathsActions.setOfferPathSlotsError({
                page: "",
                data: offerPathSlotsError,
            })
        );
        return {
            isOfferPathSlotsValid,
            invalidSlots,
            allSoftDeleteOfferPath,
            offerPathWithAllSoftDelete,
        };
    };
    const validateAllFields = () => {
        const isOfferPathNameValid = nameRef.current.validate();
        const isOfferPathWeightValid = weightRef.current.validate();
        const weightValue = weightRef.current?.value()?.toString();
        const isWeightValueNegative = weightValue?.includes("-");
        const explorationValueCheck = !(
            enableAutoOfferPath && explorationWeight > 100
        );

        const maxOfferSlotCompulsory = enableAutoOfferPath
            ? maxSlotsToReturn !== ""
            : true;

        const {
            isOfferPathSlotsValid,
            invalidSlots,
            allSoftDeleteOfferPath,
            offerPathWithAllSoftDelete,
        } = validateSlots();
        let offerPathBasicError = false;
        if (
            isOfferPathNameValid &&
            isOfferPathWeightValid &&
            !isWeightValueNegative &&
            isOfferPathSlotsValid &&
            invalidSlots.length === 0 &&
            !allSoftDeleteOfferPath &&
            explorationValueCheck &&
            maxOfferSlotCompulsory
        ) {
            return true;
        } else {
            if (!isOfferPathNameValid || !isOfferPathWeightValid)
                offerPathBasicError = true;
            if (enableAutoOfferPath) {
                if (explorationWeight > 100) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Exploration value cannot be greater than 100",
                        })
                    );
                }

                if (!maxOfferSlotCompulsory) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Max Offer Limit cannot be empty",
                        })
                    );
                }
            }

            if (
                !isOfferPathSlotsValid ||
                invalidSlots.length !== 0 ||
                allSoftDeleteOfferPath
            ) {
                if (!isOfferPathSlotsValid) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Enter atleast one Offer Path Slot",
                        })
                    );
                }
                if (invalidSlots.length || allSoftDeleteOfferPath) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "All slots must have atleast one creative",
                        })
                    );
                    let ids = offerPathWithAllSoftDelete.map(item => item.id);

                    dispatch(
                        createOfferPathsActions.addSelectedListItems({
                            page: "",
                            data: get(data, "selectedListItems", []).map(
                                item => {
                                    if (
                                        (ids.includes(item.id) ||
                                            item.editCreativeData.length ===
                                                0) &&
                                        item.type !== "OfferWall"
                                    ) {
                                        return {
                                            ...item,
                                            showAccordionTable: true,
                                        };
                                    } else return item;
                                }
                            ),
                        })
                    );
                }
            }

            if (isWeightValueNegative) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Weight cannot be Negative",
                    })
                );
            }
        }
        dispatch(
            createOfferPathsActions.setOfferPathBasicError(offerPathBasicError)
        );
    };

    useImperativeHandle(ref, () => {
        return {
            validateAllFields: validateAllFields,
        };
    });

    const createOfferPath = () => {
        dispatch(createOfferPathsActions.setMainLoader(true));
        dispatch(createOfferPathData())
            .unwrap()
            .then(response => {
                setShowPrompt(false);
                if (response) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Success",
                            message: "Created Offer Path successfully",
                        })
                    );
                    if (response.status) {
                        navigateToListing();
                    } else {
                        if (window.history.pushState) {
                            var newurl =
                                window.location.origin +
                                window.location.pathname.replace(
                                    "create",
                                    "edit/" + response.id
                                );
                            window.history.pushState(
                                { path: newurl },
                                "",
                                newurl
                            );
                        }
                    }
                }
                dispatch(createOfferPathsActions.setMainLoader(false));
            });
    };

    const updateOfferPath = id => {
        dispatch(createOfferPathsActions.setMainLoader(true));
        dispatch(updateOfferPathData(id))
            .unwrap()
            .then(response => {
                setShowPrompt(false);
                if (response) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Success",
                            message: "Updated Offer Path Successfully",
                        })
                    );
                    navigateToListing();
                }
                dispatch(createOfferPathsActions.setMainLoader(false));
            });
    };

    const navigateToListing = () => {
        history.replace({ pathname: `/surveydetails/offerpaths/listings` });
        dispatch(fetchOfferPathsData(true));
        setShowPrompt(false);
    };

    const cloneSlot = item => {
        let toClone = JSON.parse(JSON.stringify(item));
        toClone.id = "-";
        toClone.rowId = getRandomId();
        toClone.index = data.selectedListItems.length + 1;
        if (toClone.editCreativeData.length > 0) {
            toClone.editCreativeData = toClone.editCreativeData.map(_ => {
                _.id = "-";
                _.innerRowId = getRandomId();
                return _;
            });
        }
        dispatch(
            createOfferPathsActions.addSelectedListItems({
                data: [...data.selectedListItems, toClone],
            })
        );
        dispatch(
            toastListActions.setToastList({
                type: "Success",
                message: "Slot cloned successfully. Save to update.",
            })
        );
    };

    const cloneListing = () => {
        dispatch(createOfferPathsActions.setMainLoader(true));
        dispatch(cloneOfferPathData(offerPathId)).then(res => {
            if (res?.payload?.status >= 200 && res?.payload?.status < 205) {
                let responseObj = res.payload.data.data.data;
                window
                    .open(
                        window.location.href.split("/edit/")[0] +
                            "/edit/" +
                            responseObj.id +
                            "#clonedListing",
                        "_blank"
                    )
                    .focus();
            }
            dispatch(createOfferPathsActions.setMainLoader(false));
        });
    };

    const toggleHistoryVisibilty = () => {
        dispatch(createOfferPathsActions.setHistoryData([]));
    };

    return (
        <div className="w-100">
            {mainLoader && <LinearDeterminate />}
            {isSidePanel && (
                <Card className={"sticky-below-header"}>
                    <CreateSaveHeader
                        isBig={true}
                        title={`${
                            offerPathId ||
                            window.location.pathname.includes("/edit/")
                                ? "Edit"
                                : "Create"
                        } Offer Path`}
                        saveLabel={`${
                            offerPathId ||
                            window.location.pathname.includes("/edit/")
                                ? "Save"
                                : "Create"
                        }`}
                        desc={
                            "Create Offer Path which can be linked to various Surveys"
                        }
                        showPrompt={!isReadOnly && showPrompt && formChanges}
                        onSave={!isReadOnly && onSaveOfferPathHandler}
                        saveBtn={!formChanges}
                        showHistoryIcon={offerPathId ? true : false}
                        showHistory={() => {
                            historyDataAction(
                                historyListingsTable,
                                urlParams,
                                dispatch,
                                createOfferPathsActions
                            );
                        }}
                        loading={loading}
                        cloneListing={
                            !isReadOnly && offerPathId ? cloneListing : false
                        }
                    ></CreateSaveHeader>
                </Card>
            )}

            <div className="dp-parent">
                {isSidePanel && (
                    <LeftPanel
                        isEdit={!!offerPathId}
                        data={offerPaths}
                        offset={160}
                        scrollOffset={155}
                        path={`listings.offerPaths.createOfferPath`}
                        errorState={`error`}
                        loading={loading}
                    />
                )}

                <div
                    className={`rightPanel ${
                        !showLeftPanel ? "rightPanel__expanded" : ""
                    } ${!isSidePanel ? "rightPanel__expanded" : ""}
                    ${!isSidePanel ? "pl-0" : ""}
                    `}
                >
                    {loading ? (
                        <SkeletonLoader />
                    ) : (
                        <>
                            <Card>
                                <OfferPathBasics
                                    id={offerPathId}
                                    type="create"
                                    dedupeRules={dedupeRules}
                                    name={name}
                                    weight={weight}
                                    status={status}
                                    surveys={surveys}
                                    selectedSurveyIds={selectedSurveyIds}
                                    offerLimitValue={maxSlotsToReturn}
                                    autoOfferPath={enableAutoOfferPath}
                                    explorationValue={explorationWeight}
                                    // ref={offerPathBasicRef}
                                    allRefs={{ nameRef, weightRef }}
                                    onChangeName={e => {
                                        dispatch(
                                            createOfferPathsActions.setOfferPathName(
                                                e
                                            )
                                        );
                                        if (
                                            e.length < 0 &&
                                            weightRef.current.value().length < 0
                                        ) {
                                            dispatch(
                                                createOfferPathsActions.setOfferPathBasicError(
                                                    true
                                                )
                                            );
                                        }
                                    }}
                                    onSelectSurvey={survey => {
                                        dispatch(
                                            createOfferPathsActions.setSelectedSurveyIds(
                                                survey.map(x => x.id)
                                            )
                                        );
                                    }}
                                    onChangeWeight={e => {
                                        dispatch(
                                            createOfferPathsActions.setOfferPathWeight(
                                                e
                                            )
                                        );
                                    }}
                                    onChangeStatus={val =>
                                        dispatch(
                                            createOfferPathsActions.setOfferPathStatus(
                                                val
                                            )
                                        )
                                    }
                                    setDedupe={dedupeRules => {
                                        dispatch(
                                            createOfferPathsActions.setDedupe(
                                                dedupeRules
                                            )
                                        );
                                    }}
                                    onChangeOfferLimit={e => {
                                        dispatch(
                                            createOfferPathsActions.setOfferPathLimit(
                                                e
                                            )
                                        );
                                    }}
                                    onChangeAutoOfferPath={val => {
                                        dispatch(
                                            createOfferPathsActions.setAutoOfferPath(
                                                val
                                            )
                                        );
                                    }}
                                    onChangeExploration={val => {
                                        dispatch(
                                            createOfferPathsActions.setExplorationValue(
                                                val
                                            )
                                        );
                                    }}
                                    isSidePanel={isSidePanel}
                                    readOnly={isReadOnly}
                                />
                            </Card>
                            <Card>
                                <OfferPathSlots
                                    fetchDealIdsApi={fetchDealIds}
                                    creativesList={creativesList}
                                    offerWallsList={offerWallsList}
                                    action={createOfferPathsActions}
                                    data={data}
                                    pageId={""}
                                    // historyDataState={historyData}
                                    // historyDataRaw={historyDataRaw}
                                    historyApi={adsHistory}
                                    historyInnerTableApi={historyInnerTable}
                                    readOnly={isReadOnly}
                                    cloneSlot={cloneSlot}
                                    featureCopyData={{
                                        sourceId: [offerPathId],
                                        sourceName: name,
                                        sourcePage: "Offer Path",
                                        sourcePath: ["Offer Path", "Slots"],
                                        copiedData: get(
                                            data,
                                            "selectedListItems",
                                            []
                                        ).filter(
                                            selected =>
                                                !get(data, "deletedSlots", [])
                                                    .map(_ => _.id)
                                                    .includes(selected.id)
                                        ),
                                        type: "slots",
                                    }}
                                />
                            </Card>
                            {isSidePanel && (
                                <Card>
                                    <TargettingParams
                                        numberOfSets={numberOfSetsOfferPath}
                                        targetParams={get(
                                            targettingParam,
                                            "targetParams",
                                            {}
                                        )}
                                        dropDownState={get(
                                            targettingParam,
                                            "dropDownState",
                                            {}
                                        )}
                                        zipCodeState={get(
                                            targettingParam,
                                            "zipCodeState",
                                            {}
                                        )}
                                        actions={createOfferPathsActions}
                                        hash={"offerPath"}
                                        readOnly={isReadOnly}
                                        featureCopyData={{
                                            sourceId: [offerPathId],
                                            sourceName: name,
                                            sourcePage: "Offer Path",
                                            sourcePath: [
                                                "Offer Path",
                                                "Targetting Params",
                                            ],
                                            copiedData: get(
                                                targettingParam,
                                                "targetParams",
                                                {}
                                            ),
                                            type: "params",
                                        }}
                                        spamDropdownApiData={apiData}
                                        keyValuesNewType={true}
                                    />
                                </Card>
                            )}
                        </>
                    )}
                </div>
            </div>
            {historyData && historyData.length > 0 ? (
                <History
                    data={historyData}
                    rawData={historyDataRaw}
                    closeHistory={toggleHistoryVisibilty}
                    historyConfig={offerPathConfig}
                />
            ) : null}
            {showSaveConfirm && (
                <ConfirmAndSave
                    dataUpdated={offerpaths.createOfferPath}
                    dataBackup={offerpaths.createOfferPathBackup}
                    config={offerPathConfirmConfig}
                    saveCallback={() => {
                        if (
                            offerPathId ||
                            window.location?.pathname?.includes("/edit/")
                        ) {
                            updateOfferPath(
                                offerPathId ||
                                    window.location.pathname.split(
                                        "/edit/"
                                    )[1] ||
                                    null
                            );
                        } else {
                            createOfferPath();
                        }
                    }}
                    closeCallback={() => setShowSaveConfirm(false)}
                />
            )}
        </div>
    );
});

export default CreateOfferPath;
