export const BUTTON = {
    HISTORY: {
        label: "History",
        tooltip: "History",
    },
    NEW_TAB: {
        label: "Open",
        tooltip: "Open in new Tab",
    },
    CLOSE: {
        label: "Close",
        tooltip: "Close",
    },
};
