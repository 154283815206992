import { flowTargetingParams } from "./targettingParamsConstants";
import { newTP } from "./newTargettingParams";

export const FILTER_PARAMS = {
    SURVEY: {
        filterParams: [
            {
                id: "SurveyId",
                name: "Survey Id",
                children: [],
                // type1: "contains",
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "SurveyName",
                name: "Survey Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    SURVEY_PATH: {
        filterParams: [
            {
                id: "SurveyPathId",
                name: "Survey Path Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "SurveyId",
                name: "Survey Id",
                children: [],
                type1: "in",
                key: "surveyId",
                operator: "in",
                children: [],
                custom: true,
            },
            // {
            //     id: "SurveyPathName",
            //     name: "Survey Path Name",
            //     children: [],
            //     type1: "in",
            //     key: "name",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
        ],
    },
    QUESTION: {
        filterParams: [
            {
                id: "questionId",
                name: "Question Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "questionName",
                name: "Question Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    OFFER_PATH: {
        filterParams: [
            {
                id: "OfferPathId",
                name: "Offer Path Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "SurveyId",
                name: "Survey Id",
                children: [],
                type1: "in",
                key: "surveyId",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "Status",
                name: "Status",
                children: [],
                type1: "is",
                operator: "=",
                key: "status",
                filterObj: { k: "isActive", op: "=", v: [] },
            },
            // {
            //     id: "OfferPathName",
            //     name: "Offer Path Name",
            //     children: [],
            //     type1: "in",
            //     key: "name",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
        ],
    },
    AD_UNIT: {
        filterParams: [
            {
                id: "AdUnitId",
                name: "Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            // {
            //     id: "AdUnitName",
            //     name: "Ad Unit Name",
            //     children: [],
            //     type1: "in",
            //     key: "name",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
            {
                id: "adUnitId",
                name: "Ad Unit Id",
                children: [],
                type1: "in",
                key: "adUnitId",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    LEAD_DEFINITION: {
        filterParams: [
            {
                id: "leadDefinitionID",
                name: "Posting Spec ID",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            // {
            //     id: "leadDefinitionName",
            //     name: "Lead Definition Name",
            //     children: [],
            //     type1: "in",
            //     key: "name",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
        ],
    },
    OFFER_GROUP: {
        filterParams: [
            {
                id: "offerGroupID",
                name: "Offer Group ID",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "offerGroupType",
                name: "Type",
                children: [],
                type1: "in",
                key: "type",
                operator: "in",
                children: [],
            },
            {
                id: "categoryID",
                name: "Category ID",
                children: [],
                type1: "in",
                key: "hnpCategoryId",
                operator: "in",
                children: [],
                custom: true,
            },
            // {
            //     id: "offerGroupName",
            //     name: "Offer Group Name",
            //     children: [],
            //     type1: "in",
            //     key: "name",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
        ],
    },
    CATEGORIES: {
        filterParams: [
            {
                id: "categoryID",
                name: "Category ID",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            // {
            //     id: "categoryName",
            //     name: "Category Name",
            //     children: [],
            //     type1: "in",
            //     key: "name",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
        ],
    },
    GIFT_CARD: {
        filterParams: [
            {
                id: "giftCardID",
                name: "Promo ID",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    DEAL_CATEGORY: {
        filterParams: [
            {
                id: "dealCategoryID",
                name: "Category ID",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    OFFER_WALL: {
        filterParams: [
            {
                id: "offerWallID",
                name: "Offer Wall ID",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "SurveyId",
                name: "Survey Id",
                children: [],
                type1: "in",
                key: "sid",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    AB_TEST: {
        filterParams: [
            // {
            //     id: "TargettingParameters",
            //     name: "Targetting Parameters",
            //     children: [
            //         {
            //             id: "UTMSource",
            //             name: "UTM Source",
            //             type1: "in",
            //             type2: "utmData",
            //             operator: "in",
            //         },
            //         // {
            //         //     id: "Keywords",
            //         //     name: "Keywords",
            //         //     type1: "in",
            //         //     type2: "utmData",
            //         //     operator: "in",
            //         // },
            //         // { id: "Region", name: "Region" },
            //         // { id: "AgeRange", name: "Age Range" },
            //         // { id: "ZipCode", name: "Zip Code" },
            //         // { id: "OfferPath", name: "Offer Paths" },
            //         // { id: "Gender", name: "Gender" },
            //         // { id: "Country", name: "Country" },
            //         // { id: "TimeOfDay", name: "Time of day" },
            //         // { id: "UTMCampaign", name: "UTM Campaign" },
            //     ],
            // },
            {
                id: "Status",
                name: "Status",
                children: [],
                type1: "is",
                operator: "=",
                key: "status",
                filterObj: { k: "status", op: "=", v: [] },
            },
            {
                id: "Name",
                name: "Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
            },
            // {
            //     id: "SurveyId",
            //     name: "Survey Id",
            //     children: [],
            //     type1: "in",
            //     key: "surveyId",
            //     operator: "in",
            //     children: [],
            // },
        ],
    },
    SURVEY_CONFIG: {
        filterParams: [],
    },
    FEATURE_MAP: {
        filterParams: [],
    },
    FEATURE_SETS: {
        filterParams: [],
    },
    ROAS: {
        filterParams: [
            {
                id: "Status",
                name: "Status",
                children: [],
                type1: "is",
                operator: "=",
                key: "status",
                filterObj: { k: "status", op: "=", v: [] },
            },
            {
                id: "Publisher",
                name: "Publisher",
                children: [],
                type1: "in",
                key: "pubCode",
                operator: "in",
                children: [],
            },
        ],
    },
    DTO: {
        filterParams: [
            {
                id: "gift_card_id",
                name: "Gift Card ID",
                children: [],
                type1: "in",
                key: "gift_card_id",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    FEED_MANAGER: {
        filterParams: [
            //For now commented this as need to debug from backend for in operator
            // {
            //     id: "deliveryTypes",
            //     name: "Delivery Type",
            //     children: [],
            //     type1: "in",
            //     key: "deliveryTypes",
            //     operator: "in",
            //     children: [],
            // },
        ],
    },
    OFFLINE_CONVERSION: {
        filterParams: [],
    },
    SCRUB_DASHBOARD: {
        filterParams: [],
    },
    USER_JOURNEY: {
        filterParams: [
            {
                id: "user_details",
                name: "User Details",
                children: [
                    {
                        id: "First Name",
                        name: "First Name",
                        children: [],
                        type1: "in",
                        key: "first_name",
                        operator: "in",
                        children: [],
                    },
                    {
                        id: "Last Name",
                        name: "Last Name",
                        children: [],
                        type1: "in",
                        key: "last_name",
                        operator: "in",
                        children: [],
                    },
                    {
                        id: "phone",
                        name: "Phone number",
                        children: [],
                        type1: "in",
                        key: "phone_number",
                        operator: "in",
                        children: [],
                    },
                    {
                        id: "Email",
                        name: "Email",
                        children: [],
                        type1: "in",
                        key: "email",
                        operator: "in",
                        children: [],
                    },
                    {
                        id: "vertical",
                        name: "Vertical",
                        children: [],
                        type1: "in",
                        key: "domain",
                        operator: "in",
                        children: [],
                    },
                    {
                        id: "Survey_User",
                        name: "Survey",
                        children: [],
                        type1: "in",
                        key: "svid",
                        operator: "in",
                        children: [],
                    },
                ],
            },
        ],
    },
    USER_JOURNEY_DETAIL: {
        filterParams: [
            {
                id: "user_details",
                name: "User Details",
                children: [
                    {
                        id: "Activity",
                        name: "Activity",
                        children: [],
                        type1: "in",
                        key: "evt_id",
                        operator: "in",
                    },
                ],
            },
        ],
    },
    BULK_SCRUB: {
        filterParams: [
            {
                id: "bulkScrub",
                name: "Scrub",
                children: [
                    {
                        id: "flagStatus",
                        name: "Flag Status",
                        children: [],
                        type1: "in",
                        operator: "in",
                        key: "flagStatus",
                        filterObj: {},
                    },

                    // {
                    //     id: "Status",
                    //     name: "Status",
                    //     children: [],
                    //     type1: "is",
                    //     operator: "=",
                    //     key: "status",
                    //     filterObj: { k: "isActive", op: "=", v: [] },
                    // },
                ],
            },
        ],
    },
    USERS: {
        filterParams: [],
    },
    USER_GROUP: {
        filterParams: [],
    },
    LOGOLISTING: {
        filterParams: [],
    },
    JTLISTING: {
        filterParams: [],
    },
    CREATIVES: {
        filterParams: [
            {
                id: "creativeId",
                name: "Creative Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "creativeName",
                name: "Creative Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    TEMPLATE: {
        filterParams: [
            {
                id: "template",
                name: "Template",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    FLOW_QUESTION: {
        filterParams: [
            {
                id: "FlowQuestionId",
                name: "Question Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "FlowQuestionName",
                name: "Question Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    FLOW: {
        filterParams: [
            {
                id: "FlowId",
                name: "Flow Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "FlowName",
                name: "Flow Name",
                children: [],
                type1: "in",
                key: "displayName",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "Regex",
                name: "Regex",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
            },
            {
                id: "domain",
                name: "Domain",
                children: [],
                type1: "in",
                key: "domain",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "flow_vertical",
                name: "Vertical",
                children: [],
                type1: "in",
                key: "vertical",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "flow_status",
                name: "Flow Status",
                children: [],
                type1: "in",
                key: "isLive",
                operator: "in",
                children: [],
            },
        ],
    },
    CAMPAIGN: {
        filterParams: [
            {
                id: "CampId",
                name: "Campaign Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "CampName",
                name: "Campaign Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    CONTAINER: {
        filterParams: [
            {
                id: "ContainerId",
                name: "Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "ContainerName",
                name: "Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "ContainerType",
                name: "Type",
                children: [],
                type1: "in",
                key: "type",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    TEMPLATE: {
        filterParams: [
            {
                id: "TemplateId",
                name: "Feature Group Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "TemplateName",
                name: "Feature Group Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    LAYOUT: {
        filterParams: [
            {
                id: "LayoutId",
                name: "Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "LayoutName",
                name: "Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "LayoutType",
                name: "Type",
                children: [],
                type1: "in",
                key: "type",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "LayoutSubType",
                name: "Subtype",
                children: [],
                type1: "in",
                key: "subType",
                operator: "in",
                children: [],
                custom: true,
            },
        ],
    },
    COLLECTION: {
        filterParams: [
            {
                id: "CollectionId",
                name: "Collection Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "CollectionName",
                name: "Collection Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
                custom: true,
            },
            {
                id: "Type",
                name: "Type",
                children: [],
                type1: "in",
                operator: "in",
                key: "containerType",
            },
        ],
    },
    CREATE_FLOW: {
        filterParams: [
            // {
            //     id: "Survey",
            //     name: "Survey",
            //     type1: "in",
            //     key: "survey",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
            // {
            //     id: "Register",
            //     name: "Register",
            //     type1: "in",
            //     key: "register",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
            // {
            //     id: "Offer",
            //     name: "Offer",
            //     type1: "in",
            //     key: "offer",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
            // {
            //     id: "Listing",
            //     name: "Listing",
            //     type1: "in",
            //     key: "listing",
            //     operator: "in",
            //     children: [],
            //     custom: true,
            // },
            {
                id: "TargettingParameters",
                name: "Targetting Parameters",
                children: newTP.map(_ => ({
                    id: _.name,
                    name: _.opsName,
                    type1: "in",
                    operator: "in",
                    children: [],
                    key: _.name,
                })),
            },
        ],
    },
    ADVERTISER: {
        filterParams: [
            {
                id: "Advertiser Id",
                name: "Advertiser Id",
                children: [],
                type1: "in",
                key: "id",
                operator: "in",
                children: [],
            },
            {
                id: "Advertiser Name",
                name: "Advertiser Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
            },
        ],
    },
    SITE_CONFIG: {
        filterParams: [
            {
                id: "Site Config Name",
                name: "Name",
                children: [],
                type1: "in",
                key: "name",
                operator: "in",
                children: [],
            },
            {
                id: "Domain Name",
                name: "Domain Name",
                children: [],
                type1: "in",
                key: "domain",
                operator: "in",
                children: [],
            },
            {
                id: "Product Short Code",
                name: "Product Short Code",
                children: [],
                type1: "in",
                key: "productShortCode",
                operator: "in",
                children: [],
            },
        ],
    },
};
