import React, { useEffect, useState, useRef } from "react";
import styles from "./RegisterDetails.module.scss";
import Icon from "../../Shared/Icon/Icon";
import { cloneDeep, get, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { toastListActions } from "../../store/toastList/toastList";
import {
    INTENT_DROPDOWN,
    REGISTER_DETAIL_DROPDOWN,
} from "../../constants/collectionConstants";
import { useSelector } from "react-redux";
import InputList from "../InputList/InputList";
import {
    collectionActions,
    fetchAllHnpCategories,
    fetchPartnerData,
} from "../../store/flowmanager/collection";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { useParams } from "react-router-dom";
import { getPreviewObj, getRandomId } from "../../utils/globalUtils";

import { arrayMove } from "@dnd-kit/sortable";
import SortableWrapper from "../../Shared/Sortable/SortableWrapper";
import SortableItem from "../../Shared/Sortable/SortableItem";
import DragHandle from "../../Shared/Sortable/DragHandle";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import Select from "../../Shared/Select/Select";
import { fetchAllTemplate } from "../../store/flowmanager/template";
import CreateTemplate from "../../Pages/CreateTemplate/CreateTemplate";
import FlowTcpa from "../FlowTcpa/FlowTcpa";
import { fetchAllLayout } from "../../store/flowmanager/layout";
import LayoutEditComp from "../LayoutEditComp/LayoutEditComp";
import { v4 as uuidv4 } from "uuid";
import CreateFlowCategories from "../../Pages/CreateFlowCategories/CreateFlowCategories";
import LinkOutOffer from "../LinkOutOffer/LinkOutOffer";
import { useFetchAvailableTargetingParams } from "../../generalApi/generalApi";
import {
    findByValue,
    createLinkOutData,
    getFinalTargetingData,
} from "../../utils/collectionUtils";
import { AdUnitCard, HnPData } from "../CollectionDetails/CollectionDetails";
import { fetchCreateSurveyPathData } from "../../store/listings/surveyPaths";

const RegisterDetails = ({
    className,
    isReadOnly = false,
    checkboxControl = false,
    isSidePanel = false,
    inContainer = false,
    selectedFlowDomain = null,
}) => {
    const dispatch = useDispatch();
    const configuration = null;
    const collectionState = useSelector(state => state.flowmanager.collection);
    const surveyPaths = useSelector(state => state.listings.surveyPaths);
    const {
        createSurveyPath: { adUnits = [] },
        adTypeDataLoading,
    } = surveyPaths;
    const items = get(collectionState, "createCollection.items", []);
    const createCollectionState = get(collectionState, "createCollection", []);
    const collectionType = createCollectionState?.type?.id;
    // container redux state
    const containerState = useSelector(
        state => state.flowmanager.container.createContainer
    );
    const urlParams = useParams();
    const [activeDetail, setActiveDetail] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);
    const [emailPartnerData, setEmailPartnerData] = useState([]);
    const [phonePartnerData, setPhonePartnerData] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [individualLayoutData, setIndividualLayoutData] = useState([]);
    const [layoutDataCopy, setLayoutDataCopy] = useState([]);
    const [layoutDefaultConfig, setLayoutDefaultConfig] = useState([]);
    const [addConfigList, setAddConfigList] = useState([]);
    const [showConfigSelect, setShowConfigSelect] = useState(false);
    const [viewIsClick, setViewIsClick] = useState(false);
    const [createPhasePopup, setCreatePhasePopup] = useState(false);
    const [hnpData, setHnpData] = useState([]);
    const [hnpLeads, setHnpLeads] = useState([]);
    const [activeHnpId, setActiveHnpId] = useState(null);
    const [adTypeList, setAdTypeList] = useState([]);

    const [previewItem, setPreviewItem] = useState({});
    const [layoutDropdownTypeData, setLayoutDropdownTypeData] = useState([]);

    const addTcpaBtnRef = useRef();
    const wrapperRefTestOnly = useRef();

    const templateOpenRef = useRef(null);
    const saveFunctionCallRef = useRef(null);
    const layoutOpenRef = useRef(null);
    const editHnpSidePanelRef = useRef(null);
    const createFlowPageRef = useRef(null);
    const createTemplateState = useSelector(
        state => state.flowmanager.template.createTemplate
    );
    const addAdUnitRef = useRef(null);
    const linkOutRef = useRef(null);
    const [linkoutOffer, setLinkoutOffer] = useState(null);
    const [editLinkOut, setEditLinkOut] = useState({ data: [], index: 0 });
    const { apiData = [] } = useFetchAvailableTargetingParams();

    const creativeIframe = useRef(null);

    let cancelLayoutEdit = true;

    useEffect(() => {
        setAddConfigList(
            layoutDefaultConfig?.filter(
                _ =>
                    !individualLayoutData[0]?.configurations
                        ?.map(_ => _.key)
                        .includes(_.key)
            )
        );
    }, [layoutDefaultConfig, individualLayoutData]);

    useEffect(() => {
        assignRank(items);
    }, [items.length]);

    useEffect(() => {
        dispatch(fetchAllTemplate()).then(res => setTemplates(res.payload));
        dispatch(fetchAllLayout()).then(res =>
            setLayouts(get(res, "payload", []))
        );

        dispatch(fetchPartnerData("email")).then(res =>
            setEmailPartnerData(res.payload)
        );

        dispatch(fetchPartnerData("mobileNo")).then(res =>
            setPhonePartnerData(res.payload)
        );
        if (isEmpty(adUnits)) dispatch(fetchCreateSurveyPathData());
        return () => {
            setTemplates([]);
            setLayouts([]);
        };
    }, []);

    useEffect(() => {
        const setAdUnitList = () => {
            setAdTypeList(adUnits);
        };
        setAdUnitList();
    }, [adUnits]);

    useEffect(() => {
        if (createCollectionState.type && layouts?.length) {
            setLayoutDropdownTypeData(
                layouts.filter(
                    (obj, idx) =>
                        obj?.type === collectionType && obj?.subType === "INNER"
                )
            );
        }
    }, [createCollectionState.type, layouts]);

    useEffect(() => {
        if (!isEmpty(activeDetail)) {
            const data = getPreviewObj(
                inContainer
                    ? isEmpty(activeDetail.layoutConfig)
                        ? {
                              ...activeDetail,
                              layoutConfig: containerState?.layout,
                          }
                        : activeDetail
                    : activeDetail,
                collectionState?.createCollection?.type?.id,
                inContainer ? containerState?.outerLayout : {},
                inContainer ? containerState?.featureGroupProperties : {}
            );
            setPreviewItem(data);
            setHnpData(
                collectionState.createCollection?.hnpCategories?.filter(_ =>
                    activeDetail?.hnpLeadIds?.includes(_.hnpId)
                ) || []
            );
            setHnpLeads(activeDetail?.hnpLeadIds || []);
        }
    }, [activeDetail, inContainer, containerState]);

    useEffect(() => {
        setHnpData(
            collectionState.createCollection?.hnpCategories?.filter(_ =>
                hnpLeads?.includes(_.hnpId)
            ) || []
        );
    }, [hnpLeads, activeDetail.hnpLeadIds]);

    const assignTempId = arr => {
        return JSON.parse(JSON.stringify(arr)).map(item => {
            item.tempId = uuidv4();
            return item;
        });
    };

    const assignRank = data => {
        let initialData = cloneDeep(data);
        let newItems = [];
        let rank = 0;
        initialData.forEach(_ => {
            if (_.rank !== -1) {
                newItems.push({
                    ..._,
                    rank: rank + 1,
                    tcpa: assignTempId(_.tcpa),
                });
                rank++;
            } else {
                newItems.push({
                    ..._,
                    rank: -1,
                    tcpa: assignTempId(_.tcpa),
                });
            }
        });
        dispatch(collectionActions.setItems(newItems));
    };

    const collectionOptionData =
        get(configuration, "details", [])?.length > 0
            ? REGISTER_DETAIL_DROPDOWN.filter(
                  ele => !ele.id.includes(get(configuration, "details", []))
              )
            : REGISTER_DETAIL_DROPDOWN;

    const collectionDropdown = {
        className: `${styles.editDropdown}`,
        options: collectionOptionData,
        idField: "id",
        keyField: "label",
        label: "Search by name",
        showCreate: false,
    };

    const sidePanelRef = useRef();

    const addDetail = get(
        collectionState,
        "createCollection.itemsDisable",
        false
    );

    const keywordsInputJson = {
        type: "",
    };

    const addDefaultDetail = () => {
        dispatch(
            collectionActions.setItems([
                ...items,
                {
                    properties: {
                        fields: [],
                        linkOutOffer: null,
                    },
                    tcpa: [],
                    isActive: true,
                    id: uuidv4(),
                    isNew: true,
                },
            ])
        );
    };

    const removeDetail = item => {
        let itemsClone = cloneDeep([...items]);
        itemsClone.find(value => value.rank === item?.rank).isActive = false;
        let updatedItemsList = [];
        let rank = 1;
        itemsClone.forEach(_ => {
            if (_?.isActive) {
                let updatedItem = {
                    ..._,
                    rank: rank,
                };
                updatedItemsList.push(updatedItem);
                rank++;
            } else {
                let updatedItem = {
                    ..._,
                    rank: -1, //Setting this to -1 as need to update the isActive status to backend
                };
                updatedItemsList.push(updatedItem);
            }
        });
        dispatch(collectionActions.setItems(updatedItemsList));
    };

    const onSortEnd = async event => {
        const { active, over } = event;
        if (active && over && active?.id !== over?.id) {
            const oldIndex = items.findIndex(_ => _.id === active.id);
            const newIndex = items.findIndex(_ => _.id === over.id);
            const sortedItems = arrayMove(items, oldIndex, newIndex);
            assignRank(sortedItems);
        }
    };

    const editDetail = async (detail, isPreview = false) => {
        setIndividualLayoutData([detail?.layoutConfig]);
        setLayoutDataCopy([detail?.layoutConfig]);
        if (!isPreview) {
            setLayoutDefaultConfig(
                layouts.find(_ => _.name === detail?.layoutConfig?.name)
                    ?.configurations || []
            );
        } else {
            setLayoutDefaultConfig([]);
        }
        setLinkoutOffer(
            !isEmpty(detail.properties.linkOutOffer)
                ? createLinkOutData(detail.properties.linkOutOffer, true)
                : null
        );
        setActiveDetail(detail);
        setHnpLeads(detail.hnpLeadIds);
        if (!isPreview) sidePanelRef.current.open();
    };

    const addTcpa = () => {
        const tempTcpa = [
            ...activeDetail.tcpa,
            {
                html: "",
                jornaya: true,
                trustedform: true,
                implicit: true,
                tempId: uuidv4(),
                intent: [],
                visibility: ["onLoad"],
                marketingPartners: [],
                useGlobal: false,
            },
        ];

        const tempLayoutConfig = activeDetail?.layoutConfig || null;

        const tempItems = JSON.parse(JSON.stringify(items)).map(_ => {
            if (_.rank === activeDetail.rank) {
                _.properties = {
                    ...activeDetail.properties,
                    linkOutOffer: !isEmpty(linkoutOffer)
                        ? createLinkOutData(linkoutOffer)
                        : null,
                };
                _.tcpa = tempTcpa;
                _.layoutConfig = tempLayoutConfig;
                _.hnpLeadIds = activeDetail.hnpLeadIds;
            }
            return _;
        });

        setActiveDetail(tempItems.find(_ => _.rank === activeDetail.rank));
        const offSet = addTcpaBtnRef?.current?.offsetTop;
        setTimeout(() => {
            wrapperRefTestOnly.current.scrollTop = offSet;
        }, 10);
    };

    const deleteTcpa = deletedId => {
        const tempTcpa = JSON.parse(JSON.stringify(activeDetail.tcpa)).filter(
            _ => _.tempId !== deletedId
        );
        setActiveDetail({ ...activeDetail, tcpa: tempTcpa });
    };

    const saveTcpa = () => {
        const intentValues = activeDetail.tcpa.map(_ => _.intent);
        const emailCount = intentValues.filter(_ =>
            _.includes("email")
        )?.length;
        const mobileNoCount = intentValues.filter(_ =>
            _.includes("mobileNo")
        )?.length;

        const isValidTCPA =
            activeDetail.tcpa.every(
                _ =>
                    (_.useGlobal && _.intent.length > 0) ||
                    (!_.useGlobal && !!_.html)
            ) &&
            emailCount < 2 &&
            mobileNoCount < 2;

        if (isValidTCPA) {
            dispatch(
                collectionActions.setItems(
                    JSON.parse(JSON.stringify(items)).map(_ => {
                        if (_.rank === activeDetail.rank) {
                            _.tcpa = activeDetail.tcpa;
                            _.properties = {
                                ...activeDetail.properties,
                                linkOutOffer: !isEmpty(linkoutOffer)
                                    ? createLinkOutData(linkoutOffer)
                                    : null,
                            };
                            _.hnpLeadIds = activeDetail.hnpLeadIds;
                            _.layoutConfig = !isEmpty(individualLayoutData)
                                ? individualLayoutData[0]
                                : null;
                            _.layoutConfigCopy = !isEmpty(individualLayoutData)
                                ? individualLayoutData[0]
                                : null;
                        }
                        return _;
                    })
                )
            );
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        emailCount > 1 || mobileNoCount > 1
                            ? "Email and Mobile No can be mapped to only one TCPA."
                            : "Please enter all mandatory fields in TCPA.",
                })
            );
            return;
        }
        setActiveDetail({});
        sidePanelRef.current.close();
    };

    const cancelTcpa = () => {
        setActiveDetail({});
    };

    const addProperties = (data, item) => {
        dispatch(
            collectionActions.setItems(
                JSON.parse(JSON.stringify(items)).map(_ => {
                    if (_.rank === item.rank) {
                        _.properties = {
                            fields: data || [],
                            linkOutOffer: !isEmpty(linkoutOffer)
                                ? createLinkOutData(linkoutOffer)
                                : null,
                            buttons: ["next"],
                        };
                    }
                    return _;
                })
            )
        );
    };

    const updateActiveTcpa = (id, data) => {
        const activeTcpa = JSON.parse(JSON.stringify(activeDetail.tcpa)).map(
            _ => {
                if (_.tempId === id) {
                    return data;
                }
                return _;
            }
        );
        setActiveDetail({ ...activeDetail, tcpa: activeTcpa });
    };

    const selectLayout = data => {
        const item = layouts.filter(_ => _.name === data?.name);
        setActiveDetail({
            ...activeDetail,
            // properties: {
            //     ...activeDetail.properties,
            //     layout: data?.name,
            // },
            layoutConfig: item[0] || null,
        });
        setIndividualLayoutData([...item]);
        setLayoutDataCopy(item);
        setLayoutDefaultConfig(
            layouts.find(_ => _.name === item[0]?.name)?.configurations || []
        );
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={!isSidePanel ? "flexHeightTab" : ""}
        >
            <div className={`row`}>
                <p className={`headerTitle`}>{"Details"}</p>
                <p className={`headerTitleRequired`}>Collection item</p>
                {items.length > 0 && items.every(_ => _.rank) && (
                    <SortableWrapper list={items} onDrag={onSortEnd}>
                        {items
                            ?.filter(_ => _.isActive)
                            ?.map((item, index) => {
                                return (
                                    <SortableItem
                                        key={item.id}
                                        id={item.id}
                                        dragHandle
                                    >
                                        <div
                                            className={`${styles["question-details-wrap"]} ${className}`}
                                        >
                                            <div>
                                                <div
                                                    className={`${styles["add-new-question"]} ${className} ${styles["cardNumbering"]}`}
                                                    data-index={index + 1}
                                                >
                                                    <div
                                                        className={
                                                            styles[
                                                                "question-container"
                                                            ]
                                                        }
                                                    >
                                                        <InputList
                                                            inputJson={
                                                                keywordsInputJson
                                                            }
                                                            type="singleDropdownCreateTags"
                                                            title={"Collection"}
                                                            dropDownJson={
                                                                collectionDropdown
                                                            }
                                                            mainState={
                                                                item.properties
                                                                    .fields
                                                            }
                                                            setMainState={data =>
                                                                addProperties(
                                                                    data,
                                                                    item
                                                                )
                                                            }
                                                            readOnly={
                                                                isReadOnly
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={`dp-parent dp-parent-ver-center mb-20 ${styles.layoutWrapper}`}
                                                    >
                                                        <div
                                                            className={`${styles.layoutKey}`}
                                                        >
                                                            Layout Name :
                                                        </div>
                                                        <div
                                                            className={`${styles.value} ${styles.layoutValue}`}
                                                            title={
                                                                item?.layoutConfig
                                                                    ? item
                                                                          ?.layoutConfig
                                                                          ?.name
                                                                    : "-"
                                                            }
                                                        >
                                                            {item?.layoutConfig
                                                                ? item
                                                                      ?.layoutConfig
                                                                      ?.name
                                                                : "-"}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`dp-parent ${styles.tcpaDetail}`}
                                                    >
                                                        <div
                                                            className={
                                                                styles.key
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.tcpaHead
                                                                }
                                                            >
                                                                TCPA :
                                                            </span>{" "}
                                                            <span
                                                                className={
                                                                    styles.tcpaHeadValue
                                                                }
                                                            >
                                                                {!isEmpty(
                                                                    item.tcpa
                                                                ) ||
                                                                item?.tcpa.some(
                                                                    _ =>
                                                                        _.isActive
                                                                )
                                                                    ? "Added"
                                                                    : "-"}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.key
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.tcpaHead
                                                                }
                                                            >
                                                                Hnp Category :
                                                            </span>{" "}
                                                            <span
                                                                className={
                                                                    styles.tcpaHeadValue
                                                                }
                                                            >
                                                                {item
                                                                    ?.hnpLeadIds
                                                                    ?.length > 0
                                                                    ? "Added"
                                                                    : "-"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`dp-parent dp-parent-ver-center p-10 ${styles["edit-options"]}`}
                                                    >
                                                        {!isReadOnly && (
                                                            <DragHandle
                                                                id={item.id}
                                                                customClass="mr-20"
                                                            />
                                                        )}

                                                        <div
                                                            className={`${styles.option} dp-parent circleIconColor`}
                                                            onClick={() =>
                                                                editDetail(item)
                                                            }
                                                        >
                                                            <Icon
                                                                icon="Edit"
                                                                size="16px"
                                                                enableHover={
                                                                    true
                                                                }
                                                            />
                                                        </div>

                                                        <div
                                                            className={`${styles.option} dp-parent circleIconColor`}
                                                            onClick={async () => {
                                                                await editDetail(
                                                                    item,
                                                                    true
                                                                );
                                                                await layoutOpenRef.current.open();
                                                            }}
                                                        >
                                                            <Icon
                                                                icon="View"
                                                                size="16px"
                                                                enableHover={
                                                                    true
                                                                }
                                                            />
                                                        </div>

                                                        {!isReadOnly && (
                                                            <div
                                                                className={`${styles.option} dp-parent circleIconColor circleIconColor--delete`}
                                                                onClick={() => {
                                                                    item.id
                                                                        ? setDeletePopup(
                                                                              item
                                                                          )
                                                                        : removeDetail(
                                                                              item
                                                                          );
                                                                }}
                                                            >
                                                                <Icon
                                                                    icon="Delete"
                                                                    size="16px"
                                                                    enableHover={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {deletePopup === item && (
                                                        <div
                                                            className={
                                                                styles.deletePopup
                                                            }
                                                        >
                                                            <PopupTargettingParams
                                                                setName={item}
                                                                Rfunction={
                                                                    removeDetail
                                                                }
                                                                setShowPopup={
                                                                    setDeletePopup
                                                                }
                                                                customText={`Do you wish to delete this item?`}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </SortableItem>
                                );
                            })}
                    </SortableWrapper>
                )}

                {
                    <Button
                        className={styles["add-question-button"]}
                        onClick={addDefaultDetail}
                        btnTheme={ButtonTypes.primaryHover_btn}
                        disable={isReadOnly || addDetail}
                    >
                        Add items
                    </Button>
                }
            </div>
            <SidePanelInfinity
                width={"1330px"}
                title={"Edit Collection Item"}
                ref={sidePanelRef}
                disableSaveBtn={isReadOnly}
                onSave={saveTcpa}
                onClose={cancelTcpa}
                dialogContentClassName={styles.panelNoPadding}
            >
                <div className={`dp-parent w-100 ${styles.itemContainer}`}>
                    <div
                        ref={wrapperRefTestOnly}
                        className={`pt-20 pb-20 pr-20 ${styles.itemWrapper}`}
                    >
                        {activeDetail?.properties?.fields?.length > 0 && (
                            <div className={styles.detailPropertiesWrap}>
                                <p className={styles.sideTitle}>Details</p>
                                <div className="dp-parent gap-10 flex-wrap mt-10">
                                    {activeDetail?.properties?.fields?.map(
                                        _ => (
                                            <div
                                                className={styles.pill}
                                                key={
                                                    "detailpill-" +
                                                    getRandomId()
                                                }
                                            >
                                                {_}
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                        {/* <div className="mb-20">
                    <div className="dp-parent dp-parent-hor-space-between dp-child-ver-center">
                        <p className={`${styles.sideTitle} mb-10`}>
                            Template Name
                        </p>
                        {activeDetail.properties?.template?.id && (
                            <div
                                className="dp-parent gap-5 cursorPointer circleIconColor"
                                onClick={() => {
                                    setTimeout(() => {
                                        templateOpenRef.current.open();
                                        dispatch(
                                            fetchTemplate(
                                                activeDetail.properties.template
                                                    ?.id
                                            )
                                        );
                                    });

                                    setViewIsClick(true);
                                }}
                            >
                                <Icon icon={"View"} size="18px" />
                                Edit
                            </div>
                        )}
                    </div>
                    <Select
                        options={templates}
                        idField="id"
                        keyField="name"
                        label="Select Template"
                        selectedId={
                            activeDetail.properties?.template?.id
                                ? parseInt(
                                      activeDetail.properties?.template?.id
                                  )
                                : null
                        }
                        onSelect={option => {
                            if (option?.id) {
                                setActiveDetail({
                                    ...activeDetail,
                                    properties: {
                                        ...activeDetail.properties,
                                        template: {
                                            id: option.id,
                                            properties: {
                                                header1: "Register,  Apply",
                                            },
                                        },
                                    },
                                });
                            }
                        }}
                        showCreate
                        onLoadSelectReturn={false}
                        onCreate={item => {
                            setCreatePhasePopup(true);
                            templateOpenRef.current.open();
                            setTimeout(() => {
                                dispatch(
                                    templateActions.setTemplateName(item.name)
                                );
                            });
                        }}
                    />
                </div> */}

                        {
                            <div className="mb-20">
                                <div className={`${styles.tcpaHeader}`}>
                                    <p>Ad Unit Config</p>
                                </div>
                                <div>
                                    <Button
                                        onClick={() => {
                                            setEditLinkOut({
                                                data: [],
                                                index: 0,
                                            });
                                            addAdUnitRef?.current?.open();
                                        }}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={isReadOnly}
                                    >
                                        Add Config
                                    </Button>
                                </div>

                                {linkoutOffer?.length > 0 && (
                                    <div className="mt-20">
                                        {linkoutOffer?.map((_, index) => {
                                            let selectedAds =
                                                !isEmpty(_.targettingParams) &&
                                                _.targettingParams.value?.map(
                                                    element => {
                                                        return {
                                                            ...element,
                                                            value: element.value[0]?.value?.filter(
                                                                _ =>
                                                                    _.linkOutQuestion
                                                            ),
                                                        };
                                                    }
                                                );
                                            let tagData = selectedAds
                                                ?.filter(_ => !isEmpty(_.value))
                                                ?.map(_ => {
                                                    return {
                                                        valueOne:
                                                            question?.properties
                                                                ?.question_details
                                                                ?.id,
                                                        valueSecond:
                                                            _.value[0].value,
                                                        valueName:
                                                            question?.properties
                                                                ?.question_details
                                                                ?.name,
                                                    };
                                                });
                                            let answersSelected = tagData.map(
                                                _ => _.valueSecond
                                            );
                                            let finalTargettingData =
                                                getFinalTargetingData(_);
                                            let targetingsAdded = findByValue(
                                                finalTargettingData,
                                                apiData
                                            );
                                            let finalData = {
                                                adUnitId: _.adUnitId,
                                                targettingParams: {
                                                    type: "OR",
                                                    value: finalTargettingData,
                                                },
                                            };

                                            return (
                                                <>
                                                    <AdUnitCard
                                                        data={finalData}
                                                        adUnitList={adTypeList}
                                                        adTypeDataLoading={
                                                            adTypeDataLoading
                                                        }
                                                        showAnswers={false}
                                                        answersSelected={
                                                            answersSelected
                                                        }
                                                        targetingsAdded={
                                                            targetingsAdded
                                                        }
                                                        setLinkoutOffer={
                                                            setLinkoutOffer
                                                        }
                                                        setEditLinkOut={
                                                            setEditLinkOut
                                                        }
                                                        linkoutOffer={
                                                            linkoutOffer
                                                        }
                                                        index={index}
                                                        addAdUnitRef={
                                                            addAdUnitRef
                                                        }
                                                        isRegister={true}
                                                        readOnly={isReadOnly}
                                                    />
                                                </>
                                            );
                                        })}
                                    </div>
                                )}

                                <SidePanelInfinity
                                    title={"Ad Unit Config"}
                                    ref={addAdUnitRef}
                                    saveButtonText="Save"
                                    disableSaveBtn={isReadOnly}
                                    onSave={() => {
                                        let adUnitData =
                                            linkOutRef?.current.onSave(true);
                                        if (!isEmpty(adUnitData)) {
                                            let updatedLinkOut = [];
                                            if (!isEmpty(linkoutOffer)) {
                                                updatedLinkOut = [
                                                    ...linkoutOffer,
                                                ];
                                                updatedLinkOut[
                                                    editLinkOut?.index
                                                ] = adUnitData;
                                            }
                                            isEmpty(editLinkOut?.data) &&
                                            !isEmpty(linkoutOffer)
                                                ? setLinkoutOffer([
                                                      adUnitData,
                                                      ...linkoutOffer,
                                                  ])
                                                : isEmpty(linkoutOffer)
                                                ? setLinkoutOffer([adUnitData])
                                                : setLinkoutOffer(
                                                      updatedLinkOut
                                                  );
                                            addAdUnitRef?.current?.close();
                                        }
                                    }}
                                >
                                    <LinkOutOffer
                                        adUnitList={adTypeList}
                                        // questionDetails={
                                        //     question?.properties
                                        //         ?.question_details
                                        // }
                                        linkoutOffer={editLinkOut?.data}
                                        adTypeDataLoading={adTypeDataLoading}
                                        ref={linkOutRef}
                                        readOnly={isReadOnly}
                                    />
                                </SidePanelInfinity>
                            </div>
                        }

                        <div className="mb-20">
                            <div className="dp-parent dp-parent-hor-space-between dp-child-ver-center">
                                <p className={`${styles.sideTitle} mb-10`}>
                                    Layout
                                </p>
                                {!isEmpty(activeDetail?.layoutConfig?.name) ? (
                                    <p
                                        className={`${styles.sideTitle} ${styles.sideEditTitle} mb-10`}
                                        onClick={() => {
                                            layoutOpenRef.current.open();
                                        }}
                                    >
                                        <Icon
                                            icon="EditLayoutProperties"
                                            size="16px"
                                            enableHover={true}
                                        />

                                        {activeDetail?.layoutConfig
                                            ?.configurations?.length > 0
                                            ? " Layout Features"
                                            : "View Preview"}
                                    </p>
                                ) : null}
                            </div>
                            <Select
                                options={layoutDropdownTypeData}
                                idField="name"
                                keyField="name"
                                label="Select Layout"
                                selectedId={
                                    activeDetail?.layoutConfig?.name
                                        ? layoutDropdownTypeData.find(
                                              (obj, idx) =>
                                                  obj.name ===
                                                  activeDetail?.layoutConfig
                                                      ?.name
                                          )
                                            ? activeDetail?.layoutConfig?.name
                                            : null
                                        : null
                                }
                                onSelect={selectLayout}
                                onLoadSelectReturn={false}
                                isDisabled={isReadOnly}
                                portal={false}
                                CustomRender={item => {
                                    return (
                                        <div className="dp-parent gap-10 w-100">
                                            <div> {item.option.name}</div>
                                            {item.option.configurations.length >
                                                0 && (
                                                <div className="iconInsideDiv">
                                                    <Icon
                                                        icon="Customise"
                                                        size="16px"
                                                        className="circleIconColor"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                                closeIcon={true}
                            />
                        </div>
                        <p className={`${styles.sideTitle} mb-10`}>TCPA</p>
                        {activeDetail?.tcpa?.length > 0 &&
                            activeDetail?.tcpa?.map((tcpa, index) => {
                                return (
                                    <FlowTcpa
                                        key={tcpa.tempId}
                                        checkboxControl={checkboxControl}
                                        tcpaData={tcpa}
                                        isReadOnly={isReadOnly}
                                        onChange={data =>
                                            updateActiveTcpa(tcpa.tempId, data)
                                        }
                                        onDelete={() => deleteTcpa(tcpa.tempId)}
                                        emailPartnerData={emailPartnerData}
                                        phonePartnerData={phonePartnerData}
                                        showIntent
                                        showVisibility
                                        showPartners
                                        allowGlobalSelection
                                        customIntentOptions={{
                                            options: INTENT_DROPDOWN,
                                        }}
                                    />
                                );
                            })}

                        <div ref={addTcpaBtnRef} className="mb-20">
                            <Button
                                onClick={addTcpa}
                                btnTheme={ButtonTypes.primaryHover_btn}
                                disable={isReadOnly}
                            >
                                Add TCPA
                            </Button>
                        </div>

                        <div className={styles.inputWrapper}>
                            <div
                                className={`${styles.sideTitle} mb-10`}
                                htmlFor="answers"
                            >
                                HNP Lead trigger:
                            </div>
                            <div className={styles.dropdownWrapper}>
                                <Select
                                    label={
                                        activeDetail?.hnpLeadIds?.length > 0
                                            ? null
                                            : "Select"
                                    }
                                    position={
                                        activeDetail?.tcpa?.length !== 0
                                            ? "top"
                                            : "bottom"
                                    }
                                    options={
                                        collectionState.createCollection
                                            .hnpCategories
                                    }
                                    keyField={"hnpCategory"}
                                    idField={"hnpId"}
                                    multiSelect={true}
                                    selectedIds={hnpLeads || []}
                                    showOptionWithKeyValue={true}
                                    onSelect={leads => {
                                        setHnpLeads(leads.map(_ => _.hnpId));
                                        setActiveDetail({
                                            ...activeDetail,
                                            hnpLeadIds: leads.map(_ => _.hnpId),
                                        });
                                    }}
                                    portal={false}
                                    isDisabled={isReadOnly}
                                />
                            </div>
                            {hnpData?.length > 0 && (
                                <div className="mt-20">
                                    {hnpData.map(_ => {
                                        return (
                                            <HnPData
                                                hnpData={_}
                                                setActiveHnpId={setActiveHnpId}
                                                editHnpSidePanelRef={
                                                    editHnpSidePanelRef
                                                }
                                                setHnpLeads={setHnpLeads}
                                                apiData={apiData}
                                                readOnly={isReadOnly}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`overflowHidden dp-child-50`}>
                        <LayoutEditComp
                            vertical={createCollectionState.vertical}
                            selectedFlowDomain={selectedFlowDomain}
                            defaultConfigs={[]}
                            // hidePreviewHeader={true}
                            // individualLayoutData={individualLayoutData}
                            item={previewItem}
                            // showConfigSelect={showConfigSelect}
                            // addConfigList={addConfigList}
                            // setShowConfigSelect={data => {
                            //     setShowConfigSelect(data);
                            // }}
                            // onCancelHandler={e => {
                            //     setShowConfigSelect(false);
                            // }}
                            readOnly={isReadOnly}
                        />
                    </div>
                </div>
            </SidePanelInfinity>
            <SidePanelInfinity
                ref={templateOpenRef}
                title={`${viewIsClick ? "Edit" : "Create"} Template`}
                onSave={() => {
                    saveFunctionCallRef.current.onSaveHandler();
                    setTimeout(() => {
                        dispatch(fetchAllTemplate()).then(res => {
                            setTemplates(get(res, "payload", []));
                        });
                    }, 1000);
                    templateOpenRef.current.close();
                    setViewIsClick(false);
                    setCreatePhasePopup(false);
                }}
                onClose={() => {
                    setViewIsClick(false);
                    setCreatePhasePopup(false);
                }}
                disableSaveBtn={
                    createTemplateState.name == "" ||
                    createTemplateState.configuration?.length === 0 ||
                    isReadOnly
                }
                children={
                    <CreateTemplate
                        editPhaseInPopUp={viewIsClick ? true : false}
                        ref={saveFunctionCallRef}
                        showInPopup={true}
                        createPhaseInPopup={createPhasePopup}
                    />
                }
            ></SidePanelInfinity>
            <SidePanelInfinity
                ref={layoutOpenRef}
                title={"Edit Layout Features"}
                width={"93%"}
                dialogContentClassName={styles.popupStyle}
                onSave={() => {
                    let isValid = true;
                    individualLayoutData[0]?.configurations?.length &&
                        individualLayoutData[0]?.configurations.forEach(
                            (item, index) => {
                                if (
                                    item.type === "string" ||
                                    item.type === "richText"
                                ) {
                                    if (!item?.data) {
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Error",
                                                message: `Please enter default value for Key ${item.key}.`,
                                            })
                                        );
                                        isValid = false;
                                    }
                                }
                                if (item.type === "number") {
                                    let intData = parseInt(item?.data);

                                    if (intData === "" || intData === null) {
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Error",
                                                message: `Please enter default value for Key ${item.key}.`,
                                            })
                                        );
                                        isValid = false;
                                    }
                                    if (
                                        typeof intData === "number" &&
                                        (intData > parseInt(item.max) ||
                                            intData < parseInt(item.min))
                                    ) {
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Error",
                                                message: `Please enter value between ${item.min} and ${item.max} for Key ${item.key}.`,
                                            })
                                        );
                                        isValid = false;
                                    }
                                }
                            }
                        );

                    if (isValid) {
                        setLayoutDataCopy(individualLayoutData);
                        cancelLayoutEdit = false;
                        dispatch(
                            collectionActions.setItems(
                                items.map(_ => {
                                    if (_.id === activeDetail.id) {
                                        return {
                                            ..._,
                                            layoutConfig: individualLayoutData,
                                        };
                                    } else return _;
                                })
                            )
                        );

                        layoutOpenRef.current.close();
                    }
                }}
                disableSaveBtn={isReadOnly || layoutDefaultConfig?.length === 0}
                onClose={() => {
                    if (!!cancelLayoutEdit) {
                        setIndividualLayoutData(layoutDataCopy);
                    }
                }}
            >
                <LayoutEditComp
                    vertical={createCollectionState.vertical}
                    selectedFlowDomain={selectedFlowDomain}
                    defaultConfigs={layoutDefaultConfig}
                    individualLayoutData={individualLayoutData}
                    updatedLayout={data => {
                        setIndividualLayoutData(data);
                        setActiveDetail({
                            ...activeDetail,
                            layoutConfig: data[0],
                        });
                    }}
                    item={previewItem}
                    ondelete={item => {
                        setIndividualLayoutData([
                            {
                                ...individualLayoutData[0],
                                configurations:
                                    individualLayoutData[0]?.configurations?.filter(
                                        _ => _.key !== item.key
                                    ),
                            },
                        ]);
                    }}
                    showConfigSelect={showConfigSelect}
                    addConfigList={addConfigList}
                    setShowConfigSelect={data => {
                        setShowConfigSelect(data);
                    }}
                    customOnSelect={item => {
                        setShowConfigSelect(false);
                        setIndividualLayoutData([
                            {
                                ...individualLayoutData[0],
                                configurations: [
                                    ...individualLayoutData[0]?.configurations,
                                    ...item,
                                ],
                            },
                        ]);
                    }}
                    onCancelHandler={e => {
                        setShowConfigSelect(false);
                    }}
                    readOnly={isReadOnly}
                />
            </SidePanelInfinity>
            <SidePanelInfinity
                title={`Edit HNP`}
                width={"645px"}
                ref={editHnpSidePanelRef}
                onSave={async () => {
                    const saveRes = await createFlowPageRef.current?.save();
                    if (saveRes) {
                        const resObject = saveRes.data.data;
                        setHnpData(_ => [
                            ..._.map(ele =>
                                ele.hnpId === resObject.hnpId ? resObject : ele
                            ),
                        ]);
                        await dispatch(fetchAllHnpCategories());
                        editHnpSidePanelRef.current.close();
                    }
                }}
                disableSaveBtn={isReadOnly}
            >
                <CreateFlowCategories
                    passedId={activeHnpId}
                    ref={createFlowPageRef}
                />
            </SidePanelInfinity>
        </div>
    );
};

export default RegisterDetails;
