import Icon from "../../Shared/Icon/Icon";

import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { useDispatch } from "react-redux";
import { toastListActions } from "../../store/toastList/toastList";
import s from "./CreateFlow.module.scss";
import Select from "../../Shared/Select/Select";
import Input from "../../Shared/Input/Input";
import Textarea from "../../Shared/Textarea/Textarea";
import PopupTargettingParams from "../../components/PopupTargettingParams/PopupTargettingParams";
import Tooltip from "../../Shared/Tooltip/Tooltip";

const SplitNode = forwardRef(
    (
        {
            branches = [],
            readOnly = false,
            setLoading,
            setAbTotal,
            rulesMeta,
            onAnyChange,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const [nodeBranches, setNodeBranches] = useState([]);
        const [ruleSetMeta, setRuleSetMeta] = useState({
            name: "",
            description: "",
            abType: "RULESET",
        });
        const [splitChanged, setSplitChanged] = useState(false);
        const [addVariantConfirmPopup, setAddVariantConfirmPopup] =
            useState(false);
        const addSplitBtnRef = useRef();

        // const getNewVariantName = nodeBranches => {
        //     const existingNames = nodeBranches?.map(_ => _.name);
        //     let count = nodeBranches?.filter(_ => !_.toBeDeleted).length;
        //     nodeBranches?.filter(_ => !_.toBeDeleted).length;
        //     while (existingNames.includes(`Variant ${count}`)) {
        //         count += 1;
        //     }
        //     return `Variant ${count}`;
        // };

        useEffect(() => {
            if (branches.length) {
                setNodeBranches([...branches]);
                setRuleSetMeta({ ...ruleSetMeta, ...branches[0]?.ruleSetMeta });
                setLoading(false);
            }
        }, [branches]);

        useEffect(() => {
            let nodeSum = 0;
            nodeBranches.forEach(
                _ => (nodeSum += parseInt(_.targettingParams.value))
            );
            setAbTotal(nodeSum);
        }, [nodeBranches, splitChanged]);

        useImperativeHandle(ref, () => {
            return {
                getData: () => {
                    if (
                        !ruleSetMeta.name ||
                        !ruleSetMeta.description ||
                        !nodeBranches
                            .filter(_ => !_.toBeDeleted)
                            .every(_ => _.ruleMeta.name)
                    ) {
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: "Please fill all required fields.",
                            })
                        );
                        return null;
                    } else {
                        const splitNames = nodeBranches
                            .filter(_ => !_.toBeDeleted && !_.id)
                            .map(_ => _.name + _.ruleMeta?.name);
                        const uniqueNames = Array.from(new Set(splitNames));
                        if (splitNames.length === uniqueNames.length) {
                            return nodeBranches
                                .filter(
                                    _ =>
                                        (_.toBeDeleted && _.id) ||
                                        !_.toBeDeleted
                                )
                                .map(_ => ({
                                    ..._,
                                    ruleSetMeta: ruleSetMeta,
                                    ruleMeta: {
                                        ..._.ruleMeta,
                                        name: _.name + _.ruleMeta.name,
                                    },
                                }));
                        } else {
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Error",
                                    message:
                                        "Please enter unique name for each variant.",
                                })
                            );
                        }
                    }
                },
            };
        });

        const deleteSplit = node => {
            node.toBeDeleted = true;
            node.isActive = false;
            node.targettingParams.value = "0";
            setNodeBranches(_ => [..._]);
        };

        const addNewVariant = async () => {
            await setNodeBranches(_ => [
                ..._.map(r => ({ ...r, isSaved: false })),
                {
                    id: null,
                    name: "Variant:",
                    value: 50,
                    ruleSetId: branches[0]?.ruleSetId,
                    diversification: 1,
                    entitySubType: "AB_TEST",
                    entityType: "ROUTER",
                    targettingParams: {
                        type: "AB SPLIT",
                        operator: "EQUAL",
                        value: 50,
                        key: "split",
                    },
                    ruleMeta: {
                        abType: "RULE",
                        name: "",
                        description: "",
                    },
                },
            ]);
            addSplitBtnRef?.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        };

        return (
            <div className={"row"}>
                <div className="mb-20">
                    <p className="headerTitleRequired">A/B Channel*</p>
                    <p className="infoText">Its an unique id for this node</p>
                    <p className={s.readonlyId}>{ruleSetMeta.abKey || "-"}</p>
                </div>
                <div className="mb-20">
                    <p className="headerTitleRequired">Name*</p>
                    <p className="infoText">Its an unique name for this node</p>
                    <Input
                        value={ruleSetMeta.name}
                        className="maxChildContainer"
                        placeholder="Enter name"
                        required
                        disabled={
                            readOnly ||
                            (!!ruleSetMeta.name &&
                                nodeBranches
                                    .filter(_ => !_.toBeDeleted)
                                    ?.some(_ => _.isSaved))
                        }
                        onChange={e => {
                            let temp = e.target.value.trim();
                            if (!rulesMeta.ruleSetNames?.includes(temp)) {
                                ruleSetMeta.name = e.target.value.trim();
                                onAnyChange(true);
                            } else {
                                dispatch(
                                    toastListActions.setToastList({
                                        type: "Error",
                                        message: `A/b test with similar name already exists for this flow.`,
                                    })
                                );
                                ruleSetMeta.name = "";
                                e.target.value = "";
                            }
                        }}
                    ></Input>
                </div>
                <div className="mb-20">
                    <p className="headerTitleRequired">Description*</p>
                    <p className="infoText">
                        Its an unique description for this node
                    </p>
                    <Textarea
                        value={ruleSetMeta.description}
                        placeholder="Enter description"
                        onChange={e => {
                            ruleSetMeta.description = e.target.value.trim();
                            onAnyChange(true);
                        }}
                        rows={3}
                        disabled={readOnly}
                    ></Textarea>
                </div>
                <div className={`${s.splitDetails} mb-20`}>
                    <p className="headerTitleRequired">Split Details*</p>
                    <div className="dp-parent dp-parent-ver-center mb-20">
                        <Icon icon="Info" size="16px" className="mr-5" />
                        <span>
                            Total Split percentage should always be 100.
                        </span>
                    </div>
                    {/* <p className="headerTitleRequired">
                        A/B Channel:
                        <span className={`${s.readonlyId} ml-5`}>
                            {nodeBranches[0]?.abKey || "-"}
                        </span>
                    </p> */}
                </div>
                <div>
                    {nodeBranches
                        .filter(_ => !_.toBeDeleted)
                        .map((node, index) => (
                            <SplitVariant
                                key={index}
                                node={node}
                                allNodes={nodeBranches.filter(
                                    _ => !_.toBeDeleted
                                )}
                                onNodeChange={setSplitChanged}
                                deleteSplit={deleteSplit}
                                dispatch={dispatch}
                                onAnyChange={onAnyChange}
                                readOnly={readOnly}
                            />
                        ))}
                </div>

                <div
                    ref={addSplitBtnRef}
                    className={`${s.addSplitBtn} ${
                        readOnly ? s.disableLink : ""
                    }`}
                    onClick={
                        !readOnly
                            ? () => {
                                  nodeBranches.some(_ => _.isSaved)
                                      ? setAddVariantConfirmPopup(true)
                                      : addNewVariant();
                              }
                            : null
                    }
                >
                    {addVariantConfirmPopup && (
                        <div
                            className={s.confirmPopup}
                            onClick={e => e.stopPropagation()}
                        >
                            <PopupTargettingParams
                                setShowPopup={e => {
                                    setAddVariantConfirmPopup(e);
                                }}
                                Rfunction={() => {
                                    ruleSetMeta.id = "";
                                    ruleSetMeta.name = "";
                                    setAddVariantConfirmPopup(false); //need to remove and debug setShowPopup not getting called issue
                                    addNewVariant();
                                }}
                                customText={`Adding a new variant will end the existing test and start a new test. Do you wish to proceed?`}
                            />
                        </div>
                    )}
                    + Add Variant
                </div>
            </div>
        );
    }
);

export default SplitNode;

const SplitVariant = ({
    node,
    allNodes,
    onNodeChange,
    deleteSplit,
    dispatch,
    onAnyChange,
    readOnly,
}) => {
    const [initiateDelete, setInitiateDelete] = useState(false);
    return (
        <div className={s.splitWrap}>
            <div
                className={`${s.title} dp-parent dp-parent-ver-center dp-parent-hor-space-between`}
            >
                <div
                    className={`${s.splitNameWrap} dp-parent dp-parent-ver-center gap-10`}
                >
                    <span>{node.name}</span>
                    {node.ruleMeta?.name && node.isSaved ? (
                        <div className="ellipsis">
                            <Tooltip message={node.ruleMeta.name} wrap>
                                <span className="ellipsis w-100">
                                    {node.ruleMeta.name}
                                </span>
                            </Tooltip>
                        </div>
                    ) : (
                        <Input
                            placeholder="Enter Name*"
                            required
                            value={node.ruleMeta.name || ""}
                            onblur={e => {
                                let temp = e.target.value.trim();
                                if (temp.includes(":")) {
                                    dispatch(
                                        toastListActions.setToastList({
                                            type: "Error",
                                            message:
                                                "Cannot add : in variant name.",
                                        })
                                    );
                                    node.ruleMeta.name = e.target.value
                                        .replace(":", "")
                                        .trim();
                                } else {
                                    node.ruleMeta.name = e.target.value.trim();
                                    onNodeChange(Math.random());
                                    onAnyChange(true);
                                }
                            }}
                            disabled={readOnly}
                        ></Input>
                    )}
                </div>
                {!node.name.includes("Control") &&
                    allNodes.length > 2 &&
                    !readOnly && (
                        <div
                            className={`circleIconColor circleIconColor--delete cursorPointer`}
                            onClick={() =>
                                node.id
                                    ? setInitiateDelete(true)
                                    : deleteSplit(node)
                            }
                        >
                            <Icon
                                icon="Delete"
                                size="16px"
                                enableHover={true}
                            />
                        </div>
                    )}
                {initiateDelete && (
                    <div className={s.deletePopup}>
                        <PopupTargettingParams
                            setName={node}
                            Rfunction={deleteSplit}
                            setShowPopup={setInitiateDelete}
                            customText={`Do you wish to delete ${
                                (node.name &&
                                    node.ruleMeta.name &&
                                    node.name + node.ruleMeta.name) ||
                                "this Variant"
                            }?`}
                        />
                    </div>
                )}
            </div>

            <div className="dp-parent gap-20 m-10 mb-20">
                <div className="dp-child-50">
                    <p className="mb-10">Percentage*</p>
                    <Input
                        placeholder="Enter value"
                        value={node.targettingParams.value || ""}
                        required
                        type="number"
                        valueType="percentage"
                        onChange={e => {
                            node.targettingParams.value = parseInt(
                                e.target.value === "" ? 0 : e.target.value
                            );
                            onNodeChange(Math.random());
                            onAnyChange(true);
                        }}
                        disabled={readOnly}
                    />
                </div>
                <div className="dp-child-50">
                    <p className="mb-10">Diversification*</p>
                    <Select
                        options={Array.apply(0, new Array(10)).map((_, i) => {
                            return { value: i + 1, label: i + 1 };
                        })}
                        selectedId={node?.diversification}
                        idField="value"
                        keyField="label"
                        onSelect={val => {
                            if (node.diversification !== val?.value) {
                                onAnyChange(true);
                            }
                            node.diversification = val.value;
                        }}
                        isDisabled={node?.isSaved || readOnly}
                        showSearch={false}
                    />
                </div>
            </div>
            <div className="m-10">
                <p className="mb-10">Description</p>
                <Input
                    placeholder="Enter description"
                    value={node?.description}
                    onChange={e => {
                        node.description = e.target.value.trim();
                        onAnyChange(true);
                    }}
                    disabled={readOnly}
                />
            </div>
        </div>
    );
};
