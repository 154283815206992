import React from "react";
import Select from "../../Shared/Select/Select";
import Input from "../../Shared/Input/Input";
import { get } from "lodash";

const OfferWallBasics = ({
    id,
    name,
    surveys = [],
    offerLimitValue,
    selectedSurveyIds = [],
    surveySelectError,
    onChangeName,
    onSelectSurvey,
    onChangeOfferLimit,
    type,
    handleOfferWall,
    list = [],
    highlightNameError,
    allRefs,
    createOption,
    isNewTab,
    onCreateNewOfferWall,
    readOnly = false,
}) => {
    const handleCreateNewItem = value => {
        onCreateNewOfferWall(true);
        onChangeName(get(value, "name", ""));
    };

    return (
        <div
            id="offerWallBasics"
            data-hash-id="offerWallBasics"
            className={`flexHeightTab`}
        >
            <div className={`row`}>
                <p className={`headerTitle`}>Basics</p>
                {id && (
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>ID</p>
                        <p className={`infoText`}>
                            ID associated with Offer Wall
                        </p>
                        <p>{id}</p>
                    </div>
                )}
                <p className={`headerTitleRequired`}>Name*</p>
                <p className={`infoText`}>
                    They are linked to multiple surveys.{" "}
                    {/* <a href="#" className="">
            Learn more
          </a> */}
                </p>
                {type == "select" && (
                    <div className={`dp-parent`}>
                        <div className={`dropdownLayer`}>
                            <Select
                                label={"Select or create offer wall"}
                                options={list}
                                keyField="name"
                                showSearch={true}
                                placeholder={"Search Offer Wall"}
                                onSelect={handleOfferWall}
                                onLoadSelectReturn={false}
                                showCreate={true}
                                selectedId={name}
                                idField="id"
                                onCreate={handleCreateNewItem}
                                createOption={createOption}
                                isError={highlightNameError}
                                isDisabled={readOnly || !isNewTab}
                            />
                        </div>
                    </div>
                )}
                {type == "create" && (
                    <div className={`dp-parent`}>
                        <div className={`inputLayer`}>
                            <Input
                                type="text"
                                ref={allRefs ? allRefs.nameRef : null}
                                required={true}
                                value={name}
                                onChange={e => onChangeName(e.target.value)}
                                placeholder="Create a new offer wall basic"
                                disabled={readOnly}
                            />
                        </div>
                    </div>
                )}
            </div>

            {type == "create" && (
                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Survey</p>
                    <p className={`infoText`}>
                        Select one or many surveys from below
                    </p>
                    <Select
                        label={"Select Survey name"}
                        options={surveys}
                        keyField={"name"}
                        onSelect={onSelectSurvey}
                        idField={"id"}
                        selectedId={selectedSurveyIds}
                        multiSelect={false}
                        showSearch={true}
                        isError={surveySelectError}
                        isDisabled={readOnly}
                    />
                </div>
            )}

            {type == "create" && (
                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Max offer limit</p>
                    <p className={`infoText`}>
                        Select one or many surveys from below
                    </p>
                    <div className={`dp-parent`}>
                        <div className={`inputLayer`}>
                            <Input
                                type="number"
                                required={false}
                                value={offerLimitValue}
                                onChange={e =>
                                    onChangeOfferLimit(e.target.value)
                                }
                                placeholder="Add a max offer limit"
                                disabled={readOnly}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OfferWallBasics;
