import { cloneDeep, get, isEmpty } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import PanelGrid from "../../Shared/PanelGrid/PanelGrid";
import Input from "../../Shared/Input/Input";
import Select from "../../Shared/Select/Select";
import s from "./AdUnitSlots.module.scss";
import { useDispatch, useSelector } from "react-redux";
import NumberRenderer from "../Renderers/NumberRenderer/NumberRenderer";
import { getRandomId, capitalizeString } from "../../utils/globalUtils";
import { useParams } from "react-router-dom";
import Popup from "../../Shared/Popup/Popup";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import { toastListActions } from "../../store/toastList/toastList";
import { adsHistory } from "../../store/listings/adUnits";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import History from "../History/History";
import CopyRenderer from "../Renderers/CopyRenderer/CopyRenderer";
import AddDedupeRule from "../AddDedupeRule/AddDedupeRule";
import {
    AdunitActions,
    OfferEntityMacros,
    PIMacrosSlotLevel,
} from "../../constants/dedupeConstants";
import { adsConfig } from "../../constants/historyConstants";
import Table from "../../Shared/Table/Table";
import { fetchAllAdvertisers } from "../../store/listings/offlineConversion";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import LinearDeterminate from "../LinearDeterminate/LinearDeterminate";
import { fetchDealIds as fetchDealIdsApi } from "../../store/survey/offerpath";
import StatusRenderer from "../Renderers/StatusRenderer/StatusRenderer";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import ReactSelect from "../../Shared/ReactSelect/ReactSelect";
import { DisplayDataType } from "../LayoutEditComp/LayoutEditComp";
import Icon from "../../Shared/Icon/Icon";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import { checkKeyValue } from "../../utils/offerPathUtils";
import DummySelect from "../DummySelect/DummySelect";
import { CreativeRender } from "../CreativeRender/CreativeRender";

let editWeightValue = "";

export const ProfileSelectOption = ({ option }) => {
    return (
        <div className={s.dealIdDropdownWrapper}>
            <div className={s.dealNameLabel}>
                <StatusRenderer status={option.campaignStatus} noText={true} />
                <span>{option.dealName}</span>
            </div>
            <div className={s.multiValue}>
                <span>{option.campaignName}</span>
            </div>
        </div>
    );
};

const AdUnitSlots = ({
    loading,
    action,
    data,
    deletedSlots,
    historyData,
    historyDataRaw,
    readOnly = false,
    type,
    offerPathKeyList = [],
}) => {
    const dispatch = useDispatch();
    const urlParams = useParams();
    const weightRef = useRef(null);
    const keyValueRef = useRef(null);
    const offerGroupEditRef = useRef(null);
    const [openAddRuleSideBar, setOpenAddRuleSideBar] = useState(false);
    const [selectedSlot, setSelectedSlot] = useState([]);
    const [dealIdList, setDealIdList] = useState([]);
    const [editDealIdList, setEditDealIdList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [dealId, setDealId] = useState("");
    const [dealIdFailed, setDealIdFailed] = useState(false);
    const [editDealId, setEditDealId] = useState("");
    const [showOfferKeySelect, setShowOfferKeySelect] = useState(false);
    const [offerKeysData, setOfferKeysData] = useState([]);
    const [mappingDeletePopup, setMappingDeletePopup] = useState([]);
    const [editWeight, setEditWeight] = useState();
    const [editObject, setEditObject] = useState([]);
    const [editDealIdError, setEditDealIdError] = useState(false);
    const [editDealIdFailed, setEditDealIdFailed] = useState(false);
    const [editRevenueType, setEditRevenueType] = useState(null);
    const [editRevenueTypeError, setEditRevenueTypeError] = useState(false);
    const [editCreative, setEditCreative] = useState(null);
    const [editCreativeError, setEditCreativeError] = useState(false);
    const [keyValEdit, setKeyValEdit] = useState([]);
    const [dedupeEdit, setDedupeEdit] = useState([]);
    const [openSelect, setOpenSelect] = useState(false);

    const editWeightRef = useRef(null);
    const modules = useSelector(state => state.auth.user.modules);
    const adUnitSlots = useSelector(
        state => state.listings.adUnits.createAdUnit.slots.editId
    );
    const advertiserList = useSelector(
        state => state.listings.offlineConversion.advertisersList
    );
    const getSlotsForEdit = useSelector(
        state => state.listings.adUnits.createAdUnit.slots
    );

    const dedupeColumn = item => {
        const readOnly = !modules.includes(
            `SurveyManager.AdUnit.Deduping.Write`
        );
        return (
            <span
                onClick={() => {
                    if (!readOnly) {
                        setSelectedSlot([item]);
                        setOpenAddRuleSideBar(true);
                    }
                }}
                className={`${!readOnly ? "link" : ""}`}
            >
                {item.dedupeRules && item.dedupeRules.length
                    ? `${item.dedupeRules.length} Rule Added`
                    : readOnly
                    ? `-`
                    : `Add Rule`}
            </span>
        );
    };

    const keyValColumn = item => {
        return (
            <span
                onClick={() => {
                    if (!readOnly) {
                        setSelectedSlot([item]);
                        setOfferKeysData(item?.customKeyVal || []);
                        keyValueRef?.current?.open();
                    }
                }}
                className={`${!readOnly ? "link" : ""}`}
            >
                {item?.customKeyVal && item?.customKeyVal?.length
                    ? `${item?.customKeyVal?.length} Key Value Added`
                    : readOnly
                    ? `N/A`
                    : `Add Key Value`}
            </span>
        );
    };

    const editDedupeRules = dedupeRules => {
        let selectedListItemsCopy = JSON.parse(
            JSON.stringify(get(data, "selectedListItems", []))
        );
        let itemIndex = selectedListItemsCopy.findIndex(
            data => data.rowId == get(selectedSlot[0], "rowId", null)
        );
        selectedListItemsCopy[itemIndex] = {
            ...selectedListItemsCopy[itemIndex],
            isEdit: true,
            dedupeRules: dedupeRules,
        };
        setDedupeEdit(dedupeRules);
        dispatch(action.addSelectedListItems(selectedListItemsCopy));
    };

    const editKeyValue = keyValue => {
        const isValidKeyValue = checkKeyValue(offerKeysData, dispatch);
        if (isValidKeyValue) {
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            let itemIndex = selectedListItemsCopy.findIndex(
                data => data.rowId == get(selectedSlot[0], "rowId", null)
            );
            selectedListItemsCopy[itemIndex] = {
                ...selectedListItemsCopy[itemIndex],
                isEdit: true,
                customKeyVal: keyValue,
            };
            dispatch(action.addSelectedListItems(selectedListItemsCopy));
            setKeyValEdit(keyValue);
            keyValueRef?.current?.close();
        } else return false;
    };

    const handleEditDealId = e => {
        setEditDealIdError(false);
        setEditDealId(e.dealName);
    };

    const groupAds = {
        WeightGroup: "weight",
        PriorityGroup: "priority",
        RtbGroup: "disposition",
    };

    const columns = [
        {
            text: "Id",
            key: "id",
            disableCopyText: true,
            styles: {
                width: "70px",
                minWidth: "70px",
            },
            CustomRender: ({ item }) => {
                return item.id && item.id !== "-" ? (
                    <div className={s.copyBtnWrapper}>
                        <CopyRenderer>
                            <p>{item.id}</p>
                        </CopyRenderer>
                    </div>
                ) : (
                    <TagRenderer>New</TagRenderer>
                );
            },
        },
        {
            text: groupAds[type] ? groupAds[type] : "Weight",
            key: groupAds[type] ? groupAds[type] : "Weight",
            disableCopyText: true,
            maxWidth: 150,
            headerClassName: adUnitSlots === "" ? "pr-35" : "",
            styles: {
                width: "150px",
                minWidth: "150px",
            },
            CustomRender: ({ item, col, getState }) => {
                return (
                    <div className={s.copyBtnWrapper}>
                        <CopyRenderer>
                            {item.weight && item.weightPercent ? (
                                <NumberRenderer
                                    item={`${item.weight} (${Number(
                                        item.weightPercent
                                    ).toFixed(2)}%)`}
                                />
                            ) : (
                                <NumberRenderer
                                    item={item}
                                    selector={
                                        groupAds[type]
                                            ? groupAds[type]
                                            : "Weight"
                                    }
                                />
                            )}
                        </CopyRenderer>
                    </div>
                );
            },
            type: 0,
            valueType: "number",
        },
        {
            text: "Deal Id",
            key: "dealId",
            disableCopyText: true,
            headerClassName: adUnitSlots === "" ? "pr-35" : "",
            maxWidth: 450,
            styles: {
                width: "450px",
                minWidth: "450px",
            },
            CustomRender: ({ col, item, getState }) => {
                return (
                    <div className={s.copyBtnWrapper}>
                        <CopyRenderer>{item.dealId}</CopyRenderer>
                    </div>
                );
            },
            type: 0,
        },
        {
            text: "Select Revenue Type",
            key: "revenueType",
            disableCopyText: true,
            maxWidth: 250,
            styles: {
                width: "150px",
                minWidth: "150px",
            },
            CustomRender: ({ col, item, getState }) => {
                return (
                    <div className={s.copyBtnWrapper}>
                        <CopyRenderer>
                            <p>{item.revenueType ? item.revenueType : ""}</p>
                        </CopyRenderer>
                    </div>
                );
            },
            type: 5,
        },
        {
            text: "Select Creative",
            key: "creative",
            disableCopyText: true,
            maxWidth: 250,
            styles: {
                width: "250px",
                minWidth: "250px",
            },
            CustomRender: ({ col, item, getState }) => {
                const getSlots = getState().listings.adUnits.createAdUnit.slots;
                return (
                    <div
                        className={`${s.copyBtnWrapper} dp-parent dp-parent-ver-center`}
                    >
                        {!isEmpty(item.creative) &&
                            get(getSlots, "adCreativesList", [])?.some(
                                _ =>
                                    _.id ===
                                        parseInt(
                                            item.creative?.split(":")[0]
                                        ) && _.isDeprecated
                            ) && (
                                <TagRenderer
                                    className={s.deprecateTag}
                                    type={"deprecated"}
                                >
                                    Deprecated
                                </TagRenderer>
                            )}
                        <CopyRenderer>
                            <p>{item.creative ? item.creative : ""}</p>
                        </CopyRenderer>
                    </div>
                );
            },
            type: 5,
        },
        ...[
            {
                text: "Deduping",
                key: "creative",
                disableCopyText: true,
                CustomRender: ({ item }) => {
                    return dedupeColumn(item);
                },
                type: 5,
            },
        ].filter(
            _ => modules.indexOf(`SurveyManager.AdUnit.Deduping.Read`) > -1
        ),
        {
            text: "Key Value",
            key: "customKeyVal",
            disableCopyText: true,
            CustomRender: ({ item }) => {
                return keyValColumn(item);
            },
            type: 5,
        },
        {
            text: "",
            key: "",
            CustomRender: ({ col, item, getState }) => {
                const getSlots = getState().listings.adUnits.createAdUnit.slots;
                const getDeletedSlots =
                    getState().listings.adUnits.createAdUnit.deletedSlots;
                return (
                    <>
                        <ActionButtons
                            item={item}
                            revertCallback={obj => {
                                dispatch(
                                    action.revertDeleteSlots({
                                        data: obj,
                                    })
                                );
                            }}
                            isDeleted={
                                getDeletedSlots.findIndex(
                                    _ => _.id === item.id
                                ) !== -1
                            }
                            deleteCallback={obj => {
                                onDeleteItem(obj, getSlots);
                            }}
                            editCallback={onEdit}
                            showHistoryIcon={
                                item.id && item.id !== "-" ? true : false
                            }
                            historyCallback={obj => {
                                historyDataAction(
                                    adsHistory,
                                    obj,
                                    dispatch,
                                    action
                                );
                            }}
                            readOnly={readOnly}
                        />
                    </>
                );
            },
            bulkActions: true,
        },
    ];

    const toggleHistoryVisibilty = () => {
        dispatch(action.setHistoryData([]));
    };

    let timer = null;
    useEffect(() => {
        const revenueList = [
            { id: 1, key: "cpa", text: "cpa" },
            { id: 2, key: "cpc", text: "cpc" },
        ];
        dispatch(action.setRevenueList(revenueList));
        dispatch(fetchAllAdvertisers());
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (!isEmpty(editObject)) {
            setDedupeEdit(editObject[0]);
            setEditDealId(editObject[0].dealId);
            setEditWeight(editObject[0].weight);
            let editValue = get(editObject[0], groupAds[type], "");
            setEditWeight(editValue);
            setEditRevenueType(editObject[0].revenueType);
            setEditCreative(editObject[0].creative);
            setKeyValEdit(editObject[0]);
            setOpenSelect(false);
        }
    }, [editObject]);

    const selectRevenueDropdown = data => {
        dispatch(action.addRevenueType(get(data, "key", "")));
        dispatch(action.addRevenueTypeError(false));
    };

    const selectCreativeDropdown = data => {
        dispatch(action.addCreative(data));
        dispatch(action.addCreativeError(false));
    };

    const convertWeightPercent = list => {
        if (list.length > 0) {
            let totalWeight = 0;
            list.map(element => {
                if (element.weight)
                    totalWeight = totalWeight + Number(element.weight);
            });
            list.map(element => {
                if (element.weight)
                    element["weightPercent"] =
                        (element.weight / totalWeight) * 100;
            });
        }
        dispatch(action.addSelectedListItems(list));
    };

    const onAddListItem = () => {
        if (
            data?.weight &&
            data.weight.indexOf(".") == -1 &&
            data.weight.indexOf("-") == -1 &&
            data?.dealId &&
            data.dealId.indexOf(".") == -1 &&
            data?.revenueType &&
            data.creative
        ) {
            dispatch(action.setAdUnitSlotsError(false));
            const dataJson = {
                [groupAds[type] ? groupAds[type] : "weight"]: get(
                    data,
                    "weight",
                    ""
                ),
                dealId: get(data, "dealId", ""),
                revenueType: get(data, "revenueType", ""),
                creative: get(data, "creative.key", ""),
                id: "-",
                rowId: getRandomId(),
                selected: false,
            };

            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            if (
                selectedListItemsCopy.findIndex(
                    item => item.rowId == dataJson.rowId
                ) == -1
            ) {
                selectedListItemsCopy.push(dataJson);

                convertWeightPercent(selectedListItemsCopy);
                dispatch(action.addWeight(""));
                dispatch(action.addDealId(null));
                dispatch(action.addAdvId(null));
                dispatch(action.addRevenueType(null));
                dispatch(action.addCreative(get(null)));
                dispatch(action.addRevenueTypeError(false));
                dispatch(action.setDealIdError(false));
                dispatch(action.addCreativeError(false));
                setDealId("");
                setDealIdList([]);
                setDealIdFailed(false);
            } else {
                //alert index already present!
            }
        } else {
            weightRef.current.validate();
            if (!weightRef.current.validate()) {
                weightRef.current.focus();
            }

            if (!data?.dealId) dispatch(action.setDealIdError(true));
            if (!data?.revenueType) dispatch(action.addRevenueTypeError(true));
            if (!data?.creative) dispatch(action.addCreativeError(true));

            if (data.weight.indexOf(".") !== -1) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: (
                            <div>
                                {groupAds[type] ? (
                                    <span className="capitalize">
                                        {groupAds[type]}
                                    </span>
                                ) : (
                                    "Weight"
                                )}{" "}
                                must not be decimal
                            </div>
                        ),
                    })
                );
            }

            if (data.weight.indexOf(".") !== -1) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: (
                            <div>
                                {groupAds[type] ? (
                                    <span className="capitalize">
                                        {groupAds[type]}
                                    </span>
                                ) : (
                                    "Weight"
                                )}{" "}
                                must not be Negative
                            </div>
                        ),
                    })
                );
            }
        }
    };

    const onDeleteItem = (obj, data) => {
        if (urlParams.id && obj.id !== "-") {
            dispatch(action.setDeletedSlots(obj));
        } else {
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            let itemIndex = selectedListItemsCopy.findIndex(
                item => item.rowId == obj.rowId
            );
            selectedListItemsCopy.splice(itemIndex, 1);
            convertWeightPercent(selectedListItemsCopy);
            dispatch(action.addSelectedListItems(selectedListItemsCopy));
        }
    };

    const onEdit = data => {
        dispatch(action.setEditId([data]));
        dispatch(action.setEditDealId(data.dealId));
        setEditObject([data]);
        offerGroupEditRef?.current?.open();
    };

    const cancelEdit = () => {
        dispatch(action.setEditId(""));
        setEditObject([]);
    };

    const setEditRevenueTypeData = _ => {
        setEditRevenueType(_.key);
        setEditRevenueTypeError(false);
    };

    const setEditCreativeData = _ => {
        setEditCreative(_.key);
        setEditCreativeError(false);
        setOpenSelect(false);
    };

    const saveEditSlot = (data, id) => {
        if (get(data, "editId", "")?.length > 0) {
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "selectedListItems", []))
            );
            let itemIndex = selectedListItemsCopy.findIndex(
                item => item.rowId == data.editId[0].rowId
            );

            if (!editDealId) setEditDealIdError(true);
            if (!editCreative) setEditCreativeError(true);
            if (!editRevenueType) setEditRevenueTypeError(true);

            if (
                (editWeight || editWeight === 0) &&
                !editWeight.toString().includes(".") &&
                !editWeight.toString().includes("-") &&
                editDealId &&
                editCreative
            ) {
                selectedListItemsCopy[itemIndex] = {
                    ...selectedListItemsCopy[itemIndex],
                    dealId: editDealId,
                    revenueType: editRevenueType,
                    creative: editCreative,
                    [groupAds[type] ? groupAds[type] : "Weight"]:
                        editWeight != ""
                            ? editWeight
                            : get(data, "editWeight", ""),
                    isEdit: !!urlParams.id,
                };
                convertWeightPercent(selectedListItemsCopy);
                offerGroupEditRef?.current?.close();
                dispatch(action.setEditId(""));
                dispatch(action.setEditAdvId(null));
                dispatch(action.setEditDealIdError(false));
                dispatch(action.setEditCreativeError(false));
                setEditDealIdList([]);
                setEditObject([]);
                setEditCreativeError(false);
                setEditRevenueTypeError(false);
            }

            if (
                editWeight?.toString().includes(".") ||
                editWeight?.toString().includes("-")
            ) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: (
                            <div>
                                {groupAds[type] ? (
                                    <span className="capitalize">
                                        {groupAds[type]}
                                    </span>
                                ) : (
                                    "Weight"
                                )}{" "}
                                {editWeight.toString().includes(".")
                                    ? "must not be decimal"
                                    : "must not be Negative"}
                            </div>
                        ),
                    })
                );
            }

            if (
                selectedListItemsCopy.every(
                    elem => elem.weight || elem.priority
                )
            ) {
                dispatch(action.setAdUnitSlotsError(false));
            }
        }
    };

    useEffect(() => {
        if (get(data, "editId", "") != "") {
            const objIndex = data.selectedListItems.findIndex(
                obj => obj.rowId == data.editId[0].rowId
            );
            let obj = data.selectedListItems[objIndex];

            dispatch(
                action.editWeight(
                    get(obj, groupAds[type] ? groupAds[type] : "weight", "")
                )
            );

            editWeightValue = get(
                obj,
                groupAds[type] ? groupAds[type] : "weight",
                ""
            );

            let revenueValue = get(data, "revenueList", []).filter(
                item => item.key == obj?.revenueType
            );
            let creativeValue = get(data, "adCreativesList", []).filter(
                item => item.key == obj?.creative
            );
            dispatch(action.editRevenueType(revenueValue[0]));
            dispatch(action.editCreative(creativeValue[0]));
        }
    }, [data.editId]);

    const handleDealIdChange = e => {
        dispatch(action.setDealIdError(false));
        dispatch(action.addDealId(e.dealName));
    };

    const handleEditDealID = async e => {
        dispatch(action.setEditDealIdError(false));
        dispatch(action.setEditDealId(e.dealName));
    };

    return (
        <div
            data-hash-id="adUnitSlots"
            id="adUnitSlots"
            className={`flexHeightTab`}
        >
            <div className={`headerTitle`}>Ad Unit Offers</div>
            <PanelGrid
                className={`${s.resetPadding} ${
                    groupAds[type] === "RtbGroup" && s.typeIncluded
                }`}
            >
                <form
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <div className={s.header}>
                        <div className={`${s.subHeader} ${s.flexGrow}`}>
                            {groupAds[type] ? groupAds[type] : "Weight"}
                        </div>
                        <div className={`${s.subHeader} ${s.headerSelect}`}>
                            Advertiser Id
                        </div>
                        <div className={`${s.subHeader} ${s.headerSelect}`}>
                            Deal Id
                        </div>
                        <div className={`${s.subHeader} ${s.headerSelect}`}>
                            Select revenue type
                        </div>
                        <div className={s.subHeader}>Select creative</div>
                        <div
                            className={`${s.subHeader} ${s.dummyButton}`}
                        ></div>
                    </div>
                    <div className={s.body}>
                        <div className={s.input}>
                            <Input
                                ref={weightRef}
                                type="number"
                                value={get(data, "weight", "")}
                                placeholder={`Enter ${
                                    groupAds[type]
                                        ? capitalizeString(groupAds[type])
                                        : "Weight"
                                }`}
                                onChange={e =>
                                    dispatch(action.addWeight(e.target.value))
                                }
                                required={true}
                                // disabled={readOnly || !isEmpty(adUnitSlots)}
                                disabled={readOnly}
                            />
                        </div>
                        <div className={s.secondCustomSelectContainerLayer}>
                            <Select
                                label={"Select Advertiser"}
                                options={advertiserList}
                                keyField="name"
                                // portal={false}
                                position={"top"}
                                onSelect={e => {
                                    setLoader(true);
                                    setDealIdList([]);
                                    setDealIdFailed(false);
                                    dispatch(action.addAdvId(e.id));
                                    dispatch(fetchDealIdsApi(e.id))
                                        .unwrap()
                                        .then(res => {
                                            setDealIdList(res?.data);
                                            setLoader(false);
                                            if (!res?.data) {
                                                setDealIdFailed(true);
                                                dispatch(
                                                    toastListActions.setToastList(
                                                        {
                                                            type: "Error",
                                                            message:
                                                                "Failed to fetch deal id api please enter manually",
                                                        }
                                                    )
                                                );
                                            }
                                        });
                                }}
                                selectedId={get(data, "advId", null)}
                                idField="key"
                                onLoadSelectReturn={false}
                                isDisabled={readOnly}
                            />
                        </div>
                        <div className={s.customSelectContainerLayer}>
                            {dealIdList?.length ? (
                                <>
                                    <Select
                                        label={"Select Deal ID"}
                                        options={dealIdList}
                                        keyField={"dealName"}
                                        position={"top"}
                                        CustomRender={ProfileSelectOption}
                                        onSelect={handleDealIdChange}
                                        idField="dealName"
                                        onLoadSelectReturn={false}
                                        isError={get(
                                            data,
                                            "dealIdError",
                                            false
                                        )}
                                        isDisabled={readOnly}
                                        defaultOptionHeight={60}
                                        isSelectOpen={true}
                                    />
                                </>
                            ) : dealIdFailed ? (
                                <Input
                                    value={
                                        editDealId.length === 0
                                            ? dealId
                                            : editDealId
                                    }
                                    placeholder="Enter Deal Id"
                                    required={true}
                                    onChange={e => {
                                        if (editDealId.length > 0) {
                                            setEditDealId(e.target.value);
                                        } else {
                                            setDealId(e.target.value);
                                            dispatch(
                                                action.addDealId(e.target.value)
                                            );
                                        }
                                    }}
                                    isError={get(data, "dealIdError", false)}
                                    disabled={readOnly}
                                />
                            ) : (
                                <Tooltip
                                    message={
                                        "To change deal Id, please select its Advertiser first."
                                    }
                                >
                                    <div
                                        className={`${s.labelDealId} 
                                        ${
                                            !(editDealId?.length === 0
                                                ? dealId
                                                : null)
                                                ? s.placeholder
                                                : ""
                                        }
                                        ${readOnly ? s.disabled : ""}
                                        `}
                                    >
                                        {loader && (
                                            <LinearDeterminate
                                                customClass={s.loader}
                                            />
                                        )}
                                        {dealId || "Select Deal Id"}
                                    </div>
                                </Tooltip>
                            )}
                        </div>

                        <div className={s.customSelectContainerLayer}>
                            <Select
                                label={"Select Revenue Type"}
                                options={get(data, "revenueList", [])}
                                keyField="text"
                                onSelect={selectRevenueDropdown}
                                idField={"key"}
                                selectedId={get(data, "revenueType", null)}
                                position={"top"}
                                isError={get(data, "revenueTypeError", false)}
                                onLoadSelectReturn={false}
                                isDisabled={readOnly}
                            />
                        </div>
                        <div className={s.secondCustomSelectContainerLayer}>
                            <Select
                                label={"Select Creative"}
                                options={get(
                                    data,
                                    "adCreativesList",
                                    []
                                )?.filter(_ => !_.isDeprecated)}
                                keyField={"key"}
                                onSelect={selectCreativeDropdown}
                                idField={"id"}
                                selectedId={get(data, "creative.id", null)}
                                isError={get(data, "creativeError", false)}
                                onLoadSelectReturn={false}
                                position={"top"}
                                isDisabled={readOnly}
                            />
                        </div>
                        <div className={s.formButton}>
                            <Button
                                className={`${s.themBtn} ${s.marginLeft}`}
                                btnTheme={ButtonTypes.primaryHover_btn}
                                disable={readOnly}
                                type="submit"
                                onClick={onAddListItem}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                </form>
            </PanelGrid>

            {get(data, "selectedListItems", []).length > 0 && (
                <Table
                    tableData={{ loading: loading }}
                    columns={columns}
                    data={get(data, "selectedListItems", [])}
                    showBorder={true}
                    additionalProps={{
                        editId: data?.editId[0]?.rowId || "",
                        deletedIds: deletedSlots.map(_ => _.id),
                        editIdLength: data.editId?.length || [],
                        editDealId: data.editDealId,
                    }}
                    deletedIds={deletedSlots.map(_ => _.id)}
                />
            )}
            {openAddRuleSideBar ? (
                <Popup
                    bodyMaxHeight="calc(100vh - 65px)"
                    title="Add Dedupe"
                    headerPadding={"20px"}
                    showOverlay
                    width="640px"
                    showFooter={false}
                    closeOutside={false}
                    onClose={() => {
                        setOpenAddRuleSideBar(false);
                    }}
                    overlayCustomClass={"dedupeAdUnit"}
                    customClass={"dedupeAdUnit"}
                >
                    <AddDedupeRule
                        actionMacros={AdunitActions}
                        prefix={"offer"}
                        dedupeRules={selectedSlot[0]?.dedupeRules}
                        onSave={dedupeRules => {
                            setOpenAddRuleSideBar(false);
                            editDedupeRules(dedupeRules);
                        }}
                        onCancel={() => {
                            setOpenAddRuleSideBar(false);
                        }}
                        readOnly={
                            modules.indexOf(
                                `SurveyManager.AdUnit.Deduping.Write`
                            ) === -1
                        }
                        entityMacros={OfferEntityMacros}
                        featureCopyToolbarKeys={{
                            sourceName: "Ad Units Slots",
                            sourcePage: "Ad Units",
                            sourcePath: ["Ad Units", "Slots", "Dedupe"],
                            type: "ad Unit Dedupe",
                        }}
                        PIMacrosArray={PIMacrosSlotLevel}
                    ></AddDedupeRule>
                </Popup>
            ) : null}
            {historyData && historyData.length > 0 ? (
                <History
                    data={historyData}
                    rawData={historyDataRaw}
                    closeHistory={toggleHistoryVisibilty}
                    historyConfig={adsConfig}
                />
            ) : null}
            <SidePanelInfinity
                ref={keyValueRef}
                title={"Key Value"}
                saveButtonText={"Confirm"}
                onSave={() => {
                    editKeyValue(offerKeysData);
                }}
                disableSaveBtn={readOnly}
            >
                <>
                    <div style={{ position: "relative" }}>
                        <Button
                            btnTheme={ButtonTypes.primaryHover_btn}
                            onClick={() => setShowOfferKeySelect(true)}
                            disable={
                                readOnly ||
                                offerKeysData?.length ===
                                    offerPathKeyList?.length
                            }
                        >
                            Add Key Value
                        </Button>
                        {showOfferKeySelect && (
                            <div className={s.dropdownWrap}>
                                <ReactSelect
                                    showSelectAll
                                    isMulti
                                    maxMenuHeight={200}
                                    menuIsOpen
                                    options={offerPathKeyList}
                                    listSelector={"key"}
                                    searchIdField={"key"}
                                    selectedIds={
                                        offerKeysData?.map(_ => _?.key) || []
                                    }
                                    onCancelHandler={e => {
                                        setShowOfferKeySelect(false);
                                    }}
                                    customOnSelect={items => {
                                        const obj = {};
                                        offerKeysData.forEach(_ => {
                                            obj[_.id] = _;
                                        });
                                        setOfferKeysData(
                                            items?.map(_ => ({
                                                ..._,
                                                ...obj[_.id],
                                            }))
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    {offerKeysData?.length
                        ? offerKeysData?.map(_ => {
                              return (
                                  <div
                                      key={_.key}
                                      className={`${s.keyValWrap} mt-20 p-10`}
                                  >
                                      <div
                                          className={`${s.deleteIcon} circleIconColor circleIconColor--delete`}
                                      >
                                          <Tooltip
                                              message="Delete"
                                              position="top"
                                          >
                                              <Icon
                                                  icon="Delete"
                                                  size="18px"
                                                  enableHover={true}
                                                  onClick={() =>
                                                      setMappingDeletePopup(
                                                          _.key
                                                      )
                                                  }
                                              />
                                          </Tooltip>
                                      </div>
                                      {mappingDeletePopup === _.key && (
                                          <div className={s.deletePopup}>
                                              <PopupTargettingParams
                                                  setShowPopup={() =>
                                                      setMappingDeletePopup(
                                                          false
                                                      )
                                                  }
                                                  Rfunction={() =>
                                                      setOfferKeysData(
                                                          offerKeysData.filter(
                                                              o =>
                                                                  o.key !==
                                                                  _.key
                                                          )
                                                      )
                                                  }
                                                  customText={`This will delete this mapping. Do you wish to proceed?`}
                                                  readOnly={readOnly}
                                              />
                                          </div>
                                      )}
                                      <div className="mb-10">
                                          <span className="headerTitleRequired">
                                              Key:
                                          </span>{" "}
                                          {_.key}
                                      </div>

                                      <DisplayDataType
                                          element={{
                                              ..._,
                                              type: _.dataType,
                                              data: _.value,
                                          }}
                                          hideDesc
                                          customOnChange={(data, val) => {
                                              let updatedData =
                                                  cloneDeep(offerKeysData);
                                              const element = updatedData.find(
                                                  e => e.key === data.key
                                              );
                                              if (element) element.value = val;
                                              setOfferKeysData(updatedData);
                                          }}
                                      />
                                  </div>
                              );
                          })
                        : null}
                </>
            </SidePanelInfinity>
            <SidePanelInfinity
                ref={offerGroupEditRef}
                title={`Edit Ad Unit Offer`}
                onSave={() => saveEditSlot(getSlotsForEdit)}
            >
                <div className={`row`}>
                    <div className={`row`}>
                        <p className={`headerTitleRequired capitalize`}>
                            {groupAds[type] ? groupAds[type] : "Weight"}*
                        </p>
                        <p className={`infoText`}>Weight</p>
                        <Input
                            ref={editWeightRef}
                            type="number"
                            value={
                                !isEmpty(editObject) &&
                                get(editObject[0], groupAds[type], "")
                            }
                            placeholder=""
                            onChange={e => setEditWeight(e.target.value)}
                            required={true}
                            minLength={0}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                <div className={`row`}>
                    <div className="flex">
                        <div className="dp-child-50 mr-10">
                            <p className={`headerTitleRequired`}>Advertiser</p>
                            <p className={`infoText`}>Advertiser</p>
                        </div>
                        <div className="dp-child-50">
                            <p className={`headerTitleRequired`}>Deal Id*</p>
                            <p className={`infoText`}>Deal Id</p>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="dp-child-50 mr-10">
                            <Select
                                label={"Select Advertiser"}
                                options={advertiserList}
                                keyField="name"
                                portal={false}
                                onSelect={e => {
                                    setEditLoader(true);
                                    setEditDealIdList([]);
                                    setEditDealIdFailed(false);
                                    setEditDealId(null);
                                    dispatch(fetchDealIdsApi(e.id))
                                        .unwrap()
                                        .then(res => {
                                            setEditDealIdList(res.data);
                                            setEditLoader(false);

                                            if (
                                                res?.data?.length == 0 &&
                                                res?.status >= 400
                                            ) {
                                                setEditDealIdFailed(true);

                                                dispatch(
                                                    toastListActions.setToastList(
                                                        {
                                                            type: "Error",
                                                            message:
                                                                "Failed to fetch deal id api please enter manually",
                                                        }
                                                    )
                                                );
                                            }
                                        });
                                }}
                                idField="key"
                                onLoadSelectReturn={false}
                                isDisabled={readOnly}
                            />
                        </div>
                        <div className="dp-child-50">
                            {editDealIdList?.length ? (
                                <Select
                                    label={"Select Deal ID"}
                                    options={editDealIdList}
                                    keyField={"dealName"}
                                    portal={false}
                                    CustomRender={ProfileSelectOption}
                                    onSelect={handleEditDealId}
                                    idField="dealName"
                                    onLoadSelectReturn={false}
                                    isError={editDealIdError}
                                    isDisabled={readOnly}
                                    defaultOptionHeight={60}
                                    isSelectOpen={true}
                                />
                            ) : editDealIdFailed ? (
                                <Input
                                    value={editDealId}
                                    placeholder="Enter Deal Id"
                                    required={true}
                                    onChange={e => {
                                        setEditDealId(e.target.value);
                                    }}
                                />
                            ) : (
                                <Tooltip
                                    message={
                                        "To change deal Id, please select its Advertiser first."
                                    }
                                >
                                    <div
                                        className={`${s.labelDealId} ${
                                            !(editObject?.length === 0
                                                ? dealId
                                                : editDealId)
                                                ? s.placeholder
                                                : ""
                                        }`}
                                    >
                                        {editLoader && (
                                            <LinearDeterminate
                                                customClass={s.loader}
                                            />
                                        )}
                                        {editObject?.length === 0
                                            ? dealId || "Select Deal Id"
                                            : editDealId || "Select Deal Id"}
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Revenue Type</p>
                    <p className={`infoText`}>Revenue Type</p>
                    <Select
                        label={"Select"}
                        options={getSlotsForEdit?.revenueList || []}
                        keyField="text"
                        onSelect={setEditRevenueTypeData}
                        selectedId={editRevenueType}
                        idField="key"
                        position={"bottom"}
                        isError={editRevenueTypeError}
                        onLoadSelectReturn={false}
                        isDisabled={readOnly}
                    />
                </div>
                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Creative</p>
                    <p className={`infoText`}>Creative</p>
                    {get(getSlotsForEdit, "adCreativesList", [])?.some(
                        _ =>
                            _.id === parseInt(editCreative?.split(":")[0]) &&
                            !_.isDeprecated
                    ) || editCreative === null ? (
                        <Select
                            label={"Select Creative"}
                            options={getSlotsForEdit?.adCreativesList?.filter(
                                _ => !_.isDeprecated
                            )}
                            portal={false}
                            keyField="key"
                            onSelect={setEditCreativeData}
                            selectedId={editCreative}
                            idField="key"
                            position={"bottom"}
                            isError={editCreativeError}
                            onLoadSelectReturn={false}
                            isSelectOpen={openSelect}
                            isDisabled={readOnly}
                        />
                    ) : (
                        <DummySelect
                            value={editCreative}
                            onClick={() => {
                                setOpenSelect(true);
                            }}
                            onClick={() => {
                                setOpenSelect(true);
                                setEditCreative(null);
                            }}
                        />
                    )}
                    <div className={`mt-20 p-20 ${s.creativeWrap}`}>
                        {isEmpty(editObject)
                            ? get(data, "creative.key", "") && (
                                  <CreativeRender
                                      id={
                                          get(data, "creative.key", ":")?.split(
                                              ":"
                                          )[0]
                                      }
                                  ></CreativeRender>
                              )
                            : editCreative && (
                                  <CreativeRender
                                      id={editCreative?.split(":")[0]}
                                  ></CreativeRender>
                              )}
                    </div>
                </div>

                {/* <div className={`row`}>
                    <p className={`headerTitleRequired`}>Deduping</p>
                    <p className={`infoText`}>Deduping</p>
                    {dedupeColumn(dedupeEdit)}
                </div>
                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Key Value</p>
                    <p className={`infoText`}>Key Value</p>
                    {keyValColumn(keyValEdit)}
                </div> */}
            </SidePanelInfinity>
        </div>
    );
};

export default AdUnitSlots;
