import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { RELEASENOTES } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import MDJ from "mdj";

const initialState = {
    loading: false,
    releaseNotes: null,
};

export const getReleaseNotes = createAsyncThunk(
    "releaseNotes",
    async (_, { dispatch }) => {
        const header = {
            Authorization: process.env.REACT_APP_GITHUB_TOKEN,
        };
        const response = await customFetch(
            RELEASENOTES.GET_RELEASE_NOTES,
            "GET",
            {},
            header
        );
        if (get(response, "status", 400) >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Release Notes",
                })
            );
        }
        return get(response, "data", []);
    }
);

const releaseNotesSlice = createSlice({
    name: "releaseNotes",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getReleaseNotes.pending, state => {
            state.loading = true;
        });
        builder.addCase(getReleaseNotes.fulfilled, (state, action) => {
            state.loading = false;
            const data = action.payload;
            if (data && data.length) {
                const mdj = MDJ();
                state.releaseNotes = data.map(_ => ({
                    ..._,
                    body: mdj.parse(_.body),
                }));
            }
        });
    },
});
export const releaseNotesActions = releaseNotesSlice.actions;
export default releaseNotesSlice.reducer;
