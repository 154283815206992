import Input from "../../Shared/Input/Input";
import KeyValueAdd from "../KeyValueAdd/KeyValueAdd";
import styles from "./AddNewDetail.module.scss";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import React, { useEffect, useRef, useState } from "react";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import TagListDragable from "../TagListDraggable/TagListDraggable";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch, useSelector } from "react-redux";
import InputList from "../InputList/InputList";
import { REGISTER_DETAIL_DROPDOWN } from "../../constants/collectionConstants";
import { get } from "lodash";

const AddNewDetail = ({
    onCancel,
    onSave,
    className,
    question,
    actions,
    isReadOnly = false,
}) => {
    const dispatch = useDispatch();
    const [hasOther, setHasOther] = useState(false);
    const [optionList, setOptionList] = useState([]);
    const [questionName, setQuestionName] = useState("");
    const [questionTitle, setQuestionTitle] = useState("");
    const questionNameRef = useRef();
    const questionTitleRef = useRef();
    const [refEle, setRefEle] = useState(false);
    const [questionsList, setQuestionsList] = useState([]);
    const questionsData = useSelector(state => state.listings.questions);
    const configuration = null;
    const collectionState = useSelector(state => state.flowmanager.collection);
    const items = get(collectionState, "createCollection.items", []);

    useEffect(() => {
        setQuestionsList(questionsData.createQuestion.questions.questionList);
    }, [questionsData]);

    const questionCustomValidation = val => {
        const value = val.trim();
        var format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
        var detectSpace = /(\s)/g;
        if (value === questionName) {
            return true;
        }
        if (
            value &&
            value.length &&
            !format.test(value) &&
            !detectSpace.test(value)
        ) {
            const subQuestionExists = questionsList.some(
                ques => ques.questionName.toLowerCase() === value.toLowerCase()
            );
            if (subQuestionExists) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Question name already exists.",
                    })
                );
                return false;
            } else return true;
        } else return false;
    };

    useEffect(() => {
        if (question && question.id) {
            setOptionList(question.optionList);
            setQuestionName(question.questionName);
            setQuestionTitle(question.questionTitle);
            setHasOther(question.hasOther);
        }
    }, [question]);
    const addQuestion = event => {
        event.preventDefault();
        const questionName = questionNameRef.current.value().trim();
        const questionTitle = questionTitleRef.current.value().trim();
        const titleValidate = questionTitleRef.current.validate();
        const nameValidate = questionNameRef.current.validate();
        if (!titleValidate) questionTitleRef.current.focus();
        if (!nameValidate) questionNameRef.current.focus();
        if (optionList.length <= 0) {
            setRefEle(true);
        } else {
            setRefEle(false);
        }
        if (optionList.length && nameValidate && titleValidate) {
            const id =
                question && question.id ? question.id : new Date().getTime();
            onSave({
                questionName,
                questionTitle,
                optionList,
                hasOther: hasOther,
                id,
                isNew: true,
            });
            dispatch(actions.setQuestionsError(false));
        } else {
            dispatch(actions.setQuestionsError(true));
        }
    };
    const addNewOption = item => {
        setOptionList(previousState => {
            return [...previousState, item];
        });
    };

    const collectionOptionData =
        get(configuration, "details", [])?.length > 0
            ? REGISTER_DETAIL_DROPDOWN.filter(
                  ele => !ele.id.includes(get(configuration, "details", []))
              )
            : REGISTER_DETAIL_DROPDOWN;

    const collectionDropdown = {
        className: `${styles.editDropdown}`,
        options: collectionOptionData,
        idField: "id",
        keyField: "label",
        label: "Search by name",
    };

    const keywordsInputJson = {
        type: "",
    };

    return (
        <div className={`${styles["add-new-question"]} ${className}`}>
            <div>
                <div className={styles["question-container"]}>
                    {/* <TargettingParamsWrapperComp
                        availableSet={["collection"]}
                        item={{
                            id: "collection",
                        }}
                        key={"collection"}
                        header={"collection"}
                        // ref={keywordsRef}
                        readOnly={true}
                    > */}
                    <InputList
                        inputJson={keywordsInputJson}
                        type="singleDropdownCreateTags"
                        title={"Collection"}
                        dropDownJson={collectionDropdown}
                        mainState={items}
                        setMainState={data => {
                            dispatch(collectionActions.setDetailItems(data));
                        }}
                        readOnly={isReadOnly}
                    />
                    <div className="form-row">
                        <h3 className="headerTitleRequired">Question Name*</h3>
                        <p className="infoText">
                            Its a unique name associated with the question
                        </p>
                        <Input
                            value={questionName}
                            placeholder="Enter a question name"
                            ref={questionNameRef}
                            required={true}
                            disabled={
                                !!questionName &&
                                questionsList
                                    .filter(_ => !_.isNew)
                                    ?.some(_ => _.questionName === questionName)
                            }
                            custom={questionCustomValidation}
                        />
                    </div>
                    <div className="form-row">
                        <h3 className="headerTitleRequired">Question Title*</h3>
                        <p className="infoText">
                            Its a question title displayed in a survey
                        </p>
                        <Input
                            value={questionTitle}
                            placeholder="Enter a question title"
                            ref={questionTitleRef}
                            required={true}
                        />
                    </div>

                    <div className={`form-row ${styles.tableWrapper}`}>
                        <h3 className="headerTitleRequired">Choices*</h3>
                        <p className="infoText">
                            Create multiple choices for the user
                        </p>
                        <KeyValueAdd
                            keyLabel={"Key"}
                            valueLabel={"Text"}
                            onNewTag={addNewOption}
                            refElements={refEle}
                        ></KeyValueAdd>
                    </div>
                    <div
                        className={`form-row ${styles["tag-list-dragable-wrap"]}`}
                    >
                        <TagListDragable
                            itemsList={optionList}
                            setItemList={setOptionList}
                        ></TagListDragable>
                    </div>
                    <div
                        className={`form-row dp-parent ${styles["has-offer"]}`}
                    >
                        <p className="headerTitleRequired">Has Other</p>
                        <ToggleSwitch
                            isToggleOn={hasOther}
                            setIsToggleOn={setHasOther}
                        />
                    </div>
                </div>
                <div className={`${styles["save-container"]} dp-parent`}>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button
                        onClick={addQuestion}
                        btnTheme={ButtonTypes.primaryHover_btn}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AddNewDetail;
