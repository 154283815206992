import React, { useState } from "react";
import Select from "../../Shared/Select/Select";
import s from "./OfferWallDetails.modules.scss";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { useRef } from "react";
import {
    createOfferWallActions,
    createOfferWallData,
    updateOfferWallData,
} from "../../store/listings/offerWall";
import { useDispatch, useSelector } from "react-redux";
// import { updateOfferPathData } from "../../store/listings/offerPaths";
import CreateOfferWall from "../../Pages/CreateOfferWall/CreateOfferWall";
import { toastListActions } from "../../store/toastList/toastList";
import { get, isEmpty } from "lodash";
import { collectionActions } from "../../store/flowmanager/collection";
import { fetchOfferPathData } from "../../store/survey/offerpath";
// import { createOfferWallActions } from "../../store/listings/offerWall";
import { fetchOfferWallList } from "../../store/listings/giftCards";

const OfferWallDetails = ({
    handleOfferWall,
    list = [],
    name = "",
    createOption,
    id = "",
    highlightNameError,
    isSidePanel = false,
    readOnly = false,
}) => {
    const dispatch = useDispatch();
    const [isCreate, setIsCreate] = useState(false);
    const createOfferWallRef = useRef(null);
    const { loading, mainLoader } = useSelector(
        state => state.listings.offerPaths
    );

    const sidePanelRef = useRef();

    const createOfferWall = async offer_path_name => {
        sidePanelRef.current.open();
        await setIsCreate(true);
        // await dispatch(createOfferWallActions.setOfferPathWeight(100));
        await dispatch(
            createOfferWallActions.setOfferWallName(offer_path_name)
        );
    };

    const createNewOfferWall = async () => {
        let isValid = await createOfferWallRef.current.validateAllFields();

        if (isValid) {
            dispatch(createOfferWallActions.setMainLoader(true));
            dispatch(createOfferWallData())
                .unwrap()
                .then(async response => {
                    if (response) {
                        // await dispatch(fetchOfferPathData());
                        await dispatch(fetchOfferWallList());
                        await dispatch(
                            collectionActions.setSelectedOfferWallDetails(
                                response?.data?.data
                            )
                        );
                        await dispatch(
                            collectionActions.setSelectedOfferWallId(
                                response?.data?.data?.id
                            )
                        );
                        await dispatch(
                            createOfferWallActions.setMainLoader(false)
                        );
                        await dispatch(
                            toastListActions.setToastList({
                                type: "Success",
                                message: "Created Offer Wall successfully",
                            })
                        );
                        await sidePanelRef.current.close();
                        await setIsCreate(false);
                    }
                });
        }
    };

    const handleCreateNewItem = async value => {
        await dispatch(collectionActions.setSelectedOfferWallDetails({}));
        await dispatch(collectionActions.setSelectedOfferWallId(""));
        await dispatch(createOfferWallActions.setOfferWallName(value?.name));
        await createOfferWall(value?.name);
    };

    const editExistingOffer = async value => {
        await setIsCreate(false);
        handleOfferWall(value);
    };

    const updateOfferWall = async id => {
        let isValid = await createOfferWallRef.current.validateAllFields();
        if (isValid) {
            dispatch(createOfferWallActions.setMainLoader(true));
            dispatch(updateOfferWallData(id))
                .unwrap()
                .then(async response => {
                    if (response) {
                        setTimeout(async () => {
                            await dispatch(fetchOfferWallList()).then(
                                async res => {
                                    await dispatch(
                                        collectionActions.setSelectedOfferPathId(
                                            get(response, "data.data.id", "")
                                        )
                                    );
                                }
                            );
                            dispatch(
                                createOfferWallActions.setMainLoader(false)
                            );
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Success",
                                    message: "Updated Offer Wall Successfully",
                                })
                            );
                            await sidePanelRef.current.close();
                        }, 1000);
                    }
                });
        }
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={`${!isSidePanel ? "flexHeightTab" : ""}`}
        >
            <div className={`row`}>
                <p className={`headerTitle`}>{"Offer Wall"}</p>
                <div className={s.heading}>
                    <p className={`headerTitleRequired`}>Name</p>

                    <p
                        // className={`${s.editLink} ${!id} ? ${
                        //     s.disabledLink
                        // } : ''`}
                        className={`${s.editLink} ${
                            id && !isEmpty(list) ? s.enableLink : s.disabledLink
                        }`}
                        onClick={() => {
                            id && !isEmpty(list)
                                ? sidePanelRef?.current?.open()
                                : null;
                        }}
                    >
                        Edit
                    </p>
                </div>
                <p className={`infoText`}>
                    They are linked to multiple surveys.
                </p>
                {
                    <div className={`dp-parent`}>
                        <div className={`dropdownLayer`}>
                            <Select
                                label={"Select or create offer wall"}
                                options={list.map(_ => {
                                    return { ..._, name: _.id + ": " + _.name };
                                })}
                                keyField="name"
                                showSearch={true}
                                placeholder={"Search Offer Walls"}
                                onSelect={editExistingOffer}
                                onLoadSelectReturn={false}
                                showCreate={true}
                                // selectedId={selectedOfferPath ? null : name}
                                selectedId={id ? id : null}
                                idField="id"
                                onCreate={handleCreateNewItem}
                                isError={highlightNameError}
                                isDisabled={readOnly}
                                position="top"
                                loading={isEmpty(list)}
                            />
                        </div>
                    </div>
                }
                <SidePanelInfinity
                    title={`${id ? "Edit" : "Create"} Offer Wall`}
                    width={"95%"}
                    ref={sidePanelRef}
                    onSave={() => {
                        id ? updateOfferWall(id) : createNewOfferWall();
                    }}
                    disableSaveBtn={readOnly || mainLoader}
                    children={
                        <div className={s.offerWrapper}>
                            {id && !isCreate ? (
                                <CreateOfferWall
                                    id={id}
                                    isSidePanel={false}
                                    ref={createOfferWallRef}
                                />
                            ) : (
                                <CreateOfferWall
                                    id={null}
                                    isSidePanel={false}
                                    ref={createOfferWallRef}
                                />
                            )}
                        </div>
                    }
                ></SidePanelInfinity>
            </div>
        </div>
    );
};

export default OfferWallDetails;
