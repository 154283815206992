export const REGISTER_DETAIL_DROPDOWN = [
    {
        id: "fullName",
        label: "Full Name",
    },
    {
        id: "zipCode",
        label: "Zipcode",
    },
    {
        id: "jobTitle",
        label: "Job Title",
    },
    {
        id: "email",
        label: "Email",
    },
    {
        id: "address",
        label: "Address",
    },
    {
        id: "mobileNo",
        label: "MobileNo",
    },
    {
        id: "fname",
        label: "First Name",
    },
    {
        id: "lname",
        label: "Last Name",
    },
    {
        id: "skipToOffer",
        label: "Skip To Offer",
    },
    {
        id: "skipToListings",
        label: "Skip To Listings",
    },
    {
        id: "streetAddress",
        label: "Street Address",
    },
    {
        id: "city",
        label: "City",
    },
    {
        id: "state",
        label: "State",
    },

    {
        id: "gender",
        label: "Gender",
    },
    {
        id: "dob",
        label: "Date of birth",
    },
    {
        id: "customSelector",
        label: "Custom Selector",
    },
];

export const INTENT_DROPDOWN = [
    {
        id: "email",
        label: "Email",
    },
    {
        id: "mobileNo",
        label: "Mobile No",
    },
    {
        id: "simpleOptin",
        label: "Simple Opt In",
    },
    {
        id: "simpleOptin1",
        label: "Simple Opt In 1",
    },
    {
        id: "simpleOptin2",
        label: "Simple Opt In 2",
    },
    {
        id: "simpleOptin3",
        label: "Simple Opt In 3",
    },
    {
        id: "simpleOptin4",
        label: "Simple Opt In 4",
    },
    {
        id: "simpleOptin5",
        label: "Simple Opt In 5",
    },
];

export const VISIBILITY_DROPDOWN = [
    {
        id: "onLoad",
        label: "On Load",
    },
    {
        id: "onFieldValidated",
        label: "On Field Validated",
    },
];

export const PARTNER_DROPDOWN = [
    {
        id: "onLoad",
        label: "onLoad",
    },
    {
        id: "onFieldValidated",
        label: "onFieldValidated",
    },
];

export const POSITION_DROPDOWN = [
    {
        id: "top",
        label: "Top",
    },
    {
        id: "bottom",
        label: "Bottom",
    },
];
