import React, { useState, useEffect, useRef } from "react";
import styles from "./TargettingParamsWrapperComp.module.scss";

import Tooltip from "../../Shared/Tooltip/Tooltip";
import Icon from "../../Shared/Icon/Icon";
import PopupTargettingParams from "../../components/PopupTargettingParams/PopupTargettingParams";
import { get } from "lodash";
import { forwardRef } from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import Select from "../../Shared/Select/Select";

const TargettingParamsWrapperComp = forwardRef(
    (
        {
            header,
            children,
            className,
            availableSet = null,
            Rfunction,
            resetFunction,
            item,
            readOnly = false,
            showToggleSwitch = false,
            toggleSwitchState,
            toggleOnChange,
            isFlowParam = false,
            resetTooltipMsg = "Reset Param",
        },
        ref
    ) => {
        const [show, setShow] = useState(false);
        const [showPopup, setShowPopup] = useState(false);
        const [resetPopUp, setResetPopUp] = useState(false);

        useEffect(() => {
            if (availableSet) {
                if (
                    availableSet.findIndex(set => set == get(item, "id", "")) >
                    -1
                )
                    setShow(true);
                else setShow(false);
            } else setShow(true);
        }, [availableSet]);
        return (
            <>
                {show && (
                    <div
                        className={styles.TargettingParamsWrapperComp}
                        ref={ref}
                    >
                        {showPopup && (
                            <div className={styles.popupWrapper}>
                                <PopupTargettingParams
                                    setName={get(item, "id", "")}
                                    Rfunction={Rfunction}
                                    setShowPopup={setShowPopup}
                                    nameOnPopUp={get(item, "name", "")}
                                    actionText={"delete"}
                                    // yesFunc={removeSet}
                                    // displayBoolean={true}
                                />
                            </div>
                        )}

                        {resetPopUp && (
                            <div className={styles.popupWrapper}>
                                <PopupTargettingParams
                                    setName={get(item, "id", "")}
                                    Rfunction={() => {
                                        resetFunction(get(item, "id", ""));
                                    }}
                                    setShowPopup={setResetPopUp}
                                    nameOnPopUp={get(item, "name", "")}
                                    actionText={"reset"}
                                    // yesFunc={removeSet}
                                    // displayBoolean={true}
                                />
                            </div>
                        )}

                        <div className={styles.header}>
                            <div
                                className={`${styles.headerText} ${
                                    isFlowParam ? styles.rightBorder : ""
                                }`}
                            >
                                {item?.icon && (
                                    <Icon icon={item?.icon} size="18px" />
                                )}
                                {header}
                            </div>
                            {isFlowParam && (
                                <div className={styles.headerSelect}>
                                    <Select
                                        isDisabled
                                        label="Equals to"
                                        options={[{ test: 123 }]} // dummy options to show label
                                    />
                                </div>
                            )}
                            {!readOnly && (
                                <div className={styles.editIconWrapper}>
                                    {Rfunction !== undefined && (
                                        <div
                                            className={`${styles.TooltipWrapper} circleIconColor circleIconColor--delete`}
                                        >
                                            <Tooltip
                                                message="Delete Param"
                                                position="top"
                                            >
                                                <Icon
                                                    icon="Delete"
                                                    size="18px"
                                                    enableHover={true}
                                                    onClick={() => {
                                                        setShowPopup(true);
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    )}
                                    {resetFunction !== undefined && (
                                        <div
                                            className={`${styles.TooltipWrapper} circleIconColor`}
                                        >
                                            <Tooltip
                                                message={resetTooltipMsg}
                                                position="top"
                                            >
                                                <Icon
                                                    icon="Reset"
                                                    size="18px"
                                                    enableHover={true}
                                                    onClick={() => {
                                                        setResetPopUp(true);
                                                        // resetFunction(
                                                        //     get(item, "id", "")
                                                        // );
                                                    }}
                                                />
                                            </Tooltip>
                                        </div>
                                    )}
                                    {showToggleSwitch && (
                                        <div
                                            className={
                                                styles.ToggleSwitchWrapper
                                            }
                                        >
                                            <ToggleSwitch
                                                isToggleOn={toggleSwitchState}
                                                setIsToggleOn={toggleOnChange}
                                                disabled={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className={`${styles.body} ${
                                className ? className : ""
                            }`}
                        >
                            {children}
                        </div>
                    </div>
                )}
            </>
        );
    }
);

export default TargettingParamsWrapperComp;
