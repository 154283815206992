import s from "./FlowViewOnly.module.scss";
import FlowRenderer from "../../Shared/FlowRenderer/FlowRenderer";
import { FlowLegends, flowRulesToNormalRules } from "../CreateFlow/FlowUtils";
import {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
    useEffect,
} from "react";

const FlowViewOnly = forwardRef(({ ...props }, ref) => {
    const flowRef = useRef(null);

    const [localRules, setLocalRules] = useState(props?.rules || []);

    useImperativeHandle(ref, () => {
        return {
            focusNode: id => {
                flowRef.current.focusNode(id);
            },
        };
    });

    useEffect(() => {
        if (props?.rules?.length && props?.showStartNodeGlobalBackFallBack) {
            let data = props.rules?.filter(_ => _.isActive);
            const [rules] = flowRulesToNormalRules(data);
            setLocalRules(rules);
        } else {
            setLocalRules(props?.rules || []);
        }
    }, [props.rules]);

    return (
        <div className={s.wrapper}>
            {props.header && (
                <div
                    className={`${s.historyHeader} flex flex-wrap gap-5 fadeIn`}
                >
                    {props.header}
                    {props.showGoback &
                    (
                        <span
                            className="link underline"
                            onClick={() => closeHistoryPanel()}
                        >
                            Click to go back.
                        </span>
                    )}
                </div>
            )}

            <FlowRenderer
                ref={flowRef}
                filteredRules={[]}
                statsMap={{}}
                {...props}
                autoLayout
                readOnly
                rules={localRules}
                fallBackStartId={props.fallBackStartId}
                globalRulesStartId={
                    props.rules.filter(
                        _ => _.ruleSetId === props.globalRulesStartId
                    )[0]?.nextSetId
                }
                showStartNodeHistory={
                    props?.showStartNodeGlobalBackFallBack || false
                }
                litigatorRulesStartId={props?.litigatorRulesStartId}
            ></FlowRenderer>
            {props.showLegends && <FlowLegends loading={props.loading} />}
        </div>
    );
});

export default FlowViewOnly;
