import React, { useEffect, useState } from "react";
import c from "./JourneyGraph.module.scss";

import Skeleton from "react-loading-skeleton";
import { isEmpty } from "lodash";
import { useParams } from "react-router";

const labelArr = [
    "Landing",
    "Zip Submit",
    "Email Submit",
    "Registration",
    "Survey Impression",
    "Survey Completion",
    "Offer Path Impression",
    "Offer Path Completion",
    "Listing Impression",
];
const labelRewardsSurvey = [
    "Landing",
    "Zip Submit",
    "Email Submit",
    "Registration",
    "Survey Impression",
    "Survey Completion",
    "Offer Path Impression",
    "Offer Path Completion",
    "Offer Wall Impression",
    "Gift Card Fulfilled",
];
const graphColor = [
    "#D4F6F1",
    "#C4F1EA",
    "#9DECDF",
    "#69E2CE",
    "#4BCBB5",
    "#40BAA5",
    "#2E9E8B",
    "#057966",
    "#0F594D",
    "#06362E",
    "#03211C",
];

const noGraphColor = "#FFF4F4";

const JourneyGraph = ({ journeyData = [], loading }) => {
    const renderGraph = BAR_COUNT => {
        let elementArr = [];
        let showDropped = false;
        let droppedLabelAdded = false;
        let isPresentArr = journeyData;
        let lastTrueIndex = !isEmpty(journeyData)
            ? journeyData?.lastIndexOf(true)
            : -1;
        if (lastTrueIndex > -1) {
            let lastFalseIndex =
                lastTrueIndex === isPresentArr?.length
                    ? null
                    : lastTrueIndex + 1;
            for (let i = 0; i < BAR_COUNT; i++) {
                if (!isPresentArr[i] && i === lastFalseIndex)
                    showDropped = true;
                elementArr.push(
                    <div
                        className={`${c.graphItem} 
                        ${
                            isPresentArr[i] &&
                            !isPresentArr[i + 1] &&
                            !droppedLabelAdded &&
                            i === lastFalseIndex - 1 &&
                            c.noRightBorder
                        }
                        ${
                            !isPresentArr[i + 1] &&
                            !droppedLabelAdded &&
                            !isPresentArr[i] &&
                            i === lastFalseIndex &&
                            c.halfBorder
                        }`}
                        key={`${graphlabelArray[i]}_${i}`}
                    >
                        <div
                            className={`${c.graphLine} 
                            ${
                                !isPresentArr[i] &&
                                showDropped &&
                                !droppedLabelAdded &&
                                c.droppedLabel
                            } 
                            `}
                            style={
                                isPresentArr[i]
                                    ? { background: graphColor[i] }
                                    : { background: noGraphColor }
                            }
                        >
                            {!isPresentArr[i] &&
                                showDropped &&
                                !droppedLabelAdded && (
                                    <p className={`${c.label} ${c.dropped}`}>
                                        Dropped
                                    </p>
                                )}
                        </div>
                        <p className={c.label}>{graphlabelArray[i]}</p>
                    </div>
                );
                if (showDropped) droppedLabelAdded = true;
            }
        } else {
            for (let i = 0; i < BAR_COUNT; i++) {
                elementArr.push(
                    <div
                        className={`${c.graphItem} `}
                        key={`${graphlabelArray[i]}_${i}`}
                    >
                        <div
                            className={`${c.graphLine} `}
                            style={{ background: noGraphColor }}
                        ></div>
                        <p className={c.label}>{graphlabelArray[i]}</p>
                    </div>
                );
            }
        }
        return elementArr;
    };
    const urlParams = useParams();
    const [barCount, setBarCount] = useState(10);
    const [graphlabelArray, setGraphlabelArray] = useState([]);

    useEffect(() => {
        const getBarCount = () => {
            let BAR_COUNT = urlParams?.domain.includes("rewardsurveyusa")
                ? 10
                : 9;
            let labels = urlParams?.domain.includes("rewardsurveyusa")
                ? labelRewardsSurvey
                : labelArr;
            setBarCount(BAR_COUNT);
            setGraphlabelArray(labels);
        };
        getBarCount();
    }, []);

    useEffect(() => {
        const fetch = async () => {
            let BAR_COUNT = urlParams?.domain.includes("rewardsurveyusa")
                ? 10
                : 9;
            renderGraph(BAR_COUNT);
        };
        fetch();
    }, [journeyData]);

    return (
        <>
            {
                <div className={`${c.graphContainer}`}>
                    {!loading ? (
                        renderGraph(barCount)
                    ) : (
                        <Skeleton height={80} />
                    )}
                </div>
            }
        </>
    );
};

export default JourneyGraph;
