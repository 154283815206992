import s from "./FeatureCopyToolbar.module.scss";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import Icon from "../../Shared/Icon/Icon";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch } from "react-redux";
import { capitalizeString } from "../../utils/globalUtils";
import Popup from "../../Shared/Popup/Popup";
import _, { isEqual } from "lodash";
import {
    numberOfSets,
    numberOfSetsOfferPath,
    numberOfSetsFeatureSets,
    numberOfSetsABTest,
} from "../../constants/targettingParamsConstants";
import { getPageName } from "../../utils/localStorageUtils";

const FeatureCopyToolbar = forwardRef(
    (
        {
            displayText,
            featureCopyData,
            handleLoad,
            customClass,
            readOnly = false,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const [copiedContent, setCopiedContent] = useState(
            JSON.parse(localStorage.getItem("featureClipboard")) || []
        );
        const [toLoad, setToLoad] = useState(null);
        const [showPopup, setShowPopup] = useState(null);
        const [exclusionKeys, setExclusionKeys] = useState([
            "availableSet",
            "tableError",
            "pagination",
            "duplicateUser",
            "regExp",
            "undefined",
        ]); //temporary, need to remove once missing params are
        const paramsNameMapping = [
            ...numberOfSets,
            ...numberOfSetsOfferPath,
            ...numberOfSetsFeatureSets,
            ...numberOfSetsABTest,
        ]
            .map(_ => ({ [_.id]: _.name }))
            .reduce(function (result, item) {
                var key = Object.keys(item)[0]; //first property: a, b, c
                result[key] = item[key];
                return result;
            }, {});

        const addItemToToast = item =>
            dispatch(toastListActions.setToastList({ ...item }));

        useEffect(() => {
            try {
                localStorage.setItem(
                    "featureClipboard",
                    JSON.stringify(copiedContent)
                );
                window.dispatchEvent(new Event("storage"));
                setLoadContent();
            } catch (e) {}
        }, [copiedContent]);

        const setLoadContent = () => {
            try {
                if (
                    JSON.parse(localStorage.getItem("featureClipboard"))[
                        featureCopyData.type
                    ]
                ) {
                    const page = getPageName();
                    //Added this to disable offer path slots in offer wall and vice versa

                    if (page === "offerwall") {
                        if (
                            JSON.parse(
                                localStorage.getItem("featureClipboard")
                            )[featureCopyData.type].sourcePage === "Offer Wall"
                        ) {
                            setToLoad(
                                JSON.parse(
                                    localStorage.getItem("featureClipboard")
                                )[featureCopyData.type].copiedData
                            );
                        } else {
                            setToLoad(false);
                        }
                    } else if (page === "offerpaths") {
                        if (
                            JSON.parse(
                                localStorage.getItem("featureClipboard")
                            )[featureCopyData.type].sourcePage === "Offer Path"
                        ) {
                            setToLoad(
                                JSON.parse(
                                    localStorage.getItem("featureClipboard")
                                )[featureCopyData.type].copiedData
                            );
                        } else {
                            setToLoad(false);
                        }
                    } else {
                        setToLoad(
                            JSON.parse(
                                localStorage.getItem("featureClipboard")
                            )[featureCopyData.type].copiedData
                        );
                    }
                }
            } catch (e) {
                setToLoad(null);
            }
        };

        const copy = () => {
            try {
                let tempData = JSON.parse(JSON.stringify(featureCopyData));
                if (tempData.type === "slots") {
                    tempData.copiedData.map(item => {
                        item.editCreativeData.map(_ => (_.id = ""));
                        item.id = "-";
                        return item;
                    });
                }
                const latestData = JSON.parse(
                    localStorage.getItem("featureClipboard")
                );
                if (tempData && latestData && latestData[tempData?.type]) {
                    if (isEqual(tempData, latestData[tempData?.type])) {
                        addItemToToast({
                            type: "Success",
                            message: `${capitalizeString(
                                featureCopyData.type
                            )} already exist in clipboard`,
                            autoClose: 5000,
                        });
                        window.dispatchEvent(
                            new CustomEvent("itemExists", {
                                detail: tempData?.type,
                            })
                        );
                    } else {
                        addItemToToast({
                            type: "Success",
                            message: `${capitalizeString(
                                featureCopyData.type
                            )} copied to clipboard`,
                            autoClose: 5000,
                        });
                        window.dispatchEvent(
                            new CustomEvent("newItem", {
                                detail: tempData?.type,
                            })
                        );
                    }
                }
                setCopiedContent({
                    ...latestData,
                    [tempData?.type]: tempData,
                });
            } catch (e) {}
        };

        const load = data => {
            try {
                let loadData = data ? data : toLoad;
                if (handleLoad && typeof handleLoad === "function") {
                    handleLoad(loadData);
                    addItemToToast({
                        type: "Success",
                        message: `${capitalizeString(
                            featureCopyData.type
                        )} Loaded successfully`,
                        autoClose: 5000,
                    });
                    if (featureCopyData && featureCopyData.type === "params") {
                        const notCopied = Object.keys(loadData)
                            .filter(
                                tp =>
                                    !exclusionKeys.includes(tp) &&
                                    !Object.keys(
                                        featureCopyData.copiedData
                                    ).includes(tp) &&
                                    loadData[tp] !== "" &&
                                    loadData[tp].length &&
                                    loadData[tp] !== null &&
                                    loadData[tp] !== undefined
                            )
                            .map(_ => paramsNameMapping[_]);

                        if (notCopied.length > 0) {
                            addItemToToast({
                                type: "Error",
                                message: `Following params couldn't be loaded - ${notCopied.join(
                                    ", "
                                )}`,
                                autoClose: false,
                            });
                        }
                    }
                }
            } catch (e) {}
        };

        const checkLoad = () => {
            try {
                let temp = JSON.parse(JSON.stringify(toLoad));
                if (
                    window.location.href.includes("configurations/abtests") &&
                    temp?.region
                ) {
                    temp.regions = temp.region;
                    temp.availableSet = [...temp.availableSet, "regions"];
                    temp.availableSet = temp.availableSet.filter(
                        _ => _ !== "region"
                    );
                    delete temp.region;
                } else {
                    if (temp?.regions) {
                        temp.region = temp.regions;
                        temp.availableSet = [...temp.availableSet, "region"];
                        temp.availableSet = temp.availableSet.filter(
                            _ => _ !== "regions"
                        );
                        delete temp.regions;
                    }
                }
                setToLoad(temp);
                if (
                    featureCopyData.copiedData.length > 0 ||
                    Object.keys(featureCopyData.copiedData)
                        .filter(key => !exclusionKeys.includes(key))
                        .some(
                            i =>
                                featureCopyData.copiedData[i] !== "" &&
                                featureCopyData.copiedData[i] !== null &&
                                featureCopyData.copiedData[i] !== undefined &&
                                featureCopyData.copiedData[i].length
                        )
                )
                    setShowPopup(true);
                else load(temp);
            } catch (e) {}
        };

        const enableCopy = data => {
            try {
                if (!featureCopyData.type.includes("Dedupe")) {
                    if (window.location.pathname.includes("create"))
                        return false;
                }

                if (featureCopyData.type.includes("Dedupe") && data?.length)
                    return true;

                if (
                    (featureCopyData.type === "params" &&
                        Object.keys(data)
                            .filter(key => !exclusionKeys.includes(key))
                            .some(
                                i =>
                                    data[i] !== "" &&
                                    data[i] !== null &&
                                    data[i] !== undefined &&
                                    data[i].length
                            )) ||
                    data?.length
                ) {
                    return true;
                } else return false;
            } catch (e) {}
        };

        window.addEventListener("storage", setLoadContent);

        useImperativeHandle(ref, () => {
            return {
                copyData: copy,
                loadData: load,
            };
        });

        return (
            <div
                className={`dp-parent dp-parent-ver-center gap-20 ${
                    s.wrapper
                } ${customClass ? customClass : ""}`}
                ref={ref}
            >
                <div
                    className={`${s.iconWrap} ${
                        enableCopy(featureCopyData.copiedData) ? "" : s.disabled
                    }
                    ${readOnly ? s.disabled : ""}
                    `}
                    onClick={copy}
                >
                    <Tooltip message={`Copy ${displayText}`} position="top">
                        <Icon
                            icon="Copy"
                            size="16px"
                            enableHover={true}
                            color={
                                enableCopy(featureCopyData.copiedData) &&
                                !readOnly
                                    ? "#41c3a9"
                                    : "#CAD1D4"
                            }
                        />
                        <span className={s.iconLabel}>Copy {displayText}</span>
                    </Tooltip>
                </div>
                <div
                    className={`${s.iconWrap} ${toLoad ? "" : s.disabled}
                    ${readOnly ? s.disabled : ""}
                    `}
                    onClick={checkLoad}
                >
                    <Tooltip message={`Load ${displayText}`} position="top">
                        <Icon
                            icon="Paste"
                            size="16px"
                            enableHover={true}
                            color={toLoad && !readOnly ? "#41c3a9" : "#CAD1D4"}
                        />
                        <span className={s.iconLabel}>Load {displayText}</span>
                    </Tooltip>
                </div>
                {showPopup && (
                    <Popup
                        showHeader={false}
                        showFooter={false}
                        showConfirmBox={true}
                        saveButtonText={`Load ${capitalizeString(
                            featureCopyData.type
                        )}`}
                        cancelButtonText={"Cancel"}
                        closeOutside={true}
                        message={`Loading ${featureCopyData.type} ${
                            featureCopyData.type.includes("Dedupe")
                                ? " will add to your current"
                                : "will replace your current"
                        }  ${featureCopyData.type}. Do you wish to go ahead?`}
                        disableSave={false}
                        width={"400px"}
                        top={"0px"}
                        right={"-20px"}
                        portal={false}
                        onClose={() => setShowPopup(false)}
                        onSave={() => {
                            load();
                            setShowPopup(false);
                        }}
                        textAlign={"left"}
                    />
                )}
            </div>
        );
    }
);

export default FeatureCopyToolbar;
