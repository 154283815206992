import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep, forEach, get, keys } from "lodash";
import { ADS, HNP_URL, SURVEYS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import {
    getSegmentAd,
    getQuestionList,
    getSelectedMappedSegments,
    setSelectedSurveyPath,
} from "../../utils/surveyPathUtils";
import {
    getTargettingParams,
    mapTargettingParams,
} from "../../utils/globalUtils";
import { convertSegments } from "../../utils/surveyUtils";
import { toastListActions } from "../toastList/toastList";

const initialTargetParamsState = {
    region: [],
    countries: [],
    browsers: [],
    devices: [],
    os: [],
    genders: [],
    utmMediums: [],
    utmSources: [],
    utmCampaigns: [],
    utmContents: [],
    ageRange: [],
    keywords: [],
    availableSet: ["utmSources", "region", "keywords", "ageRange"],
    keyVals: [],
    timeOfDay: [],
    allowedDays: [],
    zipCodes: [],
    // spamCheck: {
    //     spamIP: false,
    //     category: [],
    // },
    userStatus: [],
    keywordCategories: [],
    city: [],
};

const initialSurveyPathState = {
    basics: {
        nameId: "",
        weight: "",
        statusEnabled: true,
        createNewPath: true,
        list: [],
        error: false,
        option: null,
        id: null,
    },
    questionBank: {
        itemsArr: [],
        itemsArrOriginal: [], //original ref, not to be edited
        details: null,
        selected: [],
        selectedListActiveItem: "",
        deleted: [],
    },
    targettingParam: {
        targetParams: initialTargetParamsState,
        dropDownState: {
            plusDropdownData: "",
            showPlusDropdown: false,
            productList: [
                { id: 1, firstName: "abcd", lastName: "myjobscornesssr.com" },
                { id: 2, firstName: "eafgbh", lastName: "realdealsweeps.com" },
                { id: 3, firstName: "ijkl", lastName: "rewardsurveyusa.com" },
                { id: 4, firstName: "mnop", lastName: "test@test.com" },
                { id: 5, firstName: "qrst", lastName: "test@test.com" },
                { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                { id: 7, firstName: "yz", lastName: "test@test.com" },
            ],
            productListPlusData: [
                {
                    id: "utmSources",
                    firstName: "UTM Sources",
                    lastName: "myjobscornesssr.com",
                },
                {
                    id: "region",
                    firstName: "Region",
                    lastName: "realdealsweeps.com",
                },
                {
                    id: "keywords",
                    firstName: "Keywords",
                    lastName: "rewardsurveyusa.com",
                },
                {
                    id: "ageRange",
                    firstName: "Age Range",
                    lastName: "test@test.com",
                },
                {
                    id: "genders",
                    firstName: "Gender",
                    lastName: "test@test.com",
                },
                {
                    id: "countries",
                    firstName: "Countries",
                    lastName: "test@test.com",
                },
                {
                    id: "zipCodes",
                    firstName: "Zip Codes",
                    lastName: "test@test.com",
                },
                {
                    id: "timeOfDay",
                    firstName: "Time of Day",
                    lastName: "test@test.com",
                },
                {
                    id: "utmCampaigns",
                    firstName: "UTM Campaigns",
                    lastName: "test@test.com",
                },
                {
                    id: "utmContents",
                    firstName: "UTM Contents",
                    lastName: "test@test.com",
                },
                {
                    id: "utmMediums",
                    firstName: "UTM Mediums",
                    lastName: "test@test.com",
                },
                {
                    id: "os",
                    firstName: "Operating Systems",
                    lastName: "test@test.com",
                },
                {
                    id: "browsers",
                    firstName: "Browsers",
                    lastName: "test@test.com",
                },
                {
                    id: "devices",
                    firstName: "Devices",
                    lastName: "test@test.com",
                },
                {
                    id: "allowedDays",
                    firstName: "Allowed Days",
                    lastName: "test@test.com",
                },
                {
                    id: "keyVals",
                    firstName: "A/B Tests",
                    lastName: "test@test.com",
                },
            ],
        },
        zipCodeState: {
            csvFileName: "",
            csvFile: "",
            csvArray: [],
            zipCodeError: false,
            onDropActiveZipCode: false,
            // zipCode: [],
        },
    },
};
const initialState = {
    pages: {
        "Page 1": {
            ...initialSurveyPathState,
        },
    },
    selectedPageId: "Page 1",
    pageOrder: ["Page 1"],
    segments: [],
    offerGroups: [],
    adUnits: [],
    deletedPageIds: [],
};

export const fetchSurveyPathsData = createAsyncThunk(
    "surveyPath/fetchSurveyPathsData",
    async (page, { dispatch, getState }) => {
        const {
            pages = {},
            segments = [],
            adUnits = [],
            offerGroups = [],
        } = get(getState(), "survey.surveyPath", {});
        let parallelCalls = [];
        const pageItemsArr = get(pages, `${page}.questionBank.itemsArr`, []);
        if (pageItemsArr.length == 0) {
            if (segments.length == 0)
                parallelCalls.push(customFetch(SURVEYS.GET_SEGMENTS, "GET"));
            else
                dispatch(
                    surveyPathActions.setSelectedItemsArr({
                        page,
                        payload: segments.map(item => convertSegments(item)),
                    })
                );
        }

        if (adUnits.length == 0)
            parallelCalls.push(customFetch(ADS.GET_AD_UNITS, "GET"));
        if (offerGroups.length == 0)
            parallelCalls.push(
                customFetch(HNP_URL.GET_OFFER_GROUPS_LIST, "GET")
            );
        const responses = await fetchAll(parallelCalls);
        if (
            !responses.every(
                response => get(response, "data.data.length", 0) > 0
            )
        )
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all required data!",
                })
            );
        if (pageItemsArr.length == 0 && segments.length == 0) {
            const dataItems = get(responses.shift(), "data.data", []);
            dispatch(
                surveyPathActions.setSelectedItemsArr({
                    page,
                    payload: dataItems.map(item => convertSegments(item)),
                })
            );
            dispatch(surveyPathActions.setSelectedSegments(dataItems));
        }
        if (adUnits.length == 0)
            dispatch(
                surveyPathActions.setSelectedAdUnits(
                    get(responses.shift(), "data.data", []).map(au => ({
                        id: au.adUnitId,
                        name: au.name || au.adUnitId,
                    }))
                )
            );
        if (offerGroups.length == 0)
            dispatch(
                surveyPathActions.setSelectedOfferGroups(
                    get(responses.shift(), "data.data", [])
                )
            );
    }
);

export const fetchSurveyPathDataById = createAsyncThunk(
    "survey/surveyPath/fetchSurveyPathDataById",
    async (id, { getState, dispatch }) => {
        const {
            adUnits = [],
            offerGroups = [],
            segments = [],
        } = get(getState(), "survey.surveyPath", {});
        const response = await customFetch(
            `${SURVEYS.FETCH_SURVEY_PATH}?surveyId=${id}`,
            "POST",
            {
                // const response = await customFetch(SURVEYS.FETCH_SURVEY_PATH, "POST", {
                filters: [
                    {
                        k: "surveyId",
                        v: +id,
                        op: "=",
                    },
                ],
            }
        );
        const data = get(response, "data.data", []);
        if (response.status >= 400)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch survey path data!",
                })
            );
        if (data.length > 0) {
            const { pages = {}, pageOrder = [] } = getSurveyPathDataByID(
                data,
                adUnits,
                offerGroups,
                segments
            );
            dispatch(surveyPathActions.setPagesData(pages));
            dispatch(surveyPathActions.setPageOrderData(pageOrder));
        }
        dispatch(surveyPathActions.setLoading(false));
    }
);

const getSurveyPathDataByID = (
    list,
    adUnits = [],
    offerGroups = [],
    segments = []
) => {
    let pages = {};
    let pageOrder = [];
    list.length > 0 &&
        list.map((element, index) => {
            pages[`Page ${index + 1}`] = {
                ...JSON.parse(JSON.stringify(initialSurveyPathState)),
            };
            pages[`Page ${index + 1}`].basics.nameId = element.name;
            pages[`Page ${index + 1}`].name = element.name;
            pages[`Page ${index + 1}`].basics.id = element.id;
            pages[`Page ${index + 1}`].basics.selectedSurveyId =
                element.survey.id;
            pages[`Page ${index + 1}`].targettingParam.targetParams =
                mapTargettingParams(element.targetParams, true);

            const segmentsArray = element?.segments;
            segmentsArray?.sort(function (a, b) {
                return a.index - b.index;
            });
            const selectedSegments =
                segmentsArray?.map(s => s.segment.id) || [];
            pages[`Page ${index + 1}`].questionBank.selected = selectedSegments;
            pages[`Page ${index + 1}`].questionBank.details =
                selectedSegments[0];
            pages[`Page ${index + 1}`].questionBank.itemsArr =
                setSelectedSurveyPath(
                    segments,
                    element.segments,
                    adUnits,
                    offerGroups
                );
            pages[`Page ${index + 1}`].questionBank.itemsArrOriginal =
                pages[`Page ${index + 1}`].questionBank.itemsArr;
            pageOrder.push(`Page ${index + 1}`);
        });
    return { pages, pageOrder };
};

const surveyPathSlice = createSlice({
    name: "surveypath",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setPagesData(state, action) {
            state.pages = { ...action.payload };
        },
        setPageOrderData(state, action) {
            state.pageOrder = [...action.payload];
        },
        addSurveyPathTab(state, action) {
            state.pages[action.payload] = {
                ...initialSurveyPathState,
                isNewTab: true,
            };
            state.selectedPageId = action.payload;
            state.pageOrder.push(action.payload);
        },
        setSelectedPageId(state, action) {
            state.selectedPageId = action.payload;
        },

        revertDeletePageId(state, action) {
            const id = state.pages[action.payload].basics.id;
            state.deletedPageIds.splice(state.deletedPageIds.indexOf(id), 1);
        },
        deletePageId(state, action) {
            if (!state.pages[action.payload].isNewTab) {
                state.deletedPageIds.push(
                    state.pages[action.payload].basics.id
                );
            } else {
                delete state.pages[action.payload];
                state.pageOrder = state.pageOrder.filter(
                    id => id != action.payload
                );
            }
        },
        setCreateNewPath(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].basics.createNewPath = payload;
        },
        setSelectedSurveyPathNameId(state, action) {
            const { page, payload, name, option } = action.payload;
            state.pages[page].basics.nameId = payload;
            state.pages[page].name = name;
            state.pages[page].basics.option = option;
        },
        setSelectedSurveyPathWeight(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].basics.weight = payload;
            state.pages[page].basics.error = payload.trim() === "";
        },
        setSelectedSurveyPathStatus(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].basics.statusEnabled = payload;
        },
        setSelectedItemsArr(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].questionBank.itemsArr = payload;
        },
        setSelectedSegments(state, action) {
            const { payload } = action;
            state.segments = payload;
        },
        setSelectedAdUnits(state, action) {
            const { payload } = action;
            state.adUnits = payload;
        },
        setSelectedOfferGroups(state, action) {
            const { payload } = action;
            state.offerGroups = payload;
        },
        setSelectedDetails(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].questionBank.details = payload;
        },
        setSelectedQuestionBankItems(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].questionBank.selected = payload;
        },
        setSelectedActiveListItem(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].questionBank.selectedListActiveItem = payload;
        },
        setDeletedQuestionBankItems(state, action) {
            const { page, payload } = action.payload;
            state.pages[page].questionBank.deleted = payload;
        },
        setSelectedSurveyPath(state, action) {
            const { page, payload } = action.payload;
            state.segments = get(payload, "segments", []);
            state.pages[page].questionBank.selected = [];
            state.pages[page].questionBank.details = null;
            state.pages[page].questionBank.itemsArr = get(
                payload,
                "segments",
                []
            ).map(item => ({
                id: get(item, "segmentId", ""),
                name: get(item, "segment.name", ""),
                type: !item.hasOffer
                    ? null
                    : get(item, "configInfo.adType", "") === "AdUnit"
                    ? "Ad Unit"
                    : "Offer Group",
                Ad: item.hasOffer
                    ? getSegmentAd(
                          item.configInfo.adType,
                          item.configInfo.id,
                          state.adUnits,
                          state.offerGroups
                      )
                    : null,
                Extras: item.hasOffer ? get(item, "configInfo.extras", []) : [],
                questionConfig: [],
                questionsList: getQuestionList(item),
            }));
            let targetParams = cloneDeep(get(payload, "targetParams", {}));
            forEach(keys(targetParams), key => {
                if (targetParams[key] == null) {
                    targetParams[key] = [];
                }
            });
            state.pages[page].targettingParam.targetParams = {
                ...targetParams,
                availableSet: keys(get(payload, "targetParams", {})).filter(
                    item =>
                        !!item &&
                        get(payload, `targetParams.${item}`, null) != null
                ),
            };
        },
        setTargetParams(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.targetParams = { ...data };
        },
        setDropDownState(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.dropDownState = { ...data };
        },
        setCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.csvFileName = data;
        },
        setCsvFile(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.csvFile = data;
        },
        setCsvArray(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.csvArray = data;
        },
        setZipCodeError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.zipCodeError = data;
        },
        setOnDropActiveZipCode(state, action) {
            const { data, page } = action.payload;
            state.pages[page].targettingParam.zipCodeState.onDropActiveZipCode =
                data;
        },
        setOfferPathNameError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].basics.pathNameError = data;
        },
        setOfferPathWeightError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].basics.weightError = data;
        },
        setWeightError(state, action) {
            const { data, page } = action.payload;
            state.pages[page].basics.error = data;
        },
        resetSurveyPath(state, action) {
            state.pages = {
                "Page 1": {
                    ...initialSurveyPathState,
                    isNewTab: true,
                },
            };
            state.pageOrder = ["Page 1"];
            state.selectedPageId = "Page 1";
            state.deletedPageIds = [];
        },
        setTcpaOptions(state, action) {
            const { segmentId, surveyPathId, index, optionsData } =
                action.payload;

            const questionsData = state.pages[
                state.selectedPageId
            ].questionBank.itemsArr.map(item => {
                if (item.id === segmentId) {
                    !item.questionsList[index].tcpa
                        ? (item.questionsList[index].tcpa = {})
                        : null;
                    item.questionsList[index].tcpa[surveyPathId] = optionsData;

                    return { ...item };
                }
                return { ...item };
            });

            state.pages[state.selectedPageId].questionBank.itemsArr =
                questionsData;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSurveyPathDataById.pending, (state, action) => {
            state.loading = true;
        });
    },
});

export const surveyPathActions = surveyPathSlice.actions;
export default surveyPathSlice.reducer;
