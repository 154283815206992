import { get } from "lodash";
import Button, { ButtonTypes } from "../Button/Button";
import Icon from "../Icon/Icon";
import Input from "../Input/Input";
import ToggleValueSwitch from "../ToggleValueSwitch/ToggleValueSwitch";
import s from "./InputWithActionBtn.module.scss";
import React, { useState } from "react";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import TagRenderer from "../../components/Renderers/TagRenderer/TagRenderer";

const InputWithActionBtn = React.forwardRef(
    (
        {
            customClass,
            inputImmediateParentDivClass,
            inputData,
            btnData,
            type,
            readOnly = false,
            isInputReadOnly = false,
            inputLoading = false,
            showPillsList = [],
            deletePillHandler,
        },
        ref
    ) => {
        let btnThemeProps = btnData?.btnTheme;

        const [hoverIconChange, setHoverIconChange] = useState(false);

        return (
            <div
                className={`row ${s.InputWithBtnWrapper} ${
                    customClass && InputWithBtnCustomClass
                }`}
            >
                <div
                    className={`maxChildContainer flex ${
                        type == "ToggleSwitch" && s.toggleSwitchWrapper
                    } ${
                        inputImmediateParentDivClass &&
                        inputImmediateParentDivClass
                    }`}
                >
                    {!inputLoading ? (
                        <>
                            <Input
                                {...inputData}
                                ref={ref}
                                type={get(inputData, "type", "text")}
                                placeholder={get(inputData, "placeholder", "")}
                                required={get(inputData, "required", false)}
                                className={`${s.inputWidth} ${get(
                                    inputData,
                                    "className",
                                    ""
                                )}`}
                                value={get(inputData, "value", "")}
                                disabled={
                                    readOnly ||
                                    get(inputData, "disabled", false)
                                }
                                maxWidth={`${get(inputData, "maxWidth", "")} ${
                                    s.inputImmediateWrapper
                                }`}
                                onChange={e =>
                                    inputData?.onChange &&
                                    inputData?.onChange(e.target.value, e)
                                }
                                onKeyUp={e =>
                                    inputData?.onKeyUp &&
                                    inputData?.onKeyUp(e.target.value, e)
                                }
                                readOnly={isInputReadOnly}
                            />
                        </>
                    ) : (
                        <div className={`${s.loadingInput} ${s.placeholder}`}>
                            <LinearDeterminate customClass={s.loader} />
                        </div>
                    )}
                    {type == "normalBtn" && (
                        <Button
                            onMouseEnter={() =>
                                setHoverIconChange(!hoverIconChange)
                            }
                            onMouseLeave={() =>
                                setHoverIconChange(!hoverIconChange)
                            }
                            disable={readOnly || get(btnData, "disable", false)}
                            btnTheme={
                                btnThemeProps != ""
                                    ? ButtonTypes[btnThemeProps]
                                    : false
                            }
                            onClick={get(btnData, "onClick", false)}
                            className={`${
                                btnData?.text ? "" : s.btnWithIcon
                            } ${get(btnData, "className", "")}`}
                        >
                            {btnData?.text ? (
                                get(btnData, "text", "")
                            ) : (
                                <Icon
                                    icon={
                                        hoverIconChange &&
                                        get(btnData, "hoverIconType", "") != ""
                                            ? get(btnData, "hoverIconType", "")
                                            : get(btnData, "iconType", "")
                                    }
                                    size={get(btnData, "iconSize", "")}
                                />
                            )}
                        </Button>
                    )}

                    {type == "ToggleSwitch" && (
                        <ToggleValueSwitch
                            classNameWrapper={`${
                                s.toggleValueSwitchContainer
                            } ${get(btnData, "classNameWrapper", "")}`}
                            onChange={get(btnData, "onChange", "")}
                            leftLabel={get(btnData, "leftLabel", "")}
                            rightLabel={get(btnData, "rightLabel", "")}
                            toggleState={get(btnData, "toggleState", false)}
                            isDisabled={readOnly}
                        />
                    )}
                </div>
                {showPillsList?.length > 0 && (
                    <>
                        <div className={`maxChildContainer ${s.pillsWrapper}`}>
                            {showPillsList.map((item, idx) => {
                                return (
                                    <div className={s.pills} key={idx}>
                                        <TagRenderer
                                            className={"mr-5"}
                                            type="default"
                                            onClose={() =>
                                                !readOnly
                                                    ? deletePillHandler(item)
                                                    : ""
                                            }
                                        >
                                            {item}
                                        </TagRenderer>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        );
    }
);

export default InputWithActionBtn;
