import s from "./FeatureCopyWidget.module.scss";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import Icon from "../../Shared/Icon/Icon";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Card from "../../Shared/Card/Card";
import MultiCopy from "../../assets/img/MultiCopy.svg";
import MultiCopyDark from "../../assets/img/MultiCopy-dark.svg";
import Button from "../../Shared/Button/Button";
import { getRandomId } from "../../utils/globalUtils";
import { useHistory } from "react-router-dom";
import {
    numberOfSets,
    numberOfSetsOfferPath,
    numberOfSetsFeatureSets,
    numberOfSetsABTest,
} from "../../constants/targettingParamsConstants";

const FeatureCopyWidget = () => {
    const history = useHistory();
    const [copiedContent, setCopiedContent] = useState({});
    const [miniWidget, setMiniWidget] = useState(false);
    const [closeWidget, setCloseWidget] = useState(false);
    const [isForm, setIsForm] = useState(
        history?.location?.pathname.includes("create") ||
            history?.location?.pathname.includes("edit")
    );

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);
    const existAnimation = useRef([]);
    const newItemAnimation = useRef([]);
    const paramsNameMapping = [
        ...numberOfSets,
        ...numberOfSetsOfferPath,
        ...numberOfSetsFeatureSets,
        ...numberOfSetsABTest,
    ]
        .map(_ => ({ [_.id]: _.name }))
        .reduce(function (result, item) {
            var key = Object.keys(item)[0]; //first property: a, b, c
            result[key] = item[key];
            return result;
        }, {});

    useEffect(() => {
        setIsForm(
            history?.location?.pathname.includes("create") ||
                history?.location?.pathname.includes("edit")
        );
    }, [history]);

    useEffect(() => {
        try {
            window.addEventListener("newItem", onNewItem);
            window.addEventListener("itemExists", onItemExists);
            window.addEventListener("storage", storageChange);
        } catch (e) {}
        return () => {
            window.removeEventListener("newItem", onNewItem);
            window.removeEventListener("itemExists", onItemExists);
            window.removeEventListener("storage", storageChange);
        };
    }, []);

    const onItemExists = e => {
        if (e.detail) {
            existAnimation.current.push(e.detail);
            setTimeout(() => {
                existAnimation.current.pop(e.detail);
            }, 2000);
        }
    };

    const onNewItem = e => {
        if (e.detail) {
            newItemAnimation.current.push(e.detail);
            setTimeout(() => {
                newItemAnimation.current.pop(e.detail);
            }, 2000);
        }
    };

    const storageChange = () => {
        try {
            setCopiedContent(
                JSON.parse(localStorage.getItem("featureClipboard")) || []
            );
        } catch (e) {}
    };

    const modulesCount = (data, type) => {
        if (data) {
            let keys = Object.keys(data)
                .filter(key => data[key]?.length !== 0)
                .filter(
                    key =>
                        key !== "availableSet" &&
                        key !== "tableError" &&
                        key !== "pagination" &&
                        key !== "duplicateUser"
                );
            if (keys.length > 1) {
                return (
                    <p>
                        {type === "slots" && (
                            <>
                                <span>ID: New ({data[0]?.type})</span> &{" "}
                                {keys.length - 1} more slots.
                            </>
                        )}

                        {type === "questions" && (
                            <>
                                <span>{data[0]?.name || ""}</span> &{" "}
                                {keys.length - 1} more questions.
                            </>
                        )}
                        {type === "params" && (
                            <>
                                <span>{paramsNameMapping[keys[0]]}</span>,{" "}
                                <span>{paramsNameMapping[keys[1]]}</span>
                                {keys.length - 2 > 0 &&
                                    ` & ${keys.length - 2} more parameters.`}
                            </>
                        )}
                    </p>
                );
            } else {
                return (
                    <p>
                        {type === "slots" && (
                            <span>ID: New ({data[0]?.type})</span>
                        )}

                        {type === "questions" && (
                            <span>{data[0]?.name || ""}</span>
                        )}

                        {type === "params" &&
                            keys.map(k => (
                                <span key={getRandomId()}>{k} </span>
                            ))}
                    </p>
                );
            }
        }
    };

    const discardClipboard = () => {
        setCloseWidget(true);
        setTimeout(() => {
            localStorage.removeItem("featureClipboard");
            window.dispatchEvent(new Event("storage"));
            setCloseWidget(false);
        }, 400);
    };

    return (
        <div>
            {isLoggedIn && Object.keys(copiedContent).length > 0 && (
                <Card
                    className={`${s.cardCustom} ${
                        miniWidget ? s.miniWidget : ""
                    } ${closeWidget ? s.closeWidget : ""}
                    ${!isForm ? s.inactive : ""}
                    `}
                >
                    <div>
                        <div className="dp-parent dp-parent-ver-center gap-20">
                            <img src={isDarkMode ? MultiCopyDark : MultiCopy} />
                            <div className={s.header}>
                                <p>Copied Items to Clipboard</p>
                                <span>
                                    {Object.keys(copiedContent).length} Item(s)
                                </span>
                            </div>
                        </div>

                        <div className={s.clipboard}>
                            {Object.keys(copiedContent).map(key => {
                                return (
                                    <div
                                        className={`${s.section} ${
                                            existAnimation.current.includes(
                                                copiedContent[key].type
                                            )
                                                ? s.shake
                                                : ""
                                        } ${
                                            newItemAnimation.current.includes(
                                                copiedContent[key].type
                                            )
                                                ? s.blink
                                                : ""
                                        } dp-parent dp-parent-col gap-10`}
                                        key={getRandomId()}
                                    >
                                        <p className={s.name}>
                                            <span>
                                                {copiedContent[key].sourcePage}:
                                            </span>{" "}
                                            <span>
                                                {copiedContent[key].sourceName}
                                            </span>
                                        </p>
                                        <div
                                            className={`${s.path} dp-parent-inline`}
                                        >
                                            {copiedContent[
                                                key
                                            ]?.sourcePath?.map(path => (
                                                <span key={getRandomId()}>
                                                    {path}
                                                </span>
                                            ))}
                                        </div>
                                        <div className={s.mappedData}>
                                            {modulesCount(
                                                copiedContent[key]?.copiedData,
                                                copiedContent[key]?.type
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>

                        <div
                            className={`${s.footer} dp-parent dp-parent-hor-space-between`}
                        >
                            <Tooltip message={`Collapse`} position="top">
                                <Button
                                    className={s.collapseBtn}
                                    onClick={() => setMiniWidget(true)}
                                >
                                    <Icon
                                        icon="Collapse"
                                        size="16px"
                                        enableHover={true}
                                        color="#74838F"
                                    />
                                </Button>
                            </Tooltip>

                            <Button onClick={discardClipboard}>
                                Discard & Close
                            </Button>
                        </div>
                    </div>

                    <div
                        className={s.miniWidgetOverlay}
                        onClick={() => setMiniWidget(false)}
                    >
                        <Tooltip
                            message={`${
                                Object.keys(copiedContent).length
                            } Item(s) are Copied. ${
                                !isForm ? "Available to load on forms." : ""
                            }`}
                            position="right"
                        >
                            <div
                                className={`${s.count} dp-parent dp-parent-ver-center dp-parent-hor-center`}
                            >
                                {Object.keys(copiedContent).length}
                            </div>
                        </Tooltip>
                    </div>
                </Card>
            )}
        </div>
    );
};

export default FeatureCopyWidget;
