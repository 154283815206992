import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PanelGrid from "../../Shared/PanelGrid/PanelGrid";
import s from "./AddOfferWallWithPriority.module.scss";
import { windowDimensions } from "../../utils/globalUtils";
import Input from "../../Shared/Input/Input";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import Select from "../../Shared/Select/Select";
import { cloneDeep, get } from "lodash";
import { getRandomId } from "../../utils/globalUtils";
import Icon from "../../Shared/Icon/Icon";
import Table from "../../Shared/Table/Table";
import ActionButtons from "../Renderers/ActionButtons/ActionButtons";
import {
    createOfferWallActions,
    updateOfferWallData,
} from "../../store/listings/offerWall";
import Popup from "../../Shared/Popup/Popup";
import CreateOfferWall from "../../Pages/CreateOfferWall/CreateOfferWall";
import { toastListActions } from "../../store/toastList/toastList";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";

function AddOfferWallWithPriority({
    data,
    action,
    onSetSlots,
    tableCustomClass,
    leftPopUpPosition = "0px",
    items = [],
    isReadOnly,
}) {
    const dispatch = useDispatch();
    const { loading, slots } = useSelector(
        state => state.listings.collectionDeals.createCategory
    );
    const offerWallList = useSelector(
        state => state.listings.collectionDeals.offerWallList
    );
    const [popupTopPosition, setPopupTopPosition] = useState();
    const [editItem, setEditItem] = useState({});
    const [offerWallId, setOfferWallId] = useState("");

    const priorityRef = useRef(null);
    const minimumDealRef = useRef(null);
    const editOfferPanelRef = useRef(null);
    const editOfferWallRef = useRef(null);
    const createOfferWallRef = useRef(null);
    const [dealCategoryError, setDealCategoryError] = useState(false);
    const [deletePopUp, setDeletePopUp] = useState(false);

    const selectDealCategory_Dropdown = obj => {
        dispatch(action.setOfferWallId(obj.id));
        dispatch(action.setOfferWallName(obj.name));
        setDealCategoryError(false);
    };

    const cbFunction = (name, e) => {
        if (typeof eval(name) === "function") {
            return eval(name)(e);
        } else {
            return eval(name);
        }
    };

    const onEdit = obj => {
        setEditItem(obj);
        editOfferPanelRef.current.open();
        dispatch(action.setIsEdit(true));
        dispatch(action.setOfferWallId(obj?.id));
        dispatch(action.setOfferWallName(obj?.offerWallName));
        dispatch(action.setPriority(obj?.priority));
    };

    const onEditOffer = () => {
        let updatedItems = cloneDeep(get(data, "properties.offerWallId", []));
        const indexToUpdate = updatedItems?.findIndex(
            x => x.rowId === editItem.rowId
        );
        updatedItems[indexToUpdate] = {
            ...updatedItems[indexToUpdate],
            ...slots,
        };
        onSetSlots([...updatedItems]);
        dispatch(action.setIsEdit(false));
        dispatch(action.setOfferWallId(""));
        dispatch(action.setOfferWallName(""));
        dispatch(action.setPriority(""));
        editOfferPanelRef.current.close();
    };

    const updateOfferWall = async id => {
        let isValid = await createOfferWallRef.current.validateAllFields();
        if (isValid) {
            dispatch(createOfferWallActions.setMainLoader(true));
            dispatch(updateOfferWallData(id))
                .unwrap()
                .then(async response => {
                    if (response) {
                        setTimeout(async () => {
                            dispatch(
                                createOfferWallActions.setMainLoader(false)
                            );
                            dispatch(
                                toastListActions.setToastList({
                                    type: "Success",
                                    message: "Updated Offer Wall Successfully",
                                })
                            );
                            await editOfferWallRef.current.close();
                        }, 1000);
                    }
                });
        }
    };

    const onDeleteItem = deleteData => {
        setDeletePopUp(deleteData);
    };

    const removeSlot = () => {
        let updatedItems = cloneDeep(get(data, "properties.offerWallId", []));
        const indexToUpdate = updatedItems?.findIndex(
            x => x.rowId === deletePopUp.rowId
        );
        updatedItems.splice(indexToUpdate, 1);
        onSetSlots([...updatedItems]);
    };

    const confirmPopupMsg = <p>Do you wish to delete this slot?</p>;

    const columns = [
        {
            text: "Offer Wall",
            key: "id",
            disableCopyText: true,
            styles: {
                width: "450px",
                maxWidth: "450px",
            },
            CustomRender: ({ item }) => {
                return (
                    <div
                        className={s.offerLink}
                        onClick={() => {
                            setOfferWallId(item?.id);
                            editOfferWallRef.current.open();
                        }}
                    >
                        {/* Using offerWallName here as from client side we are sending id : name (Confirmed with backend they are using only id on there side) */}
                        {item?.offerWallName}
                    </div>
                );
            },
        },
        {
            text: "Priority",
            key: "priority",
        },
        {
            text: "",
            key: "",
            CustomRender: ({ item }) => {
                return (
                    <>
                        <>
                            <ActionButtons
                                item={item}
                                editCallback={async item => {
                                    onEdit(item);
                                }}
                                deleteCallback={item => {
                                    let topPos = document
                                        .querySelector(".edit" + item.id)
                                        .getBoundingClientRect().top;
                                    let slotTopPosition = document
                                        .querySelector(".slotTable")
                                        .getBoundingClientRect().top;
                                    setPopupTopPosition(
                                        topPos - slotTopPosition + 210 + "px"
                                    );
                                    onDeleteItem(item);
                                }}
                                readOnly={isReadOnly}
                            />
                        </>
                        {deletePopUp === item && (
                            <div className={s.popUp}>
                                <Popup
                                    showHeader={false}
                                    showFooter={false}
                                    showConfirmBox={true}
                                    saveButtonText={"Yes, Go ahead"}
                                    cancelButtonText={"Cancel"}
                                    closeOutside={true}
                                    message={confirmPopupMsg}
                                    disableSave={false}
                                    width={"332px"}
                                    portal={true}
                                    containerId={document.querySelector(
                                        ".slotTable"
                                    )}
                                    textAlign={"left"}
                                    onClose={() => setDeletePopUp(null)}
                                    onSave={removeSlot}
                                    customClass={s.popupChannel}
                                    top={popupTopPosition}
                                    left={leftPopUpPosition}
                                />
                            </div>
                        )}
                    </>
                );
            },
            bulkActions: true,
        },
    ];

    const onAddListItem = () => {
        if (
            slots?.id &&
            slots?.offerWallName &&
            slots?.priority &&
            slots?.priority.indexOf(".") === -1
        ) {
            setDealCategoryError(false);
            const dataJson = {
                priority: slots?.priority,
                offerWallName: slots?.offerWallName,
                id: slots?.id,
                rowId: getRandomId(),
                dataId: "-",
            };
            let selectedListItemsCopy = JSON.parse(
                JSON.stringify(get(data, "properties.offerWallId", []))
            );
            if (
                selectedListItemsCopy.findIndex(
                    item => item.dataId === dataJson.rowId
                ) === -1
            ) {
                selectedListItemsCopy.push(dataJson);
                dispatch(action.setSelectedListItems(selectedListItemsCopy));
                dispatch(action.setPriority(""));
                dispatch(action.setOfferWallName(""));
                dispatch(action.setOfferWallId(""));
                dispatch(action.setSlotsError(false));
                onSetSlots(selectedListItemsCopy);
            }
        } else {
            dispatch(action.setSlotsError(true));
            if (get(slots, "id", "") === "") setDealCategoryError(true);
            if (get(slots, "priority", "") === "")
                priorityRef.current.validate();
            if (get(data, "properties.minDeals", "") === "")
                minimumDealRef?.current?.validate();
        }
    };

    return (
        <>
            <PanelGrid className={`${s.resetPadding} ${s.panelWidth}`}>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <div className={s.header}>
                        <div className={s.subHeader}>Offer Wall*</div>
                        <div className={s.subHeader}>Priority*</div>
                    </div>
                    <div className={s.body}>
                        <div className={s.toggleSwitch}>
                            <Select
                                label={"Select"}
                                options={offerWallList?.map(_ => {
                                    return {
                                        ..._,
                                        name: _.id + ": " + _.name,
                                    };
                                })}
                                selectedId={
                                    !get(slots, "isEdit", false) &&
                                    get(slots, "id", "")
                                        ? get(slots, "id", "")
                                        : null
                                }
                                keyField="name"
                                portal={false}
                                onSelect={selectDealCategory_Dropdown}
                                idField={"id"}
                                position={
                                    windowDimensions().height > 900
                                        ? "bottom"
                                        : "top"
                                }
                                isError={dealCategoryError}
                                onLoadSelectReturn={false}
                                isDisabled={isReadOnly}
                            />
                        </div>
                        <div className={s.input}>
                            <Input
                                ref={priorityRef}
                                type="number"
                                value={
                                    !get(slots, "isEdit", false)
                                        ? get(slots, "priority", "")
                                        : ""
                                }
                                placeholder="Priority"
                                required={true}
                                onChange={e =>
                                    dispatch(action.setPriority(e.target.value))
                                }
                                disabled={isReadOnly}
                            />
                        </div>
                        <Button
                            className={`${s.themBtn} ${s.marginLeft}`}
                            btnTheme={ButtonTypes.primaryHover_btn}
                            disable={isReadOnly}
                            type="submit"
                            onClick={onAddListItem}
                        >
                            Add
                        </Button>
                    </div>
                </form>
            </PanelGrid>
            {get(data, "properties.offerWallId", []).length > 0 ? (
                <div className={`slotTable ${tableCustomClass}`}>
                    <Table
                        tableData={{ loading: loading }}
                        columns={columns}
                        data={get(data, "properties.offerWallId", [])}
                        showBorder={true}
                        callback={cbFunction}
                        additionalProps={{ data: data }}
                    />
                </div>
            ) : (
                ""
            )}

            <SidePanelInfinity
                title={"Edit Offer"}
                ref={editOfferPanelRef}
                disableSaveBtn={isReadOnly}
                onSave={onEditOffer}
            >
                <div className={s.editContainer}>
                    <div>
                        {offerWallList.length > 0 && (
                            <div className={`row`}>
                                <div
                                    className={`${s.offerWallTitle} headerTitleRequired`}
                                >
                                    <p className={`headerTitleRequired mb-0`}>
                                        Offer Wall*
                                    </p>

                                    <span
                                        onClick={() => {
                                            editOfferWallRef.current.open();
                                            setOfferWallId("");
                                        }}
                                        className={s.editText}
                                    >
                                        <Icon icon={"Edit"} size="14px" />
                                        Edit
                                    </span>
                                </div>

                                <p className={`infoText`}>Offer Wall</p>

                                <Select
                                    label={"Select"}
                                    options={offerWallList?.map(_ => {
                                        return {
                                            ..._,
                                            name: _.id + ": " + _.name,
                                        };
                                    })}
                                    selectedId={
                                        get(slots, "id", "")
                                            ? get(slots, "id", "")
                                            : null
                                    }
                                    keyField="name"
                                    portal={false}
                                    onSelect={selectDealCategory_Dropdown}
                                    idField={"id"}
                                    position={"bottom"}
                                    isError={dealCategoryError}
                                    onLoadSelectReturn={false}
                                    isDisabled={isReadOnly}
                                />
                            </div>
                        )}
                        <div className={`row`}>
                            <p className={`headerTitleRequired capitalize`}>
                                Priority*
                            </p>
                            <p className={`infoText`}>
                                Priority of offer wall slots
                            </p>
                            <Input
                                ref={priorityRef}
                                type="number"
                                value={get(slots, "priority", "")}
                                placeholder="Priority"
                                required={true}
                                onChange={e =>
                                    dispatch(action.setPriority(e.target.value))
                                }
                                disabled={isReadOnly}
                            />
                        </div>
                    </div>
                </div>
            </SidePanelInfinity>
            <SidePanelInfinity
                ref={editOfferWallRef}
                title={"Edit Offer Wall"}
                width={"95%"}
                onSave={() => {
                    const updateId = offerWallId
                        ? offerWallId
                        : get(slots, "id", "");
                    updateOfferWall(updateId);
                }}
            >
                <CreateOfferWall
                    id={offerWallId ? offerWallId : get(slots, "id", "")}
                    isSidePanel={false}
                    ref={createOfferWallRef}
                />
            </SidePanelInfinity>
        </>
    );
}

export default AddOfferWallWithPriority;
