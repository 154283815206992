import Icon from "../../Shared/Icon/Icon";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import styles from "./TableFlex.module.scss";
import Pagination from "./Pagination";
import { useState, useEffect, useRef, memo } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { get, cloneDeep, isEmpty } from "lodash";
import { getRandomId, transformData } from "../../utils/globalUtils";
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { forwardRef } from "react";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { MESSAGES } from "../../constants/messages";
import Checkbox from "../../Shared/Checkbox/Checkbox";

import CustomMessagePage from "../../Pages/CustomMessagePage/CustomMessagePage";
import CopyRenderer from "../Renderers/CopyRenderer/CopyRenderer";
import TagRenderer from "../Renderers/TagRenderer/TagRenderer";
import { getPageName } from "../../utils/localStorageUtils";

const TableFlex = memo(
    forwardRef(
        (
            {
                columns = [],
                data = [],
                config,
                uniqueKey,
                // pagination = {},
                actions,
                onRefresh,
                isPagination = true,
                // loading = false,
                hnpTable,
                arrow,
                isEdit,
                onEdit,
                editObject,
                editObjectSelector = "id",
                onDelete,
                cancelEditState,
                getEditData,
                showBorder = false,
                headerBgColor,
                onAdd,
                frozen = false,
                showFakeScroll = true,
                AccordionComponent = null,
                // customErrorMsg,
                sortableRows = false,
                rowSortCallback,
                activeRowId = false,
                tableData = {},
                deletedIds = [],
                stickyHeaderClass = null,
                bulkRowOps = false,
                bulkRowOnChange,
                readOnly = false,
                callBack,

                gridView,
                gridViewList = null,
                showActionBtn = false,
                actionBtnHandler = null,
                actionBtnText = "",
                btnTheme = ButtonTypes.default_btn,
                onForceRefresh = false,
            },
            ref
        ) => {
            const [messageShow, setMessageShow] = useState(false);
            const columnMeta = useRef({});
            const {
                loading = false,
                tableError: customErrorMsg = "",
                pagination = {},
            } = tableData;
            const {
                pageNumber = null,
                rowsCount = null,
                isDesc = false,
                sortBy = null,
                totalRowsCount = null,
            } = pagination;
            const bodyRef = useRef();
            const dispatch = useDispatch();

            const [cellClassSuffix, setCellClassSuffix] = useState(
                getRandomId()
            );
            let initialData = !isEmpty(data) ? transformData(data) : data;
            const [rowData, setRowData] = useState(initialData);
            const [showRefresh, setShowRefresh] = useState(false);
            const [hasMergedColumns, setHasMergedColumns] = useState(
                columns.some(e => typeof e.text == "object")
            );
            const [selectedRowIds, setSelectedRowIds] = useState([]);

            const deleteAnimatedRowId = useRef([]);
            const cloneAnimatedRowId = useRef([]);

            useEffect(() => {
                let initialData = !isEmpty(data) ? transformData(data) : data;
                setRowData(initialData);
                (customErrorMsg &&
                    customErrorMsg.includes(MESSAGES.TABLE.noDataFromApi)) ||
                !customErrorMsg
                    ? setShowRefresh(true)
                    : setShowRefresh(false);
            }, [data, customErrorMsg]);

            useEffect(() => {
                if (typeof bulkRowOnChange == "function") {
                    bulkRowOnChange(selectedRowIds);
                }
            }, [selectedRowIds]);

            useEffect(() => {
                const observer = new IntersectionObserver(
                    ([e]) => {
                        if (stickyHeaderRef == "true") {
                            if (!e.intersectionRatio > 0)
                                stickyHeaderRef.current.classList.add(
                                    "sticky-header-shadow"
                                );
                            else
                                stickyHeaderRef.current.classList.remove(
                                    "sticky-header-shadow"
                                );
                        }
                    },
                    { threshold: 0, rootMargin: "-234px" }
                );

                if (bodyRef.current) {
                    observer.observe(bodyRef.current);
                    window.addEventListener("resize", calculateWidth);
                }

                return () => {
                    observer.disconnect();
                    window.removeEventListener("resize", calculateWidth);
                };
            }, []);

            useEffect(() => {
                if (isEmpty(data) && !loading) {
                    setMessageShow(true);
                } else {
                    setMessageShow(false);
                }
            }, [rowData, loading]);

            const tableRef = useRef();
            useEffect(() => {
                if (tableRef?.current) {
                    calculateWidth();
                    if (showFakeScroll) {
                        fakeScroll.current.style.width =
                            columnsWidthSum()[0] + "px";
                    }
                }
            });

            const calculateWidth = () => {
                //main cell calculations
                let availableSpace = tableRef.current
                    ? tableRef.current.clientWidth
                    : null;
                let colCellWidths = [];
                let totalWidths = columnsWidthSum(colCellWidths);
                let colTotalWidth = totalWidths[0];

                if (config && config.stickyHeader)
                    stickyHeaderWidthAdjust(colTotalWidth, availableSpace);

                let totalColumns = columns.length;
                for (let index = 0; index < totalColumns; index++) {
                    if (colCellWidths[index] > columns[index].maxWidth) {
                        colTotalWidth = colTotalWidth - columns[index].maxWidth;
                    }
                }

                // merged cell calculations
                columns.map((e, i) => {
                    if (typeof e.text == "object") {
                        e.text.map((item, ind) => {
                            let temp = cellMaxWidth(
                                ".innerColCell" + i + ind + cellClassSuffix,
                                ind,
                                undefined,
                                e
                            );
                            applyWidthToCells(
                                ".innerColCell" + i + ind + cellClassSuffix,
                                temp,
                                columns[i].maxWidth,
                                colTotalWidth,
                                availableSpace,
                                i
                            );
                        });
                    }
                });

                for (let index = 0; index < totalColumns; index++) {
                    applyWidthToCells(
                        ".t-cell" + index + cellClassSuffix,
                        colCellWidths[index],
                        columns[index].maxWidth,
                        colTotalWidth,
                        availableSpace,
                        index
                    );
                }
            };

            function columnsWidthSum(colCellWidths) {
                let colTotalWidth = 0;
                let colIgnoredWidth = 0;
                let maxwidth = 0;
                let totalColumns = columns.length;
                for (let index = 0; index < totalColumns; index++) {
                    let cellClass = ".t-cell" + index + cellClassSuffix;
                    let elNode = document.querySelector(cellClass);
                    if (elNode) {
                        maxwidth = colCellWidths
                            ? cellMaxWidth(cellClass, index, colCellWidths)
                            : cellMaxWidth(cellClass, index);
                        colTotalWidth += maxwidth;
                    }
                }
                return [
                    colTotalWidth,
                    colIgnoredWidth,
                    colTotalWidth - colIgnoredWidth,
                ];
            }

            function cellMaxWidth(classname, index, colCellWidths) {
                let tempArr = [];
                if (columns[index].minWidth) {
                    tempArr.push(columns[index].minWidth);
                } else {
                    let cell = document.querySelectorAll(classname);
                    cell.forEach(cc => {
                        tempArr.push(cc.scrollWidth);
                    });
                    if (Math.max(...tempArr) < 100)
                        tempArr.splice(0, tempArr.length, 100);
                }
                if (colCellWidths) colCellWidths.push(Math.max(...tempArr));
                return Math.max(...tempArr);
            }

            function applyWidthToCells(
                cellClass,
                width,
                maxWidth,
                colTotalWidth,
                availableSpace,
                index
            ) {
                let cell = document.querySelectorAll(cellClass);
                cell.forEach(cc => {
                    columnMeta.current[index] = columnMeta.current[index] || {};
                    columnMeta.current[index].styles =
                        columnMeta.current[index]?.styles || {};
                    if (colTotalWidth < availableSpace) {
                        let proposedWidth = (width / colTotalWidth) * 100 + "%";
                        cc.style.width = maxWidth
                            ? maxWidth + "px"
                            : proposedWidth;
                        cc.style.minWidth = width + "px";
                        columnMeta.current[index].styles.width = maxWidth
                            ? maxWidth + "px"
                            : proposedWidth;
                        columnMeta.current[index].styles.minWidth =
                            width + "px";
                    } else {
                        cc.style.width = width + "px";
                        cc.style.minWidth = width + "px";
                        columnMeta.current[index].styles.width = width + "px";
                        columnMeta.current[index].styles.minWidth =
                            width + "px";
                    }
                    if (cc.classList.contains("stickyCell")) {
                        let result = 0,
                            node = cc.parentNode.firstChild;
                        while (node != cc) {
                            if (
                                node !== cc &&
                                node.nodeType === Node.ELEMENT_NODE
                            )
                                result += node.offsetWidth;
                            node = node.nextElementSibling || node.nextSibling;
                        }
                        cc.style.left = result + "px";
                    }
                });
            }

            const fakeScrollWrap = useRef();
            const fakeScroll = useRef();

            const scrollTable = scrollSource => {
                let scrollRefs = [tableRef, fakeScrollWrap, stickyHeaderRef];
                scrollRefs.forEach(
                    item =>
                        item.current &&
                        (item.current.scrollLeft =
                            scrollSource.current.scrollLeft)
                );
            };

            const [compactActionBtns, setcompactActionBtns] = useState(false);

            const stickyHeaderRef = useRef();
            const stickyHeader__row = useRef();
            const stickyHeaderWidthAdjust = (colTotalWidth, availableSpace) => {
                if (availableSpace > colTotalWidth) {
                    stickyHeaderRef.current.style.width = availableSpace + "px";
                    if (stickyHeader__row && stickyHeader__row.current)
                        stickyHeader__row.current.style.width =
                            availableSpace + "px";
                    setcompactActionBtns(false);
                } else {
                    if (stickyHeaderRef && stickyHeaderRef.current)
                        stickyHeaderRef.current.style.width =
                            availableSpace + "px";
                    if (stickyHeader__row && stickyHeader__row.current)
                        stickyHeader__row.current.style.width =
                            colTotalWidth + "px";
                    setcompactActionBtns(true);
                }
            };

            const [selectedCount, setSelectedCount] = useState(10);
            const [activePage, setActivePage] = useState(1);
            const [totalPages, setTotalPages] = useState(15);
            const onSortChangeHandler = col => {
                if (
                    actions &&
                    actions.setSortBy &&
                    typeof actions.setSortBy === "function" &&
                    actions.setIsDesc &&
                    typeof actions.setIsDesc === "function" &&
                    onRefresh &&
                    typeof onRefresh === "function"
                ) {
                    const colKey = get(col, "key", "id");
                    dispatch(actions.setSortBy(colKey));
                    dispatch(
                        actions.setIsDesc(colKey === sortBy ? !isDesc : false)
                    );
                    onRefresh(true);
                }
            };

            const InnerColCell = ({ col, text, index, innerIndex }) => {
                const className = () => {
                    if (typeof col.valueType == "object") {
                        return col.valueType[innerIndex] == "number"
                            ? "numRender__common"
                            : "dp-parent dp-parent-ver-center";
                    } else {
                        return col.valueType == "number"
                            ? "numRender__common"
                            : "dp-parent dp-parent-ver-center";
                    }
                };
                let combinedIndex = index?.toString() + innerIndex?.toString();

                return (
                    <div
                        className={`${className()}
                    ${
                        combinedIndex
                            ? "innerColCell" + combinedIndex + cellClassSuffix
                            : ""
                    }`}
                    >
                        {text}
                        {col.isSortable && data.length > 0 && (
                            <span
                                className={`sort ${
                                    !isDesc
                                        ? styles["sort-des"]
                                        : styles["sort-asc"]
                                }`}
                            >
                                <div
                                    onClick={onSortChangeHandler.bind(
                                        null,
                                        col
                                    )}
                                    className={`${styles.icon} dp-parent`}
                                >
                                    <Icon icon="ColumnSort" size="13px" />
                                </div>
                            </span>
                        )}
                    </div>
                );
            };

            const RenderColumnCell = () => {
                if (columns)
                    return columns.map((col, index) => (
                        <div
                            key={"table-list-col-" + index + col.text}
                            className={
                                styles["t-head__cell"] +
                                " t-cell" +
                                index +
                                cellClassSuffix +
                                ` ${
                                    config && config.headerClass
                                        ? styles.headerClass
                                        : ""
                                } ${
                                    frozen && frozen > index ? "stickyCell" : ""
                                } ${
                                    get(col, "key", "") == sortBy
                                        ? styles["active"]
                                        : ""
                                } 
                             ${hasMergedColumns ? styles.hasMergedColumns : ""}
                             ${
                                 col.clickFunctionality
                                     ? styles.expandCollapseText
                                     : ""
                             }
                             ${
                                 col.disableCopyText
                                     ? styles["t-head__cell_without_copy"]
                                     : ""
                             }`
                            }
                            style={{
                                ...columnMeta.current[index],
                                ...(col.styles || null),
                                ...(col.headCellStyles
                                    ? col.headCellStyles
                                    : null),
                            }}
                        >
                            {typeof col.text == "object" ? (
                                <div className="dp-parent flex-wrap w-100">
                                    <div className={styles.commonTitle}>
                                        {col.commonHeader}
                                    </div>
                                    <div className="dp-parent dp-parent-hor-space-around gap-20 w-100">
                                        {col.text.map((text, innerIndex) => (
                                            <InnerColCell
                                                col={col}
                                                text={text}
                                                index={index}
                                                innerIndex={innerIndex}
                                                key={getRandomId()}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : col?.clickFunctionality ? (
                                col.text()
                            ) : (
                                <InnerColCell col={col} text={col.text} />
                            )}
                        </div>
                    ));
                return "";
            };
            const RenderRowCell = ({ data, showAccordionTable = false }) => {
                return columns.map((col, index) => {
                    const CustomRender = col.CustomRender;

                    const dynStyle =
                        !!hnpTable && data.level && index == 0 && data.level > 1
                            ? {
                                  paddingLeft: data.level * 25,
                              }
                            : {};

                    const cellClasses = [
                        styles["t-body__cell"] +
                            " t-cell" +
                            index +
                            cellClassSuffix +
                            ` ${
                                !!hnpTable && data.level && index == 0
                                    ? styles[`level-${data.level}`]
                                    : ""
                            }
                            ${col.bulkActions ? "actionBtns" : ""}
                                    ${
                                        col.bulkActions && compactActionBtns
                                            ? "actionBtns--compact"
                                            : ""
                                    }
                                    ${
                                        config && config.headerClass
                                            ? styles.headerClass
                                            : ""
                                    }
                                    ${
                                        frozen && frozen > index
                                            ? "stickyCell"
                                            : ""
                                    } 
                            ${
                                frozen && frozen == index + 1
                                    ? "stickyCell__last"
                                    : ""
                            }
                            ${
                                showAccordionTable
                                    ? styles["containsNestedTable__cell"] +
                                      " containsNestedTable__cell"
                                    : ""
                            }
                            ${
                                col.commonHeader
                                    ? "dp-parent-hor-space-around gap-20"
                                    : ""
                            }
                            ${
                                col.valueType == "number"
                                    ? "dp-parent-hor-end"
                                    : ""
                            }
                            
                            `,
                    ];

                    return (
                        <div
                            style={{
                                dynStyle,
                                ...columnMeta.current[index]?.styles,
                                ...(col?.styles || null),
                            }}
                            className={cellClasses}
                            key={
                                "table-list-cell-" +
                                (index +
                                    "" +
                                    (col.key ? data[col.key] : Math.random()))
                            }
                        >
                            {!!arrow && index == 0 && (
                                <div
                                    className={`${styles.arrowWrapper} ${
                                        data.isNesting &&
                                        data.child &&
                                        data.child.length
                                            ? styles.activeArrow
                                            : ""
                                    }`}
                                >
                                    <Icon icon="ColumnSort" size="12px" />
                                </div>
                            )}
                            {CustomRender ? (
                                <>
                                    {!col.bulkActions &&
                                    !col.disableCopyText ? (
                                        <CopyRenderer>
                                            <CustomRender
                                                col={col}
                                                item={data}
                                                colIndex={index}
                                                randomId={cellClassSuffix}
                                                cb={callBack}
                                            ></CustomRender>
                                        </CopyRenderer>
                                    ) : (
                                        <CustomRender
                                            col={col}
                                            item={data}
                                            colIndex={index}
                                            cb={callBack}
                                            randomId={cellClassSuffix}
                                        ></CustomRender>
                                    )}
                                    {index == columns.length - 1 && !!isEdit && (
                                        <>
                                            {editObject &&
                                            editObject.length &&
                                            editObject[0][editObjectSelector] ==
                                                data[editObjectSelector] ? (
                                                <div
                                                    className={
                                                        styles.saveBtnWrapper
                                                    }
                                                >
                                                    <Button
                                                        btnTheme={
                                                            ButtonTypes.primaryHover_btn
                                                        }
                                                        onClick={() =>
                                                            getEditData()
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button
                                                        btnTheme={
                                                            ButtonTypes.default_btn
                                                        }
                                                        onClick={() =>
                                                            cancelEditState()
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        styles.mainEditButtons
                                                    }
                                                >
                                                    {data.isNesting && (
                                                        <div
                                                            className={`${styles.editIcon} ${styles.addIcon}`}
                                                            onClick={() =>
                                                                onAdd(data)
                                                            }
                                                        >
                                                            <Tooltip
                                                                message="Add"
                                                                position="top"
                                                            >
                                                                <Icon
                                                                    icon="CloseFilled"
                                                                    size="18px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={`${styles.editIcon} delete_icon`}
                                                        onClick={() =>
                                                            onDelete(data)
                                                        }
                                                    >
                                                        <Tooltip
                                                            message="Delete"
                                                            position="top"
                                                        >
                                                            <Icon
                                                                icon="Delete"
                                                                size="18px"
                                                            />
                                                        </Tooltip>
                                                    </div>

                                                    {!data.isNesting && (
                                                        <div
                                                            className={
                                                                styles.editIcon
                                                            }
                                                            onClick={() =>
                                                                onEdit(data)
                                                            }
                                                        >
                                                            <Tooltip
                                                                message="Edit"
                                                                position="top"
                                                            >
                                                                <Icon
                                                                    icon="Edit"
                                                                    size="18px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : !col.disableCopyText ? (
                                <CopyRenderer content={data[col.key]}>
                                    <p>{data[col.key]}</p>
                                </CopyRenderer>
                            ) : (
                                <p>{data[col.key]}</p>
                            )}
                            {index === 0 && data["cloned"] && (
                                <TagRenderer>New</TagRenderer>
                            )}
                        </div>
                    );
                });
            };

            useEffect(() => {
                if (totalRowsCount != null) {
                    setTotalPages(Math.ceil(totalRowsCount / rowsCount));
                }
                if (pageNumber) setActivePage(pageNumber);
                if (rowsCount) setSelectedCount(rowsCount);
            }, [pageNumber, rowsCount, totalRowsCount]);

            const countChangeHandler = count => {
                const pageName = getPageName();
                if (
                    actions &&
                    actions.setPageNumber &&
                    typeof actions.setPageNumber === "function"
                ) {
                    dispatch(actions.setPageNumber(1));
                }
                setSelectedCount(count);
                if (
                    pageName === "userdashboard" &&
                    actions &&
                    actions.setRowsCount &&
                    typeof actions.setRowsCount === "function" &&
                    onRefresh &&
                    typeof onRefresh === "function"
                ) {
                    dispatch(actions.setRowsCount(count));
                    onForceRefresh();
                } else if (
                    actions &&
                    actions.setRowsCount &&
                    typeof actions.setRowsCount === "function" &&
                    onRefresh &&
                    typeof onRefresh === "function"
                ) {
                    // dispatch(actions.setPageNumber(1));
                    dispatch(actions.setRowsCount(count));
                    onRefresh(true);
                }
            };

            const pageChangeHandler = page => {
                setActivePage(page);
                const pageName = getPageName();
                if (
                    pageName === "userdashboard" &&
                    actions &&
                    actions.setPageNumber &&
                    typeof actions.setPageNumber === "function" &&
                    onRefresh &&
                    typeof onRefresh === "function"
                ) {
                    dispatch(actions.setPageNumber(page));
                    onForceRefresh();
                } else if (
                    actions &&
                    actions.setPageNumber &&
                    typeof actions.setPageNumber === "function" &&
                    onRefresh &&
                    typeof onRefresh === "function"
                ) {
                    dispatch(actions.setPageNumber(page));
                    onRefresh(true);
                }
            };

            const onRowSelectionChange = (row, type) => {
                if (type == "selectAll") {
                    setRowData(
                        rowData.map(item => ({
                            ...item,
                            isChecked: true,
                        }))
                    );
                    setSelectedRowIds(data.map(_ => _[uniqueKey]));
                } else if (type == "unselectAll") {
                    setRowData(
                        rowData.map(item => ({
                            ...item,
                            isChecked: false,
                        }))
                    );
                    setSelectedRowIds([]);
                } else {
                    if (selectedRowIds.includes(row[uniqueKey])) {
                        setRowData(
                            rowData.map(item => {
                                if (item[uniqueKey] === row[uniqueKey]) {
                                    return {
                                        ...item,
                                        isChecked: false,
                                    };
                                } else {
                                    return item;
                                }
                            })
                        );
                        setSelectedRowIds(
                            selectedRowIds.filter(_ => _ != row[uniqueKey])
                        );
                    } else {
                        setRowData(
                            rowData.map(item => {
                                if (item[uniqueKey] === row[uniqueKey]) {
                                    return {
                                        ...item,
                                        isChecked: true,
                                    };
                                } else {
                                    return item;
                                }
                            })
                        );
                        setSelectedRowIds([...selectedRowIds, row[uniqueKey]]);
                    }
                }
            };

            const DragHandle = SortableHandle(showAccordionTable => (
                <div
                    className={`dp-parent dp-parent-hor-center dp-parent-ver-center ${
                        styles["t-body__cell"]
                    } ${
                        showAccordionTable
                            ? styles["containsNestedTable__cell"]
                            : ""
                    } ${styles["drag-item"]}`}
                >
                    <Icon icon="Drag" size="18px" />
                </div>
            ));

            const SortableList = SortableContainer(({ children }) => {
                return (
                    <div>
                        <div
                            className={`${styles["t-row"]}`}
                            ref={bodyRef}
                        ></div>
                        {!!rowData &&
                            !!rowData.length &&
                            rowData.map((row, index) => (
                                <SortableItem
                                    key={getRandomId()}
                                    // key={row.id + Math.random()}
                                    row={row}
                                    index={index}
                                    disabled={!sortableRows}
                                />
                            ))}
                    </div>
                );
            });

            const getTransitionClass = row => {
                if (deleteAnimatedRowId?.current?.includes(row.id)) return "";
                else if (row.deletedRow) {
                    deleteAnimatedRowId?.current?.push(row.id);
                    return styles["deletedRowWrapper"];
                }

                if (cloneAnimatedRowId?.current?.includes(row.id)) return "";
                else if (row.animateEntry) {
                    cloneAnimatedRowId?.current?.push(row.id);
                    return " table__newRow";
                }

                return "";
            };

            const SortableItem = SortableElement(({ row, index }) => (
                <div
                    ref={ref}
                    key={
                        Math.random() +
                        "table-list-row-" +
                        (row[uniqueKey] || index + (row.id || Math.random()))
                    }
                    className={`${styles["t-row"]} ${styles["t-row_col"]} ${
                        row.showAccordionTable
                            ? styles["containsNestedTable"]
                            : ""
                    } ${
                        editObject &&
                        editObject.length &&
                        editObject[0][editObjectSelector] ==
                            row[editObjectSelector]
                            ? styles.editActive
                            : ""
                    } ${
                        deletedIds.indexOf(row.id) !== -1
                            ? styles["isDeleted"] + " table__deletedRow"
                            : null
                    } 
                    ${getTransitionClass(row)}
                    `}
                >
                    {
                        <>
                            <div
                                className={`${styles["t-body__cellWrapper"]} ${
                                    activeRowId && activeRowId == row.id
                                        ? styles["rowActive"] + " rowActive"
                                        : ""
                                } ${
                                    row.deletedRow ? styles["deletedRow"] : ""
                                } t-row`}
                            >
                                {sortableRows ? (
                                    <DragHandle data={row.showAccordionTable} />
                                ) : null}
                                {bulkRowOps ? (
                                    <div className={styles["t-body__cell"]}>
                                        <Checkbox
                                            onChange={() =>
                                                onRowSelectionChange(row)
                                            }
                                            isChecked={row?.isChecked}
                                        ></Checkbox>
                                    </div>
                                ) : null}
                                <RenderRowCell
                                    data={row}
                                    showAccordionTable={row.showAccordionTable}
                                    key={`${getRandomId()}-${index}-${getRandomId()}`}
                                ></RenderRowCell>
                            </div>
                            {row.showAccordionTable && (
                                <div className={styles.accordionTableContainer}>
                                    <AccordionComponent
                                        row={row}
                                        readOnly={readOnly}
                                    ></AccordionComponent>
                                </div>
                            )}
                        </>
                    }
                </div>
            ));

            const onSortEnd = ({ oldIndex, newIndex }) => {
                let prevsState = cloneDeep(rowData);
                prevsState = arrayMoveImmutable(prevsState, oldIndex, newIndex);
                setRowData(prevsState);
                if (typeof rowSortCallback == "function")
                    rowSortCallback(prevsState);
            };

            return (
                <div
                    className={
                        styles["table-wrapper"] +
                        ` ${showBorder ? styles["table-border"] : ""}`
                    }
                >
                    {!gridView && (
                        <>
                            {loading ? (
                                <div>
                                    <SkeletonLoader
                                        type="listing"
                                        rowsCount={
                                            rowsCount === -1 ? 10 : rowsCount
                                        }
                                    />
                                </div>
                            ) : (
                                <>
                                    <div
                                        className={
                                            styles["t-head"] +
                                            ` ${
                                                config && config.stickyHeader
                                                    ? styles["sticky-header"]
                                                    : ""
                                            } ${
                                                stickyHeaderClass
                                                    ? stickyHeaderClass
                                                    : ""
                                            }`
                                        }
                                        style={
                                            headerBgColor
                                                ? {
                                                      backgroundColor:
                                                          headerBgColor,
                                                  }
                                                : {}
                                        }
                                        ref={stickyHeaderRef}
                                        onScroll={() => {
                                            if (config && config.stickyHeader) {
                                                scrollTable(stickyHeaderRef);
                                            }
                                        }}
                                    >
                                        <div
                                            className={
                                                styles["t-row"] +
                                                " t-cellWrapper"
                                            }
                                            ref={
                                                config && config.stickyHeader
                                                    ? stickyHeader__row
                                                    : null
                                            }
                                        >
                                            {sortableRows ? (
                                                <div
                                                    className={`${
                                                        styles["t-head__cell"]
                                                    } 
                                            ${styles["emptyHeadCell"]} 
                                            ${
                                                hasMergedColumns
                                                    ? styles.hasMergedColumns
                                                    : ""
                                            }`}
                                                ></div>
                                            ) : null}
                                            {selectedRowIds.length > 0 ? (
                                                <>
                                                    <div
                                                        className={
                                                            styles[
                                                                "t-head__cell"
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            onClick={() => {
                                                                onRowSelectionChange(
                                                                    null,
                                                                    "unselectAll"
                                                                );
                                                            }}
                                                        >
                                                            <Tooltip
                                                                width="100px"
                                                                message="Unselect all rows"
                                                                position="top"
                                                            >
                                                                <Icon
                                                                    icon="DeleteMinus"
                                                                    size="18px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`${styles["t-head__cell"]} w-100`}
                                                    >
                                                        <div
                                                            className={
                                                                styles.bulkRowOps__icons
                                                            }
                                                        >
                                                            <Tooltip
                                                                message="User Group"
                                                                position="top"
                                                            >
                                                                <Icon
                                                                    icon="User-Group"
                                                                    size="18px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.bulkRowOps__icons
                                                            }
                                                        >
                                                            <Tooltip
                                                                message="Disable"
                                                                position="top"
                                                            >
                                                                <Icon
                                                                    icon="disable"
                                                                    size="18px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.bulkRowOps__icons
                                                            }
                                                        >
                                                            <Tooltip
                                                                width="100px"
                                                                message="More"
                                                                position="top"
                                                            >
                                                                <Icon
                                                                    icon="More"
                                                                    size="18px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {bulkRowOps ? (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "t-body__cell"
                                                                ]
                                                            }
                                                        >
                                                            <Tooltip
                                                                message="Select all rows"
                                                                position="top"
                                                            >
                                                                <Checkbox
                                                                    onChange={() =>
                                                                        onRowSelectionChange(
                                                                            null,
                                                                            "selectAll"
                                                                        )
                                                                    }
                                                                ></Checkbox>
                                                            </Tooltip>
                                                        </div>
                                                    ) : null}
                                                    <RenderColumnCell></RenderColumnCell>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            styles["table"] +
                                            ` ${
                                                showFakeScroll
                                                    ? styles[
                                                          "hideNaturalScroll"
                                                      ]
                                                    : ""
                                            }`
                                        }
                                        ref={tableRef}
                                        onScroll={() => {
                                            scrollTable(tableRef);
                                        }}
                                    >
                                        <div
                                            className={
                                                styles["t-body"] +
                                                ` ${
                                                    config &&
                                                    config.stickyHeader
                                                        ? styles[
                                                              "t-body__withStickyHeader"
                                                          ]
                                                        : ""
                                                } ${
                                                    hasMergedColumns
                                                        ? styles[
                                                              "t-body__withStickyHeader--mergedCols"
                                                          ]
                                                        : ""
                                                } t-body`
                                            }
                                        >
                                            <>
                                                {messageShow && (
                                                    <div
                                                        className={
                                                            styles.tableError
                                                        }
                                                    >
                                                        {customErrorMsg ? (
                                                            <CustomMessagePage
                                                                imgFile={
                                                                    customErrorMsg
                                                                }
                                                                message={
                                                                    customErrorMsg
                                                                }
                                                                showRefresh={
                                                                    showRefresh
                                                                }
                                                                onRefresh={
                                                                    onRefresh
                                                                }
                                                                showActionBtn={
                                                                    showActionBtn
                                                                }
                                                                actionBtnHandler={
                                                                    actionBtnHandler
                                                                }
                                                                actionBtnText={
                                                                    actionBtnText
                                                                }
                                                                btnTheme={
                                                                    btnTheme
                                                                }
                                                            />
                                                        ) : (
                                                            MESSAGES.TABLE
                                                                .noDataFromApi
                                                        )}
                                                    </div>
                                                )}
                                            </>

                                            <SortableList
                                                axis="y"
                                                distance={1}
                                                onSortStart={calculateWidth}
                                                onSortEnd={onSortEnd}
                                                useDragHandle
                                            />
                                        </div>
                                    </div>
                                    {showFakeScroll && (
                                        <div
                                            className={
                                                styles.fakeScrollWrap +
                                                ` ${
                                                    !isPagination
                                                        ? styles.fakeScrollWrap__bottom
                                                        : selectedCount > 10
                                                        ? styles.bottomPos
                                                        : ""
                                                }`
                                            }
                                            ref={fakeScrollWrap}
                                            onScroll={() => {
                                                scrollTable(fakeScrollWrap);
                                            }}
                                        >
                                            <div
                                                className={styles.fakeScroll}
                                                ref={fakeScroll}
                                            ></div>
                                        </div>
                                    )}
                                    {(rowData.length === undefined ||
                                        rowData.length === 0) &&
                                    !loading
                                        ? ""
                                        : isPagination && (
                                              <>
                                                  <Pagination
                                                      currentPage={activePage}
                                                      showCount={selectedCount}
                                                      totalPages={totalPages}
                                                      totalRowsCount={
                                                          totalRowsCount
                                                      }
                                                      onCountChange={
                                                          countChangeHandler
                                                      }
                                                      onPageChange={
                                                          pageChangeHandler
                                                      }
                                                  ></Pagination>
                                              </>
                                          )}
                                </>
                            )}
                        </>
                    )}
                    {gridView && (
                        <>
                            {loading ? (
                                <ul>
                                    <SkeletonLoader type="gridViewListing" />
                                </ul>
                            ) : (
                                <>
                                    {messageShow ? (
                                        <div className={styles.tableError}>
                                            {customErrorMsg ? (
                                                <CustomMessagePage
                                                    imgFile={customErrorMsg}
                                                    message={customErrorMsg}
                                                    showRefresh={showRefresh}
                                                    onRefresh={onRefresh}
                                                    btnTheme={btnTheme}
                                                />
                                            ) : (
                                                MESSAGES.TABLE.noDataFromApi
                                            )}
                                        </div>
                                    ) : (
                                        <ul
                                            className={
                                                styles["gridTableContainer"]
                                            }
                                        >
                                            {data?.map((element, index) => {
                                                return gridViewList(
                                                    element,
                                                    index
                                                );
                                            })}
                                        </ul>
                                    )}

                                    {(rowData.length === undefined ||
                                        rowData.length === 0) &&
                                    !loading
                                        ? ""
                                        : isPagination && (
                                              <>
                                                  <Pagination
                                                      currentPage={activePage}
                                                      showCount={selectedCount}
                                                      totalPages={totalPages}
                                                      totalRowsCount={
                                                          totalRowsCount
                                                      }
                                                      onCountChange={
                                                          countChangeHandler
                                                      }
                                                      onPageChange={
                                                          pageChangeHandler
                                                      }
                                                  ></Pagination>
                                              </>
                                          )}
                                </>
                            )}
                        </>
                    )}
                </div>
            );
        }
    )
);

export default TableFlex;
