import React, {
    useState,
    useMemo,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef,
    useCallback,
} from "react";
import { useDispatch } from "react-redux";
import s from "./LinkOutOffer.module.scss";
import Select from "../../Shared/Select/Select";
import _, { cloneDeep, isEmpty } from "lodash";
import InputList from "../InputList/InputList";
import { useFetchAvailableTargetingParams } from "../../generalApi/generalApi";
import { TargetingGroup } from "../../Pages/CreateFlowCategories/CreateFlowCategories";
import { getRandomId } from "../../utils/globalUtils";
import { fetchTpKey } from "../../Shared/FlowRenderer/FlowRendererUtils";
import { toastListActions } from "../../store/toastList/toastList";
import { findLinkOut } from "../../utils/collectionUtils";

const LinkOutOffer = forwardRef(
    (
        {
            adUnitList = [],
            questionDetails,
            linkoutOffer,
            adTypeDataLoading,
            readOnly,
        },
        ref
    ) => {
        const dispatch = useDispatch();
        const adUnitRef = useRef(null);
        const [element, setElement] = useState({
            layoutSubType: undefined,
            layoutType: undefined,
            name: "Survey Question + Answer",
            type: "ans_2",
            value: [],
        });
        const [selectedAdUnitList, setSelectedAdUnitList] = useState(null);
        const [valueOne, setValueOne] = useState([]);
        const [valueTwo, setValueTwo] = useState(null);
        const [selectAll, setSelectAll] = useState(false);
        const answerList = useMemo(() => {
            return (
                questionDetails?.answers?.map(_ => {
                    return {
                        id: _.answer,
                        answer: _.answer,
                        key: _.key,
                        questionId: _.questionId,
                    };
                }) || []
            );
        }, [questionDetails?.answers]);

        const [questionAnswer, setQuestionAnswer] = useState({});

        const { apiData = [] } = useFetchAvailableTargetingParams();
        const [orList, setOrList] = useState([
            { tp: {}, ref: { current: null } },
        ]);
        const [adUnitError, setAdUnitError] = useState(false);
        const availableTargetingParams = useMemo(() => {
            return apiData || [];
        }, [apiData]);

        const channelMapDropDownJson = useMemo(() => {
            return {
                keyField: "name",
                idField: "id",
                options: [
                    { name: questionDetails?.name, id: questionDetails?.id },
                ],
                selectedId: questionDetails?.id || null,
                label: valueOne,
                showSearch: true,
                onSelect: data => {
                    setValueTwo(null);
                },
                className: "multiSelectWithAny",
            };
        }, [valueOne]);
        let channelMapDropDownJsonSecond = useMemo(() => {
            return {
                keyField: "answer",
                idField: "key",
                label: "Select Answer",
                isDisabled: !valueOne || selectAll,
                options: answerList || [],
                selectedId: valueTwo?.answer || null,
                showSearch: true,
                showCreate: true,
                onSelect: data => {
                    setValueTwo(data);
                },
            };
        }, [valueOne, valueTwo]);

        let multiSelectCheckbox = {
            labelText: "Any Answer",
            isChecked: selectAll,
            onChange: data => {
                setSelectAll(data);
            },
        };

        const apiPayloadData = [
            {
                adUnitId: null,
                targettingParams: {},
            },
        ];

        const createQuestionTag = useCallback(data => {
            let linkOutFilterData = [];
            if (isEmpty(linkoutOffer)) linkOutFilterData = findLinkOut(data);
            else {
                let initialMainState = cloneDeep(data);
                let newData = initialMainState?.map(_ => ({
                    key:
                        typeof _.valueOne !== "string"
                            ? `q_${_.valueOne?.toString()}`
                            : `q_${_.valueOne}`,
                    type: "EXPRESSION",
                    value: _.valueSecond,
                    operator: _.valueSecond === "skip" ? "NOT EQUAL" : "EQUAL",
                    linkOutQuestion: true,
                }));
                linkOutFilterData = findLinkOut(newData, true);
            }
            let questionData = data?.map(_ => ({
                type: "AND",
                value: [
                    {
                        type: "OR",
                        value: [...linkOutFilterData],
                    },
                ],
            }));
            let finalValues =
                !isEmpty(questionData) && questionData[0].value[0].value;
            let finalQuestionData = [];
            if (!isEmpty(finalValues)) {
                const map = new Map(finalValues?.map(pos => [pos.value, pos]));
                questionData = [...map?.values()];
                finalQuestionData = [
                    {
                        type: "AND",
                        value: [
                            {
                                type: "OR",
                                value: questionData,
                            },
                        ],
                    },
                ];
            }
            setQuestionAnswer(finalQuestionData);
        }, []);

        const onSaveAdUnit = (isRegister = false) => {
            let isFormValid = true;
            let targetingIsValid = true;
            let targetingPresent = true;
            let finalPayloadData = null;
            let isSkipPresent = true;
            if (isEmpty(selectedAdUnitList?.id)) {
                isFormValid = false;
                setAdUnitError(true);
            }
            if (!isEmpty(selectedAdUnitList?.id)) {
                isFormValid = true;
                (apiPayloadData[0].adUnitId = selectedAdUnitList?.id),
                    (apiPayloadData[0].targettingParams = {
                        type: "OR",
                        value: orList
                            .map(_ => _.ref?.current?.getData())
                            .filter(_ => _?.value?.length),
                    });
                let emptyTargeting = [];
                let copyApiPayloadData =
                    isEmpty(linkoutOffer) || !isEmpty(questionAnswer)
                        ? cloneDeep(apiPayloadData[0].targettingParams.value)
                        : !isEmpty(linkoutOffer)
                        ? cloneDeep(apiPayloadData[0].targettingParams.value)
                        : cloneDeep(linkoutOffer[0].targettingParams.value);
                copyApiPayloadData = {
                    type: "OR",
                    value: copyApiPayloadData,
                };
                finalPayloadData = {
                    adUnitId: selectedAdUnitList?.id,
                    targettingParams: {
                        type: "OR",
                        value:
                            !isEmpty(questionAnswer) && !isRegister
                                ? [
                                      ...questionAnswer,
                                      copyApiPayloadData,
                                  ].filter(_ => _.value.length)
                                : [copyApiPayloadData].filter(
                                      _ => _.value.length
                                  ),
                    },
                };
                if (
                    !isRegister &&
                    isEmpty(finalPayloadData?.targettingParams?.value)
                ) {
                    targetingPresent = false;
                    isFormValid = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Please select atleast one question",
                        })
                    );
                }
                if (
                    !isRegister &&
                    finalPayloadData?.targettingParams?.value?.length > 0
                ) {
                    let selectedAds =
                        finalPayloadData?.targettingParams.value?.map(
                            element => {
                                return {
                                    ...element,
                                    value:
                                        !isEmpty(element.value) &&
                                        !isEmpty(element.value[0]?.value)
                                            ? element.value[0]?.value?.filter(
                                                  _ => _.linkOutQuestion
                                              )
                                            : element.value?.filter(
                                                  _ => _.linkOutQuestion
                                              ),
                                };
                            }
                        );
                    if (isEmpty(selectedAds[0]?.value)) {
                        targetingPresent = false;
                        isFormValid = false;
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: "Please select atleast one question",
                            })
                        );
                    }
                }
                finalPayloadData?.targettingParams?.value.forEach(
                    (obj, idx) => {
                        obj.value.forEach((item, index) => {
                            const tpValue = item.value[0].value;
                            const key = fetchTpKey(item);
                            const tp = availableTargetingParams.find(
                                _ => _.name === key
                            );

                            if (
                                Array.isArray(tpValue) &&
                                tpValue?.length === 0
                            ) {
                                isFormValid = false;
                                targetingIsValid = false;
                                emptyTargeting.push(tp?.opsName);
                            }
                        });
                    }
                );

                if (!targetingIsValid) {
                    emptyTargeting.forEach(element =>
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: (
                                    <div className={s.toastDiv}>
                                        Please add value for{" "}
                                        <span> {element}</span> Targeting.
                                    </div>
                                ),
                            })
                        )
                    );
                }
            }

            if (isFormValid) return finalPayloadData;
            else return false;
        };

        useImperativeHandle(ref, () => {
            return {
                onSave: onSaveAdUnit,
            };
        });

        useEffect(() => {
            if (
                ((!isEmpty(linkoutOffer) &&
                    linkoutOffer[0]?.targettingParams?.type === "AND") ||
                    (!isEmpty(linkoutOffer) &&
                        linkoutOffer[0]?.targettingParams?.type === "OR")) &&
                Array.isArray(linkoutOffer[0]?.targettingParams?.value)
            ) {
                setValueOne({
                    name: questionDetails?.name,
                    id: questionDetails?.id,
                });
                setSelectedAdUnitList(linkoutOffer[0]?.adUnitId);
                setOrList(
                    linkoutOffer[0]?.targettingParams.value.map(v => ({
                        id: getRandomId(),
                        tp: v,
                        ref: { current: null },
                    }))
                );
                let selectedAds = linkoutOffer[0]?.targettingParams.value?.map(
                    element => {
                        return {
                            ...element,
                            value: element.value[0]?.value?.filter(
                                _ => _.linkOutQuestion
                            ),
                        };
                    }
                );
                let tagData = selectedAds?.filter(_ => !isEmpty(_.value));
                let finalTagData = [];
                tagData[0]?.value.forEach(element => {
                    finalTagData.push({
                        valueOne: questionDetails?.id,
                        valueSecond: element.value,
                        valueName: questionDetails?.name,
                    });
                });
                setElement({
                    layoutSubType: undefined,
                    layoutType: undefined,
                    name: "Survey Question + Answer",
                    type: "ans_2",
                    value: !isEmpty(finalTagData) ? finalTagData : [],
                });
                createQuestionTag(finalTagData);
            } else {
                setOrList([
                    {
                        id: getRandomId(),
                        tp: !isEmpty(linkoutOffer)
                            ? linkoutOffer[0]?.targettingParams
                            : null,
                        ref: { current: null },
                    },
                ]);
                setElement({
                    layoutSubType: undefined,
                    layoutType: undefined,
                    name: "Survey Question + Answer",
                    type: "ans_2",
                    value: [],
                });
            }
        }, [linkoutOffer, questionDetails]);

        return (
            <>
                <div>
                    <div className={s.adUnitData}>
                        <div className={`${s.inputWrapper}`}>
                            <label className={s.inputLabel}>Ad Unit</label>
                            <div className={s.dropdownWrapper}>
                                <Select
                                    label={"Select Ad Unit"}
                                    placeholder={`Search and select Ad Unit`}
                                    position={"bottom"}
                                    options={adUnitList}
                                    keyField={"name"}
                                    idField={"id"}
                                    selectedId={selectedAdUnitList || null}
                                    // showOptionWithKeyValue={true}
                                    onSelect={au => {
                                        setAdUnitError(false);
                                        setSelectedAdUnitList(au);
                                    }}
                                    portal={false}
                                    showSearch={true}
                                    required={true}
                                    isDisabled={readOnly}
                                    ref={adUnitRef}
                                    loading={adTypeDataLoading}
                                    isError={adUnitError}
                                />
                            </div>
                        </div>
                        {!isEmpty(questionDetails) && (
                            <div className={`mt-20`}>
                                <InputList
                                    type="multipleSelect"
                                    dropDownJson={channelMapDropDownJson}
                                    dropDownJsonSecond={
                                        channelMapDropDownJsonSecond
                                    }
                                    multiSelectCheckbox={true}
                                    multiSelectCheckboxJson={
                                        multiSelectCheckbox
                                    }
                                    mainState={element.value.map(
                                        (obj, index) => {
                                            if (obj.valueSecond === "skip")
                                                return {
                                                    ...obj,
                                                    valueSecond: "Any Answer",
                                                };

                                            return obj;
                                        }
                                    )}
                                    setMainState={(data, type) => {
                                        createQuestionTag(data);
                                        if (selectAll && type !== "delete") {
                                            let newData = [...data];
                                            newData.forEach((obj, idx) => {
                                                if (
                                                    idx ===
                                                        newData.length - 1 ||
                                                    obj.valueSecond ===
                                                        "Any Answer"
                                                ) {
                                                    obj.valueSecond = "skip";
                                                }
                                            });
                                            element.value = newData;
                                        } else {
                                            element.value = data.map(
                                                (obj, index) => {
                                                    if (
                                                        obj.valueSecond ===
                                                        "Any Answer"
                                                    )
                                                        return {
                                                            ...obj,
                                                            valueSecond: "skip",
                                                        };
                                                    return obj;
                                                }
                                            );
                                        }
                                        setValueTwo(null);
                                        setSelectAll(false);
                                    }}
                                    onDelete={() => {}}
                                    readOnly={readOnly}
                                    tagWrappable={true}
                                />
                            </div>
                        )}

                        <TargetingGroup
                            orList={orList?.filter(
                                _ =>
                                    !(
                                        !isEmpty(_?.tp?.value) &&
                                        !isEmpty(_?.tp?.value[0]?.value) &&
                                        _?.tp?.value[0]?.value[0]
                                            .linkOutQuestion
                                    )
                            )}
                            onChange={data => {
                                setOrList(data);
                            }}
                            required={false}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            </>
        );
    }
);

export default LinkOutOffer;
