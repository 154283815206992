import React, { useEffect, useState, useRef } from "react";
import styles from "./DealOfferWallDetails.module.scss";
import Icon from "../../Shared/Icon/Icon";
import { cloneDeep, get, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { toastListActions } from "../../store/toastList/toastList";
import { REGISTER_DETAIL_DROPDOWN } from "../../constants/collectionConstants";
import { useSelector } from "react-redux";
import { collectionActions } from "../../store/flowmanager/collection";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { useParams } from "react-router-dom";

import { arrayMove } from "@dnd-kit/sortable";
import SortableWrapper from "../../Shared/Sortable/SortableWrapper";
import SortableItem from "../../Shared/Sortable/SortableItem";
import DragHandle from "../../Shared/Sortable/DragHandle";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import CreateDealOfferWallItem from "../../Pages/CreateDealOfferWallItem/CreateDealOfferWallItem";
import { collectionDealsActions } from "../../store/listings/collectionDeals";
import { v4 as uuidv4 } from "uuid";

const DealOfferWallDetails = ({
    className,
    isReadOnly = false,
    isSidePanel = false,
    inContainer = false,
}) => {
    const dispatch = useDispatch();
    const configuration = null;
    const collectionState = useSelector(state => state.flowmanager.collection);
    const items = useSelector(
        state => state.flowmanager.collection.createCollection.items
    );
    const createCollectionState = get(collectionState, "createCollection", []);
    const collectionType = createCollectionState?.type?.id;

    const urlParams = useParams();
    const [activeDetail, setActiveDetail] = useState({});
    const [deletePopup, setDeletePopup] = useState(false);

    useEffect(() => {
        items.length && assignRank(items);
    }, [items.length]);

    useEffect(() => {
        if (!isEmpty(activeDetail)) {
            const itemToUpdate = items?.findIndex(
                _ => _.id === activeDetail.id
            );
            setActiveDetail(items[itemToUpdate]);
        }
    }, [items]);

    const assignRank = data => {
        let initialData = cloneDeep(data);
        let newItems = [];
        let rank = 0;
        initialData?.forEach(_ => {
            if (_.rank !== -1) {
                newItems.push({
                    ..._,
                    rank: rank + 1,
                });
                rank++;
            } else {
                newItems.push({
                    ..._,
                    rank: -1,
                });
            }
        });
        dispatch(collectionActions.setItems(newItems));
    };

    const collectionOptionData =
        get(configuration, "details", [])?.length > 0
            ? REGISTER_DETAIL_DROPDOWN.filter(
                  ele => !ele.id.includes(get(configuration, "details", []))
              )
            : REGISTER_DETAIL_DROPDOWN;

    const collectionDropdown = {
        className: `${styles.editDropdown}`,
        options: collectionOptionData,
        idField: "id",
        keyField: "label",
        label: "Search by name",
        showCreate: false,
    };

    const sidePanelRef = useRef();

    const addDetail = get(
        collectionState,
        "createCollection.itemsDisable",
        false
    );

    const addDefaultDetail = () => {
        dispatch(
            collectionActions.setItems([
                ...items,
                {
                    properties: { fields: [] },
                    tcpa: [],
                    isActive: true,
                    isNew: true,
                    uniqueId: uuidv4(),
                },
            ])
        );
    };

    const removeDetail = item => {
        let itemsClone = cloneDeep([...items]);
        itemsClone.find(value => value.rank === item?.rank).isActive = false;
        let updatedItemsList = [];
        let rank = 1;
        itemsClone.forEach(_ => {
            if (_?.isActive) {
                let updatedItem = {
                    ..._,
                    rank: rank,
                };
                updatedItemsList.push(updatedItem);
                rank++;
            } else {
                let updatedItem = {
                    ..._,
                    rank: -1, //Setting this to -1 as need to update the isActive status to backend
                };
                updatedItemsList.push(updatedItem);
            }
        });
        dispatch(collectionActions.setItems(updatedItemsList));
    };

    const onSortEnd = async event => {
        const { active, over } = event;
        if (active && over && active?.id !== over?.id) {
            const oldIndex = items.findIndex(_ => _.id === active.id);
            const newIndex = items.findIndex(_ => _.id === over.id);
            const sortedItems = arrayMove(items, oldIndex, newIndex);
            assignRank(sortedItems);
        }
    };

    const editDetail = async (detail, isPreview = false) => {
        setActiveDetail(detail);
        if (!isPreview) sidePanelRef.current.open();
    };

    const onAddItem = () => {
        const isValid =
            !isEmpty(activeDetail.properties?.offerWallId) &&
            activeDetail.properties?.minDeals;
        if (isValid) {
            dispatch(
                collectionActions.setItems(
                    JSON.parse(JSON.stringify(items)).map(_ => {
                        if (_.rank === activeDetail.rank) {
                            _.tcpa = activeDetail.tcpa;
                            _.properties = activeDetail.properties;
                            _.hnpLeadIds = activeDetail.hnpLeadIds;
                            _.layoutConfig = null;
                            _.layoutConfigCopy = null;
                        }
                        return _;
                    })
                )
            );
        } else {
            if (isEmpty(activeDetail?.properties?.offerWallId))
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Please add at least one offer wall slot",
                    })
                );
            if (isEmpty(activeDetail?.properties?.minDeals))
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Please enter minimum deals",
                    })
                );
            return;
        }
        setActiveDetail({});
        sidePanelRef.current.close();
    };

    const cancelAddItem = () => {
        setActiveDetail({});
    };

    const onChangeDealCategory = data => {
        setActiveDetail({
            ...activeDetail,
            properties: {
                ...activeDetail.properties,
                category: data?.name,
            },
        });
    };

    const onChangeMinimumDeals = e => {
        setActiveDetail({
            ...activeDetail,
            properties: {
                ...activeDetail.properties,
                minDeals: e.target.value,
            },
        });
    };

    const onSetSlots = data => {
        setActiveDetail({
            ...activeDetail,
            properties: {
                ...activeDetail.properties,
                offerWallId: data,
            },
        });
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={!isSidePanel ? "flexHeightTab" : ""}
        >
            <div className={`row`}>
                <p className={`headerTitle`}>{"Details"}</p>
                <p className={`headerTitleRequired`}>Collection item*</p>
                {items.length > 0 && items.every(_ => _.rank) && (
                    <SortableWrapper list={items} onDrag={onSortEnd}>
                        {items
                            ?.filter(_ => _.isActive)
                            ?.map((item, index) => {
                                return (
                                    <SortableItem
                                        key={item?.uniqueId || index}
                                        id={item?.uniqueId || index}
                                        dragHandle
                                    >
                                        <div
                                            className={`${styles["question-details-wrap"]} ${className}`}
                                        >
                                            <div>
                                                <div
                                                    className={`${styles["add-new-question"]} ${className}`}
                                                    data-index={index + 1}
                                                >
                                                    <div
                                                        className={`dp-parent dp-parent-ver-center mt-20`}
                                                    >
                                                        <div
                                                            className={`dp-parent ${styles.itemDetail} ${styles.dealCategory}`}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.key
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.itemHead
                                                                    }
                                                                >
                                                                    Deal
                                                                    Category :{" "}
                                                                </span>
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.dealValue
                                                                }
                                                                title={
                                                                    item
                                                                        ?.properties
                                                                        ?.category
                                                                }
                                                            >
                                                                {item
                                                                    ?.properties
                                                                    ?.category
                                                                    ? item
                                                                          ?.properties
                                                                          ?.category
                                                                    : "-"}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`dp-parent ${styles.itemDetail}`}
                                                        >
                                                            <div
                                                                className={
                                                                    styles.key
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles.itemHead
                                                                    }
                                                                >
                                                                    Minimum
                                                                    Deals :
                                                                </span>
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.minValue
                                                                }
                                                                title={
                                                                    item
                                                                        ?.properties
                                                                        ?.minDeals
                                                                }
                                                            >
                                                                {item
                                                                    ?.properties
                                                                    ?.minDeals
                                                                    ? item
                                                                          ?.properties
                                                                          ?.minDeals
                                                                    : "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`dp-parent ${styles.itemDetail} ${styles.offerWallKey}`}
                                                    >
                                                        <div
                                                            className={
                                                                styles.key
                                                            }
                                                        >
                                                            <span
                                                                className={
                                                                    styles.itemHead
                                                                }
                                                            >
                                                                Offer Wall :
                                                            </span>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.offerWallValue
                                                            }
                                                            title={
                                                                item?.properties
                                                                    ?.minDeals
                                                            }
                                                        >
                                                            {!isEmpty(
                                                                item?.properties
                                                                    ?.offerWallId
                                                            )
                                                                ? `${item?.properties?.offerWallId?.length} Added`
                                                                : "-"}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`dp-parent dp-parent-ver-center ${styles["edit-options"]}`}
                                                    >
                                                        {/* {!isReadOnly && (
                                                            <DragHandle
                                                                id={item.id}
                                                                customClass="mr-20"
                                                            />
                                                        )} */}

                                                        <div
                                                            className={`${styles.option} dp-parent circleIconColor`}
                                                            onClick={() =>
                                                                editDetail(item)
                                                            }
                                                        >
                                                            <Icon
                                                                icon="Edit"
                                                                size="16px"
                                                                enableHover={
                                                                    true
                                                                }
                                                            />
                                                        </div>
                                                        {!isReadOnly && (
                                                            <div
                                                                className={`${styles.option} dp-parent circleIconColor circleIconColor--delete`}
                                                                onClick={() => {
                                                                    item.id
                                                                        ? setDeletePopup(
                                                                              item
                                                                          )
                                                                        : removeDetail(
                                                                              item
                                                                          );
                                                                }}
                                                            >
                                                                <Icon
                                                                    icon="Delete"
                                                                    size="16px"
                                                                    enableHover={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    {deletePopup === item && (
                                                        <div
                                                            className={
                                                                styles.deletePopup
                                                            }
                                                        >
                                                            <PopupTargettingParams
                                                                setName={item}
                                                                Rfunction={
                                                                    removeDetail
                                                                }
                                                                setShowPopup={
                                                                    setDeletePopup
                                                                }
                                                                customText={`Do you wish to delete this item?`}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </SortableItem>
                                );
                            })}
                    </SortableWrapper>
                )}

                {
                    <Button
                        className={styles["add-question-button"]}
                        onClick={addDefaultDetail}
                        btnTheme={ButtonTypes.primaryHover_btn}
                        disable={isReadOnly || addDetail}
                    >
                        Add items
                    </Button>
                }
            </div>
            <SidePanelInfinity
                width={"890px"}
                title={"Edit Item"}
                ref={sidePanelRef}
                disableSaveBtn={isReadOnly}
                onSave={onAddItem}
                onClose={cancelAddItem}
            >
                <div>
                    <CreateDealOfferWallItem
                        isSidePanel
                        onChangeDealCategory={onChangeDealCategory}
                        onChangeMinimumDeals={onChangeMinimumDeals}
                        data={activeDetail}
                        action={collectionDealsActions}
                        isReadOnly={isReadOnly}
                        onSetSlots={onSetSlots}
                    />
                </div>
            </SidePanelInfinity>
        </div>
    );
};

export default DealOfferWallDetails;
