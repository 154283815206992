import { utcISO8601Format } from "./dateUtils";

export const mapOfflineConversion = data => {
    return data.map(conversion => ({
        advertiser: conversion.advid,
        advertiserName: conversion?.advnm,
        date: conversion.date,
        ts: conversion.ts,
        timezone: conversion.tz,
        lookback: conversion.lookback,
        status: conversion.status,
    }));
};

export const mapAdvertiser = advertisers => {
    return advertisers.map(advertiser => ({
        id: advertiser.id,
        name: advertiser.name,
    }));
};

export const offlineConversionParams = metrics => {
    const startDate = utcISO8601Format(metrics.dateRange.startDate);
    const endDate = utcISO8601Format(metrics.dateRange.endDate);
    const adv_list = metrics.selectedAdvertisers;
    const params = {
        startTime: startDate,
        endTime: endDate,
        adv_name: adv_list,
    };
    return params;
};

export const isValid = formData => {
    const { selectedAdvertiserId, selectedAdvertiserName, timeZone } = formData;
    if (
        selectedAdvertiserId &&
        selectedAdvertiserName &&
        timeZone
        // campaign?.id
    )
        return true;
    else return false;
};
