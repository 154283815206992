import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep, get, isEmpty } from "lodash";
import { FILTERS } from "../../constants/listingSearch";
import { CONFIGURATIONS } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { getFeatureMap } from "../../utils/featuremapUtils";
import {
    getTargettingParams,
    mapTargettingParams,
    mapABTargettingParams,
    getFeatureMapItem,
    feedFeatureMapListData,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { getRequiredObject } from "../../utils/globalUtils";
import { SURVEYS } from "../../constants/url";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    toggleTagUtil,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const scopeChoices = [
    "global",
    "landing",
    "survey",
    "offers",
    "offer path",
    "listings",
    "fulfillment",
];

const initialTargetParamsState = {
    regions: [],
    countries: [],
    browsers: [],
    devices: [],
    os: [],
    genders: [],
    utmMediums: [],
    utmSources: [],
    clientIPs: [],
    utmCampaigns: [],
    ageRange: [],
    keywords: [],
    availableSet: ["surveyId", "utmSources", "region", "ageRange"],
    keyVals: [],
    publishers: [],
    jobTypes: [],
    // spamCheck: {
    //     spamIP: false,
    //     category: [],
    // },
    // duplicateUsers: [],
    utmTerms: [],
};

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "name",
        isDesc: false,
        totalRowsCount: 0,
        filterKey: FILTERS.FEATURE_SETS.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.FEATURE_SETS.filterOperator,
        filtersOperator: FILTERS.FEATURE_SETS.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "featuresets"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    featureMapListData: [],
    createFeatureSet: {
        basics: {
            id: "",
            name: "",
            description: "",
            status: true,
            error: true,
            showPrompt: true,
            mainLoader: false,
            surveyList: [],
        },
        targettingParam: {
            targetParams: initialTargetParamsState,
            dropDownState: {
                plusDropdownData: "",
                showPlusDropdown: false,
                productList: [
                    {
                        id: 1,
                        firstName: "abcd",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: 2,
                        firstName: "eafgbh",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: 3,
                        firstName: "ijkl",
                        lastName: "rewardsurveyusa.com",
                    },
                    { id: 4, firstName: "mnop", lastName: "test@test.com" },
                    { id: 5, firstName: "qrst", lastName: "test@test.com" },
                    { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                    { id: 7, firstName: "yz", lastName: "test@test.com" },
                ],
                productListPlusData: [
                    {
                        id: "utmSources",
                        firstName: "UTM Sources",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: "regions",
                        firstName: "Region",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: "keywords",
                        firstName: "Keywords",
                        lastName: "rewardsurveyusa.com",
                    },
                    {
                        id: "ageRange",
                        firstName: "Age Range",
                        lastName: "test@test.com",
                    },
                    {
                        id: "genders",
                        firstName: "Gender",
                        lastName: "test@test.com",
                    },
                    {
                        id: "countries",
                        firstName: "Countries",
                        lastName: "test@test.com",
                    },
                    {
                        id: "zipCodes",
                        firstName: "Zip Codes",
                        lastName: "test@test.com",
                    },
                    {
                        id: "timeOfDay",
                        firstName: "Time of Day",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmCampaigns",
                        firstName: "UTM Campaigns",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmMediums",
                        firstName: "UTM Mediums",
                        lastName: "test@test.com",
                    },
                    {
                        id: "os",
                        firstName: "Operating Systems",
                        lastName: "test@test.com",
                    },
                    {
                        id: "browsers",
                        firstName: "Browsers",
                        lastName: "test@test.com",
                    },
                    {
                        id: "devices",
                        firstName: "Devices",
                        lastName: "test@test.com",
                    },
                    {
                        id: "allowedDays",
                        firstName: "Allowed Days",
                        lastName: "test@test.com",
                    },
                    {
                        id: "keyVals",
                        firstName: "A/B Tests",
                        lastName: "test@test.com",
                    },
                ],
            },
            zipCodeState: {
                csvFileName: "",
                csvFile: "",
                csvArray: [],
                zipCodeError: false,
                onDropActiveZipCode: false,
                // zipCode: [],
            },
            clientIPsState: {
                csvFileName: "",
                csvFile: "",
                csvArray: [],
                clientIPsError: false,
                onDropActiveClientIPs: false,
            },
        },
        exclusionTargettingParam: {
            targetParams: initialTargetParamsState,
            dropDownState: {
                plusDropdownData: "",
                showPlusDropdown: false,
                productList: [
                    {
                        id: 1,
                        firstName: "abcd",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: 2,
                        firstName: "eafgbh",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: 3,
                        firstName: "ijkl",
                        lastName: "rewardsurveyusa.com",
                    },
                    { id: 4, firstName: "mnop", lastName: "test@test.com" },
                    { id: 5, firstName: "qrst", lastName: "test@test.com" },
                    { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                    { id: 7, firstName: "yz", lastName: "test@test.com" },
                ],
                productListPlusData: [
                    {
                        id: "utmSources",
                        firstName: "UTM Sources",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: "regions",
                        firstName: "Region",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: "keywords",
                        firstName: "Keywords",
                        lastName: "rewardsurveyusa.com",
                    },
                    {
                        id: "ageRange",
                        firstName: "Age Range",
                        lastName: "test@test.com",
                    },
                    {
                        id: "genders",
                        firstName: "Gender",
                        lastName: "test@test.com",
                    },
                    {
                        id: "countries",
                        firstName: "Countries",
                        lastName: "test@test.com",
                    },
                    {
                        id: "zipCodes",
                        firstName: "Zip Codes",
                        lastName: "test@test.com",
                    },
                    {
                        id: "timeOfDay",
                        firstName: "Time of Day",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmCampaigns",
                        firstName: "UTM Campaigns",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmMediums",
                        firstName: "UTM Mediums",
                        lastName: "test@test.com",
                    },
                    {
                        id: "operatingSystems",
                        firstName: "Operating Systems",
                        lastName: "test@test.com",
                    },
                    {
                        id: "browsers",
                        firstName: "Browsers",
                        lastName: "test@test.com",
                    },
                    {
                        id: "devices",
                        firstName: "Devices",
                        lastName: "test@test.com",
                    },
                    {
                        id: "allowedDays",
                        firstName: "Allowed Days",
                        lastName: "test@test.com",
                    },
                    {
                        id: "keyVals",
                        firstName: "A/B Tests",
                        lastName: "test@test.com",
                    },
                ],
            },
            zipCodeState: {
                csvFileName: "",
                csvFile: "",
                csvArray: [],
                zipCodeError: false,
                onDropActiveZipCode: false,
                // zipCode: [],
            },
            clientIPsState: {
                csvFileName: "",
                csvFile: "",
                csvArray: [],
                clientIPsError: false,
                onDropActiveClientIPs: false,
            },
        },
        featureMaps: {
            updatedList: [],
            selected: [],
            selectedDetails: null,
            selectedListActiveItem: "",
            savedList: [],
            error: false,
        },
        historyData: [],
        historyDataRaw: [],
    },
    createFeatureSetBackup: {},
};

export const historyListingsTable = createAsyncThunk(
    "listings/featureSet/historyFeatureSetListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${CONFIGURATIONS.FETCH_HISTORY_LISTINGS_FEATURESET}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchFeatureSetsList = createAsyncThunk(
    "listings/featureSet/fetchFeaturesets",
    async (_, { getState, dispatch }) => {
        const pagination = getState().listings.featureSet.pagination;
        const params = paginationParams(pagination);
        const { listings } = getState();
        let tagFilters = get(
            listings,
            "featureSet.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.FEATURE_SETS
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        const filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        let dispatchSearchError = true;
        const response = await customFetch(
            `${CONFIGURATIONS.FETCH_FEATURE_SETS}`,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch Feature Set",
                    })
                );
                dispatch(
                    featureSetSlice.actions.setTableError(
                        MESSAGES.TABLE.noDataFromApi
                    )
                );
            }
        );
        const data = get(response, "data", []);
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.FEATURE_SETS}`;
            dispatch(featureSetSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Feature Set`;
            dispatch(featureSetSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const deleteFeatureSet = createAsyncThunk(
    "listings/featureset/deletefeatureset",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.DELETE_FEATURE_SET}/${id}`,
            "DELETE"
        );
        if (get(response, "status", 400) >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete Feature Sets",
                })
            );
        }
        return response;
    }
);

export const fetchFeatureSetDataByID = createAsyncThunk(
    "listings/featureSet/fetchFeatureSetDataByID",
    async (id, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "featureSet.featureMapListData", []);
        const response = await customFetch(
            `${CONFIGURATIONS.FETCH_FEATURE_SET_BY_ID}/${id}`,
            "GET"
        );
        const getStatus = get(response, "status", []);
        if (getStatus !== 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Fetch Feature Set data",
                })
            );
        }
        const data = get(response, "data", {});
        return [data, state];
        //   return data;
    }
);

export const fetchFeatureMapListData = createAsyncThunk(
    "listings/featureSet/fetchFeatureMapListData",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            CONFIGURATIONS.GET_FEATUREMAP_LIST_DATA,
            "POST",
            {
                limit: 1000,
                offset: 0,
            }
        );
        const getStatus = get(response, "status", []);
        if (getStatus !== 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Fetch Feature List",
                })
            );
        }
        const data = get(response, "data", []);
        return data;
    }
);

export const fetchAllSurveysData = createAsyncThunk(
    "listings/featureSet/createFeatureSet",
    async (_, { dispatch, getState }) => {
        const response = await customFetch(SURVEYS.FETCH_SURVEYS_NEW, "POST");
        if (response.length === 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch surveys",
                })
            );
        const data = get(response, "data.data", []);
        return data;
    }
);

export const createNewFeatureSet = createAsyncThunk(
    "listings/featureSet/createFeatureSet",
    async (_, { getState, dispatch }) => {
        const createPostRequest_FeatureSetData =
            getState().listings.featureSet.createFeatureSet;
        const params = createPostObjectFeatureSet(
            createPostRequest_FeatureSetData
        );
        const response = await customFetch(
            CONFIGURATIONS.CREATE_NEW_FEATURE_SET,
            "POST",
            params
        );
        const details = get(response, "data.Details", "");
        if (details !== "" && details.indexOf("already exist") !== -1) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Name with same Feature Set already exists",
                })
            );
        }
        const getStatus = get(response, "status", []);
        if (getStatus >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Create Feature Set",
                })
            );
        }
        return getStatus;
    }
);

export const updateNewFeatureSet = createAsyncThunk(
    "listings/featureSet/updateFeatureSet",
    async (id, { getState, dispatch }) => {
        const createPostRequest_FeatureSetData =
            getState().listings.featureSet.createFeatureSet;
        const params = createPostObjectFeatureSet(
            createPostRequest_FeatureSetData
        );
        const response = await customFetch(
            `${CONFIGURATIONS.UPDATE_FEATURE_SET_BY_ID}/${id}`,
            "PUT",
            params
        );
        const details = get(response, "data.Details", "");
        if (details !== "" && details.indexOf("already exist") !== -1) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Name with same Feature Set already exists",
                })
            );
        }
        const getStatus = get(response, "status", []);
        if (getStatus >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to Update Feature Set",
                })
            );
        }
        return getStatus;
    }
);

export const changeFeatureSetStatus = createAsyncThunk(
    "listings/featureset/changestatus",
    async (featureSetData, { dispatch }) => {
        const response = await customFetch(
            `${CONFIGURATIONS.UPDATE_FEATURE_SET_STATUS}/${featureSetData.id}`,
            "PUT",
            featureSetData
        );
        if (get(response, "status", 400) >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update status",
                })
            );
            return null;
        }
        return true;
    }
);

const createPostObjectFeatureSet = data => {
    const obj = {
        id: data.basics.id,
        name: data.basics.name,
        description: data.basics.description,
        status: data.basics.status ? "active" : "inactive",
        targeting: getTargettingParams(
            data.targettingParam.targetParams,
            false
        ),
        exclusionTargeting: getTargettingParams(
            data.exclusionTargettingParam.targetParams,
            false
        ),
        featureMap: getFeatureMap(data.featureMaps.savedList),
    };

    // obj.targeting.spamCheck = {
    //     ...obj.targeting.spamCheck,
    //     spamIP: obj.targeting.spamCheck.spamIP.toString(),
    // };

    // obj.exclusionTargeting.spamCheck = {
    //     ...obj.exclusionTargeting.spamCheck,
    //     spamIP: obj.exclusionTargeting.spamCheck.spamIP.toString(),
    // };

    return obj;
};

export const onFilterApplied = createAsyncThunk(
    "listings/featureSet/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.featureSet.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.FEATURE_SETS.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const featureSetSlice = createSlice({
    name: "featureSet",
    initialState,
    reducers: {
        setFeatureSetId(state, action) {
            state.createFeatureSet.basics.id = action.payload;
        },
        setFeatureSetBasicsName(state, action) {
            state.createFeatureSet.basics.name = action.payload;
            // state.createFeatureSet.basics.error = !state.createAbTest.basics.name;
        },
        setFeatureSetBasicsDescription(state, action) {
            state.createFeatureSet.basics.description = action.payload;
            // state.createFeatureSet.basics.error = !state.createAbTest.basics.name;
        },
        setFeatureSetBasicsStatus(state, action) {
            state.createFeatureSet.basics.status = action.payload;
        },
        setFeatureSetBasicsError(state, action) {
            state.createFeatureSet.basics.error = action.payload;
        },
        setShowPrompt(state, action) {
            state.createFeatureSet.basics.showPrompt = action.payload;
        },
        loading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.createFeatureSet.basics.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        resetAbTest(state) {
            state.createFeatureSet.basics.name = "";
            state.createFeatureSet.basics.description = "";
            state.createFeatureSet.basics.status = true;
            state.createFeatureSet.basics.error = false;
            state.createFeatureSet.basics.showPrompt = true;
            state.createFeatureSet.targettingParam.targetParams =
                initialTargetParamsState;
            state.createFeatureSet.exclusionTargettingParam.targetParams =
                initialTargetParamsState;
            state.createFeatureSet.featureMaps.error = false;
            state.createFeatureSet.featureMaps.selected = [];
            state.createFeatureSet.featureMaps.selectedDetails = null;
            state.createFeatureSet.featureMaps.selectedListActiveItem = "";
            state.createFeatureSet.featureMaps.savedList = [];
            state.createFeatureSetBackup = state.createFeatureSet;
        },
        setHistoryData(state, action) {
            state.createFeatureSet.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createFeatureSet.historyDataRaw = action.payload;
        },
        //targetParams
        setTargettingParamsTargetParams(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.targetParams = {
                ...data,
            };
        },
        setTargettingParamsDropDownState(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.dropDownState = {
                ...data,
            };
        },
        setTargettingParamsCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.zipCodeState.csvFileName =
                data;
        },
        setTargettingParamsCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.zipCodeState.csvFile = data;
        },
        setTargettingParamsCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.zipCodeState.csvArray = data;
        },
        setTargettingParamsZipCodeError(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.zipCodeState.zipCodeError =
                data;
        },
        setTargettingParamsOnDropActiveZipCode(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.zipCodeState.onDropActiveZipCode =
                data;
        },
        setTargettingParamsClientIPsError(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.clientIPsState.clientIPsError =
                data;
        },
        setTargettingParamsOnDropActiveClientIPs(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.clientIPsState.onDropActiveClientIPs =
                data;
        },
        setTargettingClientIPCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.clientIPsState.csvFileName =
                data;
        },
        setTargettingClientIPCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.clientIPsState.csvFile =
                data;
        },
        setTargettingClientIPCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.targettingParam.clientIPsState.csvArray =
                data;
        },

        //exclusion target params
        setExclusionTargettingParamsTargetParams(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.targetParams = {
                ...data,
            };
        },
        setExclusionTargettingParamsDropDownState(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.dropDownState = {
                ...data,
            };
        },
        setExclusionTargettingParamsCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.zipCodeState.csvFileName =
                data;
        },
        setExclusionTargettingParamsCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.zipCodeState.csvFile =
                data;
        },
        setExclusionTargettingParamsCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.zipCodeState.csvArray =
                data;
        },
        setExclusionTargettingParamsZipCodeError(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.zipCodeState.zipCodeError =
                data;
        },
        setExclusionTargettingParamsOnDropActiveZipCode(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.zipCodeState.onDropActiveZipCode =
                data;
        },

        setExclusionTargettingParamsClientIPsError(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.clientIPsState.clientIPsError =
                data;
        },
        setExclusionTargettingParamsOnDropActiveClientIPs(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.clientIPsState.onDropActiveClientIPs =
                data;
        },
        setExclusionTargettingClientIPCsvFileName(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.clientIPsState.csvFileName =
                data;
        },
        setExclusionTargettingClientIPCsvFile(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.clientIPsState.csvFile =
                data;
        },
        setExclusionTargettingClientIPCsvArray(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.exclusionTargettingParam.clientIPsState.csvArray =
                data;
        },

        //feature map
        setVariantFeatureMapSelected(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.featureMaps.selected = data;
        },
        setVariantFeatureMapSelectedDetails(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.featureMaps.selectedDetails = data;
        },
        setSelectedListActiveItem(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.featureMaps.selectedListActiveItem = data;
        },
        setSavedList(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.featureMaps.savedList = data;
        },
        setUpdatedList(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.featureMaps.updatedList = data;
        },
        setFeatureMapsError(state, action) {
            const { data, page } = action.payload;
            state.createFeatureSet.featureMaps.error = data;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchFeatureSetsList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchFeatureSetsList.fulfilled, (state, action) => {
            state.list = action.payload.data;
            state.loading = false;
            state.updatedAt = new Date();
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
        });
        builder.addCase(fetchFeatureMapListData.pending, (state, action) => {
            //   state.loading = true;
        });
        builder.addCase(fetchFeatureMapListData.fulfilled, (state, action) => {
            let updateList = cloneDeep(action.payload.data);
            updateList = updateList.map(val => {
                return {
                    ...val,
                    isError:
                        val.valuekind === "default value" ||
                        (val.value && val.value.length > 0)
                            ? false
                            : true,
                    scopeChoices: scopeChoices,
                };
            });
            state.createFeatureSet.featureMaps.updatedList = [...updateList];
            state.featureMapListData = [...updateList];
            //   state.loading = false;
        });
        builder.addCase(fetchFeatureSetDataByID.pending, (state, action) => {
            //   state.loading = true;
        });
        builder.addCase(fetchFeatureSetDataByID.fulfilled, (state, action) => {
            //   state.loading = true;
            const data = action.payload[0];
            if (Object.keys(data).length !== 0) {
                state.createFeatureSet.basics.id = data.id;
                state.createFeatureSet.basics.name = data.name;
                state.createFeatureSet.basics.description = data?.description
                    ? data.description
                    : "";
                state.createFeatureSet.basics.status =
                    data.status == "active" ? true : false;
                state.createFeatureSet.targettingParam.targetParams =
                    mapABTargettingParams(data.targeting);
                state.createFeatureSet.exclusionTargettingParam.targetParams =
                    mapABTargettingParams(data.exclusionTargeting);
                if (data.featureMap != null) {
                    let featureMapListDataCopy = action.payload[1].slice();
                    state.createFeatureSet.featureMaps.updatedList =
                        feedFeatureMapListData(
                            data.featureMap,
                            featureMapListDataCopy
                        );
                    state.createFeatureSet.featureMaps.savedList =
                        getFeatureMapItem(data.featureMap, scopeChoices);
                    let selectedKeys = getFeatureMapItem(
                        data.featureMap,
                        scopeChoices
                    ).map(item => {
                        return item.key;
                    });
                    state.createFeatureSet.featureMaps.selected = selectedKeys;
                    state.createFeatureSet.featureMaps.selectedListActiveItem =
                        selectedKeys[selectedKeys.length - 1];
                    state.createFeatureSet.featureMaps.selectedDetails =
                        selectedKeys[selectedKeys.length - 1];
                }
                state.createFeatureSetBackup = state.createFeatureSet;
            }
        });
        builder.addCase(fetchAllSurveysData.fulfilled, (state, action) => {
            state.createFeatureSet.basics.surveyList = action.payload;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const featureSetActions = featureSetSlice.actions;
export default featureSetSlice.reducer;
