import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ADVERTISER, CAMPAIGNS } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import customFetch from "../../fetch/customFetch";
import { get, isEmpty } from "lodash";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const defaultCreateData = {
    campaignsDropdownMasterList: [],
    campaignsDropdownList: [],
    selectedCampaigns: [],
    panelPrepingDef: {},
    prepingDef: null,
    prepings: [],
    deletedPrepingIds: [],
    deletedPrepingDefId: null,
    historyData: [],
    historyDataRaw: [],
};

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    updatedAt: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.ADVERTISER.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.ADVERTISER.filterOperator,
        filtersOperator: FILTERS.ADVERTISER.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "advertiser"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    createAdvertiser: {
        ...defaultCreateData,
    },
};

export const syncAdvertisers = createAsyncThunk(
    "dashboards/advertiser/syncAdvertisers",
    async ({ dispatch }) => {
        const response = await customFetch(
            ADVERTISER.SYNC_ADVERTISERS,
            "GET",
            {},
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to sync advertisers.",
                    })
                );
            }
        );
        return get(response, "data", false);
    }
);

export const fetchAdvertiserData = createAsyncThunk(
    "dashboards/advertiser/fetchAdvertiserListingData",
    async (refreshClicked, { dispatch, getState }) => {
        const { dashboards } = getState();
        const state = get(dashboards, "advertiser.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            dashboards,
            "advertiser.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.ADVERTISER
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(advertiserSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "dashboards.advertiser.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.ADVERTISER,
                filterParamsList
            );
        }
        const listingData = get(getState(), "dashboards.advertiser.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                ADVERTISER.GET_ALL_ADVERTISERS,
                "POST",
                filterParamsData,
                {},
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch advertisers",
                        })
                    );
                    dispatch(
                        advertiserSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(advertiserActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.ADVERTISER}`;
            dispatch(advertiserSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Advertiser`;
            dispatch(advertiserSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchAdvertiserById = createAsyncThunk(
    "dashboards/advertiser/fetchById",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${ADVERTISER.ADVERTISER_BY_ID}/${id}`,
            "GET",
            {},
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to get advertiser details",
                    })
                );
            }
        );
        return get(response, "data.data", false);
    }
);

export const updateAdvertiser = createAsyncThunk(
    "dashboards/advertiser/update",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${ADVERTISER.ADVERTISER_BY_ID}/${params.id}`,
            "PUT",
            params,
            {},
            {},
            {},
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to update advertiser",
                    })
                );
            }
        );
        if (response === null || response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to update advertiser.`,
                })
            );
        }
        return get(response, "data", null);
    }
);

export const deletePreping = createAsyncThunk(
    "dashboards/advertiser/preping/delete",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${ADVERTISER.DELETE_PREPING}/${id}`,
            "DELETE",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to delete preping.",
                    })
                );
            }
        );
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "dashboards/advertiser/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { dashboards } = getState();
        let initialData = dashboards.advertiser.searchFilter.filterApplied;
        let filterApplied = [...initialData];
        let filterParamsData = FILTER_PARAMS.ADVERTISER.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);
        return { filterApplied };
    }
);

export const advertiserHistory = createAsyncThunk(
    "dashboards/advertiser/historyAdvertiser",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${ADVERTISER.FETCH_ADVERTISER_HISTORY}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchAdvertiserInfo = createAsyncThunk(
    "dashboards/advertiser/advertiserInfo",
    async (id, { dispatch }) => {
        const response = await customFetch(
            ADVERTISER.GET_ADVERTISER_INFO,
            "GET",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch advertiser info.",
                    })
                );
            }
        );
        return get(response, "data", []);
    }
);

export const fetchAdvAutomation = createAsyncThunk(
    "dashboards/advertiser/advAutomation",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${ADVERTISER.ADVERTISER_AUTOMATION}?advid=${id}`,
            "GET",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch advertiser automation.",
                    })
                );
            }
        );
        return get(response, "data", false);
    }
);

export const verifyAutomationType = createAsyncThunk(
    "dashboards/advertiser/verifyAutomationType",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${ADVERTISER.VERIFY_AUTOMATION_TYPE}/${params.type}/metric/revenue`,
            "POST",
            params.data,
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to verify automation type.",
                    })
                );
            }
        );
        return get(response, "data", false);
    }
);

export const saveAutomation = createAsyncThunk(
    "dashboards/advertiser/saveAutomation",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${ADVERTISER.ADVERTISER_AUTOMATION}`,
            "POST",
            params,
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to save advertiser automation.",
                    })
                );
            }
        );
        return get(response, "data.response", false);
    }
);

export const saveAdvertiser = createAsyncThunk(
    "dashboards/campaign/saveAdvertiser",
    async (params, { dispatch }) => {
        const response = await customFetch(
            ADVERTISER.CREATE_ADVERTISER,
            "POST",
            params,
            {},
            {},
            null,
            null,
            err => {
                if (
                    err?.response?.data?.error?.includes(
                        "Request validation failed"
                    )
                ) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Duplicate advertiser name.",
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Failed to create advertiser.`,
                        })
                    );
                }
                return false;
            }
        );
        if (response === null || response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to create advertiser.`,
                })
            );
            return false;
        }
        return get(response, "data", null);
    }
);

export const fetchCampaigns = createAsyncThunk(
    "dashboards/advertiser/fetchCampaigns",
    async (id, { dispatch }) => {
        const response = await customFetch(
            CAMPAIGNS.FETCH_CAMPAIGNS,
            "POST",
            {
                filters: [
                    {
                        k: "maxAdvertiserId",
                        op: "=",
                        v: id,
                    },
                ],
            },
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch campaigns",
                    })
                );
            }
        );
        return get(response, "data.data", false);
    }
);

const advertiserSlice = createSlice({
    name: "advertiser",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        resetBasics(state) {
            state.createAdvertiser = {
                ...defaultCreateData,
            };
        },
        resetSidePanel(state) {
            state.createAdvertiser.selectedCampaigns = [];
            state.createAdvertiser.panelPrepingDef = {};
        },
        setSidepanelPrepData(state, action) {
            state.createAdvertiser.panelPrepingDef = action.payload;
        },
        setCampaignsDropdownMasterList(state, action) {
            state.createAdvertiser.campaignsDropdownMasterList = action.payload;
        },
        setCampaignsDropdownList(state, action) {
            state.createAdvertiser.campaignsDropdownList = action.payload;
        },
        setSelectedCampaigns(state, action) {
            state.createAdvertiser.selectedCampaigns = action.payload;
        },
        setCampaignPrepings(state, action) {
            state.createAdvertiser.prepings = action.payload;
        },
        setDeletedCampaignPrepingsId(state, action) {
            state.createAdvertiser.deletedPrepingIds = action.payload;
        },
        setDefaultPreping(state, action) {
            state.createAdvertiser.prepingDef = action.payload;
        },
        setDeletedDefaultPrepingId(state, action) {
            state.createAdvertiser.deletedPrepingDefId = action.payload;
        },
        setHistoryData(state, action) {
            state.createAdvertiser.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createAdvertiser.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAdvertiserData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchAdvertiserData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });

        builder.addCase(syncAdvertisers.pending, state => {
            state.mainLoader = true;
        });
        builder.addCase(fetchAdvertiserById.fulfilled, (state, action) => {
            const data = action.payload;
            state.createAdvertiser.prepingDef = data.prepingDef;
            state.createAdvertiser.prepings = data.prepings;
            state.loading = false;
        });

        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const advertiserActions = advertiserSlice.actions;
export default advertiserSlice.reducer;
