import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { ROAS_CONFIG } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";

const initialState = {
    pubCode: "",
    pubName: "",
    campaign: "",
    conversionEvents: [],
    status: false,
    cpa: "",
    margin: "",
    conversionURL: "",
    subCampaignTable: [],
    deletedPsTableData: [],
    psExclusionList: [],
    newPubName: "",
    newPubId: "",
    newCampaignName: "",
    newCampaignId: "",
    epc: false,
    orgConv: false,
    type: "margin",
};

export const getRoasConfigByIds = createAsyncThunk(
    "dashboards/roas/edit/roasConfigData",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${ROAS_CONFIG.GET_ROAS_CONFIG_BY_PUB_ID}/${get(
                params,
                "pubId",
                null
            )}/campaign/${get(params, "campaignId", null)}`
        );
        const data = get(response, "data", {});
        if (isEmpty(data))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to fetch roas config by publisher id and campaign id!",
                })
            );
        return data;
    }
);

export const createRoasConfiguration = createAsyncThunk(
    "dashboards/roas/create",
    async (_, { getState, dispatch }) => {
        const params = get(getState(), "dashboards.roas", {});

        let data = createTMCDataobject(params);

        data = {
            ...data,
            orgConv: params.orgConv,
            //commented this as orgConv is required in all types
            // ...(params.epc ? { orgConv: params.orgConv } : {}),
        };
        let headerData = {
            requestType: "POST",
            pubId: params.pubCode,
            campaignId: params.campaign,
            ps: "",
        };
        const response = await sendRequest(headerData, data, dispatch);
        const apiResponseData = get(response, "data", null);
        if (!apiResponseData)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create TMS config!",
                })
            );
        if (params.type === "margin") {
            if (params.subCampaignTable.length > 0 && apiResponseData) {
                params.subCampaignTable.forEach(async (element, index) => {
                    let data = createTMCDataobject(params, element);
                    let headerData = {
                        requestType: "POST",
                        pubId: params.pubCode,
                        campaignId: params.campaign,
                        ps: element.ps,
                    };
                    const response = await sendRequest(
                        headerData,
                        data,
                        dispatch
                    );
                    if (!get(response, "data", null))
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: `Failed to create ${element.ps} PS!`,
                            })
                        );
                });
            }
        }
        return response;
    }
);

export const updateRoasConfiguration = createAsyncThunk(
    "dashboards/roas/edit",
    async (urlParams, { getState, dispatch }) => {
        const params = get(getState(), "dashboards.roas", {});

        let data = createTMCDataobject(params);

        data = {
            ...data,
            orgConv: params.orgConv,
            //commented this as orgConv is required in all types
            // ...(data.epc ? { orgConv: params.orgConv } : {}),
        };

        let headerData = {
            requestType: "PUT",
            pubId: params.pubCode,
            campaignId: params.campaign,
            ps: "",
        };

        const response = await sendRequest(headerData, data, dispatch);
        const apiResponseData = get(response, "data", null);
        if (!apiResponseData)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update TMS config!",
                })
            );
        if (params.type === "margin") {
            // For Update PS Level data
            if (params.subCampaignTable.length > 0 && apiResponseData) {
                params.subCampaignTable.forEach(async (element, index) => {
                    let data = createTMCDataobject(params, element);
                    let headerData = {
                        requestType: "PUT",
                        pubId: params.pubCode,
                        campaignId: params.campaign,
                        ps: element.ps,
                    };
                    const response = await sendRequest(
                        headerData,
                        data,
                        dispatch
                    );
                    if (!get(response, "data", null))
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: `Failed to update ${element.ps} PS!`,
                            })
                        );
                });
            }
        }

        // For delete PS Level data
        if (params.deletedPsTableData.length > 0 && !isEmpty(urlParams)) {
            params.deletedPsTableData.forEach(async (element, index) => {
                let headerData = {
                    requestType: "DELETE",
                    pubId: params.pubCode,
                    campaignId: params.campaign,
                    ps: element.ps,
                };
                const response = await sendRequest(headerData, {}, dispatch);
                if (
                    get(response, "data", null) == "" &&
                    index == params.deletedPsTableData.length - 1
                )
                    dispatch(
                        toastListActions.setToastList({
                            type: "Success",
                            message: `Successfully deleted ${
                                params.deletedPsTableData.length > 1
                                    ? "several "
                                    : ""
                            } PS!`,
                        })
                    );
            });
        }
        return response;
    }
);

const sendRequest = (headerData, data, dispatch) => {
    let response;
    if (get(headerData, "requestType", "") === "POST") {
        response = customFetch(
            `${ROAS_CONFIG.CREATE_UPDATE_ROAS_CONFIG}`,
            `${get(headerData, "requestType", "POST")}`,
            data,
            {},
            {},
            null,
            null,
            data => {
                if (data?.response?.data?.Details.includes("already exists")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `${data.response.data.Details}`,
                        })
                    );
                }
            }
        );
    } else {
        response = customFetch(
            `${ROAS_CONFIG.CREATE_UPDATE_ROAS_CONFIG}/publisher/${get(
                headerData,
                "pubId",
                null
            )}/campaign/${get(headerData, "campaignId", null)}/ps/${get(
                headerData,
                "ps",
                null
            )}`,
            `${get(headerData, "requestType", "PUT")}`,
            data
        );
    }
    return response;
};

const createTMCDataobject = (state, obj = null) => {
    return {
        pubCode: state.pubCode,
        campaign: state.campaign,
        ps: obj == null ? "" : obj.ps,
        exclusionPS:
            obj == null
                ? ((state.psExclusionList.length ||
                      state.subCampaignTable.length) &&
                      !state.epc && [
                          ...state.psExclusionList,
                          ...state.subCampaignTable.map(_ => _.ps),
                      ]) ||
                  null
                : null,
        conversionEvents: state.epc ? ["usr_reg"] : state.conversionEvents,
        margin: obj == null ? state.margin : obj.margin / 100,

        ...(state.type !== "allConv"
            ? { cpa: obj == null ? state.cpa : obj.cpa }
            : {}),
        conversionURL: state.conversionURL,
        status: state.status,
        epc: state.epc,
        type: state.type,
    };
};

const roasSlice = createSlice({
    name: "roas",
    initialState,
    reducers: {
        setPublisher(state, action) {
            state.pubCode = action.payload;
        },
        setPublisherName(state, action) {
            state.pubName = action.payload;
        },
        setCampaign(state, action) {
            state.campaign = action.payload;
        },
        setConversionEvent(state, action) {
            state.conversionEvents = action.payload;
        },
        setStatus(state, action) {
            state.status = action.payload;
        },
        setEpc(state, action) {
            state.epc = action.payload;
        },
        setOrgConv(state, action) {
            state.orgConv = action.payload;
        },
        setType(state, action) {
            state.type = action.payload;
        },
        setCpa(state, action) {
            state.cpa = +action.payload;
        },
        setProfitMargin(state, action) {
            state.margin = +action.payload;
        },
        setConversionUrl(state, action) {
            state.conversionURL = action.payload;
        },
        setSubCampaignTable(state, action) {
            state.subCampaignTable = action.payload;
        },
        setDeletedPsTableData(state, action) {
            state.deletedPsTableData = action.payload;
        },
        setPsExclusionList(state, action) {
            state.psExclusionList = action.payload;
        },
        setConfig(state, action) {
            const data = action.payload.configs[0];
            state.pubCode = data.pubCode;
            state.campaign = data.campaign;
            state.conversionEvents = data.conversionEvents || [];
            state.cpa = data.cpa;
            state.margin = data.margin;
            state.conversionURL = data.conversionURL;
            state.status = data.status;
            state.psExclusionList = data.exclusionPS || [];
            state.epc = data.epc;
            state.orgConv = data.orgConv;
            state.type =
                data.type === "" ? (data.epc ? "epc" : "margin") : data.type;

            if (action.payload.configs.length > 1) {
                // filter exclusion with added ps
                state.psExclusionList =
                    data.exclusionPS?.filter(
                        _ =>
                            action.payload.configs?.findIndex(
                                obj => obj.ps === _
                            ) === -1
                    ) || [];
                const tableData = action.payload.configs
                    .filter((itm, idx) => idx != 0)
                    .map((item, idx) => {
                        return {
                            ps: item.ps,
                            cpa: item.cpa,
                            margin: item.margin * 100,
                        };
                    });
                state.subCampaignTable = tableData || [];
            }
        },
        setNewPubName(state, action) {
            state.newPubName = action.payload;
        },
        setNewPubId(state, action) {
            state.newPubId = action.payload;
        },
        setNewCampaignName(state, action) {
            state.newCampaignName = action.payload;
        },
        setNewCampaignId(state, action) {
            state.newCampaignId = action.payload;
        },
        resetConfig(state) {
            state.pubCode = "";
            state.pubName = "";
            state.campaign = "";
            state.conversionEvents = [];
            state.status = false;
            state.cpa = "";
            state.margin = "";
            state.conversionURL = "";
            state.subCampaignTable = [];
            state.deletedPsTableData = [];
            state.psExclusionList = [];
            state.newPubName = "";
            state.newPubId = "";
            state.newCampaignName = "";
            state.newCampaignId = "";
            state.epc = false;
            state.orgConv = false;
            state.type = "margin";
        },
    },
});

export const roasActions = roasSlice.actions;
export default roasSlice.reducer;
