import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
    useRef,
} from "react";
import s from "./Settings.module.scss";
import Icon from "../../Shared/Icon/Icon";
import FlowRenderer from "../../Shared/FlowRenderer/FlowRenderer";
import { EntityType } from "../../Shared/FlowRenderer/FlowRendererUtils";
import useUndoableState from "../../Shared/hooks/use-undo";
import { useDispatch, useSelector } from "react-redux";
import AddNode from "./AddNode";
import customFetch from "../../fetch/customFetch";
import { isEmpty, isEqual } from "lodash";
import { isArrayEqual } from "../../utils/globalUtils";

import {
    cloneTemplate,
    fetchAllTemplate,
    fetchTemplate,
    templateActions,
} from "../../store/flowmanager/template";
import Select from "../../Shared/Select/Select";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import CreateTemplate, { TemplateKeys } from "../CreateTemplate/CreateTemplate";
import { CONTAINERS, MAIN, TEMPLATE } from "../../constants/url";
import { FlowActionList } from "../../components/CreateSaveHeader/CreateSaveHeader";
import {
    fetchContainerListWithData,
    reactQueryApiCall,
} from "../../generalApi/generalApi";
import { useQueryClient } from "react-query";
import noTemplate from "../../assets/img/noTemplate.svg";
import Skeleton from "react-loading-skeleton";
import { SelectWithCloneOption } from "../CreateContainer/CreateContainer";
import { toastListActions } from "../../store/toastList/toastList";
import {
    flowRulesToNormalRules,
    getCurrentNodeTree,
    handleDeleteAndCreateNode,
    resetEntitySubtypesOnChange,
} from "./FlowUtils";
import { collectionSelectList } from "../../utils/collectionUtils";
import Button, { ButtonTypes, buttonSize } from "../../Shared/Button/Button";
import OutsideClickHandler from "react-outside-click-handler";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import ContainerPreview from "./ContainerPreview";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { v4 as uuidv4 } from "uuid";

const globalBackAllowedTps = [
    "curr_ct",
    "prev_ct",
    "user_status",
    "user_status_current",
    "custom_back",

    "job_type",
    "utm_term",
    "utm_source",
    "back",
    "abt4",
];
const settingsOptions = [
    {
        id: 1,
        name: "Global Back",
        icon: "BackButtonTraffic",
    },
    {
        id: 2,
        name: "Feature Group",
        icon: "Template",
    },
    {
        id: 3,
        name: "Fallback Container",
        icon: "PreviousContainer",
    },
    {
        id: 4,
        name: "Litigator Rules",
        icon: "SpamUser",
    },
];

export const FlowSubTypes = {
    PREV: "prevContainer",
    CURRENT: "sameContainer",
    NEXT: "next",
};

const defaultFlowRule = [
    {
        ruleSetId: -1,
        id: -1,
        nextSetId: -2,
        entityType: EntityType.FLOW_ROUTER,
        entitySubType: "next",
        targettingParams: {
            type: "OR",
            value: [
                {
                    key: "back",
                    type: "EXPRESSION",
                    value: 1,
                    operator: "EQUAL",
                },
            ],
        },
        isActive: true,
    },
    {
        ruleSetId: -2,
        id: -2,
        entityType: EntityType.CONTAINER,
        entitySubType: FlowSubTypes.PREV,
        isActive: true,
    },
];

const Settings = forwardRef(
    (
        {
            flowRouterId = -2,
            selectedTemplateId = null,
            flowRules = [],
            isReadOnly = false,
            selectedFallBackContainer,
            flowFallbackId,
            settingLoader,
            setSettingLoader = () => {},
            flowVertical,
            featureGroupProperties = {},
            litigatorFlowRules,
            litigatorStartId,
            isFlowLive = false,
        },
        ref
    ) => {
        const [rootNode, setRootNode] = useState(flowRouterId || -2);
        const [autoLayout, setAutoLayout] = useState(true);
        const [openDrawer, setOpenDrawer] = useState(false);
        const [loading, setLoading] = useState(false);
        const [editNode, setEditNode] = useState(null);
        const [disconnectedNodes, setDisconnectedNodes] = useState(null);

        const [parentNode, setParentNode] = useState(null);
        const [rulesMeta, setRulesMeta] = useState({});
        const [settingType, setSettingType] = useState("Global Back");

        const [templateId, setTemplateId] = useState(null);

        const [viewIsClick, setViewIsClick] = useState(false);
        const [createPhasePopup, setCreatePhasePopup] = useState(false);
        const [templateCloneObj, setTemplateCloneObj] = useState(null);

        const [showContainerList, setShowContainerList] = useState(false);

        const [rules, setRules] = useState([]);

        const [fallBackContainerId, setFallBackContainerId] = useState(null);
        const [fallBackContainerList, setFallbackContainerList] = useState([]);
        const [detailsAddNodeCompleted, setDetailsAddNodeCompleted] =
            useState(false);

        const {
            state: localFlowRules,
            setState: setLocalFlowRules,
            resetState: resetRules,
            index: ruleStateIndex,
            lastIndex: ruleStateLastIndex,
            goBack: undoRules,
            goForward: redoRules,
        } = useUndoableState(flowRules);

        const canUndo = !isReadOnly && ruleStateIndex > 0;
        const canRedo = !isReadOnly && ruleStateIndex < ruleStateLastIndex;
        const canAutoAdjust = !isReadOnly;

        const [localLitigatorStartId, setLocalLitigatorStartId] =
            useState(litigatorStartId);

        const {
            state: localLitigatorRules,
            setState: setLocalLitigatorRules,
            resetState: resetRules1,
            index: ruleStateIndex1,
            lastIndex: ruleStateLastIndex1,
            goBack: undoLitigatorRules,
            goForward: redoLitigatorRules,
        } = useUndoableState(litigatorFlowRules);

        const canUndoLitigator = !isReadOnly && ruleStateIndex1 > 0;
        const canRedoLitigator =
            !isReadOnly && ruleStateIndex1 < ruleStateLastIndex1;
        const canAutoAdjustLitigator = !isReadOnly;

        const litigatorHeaderActionList = [
            {
                id: "UNDO",
                icon: "Undo",
                enabled: canUndoLitigator,
            },
            {
                id: "REDO",
                icon: "Redo",
                enabled: canRedoLitigator,
            },
            {
                id: "TIDY",
                icon: "AutoArrange",
                type: "SWITCH",
                text: "Auto Layout",
                value: autoLayout,
                enabled: canAutoAdjustLitigator,
            },
        ];

        // Fetching general api using react-query
        const { apiData: apiContainerData } = fetchContainerListWithData();

        const { apiData: apiTemplateData, isLoading: templateLoader } =
            reactQueryApiCall("templateListData", "Template List", {
                url: TEMPLATE.FETCH_ALL_TEMPLATE_LIST,
                method: "GET",
            });

        const createTemplateState = useSelector(
            state => state.flowmanager.template.createTemplate
        );

        const authUser = useSelector(state => state.auth.user);

        const showFlowLitigator = authUser.modules.includes(
            `FlowManager.Flow.FlowLitigator.Read`
        );

        const queryClient = useQueryClient();
        const dispatch = useDispatch();
        const templateOpenRef = useRef();
        const saveFunctionCallRef = useRef(null);
        const previewSidePanelRef = useRef();
        const globalBackFlowRef = useRef();
        const litigatorFlowRef = useRef();

        const [previewPanelTempRules, setPreviewPanelTempRules] = useState([]);
        const [backupRules, setBackupRules] = useState([]);

        const collections = useSelector(
            state => state.flowmanager.collection.list
        );

        const containers = useMemo(() => {
            return apiContainerData?.data || [];
        }, [apiContainerData]);

        const templates = useMemo(() => {
            return apiTemplateData || [];
        }, [apiTemplateData]);

        const activeRules = useMemo(() => {
            return rules?.filter(_ => _?.isActive);
        }, [rules]);

        const activeLitigatorRules = useMemo(() => {
            return localLitigatorRules?.filter(_ => _?.isActive);
        }, [localLitigatorRules]);

        const deleteNode = rule => {
            setOpenDrawer(false); // close add node side panel

            let ruleSetId;
            let deletedRuleIds = [];
            const rulesArray = rule.originalSplits || rule;
            if (Array.isArray(rulesArray) || rulesArray.length) {
                rulesArray.forEach(_ => (_.isActive = false));
                ruleSetId = rulesArray[0].ruleSetId;
                deletedRuleIds = rulesArray.map(_ => _.id);
            } else {
                rule.isActive = false;
                ruleSetId = rule.ruleSetId;
                deletedRuleIds.push(rule.id);
            }

            let activeRulesWithSameRuleSetId = (
                settingType === "Litigator Rules"
                    ? localLitigatorRules
                    : localFlowRules
            )?.filter(
                _ =>
                    _.isActive &&
                    _.ruleSetId === ruleSetId &&
                    !deletedRuleIds.includes(_.id)
            );

            const defaultActiveRules = activeRulesWithSameRuleSetId.filter(
                _ =>
                    _.entityType !== EntityType.ACTIONS &&
                    (!_.targettingParams || !_.targettingParams?.value)
            );

            if (
                defaultActiveRules.length &&
                activeRulesWithSameRuleSetId.length ===
                    defaultActiveRules.length
            ) {
                defaultActiveRules.forEach(_ => {
                    _.isActive = false;
                    deletedRuleIds.push(_.id);
                });
            }

            activeRulesWithSameRuleSetId = (
                settingType === "Litigator Rules"
                    ? localLitigatorRules
                    : localFlowRules
            )?.filter(
                _ =>
                    _.isActive &&
                    _.ruleSetId === ruleSetId &&
                    !deletedRuleIds.includes(_.id)
            );

            const ruleSetter =
                settingType === "Litigator Rules"
                    ? setLocalLitigatorRules
                    : setLocalFlowRules;

            ruleSetter(r => {
                return [
                    ...r.map(_ => ({
                        ..._,
                        nextSetId:
                            _.nextSetId === ruleSetId &&
                            activeRulesWithSameRuleSetId.length === 0
                                ? null
                                : _.nextSetId,
                        isActive: _.isActive
                            ? !deletedRuleIds.includes(_.id)
                            : _.isActive,
                    })),
                ];
            });
        };

        useEffect(() => {
            let temp = defaultFlowRule;
            const activeLocalFlowRules = localFlowRules.filter(_ => _.isActive);
            if (activeLocalFlowRules.length) {
                if (
                    activeLocalFlowRules.length === 1 &&
                    activeLocalFlowRules[0].entityType ===
                        EntityType.FLOW_ROUTER
                ) {
                    setLocalFlowRules([]);
                } else {
                    temp = localFlowRules;
                }
            }

            const [rules] = flowRulesToNormalRules(temp);

            setRules(rules);
        }, [localFlowRules]);

        useEffect(() => {
            setTemplateId(selectedTemplateId);
        }, [selectedTemplateId]);

        useEffect(() => {
            if (selectedFallBackContainer?.length > 0)
                setFallbackContainerList(selectedFallBackContainer);
        }, [selectedFallBackContainer]);

        useEffect(() => {
            if (flowFallbackId !== -3) setFallBackContainerId(flowFallbackId);
        }, [flowFallbackId]);

        useImperativeHandle(ref, () => {
            return {
                getData: () => {
                    return [
                        rootNode,
                        localFlowRules.filter(_ => _.id && _.id > 0),
                        templateId,
                        fallBackContainerList,
                        fallBackContainerId,
                        templates,
                        localLitigatorRules.filter(_ => _.id && _.id > 0),
                        localLitigatorStartId,
                    ];
                },
                highlightDisconnectedNodes: nodes => {
                    setDisconnectedNodes(nodes);
                },
            };
        });

        const createDefaultNodes = async data => {
            const defaultNode = await handleAddNode(
                {
                    ...data,
                },
                undefined,
                true
            );

            const backNode = await handleAddNode(
                [
                    {
                        entityType: EntityType.FLOW_ROUTER,
                        entitySubType: "next",
                        nextSetId: defaultNode[0]?.ruleSetId,
                        targettingParams: {
                            type: "OR",
                            value: [
                                {
                                    key: "back",
                                    type: "EXPRESSION",
                                    value: 1,
                                    operator: "EQUAL",
                                },
                            ],
                        },
                    },
                ],
                undefined,
                true
            );

            setRootNode(backNode[0].ruleSetId);

            return [...backNode, ...defaultNode];
        };

        const handleUpdateNode = async data => {
            if (Array.isArray(data)) {
                const deletedNodes = data
                    .filter(_ => _.toBeDeleted)
                    .map(_ => ({ ..._, isActive: false }));
                const newNodes = data.filter(_ => !_.id && !_.toBeDeleted);
                const existingNodes = data.filter(_ => _.id && !_.toBeDeleted);
                const newCreatedNodes = [];

                if (newNodes?.length) {
                    const nodes = await handleAddNode(newNodes, null, true);
                    newCreatedNodes.push(...nodes);
                }

                setLocalFlowRules(rules => [
                    ...deletedNodes,
                    ...existingNodes,
                    ...newCreatedNodes,
                    ...rules.filter(_ => _.ruleSetId !== data[0].ruleSetId),
                ]);
            } else {
                const ruleSetter =
                    settingType === "Litigator Rules"
                        ? setLocalLitigatorRules
                        : setLocalFlowRules;
                if (data.id && data.id !== -2) {
                    ruleSetter(rules => [
                        { ...data },
                        ...rules.filter(_ => _.id !== data.id),
                    ]);
                } else {
                    const rules = await createDefaultNodes({
                        ...data,
                        id: undefined,
                        ruleSetId: undefined,
                        nextSetId: undefined,
                    });
                    ruleSetter(rules);
                }
            }

            setLoading(false);
            setOpenDrawer(false);
        };

        const handleAddNode = async (
            data,
            pn,
            returnData = false,
            createDefaultNode = false,
            containerTypeEndCall = true
        ) => {
            setLoading(true);
            const newNodes = [];
            const defaultNodes = [];
            let localParent = pn || parentNode;

            if (createDefaultNode && rules.every(_ => _.id < 0)) {
                const nodes = await createDefaultNodes({
                    ...rules.find(_ => _.id === -2),
                    id: undefined,
                    ruleSetId: undefined,
                    nextSetId: undefined,
                });
                defaultNodes.push(...nodes);
                if (localParent?.id === -2) {
                    localParent = nodes[nodes.length - 1];
                } else {
                    localParent = nodes[0];
                }
            }

            if (Array.isArray(data)) {
                let ruleSetId = null;

                const firstNewNode = await customFetch(
                    MAIN.SAVE_NODES,
                    "POST",
                    {
                        data: [data[0]],
                    }
                );
                ruleSetId = firstNewNode?.data?.data[0]?.ruleSetId || ruleSetId;

                const allNewNodes = await customFetch(MAIN.SAVE_NODES, "POST", {
                    data: data.map((_, i) => ({
                        ...(i === 0 ? firstNewNode?.data?.data[0] : _),
                        ruleSetId: ruleSetId,
                    })),
                });
                newNodes.push(...allNewNodes.data.data);

                if (returnData) {
                    return newNodes;
                }

                if (data[0].entitySubType === "LOGICAL") {
                    const newNode = await customFetch(MAIN.SAVE_NODE, "POST", {
                        ruleSetId: ruleSetId,
                        entitySubType: "LOGICAL",
                        entityType: "ROUTER",
                        targettingParams: {},
                    });
                    newNodes.push(newNode?.data.data);
                }
            } else {
                let containerEnd;
                if (containerTypeEndCall) {
                    containerEnd = await customFetch(MAIN.SAVE_NODE, "POST", {
                        entityType: "CONTAINER_ROUTER",
                        entitySubType: "end",
                    });
                }

                const newNode = await customFetch(MAIN.SAVE_NODE, "POST", {
                    ...data,
                    ...(containerTypeEndCall && {
                        nextSetId: containerEnd?.data.data.ruleSetId,
                    }),
                });

                newNodes.push(newNode?.data.data);
                if (containerTypeEndCall)
                    newNodes.push(containerEnd?.data.data);

                if (returnData) {
                    return newNodes;
                }
            }

            if (!localParent?.id) {
                if (settingType === "Litigator Rules") {
                    setLocalLitigatorStartId(
                        newNodes?.find(_ => _.entityType === "CONTAINER")
                            ?.ruleSetId
                    );
                } else {
                    if (newNodes?.length && newNodes[newNodes?.length - 1]) {
                        newNodes[newNodes?.length - 1].nextSetId = rootNode;
                    }
                }
            }

            if (localParent) {
                if (newNodes?.length && newNodes[newNodes?.length - 1]) {
                    newNodes[newNodes?.length - 1].nextSetId =
                        localParent.nextSetId;
                }
                localParent.nextSetId = newNodes[0]?.ruleSetId;
            }

            const ruleSetter =
                settingType === "Litigator Rules"
                    ? setLocalLitigatorRules
                    : setLocalFlowRules;

            ruleSetter(r => {
                return [
                    ...r.map(_ => {
                        if (_.id === localParent?.id) {
                            _.nextSetId = localParent?.nextSetId;
                        }
                        return _;
                    }),
                    ...defaultNodes,
                    ...newNodes,
                ];
            });
            setLoading(false);
            setOpenDrawer(false);
        };

        const headerActionList = [
            {
                id: "UNDO",
                icon: "Undo",
                enabled: canUndo,
            },
            {
                id: "REDO",
                icon: "Redo",
                enabled: canRedo,
            },
            {
                id: "TIDY",
                icon: "AutoArrange",
                type: "SWITCH",
                text: "Auto Layout",
                value: autoLayout,
                enabled: canAutoAdjust,
            },
        ];

        const previewPanelSave = async () => {
            await setBackupRules([]);
            const newNodeIds = previewPanelTempRules?.map(_ => _.id);
            const ruleSetter =
                settingType === "Litigator Rules"
                    ? setLocalLitigatorRules
                    : setLocalFlowRules;

            ruleSetter(rules => [
                ...rules.filter(_ => !newNodeIds.includes(_.id)),
                ...previewPanelTempRules,
            ]);
            setPreviewPanelTempRules([]);
            previewSidePanelRef?.current?.close();
        };

        const disConnectFlow = rule => {
            if (rule.id !== -1) {
                let copyRules = JSON.parse(JSON.stringify(localFlowRules));
                let sourceData = copyRules.find(
                    (obj, idx) => obj.id === rule.id
                );
                if (sourceData?.nextSetId) {
                    const nextNode = copyRules.find(
                        (obj, idx) => obj.ruleSetId === sourceData?.nextSetId
                    );
                    if (
                        nextNode.entitySubType === "end" &&
                        nextNode.nextSetId
                    ) {
                        nextNode.nextSetId = null;
                    } else {
                        sourceData.nextSetId = null;
                    }
                }
                setLocalFlowRules(copyRules);
            } else {
                setRootNode(null);
                // setActiveRules(
                //     activeRules.map(_ => {
                //         if (_.id === -1) {
                //             _.nextSetId = null;
                //         }
                //         return _;
                //     })
                // );
            }
        };

        return (
            <>
                <div className="dp-parent h-100">
                    {loading && <LinearDeterminate></LinearDeterminate>}
                    <div className={s.leftPanel}>
                        {settingsOptions.map(_ => {
                            if (
                                _.name === "Litigator Rules" &&
                                !showFlowLitigator
                            )
                                return <></>;
                            return (
                                <div
                                    className={`dp-parent dp-parent-ver-center gap-10 ${
                                        s.option
                                    } ${
                                        settingType === _.name &&
                                        s.selectedOption
                                    }`}
                                    onClick={() => {
                                        setSettingType(_.name);
                                    }}
                                >
                                    <Icon icon={_.icon} size="16px" />
                                    <span>{_.name}</span>
                                </div>
                            );
                        })}
                    </div>
                    <div
                        className={`${s.rightPanel} ${
                            settingType === "Feature Group"
                                ? s.rightPanelglobalSettings
                                : ""
                        } relative`}
                    >
                        {settingType === "Global Back" && (
                            <>
                                <div className={s.topActionBar}>
                                    <FlowActionList
                                        actions={headerActionList}
                                        actionCallback={type => {
                                            switch (type) {
                                                case "UNDO":
                                                    undoRules();
                                                    setDisconnectedNodes(null);
                                                    break;
                                                case "REDO":
                                                    redoRules();
                                                    setDisconnectedNodes(null);
                                                    break;
                                                case "TIDY":
                                                    setAutoLayout(_ => !_);
                                                    break;
                                            }
                                        }}
                                    ></FlowActionList>
                                </div>
                                <FlowRenderer
                                    ref={globalBackFlowRef}
                                    hideExpandCollapse={true}
                                    containerAsDestination={true}
                                    readOnly={isReadOnly}
                                    startNodeText="Global Back Rules"
                                    undo={undoRules}
                                    redo={redoRules}
                                    startId={
                                        localFlowRules.find(
                                            _ => _.ruleSetId === rootNode
                                        )?.nextSetId || rootNode
                                    }
                                    containers={containers}
                                    rules={activeRules}
                                    autoLayout={autoLayout}
                                    openNode={node => {
                                        setOpenDrawer(true);
                                        setEditNode(node);
                                        if (!node) {
                                            const parent =
                                                localFlowRules.find(
                                                    _ =>
                                                        _.ruleSetId === rootNode
                                                ) ||
                                                defaultFlowRule.find(
                                                    _ =>
                                                        _.ruleSetId === rootNode
                                                );
                                            setParentNode(parent);
                                        }
                                    }}
                                    deleteNode={deleteNode}
                                    onConnect={async obj => {
                                        let sourceID = parseInt(
                                            obj.source.split(":")[1]
                                        );
                                        const targetID = parseInt(
                                            obj.target.split(":")[1]
                                        );
                                        let targetRuleSetId = "";
                                        if (
                                            obj.target.split(":")[0] === "set"
                                        ) {
                                            targetRuleSetId = targetID;
                                        } else {
                                            targetRuleSetId = rules.find(
                                                _ => _.id === targetID
                                            )?.ruleSetId;
                                        }
                                        let startNodeTree = [];
                                        getCurrentNodeTree(
                                            activeRules,
                                            startNodeTree,
                                            targetRuleSetId
                                        );

                                        const isNodeConnectCircular =
                                            startNodeTree.some(
                                                (elem, id) =>
                                                    elem.id === sourceID
                                            );
                                        if (!isNodeConnectCircular) {
                                            const newRules = [];
                                            if (sourceID === -1) {
                                                sourceID = localFlowRules.find(
                                                    _ =>
                                                        _.ruleSetId === rootNode
                                                )?.id;
                                            } else {
                                                const rule = rules.find(
                                                    _ => _.id === sourceID
                                                );
                                                const nextRule = rules.find(
                                                    _ =>
                                                        _.ruleSetId ===
                                                        rule.nextSetId
                                                );

                                                if (
                                                    rule.entityType ===
                                                    EntityType.CONTAINER
                                                ) {
                                                    setLoading(true);
                                                    if (
                                                        !nextRule ||
                                                        nextRule.entityType !==
                                                            EntityType.ACTIONS
                                                    ) {
                                                        const containerEnd =
                                                            await customFetch(
                                                                MAIN.SAVE_NODE,
                                                                "POST",

                                                                {
                                                                    entityType:
                                                                        "CONTAINER_ROUTER",
                                                                    entitySubType:
                                                                        "end",
                                                                    nextSetId:
                                                                        targetRuleSetId,
                                                                }
                                                            );
                                                        targetRuleSetId =
                                                            containerEnd.data
                                                                .data.ruleSetId;
                                                        newRules.push(
                                                            containerEnd.data
                                                                .data
                                                        );
                                                    } else if (
                                                        nextRule &&
                                                        nextRule.entityType ===
                                                            EntityType.ACTIONS
                                                    ) {
                                                        sourceID = nextRule.id;
                                                    }
                                                    setLoading(false);
                                                }
                                            }
                                            if (sourceID === undefined) {
                                                const temp =
                                                    localFlowRules.find(
                                                        _ =>
                                                            _.ruleSetId &&
                                                            _.nextSetId ===
                                                                targetRuleSetId
                                                    );

                                                setRootNode(
                                                    temp?.ruleSetId ||
                                                        targetRuleSetId
                                                );
                                            } else {
                                                setLocalFlowRules(rules => [
                                                    ...rules.map(rule => {
                                                        if (
                                                            rule.id === sourceID
                                                        ) {
                                                            return {
                                                                ...rule,
                                                                nextSetId:
                                                                    targetRuleSetId,
                                                            };
                                                        }
                                                        return rule;
                                                    }),
                                                    ...newRules,
                                                ]);
                                            }
                                            //to remove highlight from disconnected node when connected
                                            if (
                                                disconnectedNodes?.nodes &&
                                                !openDrawer
                                            ) {
                                                if (targetRuleSetId) {
                                                    setDisconnectedNodes({
                                                        nodes: disconnectedNodes?.nodes?.filter(
                                                            _ =>
                                                                _.ruleSetId !==
                                                                targetRuleSetId
                                                        ),
                                                        id: uuidv4(),
                                                    });
                                                } else {
                                                    setDisconnectedNodes(null);
                                                }
                                            }
                                        } else {
                                            dispatch(
                                                toastListActions.setToastList({
                                                    type: "Error",
                                                    message:
                                                        "Connection cannot be established between nodes in a circular manner.",
                                                })
                                            );
                                        }
                                    }}
                                    addNode={parent => {
                                        setOpenDrawer(true);
                                        setEditNode(null);
                                        if (!parent || parent?.id === -1) {
                                            parent =
                                                localFlowRules.find(
                                                    _ =>
                                                        parent?.id === -1 &&
                                                        _.entityType ===
                                                            EntityType.FLOW_ROUTER
                                                ) ||
                                                defaultFlowRule.find(
                                                    _ =>
                                                        _.ruleSetId ===
                                                        parent?.id
                                                );
                                        }
                                        setParentNode(parent);
                                    }}
                                    editNode={editNode}
                                    filteredRules={[]}
                                    statsMap={{}}
                                    openPreview={node => {
                                        setBackupRules(
                                            JSON.parse(
                                                JSON.stringify(localFlowRules)
                                            )
                                        );
                                        setEditNode(node);
                                        previewSidePanelRef?.current?.open();
                                    }}
                                    disConnectFlow={disConnectFlow}
                                    startNodeSecondaryStyle={true}
                                    disconnectedNodes={disconnectedNodes}
                                ></FlowRenderer>
                                {openDrawer && (
                                    <div className={s.drawer}>
                                        <AddNode
                                            isFlowLive={isFlowLive}
                                            loading={loading}
                                            allowedTp={globalBackAllowedTps}
                                            additionalContainerOptions={true}
                                            editNode={editNode}
                                            isReadOnly={isReadOnly}
                                            rules={activeRules}
                                            rulesMeta={rulesMeta}
                                            setLoading={setLoading}
                                            onClose={() => {
                                                setOpenDrawer(false);
                                                setEditNode(null);
                                            }}
                                            onSave={async (data, type) => {
                                                if (type === "ADD") {
                                                    await handleAddNode(
                                                        data,
                                                        undefined,
                                                        false,
                                                        true
                                                    );
                                                } else if (type === "UPDATE") {
                                                    await resetEntitySubtypesOnChange(
                                                        editNode,
                                                        data,
                                                        localFlowRules,
                                                        setLocalFlowRules
                                                    );
                                                    await handleUpdateNode(
                                                        data
                                                    );
                                                } else {
                                                    await handleDeleteAndCreateNode(
                                                        data,
                                                        handleUpdateNode,
                                                        setOpenDrawer
                                                    );
                                                }
                                                setEditNode(null);
                                            }}
                                        ></AddNode>
                                    </div>
                                )}
                            </>
                        )}
                        {settingType === "Feature Group" && (
                            <>
                                <div
                                    className={`form-row ${s.templateWrapper}`}
                                >
                                    <p className={`headerTitle`}>
                                        Feature Group Details
                                    </p>
                                    <div
                                        className={`dp-parent headerTitleRequired maxChildContainer dp-parent-hor-space-between`}
                                    >
                                        Feature Group
                                        {templateId !== null && (
                                            <span
                                                className={s.editWrapper}
                                                onClick={() => {
                                                    setTimeout(() => {
                                                        templateOpenRef.current.open();
                                                        dispatch(
                                                            fetchTemplate(
                                                                templateId
                                                            )
                                                        );
                                                    });

                                                    setViewIsClick(true);
                                                }}
                                            >
                                                <Icon
                                                    icon={"View"}
                                                    size="18px"
                                                />
                                                Edit
                                            </span>
                                        )}
                                    </div>
                                    <p className="infoText">
                                        Feature Group for flow
                                    </p>
                                    <Select
                                        showSearch={true}
                                        showCreate={true}
                                        onCreate={item => {
                                            setCreatePhasePopup(true);
                                            templateOpenRef.current.open();
                                            setTimeout(() => {
                                                dispatch(
                                                    templateActions.setTemplateName(
                                                        item.name
                                                    )
                                                );
                                            });
                                        }}
                                        loading={templateLoader || loading}
                                        label={"Select Feature Group"}
                                        options={templates}
                                        keyField={"name"}
                                        onSelect={item => {
                                            setTemplateId(item?.id || null);
                                        }}
                                        idField={"id"}
                                        onLoadSelectReturn={false}
                                        selectedId={templateId}
                                        isDisabled={isReadOnly}
                                        closeIcon={true}
                                        CustomRender={option => (
                                            <SelectWithCloneOption
                                                option={option.option}
                                                selector="name"
                                                onClone={() => {
                                                    setTemplateCloneObj(
                                                        option.option
                                                    );
                                                    templateOpenRef.current.open();
                                                    dispatch(
                                                        fetchTemplate(
                                                            option.option.id
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                        portal={false}
                                    />
                                </div>

                                {templateId === null ? (
                                    <div className={s.noSelectionWrapper}>
                                        <div>
                                            <img src={noTemplate} />
                                        </div>
                                        <div className={s.selectonText}>
                                            No Selection yet!
                                        </div>
                                        <div className={s.content}>
                                            Looks like you haven’t selected any
                                            Feature Group yet.
                                            <br /> Click on the Feature Group
                                            dropdown to select or create a
                                            Feature Group
                                        </div>
                                    </div>
                                ) : (
                                    <div className={s.dataWrapper}>
                                        {!templateLoader ? (
                                            templates
                                                .filter(
                                                    obj => obj.id === templateId
                                                )
                                                .map((item, idx) => {
                                                    const itemKeys =
                                                        Object.keys(
                                                            item.properties
                                                        );

                                                    const isPResentKey =
                                                        TemplateKeys.some(
                                                            element =>
                                                                itemKeys.includes(
                                                                    element.name
                                                                )
                                                        );

                                                    return (
                                                        <>
                                                            <div
                                                                className={`form-row maxChildContainer`}
                                                            >
                                                                <p className="headerTitleRequired">
                                                                    ID*
                                                                </p>
                                                                <p>{item.id}</p>
                                                            </div>
                                                            <div
                                                                className={`form-row maxChildContainer`}
                                                            >
                                                                <p className="headerTitleRequired">
                                                                    Features
                                                                </p>
                                                                {itemKeys.map(
                                                                    (
                                                                        _,
                                                                        idx
                                                                    ) => {
                                                                        const titleName =
                                                                            TemplateKeys.filter(
                                                                                obj =>
                                                                                    obj.name ===
                                                                                    _
                                                                            );

                                                                        return (
                                                                            <>
                                                                                {titleName?.length >
                                                                                    0 && (
                                                                                    <div
                                                                                        className={
                                                                                            s.boxWrapper
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            className={
                                                                                                s.title
                                                                                            }
                                                                                        >
                                                                                            {titleName[0]
                                                                                                ?.opsName ||
                                                                                                _}
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                s.value
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                item
                                                                                                    ?.properties[
                                                                                                    _
                                                                                                ]
                                                                                                    ?.name
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        );
                                                                    }
                                                                )}

                                                                {!isPResentKey && (
                                                                    <div
                                                                        className={`${s.boxWrapper} ${s.noConfig}`}
                                                                    >
                                                                        {" "}
                                                                        No
                                                                        Feature
                                                                        keys
                                                                        selected{" "}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    );
                                                })
                                        ) : (
                                            <>
                                                <div
                                                    className={`form-row maxChildContainer`}
                                                >
                                                    <Skeleton
                                                        width={640}
                                                        height={60}
                                                    />
                                                </div>
                                                <div
                                                    className={`form-row maxChildContainer`}
                                                >
                                                    <Skeleton
                                                        width={640}
                                                        height={60}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                        {settingType === "Fallback Container" && (
                            <>
                                <div
                                    className={`form-row ${s.fallbackWrapper}`}
                                >
                                    <p className={`headerTitle`}>
                                        Container Fallback
                                    </p>
                                    <div
                                        className={`dp-parent headerTitleRequired maxChildContainer dp-parent-hor-space-between mb-20`}
                                    >
                                        <Button
                                            btnTheme={
                                                ButtonTypes.primaryHover_btn
                                            }
                                            onClick={() => {
                                                setShowContainerList(true);
                                            }}
                                            disable={settingLoader}
                                        >
                                            Select Container
                                        </Button>
                                    </div>

                                    <OutsideClickHandler
                                        onOutsideClick={() =>
                                            setShowContainerList(false)
                                        }
                                    >
                                        {showContainerList && (
                                            <div className={s.containerList}>
                                                <Select
                                                    label={
                                                        "Select container type"
                                                    }
                                                    showSearch={true}
                                                    options={collectionSelectList.filter(
                                                        item => {
                                                            let objectReturn = true;
                                                            if (
                                                                item.id ===
                                                                "OFFER"
                                                            ) {
                                                                fallBackContainerList.forEach(
                                                                    (
                                                                        element,
                                                                        idx
                                                                    ) => {
                                                                        if (
                                                                            element?.entitySubType ===
                                                                            item.id
                                                                        ) {
                                                                            if (
                                                                                element.hasOwnProperty(
                                                                                    "entityId"
                                                                                )
                                                                            ) {
                                                                                if (
                                                                                    !element.isActive
                                                                                )
                                                                                    objectReturn = true;
                                                                                else
                                                                                    objectReturn = false;
                                                                            } else {
                                                                                objectReturn = false;
                                                                            }
                                                                        }
                                                                    }
                                                                );

                                                                if (
                                                                    objectReturn
                                                                )
                                                                    return item;
                                                            }
                                                        }
                                                    )}
                                                    keyField={"name"}
                                                    onSelect={item => {
                                                        const typeAlreadyExist =
                                                            fallBackContainerList.some(
                                                                _ =>
                                                                    _.entitySubType ===
                                                                    item.id
                                                            );

                                                        if (typeAlreadyExist) {
                                                            let copyArray =
                                                                JSON.parse(
                                                                    JSON.stringify(
                                                                        fallBackContainerList
                                                                    )
                                                                );
                                                            copyArray.forEach(
                                                                _ => {
                                                                    if (
                                                                        _.entitySubType ===
                                                                        item.id
                                                                    ) {
                                                                        _.isActive = true;
                                                                        delete _.entityId;
                                                                    }
                                                                }
                                                            );

                                                            setFallbackContainerList(
                                                                copyArray
                                                            );
                                                        } else {
                                                            setFallbackContainerList(
                                                                [
                                                                    ...fallBackContainerList,
                                                                    {
                                                                        entitySubType:
                                                                            item.id,
                                                                        entityType:
                                                                            "CONTAINER",
                                                                    },
                                                                ]
                                                            );
                                                        }

                                                        setShowContainerList(
                                                            false
                                                        );
                                                    }}
                                                    idField={"id"}
                                                    onLoadSelectReturn={false}
                                                    isSelectOpen={true}
                                                />
                                            </div>
                                        )}
                                    </OutsideClickHandler>

                                    {fallBackContainerList?.length > 0 &&
                                        fallBackContainerList.map(
                                            (element, idx) => {
                                                if (element?.isActive === false)
                                                    return null;
                                                return (
                                                    <div
                                                        className={
                                                            s.cardWrapper
                                                        }
                                                        index={idx}
                                                    >
                                                        <div
                                                            className={
                                                                s.deleteIconWrapper
                                                            }
                                                            onClick={() => {
                                                                let copyFallBack =
                                                                    JSON.parse(
                                                                        JSON.stringify(
                                                                            fallBackContainerList
                                                                        )
                                                                    );

                                                                let getData =
                                                                    copyFallBack.find(
                                                                        _ =>
                                                                            _?.entitySubType ===
                                                                            element?.entitySubType
                                                                    );

                                                                if (
                                                                    getData?.hasOwnProperty(
                                                                        "id"
                                                                    )
                                                                ) {
                                                                    copyFallBack.find(
                                                                        _ =>
                                                                            _?.entitySubType ===
                                                                            element?.entitySubType
                                                                    ).isActive = false;
                                                                } else {
                                                                    copyFallBack =
                                                                        copyFallBack.filter(
                                                                            _ =>
                                                                                _?.entitySubType !==
                                                                                element?.entitySubType
                                                                        );
                                                                }

                                                                setFallbackContainerList(
                                                                    copyFallBack
                                                                );
                                                            }}
                                                        >
                                                            <Tooltip
                                                                message={`Delete`}
                                                                position="top"
                                                            >
                                                                <Icon
                                                                    icon={
                                                                        "Delete"
                                                                    }
                                                                    enableHover={
                                                                        true
                                                                    }
                                                                    size="16px"
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                        <div
                                                            className={
                                                                s.containerTypeWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    s.label
                                                                }
                                                            >
                                                                Container :
                                                            </div>
                                                            <div>
                                                                {
                                                                    collectionSelectList.filter(
                                                                        item =>
                                                                            item.id ===
                                                                            element?.entitySubType
                                                                    )[0]?.name
                                                                }
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                s.fallbackContainerDropdownWrapper
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    s.label
                                                                }
                                                            >
                                                                Fallback
                                                                Container*
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    label={
                                                                        element?.entityId
                                                                            ? ""
                                                                            : "Select Container"
                                                                    }
                                                                    loading={
                                                                        !containers.length
                                                                    }
                                                                    options={containers
                                                                        ?.filter(
                                                                            _ =>
                                                                                _?.type ===
                                                                                element?.entitySubType
                                                                        )
                                                                        ?.map(
                                                                            _ => {
                                                                                return {
                                                                                    ..._,
                                                                                    name:
                                                                                        _.id +
                                                                                        ": " +
                                                                                        _.name,
                                                                                };
                                                                            }
                                                                        )}
                                                                    selectedId={
                                                                        element?.entityId ||
                                                                        null
                                                                    }
                                                                    keyField={
                                                                        "name"
                                                                    }
                                                                    idField={
                                                                        "id"
                                                                    }
                                                                    onSelect={async e => {
                                                                        let fallBackCopy =
                                                                            JSON.parse(
                                                                                JSON.stringify(
                                                                                    fallBackContainerList
                                                                                )
                                                                            );
                                                                        if (
                                                                            e?.id !==
                                                                            element?.entityId
                                                                        ) {
                                                                            setSettingLoader(
                                                                                true
                                                                            );

                                                                            let getTypeData =
                                                                                fallBackCopy.find(
                                                                                    item =>
                                                                                        item?.entitySubType ===
                                                                                        element?.entitySubType
                                                                                );
                                                                            if (
                                                                                getTypeData.hasOwnProperty(
                                                                                    "id"
                                                                                )
                                                                            ) {
                                                                                fallBackCopy.forEach(
                                                                                    _ => {
                                                                                        if (
                                                                                            _?.entitySubType ===
                                                                                            element?.entitySubType
                                                                                        ) {
                                                                                            _.entityId =
                                                                                                e.id;
                                                                                            _.isActive = true;
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                            let data =
                                                                                [];
                                                                            if (
                                                                                !getTypeData.hasOwnProperty(
                                                                                    "id"
                                                                                )
                                                                            ) {
                                                                                data =
                                                                                    await handleAddNode(
                                                                                        {
                                                                                            entityType:
                                                                                                "CONTAINER",
                                                                                            entitySubType:
                                                                                                element.entitySubType,
                                                                                            entityId:
                                                                                                e.id,
                                                                                            ...(fallBackContainerId !==
                                                                                                null && {
                                                                                                ruleSetId:
                                                                                                    fallBackContainerId,
                                                                                            }),
                                                                                        },
                                                                                        undefined,
                                                                                        true,
                                                                                        false,
                                                                                        false
                                                                                    );

                                                                                let dataCopy =
                                                                                    JSON.parse(
                                                                                        JSON.stringify(
                                                                                            data
                                                                                        )
                                                                                    );

                                                                                dataCopy.forEach(
                                                                                    element => {
                                                                                        element.isActive = true;
                                                                                    }
                                                                                );

                                                                                fallBackCopy.forEach(
                                                                                    (
                                                                                        _,
                                                                                        idx
                                                                                    ) => {
                                                                                        if (
                                                                                            _?.entitySubType ===
                                                                                            element?.entitySubType
                                                                                        ) {
                                                                                            fallBackCopy[
                                                                                                idx
                                                                                            ] =
                                                                                                dataCopy[0];
                                                                                        }
                                                                                    }
                                                                                );

                                                                                setFallbackContainerList(
                                                                                    [
                                                                                        ...fallBackCopy,
                                                                                    ]
                                                                                );
                                                                            } else {
                                                                                setFallbackContainerList(
                                                                                    [
                                                                                        ...fallBackCopy,
                                                                                    ]
                                                                                );
                                                                            }
                                                                            setTimeout(
                                                                                () => {
                                                                                    setSettingLoader(
                                                                                        false
                                                                                    );
                                                                                },
                                                                                1000
                                                                            );

                                                                            if (
                                                                                fallBackContainerId ===
                                                                                null
                                                                            ) {
                                                                                setFallBackContainerId(
                                                                                    data[0]
                                                                                        ?.ruleSetId
                                                                                );
                                                                            }
                                                                        }
                                                                    }}
                                                                    onLoadSelectReturn={
                                                                        false
                                                                    }
                                                                    showSearch={
                                                                        true
                                                                    }
                                                                    isDisabled={
                                                                        settingLoader
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                </div>
                            </>
                        )}
                        {settingType === "Litigator Rules" && (
                            <>
                                <div className={s.topActionBar}>
                                    <FlowActionList
                                        actions={litigatorHeaderActionList}
                                        actionCallback={type => {
                                            switch (type) {
                                                case "UNDO":
                                                    undoLitigatorRules();
                                                    break;
                                                case "REDO":
                                                    redoLitigatorRules();
                                                    break;
                                                case "TIDY":
                                                    setAutoLayout(_ => !_);
                                                    break;
                                            }
                                        }}
                                    ></FlowActionList>
                                </div>
                                <FlowRenderer
                                    ref={litigatorFlowRef}
                                    containerAsLastNode
                                    hideExpandCollapse
                                    showDummyStartNode
                                    startNodeSecondaryStyle
                                    startNodeText="Litigator Rules"
                                    readOnly={isReadOnly}
                                    undo={undoLitigatorRules}
                                    redo={redoLitigatorRules}
                                    startId={localLitigatorStartId}
                                    containers={containers}
                                    rules={activeLitigatorRules}
                                    autoLayout={autoLayout}
                                    openNode={node => {
                                        setOpenDrawer(true);
                                        setEditNode(node);
                                    }}
                                    openPreview={node => {
                                        setBackupRules(
                                            JSON.parse(
                                                JSON.stringify(
                                                    localLitigatorRules
                                                )
                                            )
                                        );
                                        setEditNode(node);
                                        previewSidePanelRef?.current?.open();
                                    }}
                                    deleteNode={deleteNode}
                                    editNode={editNode}
                                    filteredRules={[]}
                                    statsMap={{}}
                                ></FlowRenderer>
                                {openDrawer && (
                                    <>
                                        <div className={s.drawer}>
                                            <AddNode
                                                isFlowLive={isFlowLive}
                                                loading={loading}
                                                setLoading={setLoading}
                                                nodeOptions={["CONTAINER"]}
                                                editNode={editNode}
                                                isReadOnly={isReadOnly}
                                                rules={localLitigatorRules}
                                                onClose={() => {
                                                    setOpenDrawer(false);
                                                    setEditNode(null);
                                                }}
                                                onSave={async (data, type) => {
                                                    if (type === "ADD") {
                                                        await handleAddNode(
                                                            data,
                                                            undefined,
                                                            false,
                                                            false
                                                        );
                                                    } else if (
                                                        type === "UPDATE"
                                                    ) {
                                                        await resetEntitySubtypesOnChange(
                                                            editNode,
                                                            data,
                                                            localLitigatorRules,
                                                            setLocalLitigatorRules
                                                        );
                                                        await handleUpdateNode(
                                                            data
                                                        );
                                                    }
                                                    setEditNode(null);
                                                }}
                                            ></AddNode>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
                <SidePanelInfinity
                    ref={templateOpenRef}
                    title={`${
                        templateCloneObj
                            ? "Clone & Select"
                            : viewIsClick
                            ? "Edit"
                            : "Create"
                    } Feature Group`}
                    saveButtonText={
                        templateCloneObj
                            ? "Confirm"
                            : viewIsClick
                            ? "Save"
                            : "Create"
                    }
                    onSave={async () => {
                        if (templateCloneObj) {
                            templateOpenRef?.current?.close();
                            setLoading(true);
                            const clonedItem = await dispatch(
                                cloneTemplate(templateCloneObj.id)
                            ).then(res => res.payload);

                            if (clonedItem) {
                                queryClient.removeQueries("templateListData", {
                                    exact: true,
                                });
                                setTemplateId(clonedItem?.id);
                                dispatch(
                                    toastListActions.setToastList({
                                        type: "Success",
                                        message:
                                            "Feature Group cloned successfully.",
                                        autoClose: 5000,
                                    })
                                );
                            }
                            setLoading(false);
                        } else {
                            let saveSuccessfulFlag =
                                await saveFunctionCallRef.current.onSaveHandler();
                            if (saveSuccessfulFlag) {
                                templateOpenRef.current.close();
                                setViewIsClick(false);
                                setCreatePhasePopup(false);
                            }
                        }
                    }}
                    onClose={() => {
                        setViewIsClick(false);
                        setCreatePhasePopup(false);
                        setTemplateCloneObj(null);
                    }}
                    disableSaveBtn={
                        isReadOnly ||
                        createTemplateState.loading ||
                        createTemplateState.mainLoader ||
                        !isFlowLive
                    }
                    children={
                        <CreateTemplate
                            editPhaseInPopUp={
                                viewIsClick || templateCloneObj ? true : false
                            }
                            ref={saveFunctionCallRef}
                            showInPopup={true}
                            createPhaseInPopup={createPhasePopup}
                            newTemplateCreatedId={id => {
                                setTemplateId(id);
                            }}
                            isClonePanel={templateCloneObj}
                        />
                    }
                ></SidePanelInfinity>
                <SidePanelInfinity
                    onSave={!isReadOnly && previewPanelSave}
                    onClose={() => {
                        setPreviewPanelTempRules([]);
                        if (backupRules?.length > 0) {
                            const ruleSetter =
                                settingType === "Litigator Rules"
                                    ? setLocalLitigatorRules
                                    : setLocalFlowRules;
                            ruleSetter(backupRules);
                        }
                        setBackupRules([]);
                        setEditNode(null);
                    }}
                    title={"Details"}
                    ref={previewSidePanelRef}
                    width={"900px"}
                    noContentPadding={true}
                    saveButtonText="Confirm"
                    disableSaveBtn={
                        isReadOnly ||
                        detailsAddNodeCompleted ||
                        containers.find(_ => _.id === editNode?.entityId)?.items
                            ?.length === 0
                    }
                >
                    <ContainerPreview
                        vertical={flowVertical}
                        id={editNode?.entityId}
                        featureGroupProperties={featureGroupProperties}
                        containers={containers}
                        collections={collections}
                        rules={[
                            ...((settingType === "Litigator Rules"
                                ? activeLitigatorRules
                                : activeRules
                            )?.filter(
                                rule =>
                                    !previewPanelTempRules
                                        ?.map(_ => _.id)
                                        .includes(rule.id)
                            ) || []),
                            ...previewPanelTempRules,
                        ]}
                        startId={editNode?.nextSetId}
                        deleteNode={rule => {
                            if (previewPanelTempRules.includes(rule)) {
                                rule.isActive = false;
                                setPreviewPanelTempRules(_ => [
                                    ..._.filter(_ => _.id !== rule.id),
                                ]);
                            } else {
                                deleteNode(rule);
                            }
                        }}
                        addNode={async rule => {
                            let newNode = rule;
                            if (!newNode.id) {
                                setDetailsAddNodeCompleted(true);
                                const createdNode = await customFetch(
                                    MAIN.SAVE_NODE,
                                    "POST",
                                    newNode
                                );
                                newNode = createdNode?.data?.data;
                                if (!isEmpty(newNode)) {
                                    setDetailsAddNodeCompleted(false);
                                }
                            }
                            //Use oldTargetting values and update the nodes only when value changes
                            let oldTargetting = (
                                settingType === "Litigator Rules"
                                    ? activeLitigatorRules
                                    : activeRules
                            )?.filter(_ => _.id === newNode.id);

                            if (
                                !newNode.id ||
                                !isArrayEqual(
                                    newNode?.targettingParams?.value,
                                    !isEmpty(oldTargetting) &&
                                        oldTargetting[0]?.targettingParams
                                            ?.value
                                )
                            ) {
                                //If newnode added then add newNode at end
                                if (
                                    !(
                                        settingType === "Litigator Rules"
                                            ? activeLitigatorRules
                                            : activeRules
                                    )?.find(_ => _.id === newNode.id)
                                ) {
                                    setPreviewPanelTempRules(_ =>
                                        [
                                            ..._.filter(
                                                _ => _.id !== newNode.id
                                            ),
                                            newNode,
                                        ].sort((a, b) => a.id - b.id)
                                    );
                                } else {
                                    let newRules = [];
                                    if (
                                        previewPanelTempRules?.find(
                                            _ => _.id === newNode.id
                                        )
                                    ) {
                                        newRules = previewPanelTempRules.map(
                                            element =>
                                                element.id === newNode.id
                                                    ? {
                                                          ...newNode,
                                                      }
                                                    : element
                                        );
                                        newRules = [
                                            ...(settingType ===
                                            "Litigator Rules"
                                                ? activeLitigatorRules
                                                : activeRules),
                                            ...newRules,
                                        ];
                                    } else {
                                        newRules = (
                                            settingType === "Litigator Rules"
                                                ? activeLitigatorRules
                                                : activeRules
                                        )?.map(element =>
                                            element.id === newNode.id
                                                ? {
                                                      ...newNode,
                                                  }
                                                : element
                                        );
                                        newRules = [
                                            ...previewPanelTempRules,
                                            ...newRules,
                                        ];
                                    }
                                    //Getting unique rules
                                    newRules = [
                                        ...newRules
                                            .reduce((a, b) => {
                                                a.set(b.id, b);
                                                return a;
                                            }, new Map())
                                            .values(),
                                    ].sort((a, b) => a.id - b.id);
                                    setPreviewPanelTempRules(newRules);
                                }
                            }
                            setLoading(false);
                        }}
                        readOnly={isReadOnly}
                        loading={loading}
                        setLoading={setLoading}
                    ></ContainerPreview>
                </SidePanelInfinity>
            </>
        );
    }
);

export default Settings;
