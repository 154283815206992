import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UAC } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { dynamicSort } from "../../utils/globalUtils";

const initialState = {
    menulinks: [],
    showMenu: true,
};

export const fetchMenu = createAsyncThunk(
    "menulinks",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(`${UAC.MENU}`, "GET", {});

        response.data.sort(dynamicSort("order"));
        return response;
    }
);

const menulinksSlice = createSlice({
    name: "links",
    initialState,
    reducers: {
        setMenulinks(state, action) {
            state.menulinks = action.payload;
        },
        setShowMenu(state, action) {
            state.showMenu = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchMenu.fulfilled, (state, action) => {
            state.menulinks = action.payload.data;
        });
    },
});

export const menulinksActions = menulinksSlice.actions;
export default menulinksSlice.reducer;
