import Input from "../../Shared/Input/Input";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import React, { useState, useRef, useEffect } from "react";
import { checkIfEnterKey } from "../../utils/globalUtils";
import s from "./KeyValueAdd.module.scss";
import { stubFalse } from "lodash";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";

const KeyValueAdd = ({
    onNewTag,
    keyLabel,
    valueLabel,
    refElements = false,
    readOnly = false,
    toggleLabel,
    toggleKey,
}) => {
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [toggleValue, setToggleValue] = useState(true);
    const keyRef = useRef(null);
    const valueRef = useRef(null);

    const addKeyValue = () => {
        const valueValidate = valueRef.current.validate();
        const keyValidate = keyRef.current.validate();
        if (!valueValidate) valueRef.current.focus();
        if (!keyValidate) keyRef.current.focus();
        if (valueValidate && keyValidate) {
            let newItem = {
                key,
                value,
                id: new Date().getTime(),
            };
            if (toggleKey) newItem[toggleKey] = toggleValue;
            if (typeof onNewTag === "function") onNewTag(newItem);
            setKey("");
            setValue("");
            keyRef.current.focus();
        }
    };
    const checkSubmit = e => {
        if (checkIfEnterKey(e)) addKeyValue();
    };
    useEffect(() => {
        if (refElements) {
            keyRef.current.validate();
            valueRef.current.validate();
        }
    }, [refElements]);
    return (
        <>
            <div className="table">
                <div className="tableHeader">
                    <div className="tableRow">
                        <div className="tableCell">
                            <div className="tableCellChild">
                                {keyLabel || "Key"}
                            </div>
                        </div>
                        <div className="tableCell">
                            <div className="tableCellChild">
                                {valueLabel || "Value"}
                            </div>
                        </div>
                        {toggleLabel && (
                            <div className="tableCell">
                                <div className="tableCellChild">
                                    {toggleLabel || "Toggle"}
                                </div>
                            </div>
                        )}
                        <div className="tableCell"></div>
                    </div>
                </div>
                <div className="tableBody">
                    <div className="tableRow">
                        <div className={`tableCell ${s.customTableCell}`}>
                            <div className="tableCellChild">
                                <Input
                                    value={key}
                                    placeholder={`Enter ${keyLabel || "Key"}`}
                                    ref={keyRef}
                                    onKeyPress={checkSubmit}
                                    onChange={e => setKey(e.target.value)}
                                    required={true}
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                        <div className={`tableCell ${s.customTableCell}`}>
                            <div className="tableCellChild">
                                <Input
                                    value={value}
                                    placeholder={`Enter ${
                                        valueLabel || "Value"
                                    }`}
                                    ref={valueRef}
                                    onKeyPress={checkSubmit}
                                    onChange={e => setValue(e.target.value)}
                                    required={true}
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                        {toggleKey && (
                            <div className={`tableCell`}>
                                <div className="tableCellChild pt-20">
                                    <ToggleSwitch
                                        isToggleOn={toggleValue}
                                        setIsToggleOn={setToggleValue}
                                        disabled={readOnly}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={`tableCell ${s.customTableCell}`}>
                            <div className="tableCellChild">
                                <Button
                                    btnTheme={ButtonTypes.primaryHover_btn}
                                    label="Add"
                                    onClick={addKeyValue}
                                    disable={readOnly}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default KeyValueAdd;
