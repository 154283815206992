import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({
    component: Component,
    path: path,
    module,
    ...restOfProps
}) => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const user = useSelector(state => state.auth.user);

    const checkPerms = (comp, props) => {
        if (!module) {
            return comp;
        } else {
            if (
                module &&
                user &&
                user.modules &&
                user.modules?.indexOf(`${module}.Read`) > -1
            ) {
                return comp;
            } else {
                return (
                    <Redirect
                        to={{
                            pathname: "/403",
                            state: { from: props.location },
                        }}
                    />
                );
            }
        }
    };

    return (
        <Route
            render={props =>
                isLoggedIn && Component ? (
                    checkPerms(<Component {...props} path={path} />, props)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
            {...restOfProps}
        />
    );
};

export default ProtectedRoute;
