import React, { useEffect, useState } from "react";
import styles from "./ToggleSwitch.module.scss";

const ToggleSwitch = ({
    isToggleOn,
    setIsToggleOn,
    setText,
    children,
    disabled = false,
    customEnabledText,
    customDisabledText,
}) => {
    const [localToggleOn, setLocalToggleOn] = useState(isToggleOn);

    useEffect(() => {
        setLocalToggleOn(isToggleOn);
    }, [isToggleOn]);

    const handleClick = () => {
        if (!disabled) {
            setIsToggleOn(!isToggleOn);
            setLocalToggleOn(!isToggleOn);
        }
    };

    return (
        <div
            className={`${
                styles.toggleSwitchLayer
            } dp-parent dp-parent-ver-center ${
                disabled ? styles.disabled : ""
            }`}
        >
            <div
                onClick={handleClick}
                className={`${styles.ToggleSwitch} ${
                    localToggleOn ? `${styles.active}` : ""
                }`}
            >
                <div
                    className={
                        localToggleOn
                            ? `${styles.knob} ${styles.active}`
                            : `${styles.knob}`
                    }
                />
            </div>
            {setText && (
                <div
                    className={`${styles.toggleText} ${
                        localToggleOn ? styles.active : ""
                    }`}
                >
                    {!children
                        ? localToggleOn
                            ? customEnabledText || "Enabled"
                            : customDisabledText || "Disabled"
                        : children}
                </div>
            )}
        </div>
    );
};

export default ToggleSwitch;
