import React, { useEffect, useState, useRef } from "react";
import styles from "./GratificationDetails.module.scss";
import { cloneDeep, get, isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { toastListActions } from "../../store/toastList/toastList";
import { REGISTER_DETAIL_DROPDOWN } from "../../constants/collectionConstants";
import { useSelector } from "react-redux";
import { collectionActions } from "../../store/flowmanager/collection";
import SidePanelInfinity from "../../Shared/SidePanelInfinity/SidePanelInfinity";
import { useParams } from "react-router-dom";
import { arrayMove } from "@dnd-kit/sortable";
import CreateDealOfferWallItem from "../../Pages/CreateDealOfferWallItem/CreateDealOfferWallItem";
import {
    collectionDealsActions,
    fetchDealOfferWallList,
} from "../../store/listings/collectionDeals";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CreateAdUnit from "../../Pages/CreateAdUnit/CreateAdUnits";
import Select from "../../Shared/Select/Select";
import {
    adUnitsActions,
    updateAdUnitDetails,
    addAdUnitDetails,
} from "../../store/listings/adUnits";
import AddOfferWallWithPriority from "../../components/AddOfferWallWithPriority/AddOfferWallWithPriority";

const GratificationDetails = ({
    isReadOnly = false,
    isSidePanel = false,
    adUnitError = false,
    adUnitId,
    handleAdUnit,
    setAdUnitError,
}) => {
    const dispatch = useDispatch();
    const configuration = null;
    const collectionState = useSelector(state => state.flowmanager.collection);
    const items = useSelector(
        state => state.flowmanager.collection.createCollection.items
    );
    const { isDarkMode } = useSelector(state => state.globalConfig);
    const createCollectionState = get(collectionState, "createCollection", []);

    const urlParams = useParams();
    const [activeDetail, setActiveDetail] = useState({});
    const { mainLoader } = useSelector(state => state.listings.offerPaths);
    const allAdUnitList = useSelector(state => state.listings.adUnits.list);
    const [isCreate, setIsCreate] = useState(false);
    const [adId, setAdId] = useState("");

    const getGratifcationType = get(
        createCollectionState,
        "gratificationType",
        "AdUnit"
    );
    const offerWallSlots = useSelector(
        state => state.flowmanager.collection.createCollection.offerWallSlots
    );
    const offerWallList = useSelector(
        state => state.listings.collectionDeals.offerWallList
    );

    useEffect(() => {
        if (!isEmpty(allAdUnitList) && adUnitId) {
            let selectedAdUnit = allAdUnitList.find(
                _ => _.adUnitId === adUnitId
            );
            setAdId(selectedAdUnit?.id);
        }
    }, [allAdUnitList, adUnitId]);

    useEffect(() => {
        if (isEmpty(activeDetail)) {
            setActiveDetail(items[0]);
        }
        if (isEmpty(offerWallList)) {
            dispatch(fetchDealOfferWallList());
        }
    }, [getGratifcationType]);

    useEffect(() => {
        items.length && assignRank(items);
    }, [items.length]);

    useEffect(() => {
        if (!isEmpty(activeDetail)) {
            const itemToUpdate = items?.findIndex(
                _ => _.id === activeDetail.id
            );
            setActiveDetail(items[itemToUpdate]);
        }
    }, [items]);

    const assignRank = data => {
        let initialData = cloneDeep(data);
        let newItems = [];
        let rank = 0;
        initialData?.forEach(_ => {
            if (_.rank !== -1) {
                newItems.push({
                    ..._,
                    rank: rank + 1,
                });
                rank++;
            } else {
                newItems.push({
                    ..._,
                    rank: -1,
                });
            }
        });
        dispatch(collectionActions.setItems(newItems));
    };

    const sidePanelRef = useRef();
    const editDetailsRef = useRef();
    const createAdUnitRef = useRef(null);

    const onAddItem = () => {
        const isValid =
            !isEmpty(activeDetail.properties?.offerWallId) &&
            activeDetail.properties?.minDeals;
        if (isValid) {
            dispatch(
                collectionActions.setItems(
                    JSON.parse(JSON.stringify(items)).map(_ => {
                        if (_.rank === activeDetail.rank) {
                            _.tcpa = activeDetail.tcpa;
                            _.properties = activeDetail.properties;
                            _.hnpLeadIds = activeDetail.hnpLeadIds;
                            _.layoutConfig = null;
                            _.layoutConfigCopy = null;
                        }
                        return _;
                    })
                )
            );
        } else {
            if (isEmpty(activeDetail?.properties?.offerWallId))
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Please add at least one offer wall slot",
                    })
                );
            if (isEmpty(activeDetail?.properties?.minDeals))
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Please enter minimum deals",
                    })
                );
            return;
        }
        setActiveDetail({});
        sidePanelRef.current.close();
    };

    const cancelAddItem = () => {
        setActiveDetail({});
    };

    const onChangeDealCategory = data => {
        setActiveDetail({
            ...activeDetail,
            properties: {
                ...activeDetail.properties,
                category: data?.name,
            },
        });
    };

    const onChangeMinimumDeals = e => {
        setActiveDetail({
            ...activeDetail,
            properties: {
                ...activeDetail.properties,
                minDeals: e.target.value,
            },
        });
    };

    const onSetSlots = data => {
        setActiveDetail({
            ...activeDetail,
            properties: {
                offerWallId: data,
            },
        });
        dispatch(collectionActions.setOfferWallSlots(data));
    };

    const onChangeGratificationType = async val => {
        if (val === "OfferWall") {
            onSetSlots(offerWallSlots);
            if (isEmpty(offerWallList)) dispatch(fetchDealOfferWallList());
        }
        await dispatch(collectionActions.setGratificationType(val));
    };

    const onSelectAdUnit = val => {
        setIsCreate(false);
        setAdUnitError(false);
        handleAdUnit(val);
    };

    const createNewAdUnit = async () => {
        let isValid = await createAdUnitRef.current.validateAllFields();

        if (isValid) {
            dispatch(adUnitsActions.setMainLoader(true));
            dispatch(addAdUnitDetails())
                .unwrap()
                .then(async response => {
                    if (response) {
                        await dispatch(adUnitsActions.setMainLoader(false));
                        await dispatch(
                            toastListActions.setToastList({
                                type: "Success",
                                message: "Created Ad Unit successfully",
                            })
                        );
                        await editDetailsRef.current.close();
                        await setIsCreate(false);
                    }
                });
        }
    };

    const updateAdUnit = async id => {
        let isValid = await createAdUnitRef.current.validateAllFields();
        if (isValid) {
            dispatch(adUnitsActions.setMainLoader(true));
            dispatch(updateAdUnitDetails(id))
                .unwrap()
                .then(async response => {
                    if (response) {
                        dispatch(adUnitsActions.setMainLoader(false));
                        dispatch(
                            toastListActions.setToastList({
                                type: "Success",
                                message: "Updated Ad Unit Successfully",
                            })
                        );
                        await editDetailsRef.current.close();
                    }
                });
        }
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={!isSidePanel ? "flexHeightTab" : ""}
        >
            <div className={`row`}>
                <p className={`headerTitle mb-0`}>{"Details"}</p>
            </div>
            <div className={`row`}>
                <p className={`headerTitleRequired`}>Type*</p>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={getGratifcationType}
                        onChange={val => {
                            onChangeGratificationType(val.target.value);
                        }}
                    >
                        <FormControlLabel
                            value="AdUnit"
                            control={
                                <Radio
                                    sx={{
                                        color: "#CAD1D4",
                                        "&.Mui-checked": {
                                            color: "#41c3a9",
                                        },
                                        "&.Mui-disabled": {
                                            color: "#a8aeb1",
                                            cursor: "not-allowed",
                                            pointerEvents: "auto",
                                        },
                                    }}
                                />
                            }
                            label="Ad Unit"
                            classes={{
                                label: `${
                                    isDarkMode
                                        ? styles.typeDisabled
                                        : styles.typeLabel
                                }`,
                            }}
                            sx={{
                                "&.Mui-disabled": {
                                    color: "#a8aeb1",
                                    cursor: "not-allowed",
                                    pointerEvents: "auto",
                                },
                            }}
                        />
                        <FormControlLabel
                            value="OfferWall"
                            control={
                                <Radio
                                    sx={{
                                        color: "#CAD1D4",
                                        "&.Mui-checked": {
                                            color: "#41c3a9",
                                        },
                                        "&.Mui-disabled": {
                                            color: "#a8aeb1",
                                            cursor: "not-allowed",
                                            pointerEvents: "auto",
                                        },
                                    }}
                                />
                            }
                            label="Offer Wall"
                            classes={{
                                label: `${
                                    isDarkMode
                                        ? styles.typeDisabled
                                        : styles.typeLabel
                                }`,
                            }}
                            sx={{
                                "&.Mui-disabled": {
                                    color: "#a8aeb1",
                                    cursor: "not-allowed",
                                    pointerEvents: "auto",
                                },
                            }}
                        />
                    </RadioGroup>
                </FormControl>
            </div>

            {getGratifcationType === "AdUnit" && (
                <div>
                    <div className={`row`}>
                        <div className={styles.heading}>
                            <p className={`headerTitleRequired`}>Name*</p>

                            <p
                                className={`${styles.editLink} ${
                                    adId && !isEmpty(allAdUnitList)
                                        ? styles.enableLink
                                        : styles.disabledLink
                                }`}
                                onClick={() => {
                                    adId && !isEmpty(allAdUnitList)
                                        ? editDetailsRef?.current?.open()
                                        : null;
                                }}
                            >
                                Edit
                            </p>
                        </div>
                        <p className={`infoText`}>
                            Ad units for collection type gratification
                        </p>
                        {
                            <div className={`dp-parent`}>
                                <div className={`dropdownLayer`}>
                                    <Select
                                        label={"Select Ad Unit"}
                                        options={allAdUnitList.map(_ => {
                                            return {
                                                ..._,
                                                name: _.id + ": " + _.name,
                                            };
                                        })}
                                        keyField="name"
                                        showSearch={true}
                                        placeholder={"Search Ad Unit"}
                                        onSelect={onSelectAdUnit}
                                        onLoadSelectReturn={false}
                                        showCreate={false}
                                        selectedId={adUnitId ? adUnitId : null}
                                        idField="adUnitId"
                                        isError={adUnitError}
                                        isDisabled={isReadOnly}
                                        position="top"
                                        loading={isEmpty(allAdUnitList)}
                                    />
                                </div>
                            </div>
                        }
                        <SidePanelInfinity
                            title={`${adUnitId ? "Edit" : "Create"} Ad Unit`}
                            width={"95%"}
                            ref={editDetailsRef}
                            onSave={() => {
                                adId ? updateAdUnit(adId) : createNewAdUnit();
                            }}
                            disableSaveBtn={isReadOnly || mainLoader}
                            children={
                                <div className={styles.adUnitWrapper}>
                                    {adUnitId && !isCreate ? (
                                        <CreateAdUnit
                                            id={adId}
                                            isSidePanel={true}
                                            ref={createAdUnitRef}
                                        />
                                    ) : (
                                        <CreateAdUnit
                                            id={null}
                                            isSidePanel={true}
                                            ref={createAdUnitRef}
                                        />
                                    )}
                                </div>
                            }
                        ></SidePanelInfinity>
                    </div>
                </div>
            )}

            {getGratifcationType === "OfferWall" && (
                <div>
                    <AddOfferWallWithPriority
                        action={collectionDealsActions}
                        isReadOnly={isReadOnly}
                        data={activeDetail}
                        onSetSlots={onSetSlots}
                        tableCustomClass={styles.gratificationOfferTable}
                        leftPopUpPosition={"324px"}
                        items={[activeDetail]}
                    />
                </div>
            )}

            <SidePanelInfinity
                width={"890px"}
                title={"Edit Item"}
                ref={sidePanelRef}
                disableSaveBtn={isReadOnly}
                onSave={onAddItem}
                onClose={cancelAddItem}
            >
                <div>
                    <CreateDealOfferWallItem
                        isSidePanel
                        onChangeDealCategory={onChangeDealCategory}
                        onChangeMinimumDeals={onChangeMinimumDeals}
                        data={activeDetail}
                        action={collectionDealsActions}
                        onSetSlots={onSetSlots}
                    />
                </div>
            </SidePanelInfinity>
        </div>
    );
};

export default GratificationDetails;
