import c from "./AccordionContainer.scss";
const AccordionContainer = ({ children, extraClass }) => {
    return (
        <div className={`${c.accord_child} ${extraClass ? extraClass : ""}`}>
            {children}
        </div>
    );
};

export default AccordionContainer;
