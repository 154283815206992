import React, { useEffect, useState } from "react";
import s from "./AppNavigator.module.scss";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderAccordian from "./HeaderAccordian/HeaderAccordian";
import logo from "../../assets/img/logo.svg";
import logodarkMode from "../../assets/img/logo-darkMode.svg";
import Icon from "../../Shared/Icon/Icon";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import OutsideClickHandler from "react-outside-click-handler";
import { globalConfigActions } from "../../store/globalConfig/globalConfig";
import { menulinksActions } from "../../store/menulinks/links";
import CustomTooltipRenderer from "../Renderers/CustomTooltipRenderer/CustomTooltipRenderer";

const AppNavigator = () => {
    const linkData = useSelector(state => state.menulinks.links.menulinks);
    // console.log(linkData);
    // const linkData = [
    //     {
    //         subMenu: [
    //             {
    //                 text: "Surveys",
    //                 module: "SurveyManager.Survey",
    //                 order: 1,
    //                 path: "/surveys/listings",
    //                 isProd: true,
    //                 isStaging: true,
    //             },
    //             {
    //                 path: "/surveypaths/listings",
    //                 text: "Survey Paths",
    //                 order: "2",
    //                 isStaging: true,
    //                 module: "SurveyManager.SurveyPath",
    //                 isProd: true,
    //             },
    //             {
    //                 isProd: true,
    //                 isStaging: true,
    //                 path: "/questions/listings",
    //                 text: "Questions",
    //                 order: 3,
    //                 module: "SurveyManager.Question",
    //             },
    //             {
    //                 isStaging: true,
    //                 path: "/offerpaths/listings",
    //                 module: "SurveyManager.OfferPath",
    //                 order: 4,
    //                 isProd: true,
    //                 text: "Offer Paths",
    //             },
    //             {
    //                 order: "5",
    //                 isProd: true,
    //                 isStaging: true,
    //                 text: "Ad Units",
    //                 path: "/adunits/listings",
    //                 module: "SurveyManager.AdUnit",
    //             },
    //             {
    //                 order: 6,
    //                 text: "Creatives",
    //                 path: "/creative/listings",
    //                 module: "SurveyManager.Creative",
    //             },
    //         ],
    //         module: "SurveyManager",
    //         text: "Survey Manager",
    //         isStaging: true,
    //         isProd: true,
    //         path: "/surveydetails",
    //         order: 1,
    //     },
    //     {
    //         subMenu: [
    //             {
    //                 order: 1,
    //                 isStaging: true,
    //                 path: "/leaddefinition/listings",
    //                 module: "HNP.Defination",
    //                 text: "Lead Definitions",
    //                 isProd: true,
    //             },
    //             {
    //                 isProd: true,
    //                 text: "Offer Groups",
    //                 order: "2",
    //                 path: "/offergroups/listings",
    //                 module: "HNP.OfferGroup",
    //                 isStaging: true,
    //             },
    //             {
    //                 order: 3,
    //                 isProd: true,
    //                 module: "HNP.Category",
    //                 path: "/categories/listings",
    //                 text: "Lead Categories",
    //                 isStaging: true,
    //             },
    //         ],
    //         isStaging: true,
    //         path: "/hnp",
    //         order: 2,
    //         isProd: true,
    //         text: "Host & Post",
    //         module: "HNP",
    //     },
    //     {
    //         subMenu: [
    //             {
    //                 isProd: true,
    //                 path: "/giftcards/listings",
    //                 text: "Gift Cards",
    //                 module: "Deals.GiftCard",
    //                 isStaging: true,
    //                 order: 1,
    //             },
    //             {
    //                 module: "Deals.Category",
    //                 isStaging: true,
    //                 isProd: true,
    //                 text: "Categories",
    //                 path: "/categories/listings",
    //                 order: 2,
    //             },
    //             {
    //                 path: "/offerwall/listings",
    //                 module: "Deals.OfferWall",
    //                 text: "Offer Walls",
    //                 isProd: true,
    //                 order: 3,
    //                 isStaging: true,
    //             },
    //         ],
    //         isProd: true,
    //         module: "Deals",
    //         path: "/deals",
    //         isStaging: true,
    //         order: 3,
    //         text: "Deals",
    //     },
    //     {
    //         path: "/configurations",
    //         text: "Configurations",
    //         module: "Configurations",
    //         order: 4,
    //         subMenu: [
    //             {
    //                 path: "/abtests/listings",
    //                 isProd: true,
    //                 text: "A/B Tests",
    //                 order: 1,
    //                 module: "Configurations.ABTest",
    //                 isStaging: true,
    //             },
    //             {
    //                 isProd: true,
    //                 path: "/surveyconfigs/listings",
    //                 isStaging: true,
    //                 order: 2,
    //                 module: "Configurations.SurveyConfig",
    //                 text: "Survey Configs",
    //             },
    //             {
    //                 isStaging: true,
    //                 text: "Features",
    //                 module: "Configurations.Feature",
    //                 order: 3,
    //                 path: "/featuremaps/listings",
    //                 isProd: true,
    //             },
    //             {
    //                 text: "Feature Sets",
    //                 isProd: true,
    //                 path: "/featuresets/listings",
    //                 order: 4,
    //                 isStaging: true,
    //                 module: "Configurations.FeatureSet",
    //             },
    //         ],
    //         isStaging: true,
    //         isProd: true,
    //     },
    //     {
    //         isProd: true,
    //         isStaging: true,
    //         path: "/dashboards",
    //         subMenu: [
    //             {
    //                 module: "Dashboard.ROAS",
    //                 path: "/roas/listings",
    //                 isProd: false,
    //                 text: "ROAS",
    //                 order: 1,
    //                 isStaging: false,
    //             },
    //             {
    //                 titleText: "Direct to Offer Wall",
    //                 module: "Dashboard.DTO",
    //                 isStaging: true,
    //                 text: "DTO",
    //                 path: "/urlgenerator/listings",
    //                 order: "2",
    //                 isProd: true,
    //             },
    //             {
    //                 order: 3,
    //                 module: "Dashboard.FeedManager",
    //                 isProd: true,
    //                 text: "Feed Manager",
    //                 path: "/feedmanager/listings",
    //                 isStaging: true,
    //             },
    //             {
    //                 text: "Offline Conversion",
    //                 order: 4,
    //                 path: "/offlineconversion/listings",
    //                 isStaging: true,
    //                 isProd: false,
    //                 module: "Dashboard.OfflineConversion",
    //             },
    //             {
    //                 text: "Logo Library",
    //                 path: "/logolibrary/listings",
    //                 module: "Dashboard.LogoLibrary",
    //                 order: 5,
    //             },
    //             {
    //                 text: "Job Type Manager",
    //                 order: 6,
    //                 module: "Dashboard.JobType",
    //                 path: "/jobtype/listings",
    //             },
    //             {
    //                 text: "Scrub Dashboard",
    //                 isProd: false,
    //                 isStaging: true,
    //                 module: "Dashboard.ScrubDashboard",
    //                 Children: [
    //                     {
    //                         text: "Advertiser Scrub",
    //                         isProd: false,
    //                         isStaging: true,
    //                         order: 7,
    //                         path: "/scrubdashboard/listings/advertiser",
    //                         module: "Dashboard.ScrubDashboard",
    //                     },
    //                     {
    //                         isStaging: true,
    //                         path: "/scrubdashboard/listings/publisher",
    //                         isProd: false,
    //                         order: 8,
    //                         text: "Publisher Scrub",
    //                         module: "Dashboard.ScrubDashboard",
    //                     },
    //                 ],
    //             },

    //             {
    //                 path: "/userdashboard/listings",
    //                 module: "Dashboard.UserDashboard",
    //                 text: "User Dashboard",
    //                 isStaging: true,
    //                 isProd: false,
    //                 order: 9,
    //             },
    //         ],
    //         text: "Control Centre",
    //         order: 5,
    //         module: "Dashboard",
    //     },
    //     {
    //         subMenu: [
    //             {
    //                 isStaging: true,
    //                 order: 1,
    //                 module: "Reporting.Automation",
    //                 text: "Automation Audit",
    //                 path: "/automationdashboard",
    //                 isProd: true,
    //             },
    //         ],
    //         path: "/reporting",
    //         isProd: true,
    //         order: 6,
    //         text: "Reporting",
    //         isStaging: true,
    //         module: "Reporting",
    //     },
    // ];

    const { showLeftNav, isDarkMode, leftNavAutoExpandCollapse } = useSelector(
        state => state.globalConfig
    );
    const userData = useSelector(state => state.auth);
    const [data, setData] = useState([]);
    // const [data, setData] = useState(linkData);
    const [showAcc, setShowAcc] = useState("");
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [nameInitials, setNameInitials] = useState("");
    const [photoUrl, setPhotoUrl] = useState("");
    const [nameEmail, setNameEmail] = useState("");
    const [arrowIconHide, setArrowIconHide] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();

    useEffect(() => {
        setData(linkData);
    }, [linkData]);

    useEffect(() => {
        if (!userData.user) return;
        const fullName = userData.user.name.split(" ");
        const userImage = userData.user?.photoURL;
        if (!fullName.length) return;
        const initials =
            fullName.length === 2
                ? fullName.shift().charAt(0) + fullName.pop().charAt(0)
                : fullName[0].charAt(0);
        const userNameEmail = {
            name: userData.user.name || "",
            email: userData.user.emailId || "",
        };
        setNameEmail(userNameEmail);
        setNameInitials(initials.toUpperCase());
        setPhotoUrl(userImage);
    }, []);

    const toggleDarkMode = () => {
        localStorage.setItem("darkMode", !isDarkMode);
        dispatch(globalConfigActions.toggleDarkMode(!isDarkMode));
    };

    const logoutUser = () => {
        history.replace({
            pathname: `/login`,
            hash: "logout",
        });
    };

    const releaseNotes = () => {
        history.push("/release");
    };

    const navigateToUAC = () => {
        history.push("/settings");
    };

    const ProfileSelectOption = (option, idx) => {
        return (
            <div
                className={s.dropdownList}
                onClick={e => {
                    if (option?.isToggle) {
                        e.stopPropagation();
                    } else {
                        option.action();
                        setShowProfileDropdown(false);
                    }
                }}
                key={idx}
            >
                <Icon
                    className={s.icon}
                    size="16px"
                    icon={option?.icon}
                    enableHover={true}
                />
                <div className={s.option}>{option?.text}</div>
                {option?.isToggle && (
                    <div className={s.extras}>
                        <ToggleSwitch
                            isToggleOn={isDarkMode}
                            setIsToggleOn={toggleDarkMode}
                        />
                    </div>
                )}
            </div>
        );
    };

    const [profileSelectOptions, setProfileSelectOptions] = useState([
        {
            icon: "DarkMode",
            text: "Dark Mode",
            isToggle: true,
            action: toggleDarkMode,
        },
        { icon: "ReleaseNote", text: "Release Notes", action: releaseNotes },
        { icon: "LogOut", text: "Log Out", action: logoutUser },
    ]);

    useEffect(() => {
        const uacRoute = {
            icon: "Settings",
            text: "Settings",
            action: navigateToUAC,
        };
        if (userData?.user?.modules?.includes("UAC.Read")) {
            let copyArray = [...profileSelectOptions];
            copyArray.unshift(uacRoute);
            setProfileSelectOptions(copyArray);
        }

        if (
            window.location.href.includes("/users/") ||
            window.location.href.includes("/user-group/")
        ) {
            dispatch(menulinksActions.setShowMenu(false));
        } else {
            dispatch(menulinksActions.setShowMenu(true));
        }
    }, []);

    const arrowShowHideFun = () => {
        if (window.innerWidth > 1900) {
            setArrowIconHide(true);
        } else {
            setArrowIconHide(false);
            if (!leftNavAutoExpandCollapse) {
                if (
                    pathname.includes("create") ||
                    pathname.includes("edit") ||
                    pathname.includes("userdashboard/details")
                ) {
                    dispatch(globalConfigActions.setShowLeftNav(false));
                } else {
                    dispatch(globalConfigActions.setShowLeftNav(true));
                }
            }
        }
    };

    useEffect(() => {
        arrowShowHideFun();
        window.addEventListener("resize", arrowShowHideFun);
        return () => {
            window.removeEventListener("resize", arrowShowHideFun);
        };
    }, [pathname]);

    return (
        <div
            className={`${s.appNavigatorWrapper} ${
                !showLeftNav ? s.collapseAppNavigatorWrapper : ""
            }`}
        >
            <div
                className={`logoBetaImgWrapper ${
                    isDarkMode ? "logoBetaImgWrapper__dark" : ""
                } ${s.logoWrapper} ${
                    !showLeftNav
                        ? `${s.collapseLogoWrapper} logoBetaImgWrapperCollapsed`
                        : ""
                }`}
            >
                <div
                    className={`logoContainer ${
                        isDarkMode ? "logoContainer__dark" : ""
                    }`}
                >
                    <Link to="/surveydetails/surveys">
                        <div className={s.mainLogoWrapper}>
                            <img
                                src={
                                    showLeftNav
                                        ? isDarkMode
                                            ? logodarkMode
                                            : logo
                                        : logo
                                }
                            />
                        </div>
                    </Link>
                </div>

                <div
                    className={`${s.arrowWrapper}`}
                    onClick={() => {
                        setShowAcc("");
                        dispatch(
                            globalConfigActions.setShowLeftNav(!showLeftNav)
                        );
                        dispatch(
                            globalConfigActions.leftNavExpandCollapseSwitch(
                                true
                            )
                        );
                    }}
                    style={arrowIconHide ? { display: "none" } : {}}
                >
                    <Icon
                        icon={showLeftNav ? "ArrowFirst" : "ArrowLast"}
                        size="12px"
                    />
                </div>
            </div>
            <div className={s.liContainer}>
                {data?.length > 0 &&
                    data.map((data, index) => {
                        return (
                            <HeaderAccordian
                                key={index}
                                data={data}
                                index={index}
                                showAcc={showAcc}
                                setShowAccFunction={data => setShowAcc(data)}
                                pathname={pathname}
                            />
                        );
                    })}
            </div>

            <div
                className={`${s.userProfileWrapper} ${
                    !showLeftNav && s.userProfileWrapperCollapse
                } ${showProfileDropdown ? s.profileDropdownOpen : ""}`}
                onClick={() => setShowProfileDropdown(!showProfileDropdown)}
            >
                <div
                    className={`${s.userProfile} ${
                        !showLeftNav && s.userProfileCollapse
                    }`}
                >
                    {!showProfileDropdown && (
                        <>
                            <div className={s.userInfoWrapper}>
                                {photoUrl ? (
                                    <div className={`${s.userName} flex`}>
                                        {showLeftNav ? (
                                            <img
                                                referrerPolicy="no-referrer"
                                                src={photoUrl}
                                                alt={`${nameInitials}profile`}
                                            />
                                        ) : (
                                            <img
                                                referrerPolicy="no-referrer"
                                                src={photoUrl}
                                                alt={`${nameInitials}profile`}
                                            />
                                        )}
                                    </div>
                                ) : nameInitials ? (
                                    <div className={`${s.userName} flex`}>
                                        <span>{nameInitials}</span>
                                    </div>
                                ) : (
                                    <Icon
                                        icon="Profile"
                                        size="38px"
                                        className={s.defaultProfile}
                                    />
                                )}
                                {nameEmail && showLeftNav && (
                                    <div className={s.userDataInfo}>
                                        <div className={s.name}>
                                            Hi,
                                            <span> {nameEmail.name}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={s.arrowIcon}>
                                <Icon icon="RightArrow" size="10px" />
                            </div>
                        </>
                    )}
                </div>
            </div>
            <OutsideClickHandler
                onOutsideClick={e => {
                    setShowProfileDropdown(false);
                }}
            >
                <div
                    className={`${s.dropdown} ${
                        showProfileDropdown && s.profileDropdownActive
                    }`}
                >
                    {profileSelectOptions.map((item, idx) => {
                        return ProfileSelectOption(item, idx);
                    })}
                    <div className={s.userInfodropdownContainer}>
                        <div
                            className={s.userInfodropdownWrapper}
                            onClick={() => {
                                setShowProfileDropdown(false);
                            }}
                        >
                            <div className={s.userInfodropdownActive}>
                                {photoUrl ? (
                                    <div className={`${s.userName} flex`}>
                                        {showLeftNav ? (
                                            <img
                                                referrerPolicy="no-referrer"
                                                src={photoUrl}
                                                alt={`${nameInitials}profile`}
                                            />
                                        ) : (
                                            <img
                                                referrerPolicy="no-referrer"
                                                src={photoUrl}
                                                alt={`${nameInitials}profile`}
                                            />
                                        )}
                                    </div>
                                ) : nameInitials ? (
                                    <div className={`${s.userName} flex`}>
                                        <span>{nameInitials}</span>
                                    </div>
                                ) : (
                                    <Icon
                                        icon="Profile"
                                        size="38px"
                                        className={s.defaultProfile}
                                    />
                                )}
                                {nameEmail && (
                                    <div className={s.userDataInfo}>
                                        <div className={s.name}>
                                            {nameEmail.name}
                                        </div>
                                        {showProfileDropdown && (
                                            <div className={s.email}>
                                                {nameEmail.email}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default AppNavigator;
