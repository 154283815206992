import { useState } from "react";
import dagre from "@pinging/dagrejs";
import style from "./FlowRenderer.module.scss";
import Icon from "../Icon/Icon";
import Select from "../Select/Select";
import { getTransformPositions } from "../../utils/globalUtils";
import { cloneDeep, get, isEmpty } from "lodash";
import LogoComponent from "../Logo/LogoComponent";
import { capitalizeString } from "../../utils/globalUtils";
import { getCurrentNodeTree } from "../../Pages/CreateFlow/FlowUtils";
import CustomTooltipRenderer from "../../components/Renderers/CustomTooltipRenderer/CustomTooltipRenderer";

export const EntitySubType = {
    goto: "goto",
    next: "next",
    end: "end",
};

export const EntityType = {
    CONTAINER: "CONTAINER",
    ACTIONS: "CONTAINER_ROUTER",
    ROUTER: "ROUTER",
    FLOW_ROUTER: "FLOW_ROUTER",
    FLOW_START: "FLOW_START",
    COLLAPSED: "COLLAPSED",
};

export const NodeTypes = {
    START: "Start",
    RULE_SET: "RuleSetNode",
    TARGETING: "Targeting",
    RULE: "rule",
};

export const NodeRenderType = {
    AB_ROUTER: "AB_ROUTER",
    START_END: "START_END",
    TP_ROUTER: "TP_ROUTER",
    TP: "TP",
    AB: "AB",
    CONTAINER: "CONTAINER",
    PREVIEW_CONTAINER: "PREVIEW_CONTAINER",
    FLOW_START: "FLOW_START",
    COLLAPSED: "COLLAPSED",
};

export const RouterTypes = {
    AB_TEST: "AB_TEST",
    LOGIC: "LOGICAL",
};
export const KeyTypes = {
    SET: "set:",
    RULE: "rule:",
    TARGETING: "target:",
};

export const EntityIcon = {
    SURVEY: "CreateSurvey",
    OFFER: "OfferPath",
    REGISTER: "Reporting",
    LISTINGS: "ListView",
    GENERIC_PATH_OFFER_WALL: "GiftCard",
    GIFT_CARD_VIEWER: "GridView",
    DETAILS_CONFIRMATION: "Reporting",
    sameContainer: "SameContainer",
    prevContainer: "PreviousContainer",
    FLOW_START: "Flow",
    THIRD_PARTY: "Redirect",
};
export const EntityName = {
    SURVEY: "SURVEY",
    OFFER: "OFFER",
    REGISTER: "REGISTER",
    LISTINGS: "LISTING",
    GENERIC_PATH_OFFER_WALL: "Gift Card Offer Wall",
    GIFT_CARD_VIEWER: "GC LISTING",
    DETAILS_CONFIRMATION: "DETAILS",
    GRATIFICATION: "GRATIFICATION",
    OFFER_WALL: "Back Offer Wall",
    sameContainer: "Stay on same container",
    prevContainer: "Previous container",
    FLOW_START: "Flow",
    DEALS_OFFERWALL: "Deals Offer Wall",
    NO_OFFER: "No Offer",
    THIRD_PARTY: "Redirect",
    STANDALONE: "STANDALONE",
    CONFIRMATION: "CONFIRMATION",
    NO_JOBS: "No Jobs",
};
export const LinkTypes = {
    REGULAR: "regular",
    NON_EDITABLE: "actionStart",
    DASHED: "dashed",
    LABELLED: "labelled",
};

export const FlowRouterRelativeSubTypes = ["sameContainer", "prevContainer"];
export const ContainerTypesWithPreview = ["SURVEY", "REGISTER", "FLOW_START"];

export function isEmptyObj(obj) {
    if (obj == null || obj == undefined) return true;
    return Object.keys(obj).length == 0;
}

export const getNodeDimensions = node => {
    if (node.width && node.height) {
        return [node.width, node.height];
    }
    if (node.nodeType === NodeRenderType.AB_ROUTER) {
        return [250, 20];
    } else if (node.nodeType === NodeRenderType.TP_ROUTER) {
        return [100, 40];
    } else if (node.nodeType === NodeRenderType.TP) {
        return [100, 20];
    } else if (node.nodeType === NodeRenderType.AB) {
        return [250, 40];
    } else if (node.nodeType === NodeRenderType.CONTAINER) {
        return [250, 100];
    } else if (node.nodeType === NodeRenderType.PREVIEW_CONTAINER) {
        return [300, 70];
    } else if (node.nodeType === NodeRenderType.START_END) {
        return [70, 50];
    } else if (node.nodeType === NodeRenderType.COLLAPSED) {
        return [250, 50];
    }
    return [250, 50];
};
export const findNextSetId = (rule, ruleSetMapping) => {
    return rule?.entityType !== EntityType.ACTIONS ||
        !ruleSetMapping[rule?.nextSetId]
        ? rule
        : findNextSetId(ruleSetMapping[rule?.nextSetId][0], ruleSetMapping);
};
export function createTargetId(rule, ruleSetMapping) {
    let targetId = rule.id;
    const hasRouterInStartNode = ruleSetMapping[rule.ruleSetId].some(
        r =>
            r.entityType === EntityType.ROUTER ||
            r.entityType === EntityType.ACTIONS
    );
    const collapsedRule = ruleSetMapping[rule.ruleSetId].find(
        r =>
            r.entityType === EntityType.COLLAPSED &&
            (r.id === -rule.id || r.id === -rule.ruleSetId)
    );

    if (hasRouterInStartNode) {
        targetId = rule.ruleSetId;
        return [KeyTypes.SET + targetId, rule.id, collapsedRule];
    }
    return [KeyTypes.RULE + targetId, targetId, collapsedRule];
}
export function createLinkObj(
    source,
    target,
    sourceRule,
    targetRule,
    filteredRules,
    addNode,
    showPasteIcon,
    disablePasteIcon,
    loadCopiedNodes,
    isDarkMode,
    readOnly,
    hiddenNodes,
    editNode,
    disConnectFlow,
    rules,
    disconnectedNodeTreeRuleSetIds = []
) {
    const nextNodeForAdd =
        rules?.find(
            _ =>
                _.ruleSetId === sourceRule?.nextSetId &&
                _?.entityType === EntityType.ACTIONS &&
                _.entitySubType === EntitySubType.end
        ) || sourceRule;

    const skipContainer =
        targetRule.entityType === EntityType.ACTIONS &&
        sourceRule?.entityType === EntityType.CONTAINER;

    const findColor = () => {
        if (
            disconnectedNodeTreeRuleSetIds?.includes(targetRule?.ruleSetId) ||
            disconnectedNodeTreeRuleSetIds?.includes(sourceRule?.ruleSetId)
        ) {
            return "#e76e6e";
        }
        if (filteredRules.length) {
            if (targetRule.isFilteredOut) return "#E5EFEF";

            return "#41C3A9";
        }
        if ((targetRule.histClass || sourceRule.histClass) === "isAdded") {
            if (isDarkMode) return "#347D39";
            return "#A9EFC5";
        } else if (
            (targetRule.histClass || sourceRule.histClass) === "isDeleted"
        ) {
            if (isDarkMode) return "#922323";
            return "#FFC2C2";
        } else if (sourceRule.histClass === "isUpdated") {
            if (isDarkMode) return "#966600";
            return "FFE99C";
        }

        if (readOnly) return null;

        if (targetRule?.ruleSetId === editNode?.ruleSetId) {
            if (editNode.entitySubType === "LOGICAL") return "#ff9e57";
            if (editNode.entitySubType === "AB_TEST") return "#9490FB";
            return "#2198DB";
        }

        if (isDarkMode) return "#465158";

        return "#cad1d4";
    };

    const color = findColor();

    return {
        id: source + "-" + target,
        source: source,
        target: target,
        category: LinkTypes.REGULAR,
        animated:
            (filteredRules.length && !targetRule.isFilteredOut) ||
            sourceRule?.entityType === EntityType.COLLAPSED,
        type: "custom",
        hidden:
            hiddenNodes.includes(targetRule.id) ||
            hiddenNodes.includes(targetRule.ruleSetId) ||
            hiddenNodes.includes(sourceRule?.id),
        sourceHandle:
            sourceRule?.entityType === EntityType.CONTAINER
                ? "normal"
                : undefined,
        data: {
            direction: skipContainer ? "right" : null,
            color: color,
            ...(addNode && !skipContainer
                ? {
                      addNode: () => {
                          addNode(nextNodeForAdd);
                      },
                  }
                : {}),

            ...(showPasteIcon
                ? {
                      loadCopiedNodes: () => {
                          !disablePasteIcon &&
                              loadCopiedNodes(
                                  nextNodeForAdd?.id === -1
                                      ? "start"
                                      : nextNodeForAdd
                              );
                      },
                  }
                : {}),

            ...(disConnectFlow && !skipContainer
                ? {
                      disConnectFlow: () => {
                          disConnectFlow(sourceRule);
                      },
                  }
                : {}),
        },
    };
}

export function createLinks(
    startId,
    ruleSetMapping,
    filteredRules,
    readOnly,
    isDarkMode,
    showPasteIcon,
    disablePasteIcon,
    editNode,
    addNode,
    loadCopiedNodes,
    hiddenNodes,
    rules,
    disConnectFlow,
    globalRulesStartId,
    fallBackStartId,
    disconnectedNodeTreeRuleSetIds,
    litigatorRulesStartId
) {
    let newLinks = [];

    Object.keys(ruleSetMapping).forEach(rs => {
        rs = parseInt(rs);
        // create link from start node if ruleSetId Matches startId
        if (rs === startId) {
            const rule = findNextSetId(ruleSetMapping[rs][0], ruleSetMapping);
            const [target, targetId, collapsedRule] = createTargetId(
                rule,
                ruleSetMapping
            );
            newLinks.push(
                createLinkObj(
                    KeyTypes.SET + -1,
                    target,
                    { id: -1 },
                    rule,
                    filteredRules,
                    addNode,
                    showPasteIcon,
                    disablePasteIcon,
                    loadCopiedNodes,
                    isDarkMode,
                    readOnly,
                    hiddenNodes,
                    editNode,
                    disConnectFlow,
                    rules,
                    disconnectedNodeTreeRuleSetIds
                )
            );
            if (collapsedRule) {
                newLinks.push(
                    createLinkObj(
                        KeyTypes.SET + -1,
                        KeyTypes.RULE + collapsedRule.id,
                        { id: -1 },
                        collapsedRule,
                        filteredRules,
                        null,
                        false,
                        disablePasteIcon,
                        null,
                        isDarkMode,
                        readOnly,
                        hiddenNodes,
                        editNode,
                        rules,
                        disconnectedNodeTreeRuleSetIds
                    )
                );
            }
        }
        if (rs === globalRulesStartId) {
            const rule = findNextSetId(ruleSetMapping[rs][0], ruleSetMapping);

            const [target, targetId, collapsedRule] = createTargetId(
                rule,
                ruleSetMapping
            );

            newLinks.push(
                createLinkObj(
                    KeyTypes.SET + -2,
                    target,
                    { id: -2 },
                    rule,
                    filteredRules,
                    addNode,
                    showPasteIcon,
                    disablePasteIcon,
                    loadCopiedNodes,
                    isDarkMode,
                    readOnly,
                    hiddenNodes,
                    editNode,
                    disConnectFlow,
                    rules,
                    disconnectedNodeTreeRuleSetIds
                )
            );
            if (collapsedRule) {
                newLinks.push(
                    createLinkObj(
                        KeyTypes.SET + -2,
                        KeyTypes.RULE + collapsedRule.id,
                        { id: -2 },
                        collapsedRule,
                        filteredRules,
                        null,
                        false,
                        disablePasteIcon,
                        null,
                        isDarkMode,
                        readOnly,
                        hiddenNodes,
                        editNode,
                        rules,
                        disconnectedNodeTreeRuleSetIds
                    )
                );
            }
        }

        if (rs === fallBackStartId) {
            const rule = findNextSetId(ruleSetMapping[rs][0], ruleSetMapping);
            const [target, targetId, collapsedRule] = createTargetId(
                rule,
                ruleSetMapping
            );
            newLinks.push(
                createLinkObj(
                    KeyTypes.SET + -3,
                    target,
                    { id: -3 },
                    rule,
                    filteredRules,
                    addNode,
                    showPasteIcon,
                    disablePasteIcon,
                    loadCopiedNodes,
                    isDarkMode,
                    readOnly,
                    hiddenNodes,
                    editNode,
                    disConnectFlow,
                    rules,
                    disconnectedNodeTreeRuleSetIds
                )
            );
            if (collapsedRule) {
                newLinks.push(
                    createLinkObj(
                        KeyTypes.SET + -3,
                        KeyTypes.RULE + collapsedRule.id,
                        { id: -3 },
                        collapsedRule,
                        filteredRules,
                        null,
                        false,
                        disablePasteIcon,
                        null,
                        isDarkMode,
                        readOnly,
                        hiddenNodes,
                        editNode,
                        rules,
                        disconnectedNodeTreeRuleSetIds
                    )
                );
            }
        }

        if (rs === litigatorRulesStartId) {
            const rule = findNextSetId(ruleSetMapping[rs][0], ruleSetMapping);

            const [target, targetId, collapsedRule] = createTargetId(
                rule,
                ruleSetMapping
            );

            newLinks.push(
                createLinkObj(
                    KeyTypes.SET + -4,
                    target,
                    { id: -4 },
                    rule,
                    filteredRules,
                    addNode,
                    showPasteIcon,
                    disablePasteIcon,
                    loadCopiedNodes,
                    isDarkMode,
                    readOnly,
                    hiddenNodes,
                    editNode,
                    disConnectFlow,
                    rules,
                    disconnectedNodeTreeRuleSetIds
                )
            );
            if (collapsedRule) {
                newLinks.push(
                    createLinkObj(
                        KeyTypes.SET + -2,
                        KeyTypes.RULE + collapsedRule.id,
                        { id: -2 },
                        collapsedRule,
                        filteredRules,
                        null,
                        false,
                        disablePasteIcon,
                        null,
                        isDarkMode,
                        readOnly,
                        hiddenNodes,
                        editNode,
                        rules,
                        disconnectedNodeTreeRuleSetIds
                    )
                );
            }
        }

        ruleSetMapping[rs].forEach(rule => {
            // Connect Routers and Next Container Routers rules with sameRuleSetIds to set
            if (
                rule.entityType === EntityType.ROUTER ||
                (rule.entityType === EntityType.ACTIONS &&
                    rule.entitySubType === EntitySubType.next)
            ) {
                newLinks.push(
                    createLinkObj(
                        KeyTypes.SET + rs,
                        KeyTypes.RULE + rule.id,
                        { id: rs },
                        rule,
                        filteredRules,
                        null,
                        false,
                        disablePasteIcon,
                        null,
                        isDarkMode,
                        readOnly,
                        hiddenNodes,
                        editNode,
                        disConnectFlow,
                        rules,
                        disconnectedNodeTreeRuleSetIds
                    )
                );
            }
            if (rule.nextSetId && rule.entitySubType !== EntitySubType.end) {
                let nextNodes = rules.filter(
                    _ => _.ruleSetId === rule.nextSetId
                );
                nextNodes.forEach(nextNode => {
                    let nextRule = findNextSetId(nextNode, ruleSetMapping);
                    if (
                        nextNode.entityType === EntityType.ACTIONS &&
                        nextNode.entitySubType === EntitySubType.next
                    ) {
                        nextRule = nextNode;
                    }

                    if (nextRule) {
                        const [target, targetId, collapsedRule] =
                            createTargetId(nextRule, ruleSetMapping);
                        newLinks.push(
                            createLinkObj(
                                KeyTypes.RULE + rule.id,
                                target,
                                rule,
                                nextRule,
                                filteredRules,
                                addNode,
                                showPasteIcon,
                                disablePasteIcon,
                                loadCopiedNodes,
                                isDarkMode,
                                readOnly,
                                hiddenNodes,
                                editNode,
                                disConnectFlow,
                                rules,
                                disconnectedNodeTreeRuleSetIds
                            )
                        );
                        if (collapsedRule) {
                            newLinks.push(
                                createLinkObj(
                                    KeyTypes.RULE + rule.id,
                                    KeyTypes.RULE + collapsedRule.id,
                                    rule,
                                    collapsedRule,
                                    filteredRules,
                                    null,
                                    false,
                                    disablePasteIcon,
                                    null,
                                    isDarkMode,
                                    readOnly,
                                    hiddenNodes,
                                    editNode,
                                    rules,
                                    disconnectedNodeTreeRuleSetIds
                                )
                            );
                        }
                    }
                });
            }
        });
    });
    return newLinks;
}
export const getLayoutedElements = (
    nodes,
    edges,
    direction = "TB",
    align,
    forceChange,
    nodePositions,
    rules,
    hideFiltered
) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({
        rankdir: direction,
        align,
    });

    let nodeWidth = 250;
    let nodeHeight = 100;

    nodes.forEach((node, i) => {
        [nodeWidth, nodeHeight] = getNodeDimensions(node);
        let id = node.id;
        const idSplit = id.split(":");
        if (idSplit.length === 2 && idSplit[1] != -1 && idSplit[1] != -2) {
            id = idSplit[0] + ":" + Math.abs(idSplit[1]);
        }
        const parentRules = rules.filter(_ => _.nextSetId === node.ruleSetId);
        const siblings = rules.filter(_ => _.ruleSetId === node.ruleSetId);
        const isRouterNode = node.id.includes("set:");
        nodeHeight =
            siblings?.length === 1 || isRouterNode
                ? nodeHeight + parentRules.length * 20
                : nodeHeight;
        dagreGraph.setNode(id, {
            width: nodeWidth,
            height: nodeHeight,
        });
    });

    edges
        .filter(_ => nodes.findIndex(n => n.id === _.source) !== -1)
        .forEach(edge => {
            const sourceIdSplit = edge.source.split(":");
            const targetIdSplit = edge.target.split(":");

            if (
                sourceIdSplit.length === 2 &&
                targetIdSplit.length === 2 &&
                sourceIdSplit[1] >= -3 &&
                targetIdSplit[1] >= -2
            ) {
                // target = idSplit[0] + ":" + Math.abs(idSplit[1]);
                dagreGraph.setEdge(edge.source, edge.target);
            }
        });

    dagre.layout(dagreGraph, {
        nodesep: 100,
    });

    nodes.forEach(node => {
        let id = node.id;
        const idSplit = id.split(":");
        if (
            idSplit.length === 2 &&
            idSplit[1] < -2 // Start node
            // Fake node created in global back for place holder "Previous node"
            // Collapsed nodes have id negative of the original node
        ) {
            const intId = Math.abs(idSplit[1]);
            id = idSplit[0] + ":" + intId;
            const node = rules.find(_ => _.id === intId);
            if (dagreGraph.node(KeyTypes.SET + node?.ruleSetId)) {
                id = KeyTypes.SET + node?.ruleSetId;
            }
        }
        const nodeWithPosition = dagreGraph.node(id);
        node.targetPosition = isHorizontal ? "left" : "top";
        node.sourcePosition = isHorizontal ? "right" : "bottom";

        [nodeWidth, nodeHeight] = getNodeDimensions(node);

        if (!nodePositions[id] || forceChange || hideFiltered) {
            const position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y,
            };

            dagreGraph.setNode(node.id, {
                width: nodeWithPosition.width,
                height: nodeWithPosition.height,
                ...position,
            });

            node.position = position;
            nodePositions[id] = position;
        } else {
            node.position = {
                x: nodePositions[id].x,
                y: nodePositions[id].y,
            };
        }

        return node;
    });

    return { nodes, edges };
};

export const getCustomClientRect = (
    portalElement,
    portalElement2,
    topOffset = 0,
    leftOffset = 0
) => {
    const viewPortTransformPositions = getTransformPositions(
        portalElement.querySelector(".react-flow__viewport")
    );

    const customClientRect = getTransformPositions(portalElement2);

    customClientRect.top -= 20;
    customClientRect.left -= 20;

    customClientRect.left +=
        customClientRect.width / viewPortTransformPositions.scale;

    customClientRect.height /= viewPortTransformPositions.scale;
    customClientRect.width /= viewPortTransformPositions.scale;

    customClientRect.top += topOffset;
    customClientRect.left += leftOffset;

    return customClientRect;
};

export const NodeAction = ({
    rules,
    position = { x: 0, y: 0, height: 0, width: 0 },
    rule = {},
    copyCurrentTree,
    copyNode,
    openPreview,
    openDetails,
    deleteNode,
    goto,
    prefix = "rule:",
    portalElement,
    readOnly,
    deleteAllNodes,
    containerAsDestination,
    isHistory = false,
    addFilter,
    containers = [],
    showAddFilter = false,
    filterSupported = false,
    removeFilter,
    filterApplied,
    availableTargetingParams,
    showFiltered,
    cloneAndCopy,
    nodeName = "node",
}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const customClientRect = getCustomClientRect(
        portalElement,
        portalElement.querySelector(
            `.react-flow__node[data-id='${
                prefix === "set:" ? prefix + rule?.ruleSetId : prefix + rule?.id
            }']`
        )
    );

    const getFilterObj = rule => {
        let ruleType = capitalizeString(rule?.entitySubType);
        let newFilter = [];

        if (rule.entityType === "CONTAINER") {
            let ruleId = rule?.entityId;
            let filteredValue = containers?.find(_ => _.id === ruleId);
            newFilter = [
                {
                    custom: true,
                    filterType: "multiSelectEditable",
                    ruleId: rule?.id,
                    filterTag: [
                        {
                            id: "in",
                            isEnabled: false,
                            name: "contains",
                            parentType: ruleType,
                            type: "update",
                            type1: "in",
                        },
                    ],

                    filterValues: [
                        {
                            isEnabled: true,
                            operator: "or",
                            type: "updateValues",
                            type1: ruleType,
                            value: [
                                {
                                    id: filteredValue?.id,
                                    name: filteredValue?.name,
                                },
                            ],
                        },
                    ],
                    id: ruleType,
                    k: rule?.entitySubType?.toLowerCase(),
                    name: ruleType,
                    op: "in",
                    page: "createFlow",
                },
            ];
        }
        if (rule.entitySubType === "LOGICAL") {
            const parsedTargettingParams = rule.targettingParams || {};
            const key = fetchTpKey(parsedTargettingParams);
            const targettingDetails = availableTargetingParams?.find(
                _ => _.name === key
            );
            let targettingValue = rule?.targettingParams?.value[0]?.value;
            targettingValue =
                typeof targettingValue === "object"
                    ? targettingValue[0]?.value
                    : targettingValue;
            let filteredValue = {};
            filteredValue = !isEmpty(targettingDetails?.possibleValues)
                ? targettingDetails?.possibleValues?.find(
                      _ => _.id === targettingValue
                  )
                : rule?.targettingParams?.value[0]?.value;

            if (
                rule.entitySubType === "LOGICAL" &&
                isEmpty(targettingDetails?.possibleValues)
            ) {
                ruleType = targettingDetails?.opsName?.replace(
                    /[^a-zA-Z ]/g,
                    " "
                );
                filteredValue =
                    typeof filteredValue === "object" && !isEmpty(filteredValue)
                        ? (parseInt(filteredValue[0].value) + 1).toString()
                        : filteredValue;
                let filteredKey = rule?.targettingParams?.value[0];
                filteredKey =
                    typeof filteredKey?.value === "object" &&
                    !isEmpty(filteredKey?.value)
                        ? filteredKey?.value[0]?.key
                        : typeof filteredKey === "object"
                        ? filteredKey?.key
                        : filteredKey;

                newFilter = [
                    {
                        custom: false,
                        id: filteredKey,
                        name: ruleType,
                        ruleId: rule?.id,
                        k: filteredKey,
                        op: "in",
                        page: "createFlow",
                        filterType: "multiSelectEditable",
                        filterValues: [],
                        filterTag: [
                            {
                                id: filteredValue,
                                isEnabled: true,
                                name: filteredValue,
                                operator: "=",
                                parentType: filteredKey,
                                type: "update",
                                type1: "contains",
                            },
                        ],
                    },
                ];
            }
            if (
                rule.entitySubType === "LOGICAL" &&
                !isEmpty(targettingDetails?.possibleValues)
            ) {
                // let filteredKey = rule?.targettingParams?.value[0]?.key;
                ruleType = targettingDetails?.opsName?.replace(
                    /[^a-zA-Z ]/g,
                    " "
                );
                newFilter = [
                    {
                        custom: true,
                        filterType: "multiSelectEditable",
                        ruleId: rule?.id,
                        filterTag: [
                            {
                                id: "in",
                                isEnabled: false,
                                name: "contains",
                                parentType: ruleType,
                                type: "update",
                                type1: "in",
                            },
                        ],

                        filterValues: [
                            {
                                isEnabled: true,
                                operator: "or",
                                type: "updateValues",
                                type1: ruleType,
                                value: [
                                    {
                                        id: targettingValue,
                                        name: filteredValue?.label,
                                    },
                                ],
                            },
                        ],
                        id: targettingDetails?.name,
                        k: targettingDetails?.name,
                        name: ruleType,
                        op: "in",
                        page: "createFlow",
                    },
                ];
            }
        }
        return newFilter;
    };

    const getUpdatedFilterObj = rule => {
        let ruleType = capitalizeString(rule?.entitySubType);
        let updatedFilterObject = [];
        if (
            rule.entityType === "CONTAINER" ||
            rule.entitySubType === "LOGICAL"
        ) {
            let valueToRemove =
                rule.entitySubType === "LOGICAL"
                    ? rule?.targettingParams?.value[0].value
                    : rule?.name.split(":")[1]?.trim();
            let initialFiltersApplied = cloneDeep(filterApplied);
            updatedFilterObject = initialFiltersApplied?.find(
                _ => _.ruleId === rule.id || _.id === ruleType
            );
            if (typeof valueToRemove === "object") {
                valueToRemove = valueToRemove[0]?.value;
            }
            let filteredValues =
                rule.entitySubType === "LOGICAL"
                    ? updatedFilterObject?.filterValues[0]?.value.filter(
                          _ => _.id !== valueToRemove
                      )
                    : updatedFilterObject?.filterValues[0]?.value.filter(
                          _ => _.name !== valueToRemove
                      );
            if (isEmpty(filteredValues)) {
                updatedFilterObject = initialFiltersApplied.filter(
                    _ => _.ruleId !== rule.id
                );
            } else {
                const target = initialFiltersApplied.find(
                    _ => _.ruleId === rule.id || _.id === ruleType
                );
                Object.assign(target, updatedFilterObject);
            }
        }
        return updatedFilterObject;
    };

    // Add as Filter
    const actionList = [
        ...(cloneAndCopy && (!readOnly || isHistory)
            ? [
                  {
                      id: "CLONE",
                      name: "Clone & Copy",
                      icon: "Clone",
                      callback: rule => {
                          cloneAndCopy(rule);
                      },
                  },
              ]
            : []),
        ...(showAddFilter &&
        addFilter &&
        filterSupported &&
        (!readOnly || isHistory) &&
        !showFiltered
            ? [
                  {
                      id: "ADD",
                      name: "Add as Filter",
                      icon: "AddFilter",
                      callback: rule => {
                          const newFilter = getFilterObj(rule);
                          addFilter(newFilter);
                      },
                  },
              ]
            : []),
        ...(!showAddFilter &&
        removeFilter &&
        filterSupported &&
        (!readOnly || isHistory) &&
        !showFiltered
            ? [
                  {
                      id: "REMOVE",
                      name: "Remove from filter",
                      icon: "RemoveFilter",
                      callback: rule => {
                          const updatedFilter = getUpdatedFilterObj(rule);
                          removeFilter(updatedFilter);
                      },
                  },
              ]
            : []),
        ...(copyCurrentTree && (!readOnly || isHistory)
            ? [
                  {
                      id: "COPY",
                      name: "Copy",
                      icon: "Copy",
                      items: [
                          {
                              id: "COPY_SINGLE",
                              name: `Copy single ${nodeName}`,
                              callback: rule => {
                                  copyNode(
                                      rules,
                                      rule?.ruleSetId,
                                      prefix !== "set:" ? rule.id : null
                                  );
                              },
                          },
                          {
                              id: "COPY_WITH_FLOW",
                              name: `Copy ${nodeName} with flow`,
                              callback: rule => {
                                  copyCurrentTree(
                                      rules,
                                      rule.ruleSetId,
                                      prefix !== "set:" ? rule.id : null
                                  );
                              },
                          },
                      ],
                      callback: rule => {
                          copyCurrentTree(
                              rules,
                              rule.ruleSetId,
                              prefix !== "set:" ? rule.id : null
                          );
                      },
                  },
              ]
            : []),
        ...(openPreview
            ? [
                  {
                      id: "VIEW",
                      name: "Details",
                      icon: "View",
                      callback: openPreview,
                  },
              ]
            : []),
        ...(openDetails
            ? [
                  {
                      id: "DETAILS",
                      name: "Details",
                      icon: "Details",
                      callback: openDetails,
                  },
              ]
            : []),
        ...(goto && !readOnly
            ? [
                  {
                      id: "GOTO",
                      name: "Go to",
                      icon: "AccordianRight",
                      callback: goto,
                  },
              ]
            : []),
        ...(!(rule.id < 0) && deleteNode && !readOnly
            ? [
                  {
                      id: "DELETE",
                      name: "Delete",
                      icon: "Delete",
                      callback: deleteNode,
                  },
              ]
            : []),
        ...(deleteAllNodes && !containerAsDestination && !readOnly
            ? [
                  {
                      id: "DELETEALL",
                      name: "Delete All",
                      icon: "Delete",
                      callback: deleteAllNodes,
                  },
              ]
            : []),
    ];

    const nestedOptions = data => {
        return (
            <div className={style.dropDownPopUpWrapper} key={data.index}>
                <div>
                    {data?.option?.items.map((item, idx) => {
                        return (
                            <div
                                key={item.name}
                                className={`${style.singleOptionList}`}
                                onMouseDown={e => {
                                    e.stopPropagation();
                                    item.callback(rule);
                                }}
                            >
                                <div className="dp-parent-inline dp-parent-ver-center">
                                    <span>{item.name}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className={style.nodeActions} onClick={e => e.stopPropagation()}>
            <div className={style.dropdown}>
                {showDropdown && (
                    <Select
                        customClientRect={customClientRect}
                        width={172}
                        options={actionList}
                        isSelectOpen={true}
                        // multiSelect
                        onLoadSelectReturn={false}
                        keyField="name"
                        idField="id"
                        portal={true}
                        portalElement={portalElement.querySelector(
                            ".react-flow__viewport"
                        )}
                        onSelect={e => {
                            setShowDropdown(false);
                            e?.items ? null : e.callback(rule);
                        }}
                        hideLabel={true}
                        CustomRender={obj => {
                            return !isEmpty(obj?.option?.items) ? (
                                <>
                                    <CustomTooltipRenderer
                                        data={`${[obj]}`}
                                        customRenderer={() =>
                                            nestedOptions(obj)
                                        }
                                        position="right"
                                        handlePromptMouseLeave={true}
                                        customClass={"w-100"}
                                        portalElement={portalElement.querySelector(
                                            ".react-flow__viewport"
                                        )}
                                        customClientRect={getCustomClientRect(
                                            portalElement.querySelector(
                                                ".react-flow__viewport"
                                            ),
                                            portalElement?.querySelector(
                                                rule?.entityType === "CONTAINER"
                                                    ? `.react-flow__node[data-id='${
                                                          "rule:" + rule?.id
                                                      }']`
                                                    : `.react-flow__node[data-id='${
                                                          "set:" +
                                                          rule?.ruleSetId
                                                      }']`
                                            ),
                                            rule?.entityType === "CONTAINER"
                                                ? 140
                                                : rule?.entitySubType ===
                                                  "AB_TEST"
                                                ? 84
                                                : 80,
                                            rule?.entityType === "CONTAINER"
                                                ? 380
                                                : rule?.entitySubType ===
                                                  "AB_TEST"
                                                ? 160
                                                : 250
                                        )}
                                    >
                                        <div
                                            className="dp-parent dp-parent-hor-space-between dp-parent-ver-center"
                                            onClick={e => e.stopPropagation()}
                                        >
                                            <div
                                                className={`ellipsis dp-parent dp-parent-ver-center w-100`}
                                            >
                                                <Icon
                                                    icon={obj.option.icon}
                                                    size={"14px"}
                                                    className={
                                                        "mr-5 circleIconColor"
                                                    }
                                                    enableHover={true}
                                                />
                                                {obj.option.name}
                                            </div>
                                            <div
                                                className="dp-parent"
                                                onMouseDown={e =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <Icon
                                                    icon={"RightArrow"}
                                                    size={"14px"}
                                                    className={
                                                        "mr-5 circleIconColor"
                                                    }
                                                    enableHover={true}
                                                />
                                            </div>
                                        </div>
                                    </CustomTooltipRenderer>
                                </>
                            ) : (
                                <span
                                    className={`dp-parent-inline dp-parent-ver-center ${
                                        obj.option.icon === "Delete" &&
                                        style.deleteIconWrapper
                                    }`}
                                >
                                    <Icon
                                        icon={obj.option.icon}
                                        size={"14px"}
                                        className={"mr-5 circleIconColor"}
                                        enableHover={true}
                                    />
                                    {obj.option.name}
                                </span>
                            );
                        }}
                        onSelectClose={() => {
                            setShowDropdown(false);
                        }}
                        extraOutsideHandler
                        overlayClassName="relative z-1000"
                        notooltip
                    />
                )}
            </div>
            {actionList.length > 1 ? (
                <div
                    onClick={
                        !readOnly
                            ? e => {
                                  e.stopPropagation();
                                  setShowDropdown(_ => !_);
                              }
                            : null
                    }
                    className={`dp-parent-inline ${
                        !readOnly ? "cursorPointer" : style.cursorDisable
                    }`}
                >
                    <Icon
                        icon={"More"}
                        enableHover={true}
                        size={"14px"}
                        className="circleIconColor"
                    />
                </div>
            ) : (
                actionList.map(action => (
                    <div
                        key={action.id}
                        onClick={e => {
                            e.stopPropagation();
                            action.callback(rule);
                        }}
                        className="cursorPointer"
                    >
                        <Icon
                            icon={action.icon}
                            enableHover={true}
                            size={"14px"}
                            className={`circleIconColor ${
                                action.icon === "Delete"
                                    ? "circleIconColor--delete"
                                    : ""
                            }`}
                        />
                    </div>
                ))
            )}
        </div>
    );
};

export const fetchTpKey = (targettingParams, sendActualKey) => {
    if (targettingParams?.key) {
        if (targettingParams.key.indexOf("q_") === 0 && !sendActualKey) {
            return "ans_2";
        }
        return targettingParams?.key;
    }
    if (
        targettingParams?.type !== "EXPRESSION" &&
        Array.isArray(targettingParams?.value) &&
        targettingParams?.value?.length
    ) {
        return fetchTpKey(targettingParams?.value[0], sendActualKey);
    }
};

export const fetchTpObj = (targettingParams, availableTargetingParams) => {
    return availableTargetingParams?.find(_ => {
        const key = fetchTpKey(targettingParams);
        return _.name === key;
    });
};

export const renderTargetingNode = (
    targettingParams,
    availableTargetingParams,
    withIcon = true
) => {
    const option = fetchTpObj(targettingParams, availableTargetingParams);

    return (
        <>
            {withIcon && (
                <Icon
                    icon={option?.icon}
                    fallbackIcon="Default"
                    size="18px"
                    className="circleIconColor"
                />
            )}
            <span className="ml-5 mr-5">{option?.opsName}</span>
        </>
    );
};
export const getSingleChildNodes = (rule, rules, hiddenNodes) => {
    let resultRules = [];
    const id =
        rule.entityType === EntityType.ROUTER ||
        rule.entitySubType === EntityType.ROUTER
            ? null
            : rule.id;
    const parentNode = rules.find(_ => _.nextSetId === rule.ruleSetId);
    getCurrentNodeTree(rules, resultRules, rule.ruleSetId, id);
    const allChildIds = resultRules.map(_ => _.id);
    const restRules = rules.filter(
        _ =>
            !allChildIds.includes(_.id) &&
            parentNode?.id !== _.id &&
            _.entityType !== EntityType.COLLAPSED &&
            !hiddenNodes.includes(_.id)
    );
    let childRulesWithMultipleParents = resultRules.filter(
        _ =>
            _.id !== rule.id &&
            restRules.findIndex(r => r.nextSetId === _.ruleSetId) !== -1
    );
    let idsToRemove = [];
    let individualChildTrees = {};
    childRulesWithMultipleParents.forEach(_ => {
        let res = [];
        getCurrentNodeTree(rules, res, _.ruleSetId);
        individualChildTrees[_.id] = res;
        idsToRemove.push(...res.map(r => r.id));
    });

    idsToRemove = [...new Set(idsToRemove)];
    const filteredIds = allChildIds.filter(_ => !idsToRemove.includes(_));
    const filteredRules = rules.filter(_ => filteredIds.includes(_.id));
    childRulesWithMultipleParents = childRulesWithMultipleParents.filter(_ => {
        return Object.keys(individualChildTrees).every(childTreeId => {
            const hasTreeParent =
                individualChildTrees[childTreeId].findIndex(
                    tn => tn.nextSetId === _.ruleSetId
                ) !== -1;
            if (hasTreeParent) {
                return (
                    filteredRules.findIndex(
                        tn => tn.nextSetId === _.ruleSetId
                    ) !== -1
                );
            }

            return !hasTreeParent;
        });
    });

    // const rRestRules = rules.filter(_ => filteredIds.includes(_.id));
    // let rChildRulesWithMultipleParents = rRestRules.filter(
    //     _ =>
    //         _.id !== rule.id &&
    //         childRulesWithMultipleParents.findIndex(
    //             r => r.nextSetId === _.ruleSetId
    //         ) !== -1
    // );
    // childRulesWithMultipleParents = [
    //     ...rChildRulesWithMultipleParents,
    //     ...childRulesWithMultipleParents,
    // ];
    return [filteredIds, filteredRules, childRulesWithMultipleParents];
};

export const beautifyTargettingParams = (
    targettingParams,
    availableTargetingParams,
    isDarkMode,
    possibleValues = []
) => {
    const tpObj = fetchTpObj(targettingParams, availableTargetingParams);

    if (possibleValues.length) {
        tpObj.possibleValues = possibleValues;
    }

    let beautifiedTargettingParams = "";
    const wrapLimit = 20;

    if (targettingParams.type == "AB SPLIT") {
        beautifiedTargettingParams = `${targettingParams.value}%`;
        return beautifiedTargettingParams;
    }

    if (targettingParams.type == "EXPRESSION") {
        const tp = tpObj?.possibleValues?.find(
            _ => _.id === targettingParams.value
        );
        const logo = get(tp, `logo`, "");
        const customIcon = isDarkMode
            ? get(tp, `whiteIcon`, get(tp, `icon`, ""))
            : get(tp, `icon`, "");
        beautifiedTargettingParams = (
            <span>
                <span className="dp-parent-inline dp-parent-ver-center">
                    {customIcon && logo ? (
                        <LogoComponent
                            name={customIcon}
                            size={12}
                            className="mr-5"
                        />
                    ) : customIcon ? (
                        <Icon icon={customIcon} size="15px" className="mr-5" />
                    ) : (
                        ""
                    )}
                </span>

                <span>{tp?.label || targettingParams.value}</span>
            </span>
        );
    } else if (targettingParams.type == "AND") {
        beautifiedTargettingParams = targettingParams.value
            .map(t =>
                beautifyTargettingParams(
                    t,
                    availableTargetingParams,
                    isDarkMode,
                    possibleValues
                )
            )
            .reduce((prev, curr) => {
                // if (prev.length + curr.length + 3 > wrapLimit) prev += "\n";
                return (
                    <span>
                        {prev} & {curr}
                    </span>
                );
            });
    } else if (targettingParams.type == "OR") {
        const items = targettingParams.value.map(t =>
            beautifyTargettingParams(
                t,
                availableTargetingParams,
                isDarkMode,
                possibleValues
            )
        );
        beautifiedTargettingParams = (
            <span>
                {items[0]}
                <span>
                    {items.length - 1 !== 0 ? " + " + (items.length - 1) : ""}
                </span>
            </span>
        );

        // beautifiedTargettingParams = targettingParams.value
        //     .map(t =>
        //         beautifyTargettingParams(
        //             t,
        //             availableTargetingParams,
        //             isDarkMode,
        //             possibleValues
        //         )
        //     )
        //     .reduce((prev, curr) => {
        //         // if (prev.length + curr.length + 3 > wrapLimit) prev += "\n";
        //         return (
        //             <span>
        //                 {prev} , {curr}
        //             </span>
        //         );
        //     });
    }

    return beautifiedTargettingParams;
};

export const removeColorFromRules = rules => {
    return rules?.map(_ => {
        if (_.histClass === "isDeleted") {
            _.isActive = false;
        }
        delete _.histClass;
        return _;
    });
};
