import { assign, cloneDeep, get } from "lodash";
import { convertSegments } from "./surveyUtils";

export const getQuestionList = (item, fromCreatePage = false) => {
    const list = get(
        item,
        fromCreatePage
            ? "data.pages.0.elements"
            : "segment.data.pages.0.elements",
        []
    );
    return list.map(question => ({
        questionName: get(question, "name", ""),
        questionTitle: get(question, "title", ""),
        optionList: get(question, "choices", []).map(choice => ({
            key: choice.text,
            value: choice.value,
            hide_tcpa: !get(choice, "hide_tcpa", false),
        })),
        tcpa: get(question, "tcpa", {}),
    }));
};
export const getSegmentAd = (type, id, adUnits, offerGroups) => {
    if (type === "AdUnit") {
        const adUnit = adUnits.find(au => au.id == id) || {};
        return get(adUnit, "name", "")
            ? get(adUnit, "name", "")
            : get(adUnit, "id", "");
    }
    return get(
        offerGroups.find(og => og.id == id),
        "name",
        ""
    );
};
export const getSegmentAdObject = (type, id, adUnits, offerGroups) => {
    if (type === "AdUnit") return adUnits.find(au => au.id == id) || {};
    return offerGroups.find(og => og.id == id) || {};
};

export const getSelectedMappedSegments = (segments, selectedSegmentIds, id) => {
    if (!selectedSegmentIds.length) return [];
    return selectedSegmentIds.map((selectedSegmentId, index) => {
        const segment = segments.find(_ => _.id === selectedSegmentId);
        let configInfo = null;
        if (segment.hasOffer) {
            configInfo = {};
            if (segment.type == "Offer Group") {
                configInfo.leadData = {
                    category: get(segment, "AdObject.hnpCategory.name", ""),
                    questionConfig: [],
                };
                get(segment, "questionConfig", []).map(config => {
                    let object = {
                        questionName: get(config, "questionName", ""),
                        validAnswers: get(config, "validAnswers", []),
                        matchType: get(config, "matchType", ""),
                    };
                    configInfo.leadData.questionConfig.push(object);
                });
            }
            configInfo = {
                ...configInfo,
                id: get(segment, "AdObject.id", ""),
                extras: segment.Extras,
                adType: segment.type === "Ad Unit" ? "AdUnit" : "OfferGroup",
            };
        }
        return {
            pathId: id ? +id : null,
            index: index,
            hasOffer: segment.hasOffer,
            segmentId: segment.id,
            configInfo,
        };
    });
};

export const getTCPA = (data, updatedQuestionsData) => {
    if (data && updatedQuestionsData) {
        let temp = JSON.parse(JSON.stringify(data));
        if (temp.pages?.length) {
            temp.pages[0]?.elements.map((elem, index) => {
                elem.tcpa = updatedQuestionsData[index].tcpa;
            });
        }
        return temp;
    } else {
        return data;
    }
};

export const setSelectedSurveyPath = (
    itemsArr,
    segments = [],
    adUnits,
    offerGroups,
    fromCreatePage = false
) => {
    let newCopy = itemsArr;
    for (let segment of segments) {
        let itemsArrCopy = cloneDeep(newCopy),
            itemIndex = itemsArr.findIndex(
                item => item.id === segment.segmentId
            ),
            itemArrSegment = assign({}, get(itemsArr, itemIndex, {}));
        itemArrSegment.hasOffer = segment.hasOffer;
        itemArrSegment.type = !segment.hasOffer
            ? null
            : get(segment, "configInfo.adType", "") === "AdUnit"
            ? "Ad Unit"
            : "Offer Group";
        itemArrSegment.Extras = segment.hasOffer
            ? get(segment, "configInfo.extras", [])
            : [];
        itemArrSegment.Ad = segment.hasOffer
            ? getSegmentAd(
                  segment.configInfo.adType,
                  segment.configInfo.id,
                  adUnits,
                  offerGroups
              )
            : null;
        itemArrSegment.AdObject = segment.hasOffer
            ? getSegmentAdObject(
                  segment.configInfo.adType,
                  segment.configInfo.id,
                  adUnits,
                  offerGroups
              )
            : {};
        itemArrSegment.questionsList = getQuestionList(segment.segment, true);

        itemArrSegment.data = getTCPA(
            itemArrSegment.data,
            itemArrSegment.questionsList
        );

        itemArrSegment.questionConfig =
            segment.hasOffer && segment.configInfo.adType == "OfferGroup"
                ? get(segment, "configInfo.leadData.questionConfig", [])
                : [];

        itemsArrCopy[itemIndex] = {
            ...itemsArrCopy[itemIndex],
            ...itemArrSegment,
        };
        newCopy = itemsArrCopy;
    }
    return fromCreatePage
        ? newCopy
        : newCopy.map(item => {
              return !item.hasOffer ? convertSegments(item) : item;
          });
};
