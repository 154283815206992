import { isEmpty, map } from "lodash";
import { uploadDateFormat } from "./dateUtils";
import { cloneDeep } from "lodash";
import {
    getPageName,
    localStorageRecentFilterData,
    localStorageRecentSearchData,
} from "./localStorageUtils";
import { getRequiredObject } from "./globalUtils";
import { paginationParams } from "./paginationUtils";

const addEscapeSequenceForValues = value => {
    let updatedValue = value;
    if (typeof value === "string") {
        updatedValue = value?.replace("_", "\\_");
    }
    return updatedValue;
};

export const getFilterValue = (filterOperator, filterTerm) => {
    let value = "";
    if (filterOperator === "ilike") value = `%${filterTerm}%`;
    if (filterOperator === "like") value = [filterTerm]; //Used for Datastore cases
    if (filterOperator === "=") value = +filterTerm;
    if (filterOperator === "in") value = [[filterTerm]];
    if (filterOperator === "ilike=") value = [`%${filterTerm}%`];
    if (filterOperator === "like_string") value = filterTerm;
    if (filterOperator === "string=") value = filterTerm;
    if (filterOperator === "array=") value = [filterTerm];
    value = addEscapeSequenceForValues(value);
    return value;
};

export const getScrubFilterValue = (filterOperator, filterTerm, type) => {
    let value = "";
    if (filterOperator === "prefix") {
        value = `${type}_source_${filterTerm}`;
    } else value = filterTerm;
    return value;
};

export const getDateBetweenArray = date => {
    let { startDate, endDate } = date;
    let formattedStartDate = uploadDateFormat(startDate);
    let formattedEndDate = uploadDateFormat(endDate);
    let value = [];
    value[0] = formattedStartDate;
    value[1] = formattedEndDate;
    return value;
};

export const getFilterOperator = filterOperator => {
    let operator = filterOperator;
    if (filterOperator === "ilike=") operator = "ilike"; // Special case as GO service filters
    if (filterOperator === "like_string") operator = "like";
    if (filterOperator === "string=" || filterOperator === "array=")
        operator = "=";
    return operator;
};

const createTextFilterObj = (filterKey, filterOperatorData, filterTerm) => {
    const params = {};
    let filters = [];
    filterKey.forEach((key, i) => {
        let value = getFilterValue(filterOperatorData[i], filterTerm.trim());
        let filterOperator = getFilterOperator(filterOperatorData[i]);
        if (value && filterOperator !== ">=<") {
            let result = {
                k: filterKey[i],
                v: value,
                op: filterOperator,
            };
            filters.push(result);
        }
        //Special case for datastore filters
        if (value && filterOperator === ">=<") {
            let filter1 = {
                k: filterKey[i],
                v: value && value[0],
                op: ">=",
            };
            filters.push(filter1);
            let filter2 = {
                k: filterKey[i],
                v: value && value[1],
                op: "<",
            };
            filters.push(filter2);
        }
    });
    params["filters"] = filters;
    return params;
};

const getStatusVal = val => {
    let path = getPageName();
    let value = "active";
    if (path.includes("offerpath")) {
        val === "active" ? (value = true) : (value = false);
    } else if (path.includes("roas")) {
        val === "active" ? (value = [true]) : (value = [false]);
    } else value = [val];
    return value;
};

const getStatusKey = () => {
    let path = getPageName();
    let value = "";
    if (path.includes("offerpath")) {
        value = "isActive";
    } else value = "status";
    return value;
};

const getValue = (operator, val, isArray = false) => {
    let finalValue = "";
    const value = addEscapeSequenceForValues(val);
    if (operator === "ilike") finalValue = `%${value}%`;
    else if (isArray && operator === "=") finalValue = [val];
    else if (operator === "=") finalValue = val;
    else finalValue = `%${value}%`;
    return finalValue;
};

// Check for go lang services as thi require array of values even if single
export const isArrayValue = element => {
    return (
        element?.id === "gift_card_id" ||
        element?.id === "giftCardID" ||
        element?.id === "dealCategoryID" ||
        element?.id === "Name" ||
        element?.id === "Campaign" ||
        element?.id === "Publisher"
    );
};

export const getFilterObjBasedOnTag = (data, filterObjData) => {
    let tagFilterData = [];
    data.forEach(element => {
        if (!isEmpty(element?.filterTag)) {
            element?.filterTag.forEach(el => {
                if (el.parentType === "Status" && el.isEnabled) {
                    // {k: "status", op: "=", v: ["active"]}
                    let key = getStatusKey();
                    let statusObj = { k: key, op: "=", v: ["active"] };
                    let statusVal = el?.status;
                    statusVal = getStatusVal(statusVal);
                    statusObj.v = statusVal;
                    tagFilterData["status"] = statusObj;
                } else if (el?.operator && el?.isEnabled) {
                    const isArray = isArrayValue(element);
                    let statusObj = {
                        k: element?.k || "id",
                        op: el.operator,
                        v: getValue(el.operator, el.name, isArray),
                    };
                    tagFilterData[element.id] = statusObj;
                } else if (
                    !isEmpty(element?.filterValues) &&
                    !isEmpty(element?.filterValues[0].value)
                ) {
                    // {k: "sid", op: "in", v: ["active", "sss"]}
                    if (element?.filterValues[0].isEnabled) {
                        let valuesArray = element?.filterValues[0].value;
                        const values = valuesArray.map(reqArr => reqArr.id);
                        let statusObj = {
                            k: element.k,
                            op: element.op,
                            v: values,
                        };
                        tagFilterData[element.id] = statusObj;
                    } else {
                        tagFilterData[element.id] = [];
                    }
                } else {
                    tagFilterData["status"] = [];
                }
            });
        } else if (element?.isEnabled) {
            // const filterObj = getFilterParams(element?.path, element, );
            const filterObj = filterObjData;
            const filterData = createTextFilterObj(
                filterObj.filterKey,
                filterObj.filterOperator,
                element.name
            );
            tagFilterData = filterData.filters;
        }
    });
    return tagFilterData;
};

const checkIfSearchTagPresent = filterApplied => {
    const isPresentIndex = filterApplied.findIndex(x => x.type === "searchTag");
    let present = false;
    if (isPresentIndex > -1) {
        present = true;
    }
    return { present, isPresentIndex };
};

export const getUpdatedFilterParams = async (
    pagination,
    tagFilters,
    filters,
    filterParamsList
) => {
    const params = paginationParams(pagination);
    const updatedParams = getUpdatedParams(tagFilters, params, filters);
    const filterParamsData = getRequiredObject(filterParamsList, updatedParams);
    return filterParamsData;
};

export const checkIfSameKeyPresent = (filterApplied, key, isCustom = false) => {
    let sameKeyTags = filterApplied.filter(x => x.k === key);
    const isPresentTagIndex =
        (sameKeyTags.length > 1 && isCustom) ||
        (!isEmpty(sameKeyTags) &&
            sameKeyTags[0]?.type === "searchTag" &&
            isCustom) ||
        !isCustom
            ? filterApplied.findIndex(x => x.k === key)
            : -1;
    let presentTag = false;
    if (isPresentTagIndex > -1) {
        presentTag = true;
    }
    return { presentTag, isPresentTagIndex };
};

export const filterTagData = (data, page) => {
    let filteredData = data.filter(el => el.page === page);
    return filteredData;
};

export const filterRecentFilter = filterApplied => {
    let filteredData = filterApplied.filter(
        el => el?.filterTag[0]?.isEnabled || el?.filterValues[0]?.isEnabled
    );
    return filteredData;
};

export const saveRecentFilterToLocalStorage = filterApplied => {
    const pageName = getPageName();
    let recentFilter = {};
    const { initialValue, pinKey, foundIndexRecentFilter } =
        localStorageRecentFilterData();
    let finalRecentFilter = initialValue ? initialValue : [];
    if (foundIndexRecentFilter > -1) {
        let recentFilterData = initialValue?.[foundIndexRecentFilter];
        let initialFilterData = recentFilterData.filterApplied;

        let newtagData = initialFilterData;
        filterApplied.forEach(element => {
            let newTagElement = element;
            const foundIndex = initialFilterData?.findIndex(
                x => x.id === newTagElement?.id
            );

            if (foundIndex > -1) {
                let tempTag = initialFilterData[foundIndex];
                let updatedTag = tempTag;
                updatedTag = {
                    ...tempTag,
                    filterTag: newTagElement?.filterTag,
                    filterValues: newTagElement?.filterValues,
                };
                newTagElement = updatedTag;
                newtagData[foundIndex] = newTagElement;
            } else {
                newtagData = initialFilterData;
                (newTagElement?.filterTag[0]?.isEnabled ||
                    newTagElement?.filterValues[0]?.isEnabled) &&
                    newtagData.unshift(newTagElement);
            }
        });
        let finalTagData = filterTagData(newtagData, pageName);
        recentFilter = {
            id: pageName,
            filterApplied: finalTagData,
        };
        initialValue[foundIndexRecentFilter] = recentFilter;
        finalRecentFilter = initialValue;
    } else {
        let finalTagData = filterTagData(filterApplied, pageName);
        recentFilter = {
            id: pageName,
            filterApplied: finalTagData,
        };
        const newTagElement = filterApplied[0];
        (newTagElement?.filterTag[0]?.isEnabled ||
            newTagElement?.filterValues[0]?.isEnabled) &&
            finalRecentFilter.unshift(recentFilter);
    }
    !isEmpty(filterApplied) &&
        localStorage.setItem("recentFilter", JSON.stringify(finalRecentFilter));
};

export const createTagUtil = (filterApplied, params, filterParamsData) => {
    let sD = {};
    let pageName = getPageName();
    // if (pageName === "advScrubdashboard" || pageName === "pubScrubdashboard")
    //     pageName = "scrubdashboard"; //Working on client side filter
    if (params?.id && params?.type === "create") {
        var foundIndex = filterParamsData.findIndex(x => x.id === params.id);
        sD["id"] = params.id;
        sD["name"] = params.name;
        sD["k"] = params.key || "name";
        sD["op"] = params.operator || "ilike";
        sD["filterTag"] = [];
        sD["filterValues"] = [];
        sD["custom"] = params.custom || false;
        sD["filterType"] = params.filterType || "multiSelectEditable"; //Todo: Check if this can be used for across filters
        filterApplied.push(sD);
    } else if (params?.id && params?.type === "searchTag") {
        var foundIndex = filterParamsData.findIndex(x => x.id === params.id);
        sD["path"] = getPageName();
        sD["id"] = params.id;
        sD["name"] = params.name;
        sD["k"] = params.key || "name";
        sD["op"] = params.operator || "ilike";
        sD["type"] = "searchTag";
        sD["isEnabled"] = true;
        sD["filterTag"] = [];
        sD["filterValues"] = [];
        //Check if already with k name is there if yes then remove and unshift to 1st position
        const { isPresentIndex, present } =
            checkIfSearchTagPresent(filterApplied);
        if (present) {
            filterApplied.splice(isPresentIndex, 1);
        }
        // filterApplied.push(sD);
        //Set the search term to ""
        //Also check if in tagFilters k name is there then remove that
        const { isPresentTagIndex, presentTag } = checkIfSameKeyPresent(
            filterApplied,
            params?.key || "name"
        );
        if (presentTag) {
            filterApplied.splice(isPresentTagIndex, 1);
        }
        filterApplied.unshift(sD);
        let recentSearch = {};
        const { initialValue, pinKey, foundIndexRecentSearch } =
            localStorageRecentSearchData();
        let finalRecentSearch = initialValue ? initialValue : [];
        if (foundIndexRecentSearch > -1) {
            let recentSearchData = initialValue?.[foundIndexRecentSearch];
            let initialSearchData = recentSearchData.searchData;
            const foundIndexId = initialSearchData?.findIndex(
                x => x.id === params.id
            );
            if (foundIndexId === -1) initialSearchData.unshift(sD);
            recentSearchData = {
                ...recentSearchData,
                searchData: initialSearchData,
            };
            finalRecentSearch[foundIndexRecentSearch] = recentSearchData;
            // finalRecentSearch = initialValue
        } else {
            recentSearch = {
                id: pageName,
                searchData: [sD],
            };
            finalRecentSearch.unshift(recentSearch);
        }
        localStorage.setItem("recentSearch", JSON.stringify(finalRecentSearch));
        //Add to recentSearch for specific page
        //Keep on adding till 5 elements
    } else if (params?.type === "update" && !params?.isOperator) {
        let tempTagData = filterApplied;
        var foundIndex = tempTagData.findIndex(x => x.id === params.parentType);

        let tempFilter = !isEmpty(tempTagData[foundIndex])
            ? cloneDeep(tempTagData[foundIndex]["filterTag"])
            : [];
        if (params.parentType === "Status") {
            tempFilter = [];
            let initialParams = cloneDeep(params);
            let searchFilter = {
                ...initialParams,
                isEnabled: true,
            };
            tempFilter.push(searchFilter);
            let recentFilter = {};
            const { initialValue, pinKey, foundIndexRecentFilter } =
                localStorageRecentFilterData();
            let finalRecentFilter = initialValue ? initialValue : [];
            if (foundIndexRecentFilter > -1) {
                let recentFilterData = initialValue?.[foundIndexRecentFilter];
                let initialFilterData = recentFilterData.filterApplied[0];

                let updatedFilterTag = initialFilterData?.filterTag;
                updatedFilterTag = [searchFilter];
                let newFilterObj = {
                    ...initialFilterData,
                    filterTag: updatedFilterTag,
                };
                initialValue[foundIndexRecentFilter].filterApplied = [
                    newFilterObj,
                ];
                finalRecentFilter = initialValue;
            } else {
                tempTagData[foundIndex] = {
                    ...tempTagData[foundIndex],
                    filterTag: tempFilter,
                };
                recentFilter = {
                    id: pageName,
                    filterApplied: tempTagData,
                };
                finalRecentFilter.unshift(recentFilter);
            }
            localStorage.setItem(
                "recentFilter",
                JSON.stringify(finalRecentFilter)
            );
            //Add to recentFilter LS for specific page
            //Here if found update the values only
        } else if (params?.operator) {
            if (!isEmpty(tempFilter)) {
                tempFilter = [];
            }
            tempFilter[0] = { ...params };
        } else if (
            !isEmpty(tempFilter) &&
            (tempFilter[0]?.id === "contains" ||
                tempFilter[0]?.id === "does not contain" ||
                tempFilter[0]?.id === "in")
        ) {
            if (tempFilter[0]?.operator) {
                tempFilter = [];
            }
            if (!isEmpty(tempFilter))
                tempFilter[0] = { ...tempFilter[0], ...params };
            else tempFilter = { ...params };
        } else {
            if (tempFilter[0]?.operator) {
                tempFilter = [];
            }
            tempFilter.push(params);
        }
        tempTagData[foundIndex] = {
            ...tempTagData[foundIndex],
            filterTag: tempFilter,
            page: pageName,
        };

        filterApplied = tempTagData;
        saveRecentFilterToLocalStorage(filterApplied);
    } else if (params?.type === "update" && params?.isOperator) {
        let tempTagData = filterApplied;
        var foundIndex = tempTagData.findIndex(x => x.id === params.parentType);
        let tempFilter = cloneDeep(tempTagData[foundIndex]?.["filterValues"]);
        tempFilter[0] = {
            ...tempFilter[0],
            ...params,
            operator: params.name,
        };
        tempTagData[foundIndex] = {
            ...tempTagData[foundIndex],
            filterValues: tempFilter,
        };
        filterApplied = tempTagData;
        saveRecentFilterToLocalStorage(filterApplied);
    } else if (params?.type === "updateValues") {
        let tempTagData = filterApplied;
        var foundIndex = tempTagData.findIndex(x => x.name === params.type1);

        let tempFilter =
            cloneDeep(tempTagData[foundIndex]?.["filterValues"]) || [];

        if (!isEmpty(tempFilter)) {
            !isEmpty(params?.value)
                ? (tempFilter[0] = {
                      ...tempFilter[0],
                      ...params,
                      isEnabled: true,
                  })
                : (tempFilter[0] = {
                      ...tempFilter[0],
                      ...params,
                      isEnabled: false,
                  });
        } else {
            let initialParams = cloneDeep(params);
            tempFilter.push({ ...initialParams, isEnabled: true });
        }
        tempTagData[foundIndex] = {
            ...tempTagData[foundIndex],
            filterValues: tempFilter,
            page: pageName,
        };
        filterApplied = tempTagData;
        saveRecentFilterToLocalStorage(filterApplied);
    } else {
        let finalParams = Array.isArray(params)
            ? params[params.length - 1]
            : !isEmpty(params)
            ? params
            : filterApplied[filterApplied.length - 1];
        const { isPresentTagIndex, presentTag } = checkIfSameKeyPresent(
            filterApplied,
            finalParams?.k,
            true
        );
        filterApplied = Array.isArray(params)
            ? params
            : !isEmpty(params)
            ? [params]
            : filterApplied;
        if (presentTag) {
            if (filterApplied.length > 1)
                filterApplied.splice(isPresentTagIndex, 1);
        }
        saveRecentFilterToLocalStorage(filterApplied);
    }
    // saveRecentFilterToLocalStorage(filterApplied);
    return filterApplied;
};

export const toggleTagUtil = (filterApplied, params) => {
    let tempTagData = filterApplied;
    var foundIndex = tempTagData.findIndex(x => x.id === params.id);

    if (params.id === "Status") {
        let tempFilter = cloneDeep(tempTagData[foundIndex]["filterTag"]);
        let previousValue = tempFilter[0].isEnabled;
        tempFilter[0] = { ...tempFilter[0], isEnabled: !previousValue };
        tempTagData[foundIndex] = {
            ...tempTagData[foundIndex],
            filterTag: tempFilter,
        };
    } else if (params?.filterTag[0]?.operator) {
        let tempFilter = cloneDeep(tempTagData[foundIndex]?.["filterTag"]);
        let previousValue = tempFilter[0].isEnabled;
        tempFilter[0] = { ...tempFilter[0], isEnabled: !previousValue };
        tempTagData[foundIndex] = {
            ...tempTagData[foundIndex],
            filterTag: tempFilter,
        };
    } else {
        let tempFilter = cloneDeep(tempTagData[foundIndex]?.["filterValues"]);
        let previousValue = tempFilter[0].isEnabled;
        tempFilter[0] = { ...tempFilter[0], isEnabled: !previousValue };
        tempTagData[foundIndex] = {
            ...tempTagData[foundIndex],
            filterValues: tempFilter,
        };
    }

    filterApplied = tempTagData;
    return filterApplied;
};

const getFinalOperator = (tagFilters, params) => {
    let updatedParams = cloneDeep(params);
    let path = getPageName();
    //If only one length and type is searchTag return updated params
    if (tagFilters.length === 1 && tagFilters[0]?.type === "searchTag") {
        updatedParams = {
            ...updatedParams,
            filters_op: path.includes("feedmanager") ? "OR" : "AND",
        };
    }
    return updatedParams;
};

export const getUpdatedParams = (tagFilters, params, filterObjData) => {
    if (!isEmpty(tagFilters)) {
        params.filters = params.filters?.length ? params.filters : [];

        let finalTagData = [];
        tagFilters.forEach(element => {
            let finalFilterObj = [];
            let updatedTagData = cloneDeep(element);
            if (element?.filterTag && !isEmpty(element?.filterTag)) {
                finalFilterObj = getFilterObjBasedOnTag(
                    tagFilters,
                    filterObjData
                );
                if (!isEmpty(Object.values(finalFilterObj))) {
                    const filterArr = Object.values(finalFilterObj);
                    !isEmpty(filterArr) &&
                        filterArr.forEach(filter => {
                            var obj = params.filters.find(entry => {
                                return entry.k === filter.k;
                            });
                            if (!obj) {
                                !isEmpty(filter) && params.filters.push(filter);
                                updatedTagData = {
                                    ...updatedTagData,
                                    isEnabled: true,
                                };
                            }
                        });
                }
            } else if (element?.isEnabled) {
                finalFilterObj = getFilterObjBasedOnTag(
                    tagFilters,
                    filterObjData
                );
                if (!isEmpty(Object.values(finalFilterObj))) {
                    const filterArr = Object.values(finalFilterObj);
                    !isEmpty(filterArr) &&
                        filterArr.forEach(filter => {
                            var obj = params.filters.find(entry => {
                                return entry.k === filter.k;
                            });
                            if (!obj) {
                                !isEmpty(filter) && params.filters.push(filter);
                                updatedTagData = {
                                    ...updatedTagData,
                                    isEnabled: true,
                                };
                            }
                        });
                }
            }
            !isEmpty(updatedTagData) && finalTagData.push(updatedTagData);
        });
    }
    const finalParams = getFinalOperator(tagFilters, params);
    return finalParams;
};

export const fetchFilteredData = (data, currentElement = "") => {
    !isEmpty(data) &&
        data?.forEach(element => {
            if (element?.id === "Status" && !isEmpty(element?.filterTag)) {
                fetch = true;
            } else if (
                element?.id &&
                !isEmpty(element?.filterTag) &&
                !isEmpty(element?.filterValues) &&
                (element?.filterTag[0]?.isEnabled ===
                    element?.filterValues[0]?.isEnabled ||
                    element?.filterTag[0]?.isEnabled ||
                    element?.filterValues[0]?.isEnabled)
            ) {
                fetch = true;
            } else if (element?.isEnabled) fetch = true;
        });
    return fetch;
};

export const removeIncompleteTag = (data, current) => {
    let updatedTagData = [...data];
    const foundIndex = data.findIndex(x => x.name === current);
    if (foundIndex > -1) {
        let currentEle = data?.[foundIndex];
        if (
            currentEle?.filterTag[0]?.name &&
            currentEle?.filterTag[0]?.operator
        )
            return;
        else if (
            currentEle?.filterValues &&
            currentEle?.filterValues[0]?.value &&
            !isEmpty(currentEle?.filterValues[0]?.value)
        )
            return;
        else if (
            (currentEle?.id === "Status" && isEmpty(currentEle?.filterTag)) ||
            (!currentEle?.filterValues &&
                !currentEle?.filterValues[0]?.value &&
                currentEle?.id !== "Status") ||
            (isEmpty(currentEle?.filterValues[0]?.value) &&
                currentEle?.id !== "Status")
        ) {
            updatedTagData.splice(foundIndex, 1);
        } else return;
    }
    return updatedTagData;
};

export const deleteTag = data => {
    let updatedTag = [...data];
    let lastElement = updatedTag[updatedTag.length - 1];
    if (lastElement?.name === "Status") return;
    else updatedTag.splice(updatedTag.length - 1, 1);
    return updatedTag;
};

export const merge = (a, b, prop) => {
    var reduced = a.filter(aitem => {
        return !b.find(bitem => {
            return aitem[prop] === bitem[prop];
        });
    });
    return reduced.concat(b);
};

export const createFinalSelected = (data, selected) => {
    let tempData = data;
    let dataLength = tempData.length;
    for (let i = 0; i < selected.length; i++) {
        // const element = array[i];
        tempData = [
            ...tempData.slice(dataLength - 1),
            ...tempData.slice(0, dataLength - 1),
        ];
    }
    return tempData;
};

export const getOperatorForCustomTag = label => {
    let operator = "ilike";
    const pageName = getPageName();
    if (
        (label.toLowerCase().includes("name") ||
            label.toLowerCase().includes("phone") ||
            label.toLowerCase().includes("email") ||
            label.toLowerCase().includes("regex") ||
            label.toLowerCase().includes("advertiser name") ||
            label.toLowerCase().includes("site config name")) &&
        pageName !== "abtests"
    ) {
        operator = "ilike";
    } else {
        operator = "=";
    }
    return operator;
};

export const getOperatorNameForCustomTag = operator => {
    let operatorName = "contains";
    if (operator === "ilike") operatorName = "contains";
    else if (operator === "=") operatorName = "is";
    else operatorName = "contains";
    return operatorName;
};

export const defaultInitialStatusFilter = {
    id: "Status",
    name: "Status",
    k: "status",
    op: "=",
    filterTag: [
        {
            id: "active",
            name: "Active",
            status: "active",
            parentType: "Status",
            type: "update",
            type1: "active",
            isEnabled: true,
        },
    ],
    filterValues: [],
    page: "",
};

export const userActivities = [
    // { id: "lp_imp", name: "Landing Impression" },
    { id: "zip_sub", name: "Zip Submit" },
    { id: "hnp_lead_send", name: "Lead Send" },
    { id: "hnp_lead_success", name: "Lead Success" },
    { id: "hnp_lead_failure", name: "Lead failure" },
    { id: "email_sub", name: "Email Submitted" },
    { id: "usr_reg", name: "Registration" },
    { id: "survey_quest_imp", name: "Survey Question Seen" },
    { id: "sv_ans", name: "Survey Question Answered" },
    { id: "ad_clk", name: "Ad Clicked" },
    { id: "grat_imp", name: "Listings Seen" },
    { id: "grat_avail", name: "Listing Clicked" },
    { id: "ad_conv", name: "Ad Conversions" },
    { id: "sweeps_entry", name: "Sweeps Entry" },
    { id: "entry_confirmed", name: "Sweeps Entry Confirmed" },
    // { id: "slot_imp_v", name: "Offer Seen" },
    { id: "unsubscribe", name: "Unsubscribe" },
    { id: "gc_fulfil", name: "Gift Card Fulfilled" },
];

export const defaultActivityFilter = {
    id: "Activity",
    name: "Activity",
    k: "evt_id",
    op: "in",
    filterTag: [
        {
            id: "in",
            name: "contains",
            parentType: "Activity",
            type: "update",
            type1: "in",
            isEnabled: true,
        },
    ],
    filterValues: [
        {
            type: "updateValues",
            type1: "Activity",
            operator: "or",
            isEnabled: true,
            value: userActivities,
        },
    ],
    custom: false,
    page: "userJourney",
};

export const mapTargettingValues = data => {
    return data?.map(arr => ({
        id: arr?.id,
        label: arr?.label,
        name: arr?.label,
    }));
};

export const updateHeaderName = name => {
    return name.replace("-", " ");
};
