import { combineReducers } from "redux";
import adUnits from "./adUnits";
import surveys from "./surveys";
import surveyPaths from "./surveyPaths";
import offerPaths from "./offerPaths";
import questions from "./questions";
import categories from "./categories";
import leadDefinition from "./leadDefinition";
import offerGroups from "./offerGroups";
import roasConfigs from "./roasConfigs";
import abtests from "./abtests";
import urlGenerator from "./urlgenerator";
import surveyConfigs from "./surveyConfigs";
import feedManager from "./feedManager";
import featuremaps from "./featuremaps";
import featureSet from "./featureSet";
import configFeatureSet from "../survey/featureSets";
import reportingMetrics from "./reportingMetrics";
import giftCards from "./giftCards";
import dealCategories from "./dealCategories";
import offerWall from "./offerWall";
import offlineConversion from "./offlineConversion";
import mediaLibrary from "./logoLibrary";
import jt from "./jt";
import creative from "./creative";
import scrubDashboard from "./scrubDashboard";
import userJourney from "./userJourney";
import userJourneyDetail from "./userJourneyDetail";
import bulkScrub from "./bulkScrub";
import collectionDeals from "./collectionDeals";

const config = combineReducers({
    surveyConfigs,
    configFeatureSet,
});

const listings = combineReducers({
    adUnits,
    surveys,
    surveyPaths,
    offerPaths,
    questions,
    categories,
    leadDefinition,
    offerGroups,
    roasConfigs,
    abtests,
    urlGenerator,
    config,
    feedManager,
    featuremaps,
    featureSet,
    reportingMetrics,
    giftCards,
    dealCategories,
    offerWall,
    offlineConversion,
    mediaLibrary,
    jt,
    creative,
    scrubDashboard,
    userJourney,
    userJourneyDetail,
    bulkScrub,
    collectionDeals,
});

export default listings;
