import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    showLeftPanel: true,
    isDarkMode: false,
    showLeftNav: true,
    leftNavAutoExpandCollapse: false,
    showModifyColumnsPanel: false,
};

const globalConfigSlice = createSlice({
    name: "globalConfig",
    initialState,
    reducers: {
        toggleshowLeftPanel(state, action) {
            state.showLeftPanel = !state.showLeftPanel;
        },
        setShowLeftPanel(state, action) {
            state.showLeftPanel = action.payload;
        },
        toggleDarkMode(state, action) {
            state.isDarkMode = !state.isDarkMode;
        },
        setDarkMode(state, action) {
            state.isDarkMode = action.payload;
        },
        setShowLeftNav(state, action) {
            state.showLeftNav = action.payload;
        },
        leftNavExpandCollapseSwitch(state, action) {
            state.leftNavAutoExpandCollapse = action.payload;
        },
        setShowModifyColumnsPanel(state, action) {
            state.showModifyColumnsPanel = action.payload;
        },
    },
});

export const globalConfigActions = globalConfigSlice.actions;
export default globalConfigSlice.reducer;
