import s from "./CopyRenderer.module.scss";
import Tooltip from "../../../Shared/Tooltip/Tooltip";
import Icon from "../../../Shared/Icon/Icon";
import { useEffect, useRef, useState } from "react";

const CopyRenderer = ({
    children,
    content,
    noHover = false,
    customClass,
    hideCopyIcon = false,
}) => {
    const renderedComponent = useRef();
    const [tooltipMsg, setTooltipMsg] = useState("Copy");
    const [copyActive, setCopyActive] = useState(false);

    const [tooltipWidth, setTooltipWidth] = useState("50px");
    const [toCopy, setToCopy] = useState("");

    useEffect(() => {
        setToCopy(content ? content : renderedComponent?.current?.innerText);
    }, [content, renderedComponent]);

    function copyToClipboard() {
        navigator.clipboard.writeText(toCopy);
        setTooltipWidth("130px");
        setTooltipMsg("Copied successfully");
        setCopyActive(true);
        setTimeout(() => {
            setTooltipWidth("50px");
            setTooltipMsg("Copy");
            setCopyActive(false);
        }, 2000);
    }
    return (
        <div className={`${s.copyWrap}`} ref={renderedComponent}>
            <div>
                {toCopy !== "-" && toCopy !== "" && !hideCopyIcon && (
                    <div
                        className={`${s.iconWrap} ${
                            copyActive && !noHover ? s.iconWrap_active : ""
                        } ${noHover ? s.noHover : ""} copyWrap__icon`}
                        onClick={copyToClipboard}
                    >
                        <Tooltip
                            width={tooltipWidth}
                            message={tooltipMsg}
                            position="top"
                        >
                            <Icon
                                icon="Copy"
                                size="16px"
                                enableHover={true}
                                color="#74838F"
                            />
                        </Tooltip>
                    </div>
                )}
                <span className={`${customClass ? customClass : ""}`}>
                    {children}
                </span>
            </div>
        </div>
    );
};

export default CopyRenderer;
