import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { HNP_URL, COLLECTION, FLOWCATEGORIES } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialState = {
    list: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.FLOWCATEGORIES.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.FLOWCATEGORIES.filterOperator,
        filtersOperator: FILTERS.FLOWCATEGORIES.operator,
        filters: [],
    },
    createFlowCategory: {
        name: "",
        targettingParams: {},
        loading: false,
        mainLoader: false,
        showPrompt: true,
        offerGroupMapped: [],
    },
    createFlowCategoryBackup: {},
    searchFilter: {
        filterApplied: getInitialTagData([], "categories"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    historyData: [],
    historyDataRaw: [],
};

export const getFlowCategoriesData = createAsyncThunk(
    "listings/flowCategories/getFlowCategoriesData",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${FLOWCATEGORIES.GET_FLOWCATEGORIES_BYID}/${id}`
        );
        const data = get(response, "data.data", {});
        return data;
    }
);

export const getMappedOfferGroup = createAsyncThunk(
    "listings/flowCategories/getMappedOfferGroup",
    async (name, { getState, dispatch }) => {
        const response = await customFetch(
            `${FLOWCATEGORIES.FETCH_MAPPED_OFFERGROUPS}/${name}`
        );
        const data = get(response, "data.data", {});
        return data;
    }
);

export const createFlowCategory = createAsyncThunk(
    "listings/flowCategories/createCategory",
    async (data, { getState, dispatch }) => {
        dispatch(flowCategoriesActions.setMainLoader(true));
        const response = await customFetch(
            FLOWCATEGORIES.CREATE_FLOWCATEGORIES,
            "POST",
            data,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Flow lead category already modified while you were editing.",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message:
                                "Check History or reload Flow lead category.",
                        })
                    );
                } else if (
                    resData.message.includes("category already exists")
                ) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Lead Category with same name already exists.",
                            autoClose: 5000,
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to create Lead Category.",
                            autoClose: 5000,
                        })
                    );
                }
                dispatch(flowCategoriesActions.setMainLoader(false));
            }
        );
        if (response && get(response, "data.status", false) === "success") {
            dispatch(flowCategoriesActions.setMainLoader(false));
            dispatch(flowCategoriesActions.setShowPrompt(false));
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Created Lead Category Successfully",
                    autoClose: 5000,
                })
            );
        }
        if (!response || !response.data || !response.data.status !== "success")
            dispatch(flowCategoriesActions.setMainLoader(false));
        return response;
    }
);

export const updateFlowCategory = createAsyncThunk(
    "listings/flowCategories/updateCategory",
    async (data, { getState, dispatch }) => {
        dispatch(flowCategoriesActions.setMainLoader(true));
        const response = await customFetch(
            FLOWCATEGORIES.UPDATE_FLOWCATEGORIES,
            "POST",
            data,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Flow lead category already modified while you were editing.",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message:
                                "Check History or reload Flow lead category.",
                        })
                    );
                } else if (
                    resData.message.includes("category already exists")
                ) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Lead Category with same name already exists.",
                            autoClose: 5000,
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to create Lead Category.",
                            autoClose: 5000,
                        })
                    );
                }
                dispatch(flowCategoriesActions.setMainLoader(false));
            }
        );
        if (response && get(response, "data.status", false) === "success") {
            dispatch(flowCategoriesActions.setMainLoader(false));
            dispatch(flowCategoriesActions.setShowPrompt(false));
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Updated Flow Category Successfully",
                })
            );
        }
        return response;
    }
);

export const fetchFlowCategoriesData = createAsyncThunk(
    "listings/flowCategories/fetchCategories",
    async (refreshClicked, { getState, dispatch }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "categories.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "categories.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.FLOWCATEGORIES
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(categoriesSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "flowmanager.categories.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.FLOWCATEGORIES,
                filterParamsList
            );
        }
        const listingData = get(getState(), "flowmanager.categories.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                FLOWCATEGORIES.GET_ALL_FLOWCATEGORIES,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch hnp categories",
                        })
                    );
                    dispatch(
                        categoriesSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(
                flowCategoriesActions.setFilter(filterParamsData?.filters)
            );
            data = get(response, "data.data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.CATEGORIES}`;
            dispatch(categoriesSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Category`;
            dispatch(categoriesSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const deleteFlowCategory = createAsyncThunk(
    "flowmanager/flowCategories/deleteflowcategory",
    async (obj, { getState, dispatch }) => {
        const response = await customFetch(
            `${FLOWCATEGORIES.DELETE_FLOWCATEGORIES}/${obj.hnpId}`,
            "GET",
            {},
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Cannot delete!")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Failed to delete, this Flow category is mapped to some containers`,
                        })
                    );
                }
            }
        );
        if (get(response, "data.status", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Successfully deleted the category with id - ${obj.hnpId}`,
                })
            );
            dispatch(fetchFlowCategoriesData(true));
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete the category with id - ${obj.hnpId}`,
                })
            );
        }
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/categories/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.categories.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.CATEGORIES.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchCategoryHistory = createAsyncThunk(
    "flowmanager/categories/categoryHistory",
    async id => {
        const response = await customFetch(
            `${FLOWCATEGORIES.FETCH_CATEGORY_HISTORY}/${id}`
        );
        return get(response, "data", []);
    }
);

const categoriesSlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        //create category actions
        setName(state, action) {
            state.createFlowCategory.name = action.payload;
        },
        setDesc(state, action) {
            state.createFlowCategory.desc = action.payload;
        },
        setShowPrompt(state, action) {
            state.createFlowCategory.showPrompt = action.payload;
        },
        setLoading(state, action) {
            state.createFlowCategory.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.createFlowCategory.mainLoader = action.payload;
        },
        resetData(state, action) {
            state.createFlowCategory = initialState.createFlowCategory;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchFlowCategoriesData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchFlowCategoriesData.fulfilled, (state, action) => {
            state.list = get(action.payload, "data", []);
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(getFlowCategoriesData.pending, (state, action) => {
            state.createFlowCategory.loading = true;
        });
        builder.addCase(getFlowCategoriesData.fulfilled, (state, action) => {
            const {
                hnpCategory = null,
                targettingParams = {},
                updatedAt = null,
            } = action.payload;
            state.createFlowCategory.name = hnpCategory;
            state.createFlowCategory.targettingParams = targettingParams;
            // state.createFlowCategory.loading = false;
            state.createFlowCategory.updatedAt = updatedAt;

            state.createFlowCategoryBackup = JSON.parse(
                JSON.stringify(state.createFlowCategory)
            );
        });
        builder.addCase(getMappedOfferGroup.fulfilled, (state, action) => {
            state.createFlowCategory.offerGroupMapped = action.payload;
            state.createFlowCategory.loading = false;
        });
    },
});

export const flowCategoriesActions = categoriesSlice.actions;
export default categoriesSlice.reducer;
