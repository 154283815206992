export const titlePathMap = {
    "/surveydetails/surveys/listings": "Surveys",
    "/surveydetails/surveys/create": "Create Survey",
    "/surveydetails/surveys/edit": "Edit Survey",

    "/surveydetails/surveypaths/listings": "Survey Paths",
    "/surveydetails/surveypaths/create": "Create Survey Path",
    "/surveydetails/surveypaths/edit": "Edit Survey Path",

    "/surveydetails/questions/listings": "Questions",
    "/surveydetails/questions/create": "Create Question",
    "/surveydetails/questions/edit": "Edit Question",

    "/surveydetails/offerpaths/listings": "Offer Paths",
    "/surveydetails/offerpaths/create": "Create Offer Path",
    "/surveydetails/offerpaths/edit": "Edit Offer Path",

    "/surveydetails/adunits/listings": "Ad Units",
    "/surveydetails/adunits/create": "Create Ad Unit",
    "/surveydetails/adunits/edit": "Edit Ad Unit",

    "/hnp/leaddefinition/listings": "Posting Spec",
    "/hnp/leaddefinition/create": "Create Posting Spec",
    "/hnp/leaddefinition/edit": "Edit Posting Spec",

    "/hnp/offergroups/listings": "Offer Groups",
    "/hnp/offergroups/create": "Create Offer Group",
    "/hnp/offergroups/edit": "Edit Offer Group",

    "/hnp/categories/listings": "Lead Categories",
    "/hnp/categories/create": "Create Lead Category",
    "/hnp/categories/edit": "Edit Lead Category",

    "/deals/giftcards/listings": "Promos",
    "/deals/giftcards/create": "Create Promo",
    "/deals/giftcards/edit": "Edit Promo",

    "/deals/categories/listings": "Categories",
    "/deals/categories/create": "Create Category",
    "/deals/categories/edit": "Edit Category",

    "/deals/offerwall/listings": "Offer Walls",
    "/deals/offerwall/create": "Create Offer Wall",
    "/deals/offerwall/edit": "Edit Offer Wall",

    "/configurations/abtests/listings": "A/B Tests",
    "/configurations/abtests/create": "Create A/B Test",
    "/configurations/abtests/edit": "Edit A/B Test",

    "/configurations/surveyconfigs/listings": "Survey Configs",
    "/configurations/surveyconfigs/create": "Create Survey Config",
    "/configurations/surveyconfigs/edit": "Edit Survey Config",

    "/configurations/featuremaps/listings": "Features",
    "/configurations/featuremaps/create": "Create Feature",
    "/configurations/featuremaps/edit": "Edit Feature",

    "/configurations/featuresets/listings": "Feature Sets",
    "/configurations/featuresets/create": "Create Feature Set",
    "/configurations/featuresets/edit": "Edit Feature Set",

    "/dashboards/roas/listings": "TMS",
    "/dashboards/roas/create": "Create TMS Config",
    "/dashboards/roas/edit": "Edit TMS Config",

    "/dashboards/urlgenerator/listings": "DTO",
    "/dashboards/urlgenerator/create": "Generate Url",
    "/dashboards/urlgenerator/edit": "Edit Url",

    "/dashboards/jobtype/listings": "JT",
    "/dashboards/jobtype/edit": "Edit JT",

    "/dashboards/feedmanager/listings": "Feed Manager",
    "/dashboards/feedmanager/create": "Create Feed",
    "/dashboards/feedmanager/edit": "Edit Feed",

    "/settings/users/listings": "Users",
    "/settings/users/create": "Create User",
    "/settings/users/edit": "Edit User",

    "/settings/user-group/listings": "User Groups",
    "/settings/user-group/create": "Create User Group",
    "/settings/user-group/edit": "Edit User Group",

    "/dashboards/offlineconversion/listings": "Offline Conversion",
    "/dashboards/logolibrary/listings": "Media Library",
    "/dashboards/scrubdashboard/listings/advertiser":
        "Advertiser Scrub Dashboard",
    "/dashboards/scrubdashboard/listings/publisher":
        "Publisher Scrub Dashboard",
    "/dashboards/userdashboard/listings": "User Dashboard",
    "/dashboards/userdashboard/details": "User Journey Detail",
    "/dashboards/advertiser/listings": "Advertiser",
    "/dashboards/advertiser/edit": "Edit Advertiser",
    "/dashboards/advertiser/create": "Create Advertiser",

    "/reporting/automationdashboard": "Automation Dashboard",

    "/surveydetails/creative/listings": "Creative",

    "/release": "Release Notes",
    "/login": "Login",

    "/flowmanager/question/listings": "Questions",
    "/flowmanager/question/create": "Create Question",
    "/flowmanager/question/edit": "Edit Question",

    "/flowmanager/container/listings": "Container",
    "/flowmanager/container/create": "Create Container",
    "/flowmanager/container/edit": "Edit Container",

    "/flowmanager/template/listings": "Feature Group",
    "/flowmanager/template/create": "Create Feature Group",
    "/flowmanager/template/edit": "Edit Feature Group",

    "/flowmanager/collection/listings": "Collection",
    "/flowmanager/collection/create": "Create Collection",
    "/flowmanager/collection/edit": "Edit Collection",

    "/flowmanager/flow/listings": "Flow",
    "/flowmanager/flow/create": "Create Flow",
    "/flowmanager/flow/edit": "Edit Flow",

    "/flowmanager/layout/listings": "Layout",
    "/flowmanager/layout/create": "Create Layout",
    "/flowmanager/layout/edit": "Edit Layout",

    "/flowmanager/campaign/listings": "Campaign",
    "/flowmanager/campaign/edit": "Edit Campaign",

    "/dashboards/campaign/listings": "Campaign",
    "/dashboards/campaign/create": "Create Campaign",
    "/dashboards/campaign/edit": "Edit Campaign",
};
