import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep, forEach, get, isEmpty, keys, isEqual } from "lodash";
import { ADS, HNP_URL, SURVEYS } from "../../constants/url";
import { FILTERS } from "../../constants/listingSearch";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import {
    getTargettingParams,
    mapTargettingParams,
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getQuestionList,
    getSelectedMappedSegments,
    setSelectedSurveyPath,
} from "../../utils/surveyPathUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialTargetParamsState = {
    region: [],
    countries: [],
    browsers: [],
    devices: [],
    os: [],
    genders: [],
    utmMediums: [],
    utmSources: [],
    utmCampaigns: [],
    utmContents: [],
    ageRange: [],
    keywords: [],
    availableSet: ["utmSources", "region", "keywords", "ageRange"],
    keyVals: [],
    timeOfDay: [],
    allowedDays: [],
    zipCodes: [],
    // spamCheck: {
    //     spamIP: false,
    //     category: [],
    // },
    userStatus: [],
    keywordCategories: [],
    city: [],
    pagination: {
        startOffset: 0,
        rowsCount: 10,
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.SURVEY_PATH.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.SURVEY_PATH.filterOperator,
        filtersOperator: FILTERS.SURVEY_PATH.operator,
        filters: [],
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

const initialState = {
    list: [],
    surveyList: [],
    createSurveyPath: {
        basics: {
            nameId: "",
            weight: "",
            statusEnabled: true,
            error: false,
            selectedSurveyId: null,
        },
        questionBank: {
            itemsArr: [],
            itemsArrOriginal: [], //original ref, not to be edited
            details: null,
            selected: [],
            selectedListActiveItem: "",
            deleted: [],
            selectedItems: [],
        },
        targettingParam: {
            targetParams: initialTargetParamsState,
            dropDownState: {
                plusDropdownData: "",
                showPlusDropdown: false,
                productList: [
                    {
                        id: 1,
                        firstName: "abcd",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: 2,
                        firstName: "eafgbh",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: 3,
                        firstName: "ijkl",
                        lastName: "rewardsurveyusa.com",
                    },
                    { id: 4, firstName: "mnop", lastName: "test@test.com" },
                    { id: 5, firstName: "qrst", lastName: "test@test.com" },
                    { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                    { id: 7, firstName: "yz", lastName: "test@test.com" },
                ],
                productListPlusData: [
                    {
                        id: "utmSources",
                        firstName: "UTM Sources",
                    },
                    { id: "region", firstName: "Region" },
                    {
                        id: "keywords",
                        firstName: "Keywords",
                    },
                    { id: "ageRange", firstName: "Age Range" },
                    { id: "genders", firstName: "Gender" },
                    {
                        id: "countries",
                        firstName: "Countries",
                    },
                    { id: "zipCodes", firstName: "Zip Codes" },
                    {
                        id: "timeOfDay",
                        firstName: "Time of Day",
                    },
                    {
                        id: "utmCampaigns",
                        firstName: "UTM Campaigns",
                    },
                    {
                        id: "utmContents",
                        firstName: "UTM Contents",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmMediums",
                        firstName: "UTM Mediums",
                    },
                    {
                        id: "os",
                        firstName: "Operating Systems",
                    },
                    { id: "browsers", firstName: "Browsers" },
                    { id: "devices", firstName: "Devices" },
                    {
                        id: "allowedDays",
                        firstName: "Allowed Days",
                    },
                    { id: "keyVals", firstName: "A/B Tests" },
                ],
            },
            zipCodeState: {
                csvFileName: "",
                csvFile: "",
                csvArray: [],
                zipCodeError: false,
                onDropActiveZipCode: false,
                // zipCode: [],
            },
            // error: false,
        },
        offerGroups: [],
        adUnits: [],
        segments: [],
        historyDataRaw: [],
        historyData: [],
    },
    createSurveyPathBackup: {},
    editId: null,
    // loading: false,
    loading: false,
    mainLoader: false,
    adTypeDataLoading: false,
    updatedAt: new Date(),
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.SURVEY_PATH.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.SURVEY_PATH.filterOperator,
        filtersOperator: FILTERS.SURVEY_PATH.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "surveypaths"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const historyListingsTable = createAsyncThunk(
    "listings/surveyPath/historyAdUnitListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${SURVEYS.FETCH_HISTORY_LISTINGS_SURVEYPATH}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchSurveys = createAsyncThunk(
    "listings/surveyPaths/surveys",
    async (_, { dispatch }) => {
        const response = await customFetch(SURVEYS.FETCH_SURVEYS, "GET");
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Surveys",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const fetchSurveyPathsData = createAsyncThunk(
    "listings/surveyPaths/fetchSurveyPathsListingData",
    async (refreshClicked, { getState, dispatch }) => {
        const pagination = get(
            getState(),
            "listings.surveyPaths.pagination",
            {}
        );
        const params = paginationParams(pagination);
        const { listings } = getState();

        let tagFilters = get(
            listings,
            "surveyPaths.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.SURVEY_PATH
        );

        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(surveyPathsSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.surveyPaths.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.SURVEY_PATH,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.surveyPaths.list", []);
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                SURVEYS.FETCH_SURVEY_PATH,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch survey paths list",
                        })
                    );
                    dispatch(
                        surveyPathsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(surveyPathsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.SURVEY_PATH}`;
            dispatch(surveyPathsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Survey Path`;
            dispatch(surveyPathsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchCreateSurveyPathData = createAsyncThunk(
    "listings/surveyPaths/create/fetchSurveyPathsQuestionBankData",
    async (_, { dispatch }) => {
        const responses = await fetchAll([
            customFetch(SURVEYS.GET_SEGMENTS, "GET"), //responses[0].data.data //Remove once all the flows are tested properly
            // customFetch(SURVEYS.GET_SEGMENTS,
            //   "POST",
            //   {},
            //   {},
            //   {},
            //   null,
            //   null,
            //   null
            // ),
            customFetch(ADS.GET_AD_UNITS, "GET"), //responses[1].data.data
            customFetch(HNP_URL.GET_OFFER_GROUPS_LIST, "GET"), //responses[2].data.data
        ]);
        if (
            get(responses, "0.data.data.length", 0) == 0 ||
            get(responses, "1.data.data.length", 0) == 0 ||
            get(responses, "2.data.data.length", 0) == 0
        )
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all required data!",
                })
            );
        return { responses };
    }
);

export const fetchSurveyPathDataById = createAsyncThunk(
    "listings/surveyPaths/getSurveyPathDetails",
    async ({ id, history }, { dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const response = await customFetch(
            `${SURVEYS.GET_SURVEY_PATH_DETAILS}/${id}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        let data = [];
        if (isError) {
            data = isErrorResponse;
        } else data = get(response, "data.data", {});
        if (isEmpty(data))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch survey path details",
                })
            );
        const status = get(data, "status", false);
        if (status === 404) {
            const message = get(data, "data.message", "");
            //If status is 404 and message is as below redirect
            if (message.includes("Error reading record")) {
                history.push("/404");
            }
        }
        return data;
    }
);

export const updateSurveyPathData = createAsyncThunk(
    "listings/surveyPaths/updateSurveyPathDetails",
    async (id, { getState, dispatch }) => {
        const params = getSurveyPathParams(getState(), id);
        params["id"] = +id;
        const response = await customFetch(
            SURVEYS.UPDATE_SURVEY_PATH_DETAILS,
            "PUT",
            params
        );
        const success = get(response, "data.success", false);
        if (!success)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update survey path!",
                })
            );
        else {
            saveSubQuestionMapping(getState, dispatch, response.data.data.id);
        }
        return response;
    }
);

const saveSubQuestionMapping = (getState, dispatch, surveyPathId) => {
    const itemsArray = JSON.parse(
        JSON.stringify(
            getState().listings.surveyPaths.createSurveyPath.questionBank
                .itemsArr
        ).replaceAll("{{newSurveyPathId}}", surveyPathId)
    );

    itemsArray.forEach(item => {
        //save tcpa data
        item.questionsList.forEach(ques => {
            if (ques.tcpa[surveyPathId] && ques.tcpa[surveyPathId].dirty) {
                const tcpaData = ques.tcpa[surveyPathId];
                delete tcpaData.dirty;
                tcpaData.segmentId = item.id;
                tcpaData.surveyPathID = surveyPathId;
                (async () => {
                    const response = await customFetch(
                        `${SURVEYS.UPDATE_TCPA}`,
                        "POST",
                        tcpaData
                    );
                    if (!get(response, "data.success", false))
                        dispatch(
                            toastListActions.setToastList({
                                type: "Error",
                                message: `Failed to update TCPA for segment id ${item.id}`,
                            })
                        );
                })();
            }
        });

        //save questions data
        const selectedQuestions =
            getState().listings.surveyPaths.createSurveyPath.questionBank
                .selected;
        if (item.dirty && selectedQuestions.includes(item.id)) {
            item.data.pages[0].elements = item.data.pages[0].elements.map(
                item => {
                    delete item.tcpa;
                    delete item.dirty;
                    return item;
                }
            );
            const subQuestionObj = { data: item.data, name: item.data.title };
            (async () => {
                const response = await customFetch(
                    `${SURVEYS.UPDATE_SEGMENT}/${item.id}`,
                    "POST",
                    subQuestionObj
                );

                if (!get(response, "data.success", false))
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Failed to update the segment! Please try again later!",
                        })
                    );
            })();
        }
    });
};

export const deleteSurveyPathData = createAsyncThunk(
    "listings/surveyPaths/deleteSurveyPathDetails",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.DELETE_SURVEY_PATH_DETAILS}/${id}`,
            "DELETE"
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete survey path!",
                })
            );
        return response;
    }
);

export const createSurveyPathData = createAsyncThunk(
    "listings/surveyPaths/createSurveyPathDetails",
    async (_, { getState, dispatch }) => {
        const params = getSurveyPathParams(getState());
        const response = await customFetch(
            SURVEYS.CREATE_SURVEY_PATH_DETAILS,
            "POST",
            params
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create survey path!",
                })
            );
        else {
            saveSubQuestionMapping(getState, dispatch, response.data.data.id);
        }

        return response;
    }
);

export const cloneSurveyPathData = createAsyncThunk(
    "listings/surveyPaths/cloneSurveyPath",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.CLONE_SURVEY_PATH_DETAILS}`,
            "POST",
            { id: id }
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to clone survey path!",
                })
            );
        return response;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/surveyPaths/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.surveyPaths.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.SURVEY_PATH.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const getSurveyPathParams = (state, id) => {
    const surveyPathState = state.listings.surveyPaths.createSurveyPath;
    const obj = {
        name: surveyPathState.basics.nameId,
        survey: { id: surveyPathState.basics.selectedSurveyId },
        segments: getSelectedMappedSegments(
            surveyPathState.questionBank.itemsArr,
            surveyPathState.questionBank.selected.filter(
                id => !surveyPathState.questionBank.deleted.includes(id)
            ),
            id
        ),
        targetParams: getTargettingParams(
            surveyPathState.targettingParam.targetParams,
            [],
            false,
            null,
            true,
            true
        ),
    };

    // obj.targetParams.spamCheck = {
    //     ...obj.targetParams.spamCheck,
    //     spamIP: obj.targetParams.spamCheck.spamIP.toString(),
    // };

    return obj;
};

const setLoadingComplete = state => {
    surveyPathsSlice.actions.loading(false);
    state.createSurveyPathBackup = state.createSurveyPath;
};

const surveyPathsSlice = createSlice({
    name: "surveyPaths",
    initialState,
    reducers: {
        setCreateNewPath(state, action) {
            state.createSurveyPath.basics.createNewPath = action.payload;
            if (state.createSurveyPath.basics.weight.length > 0) {
                state.createSurveyPath.basics.error =
                    typeof action.payload == "string" &&
                    action.payload.trim() == "";
            }
        },
        setSurveyPathBasicError(state, action) {
            state.createSurveyPath.basics.error = action.payload;
        },
        setSelectedSurveyPathNameId(state, action) {
            state.createSurveyPath.basics.nameId = action.payload;
        },
        setSelectedSurveyPathWeight(state, action) {
            state.createSurveyPath.basics.weight = action.payload;
            if (state.createSurveyPath.basics.nameId.length > 0) {
                state.createSurveyPath.basics.error =
                    typeof action.payload == "number" &&
                    action.payload.trim() == "";
            }
        },
        setSelectedSurveyPathStatus(state, action) {
            state.createSurveyPath.basics.statusEnabled = action.payload;
        },
        setSelectedItemsArr(state, action) {
            const { payload } = action;
            state.createSurveyPath.questionBank.itemsArr = payload;
        },
        setSelectedAdUnits(state, action) {
            const { payload } = action;
            state.createSurveyPath.adUnits = payload;
        },
        setSelectedOfferGroups(state, action) {
            const { payload } = action;
            state.createSurveyPath.offerGroups = payload;
        },
        setSelectedDetails(state, action) {
            const { payload } = action;
            state.createSurveyPath.questionBank.details = payload;
        },
        setSelectedQuestionBankItems(state, action) {
            const { payload } = action;
            state.createSurveyPath.questionBank.selected = payload;
            state.createSurveyPath.questionBank.selectedItems =
                state.createSurveyPath.questionBank.itemsArr.filter(
                    _ =>
                        payload.includes(_.id) &&
                        !state.createSurveyPath.questionBank.deleted.includes(
                            _.id
                        )
                );
        },
        setSelectedItems(state, action) {
            state.createSurveyPath.questionBank.selectedItems =
                state.createSurveyPath.questionBank.itemsArr.filter(_ =>
                    state.createSurveyPath.questionBank.selected.includes(_.id)
                );
        },
        setSelectedActiveListItem(state, action) {
            const { payload } = action;
            state.createSurveyPath.questionBank.selectedListActiveItem =
                payload;
        },
        setDeletedQuestionBankItems(state, action) {
            const { payload } = action;
            state.createSurveyPath.questionBank.deleted = payload;
            state.createSurveyPath.questionBank.selectedItems =
                state.createSurveyPath.questionBank.itemsArr.filter(
                    _ =>
                        !payload.includes(_.id) &&
                        state.createSurveyPath.questionBank.selected.includes(
                            _.id
                        )
                );
        },
        setSelectedSurveyPath(state, action) {
            const { payload } = action;
            state.createSurveyPath.questionBank.segments = get(
                payload,
                "segments",
                {}
            );
            let targetParams = cloneDeep(get(payload, "targetParams", {}));
            forEach(keys(targetParams), key => {
                if (targetParams[key] == null) {
                    targetParams[key] = [];
                }
            });
            state.createSurveyPath.targettingParam.targetParams = {
                ...targetParams,
                availableSet: keys(get(payload, "targetParams", {})).filter(
                    item =>
                        !!item &&
                        get(payload, `targetParams.${item}`, null) != null
                ),
            };
        },
        setTargetParams(state, action) {
            const { data } = action.payload;
            state.createSurveyPath.targettingParam.targetParams = { ...data };
        },
        setTargettingParamError(state, action) {
            state.createSurveyPath.targettingParam.error = action.payload;
        },
        setDropDownState(state, action) {
            const { data } = action.payload;
            state.createSurveyPath.targettingParam.dropDownState = { ...data };
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSelectedSurveyId(state, action) {
            state.createSurveyPath.basics.selectedSurveyId = action.payload;
        },
        loading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setCsvFileName(state, action) {
            const { data } = action.payload;
            state.createSurveyPath.targettingParam.zipCodeState.csvFileName =
                data;
        },
        setCsvFile(state, action) {
            const { data } = action.payload;
            state.createSurveyPath.targettingParam.zipCodeState.csvFile = data;
        },
        setCsvArray(state, action) {
            const { data } = action.payload;
            state.createSurveyPath.targettingParam.zipCodeState.csvArray = data;
        },
        setZipCodeError(state, action) {
            const { data } = action.payload;
            state.createSurveyPath.targettingParam.zipCodeState.zipCodeError =
                data;
        },
        setOnDropActiveZipCode(state, action) {
            const { data } = action.payload;
            state.createSurveyPath.targettingParam.zipCodeState.onDropActiveZipCode =
                data;
        },
        resetSurveyPath(state) {
            state.createSurveyPath.basics.nameId = "";
            state.createSurveyPath.basics.selectedSurveyId = null;
            state.createSurveyPath.targettingParam.targetParams =
                initialTargetParamsState;
            state.createSurveyPath.questionBank =
                initialState.createSurveyPath.questionBank;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setHistoryData(state, action) {
            state.createSurveyPath.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.createSurveyPath.historyDataRaw = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },

        setTcpaOptions(state, action) {
            const { segmentId, surveyPathId, index, optionsData } =
                action.payload;

            const questionsData =
                state.createSurveyPath.questionBank.itemsArr.map(item => {
                    if (item.id === segmentId) {
                        !item.questionsList[index].tcpa
                            ? (item.questionsList[index].tcpa = {})
                            : null;
                        item.questionsList[index].tcpa[surveyPathId] =
                            optionsData;

                        return { ...item };
                    }
                    return { ...item };
                });

            state.createSurveyPath.questionBank.itemsArr = questionsData;
        },
        setClonedItem(state, action) {
            state.list = action.payload;
        },
        setDeletedItem(state, action) {
            state.list = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSurveys.pending, state => {});
        builder.addCase(fetchSurveys.fulfilled, (state, action) => {
            state.surveyList = action.payload.map(survey => ({
                id: survey.id,
                name: `${survey.id}: ${survey.name}`,
            }));
        });
        builder.addCase(fetchSurveyPathsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchSurveyPathsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.updatedAt = new Date();
            state.loading = false;
        });
        builder.addCase(fetchCreateSurveyPathData.pending, (state, action) => {
            state.loading = true;
            state.adTypeDataLoading = true;
        });
        builder.addCase(
            fetchCreateSurveyPathData.fulfilled,
            (state, action) => {
                const { responses } = action.payload;
                const segments = get(responses, `0.data.data`, []);
                const adUnits = get(responses, `1.data.data`, []).map(au => ({
                    id: au.adUnitId,
                    name: au.name || au.adUnitId,
                }));
                const offerGroups = get(responses, `2.data.data`, []);
                state.createSurveyPath.segments = segments;
                state.createSurveyPath.adUnits = adUnits;
                state.createSurveyPath.offerGroups = offerGroups;
                state.createSurveyPath.questionBank.selected = [];
                state.createSurveyPath.questionBank.details = null;
                state.createSurveyPath.questionBank.itemsArr = segments.map(
                    item => {
                        return {
                            ...item,
                            id: get(item, "id", ""),
                            name: get(item, "name", ""),
                            hasOffer: false,
                            type: null,
                            Ad: null,
                            AdObject: {},
                            Extras: [],
                            questionConfig: [],
                            questionsList: getQuestionList(item, true),
                        };
                    }
                );
                state.createSurveyPath.questionBank.itemsArrOriginal =
                    state.createSurveyPath.questionBank.itemsArr;
                setLoadingComplete(state);
                state.adTypeDataLoading = false;
            }
        );
        builder.addCase(fetchCreateSurveyPathData.rejected, (state, action) => {
            state.adTypeDataLoading = false;
        });
        builder.addCase(fetchSurveyPathDataById.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchSurveyPathDataById.fulfilled, (state, action) => {
            state.createSurveyPath.basics.nameId = action.payload.name;
            state.createSurveyPath.basics.selectedSurveyId = get(
                action.payload,
                "survey.id",
                null
            );
            state.createSurveyPath.targettingParam.targetParams =
                mapTargettingParams(action.payload.targetParams, true);

            const segmentsArray = action.payload?.segments;
            segmentsArray?.sort(function (a, b) {
                return a.index - b.index;
            });
            const selectedSegments = segmentsArray?.map(s => s.segment.id);
            state.createSurveyPath.questionBank.selected =
                selectedSegments || [];
            state.createSurveyPath.questionBank.details =
                selectedSegments?.length ? selectedSegments[0] : [];
            state.createSurveyPath.questionBank.selectedListActiveItem =
                selectedSegments?.length ? selectedSegments[0] : [];
            state.createSurveyPath.questionBank.itemsArr =
                setSelectedSurveyPath(
                    state.createSurveyPath.questionBank.itemsArr,
                    action.payload?.segments,
                    state.createSurveyPath.adUnits,
                    state.createSurveyPath.offerGroups,
                    true
                );
            state.createSurveyPath.questionBank.itemsArrOriginal =
                state.createSurveyPath.questionBank.itemsArr;
            state.createSurveyPath.questionBank.selectedItems =
                state.createSurveyPath.questionBank.itemsArr.filter(_ =>
                    state.createSurveyPath.questionBank.selected.includes(_.id)
                );
            setLoadingComplete(state);
        });
        builder.addCase(fetchSurveyPathDataById.rejected, (state, action) => {
            setLoadingComplete(state);
            alert("Failed to fetch survey path");
        });
        builder.addCase(updateSurveyPathData.pending, (state, action) => {
            state.mainLoader = true;
        });
        builder.addCase(updateSurveyPathData.fulfilled, (state, action) => {
            state.mainLoader = false;
        });
        builder.addCase(updateSurveyPathData.rejected, (state, action) => {
            state.mainLoader = false;
            alert(`Failed to update survey path`);
        });
        builder.addCase(createSurveyPathData.pending, (state, action) => {
            state.mainLoader = true;
        });
        builder.addCase(createSurveyPathData.fulfilled, (state, action) => {
            state.mainLoader = false;
        });
        builder.addCase(createSurveyPathData.rejected, (state, action) => {
            state.mainLoader = false;
            alert(`Failed to update survey path`);
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const surveyPathsActions = surveyPathsSlice.actions;
export default surveyPathsSlice.reducer;
