import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { LAYOUT } from "../../constants/url";
import { toastListActions } from "../toastList/toastList";
import customFetch from "../../fetch/customFetch";
import { get, isEmpty } from "lodash";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { mapListingData } from "../../utils/layoutUtils";

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    // updatedAt: new Date(),
    createLayout: {
        basics: {
            id: null,
            name: "",
            version: "",
            description: "",
            descError: false,
            type: null,
            subType: null,
            previewLink: "",
            tags: [],
        },
        property: {
            index: null,
            key: "",
            description: "",
            type: null,
            required: false,
            choices: [],
            propDescError: false,
            min: "",
            max: "",
            data: "",
            isMultiSelect: null,
        },
        configurations: [],
    },
    createLayoutBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.FLOW_LAYOUT.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.FLOW_LAYOUT.filterOperator,
        filtersOperator: FILTERS.FLOW_LAYOUT.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "layout"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    historyData: [],
    historyDataRaw: [],
};

export const fetchLayoutsData = createAsyncThunk(
    "flowmanager/layout/fetchLayoutListingData",
    async (refreshClicked, { dispatch, getState }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "layout.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "layout.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.FLOW_LAYOUT
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(layoutSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "flowmanager.layout.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.FLOW_LAYOUT,
                filterParamsList
            );
        }
        const listingData = get(getState(), "flowmanager.layout.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let response = null;
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            response = await customFetch(
                LAYOUT.FETCH_LAYOUTS_POST,
                "POST",
                filterParamsData,
                {},
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch layouts",
                        })
                    );
                    dispatch(
                        layoutSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(layoutActions.setFilter(filterParamsData?.filters));
            const mappedData = await mapListingData(
                get(response, "data.data.data", [])
            );
            data = mappedData;
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.LAYOUT}`;
            dispatch(layoutSlice.actions.setTableError(errorMessage));
            if (!isEmpty(data?.data)) {
                return data;
            }
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new layout`;
            dispatch(layoutSlice.actions.setTableError(errorMessage));
        }
        return { ...response?.data.data, data };
    }
);

export const fetchAllLayout = createAsyncThunk(
    "flowmanager/layout/fetchAllLayout",
    async (id, { dispatch }) => {
        const response = await customFetch(`${LAYOUT.FETCH_ALL_LAYOUTS}`);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all layouts.",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const createLayouts = createAsyncThunk(
    "flowmanager/layout/create",
    async (params, { dispatch }) => {
        const response = await customFetch(
            LAYOUT.CREATE_LAYOUT,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                if (
                    data?.response?.data?.message?.includes(
                        "Layout with name already exists"
                    )
                ) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `${data.response.data.message}`,
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to create layout.",
                        })
                    );
                }
            }
        );
        if (get(response, "data.status", false) !== "success") {
            dispatch(layoutActions.setMainLoader(false));
        }

        return get(response, "data.status", false) === "success";
    }
);

export const fetchLayoutData = createAsyncThunk(
    "flowmanager/layout/layoutData",
    async (id, { dispatch }) => {
        const response = await customFetch(`${LAYOUT.GET_LAYOUT_BY_ID}/${id}`);
        if (get(response, "data.status", false) !== "success")
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch layout",
                })
            );
        return response.data.data;
    }
);

export const updateLayouts = createAsyncThunk(
    "flowmanager/layout/edit",
    async (params, { dispatch }) => {
        let isConcurreny = false;
        const response = await customFetch(
            LAYOUT.CREATE_LAYOUT,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data?.response?.data;

                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Layout already modified while you were editing",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload layout",
                        })
                    );
                }

                isConcurreny = true;
            }
        );
        if (get(response, "data.status", false) !== "success" && !isConcurreny)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message:
                        "Failed to update the layout. Please try again later!",
                })
            );
        return get(response, "data.status", false) === "success";
    }
);

export const deleteLayout = createAsyncThunk(
    "flowmanager/layout/delete",
    async (params, { dispatch }) => {
        const response = await customFetch(
            LAYOUT.CREATE_LAYOUT,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to delete layout.",
                    })
                );
            }
        );
        if (get(response, "data.status", false) === "success") {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Layout deleted successfully.",
                    autoClose: 5000,
                })
            );
        } else {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete layout.",
                })
            );
        }
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/layout/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.layout.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.LAYOUT.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchLayoutHistory = createAsyncThunk(
    "flowmanager/layout/layoutHistory",
    async id => {
        const response = await customFetch(
            `${LAYOUT.FETCH_LAYOUT_HISTORY}/${id}`
        );
        return get(response, "data", []);
    }
);

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setLayoutId(state, action) {
            state.createLayout.basics.id = action.payload;
        },
        setLayoutName(state, action) {
            state.createLayout.basics.name = action.payload;
        },
        setPreviewLink(state, action) {
            state.createLayout.basics.previewLink = action.payload;
        },
        setLayoutVersion(state, action) {
            state.createLayout.basics.version = action.payload;
        },
        setLayoutDesc(state, action) {
            state.createLayout.basics.description = action.payload;
        },
        setLayoutDescError(state, action) {
            state.createLayout.basics.descError = action.payload;
        },
        setLayoutType(state, action) {
            state.createLayout.basics.type = action.payload;
        },
        setLayoutSubType(state, action) {
            state.createLayout.basics.subType = action.payload;
        },
        setUpdatedAt(state, action) {
            state.createLayout.basics.updatedAt = action.payload;
        },
        setTags(state, action) {
            state.createLayout.basics.tags = action.payload;
        },
        setPropertyKey(state, action) {
            state.createLayout.property.key = action.payload;
        },
        setPropertyDesc(state, action) {
            state.createLayout.property.description = action.payload;
        },
        setPropertyMin(state, action) {
            state.createLayout.property.min = action.payload;
        },
        setPropertyMax(state, action) {
            state.createLayout.property.max = action.payload;
        },
        setPropertyType(state, action) {
            state.createLayout.property.type = action.payload;
        },
        setIsMandatory(state, action) {
            state.createLayout.property.required = action.payload;
        },
        setValues(state, action) {
            state.createLayout.property.choices = action.payload;
        },
        setDefaultValue(state, action) {
            state.createLayout.property.data = action.payload;
        },
        setSelectType(state, action) {
            state.createLayout.property.isMultiSelect = action.payload;
        },
        resetPropFields(state, action) {
            // state.createLayout.property.choices = [];
            state.createLayout.property.propDescError = false;
            // state.createLayout.property.min = "";
            // state.createLayout.property.max = "";
            // state.createLayout.property.data = "defaultValue";
        },
        setPropDescError(state, action) {
            state.createLayout.property.propDescError = action.payload;
        },
        setPropIndex(state, action) {
            state.createLayout.property.index = action.payload;
        },
        setConfigurations(state, action) {
            state.createLayout.configurations = action.payload;
        },
        setEditData(state, action) {
            state.createLayout.property = action.payload;
        },
        resetLayout(state) {
            state.createLayout = {
                ...initialState.createLayout,
            };
        },
        resetProperty(state) {
            state.createLayout.property = {
                ...initialState.createLayout.property,
            };
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },

        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
        setCreateLayoutBackup(state, action) {
            state.createLayoutBackup = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchLayoutsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchLayoutsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            // state.updatedAt = new Date();
        });
        builder.addCase(fetchLayoutData.fulfilled, (state, action) => {
            const {
                id,
                name,
                version,
                description,
                type,
                subType,
                previewLink,
                configurations,
                tags,
                updatedAt,
            } = action.payload;
            state.createLayout.basics.id = id;
            state.createLayout.basics.name = name;
            state.createLayout.basics.version = version;
            state.createLayout.basics.description = description;
            state.createLayout.basics.type = type;
            state.createLayout.basics.subType = subType;
            state.createLayout.basics.previewLink = previewLink;
            state.createLayout.basics.tags = tags;
            state.createLayout.configurations = configurations;
            state.createLayout.updatedAt = updatedAt;
            state.createLayoutBackup = state.createLayout;
        });

        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const layoutActions = layoutSlice.actions;
export default layoutSlice.reducer;
