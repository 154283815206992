import * as moment from "moment";
import { shortListingtDateFormat } from "../../../utils/dateUtils";
import styles from "./DateRenderer.modules.scss";

const DateRenderer = ({
    dateFormat = shortListingtDateFormat,
    date,
    seconds,
    compact = true,
    showTime = true,
}) => {
    return compact ? (
        <div className={styles.flexDate}>
            <p className={styles.dateWithTime}>
                {(
                    date &&
                    (date !== "-" || date !== null) &&
                    dateFormat(date)
                )?.split(",")?.[0] ||
                    (seconds &&
                        seconds !== "-" &&
                        moment().seconds(seconds).format("MM/DD/YY")) ||
                    "-"}
            </p>
            {showTime && (
                <p className={styles.subTime}>
                    {showTime
                        ? (
                              date &&
                              (date !== "-" || date !== null) &&
                              dateFormat(date)
                          )?.split(",")?.[1] ||
                          (seconds &&
                              seconds !== "-" &&
                              moment().seconds(seconds).format("MM/DD/YY")) ||
                          "-"
                        : "-"}
                </p>
            )}
        </div>
    ) : (
        <p>
            {(date && (date !== "-" || date !== null) && dateFormat(date)) ||
                (seconds &&
                    seconds !== "-" &&
                    moment()
                        .seconds(seconds)
                        .format("MMMM Do YYYY, h:mm:ss a")) ||
                "-"}
        </p>
    );
};

export default DateRenderer;
