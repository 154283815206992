import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { DEALS, SURVEYS, HNP_URL, MAX_ENDPOINT } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import fetchAll from "../../fetch/fetchAll";
import {
    getTargettingParams,
    mapTargettingParams,
    keyValsFromApi,
    getRandomId,
    getRequiredObject,
    isArrayEqual,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import {
    setPaginationStartOffset,
    paginationParams,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import {
    adGroupPostApiData,
    adGroupPutApiData,
    adSlotsPostApiData,
    adSlotsPutApiData,
    deleteSlotsAdsApiData,
    deleteSlotsApiData,
    slotTypeSmallCase,
} from "../../utils/offerPathUtils";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { initialFilterData } from "../../utils/offerPathUtils";
import { isEmpty } from "lodash";

const initialTargetParamsState = {
    region: [],
    countries: [],
    browsers: [],
    devices: [],
    os: [],
    genders: [],
    utmMediums: [],
    utmSources: [],
    utmCampaigns: [],
    utmContents: [],
    ageRange: [],
    keywords: [],
    availableSet: ["utmSources", "region", "keywords", "ageRange"],
    keyVals: [],
    zipCodes: [],
    timeOfDay: [],
    allowedDays: [],
    landingKeywords: [],
    landingJobTypes: [],
    // spamCheck: {
    //     spamIP: false,
    //     category: [],
    // },
    jobTypes: [],
    visitCount: [],
    registeredVisitCount: [],
    userStatus: [],
    keywordCategories: [],
    city: [],
    pagination: {
        startOffset: 0,
        rowsCount: 10,
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.OFFER_PATH.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.OFFER_PATH.filterOperator,
        filtersOperator: FILTERS.OFFER_PATH.operator,
        filters: [],
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

const initialState = {
    list: [],
    offerWallsList: [],
    // loading: false,
    updatedAt: new Date(),
    loading: false,
    mainLoader: false,
    surveyList: [],
    createOfferPath: {
        adSlotsData: [],
        basics: {
            name: "",
            weight: "",
            status: true,
            error: false,
            surveys: [],
            selectedSurveyIds: [],
            maxSlotsToReturn: "",
            enableAutoOfferPath: false,
            explorationWeight: "",
        },
        dedupeRules: [],
        slots: {
            error: false,
            index: "",
            type: "",
            trafficSplit: "",
            offerWall: "",
            offerWallTemplate: "",
            selectedListItems: [], //also contains soft deleted items
            selectedItems: [], //only contains selected items (used for save and confirm)
            editIndex: "",
            editType: "WeightGroup",
            editTrafficSplit: "",
            editOfferWallTemplate: "",
            editId: "",
            toggle: false,
            weight: "",
            dealId: "",
            dedupeRules: [],
            revenueType: null,
            creative: null,
            editWeight: "",
            editDealId: "",
            editRevenueType: null,
            editCreative: null,
            selectedMode: null,
            selectedRowId: null,
            rowTypeMode: "",
            expandCollapseTable: false,
            historyData: [],
            historyDataRaw: [],
            deletedSlots: [],
            deletedSlotAds: [],
        },
        targettingParam: {
            targetParams: initialTargetParamsState,
            dropDownState: {
                plusDropdownData: "",
                showPlusDropdown: false,
                productList: [
                    {
                        id: 1,
                        firstName: "abcd",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: 2,
                        firstName: "eafgbh",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: 3,
                        firstName: "ijkl",
                        lastName: "rewardsurveyusa.com",
                    },
                    { id: 4, firstName: "mnop", lastName: "test@test.com" },
                    { id: 5, firstName: "qrst", lastName: "test@test.com" },
                    { id: 6, firstName: "uvwx", lastName: "test@test.com" },
                    { id: 7, firstName: "yz", lastName: "test@test.com" },
                ],
                productListPlusData: [
                    {
                        id: "utmSources",
                        firstName: "UTM Sources",
                        lastName: "myjobscornesssr.com",
                    },
                    {
                        id: "region",
                        firstName: "Region",
                        lastName: "realdealsweeps.com",
                    },
                    {
                        id: "keywords",
                        firstName: "Keywords",
                        lastName: "rewardsurveyusa.com",
                    },
                    {
                        id: "ageRange",
                        firstName: "Age Range",
                        lastName: "test@test.com",
                    },
                    {
                        id: "genders",
                        firstName: "Gender",
                        lastName: "test@test.com",
                    },
                    {
                        id: "countries",
                        firstName: "Countries",
                        lastName: "test@test.com",
                    },
                    {
                        id: "zipCodes",
                        firstName: "Zip Codes",
                        lastName: "test@test.com",
                    },
                    {
                        id: "timeOfDay",
                        firstName: "Time of Day",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmCampaigns",
                        firstName: "UTM Campaigns",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmContents",
                        firstName: "UTM Contents",
                        lastName: "test@test.com",
                    },
                    {
                        id: "utmMediums",
                        firstName: "UTM Mediums",
                        lastName: "test@test.com",
                    },
                    {
                        id: "os",
                        firstName: "Operating Systems",
                        lastName: "test@test.com",
                    },
                    {
                        id: "browsers",
                        firstName: "Browsers",
                        lastName: "test@test.com",
                    },
                    {
                        id: "devices",
                        firstName: "Devices",
                        lastName: "test@test.com",
                    },
                    {
                        id: "allowedDays",
                        firstName: "Allowed Days",
                        lastName: "test@test.com",
                    },
                    {
                        id: "keyVals",
                        firstName: "A/B Tests",
                        lastName: "test@test.com",
                    },
                    {
                        id: "landingKeywords",
                        firstName: "Landing Keywords",
                        lastName: "test@test.com",
                    },
                    {
                        id: "landingJobTypes",
                        firstName: "Landing Job Types",
                        lastName: "test@test.com",
                    },
                    {
                        id: "jobTypes",
                        firstName: "Job Types",
                        lastName: "test@test.com",
                    },
                    {
                        id: "visitCount",
                        firstName: "Visit Count",
                        lastName: "test@test.com",
                    },
                    {
                        id: "registeredVisitCount",
                        firstName: "Registered Visit Count",
                        lastName: "test@test.com",
                    },
                ],
            },
            zipCodeState: {
                csvFileName: "",
                csvFile: "",
                csvArray: [],
                zipCodeError: false,
                onDropActiveZipCode: false,
                // zipCode: [],
            },
        },
        customKeyVal: [],
    },
    createOfferPathBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        searchKey: "",
        searchTerm: null,
        filterKey: FILTERS.OFFER_PATH.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.OFFER_PATH.filterOperator,
        filtersOperator: FILTERS.OFFER_PATH.operator,
    },
    searchFilter: {
        filterApplied: getInitialTagData(initialFilterData, "offerpaths"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const adsHistory = createAsyncThunk(
    "listings/offerpaths/adshistory",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${SURVEYS.FETCH_HISTORY_LOG}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const historyInnerTable = createAsyncThunk(
    "listings/offerpaths/historyInnerTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${SURVEYS.FETCH_HISTORY_INNERTABLE_LOG}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchDealIds = createAsyncThunk(
    "listings/offerpaths/fetchDealIds",
    async (advertiserId, { dispatch }) => {
        const params = {
            url: `${MAX_ENDPOINT.GET_DEAL_ID}/${advertiserId}/sponsorshipdeals`,
            options: {
                headers: {
                    "Content-type": "application/json",
                },
                method: "GET",
            },
        };

        let apiFailed = false;
        const response = await customFetch(
            HNP_URL.MAX_PROXY,
            "POST",
            params,
            {},
            {},
            null,
            null,
            obj => {
                apiFailed = true;
            }
        );

        if (apiFailed) return { data: [], status: 500 };

        return { data: response.data.data };
    }
);

export const historyListingsTable = createAsyncThunk(
    "listings/offerpaths/historyOfferPathListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${SURVEYS.FETCH_HISTORY_LISTINGS_OFFERPATH}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchSurveys = createAsyncThunk(
    "listings/offerpaths/surveys",
    async (_, { dispatch }) => {
        const response = await customFetch(SURVEYS.FETCH_SURVEYS, "GET");
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Surveys",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const fetchOfferPathsData = createAsyncThunk(
    "listings/offerPaths/fetchOfferPathsListingData",
    async (refreshClicked, { getState, dispatch }) => {
        const pagination = getState().listings.offerPaths.pagination;
        const params = paginationParams(pagination);
        const pageParamsList = ["offset", "limit"];
        const { listings } = getState();

        let tagFilters = get(
            listings,
            "offerPaths.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.OFFER_PATH
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = pagination?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(offerPathsSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.offerPaths.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.OFFER_PATH,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.offerPaths.list", []);
        const prevTotalRows = pagination?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                SURVEYS.FETCH_OFFER_PATH,
                "POST",
                filterParamsData,
                {},
                null,
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch offer paths list",
                        })
                    );
                    dispatch(
                        offerPathsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(
                createOfferPathsActions.setFilter(filterParamsData?.filters)
            );
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && pagination.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.OFFER_PATHS}`;
            dispatch(offerPathsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Offer Path`;
            dispatch(offerPathsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchOfferWalls = createAsyncThunk(
    "listings/offerpaths/fetchOfferwalls",
    async (_, { dispatch }) => {
        const response = await customFetch(DEALS.FETCH_OFFERWALL_LIST);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Offer Wall List!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const fetchOfferPathDataById = createAsyncThunk(
    "listings/offerPaths/fetchOfferPathDataById",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.GET_OFFER_PATH_BY_ID}/${id}`,
            "GET"
        );
        const creativesList =
            getState().listings.adUnits.createAdUnit.slots.adCreativesList;
        const data = get(response, "data.data", []);
        const dedupeRules = get(response, "", []);
        if (data.length == 0)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch offer path data!",
                })
            );
        return [data, creativesList, dedupeRules];
    }
);

export const deleteOfferPathData = createAsyncThunk(
    "listings/offerPaths/deleteOfferPath",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.DELETE_OFFER_PATH}/${id}`,
            "DELETE"
        );
        if (get(response, "status", "") != 200)
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to delete the offer path!",
                })
            );
        return response;
    }
);

export const updateOfferPathData = createAsyncThunk(
    "listings/offerPaths/updateOfferPath",
    async (id, { dispatch, getState }) => {
        const offerPathState = getState().listings.offerPaths.createOfferPath;
        const params = getOfferPathParams(offerPathState);
        const response = await customFetch(
            `${SURVEYS.UPDATE_OFFER_PATH}/${id}`,
            "PUT",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to update the offer path!",
                })
            );
        } else {
            const data = {
                state: offerPathState,
                id: id,
            };

            dispatch(deleteAdSlot());
            const deleteOpSlotAdsRes = await dispatch(
                deleteOfferPathSlotsAds()
            );

            if (
                deleteOpSlotAdsRes?.payload?.length &&
                deleteOpSlotAdsRes?.payload.some(_ => !_)
            ) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message:
                            "Something went wrong while deleting slot offers.",
                    })
                );
                return false;
            }
            const updateAdSlotRes = await dispatch(updateAdSlot(data)).unwrap();
            const obj = {
                slots: getState().listings.offerPaths.createOfferPath.slots
                    .selectedListItems,
                responses: updateAdSlotRes,
                existingData: data.state.adSlotsData,
            };
            const resArr = await dispatch(updateGroup(obj));
            if (
                resArr.meta?.arg?.responses?.some(_ => !_ || _?.status !== 200)
            ) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Something went wrong while updating slots.",
                    })
                );
                return false;
            }
        }
        return response;
    }
);

export const createOfferPathData = createAsyncThunk(
    "listings/offerPaths/createOfferPath",
    async (_, { dispatch, getState }) => {
        const offerPathState = getState().listings.offerPaths.createOfferPath;
        const params = getOfferPathParams(offerPathState);
        const response = await customFetch(
            SURVEYS.CREATE_OFFER_PATH,
            "POST",
            params
        );
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to create the offer path!",
                })
            );
        } else {
            const data = {
                state: offerPathState,
                id: response.data.data.id,
            };
            const createAdSlotRes = await dispatch(createAdSlot(data)).unwrap();
            const obj = {
                slots: data.state.slots.selectedListItems,
                responses: createAdSlotRes,
            };

            const resArr = await dispatch(createGroup(obj));
            if (
                resArr.meta?.arg?.responses?.some(_ => !_ || _?.status !== 200)
            ) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Something went wrong while creating slots.",
                    })
                );
                return {
                    status: false,
                    id: response.data.data.id,
                };
            }
        }
        return response;
    }
);

export const cloneOfferPathData = createAsyncThunk(
    "listings/offerPaths/cloneOfferPathData",
    async (id, { getState, dispatch }) => {
        const response = await customFetch(
            `${SURVEYS.CLONE_OFFER_PATH}`,
            "POST",
            { id: id }
        );
        if (!get(response, "data.success", false))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to clone offer path!",
                })
            );
        return response;
    }
);

const createAdSlot = createAsyncThunk(
    "listings/offerpaths/createAdSlots",
    async (data, { getState, dispatch }) => {
        const adSlots = data.state.slots.selectedListItems;
        if (adSlots && adSlots.length) {
            const postData = adSlotsPostApiData(adSlots, data.id);
            return await fetchAll(postData);
        }
        return;
    }
);

const updateAdSlot = createAsyncThunk(
    "listings/offerpaths/updateAdSlots",
    async (data, { getState, dispatch }) => {
        const adSlots = data.state.slots.selectedListItems;
        if (adSlots && adSlots.length) {
            const slotsToBeDeleted =
                getState().listings.offerPaths.createOfferPath.slots
                    .deletedSlots;
            let updatedAdSlots = adSlots.filter(adSlot =>
                slotsToBeDeleted.every(
                    deletedSlot => deletedSlot.rowId !== adSlot.rowId
                )
            );
            if (!isArrayEqual(adSlots, updatedAdSlots)) {
                updatedAdSlots = updatedAdSlots.map((item, idx) => ({
                    ...item,
                    index: idx + 1,
                    isEdit: true,
                }));
                dispatch(
                    offerPathsSlice.actions.addSelectedListItems({
                        page: "",
                        data: updatedAdSlots,
                    })
                );
            }
            const createSlots = updatedAdSlots.filter(slot => slot.id === "-");
            const updateSlots = updatedAdSlots.filter(
                slot => slot.id !== "-" && slot.isEdit
            );
            const createSlotsApi = adSlotsPostApiData(createSlots, data.id);
            const updateSlotsApi = adSlotsPutApiData(updateSlots, data.id);
            return await fetchAll([...createSlotsApi, ...updateSlotsApi]);
        }
        return;
    }
);

const deleteAdSlot = createAsyncThunk(
    "listings/offerpaths/deleteslots",
    async (_, { getState }) => {
        const slotsToBeDeleted =
            getState().listings.offerPaths.createOfferPath.slots.deletedSlots;
        const deletedSlotsApi = deleteSlotsApiData(slotsToBeDeleted);
        return await fetchAll(deletedSlotsApi);
    }
);

const createGroup = createAsyncThunk(
    "listings/offerpaths/creategroups",
    async (data, { dispatch }) => {
        const groupsApi = [];
        data.slots.forEach((slot, index) => {
            if (!slot.editCreativeData.length || slot?.type === "OfferWall")
                return;
            const adGroupPostData = adGroupPostApiData(
                slot,
                index,
                data.responses
            );
            groupsApi.push(adGroupPostData);
        });
        return await fetchAll(groupsApi);
    }
);

const updateGroup = createAsyncThunk(
    "listings/offerpaths/updategroups",
    async (data, { getState }) => {
        let groupsApi = [];
        data.slots.forEach((slot, index) => {
            if (slot.editCreativeData.length && slot?.type !== "OfferWall") {
                if (slot.id === "-") {
                    const adGroupApiData = adGroupPostApiData(
                        slot,
                        index,
                        data.responses
                    );
                    groupsApi.push(adGroupApiData);
                } else {
                    const existingSlotData = data.existingData.find(
                        data => data.id == slot.id
                    );
                    if (
                        !existingSlotData ||
                        !existingSlotData[slotTypeSmallCase[slot.type]]
                    ) {
                        const adGroupApiData = adGroupPostApiData(
                            slot,
                            index,
                            data.responses
                        );
                        groupsApi.push(adGroupApiData);
                    } else {
                        const adGroupApiData = adGroupPutApiData(
                            slot,
                            existingSlotData[slotTypeSmallCase[slot.type]]
                        );
                        groupsApi.push(adGroupApiData);
                    }
                }
            }
        });
        return await fetchAll(groupsApi);
    }
);

export const deleteOfferPathSlotsAds = createAsyncThunk(
    "listings/offerpaths/deleteOfferPathSlotsAds",
    async (_, { getState }) => {
        const adsToBeDeleted =
            getState().listings.offerPaths.createOfferPath.slots.deletedSlotAds;
        const deletedSlotAdsApi = deleteSlotsAdsApiData(adsToBeDeleted);
        return await fetchAll(deletedSlotAdsApi);
    }
);

const group = {
    WeightGroup: "wg",
    PriorityGroup: "pg",
    RtbGroup: "rtbg",
};

const ads = {
    WeightGroup: "wa",
    PriorityGroup: "pa",
    RtbGroup: "rtba",
};

const groupAds = {
    WeightGroup: "weightedAds",
    PriorityGroup: "priorityAds",
    RtbGroup: "rtbAds",
};

const getOfferPathParams = offerPathState => {
    const selectedSurveyIds = offerPathState.basics.selectedSurveyIds;
    const obj = {
        name: offerPathState.basics.name,
        weight: offerPathState.basics.weight,
        isActive: offerPathState.basics.status,
        dedupeKeys: offerPathState.dedupeRules,
        maxSlotsToReturn:
            offerPathState.basics.maxSlotsToReturn === ""
                ? -1
                : offerPathState.basics.maxSlotsToReturn,
        enableAutoOfferPath: offerPathState.basics.enableAutoOfferPath,
        explorationWeight:
            offerPathState.basics.explorationWeight === ""
                ? 0
                : offerPathState.basics.explorationWeight,

        // offerPathAdSlots: getOfferPathAdSLots(
        //   offerPathState.slots.selectedListItems
        // ),
        targetParams: getTargettingParams(
            offerPathState.targettingParam.targetParams,
            selectedSurveyIds,
            true,
            null,
            true,
            true
        ),
    };
    // obj.targetParams.spamCheck = {
    //     ...obj.targetParams.spamCheck,
    //     spamIP: obj.targetParams.spamCheck.spamIP.toString(),
    // };
    return obj;
};

const getOfferPathAdSLots = slots => {
    return slots.map(slot => ({
        slotIndex: slot.index,
        trafficSplit: slot.trafficSplit,
        type: slot.type,
        // slot.type.toLowerCase() === "weight group" ? "WeightGroup" : "OfferWall",
    }));
};

const changeToggle = (array, id) => {
    let expandAllFlag = null;
    let expandLength = 0;
    let collapseLength = 0;

    let selectedListItemsCopy = JSON.parse(JSON.stringify(array));
    selectedListItemsCopy.map(item => {
        if (item.rowId == id) {
            return (item.showAccordionTable = !item.showAccordionTable);
        }

        // else {
        //   return (item.showAccordionTable = false);
        // }
    });
    selectedListItemsCopy.forEach(element => {
        if (element.showAccordionTable) {
            expandLength = expandLength + 1;
        }
        if (!element.showAccordionTable) {
            collapseLength = collapseLength + 1;
        }
    });
    if (expandLength == selectedListItemsCopy.length) expandAllFlag = true;
    if (collapseLength == selectedListItemsCopy.length) expandAllFlag = false;
    return { selectedListItemsCopy, expandAllFlag };
};

const param = {
    WeightGroup: "weight",
    PriorityGroup: "priority",
    RtbGroup: "disposition",
};

const mapCreatives = (slot, list) => {
    const ads = {
        WeightGroup: "weightGroup",
        PriorityGroup: "priorityGroup",
        RtbGroup: "rtbGroup",
    };
    const groupType = ads[slot.type];
    if (slot[groupType]) {
        return slot[groupType][groupAds[slot.type]].map(data => {
            let getindex = list.findIndex(
                element => element.id == data.creativeId
            );
            return {
                id: data.id,
                // creative: data.creativeId,
                creative: `${
                    list[getindex] && list[getindex].key
                        ? list[getindex].key
                        : "-"
                }`,
                dedupeRules: data.dedupeKeys || [],
                dealId: data.dealId,
                [param[slot.type]]: data[param[slot.type]],
                revenueType: data.revType,
                innerRowId: getRandomId(),
                customKeyVal: data.customKeyVal || [],
            };
        });
    }
    if (slot?.type === "OfferWall" && slot?.adsCreative) {
        return [
            {
                creative: `${slot?.adsCreative?.id}: ${slot?.adsCreative?.name}`,
            },
        ];
    }
    return [];
};

export const onFilterApplied = createAsyncThunk(
    "listings/offerPaths/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.offerPaths.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.SURVEY_PATH.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const offerPathsSlice = createSlice({
    name: "offerPaths",
    initialState,
    reducers: {
        setOfferPathName(state, action) {
            state.createOfferPath.basics.name = action.payload;
            // state.createOfferPath.basics.error =
            //   typeof action.payload == "string" && action.payload.trim() == "";
            if (state.createOfferPath.basics.weight.length > 0) {
                state.createOfferPath.basics.error =
                    typeof action.payload == "string" &&
                    action.payload.trim() == "";
            }
        },
        setOfferPathLimit(state, action) {
            state.createOfferPath.basics.maxSlotsToReturn = action.payload;
        },
        setOfferPathBasicError(state, action) {
            state.createOfferPath.basics.error = action.payload;
        },
        setOfferPathWeight(state, action) {
            state.createOfferPath.basics.weight = action.payload;
            if (state.createOfferPath.basics.name.length > 0) {
                state.createOfferPath.basics.error =
                    typeof action.payload == "number" &&
                    action.payload.trim() == "";
            }
        },
        setAutoOfferPath(state, action) {
            state.createOfferPath.basics.enableAutoOfferPath = action.payload;
        },
        setExplorationValue(state, action) {
            state.createOfferPath.basics.explorationWeight = action.payload;
        },
        setOfferPathSlotsError(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.error = data;
        },
        setOfferPathStatus(state, action) {
            state.createOfferPath.basics.status = action.payload;
        },
        addIndex(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.index = data;
        },
        addType(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.type = data;
        },
        addTrafficSplit(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.trafficSplit = data;
        },
        addSelectedListItems(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.selectedListItems = data;
            state.createOfferPath.slots.selectedItems = data.filter(
                _ => !state.createOfferPath.slots.deletedSlots.includes(_)
            );
        },
        addEditIndex(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editIndex = data;
        },
        setEditType(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editType = data;
        },
        setEditTrafficSplit(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editTrafficSplit = data;
        },
        setEditOfferWallTemplate(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editOfferWallTemplate = data;
        },
        setEditId(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editId = data;
        },
        setToggle(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.toggle = data;
        },
        addWeight(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.weight = data;
        },
        addDealId(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.dealId = data;
        },
        addRevenueType(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.revenueType = data;
        },
        addCreative(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.creative = data;
        },
        editWeight(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editWeight = data;
        },
        editDealId(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editDealId = data;
        },
        editRevenueType(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editRevenueType = data;
        },
        editCreative(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.editCreative = data;
        },
        selectedMode(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.selectedMode = data;
        },
        selectedRowId(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.selectedRowId = data;
        },
        rowTypeMode(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.rowTypeMode = data;
        },
        toggleShowAccordionTable(state, action) {
            const { data, list } = action.payload;
            const { selectedListItemsCopy, expandAllFlag } = changeToggle(
                list,
                data
            );
            state.createOfferPath.slots.selectedListItems =
                selectedListItemsCopy;
            if (expandAllFlag != null) {
                state.createOfferPath.slots.expandCollapseTable = expandAllFlag;
            }
        },
        expandCollapseTableToggle(state, action) {
            const { page, data } = action.payload;
            state.createOfferPath.slots.expandCollapseTable = data;
        },
        expandCollapseTable(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.selectedListItems = data;
        },
        toggleHistory(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.toggleHistory = data;
        },
        historyData(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.historyData = data;
        },
        setHistoryDataRaw(state, action) {
            state.createOfferPath.slots.historyDataRaw = action.payload;
        },
        setTargetParams(state, action) {
            const { data } = action.payload;
            state.createOfferPath.targettingParam.targetParams = { ...data };
        },
        setDropDownState(state, action) {
            const { data } = action.payload;
            state.createOfferPath.targettingParam.dropDownState = { ...data };
        },
        setCsvFileName(state, action) {
            const { data } = action.payload;
            state.createOfferPath.targettingParam.zipCodeState.csvFileName =
                data;
        },
        setCsvFile(state, action) {
            const { data } = action.payload;
            state.createOfferPath.targettingParam.zipCodeState.csvFile = data;
        },
        setCsvArray(state, action) {
            const { data } = action.payload;
            state.createOfferPath.targettingParam.zipCodeState.csvArray = data;
        },
        setZipCodeError(state, action) {
            const { data } = action.payload;
            state.createOfferPath.targettingParam.zipCodeState.zipCodeError =
                data;
        },
        setOnDropActiveZipCode(state, action) {
            const { data } = action.payload;
            state.createOfferPath.targettingParam.zipCodeState.onDropActiveZipCode =
                data;
        },
        // setZipCodes(state, action) {
        //   const { data } = action.payload;
        //   state.createOfferPath.targettingParam.zipCodeState.zipCode = data;
        // },
        loading(state, action) {
            state.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        resetOfferPath(state) {
            state.createOfferPath.basics.selectedSurveyIds = [];
            state.createOfferPath.basics.name = "";
            state.createOfferPath.basics.maxSlotsToReturn = "";
            state.createOfferPath.basics.weight = "";
            state.createOfferPath.basics.enableAutoOfferPath = false;
            state.createOfferPath.basics.explorationWeight = "";
            state.createOfferPath.slots.selectedListItems = [];
            state.createOfferPath.dedupeRules = [];
            state.createOfferPath.slots.deletedSlots = [];
            state.createOfferPath.slots.deletedSlotAds = [];
            (state.createOfferPath.slots.expandCollapseTable = false),
                (state.createOfferPath.targettingParam.targetParams =
                    initialTargetParamsState);
            state.createOfferPathBackup = state.createOfferPath;
            state.createOfferPath.customKeyVal = [];
        },
        setSurveys(state, action) {
            state.createOfferPath.basics.surveys = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSelectedSurveyIds(state, action) {
            state.createOfferPath.basics.selectedSurveyIds = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        revertDeleteSlots(state, action) {
            const { data } = action.payload;
            const index = state.createOfferPath.slots.deletedSlots.findIndex(
                _ => _.id === data.id
            );
            state.createOfferPath.slots.deletedSlots.splice(index, 1);

            if (state.createOfferPath.slots.deletedSlots.length === 0) {
                state.createOfferPath.slots.selectedItems =
                    state.createOfferPath.slots.selectedListItems;
            } else {
                state.createOfferPath.slots.selectedItems.push(data);
            }
        },
        setDeletedSlots(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.deletedSlots.push(data);
            state.createOfferPath.slots.selectedItems =
                state.createOfferPath.slots.selectedItems.filter(
                    _ => _.id !== data.id
                );
        },
        revertDeleteSlotAds(state, action) {
            const { data, page } = action.payload;
            const index = state.createOfferPath.slots.deletedSlotAds.findIndex(
                _ => _.ad.id === data.id
            );
            state.createOfferPath.slots.deletedSlotAds.splice(index, 1);
        },
        setDeletedSlotAds(state, action) {
            const { data } = action.payload;
            state.createOfferPath.slots.deletedSlotAds.push(data);
        },
        setHistoryData(state, action) {
            state.createOfferPath.slots.historyData = action.payload;
        },
        updateOfferWallData(state, action) {
            const { field, value } = action.payload.data;
            state.createOfferPath.slots[field] = value;
        },
        resetOfferWallData(state) {
            state.createOfferPath.slots.offerWall = "";
            state.createOfferPath.slots.offerWallTemplate = "";
            state.createOfferPath.slots.editOfferWallTemplate = "";
        },
        setDedupe(state, action) {
            state.createOfferPath.dedupeRules = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setClonedItem(state, action) {
            const temp = state.list.map(_ => {
                if (_.animateEntry) _.animateEntry = false;
                return _;
            });
            state.list = [action.payload, ...temp];
        },
        setDeletedItem(state, action) {
            state.list = action.payload;
        },
        removeDeletedItems(state, action) {
            state.list = state.list.filter(_ => !_.deletedrow);
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchSurveys.pending, state => {
            // state.loading = true;
        });
        builder.addCase(fetchSurveys.fulfilled, (state, action) => {
            // state.loading = false;
            state.surveyList = action.payload.map(survey => ({
                id: survey.id,
                name: `${survey.id}: ${survey.name}`,
            }));
        });
        builder.addCase(fetchOfferWalls.fulfilled, (state, action) => {
            state.offerWallsList = action.payload.map(ow => ({
                id: ow.id,
                name: `${ow.id}: ${ow.name}`,
            }));
        });
        builder.addCase(fetchOfferPathsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchOfferPathsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchOfferPathDataById.pending, (state, action) => {
            // state.loading = true;
        });
        builder.addCase(fetchOfferPathDataById.fulfilled, (state, action) => {
            if (Array.isArray(action.payload)) {
                state.createOfferPath.adSlotsData =
                    action.payload[0]?.offerPathAdSlots;
                state.createOfferPath.basics.name = action.payload[0]?.name;
                state.createOfferPath.basics.weight = action.payload[0]?.weight;
                state.createOfferPath.basics.maxSlotsToReturn =
                    action.payload[0]?.maxSlotsToReturn === -1
                        ? ""
                        : action.payload[0]?.maxSlotsToReturn;
                state.createOfferPath.basics.status =
                    action.payload[0]?.isActive;
                state.createOfferPath.dedupeRules =
                    action.payload[0]?.dedupeKeys || [];
                state.createOfferPath.basics.enableAutoOfferPath =
                    action.payload[0]?.enableAutoOfferPath;
                state.createOfferPath.basics.explorationWeight =
                    action.payload[0]?.explorationWeight?.toString();
                state.createOfferPath.basics.selectedSurveyIds = keyValsFromApi(
                    get(action?.payload[0], "targetParams.keyVals", []),
                    "survey"
                );
                state.createOfferPath.slots.selectedListItems =
                    action.payload[0]?.offerPathAdSlots
                        ?.sort((a, b) => a.slotIndex - b.slotIndex)
                        ?.map((slot, i) => ({
                            index: i + 1,
                            trafficSplit: slot.trafficSplit,
                            id: slot.id,
                            type: slot.type,
                            editCreativeData: mapCreatives(
                                slot,
                                action.payload[1]
                            ),
                            rowId: getRandomId(),
                            ...(slot?.type === "RtbGroup" &&
                                slot?.rtbGroup?.dynamicSortFunc && {
                                    rtbGroupId: slot?.rtbGroup?.id,
                                    dynamicSortFunc:
                                        slot?.rtbGroup.dynamicSortFunc,
                                }),
                            ...(slot?.type === "OfferWall" &&
                                slot?.offerWall &&
                                slot?.adsCreative && {
                                    offerWall: `${slot?.offerWall?.id}: ${slot?.offerWall?.name}`,
                                    adsCreative: `${slot?.adsCreative?.id}: ${slot?.adsCreative?.name}`,
                                }),
                        }))
                        ?.sort((a, b) => a.index - b.index);
                state.createOfferPath.slots.selectedItems =
                    state.createOfferPath.slots.selectedListItems;
                state.createOfferPath.targettingParam.targetParams =
                    mapTargettingParams(action.payload[0]?.targetParams, true);
            }
            // state.loading = false;
            state.createOfferPathBackup = state.createOfferPath;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const createOfferPathsActions = offerPathsSlice.actions;
export default offerPathsSlice.reducer;
