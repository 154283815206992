import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import { TEMPLATE } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { get } from "lodash";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";
import { TemplateKeys } from "../../Pages/CreateTemplate/CreateTemplate";

const initialState = {
    list: [],
    updatedAt: new Date(),
    loading: false,
    createTemplate: {
        name: "",
        id: "",
        configuration: [],
        loading: false,
        mainLoader: false,
        showPrompt: true,
    },
    createTemplateBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.TEMPLATE.filterKey,
        filterTerm: null,
        // prefix: null,
        filterOperator: FILTERS.TEMPLATE.filterOperator,
        filtersOperator: FILTERS.TEMPLATE.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "template"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    historyData: [],
    historyDataRaw: [],
};

export const fetchTemplateData = createAsyncThunk(
    "flowmanager/template/listings",
    async (refreshClicked, { dispatch, getState }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "template.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "template.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.TEMPLATE
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );

        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(template.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "flowmanager.template.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.TEMPLATE,
                filterParamsList
            );
        }

        const listingData = get(getState(), "flowmanager.template.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                `${TEMPLATE.FETCH_TEMPLATE_LIST}`,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to load Feature Group List",
                        })
                    );
                    dispatch(
                        template.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(templateActions.setFilter(filterParamsData?.filters));
            data = get(response, "data.data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.TEMPLATE}`;
            dispatch(template.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Feature Group`;
            dispatch(template.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const createUpdateTemplate = createAsyncThunk(
    "flowmanager/template/createUpdateTemplate",
    async (_, { getState, dispatch }) => {
        let isConcurreny = false;
        const data = getState().flowmanager.template.createTemplate;
        const params = postUpdateObject(data);
        const response = await customFetch(
            TEMPLATE.CREATE_TEMPLATE,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Feature Group already modified while you were editing",
                            autoClose: 4000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload Feature Group",
                        })
                    );

                    isConcurreny = true;
                }
            }
        );

        if ((response == null || response?.status >= 400) && !isConcurreny) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to ${
                        data.id != "" ? "create" : "update"
                    } Feature Group`,
                })
            );
        }
        return get(response, "data", null);
    }
);

export const deleteTemplate = createAsyncThunk(
    "flowmanager/template/deleteTemplate",
    async (obj, { getState, dispatch }) => {
        const response = await customFetch(
            `${TEMPLATE.DELETE_TEMPLATE}/${obj.id}`,
            "POST",
            {},
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.includes("Cannot delete!")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Failed to delete, this Feature Group is mapped to some container(s).`,
                        })
                    );
                }
            }
        );
        return get(response, "data", null);
    }
);

export const fetchTemplate = createAsyncThunk(
    "flowmanager/template/fetchTemplate",
    async (id, { dispatch }) => {
        const response = await customFetch(`${TEMPLATE.FETCH_TEMPLATE}/${id}`);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Feature Group.",
                })
            );
        }
        return get(response, "data", []);
    }
);

export const fetchAllTemplate = createAsyncThunk(
    "flowmanager/template/fetchAllTemplate",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${TEMPLATE.FETCH_ALL_TEMPLATE_LIST}`
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all Feature Group.",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const saveKeyword = createAsyncThunk(
    "flowmanager/template/saveKeyword",
    async (params, { dispatch }) => {
        const response = await customFetch(
            TEMPLATE.SAVE_KEYWORD,
            "POST",
            params
        );

        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all Feature Group.",
                })
            );
        }
        return get(response, "data", []);
    }
);

export const fetchKeyword = createAsyncThunk(
    "flowmanager/template/fetchKeyword",
    async (id, { dispatch }) => {
        const response = await customFetch(`${TEMPLATE.FETCH_KEYWORD}/${id}`);
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all Feature Group.",
                })
            );
        }
        // console.log(response, "res");
        return get(response, "data", []);
    }
);

const postUpdateObject = data => {
    let obj = {};
    obj.name = data.name;
    obj.properties = {};
    const configs = data.configuration;
    configs?.value?.map(tp => {
        if (tp?.value?.length) {
            obj.properties[tp.value[0].key] = tp.value[0].value;
        }
    });
    if (data?.updatedAt) obj.updatedAt = data.updatedAt;
    if (data.id != "" && data.id != null) obj.id = data.id;
    return obj;
};

export const onFilterApplied = createAsyncThunk(
    "flowmanager/template/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.template.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.OFFER_PATH.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const fetchTemplateHistory = createAsyncThunk(
    "flowmanager/template/templateHistory",
    async id => {
        const response = await customFetch(
            `${TEMPLATE.FETCH_TEMPLATE_HISTORY}/${id}`
        );
        return get(response, "data", []);
    }
);

export const cloneTemplate = createAsyncThunk(
    "flowmanager/template/clone",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${TEMPLATE.CLONE}/${id}`,
            "POST",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to clone Feature Group.",
                    })
                );
            }
        );
        if (get(response, "data.status", false) === "success") {
            return response.data.data;
        }
    }
);

const template = createSlice({
    name: "template",
    initialState,
    reducers: {
        setTemplateType(state, action) {
            state.createTemplate.type = action.payload;
        },
        setTemplateName(state, action) {
            state.createTemplate.name = action.payload;
        },
        setTemplateType(state, action) {
            state.createTemplate.type = action.payload;
        },
        setConfiguration(state, action) {
            state.createTemplate.configuration = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
            state.pagination.prefix = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetTemplate(state) {
            state.createTemplate = initialState.createTemplate;
        },
        setMainLoader(state, action) {
            state.createTemplate.mainLoader = action.payload;
        },
        setLoading(state, action) {
            state.createTemplate.loading = action.payload;
        },
        setShowPrompt(state, action) {
            state.createTemplate.showPrompt = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchTemplateData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchTemplateData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchTemplate.pending, state => {
            state.createTemplate.loading = true;
        });
        builder.addCase(fetchTemplate.fulfilled, (state, action) => {
            const { data } = action.payload;
            state.createTemplate.name = data.name;
            state.createTemplate.id = data.id;

            if (data?.properties) {
                let configurationData = [];
                Object.keys(data?.properties).forEach((element, idx) => {
                    if (TemplateKeys.findIndex(_ => _.name === element) > -1) {
                        configurationData.push({
                            valueOne: element,
                            valueSecond: data.properties[element],
                        });
                    }
                });
                const convertedConfig = {
                    type: "AND",
                    value: configurationData.map(v => ({
                        type: "OR",
                        value: [
                            {
                                key: v.valueOne,
                                operator: "EQUAL",
                                type: "EXPRESSION",
                                value: v.valueSecond,
                            },
                        ],
                    })),
                };
                state.createTemplate.configuration = convertedConfig;
                state.createTemplate.updatedAt = data.updatedAt;
                state.createTemplate.loading = false;
            }

            state.createTemplateBackup = JSON.parse(
                JSON.stringify(state.createTemplate)
            );
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const templateActions = template.actions;
export default template.reducer;
