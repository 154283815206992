import React, { useRef } from "react";
import Input from "../../Shared/Input/Input";
import { useDispatch } from "react-redux";
import ToggleValueSwitch from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import s from "./ThirdPartyDetails.module.scss";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";

const ThirdPartyDetails = ({
    cardTitle,
    data,
    isReadOnly,
    action,
    onChangeUrl,
    urlError,
    isSidePanel,
}) => {
    const thirdPartyRefs = {
        urlRef: useRef(null),
    };
    const dispatch = useDispatch();
    const { thirdPartyUrl, destinationTab, focusTab, enableParamForwarding } =
        data;

    const onChangeDestinationTab = val => {
        dispatch(action.setDestinationTab(val));
        if (!val) {
            dispatch(action.setFocusValue(false));
        }
    };
    const onChangeFocusTab = val => {
        dispatch(action.setFocusValue(val));
    };
    const onChangeEnableParamForwarding = val => {
        dispatch(action.setEnableParamForwarding(val));
    };

    return (
        <div
            id="collectionDetails"
            data-hash-id="collectionDetails"
            className={`${!isSidePanel ? "flexHeightTab" : ""}`}
        >
            <p className={`headerTitle`}>{cardTitle}</p>
            <div className={`row`}>
                <p className={`headerTitleRequired`}>URL*</p>
                <Input
                    className={`maxChildContainer `}
                    ref={thirdPartyRefs.urlRef}
                    type={"text"}
                    placeholder={"Enter URL"}
                    value={thirdPartyUrl ? thirdPartyUrl : ""}
                    onChange={e => onChangeUrl(e?.target?.value)}
                    required={true}
                    disabled={isReadOnly}
                    isError={urlError}
                />
            </div>
            <div className={`row`}>
                <p className={`headerTitleRequired`}>Parameter Forwarding</p>
                <ToggleSwitch
                    isToggleOn={enableParamForwarding}
                    disabled={isReadOnly}
                    setIsToggleOn={onChangeEnableParamForwarding}
                />
            </div>
            <div className={`row`}>
                <p className={`headerTitleRequired`}>Destination Tab*</p>
                <ToggleValueSwitch
                    onChange={onChangeDestinationTab}
                    leftLabel={"Active Tab"}
                    rightLabel={"New Tab"}
                    toggleState={destinationTab}
                    isDisabled={isReadOnly}
                ></ToggleValueSwitch>
            </div>
            <div className={`row`}>
                <p className={`headerTitleRequired`}>Focus*</p>
                <ToggleValueSwitch
                    onChange={onChangeFocusTab}
                    leftLabel={"Active Tab"}
                    rightLabel={"New Tab"}
                    toggleState={focusTab}
                    isDisabled={!destinationTab || isReadOnly}
                    classNameWrapper={`${
                        !destinationTab ? s.disableFocus : ""
                    }`}
                ></ToggleValueSwitch>
            </div>
        </div>
    );
};

export default ThirdPartyDetails;
