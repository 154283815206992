import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import { CAMPAIGNS, SCRUB_DASHBOARD } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { get } from "lodash";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty } from "lodash";
import { showToast } from "../../index";

const initialState = {
    list: [],
    updatedAt: new Date(),
    loading: false,
    mainLoader: false,
    flowsList: [],
    createCampaign: {
        id: null,
        name: null,
    },
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.CAMPAIGNS.filterKey,
        filterTerm: null,
        // prefix: null,
        filterOperator: FILTERS.CAMPAIGNS.filterOperator,
        filtersOperator: FILTERS.CAMPAIGNS.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "campaign"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchCampaignData = createAsyncThunk(
    "dashboards/campaign/listings",
    async (
        { refreshClicked, noPagination, advId = null },
        { dispatch, getState }
    ) => {
        const { dashboards } = getState();
        const state = get(dashboards, "campaign.pagination", {});
        const params = noPagination ? {} : paginationParams(state);
        let tagFilters = get(
            dashboards,
            "campaign.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.CAMPAIGNS
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(campaign.actions.setPageNumber(1));
            const paginationNew = noPagination
                ? {}
                : get(getState(), "dashboards.campaign.pagination", {});
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.CAMPAIGNS,
                filterParamsList
            );
        }

        const listingData = get(getState(), "dashboards.campaign.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                `${CAMPAIGNS.FETCH_CAMPAIGNS}`,
                "POST",
                // filterParamsData,
                advId
                    ? {
                          ...(filterParamsData || {}),
                          filters: [
                              {
                                  k: "maxAdvertiserId",
                                  op: "=",
                                  v: advId,
                              },
                          ],
                      }
                    : filterParamsData, //for fetching campaigns with pagination in advertiser page
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    showToast({
                        type: "Error",
                        message: "Failed to load Campaign List",
                    });
                    dispatch(
                        campaign.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(campaignActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = finalData;
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.CAMPAIGNS}`;
            dispatch(campaign.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new campaign`;
            dispatch(campaign.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchCampaign = createAsyncThunk(
    "dashboards/campaign/fetchCampaign",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${CAMPAIGNS.GET_CAMPAIGN_BY_ID}/${id}?format=true`,
            "GET",
            {},
            {},
            {},
            null,
            null,
            err => {
                showToast({
                    type: "Error",
                    message: "Failed to fetch campaign.",
                });
                return false;
            }
        );
        if (!response || response?.status >= 400) {
            showToast({
                type: "Error",
                message: "Failed to fetch campaign.",
            });
        }
        return get(response, "data.data", []);
    }
);

export const fetchCampaignAll = createAsyncThunk(
    "dashboards/campaign/fetchCampaignAll",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${CAMPAIGNS.GET_ALL_CAMPAIGN}`,
            "GET"
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Campaign!",
                })
            );
        }
        //sorting based on created at
        //As backend gives all data
        let descData = get(response, "data.data", [])?.sort(
            ({ createdAt: a }, { createdAt: b }) => (b < a ? -1 : b > a ? 1 : 0)
        );
        return descData;
    }
);

export const approveCampaign = createAsyncThunk(
    "dashboards/campaign/approve",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${CAMPAIGNS.APPROVE_CAMPAIGN}`,
            "POST",
            params
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to approve campaign!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const goLiveCampaign = createAsyncThunk(
    "dashboards/campaign/goLiveCampaign",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${CAMPAIGNS.GOLIVE_CAMPAIGN}`,
            "POST",
            params
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to go live!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const goLiveMappingCompleted = createAsyncThunk(
    "dashboards/campaign/mapping",
    async (params, { dispatch }) => {
        const response = await customFetch(
            `${CAMPAIGNS.MAPPING_CAMPAIGN_COMPLETED}`,
            "POST",
            params
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to go live!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

export const createCampaign = createAsyncThunk(
    "dashboards/campaign/createCampaign",
    async (params, { getState, dispatch }) => {
        const response = await customFetch(
            CAMPAIGNS.CREATE_CAMPAIGN,
            "POST",
            params,
            {},
            {},
            null,
            null,
            err => {
                if (
                    err?.response?.data?.error?.includes(
                        "CAMPAIGN_NAME_DUPLICATE"
                    )
                ) {
                    showToast({
                        type: "Error",
                        message: "Duplicate campaign name.",
                    });
                } else if (
                    err?.response?.data?.error?.includes("Image not compatible")
                ) {
                    showToast({
                        type: "Error",
                        message:
                            "Image not compatible, Image requirements(min height: 100px, min width: 177px, max height: 20000px, max width: 38200px, aspect ratios: 1:1, 4:3, 1.91:1, 16:9, 1.5:1)",
                    });
                } else {
                    showToast({
                        type: "Error",
                        message: "Failed to create campaign.",
                    });
                }
                return false;
            }
        );
        if (!response || response?.status >= 400) {
            showToast({
                type: "Error",
                message: `Failed to create campaign.`,
            });
            return false;
        }
        return get(response, "data", null);
    }
);

export const updateCampaign = createAsyncThunk(
    "dashboards/campaign/updateCampaign",
    async (data, { getState, dispatch }) => {
        const response = await customFetch(
            `${CAMPAIGNS.UPDATE_CAMPAIGN}/${data.id}`,
            "PUT",
            data.params,
            {},
            {},
            null,
            null,
            err => {
                showToast({
                    type: "Error",
                    message: "Failed to save campaign.",
                });
                return false;
            }
        );
        if (!response || response?.status >= 400) {
            showToast({
                type: "Error",
                message: `Failed to save campaign.`,
            });
            return false;
        }
        return get(response, "data", null);
    }
);

export const fetchPublisherCampaigns = createAsyncThunk(
    "dashboards/campaign/publisherCampaigns",
    async (pubId, { getState, dispatch }) => {
        const response = await customFetch(
            `${SCRUB_DASHBOARD.GET_CAMPAIGN_FOR_PUBLISHER}/${pubId}/campaigns`,
            "GET",
            {},
            {},
            {},
            null,
            null,
            err => {
                showToast({
                    type: "Error",
                    message: "Failed to fetch campaigns for this publisher.",
                });
                return false;
            }
        );
        if (!response || response?.status >= 400) {
            showToast({
                type: "Error",
                message: "Failed to fetch campaigns for this publisher.",
            });
            return false;
        }
        console.log(response.data);
        return get(response, "data", null);
    }
);

export const onFilterApplied = createAsyncThunk(
    "dashboards/campaign/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { dashboards } = getState();
        let initialData = dashboards.campaign.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.CAMPAIGN.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const campaign = createSlice({
    name: "campaign",
    initialState,
    reducers: {
        setCampaignId(state, action) {
            state.createCampaign.id = action.payload;
        },
        setCampaignName(state, action) {
            state.createCampaign.name = action.payload;
        },

        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
            state.pagination.prefix = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetData(state) {
            state.createCampaign = initialState.createCampaign;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setShowPrompt(state, action) {
            state.createContainer.showPrompt = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setList(state, action) {
            state.list = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCampaignData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchCampaignData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(fetchCampaignAll.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchCampaignAll.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = action.payload;
            state.loading = false;
        });
    },
});

export const campaignActions = campaign.actions;
export default campaign.reducer;
