import {
    SortableContainer,
    SortableElement,
    SortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import styles from "./TagListDraggable.scss";
import { eq, cloneDeep, get } from "lodash-es";
import Icon from "../../Shared/Icon/Icon";
import Popup from "../../Shared/Popup/Popup";
import React, { createRef, useEffect, useRef, useState } from "react";
import Input from "../../Shared/Input/Input";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";

const TagListDragable = ({ itemsList, setItemList, toggleMode, toggleKey }) => {
    const dragContainerRefs = useRef([]);
    const keyRef = useRef("");
    const valRef = useRef("");

    const removeItem = removeItem => {
        let prevsState = cloneDeep(itemsList);
        prevsState = prevsState.filter(
            item => get(item, "id", "") != get(removeItem, "id", "")
        );
        setItemList(prevsState);
    };
    const changeItem = (changeItem, index) => {
        let prevState = cloneDeep(itemsList);
        prevState.map(item => {
            if (get(item, "id", "") == get(changeItem, "id", "")) {
                item["key"] =
                    (keyRef?.current && keyRef?.current?.value()) ||
                    get(changeItem, "key", "") ||
                    "";
                item["value"] =
                    (valRef?.current && valRef?.current?.value()) ||
                    get(changeItem, "value", "") ||
                    "";
                if (toggleMode && toggleKey) {
                    item[toggleKey] = changeItem[toggleKey];
                }
            }
            return item;
        });
        setItemList(prevState);
        dragContainerRefs.current[index]?.current?.close();
    };
    const TagContent = ({ item, index }) => {
        let tempRef = createRef();
        dragContainerRefs.current[index] = tempRef;

        const [togValue, setTogValue] = useState(false);

        return (
            <li
                className={`dp-parent dp-child-no-grow ${styles["sortable-list-item"]}`}
            >
                <DragHandle />
                <Tooltip message={`Click to edit`} position="top">
                    <div
                        className={`${styles["item-content-wrap"]} dp-parent dp-parent-hor-center dp-parent-ver-center`}
                        onClick={() => {
                            setTogValue(item[toggleKey]);
                            dragContainerRefs.current[index].current.open();
                        }}
                    >
                        <span
                            className={`${styles["item-bullet"]} ${
                                toggleMode && item[toggleKey]
                                    ? toggleMode && styles.enabled
                                        ? styles.enabled
                                        : styles.disabled
                                    : styles.disabled
                            }`}
                        ></span>
                        <span className={styles["item-key"]}>{item.key}</span>
                        <span className={styles["item-value"]}>
                            {item.value}
                        </span>
                    </div>
                </Tooltip>
                <span
                    title={"remove"}
                    onClick={removeItem.bind(null, item)}
                    className={styles["remove-item"]}
                >
                    <Icon icon="CloseFilled" size="18px" />
                </span>

                <Popup
                    ref={dragContainerRefs.current[index]}
                    showHeader={false}
                    showFooter={false}
                    showConfirmBox={true}
                    closeOutside={true}
                    onSave={() => {
                        if (toggleMode) {
                            changeItem(
                                { ...item, [toggleKey]: togValue },
                                index
                            );
                        } else {
                            changeItem(item, index);
                        }
                    }}
                    genericClose={true}
                    CustomRender={() => {
                        return (
                            <div className={`${styles["tag-edit"]} dp-parent`}>
                                <div className={"dp-child-50"}>
                                    <p className={"headerTitleRequired"}>
                                        Value
                                    </p>
                                    <Input ref={keyRef} value={item.key} />
                                </div>
                                <div className={"dp-child-50"}>
                                    <p className={"headerTitleRequired"}>
                                        Text
                                    </p>
                                    <Input ref={valRef} value={item.value} />
                                </div>
                                {toggleMode && (
                                    <div className={styles.editPopupToggle}>
                                        <p className={"headerTitleRequired"}>
                                            Confirm TCPA
                                        </p>
                                        <ToggleSwitch
                                            isToggleOn={togValue}
                                            setIsToggleOn={val => {
                                                setTogValue(!togValue);
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    }}
                    width={"510px"}
                    top={"0"}
                    left={"0"}
                    portal={false}
                ></Popup>
            </li>
        );
    };
    const DragHandle = SortableHandle(() => (
        <span
            className={`${styles["drag-item"]} dp-parent dp-parent-hor-center dp-parent-ver-center`}
        >
            <Icon icon="Drag" size="18px" />
        </span>
    ));
    const SortableItem = SortableElement(({ item, sortIndex }) => (
        <TagContent index={sortIndex} item={item}></TagContent>
    ));
    const SortableList = SortableContainer(({ children }) => {
        return (
            <ul
                className={`dp-parent flex-wrap ${styles["sortable-list-wrap"]}`}
            >
                {children}
            </ul>
        );
    });
    const onSortEnd = ({ oldIndex, newIndex }) => {
        let prevsState = cloneDeep(itemsList);
        prevsState = arrayMoveImmutable(prevsState, oldIndex, newIndex);
        setItemList(prevsState);
    };
    return (
        <SortableList
            lockToContainerEdges={true}
            lockAxis="xy"
            axis="xy"
            onSortEnd={onSortEnd}
            useDragHandle
        >
            {itemsList &&
                itemsList.map((item, index) => (
                    <SortableItem
                        key={`item-${item.value}-${index}`}
                        sortIndex={index}
                        index={index}
                        item={item}
                    />
                ))}
        </SortableList>
    );
};

export default TagListDragable;
