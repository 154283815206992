import { useSelector } from "react-redux";

import pageNotFoundImg from "../../assets/img/404.svg";
import upload_csv from "../../assets/img/upload_data.svg";
import styles from "./CustomMessagePage.module.scss";
import apiFailed from "../../assets/img/apiFailed.svg";
import nodatadaterange from "../../assets/img/nodatadaterange.png";
import noDataWithFilters from "../../assets/img/noDataWithFilters.svg";
import nodatawithoutfilters from "../../assets/img/nodatawithoutfilters.svg";
import newApiFailed from "../../assets/img/newApiFailed.svg";

import pageNotFoundImg_Dark from "../../assets/img/404-dark.svg";
import upload_csv_Dark from "../../assets/img/upload_data-dark.svg";
import apiFailed_Dark from "../../assets/img/apiFailed-dark.svg";
import nodatadaterange_Dark from "../../assets/img/nodatadaterange-dark.png";
import noDataWithFilters_Dark from "../../assets/img/noDataWithFilters-dark.svg";
import nodatawithoutfilters_Dark from "../../assets/img/nodatawithoutfilters-dark.svg";
import newApiFailed_Dark from "../../assets/img/newApiFailed-dark.svg";
import Button, { ButtonTypes } from "../../Shared/Button/Button";

const CustomMessagePage = ({
    imgFile = "Oops, failed to fetch",
    message = "This page is under maintenance",
    showRefresh,
    onRefresh,
    showActionBtn,
    actionBtnHandler,
    actionBtnText,
    fullHeight = false,
    btnTheme = ButtonTypes.default_btn,
}) => {
    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);

    const getImg = imgFile => {
        if (
            imgFile === "upload_csv" ||
            imgFile?.indexOf("Apply Filters to fetch user data") > -1
        ) {
            return (
                <img
                    src={isDarkMode ? upload_csv_Dark : upload_csv}
                    alt={message}
                />
            );
        } else if (imgFile?.indexOf("No results found, try searching") > -1) {
            return (
                <img
                    src={
                        isDarkMode ? noDataWithFilters_Dark : noDataWithFilters
                    }
                    alt={message}
                />
            );
        } else if (imgFile?.indexOf("Oops, failed to fetch") > -1) {
            return (
                <img
                    src={isDarkMode ? apiFailed_Dark : apiFailed}
                    alt={message}
                />
            );
        } else if (
            imgFile?.indexOf("Looks like there is no data") > -1 ||
            imgFile?.indexOf(
                "There are no records matching your selected filters."
            ) > -1
        ) {
            return (
                <img
                    src={
                        isDarkMode
                            ? nodatawithoutfilters_Dark
                            : nodatawithoutfilters
                    }
                    alt={message}
                />
            );
        } else if (
            imgFile?.indexOf("No results found for the selected date range") >
            -1
        ) {
            return (
                <img
                    src={isDarkMode ? nodatadaterange_Dark : nodatadaterange}
                    alt={message}
                />
            );
        } else if (imgFile?.indexOf("Error Msg") > -1) {
            return (
                <img
                    src={isDarkMode ? newApiFailed_Dark : newApiFailed}
                    alt={message}
                />
            );
        } else if (imgFile?.indexOf("404") > -1) {
            return (
                <img
                    src={isDarkMode ? pageNotFoundImg_Dark : pageNotFoundImg}
                    alt={message}
                />
            );
        } else if (
            message.indexOf(
                "Enter Data & upload CSV file to generate listing"
            ) > -1 ||
            message.indexOf("Enter Data & Scrub Rate to generate listing.") > -1
        )
            return (
                <img
                    src={isDarkMode ? upload_csv_Dark : upload_csv}
                    alt={message}
                />
            );
        else
            return (
                <img
                    src={isDarkMode ? pageNotFoundImg_Dark : pageNotFoundImg}
                    alt={message}
                />
            );
    };

    return (
        <div
            className={`${styles.pageWrap} ${
                fullHeight ? styles.fullHeight : ""
            } `}
        >
            <div className={styles.img_wrap}>{getImg(imgFile)}</div>
            <div className={styles.txt}>
                {message}
                {onRefresh && showRefresh && (
                    <>
                        {""} Please
                        <span onClick={onRefresh}> refresh</span> and try again.
                    </>
                )}
                {showActionBtn && typeof actionBtnHandler === "function" && (
                    <div className={styles.goBackBtn}>
                        <Button
                            onClick={() => actionBtnHandler()}
                            btnTheme={btnTheme}
                        >
                            {actionBtnText}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomMessagePage;
