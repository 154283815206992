import React from "react";
import { withRouter } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import AppNavigator from "../AppNavigator/AppNavigator";
import s from "./Layout.module.scss";

const Layout = props => {
    const { pathname } = props.location;
    const { isDarkMode, showLeftNav, leftNavAutoExpandCollapse } = useSelector(
        state => state.globalConfig
    );

    const rightPanelRef = useRef(null);

    useEffect(() => {
        if (pathname.indexOf("listings") !== -1) {
            document.body.style.background = "#fff";
        } else {
            document.body.style.background = "#F3F9F9";
        }
        if (document.body.querySelector(".bodyContainer")) {
            if (pathname.indexOf("release") !== -1) {
                document.body.querySelector(".bodyContainer").style.paddingTop =
                    "30px";
            } else {
                document.body.querySelector(".bodyContainer").style.paddingTop =
                    "0px";
            }
        }
    }, [props.location]);

    const showHideAppNavigator = () => {
        if (
            !pathname.includes("release") &&
            !pathname.includes("users") &&
            !pathname.includes("user-group") &&
            !pathname.includes("dailystatus") &&
            !pathname.includes("hourlystatus")
        ) {
            return <AppNavigator />;
        } else {
            return;
        }
    };

    const appNavigatorClass = () => {
        if (
            pathname.includes("release") ||
            pathname.includes("users") ||
            pathname.includes("user-group") ||
            pathname.includes("dailystatus") ||
            pathname.includes("hourlystatus")
        ) {
            return s.noAppNavigator;
        } else {
            return;
        }
    };

    const panelSizeClassAppend = () => {
        if (window.innerWidth > 1900) {
            rightPanelRef?.current?.classList.add(s.rightPanelLarge);
            if (
                pathname.includes("/flow/create") ||
                pathname.includes("/flow/edit")
            ) {
                rightPanelRef?.current?.classList.add(s.maxWidthFlow);
            } else {
                rightPanelRef?.current?.classList.remove(s.maxWidthFlow);
                rightPanelRef?.current?.classList.add(s.maxWidth);
            }
        } else {
            rightPanelRef?.current?.classList.remove(s.maxWidth);

            if (!leftNavAutoExpandCollapse) {
                if (
                    pathname.includes("create") ||
                    pathname.includes("edit") ||
                    pathname.includes("userdashboard/details")
                ) {
                    rightPanelRef?.current?.classList.add(s.rightPanelSmall);
                }
            }
        }
    };

    useEffect(() => {
        panelSizeClassAppend();
        window.addEventListener("resize", panelSizeClassAppend);
        return () => {
            window.removeEventListener("resize", panelSizeClassAppend);
        };
    }, [pathname]);

    return (
        <React.Fragment>
            {pathname !== "/login" && (
                <div
                    className={`bodyContainer dp-parent ${
                        pathname.indexOf("listings") !== -1 ? "listingPage" : ""
                    } ${appNavigatorClass()}`}
                >
                    <div className="dp-parent w-100">
                        <div className={s.navigatorLeftPanel}>
                            {showHideAppNavigator()}
                        </div>
                        <div
                            className={`${s.navigatorRightPanel} ${
                                showLeftNav
                                    ? s.rightPanelLarge
                                    : s.rightPanelSmall
                            }`}
                            ref={rightPanelRef}
                        >
                            {props.children}
                        </div>
                    </div>
                </div>
            )}
            {pathname === "/login" && props.children}
        </React.Fragment>
    );
};

export default withRouter(Layout);
