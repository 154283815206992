import React, { useRef, useState, useEffect, memo } from "react";
import Input from "../../Shared/Input/Input";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import Select from "../../Shared/Select/Select";
import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { fetchSurveysData } from "../../store/listings/surveys";
import { adUnitsActions } from "../../store/listings/adUnits";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { propEqualComparision } from "../../utils/globalUtils";
import s from "./AdUnitBasics.module.scss";
import Popup from "../../Shared/Popup/Popup";

const AdUnitBasics = forwardRef(
    (
        {
            name,
            type,
            surveys,
            selectedSurveyIds,
            status,
            onChangeName,
            onSelectSurvey,
            onChangeType,
            onChangeStatus,
            dropdownError,
            adUnitId,
            readOnly = false,
            onSortFunction,
        },
        ref
    ) => {
        const inputRef = useRef(null);
        // const slotTypeList = [];

        const slotTypeList = [
            { id: 1, key: "WeightGroup", text: "WeightGroup" },
            { id: 2, key: "PriorityGroup", text: "PriorityGroup" },
            { id: 3, key: "RtbGroup", text: "RtbGroup" },
        ];
        // console.log(ref);

        const dispatch = useDispatch();
        const urlParams = useParams();

        const slots = useSelector(
            state => state.listings.adUnits.createAdUnit.slots.selectedListItems
        );

        const getBasics = useSelector(
            state => state.listings.adUnits.createAdUnit.basics
        );

        const cancelEdit = () => {
            // Reseting to default;
        };

        // useEffect(() => {
        //   dispatch(fetchSurveysData())
        //     .unwrap()
        //     .then((response) => {
        //       dispatch(adUnitsActions.setSurveys(response));
        //     });
        // }, []);

        return (
            <div
                data-hash-id="adUnitBasics"
                id="adUnitBasics"
                className={`flexHeightTab`}
            >
                <div className={`row`}>
                    <p className={`headerTitle`}>Basics</p>

                    {urlParams.id && (
                        <div className={`row`}>
                            <p className={`headerTitleRequired`}>ID</p>
                            <p className={`infoText`}>
                                ID associated with an Ad Unit
                            </p>
                            <p>{adUnitId}</p>
                        </div>
                    )}

                    <p className={`headerTitleRequired`}>Name*</p>
                    <p className={`infoText`}>
                        They are linked to multiple surveys.{" "}
                        {/* <a href="#" className="">
              Learn more
            </a> */}
                    </p>
                    <div className={`dp-parent`}>
                        <div className={`inputLayer`}>
                            <Input
                                type="text"
                                // ref={inputRef}
                                ref={ref}
                                required={true}
                                placeholder="Create new add unit"
                                value={name}
                                onChange={onChangeName}
                                disabled={readOnly}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className={`row`}>
          <p className={`headerTitleRequired`}>Select Survey</p>
          <p className={`infoText`}>Select one or many surveys from below</p>
          <Select
            label={selectedSurveyIds.length ? null : "Select Survey name"}
            options={surveys}
            keyField={"name"}
            onSelect={onSelectSurvey}
            idField={"id"}
            selectedIds={selectedSurveyIds}
            isError={dropdownError}
            multiSelect={true}
          />
        </div> */}

                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Slot type*</p>
                    <p className={`infoText`}>
                        Ad unit has a slot type consisting of weight group.
                        {/* <a href="#" className="">
              Learn more
            </a> */}
                    </p>
                    <Select
                        options={slotTypeList}
                        keyField={"text"}
                        onSelect={onChangeType}
                        idField={"key"}
                        selectedId={type}
                        isError={dropdownError}
                        label={type != null ? null : "Select a type"}
                        onLoadSelectReturn={false}
                        isDisabled={readOnly || urlParams.id || slots.length}
                    />
                </div>
                {type === "RtbGroup" && (
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>Optimisation</p>
                        <p className={`infoText`}>
                            Ad unit has a slot type consisting of weight group.
                        </p>

                        <MultiplierPopup
                            readOnly={readOnly}
                            bidMultiplier={getBasics?.bidMultiplier}
                            scoreMultiplier={getBasics?.scoreMultiplier}
                            onSave={({ bid, score }) => {
                                dispatch(adUnitsActions.setBidMultiplier(bid));
                                dispatch(
                                    adUnitsActions.setScoreMultiplier(score)
                                );
                                onSortFunction({ bid: bid, score: score });
                            }}
                            onDropdownToggle={open => {
                                if (open) {
                                    cancelEdit();
                                }
                            }}
                        />
                    </div>
                )}
                {/* <div className={`row`}>
          <p className={`headerTitleRequired`}>Ad Unit status</p>
          <ToggleSwitch
            isToggleOn={status}
            setIsToggleOn={onChangeStatus}
            setText={true}
          />
        </div> */}
            </div>
        );
    }
);

const MultiplierPopup = memo(
    ({
        readOnly = false,
        editable = true,
        bidMultiplier,
        scoreMultiplier,
        onSave,
        onDropdownToggle = () => null,
    }) => {
        const [multiplierDropdownOpen, setMultiplierDropdownOpen] =
            useState(false);

        const [bidMultiplierPercentage, setBidMultiplierPercentage] = useState(
            bidMultiplier * 100
        );
        const [scoreMultiplierPercentage, setScoreMultiplierPercentage] =
            useState(scoreMultiplier * 100);

        const ref = useRef(null);

        const getPopupPosition = () => {
            const rect = ref.current.getBoundingClientRect();
            return {
                top: rect.y + window.scrollY + 20,
                left: rect.left,
            };
        };

        useEffect(() => {
            onDropdownToggle(multiplierDropdownOpen);
        }, [multiplierDropdownOpen]);

        return (
            <>
                {multiplierDropdownOpen && (
                    <Popup
                        showHeader={true}
                        showFooter={false}
                        showConfirmBox={true}
                        saveButtonText={"Done"}
                        cancelButtonText={"Cancel"}
                        closeOutside={true}
                        disableSave={readOnly}
                        width={"320px"}
                        textAlign={"left"}
                        title={"Change Formula"}
                        portal={true}
                        customClass={s.multiplierFormContainer}
                        {...getPopupPosition()}
                        onClose={() => setMultiplierDropdownOpen(false)}
                        onSave={() => {
                            onSave({
                                bid: bidMultiplierPercentage / 100,
                                score: scoreMultiplierPercentage / 100,
                            });
                            setMultiplierDropdownOpen(false);
                        }}
                        headerPadding={"20px"}
                        CustomRender={FormulaForm}
                        customRenderProps={{
                            bidMultiplierPercentage,
                            setBidMultiplierPercentage,
                            scoreMultiplierPercentage,
                            setScoreMultiplierPercentage,
                            readOnly,
                        }}
                    ></Popup>
                )}
                <span
                    ref={ref}
                    className={`${editable ? "link" : ""} italic ${
                        s.formulaDisplay
                    }`}
                    onClick={() => {
                        if (editable) {
                            setMultiplierDropdownOpen(true);
                        }
                    }}
                >
                    Bid * {bidMultiplier} + <br></br> Relevance Score *{" "}
                    {scoreMultiplier}
                </span>
            </>
        );
    }
);

const FormulaForm = memo(
    ({
        bidMultiplierPercentage,
        setBidMultiplierPercentage,
        scoreMultiplierPercentage,
        setScoreMultiplierPercentage,
        readOnly,
    }) => {
        return (
            <>
                <div className={s.formula}>
                    Formula:{" "}
                    <span className="italic">
                        Bid * {bidMultiplierPercentage / 100} + Relevance Score
                        * {scoreMultiplierPercentage / 100}
                    </span>
                </div>
                <div className={s.multiplierForm}>
                    <table>
                        <tbody>
                            <tr>
                                <td>Bid Weight </td>
                                <td>
                                    <Input
                                        type={"number"}
                                        placeholder="Enter bid multiplier"
                                        value={bidMultiplierPercentage}
                                        valueType="percentage"
                                        onChange={e => {
                                            const value = e.target.value;
                                            setBidMultiplierPercentage(value);
                                            const remainingScore = 100 - value;
                                            setScoreMultiplierPercentage(
                                                remainingScore
                                            );
                                        }}
                                        disabled={readOnly}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Relevance Score Weight </td>
                                <td>
                                    <Input
                                        type={"number"}
                                        valueType="percentage"
                                        placeholder="Enter score multiplier"
                                        value={scoreMultiplierPercentage}
                                        onChange={e => {
                                            const value = e.target.value;
                                            setScoreMultiplierPercentage(value);
                                            const remainingScore = 100 - value;
                                            setBidMultiplierPercentage(
                                                remainingScore
                                            );
                                        }}
                                        disabled={readOnly}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    },
    (next, prev) => {
        return propEqualComparision(next, prev, [
            "bidMultiplierPercentage",
            "scoreMultiplierPercentage",
        ]);
    }
);

export default AdUnitBasics;
