import React, { useEffect, useState, useRef } from "react";
import { get, findIndex } from "lodash-es";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import Input from "../../Shared/Input/Input";
import Icon from "../../Shared/Icon/Icon";
import styles from "./InputList.module.scss";
import CsvReader from "../../Shared/CsvFileReader/CsvReader";
import { CSVLink } from "react-csv";
import Select from "../../Shared/Select/Select";
import { toastListActions } from "../../store/toastList/toastList";
import { useDispatch, useSelector } from "react-redux";
import ChannelMapTable from "../ChannelMapTable/ChannelMapTable";
import { cloneDeep, isEmpty } from "lodash";
import FileUploader from "../FileUploader/FileUploader";
import { ZIPCODE } from "../../constants/templateData";
import { isValidIP } from "../../utils/globalUtils";
import {
    BROWSERSLIST,
    COUNTRIESLIST,
    DEVICESLIST,
    OPERATINGSYSTEMLIST,
    USERSTATUSDROPDOWNOPTIONS,
    UTMSOURCESDROPDOWNOPTIONS,
} from "../../constants/targettingParamsConstants";
import { REGISTER_DETAIL_DROPDOWN } from "../../constants/collectionConstants";
import {
    arrayMove,
    SortableContainer,
    SortableElement,
    sortableHandle,
} from "react-sortable-hoc";
import { getRandomId } from "../../utils/globalUtils";
import Tooltip from "../../Shared/Tooltip/Tooltip";
import Slider from "@mui/material/Slider";
import ReactSelect from "../../Shared/ReactSelect/ReactSelect";
import LogoComponent from "../../Shared/Logo/LogoComponent";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import PopupTargettingParams from "../PopupTargettingParams/PopupTargettingParams";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import ToggleValueSwitch from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import CompareList from "../../Shared/CompareList/CompareList";

const InputList = ({
    type,
    title,
    placeholderOne,
    placeholderTwo,
    savedData, //for comparison with new data
    setSavedData,
    inputJson = {},
    dropDownJson,
    mainState,
    setMainState,
    customValidation,
    csvFileName,
    setCsvFileName,
    csvFile,
    setCsvFile,
    csvArray,
    setCsvArray,
    zipcodeValues,
    setZipcodeValues,
    error,
    setError,
    onDropActiveZipCode,
    setonDropActiveZipCode,
    disabledValidation = false,
    dropDownJsonSecond,
    addedData = () => {},
    showTags = true,
    showTable = false,
    updatedOptions = [],
    updatedSecondDropdownOptions = [],
    onDelete,
    dropDownOptionToParent,
    readOnly = false,
    showToggle = false,
    multiSelectCheckbox = false,
    multiSelectCheckboxJson,
    singleInputBulkUpload = false,
    tagWrappable = false,
    characterLimitForUpload = null,
    hideSelectedPills = false,
    dynamicInputAndCsvUploaderType = false,
    uploadMsg = "",
    mainStateDataHide = false,
    labelOne,
    labelTwo,
}) => {
    const inputTypes = {
        singleInput: "singleInput",
        multipleInput: "multipleInput",
        inputDropdown: "inputDropdown",
        inputDropdownReverse: "inputDropdownReverse",
        ZipCodeType: "ZipCodeType",
        singleDropdown: "singleDropdown",
        multipleDropdown: "multipleDropdown",
        multipleSelect: "multipleSelect",
        singleDropdownCreateTags: "singleDropdownCreateTags",
        ClientIPsType: "ClientIPsType",
        multipleInputSortable: "multipleInputSortable",
        rangeSlider: "rangeSlider",
    };
    // const [values, setValues] = useState([]);
    const [valueOneState, setValueOneState] = useState("");
    const [valueSecondState, setValueSecondState] = useState("");
    // const [valueThirdState, setValueThirdState] = useState("");
    // const [valueThirdStateError, setValueThirdStateError] = useState(false);
    const [toggleValue, setToggleValue] = useState(false);
    const [showError, setShowError] = useState(false);
    const [secondDropdownError, setSecondDropdownError] = useState(false);
    const [dropdownText, setDropdownText] = useState("");
    const [disabledValidationBtn, setDisabledValidationBtn] = useState(false);

    const [valueOneStateError, setValueOneStateError] = useState(false);
    const [valueSecondStateError, setValueSecondStateError] = useState(false);
    const [showCount, setShowCount] = useState(!showTable && !showTags);
    const zipCodeRef = useRef(null);
    const clientIPsRef = useRef(null);

    const isDarkMode = useSelector(state => state.globalConfig.isDarkMode);

    const [optionsOneUpdatedList, setOptionsOneUpdatedList] =
        useState(updatedOptions);
    const [optionsTwoUpdatedList, setOptionsTwoUpdatedList] = useState(
        updatedSecondDropdownOptions
    );
    const [showPopup, setShowPopup] = useState(false);
    const [valueToDelete, setValueToDelete] = useState({});
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);

    const [includedList, setIncludedList] = useState([]);
    const [excludedList, setExcludedList] = useState([]);

    const [characterLimitExceedDisable, setCharacterLimitExceedDisable] =
        useState(false);

    const inputOneRef = useRef(null);
    const inputTwoRef = useRef(null);
    const dispatch = useDispatch();
    const fileDropRef = useRef(null);

    let data = [{ zipcode: 40005 }, { zipcode: 12345 }, { zipcode: 32087 }];
    let clientIPsData = [
        { clientIP: "198.162.0.1" },
        { clientIP: "255.32.50.155" },
        { clientIP: "8.8.8.8" },
    ];

    let noramlTextData = [
        { data: "Amazon" },
        { data: "Work from home" },
        { data: "jobs" },
    ];

    const removeValue = (index, element) => {
        var tempStrMainState = [...mainState]; // make a separate copy of the array
        tempStrMainState.splice(index, 1);
        setMainState(tempStrMainState);

        if (characterLimitForUpload !== null) {
            if (
                tempStrMainState.join(",").replaceAll(",", "")?.length <=
                characterLimitForUpload
            ) {
                setCharacterLimitExceedDisable(false);
            }
        }
    };

    const onDeleteItemClick = item => {
        if (item && item.valueOne) {
            setValueToDelete(item);
            setShowPopup(item.valueOne);
        }
    };

    const selectFile = async file => {
        await setSelectedFiles(file);

        if (file && file[0]) {
            var reader = new FileReader();
            let filename = file[0].name;
            reader.onload = function (e) {
                var contents = e.target.result;
                if (typeof filename === "undefined") {
                    setError(true);
                } else {
                    processCSV(contents, filename);
                }
            };
            reader.readAsText(file[0]);
        }
    };

    const processCSV = (str, filename, delim = ",") => {
        try {
            setUploadSuccess(false);
            const headers = str
                .slice(0, str.indexOf("\n"))
                // .replace(/\r?\n|\r/g, " ")
                .replace(/['"]+/g, "")
                .trim()
                .split(delim);
            const rows = str
                .slice(str.indexOf("\n") + 1)
                .replace(/['"]+/g, "")
                .split("\n");

            const newArray = rows.map(row => {
                const values = row.split(delim);
                const eachObject = headers.reduce((obj, header, i) => {
                    obj[header] = values[i];
                    return obj;
                }, {});
                return eachObject;
            });
            let valueCopy = [];
            newArray.map((element, index) => {
                var size = Object.keys(element).length;
                let trimmedData = element.zipcode
                    .replace(/\r?\n|\r/g, " ")
                    .trim();
                if (size == 1 && element.zipcode && trimmedData.length == 5) {
                    if (!mainState.includes(trimmedData)) {
                        valueCopy.push(+trimmedData);
                    }
                    setError(false);
                } else if (element.zipcode == "" && trimmedData.length == 5) {
                    setError(false);
                } else {
                    setError(true);
                }
            });
            setCsvFileName(filename);
            setCsvArray(valueCopy);
            setUploadSuccess(true);
            if (valueCopy.length) {
                let newZipcodes = [];
                valueCopy.map(zipcode => {
                    if (mainState.indexOf(zipcode) == -1) {
                        newZipcodes.push(zipcode);
                    }
                });
                let mainStateCopy = [];
                if (isReset) mainStateCopy = [...newZipcodes];
                else mainStateCopy = [...newZipcodes, ...mainState];
                setMainState(mainStateCopy);
            }
            selectFile(undefined);
        } catch (error) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Error while uploading csv.",
                })
            );
            selectFile(undefined);
            setUploadSuccess(false);
        }
    };

    const onNew = e => {
        // let filename = e.target.files[0].name || e[0].name;
        // const file = e.target.files[0] || e[0];

        let filename =
            e && e.target && e.target.files
                ? e.target.files[0].name
                : e[0].name;
        let file = e && e.target && e.target.files ? e.target.files[0] : e[0];

        const reader = new FileReader();

        reader.onload = function (e) {
            const text = e.target.result;
            if (typeof filename === "undefined") {
                setError(true);
            } else {
                processCSV(text, filename); // plugged in here
            }
        };
        reader.readAsText(file);
    };

    const deleteRow = val => {
        var tempStrMainState = [...mainState];
        const index = tempStrMainState
            .map(e => e.valueOne)
            .indexOf(val.valueOne);
        let valueToAdd = tempStrMainState[index];
        onDelete(valueToAdd);
        tempStrMainState.splice(index, 1);
        setMainState(tempStrMainState, "delete");
        setShowPopup(false);
    };

    const toggleShowCount = () => {
        setShowCount(!showCount);
    };

    useEffect(() => {
        if (get(inputJson, "activeRef", "") == "secondRef") {
            inputTwoRef?.current?.focus();
        } else if (get(inputJson, "activeRef", "") == "firstRef") {
            inputOneRef?.current?.focus();
        }
    }, []);

    useEffect(() => {
        if (disabledValidation && valueSecondState.length <= 0) {
            setDisabledValidationBtn(true);
        } else if (disabledValidation && valueOneState.length <= 0) {
            setDisabledValidationBtn(true);
        } else {
            setDisabledValidationBtn(false);
        }
    }, [disabledValidation, valueOneState, valueSecondState]);

    useEffect(() => {
        if (type === inputTypes.multipleDropdown) {
            setValueOneState(null);
            setValueSecondState(null);
            setOptionsOneUpdatedList(updatedOptions);
            setOptionsTwoUpdatedList(updatedSecondDropdownOptions);
        }
    }, [updatedOptions]);

    const sendErrorToast = text => {
        dispatch(
            toastListActions.setToastList({
                type: "Error",
                message: text,
            })
        );
    };

    const isAgeRangeOverlap = (range1, range2) => {
        const start1 = parseInt(range1.valueOne);
        const end1 = parseInt(range1.valueSecond);
        const start2 = parseInt(range2.valueOne);
        const end2 = parseInt(range2.valueSecond);

        // Check if any of the ranges include the start or end of the other range
        if (
            (start1 >= start2 && start1 <= end2) ||
            (end1 >= start2 && end1 <= end2) ||
            (start2 >= start1 && start2 <= end1) ||
            (end2 >= start1 && end2 <= end1)
        ) {
            return true; // There's an overlap
        }

        return false; // No overlap
    };

    const valueSubmit = async e => {
        e.preventDefault();
        let isCustomValid = true;
        let customValidationRes = {};
        let isIncludedFromParent = false;
        let indexToReverseData = 0;
        if (type === inputTypes.singleInput) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(
                    valueOneState,
                    inputJson.showIncludeExclude && !toggleIsExcluded
                );
                isCustomValid = customValidationRes.isValid;
                isIncludedFromParent =
                    customValidationRes?.isIncludedFromParent;
                indexToReverseData = customValidationRes?.indexToReverseData;
            }
            if (inputOneRef?.current?.validate() && isCustomValid) {
                if (mainState.indexOf(valueOneState) === -1) {
                    if (inputJson.showIncludeExclude) {
                        if (isIncludedFromParent) {
                            let mainStateCopy = cloneDeep(mainState);
                            mainStateCopy.forEach((val, i) => {
                                if (i === indexToReverseData) {
                                    val.isIncluded = !val.isIncluded;
                                }
                            });
                            setMainState(mainStateCopy);
                        } else {
                            setMainState([
                                ...mainState,
                                {
                                    name: valueOneState,
                                    isIncluded: !toggleIsExcluded,
                                },
                            ]);
                        }
                    } else {
                        setMainState([...mainState, valueOneState]);
                    }
                    setValueOneState("");
                    setShowError(false);
                } else {
                    sendErrorToast(
                        `${title || ""} ${valueOneState} already exist`
                    );
                }
            }
        } else if (type == inputTypes.multipleInput) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(
                    valueOneState,
                    valueSecondState,
                    title
                );
                isCustomValid = customValidationRes.isValid;
            }
            if (
                inputOneRef?.current?.validate() &&
                inputTwoRef?.current?.validate() &&
                isCustomValid
            ) {
                let outPut = {
                    valueOne: dropDownJson?.lowercaseValues
                        ? valueOneState.toLowerCase()
                        : valueOneState,
                    valueSecond: dropDownJson?.lowercaseValues
                        ? valueSecondState.toLowerCase()
                        : valueSecondState,
                };
                if (findIndex(mainState, outPut) === -1) {
                    if (inputJson.overlapValidation) {
                        let overlapDetected = false;
                        for (const range of mainState) {
                            if (isAgeRangeOverlap(range, outPut)) {
                                overlapDetected = true;
                                break;
                            }
                        }

                        if (overlapDetected) {
                            sendErrorToast(
                                `${outPut.valueOne}-${outPut.valueSecond} overlaps with existing age range.`
                            );
                            return false;
                        }
                    }
                    setMainState(
                        dropDownJson?.showNewValueFirst
                            ? [outPut, ...mainState]
                            : [...mainState, outPut]
                    );
                    inputOneRef?.current?.focus();
                    setValueSecondState("");
                    setValueOneState("");
                    setShowError(false);
                } else {
                    sendErrorToast(
                        `${placeholderOne}: ${valueOneState}, ${placeholderTwo}: ${valueSecondState} already exist`
                    );
                }
            }
        } else if (type == inputTypes.inputDropdown) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(
                    valueOneState,
                    valueSecondState
                );
                isCustomValid = customValidationRes.isValid;
            }
            if (
                inputOneRef?.current?.validate() &&
                valueSecondState.length > 0 &&
                isCustomValid
            ) {
                let outPut = {
                    valueOne: valueOneState,
                    valueSecond: valueSecondState,
                };
                if (findIndex(mainState, outPut) === -1) {
                    setMainState([...mainState, outPut]);
                    setValueOneState("");
                    // setValueSecondState("");
                    setShowError(false);
                } else {
                    sendErrorToast(
                        `${
                            title || ""
                        } ${valueOneState}: ${valueSecondState} already exist`
                    );
                }
            } else {
                setShowError(valueSecondState.length == 0);
            }
        } else if (type == inputTypes.inputDropdownReverse) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(
                    valueSecondState,
                    valueOneState
                );
                isCustomValid = customValidationRes.isValid;
            }
            if (
                inputOneRef?.current?.validate() > 0 &&
                valueSecondState.length > 0 &&
                isCustomValid
            ) {
                let outPut = {
                    valueOne: valueSecondState,
                    valueSecond: valueOneState,
                };
                if (findIndex(mainState, outPut) === -1) {
                    setMainState([...mainState, outPut]);
                    setValueOneState("");
                    setValueSecondState("");
                    setShowError(false);
                } else {
                    sendErrorToast(
                        `${
                            title || ""
                        } ${valueSecondState}: ${valueOneState} already exist`
                    );
                }
            } else {
                setShowError(valueSecondState.length == 0);
            }
        } else if (type == inputTypes.ZipCodeType) {
            const checkIfNumber = n => {
                return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
            };
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(valueOneState);
                isCustomValid = customValidationRes.isValid;
            }
            if (
                zipCodeRef.current.validate() &&
                checkIfNumber(valueOneState) &&
                isCustomValid
            ) {
                if (mainState.indexOf(parseInt(valueOneState)) === -1) {
                    setMainState([...mainState, valueOneState]);
                    setValueOneState("");
                    setShowError(false);
                } else
                    sendErrorToast(
                        `${title || ""} ${valueOneState} already exist`
                    );
            } else {
                sendErrorToast(`Invalid Zip Code`);
                setShowError(true);
            }
        } else if (
            (!inputJson.showIncludeExclude &&
                type === inputTypes.multipleDropdown) ||
            type === inputTypes.multipleSelect
        ) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(
                    valueOneState,
                    valueSecondState
                );
                isCustomValid = customValidationRes.isValid;
            }
            if (
                !isEmpty(valueOneState) &&
                valueOneState?.id?.toString().length > 0 &&
                !isEmpty(valueSecondState) &&
                valueSecondState?.id?.toString().length > 0 &&
                isCustomValid
            ) {
                let outPut = {
                    valueOne: valueOneState?.id,
                    valueSecond: valueSecondState?.id,
                    valueName: valueOneState?.name,
                };

                // if (inputJson.showIncludeExclude) {
                //     let isIncluded = {
                //         isIncluded: !toggleIsExcluded,
                //     };
                //     outPut = {
                //         valueOne: valueOneState?.name,
                //         valueSecond: valueSecondState?.name,
                //         name:
                //             valueOneState?.name + ":" + valueSecondState?.name,
                //         publisherId: Math.random(),
                //     };
                //     outPut = Object.assign(outPut, isIncluded);
                //     setValueOneState(null);
                // }

                if (findIndex(mainState, outPut) === -1) {
                    if (typeof setSavedData === "function") {
                        setSavedData(outPut);
                    }
                    addedData(outPut);
                    if (type === inputTypes.multipleSelect) {
                        if (outPut.valueSecond === "skip") {
                            const newState = mainState?.filter(
                                (obj, idx) => obj.valueOne !== outPut.valueOne
                            );

                            setMainState([...newState, outPut]);
                        } else {
                            const newState = mainState
                                ?.filter(
                                    (obj, idx) =>
                                        obj.valueOne === outPut.valueOne
                                )
                                .find(
                                    (item, idx) =>
                                        item.valueSecond === "Any Answer"
                                );

                            if (!newState) setMainState([...mainState, outPut]);
                            else setMainState([...mainState]);
                        }
                    } else {
                        setMainState([...mainState, outPut]);
                    }

                    setShowError(false);
                    setSecondDropdownError(false);
                    // setValueOneState(null); //Commenting this as this is resetting the value. Can remove this once properly tested
                    setValueSecondState(null);
                } else {
                    sendErrorToast(
                        `${title || ""} ${valueOneState?.id}: ${
                            valueSecondState?.id
                        } already exist`
                    );
                }
            } else {
                setShowError(!valueOneState || valueOneState?.id.length === 0);
                setSecondDropdownError(
                    !valueSecondState || valueSecondState?.id.length === 0
                );
            }
        } else if (
            inputJson.showIncludeExclude &&
            type === inputTypes.multipleDropdown
        ) {
            if (
                !isEmpty(valueOneState) &&
                valueOneState?.id?.toString().length > 0
            ) {
                let outPut = {
                    valueOne: valueOneState?.id,
                    valueName: valueOneState?.name,
                };

                // let isIncluded = {
                //     isIncluded: !toggleIsExcluded,
                // };
                outPut = {
                    // valueOne: valueOneState?.name,
                    name: valueOneState?.name,
                    publisherId: valueOneState?.id,
                    subPublisherId: valueSecondState.id,
                    // subSubPublisherId: valueThirdState.id,
                    isIncluded: !toggleIsExcluded,
                };
                // outPut = Object.assign(outPut, isIncluded);
                setValueOneState(null);
                if (findIndex(mainState, outPut) === -1) {
                    if (typeof setSavedData === "function") {
                        setSavedData(outPut);
                    }
                    addedData(outPut);
                    setMainState([...mainState, outPut]);

                    setShowError(false);
                    const filterAndRemove = ele => {
                        let filteredObjects = optionsOneUpdatedList.filter(
                            obj => !ele.includes(obj.name)
                        );
                        return filteredObjects;
                    };

                    let newArray = filterAndRemove([valueOneState.name]);
                    setOptionsOneUpdatedList(newArray);
                } else {
                    sendErrorToast(
                        `${title || ""} ${valueOneState?.id} already exist`
                    );
                }
            } else {
                setShowError(!valueOneState || valueOneState?.id.length === 0);
            }
        } else if (type === inputTypes.singleDropdownCreateTags) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(valueOneState);
                isCustomValid = customValidationRes.isValid;
            }
            if (isCustomValid) {
                if (mainState.indexOf(valueOneState) === -1) {
                    setMainState([...mainState, valueOneState]);
                    setValueOneState("");
                } else {
                    sendErrorToast(
                        `${title || ""} ${valueOneState} already exist`
                    );
                }
            }
        } else if (type == inputTypes.ClientIPsType) {
            if (dynamicInputAndCsvUploaderType) {
                if (clientIPsRef.current.validate()) {
                    if (typeof customValidation === "function") {
                        const duplicateAvailable =
                            customValidation(valueOneState);

                        if (
                            mainState.indexOf(valueOneState) === -1 &&
                            !duplicateAvailable
                        ) {
                            setMainState([...mainState, valueOneState]);
                            setValueOneState("");
                            setShowError(false);
                        } else
                            sendErrorToast(
                                `${title || ""} ${valueOneState} already exist`
                            );
                    } else {
                        if (mainState.indexOf(valueOneState) === -1) {
                            setMainState([...mainState, valueOneState]);
                            setValueOneState("");
                            setShowError(false);
                        } else
                            sendErrorToast(
                                `${title || ""} ${valueOneState} already exist`
                            );
                    }
                } else {
                    sendErrorToast(`Invalid Keyword`);
                    setShowError(true);
                }
            } else {
                if (typeof customValidation === "function") {
                    customValidationRes = customValidation(valueOneState);
                    isCustomValid = customValidationRes.isValid;
                }
                if (
                    clientIPsRef.current.validate() &&
                    isValidIP(valueOneState) &&
                    isCustomValid
                ) {
                    if (mainState.indexOf(valueOneState) === -1) {
                        setMainState([...mainState, valueOneState]);
                        setValueOneState("");
                        setShowError(false);
                    } else
                        sendErrorToast(
                            `${title || ""} ${valueOneState} already exist`
                        );
                } else {
                    sendErrorToast(`Invalid IP Address`);
                    setShowError(true);
                }
            }
        } else if (type === inputTypes.multipleInputSortable) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(
                    valueOneState,
                    valueSecondState
                );
                isCustomValid = customValidationRes.isValid;
            }
            if (
                inputOneRef?.current?.validate() &&
                inputTwoRef?.current?.validate() &&
                isCustomValid
            ) {
                if (
                    !mainState
                        .map(_ => _.answer?.toLowerCase())
                        .includes(valueOneState.toLowerCase()) &&
                    !mainState
                        .map(_ => _.key?.toLowerCase())
                        .includes(valueSecondState.toLowerCase())
                ) {
                    setMainState([
                        ...mainState,
                        {
                            answer: valueOneState,
                            key: valueSecondState,
                            intent: toggleValue,
                        },
                    ]);
                    setValueOneState("");
                    setValueSecondState("");
                    setToggleValue(false);
                    setShowError(false);
                } else {
                    mainState
                        .map(_ => _.answer.toLowerCase())
                        .includes(valueOneState.toLowerCase())
                        ? sendErrorToast(
                              `Answer ${valueOneState} already exists.`
                          )
                        : sendErrorToast(
                              `Key ${valueSecondState} already exists.`
                          );
                }
            }
        } else if (type === inputTypes.rangeSlider) {
            if (
                JSON.stringify(mainState).includes(JSON.stringify(rangeValue))
            ) {
                sendErrorToast(`${rangeValue} range already exists.`);
            } else {
                setMainState([...mainState, rangeValue]);
            }
        }

        if (customValidationRes && customValidationRes.type === "toast") {
            if (customValidationRes.status === "error")
                sendErrorToast(customValidationRes.text);
            else {
                dispatch(
                    toastListActions.setToastList({
                        type: customValidationRes.status,
                        message: customValidationRes.text,
                    })
                );
            }
        }
    };

    const style = {
        color: "#CAD1D5",
    };

    const handleOptionClick = data => {
        // setLabelProduct(title);
        // setLabelCountry(title);
        setValueSecondState(get(data, "label", "id"));
        setShowError(false);
        if (
            dropDownOptionToParent &&
            typeof dropDownOptionToParent == "function"
        ) {
            dropDownOptionToParent(data);
        }
    };

    const handleFirstOptionClick = data => {
        let v1 = {};
        v1.name = get(data, "name", "");
        v1.id = get(data, "id", "");
        setValueOneState(v1);
        setShowError(false);
    };

    const handleFirstOptionClickAndUpdateSecond = data => {
        //Here on first option click change the channel list
        let v1 = {};
        v1.name = get(data, "name", "");
        v1.id = get(data, "id", "");

        //Fetch the abtMap for the config selected and updated the channel list
        let initialChannels = get(data, "abtMap", []);
        const channelPresent = initialChannels
            ?.map(channel => channel.channel)
            .flat();
        let channelInitialCopy = updatedSecondDropdownOptions;
        let updatedChannel = channelInitialCopy?.filter(el => {
            return !channelPresent?.includes(el.id);
        });
        setOptionsTwoUpdatedList(updatedChannel);
        setValueOneState(v1);
        setShowError(false);

        // // let copyData = updatedSecondDropdownOptions;
        // let newPublisherData = updatedSecondDropdownOptions?.filter(
        //     _ => _.name != v1.name
        // );

        // console.log(newPublisherData, "updatedSecondPublisher");
    };

    const handleSecondOptionClick = data => {
        let v2 = {};
        v2.name = get(data, "name", "");
        v2.id = get(data, "id", "");
        setValueSecondState(v2);
        setSecondDropdownError(false);
    };

    // const handleThirdOptionClick = data => {
    //     let v2 = {};
    //     v2.name = get(data, "name", "");
    //     v2.id = get(data, "id", "");
    //     setValueThirdState(v2);
    //     setValueThirdStateError(false);
    // };

    const clearUploadedFile = () => {
        setCsvFileName("");
        setCsvFile("");
        let valueCopyNew = [];

        if (
            mainState[0] !== null &&
            !Array.isArray(mainState[0]) &&
            !["string", "number"].includes(typeof mainState[0])
        ) {
            mainState.map(_ => {
                if (!csvArray.some(c => c.rowId === _.rowId)) {
                    valueCopyNew.push(_);
                }
            });
        } else {
            mainState.map((element, index) => {
                if (csvArray.indexOf(element) == -1) {
                    valueCopyNew.push(element);
                }
            });
        }
        setMainState(valueCopyNew);
        setCsvArray([]);
    };

    const utmParamDropdownHandler = async value => {
        let isCustomValid = true;
        let customValidationRes = {};
        setShowDropdown(false);
        if (type === inputTypes.singleDropdownCreateTags) {
            if (typeof customValidation === "function") {
                customValidationRes = customValidation(value);
                isCustomValid = customValidationRes.isValid;
            }
            if (isCustomValid) {
                if (mainState.indexOf(value) === -1) {
                    setMainState([...mainState, value]);
                    setValueOneState("");
                }
            }
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setMainState(arrayMove(mainState, oldIndex, newIndex));
    };

    const onChangetoggle = val => {
        setToggleValue(val);
    };

    const updateIntent = (val, item) => {
        let updatedItems = cloneDeep(mainState);
        updatedItems.find(
            ele => ele?.answer === item?.answer && ele?.key === item?.key
        ).intent = val ? 1 : -1;
        setMainState(updatedItems);
    };

    const SortableItem = SortableElement(({ items, id }) => (
        <div className={`${styles.draggableItemCard} dp-parent`}>
            <div>
                <div className={`${styles.newAnswer}`}>
                    <div className={styles.answerContainer}>
                        <div className={styles.key}>Answer : </div>
                        <div
                            className={styles.answerValue}
                            title={items?.answer + " - " + items?.key}
                        >
                            {items?.answer + " - " + items?.key}
                        </div>
                    </div>

                    <div className={`dp-parent ${styles["edit-options"]}`}>
                        {/* Commented this as currently backend does not support sorting in questions. And this case is specific to flow questions */}
                        {/* {!readOnly && (
                            <div className={styles.dragWrapper}>
                                <DragHandle></DragHandle>
                            </div>
                        )} */}

                        {!readOnly && (
                            <div
                                className={`${styles.option} circleIconColor circleIconColor--delete`}
                                onClick={() => {
                                    items?.id
                                        ? setDeletePopup(items)
                                        : removeValue(id, items);
                                }}
                            >
                                <Tooltip message={"Delete"}>
                                    <Icon
                                        icon="Delete"
                                        size="16px"
                                        enableHover={true}
                                    />
                                </Tooltip>
                            </div>
                        )}
                    </div>

                    {deletePopup === items && (
                        <div className={styles.deletePopup}>
                            <PopupTargettingParams
                                setName={id}
                                Rfunction={removeValue}
                                setShowPopup={setDeletePopup}
                                customText={`Do you wish to delete this answer?`}
                            />
                        </div>
                    )}
                    <div className={styles.conversionDetail}>
                        <div
                            className={`${styles.additionalDetails} ${styles.customEvent} ${styles.conversionWrapper}`}
                        >
                            <div className={styles.key}>Conversion :</div>
                            <div
                                className={`${styles.value} dp-parent flex-wrap`}
                            >
                                {readOnly ? (
                                    <p>
                                        {items?.intent > 0
                                            ? "Enabled"
                                            : "Disabled"}
                                    </p>
                                ) : (
                                    <ToggleSwitch
                                        isToggleOn={
                                            items?.intent <= 0 ? false : true
                                        }
                                        setIsToggleOn={val => {
                                            updateIntent(val, items);
                                        }}
                                        disabled={readOnly}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ));

    const SortableList = SortableContainer(({ items }) => (
        <div className="dp-parent flex-wrap gap-2030">
            {items?.map((item, index) => (
                <SortableItem
                    items={item}
                    id={index}
                    index={index}
                    key={`item-${getRandomId()}-${index}-${getRandomId()}-${index}`}
                />
            ))}
        </div>
    ));
    const DragHandle = sortableHandle(() => (
        <div
            className={`circleIconColor ${styles.option} ${styles.dragDisabled}`}
        >
            {<Icon icon="Drag" size="16px" />}
        </div>
    ));

    const [rangeValue, setRangeValue] = useState([0, 1]);

    const [toggleIsExcluded, setToggleIsExcluded] = useState(false);

    return (
        <>
            <div
                className={`${styles.inputListWrapper} ${
                    tagWrappable ? styles.tagWrappable : ""
                }`}
            >
                {type == "singleInput" && (
                    <>
                        <div
                            className={`${
                                inputJson.showIncludeExclude
                                    ? styles.singleInputToggle
                                    : ""
                            } ${styles.typeOne}`}
                        >
                            {!readOnly && (
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        valueSubmit(e);
                                    }}
                                >
                                    {inputJson.showIncludeExclude && (
                                        <ToggleValueSwitch
                                            classNameWrapper={
                                                styles.toggleWidth
                                            }
                                            onChange={responseType => {
                                                setToggleIsExcluded(
                                                    responseType
                                                );
                                            }}
                                            leftLabel={"Include"}
                                            rightLabel={"Exclude"}
                                            toggleState={toggleIsExcluded}
                                            isDisabled={readOnly}
                                        />
                                    )}
                                    <Input
                                        outerBoxClass={styles.inputWidth}
                                        value={valueOneState}
                                        onChange={e => {
                                            setValueOneState(e.target.value);
                                            setShowError(false);
                                        }}
                                        placeholder={placeholderOne}
                                        required={true}
                                        type={get(inputJson, "type", "text")}
                                        ref={inputOneRef}
                                        maxLength={
                                            inputJson.maxCharacter || null
                                        }
                                        minLength={
                                            inputJson.minCharacter || null
                                        }
                                        disabled={readOnly}
                                    />

                                    <Button
                                        type="submit"
                                        className={styles.button}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={readOnly}
                                    >
                                        Add
                                    </Button>
                                </form>
                            )}

                            {!readOnly && singleInputBulkUpload && (
                                <>
                                    <div className={styles.divideLineWrapper}>
                                        <div
                                            className={styles.divideLine}
                                        ></div>
                                        <div
                                            className={styles.divideLineContent}
                                        >
                                            OR
                                        </div>
                                    </div>
                                    <div
                                        className={`${
                                            styles.browseCsvWrapper
                                        } ${
                                            error
                                                ? styles.browseCsvWrapper_error
                                                : ""
                                        }`}
                                    >
                                        {csvArray.length && csvFileName ? (
                                            <div className={styles.fileName}>
                                                <p
                                                    className="ellipsis"
                                                    title={csvFileName}
                                                >
                                                    {csvFileName}
                                                </p>
                                                <div
                                                    className={
                                                        styles.removeFileBtn
                                                    }
                                                    onClick={clearUploadedFile}
                                                >
                                                    Remove
                                                </div>
                                            </div>
                                        ) : error ? (
                                            <div
                                                className={
                                                    styles.zipCodeErrorState
                                                }
                                            >
                                                <Icon
                                                    icon="Warning"
                                                    size="14px"
                                                />
                                                Something went wrong. Please
                                                upload a valid CSV file.
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    styles.uploadIconWrapper
                                                }
                                            >
                                                <Icon
                                                    icon="Upload"
                                                    size="20px"
                                                    style={style}
                                                />{" "}
                                                Upload Values in CSV
                                            </div>
                                        )}
                                        <CsvReader
                                            type={type}
                                            csvFileName={csvFileName}
                                            setCsvFileName={setCsvFileName}
                                            csvFile={csvFile}
                                            setCsvFile={setCsvFile}
                                            csvArray={csvArray}
                                            setCsvArray={setCsvArray}
                                            error={error}
                                            setError={setError}
                                            onDropActiveZipCode={
                                                onDropActiveZipCode
                                            }
                                            setonDropActiveZipCode={
                                                setonDropActiveZipCode
                                            }
                                            mainState={mainState}
                                            setMainState={setMainState}
                                            readOnly={readOnly}
                                            showUploadMsg={false}
                                        />
                                    </div>
                                </>
                            )}

                            {mainState &&
                            mainState.length > 0 &&
                            !inputJson.showIncludeExclude ? (
                                <ul>
                                    {mainState.map((element, index) => {
                                        return (
                                            <li key={index}>
                                                <Tooltip
                                                    message={element || null}
                                                    position="top"
                                                    wrap
                                                >
                                                    <span>{element}</span>
                                                </Tooltip>
                                                {!readOnly && (
                                                    <div
                                                        onClick={() =>
                                                            removeValue(
                                                                index,
                                                                element
                                                            )
                                                        }
                                                        className={`${styles.icon} ${styles.crossIcon}`}
                                                    >
                                                        <Tooltip
                                                            message={"Delete"}
                                                        >
                                                            <Icon
                                                                icon="CloseFilled"
                                                                size="14px"
                                                                enableHover={
                                                                    true
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                ""
                            )}
                        </div>
                        {mainState &&
                            mainState.length > 0 &&
                            inputJson.showIncludeExclude && (
                                <div
                                    className={`${styles.boxWrapper} dp-parent`}
                                >
                                    <div
                                        className={`${styles.boxItem} dp-child-50`}
                                    >
                                        <CompareList
                                            title={"Included"}
                                            showIcon={false}
                                            listData={mainState.filter(
                                                _ => _.isIncluded
                                            )}
                                            selector={"name"}
                                            onListChange={data => {
                                                setMainState([
                                                    ...data,
                                                    ...mainState.filter(
                                                        _ => !_.isIncluded
                                                    ),
                                                ]);
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={`${styles.boxItem} dp-child-50`}
                                    >
                                        <CompareList
                                            title={"Excluded"}
                                            showIcon={false}
                                            listData={mainState.filter(
                                                _ => !_.isIncluded
                                            )}
                                            selector={"name"}
                                            onListChange={data =>
                                                setMainState([
                                                    ...data,
                                                    ...mainState.filter(
                                                        _ => _.isIncluded
                                                    ),
                                                ])
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                    </>
                )}
                {(type == "multipleInput" ||
                    type == "multipleInputSortable") && (
                    <div className={styles.typeTwo}>
                        {!readOnly && (
                            <form
                                className={`${
                                    showToggle ? styles.inputContainer : ""
                                }`}
                                onSubmit={e => {
                                    e.preventDefault();
                                    valueSubmit(e);
                                }}
                            >
                                <div
                                    className={`${
                                        showToggle
                                            ? styles.inputMultiContainer
                                            : styles.inputWithoutToggle
                                    }`}
                                >
                                    <div
                                        className={`${styles.inputOne} ${
                                            showToggle ? styles.inputMulti : ""
                                        }`}
                                    >
                                        <Input
                                            className={
                                                valueOneStateError
                                                    ? styles.borderRed
                                                    : ""
                                            }
                                            value={valueOneState}
                                            onChange={e => {
                                                setValueOneState(
                                                    e.target.value
                                                );
                                                setShowError(false);
                                                setValueOneStateError(false);
                                            }}
                                            maxLength={
                                                inputJson?.maxCharacter || null
                                            }
                                            placeholder={placeholderOne}
                                            required={true}
                                            type={get(
                                                inputJson,
                                                "type",
                                                "text"
                                            )}
                                            ref={inputOneRef}
                                            disabled={readOnly}
                                        />
                                    </div>
                                    <div
                                        className={`${styles.inputSecond} ${
                                            showToggle ? styles.inputMulti : ""
                                        }`}
                                    >
                                        <Input
                                            className={
                                                valueSecondStateError
                                                    ? styles.borderRed
                                                    : ""
                                            }
                                            value={valueSecondState}
                                            onChange={e => {
                                                setValueSecondState(
                                                    e.target.value
                                                );
                                                setShowError(false);
                                                setValueSecondStateError(false);
                                            }}
                                            maxLength={
                                                inputJson?.maxCharacter || null
                                            }
                                            minLength={
                                                inputJson?.minCharacter || null
                                            }
                                            placeholder={placeholderTwo}
                                            required={true}
                                            type={get(
                                                inputJson,
                                                "type",
                                                "text"
                                            )}
                                            ref={inputTwoRef}
                                            disabled={readOnly}
                                        />
                                    </div>
                                    {!showToggle && (
                                        <Button
                                            type="submit"
                                            className={styles.button}
                                            btnTheme={
                                                ButtonTypes.primaryHover_btn
                                            }
                                            disable={
                                                readOnly ||
                                                (disabledValidationBtn
                                                    ? true
                                                    : false)
                                            }
                                        >
                                            Add
                                        </Button>
                                    )}
                                </div>
                                {showToggle && (
                                    <div className={styles.btnWrapper}>
                                        <div className="dp-parent dp-parent-ver-center gap-10">
                                            <p className="headerTitleRequired mb-0">
                                                Conversion
                                            </p>
                                            <ToggleSwitch
                                                isToggleOn={toggleValue}
                                                setIsToggleOn={onChangetoggle}
                                                disabled={readOnly}
                                            />
                                        </div>

                                        <Button
                                            type="submit"
                                            className={styles.button}
                                            btnTheme={
                                                ButtonTypes.primaryHover_btn
                                            }
                                            disable={
                                                readOnly ||
                                                (disabledValidationBtn
                                                    ? true
                                                    : false)
                                            }
                                        >
                                            Add
                                        </Button>
                                    </div>
                                )}
                            </form>
                        )}

                        {!hideSelectedPills &&
                        mainState &&
                        mainState.length > 0 ? (
                            type === "multipleInputSortable" ? (
                                <div className="mt-10">
                                    <SortableList
                                        axis="xy"
                                        distance={1}
                                        items={mainState}
                                        onSortEnd={onSortEnd}
                                        useDragHandle
                                    />
                                </div>
                            ) : (
                                !hideSelectedPills && (
                                    <ul>
                                        {mainState.map((element, index) => {
                                            return (
                                                <li key={index}>
                                                    <div
                                                        className={
                                                            styles.elementOne
                                                        }
                                                    >
                                                        <span>
                                                            {placeholderOne} :{" "}
                                                        </span>
                                                        <span>
                                                            {element.valueOne}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.elementSecond
                                                        }
                                                    >
                                                        <span>
                                                            {placeholderTwo} :{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                element.valueSecond
                                                            }
                                                        </span>
                                                    </div>
                                                    {!readOnly && (
                                                        <div
                                                            onClick={() =>
                                                                removeValue(
                                                                    index,
                                                                    element
                                                                )
                                                            }
                                                            className={`${styles.icon} ${styles.crossIcon}`}
                                                        >
                                                            <Icon
                                                                icon="CloseFilled"
                                                                size="14px"
                                                            />
                                                        </div>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )
                            )
                        ) : (
                            ""
                        )}
                    </div>
                )}
                {type == "inputDropdown" && (
                    <>
                        <div className={styles.typeThree}>
                            {!readOnly && (
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        valueSubmit(e);
                                    }}
                                >
                                    <div className={styles.inputOne}>
                                        <Input
                                            value={valueOneState}
                                            onChange={e => {
                                                setValueOneState(
                                                    e.target.value
                                                );
                                                setShowError(false);
                                            }}
                                            placeholder={placeholderOne}
                                            required={true}
                                            ref={inputOneRef}
                                            type={get(
                                                inputJson,
                                                "type",
                                                "text"
                                            )}
                                            maxLength={
                                                inputJson.maxCharacter || null
                                            }
                                            minLength={
                                                inputJson.minCharacter || null
                                            }
                                            disabled={readOnly}
                                        />
                                    </div>
                                    <div className={styles.inputSecond}>
                                        <Select
                                            className={get(
                                                dropDownJson,
                                                "className",
                                                ""
                                            )}
                                            label={get(
                                                dropDownJson,
                                                "label",
                                                ""
                                            )}
                                            options={get(
                                                dropDownJson,
                                                "options",
                                                []
                                            )}
                                            keyField={get(
                                                dropDownJson,
                                                "keyField",
                                                "text"
                                            )}
                                            onCreate={handleOptionClick}
                                            onSelect={handleOptionClick}
                                            idField={get(
                                                dropDownJson,
                                                "idField",
                                                "id"
                                            )}
                                            onLoadSelectReturn={false}
                                            selectedId={
                                                get(
                                                    dropDownJson,
                                                    "selectedID",
                                                    ""
                                                ) == ""
                                                    ? valueSecondState
                                                    : get(
                                                          dropDownJson,
                                                          "selectedID",
                                                          ""
                                                      )
                                            }
                                            position={get(
                                                dropDownJson,
                                                "position",
                                                "bottom"
                                            )}
                                            isError={showError}
                                            isDisabled={readOnly}
                                        />
                                    </div>
                                    <Button
                                        type="submit"
                                        className={styles.button}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={readOnly}
                                    >
                                        Add
                                    </Button>
                                </form>
                            )}

                            {mainState && mainState.length > 0 ? (
                                <ul>
                                    {mainState.map((element, index) => {
                                        return (
                                            <li key={index}>
                                                <div
                                                    className={
                                                        styles.elementOne
                                                    }
                                                >
                                                    <span>
                                                        {element.valueOne} :{" "}
                                                    </span>
                                                    <span>
                                                        {element.valueSecond}
                                                    </span>
                                                </div>
                                                {!readOnly && (
                                                    <div
                                                        onClick={() =>
                                                            removeValue(
                                                                index,
                                                                element
                                                            )
                                                        }
                                                        className={`${styles.icon} ${styles.crossIcon}`}
                                                    >
                                                        <Icon
                                                            icon="CloseFilled"
                                                            size="14px"
                                                        />
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                ""
                            )}
                        </div>
                    </>
                )}
                {type == "inputDropdownReverse" && (
                    <>
                        <div className={styles.typeFour}>
                            {!readOnly && (
                                <form
                                    onSubmit={e => {
                                        valueSubmit(e);
                                    }}
                                >
                                    <div className={styles.inputOne}>
                                        <Select
                                            className={get(
                                                dropDownJson,
                                                "className",
                                                ""
                                            )}
                                            label={get(
                                                dropDownJson,
                                                "label",
                                                ""
                                            )}
                                            options={get(
                                                dropDownJson,
                                                "options",
                                                []
                                            )}
                                            keyField={get(
                                                dropDownJson,
                                                "keyField",
                                                "text"
                                            )}
                                            onSelect={handleOptionClick}
                                            idField={get(
                                                dropDownJson,
                                                "idField",
                                                "id"
                                            )}
                                            onLoadSelectReturn={false}
                                            selectedId={
                                                get(
                                                    dropDownJson,
                                                    "selectedID",
                                                    ""
                                                ) == ""
                                                    ? valueSecondState
                                                    : get(
                                                          dropDownJson,
                                                          "selectedID",
                                                          ""
                                                      )
                                            }
                                            position={get(
                                                dropDownJson,
                                                "position",
                                                "bottom"
                                            )}
                                            isError={showError}
                                            isDisabled={readOnly}
                                        />
                                    </div>
                                    <div className={styles.inputSecond}>
                                        <Input
                                            value={valueOneState}
                                            onChange={e => {
                                                setValueOneState(
                                                    e.target.value
                                                );
                                                setShowError(false);
                                            }}
                                            placeholder={placeholderOne}
                                            ref={inputOneRef}
                                            type={get(
                                                inputJson,
                                                "type",
                                                "text"
                                            )}
                                            required
                                            maxLength={
                                                inputJson.maxCharacter || null
                                            }
                                            minLength={
                                                inputJson.minCharacter || null
                                            }
                                            disabled={readOnly}
                                        />
                                        {get(inputJson, "type", "text") ==
                                            "color" && (
                                            <input
                                                className={styles.colorInput}
                                                type={"color"}
                                                value={valueOneState}
                                                onChange={e => {
                                                    setValueOneState(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                    <Button
                                        type="submit"
                                        className={styles.button}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={readOnly}
                                    >
                                        Add
                                    </Button>
                                </form>
                            )}

                            {mainState && mainState.length > 0 ? (
                                <ul>
                                    {mainState.map((element, index) => {
                                        return (
                                            <li key={index}>
                                                <div
                                                    className={
                                                        styles.elementOne
                                                    }
                                                >
                                                    <span>
                                                        {element.valueOne} :{" "}
                                                    </span>
                                                    <span>
                                                        {element.valueSecond}
                                                    </span>
                                                </div>
                                                {!readOnly && (
                                                    <div
                                                        onClick={() =>
                                                            removeValue(
                                                                index,
                                                                element
                                                            )
                                                        }
                                                        className={`${styles.icon} ${styles.crossIcon}`}
                                                    >
                                                        <Icon
                                                            icon="CloseFilled"
                                                            size="14px"
                                                        />
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : (
                                ""
                            )}
                        </div>
                    </>
                )}
                {type == "ZipCodeType" && (
                    <>
                        {!readOnly && (
                            <>
                                <form onSubmit={e => valueSubmit(e)}>
                                    <Input
                                        ref={zipCodeRef}
                                        required={true}
                                        value={valueOneState}
                                        onChange={e =>
                                            setValueOneState(e.target.value)
                                        }
                                        placeholder={placeholderOne}
                                        type={get(inputJson, "type", "text")}
                                        onfocus={() => setShowError(false)}
                                        maxLength={get(
                                            inputJson,
                                            "maxCharacter",
                                            null
                                        )}
                                        minLength={get(
                                            inputJson,
                                            "minCharacter",
                                            null
                                        )}
                                        disabled={readOnly}
                                    />
                                    <Button
                                        type="submit"
                                        className={styles.button}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={readOnly}
                                    >
                                        Add
                                    </Button>
                                </form>

                                <div className={styles.divideLineWrapper}>
                                    <div className={styles.divideLine}></div>
                                    <div className={styles.divideLineContent}>
                                        OR
                                    </div>
                                </div>
                                <div
                                    className={`${styles.browseCsvWrapper} ${
                                        error
                                            ? styles.browseCsvWrapper_error
                                            : ""
                                    }`}
                                >
                                    {csvArray.length && csvFileName ? (
                                        <div className={styles.fileName}>
                                            <p
                                                className="ellipsis"
                                                title={csvFileName}
                                            >
                                                {csvFileName}
                                            </p>
                                            <div
                                                className={styles.removeFileBtn}
                                                onClick={clearUploadedFile}
                                            >
                                                Remove
                                            </div>
                                        </div>
                                    ) : error ? (
                                        <div
                                            className={styles.zipCodeErrorState}
                                        >
                                            <Icon icon="Warning" size="14px" />
                                            Something went wrong. Please upload
                                            CSV as per sample format
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.uploadIconWrapper}
                                        >
                                            <Icon
                                                icon="Upload"
                                                size="20px"
                                                style={style}
                                            />{" "}
                                            Upload Zip Codes CSV
                                        </div>
                                    )}
                                    <CsvReader
                                        type={type}
                                        csvFileName={csvFileName}
                                        setCsvFileName={setCsvFileName}
                                        csvFile={csvFile}
                                        setCsvFile={setCsvFile}
                                        csvArray={csvArray}
                                        setCsvArray={setCsvArray}
                                        error={error}
                                        setError={setError}
                                        onDropActiveZipCode={
                                            onDropActiveZipCode
                                        }
                                        setonDropActiveZipCode={
                                            setonDropActiveZipCode
                                        }
                                        mainState={mainState}
                                        setMainState={setMainState}
                                        readOnly={readOnly}
                                    />
                                </div>
                                <div className={styles.sampleWrapper}>
                                    <div className={styles.text}>
                                        <Icon icon="Info" size="14px" />
                                        Bulk Upload Zip codes CSV as per the
                                        sample format
                                    </div>
                                    <CSVLink data={data}>
                                        <Button
                                            btnTheme={ButtonTypes.themBtn}
                                            disable={false}
                                        >
                                            <Icon icon="Download" size="14px" />
                                            Download Template
                                        </Button>
                                    </CSVLink>
                                </div>
                            </>
                        )}

                        {mainState && mainState.length > 0 ? (
                            <ul className={styles.zipcodeUlTag}>
                                {mainState.map((element, index) => {
                                    return (
                                        <li key={index}>
                                            <span>{element}</span>
                                            {!readOnly && (
                                                <div
                                                    onClick={() =>
                                                        removeValue(
                                                            index,
                                                            element
                                                        )
                                                    }
                                                    className={`${styles.icon} ${styles.crossIcon}`}
                                                >
                                                    <Icon
                                                        icon="CloseFilled"
                                                        size="14px"
                                                    />
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            ""
                        )}
                    </>
                )}
                {type === "ClientIPsType" && (
                    <>
                        {!readOnly && (
                            <>
                                <form onSubmit={e => valueSubmit(e)}>
                                    <Input
                                        ref={clientIPsRef}
                                        required={true}
                                        value={valueOneState}
                                        onChange={e =>
                                            setValueOneState(e.target.value)
                                        }
                                        placeholder={placeholderOne}
                                        type={get(inputJson, "type", "text")}
                                        onfocus={() => setShowError(false)}
                                        maxLength={
                                            inputJson.maxCharacter || null
                                        }
                                        minLength={
                                            inputJson.minCharacter || null
                                        }
                                        disabled={readOnly}
                                    />
                                    <Button
                                        type="submit"
                                        className={styles.button}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={readOnly}
                                    >
                                        Add
                                    </Button>
                                </form>

                                <div className={styles.divideLineWrapper}>
                                    <div className={styles.divideLine}></div>
                                    <div className={styles.divideLineContent}>
                                        OR
                                    </div>
                                </div>
                                <div
                                    className={`${styles.browseCsvWrapper} ${
                                        error
                                            ? styles.browseCsvWrapper_error
                                            : ""
                                    }`}
                                >
                                    {csvArray.length && csvFileName ? (
                                        <div className={styles.fileName}>
                                            <p
                                                className="ellipsis"
                                                title={csvFileName}
                                            >
                                                {csvFileName}
                                            </p>
                                            <div
                                                className={styles.removeFileBtn}
                                                onClick={clearUploadedFile}
                                            >
                                                Remove
                                            </div>
                                        </div>
                                    ) : error ? (
                                        <div
                                            className={styles.zipCodeErrorState}
                                        >
                                            <Icon icon="Warning" size="14px" />
                                            Something went wrong. Please upload
                                            CSV as per sample format
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.uploadIconWrapper}
                                        >
                                            <Icon
                                                icon="Upload"
                                                size="20px"
                                                style={style}
                                            />
                                            {dynamicInputAndCsvUploaderType
                                                ? `Upload ${uploadMsg} Csv`
                                                : "Upload Client IPs CSV"}
                                        </div>
                                    )}
                                    <CsvReader
                                        type={type}
                                        csvFileName={csvFileName}
                                        setCsvFileName={setCsvFileName}
                                        csvFile={csvFile}
                                        setCsvFile={setCsvFile}
                                        csvArray={csvArray}
                                        setCsvArray={setCsvArray}
                                        error={error}
                                        setError={setError}
                                        onDropActiveZipCode={
                                            onDropActiveZipCode
                                        }
                                        setonDropActiveZipCode={
                                            setonDropActiveZipCode
                                        }
                                        mainState={mainState}
                                        setMainState={setMainState}
                                        readOnly={readOnly}
                                        dynamicInputAndCsvUploaderType={
                                            dynamicInputAndCsvUploaderType
                                        }
                                    />
                                </div>
                                <div
                                    className={`${styles.sampleWrapper} ${
                                        mainStateDataHide
                                            ? styles.mainStateDataHide
                                            : ""
                                    }`}
                                >
                                    <div className={styles.text}>
                                        <Icon icon="Info" size="14px" />
                                        {`Bulk Upload ${uploadMsg} CSV as per the
                                        sample format`}
                                    </div>
                                    <CSVLink
                                        data={
                                            dynamicInputAndCsvUploaderType
                                                ? noramlTextData
                                                : clientIPsData
                                        }
                                    >
                                        <Button
                                            btnTheme={ButtonTypes.themBtn}
                                            disable={false}
                                        >
                                            <Icon icon="Download" size="14px" />
                                            Download Template
                                        </Button>
                                    </CSVLink>
                                </div>
                            </>
                        )}

                        {mainState &&
                        mainState.length > 0 &&
                        !mainStateDataHide ? (
                            <ul className={styles.zipcodeUlTag}>
                                {mainState.map((element, index) => {
                                    return (
                                        <li key={index}>
                                            <span>{element}</span>
                                            {!readOnly && (
                                                <div
                                                    onClick={() =>
                                                        removeValue(
                                                            index,
                                                            element
                                                        )
                                                    }
                                                    className={`${styles.icon} ${styles.crossIcon}`}
                                                >
                                                    <Icon
                                                        icon="CloseFilled"
                                                        size="14px"
                                                    />
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            ""
                        )}
                    </>
                )}
                {type === "multipleDropdown" && (
                    <>
                        <div
                            className={`${
                                inputJson.showIncludeExclude
                                    ? styles.keyValueHandler
                                    : ""
                            } row`}
                        >
                            {!readOnly && (
                                <form onSubmit={e => valueSubmit(e)}>
                                    {inputJson.showIncludeExclude && (
                                        <ToggleValueSwitch
                                            classNameWrapper={`${styles.keyValueToggle}`}
                                            onChange={responseType => {
                                                setToggleIsExcluded(
                                                    responseType
                                                );
                                            }}
                                            leftLabel={"Include"}
                                            rightLabel={"Exclude"}
                                            toggleState={toggleIsExcluded}
                                            isDisabled={readOnly}
                                        ></ToggleValueSwitch>
                                    )}
                                    <div
                                        className={`${styles.inputOne} ${get(
                                            dropDownJson,
                                            "className",
                                            ""
                                        )} ${
                                            labelOne
                                                ? "dp-parent dp-parent-col gap-10"
                                                : ""
                                        }`}
                                    >
                                        {labelOne && <p>{labelOne}</p>}
                                        <Select
                                            label={get(
                                                dropDownJson,
                                                "label",
                                                ""
                                            )}
                                            onSelect={
                                                get(
                                                    dropDownJson,
                                                    "onSelectFirstUpdateSecond",
                                                    false
                                                )
                                                    ? handleFirstOptionClickAndUpdateSecond
                                                    : handleFirstOptionClick
                                            }
                                            keyField={"name"}
                                            options={optionsOneUpdatedList}
                                            idField={"id"}
                                            selectedId={
                                                get(
                                                    valueOneState,
                                                    "id",
                                                    null
                                                ) === ""
                                                    ? null
                                                    : get(
                                                          valueOneState,
                                                          "id",
                                                          null
                                                      )
                                            }
                                            isError={showError}
                                            onLoadSelectReturn={true}
                                            position={get(
                                                dropDownJson,
                                                "position",
                                                "bottom"
                                            )}
                                            isDisabled={readOnly}
                                        ></Select>
                                    </div>
                                    <div
                                        className={`${styles.inputSecond} ${get(
                                            dropDownJson,
                                            "className",
                                            ""
                                        )} ${
                                            labelTwo
                                                ? "dp-parent dp-parent-col gap-10"
                                                : ""
                                        }`}
                                    >
                                        {labelTwo && <p>{labelTwo}</p>}
                                        <Select
                                            label={get(
                                                dropDownJsonSecond,
                                                "label",
                                                ""
                                            )}
                                            onSelect={handleSecondOptionClick}
                                            keyField={"name"}
                                            options={optionsTwoUpdatedList}
                                            idField={"id"}
                                            selectedId={
                                                get(
                                                    valueSecondState,
                                                    "id",
                                                    null
                                                ) === ""
                                                    ? null
                                                    : get(
                                                          valueSecondState,
                                                          "id",
                                                          null
                                                      )
                                            }
                                            isError={secondDropdownError}
                                            onLoadSelectReturn={true}
                                            position={get(
                                                dropDownJsonSecond,
                                                "position",
                                                "bottom"
                                            )}
                                            // isDisabled={
                                            //     inputJson.showIncludeExclude ||
                                            //     readOnly ||
                                            //     (!get(
                                            //         valueOneState,
                                            //         "id",
                                            //         null
                                            //     ) &&
                                            //         !get(
                                            //             valueOneState,
                                            //             "name",
                                            //             null
                                            //         ))
                                            // }
                                        ></Select>
                                    </div>
                                    {/* {inputJson.showThirdSelect && (
                                        <div
                                            className={`${
                                                styles.inputSecond
                                            } ${get(
                                                dropDownJson,
                                                "className",
                                                ""
                                            )} ${
                                                inputJson.labelThree
                                                    ? "dp-parent dp-parent-col gap-10"
                                                    : ""
                                            }`}
                                        >
                                            {inputJson.labelThree && (
                                                <p>{inputJson.labelThree}</p>
                                            )}
                                            <Select
                                                label={get(
                                                    dropDownJsonSecond,
                                                    "label",
                                                    ""
                                                )}
                                                onSelect={
                                                    handleThirdOptionClick
                                                }
                                                keyField={"name"}
                                                options={optionsTwoUpdatedList}
                                                idField={"id"}
                                                selectedId={
                                                    get(
                                                        valueThirdState,
                                                        "id",
                                                        null
                                                    ) === ""
                                                        ? null
                                                        : get(
                                                              valueThirdState,
                                                              "id",
                                                              null
                                                          )
                                                }
                                                isError={valueThirdStateError}
                                                onLoadSelectReturn={true}
                                                position={get(
                                                    dropDownJsonSecond,
                                                    "position",
                                                    "bottom"
                                                )}
                                                // isDisabled={
                                                //     inputJson.showIncludeExclude ||
                                                //     readOnly ||
                                                //     (!get(
                                                //         valueOneState,
                                                //         "id",
                                                //         null
                                                //     ) &&
                                                //         !get(
                                                //             valueOneState,
                                                //             "name",
                                                //             null
                                                //         ))
                                                // }
                                            ></Select>
                                        </div>
                                    )} */}
                                    <Button
                                        type="submit"
                                        className={styles.button}
                                        btnTheme={ButtonTypes.primaryHover_btn}
                                        disable={readOnly}
                                    >
                                        Add
                                    </Button>
                                </form>
                            )}

                            {!inputJson.showIncludeExclude &&
                            mainState &&
                            mainState.length > 0 &&
                            !showCount ? (
                                showTags ? (
                                    // <ul className={styles.tagsUL}>
                                    <ul>
                                        {mainState.map((element, index) => {
                                            return (
                                                <li key={index}>
                                                    <div
                                                        className={
                                                            styles.elementOne
                                                        }
                                                    >
                                                        <span>
                                                            {element.valueOne} :{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                element.valueSecond
                                                            }
                                                        </span>
                                                    </div>
                                                    {!readOnly && (
                                                        <div
                                                            onClick={() =>
                                                                deleteRow(
                                                                    element
                                                                )
                                                            }
                                                            className={`${styles.icon} ${styles.crossIcon}`}
                                                        >
                                                            <Icon
                                                                icon="CloseFilled"
                                                                size="14px"
                                                            />
                                                        </div>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <ChannelMapTable
                                        data={mainState}
                                        onDeleteItem={val =>
                                            onDeleteItemClick(val)
                                        }
                                        showPopup={showPopup}
                                        setShowPopup={val => {
                                            setShowPopup(val);
                                        }}
                                        onDeleteConfirmation={() => {
                                            deleteRow(valueToDelete);
                                        }}
                                        valueToDelete={valueToDelete}
                                        readOnly={readOnly}
                                    />
                                )
                            ) : (
                                ""
                            )}
                            {!inputJson.showIncludeExclude &&
                            !isEmpty(mainState) &&
                            showCount &&
                            !showTags ? (
                                <>
                                    <p
                                        className={styles.tableCount}
                                        onClick={toggleShowCount}
                                    >
                                        View {mainState.length} Mapped{" "}
                                        {mainState.length === 1
                                            ? `item`
                                            : `items`}
                                    </p>
                                </>
                            ) : (
                                ""
                            )}
                        </div>

                        {mainState &&
                            mainState.length > 0 &&
                            inputJson.showIncludeExclude && (
                                <div
                                    className={`${styles.boxWrapper} dp-parent`}
                                >
                                    <div
                                        className={`${styles.boxItem} dp-child-50`}
                                    >
                                        <CompareList
                                            title={"Included"}
                                            showIcon={false}
                                            listData={mainState.filter(
                                                _ => _.isIncluded
                                            )}
                                            selector={"name"}
                                            multipleSelectOptions={true}
                                            onListChange={data =>
                                                setMainState([
                                                    ...data,
                                                    ...mainState.filter(
                                                        _ => !_.isIncluded
                                                    ),
                                                ])
                                            }
                                        />
                                    </div>
                                    <div
                                        className={`${styles.boxItem} dp-child-50`}
                                    >
                                        <CompareList
                                            title={"Excluded"}
                                            showIcon={false}
                                            listData={mainState.filter(
                                                _ => !_.isIncluded
                                            )}
                                            selector={"name"}
                                            multipleSelectOptions={true}
                                            onListChange={data =>
                                                setMainState([
                                                    ...data,
                                                    ...mainState.filter(
                                                        _ => _.isIncluded
                                                    ),
                                                ])
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                    </>
                )}
                {type === "multipleSelect" && (
                    <>
                        <div className={"row"}>
                            {!readOnly && (
                                <form onSubmit={e => valueSubmit(e)}>
                                    <div className="w-100">
                                        <div className="w-100">
                                            <div
                                                className={`dp-parent dp-parent-ver-center gap-20 ${get(
                                                    dropDownJson,
                                                    "className",
                                                    ""
                                                )}`}
                                            >
                                                <Select
                                                    {...dropDownJson}
                                                    onSelect={e => {
                                                        dropDownJson.onSelect(
                                                            e
                                                        );
                                                        handleFirstOptionClick(
                                                            e
                                                        );
                                                    }}
                                                    onLoadSelectReturn={true}
                                                    isDisabled={readOnly}
                                                ></Select>
                                                {multiSelectCheckbox && (
                                                    <Checkbox
                                                        {...multiSelectCheckboxJson}
                                                        onChange={data => {
                                                            multiSelectCheckboxJson.onChange(
                                                                data
                                                            );
                                                            if (data) {
                                                                handleSecondOptionClick(
                                                                    {
                                                                        name: "skip",
                                                                        id: "skip",
                                                                    }
                                                                );
                                                            } else {
                                                                setValueSecondState(
                                                                    ""
                                                                );
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="dp-parent mt-10 w-100 mb-10">
                                            <div
                                                className={`flex-auto ${get(
                                                    dropDownJsonSecond,
                                                    "className",
                                                    ""
                                                )}`}
                                            >
                                                <Select
                                                    {...dropDownJsonSecond}
                                                    onCreate={e => {
                                                        if (e) {
                                                            dropDownJsonSecond.onSelect(
                                                                e
                                                            );
                                                            handleSecondOptionClick(
                                                                {
                                                                    name: e.answer,
                                                                    id: e.answer,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    onSelect={e => {
                                                        if (e) {
                                                            dropDownJsonSecond.onSelect(
                                                                e
                                                            );
                                                            handleSecondOptionClick(
                                                                {
                                                                    name: e[
                                                                        dropDownJsonSecond
                                                                            .keyField
                                                                    ],
                                                                    id: e[
                                                                        dropDownJsonSecond
                                                                            .idField
                                                                    ],
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    onLoadSelectReturn={true}
                                                ></Select>
                                            </div>
                                            <Button
                                                type="submit"
                                                className={styles.button}
                                                btnTheme={
                                                    ButtonTypes.primaryHover_btn
                                                }
                                                disable={readOnly}
                                            >
                                                Add
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}

                            {mainState && mainState.length > 0 && !showCount ? (
                                showTags ? (
                                    // <ul className={styles.tagsUL}>
                                    <ul>
                                        {mainState.map((element, index) => {
                                            const valueOne =
                                                dropDownJson?.options?.find(
                                                    _ =>
                                                        _[
                                                            dropDownJson.idField
                                                        ] === element.valueOne
                                                ) || element.valueOne;

                                            const valueSecond =
                                                (
                                                    valueOne.answers ||
                                                    dropDownJsonSecond?.options
                                                )?.find(
                                                    _ =>
                                                        _[
                                                            dropDownJsonSecond
                                                                .idField
                                                        ] ===
                                                        element.valueSecond
                                                ) || element.valueSecond;
                                            return (
                                                <li key={index}>
                                                    <div
                                                        className={
                                                            styles.elementOne
                                                        }
                                                    >
                                                        <span>
                                                            {valueOne[
                                                                dropDownJson
                                                                    .keyField
                                                            ] || valueOne}{" "}
                                                            :{" "}
                                                        </span>
                                                        <span>
                                                            {valueSecond[
                                                                dropDownJsonSecond
                                                                    .keyField
                                                            ] || valueSecond}
                                                        </span>
                                                    </div>
                                                    {!readOnly && (
                                                        <div
                                                            onClick={() =>
                                                                deleteRow(
                                                                    element
                                                                )
                                                            }
                                                            className={`${styles.icon} ${styles.crossIcon}`}
                                                        >
                                                            <Icon
                                                                icon="CloseFilled"
                                                                size="14px"
                                                            />
                                                        </div>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <ChannelMapTable
                                        data={mainState}
                                        onDeleteItem={val =>
                                            onDeleteItemClick(val)
                                        }
                                        showPopup={showPopup}
                                        setShowPopup={val => {
                                            setShowPopup(val);
                                        }}
                                        onDeleteConfirmation={() => {
                                            deleteRow(valueToDelete);
                                        }}
                                        valueToDelete={valueToDelete}
                                        readOnly={readOnly}
                                    />
                                )
                            ) : (
                                ""
                            )}
                            {!isEmpty(mainState) && showCount && !showTags ? (
                                <>
                                    <p
                                        className={styles.tableCount}
                                        onClick={toggleShowCount}
                                    >
                                        View {mainState.length} Mapped{" "}
                                        {mainState.length === 1
                                            ? `item`
                                            : `items`}
                                    </p>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </>
                )}
                {type == "singleDropdownCreateTags" && (
                    <>
                        <div className={styles.singleDropdownCreateTagsWrapper}>
                            {!readOnly && (
                                <div className={styles.utmSourcesWrapper}>
                                    {showDropdown && (
                                        <div className={styles.dropDownWrapper}>
                                            <Select
                                                className={get(
                                                    dropDownJson,
                                                    "className",
                                                    ""
                                                )}
                                                label={get(
                                                    dropDownJson,
                                                    "label",
                                                    ""
                                                )}
                                                placeholder={get(
                                                    dropDownJson,
                                                    "label",
                                                    ""
                                                )}
                                                options={get(
                                                    dropDownJson,
                                                    "options",
                                                    []
                                                ).filter(
                                                    _ =>
                                                        !mainState.includes(
                                                            _.id
                                                        )
                                                )}
                                                keyField={get(
                                                    dropDownJson,
                                                    "keyField",
                                                    "text"
                                                )}
                                                onSelect={item => {
                                                    utmParamDropdownHandler(
                                                        item.id.toString()
                                                    );
                                                }}
                                                idField={get(
                                                    dropDownJson,
                                                    "idField",
                                                    "id"
                                                )}
                                                onLoadSelectReturn={false}
                                                showSearch={true}
                                                showCreate={get(
                                                    dropDownJson,
                                                    "showCreate",
                                                    true
                                                )}
                                                onCreate={item => {
                                                    utmParamDropdownHandler(
                                                        item.label.toString()
                                                    );
                                                }}
                                                position={get(
                                                    dropDownJson,
                                                    "position",
                                                    "bottom"
                                                )}
                                                isSelectOpen={true}
                                                onSelectClose={val => {
                                                    setShowDropdown(
                                                        !showDropdown
                                                    );
                                                }}
                                                portal={false}
                                                hideLabel={true}
                                                hideOutline={true}
                                                CustomRender={item => {
                                                    const option = item.option;
                                                    const logo = get(
                                                        option,
                                                        `logo`,
                                                        ""
                                                    );
                                                    const customIcon =
                                                        isDarkMode
                                                            ? get(
                                                                  option,
                                                                  `whiteIcon`,
                                                                  get(
                                                                      option,
                                                                      `icon`,
                                                                      ""
                                                                  )
                                                              )
                                                            : get(
                                                                  option,
                                                                  `icon`,
                                                                  ""
                                                              );
                                                    return (
                                                        <>
                                                            {customIcon &&
                                                            logo ? (
                                                                <LogoComponent
                                                                    name={
                                                                        customIcon
                                                                    }
                                                                    size={18}
                                                                    className="mr-5"
                                                                />
                                                            ) : customIcon ? (
                                                                <Icon
                                                                    icon={
                                                                        customIcon
                                                                    }
                                                                    size="15px"
                                                                    className="mr-5"
                                                                />
                                                            ) : null}
                                                            {
                                                                option[
                                                                    get(
                                                                        dropDownJson,
                                                                        "keyField",
                                                                        "text"
                                                                    )
                                                                ]
                                                            }
                                                        </>
                                                    );
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <ul className="gap-10">
                                {!readOnly && (
                                    <div
                                        className={`${styles.plusIconWrapper} ${
                                            readOnly ? styles.disabled : ""
                                        }`}
                                        onClick={
                                            !readOnly
                                                ? () =>
                                                      setShowDropdown(
                                                          !showDropdown
                                                      )
                                                : null
                                        }
                                    >
                                        <Icon
                                            icon="Plus"
                                            size="14px"
                                            color={
                                                !readOnly
                                                    ? "#41C3A9"
                                                    : "#cad1d4"
                                            } // Will have to add a class for this
                                            disable={readOnly}
                                        />
                                    </div>
                                )}
                                {mainState?.length > 0 &&
                                    mainState.map((item, index) => {
                                        let getValue =
                                            dropDownJson.options.find(
                                                element =>
                                                    element[
                                                        dropDownJson.idField
                                                    ] === item
                                            ) ||
                                            REGISTER_DETAIL_DROPDOWN.find(
                                                element => element.id === item
                                            ) ||
                                            UTMSOURCESDROPDOWNOPTIONS.find(
                                                element => element.id === item
                                            ) ||
                                            USERSTATUSDROPDOWNOPTIONS.find(
                                                element => element.id === item
                                            ) ||
                                            BROWSERSLIST.find(
                                                element => element.id === item
                                            ) ||
                                            COUNTRIESLIST.find(
                                                element => element.id === item
                                            ) ||
                                            OPERATINGSYSTEMLIST.find(
                                                element => element.id === item
                                            ) ||
                                            DEVICESLIST.find(
                                                element => element.id === item
                                            );

                                        const logo = get(getValue, `logo`, "");
                                        const customIcon = isDarkMode
                                            ? get(
                                                  getValue,
                                                  `whiteIcon`,
                                                  get(getValue, `icon`, "")
                                              )
                                            : get(getValue, `icon`, "");

                                        return (
                                            <li key={index}>
                                                {getValue ? (
                                                    <>
                                                        {customIcon && logo ? (
                                                            <LogoComponent
                                                                name={
                                                                    customIcon
                                                                }
                                                                size={18}
                                                                className="mr-5"
                                                            />
                                                        ) : customIcon ? (
                                                            <Icon
                                                                icon={
                                                                    customIcon
                                                                }
                                                                size="15px"
                                                                className="mr-5"
                                                            />
                                                        ) : null}
                                                        <span>
                                                            {
                                                                getValue[
                                                                    dropDownJson
                                                                        .keyField
                                                                ]
                                                            }
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <span>{item}</span>
                                                    </>
                                                )}
                                                {!readOnly && (
                                                    <div
                                                        onClick={() =>
                                                            removeValue(
                                                                index,
                                                                item
                                                            )
                                                        }
                                                        className={`${styles.icon} ${styles.crossIcon}`}
                                                    >
                                                        <Icon
                                                            icon="CloseFilled"
                                                            size="14px"
                                                            enableHover={true}
                                                        />
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </>
                )}

                {type === "rangeSlider" && (
                    <div className={`dp-parent dp-parent-col gap-20`}>
                        {!readOnly && (
                            <form onSubmit={e => valueSubmit(e)}>
                                <Slider
                                    getAriaLabel={() => title}
                                    value={rangeValue}
                                    onChange={(event, newValue) => {
                                        setRangeValue(newValue);
                                    }}
                                    valueLabelDisplay="auto"
                                    disabled={readOnly}
                                />
                                <Button
                                    type="submit"
                                    className={styles.button}
                                    btnTheme={ButtonTypes.primaryHover_btn}
                                    disable={readOnly}
                                >
                                    Add
                                </Button>
                            </form>
                        )}
                        {mainState && mainState.length > 0 ? (
                            <ul>
                                {mainState.map((element, index) => {
                                    return (
                                        <li key={index}>
                                            <div className={styles.elementOne}>
                                                <span>{placeholderOne} : </span>
                                                <span>{element[0]}</span>
                                            </div>
                                            <div
                                                className={styles.elementSecond}
                                            >
                                                <span>{placeholderTwo} : </span>
                                                <span>{element[1]}</span>
                                            </div>
                                            {!readOnly && (
                                                <div
                                                    onClick={() =>
                                                        removeValue(
                                                            index,
                                                            element
                                                        )
                                                    }
                                                    className={`${styles.icon} ${styles.crossIcon}`}
                                                >
                                                    <Icon
                                                        icon="CloseFilled"
                                                        size="14px"
                                                    />
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            ""
                        )}
                    </div>
                )}

                {type === "singleDropdown" && (
                    <div className={styles.typeOne}>
                        {!readOnly && !dropDownJson?.hideSelectBox && (
                            <Select
                                {...dropDownJson}
                                onSelect={val => {
                                    const value = [
                                        ...new Set(
                                            [
                                                ...(dropDownJson?.singleInputBulkUpload
                                                    ? mainState.filter(
                                                          _ =>
                                                              !dropDownJson.options?.some(
                                                                  item =>
                                                                      item[
                                                                          dropDownJson
                                                                              ?.idField
                                                                      ] === _
                                                              )
                                                      )
                                                    : []),
                                                ...(dropDownJson?.multiSelect
                                                    ? [
                                                          val.map(
                                                              _ =>
                                                                  _[
                                                                      dropDownJson
                                                                          ?.idField
                                                                  ]
                                                          ),
                                                      ]
                                                    : [
                                                          val[
                                                              dropDownJson
                                                                  ?.idField
                                                          ],
                                                      ]),
                                            ].flat()
                                        ),
                                    ];

                                    if (characterLimitForUpload === null) {
                                        setMainState(value);
                                    } else if (
                                        value.join(",").replaceAll(",", "")
                                            ?.length <= characterLimitForUpload
                                    ) {
                                        setCharacterLimitExceedDisable(false);
                                        setMainState(value);
                                    } else {
                                        setCharacterLimitExceedDisable(true);
                                        dispatch(
                                            toastListActions.setToastList({
                                                type: "Error",
                                                message:
                                                    "Selected value exceeds character limit.",
                                            })
                                        );

                                        return "Error";
                                    }
                                }}
                                onLoadSelectReturn={false}
                                isDisabled={readOnly}
                            ></Select>
                        )}

                        {!readOnly && dropDownJson?.singleInputBulkUpload && (
                            <>
                                <div className={styles.divideLineWrapper}>
                                    <div className={styles.divideLine}></div>
                                    <div className={styles.divideLineContent}>
                                        OR
                                    </div>
                                </div>
                                <div
                                    className={`${styles.browseCsvWrapper} ${
                                        error
                                            ? styles.browseCsvWrapper_error
                                            : ""
                                    }`}
                                >
                                    {csvArray.length && csvFileName ? (
                                        <div className={styles.fileName}>
                                            <p
                                                className="ellipsis"
                                                title={csvFileName}
                                            >
                                                {csvFileName}
                                            </p>
                                            <div
                                                className={styles.removeFileBtn}
                                                onClick={clearUploadedFile}
                                            >
                                                Remove
                                            </div>
                                        </div>
                                    ) : error ? (
                                        <div
                                            className={styles.zipCodeErrorState}
                                        >
                                            <Icon icon="Warning" size="14px" />
                                            Something went wrong. Please upload
                                            a valid CSV file.
                                        </div>
                                    ) : (
                                        <div
                                            className={styles.uploadIconWrapper}
                                        >
                                            <Icon
                                                icon="Upload"
                                                size="20px"
                                                style={style}
                                            />{" "}
                                            Upload Values in CSV
                                        </div>
                                    )}
                                    <CsvReader
                                        type={dropDownJson?.customType || type}
                                        csvFileName={csvFileName}
                                        setCsvFileName={setCsvFileName}
                                        csvFile={csvFile}
                                        setCsvFile={setCsvFile}
                                        csvArray={csvArray}
                                        setCsvArray={setCsvArray}
                                        error={error}
                                        setError={setError}
                                        onDropActiveZipCode={
                                            onDropActiveZipCode
                                        }
                                        setonDropActiveZipCode={
                                            setonDropActiveZipCode
                                        }
                                        mainState={mainState}
                                        setMainState={setMainState}
                                        readOnly={readOnly}
                                        showUploadMsg={false}
                                        disable={
                                            dropDownJson?.disableComponent ||
                                            characterLimitExceedDisable ||
                                            false
                                        }
                                        characterLimit={characterLimitForUpload}
                                    />
                                </div>
                                {dropDownJson?.sampleCsv && (
                                    <div className={styles.sampleWrapper}>
                                        <div className={styles.text}>
                                            <Icon icon="Info" size="14px" />
                                            Bulk Upload CSV as per the sample
                                            format
                                        </div>
                                        <CSVLink data={dropDownJson.sampleCsv}>
                                            <Button
                                                btnTheme={ButtonTypes.themBtn}
                                                disable={false}
                                            >
                                                <Icon
                                                    icon="Download"
                                                    size="14px"
                                                />
                                                Download Template
                                            </Button>
                                        </CSVLink>
                                    </div>
                                )}
                            </>
                        )}

                        {mainState &&
                        mainState.length > 0 &&
                        !dropDownJson?.hideSelectBox ? (
                            <ul>
                                {mainState.map((element, index) => {
                                    return (
                                        <li key={index} className="mt-10">
                                            <Tooltip
                                                message={element || null}
                                                position="top"
                                                wrap
                                            >
                                                <span>{element}</span>
                                            </Tooltip>
                                            {!readOnly && (
                                                <div
                                                    onClick={() =>
                                                        removeValue(
                                                            index,
                                                            element
                                                        )
                                                    }
                                                    className={`${styles.icon} ${styles.crossIcon}`}
                                                >
                                                    <Tooltip message={"Delete"}>
                                                        <Icon
                                                            icon="CloseFilled"
                                                            size="14px"
                                                            enableHover={true}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            ""
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default InputList;
