import Tooltip from "../../../Shared/Tooltip/Tooltip";
import Icon from "../../../Shared/Icon/Icon";
import s from "./ActionButtons.module.scss";
import { BUTTON } from "../../../constants/buttonLabel";
import { useRef } from "react";

const ActionButtons = ({
    item,
    editCallback,
    cloneCallback,
    disableCloneBtn = false,
    historyCallback,
    showHistoryIcon,
    disableCallback,
    tooltipLabel = "",
    deleteCallback,
    createVariantCallback,
    viewVariantCallback,
    copyUrlCallback,
    copyCallback,
    reTriggerCallback,
    applyConversion,
    addCallback,
    viewIconCallback,
    previewCallback,
    viewChildTableText = "Show",
    hideChildTableText = "Hide",
    isDeleted = false,
    revertCallback,
    giftCardCallback,
    isKey = "id",
    downloadCallback,
    downloadLabel = "Download file",
    loading = false,
    readOnly = false,
    className,
    isFulfilled = false,
    customCallback,
    customData,
}) => {
    const deleteRef = useRef(null);
    return (
        <div className="btnWrap">
            <div className={`editIconWrapper ${className}`}>
                {item && previewCallback && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => previewCallback(item)}
                    >
                        <Tooltip message="Preview" position="top">
                            <Icon icon="View" size="18px" enableHover={true} />
                        </Tooltip>
                    </div>
                )}
                {!readOnly && item && isDeleted && revertCallback && (
                    <div
                        className={`editIcon revertIcon circleIconColor`}
                        onClick={() => {
                            revertCallback(item);
                        }}
                    >
                        <Tooltip
                            width="100px"
                            message="Revert delete"
                            position="top"
                        >
                            <Icon
                                icon="Revert"
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}
                {!readOnly &&
                    item &&
                    giftCardCallback &&
                    typeof giftCardCallback == "function" && (
                        <div
                            className={`editIcon revertIcon circleIconColor`}
                            onClick={() => {
                                giftCardCallback(item);
                            }}
                        >
                            {isFulfilled ? (
                                <Tooltip
                                    message="Revert Fulfillment"
                                    position="top"
                                >
                                    <Icon
                                        icon="Revert"
                                        size="18px"
                                        enableHover={true}
                                    />
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    message="Fulfill Gift Card"
                                    position="top"
                                >
                                    <Icon
                                        icon="GiftCard"
                                        size="18px"
                                        enableHover={true}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    )}

                {!readOnly && item && editCallback && !isDeleted && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => editCallback(item)}
                    >
                        <Tooltip message="Edit" position="top">
                            <Icon icon="Edit" size="18px" enableHover={true} />
                        </Tooltip>
                    </div>
                )}

                {!readOnly && item && cloneCallback && !isDeleted && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => cloneCallback(item)}
                    >
                        <Tooltip message="Clone" position="top">
                            <Icon
                                disable={disableCloneBtn}
                                icon="Clone"
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}

                {item && showHistoryIcon && historyCallback && !isDeleted && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => historyCallback(item)}
                    >
                        <Tooltip
                            message={BUTTON.HISTORY.tooltip}
                            position="top"
                        >
                            <Icon icon="Time" size="18px" enableHover={true} />
                        </Tooltip>
                    </div>
                )}

                {item && copyUrlCallback && !isDeleted && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => copyUrlCallback(item.url)}
                    >
                        <Tooltip message="Copy URL" position="top">
                            <Icon icon="URL" size="18px" enableHover={true} />
                        </Tooltip>
                    </div>
                )}

                {item &&
                    item.deliveryTypeDetails &&
                    copyCallback &&
                    !isDeleted && (
                        <div
                            className={`editIcon ${
                                "edit" + item[isKey]
                            } circleIconColor`}
                            onClick={copyCallback}
                        >
                            <Tooltip
                                message="Show delivery details"
                                position="top"
                            >
                                <Icon
                                    icon="Details"
                                    size="18px"
                                    enableHover={true}
                                    className="copyIcon"
                                />
                            </Tooltip>
                        </div>
                    )}

                {!readOnly && item && createVariantCallback && !isDeleted && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={createVariantCallback}
                    >
                        <Tooltip message="Create new variant" position="top">
                            <Icon icon="Add" size="18px" enableHover={true} />
                        </Tooltip>
                    </div>
                )}

                {!readOnly && item && disableCallback && !isDeleted && (
                    <div
                        className={`editIcon ${
                            "edit" + item[isKey]
                        } circleIconColor ${
                            item.status == "active"
                                ? "circleIconColor--disable"
                                : "circleIconColor--enable"
                        }`}
                        onClick={() => disableCallback(item, "Disable")}
                    >
                        <Tooltip
                            message={
                                (item.status == "active"
                                    ? "Disable"
                                    : "Enable") + ` ${tooltipLabel}`
                            }
                            position="top"
                        >
                            <Icon
                                icon={
                                    item.status == "active"
                                        ? "Disable"
                                        : "Check"
                                }
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}

                {!readOnly && item && deleteCallback && !isDeleted && (
                    <div
                        className={`editIcon ${
                            "edit" + item[isKey]
                        } circleIconColor circleIconColor--delete`}
                        onClick={() =>
                            deleteCallback(item, "Delete", deleteRef)
                        }
                        ref={deleteRef}
                    >
                        <Tooltip message="Delete" position="top">
                            <Icon
                                icon="Delete"
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}

                <div className="moreActionsBtn">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

                {item && viewVariantCallback && (
                    <div
                        className={`editIcon accordionArw circleIconColor`}
                        onClick={() => viewVariantCallback(item)}
                    >
                        <Tooltip
                            message={
                                item.showAccordionTable
                                    ? hideChildTableText
                                    : viewChildTableText
                            }
                            position="top"
                        >
                            <Icon
                                icon={
                                    item.showAccordionTable
                                        ? "AccordianDownFilled"
                                        : "AccordianDown"
                                }
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}

                {item && downloadCallback && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => {
                            !loading ? downloadCallback(item) : null;
                        }}
                    >
                        <Tooltip
                            message={
                                loading ? "Downloading file" : downloadLabel
                            }
                            position="top"
                        >
                            <div
                                className={` ${s.downloadIcon} ${
                                    loading ? "spinLoader" : ""
                                }`}
                            >
                                {loading ? (
                                    <div className={`flex ${s.icon}`}>
                                        <Icon
                                            icon="Loader"
                                            size="18px"
                                            enableHover={true}
                                        />
                                    </div>
                                ) : (
                                    <Icon
                                        icon="Download"
                                        size="18px"
                                        enableHover={true}
                                    />
                                )}
                            </div>
                        </Tooltip>
                    </div>
                )}
                {item && reTriggerCallback && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() =>
                            item?.validate ? "" : reTriggerCallback(item)
                        }
                    >
                        <Tooltip
                            message={
                                item?.validate
                                    ? "Re-Triggering in Process"
                                    : "Re-Trigger"
                            }
                            position="left"
                        >
                            <Icon
                                disable={item?.validate ? true : false}
                                icon="ReTrigger"
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}
                {item && applyConversion && (
                    <div
                        className={`editIcon circleIconColor ${
                            loading ? "spinLoader pointer-none" : ""
                        }`}
                        onClick={() =>
                            item?.validate ? "" : applyConversion(item)
                        }
                    >
                        <Tooltip
                            message={
                                item?.validate
                                    ? "Conversion in progress"
                                    : "Fire Conversion"
                            }
                            position="left"
                        >
                            <Icon
                                disable={item?.validate ? true : false}
                                icon="Convert"
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}
                {item && viewIconCallback && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => viewIconCallback(item)}
                    >
                        <Tooltip message="View user journey" position="top">
                            <Icon icon="View" size="18px" enableHover={true} />
                        </Tooltip>
                    </div>
                )}
                {item && addCallback && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => (readOnly ? "" : addCallback(item))}
                    >
                        <Tooltip message="Add Category" position="top">
                            <Icon
                                disable={readOnly}
                                icon="Add"
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}
                {item && customCallback && (
                    <div
                        className={`editIcon circleIconColor`}
                        onClick={() => (readOnly ? "" : customCallback(item))}
                    >
                        <Tooltip message={customData?.tooltip} position="top">
                            <Icon
                                disable={readOnly}
                                icon={customData?.icon}
                                size="18px"
                                enableHover={true}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ActionButtons;
