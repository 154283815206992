import s from "./NumberRenderer.module.scss";
import { roundOffNumber } from "../../../utils/globalUtils";

const NumberRenderer = ({
    item,
    selector,
    className,
    withComma = false,
    decimals = 0,
    prefix = "",
    postFix = "",
    intlFormat = false,
}) => {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });

    function numberWithCommas(num) {
        return roundOffNumber(num, decimals, withComma);
    }
    return (
        <div className={`numRender__common ${className ? className : ""}`}>
            {prefix}
            {intlFormat
                ? formatter.format(selector ? item[selector] : item)
                : numberWithCommas(selector ? item[selector] : item)}
            {postFix}
        </div>
    );
};

export default NumberRenderer;
