import React, { useState, useRef, useImperativeHandle, useEffect } from "react";
import { validateWebsiteUrl } from "../../utils/globalUtils";
import Icon from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import c from "./Input.module.scss";
const Input = React.forwardRef(
    (
        {
            id,
            className,
            maxWidth,
            type,
            placeholder,
            onClick,
            onChange,
            value = "",
            disabled,
            onKeyPress,
            onKeyUp,
            onKeyDown,
            required,
            custom,
            maxLength,
            minLength,
            isError = false,
            errorMsg = "",
            outerBoxClass,
            onBlur = true,
            customValidation = false,
            customIcon = false,
            customIconClass,
            onfocus,
            autoFocus = false,
            arrowHide = false,
            onblur,
            valueType = "",
            checkAvailabilityLoader = false,
            loader,
            increasedHeight = false,
            optionalFileInput = false,
            readOnly = false,
            disableTrim = false,
            visualSuffix = false,
            inputMaxValue = "",
            showInputValueLength = false,
        },
        ref
    ) => {
        const inputRef = useRef();
        const [isValid, setIsValid] = useState(true);
        const [inputValue, setInputValue] = useState(value);
        const inputClass = [
            className ? className : "",
            c.inputField,
            isValid ? "" : c.invalid,
            customIcon ? c.hasCustomIcon : "",
            type == "number" && !arrowHide
                ? valueType == "" || valueType !== "percentage"
                    ? c.numberPadding
                    : c.numberPaddingValueType
                : "",
            optionalFileInput ? c.numberPadding : "",
            checkAvailabilityLoader ? c.loaderPadding : "",
        ];

        useEffect(() => {
            if (!isValid) validateInput();
        }, [inputValue]);

        useEffect(() => {
            setIsValid(!isError);
        }, [isError]);

        useEffect(() => {
            if (typeof value != "undefined") setInputValue(value);
        }, [value]);

        const activate = () => {
            inputRef.current.focus();
        };

        const getValue = () => {
            if (typeof value == "string") {
                return inputRef.current.value.trim();
            }
            return inputValue;
        };

        const clearValue = () => {
            setInputValue("");
        };

        const onInputChange = (e, trimValue) => {
            if (type == "number") {
                if (/^-?\d*\.?\d*$/.test(inputRef.current.value)) {
                    setInputValue(inputRef.current.value);
                    if (typeof onChange === "function") onChange(e);
                }
            } else {
                setInputValue(inputRef.current.value);
                if (typeof onChange === "function")
                    onChange(
                        trimValue
                            ? {
                                  ...e,
                                  target: {
                                      ...e.target,
                                      value: e.target.value.trim(),
                                  },
                              }
                            : e
                    );
            }
        };

        const validateInput = value => {
            let min = minLength || 1;
            let max = maxLength || 10000000000;
            let isTempValid = true;
            if (typeof value === "string" && value) {
                value = value;
            } else {
                value = getValue();
            }
            if (
                required &&
                ((value && value.length) < min || (value && value.length) > max)
            )
                isTempValid = false;
            else if (typeof custom === "function" && !custom(value))
                isTempValid = false;

            if (type === "url" && !validateWebsiteUrl(value)) {
                isTempValid = false;
            }
            if (customValidation) {
                var format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;

                if (!format.test(value)) {
                    isTempValid = true;
                } else {
                    isTempValid = false;
                }
            }
            setIsValid(isTempValid);
            return isTempValid;
        };

        const removeValidateInput = () => {
            let isTempValid = false;
            setIsValid(true);
            return isTempValid;
        };

        useImperativeHandle(ref, () => {
            return {
                focus: activate,
                value: getValue,
                validate: validateInput,
                clear: clearValue,
                removeValidate: removeValidateInput,
            };
        });

        const incrementCounter = () => {
            let value = Number(inputValue) + 1;
            setInputValue(value.toString());
            if (typeof onChange === "function" && onChange)
                onChange({
                    target: {
                        value: value.toString(),
                    },
                });
        };

        let decrementCounter = () => {
            let value = inputValue <= 0 ? 0 : Number(inputValue) - 1;
            setInputValue(value.toString());
            if (typeof onChange === "function" && onChange)
                onChange({
                    target: {
                        value: value.toString(),
                    },
                });
        };

        return (
            <>
                <div
                    className={`${c[valueType]} ${c.inputBox} ${
                        maxWidth ? maxWidth : ""
                    } ${outerBoxClass ? outerBoxClass : ""} ${
                        showInputValueLength ? c.inputBoxResetOverflow : ""
                    } ${visualSuffix ? c.visualSuffix : ""} ${c.noSelect}`}
                >
                    {customIcon && (
                        <div className={`${c.icon} ${customIconClass || ""}`}>
                            <Icon icon={customIcon} size="15px" />
                        </div>
                    )}
                    {showInputValueLength && (
                        <div
                            className={c.inputCount}
                        >{`${value?.length}/${inputMaxValue}`}</div>
                    )}
                    <input
                        id={id}
                        className={inputClass.join(" ")}
                        value={inputValue}
                        type="text"
                        placeholder={placeholder}
                        onChange={onInputChange}
                        onClick={onClick}
                        disabled={disabled}
                        ref={inputRef}
                        onKeyPress={onKeyPress}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                        onBlur={
                            onBlur
                                ? e => {
                                      validateInput(e.target.value);
                                  }
                                : null
                        }
                        min={minLength}
                        onFocus={onfocus}
                        autoFocus={autoFocus}
                        onBlurCapture={e => {
                            onInputChange(e, !disableTrim);
                            if (typeof onblur === "function") {
                                onblur(e);
                            }
                        }}
                        readOnly={readOnly}
                    />
                    {optionalFileInput && (
                        <label className={c.uploadFile}>
                            <Tooltip message={"Upload Image"} position="top">
                                <Icon
                                    icon="Upload"
                                    enableHover={true}
                                    size="14px"
                                />
                                <input
                                    id="upload"
                                    type="file"
                                    className="partialHidden"
                                    accept="image/*"
                                    onChange={e => {
                                        if (e.target.value) {
                                            inputRef.current.value =
                                                e.target.value;
                                            onInputChange({
                                                ...e,
                                                target: {
                                                    ...e.target,
                                                    value: URL.createObjectURL(
                                                        e.target.files[0]
                                                    ),
                                                },
                                            });
                                        }
                                    }}
                                />
                            </Tooltip>
                        </label>
                    )}
                    {type == "number" && !disabled && !arrowHide && !readOnly && (
                        <>
                            <div
                                className={`${c.sortingArrowWrapper} ${
                                    increasedHeight ? c[increasedHeight] : ""
                                }`}
                            >
                                <div
                                    className={`${c.sortingArrow} ${c.sortingArrowUp}`}
                                    onClick={incrementCounter}
                                >
                                    <Icon icon="ColumnSort" size="14px" />
                                </div>
                                <div
                                    className={`${c.sortingArrow} ${c.sortingArrowDown}`}
                                    onClick={decrementCounter}
                                >
                                    <Icon icon="ColumnSort" size="14px" />
                                </div>
                            </div>
                        </>
                    )}
                    {type == "text" && checkAvailabilityLoader ? (
                        <>
                            {loader && loader !== "" && (
                                <div className={c.checkTextWrapper}>
                                    Checking Availability
                                    <span>
                                        <Icon icon="Loader" size="12px" />
                                    </span>
                                </div>
                            )}
                            {!loader && loader !== "" && (
                                <div className={c.checkFilledImg}>
                                    <Icon icon="CheckFilled" size="16px" />
                                </div>
                            )}
                        </>
                    ) : (
                        ""
                    )}

                    {visualSuffix && (
                        <span className={c.suffixText}>{visualSuffix}</span>
                    )}
                </div>
                {errorMsg !== ""
                    ? !isValid && (
                          <div className={`textError ${c.textErrorStyling}`}>
                              <Icon
                                  icon={"ErrorFilled"}
                                  size="14px"
                                  enableHover={true}
                              />
                              {errorMsg}
                          </div>
                      )
                    : ""}
            </>
        );
    }
);

export default Input;
