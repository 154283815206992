import { isEmpty, cloneDeep } from "lodash";
import { utcISO8601Format } from "./dateUtils";
import { isValidIdPublisher } from "./globalUtils";

export const mapAdvScrubDashboard = async (data, advList = []) => {
    return data?.map(scrubData => ({
        sourceId: scrubData.AdvertiserID,
        campaignId: scrubData.CampaignID,
        createdAt: scrubData.CreatedAt,
        scrubRatio: scrubData.scrubRate,
        date: scrubData.ScrubDate,
        sourceName: advList.find(
            element => element.id === scrubData.AdvertiserID
        )?.name,
    }));
};

export const mapScrubDashboardData = async (
    data,
    sourceList = [],
    type = "advertiser"
) => {
    return data?.map(scrubData => ({
        sourceId: scrubData?.data?.source,
        campaignId: scrubData?.data?.campaign,
        updatedAt: scrubData?.updatedAt,
        updatedBy: scrubData?.updatedBy?.name,
        scrubRatio: scrubData?.data?.scrub_ratio,
        date: scrubData?.data?.scrub_date,
        start_date: scrubData?.data?.scrub_start_date,
        end_date: scrubData?.data?.scrub_end_date,
        sourceName: scrubData?.data?.srcnm,
        campaignName: scrubData?.data?.cmpnm,
        discount: scrubData?.data?.discount,
        srcnumber: scrubData?.data?.srcnumber,
        updatedSrcNumber: scrubData?.data?.srcnumber,
        updatedScrubRatio: scrubData?.data?.scrub_ratio,
        updatedRPC: scrubData?.data?.after_scrub_rpc,
        dsnumber: scrubData?.data?.dsnumber,
        scrub_type: scrubData?.data?.scrub_type,
        scrub_type_name: scrubTypes.find(
            _ => _.id === scrubData?.data?.scrub_type
        )?.name,
    }));
};

//Will remove this flags logic once covered all the cases
// {
//     "matches": 1, Transparent
//     "input_and_fetched_ds_number_mismatch": 1, Yellow (Warning)
//     "both_zero": 0, Orange (Required)
//     "rpc_exceed_limit": 0, Red
//     "either_one_ds_number_is_zero": 0 Red
// }

// Red = either_one_ds_number_is_zero  + rpc_exceed_limit

// cases:
// 0. matching : No Flag
// 1. numbers dont match (both non zero, input ds rev will be honoured) : Yellow Flag
// 2. dummy event need to be fired (both zero) : Orange Flags Required
// 3. rpc > 5 : Red Flag
// 4. one is zero and other is non zero : Red Flag

// Red Flag : Not allowed to proceed, need to update the input ds rev or contact developer
// yellow flag: can proceed as is
// orange: need to fire dummy events before moving forward

//Old flags code remove once new changes are stable and working as expected
// export const getRowType = flag => {
//     switch (flag) {
//         case 4:
//             return {
//                 type: "errorRow",
//                 message: "One is zero and other non zero",
//                 // message: "One is zero and other is non zero",
//                 icon: "Error",
//             };
//             break;
//         case 3:
//             return { type: "errorRow", message: "rpc > 5", icon: "Error" };
//             break;
//         case 2:
//             return {
//                 type: "requiredRow",
//                 message: "Dummy event need to be fired",
//                 // message: "Dummy event need to be fired (both zero)",
//                 icon: "Warning",
//             };
//             break;
//         case 1:
//             return {
//                 type: "warningRow",
//                 message: "DS numbers dont match",
//                 // message:
//                 //     "numbers dont match (both non zero, input ds rev will be honoured)",
//                 icon: "Warning",
//             };
//             break;
//         case 0:
//             return { type: "", message: "", icon: "" };
//             break;
//         default:
//             return { type: "", message: "", icon: "" };
//     }
// };

// 1: fire dummy events Orange
// 2: rpc > 5 Red

export const getRowType = (flag, type, limit = 5) => {
    switch (flag) {
        case 1:
            return {
                type: "requiredRow",
                message: "Dummy event needs to be fired",
                // message: "Dummy event need to be fired (both zero)",
                icon: "Warning",
            };
            break;
        case 2:
            return {
                type: "errorRow",
                message: `${type === "advertiser" ? "rpc" : "cpc"} > ${limit}`,
                icon: "Error",
            };
            break;
        case 3:
            return {
                type: "errorRow",
                message: "Total Clicks 0",
                icon: "Error",
            };
            break;
        default:
            return { type: "", message: "", icon: "" };
    }
};

export const mapCountData = data => {
    const {
        either_one_ds_number_is_zero,
        rpc_exceed_limit,
        input_and_fetched_ds_number_mismatch,
        both_zero,
    } = data;
    let finalData = {
        error: either_one_ds_number_is_zero + rpc_exceed_limit,
        warning: both_zero,
        required: input_and_fetched_ds_number_mismatch,
    };
    return finalData;
};

export const getAdminFlag = (val, readOnly = true, showError) => {
    if (!readOnly && !isEmpty(val) && (val[0] === 2 || val[0] === 3))
        return [1];
    else return val;
};

export const getIndexCount = data => {
    const obj = data.reduce((val, cur) => {
        val[cur.index] = val[cur.index] ? val[cur.index] + 1 : 1;
        return val;
    }, {});

    const res = Object.keys(obj).map(key => ({
        index: +key,
        count: obj[key],
    }));
    return res;
};

export const getIsExpanded = (data, index) => {
    let count = data?.filter(_ => {
        _?.index === index;
    });
    count = !isEmpty(data) ? data[0]?.count : 1;
    return count > 1 ? true : false;
};

export const mapBulkScrubDashboardData = async (
    data,
    sourceList = [],
    campaignList = [],
    type = "advertiser",
    readOnly = true
) => {
    const mappedIndexData = getIndexCount(data);
    return data?.map((scrubData, index) => {
        const flagValue = getAdminFlag(
            !isEmpty(scrubData?.flags) ? scrubData?.flags : [],
            readOnly,
            scrubData?.after_scrub_rpc === -1 && scrubData?.total_clicks === 0
        );
        let updatedRow = !isEmpty(scrubData?.flags)
            ? getRowType(flagValue[0], type, +scrubData?.rpc_limit)
            : {};
        const updatedValue = {
            id: `${index}`,
            index: scrubData.index,
            type: type === "advertiser" ? "Advertiser" : "Publisher",
            srcid: scrubData?.srcid,
            cmpid: scrubData?.cmpid,
            // -9223372036854776000  is converted to -1 because GO does not have null so added a check else backend returns this value if null
            scrub_ratio:
                scrubData?.scrub_ratio === -1 ? 0 : scrubData?.scrub_ratio,
            scrub_date: scrubData?.scrub_date,
            srcnm: sourceList?.find(element =>
                type === "advertiser"
                    ? element.id === +scrubData?.srcid
                    : element.id === scrubData?.srcid
            )?.name,
            cmpnm: campaignList?.find(element =>
                type === "advertiser"
                    ? element.id === +scrubData?.cmpid
                    : element.cmpid === scrubData?.cmpid &&
                      element.pubid === scrubData.srcid
            )?.name,
            discount_factor: scrubData?.discount_factor,
            dsnumber: scrubData?.dsnumber,
            initialSrcnumber: scrubData?.srcnumber,
            srcnumber: scrubData?.srcnumber,
            updatedSrcNumber: scrubData?.srcnumber,
            updatedScrubRatio: scrubData?.scrub_ratio,
            updatedRPC: scrubData?.after_scrub_rpc,
            initialFlags: scrubData?.flags,
            // Added this as based on the dsnumber and usercheck we want to give priority to flag
            initialFlagType:
                scrubData?.dsnumber !== 0
                    ? !isEmpty(scrubData?.flags)
                        ? getRowType(
                              scrubData?.flags[0],
                              type,
                              +scrubData?.rpc_limit
                          )
                        : {}
                    : updatedRow,
            flags: flagValue,
            rowType: updatedRow,
            rowColor: updatedRow,
            total_clicks: scrubData?.total_clicks,
            current_rpc:
                scrubData?.current_rpc === -1 ? -1 : scrubData?.current_rpc,
            after_scrub_rpc:
                scrubData?.after_scrub_rpc === -1
                    ? -1
                    : scrubData?.after_scrub_rpc,
            rpc_limit: scrubData?.rpc_limit,
            expanded: getIsExpanded(mappedIndexData, scrubData.index),
            scrub_type: scrubData?.scrub_type,
            scrub_type_name: scrubTypes.find(
                _ => _.id === scrubData?.scrub_type
            )?.name,
        };
        return updatedValue;
    });
};

export const mapPublisherScrubDasboard = async (data, publisherList = []) => {
    return data?.map(scrubData => ({
        sourceId: scrubData.Source,
        campaignId: scrubData.Campaign,
        createdAt: scrubData.CreatedAt,
        scrubRatio: scrubData.scrubRate,
        date: scrubData.ScrubDate,
        sourceName: publisherList.find(
            element => element.id === scrubData.Source
        )?.name,
    }));
};

export const mapAdvertiser = advertisers => {
    return advertisers?.map(advertiser => ({
        id: advertiser.id,
        name: advertiser.name,
        key: `${advertiser.id} (${advertiser.name})`,
        label: `${advertiser.id}: ${advertiser.name}`,
    }));
};

export const mapPublisher = publisher => {
    return publisher?.map(publisher => ({
        id: publisher.id,
        name: publisher.name,
        key: `${publisher.id} (${publisher.name})`,
        label: `${publisher.id}: ${publisher.name}`,
        isEverFlow: publisher?.everflow_partner,
    }));
};

export const mapCampaign = (campaign, type = "advertiser", isAll = false) => {
    return type === "advertiser"
        ? campaign?.map(ele => ({
              id: ele?.id,
              name: isAll ? ele.name : ele.text,
              text: `${ele.id} : ${isAll ? ele.name : ele.text}`,
              key: `${ele.id} : (${isAll ? ele.name : ele.text})`,
              label: `${ele.id} : ${isAll ? ele.name : ele.text}`,
          }))
        : campaign?.map(ele => ({
              id: ele?.cmpid,
              cmpid: ele?.cmpid,
              name: ele?.name,
              pubid: ele?.pubid,
              text: `${isAll ? ele.cmpid : ele.cmpid} : ${ele?.name}`,
              key: `${isAll ? ele.cmpid : ele.cmpid} : (${ele?.name})`,
              label: `${isAll ? ele.cmpid : ele.cmpid} : ${ele?.name}`,
          }));
};

export const scrubDashboardParams = metrics => {
    const startDate = utcISO8601Format(metrics.dateRange.startDate);
    const endDate = utcISO8601Format(metrics.dateRange.endDate);
    const adv_list = metrics.selectedAdvertisers;
    const params = {
        startTime: startDate,
        endTime: endDate,
        adv_name: adv_list,
    };
    return params;
};

export const isValid = (formData, dsCost, advCost, scrubRatio, viewType) => {
    const {
        selectedAdvertiserId,
        selectedAdvertiserName,
        selectedPublisherId,
        selectedPublisherName,
        selectedCampaignId,
        selectedCampaignName,
        campaign,
        isPublisherScrub,
        rpcValue,
        selectedScrubType,
    } = formData;
    if (viewType === "Auto Calculated scrub") {
        if (
            isPublisherScrub &&
            selectedPublisherId &&
            selectedPublisherName &&
            (dsCost || rpcValue) &&
            advCost &&
            scrubRatio &&
            selectedScrubType
        )
            return true;
        if (
            !isPublisherScrub &&
            selectedAdvertiserId &&
            selectedAdvertiserName &&
            ((selectedCampaignId && selectedCampaignName) || campaign) &&
            (dsCost || rpcValue) &&
            advCost &&
            scrubRatio &&
            selectedScrubType
        )
            return true;
        else return false;
    } else {
        if (
            isPublisherScrub &&
            selectedPublisherId &&
            selectedPublisherName &&
            scrubRatio
        )
            return true;
        if (
            !isPublisherScrub &&
            selectedAdvertiserId &&
            selectedAdvertiserName &&
            ((selectedCampaignId && selectedCampaignName) || campaign) &&
            scrubRatio
        )
            return true;
        else return false;
    }
};

export const isActionButtonDisabled = (
    sourceData,
    isPublisherScrub = false
) => {
    const { sourceName, sourceId, sourceRefId } = sourceData;
    let idValid = sourceId && isValidIdPublisher(sourceId);
    if (sourceName && sourceId && idValid) return true;
    else if (isPublisherScrub && sourceName && sourceId && sourceRefId)
        return true;
    else return false;
};

export const getSum = list => {
    const calculateSum = list
        .map(item => item.revenue)
        .reduce((prev, next) => prev + next);
    return calculateSum;
};

export const getUniqueBy = (arr, prop) => {
    const set = new Set();
    return arr.filter(o => !set.has(o[prop]) && set.add(o[prop]));
};

export const calculateScrubRatio = (
    dSCost,
    sourceInputValue,
    discountPercent,
    totalClicks
) => {
    let scrubRatio = 0;
    let discount = discountPercent !== "" ? +discountPercent / 100 : 0;
    if (totalClicks) {
        scrubRatio = (1 - discount) * (sourceInputValue / dSCost);
        scrubRatio =
            scrubRatio !== Infinity
                ? !Number.isNaN(scrubRatio)
                    ? scrubRatio
                    : -1
                : -1;
    } else scrubRatio = -1;
    return scrubRatio;
};

// rpc : (source number * (1 - discount))/total_clicks
export const calculateRPC = (
    sourceInputValue,
    discountPercent,
    totalClicks
) => {
    let calculatedRPC = 0;
    let discount = discountPercent !== "" ? +discountPercent / 100 : 0;
    if (totalClicks) {
        calculatedRPC = (sourceInputValue * (1 - discount)) / totalClicks;
    } else calculatedRPC = -1;
    return calculatedRPC;
};

export const createParentTableData = (data, type = "advertiser") => {
    let parentTableData = [];

    parentTableData = data.reduce((acc, val) => {
        var o = acc
            .filter(obj => {
                return obj.index === val.index;
            })
            .pop() || {
            ...val,
            index: val.index,
            srcnumber: 0,
            updatedSrcNumber: 0,
            dsnumber: 0,
            discount_factor: 0,
            count: 1,
            total_clicks: 0,
            campaignIds: [],
            campaignNames: [],
            flags: [],
            updatedScrubRatio: 0,
            updatedRPC: 0,
            campaignCount: 0,
        };
        o.updatedSrcNumber += val.updatedSrcNumber;
        o.srcnumber += val.srcnumber;

        o.campaignIds = val?.cmpid ? o.campaignIds.concat(+val.cmpid) : [];
        o.campaignNames = val?.cmpnm ? o.campaignNames.concat(val.cmpnm) : [];
        o.flags = o.flags.concat(val.flags);
        o.dsnumber += Math.round(val.dsnumber * 100) / 100;
        o.discount_factor = val.discount_factor;
        o.total_clicks += val.total_clicks;
        o.campaignCount += 1;
        acc.push(o);
        return acc;
    }, []);

    const unique = getUniqueBy(parentTableData, "index");

    const updatedData = unique?.map(_ => {
        let new_scrub_ratio = calculateScrubRatio(
            _.dsnumber,
            _.srcnumber,
            _.discount_factor,
            _.total_clicks
        );
        let new_after_scrub_rpc = calculateRPC(
            _.srcnumber,
            _.discount_factor,
            _.total_clicks
        );
        let updatedScrubRatio = calculateScrubRatio(
            _.dsnumber,
            _.updatedSrcNumber,
            _.discount_factor,
            _.total_clicks
        );
        let updatedRPC = calculateRPC(
            _.updatedSrcNumber,
            _.discount_factor,
            _.total_clicks
        );
        let updatedFlags = _.flags.sort((a, b) => b - a);
        let updatedRow = !isEmpty(updatedFlags)
            ? getRowType(updatedFlags[0], type, +_.rpc_limit)
            : {};
        return {
            ..._,
            expanded: _.campaignCount > 1,
            scrub_ratio: new_scrub_ratio,
            updatedScrubRatio: updatedScrubRatio,
            updatedRPC,
            after_scrub_rpc: new_after_scrub_rpc,
            flags: updatedFlags,
            rowColor: updatedRow,
            rowType: updatedRow,
            // initialFlagType: updatedRow,
            // initialFlags: updatedFlags,
        };
    });

    return updatedData;
};

/**
 * @param scrubRatio Scrub ratio of the row
 * @param children Array of all children with unique campaigns and their ds number

    new calculated scrub ratio
    assign new src number to each child => srcNumber = dsNumber * scrubRatio
 */
export const redistribute = (scrubRatio, discountFactor, advCost, children) => {
    scrubRatio = scrubRatio / ((100 - discountFactor) / 100);
    let total = 0;

    const temp = children?.map((_, index) => {
        let newSrcNumber = scrubRatio * _.dsnumber;
        newSrcNumber = Math.round(newSrcNumber * 100) / 100;
        if (index < children.length - 1) total += newSrcNumber;
        let newScrubRatio = calculateScrubRatio(
            _.dsnumber,
            newSrcNumber,
            _.discount_factor,
            _.total_clicks
        );
        let newRPC = calculateRPC(
            newSrcNumber,
            _.discount_factor,
            _.total_clicks
        );
        return {
            ..._,
            srcnumber: newSrcNumber,
            scrub_ratio: newScrubRatio,
            after_scrub_rpc: newRPC,
            updatedScrubRatio: newScrubRatio,
            updatedRPC: newRPC,
        };
    });
    let finalChildSrcNumber = Math.round((advCost - total) * 100) / 100;
    temp[children.length - 1].srcnumber = finalChildSrcNumber;
    const { dsnumber, discount_factor, total_clicks } =
        temp[children.length - 1];
    temp[children.length - 1].scrub_ratio = calculateScrubRatio(
        dsnumber,
        finalChildSrcNumber,
        discount_factor,
        total_clicks
    );
    temp[children.length - 1].after_scrub_rpc = calculateRPC(
        finalChildSrcNumber,
        discount_factor,
        total_clicks
    );
    return temp;
};

export const getRPCLimit = data => {
    let defaultRPCLimit = 5;
    const limit = !isEmpty(data) ? +data[0]?.rpc_limit : defaultRPCLimit;
    return limit;
};

export const reCalculateScrubAndRPC = (
    newSrcNumber,
    dsnumber,
    discount_factor,
    total_clicks
) => {
    let scrubRatio = calculateScrubRatio(
        dsnumber,
        newSrcNumber,
        discount_factor,
        total_clicks
    );
    let rpc = calculateRPC(newSrcNumber, discount_factor, total_clicks);
    const updatedFields = { scrubRatio, rpc };
    return updatedFields;
};

export const distributeSrcNumber = async (
    parentSrcNumber,
    srcNumber,
    children
) => {
    let total = 0;
    const childrenLength = children?.length;
    const temp = children?.map((_, index) => {
        // if (index < childrenLength - 1) {
        const splitSrcNumber = srcNumber / childrenLength;
        const updatedSrcNumber =
            Math.round((_.updatedSrcNumber + splitSrcNumber) * 100) / 100;
        total = total + updatedSrcNumber;
        if (childrenLength > 1) {
            const updatedFields = reCalculateScrubAndRPC(
                updatedSrcNumber,
                _.dsnumber,
                _.discount_factor,
                _.total_clicks
            );
            return {
                ..._,
                srcnumber: updatedSrcNumber,
                scrub_ratio: updatedFields.scrubRatio,
                after_scrub_rpc: updatedFields.rpc,
                updatedSrcNumber: updatedSrcNumber,
            };
        } else
            return {
                ..._,
                updatedSrcNumber,
            };
    });
    if (!isEmpty(temp)) {
        const { dsnumber, discount_factor, total_clicks } =
            temp[temp.length - 1];
        if (temp.length > 1) {
            let prevElements = cloneDeep(temp);
            prevElements.pop();
            let prevTotal = prevElements
                ?.map(item => item.updatedSrcNumber)
                .reduce((prev, next) => prev + next);
            prevTotal = Math.round(prevTotal * 100) / 100;
            let newSrcNumber =
                Math.round((parentSrcNumber - prevTotal) * 100) / 100;
            const updatedFields = reCalculateScrubAndRPC(
                newSrcNumber,
                dsnumber,
                discount_factor,
                total_clicks
            );
            temp[temp.length - 1].srcnumber = newSrcNumber;
            temp[temp.length - 1].updatedSrcNumber = newSrcNumber;
            temp[temp.length - 1].scrub_ratio = updatedFields.scrubRatio;
            temp[temp.length - 1].after_scrub_rpc = updatedFields.rpc;
        } else {
            const updatedFields = reCalculateScrubAndRPC(
                Math.round(total * 100) / 100,
                dsnumber,
                discount_factor,
                total_clicks
            );
            temp[temp.length - 1].srcnumber = Math.round(total * 100) / 100;
            temp[temp.length - 1].updatedSrcNumber =
                Math.round(total * 100) / 100;
            temp[temp.length - 1].scrub_ratio = updatedFields.scrubRatio;
            temp[temp.length - 1].after_scrub_rpc = updatedFields.rpc;
            temp[temp.length - 1].updatedScrubRatio = updatedFields.scrubRatio;
            temp[temp.length - 1].updatedRPC = updatedFields.rpc;
        }
    }
    return temp;
};

export const getUpdatedFlagsandRow = (
    _,
    isBulkReadOnly,
    isReAdjust = false
) => {
    let updatedFlag = reCalculateFlags(_, isReAdjust);
    let adminUpdatedFlag = getAdminFlag(
        !isEmpty(updatedFlag) ? updatedFlag : [],
        isBulkReadOnly,
        _.after_scrub_rpc === -1 && _.total_clicks === 0
    );
    let updatedRow = !isEmpty(adminUpdatedFlag)
        ? getRowType(adminUpdatedFlag[0], _.type.toLowerCase(), _.rpc_limit)
        : {};
    let initiaFlagTypeRow = !isEmpty(updatedFlag)
        ? getRowType(updatedFlag[0], _.type.toLowerCase(), _.rpc_limit)
        : {};
    return {
        adminUpdatedFlag,
        updatedRow,
        initiaFlagTypeRow,
    };
};

export const reCalculateFlags = (_, isReAdjust) => {
    let updatedFlags = [];
    if (_.total_clicks === 0) updatedFlags.push(3);
    if (
        (!isReAdjust && _.after_scrub_rpc > +_.rpc_limit) ||
        (isReAdjust && _.updatedRPC > +_.rpc_limit)
    )
        updatedFlags.push(2);
    if (_.dsnumber === 0 && _.total_clicks > 0) updatedFlags.push(1);
    return updatedFlags;
};

export const scrubTypes = [
    {
        id: "DailyUpload",
        name: "Daily Upload",
    },
    {
        id: "MonthlyAdjustment",
        name: "Monthly Adjustment",
    },
    {
        id: "Chargeback/Ad-hoc",
        name: "Chargeback/ Ad-hoc",
    },
];
