import React, {
    useEffect,
    useImperativeHandle,
    useState,
    forwardRef,
} from "react";
import s from "./CompareList.module.scss";
import Icon from "../Icon/Icon";

const CompareList = forwardRef(
    (
        {
            listData = [],
            selector = null,
            title = "Selected",
            // masterList, //to compare
            onListChange, //to send currently active items
            showIcon = false,
            iconSelector = null,
            secondSelector = false,
        },
        ref
    ) => {
        const [deletedItems, setDeletedItems] = useState([]);

        // Need this code to debug and add softdelete
        // const [ogList, setOgList] = useState([]);
        // const [currentList, setCurrentList] = useState([]);
        // const [currentListIds, setCurrentListIds] = useState([]);
        // useEffect(() => {
        //     console.log(listData, " acc");
        // }, [listData]);
        // useEffect(() => {
        //     if (!ogList.length) {
        //         setOgList(listData?.map(_ => (selector ? _[selector] : _)));
        //     } //invoke once
        // }, []);
        // useEffect(() => {
        //     const updatedData = [];
        //     listData.map(_ => {
        //         const key = selector ? _[selector] : _;
        //         if (!currentListIds.includes(key)) {
        //             updatedData.push(_);
        //         }
        //     });
        //     if (updatedData.length) {
        //         setCurrentList([...currentList, ...updatedData]);
        //         setCurrentListIds(
        //             [...currentList, ...updatedData].map(_ =>
        //                 selector ? _[selector] : _
        //             )
        //         );
        //     }
        // }, [listData]);
        // useEffect(() => {
        //     console.log("before   ", getActiveItems());
        //     // console.log(currentList, listData, getActiveItems());
        //     if (typeof onListChange === "function") {
        //         onListChange(getActiveItems());
        //     }
        // }, [currentList, deletedItems]);

        useEffect(() => {
            if (typeof onListChange === "function") {
                onListChange(getActiveItems());
            }
        }, [deletedItems]);

        const getActiveItems = () => {
            return (
                listData?.filter(
                    _ => !deletedItems.includes(selector ? _[selector] : _)
                ) || null
            );
        };

        useImperativeHandle(ref, () => {
            return {
                getActiveList: getActiveItems,
            };
        });

        const keyValuePair = item => (
            <>
                <div className={`${s.keyValueWrapper}`}>
                    <div className={`${s.keyValueItem}`}>
                        <span>
                            {`${item[selector]} ${
                                item[secondSelector]
                                    ? ": " + item[secondSelector]
                                    : ""
                            } `}
                        </span>
                    </div>
                </div>
            </>
        );

        return (
            <div ref={ref}>
                <div className="dp-parent dp-parent-ver-center dp-parent-hor-space-between p-20 pb-10">
                    <div className={"fontMedium"}>
                        {title} ({listData?.length})
                    </div>
                    <div
                        className={s.clearBtn}
                        onClick={() => {
                            setDeletedItems(
                                listData?.map(_ => (selector ? _[selector] : _))
                            );
                        }}
                    >
                        Clear All
                    </div>
                </div>
                {listData?.length ? (
                    <div className={s.listWrap}>
                        {listData?.map(item => {
                            const itemKey = selector ? item[selector] : item;
                            return (
                                <div
                                    className={`${s.listItem} ${
                                        deletedItems.includes(itemKey)
                                            ? s.softDeleted
                                            : ""
                                    }`}
                                >
                                    {showIcon && (
                                        <Icon icon={"Plus"} size="14px" />
                                    )}
                                    {iconSelector && (
                                        <Icon
                                            icon={item[iconSelector]}
                                            size="14px"
                                        />
                                    )}
                                    {!secondSelector
                                        ? itemKey
                                        : keyValuePair(item)}
                                    <div className={s.iconWrap}>
                                        {deletedItems.includes(itemKey) ? (
                                            <div
                                                onClick={() => {
                                                    setDeletedItems(
                                                        deletedItems.filter(
                                                            _ => _ !== itemKey
                                                        )
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    icon={"Revert"}
                                                    size="14px"
                                                    className="circleIconColor circleIconColor--delete"
                                                    enableHover
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => {
                                                    setDeletedItems([
                                                        ...deletedItems,
                                                        itemKey,
                                                    ]);
                                                }}
                                            >
                                                <Icon
                                                    icon={"Delete"}
                                                    size="14px"
                                                    className="circleIconColor circleIconColor--delete"
                                                    enableHover
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="p-20 pt-0">{`No items ${title}`}</div>
                )}
            </div>
        );
    }
);

export default CompareList;
