import { isEmpty, cloneDeep } from "lodash";

export const collectionSelectList = [
    {
        name: "Register",
        id: "REGISTER",
    },
    {
        name: "Survey",
        id: "SURVEY",
    },
    {
        name: "Offer",
        id: "OFFER",
    },
    {
        name: "Listings",
        id: "LISTINGS",
    },
    {
        name: "Back Offer Wall",
        id: "OFFER_WALL",
    },
    {
        name: "Gift Card Listing",
        id: "GIFT_CARD_VIEWER",
    },
    {
        name: "Gift Card Offer Wall",
        id: "GENERIC_PATH_OFFER_WALL",
    },
    {
        name: "Details Confirmation",
        id: "DETAILS_CONFIRMATION",
    },
    {
        name: "Gratification",
        id: "GRATIFICATION",
    },
    {
        name: "Deals Offer Wall",
        id: "DEALS_OFFERWALL",
    },
    {
        name: "No Offer",
        id: "NO_OFFER",
    },
    {
        name: "Redirect",
        id: "THIRD_PARTY",
    },
    {
        name: "Standalone",
        id: "STANDALONE",
    },
    {
        name: "Confirmation",
        id: "CONFIRMATION",
    },
    {
        name: "No Jobs",
        id: "NO_JOBS",
    },
];

export const mapCollectionData = async data => {
    let initialData = data;
    let mappedData = data?.data?.map(collectionData => ({
        name: collectionData.name,
        id: collectionData.id,
        description: collectionData.description,
        type: {
            id: collectionData?.containerType,
            name: collectionData?.containerType,
        },
        containerTypeMapped: collectionSelectList.find(
            el => el?.id === collectionData?.containerType
        )?.name,
        containerType: collectionData?.containerType,
        isActive: collectionData.type,
        createdAt: collectionData.createdAt,
        updatedAt: collectionData.updatedAt,
        items: collectionData.items,
    }));
    return { ...initialData, data: mappedData };
};

//Finds the targeting nested values for getting the opsName
export const findByValue = (array, apiData) => {
    let targetingAdded = [];
    if (!isEmpty(array)) {
        for (const item of array) {
            if (item.value?.length && !isEmpty(item?.value[0]?.value)) {
                //Survey Question + Answer" is set to default because the keys there are based on the question
                item?.value?.forEach(_ => {
                    if (
                        !isEmpty(_.value) &&
                        !_.value[0].hasOwnProperty("linkOutQuestion")
                    ) {
                        let targetingOpsName =
                            apiData.find(tp => tp.name === _.value[0].key)
                                ?.opsName || "Survey Question + Answer";
                        targetingAdded.push(targetingOpsName);
                    }
                });
            } else if (item.value?.length) {
                item?.value?.forEach(_ => {
                    let targetingOpsName = apiData.find(
                        tp => tp.name === _.key
                    )?.opsName;
                    targetingAdded.push(targetingOpsName);
                });
            }
        }
    }
    return targetingAdded;
};

export const createLinkOutData = (data, isEdit = false) => {
    let finalLinkoutData = cloneDeep(data);
    if (!isEmpty(finalLinkoutData)) {
        finalLinkoutData.forEach(_ => {
            if (!isEmpty(_.targettingParams) && !isEdit) {
                _.targettingParams.type = "AND";
            }
            if (!isEmpty(_.targettingParams) && isEdit) {
                _.targettingParams.type = "OR";
            }
        });
    }
    return finalLinkoutData;
};

export const findLinkOut = (data, existing = false) => {
    let linkOutValues = [];
    let res = [];
    if (existing) linkOutValues = data;
    else {
        res = data.map(_ => ({
            key:
                typeof _.valueOne !== "string"
                    ? `q_${_.valueOne?.toString()}`
                    : `q_${_.valueOne}`,
            type: "EXPRESSION",
            value: _.valueSecond,
            operator: _.valueSecond === "skip" ? "NOT EQUAL" : "EQUAL",
            linkOutQuestion: true,
        }));
        linkOutValues = res;
    }
    return linkOutValues;
};

//Converts the targetting as per the Targetting node format;
export const getFinalTargetingData = data => {
    let intialTargetting = cloneDeep(data);
    let updatedTargetting =
        (!isEmpty(intialTargetting?.targettingParams?.value) &&
            Array.isArray(intialTargetting?.targettingParams?.value) &&
            intialTargetting?.targettingParams?.value?.find(
                _ => _.type === "OR"
            )) ||
        {};
    let finalTargettingData = !isEmpty(updatedTargetting)
        ? [
              ...intialTargetting?.targettingParams?.value.filter(
                  _ => _.type === "AND"
              ),
              ...updatedTargetting?.value,
          ]
        : [...(intialTargetting?.targettingParams?.value || [])];
    return finalTargettingData;
};
