import React, {
    useRef,
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import CreateSaveHeader from "../../components/CreateSaveHeader/CreateSaveHeader";
import Card from "../../Shared/Card/Card";
import Input from "../../Shared/Input/Input";
import Textarea from "../../Shared/Textarea/Textarea";
import s from "./CreateFlowCategories.module.scss";
import Skeleton from "react-loading-skeleton";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import _, { get } from "lodash";
import {
    flowCategoriesActions,
    createFlowCategory,
    fetchFlowCategoriesData,
    getFlowCategoriesData,
    updateFlowCategory,
    fetchCategoryHistory,
    getMappedOfferGroup,
} from "../../store/flowmanager/categories";

import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import { toastListActions } from "../../store/toastList/toastList";
import TargetingNode from "../CreateFlow/TargetingNode";
import { useFetchAvailableTargetingParams } from "../../generalApi/generalApi";
import Select from "../../Shared/Select/Select";
import Icon from "../../Shared/Icon/Icon";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import { fetchTpKey } from "../../Shared/FlowRenderer/FlowRendererUtils";
import { getTP } from "../CreateFlow/AddNode";
import { getRandomId } from "../../utils/globalUtils";
import ConfirmAndSave from "../../components/ConfirmAndSave/ConfirmAndSave";
import { flowCategoryConfirmConfig } from "../../constants/confirmSaveConstants";
import { historyDataAction } from "../../utils/historyPopUpUtils";
import History from "../../components/History/History";
import { flowCategoryConfig } from "../../constants/historyConstants";
import TagRenderer from "../../components/Renderers/TagRenderer/TagRenderer";

const CreateFlowCategories = forwardRef(({ passedId }, ref) => {
    // description is currently disabled coz desc is not implemented on api side, uncomment all description code once api get implemnted
    const dispatch = useDispatch();
    const {
        name,
        desc,
        targettingParams = {},
        loading,
        mainLoader,
        showPrompt,
        updatedAt,
        offerGroupMapped,
    } = useSelector(state => state.flowmanager.categories.createFlowCategory);
    const flowCategoryData = useSelector(
        state => state.flowmanager.categories.createFlowCategory
    );
    const flowCategory = useSelector(state => state.flowmanager.categories);
    const { historyData, historyDataRaw } = flowCategory;
    const flowCategoryDataBackup = useSelector(
        state => state.flowmanager.categories.createFlowCategoryBackup
    );
    const urlParams = useParams();
    const history = useHistory();
    const adCategRef = useRef(null);
    const tpWrapperRef = useRef(null);
    const saveConfirmCallbacks = useRef(null);

    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [orList, setOrList] = useState([{ tp: {}, ref: { current: null } }]);
    const { apiData = [], isLoading } = useFetchAvailableTargetingParams();

    const availableTargetingParams = useMemo(() => {
        return apiData || [];
    }, [apiData]);

    const selectedID = passedId || urlParams.id;

    const apiPayloadData = {
        hnpCategory: name,
        targettingParams: {},
        isActive: true,
        ...(selectedID && {
            hnpId: selectedID,
            updatedAt: updatedAt,
        }),
    };

    const showConfirmAndSave = async () => {
        return new Promise((resolve, reject) => {
            saveConfirmCallbacks.current = {
                resolve,
                reject,
            };
            setShowSaveConfirm(true);
        });
    };

    const onSaveHandler = async () => {
        apiPayloadData.targettingParams = {
            type: "OR",
            value: orList
                .map(_ => _.ref.current.getData())
                .filter(_ => _.value.length),
        };
        let isFormValid = true;
        let targetingIsValid = true;

        if (adCategRef.current.validate()) {
            isFormValid = true;
        } else {
            isFormValid = false;
            adCategRef.current.focus();
        }

        let emptyTargeting = [];
        apiPayloadData.targettingParams.value?.forEach((obj, idx) => {
            obj.value.forEach((item, index) => {
                const tpValue = item.value[0].value;
                const key = fetchTpKey(item);
                const tp = availableTargetingParams.find(_ => _.name === key);

                if (Array.isArray(tpValue) && tpValue?.length === 0) {
                    isFormValid = false;
                    targetingIsValid = false;
                    emptyTargeting.push(tp?.opsName);
                }
            });
        });

        if (!targetingIsValid) {
            emptyTargeting.forEach(element =>
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: (
                            <div className={s.toastDiv}>
                                Please add value for <span> {element}</span>{" "}
                                Targeting.
                            </div>
                        ),
                    })
                )
            );
        }

        if (isFormValid) {
            let confirm = true;
            if (selectedID) {
                confirm = await showConfirmAndSave();
            }
            if (confirm) {
                const returnedData = selectedID
                    ? await updateFlowCategoryFn()
                    : await createFlowCategoryFn();
                return returnedData;
            }
        }
    };

    const createFlowCategoryFn = async () => {
        return dispatch(createFlowCategory(apiPayloadData))
            .unwrap()
            .then(response => {
                response &&
                    response.data.error &&
                    get(response, "data.error", "")
                        .toLowerCase()
                        .includes("duplicate key value") &&
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Duplicate Name Not Allowed!!",
                        })
                    );

                response &&
                    get(response, "data.status", false) === "success" &&
                    navigateToListings();
            });
    };

    const updateFlowCategoryFn = async () => {
        return dispatch(updateFlowCategory(apiPayloadData))
            .unwrap()
            .then(response => {
                if (
                    response &&
                    get(response, "data.status", false) === "success"
                ) {
                    if (passedId) {
                        return response;
                    } else navigateToListings();
                }
            });
    };

    const navigateToListings = () => {
        history.replace({ pathname: `/hnp/flowcategories/listings` });
        dispatch(fetchFlowCategoriesData());
    };

    useImperativeHandle(ref, () => {
        return {
            save: onSaveHandler,
        };
    });

    useEffect(() => {
        if (adCategRef.current) adCategRef.current.focus();
        return () => {
            dispatch(flowCategoriesActions.resetData()); //set initial state on unmount to avoid edit page changes on create page
        };
    }, []);

    useEffect(() => {
        if (selectedID) {
            dispatch(getFlowCategoriesData(selectedID)).then(res => {
                const getName = get(res, "payload.hnpCategory");
                dispatch(getMappedOfferGroup(getName));
            });
        }
    }, [passedId, urlParams]);

    useEffect(() => {
        if (
            targettingParams?.type === "OR" &&
            Array.isArray(targettingParams?.value)
        ) {
            setOrList(
                targettingParams.value.map(v => ({
                    id: getRandomId(),
                    tp: v,
                    ref: { current: null },
                }))
            );
        } else {
            setOrList([
                {
                    id: getRandomId(),
                    tp: targettingParams,
                    ref: { current: null },
                },
            ]);
        }
    }, [targettingParams]);

    const authUser = useSelector(state => state.auth.user);
    const isReadOnly = !authUser.modules.includes(`HNP.FlowCategory.Write`);

    const toggleHistoryVisibilty = () => {
        dispatch(flowCategoriesActions.setHistoryData([]));
        dispatch(flowCategoriesActions.setHistoryDataRaw([]));
    };

    return (
        <>
            {showSaveConfirm && (
                <ConfirmAndSave
                    hideComparision={true}
                    entityType="HNP_CATEGORY"
                    entityId={selectedID}
                    dataUpdated={flowCategoryData}
                    dataBackup={flowCategoryDataBackup}
                    config={flowCategoryConfirmConfig}
                    saveCallback={() => {
                        setShowSaveConfirm(false);
                        saveConfirmCallbacks.current?.resolve(true);
                    }}
                    closeCallback={() => {
                        setShowSaveConfirm(false);
                        saveConfirmCallbacks.current?.resolve(false);
                    }}
                />
            )}
            <div className={s.createCategoriesWrapper}>
                {mainLoader && <LinearDeterminate />}

                {loading ? (
                    <SkeletonLoader />
                ) : (
                    <>
                        {!passedId && (
                            <Card className={`sticky-below-header ${s.header}`}>
                                <CreateSaveHeader
                                    onSave={!isReadOnly && onSaveHandler}
                                    isBig={true}
                                    title={`${
                                        selectedID ? "Edit" : "Create"
                                    } Flow Category`}
                                    saveLabel={`${
                                        selectedID ? "Save" : "Create"
                                    }`}
                                    desc={"Create Flow category for advertiser"}
                                    showPrompt={!isReadOnly && showPrompt}
                                    showHamburgerBtn={false}
                                    loading={loading}
                                    showHistoryIcon={
                                        urlParams.id ? true : false
                                    }
                                    showHistory={() => {
                                        historyDataAction(
                                            fetchCategoryHistory,
                                            urlParams,
                                            dispatch,
                                            flowCategoriesActions,
                                            true
                                        );
                                    }}
                                ></CreateSaveHeader>
                            </Card>
                        )}
                        <Card
                            className={
                                passedId ? "p-0 noShadow" : "card-padding"
                            }
                        >
                            <div>
                                <h3
                                    data-hash-id="leadBasics"
                                    id="leadBasics"
                                    className="headerTitle"
                                >
                                    Basics
                                </h3>
                                {selectedID && (
                                    <div className={`row`}>
                                        <p className={`headerTitleRequired`}>
                                            ID
                                        </p>
                                        <p className={`infoText`}>
                                            ID associated with a Lead Category
                                        </p>
                                        <p>{selectedID}</p>
                                    </div>
                                )}
                                <div className={"row"}>
                                    <p className="headerTitleRequired">Name*</p>
                                    <p className="infoText">
                                        Its an unique identifier name for the
                                        category
                                    </p>
                                    <Input
                                        ref={adCategRef}
                                        className="maxChildContainer"
                                        placeholder="Enter leads category name"
                                        required={true}
                                        onChange={e =>
                                            dispatch(
                                                flowCategoriesActions.setName(
                                                    e.target.value
                                                )
                                            )
                                        }
                                        value={name}
                                        disabled={isReadOnly}
                                    ></Input>
                                </div>
                                {selectedID && (
                                    <div className={"row maxChildContainer"}>
                                        <p className="headerTitleRequired">
                                            Offer Groups Assigned
                                        </p>
                                        <p className="infoText">
                                            Its an unique identifier name for
                                            the category
                                        </p>

                                        <div
                                            className={`${
                                                s.offerGroupAlignment
                                            } ${
                                                !offerGroupMapped?.length &&
                                                s.noOfferGroupMapped
                                            }`}
                                        >
                                            {loading ? (
                                                <>
                                                    <Skeleton height={40} />
                                                </>
                                            ) : offerGroupMapped?.length ? (
                                                offerGroupMapped.map(
                                                    (item, idx) => {
                                                        return (
                                                            <TagRenderer
                                                                key={idx}
                                                                showKeyValue={
                                                                    true
                                                                }
                                                                valueOne={
                                                                    item.id
                                                                }
                                                                valueTwo={
                                                                    item.name
                                                                }
                                                                isClickable={
                                                                    true
                                                                }
                                                                onClick={() => {
                                                                    window.open(
                                                                        `/hnp/offergroups/edit/${item.id}`,
                                                                        "_blank",
                                                                        "noreferrer"
                                                                    );
                                                                }}
                                                            />
                                                        );
                                                    }
                                                )
                                            ) : (
                                                "No Offer Groups Assigned"
                                            )}
                                        </div>
                                    </div>
                                )}
                                <TargetingGroup
                                    orList={orList}
                                    onChange={data => setOrList(data)}
                                    readOnly={isReadOnly}
                                />
                            </div>
                        </Card>
                    </>
                )}
            </div>
            {historyData && historyData.length > 0 ? (
                <History
                    data={historyData}
                    rawData={historyDataRaw}
                    closeHistory={toggleHistoryVisibilty}
                    historyConfig={flowCategoryConfig}
                />
            ) : null}
        </>
    );
});

export const TargetingWrapper = forwardRef(
    (
        {
            targettingParams,
            forcedTpList = [],
            addLabel = "Add Targeting",
            onChange = () => {},
            readOnly,
        },
        ref
    ) => {
        const [tpList, setTpList] = useState([]);
        const [addedKeys, setAddedKeys] = useState([]);
        const [openDropdown, setOpenDropdown] = useState(false);

        const { apiData = [], isLoading } = useFetchAvailableTargetingParams();

        const availableTargetingParams = useMemo(() => {
            if (forcedTpList.length) {
                return forcedTpList;
            }
            return apiData || [];
        }, [apiData, forcedTpList]);

        useEffect(() => {
            if (targettingParams?.value?.length) {
                setTpList([...targettingParams.value]);
            }
        }, [targettingParams]);

        useEffect(() => {
            setAddedKeys(tpList.map(_ => fetchTpKey(_?.value[0])));
        }, [tpList]);

        useImperativeHandle(ref, () => {
            return {
                getData: () => ({
                    type: "AND",
                    value: tpList,
                }),
            };
        });

        return (
            <ul>
                {tpList?.map((obj, i) => {
                    console.log(obj, "obj");
                    const key = fetchTpKey(obj);
                    const tp = availableTargetingParams.find(
                        _ => _.name === key
                    );
                    const branches = [
                        {
                            name: tp?.opsName,
                            value: Array.isArray(obj?.value)
                                ? obj?.value
                                : obj?.key
                                ? [
                                      {
                                          key: obj?.key,
                                          value: [obj?.value],
                                      },
                                  ]
                                : [],
                            connectionType: obj.type,
                        },
                    ];
                    return (
                        <li>
                            <TargetingNode
                                forcedTpList={availableTargetingParams}
                                branches={branches}
                                inlineMode={true}
                                onDelete={() => {
                                    setTpList(_ => [
                                        ..._.filter(
                                            (item, index) => i !== index
                                        ),
                                    ]);

                                    onChange();
                                }}
                                singleBranch={true}
                                readOnly={readOnly}
                                onChange={branches => {
                                    if (branches?.length) {
                                        setTpList(_ =>
                                            _.map((tp, j) => {
                                                if (i === j) {
                                                    return {
                                                        ...tp,
                                                        type:
                                                            branches[0]
                                                                ?.connectionType ||
                                                            tp.type,
                                                        value: branches[0]
                                                            ?.value?.length
                                                            ? branches[0]?.value?.map(
                                                                  v => {
                                                                      if (
                                                                          typeof v ===
                                                                          "object"
                                                                      ) {
                                                                          if (
                                                                              v.valueOne &&
                                                                              v.valueSecond
                                                                          ) {
                                                                              let tpData =
                                                                                  getTP(
                                                                                      branches[0]
                                                                                          .type,
                                                                                      v,
                                                                                      availableTargetingParams
                                                                                  );

                                                                              if (
                                                                                  v.valueSecond ===
                                                                                  "skip"
                                                                              ) {
                                                                                  let copyData =
                                                                                      [
                                                                                          ...tpData,
                                                                                      ];
                                                                                  copyData.forEach(
                                                                                      (
                                                                                          obj,
                                                                                          idx
                                                                                      ) => {
                                                                                          (obj.value =
                                                                                              v.valueSecond),
                                                                                              (obj.operator =
                                                                                                  "NOT EQUAL");
                                                                                      }
                                                                                  );

                                                                                  tpData =
                                                                                      copyData;
                                                                              }

                                                                              return {
                                                                                  type: "AND",
                                                                                  value: tpData,
                                                                              };
                                                                          }
                                                                      }
                                                                      return {
                                                                          key: branches[0]
                                                                              .type,
                                                                          operator:
                                                                              "EQUAL",
                                                                          type: "EXPRESSION",
                                                                          value: v,
                                                                      };
                                                                  }
                                                              )
                                                            : [
                                                                  {
                                                                      key: branches[0]
                                                                          .type,
                                                                      operator:
                                                                          "EQUAL",
                                                                      type: "EXPRESSION",
                                                                      value: branches[0]
                                                                          ?.value,
                                                                  },
                                                              ],
                                                    };
                                                }
                                                return tp;
                                            })
                                        );
                                    }
                                    onChange();
                                }}
                                customAnswerAndSkipAnswerSupport={true}
                                incrementBranchName={true}
                            />
                        </li>
                    );
                })}
                <li className="relative">
                    <Button
                        onClick={() => setOpenDropdown(true)}
                        btnTheme={ButtonTypes.primaryHover_btn}
                        disable={readOnly}
                        className={openDropdown ? s.plusDropDownActive : ""}
                    >
                        <Icon className="mr-5" icon="Plus" size="14px" />
                        {addLabel}
                    </Button>

                    {openDropdown && (
                        <div className={s.plusDropdownWrapper}>
                            <Select
                                options={availableTargetingParams.filter(
                                    _ =>
                                        !addedKeys.includes(_.name) ||
                                        _.name === "ans_2"
                                )}
                                keyField={"opsName"}
                                idField={"name"}
                                onLoadSelectReturn={false}
                                isSelectOpen={true}
                                portal={false}
                                hideLabel={true}
                                hideOutline={true}
                                onSelectClose={() => setOpenDropdown(false)}
                                CustomRender={({ option }) => (
                                    <>
                                        <Icon
                                            icon={option?.icon}
                                            fallbackIcon="Default"
                                            size="18px"
                                        />
                                        <span className="ml-5">
                                            {option.opsName}
                                        </span>
                                    </>
                                )}
                                onSelect={obj => {
                                    setOpenDropdown(false);
                                    setTpList(_ => [
                                        ..._,
                                        {
                                            type: "OR",
                                            value: [
                                                {
                                                    key: obj.name,
                                                    type: "EXPRESSION",
                                                    value: [],
                                                },
                                            ],
                                        },
                                    ]);
                                    onChange();
                                }}
                                isDisabled={readOnly}
                            />
                        </div>
                    )}
                </li>
            </ul>
        );
    }
);

export const TargetingGroup = ({
    orList = [],
    required = true,
    onChange,
    readOnly,
}) => {
    return (
        <div className={`row ${s.maxWidth} mt-20`}>
            <p className="headerTitleRequired">
                Targeting Parameter{required ? "*" : ""}
            </p>
            <p className="infoText">
                Its an unique identifier name for the category
            </p>
            <ul
                className={`${s.logicalGroupList} ${
                    readOnly ? s.readOnlyView : ""
                }
                fadeIn
                `}
            >
                {orList?.map(item => (
                    <li key={item.id}>
                        <span className={s.groupLabel}>And Group</span>
                        {!readOnly && (
                            <div className={s.actionsContainer}>
                                <Icon
                                    icon={"Delete"}
                                    size={"16px"}
                                    enableHover={true}
                                    onClick={() => {
                                        onChange(_ => [
                                            ..._.filter(v => v.id !== item.id),
                                        ]);
                                    }}
                                />
                            </div>
                        )}

                        <TargetingWrapper
                            targettingParams={item.tp}
                            ref={item.ref}
                            readOnly={readOnly}
                        />
                    </li>
                ))}
                {!readOnly && (
                    <li
                        className="link cursorPointer"
                        onClick={() => {
                            onChange(_ => [
                                ..._,
                                {
                                    id: getRandomId(),
                                    tp: {},
                                    ref: {
                                        current: null,
                                    },
                                },
                            ]);
                        }}
                    >
                        <Icon icon={"Plus"} size={"14px"} className="mr-5" />
                        Add And Group
                    </li>
                )}
            </ul>
        </div>
    );
};

export default CreateFlowCategories;
