import { get } from "lodash";
import React, { useState } from "react";
import Input from "../../Shared/Input/Input";
import s from "./EditOfferSlots.module.scss";
import Select from "../../Shared/Select/Select";
import ToggleValueSwitch from "../../Shared/ToggleValueSwitch/ToggleValueSwitch";
import DummySelect from "../DummySelect/DummySelect";

const EditOfferSlots = ({
    type,
    onChange,
    data,
    inputState,
    dropDownJson,
    inputJson,
    toggleValueSwitchJson,
    labelStyle = false,
}) => {
    let typeReturnValue;
    const [selectOpenOnChange, setSelectOpenOnChange] = useState(false);

    switch (type) {
        case 1:
            let value =
                inputState == toggleValueSwitchJson.leftLabel ? false : true;
            typeReturnValue = (
                <div className={s.toggleSwitch}>
                    <ToggleValueSwitch
                        leftLabel={toggleValueSwitchJson.leftLabel}
                        rightLabel={toggleValueSwitchJson.rightLabel}
                        // boolean={true}
                        // toggleState={editTypeToggle}
                        toggleState={value}
                        onChange={onChange}
                    />
                </div>
            );
            break;

        case 0:
            typeReturnValue = (
                <Input
                    className={get(inputJson, "className", "")}
                    ref={get(inputJson, "ref", null)}
                    type={get(inputJson, "type", "")}
                    value={inputState}
                    required={true}
                    onChange={e => onChange(e.target.value)}
                    placeholder={get(inputJson, "placeholder", "")}
                />
            );
            break;

        case 5:
            if (!get(dropDownJson, "condition", false) || selectOpenOnChange) {
                typeReturnValue = (
                    <>
                        <Select
                            className={get(dropDownJson, "className", "")}
                            label={
                                selectOpenOnChange
                                    ? ""
                                    : inputState && inputState.id
                                    ? ""
                                    : get(dropDownJson, "label", "")
                            }
                            options={data ? data : []}
                            keyField={get(dropDownJson, "keyField", "text")}
                            onSelect={onChange}
                            idField={get(dropDownJson, "idField", "id")}
                            onLoadSelectReturn={false}
                            selectedId={get(
                                dropDownJson,
                                "selectedIdField",
                                inputState && inputState.id
                                    ? inputState.id
                                    : null
                            )}
                            position={get(dropDownJson, "position", "bottom")}
                            isSelectOpen={selectOpenOnChange}
                            isError={get(dropDownJson, "isError", false)}
                        />
                    </>
                );
            } else {
                typeReturnValue = (
                    <DummySelect
                        value={inputState.key}
                        onClick={() => {
                            setSelectOpenOnChange(true);
                        }}
                        labelStyle={labelStyle}
                    />
                );
            }

            break;

        // For multi select
        case 4:
            typeReturnValue = (
                <Select
                    className={get(dropDownJson, "className", "")}
                    label={
                        inputState && inputState.id
                            ? ""
                            : get(dropDownJson, "label", "")
                    }
                    options={data ? data : []}
                    keyField={get(dropDownJson, "keyField", "text")}
                    onSelect={onChange}
                    onSelectClose={onChange}
                    idField={get(dropDownJson, "idField", "id")}
                    onLoadSelectReturn={false}
                    // selectedId={inputState && inputState.id ? inputState.id : null}
                    selectedIds={get(
                        dropDownJson,
                        "selectedIdField",
                        inputState && inputState.id ? inputState.id : null
                    )}
                    position={get(dropDownJson, "position", "bottom")}
                    multiSelect={true}
                />
            );
            break;

        case 9:
            typeReturnValue = (
                <div className={s.multiComponent}>
                    <Input
                        className={get(inputJson, "className", "")}
                        type={get(inputJson, "type", "")}
                        value={inputState[0]}
                        required={true}
                        onChange={e => onChange(e.target.value)}
                        placeholder={get(data, "placeholder", "")}
                    />
                    <div className={s.toggleSwitch}>
                        <ToggleValueSwitch
                            leftLabel={get(
                                toggleValueSwitchJson,
                                "leftLabel",
                                ""
                            )}
                            rightLabel={get(
                                toggleValueSwitchJson,
                                "rightLabel",
                                ""
                            )}
                            // boolean={true}
                            toggleState={inputState[1]}
                            onChange={onChange}
                        />
                    </div>
                </div>
            );
            break;
        case "none":
            return data ? data : "";
            break;

        default:
            return type;
    }
    return typeReturnValue;
};

export default EditOfferSlots;
