import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { useParams } from "react-router";
import ActionButtons from "../../components/Renderers/ActionButtons/ActionButtons";
import DateRenderer from "../Renderers/DateRenderer/DateRenderer";
import { createGraphData } from "../../utils/userJourneyUtils";
import s from "./UserDetailsTable.module.scss";
import Table from "../../Shared/Table/Table";
import JourneyGraph from "../../Shared/JourneyGraph/JourneyGraph";
import {
    applyConversion,
    fetchUserJourneyDetailList,
} from "../../store/listings/userJourneyDetail";
import Popup from "../../Shared/Popup/Popup";
import { MESSAGES } from "../../constants/messages";
import { usePrevious } from "../../Shared/hooks/use-previous";

export const CLICK_EVENTS = ["Ad Clicked", "Offer Clicked"];
const tableConfig = {
    stickyHeader: true,
};

const UserDetailsTable = memo(({ row, readOnly = false }) => {
    const userJourneyDetail = useSelector(
        state => state.listings.userJourneyDetail
    );
    const userData = get(userJourneyDetail, "list", []);
    const filteredList = get(userJourneyDetail, "filteredList", []);
    const unFilteredList = get(userJourneyDetail, "unfilteredList", []);

    const [accordionData, setAccordionData] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const prevFilteredList = usePrevious(filteredList);
    const prevUnfilteredList = usePrevious(unFilteredList);
    const urlParams = useParams();
    const [reRender, setReRender] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (
            prevFilteredList?.length !== filteredList?.length ||
            reRender ||
            prevUnfilteredList?.length !== unFilteredList?.length
        ) {
            const filteredData =
                Array.isArray(filteredList) &&
                filteredList?.filter(ele => ele?.sid === row?.sid);
            setAccordionData(filteredData || []);
            setReRender(false);
        }
        return () => {
            setReRender(false);
        };
    }, [filteredList, reRender]);

    useEffect(() => {
        const filteredGraphData =
            Array.isArray(userData) &&
            userData?.filter(ele => ele?.sid === row?.sid);
        let graph = [];
        if (urlParams?.domain.includes("rewardsurveyusa")) {
            graph =
                !isEmpty(filteredGraphData) &&
                createGraphData(filteredGraphData, true);
        } else {
            graph =
                !isEmpty(filteredGraphData) &&
                createGraphData(filteredGraphData, false);
        }
        setGraphData(graph);
    }, [userData]);

    const applyConversionFunction = item => {
        return dispatch(applyConversion(item)).then(async () => {
            await setReRender(true);
        });
    };

    const showFireConversion = ({ item, stateList }) => {
        const latestUserData = stateList;
        return (
            latestUserData?.findIndex(
                _ =>
                    (_.Activity.includes("Click conversion (manual)") ||
                        _.Activity.includes("Ad Conversions") ||
                        _.Activity.includes("Ad Conversion")) &&
                    _.clickId === item.clickId
            ) === -1
        );
    };

    return (
        <div className={s.accordionTable}>
            <JourneyGraph journeyData={graphData} loading={userData.loading} />
            <div
                className={s.tableContainer}
                style={{
                    height:
                        accordionData.length > 10 || accordionData.length === 0
                            ? 500
                            : accordionData.length * 41 + 80,
                }}
            >
                <Table
                    columns={accordionColumns}
                    data={accordionData}
                    callback={(name, e) => {
                        if (typeof eval(name) === "function") {
                            return eval(name)(e);
                        } else {
                            return eval(name);
                        }
                    }}
                    tableData={{
                        tableError: !accordionData.length
                            ? MESSAGES.TABLE.userJourneyNoDataForFilter
                            : "",
                    }}
                ></Table>
            </div>
        </div>
    );
});

export default UserDetailsTable;

const confirmPopupMsg = (
    <p>
        This will fire a Conversion Pixel <br></br>
        Do you wish to go ahead?
    </p>
);

const accordionColumns = [
    {
        text: "TS",
        key: "ts",
        disableCopyText: true,
        CustomRender: ({ item }) => {
            return <DateRenderer date={get(item, "ts")} />;
        },
    },
    {
        text: "Activity",
        key: "Activity",
        // disableCopyText: true,
    },
    {
        text: "Section",
        key: "Section",
        // disableCopyText: true,
    },
    {
        text: "Category",
        key: "category",
        // disableCopyText: true,
    },
    {
        text: "Advertiser",
        key: "Advertiser",
    },
    {
        text: "Campaign",
        key: "Campaign",
        // disableCopyText: true,
    },
    {
        text: "Click ID",
        key: "clickId",
        // disableCopyText: true,
    },
    {
        text: "Creative ID",
        key: "crid",
        // disableCopyText: true,
    },
    {
        text: "Question",
        key: "Question",
        // disableCopyText: true,
    },
    {
        text: "Answer",
        key: "Answer",
        // disableCopyText: true,
    },
    {
        text: "",
        key: "",
        styles: {
            position: "sticky",
            right: 0,
            zIndex: 2,
        },
        CustomRender: ({ item, cb, getState }) => {
            const [showConfirm, setShowConfirm] = useState(false);
            const [loading, setLoading] = useState(false);
            const stateList = getState().listings.userJourneyDetail.list;
            return cb("showFireConversion", { item, stateList }) &&
                CLICK_EVENTS.includes(item?.Activity) ? (
                <>
                    <ActionButtons
                        item={item}
                        applyConversion={e => setShowConfirm(true)}
                        readOnly={cb("readOnly")}
                        loading={loading}
                    />

                    {showConfirm && (
                        <div className={s.confirmPopupContainer}>
                            <Popup
                                customClass={s.confirmPopup}
                                showHeader={false}
                                showFooter={false}
                                showConfirmBox={true}
                                saveButtonText={"Yes, Go ahead"}
                                cancelButtonText={"Cancel"}
                                closeOutside={true}
                                message={confirmPopupMsg}
                                disableSave={false}
                                width={"332px"}
                                portal={false}
                                onClose={() => setShowConfirm(false)}
                                onSave={() => {
                                    setLoading(true);
                                    cb("applyConversionFunction", item).then(
                                        _ => setLoading(false)
                                    );
                                    setShowConfirm(false);
                                }}
                                textAlign={"left"}
                            />
                        </div>
                    )}
                </>
            ) : (
                " "
            );
        },
        bulkActions: true,
    },
];
