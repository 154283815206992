import { createSlice } from "@reduxjs/toolkit";

const initialAdUnitState = {
    basics: {},
    slots: {},
};
const initialState = {
    pages: {
        "Page 1": {
            ...initialAdUnitState,
        },
    },
    selectedPageId: "Page 1",
};

const adUnitSlice = createSlice({
    name: "adunit",
    initialState,
    reducers: {
        addAdUnitTab(state, action) {
            state.pages[action.payload] = {
                ...initialAdUnitState,
            };
            state.selectedPageId = action.payload;
        },
        setSelectedPageId(state, action) {
            state.selectedPageId = action.payload;
        },
        deletePageId(state, action) {
            delete state.pages[action.payload];
        },
    },
});

export const adUnitActions = adUnitSlice.actions;
export default adUnitSlice.reducer;
