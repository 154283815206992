import React, { useState } from "react";
import Icon from "../Icon/Icon";
import s from "./InfoText.module.scss";
import PropTypes from "prop-types";

export const InfoTextTypes = {
    SUCCESS: "success",
    DANGER: "danger",
    WARNING: "warning",
    INFO: "infoWarning",
};

const InfoText = ({
    message = "hello",
    theme,
    closeAble = false,
    showInfoIcon = true,
    className,
    ...props
}) => {
    const [removeBox, setRemoveBox] = useState(true);
    const deleteBox = () => {
        setRemoveBox(false);
    };
    const getIcon = theme => {
        switch (theme) {
            case "success":
                return "Check";
            case "danger":
                return "Error";
            case "warning":
                return "Error";
            case "info":
                return "Info";
            default:
                return "Info";
        }
    };
    return removeBox ? (
        <div
            className={`${s.infoText} ${theme ? s[theme] : s.success} ${
                closeAble ? "pr-40" : ""
            } ${className ? className : ""} `}
        >
            {showInfoIcon && (
                <Icon
                    icon={getIcon(theme)}
                    size="16px"
                    className={`${s.infoIcon}`}
                />
            )}
            <label>{props.children || message}</label>
            {closeAble && (
                <Icon
                    icon="CloseDefault"
                    size="14px"
                    onClick={() => deleteBox()}
                    className={`${s.crossIcon}`}
                />
            )}
        </div>
    ) : (
        ""
    );
};

InfoText.propTypes = {
    /**
     *  Theme out of supported theme, refer storybook for all available themes
     */
    theme: PropTypes.string,

    /**
     * Text to be displayed in the button
     */
    message: PropTypes.string,

    /**
     * If it has to have a close button ?
     */
    closeAble: PropTypes.bool,
};

InfoText.defaultProps = {
    theme: InfoTextTypes.SUCCESS,
    closeAble: false,
};

export default InfoText;
