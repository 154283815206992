import React, { useState, useRef, useEffect } from "react";
import Icon from "../../Shared/Icon/Icon";
import { getType, getTypeOfChange } from "../../utils/historyPopUpUtils.js";
import s from "./History.scss";
import { getRandomId, isElementEmpty } from "../../utils/globalUtils";
import Accordion from "../../Shared/Accordion/Accordion";
import { get, isEmpty, isEqual } from "lodash";
import Tooltip from "../../Shared/Tooltip/Tooltip";

const ChangedContentRenderer = ({
    keysObj,
    newData,
    oldData,
    config,
    isPublishHistory = false,
}) => {
    const printData = () => {
        return keysObj?.map((obj, index) => {
            const currType = window.location.href.includes("/create")
                ? "Added"
                : getTypeOfChange(
                      get(oldData, obj.id, ""),
                      get(newData, obj.id, "")
                  );
            if (
                get(oldData, obj.id, "") ||
                get(oldData, obj.id, "") === 0 ||
                get(newData, obj.id, "") ||
                get(newData, obj.id, "") === 0
            ) {
                if (obj.renderer === "text") {
                    return (
                        <React.Fragment key={getRandomId() + index}>
                            <p className={s.histMb10}>{obj.label}</p>
                            <TextDiff
                                type={currType}
                                entity={obj.label}
                                text1={get(oldData, keysObj[index].id, "")}
                                text2={get(newData, keysObj[index].id)} // Removing the default string as in null cases no need to show ""
                                valueMapping={obj?.valueMapping}
                            />
                        </React.Fragment>
                    );
                } else if (
                    (obj.renderer === "array" &&
                        !isElementEmpty(
                            <ArrayDiff
                                type={currType}
                                newData={get(newData, obj.id, "") || []}
                                oldData={get(oldData, obj.id, "") || []}
                                ignoreList={obj.ignoreList}
                                innerPaths={obj.innerPaths}
                            />
                        )) ||
                    (obj.renderer === "object" &&
                        !isElementEmpty(
                            <ObjectDiff
                                type={currType}
                                newData={get(newData, obj.id, "") || []}
                                oldData={get(oldData, obj.id, "") || []}
                                ignoreList={obj.ignoreList}
                                keyMapping={obj.keyMapping}
                                valueMapping={obj.valueMapping}
                            />
                        )) ||
                    (obj.renderer === "flatArray" &&
                        ((currType === "Added" &&
                            !isEmpty(get(newData, obj.id, ""))) ||
                            currType !== "Added") &&
                        !isElementEmpty(
                            <ArrayDiff
                                type={currType}
                                newData={get(newData, obj.id, "") || []}
                                oldData={get(oldData, obj.id, "") || []}
                                ignoreList={obj.ignoreList}
                                innerPaths={obj.innerPaths}
                            />
                        ))
                ) {
                    return (
                        <React.Fragment key={getRandomId() + index}>
                            <p className={s.histMb10}>{obj.label}</p>
                            <Accordion
                                accordContainer={
                                    obj.renderer === "array" ||
                                    obj.renderer === "flatArray" ? (
                                        <ArrayDiff
                                            key={getRandomId() + index}
                                            type={currType}
                                            newData={
                                                get(newData, obj.id, "") || []
                                            }
                                            oldData={
                                                get(oldData, obj.id, "") || []
                                            }
                                            ignoreList={obj.ignoreList}
                                            innerPaths={obj.innerPaths}
                                            isFlatArray={
                                                obj.renderer === "flatArray"
                                            }
                                        />
                                    ) : (
                                        <ObjectDiff
                                            key={getRandomId() + index}
                                            type={currType}
                                            newData={
                                                get(newData, obj.id, "") || []
                                            }
                                            oldData={
                                                get(oldData, obj.id, "") || []
                                            }
                                            ignoreList={obj.ignoreList}
                                            keyMapping={obj.keyMapping}
                                            valueMapping={obj.valueMapping}
                                        />
                                    )
                                }
                                accordianWrapClass={`${s.accordionWrap} ${s[currType]}`}
                                accordianClass={s.accordionItem}
                                accordianClassOpen={s.accordionItem__open}
                                accordContainerClass={s.childComponentWrapper}
                            >
                                <div
                                    className={`${s.difftextWrap} ${s[currType]} dp-parent dp-parent-ver-center gap-10`}
                                    key={getRandomId() + index}
                                >
                                    <Icon
                                        size="10px"
                                        icon={
                                            currType === "Updated"
                                                ? "EditFilled"
                                                : currType === "Added"
                                                ? "Plus"
                                                : "HistoryRemoveFilled"
                                        }
                                        color={
                                            currType === "Updated"
                                                ? "#4a90e2"
                                                : currType === "Added"
                                                ? "#41c3a9"
                                                : "#E76E6E"
                                        }
                                    />
                                    <div>
                                        {currType}
                                        <span className={s.entity}>
                                            {obj.label}
                                        </span>
                                    </div>
                                </div>
                            </Accordion>
                        </React.Fragment>
                    );
                }
            }
        });
    };

    if (isElementEmpty(printData()))
        return (
            <div>No differences found, you can proceed saving the changes.</div>
        );

    return (
        <>
            <h1
                className={`dp-parent gap-10 ${
                    isPublishHistory ? s.publishChange : ""
                }`}
            >
                <Icon icon={config.text.icon} size="16px" color="#74838F" />
                {config.text.heading}
            </h1>
            <div className="pl-25">{printData()}</div>
        </>
    );
};

export default ChangedContentRenderer;

const getArrayData = (someData, ignoreList = [], nested = false) => {
    if (someData === undefined || someData === null)
        return JSON.stringify(someData);

    let domData = "";

    if (getType(someData) === "string" || getType(someData) === "number") {
        domData = <div>{someData}</div>;
    } else if (getType(someData) === "object") {
        domData = Object.keys(someData)?.map(key => {
            if (!ignoreList.includes(key)) {
                return (
                    <div
                        className={`${s.item} ${
                            nested ? "ml-5" : s.histMb10
                        } dp-parent gap-10 flex-wrap`}
                        key={getRandomId() + getRandomId()}
                    >
                        <div className={s.key}>{key} : </div>
                        <div className={s.value}>
                            {getType(someData[key]) === "object"
                                ? getObjData(someData[key], true)
                                : getType(someData[key]) === "array"
                                ? getArrayData(someData[key], ignoreList, true)
                                : JSON.stringify(someData[key])}
                        </div>
                    </div>
                );
            }
        });
    } else if (getType(someData) === "array") {
        domData = getObjData(someData);
    }

    return domData;
};

const getUpdatedArrayData = (someData, type = "Updated") => {
    if (someData === undefined || someData === null)
        return JSON.stringify(someData);
    let domData = "";

    if (getType(someData) === "string") {
        domData = <div>{someData}</div>;
    } else {
        domData = Object.keys(someData)?.map(key => {
            if (someData[key].old || someData[key].new) {
                return (
                    <div
                        className={`${s.item} ${s.histMb10} dp-parent dp-parent-col gap-10`}
                        key={getRandomId() + getRandomId()}
                    >
                        <div className="capitalize">{key} :</div>
                        <div className={`${s.updatedContent} dp-parent`}>
                            {type !== "Added" && (
                                <div
                                    className={`${s.oldData} dp-child-50 p-10`}
                                >
                                    {getType(someData[key].old) === "array" &&
                                    someData[key].old?.length > 0 &&
                                    getType(someData[key].old[0]) === "string"
                                        ? someData[key].old.map(text => (
                                              <p key={text}>{text}</p>
                                          ))
                                        : getObjData(someData[key].old)}
                                </div>
                            )}
                            {type !== "Removed" && (
                                <div className="dp-child-50 p-10">
                                    {getType(someData[key].new) === "array" &&
                                    someData[key].new?.length > 0 &&
                                    getType(someData[key].new[0]) ===
                                        "string" ? (
                                        someData[key].new.map(text => (
                                            <p className={s.value} key={text}>
                                                {text}
                                            </p>
                                        ))
                                    ) : (
                                        <div
                                            className={s.value}
                                            key={getRandomId()}
                                        >
                                            {getObjData(someData[key].new)}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
        });
    }
    return domData;
};

const getArrayUpdatedDiffData = (oldData, newData, innerPaths) => {
    let removedArr = [];
    let addedArr = [];
    let updatedArr = [];

    const updatedLongestData =
        oldData.length > newData.length ? oldData : newData;

    if (
        getType(oldData[0]) === "string" ||
        getType(oldData[0]) === "number" ||
        getType(newData[0]) === "string" ||
        getType(newData[0]) === "number"
    ) {
        removedArr.push(oldData.filter(val => !newData.includes(val)));
        addedArr.push(newData.filter(val => !oldData.includes(val)));
    } else {
        updatedLongestData.forEach((el, index) => {
            if (oldData[index] && !newData[index]) {
                removedArr.push(oldData[index]);
            } else if (!oldData[index] && newData[index]) {
                addedArr.push(newData[index]);
            } else if (
                oldData[index] &&
                newData[index] &&
                !isEqual(oldData[index], newData[index])
            ) {
                let tempData = {};
                if (getType(oldData[index]) === "object" && innerPaths) {
                    Object.keys(oldData[index]).forEach(key => {
                        if (innerPaths.includes(key)) {
                            const removedElems = oldData[index][key].filter(
                                val => !newData[index][key].includes(val)
                            );
                            const addedElems = newData[index][key].filter(
                                val => !oldData[index][key].includes(val)
                            );

                            if (
                                removedElems.length > 0 ||
                                addedElems.length > 0
                            ) {
                                tempData[key] = {
                                    old: removedElems,
                                    new: addedElems,
                                };
                            }
                        } else {
                            if (
                                !isEqual(
                                    oldData[index][key],
                                    newData[index][key]
                                )
                            ) {
                                tempData[key] = {
                                    old: oldData[index][key],
                                    new: newData[index][key],
                                };
                            }
                        }
                    });
                    updatedArr.push(tempData);
                }
            }
        });
    }
    return {
        removed: removedArr,
        added: addedArr,
        updated: updatedArr,
    };
};

const getObjData = (data, nested, isValue = false) => {
    if (data === undefined) return null;

    if (getType(data) === "object") {
        return Object.keys(data).map(_ => (
            <div className={nested ? "ml-5" : s.histMb10} key={getRandomId()}>
                {_} :
                {getType(data[_]) === "array"
                    ? getArrayData(data[_], [], true)
                    : getType(data[_]) === "object"
                    ? getObjData(data[_], true)
                    : JSON.stringify(data[_])}
            </div>
        ));
    } else if (Array.isArray(data)) {
        if (["string", "number"].includes(getType(data[0]))) {
            return data.join(", ");
        } else if (getType(data[0]) === "object") {
            return data.map(obj => (
                <div className={`${s.separator} mb-20`} key={getRandomId()}>
                    {getObjData(obj)}
                </div>
            ));
        }
    } else return JSON.stringify(data);
};

const TextDiff = ({
    type = "Removed",
    entity,
    text1,
    text2,
    valueMapping = [],
}) => {
    return (
        <div
            className={`${s.difftextWrap} ${s[type]} dp-parent dp-parent-ver-center gap-10`}
        >
            <Icon
                size="10px"
                icon={
                    type === "Updated"
                        ? "EditFilled"
                        : type === "Added"
                        ? "Plus"
                        : "HistoryRemoveFilled"
                }
                color={
                    type === "Updated"
                        ? "#4a90e2"
                        : type === "Added"
                        ? "#41c3a9"
                        : "#E76E6E"
                }
            />
            <p>
                {type}
                <span className={s.entity}>{entity}</span>
                {text1 && (
                    <span
                        className={`${s.txt} ${
                            type !== "Added" ? s.strike : ""
                        }`}
                    >
                        {valueMapping[text1] || text1}
                    </span>
                )}
                {text1 && text2 && "to"}
                {
                    <span className={s.txt}>
                        {JSON.stringify(valueMapping[text2] || text2)}
                    </span>
                }
            </p>
        </div>
    );
};

const ArrayDiff = ({
    type = "Removed",
    newData = [],
    oldData = [],
    ignoreList = [],
    innerPaths = null,
    isFlatArray = false,
}) => {
    const updatedLongestData =
        oldData.length > newData.length ? oldData : newData;
    const updatedData = getArrayUpdatedDiffData(oldData, newData, innerPaths);

    const printData = () => {
        if (type === "Removed" && oldData) {
            return Object.keys(oldData).map(_ => (
                <div className={s.separator} key={getRandomId()}>
                    {getArrayData(oldData[_], ignoreList)}
                </div>
            ));
        }
        if (type === "Added" && newData) {
            return Object.keys(newData).map(_ => (
                <div className={s.separator} key={getRandomId()}>
                    {getArrayData(newData[_], ignoreList)}
                </div>
            ));
        }
        if (type === "Updated" && oldData && newData) {
            if (innerPaths) {
                return (
                    <>
                        {updatedData.removed.length > 0 &&
                            !isElementEmpty(
                                updatedData.removed.map(el => getObjData(el))
                            ) && (
                                <div
                                    className={s.updatedContent}
                                    key={getRandomId()}
                                >
                                    {updatedData.removed?.map(el => {
                                        return (
                                            <Accordion
                                                accordContainer={
                                                    <div className="pt-10">
                                                        {getObjData(el)}
                                                    </div>
                                                }
                                                accordianWrapClass={`${s.accordionWrap} ${s["Removed"]} ${s.nestedAccordion}`}
                                                accordianClass={s.accordionItem}
                                                accordianClassOpen={
                                                    s.accordionItem__open
                                                }
                                                accordContainerClass={
                                                    s.childComponentWrapper
                                                }
                                                showContent
                                            >
                                                <div
                                                    className={`${s.difftextWrap} ${s["Removed"]} dp-parent dp-parent-ver-center gap-10`}
                                                    key={getRandomId()}
                                                >
                                                    <Icon
                                                        size="10px"
                                                        icon={
                                                            "HistoryRemoveFilled"
                                                        }
                                                        color={"#E76E6E"}
                                                    />
                                                    <p>Removed</p>
                                                </div>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            )}
                        {updatedData.added.length > 0 &&
                            !isElementEmpty(
                                updatedData.added.map(el => getObjData(el))
                            ) && (
                                <div
                                    className={s.updatedContent}
                                    key={getRandomId()}
                                >
                                    {updatedData.added?.map(el => {
                                        return (
                                            <Accordion
                                                accordContainer={
                                                    <div className="pt-10">
                                                        {getObjData(el)}
                                                    </div>
                                                }
                                                accordianWrapClass={`${s.accordionWrap} ${s["Added"]} ${s.nestedAccordion}`}
                                                accordianClass={s.accordionItem}
                                                accordianClassOpen={
                                                    s.accordionItem__open
                                                }
                                                accordContainerClass={
                                                    s.childComponentWrapper
                                                }
                                                showContent
                                            >
                                                <div
                                                    className={`${s.difftextWrap} ${s["Added"]} dp-parent dp-parent-ver-center gap-10`}
                                                    key={getRandomId()}
                                                >
                                                    <Icon
                                                        size="10px"
                                                        icon={"Plus"}
                                                        color={"#41c3a9"}
                                                    />
                                                    <p>Added</p>
                                                </div>
                                            </Accordion>
                                        );
                                    })}
                                </div>
                            )}
                        {updatedData.updated.length > 0 &&
                            !isElementEmpty(
                                updatedData.updated?.map(el =>
                                    getUpdatedArrayData(el)
                                )
                            ) && (
                                <div
                                    className={s.updatedContent}
                                    key={getRandomId()}
                                >
                                    {updatedData.updated?.map(el => {
                                        if (
                                            (getType(el) === "object" &&
                                                Object.keys(el).length > 0) ||
                                            (getType(el) === "array" &&
                                                el.length > 0) ||
                                            (getType(el) !== "array" &&
                                                getType(el) !== "object")
                                        ) {
                                            let currType = getTypeOfChange(
                                                el.old,
                                                el.new
                                            );
                                            return (
                                                <Accordion
                                                    accordContainer={
                                                        <div className="pt-10">
                                                            {getUpdatedArrayData(
                                                                el,
                                                                currType
                                                            )}
                                                        </div>
                                                    }
                                                    accordianWrapClass={`${s.accordionWrap} ${s[currType]} ${s.nestedAccordion}`}
                                                    accordianClass={
                                                        s.accordionItem
                                                    }
                                                    accordianClassOpen={
                                                        s.accordionItem__open
                                                    }
                                                    accordContainerClass={
                                                        s.childComponentWrapper
                                                    }
                                                    showContent
                                                >
                                                    <div
                                                        className={`${s.difftextWrap} ${s[currType]} dp-parent dp-parent-ver-center gap-10`}
                                                        key={getRandomId()}
                                                    >
                                                        <Icon
                                                            size="10px"
                                                            icon={
                                                                currType ===
                                                                "Updated"
                                                                    ? "EditFilled"
                                                                    : currType ===
                                                                      "Added"
                                                                    ? "Plus"
                                                                    : "HistoryRemoveFilled"
                                                            }
                                                            color={
                                                                currType ===
                                                                "Updated"
                                                                    ? "#4a90e2"
                                                                    : currType ===
                                                                      "Added"
                                                                    ? "#41c3a9"
                                                                    : "#E76E6E"
                                                            }
                                                        />
                                                        <p>{currType}</p>
                                                    </div>
                                                </Accordion>
                                            );
                                        }
                                    })}
                                </div>
                            )}
                    </>
                );
            } else if (isFlatArray) {
                return (
                    <div className={s.updatedContent}>
                        <div
                            className={`${s.diffWrap} ${s.diffWrap__update} dp-parent`}
                            key={getRandomId()}
                        >
                            <div className={`${s.oldData} dp-child-50 p-10`}>
                                {getArrayData(oldData, ignoreList)}
                            </div>
                            <div className="dp-child-50 p-10">
                                {getArrayData(newData, ignoreList)}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={s.updatedContent}>
                        {Object.keys(updatedLongestData).map(_ => {
                            if (
                                !isEqual(
                                    getArrayData(oldData[_], ignoreList),
                                    getArrayData(newData[_], ignoreList)
                                )
                            ) {
                                return (
                                    <div
                                        className={`${s.diffWrap} ${s.diffWrap__update} dp-parent`}
                                        key={getRandomId() + getRandomId()}
                                    >
                                        <div
                                            className={`${s.oldData} dp-child-50 p-10`}
                                        >
                                            {getArrayData(
                                                oldData[_],
                                                ignoreList
                                            )}
                                        </div>
                                        <div className="dp-child-50 p-10">
                                            {getArrayData(
                                                newData[_],
                                                ignoreList
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                );
            }
        }
    };

    return (
        <CollapsibleHeightBox key={getRandomId()}>
            {printData()}
        </CollapsibleHeightBox>
    );
};

const ObjectDiff = ({
    type = "Removed",
    newData = [],
    oldData = [],
    ignoreList = [],
    keyMapping = null,
    valueMapping = null,
}) => {
    const getValue = (key, db) => {
        return getObjData(
            Array.isArray(db[key])
                ? db[key].map(el =>
                      valueMapping
                          ? (valueMapping[key] && valueMapping[key][el]) || el
                          : el
                  )
                : db[key]
        );
    };

    const printData = () => {
        if (type === "Removed") {
            return (
                <React.Fragment key={getRandomId()}>
                    {Object.keys(oldData).map(_ => {
                        if (oldData[_] && !ignoreList.includes(_)) {
                            return (
                                <CollapsibleHeightBox
                                    customClass={`${s.item} dp-parent gap-10`}
                                >
                                    <div className={s.key}>
                                        {keyMapping ? keyMapping[_] || _ : _} :{" "}
                                    </div>
                                    <div className={s.value}>
                                        {getValue(_, oldData)}
                                    </div>
                                </CollapsibleHeightBox>
                            );
                        }
                    })}
                </React.Fragment>
            );
        }

        if (type === "Added") {
            return (
                <React.Fragment key={getRandomId()}>
                    {Object.keys(newData).map(_ => {
                        if (
                            !ignoreList.includes(_) &&
                            getValue(_, newData) &&
                            getValue(_, newData) !== "null"
                        ) {
                            return (
                                <CollapsibleHeightBox
                                    customClass={`${s.item} dp-parent gap-10`}
                                    key={getRandomId()}
                                >
                                    <div className={s.key}>
                                        {keyMapping ? keyMapping[_] || _ : _} :{" "}
                                    </div>
                                    <div className={s.value}>
                                        {getValue(_, newData)}
                                    </div>
                                </CollapsibleHeightBox>
                            );
                        } else {
                            return null;
                        }
                    })}
                </React.Fragment>
            );
        }

        if (type === "Updated") {
            return (
                <React.Fragment key={getRandomId()}>
                    {Object.keys(oldData).map(_ => {
                        if (
                            !ignoreList.includes(_) &&
                            (newData[_] || oldData[_]) &&
                            !isEqual(
                                getObjData(newData[_]),
                                getObjData(oldData[_])
                            )
                        ) {
                            return (
                                <CollapsibleHeightBox
                                    customClass={`${s.diffWrap__update} ${s.updatedContent} dp-parent gap-10`}
                                    key={getRandomId()}
                                >
                                    <div
                                        className={`${s.oldData} dp-child-50 p-10`}
                                    >
                                        <span className={s.key}>
                                            {keyMapping
                                                ? keyMapping[_] || _
                                                : _}{" "}
                                            :{" "}
                                        </span>
                                        <span className={s.value}>
                                            {getValue(_, oldData)}
                                        </span>
                                    </div>

                                    <div className="dp-child-50 p-10">
                                        <span className={s.key}>
                                            {keyMapping
                                                ? keyMapping[_] || _
                                                : _}{" "}
                                            :{" "}
                                        </span>
                                        <span className={s.value}>
                                            {getValue(_, newData)}
                                        </span>
                                    </div>
                                </CollapsibleHeightBox>
                            );
                        }
                    })}
                </React.Fragment>
            );
        }
    };

    return printData();
};

const CollapsibleHeightBox = props => {
    const [expand, setExpand] = useState(false);
    const [restrictHeight, setRestrictHeight] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        if (ref?.current?.clientHeight > (props.height || 500))
            setRestrictHeight(true);
        return () => {
            setRestrictHeight(false);
        };
    }, []);
    return (
        <React.Fragment key={getRandomId()}>
            <div
                ref={ref}
                className={`${s.diffWrap} ${
                    props.customClass ? props.customClass : ""
                }  ${restrictHeight && !expand ? s.diffWrap__compact : ""}`}
            >
                {props.children}
            </div>
            {restrictHeight && (
                <Tooltip
                    message={expand ? "View Less" : "View More"}
                    position="top"
                >
                    <div
                        className={`${s.heightToggleBtn} ${
                            expand ? s.rotate : ""
                        } dp-parent dp-parent-ver-center dp-parent-hor-center`}
                        onClick={() => setExpand(!expand)}
                    >
                        <Icon icon="ArrowDown" size="10px" color="#74838F" />
                    </div>
                </Tooltip>
            )}
        </React.Fragment>
    );
};
