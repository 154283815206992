import { get } from "lodash";
import { getTargettingParams } from "./globalUtils";
import { getQuestionList, getSelectedMappedSegments } from "./surveyPathUtils";

export const convertSegments = item => {
    return {
        id: get(item, "id", ""),
        name: get(item, "name", ""),
        data: get(item, "data", ""),
        hasOffer: false,
        type: null,
        Ad: null,
        AdObject: {},
        Extras: [],
        questionConfig: [],
        questionsList: getQuestionList(item, true),
    };
};

export const getSurveyPathParams = (state, surveyId) => {
    let result = [];
    Object.keys(state).forEach(function (key) {
        const surveyPathId = state[key].basics.id;
        const obj = {
            name: state[key].basics.nameId,
            survey: { id: surveyId },
            segments: getSelectedMappedSegments(
                state[key].questionBank.itemsArr,
                state[key].questionBank.selected.filter(
                    id => !state[key].questionBank.deleted.includes(id)
                ),
                surveyPathId
            ),
            targetParams: getTargettingParams(
                state[key].targettingParam.targetParams,
                [],
                false,
                null,
                true,
                true
            ),
        };
        // obj.targetParams.spamCheck = {
        //     ...obj.targetParams.spamCheck,
        //     spamIP: obj.targetParams.spamCheck.spamIP.toString(),
        // };
        if (surveyPathId && !state[key].isNewTab) {
            obj["id"] = +surveyPathId;
        }
        result.push(obj);
    });
    return result;
};

export const getOfferPathParams = (
    offerPathState,
    surveyId,
    isEdit = false,
    isDelete = false
) => {
    let result = [];
    Object.keys(offerPathState).forEach(function (key) {
        const obj = {
            name: offerPathState[key].name
                ? offerPathState[key].name
                : offerPathState[key].basics.name,
            weight: offerPathState[key].basics.weight,
            maxSlotsToReturn:
                offerPathState[key].basics.maxSlotsToReturn === ""
                    ? -1
                    : offerPathState[key].basics.maxSlotsToReturn,
            isActive: offerPathState[key].basics.status,
            dedupeKeys: offerPathState[key].dedupeRules,
            enableAutoOfferPath: offerPathState[key].basics.enableAutoOfferPath,
            explorationWeight:
                offerPathState[key].basics.explorationWeight === ""
                    ? 0
                    : offerPathState[key].basics.explorationWeight,
            // sid: +surveyId,
            // offerPathAdSlots: getOfferPathAdSLots(
            //   offerPathState[key].slots.selectedListItems
            // ),
            targetParams: getTargettingParams(
                offerPathState[key].targettingParam.targetParams,
                surveyId ? [surveyId] : [],
                false,
                isDelete ? surveyId : null,
                true,
                true
            ),
        };
        // obj.targetParams.spamCheck = {
        //     ...obj.targetParams.spamCheck,
        //     spamIP: obj.targetParams.spamCheck.spamIP.toString(),
        // };
        if (offerPathState[key].isNewOfferPath) {
        } else if (!offerPathState[key].isNewOfferPath || isEdit) {
            obj["id"] = offerPathState[key].basics.name;
        }
        result.push(obj);
    });
    return result;
};
