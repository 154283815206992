const config = {
    BASE_URL: `https://${process.env.REACT_APP_BASE_URL}api.connexusdigital.com`,
    ROAS_CONFIG_URL: `https://roas-cpa-system-${process.env.REACT_APP_ROAS_CONFIG_URL}qvznpy2hwq-uc.a.run.app`,
    GIFTCARD_URL: `https://gift-urls-service-${process.env.REACT_APP_NEW_CONFIG_URL}qvznpy2hwq-ue.a.run.app`,
    GIFTCARD: `https://gift-card-service-${process.env.REACT_APP_NEW_CONFIG_URL}qvznpy2hwq-ue.a.run.app`,
    NEW_CONFIG_URL: `https://config-manager-${process.env.REACT_APP_NEW_CONFIG_URL}qvznpy2hwq-uc.a.run.app`,
    FEED_MANAGER_URL: `https://feed-manager-${process.env.REACT_APP_CONFIG_URL}qvznpy2hwq-ue.a.run.app`,
    REPORTING_URL: `https://reporting-service-${process.env.REACT_APP_CONFIG_URL}qvznpy2hwq-ue.a.run.app`,
    FIREBASE_URL: `https://opdash-prod-api.web.app/${process.env.REACT_APP_FIREBASE_URL}`,
    // FIREBASE_URL: `http://localhost:5001/consumerresearch-2/us-central1/appApiStaging/${process.env.REACT_APP_FIREBASE_URL}`,
    UAC_URL: `https://opdash-prod-api.web.app/${process.env.REACT_APP_FIREBASE_URL}`,
    DEAL_UPLOAD: `https://conversion-reporting-${process.env.REACT_APP_CONFIG_URL}qvznpy2hwq-ue.a.run.app`,
    SCRUB: `https://advertiser-rate-scrubing-${process.env.REACT_APP_CONFIG_URL}qvznpy2hwq-ue.a.run.app`,
    JT_Logo: `https://jt-logo-service-${process.env.REACT_APP_CONFIG_URL}qvznpy2hwq-ue.a.run.app`,
    FLOW_BASE_URL: `https://${process.env.REACT_APP_FLOW_URL}/`,
    FLOW_PROD_BASE_URL: `https://${process.env.REACT_APP_FLOW_PROD_URL}/`,
    BASE_URL_MAX: `https://api.campaigns.connexusdigital.com`,
    AUTOMATION_BASE_URL: `https://${process.env.REACT_APP_AUTOMATION_URL}`,
    CAMPAIGN_BASE_URL: `https://${process.env.REACT_APP_ADVERTISER_DASHBOARD_URL}`,
};

const {
    BASE_URL,
    BASE_URL_MAX,
    ROAS_CONFIG_URL,
    GIFTCARD_URL,
    GIFTCARD,
    NEW_CONFIG_URL,
    FEED_MANAGER_URL,
    REPORTING_URL,
    FIREBASE_URL,
    UAC_URL,
    DEAL_UPLOAD,
    SCRUB,
    JT_Logo,
    FLOW_BASE_URL,
    FLOW_PROD_BASE_URL,
    AUTOMATION_BASE_URL,
    CAMPAIGN_BASE_URL,
} = config;

export const HNP_URL = {
    GET_LEAD_DEFINITION_LIST: `${BASE_URL}/a/api/v6/hnpCreatives`,
    GET_LEAD_DEFINITION_DATA: `${BASE_URL}/a/api/v1/hnpCreative`,
    GET_OFFER_GROUPS_LIST: `${BASE_URL}/a/api/v6/hnp/og`,
    GET_CATEGORIES_LIST: `${BASE_URL}/a/api/v6/hnpCategories`,
    GET_CATEGORY_DATA: `${BASE_URL}/a/api/v1/hnpCategory`,
    GET_OFFER_GROUP: `${BASE_URL}/a/api/v5/hnp/og`,
    GET_OFFERS_HISTORY: `${BASE_URL}/changelog/v2/hnpOffer`,
    FETCH_HISTORY_LISTINGS: `${BASE_URL}/changelog/v2/hnpOfferGroup`,
    CREATE_OFFER_GROUP: `${BASE_URL}/a/api/v5/hnp/og`,
    UPDATE_OFFER_GROUP: `${BASE_URL}/a/api/v5/hnp/og`,
    DELETE_OFFER_GROUP: `${BASE_URL}/a/api/v5/hnp/og`,
    CREATE_OFFERS: `${BASE_URL}/a/api/v5/hnp/offer`,
    DELETE_OFFERS_ROW: `${BASE_URL}/a/api/v5/hnp/offer`,
    CREATE_HNP_CATEGORY: `${BASE_URL}/a/api/v1/hnpCategory`,
    UPDATE_HNP_CATEGORY: `${BASE_URL}/a/api/v1/hnpCategory`,
    DELETE_HNP_CATEGORY: `${BASE_URL}/a/api/v1/hnpCategory`,
    CREATE_HNP_CREATIVE: `${BASE_URL}/a/api/v1/advertiser/undefined/hnpCreative`,
    UPDATE_HNP_CREATIVE: `${BASE_URL}/a/api/v1/hnpCreative`,
    DELETE_HNP_CREATIVE: `${BASE_URL}/a/api/v1/hnpCreative`,
    FETCH_HISTORY_LISTINGS_LEAD_DEFINITION: `${BASE_URL}/changelog/v2/hnpCreative`,
    LEAD_TEST: `${BASE_URL}/a/api/v2/proxy`,
    MAX_PROXY: `${BASE_URL}/a/api/v2/max/proxy`,
    FETCH_SUPPRESSION_LIST: `${BASE_URL}/a/api/v1/suppression_list`,
    // New POST APIs for fetching and filtering data
    FETCH_LEAD_DEFINITION_LIST: `${BASE_URL}/a/api/v7/hnpCreatives`,
    FETCH_OFFER_GROUPS_LIST: `${BASE_URL}/a/api/v7/hnp/og`,
    FETCH_CATEGORIES_LIST: `${BASE_URL}/a/api/v7/hnpCategory`,
};

export const ROAS_CONFIG = {
    GET_ROAS_CONFIG_LIST: `${ROAS_CONFIG_URL}/api/v1/roasConfigs`,
    // DELETE_ROAS_CONFIG: `${ROAS_CONFIG_URL}/api/v1/roasConfig/publisher`,
    GET_ROAS_CONFIG_BY_PUB_ID: `${ROAS_CONFIG_URL}/api/v1/roasConfig/publisher`,
    UPDATE_ROAS_CONFIG: `${ROAS_CONFIG_URL}/api/v1/roasConfig/publisher`,
    // CREATE_ROAS_CONFIG: `${ROAS_CONFIG_URL}/api/v1/roasConfig`,
    // New POST APIs for fetching and filtering data
    // FETCH_ROAS_CONFIG_LIST: `${ROAS_CONFIG_URL}/api/v2/roasConfigs/filter`,

    // NEW apis after ps level is introduced
    GET_ROAS_CONFIG_BY_PUB_ID: `${ROAS_CONFIG_URL}/api/v2/roasConfig/publisher`,
    CREATE_UPDATE_ROAS_CONFIG: `${ROAS_CONFIG_URL}/api/v1/roasConfig`,
    FETCH_ROAS_CONFIG_LIST: `${ROAS_CONFIG_URL}/api/v3/roasConfigs/filter`,
    DELETE_ROAS_CONFIG: `${ROAS_CONFIG_URL}/api/v1/roasConfig/publisher`,
    FETCH_TMS_HISTORY: `${BASE_URL}/changelog/v2/roasConfig`,
};
export const CREATE_SURVEY = `${BASE_URL}/s/surveys`;
export const ADS = {
    GET_AD_CREATIVES: `${BASE_URL}/a/api/v1/adsCreatives`,
    UPDATE_AD_CREATIVES: `${BASE_URL}/a/api/v1/adsCreative`,
    FETCH_CREATIVE: `${BASE_URL}/a/api/v7/adsCreatives/filter`,
    DELETE_AD_CREATIVES: `${BASE_URL}/a/api/v1/adsCreative`,
    GET_SINGLE_AD_CREATIVE: `${BASE_URL}/a/api/v1/adsCreative`,
    CREATE_AD_CREATIVE: `${BASE_URL}/a/api/v1/advertiser/1/adsCreative`,
    GET_AD_UNIT_DATA: `${BASE_URL}/a/api/v3/au`,
    CREATE_AD_UNIT: `${BASE_URL}/a/api/v3/au`,
    UPDATE_AD_UNIT: `${BASE_URL}/a/api/v3/au`,
    DELETE_AD_UNIT: `${BASE_URL}/a/api/v3/au`,
    GET_AD_UNITS: `${BASE_URL}/a/api/v6/au`,
    //New POST APIs for fetching and filtering data
    FETCH_AD_UNITS: `${BASE_URL}/a/api/v7/au/filter`,
    // GET_ADS_HISTORY: `${BASE_URL}/changelog/v2/weightedAd`,
    GET_ADS_HISTORY: `${BASE_URL}/changelog/v2`,
    FETCH_HISTORY_LISTINGS: `${BASE_URL}/changelog/v2/offerPathAdSlot`,
};

export const SURVEYS = {
    GET_OFFER_PATH_LIST: `${BASE_URL}/a/api/v6/op`,
    GET_OFFER_PATH_BY_ID: `${BASE_URL}/a/api/v3/op`,
    UPDATE_OFFER_PATH: `${BASE_URL}/a/api/v3/op`,
    DELETE_OFFER_PATH: `${BASE_URL}/a/api/v3/op`,
    CREATE_OFFER_PATH: `${BASE_URL}/a/api/v3/op`,
    CLONE_OFFER_PATH: `${BASE_URL}/a/api/v7/op/clone`,
    GET_OFFER_KEYS: `${BASE_URL}/a/api/v1/offerKeys`,
    CREATE_AD_SLOT: `${BASE_URL}/a/api/v3/op/adslot`,
    DELETE_SLOT_AD: `${BASE_URL}/a/api/v3`,
    CREATE_AD_GROUP: `${BASE_URL}/a/api/v3`,
    GET_SEGMENTS: `${BASE_URL}/s/segments`,
    CREATE_SEGMENT: `${BASE_URL}/s/api/v2/segments`,
    UPDATE_SEGMENT: `${BASE_URL}/s/api/v2/segments`,
    DELETE_SEGMENT: `${BASE_URL}/s/api/v2/segments`,
    FETCH_HISTORY_LISTINGS_QUESTIONS: `${BASE_URL}/changelog/v2/segments`,
    GET_SURVEY_PATHS: `${BASE_URL}/s/api/v2/surveyPaths`,
    FETCH_HISTORY_LISTINGS_SURVEYPATH: `${BASE_URL}/changelog/v2/surveyPath`,
    GET_SURVEY_PATH_DETAILS: `${BASE_URL}/s/surveypaths`,
    UPDATE_SURVEY_PATH_DETAILS: `${BASE_URL}/s/surveypaths`,
    DELETE_SURVEY_PATH_DETAILS: `${BASE_URL}/s/surveypaths`,
    CREATE_SURVEY_PATH_DETAILS: `${BASE_URL}/s/surveypaths`,
    CLONE_SURVEY_PATH_DETAILS: `${BASE_URL}/s/surveypaths/clone`,
    DELETE_SURVEY: `${BASE_URL}/s/surveys`,
    FETCH_SURVEYS: `${BASE_URL}/s/surveys`,
    FETCH_HISTORY_LOG: `${BASE_URL}/changelog/v2/offerPathAdSlot`,
    FETCH_HISTORY_INNERTABLE_LOG: `${BASE_URL}/changelog/v2/weightedAd`,
    FETCH_HISTORY_LISTINGS_OFFERPATH: `${BASE_URL}/changelog/v2/offerPath`,
    ADUNIT_SLOT: `${BASE_URL}/a/api/v3`,
    // New POST APIs for fetching and filtering data
    FETCH_SURVEY_PATH: `${BASE_URL}/s/api/v2/surveypaths`,
    FETCH_OFFER_PATH: `${BASE_URL}/a/api/v7/op`,
    FETCH_SURVEYS_NEW: `${BASE_URL}/s/api/v2/surveys`,
    FETCH_HISTORY_LISTINGS_SURVEYS: `${BASE_URL}/changelog/v2/surveys`,
    FETCH_SEGMENTS: `${BASE_URL}/s/api/v3/segments`,
    UPDATE_TCPA: `${BASE_URL}/s/tcpa`,
};

export const CONFIGURATIONS = {
    FETCH_ABTEST_HISTORY: `${BASE_URL}/changelog/v2/ABTest`,
    FETCH_ABTEST_VARIANT_HISTORY: `${BASE_URL}/changelog/v2/Variant2`,
    GET_AB_TESTS: `${NEW_CONFIG_URL}/api/v1/abtests`,
    GET_AB_TEST: `${NEW_CONFIG_URL}/api/v1/abtest`,
    MODIFY_AB_TEST_VARIANT: `${NEW_CONFIG_URL}/api/v1/variant`,

    CLONE_AB_TEST_VARIANT: `${NEW_CONFIG_URL}/api/v2/variant/clone`,

    GET_AB_TEST_ID: `${NEW_CONFIG_URL}/api/v1/abtest`,
    GET_AB_TEST_ID_NEW: `${NEW_CONFIG_URL}/api/v2/abtestConfig`,
    GET_FEATUREMAP_LIST_DATA: `${NEW_CONFIG_URL}/api/v2/features/filter`,
    // CREATE_FEATURE_MAP: `${NEW_CONFIG_URL}/api/v2/feature`,
    CREATE_FEATURE_MAP: `${NEW_CONFIG_URL}/api/v2/featureWithImage`,
    GET_FEATURE_MAP_DATA: `${NEW_CONFIG_URL}/api/v2/feature`,
    // UPDATE_FEATURE_MAP: `${NEW_CONFIG_URL}/api/v2/feature`,
    UPDATE_FEATURE_MAP: `${NEW_CONFIG_URL}/api/v2/featureWithImage`,
    DELETE_FEATURE_MAP: `${NEW_CONFIG_URL}/api/v2/feature`,
    CREATE_ABTEST: `${NEW_CONFIG_URL}/api/v2/abtestConfig`,
    GET_DOMAINS: `${NEW_CONFIG_URL}/api/v1/domains`,
    GET_LANDERS: `${NEW_CONFIG_URL}/api/v1/landers`,
    GET_CONFIGS: `${NEW_CONFIG_URL}/api/v1/configs`,
    MODIFY_ABTEST: `${NEW_CONFIG_URL}/api/v2/abtestConfig`,
    MODIFY_AB_TEST_VARIANTS: `${FIREBASE_URL}/v1/variants`,
    DELETE_AB_TEST_VARIANTS: `${NEW_CONFIG_URL}/api/v1/variant`,
    MODIFY_SURVEY_FEATURE_SETS: `${FIREBASE_URL}/v1/feature-sets`,
    GET_SURVEY_CONFIGS_LIST: `${NEW_CONFIG_URL}/api/v1/configs`,
    FETCH_HISTORY_LISTINGS_SURVEYCONFIG: `${BASE_URL}/changelog/v2/Config2`,
    FETCH_HISTORY_LISTINGS_FEATURESET: `${BASE_URL}/changelog/v2/FeatureSet2`,
    FETCH_HISTORY_LISTINGS_FEATUREMAP: `${BASE_URL}/changelog/v2/Feature2`,
    DELETE_SURVEY_CONFIG: `${NEW_CONFIG_URL}/api/v1/config`,
    GET_SAVED_CONFIG: `${NEW_CONFIG_URL}/api/v1/config`,
    CREATE_SURVEY_CONFIG: `${NEW_CONFIG_URL}/api/v2/config`,
    GET_FEATURE_SETS: `${NEW_CONFIG_URL}/api/v2/feature-sets/filter`,
    DELETE_FEATURE_SET: `${NEW_CONFIG_URL}/api/v1/feature-sets`,
    // New POST APIs for fetching and filtering data
    FETCH_AB_TESTS: `${NEW_CONFIG_URL}/api/v2/abtests`,
    FETCH_FEATURE_SETS: `${NEW_CONFIG_URL}/api/v2/feature-sets/filter`,
    // Api for individual Feature set page
    CREATE_NEW_FEATURE_SET: `${NEW_CONFIG_URL}/api/v1/feature-sets`,
    UPDATE_FEATURE_SET_BY_ID: `${NEW_CONFIG_URL}/api/v1/feature-sets`,
    UPDATE_FEATURE_SET_STATUS: `${NEW_CONFIG_URL}/api/v1/feature-sets`,
    FETCH_FEATURE_SET_BY_ID: `${NEW_CONFIG_URL}/api/v1/feature-sets`,
    FETCH_AB_TESTS: `${NEW_CONFIG_URL}/api/v2/abtests/filter`,
    FETCH_SURVEY_CONFIG: `${NEW_CONFIG_URL}/api/v2/configs/filter`,
    FETCH_FEATURE_MAP: `${NEW_CONFIG_URL}/api/v2/features/filter`,
};

export const GIFTCARDS = {
    GET_GIFTCARDURL_LIST: `${GIFTCARD_URL}/api/dowURL/v1/`,
    GET_GIFTCARDS_LIST: `${GIFTCARD}/api/v1/giftCards`,
    CREATE_GIFT_CARD_URL: `${GIFTCARD_URL}/api/dowURL/v1/`,
    DELETE_GIFT_CARD_URL: `${GIFTCARD_URL}/api/dowURL/v1/`,
    // New POST APIs for fetching and filtering data
    FETCH_URLGENERATOR_LIST: `${GIFTCARD_URL}/api/dowURL/v1/filter`,
};

export const FEEDMANGER = {
    GET_ALL_ADVERTISER_SOURCES: `${FEED_MANAGER_URL}/api/v1/advertiser_sources`,
    GET_BID_CATEGORIES_OPTIONS: `https://job-categorizer-prod-qvznpy2hwq-ue.a.run.app/jobCategories`,
    GET_ALL_PUBLISHER_SOURCES: `${FEED_MANAGER_URL}/api/v1/publisher_feeds`,
    DELETE_PUBLISHER_FEED: `${FEED_MANAGER_URL}/api/v1/publisher_feed`,
    GET_PUBLISHER_FEED: `${FEED_MANAGER_URL}/api/v1/publisher_feed`,
    CREATE_PUBLISHER_FEED: `${FEED_MANAGER_URL}/api/v1/publisher_feed`,
    UPDATE_PUBLISHER_FEED: `${FEED_MANAGER_URL}/api/v1/publisher_feed`,
    // New POST APIs for fetching and filtering data
    FETCH_FEED_MANAGER_DATA: `${FEED_MANAGER_URL}/api/v2/publisher_feeds`,
};

export const REPORTING = {
    FETCH_REPORTING_METRICS: `${REPORTING_URL}/api/v1/advertiser-metrics`,
    FETCH_ALL_ADVERTISERS: `${REPORTING_URL}/api/v1/advertisers`,
    VALIDATE_METRICS: `${REPORTING_URL}/api/v2/validate-metrics/source1/advertiser/source2/dedup`,
};

export const DEALS = {
    FETCH_GIFT_CARDS_LIST: `${GIFTCARD}/api/v2/giftCards/filter`,
    DELETE_GIFT_CARD: `${GIFTCARD}/api/v1/giftCard`,
    FETCH_OFFERWALL_LIST: `${BASE_URL}/a/api/v3/ow`,
    FETCH_DEALCATEGORY_LIST: `${GIFTCARD}/api/v1/dealCategory`,
    FETCH_GIFT_CARD: `${GIFTCARD}/api/v1/giftCard`,
    CREATE_GIFT_CARD: `${GIFTCARD}/api/v1/giftCard`,
    UPDATE_GIFT_CARD: `${GIFTCARD}/api/v1/giftCard`,
    FETCH_DEAL_CATEGORIES_LIST: `${GIFTCARD}/api/v2/dealCategory/filter`,
    DELETE_DEAL_CATEGORY: `${GIFTCARD}/api/v1/dealCategory`,
    CREATE_DEAL_CATEGORY: `${GIFTCARD}/api/v1/dealCategory`,
    UPDATE_DEAL_CATEGORY: `${GIFTCARD}/api/v1/dealCategory`,
    FETCH_DEAL_CATEGORY: `${GIFTCARD}/api/v1/dealCategory`,
    FETCH_OFFER_WALL: `${BASE_URL}/a/api/v7/ow`,
    GET_OFFER_WALL_BY_ID: `${BASE_URL}/a/api/v3/ow`,
    CREATE_OFFER_WALL: `${BASE_URL}/a/api/v3/ow`,
    DELETE_OFFER_WALL: `${BASE_URL}/a/api/v3/ow`,
    UPDATE_OFFER_WALL: `${BASE_URL}/a/api/v3/ow`,
    CREATE_AD_SLOT: `${BASE_URL}/a/api/v3/ow/adslot`,
};

export const UAC = {
    FETCH_USERS_LIST: `${UAC_URL}/v1/users`,
    UPDATE_USER_LIST_BY_EMAIL: `${UAC_URL}/v1/users`,
    FETCH_USER_GROUPS_LIST: `${UAC_URL}/v1/usergroups`,
    UPDATE_USER_GROUPS_LIST_BY_ID: `${UAC_URL}/v1/usergroups`,
    CREATE_USER_GROUP: `${UAC_URL}/v1/usergroup/create`,
    MENU: `${UAC_URL}/v1/navigation-menu`,
};
export const OFFLINE_CONVERSION = {
    FETCH_OFFLINE_CONVERSION_DATA: `${DEAL_UPLOAD}/api/v1/attribution_stats/filter`,
    UPLOAD_OFFLINE_CONVERSION_DATA: `${DEAL_UPLOAD}/api/v1/report_ad_conversions`,
    GET_CSV_DOWNLOAD_DATA: `${DEAL_UPLOAD}/api/v1/attribution_stats`,
    FETCH_ALL_ADVERTISERS: `${BASE_URL}/a/api/v5/max/advertiser/filter`,
    FETCH_CAMPAIGN: `https://api.campaigns.connexusdigital.com/search/v1?entity=campaigns&advertiserId`,
};

export const SCRUB_DASHBOARD = {
    FETCH_ADVERTISER_SCRUB_DASHBOARD_DATA: `${SCRUB}/adscrubrates/filter`,
    FETCH_PUBLISHER_SCRUB_DASHBOARD_DATA: `${SCRUB}/costscrubrates/filter`,
    POST_ADVERTISER_SCRUB_DASHBOARD_DATA: `${SCRUB}/adscrubrates`,
    POST_PUBLISHER_SCRUB_DASHBOARD_DATA: `${SCRUB}/costscrubrates`,
    CREATE_PUBLISHER: `${SCRUB}/publisher`,
    CHECK_PUBLISHER: `${SCRUB}/publisher`,
    FETCH_PUBLISHERS: `${SCRUB}/publishers/filter`,
    GET_CAMPAIGN_FOR_PUBLISHER: `${SCRUB}/publisher`,
    CHANGE_LOG_FILTER: `${BASE_URL}/changelog/v2/filters`,
    GET_ADVERTISER_DATA_STUDIO_REVENUE: `${SCRUB}/AdvertiserRevenue`,
    GET_PUBLISHER_DATA_STUDIO_COST: `${SCRUB}/PublisherCost`,
    FIRE_REVENUE_DUMMY_EVENT: `${SCRUB}/DummyEvent/revenue`,
    FIRE_COST_DUMMY_EVENT: `${SCRUB}/DummyEvent/cost`,
    BULK_UPLOAD: `${SCRUB}/scrub/bulk/upload_csv`,
    PROCESS_BULK_UPLOAD: `${SCRUB}/scrub/bulk/apply`,
    FETCH_ALL_CAMPAIGNS: `${BASE_URL}/a/api/v5/max/campaign/filter`,
    FETCH_PUBLISHER_ALL_CAMPAIGNS: `${SCRUB}/publishers/campaigns`,
};

export const USER_JOURNEY = {
    GET_USER_JOURNEY_LIST: `${GIFTCARD_URL}/api/dowURL/v1/`,
    GET_USER_JOURNEYS_LIST: `${GIFTCARD}/api/v1/giftCards`,
    CREATE_USER_JOURNEY_URL: `${GIFTCARD_URL}/api/dowURL/v1/`,
    FETCH_USER_JOURNEY_LIST: `${BASE_URL}/u/api/v1/user_journey/users`,
    FETCH_USER_JOURNEY_DETAIL: `${BASE_URL}/u/api/v1/user_journey/journey`,
    FETCH_DOMAIN_LIST: `${BASE_URL}/u/api/v2/user_journey/domains`,
    GET_USER_STATUS: `${BASE_URL}/u/api/v1/user_journey/status`,
    GIFT_CARDS_FOR_USER: `${BASE_URL}/u/api/v1/user_journey/gc_fulfilment`,
    APPLY_CONVERSION: `${BASE_URL}/u/api/v1/user_journey/manual_conversion/trigger`,
    CHECK_CONVERSION: `${BASE_URL}/u/api/v1/user_journey/manual_conversion/check`,
    FETCH_SURVEY: `${BASE_URL}/u/api/v1/user_journey/surveys`,
};

export const RELEASENOTES = {
    GET_RELEASE_NOTES: `https://api.github.com/repos/intent-labs/ops-dashboard-v2/releases`,
    // GET_RELEASE_NOTES: `https://api.github.com/repos/Jayvora/scrolling-list/releases`, //Dummy data to test
};

export const JTLOGO = {
    FETCH_JTLOGO_LIST: `${JT_Logo}/api/v1/logos`,
    UPLOAD_IMAGE: `${JT_Logo}/api/v1/logo`,
    CHECK_IMAGE_AVAILABLE: `${JT_Logo}/api/v1/logo/available`,
    FETCH_JT_CONFIGS: `${JT_Logo}/api/v1/jtConfigs`,
    FETCH_JT_CONFIG: `${JT_Logo}/api/v1/jtConfig`,
    UPDATE_JT_CONFIG: `${JT_Logo}/api/v1/jtConfig`,
};

export const fetchSpamIpDropdownUrl = {
    spamIpDropdownUrl: `https://ip-${process.env.REACT_APP_NEW_CONFIG_URL}qvznpy2hwq-ue.a.run.app/api/v1/categories`,
};

export const COLLECTION = {
    FETCH_COLLECTION_LIST: `${FLOW_BASE_URL}api/collection/filter`,
    FETCH_ALL_COLLECTION_LIST: `${FLOW_BASE_URL}api/collection/all`,
    CREATE_COLLECTION: `${FLOW_BASE_URL}api/collection/save`,
    CREATE_COLLECTION_WITH_TCPA: `${FLOW_BASE_URL}api/collection/v1/save`,
    GET_COLLECTION: `${FLOW_BASE_URL}api/collection`,
    DELETE_COLLECTION: `${FLOW_BASE_URL}api/collection/delete`,
    PHONE_PARTNERS: `${FLOW_BASE_URL}api/proxy/phone_partners`,
    EMAIL_PARTNERS: `${FLOW_BASE_URL}api/proxy/email_partners`,
    FETCH_ALL_HNP_CATEGORIES: `${FLOW_BASE_URL}api/hnp/all`,
    FETCH_COLLECTION_HISTORY: `${BASE_URL}/changelog/v2/FLOW_COLLECTION`,
    CLONE: `${FLOW_BASE_URL}api/collection/clone`,
};

export const CONTAINERS = {
    FETCH_CONTAINERS_LIST: `${FLOW_BASE_URL}api/container/filter/v1`,
    CREATE_CONTAINER: `${FLOW_BASE_URL}api/container/save`,
    FETCH_CONTAINER: `${FLOW_BASE_URL}api/container/filter`,
    DELETE_CONTAINER: `${FLOW_BASE_URL}api/container/delete`,
    CLONE: `${FLOW_BASE_URL}api/container/clone`,
    FETCH_CONTAINER_HISTORY: `${BASE_URL}/changelog/v2/FLOW_CONTAINER`,
    FETCH_CONTAINERS_LIST_WITH_DATA: `${FLOW_BASE_URL}api/container/filter`,
    FETCH_DEFAULT_LAYOUT: `${FLOW_BASE_URL}api/layout/default`,
};

export const FLOW_CAMPAIGN = {
    FETCH_CAMPAIGN_LIST: `${FLOW_BASE_URL}api/publish/filter`,
    CREATE_CAMPAIGN: `${FLOW_BASE_URL}api/publish/save`,
    FETCH_CAMPAIGN: `${FLOW_BASE_URL}api/publish`,
    DELETE_CAMPAIGN: `${FLOW_BASE_URL}api/publish/delete`,
};

export const CAMPAIGNS = {
    FETCH_CAMPAIGNS: `${BASE_URL}/a/api/v5/max/campaign/filter`,
    GET_CAMPAIGN_FROM_MAX: `${BASE_URL_MAX}/api/campaignservice/v1.1/campaigns`,

    GET_CAMPAIGN_BY_ID: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/campaign`,
    FETCH_MACROS: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/source_traffic`,
    CREATE_CAMPAIGN: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/create_campaign`,
    FETCH_PUBLISHERS: `${CAMPAIGN_BASE_URL}/a/api/v1/static_publisher/groupby?vertical=incent`,
    UPDATE_CAMPAIGN: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/campaign`,
    GET_ALL_CAMPAIGN: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/campaign/getAll`,
    APPROVE_CAMPAIGN: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/activate_campaign`,
    GOLIVE_CAMPAIGN: `${CAMPAIGN_BASE_URL}/a/api/v2/placements/offer`,
    MAPPING_CAMPAIGN_COMPLETED: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/mapping_completed`,
};

export const TEMPLATE = {
    FETCH_TEMPLATE_LIST: `${FLOW_BASE_URL}api/template/filter`,
    FETCH_ALL_TEMPLATE_LIST: `${FLOW_BASE_URL}api/template/all`,
    CREATE_TEMPLATE: `${FLOW_BASE_URL}api/template/save`,
    UPDATE_TEMPLATE: `${FLOW_BASE_URL}api/template/save`,
    FETCH_TEMPLATE: `${FLOW_BASE_URL}api/template`,
    DELETE_TEMPLATE: `${FLOW_BASE_URL}api/template/delete`,
    FETCH_TEMPLATE_HISTORY: `${BASE_URL}/changelog/v2/FLOW_TEMPLATE`,
    CLONE: `${FLOW_BASE_URL}api/template/clone`,
    SAVE_KEYWORD: `${FLOW_BASE_URL}api/proxy/static_listings`,
    FETCH_KEYWORD: `${FLOW_BASE_URL}api/keyword_map`,
};

export const LAYOUT = {
    FETCH_ALL_LAYOUTS: `${FLOW_BASE_URL}api/layout/all`,
    FETCH_LAYOUTS_POST: `${FLOW_BASE_URL}api/layout/filter`,
    CREATE_LAYOUT: `${FLOW_BASE_URL}api/layout/save`,
    GET_LAYOUT_BY_ID: `${FLOW_BASE_URL}api/layout/byId`,
    FETCH_LAYOUT_HISTORY: `${BASE_URL}/changelog/v2/FLOW_LAYOUT`,
};

export const MAIN = {
    GET_ALL_QUESTIONS: `${FLOW_BASE_URL}api/question/filter`,
    GET_QUESTION: `${FLOW_BASE_URL}api/question`,
    SAVE_QUESTION: `${FLOW_BASE_URL}api/question/save`,
    DELETE_QUESTION: `${FLOW_BASE_URL}api/question/delete`,
    FETCH_QUESTION_HISTORY: `${BASE_URL}/changelog/v2/FLOW_QUESTION`,

    GET_ALL_FLOWS: `${FLOW_BASE_URL}api/flow/all_with_rules`,
    GET_ALL_FLOWS_POST: `${FLOW_BASE_URL}api/flow/filter`,
    GET_ALL_FLOWSDETAILS: `${FLOW_BASE_URL}api/flow/all`,
    PROD_GET_ALL_FLOWSDETAILS: `${FLOW_PROD_BASE_URL}api/flow/all`,
    // GET_FLOW: `${FLOW_BASE_URL}api/flow/rules`,
    GET_FLOW: `${FLOW_BASE_URL}api/flow/v2/rules`, //rules with meta data
    SAVE_FLOW: `${FLOW_BASE_URL}api/flow/save`,
    SAVE_NODE: `${FLOW_BASE_URL}api/router/save`,
    SAVE_NODES: `${FLOW_BASE_URL}api/router_with_meta/save/all`, //save nodes with meta, accepts array
    SAVE_META: `${FLOW_BASE_URL}api/route_meta/save`,
    GET_NODE_META: `${FLOW_BASE_URL}api/route_meta/get?ruleSetIds=`,
    GET_SPLIT_META: `${FLOW_BASE_URL}api/route_meta/get?ruleIds=`,
    CONTAINER_PREVIEW: `${FLOW_BASE_URL}/item`,
    GET_ALL_TARGETINGS: `${FLOW_BASE_URL}api/targeting-option/all`,
    SAVE_ALL: `${FLOW_BASE_URL}api/flow/v2/save`, //save nodes with meta (accepts array) and flow details
    GET_FLOW_HISTORY: `${BASE_URL}/changelog/v2/FLOW`,
    DEPENDENCY: `${FLOW_BASE_URL}api/dependency/get?sourceComponentType=SITE`,
    PROD_DEPENDENCY: `${FLOW_PROD_BASE_URL}api/dependency/get?sourceComponentType=SITE`,
    FLOW_FILTERS: `${FLOW_BASE_URL}api/router/v1/flow/emulate?rule_set_id=`,
    FETCH_FLOW_HISTORY: `${BASE_URL}/changelog/v2/FLOW`,
    PUBLISH: `${FLOW_BASE_URL}api/publish/payload/`,
    PUBLISH_SITECONFIG: `${FLOW_BASE_URL}api/publish/site_config_payload/`,
    DEPLOY: `${FLOW_PROD_BASE_URL}api/publish/deploy`,
    DEPLOY_SITECONFIG: `${FLOW_PROD_BASE_URL}api/publish/site_config/deploy`,
    GET_PUBLISH_DIFF: `${FLOW_PROD_BASE_URL}api/publish/diff/`,
    GET_STATS: `${FLOW_BASE_URL}api/proxy/analytics/query`,
    GET_PROD_FLOW: `${FLOW_PROD_BASE_URL}api/flow/v2/rules`,
    GET_PUBLISH_HISTORY: `${FLOW_BASE_URL}api/publish/hist`,
};

export const STATUS = {
    getStatus: `https://reporting-service-qvznpy2hwq-ue.a.run.app/api/v1/process-log/filter`,
};

export const FLOWCATEGORIES = {
    GET_ALL_FLOWCATEGORIES: `${FLOW_BASE_URL}api/hnp/filter`,
    GET_FLOWCATEGORIES_BYID: `${FLOW_BASE_URL}api/hnp/rules`,
    CREATE_FLOWCATEGORIES: `${FLOW_BASE_URL}api/hnp/save`,
    UPDATE_FLOWCATEGORIES: `${FLOW_BASE_URL}api/hnp/save`,
    DELETE_FLOWCATEGORIES: `${FLOW_BASE_URL}api/hnp/delete`,
    FETCH_CATEGORY_HISTORY: `${BASE_URL}/changelog/v2/FLOW_HNP_LEAD`,
    FETCH_MAPPED_OFFERGROUPS: `${BASE_URL}/a/api/v5/hnp/og/cat`,
};

export const MAX_ENDPOINT = {
    GET_DEAL_ID: `${BASE_URL_MAX}/api/campaignservice/v1.1/advertisers`,
};

export const ADVERTISER = {
    GET_ALL_ADVERTISERS: `${BASE_URL}/a/api/v5/max/advertiser/filter`,
    ADVERTISER_BY_ID: `${BASE_URL}/a/api/v5/max/advertiser`,
    DELETE_PREPING: `${BASE_URL}/a/api/v1/requestDef`,
    SYNC_ADVERTISERS: `${BASE_URL}/a/api/v5/max/update`,
    FETCH_ADVERTISER_HISTORY: `${BASE_URL}/changelog/v2/MaxAdvertiser`,
    GET_ADVERTISER_INFO: `${AUTOMATION_BASE_URL}/api/v2/automation/advertisers/info`,
    ADVERTISER_AUTOMATION: `${AUTOMATION_BASE_URL}/api/v1/attribution-config`,
    VERIFY_AUTOMATION_TYPE: `${AUTOMATION_BASE_URL}/api/v2/get-reporting-data/source`,
    CREATE_ADVERTISER: `${CAMPAIGN_BASE_URL}/a/api/v1/adv_integration/create_adv`,
};

export const PREVIEW_LINK_URL = {
    previewLink: `${FLOW_BASE_URL}api/preview-config/create`,
};

export const SITE_CONFIG = {
    GET_ALL_CONFIGS: `${FLOW_BASE_URL}api/site_config/filter`,
    GET_CONFIG: `${FLOW_BASE_URL}api/site_config`,
    SAVE_CONFIG: `${FLOW_BASE_URL}api/site_config/save`,
    FETCH_SITECONFIG_HISTORY: `${BASE_URL}/changelog/v2/FLOW_SITE_CONFIG`,
};
