import s from "./TextRenderer.module.scss";
import Tooltip from "../../../Shared/Tooltip/Tooltip";

const TextRenderer = ({
    content,
    maxWidth,
    variant = false,
    position = "top",
    children,
    hideTooltip = false,
}) => {
    return (
        <div>
            <Tooltip
                message={content}
                position={position}
                wrap={true}
                variant={variant}
                hideTooltip={hideTooltip}
            >
                <p className={s.showEllipsis} style={{ maxWidth: maxWidth }}>
                    {children || content}
                </p>
            </Tooltip>
        </div>
    );
};

export default TextRenderer;
