import { borderRight } from "@mui/system";
import _ from "lodash";
import {
    CONFIGURATIONS,
    SURVEYS,
    DEALS,
    COLLECTION,
    TEMPLATE,
    MAIN,
} from "../constants/url";
import { decodeValue } from "./globalUtils";

const getTrimmedData = obj => {
    if (obj && typeof obj === "object") {
        Object.keys(obj).map(key => {
            if (typeof obj[key] === "object") {
                getTrimmedData(obj[key]);
            } else if (typeof obj[key] === "string") {
                obj[key] = obj[key].trim();
            }
        });
    }
    return obj;
};

export const manipulateData = (apiUrl, data, method, isResponse = false) => {
    let manipulatedData =
        data instanceof FormData
            ? getTrimmedData(data)
            : getTrimmedData(JSON.parse(JSON.stringify(data)));

    try {
        if (!isResponse) {
            if (
                (apiUrl === CONFIGURATIONS.MODIFY_AB_TEST_VARIANTS &&
                    (method === "PUT" || method === "POST")) ||
                ((apiUrl.includes(CONFIGURATIONS.CREATE_NEW_FEATURE_SET) ||
                    apiUrl.includes(CONFIGURATIONS.UPDATE_FEATURE_SET_BY_ID)) &&
                    (method === "PUT" || method === "POST"))
            ) {
                if (Array.isArray(manipulatedData)) {
                    manipulatedData.forEach(element => {
                        element.targeting.keyVals =
                            element.targeting.keyVals?.map(string => {
                                return {
                                    key: string.split(":")[0],
                                    value: string.split(":")[1],
                                };
                            });
                        element.exclusionTargeting.keyVals =
                            element.exclusionTargeting.keyVals?.map(string => {
                                return {
                                    key: string.split(":")[0],
                                    value: string.split(":")[1],
                                };
                            });
                    });
                } else {
                    if (
                        manipulatedData &&
                        manipulatedData.targeting &&
                        manipulatedData.targeting.keyVals
                    ) {
                        manipulatedData.targeting.keyVals =
                            manipulatedData.targeting.keyVals?.map(string => {
                                return {
                                    key: string.split(":")[0],
                                    value: string.split(":")[1],
                                };
                            });
                    }
                    if (
                        manipulatedData &&
                        manipulatedData.exclusionTargeting &&
                        manipulatedData.exclusionTargeting.keyVals
                    ) {
                        manipulatedData.exclusionTargeting.keyVals =
                            manipulatedData.exclusionTargeting.keyVals?.map(
                                string => {
                                    return {
                                        key: string.split(":")[0],
                                        value: string.split(":")[1],
                                    };
                                }
                            );
                    }
                }
            }

            if (
                (apiUrl.includes(SURVEYS.GET_OFFER_PATH_BY_ID) &&
                    (method === "PUT" || method === "POST")) ||
                (apiUrl.includes(SURVEYS.UPDATE_SURVEY_PATH_DETAILS) &&
                    (method === "PUT" || method === "POST"))
            ) {
                data.targetParams["operatingSystems"] = data.targetParams["os"];
                delete data.targetParams["os"];
                manipulatedData = data;
            }

            if (
                (method === "POST" &&
                    apiUrl.includes(DEALS.CREATE_OFFER_WALL)) ||
                (method === "PUT" && apiUrl.includes(DEALS.UPDATE_OFFER_WALL))
            ) {
                data.targetParams["age"] = data.targetParams["ageRange"];
                delete data.targetParams["ageRange"];
                data.targetParams["gender"] = data.targetParams["genders"];
                delete data.targetParams["genders"];
                data.targetParams["country"] = data.targetParams["countries"];
                delete data.targetParams["countries"];
                manipulatedData = data;
            }
        } else {
            // Sorting on index property for questions in survey path
            if (
                method === "GET" &&
                apiUrl.includes(DEALS.GET_OFFER_WALL_BY_ID)
            ) {
                data.data.data.targetParams["ageRange"] =
                    data.data.data.targetParams["age"];
                data.data.data.targetParams["genders"] =
                    data.data.data.targetParams["gender"];
                data.data.data.targetParams["countries"] =
                    data.data.data.targetParams["country"];
                manipulatedData = data;
            }

            if (
                method === "POST" &&
                apiUrl.indexOf(`${SURVEYS.FETCH_SURVEY_PATH}?surveyId=`) !== -1
            ) {
                manipulatedData?.data?.data?.forEach(element => {
                    element?.segments.sort((a, b) => a.index - b.index);
                });
            } else if (
                method === "GET" &&
                apiUrl.indexOf(`${SURVEYS.GET_SURVEY_PATH_DETAILS}/`) !== -1
            ) {
                manipulatedData?.data?.data?.segments.sort(
                    (a, b) => a.index - b.index
                );
            }

            //Showing survey names with their id
            if (
                method === "POST" &&
                apiUrl === SURVEYS.FETCH_SURVEYS_NEW &&
                window.location.href?.includes("abtests")
            ) {
                manipulatedData = manipulatedData?.data?.data?.map(survey => ({
                    id: survey.id,
                    name: `${survey.id}: ${survey.name}`,
                }));
            }

            // Skip feature sets without ids
            if (
                method === "POST" &&
                apiUrl.indexOf(CONFIGURATIONS.GET_FEATURE_SETS) !== -1 &&
                manipulatedData?.data?.data?.length
            ) {
                manipulatedData.data.data = manipulatedData.data.data.filter(
                    _ => _.id
                );
            }

            if (
                (method === "GET" &&
                    apiUrl.includes(SURVEYS.GET_OFFER_PATH_BY_ID)) ||
                (method === "GET" &&
                    apiUrl.includes(SURVEYS.GET_SURVEY_PATH_DETAILS))
            ) {
                data.data.data.targetParams["os"] =
                    data.data.data.targetParams["operatingSystems"];
                delete data.data.data.targetParams["operatingSystems"];
                manipulatedData = data;
            }

            if (
                (method === "POST" &&
                    apiUrl.includes(SURVEYS.FETCH_SURVEY_PATH)) ||
                (method === "POST" &&
                    apiUrl.includes(SURVEYS.FETCH_OFFER_PATH) &&
                    window.location.href?.includes("surveys"))
            ) {
                data.data.data.map(item => {
                    item.targetParams["os"] =
                        item.targetParams["operatingSystems"];
                    delete item.targetParams["operatingSystems"];
                    return item;
                });
                manipulatedData = data;
            }

            // Sid coming inside of targeting params for offer paths
            if (
                method === "POST" &&
                apiUrl.indexOf(SURVEYS.FETCH_OFFER_PATH) !== -1 &&
                manipulatedData?.data?.data?.length
            ) {
                manipulatedData.data.data = manipulatedData.data.data.map(_ => {
                    const surveyIdkeyValString =
                        _.targetParams?.keyVals?.find(
                            item => item.indexOf("surveyId") !== -1
                        ) || "";
                    const surveyIdVals = surveyIdkeyValString?.split(":");
                    const decodedValue =
                        surveyIdVals[1] && decodeValue(surveyIdVals[1]).trim();
                    const surveyIdsWithComma = decodedValue
                        ?.replace("  ", " ")
                        .split(" ");
                    return {
                        ..._,
                        sid: surveyIdsWithComma || _.sid,
                    };
                });
            }

            //history utils expect data to be inside data object
            if (
                method === "GET" &&
                (apiUrl.includes(COLLECTION.FETCH_COLLECTION_HISTORY) ||
                    apiUrl.includes(TEMPLATE.FETCH_TEMPLATE_HISTORY) ||
                    apiUrl.includes(MAIN.FETCH_QUESTION_HISTORY))
            ) {
                manipulatedData = { data: data };
            }
        }
    } catch (error) {
        // console.log(error);
    } finally {
        return manipulatedData;
    }
};

export const manipulateKeyVals = (data, toEncode = false) => {
    let encodedData = data;
    if (Array.isArray(encodedData)) {
        encodedData = encodedData?.map(string => {
            const keyValues = string?.toString()?.split(":");
            try {
                if (keyValues[0] === "surveyId") {
                    if (keyValues[1]?.includes(",")) {
                        let commaRemovedValue = keyValues[1].replaceAll(
                            ",",
                            " "
                        );
                        return `${keyValues[0]}:${commaRemovedValue}`;
                    } else {
                        return `${keyValues[0]}:${keyValues[1]}`;
                    }
                } else {
                    if (toEncode) {
                        let text = "";
                        keyValues[1].split(",").forEach((item, idx) => {
                            item = item.replace(/,/g, "").trim();
                            try {
                                if (idx == 0) text += encodeURI(item);
                                else text += " " + encodeURI(item);
                            } catch {
                                if (idx == 0) text += item;
                                else text += " " + item;
                            }
                        });

                        return `${keyValues[0]}:${text}`;
                    } else {
                        return `${keyValues[0]}:${encodeURI(keyValues[1])}`;
                    }
                }
            } catch (err) {
                return `${keyValues[0]}:${keyValues[1]}`;
            }
        });
    }
    return encodedData;
};
