import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../Shared/ToggleSwitch/ToggleSwitch";
import Select from "../../Shared/Select/Select";
import Input from "../../Shared/Input/Input";
import { get } from "lodash";
import Button, { ButtonTypes } from "../../Shared/Button/Button";
import Popup from "../../Shared/Popup/Popup";
import { useDispatch, useSelector } from "react-redux";
import AddDedupeRule from "../AddDedupeRule/AddDedupeRule";
import { OfferpathEntityMacros } from "../../constants/dedupeConstants";
import { toastListActions } from "../../store/toastList/toastList";

const OfferPathBasics = ({
    id,
    name,
    weight,
    dedupeRules = [],
    surveys = [],
    selectedSurveyIds = [],
    selectedSurveyLabel,
    offerLimitValue,
    status,
    onChangeStatus,
    onChangeWeight,
    onChangeName,
    onSelectSurvey,
    onChangeOfferLimit,
    type,
    handleOfferPath,
    list = [],
    highlightNameError,
    highlightWeightError,
    allRefs,
    createOption,
    isNewTab,
    onCreateNewOfferPath,
    setDedupe,
    readOnly = false,
    isSidePanel = false,
    onChangeAutoOfferPath,
    onChangeExploration,
    autoOfferPath,
    explorationValue,
    highlightExplorationWeightError = false,
    highlightMaxSlotLimittError = false,
}) => {
    const dispatch = useDispatch();
    const [openAddRuleSideBar, setOpenAddRuleSideBar] = useState(false);

    const modules = useSelector(state => state.auth.user.modules);

    const autoOfferPathReadOnly = !(
        modules.indexOf(`SurveyManager.OfferPath.AutoOfferPath.Write`) > -1
    );

    const handleCreateNewItem = value => {
        onCreateNewOfferPath(true);
        onChangeWeight("");
        onChangeName(get(value, "name", ""));
    };

    useEffect(() => {
        if (!autoOfferPath) onChangeExploration("0");
    }, [autoOfferPath]);

    return (
        <>
            <div
                id="offerPathBasics"
                data-hash-id="offerPathBasics"
                className={`flexHeightTab`}
            >
                <div className={`row`}>
                    <p className={`headerTitle`}>Basics</p>
                    {id && !selectedSurveyLabel && (
                        <div className={`row`}>
                            <p className={`headerTitleRequired`}>ID</p>
                            <p className={`infoText`}>
                                ID associated with Offer Path
                            </p>
                            <p className={`headerTitleRequired`}>{id}</p>
                        </div>
                    )}
                    <p className={`headerTitleRequired`}>Name*</p>
                    <p className={`infoText`}>
                        They are linked to multiple surveys.{" "}
                        {/* <a href="#" className="">
            Learn more
          </a> */}
                    </p>
                    {type == "select" && (
                        <div className={`dp-parent`}>
                            <div className={`dropdownLayer`}>
                                <Select
                                    label={
                                        selectedSurveyLabel ||
                                        "Select or create offer path"
                                    }
                                    options={list}
                                    keyField="name"
                                    showSearch={true}
                                    placeholder={"Search Offer Paths"}
                                    onSelect={handleOfferPath}
                                    onLoadSelectReturn={false}
                                    showCreate={true}
                                    selectedId={
                                        selectedSurveyLabel ? null : name
                                    }
                                    idField="id"
                                    onCreate={handleCreateNewItem}
                                    createOption={createOption}
                                    isError={highlightNameError}
                                    isDisabled={readOnly || !isNewTab}
                                />
                            </div>
                        </div>
                    )}
                    {type == "create" && (
                        <div className={`dp-parent`}>
                            <div className={`inputLayer`}>
                                <Input
                                    type="text"
                                    // ref={inputRef}
                                    // ref={nameRef}
                                    ref={allRefs ? allRefs.nameRef : null}
                                    required={true}
                                    value={name}
                                    onChange={e => onChangeName(e.target.value)}
                                    placeholder="Create a new offer path basic"
                                    disabled={readOnly}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {isSidePanel && (
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>Status</p>
                        <ToggleSwitch
                            isToggleOn={status}
                            setIsToggleOn={onChangeStatus}
                            setText={true}
                            disabled={readOnly}
                        />
                    </div>
                )}
                {type == "create" && isSidePanel && (
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>Surveys</p>
                        <p className={`infoText`}>
                            Select one or many surveys from below
                        </p>
                        <Select
                            label={
                                selectedSurveyIds.length
                                    ? null
                                    : "Select Survey name"
                            }
                            options={surveys}
                            keyField={"name"}
                            onSelect={onSelectSurvey}
                            idField={"id"}
                            selectedIds={selectedSurveyIds}
                            multiSelect={true}
                            showSearch={true}
                            isDisabled={readOnly}
                        />
                    </div>
                )}
                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Weight*</p>
                    <p className={`infoText`}>
                        Each of the offer path carries a weight.{" "}
                        {/* <a href="#" className="">
            Learn more
          </a> */}
                    </p>
                    <Input
                        type="number"
                        // ref={weightRef}
                        ref={allRefs ? allRefs.weightRef : null}
                        required={true}
                        value={weight}
                        placeholder="Enter weight"
                        onChange={e => onChangeWeight(e.target.value)}
                        maxWidth="maxChildContainer"
                        isError={highlightWeightError}
                        disabled={readOnly}
                        // disabled={readOnly || !isSidePanel} //Commented as this was causing issue in Survey edit
                    />
                </div>

                <div className={`row`}>
                    <p className={`headerTitleRequired`}>Auto Offer Path</p>
                    <ToggleSwitch
                        isToggleOn={autoOfferPath}
                        setIsToggleOn={onChangeAutoOfferPath}
                        setText={true}
                        disabled={autoOfferPathReadOnly}
                    />
                </div>

                {autoOfferPath && (
                    <div className={`row`}>
                        <p className={`headerTitleRequired`}>
                            Exploration Value (Add value between 0 to 100)
                        </p>
                        <p className={`infoText`}>
                            Select one or many surveys from below
                        </p>
                        <div className={`dp-parent`}>
                            <div className={`inputLayer`}>
                                <Input
                                    type="number"
                                    valueType="percentage"
                                    arrowHide={true}
                                    required={true}
                                    value={explorationValue}
                                    onChange={e => {
                                        onChangeExploration(e.target.value);
                                    }}
                                    placeholder="Add Exploration Value"
                                    disabled={autoOfferPathReadOnly}
                                    maxLength={3}
                                    isError={highlightExplorationWeightError}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className={`row`}>
                    <p className={`headerTitleRequired`}>
                        {autoOfferPath ? "Max offer limit*" : "Max offer limit"}
                    </p>
                    <p className={`infoText`}>
                        Select one or many surveys from below
                    </p>
                    <div className={`dp-parent`}>
                        <div className={`inputLayer`}>
                            <Input
                                type="number"
                                value={offerLimitValue}
                                onChange={e =>
                                    onChangeOfferLimit(e.target.value)
                                }
                                placeholder="Add a max offer limit"
                                disabled={readOnly}
                                isError={highlightMaxSlotLimittError}
                                required={true}
                            />
                        </div>
                    </div>
                </div>

                {isSidePanel &&
                    modules.indexOf(`SurveyManager.OfferPath.Deduping.Read`) >
                        -1 && (
                        <div className={`row`}>
                            <p className={`headerTitleRequired`}>Dedupe Rule</p>
                            {dedupeRules && dedupeRules.length ? (
                                <span
                                    onClick={() => {
                                        setOpenAddRuleSideBar(true);
                                    }}
                                    className="link"
                                >
                                    {dedupeRules.length} Rule Added
                                </span>
                            ) : (
                                <Button
                                    btnTheme={ButtonTypes.primaryHover_btn}
                                    onClick={() => {
                                        setOpenAddRuleSideBar(true);
                                    }}
                                    disable={readOnly}
                                >
                                    Add Rule
                                </Button>
                            )}
                        </div>
                    )}
            </div>
            {openAddRuleSideBar ? (
                <Popup
                    bodyMaxHeight="calc(100vh - 65px)"
                    title="Add Dedupe"
                    headerPadding="20px"
                    showOverlay
                    width="640px"
                    showFooter={false}
                    onClose={() => setOpenAddRuleSideBar(false)}
                >
                    <AddDedupeRule
                        prefix={"op"}
                        dedupeRules={dedupeRules}
                        onSave={dedupeRules => {
                            setOpenAddRuleSideBar(false);
                            setDedupe(dedupeRules);
                        }}
                        onCancel={() => {
                            setOpenAddRuleSideBar(false);
                        }}
                        readOnly={
                            modules.indexOf(
                                `SurveyManager.OfferPath.Deduping.Write`
                            ) === -1
                        }
                        entityMacros={OfferpathEntityMacros}
                        featureCopyToolbarKeys={{
                            sourceName: `Offer Path Basics ${
                                name && "-" + name
                            }`,
                            sourcePage: "Offer Path",
                            sourcePath: ["Offer Path", "Basics", "Dedupe"],
                            type: "offer Basics Dedupe",
                        }}
                    ></AddDedupeRule>
                </Popup>
            ) : null}
        </>
    );
};

export default OfferPathBasics;
