import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";
import { ROAS_CONFIG } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";
import { MESSAGES } from "../../constants/messages";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { initialFilterData } from "../../utils/roasUtils";

const initialState = {
    list: [],
    loading: false,
    mainLoader: false,
    updatedAt: new Date(),
    historyData: [],
    historyDataRaw: [],
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "campaign",
        isDesc: false,
        totalRowsCount: 0,
        searchKey: "campaign",
        searchTerm: null,
        filterKey: FILTERS.ROAS_DASHBOARD.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.ROAS_DASHBOARD.filterOperator,
        filtersOperator: FILTERS.ROAS_DASHBOARD.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData(initialFilterData, "roas"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const historyListingsTable = createAsyncThunk(
    "listings/roasConfigs/historyroasConfigsListingsTable",
    async (id, { getState, dispatch }) => {
        const response = customFetch(
            `${ROAS_CONFIG.FETCH_TMS_HISTORY}/${id}`,
            "GET",
            {}
        );
        return response;
    }
);

export const fetchRoasConfigsData = createAsyncThunk(
    "listings/roasConfigs/roasConfigsListingData",
    async (refreshClicked, thunkApi) => {
        const { getState, dispatch } = thunkApi;
        const { listings } = getState();
        const state = get(listings, "roasConfigs.pagination", {});
        const params = paginationParams(state);
        let tagFilters = get(
            listings,
            "roasConfigs.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.ROAS_DASHBOARD
        );
        const filterParamsList = [
            "filters",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(roasConfigsSlice.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "listings.roasConfigs.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.ROAS_DASHBOARD,
                filterParamsList
            );
        }
        const listingData = get(getState(), "listings.roasConfigs.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                ROAS_CONFIG.FETCH_ROAS_CONFIG_LIST,
                "POST",
                filterParamsData,
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to fetch TMS configs",
                        })
                    );
                    dispatch(
                        roasConfigsSlice.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(roasConfigsActions.setFilter(filterParamsData?.filters));
            data = get(response, "data", []);
        } else {
            var finalData = {
                data: listingData,
                metadata: { totalFound: prevTotalRows },
            };
            data = finalData;
        }

        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.ROAS}`;
            dispatch(roasConfigsSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data?.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new TMS Config`;
            dispatch(roasConfigsSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const deleteRoasConfig = createAsyncThunk(
    "listings/roasconfigs/deleteroasConfig",
    async params => {
        return await customFetch(
            `${ROAS_CONFIG.DELETE_ROAS_CONFIG}/${params.pubCode}/campaign/${
                params.campaign
            }/ps/${get(params, "ps", "")}`,
            "DELETE"
        );
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/roasConfigs/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { listings } = getState();
        let initialData = listings.roasConfigs.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.ROAS.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

const roasConfigsSlice = createSlice({
    name: "roasConfigs",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setMainLoader(state, action) {
            state.mainLoader = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchRoasConfigsData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchRoasConfigsData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metadata.totalFound",
                ""
            );
            state.list =
                get(action.payload, "data", "") == null
                    ? []
                    : get(action.payload, "data", "");
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
    },
});

export const roasConfigsActions = roasConfigsSlice.actions;
export default roasConfigsSlice.reducer;
