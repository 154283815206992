import { get } from "lodash";
import Icon from "../../../Shared/Icon/Icon";
import CustomTooltipRenderer from "../CustomTooltipRenderer/CustomTooltipRenderer";
import s from "./ImageWithName.module.scss";

export const ImageWithName = ({
    item,
    showName = true,
    position = "right",
    imgWidth = "30px",
    imgHeight = "30px",
}) => {
    const ConfigDetailPopup = () => {
        return (
            <div className={s.listingTableHoverImg}>
                <div className="detailName">
                    <img src={get(item, "mediaUrl", "")} />
                </div>
            </div>
        );
    };
    return (
        <div className={`${s.tableImgWrapper}`}>
            {get(item, "mediaUrl", "") && (
                <CustomTooltipRenderer
                    data={[get(item, "mediaUrl", "")]}
                    customRenderer={ConfigDetailPopup}
                    position={position}
                >
                    <img
                        className={s.normalImg}
                        style={{ width: imgWidth, height: imgHeight }}
                        src={get(item, "mediaUrl", "")}
                    />
                </CustomTooltipRenderer>
            )}

            {showName && <div className={s.text}>{get(item, "name", "")}</div>}
        </div>
    );
};

export const IconWithName = ({ item }) => {
    return (
        <div>
            <Icon icon={item.icon}></Icon>
            <span>{item.name}</span>
        </div>
    );
};

export default ImageWithName;
