import {
    forwardRef,
    memo,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import LinearDeterminate from "../../components/LinearDeterminate/LinearDeterminate";
import Button, { ButtonTypes } from "../Button/Button";
import Icon from "../Icon/Icon";
import s from "./Popup.module.scss";

const Popup = forwardRef(
    (
        {
            closeOutside = true,
            title = "",
            disableSave = false,
            onSave,
            saveButtonText = "Save",
            onClose,
            cancelButtonText = "Cancel",
            width = null,
            showHeader = true,
            showFooter = true,
            showConfirmBox = false,
            message,
            CustomRender,
            customRenderProps,
            textAlign,
            isResetButtonEnabled = false,
            resetButtonText = "",
            onReset,
            bodyMaxHeight,
            bodyPadding,
            bodyOverflow = "auto",
            minWidth,
            left,
            top,
            customClass = null,
            right,
            bottom,
            showFooterBtnsEnd = false,
            showWhiteFooter = false,
            useDifferentForCloseAndCancel = false,
            onCancel,
            children,
            containerId,
            portal = false,
            genericClose = false,
            leftSideClose = false,
            closeIconSize = 30,
            closeIconColor = "#74838F",
            showOverlay = false,
            overlayColor = "#ffffffcc",
            headerPadding = "20px",
            saveBtnTheme = ButtonTypes.primaryHover_btn,
            onPopUpClick,
            overlayCustomClass,
            isImage = false,
            imgSrc,
            headerCustom = "",
            showActionButtonsAtEnd = false,
            showPopUpLoader = false,
            customBtnText = null,
            customBtnClass,
            onCustomBtnClick = () => {},
        },
        ref
    ) => {
        const [showPopup, setShowPopup] = useState(false);
        useEffect(() => {
            if (showOverlay) document.body.style.overflow = "hidden";
            window.addEventListener("keydown", handleKeyDown);
            return () => {
                window.removeEventListener("keydown", handleKeyDown);
                if (showOverlay) document.body.style.overflow = "auto";
            };
        }, []);
        const handleKeyDown = e => {
            e.stopPropagation();
            if (e.keyCode === 27) {
                onClose && onClose(e);
            }
        };
        const onCloseHandler = e => {
            setShowPopup(false);
            if (useDifferentForCloseAndCancel && typeof onCancel === "function")
                onCancel(e);
            else if (typeof onClose === "function") onClose(e);
        };
        const onOpenHandler = () => {
            setShowPopup(true);
        };
        useImperativeHandle(
            ref,
            () => {
                return {
                    open: onOpenHandler,
                    close: onCloseHandler,
                };
            },
            [showPopup]
        );
        let styles = {},
            bodyStyles = {};
        if (width != null) {
            if (
                width.includes("px") ||
                width.includes("%") ||
                width.includes("vw")
            ) {
                styles.width = width;
            } else {
                styles.width = width + "px";
            }
        }
        if (left) styles.left = left;
        if (top) styles.top = top;
        if (right) styles.right = right;
        if (bottom) styles.bottom = bottom;
        if (left || top || right || bottom) styles.position = "absolute";
        if (minWidth) styles.minWidth = minWidth;
        if (bodyMaxHeight) {
            if (bodyMaxHeight.includes("px") || bodyMaxHeight.includes("vh")) {
                bodyStyles.height = bodyMaxHeight;
            } else {
                bodyStyles.height = bodyMaxHeight + "px";
            }

            bodyStyles.overflow = "auto";
        }
        if (bodyOverflow !== "auto") {
            bodyStyles.overflow = bodyOverflow;
        }
        if (bodyPadding) bodyStyles.padding = bodyPadding;

        const popup = (
            <div
                className={`${s.popUpWrap} ${
                    showOverlay ? s.showOverlay : ""
                } ${overlayCustomClass ? overlayCustomClass : ""}`}
                style={{ backgroundColor: overlayColor }}
            >
                <OutsideClickHandler
                    onOutsideClick={onCloseHandler}
                    useCapture={false}
                    // onOutsideClick={(e) => closeOutside ? onCloseHandler(e) : null}
                    disabled={!closeOutside}
                >
                    <div
                        className={`fadeIn ${s.wrapper} ${
                            customClass ? customClass : ""
                        }`}
                        style={styles}
                        ref={ref}
                    >
                        {showHeader && (
                            <div
                                className={`${s.header}
                                ${headerCustom ? s[headerCustom] : ""}
                                `}
                                style={{ padding: headerPadding }}
                            >
                                {showPopUpLoader && (
                                    <LinearDeterminate
                                        customClass={s.popUploader}
                                    />
                                )}
                                {leftSideClose ? (
                                    <div
                                        className={s.leftSideCloseIconWrap}
                                        onClick={onClose}
                                        title="Close"
                                    >
                                        <Icon
                                            icon="CloseFilled"
                                            size={closeIconSize}
                                            color={closeIconColor}
                                        />
                                    </div>
                                ) : null}
                                <div className={s.title}>{title}</div>
                                {!leftSideClose ? (
                                    <div
                                        className={s.close}
                                        title="Close"
                                        onClick={onClose}
                                    >
                                        <Icon icon="CloseDefault" size="16px" />
                                    </div>
                                ) : null}
                            </div>
                        )}
                        {showConfirmBox ? (
                            <div
                                className={`${
                                    s.promptMessageWrapper
                                } confimBoxBody ${
                                    textAlign == "left" ? s.leftAlign : ""
                                } ${textAlign == "right" ? s.rightAlign : ""}`}
                            >
                                {CustomRender && (
                                    <CustomRender
                                        {...customRenderProps}
                                    ></CustomRender>
                                )}
                                {!CustomRender && (
                                    <div className={s.promptMessage}>
                                        {message}
                                    </div>
                                )}
                                {/* {!CustomRender && <p className={s.promptMessage}>{message}</p>} */}
                                <div className={s.promptButtonWrapper}>
                                    <Button
                                        btnTheme={ButtonTypes.default_btn}
                                        className={s.button}
                                        onClick={onCloseHandler}
                                    >
                                        {cancelButtonText}
                                    </Button>
                                    <Button
                                        className={s.button}
                                        btnTheme={saveBtnTheme}
                                        onClick={e => onSave(e)}
                                        disable={disableSave}
                                    >
                                        {saveButtonText}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`${s.body} ${
                                    !showHeader && !showFooter
                                        ? s.borderRadiusAll
                                        : !showHeader
                                        ? s.borderRadiusTop
                                        : !showFooter
                                        ? s.borderRadiusBottom
                                        : ""
                                }`}
                                style={bodyStyles}
                                onClick={onPopUpClick ? onPopUpClick : null}
                            >
                                {children}
                            </div>
                        )}
                        {showConfirmBox ? (
                            ""
                        ) : showFooter ? (
                            isResetButtonEnabled ? (
                                <div
                                    className={`${s.footer} ${
                                        showFooterBtnsEnd
                                            ? s.showFooterBtnsEnd
                                            : ""
                                    } ${
                                        showWhiteFooter ? s.showWhiteFooter : ""
                                    }
                                    ${
                                        showActionButtonsAtEnd
                                            ? s.showActionButtonsAtEnd
                                            : ""
                                    }
                                    `}
                                >
                                    <div className={s.buttonWrapper}>
                                        <Button
                                            btnTheme={ButtonTypes.default_btn}
                                            className={s.button}
                                            onClick={
                                                useDifferentForCloseAndCancel
                                                    ? onCancel
                                                    : onClose
                                            }
                                        >
                                            {cancelButtonText}
                                        </Button>
                                        <Button
                                            className={s.button}
                                            btnTheme={saveBtnTheme}
                                            onClick={e => onSave(e)}
                                            disable={disableSave}
                                        >
                                            {saveButtonText}
                                        </Button>
                                    </div>
                                    <Button
                                        className={s.button}
                                        btnTheme={ButtonTypes.primary_btn}
                                        onClick={onReset}
                                    >
                                        {resetButtonText}
                                    </Button>
                                </div>
                            ) : (
                                <div
                                    className={`${s.footer} ${
                                        showFooterBtnsEnd
                                            ? s.showFooterBtnsEnd
                                            : ""
                                    } ${
                                        showWhiteFooter ? s.showWhiteFooter : ""
                                    }
                                    ${
                                        showActionButtonsAtEnd
                                            ? s.showActionButtonsAtEnd
                                            : ""
                                    }
                                    `}
                                >
                                    {customBtnText && (
                                        <Button
                                            btnTheme={ButtonTypes.default_btn}
                                            className={`${s.button} ${
                                                customBtnClass
                                                    ? customBtnClass
                                                    : ""
                                            }`}
                                            onClick={onCustomBtnClick}
                                        >
                                            {customBtnText}
                                        </Button>
                                    )}
                                    <Button
                                        btnTheme={ButtonTypes.default_btn}
                                        className={s.button}
                                        onClick={
                                            useDifferentForCloseAndCancel
                                                ? onCancel
                                                : onClose
                                        }
                                    >
                                        {cancelButtonText}
                                    </Button>
                                    <Button
                                        className={s.button}
                                        btnTheme={saveBtnTheme}
                                        onClick={e => onSave(e)}
                                        disable={disableSave}
                                    >
                                        {saveButtonText}
                                    </Button>
                                </div>
                            )
                        ) : (
                            ""
                        )}
                        {isImage && (
                            <div className={s.imgWrapper}>
                                <img alt="" src={imgSrc} />
                            </div>
                        )}
                    </div>
                </OutsideClickHandler>
            </div>
        );
        return (
            <>
                {(showPopup || !genericClose) &&
                    (portal
                        ? createPortal(popup, containerId || document.body)
                        : popup)}
            </>
        );
    }
);

export default Popup;
