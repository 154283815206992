import {
    fetchSpamIpDropdownUrl,
    LAYOUT,
    MAIN,
    CONTAINERS,
    CAMPAIGNS,
    ADVERTISER,
} from "../constants/url";
import customFetch from "../fetch/customFetch";
import { useQuery, useQueryClient } from "react-query";
import { toastListActions } from "../store/toastList/toastList";
import { useDispatch } from "react-redux";
import { get } from "lodash";

export const useFetchSpamIpDropdown = () => {
    const dispatch = useDispatch();

    const { isLoading, isFetching, error, data } = useQuery(
        "repoData",
        async () => {
            const response = await customFetch(
                `${fetchSpamIpDropdownUrl.spamIpDropdownUrl}`,
                "GET",
                {}
            ).then(res => res?.data);

            if (!get(response, "success", false)) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch Spam Api",
                    })
                );
            }

            return response?.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    const apiData = data;
    return { apiData, isLoading };
};

export const useFetchAvailableTargetingParams = () => {
    const dispatch = useDispatch();

    const { isLoading, isFetching, error, data } = useQuery(
        "targetingParams",
        async () => {
            const response = await customFetch(
                `${MAIN.GET_ALL_TARGETINGS}`,
                "GET",
                {}
            ).then(res => res?.data);

            if (get(response, "status", false) !== "success") {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch available targetings",
                    })
                );
            }

            const userAttributeTps = response?.data["User Attribute"] || [];
            const userBehaviourTps = response?.data["User Behaviour"] || [];

            return [...userAttributeTps, ...userBehaviourTps];
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    const apiData = data;
    return { apiData, isLoading };
};

export const useFetchAvailableQuestions = () => {
    const dispatch = useDispatch();

    const { isLoading, isFetching, error, data } = useQuery(
        "questionList",
        async () => {
            const response = await customFetch(
                `${MAIN.GET_ALL_QUESTIONS}`,
                "POST",
                {}
            ).then(res => res?.data);

            if (get(response, "status", false) !== "success") {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch available targetings",
                    })
                );
            }
            return response.data?.data || [];
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    const apiData = data;
    return { apiData, isLoading };
};

export const useFetchLayouts = () => {
    const { isLoading, isFetching, error, data } = useQuery(
        "layoutList",
        async () => {
            const response = await customFetch(`${LAYOUT.FETCH_ALL_LAYOUTS}`);
            if (response?.status >= 400) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch all layouts.",
                    })
                );
            }
            return get(response, "data.data", []);
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 15 * (60 * 1000),
            cacheTime: Infinity,
        }
    );

    const apiData = data;
    return { apiData, isLoading };
};

export const reactQueryApiCall = (
    apiCallName,
    entityName,
    customFetchParameters = {},
    reactQueryJson = {}
) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    // Below obj is for customFetch function json
    const {
        url,
        method = "GET",
        dataValue = {},
        customHeaders = {},
        optionalParams = {},
        onDownloadProgress = null,
        cancelToken = null,
        errorCallback = null,
    } = customFetchParameters;

    // Below obj is for react query json
    const {
        refetchOnWindowFocus = false,
        refetchOnMount = false,
        refetchOnReconnect = false,
        retry = false,
        staleTime = 15 * (60 * 1000),
        cacheTime = Infinity,
        onSettled = (data, error) => {
            if (data === null) {
                setTimeout(() => {
                    queryClient.removeQueries(apiCallName, {
                        exact: true,
                    });

                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Failed to fetch ${entityName}`,
                        })
                    );
                });
            }
        },
    } = reactQueryJson;

    const { isLoading, isFetching, error, data } = useQuery(
        apiCallName,
        async () => {
            const response = await customFetch(
                url,
                method,
                dataValue,
                customHeaders,
                optionalParams,
                onDownloadProgress,
                cancelToken,
                errorCallback
            ).then(res => res?.data);

            return response?.data;
        },
        {
            refetchOnWindowFocus,
            refetchOnMount,
            refetchOnReconnect,
            retry,
            staleTime,
            cacheTime,
            onSettled,
        }
    );

    const apiData = data;
    return { apiData, isLoading };
};

export const fetchAllQuestions = () => {
    const dispatch = useDispatch();

    const { isLoading, data } = useQuery(
        "fetchAllQuestions",
        async () => {
            const response = await customFetch(
                `${MAIN.GET_ALL_QUESTIONS}`,
                "POST",
                {}
            ).then(res => res?.data);

            if (get(response, "status", false) !== "success") {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch all questions",
                    })
                );
            }
            return response?.data;
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    const apiData = data;
    return { apiData, isLoading };
};

export const fetchContainerListWithData = () => {
    const { apiData, isLoading } = reactQueryApiCall(
        "containerListWithData",
        "Container List With Data",
        {
            url: CONTAINERS.FETCH_CONTAINERS_LIST_WITH_DATA,
            method: "POST",
        }
    );

    return { apiData, isLoading };
};

export const fetchCampaignMacros = () => {
    const { apiData, isLoading } = reactQueryApiCall(
        "campaignMacros",
        "Macros for campaign ad config",
        {
            url: CAMPAIGNS.FETCH_MACROS,
            method: "GET",
        }
    );

    return { apiData, isLoading };
};

export const fetchAdvertisers = () => {
    const { apiData, isLoading } = reactQueryApiCall(
        "advertisers",
        "List of all advertisers",
        {
            url: ADVERTISER.GET_ALL_ADVERTISERS,
            method: "POST",
        }
    );

    return { apiData, isLoading };
};
