import { createPortal } from "react-dom";

function EdgeLabelRenderer({ parentRef, children }) {
    const edgeLabelRenderer = parentRef?.current?.querySelector(
        ".react-flow__edgelabel-renderer"
    );

    if (!edgeLabelRenderer) {
        return null;
    }

    return createPortal(children, edgeLabelRenderer);
}

export default EdgeLabelRenderer;
