import { createSlice } from "@reduxjs/toolkit";
import { getRandomId } from "../../utils/globalUtils";

const initialState = [];

const toastListSlice = createSlice({
    name: "toastList",
    initialState,
    reducers: {
        setToastList(state, action) {
            const id = getRandomId();
            const updatedAt = new Date();
            return [...state, { ...action.payload, id, updatedAt }];
        },
        deleteToast(state, action) {
            return state.filter(item => item.id != action.payload);
        },
    },
});

export const toastListActions = toastListSlice.actions;
export default toastListSlice.reducer;
