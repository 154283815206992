import { useDispatch } from "react-redux";
import s from "./ModifyColumn.module.scss";
import CustomSearchInput from "../../components/CustomSearchInput/CustomSearchInput";
import Popup from "../Popup/Popup";
import { capitalizeString } from "../../utils/globalUtils";
import Checkbox from "../Checkbox/Checkbox";
import { useEffect, useState } from "react";
import Icon from "../../Shared/Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";
import { globalConfigActions } from "../../store/globalConfig/globalConfig";
import { getPageName } from "../../utils/localStorageUtils";

import { arrayMove } from "@dnd-kit/sortable";
import SortableWrapper from "../Sortable/SortableWrapper";
import SortableItem from "../Sortable/SortableItem";
import DragHandle from "../Sortable/DragHandle";

const ModifyColumn = ({ allColumns = [], handleModifiedColumns }) => {
    const dispatch = useDispatch();
    const page = getPageName();
    const [columns, setColumns] = useState(allColumns);
    const [storageDataBackup, setStorageDataBackup] = useState(
        localStorage.getItem("tableColumnsData")
            ? JSON.parse(localStorage.getItem("tableColumnsData"))
            : {}
    );
    const [storageData, setStorageData] = useState(
        localStorage.getItem("tableColumnsData")
            ? JSON.parse(localStorage.getItem("tableColumnsData"))
            : {}
    );
    const [orderedColumns, setOrderedColumns] = useState(
        storageData[page] && storageData[page].order
            ? storageData[page].order
            : columns?.filter(_ => _.getIsVisible()).map(_ => _.id)
    );

    // const [pinnedColumns, setPinnedColumns] = useState({ left: [] });
    useEffect(() => {
        let temp = {};
        allColumns.map(col => {
            temp[col.id] = orderedColumns?.includes(col.id);
        });
        setStorageData({
            ...storageData,
            [page]: {
                visible: temp,
                order: orderedColumns,
                timestamp: Date.now(),
            },
        });
    }, [orderedColumns]);

    const fixedColumn = new Set([]);

    const addFixedColumn = columns => {
        return [
            ...columns.filter(_ => !Array.from(fixedColumn).includes(_)),
            ...Array.from(fixedColumn),
        ];
    };

    const onColumnSort = event => {
        const { active, over } = event;
        if (active && over && active?.id !== over?.id) {
            const oldIndex = orderedColumns.findIndex(_ => _ === active.id);
            const newIndex = orderedColumns.findIndex(_ => _ === over.id);
            setOrderedColumns(arrayMove(orderedColumns, oldIndex, newIndex));
        }
    };

    const hideColumn = colId => {
        setOrderedColumns(orderedColumns?.filter(_ => _ !== colId));
    };

    const resetColumns = async () => {
        if (storageDataBackup && storageDataBackup[page]?.visible) {
            await setOrderedColumns(
                storageDataBackup[page].order.filter(key =>
                    Object.keys(storageDataBackup[page].visible).includes(key)
                )
            );
        } else {
            setOrderedColumns(addFixedColumn(columns.map(_ => _.id)));
        }
    };

    const checkboxWrap = column => {
        return (
            <div key={column.id} className="mb-20">
                <Checkbox
                    isChecked={() => orderedColumns?.includes(column.id)}
                    labelText={
                        column?.columnDef?.header ||
                        capitalizeString(column?.id)
                    }
                    disabled={
                        (orderedColumns?.length === 2 &&
                            orderedColumns?.includes(column.id)) ||
                        !column?.columnDef?.header
                    }
                    onChange={check => {
                        check
                            ? setOrderedColumns(
                                  addFixedColumn([...orderedColumns, column.id])
                              )
                            : hideColumn(column.id);
                    }}
                />
            </div>
        );
    };

    return (
        <Popup
            bodyMaxHeight="calc(100vh - 138px)"
            title="Modify Columns"
            headerPadding="20px"
            width="640px"
            bodyPadding="0"
            showOverlay
            showActionButtonsAtEnd
            showWhiteFooter
            onSave={() => {
                localStorage.setItem(
                    "tableColumnsData",
                    JSON.stringify(storageData)
                );
                handleModifiedColumns({
                    ...storageData[page],
                    order: storageData[page].order,
                });
                dispatch(globalConfigActions.setShowModifyColumnsPanel(false));
            }}
            onClose={() => {
                dispatch(globalConfigActions.setShowModifyColumnsPanel(false));
            }}
        >
            <div className={`${s.searchBar} p-20`}>
                <CustomSearchInput
                    data={allColumns}
                    placeholder="Search a column"
                    handleSearch={e => {
                        let value =
                            e?.target?.value?.trim().toLowerCase() || "";
                        if (value !== "") {
                            const newList = allColumns.filter(col =>
                                col.id.toLowerCase().includes(value)
                            );
                            setColumns(newList);
                        } else {
                            setColumns(allColumns);
                        }
                    }}
                />
            </div>

            <div className={`${s.container} dp-parent`}>
                <div className={`${s.containerChild} ${s.visibilityWrapper}`}>
                    <div
                        className={`${s.top} dp-parent dp-parent-hor-space-between dp-parent-ver-center pl-20 pr-20`}
                    >
                        <div>Selected ({orderedColumns?.length || 0})</div>
                        <div className="dp-parent gap-20">
                            <div
                                className={`${s.btn}`}
                                onClick={() => {
                                    setOrderedColumns(
                                        addFixedColumn(
                                            allColumns.map(_ => _.id)
                                        )
                                    );
                                }}
                            >
                                Add All
                            </div>
                            <div className={`${s.btn} `} onClick={resetColumns}>
                                Reset
                            </div>
                        </div>
                    </div>
                    <div className={`${s.content} p-20`}>
                        {columns.length === 0 && <p>No results found.</p>}
                        {columns.map(column => {
                            if (column?.columnDef?.header) {
                                return checkboxWrap(column);
                            }
                        })}
                        {columns.map(column => {
                            if (!column?.columnDef?.header) {
                                fixedColumn.add(column.id);
                                return checkboxWrap(column);
                            }
                        })}
                    </div>
                </div>
                <div className={`${s.containerChild} ${s.pinnedWrapper}`}>
                    <div
                        className={`${s.top} dp-parent dp-parent-hor-space-between dp-parent-ver-center pl-20 pr-20`}
                    >
                        <div>Column Reorder</div>
                        <div
                            className={s.btn}
                            onClick={() => {
                                if (storageDataBackup[page]?.order) {
                                    setOrderedColumns(
                                        addFixedColumn(
                                            storageDataBackup[page].order
                                        )
                                    );
                                } else {
                                    let temp = columns
                                        .filter(col =>
                                            orderedColumns.includes(col.id)
                                        )
                                        .map(_ => _.id);
                                    setOrderedColumns(addFixedColumn(temp));
                                }
                            }}
                        >
                            Reset
                        </div>
                    </div>
                    <div className={`${s.content} pl-20 pr-20`}>
                        <SortableWrapper
                            list={orderedColumns?.map(id =>
                                allColumns.find(col => col.id === id)
                            )}
                            onDrag={onColumnSort}
                        >
                            {orderedColumns
                                ?.map(id =>
                                    allColumns.find(col => col.id === id)
                                )
                                .map(item => {
                                    if (!item?.columnDef?.header) {
                                        fixedColumn.add(item.id);
                                    }
                                    const row = () => {
                                        return (
                                            <div
                                                // className={`${
                                                //     pinnedColumns.left.includes(item.id) ? s.isPinned : ""
                                                // }`}
                                                className={`${s.row} ${
                                                    !item?.columnDef?.header
                                                        ? s.disabled
                                                        : ""
                                                } dp-parent dp-parent-ver-center`}
                                            >
                                                <div
                                                    className={`${s.textWrapper} dp-parent gap-10`}
                                                >
                                                    {onColumnSort && (
                                                        <DragHandle
                                                            id={item.id}
                                                        />
                                                    )}
                                                    <div className={s.text}>
                                                        {item?.columnDef
                                                            ?.header ||
                                                            capitalizeString(
                                                                item?.id
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="dp-parent gap-10">
                                                    {/* <div
                                                className={`dp-parent ${s.pinIcon} `}
                                                onClick={e => {
                                                    setPinnedColumns({
                                                        left: pinnedColumns.left.includes(item.id)
                                                            ? pinnedColumns.left.filter(_ => _ !== item.id)
                                                            : [...pinnedColumns.left, item.id],
                                                    });
                                                    e.stopPropagation();
                                                }}
                                            >
                                                <Tooltip
                                                    message={
                                                        pinnedColumns.left.includes(item.id)
                                                            ? "Remove from Pinned Columns"
                                                            : "Pin column"
                                                    }
                                                >
                                                    <Icon
                                                        icon={
                                                            pinnedColumns.left.includes(item.id)
                                                                ? "PinFilled"
                                                                : "Pin"
                                                        }
                                                        size="18px"
                                                        color="#74838F"
                                                        enableHover
                                                    />
                                                </Tooltip>
                                            </div> */}
                                                    <div
                                                        className={`dp-parent circleIconColor circleIconColor--delete ${
                                                            orderedColumns?.length ===
                                                                2 &&
                                                            orderedColumns?.includes(
                                                                item.id
                                                            )
                                                                ? s.disabled
                                                                : ""
                                                        }`}
                                                        onClick={e => {
                                                            hideColumn(item.id);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <Tooltip
                                                            message={"Remove"}
                                                        >
                                                            <Icon
                                                                icon="Remove"
                                                                size="18px"
                                                                enableHover
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    };
                                    return item?.columnDef?.header ? (
                                        <SortableItem
                                            key={item.id}
                                            id={item.id}
                                            dragHandle
                                        >
                                            {row()}
                                        </SortableItem>
                                    ) : (
                                        row()
                                    );
                                })}
                        </SortableWrapper>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default ModifyColumn;
