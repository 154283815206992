import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import { COLLECTION } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import _, { get } from "lodash";
import {
    getRequiredObject,
    getInitialRowsCount,
} from "../../utils/globalUtils";
import { FILTERS } from "../../constants/listingSearch";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import {
    createTagUtil,
    getUpdatedParams,
    getUpdatedFilterParams,
} from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { getInitialTagData } from "../../utils/localStorageUtils";
import { isEmpty, sortBy } from "lodash";
import { mapCollectionData } from "../../utils/collectionUtils";

const initialState = {
    list: [],
    // updatedAt: new Date(),
    loading: false,
    createCollection: {
        vertical: "",
        type: null,
        name: "",
        id: "",
        description: "",
        mainLoader: false,
        showPrompt: true,
        containerType: "",
        items: [],
        itemsDisable: false,
        detailsList: [],
        deletedQuestions: [],
        selectedOfferPath: {},
        selectedOfferPathId: "",
        selectedOfferWall: {},
        selectedOfferWallId: "",
        tcpa: [],
        addItems: false,
        hasOfferDetails: [],
        partnerData: [],
        hnpCategories: [],
        layoutCopy: {},
        gratificationType: "AdUnit",
        selectedAdUnitId: "",
        offerWallSlots: [],
        thirdPartyUrl: "",
        destinationTab: false,
        focusTab: false,
        enableParamForwarding: false,
        editPhaseVerticalNotSelected: false,
    },
    createCollectionBackup: {},
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        filterKey: FILTERS.OFFER_PATH.filterKey,
        filterTerm: null,
        filterOperator: FILTERS.OFFER_PATH.filterOperator,
        filtersOperator: FILTERS.OFFER_PATH.operator,
        filters: [],
    },
    searchFilter: {
        filterApplied: getInitialTagData([], "collection"),
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
    historyData: [],
    historyDataRaw: [],
};

export const fetchCollectionData = createAsyncThunk(
    "flowmanager/collection/listings",
    async ({ refreshClicked, noPagination }, { dispatch, getState }) => {
        const { flowmanager } = getState();
        const state = get(flowmanager, "collection.pagination", {});
        const params = noPagination ? {} : paginationParams(state);
        let tagFilters = get(
            flowmanager,
            "collection.searchFilter.filterApplied",
            []
        );
        const updatedParams = getUpdatedParams(
            tagFilters,
            params,
            FILTERS.COLLECTION
        );
        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let filterParamsData = getRequiredObject(
            filterParamsList,
            updatedParams
        );
        const prevFilters = state?.filters;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) &&
            !isEmpty(prevFilters)
        ) {
            await dispatch(collection.actions.setPageNumber(1));
            const paginationNew = get(
                getState(),
                "flowmanager.collection.pagination",
                {}
            );
            filterParamsData = await getUpdatedFilterParams(
                paginationNew,
                tagFilters,
                FILTERS.COLLECTION,
                filterParamsList
            );
        }
        const listingData = get(getState(), "flowmanager.collection.list", []);
        const prevTotalRows = state?.totalRowsCount;
        let data = [];
        let dispatchSearchError = true;
        if (
            JSON.stringify(filterParamsData?.filters) !==
                JSON.stringify(prevFilters) ||
            isEmpty(prevFilters) ||
            refreshClicked
        ) {
            const response = await customFetch(
                `${COLLECTION.FETCH_COLLECTION_LIST}`,
                "POST",
                filterParamsData,
                {},
                {},
                {},
                null,
                null,
                () => {
                    dispatchSearchError = false;
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to load Collection List",
                        })
                    );
                    dispatch(
                        collection.actions.setTableError(
                            MESSAGES.TABLE.noDataFromApi
                        )
                    );
                }
            );
            dispatch(collectionActions.setFilter(filterParamsData?.filters));
            data = await mapCollectionData(get(response, "data.data", []));
        } else {
            var finalData = {
                data: listingData,
                metaData: { totalFound: prevTotalRows },
            };
            data = await mapCollectionData(finalData);
        }
        if (
            (dispatchSearchError && state.filterTerm) ||
            (dispatchSearchError && !isEmpty(tagFilters))
        ) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.QUESTIONS}`;
            dispatch(collection.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new collection`;
            dispatch(collection.actions.setTableError(errorMessage));
        }
        return { data };
    }
);

export const fetchAllCollection = createAsyncThunk(
    "flowmanager/collection/fetchAllCollection",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${COLLECTION.FETCH_ALL_COLLECTION_LIST}`
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all collection!",
                })
            );
        }
        return get(response, "data.data", []);
    }
);

const getCollectionParams = (state, isDelete = false) => {
    const collectionState = state;

    var obj = {};
    if (collectionState?.updatedAt) obj.updatedAt = collectionState.updatedAt;
    if (collectionState?.id !== "" && collectionState.id !== null)
        obj.id = collectionState.id;
    if (isDelete) obj.isActive = false;
    obj.vertical = collectionState.vertical || "";
    if (
        collectionState.type?.id === "REGISTER" ||
        collectionState.type?.id === "DETAILS_CONFIRMATION"
    ) {
        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: JSON.parse(JSON.stringify(collectionState.items))?.map(
                item => {
                    item.tcpa?.map(tcpa => {
                        delete tcpa.tempId;
                        return tcpa;
                    });
                    //deleting temp id assigned on frontend
                    if (item.isNew && item.id) {
                        delete item.id;
                        delete item.isNew;
                    }
                    delete item?.layoutConfigCopy;
                    return item;
                }
            ),
        };
    } else if (
        collectionState.type?.id === "SURVEY" ||
        collectionState.type?.id === "OFFER"
    ) {
        //In survey create a array such that if not present then set isActive to false and set the rank accordingly
        let finalDetails = !isEmpty(collectionState?.deletedQuestions)
            ? [
                  ...collectionState.detailsList,
                  ...collectionState.deletedQuestions,
              ]
            : [...collectionState.detailsList];
        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: JSON.parse(JSON.stringify(finalDetails))?.map(item => {
                item.tcpa?.map(tcpa => {
                    delete tcpa.tempId;
                    return tcpa;
                });
                delete item?.layoutConfigCopy;
                return item;
            }),
        };
    } else if (collectionState.type?.id === "OFFER_WALL") {
        const selectedOfferWall = get(collectionState, "selectedOfferWall", "");
        const selectedOfferWallId = get(
            collectionState,
            "selectedOfferWallId",
            ""
        );
        //Temporary as need to make all other offer inActive
        const previousItems = collectionState?.items?.map(_ => {
            return { ..._, isActive: false };
        });
        let initialItems = [];
        if (!isEmpty(previousItems)) {
            let oldId = previousItems[0]?.id;
            initialItems = [
                {
                    rank: 1,
                    id: oldId,
                    properties: {
                        offer: {
                            offer_header: "",
                            offer_wall_id: selectedOfferWallId
                                ? selectedOfferWallId
                                : "",
                            offer_description: "",
                            offer_logo: "",
                        },
                        buttons: ["next"],
                    },
                    isActive: true,
                },
            ];
        } else {
            initialItems = [
                {
                    rank: 1,
                    properties: {
                        offer: {
                            offer_header: "",
                            offer_wall_id: selectedOfferWallId
                                ? selectedOfferWallId
                                : "",
                            offer_description: "",
                            offer_logo: "",
                        },
                        buttons: ["next"],
                    },
                    isActive: true,
                },
            ];
        }

        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: initialItems,
        };
    } else if (collectionState.type?.id === "LISTINGS") {
        let finalDetails = !isEmpty(collectionState?.deletedQuestions)
            ? [
                  ...collectionState.detailsList,
                  ...collectionState.deletedQuestions,
              ]
            : [...collectionState.detailsList];
        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: JSON.parse(JSON.stringify(finalDetails))?.map(item => {
                return item;
            }),
        };
    } else if (collectionState.type?.id === "DEALS_OFFERWALL") {
        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: collectionState.items?.filter(_ => _.id || _.rank !== -1),
        };
    } else if (
        collectionState.type?.id === "GRATIFICATION" ||
        collectionState.type?.id === "STANDALONE" ||
        collectionState.type?.id === "CONFIRMATION"
    ) {
        const selectedAdUnitId = get(collectionState, "selectedAdUnitId", "");
        const selectedOfferWallId = get(collectionState, "offerWallSlots", []);
        const getGratifcationType = get(
            collectionState,
            "gratificationType",
            "AdUnit"
        );

        const previousItems = collectionState?.items?.map(_ => {
            return { ..._, isActive: false };
        });
        let initialItems = [];
        if (!isEmpty(previousItems)) {
            let oldId = previousItems[0]?.id;
            initialItems = [
                {
                    rank: 1,
                    id: oldId,
                    properties: {
                        adUnitId:
                            getGratifcationType === "AdUnit"
                                ? selectedAdUnitId
                                : "",
                        offerWallId:
                            getGratifcationType === "OfferWall"
                                ? selectedOfferWallId
                                : [],
                    },
                    isActive: true,
                    tcpa: [],
                    hnpLeads: [],
                    hnpLeadIds: [],
                },
            ];
        } else {
            initialItems = [
                {
                    rank: 1,
                    properties: {
                        adUnitId:
                            getGratifcationType === "AdUnit"
                                ? selectedAdUnitId
                                : "",
                        offerWallId:
                            getGratifcationType === "OfferWall"
                                ? selectedOfferWallId
                                : [],
                    },
                    isActive: true,
                    tcpa: [],
                    hnpLeads: [],
                    hnpLeadIds: [],
                },
            ];
        }
        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: initialItems,
        };
    } else if (collectionState.type?.id === "THIRD_PARTY") {
        const thirdPartyUrl = get(collectionState, "thirdPartyUrl", "");
        let destinationTab = get(collectionState, "destinationTab", false);
        let focusTab = get(collectionState, "focusTab", false);
        let enableParamForwarding = get(
            collectionState,
            "enableParamForwarding",
            false
        );
        const previousItems = collectionState?.items?.map(_ => {
            return { ..._, isActive: false };
        });
        let initialItems = [];
        let finalProperties = {
            url: thirdPartyUrl,
            destinationTab: !destinationTab ? "same" : "new",
            focus: !focusTab ? "same" : "new",
            enableParamForwarding: enableParamForwarding,
        };
        if (!isEmpty(previousItems)) {
            let oldId = previousItems[0]?.id;
            initialItems = [
                {
                    rank: 1,
                    id: oldId,
                    properties: finalProperties,
                    isActive: true,
                },
            ];
        } else {
            initialItems = [
                {
                    rank: 1,
                    properties: finalProperties,
                    isActive: true,
                },
            ];
        }
        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: initialItems,
        };
    } else {
        obj = {
            ...obj,
            containerType: collectionState.type?.id,
            name: collectionState.name,
            description: collectionState.description,
            items: [],
        };
    }
    return obj;
};

export const createUpdateCollection = createAsyncThunk(
    "flowmanager/createCollection",
    async (deleteItem, { getState, dispatch }) => {
        let isConcurreny = false;
        const data = getState().flowmanager.collection.createCollection;
        const params = getCollectionParams(
            deleteItem ? deleteItem : data,
            deleteItem ? true : false
        );

        const response = await customFetch(
            COLLECTION.CREATE_COLLECTION_WITH_TCPA,
            "POST",
            params,
            {},
            {},
            null,
            null,
            data => {
                const resData = data.response.data;
                if (resData.message.includes("Entity already modified")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Collection already modified while you were editing",
                            autoClose: 5000,
                        })
                    );
                    dispatch(
                        toastListActions.setToastList({
                            type: "Info",
                            message: "Check History or reload collection",
                        })
                    );

                    isConcurreny = true;
                }
                if (resData.message.includes("marketing_partners")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message:
                                "Data too long. Please ensure your input fits within the specified limit.",
                            autoClose: 5000,
                        })
                    );
                }
            }
        );
        if (!isConcurreny) {
            if (data?.id !== "" && response) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Success",
                        message: "Updated collection successfully",
                    })
                );
            } else if (response && deleteItem) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Success",
                        message: "Deleted collection successfully",
                    })
                );
            } else if (response) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Success",
                        message: "Created collection successfully",
                    })
                );
            }
            if (response == null || response?.status >= 400) {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to create collection",
                    })
                );
            }
        }
        return get(response, "data", null);
    }
);

export const deleteCollection = createAsyncThunk(
    "flowmanager/collection/delete",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${COLLECTION.DELETE_COLLECTION}/${id}`,
            "POST",
            {},
            {},
            {},
            null,
            null,
            data => {
                const resData = data?.response?.data;
                if (resData && resData.includes("Cannot delete!")) {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: `Failed to delete, this collection is mapped to some container(s).`,
                        })
                    );
                } else {
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: "Failed to delete collection.",
                        })
                    );
                }
            }
        );
        if (get(response, "data.status", false) === "success") {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: "Collection deleted successfully.",
                    autoClose: 5000,
                })
            );
        }
    }
);

export const fetchCollectionById = createAsyncThunk(
    "flowmanager/fetchCollectionById",
    async ({ layoutData, id, history }, { dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const response = await customFetch(
            `${COLLECTION.GET_COLLECTION}/${id}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        let data = [];
        if (isError) {
            data = isErrorResponse;
        } else data = get(response, "data.data", {});
        if (isEmpty(data))
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch collection details",
                })
            );
        const status = get(data, "status", false);
        if (status === 404) {
            const message = get(data, "data.message", "");
            //If status is 404 and message is as below redirect
            if (message.includes("Error reading record")) {
                history.push("/404");
            }
        }
        return { data, layoutData };
    }
);

export const cloneCollection = createAsyncThunk(
    "flowmanager/collection/clone",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${COLLECTION.CLONE}/${id}`,
            "POST",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to clone collection.",
                    })
                );
            }
        );
        if (get(response, "data.status", false) === "success") {
            return response.data.data;
        }
    }
);

export const onFilterApplied = createAsyncThunk(
    "flowmanager/collection/onFilterApplied",
    async (params, { dispatch, getState }) => {
        const { flowmanager } = getState();
        let initialData = flowmanager.collection.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.COLLECTION.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const typeBasedData = data => {
    const type = data?.containerType;
};

export const fetchPartnerData = createAsyncThunk(
    "flowmanager/collection/fetchPartnerData",
    async (selectedIntent, { dispatch }) => {
        const response = await customFetch(
            selectedIntent === "email"
                ? COLLECTION.EMAIL_PARTNERS
                : COLLECTION.PHONE_PARTNERS
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch partner data.",
                })
            );
        }
        // dispatch(collectionActions.setPartnerData());
        return get(response, "data.data", [])?.map(el => ({
            id: decodeURIComponent(el),
            label: decodeURIComponent(el),
        }));
    }
);

export const fetchAllHnpCategories = createAsyncThunk(
    "flowmanager/collection/fetchAllHnpCategories",
    async (id, { dispatch }) => {
        const response = await customFetch(
            `${COLLECTION.FETCH_ALL_HNP_CATEGORIES}`
        );
        if (response?.status >= 400) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch all hnp categories.",
                })
            );
        }

        dispatch(
            collectionActions.setHnpCategories(get(response, "data.data", []))
        );
    }
);

export const fetchCollectionHistory = createAsyncThunk(
    "flowmanager/collection/collectionHistory",
    async id => {
        const response = await customFetch(
            `${COLLECTION.FETCH_COLLECTION_HISTORY}/${id}`
        );
        return get(response, "data", []);
    }
);

const collection = createSlice({
    name: "collection",
    initialState,
    reducers: {
        setCollectionVertical(state, action) {
            state.createCollection.vertical = action.payload;
        },
        setCollectionType(state, action) {
            state.createCollection.type = action.payload;
        },
        setCollectionName(state, action) {
            state.createCollection.name = action.payload;
        },
        setCollectionId(state, action) {
            state.createCollection.id = action.payload;
        },
        setCollectionDesc(state, action) {
            state.createCollection.description = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.pagination.searchTerm = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        resetCollection(state) {
            state.createCollection.type = "";
            state.createCollection.name = "";
            state.createCollection.id = "";
            state.createCollection.description = "";
            state.createCollection.vertical = "";
            state.createCollection.mainLoader = false;
            state.createCollection.showPrompt = true;
            state.createCollection.containerType = "";
            state.createCollection.items = [];
            state.createCollection.detailsList = [];
            state.createCollection.deletedQuestions = [];
            state.createCollection.selectedOfferPath = {};
            state.createCollection.selectedOfferPathId = "";
            state.createCollection.selectedOfferWall = {};
            state.createCollection.selectedOfferWallId = "";
            state.createCollection.tcpa = [];
            state.createCollection.hasOfferDetails = [];
            state.createCollection.addItems = false;
            state.createCollection.partnerData = [];
            state.createCollection.gratificationType = "AdUnit";
            state.createCollection.selectedAdUnitId = "";
            state.createCollection.offerWallSlots = [];
            state.createCollection.thirdPartyUrl = "";
            state.createCollection.destinationTab = false;
            state.createCollection.focusTab = false;
            state.createCollection.enableParamForwarding = false;
        },
        resetDetails(state) {
            state.createCollection.selectedOfferPath = {};
            state.createCollection.selectedOfferPathId = "";
            state.createCollection.selectedOfferWall = {};
            state.createCollection.selectedOfferWallId = "";
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        resetCategory(state) {
            state.createCollection = initialState.createCollection;
        },
        setMainLoader(state, action) {
            state.createCollection.mainLoader = action.payload;
        },
        setShowPrompt(state, action) {
            state.createCollection.showPrompt = action.payload;
        },
        setFilter(state, action) {
            state.pagination.filters = action.payload;
        },
        setAddItems(state, action) {
            state.createCollection.addItems = action.payload;
        },
        setItems(state, action) {
            state.createCollection.items = action.payload;
        },
        setDetailItems(state, action) {
            state.createCollection.detailsList = action.payload;
        },
        setDeletedQuestions(state, action) {
            state.createCollection.deletedQuestions = action.payload;
        },
        addDetails(state, action) {
            state.createCollection.detailsList?.push(action.payload);
        },
        setDetails(state, action) {
            state.createCollection.detailsList = action?.payload;
        },
        setTcpaOptions(state, action) {
            state.createCollection.tcpa = action.payload;
        },
        setSurveyTcpaOptions(state, action) {
            state.createCollection.tcpa = action.payload;
        },
        setHasOfferDetails(state, action) {
            state.createCollection.hasOfferDetails = action.payload;
        },
        setSelectedOfferPathDetails(state, action) {
            state.createCollection.selectedOfferPath = action.payload;
        },
        setSelectedOfferPathId(state, action) {
            state.createCollection.selectedOfferPathId = action.payload;
        },
        setSelectedOfferWallDetails(state, action) {
            state.createCollection.selectedOfferWall = action.payload;
        },
        setSelectedOfferWallId(state, action) {
            state.createCollection.selectedOfferWallId = action.payload;
        },
        setPartnerData(state, action) {
            state.createCollection.partnerData = action.payload;
        },
        setHnpCategories(state, action) {
            state.createCollection.hnpCategories = action.payload;
        },
        setHistoryData(state, action) {
            state.historyData = action.payload;
        },
        setHistoryDataRaw(state, action) {
            state.historyDataRaw = action.payload;
        },
        setGratificationType(state, action) {
            state.createCollection.gratificationType = action.payload;
        },
        setSelectedAdUnitId(state, action) {
            state.createCollection.selectedAdUnitId = action.payload;
        },
        setOfferWallSlots(state, action) {
            state.createCollection.offerWallSlots = action.payload;
        },
        setThirdPartyURL(state, action) {
            state.createCollection.thirdPartyUrl = action.payload;
        },
        setDestinationTab(state, action) {
            state.createCollection.destinationTab = action.payload;
        },
        setFocusValue(state, action) {
            state.createCollection.focusTab = action.payload;
        },
        setEnableParamForwarding(state, action) {
            state.createCollection.enableParamForwarding = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchCollectionData.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchCollectionData.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload.data,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data.data", []);
            state.loading = false;
            // state.updatedAt = new Date();
        });
        builder.addCase(createUpdateCollection.pending, (state, action) => {
            state.createCollection.mainLoader = true;
        });
        builder.addCase(createUpdateCollection.fulfilled, (state, action) => {
            state.createCollection.mainLoader = false;
        });
        builder.addCase(createUpdateCollection.rejected, (state, action) => {
            state.createCollection.mainLoader = false;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(fetchCollectionById.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchCollectionById.fulfilled, (state, action) => {
            const { data, layoutData } = action.payload;
            const manipulateRegisterData = () => {
                let sortedItem = sortBy(data?.items, ele => ele?.rank);
                return (state.createCollection.items = sortedItem.map(
                    (object, index) => {
                        if (object.layoutConfig?.name) {
                            let data = layoutData?.filter(
                                _ => _.name === object.layoutConfig?.name
                            );
                            if (data?.length > 0) {
                                if (
                                    object.layoutConfig?.configurations
                                        ?.length > 0
                                ) {
                                    let dataCopy = JSON.parse(
                                        JSON.stringify(object.layoutConfig)
                                    );
                                    object.layoutConfig.configurations =
                                        dataCopy?.configurations.map(
                                            (item, i) => ({
                                                ...data[0].configurations.find(
                                                    element =>
                                                        element.key ===
                                                            item.key && element
                                                ),
                                                ...item,
                                            })
                                        );

                                    // object.layoutConfigCopy =
                                    //     object.layoutConfig;
                                    return object;
                                }
                            } else {
                                object.layoutConfig = {};
                            }
                        }
                        if (object?.tcpa?.length > 0) {
                            let tcpaCopy = JSON.parse(
                                JSON.stringify(object?.tcpa)
                            );
                            object.tcpa = tcpaCopy.map((item, i) => {
                                if (isEmpty(item?.visibility))
                                    return {
                                        ...item,
                                        visibility: ["onLoad"],
                                    };

                                return item;
                            });
                        }
                        return object;
                    }
                ));
            };

            const manipulateSurveyData = () => {
                let sortedItem = sortBy(data?.items, ele => ele?.rank);
                return (state.createCollection.detailsList = sortedItem.map(
                    (object, index) => {
                        if (object.layoutConfig?.name) {
                            let data = layoutData?.filter(
                                _ => _.name === object.layoutConfig?.name
                            );
                            if (data?.length > 0) {
                                if (
                                    object.layoutConfig?.configurations
                                        ?.length > 0
                                ) {
                                    let dataCopy = JSON.parse(
                                        JSON.stringify(object.layoutConfig)
                                    );
                                    object.layoutConfig.configurations =
                                        dataCopy?.configurations.map(
                                            (item, i) => ({
                                                ...data[0].configurations.find(
                                                    element =>
                                                        element.key ===
                                                            item.key && element
                                                ),
                                                ...item,
                                            })
                                        );

                                    // object.layoutConfigCopy =
                                    //     object.layoutConfig;
                                    return object;
                                }
                            } else {
                                object.layoutConfig = {};
                            }
                        }
                        return object;
                    }
                ));
            };

            const manipulateListingData = () => {
                let sortedItem = sortBy(data?.items, ele => ele?.rank);
                return sortedItem;
            };
            //Todo : Instead of conditions here create a function and pass type and return items
            // Will require re QA post this changes for all collection types
            state.createCollection = {
                ...state.createCollection,
                ...data,
                editPhaseVerticalNotSelected:
                    data?.vertical === "" || data?.vertical === null,
                vertical: data?.vertical || "",
                type: {
                    id: data?.containerType,
                    name: data?.containerType,
                },
                items: !isEmpty(data?.items)
                    ? data.containerType === "OFFER_WALL" ||
                      data.containerType === "DEALS_OFFERWALL" ||
                      data.containerType === "GRATIFICATION" ||
                      data.containerType === "STANDALONE" ||
                      data.containerType === "CONFIRMATION" ||
                      data.containerType === "THIRD_PARTY"
                        ? data.items
                        : data.containerType === "REGISTER" ||
                          data.containerType === "DETAILS_CONFIRMATION"
                        ? manipulateRegisterData()
                        : []
                    : [],
                detailsList: !isEmpty(data?.items)
                    ? data.containerType === "SURVEY"
                        ? manipulateSurveyData()
                        : data.containerType === "LISTINGS" ||
                          data.containerType === "OFFER"
                        ? manipulateListingData()
                        : []
                    : [],
            };

            if (data?.containerType === "OFFER") {
                state.createCollection.selectedOfferPathId =
                    +data?.items[0]?.properties?.offer?.offer_path_id;
            }
            if (data?.containerType === "OFFER_WALL") {
                state.createCollection.selectedOfferWallId =
                    +data?.items[0]?.properties?.offer?.offer_wall_id;
            }
            if (
                data?.containerType === "GRATIFICATION" ||
                data?.containerType === "STANDALONE" ||
                data?.containerType === "CONFIRMATION"
            ) {
                state.createCollection.selectedAdUnitId =
                    data?.items[0]?.properties?.adUnitId;
                state.createCollection.offerWallSlots =
                    data?.items[0]?.properties?.offerWallId;
                if (!data?.items[0]?.properties?.adUnitId)
                    state.createCollection.gratificationType = "OfferWall";
            }
            if (data?.containerType === "THIRD_PARTY") {
                state.createCollection.thirdPartyUrl =
                    data?.items[0]?.properties?.url;
                state.createCollection.destinationTab =
                    data?.items[0]?.properties?.destinationTab !== "same";
                state.createCollection.focusTab =
                    data?.items[0]?.properties?.focus !== "same";
                state.createCollection.enableParamForwarding =
                    data?.items[0]?.properties?.enableParamForwarding;
            }
            state.loading = false;
            state.createCollectionBackup = state.createCollection;
        });
    },
});

export const collectionActions = collection.actions;
export default collection.reducer;
