import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, uniqBy } from "lodash";
import { FILTERS } from "../../constants/listingSearch";
import { MESSAGES } from "../../constants/messages";
import { JTLOGO } from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import {
    getInitialRowsCount,
    getRequiredObject,
} from "../../utils/globalUtils";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { toastListActions } from "../toastList/toastList";

const initialState = {
    list: [],
    logoList: [],
    loading: false,
    updatedAt: new Date(),
    searchTerm: "",
    pagination: {
        startOffset: 0,
        rowsCount: getInitialRowsCount(),
        pageNumber: 1,
        sortBy: "",
        isDesc: true,
        totalRowsCount: 0,
        // filterKey: FILTERS.JT.filterKey,
        filterTerm: null,
        prefix: null,
        // filterOperator: FILTERS.JT.filterOperator,
        // filtersOperator: FILTERS.JT.operator,
    },
    createJT: {
        name: "",
        content: {},
        deletedPages: [],
        mainLoader: false,
        loading: true,
    },
    tableError: MESSAGES.TABLE.noDataFromApi,
};

export const fetchAllLogos = createAsyncThunk(
    "listings/jt/fetchAllLogos",
    async (noPagination, { getState, dispatch }) => {
        const response = await customFetch(
            `${JTLOGO.FETCH_JTLOGO_LIST}`,
            "POST",
            {},
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch Logo List",
                    })
                );
            }
        );
        const data = get(response, "data", []);
        return data;
    }
);

export const updateJTConfig = createAsyncThunk(
    "listings/jt/updateJTConfig",
    async (name, { getState, dispatch }) => {
        dispatch(jtActions.setMainLoader(true));
        const { listings } = getState();
        const { content, deletedPages = [] } = get(listings, "jt.createJT", {});
        const response = await customFetch(
            `${JTLOGO.UPDATE_JT_CONFIG}?fileName=${name}`,
            "PUT",
            {
                userName: "test@test.com",
                content: JSON.stringify(processContent(content, deletedPages)),
            }
        );
        return response;
    }
);

export const fetchJTConfigs = createAsyncThunk(
    "listings/jt/fetchJTConfigs",
    async (_, { getState, dispatch }) => {
        const { listings } = getState();
        const state = get(listings, "jt.pagination", {});

        const params = paginationParams(state);
        const filterParamsList = ["offset", "limit", "prefix"];
        const filterParamsData = getRequiredObject(filterParamsList, params);
        let dispatchSearchError = true;
        const response = await customFetch(
            JTLOGO.FETCH_JT_CONFIGS,
            "POST",
            filterParamsData,
            {},
            {},
            null,
            null,
            () => {
                dispatchSearchError = false;
                dispatch(
                    toastListActions.setToastList({
                        type: "Error",
                        message: "Failed to fetch hnp categories",
                    })
                );
                dispatch(
                    jtSlice.actions.setTableError(MESSAGES.TABLE.noDataFromApi)
                );
            }
        );
        const data = get(response, "data", []);
        if (dispatchSearchError && state.filterTerm) {
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${MESSAGES.TABLE_COLUMNS.CATEGORIES}`;
            dispatch(jtSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length) {
            var errorMessage = `${MESSAGES.TABLE.noData} Create a new Category`;
            dispatch(jtSlice.actions.setTableError(errorMessage));
        }
        return data;
    }
);

export const fetchJTConfigById = createAsyncThunk(
    "dashboard/jt/fetchJTConfigById",
    async (fileName, { getState, dispatch }) => {
        const response = await customFetch(`${JTLOGO.FETCH_JT_CONFIG}`, "GET", {
            fileName: decodeURIComponent(fileName),
        });
        if (!get(response, "data.success", false)) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: `Failed to delete the category with id - ${id}`,
                })
            );
        }
        return response.data;
    }
);

const processContent = (content, deletedPages) => {
    content = JSON.parse(JSON.stringify(content));
    const result = {};
    Object.keys(content).forEach(key => {
        if (deletedPages.indexOf(key) === -1) {
            result[key] = content[key];
        }
    });
    return result;
};

const tableDataToJson = (data, clearEmpty) => {
    const obj = {};
    data?.forEach(element => {
        if (clearEmpty) {
            if (element.key) {
                obj[element.key] =
                    element.values || tableDataToJson(element.subRows);
            }
        } else {
            obj[element.key] =
                element.values || tableDataToJson(element.subRows);
        }
    });
    return obj;
};

const jtSlice = createSlice({
    name: "jt",
    initialState,
    reducers: {
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setSearchTerm(state, action) {
            state.searchTerm = action.payload;
        },
        //create category actions
        setName(state, action) {
            state.createJT.name = action.payload;
        },
        setLoading(state, action) {
            state.createJT.loading = action.payload;
        },
        setMainLoader(state, action) {
            state.createJT.mainLoader = action.payload;
        },
        resetData(state, action) {
            state.createJT = initialState.createJT;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
            state.pagination.prefix = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        forceDeletePage(state, action) {
            delete state.createJT.content[action.payload];
        },
        addPage(state, action) {
            state.createJT.content[action.payload] = {};
        },
        deletePage(state, action) {
            state.createJT.deletedPages.push(action.payload);
        },
        revertDeletePage(state, action) {
            state.createJT.deletedPages.splice(
                state.createJT.deletedPages.indexOf(action.payload),
                1
            );
        },
        updatePageName(state, action) {
            const { current, updated } = action.payload;
            state.createJT.content[updated] = state.createJT.content[current];
            state.createJT.content = processContent(state.createJT.content, [
                current,
            ]);
        },
        setJobTypeValues(state, action) {
            state.createJT.content["JobTypes"] = action.payload;
        },
        setPageContent(state, action) {
            const { selectedPage, data } = action.payload;
            state.createJT.content[selectedPage] = tableDataToJson(data);
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchJTConfigs.pending, state => {
            state.loading = true;
        });
        builder.addCase(fetchJTConfigs.fulfilled, (state, action) => {
            state.pagination.totalRowsCount = get(
                action.payload,
                "metaData.totalFound",
                ""
            );
            state.list = get(action.payload, "data", []);
            state.loading = false;
            state.updatedAt = new Date();
        });
        builder.addCase(fetchJTConfigById.fulfilled, (state, action) => {
            state.createJT = {
                name: get(action.payload, "data.fileName", ""),
                content: JSON.parse(get(action.payload, "data.content", "")),
                deletedPages: [],
                mainLoader: false,
                loading: false,
            };
        });
        builder.addCase(fetchAllLogos.fulfilled, (state, action) => {
            const list = action.payload.data?.filter(
                _ => _.name && _.name[0] !== "."
            );
            state.logoList = uniqBy(list, "name");
        });
    },
});

export const jtActions = jtSlice.actions;
export default jtSlice.reducer;
