export const getPageName = () => {
    let pageName = window.location.pathname.split("/")[2];
    if (pageName === "categories") {
        let isDealCategories = window.location.pathname.split("/")[1];
        if (isDealCategories === "deals") {
            pageName = "dealCategories";
        } else pageName = "categories";
    }
    if (pageName === "userdashboard") {
        let isDetailsPage = window.location.pathname.split("/")[3];
        if (isDetailsPage === "details") {
            pageName = "userJourney";
        } else pageName = "userdashboard";
    }
    if (pageName === "flow") {
        let isCreatePage = window.location.pathname.split("/")[3];
        if (isCreatePage === "create" || isCreatePage === "edit") {
            pageName = "createFlow";
        } else pageName = "flow";
    }
    if (pageName === "scrubdashboard") {
        let getScrubType = window.location.pathname.split("/")[4];
        if (getScrubType === "advertiser") pageName = "advScrubdashboard";
        else if (getScrubType === "publisher") pageName = "pubScrubdashboard";
        else pageName = "advScrubdashboard";
    }
    return pageName;
};

export const localStoragePinData = () => {
    let initialValue = JSON.parse(localStorage.getItem("pinData"));
    let pinKey = getPageName();
    const foundIndex = initialValue?.findIndex(x => x.id === pinKey);
    return { initialValue, pinKey, foundIndex };
};

export const localStoragePinDataWithPath = pinKey => {
    let initialValue = JSON.parse(localStorage.getItem("pinData"));
    const foundIndex = initialValue?.findIndex(x => x.id === pinKey);
    return { initialValue, foundIndex };
};

export const localStorageRecentSearchData = () => {
    let initialValue = JSON.parse(localStorage.getItem("recentSearch"));
    let pinKey = getPageName();
    const foundIndexRecentSearch = initialValue?.findIndex(
        x => x.id === pinKey
    );
    return { initialValue, pinKey, foundIndexRecentSearch };
};

export const localStorageRecentFilterData = () => {
    let initialValue = JSON.parse(localStorage.getItem("recentFilter"));
    let pinKey = getPageName();
    const foundIndexRecentFilter = initialValue?.findIndex(
        x => x.id === pinKey
    );
    return { initialValue, pinKey, foundIndexRecentFilter };
};

export const getInitialTagData = (initialFilterData = [], pageName) => {
    const { initialValue, pinKey, foundIndex } = localStoragePinData();
    let finalValue = [];
    if (pageName === pinKey) {
        if (foundIndex > -1 && initialValue?.[foundIndex].isPinned) {
            finalValue = initialValue?.[foundIndex].filterApplied;
        } else {
            finalValue = initialFilterData;
        }
    }
    return finalValue;
};
