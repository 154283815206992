import { utcISO8601Format } from "./dateUtils";
import { getRandomId } from "./globalUtils";

export const mapReportMetrics = data => {
    return data.map(metrics => ({
        startTime: metrics.startTime,
        endTime: metrics.endTime,
        advertiser: metrics.advertiser,
        processId: metrics.processId,
        validatedAt: metrics.validatedAt,
        estimatedClicks: +metrics.data?.clicks?.estimated.toFixed(2),
        actualClicks: +metrics.data?.clicks?.actual.toFixed(2),
        clicksDiscrepancy: calculateDiscrepancy(
            metrics.data?.clicks?.estimated,
            metrics.data?.clicks?.actual
        ),
        estimatedRevenue: +metrics.data?.revenue?.estimated.toFixed(2),
        actualRevenue: +metrics.data?.revenue?.actual.toFixed(2),
        revenueDiscrepancy: calculateDiscrepancy(
            metrics.data?.revenue?.estimated,
            metrics.data?.revenue?.actual
        ),
    }));
};

const calculateDiscrepancy = (prevValue = 0, currentValue = 0) => {
    const value = (~~(currentValue - prevValue) / prevValue) * 100;
    if (isNaN(value)) return 0;
    return +value.toFixed(2);
};

export const mapAdvertiser = advertisers => {
    return advertisers.map(advertiser => ({
        id: getRandomId(),
        name: advertiser,
    }));
};

export const reportMetricsParams = metrics => {
    const startDate = utcISO8601Format(metrics.dateRange.startDate);
    const endDate = utcISO8601Format(metrics.dateRange.endDate);
    const adv_list = metrics.selectedAdvertisers;
    const params = {
        startTime: startDate,
        endTime: endDate,
        granularity: metrics.granularityIsHour ? "hour" : "day",
    };
    if (adv_list.length) {
        params["adv_name"] = adv_list.join(",");
    }
    return params;
};

export const validateMetricsParams = metrics => {
    return {
        startTime: metrics.startTime,
        endTime: metrics.endTime,
        alertMetrices: ["revenue", "clicks"],
        granularity: "day",
        alertChannels: [],
    };
};
