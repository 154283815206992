import React from "react";
import styles from "./Textarea.module.scss";
import Icon from "../Icon/Icon";

const Textarea = React.forwardRef(
    (
        {
            placeholder,
            className,
            onChange,
            onBlur,
            isError,
            value,
            disabled,
            rows = 3,
            showErrorMsg = false,
            errorMsg = "Invalid text",
            inputMaxValue = "",
            showInputValueLength = false,
        },
        ref
    ) => {
        return (
            <>
                <div className={styles.textAreaWrapper}>
                    {showInputValueLength && (
                        <div
                            className={styles.inputCount}
                        >{`${value?.length}/${inputMaxValue}`}</div>
                    )}
                    <textarea
                        ref={ref}
                        onBlur={onBlur}
                        onChange={onChange}
                        className={`${styles.textarea} ${className} ${
                            isError && styles.error
                        } ${!rows && styles.fixedHeight}`}
                        placeholder={placeholder}
                        value={value}
                        disabled={disabled}
                        rows={rows}
                    ></textarea>
                    {showErrorMsg && isError ? (
                        <div className={styles.errorMsg}>
                            <Icon
                                icon={"ErrorFilled"}
                                size="14px"
                                enableHover={true}
                            />
                            {errorMsg}
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </>
        );
    }
);
export default Textarea;
