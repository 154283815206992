import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { MESSAGES } from "../../constants/messages";
import {
    SCRUB_DASHBOARD,
    OFFLINE_CONVERSION,
    HNP_URL,
} from "../../constants/url";
import customFetch from "../../fetch/customFetch";
import { toastListActions } from "../toastList/toastList";
import { get, isEmpty } from "lodash";
import {
    mapAdvertiser,
    mapPublisher,
    mapCampaign,
    mapBulkScrubDashboardData,
    createParentTableData,
    getRPCLimit,
} from "../../utils/scrubDashboardUtils";
import subDays from "date-fns/subDays";
import startOfDay from "date-fns/startOfDay";
import {
    paginationParams,
    setPaginationStartOffset,
} from "../../utils/paginationUtils";
import { createTagUtil } from "../../utils/filterUtils";
import { FILTER_PARAMS } from "../../constants/filterConstants";
import { uploadDateFormat } from "../../utils/dateUtils";
import { mapCountData } from "../../utils/scrubDashboardUtils";
import { getInitialTagData } from "../../utils/localStorageUtils";

const initialDefaultDateRange = {
    startDate: startOfDay(subDays(new Date(), 7)),
    endDate: new Date(),
    key: "selection",
};

const initialState = {
    list: [],
    updatedData: [],
    unfilteredList: [],
    parentData: [],
    tableCountData: {},
    isPublisherScrub: false,
    advertisersList: [],
    publisherList: [],
    campaignList: [],
    domainList: [],
    dsCost: "",
    advCost: "",
    scrubRatio: "",
    discountFactor: 0,
    selectedAdvertiserId: null,
    selectedAdvertiserName: null,
    selectedPublisherId: null,
    selectedPublisherName: null,
    selectedDate: new Date(),
    selectedCampaignId: null,
    selectedCampaignName: null,
    selectedDomainId: null,
    selectedDomainName: null,
    product: null,
    timeZone: null,
    lookBack: null,
    updatedAt: new Date(),
    loading: false,
    advLoader: false,
    mainLoader: false,
    isValid: false,
    uploadLoader: false,
    formDate: initialDefaultDateRange,
    isDSCostFetched: false,
    isInputLoading: false,
    showFireEvents: false,
    dateArray: [],
    viewType: "Auto Calculated scrub",
    revenueTableList: [],
    uploadError: "",
    rpcLimit: 5,
    pagination: {},
    searchFilter: {
        filterApplied: getInitialTagData([], "scrubdashboard"),
    },
    publisherPopup: {
        id: "",
        name: "",
        isSameId: false,
    },
    campaignPopup: {
        publisherId: "",
        campaignId: "",
        campaignName: "",
        isSameCombination: false,
    },
    tableError: MESSAGES.TABLE.clearedAllData,
};

export const fetchBulkScrubDashboardData = createAsyncThunk(
    "bulkScrub/fetchBulkScrubDashboardData",
    async (uploadedData, { getState, dispatch }) => {
        const { processedData, readOnly } = uploadedData;
        const pagination = get(
            getState(),
            "listings.scrubDashboard.pagination",
            {}
        );
        const params = paginationParams(pagination);
        const { listings } = getState();
        const isPublisherScrub = get(
            listings,
            "scrubDashboard.isPublisherScrub",
            false
        );
        const loading = get(listings, "scrubDashboard.loading", false);

        const filterParamsList = [
            "filters",
            "filters_op",
            "offset",
            "limit",
            "sortby",
            "orderby",
        ];
        let dispatchSearchError = true;
        const response = processedData;
        let data = get(response, "data", []);
        let mappedCountData = await mapCountData(
            get(processedData, "flag_counts", {})
        );
        if (pagination.filterTerm) {
            let columnName = isPublisherScrub
                ? MESSAGES.TABLE_COLUMNS.PUBLISHER_SCRUB_DASHBOARD
                : MESSAGES.TABLE_COLUMNS.ADVERTISER_SCRUB_DASHBOARD;
            var errorMessage = `${MESSAGES.TABLE.noDataOnSearch}${columnName}`;
            dispatch(bulkScrubSlice.actions.setTableError(errorMessage));
        } else if (dispatchSearchError && !data.length && !loading) {
            var errorMessage = `${MESSAGES.TABLE.scrubDashboardNoData}`;
            dispatch(bulkScrubSlice.actions.setTableError(errorMessage));
        }
        if (!isPublisherScrub) {
            const advertiserList = get(
                listings,
                "scrubDashboard.advertisersList",
                []
            );
            const campaignList = get(
                listings,
                "scrubDashboard.campaignList",
                []
            );
            if (!isEmpty(advertiserList)) {
                data = await mapBulkScrubDashboardData(
                    get(response, "data", []),
                    advertiserList,
                    campaignList,
                    "advertiser",
                    readOnly
                );
                return {
                    ...response,
                    ...response?.data,
                    data,
                    mappedCountData,
                };
            } else {
                data = await mapBulkScrubDashboardData(
                    get(response, "data", []),
                    [],
                    [],
                    "advertiser",
                    readOnly
                );
                return { ...response, ...response.data, data, mappedCountData };
            }
        } else {
            const publisherList = get(
                listings,
                "scrubDashboard.publisherList",
                []
            );
            const campaignList = get(
                listings,
                "scrubDashboard.campaignList",
                []
            );
            if (!isEmpty(publisherList)) {
                data = await mapBulkScrubDashboardData(
                    get(response, "data", []),
                    publisherList,
                    campaignList,
                    "publisher",
                    readOnly
                );
                return {
                    ...response,
                    ...response?.data,
                    data,
                    mappedCountData,
                };
            } else {
                data = await mapBulkScrubDashboardData(
                    get(response, "data", []),
                    [],
                    [],
                    "publisher",
                    readOnly
                );
                return {
                    ...response,
                    ...response?.data,
                    data,
                    mappedCountData,
                };
            }
        }
    }
);

export const fetchAllAdvertisers = createAsyncThunk(
    "bulkScrub/fetchAllAdvertisers",
    async (_, { dispatch }) => {
        const response = await customFetch(
            OFFLINE_CONVERSION.FETCH_ALL_ADVERTISERS,
            "POST"
        );
        if (response?.status >= 400 || !response) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Advertisers",
                })
            );
        }
        const data = get(response, "data.data", []);
        return data;
    }
);

export const fetchAllPublishers = createAsyncThunk(
    "bulkScrub/fetchAllPublishers",
    async (_, { dispatch }) => {
        const response = await customFetch(
            SCRUB_DASHBOARD.FETCH_PUBLISHERS,
            "POST"
        );
        if (response?.status >= 400 || !response) {
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: "Failed to fetch Publishers",
                })
            );
        }
        const data = get(response, "data.data", []);
        return data;
    }
);

export const fetchCampaign = createAsyncThunk(
    "bulkScrub/fetchCampaign",
    async (sourceId, { getState, dispatch }) => {
        const advertiser = get(
            getState(),
            "listings.scrubDashboard.selectedAdvertiserId",
            {}
        );
        const isPublisherScrub = get(
            getState(),
            "listings.scrubDashboard.isPublisherScrub",
            false
        );
        var campaignURL = `${OFFLINE_CONVERSION.FETCH_CAMPAIGN}=${sourceId}`;
        isPublisherScrub
            ? (campaignURL = `${SCRUB_DASHBOARD.GET_CAMPAIGN_FOR_PUBLISHER}/${sourceId}/campaigns`)
            : (campaignURL = `${OFFLINE_CONVERSION.FETCH_CAMPAIGN}=${sourceId}`);

        let response = null;
        if (isPublisherScrub) {
            if (sourceId) {
                response = await customFetch(campaignURL, "GET");
            } else response = [];
        } else {
            const params = {
                url: campaignURL,
                options: {
                    headers: {
                        "Content-type": "application/json",
                    },
                    method: "GET",
                },
            };
            response = advertiser
                ? await customFetch(HNP_URL.MAX_PROXY, "POST", params)
                : null;
        }
        let data = null;
        if (isPublisherScrub) {
            data = { data: get(response, "data", []), isPublisherScrub: true };
        } else data = get(response, "data.data", []);
        return data;
    }
);

export const uploadBulkScrub = createAsyncThunk(
    "bulkScrub/uploadBulkScrub",
    async (uploadData, { getState, dispatch }) => {
        const headers = {
            "content-type": "multipart/form-data",
        };
        let formData = new FormData();
        formData.append("bulk_scrub_csv", uploadData.bulk_scrub_csv);
        formData.append("is_advertiser", uploadData.is_advertiser);
        let errorData = "";
        const response = await customFetch(
            SCRUB_DASHBOARD.BULK_UPLOAD,
            "POST",
            formData,
            headers,
            {},
            null,
            null,
            e => {
                if (get(e, "response.status", "") === 500) {
                    let errorMessage = get(e, "");
                    dispatch(
                        bulkScrubActions.setUploadError(e?.response?.data)
                    );
                    errorMessage = errorMessage
                        ? errorMessage
                        : "Invalid csv file";
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: errorMessage,
                        })
                    );
                }
            }
        );

        if (get(response, "status", "") === 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `File successfully processed`,
                })
            );
        }
        return response;
    }
);

export const uploadBulkScrubJSON = createAsyncThunk(
    "bulkScrub/uploadBulkScrubJSON",
    async (uploadData, { getState, dispatch }) => {
        const headers = {
            "content-type": "multipart/form-data",
        };
        let formData = new FormData();
        formData.append(
            "bulk_scrub_json",
            JSON.stringify(uploadData?.bulk_scrub_json)
        );
        formData.append("is_advertiser", uploadData?.is_advertiser);
        formData.append("json", uploadData?.json);
        const response = await customFetch(
            SCRUB_DASHBOARD.BULK_UPLOAD,
            "POST",
            formData,
            headers,
            {},
            null,
            null,
            e => {
                if (get(e, "response.status", "") === 500) {
                    let errorMessage = get(e, "");
                    dispatch(
                        bulkScrubActions.setUploadError(e?.response?.data)
                    );
                    errorMessage = errorMessage
                        ? errorMessage
                        : "Error in entered data";
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: errorMessage,
                        })
                    );
                }
            }
        );

        if (get(response, "status", "") === 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Data successfully processed`,
                })
            );
        }
        return response;
    }
);

export const postBulkData = createAsyncThunk(
    "bulkScrub/postBulkData",
    async ({ finalData, message }, { getState, dispatch }) => {
        const response = await customFetch(
            SCRUB_DASHBOARD.PROCESS_BULK_UPLOAD,
            "PUT",
            finalData,
            null,
            {},
            null,
            null,
            e => {
                if (get(e, "response.status", "") === 500) {
                    let errorMessage = get(e, "");
                    if (
                        e?.response?.data?.includes(
                            "repeated rows in bulk scrub"
                        )
                    )
                        errorMessage = "Repeated rows in bulk scrub";
                    else {
                        errorMessage = errorMessage
                            ? errorMessage
                            : "Failed to apply scrub";
                    }
                    dispatch(
                        toastListActions.setToastList({
                            type: "Error",
                            message: errorMessage,
                        })
                    );
                }
            }
        );

        if (get(response, "status", "") === 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `${message} applied successfully`,
                })
            );
        }
        return response;
    }
);

export const postScrubDashboardData = createAsyncThunk(
    "bulkScrub/postScrubDashboardData",
    async (
        { scrubData, dateParams, isPublisherScrub },
        { getState, dispatch }
    ) => {
        const params = dateParams;
        let postURL = "";
        isPublisherScrub
            ? (postURL = SCRUB_DASHBOARD.POST_PUBLISHER_SCRUB_DASHBOARD_DATA)
            : (postURL = SCRUB_DASHBOARD.POST_ADVERTISER_SCRUB_DASHBOARD_DATA);
        const response = await customFetch(
            postURL,
            "PUT",
            scrubData,
            null,
            { params: params },
            null,
            null,
            null
        );

        if (get(response, "status", "") >= 200) {
            dispatch(
                toastListActions.setToastList({
                    type: "Success",
                    message: `Scrub applied successfully`,
                })
            );
            dispatch(fetchScrubDashboardData(isPublisherScrub));
        } else {
            let errorMessage = get(response, "error", []);
            errorMessage =
                !isEmpty(errorMessage) && errorMessage[0]
                    ? errorMessage[0]
                    : "Failed to apply scrub data.";
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: errorMessage,
                })
            );
        }
        return response;
    }
);

export const fireDummyEvent = createAsyncThunk(
    "bulkScrub/fireDummyEvent",
    async ({ dummyEventData, isPublisherScrub }, { getState, dispatch }) => {
        let postURL = "";
        isPublisherScrub
            ? (postURL = SCRUB_DASHBOARD.FIRE_COST_DUMMY_EVENT)
            : (postURL = SCRUB_DASHBOARD.FIRE_REVENUE_DUMMY_EVENT);
        const response = await customFetch(
            postURL,
            "POST",
            dummyEventData,
            null,
            null,
            null,
            null,
            null
        );
        if (get(response, "status", "") >= 400) {
            let errorMessage = get(response, "error", []);
            errorMessage =
                !isEmpty(errorMessage) && errorMessage[0]
                    ? errorMessage[0]
                    : "Failed to fire dummy event.";
            dispatch(
                toastListActions.setToastList({
                    type: "Error",
                    message: errorMessage,
                })
            );
        }
        return response;
    }
);

export const setValidState = createAsyncThunk(
    "bulkScrub/setValidState",
    async (_, {}) => {
        return;
    }
);

export const onFilterApplied = createAsyncThunk(
    "listings/bulkScrub/onFilterApplied",
    async (params, { getState }) => {
        const { listings } = getState();
        let initialData = listings.bulkScrub.searchFilter.filterApplied;
        let filterApplied = [...initialData];

        //
        let filterParamsData = FILTER_PARAMS.BULK_SCRUB.filterParams;
        filterApplied = createTagUtil(filterApplied, params, filterParamsData);

        return { filterApplied };
    }
);

export const addNewPublisher = createAsyncThunk(
    "listings/bulkScrub/addNewPublisher",
    async (publisherData, { getState }) => {
        const response = await customFetch(
            SCRUB_DASHBOARD.CREATE_PUBLISHER,
            "POST",
            publisherData,
            null,
            null,
            null,
            null,
            null
        );
        return { response };
    }
);

export const addNewCampaign = createAsyncThunk(
    "listings/bulkScrub/addNewCampaign",
    async (campaignData, { getState }) => {
        const { name, id, pubid } = campaignData;
        const createCampaignData = {
            cmpid: id,
            name: name,
        };
        const campaignURL = `${SCRUB_DASHBOARD.CREATE_PUBLISHER}/${pubid}/campaign`;
        const response = await customFetch(
            campaignURL,
            "POST",
            createCampaignData,
            null,
            null,
            null,
            null,
            null
        );
        return { response };
    }
);

export const checkPublisherPresent = createAsyncThunk(
    "listings/bulkScrub/checkIfPublisherPresent",
    async (id, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const response = await customFetch(
            `${SCRUB_DASHBOARD.CHECK_PUBLISHER}/${id}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        // return response;
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

export const checkPublisherCampaignPresent = createAsyncThunk(
    "listings/bulkScrub/checkPublisherCampaignPresent",
    async ({ publisherId, campaignId }, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const response = await customFetch(
            `${SCRUB_DASHBOARD.CHECK_PUBLISHER}/${publisherId}/campaign/${campaignId}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

export const fetchScrubData = createAsyncThunk(
    "listings/bulkScrub/fetchScrubData",
    async ({ adv, campaign }, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const dateRange = get(
            getState(),
            "listings.scrubDashboard.formDate",
            {}
        );
        const startDate = uploadDateFormat(dateRange?.startDate);
        const endDate = uploadDateFormat(dateRange?.endDate);
        const response = await customFetch(
            `${SCRUB_DASHBOARD.GET_ADVERTISER_DATA_STUDIO_REVENUE}?srcid=${adv}&cmpid=${campaign}&startDate=${startDate}&endDate=${endDate}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

export const fetchPublisherScrubData = createAsyncThunk(
    "listings/bulkScrub/fetchPublisherScrubData",
    async ({ publisher, campaign }, { getState, dispatch }) => {
        let isError = false;
        let isErrorResponse = null;
        const dateRange = get(
            getState(),
            "listings.scrubDashboard.formDate",
            {}
        );
        const startDate = uploadDateFormat(dateRange?.startDate);
        const endDate = uploadDateFormat(dateRange?.endDate);
        const response = await customFetch(
            `${SCRUB_DASHBOARD.GET_PUBLISHER_DATA_STUDIO_COST}?srcid=${publisher}&cmpid=${campaign}&startDate=${startDate}&endDate=${endDate}`,
            "GET",
            null,
            null,
            null,
            null,
            null,
            e => {
                isErrorResponse = e?.response;
                isError = true;
                return e?.response;
            }
        );
        if (isError) {
            return isErrorResponse;
        } else return response;
    }
);

const bulkScrubSlice = createSlice({
    name: "bulkScrub",
    initialState,
    reducers: {
        setType(state, action) {
            state.isPublisherScrub = action.payload;
        },
        setDataStudioCost(state, action) {
            state.dsCost = action.payload;
        },
        setAdvertiserCost(state, action) {
            state.advCost = action.payload;
        },
        setScrubRatio(state, action) {
            state.scrubRatio = action.payload;
        },
        setDiscountFactor(state, action) {
            state.discountFactor = action.payload;
        },
        setSelectedAdvertiserId(state, action) {
            state.selectedAdvertiserId = action.payload;
        },
        setSelectedAdvertiserName(state, action) {
            state.selectedAdvertiserName = action.payload;
        },
        setSelectedPublisherId(state, action) {
            state.selectedPublisherId = action.payload;
        },
        setSelectedPublisherName(state, action) {
            state.selectedPublisherName = action.payload;
        },
        setSelectedCampaignId(state, action) {
            state.selectedCampaignId = action.payload;
        },
        setSelectedCampaignName(state, action) {
            state.selectedCampaignName = action.payload;
        },
        setSelectedDomainId(state, action) {
            state.selectedDomainId = action.payload;
        },
        setSelectedDomainName(state, action) {
            state.selectedDomainName = action.payload;
        },
        setCampaign(state, action) {
            state.campaign = action.payload;
        },
        setDateRange(state, action) {
            state.pagination.date = action.payload;
            state.formDate = action.payload;
        },
        setFormDate(state, action) {
            state.pagination.date = action.payload;
            state.formDate = action.payload;
        },
        setSortBy(state, action) {
            state.pagination.sortBy = action.payload;
        },
        setIsDesc(state, action) {
            state.pagination.isDesc = action.payload;
        },
        setPaginationType(state, action) {
            state.pagination.type = action.payload;
        },
        setSelectedDate(state, action) {
            state.selectedDate = action.payload;
        },
        setIsValid(state, action) {
            state.isValid = action.payload;
        },
        setTableError(state, action) {
            state.tableError = action.payload;
        },
        setCampaignList(state, action) {
            state.campaignList = action.payload;
        },
        setViewType(state, action) {
            state.viewType = action.payload;
        },
        setRevenueTableList(state, action) {
            state.revenueTableList = action.payload;
        },
        setUpdatedData(state, action) {
            state.updatedData = action.payload;
        },
        setParentData(state, action) {
            state.parentData = action.payload;
        },
        setUnFilteredList(state, action) {
            state.unfilteredList = action.payload;
        },
        setListData(state, action) {
            state.list = action.payload;
        },
        setUpdatedList(state, action) {
            state.updatedData = action.payload;
        },
        setTableCountData(state, action) {
            state.tableCountData = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setUploadError(state, action) {
            state.uploadError = action.payload;
        },
        resetList(state) {
            state.pagination.pageNumber = 1;
            state.pagination.startOffset = 0;
            state.list = [];
            state.updatedData = [];
            state.parentData = [];
            state.unfilteredList = [];
            state.tableCountData = {};
            state.tableError = null;
            state.loading = true;
        },
        resetState(state) {
            state.list = [];
            state.updatedData = [];
            state.parentData = [];
            state.unfilteredList = [];
            state.tableCountData = {};
            state.selectedAdvertiserId = null;
            state.selectedAdvertiserName = null;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
            state.selectedCampaignId = null;
            state.selectedCampaignName = null;
            state.selectedDomainId = null;
            state.selectedDomainName = null;
            state.selectedDate = new Date();
            state.campaign = null;
            state.product = null;
            state.timeZone = null;
            state.dsCost = "";
            state.advCost = "";
            state.scrubRatio = "";
            state.discountFactor = "";
            state.revenueTableList = [];
            state.formDate = initialDefaultDateRange;
            state.isInputLoading = false;
            state.showFireEvents = false;
            state.dateArray = [];
            state.viewType = "Auto Calculated scrub";
            state.uploadError = "";
        },
        resetAdvPublisherDropdown(state) {
            state.selectedAdvertiserId = null;
            state.selectedAdvertiserName = null;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
        },
        setFilterValue(state, action) {
            state.pagination.filterTerm = action.payload;
        },
        setRowsCount(state, action) {
            state.pagination.rowsCount = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                action.payload,
                state.pagination.pageNumber
            );
        },
        setPageNumber(state, action) {
            state.pagination.pageNumber = action.payload;
            state.pagination.startOffset = setPaginationStartOffset(
                state.pagination.rowsCount,
                action.payload
            );
        },
        setPublisherId(state, action) {
            state.publisherPopup.id = action.payload;
        },
        setPublisherName(state, action) {
            state.publisherPopup.name = action.payload;
        },
        setIsSameId(state, action) {
            state.publisherPopup.isSameId = action.payload;
        },
        resetPublisherPopup(state) {
            state.publisherPopup.name = "";
            state.publisherPopup.id = "";
            state.publisherPopup.isSameId = false;
        },

        resetPublisherSelectedValue(state) {
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
        },
        resetCampaignSelectedValue(state) {
            state.selectedCampaignId = null;
            state.selectedCampaignName = null;
            state.campaign = null;
        },
        setCampaignId(state, action) {
            state.campaignPopup.campaignId = action.payload;
        },
        setCampaignName(state, action) {
            state.campaignPopup.campaignName = action.payload;
        },
        setPublisherRefInCampaign(state, action) {
            state.campaignPopup.publisherId = action.payload;
        },
        setIsSameCombination(state, action) {
            state.campaignPopup.isSameCombination = action.payload;
        },
        resetCampaignPopup(state) {
            state.campaignPopup.campaignName = "";
            state.campaignPopup.campaignId = "";
            state.campaignPopup.publisherId = "";
            state.campaignPopup.isSameCombination = false;
        },
        setPublisherList(state, action) {
            state.publisherList = action.payload;
        },
        setCampaignList(state, action) {
            state.campaignList = action.payload;
        },
        setIsDSCostFetched(state, action) {
            state.isDSCostFetched = action.payload;
        },
        setShowFireEvents(state, action) {
            state.showFireEvents = action.payload;
        },
        setDateArray(state, action) {
            state.dateArray = action.payload;
        },
        setIsInputLoading(state, action) {
            state.isInputLoading = action.payload;
        },
        resetSidePopUpData(state) {
            state.selectedAdvertiserId = null;
            state.selectedAdvertiserName = null;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
            state.selectedCampaignId = null;
            state.selectedCampaignName = null;
            state.selectedDomainId = null;
            state.selectedDomainName = null;
            state.selectedDate = new Date();
            state.campaign = null;
            state.product = null;
            state.timeZone = null;
            state.dsCost = "";
            state.advCost = "";
            state.scrubRatio = "";
            state.discountFactor = "";
            state.revenueTableList = [];
            state.formDate = initialDefaultDateRange;
            state.isInputLoading = false;
            state.showFireEvents = false;
            state.dateArray = [];
            state.viewType = "Auto Calculated scrub";
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchBulkScrubDashboardData.pending, state => {
            state.loading = true;
        });
        builder.addCase(
            fetchBulkScrubDashboardData.fulfilled,
            (state, action) => {
                let type = state.isPublisherScrub ? "publisher" : "advertiser";
                state.loading = false;
                state.list = get(action.payload, "data", []);
                state.parentData = createParentTableData(
                    get(action.payload, "data", []),
                    type
                );
                state.updatedData = get(action.payload, "data", []);
                state.unfilteredList = get(action.payload, "data", []);
                state.rpcLimit = getRPCLimit(get(action.payload, "data", []));
                state.updatedAt = new Date();
            }
        );
        builder.addCase(fetchBulkScrubDashboardData.rejected, state => {
            state.loading = false;
        });
        builder.addCase(uploadBulkScrub.pending, state => {
            state.loading = true;
        });
        builder.addCase(uploadBulkScrub.fulfilled, (state, action) => {
            state.loading = false;
        });
        builder.addCase(uploadBulkScrub.rejected, state => {
            state.loading = false;
        });
        builder.addCase(uploadBulkScrubJSON.pending, state => {
            state.uploadLoader = true;
            state.isInputLoading = true;
        });
        builder.addCase(uploadBulkScrubJSON.fulfilled, (state, action) => {
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(uploadBulkScrubJSON.rejected, state => {
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(postBulkData.pending, state => {
            state.loading = true;
            state.mainLoader = true;
        });
        builder.addCase(postBulkData.fulfilled, (state, action) => {
            state.loading = false;
            state.mainLoader = false;
        });
        builder.addCase(postBulkData.rejected, state => {
            state.loading = false;
            state.mainLoader = false;
        });
        builder.addCase(fetchAllAdvertisers.pending, state => {
            state.advLoader = true;
        });
        builder.addCase(fetchAllAdvertisers.fulfilled, (state, action) => {
            state.advLoader = false;
            state.advertisersList = mapAdvertiser(action.payload);
        });
        builder.addCase(fetchAllAdvertisers.rejected, state => {
            state.advLoader = false;
        });
        builder.addCase(fetchAllPublishers.fulfilled, (state, action) => {
            state.publisherList = mapPublisher(action.payload);
        });
        builder.addCase(fetchCampaign.fulfilled, (state, action) => {
            const isPublisherScrub = get(
                action.payload,
                "isPublisherScrub",
                false
            );
            state.campaignList = isPublisherScrub
                ? mapCampaign(action.payload?.data, "publisher")
                : mapCampaign(action.payload, "advertiser");
        });
        builder.addCase(postScrubDashboardData.pending, state => {
            state.mainLoader = true;
            state.uploadLoader = true;
            state.isInputLoading = true;
        });
        builder.addCase(postScrubDashboardData.fulfilled, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(postScrubDashboardData.rejected, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(setValidState.fulfilled, (state, action) => {
            state.isValid = action.payload;
        });
        builder.addCase(onFilterApplied.fulfilled, (state, action) => {
            const { filterApplied } = action.payload;
            state.searchFilter.filterApplied = filterApplied;
        });
        builder.addCase(addNewPublisher.pending, state => {
            state.advLoader = true;
        });
        builder.addCase(addNewPublisher.fulfilled, (state, action) => {
            state.advLoader = false;
        });
        builder.addCase(addNewPublisher.rejected, state => {
            state.advLoader = false;
            state.selectedPublisherId = null;
            state.selectedPublisherName = null;
        });
        builder.addCase(fireDummyEvent.pending, state => {
            state.mainLoader = true;
            state.uploadLoader = true;
            state.isInputLoading = true;
        });
        builder.addCase(fireDummyEvent.fulfilled, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(fireDummyEvent.rejected, state => {
            state.mainLoader = false;
            state.uploadLoader = false;
            state.isInputLoading = false;
        });
        builder.addCase(fetchScrubData.pending, state => {
            state.isInputLoading = true;
        });
        builder.addCase(fetchScrubData.fulfilled, state => {
            state.isInputLoading = false;
        });
        builder.addCase(fetchScrubData.rejected, state => {
            state.isInputLoading = false;
        });
        builder.addCase(fetchPublisherScrubData.pending, state => {
            state.isInputLoading = true;
        });
        builder.addCase(fetchPublisherScrubData.fulfilled, state => {
            state.isInputLoading = false;
        });
        builder.addCase(fetchPublisherScrubData.rejected, state => {
            state.isInputLoading = false;
        });
    },
});

export const bulkScrubActions = bulkScrubSlice.actions;
export default bulkScrubSlice.reducer;
